var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-large","title":"帯域上限値設定"},scopedSlots:_vm._u([(_vm.isLoaded)?{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"detail-container"},[_c('div',[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"回線番号"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.enumber))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"帯域"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm._f("bandwidthTo")(_vm.bandwidth,"Mbps"))+"(設定可能帯域："),_c('span',{class:{ 'text-danger': _vm.remainingBandwidth.value < 0 }},[_vm._v(_vm._s(_vm._f("bandwidthTo")(_vm.remainingBandwidth)))]),_vm._v(")")])])],1)]),_c('cross-validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('app-table',{attrs:{"primary-key":"menu","fields":_vm.fields,"items":_vm.items,"thead-class":"text-center","sticky-header":"800px"},scopedSlots:_vm._u([{key:"cell(cloudServiceMenu)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cloudServiceMenuTo")(item.cloudServiceMenu))+" ")]}},{key:"head(bandwidth)",fn:function(){return [_c('app-badge',{attrs:{"item":"帯域上限値","isInfo":false}})]},proxy:true},{key:"cell(bandwidth)",fn:function(ref){
var item = ref.item;
return [_c('validation-provider',{attrs:{"vid":item.cloudLineSeq + 'bandwidth',"name":'帯域上限値',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('app-select',{attrs:{"options":_vm.bandwidthSelectList(item),"label":"text","reduce":function (option) { return option.value; },"appendToBody":true,"clearable":false,"state":_vm._f("validState")(validContext)},model:{value:(item.bandwidth),callback:function ($$v) {_vm.$set(item, "bandwidth", $$v)},expression:"item.bandwidth"}})]}}],null,true)})]}},{key:"head(description)",fn:function(){return [_c('app-badge',{attrs:{"item":"備考","isInfo":true,"is-required":false,"msg":_vm._f("msg")('info_max_length',{ maxLength: '200' })}})]},proxy:true},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_c('validation-provider',{attrs:{"vid":item.cloudLineSeq + 'description',"name":'備考',"rules":{ max: 200 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-textarea',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})]}}],null,true)})],1),_c('cross-validation-provider',{attrs:{"vid":"no_change","passed":_vm.cloudLineChanged}},[_vm._v(" "+_vm._s(_vm._f("msg")("no_change_data"))+" ")]),_c('cross-validation-provider',{attrs:{"vid":"no_multiple_change","passed":_vm.cloudLineNotChangedMoreThanOne}},[_vm._v(" "+_vm._s(_vm._f("msg")("more_than_one_change"))+" ")]),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("設定")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)],1)]}}],null,true)})]}}:null],null,true)},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
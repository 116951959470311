import { Option } from "@/apis/CommonApi";

/**
 * selectやradioの設定値(value)を渡すとオプションから該当する表示値(text)を返却する
 * 存在しない場合には、空文字を返却する。
 * @param value selectやradioの設定値(value)
 * @param options selectやradioに設定するオプション(value/text)
 */
export default function optionText(value: string, options: Option[]): string {
  const optionRecord = options.find((v) => v.value === value);
  return optionRecord === undefined ? "" : optionRecord.text;
}

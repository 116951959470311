import { AxiosResponse } from "axios";

import AbstractBaseApi from "./AbstractBaseApi";

/** 想ネットワークの一覧情報取得API用インタフェース */
export interface VirtualNetworkGetEntity {
  totalBillingLine: number;
  total: number;
  /** 想ネットワーク情報 */
  vnInfoList: VirtualNetworkEntity[];
  /**仮想ネットワーク設定排他情報 */
  vnKeyId: string;
}

/** 想ネットワーク情報 */
export interface VirtualNetworkEntity {
  /** 仮想ネットワークタイプ
   * L2: L2回線
   * L3: L3回線
   */
  vnType: "L2" | "L3";
  /** 仮想ネットワーク名 */
  vnName: string;
  /** VLAN IDのType指定
    L2回線のときのみ適用項目
    UNTAG: IDの指定なし
    OTHER: 他の仮想ネットワークで指定されていないID,もしくは指定なし
    SPECIFIED: 指定ID
    */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED" | null;
  /** VLAN ID */
  vlanId: number | null;
  /** VNコード */
  vnCode: string;
  /** VNコネクト数 */
  numberOfVnConnect: number;
  /** VNコネクト数(上限数) */
  upperLimitVnConnect: number;
  /** 課金対象回線数 */
  numberOfBillingLine: number;
}

export interface VirtualNetworkPostEntity {
  /** 仮想ネットワークタイプ
   * L2: L2回線
   * L3: L3回線
   */
  vnType: "L2" | "L3";
  /** 仮想ネットワーク名 */
  vnName: string;
  /** VLAN IDのType指定
    L2回線のときのみ適用項目
    UNTAG: IDの指定なし
    OTHER: 他の仮想ネットワークで指定されていないID,もしくは指定なし
    SPECIFIED: 指定ID
    */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED" | null;
  /** VLAN ID */
  vlanId: number | null;
  /** 仮想ネットワーク設定排他情報 */
  vnKeyId: string;
  /** メッセージ */
  note?: string;
}
export interface VirtualNetworkUpdateEntity {
  /** 仮想ネットワーク名 */
  vnName: string;
  /** 仮想ネットワーク設定排他情報 */
  vnKeyId: string;
  /** メッセージ */
  note?: string;
}
export interface VirtualNetworkDeleteEntity {
  /** 削除対象VNコードリスト */
  vnCodeList: string[];
  /** 仮想ネットワーク設定排他情報 */
  vnKeyId: string;
  /** メッセージ */
  note?: string;
}

export interface VNNetworkBriefResponse {
  /**
   * フロー設定対象リスト
   * 対象となるVNコネクトがない場合は空配列を返します。
   * リスト1要素当たり以下のいずれかを返し、返さない方はNULLとなる。
   * vnConnectName, wnumber：対象の回線がL3かつVLAN指定がUntagの場合NULL
   * vpnCode：対象の回線がL2の場合、もしくはL3かつVLAN指定がUntagでない場合NULL
   */
  targetList: VNetworkBrief[];
}

export interface VNetworkBrief {
  /**
   * VPNコード
   * 対象の回線がL2の場合、もしくはL3かつVLAN指定がUntagでない場合NULL
   */
  vpnCode: string | null;
  /**
   * VNコネクト名
   * 対象の回線がL3かつVLAN指定がUntagの場合NULL
   */
  vnConnectName: string | null;
  /**
   * W番号
   * 対象の回線がL3かつVLAN指定がUntagの場合NULL
   */
  wnumber: string | null;
}

export interface FlowResponse {
  /**
   * 総件数
   * 一覧表示対象の総件数
   */
  flowTotal: number;
  /**
   * フロー設定リスト
   * 対象となるフロー設定がない場合は空配列を返します.
   */
  flowList: {
    /**
     * フローSEQ
     * 主キー
     */
    flowSeq: string;
    /**
     * VNコネクト名
     * 対象の回線がL3かつVLAN指定がUntagの場合NULL
     */
    vnConnectName: string | null;
    /**
     * W番号
     * 対象の回線がL3かつVLAN指定がUntagの場合NULL
     */
    wnumber: string | null;
    /**
     * VPNコード
     * 対象の回線がL2の場合、もしくはL3かつVLAN指定がUntagでない場合NULL
     */
    vpnCode: string | null;
    /**
     * 送信元アドレス
     * ANYの場合はNULLを設定する.
     */
    srcAddress: string | null;
    /**
     * 送信先アドレス
     * ANYの場合はNULLを設定する.
     */
    dstAddress: string | null;
    /**
     * w番号有効無効(オーダステータス)
     * オーダステータス状態
     * NOTHING: 未反映
     * VALID: 有効
     * INVALID: 無効
     * L3VPNの場合はNULLを設定する.
     */
    flowStatus: "NOTHING" | "VALID" | "INVALID" | null;
    /**
     * IF状態
     * シャットダウン状態
     * NO_SHUTDOWN: 開放状態
     * SHUTDOWN: 閉塞状態
     * L3VPNの場合はNULLを設定する.
     */
    ifStatus: "NO_SHUTDOWN" | "SHUTDOWN" | null;
    /**
     * 設定値の説明
     */
    description: string | null;
  }[];
  flowKeyId: string;
}

export interface FlowRequest {
  /**
   * E番号
   * 主キー相当
   */
  enumber: string;
  /**
   * VLAN IDのType指定
   * L2回線の場合に設定し、それ以外はNULLを設定する.
   * UNTAG: IDの指定なし
   * OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
   * SPECIFIED: 指定ID
   */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED" | null;
  /**
   * VLAN ID
   * L2回線のときかつ、vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに入力可能
   */
  vlanId: number | null;
  /**
   * フロー設定変更情報リスト
   * リストの順序はフロー設定の優先度に沿って指定
   * 追加時：flowSeqはNULL
   * 変更時：flowSeqは必須
   * 削除時：本リストに対して削除対象要素の指定を行わない。
   * 全件削除時：本リストを空リストする.
   */
  flowModifyList: {
    /**
     * フローSEQ
     * 主キー
     */
    flowSeq: string | null;
    /**
     * W番号
     * 対象の回線がL3かつVLAN指定がUntagの場合NULL
     */
    wnumber: string | null;
    /**
     * VPNコード
     * 対象の回線がL2の場合、もしくはL3かつVLAN指定がUntagでない場合NULL
     */
    vpnCode: string | null;
    /**
     * 送信元アドレス
     * ANYの場合はNULLを設定する.
     */
    srcAddress: string | null;
    /**
     * 送信先アドレス
     * ANYの場合はNULLを設定する.
     */
    dstAddress: string | null;
    /**
     * 設定値の説明
     */
    description: string | null;
  }[];
  /** 操作履歴の備考 */
  note?: string | null;
  /**
   * フロー設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと.
   */
  flowKeyId: string;
}

export default class VirtualNetworkApi extends AbstractBaseApi {
  /**
   * 仮想ネットワークの一覧情報を取得
   */
  async getAll(): Promise<VirtualNetworkGetEntity> {
    return (await this.api.get("/v1/network/vnetwork/vns")).data;
  }

  /**
   * 仮想ネットワークを追加
   * @param data
   */
  async post(data: VirtualNetworkPostEntity): Promise<AxiosResponse> {
    return (await this.api.post("/v1/network/vnetwork/vn", data)).data;
  }

  /**
   * 仮想ネットワークを変更
   * @param vnCode
   * @param data
   */
  async update(
    vnCode: string,
    data: VirtualNetworkUpdateEntity
  ): Promise<AxiosResponse> {
    return (await this.api.post(`/v1/network/vnetwork/vn/${vnCode}`, data))
      .data;
  }

  /**
   * 仮想ネットワークを削除
   * @param data
   */
  async delete(data: VirtualNetworkDeleteEntity): Promise<AxiosResponse> {
    return (await this.api.post("/v1/network/vnetwork/vn/delete", data)).data;
  }

  /**
   * フロー設定可能なVN回線の一覧を取得
   */
  async getFlowAvailableVnNetwork(queries: {
    /**
     * E番号
     * 主キー相当
     */
    enumber: string;
    /**
     * VLAN IDのType指定
     * UNTAG: IDの指定なし
     * OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
     * SPECIFIED: 指定ID
     */
    vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED";
    /**
     * VLAN ID
     * vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに入力可能
     */
    vlanId?: number | null;
  }): Promise<VNNetworkBriefResponse> {
    return (
      await this.api.get("/v1/network/vnetwork/flow/target", {
        params: {
          ...queries,
        },
      })
    ).data;
  }

  /**
   * VN回線のフロー設定情報を取得
   * @param queries クエリパラメータ群
   */
  async getFlowSettings(queries: {
    /**
     * E番号
     * 主キー相当
     */
    enumber: string;
    /**
     * VLAN IDのType指定
     * UNTAG: IDの指定なし
     * OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
     * SPECIFIED: 指定ID
     */
    vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED";
    /**
     * VLAN ID
     * vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに入力可能
     */
    vlanId?: number | null;
  }): Promise<FlowResponse> {
    return (
      await this.api.get("/v1/network/vnetwork/flow", {
        params: {
          ...queries,
        },
      })
    ).data;
  }

  /**
   * VN回線のフロー設定情報を変更
   * @param data 変更するフロー設定
   */
  async updateFlowSettings(data: FlowRequest): Promise<void> {
    await this.api.post("/v1/network/vnetwork/flow", data);
  }
}

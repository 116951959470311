import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * サブネットマスクのチェック
 * チェック内容:
 *   「IPアドレス/サブネット」の形式であることかどうか
 *    IPアドレス(XXX.XXX.XXX.XXX の XXX部分)の値が0～255であること
 *    サブネットマスクは指定範囲内(値)であるかどうか
 *
 * @param value 入力値
 * @param min サブネットマスクの最小値
 * @param max サブネットマスクの最大値
 * @param exclude 除外アドレス
 * @param eq サブネットマスクの固定値
 * 範囲チェック時はmin,maxの両方を指定、例：{min:16, max:32, exclude: [31]}
 * 固定値チェック時はeqを指定、例：{eq: 20}
 */
export default {
  params: ["min", "max", "exclude", "eq"],
  validate: function (
    value: string | null | undefined,
    {
      min,
      max,
      exclude,
      eq,
    }: {
      min: number | null;
      max: number | null;
      exclude: number[] | null;
      eq: number | null;
    }
  ): boolean {
    if (!value) return true;

    const cidrArray = value.split("/");
    if (cidrArray.length !== 2) return false;

    if (!ipaddr.IPv4.isValidFourPartDecimal(cidrArray[0])) return false;

    let cidr: [ipaddr.IPv4, number];
    try {
      cidr = ipaddr.IPv4.parseCIDR(value);
    } catch (e) {
      return false;
    }

    const subnet: number = +cidr[1];
    if (exclude != null && exclude.includes(subnet)) {
      return false;
    }
    if (max && subnet > max) {
      return false;
    }
    if (min && subnet < min) {
      return false;
    }
    if (eq && subnet !== eq) {
      return false;
    }
    return true;
  },
} as ValidationRuleSchema;



























import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "Type4ExtraSiteConfirmModal",
  props: {
    message: {
      type: String as PropType<string>,
      required: true,
    },
  },
});

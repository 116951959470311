import AbstractBaseApi from "./AbstractBaseApi";

/** 通知先設定インターフェース */
export interface NotificationEntity {
  /**
   * お知らせ通知受信フラグ
   * trueの場合はON、falseの場合はOFFとする。
   */
  receivesNotifications: boolean;
  /**
   * お知らせ受信種別
   * お知らせ通知受信フラグがONの場合はいずれかのフラグをtrueに設定する。
   */
  receivingType: {
    /** その他お知らせ */
    receivesNotice: boolean;
    /** 障害情報 */
    receivesFailure: boolean;
    /** メンテナンス */
    receivesMaintenance: boolean;
  };
  /**
   * 通知先メールアドレスリスト
   */
  notificationMailList: string[];
  /**
   * 通知先排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  receiveNotificationKeyId: string;
}

export default class NotificationApi extends AbstractBaseApi {
  /** 通知先情報取得 */
  async getNotificationInfo(): Promise<NotificationEntity> {
    return (await this.api.get("/v1/support/notification")).data;
  }

  /** 通知先情報設定
   *  @param data 更新する通知先連絡情報
   */
  async update(data: NotificationEntity): Promise<void> {
    return await this.api.post("/v1/support/notification", data);
  }
}

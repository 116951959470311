






import Vue, { PropType } from "vue";
import Sortable from "sortablejs";

type SortableVue = Vue & { _sortable?: Sortable };
type Item = Record<string, unknown>;

/**
 * BootstrapVueのb-tableのtbodyに対してSortableJSでドラッグ&ドロップで順序変更が出来るようになるコンポーネント
 * 使用条件が整うならVue.Draggableを使った方がいい
 * @see https://github.com/SortableJS/Sortable
 * @see https://github.com/SortableJS/Vue.Draggable
 */
export default Vue.extend({
  name: "AppTableDraggable",
  props: {
    /** テーブルitems */
    value: {
      type: Array as PropType<Item[]>,
      default: () => [],
      required: false,
    },
    /**
     * itemsの主キー項目名
     * bootstrap-vueのtableのprimaryKeyと必ず一致させること
     * このkeyの値を使ってソートを行うため重複も禁止
     */
    primaryKey: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * SortableJS Options
     * @see https://github.com/SortableJS/Sortable
     */
    options: {
      type: Object as PropType<Sortable.Options>,
      default: () => ({}),
      required: false,
    },
  },
  computed: {
    /** primaryKeyでindex化したitems */
    indexedData(): Record<string | number, Item> {
      return this.value.toMap((e) => e[this.primaryKey] as string | number);
    },
  },
  mounted() {
    const el = this.$el.querySelector("tbody")!;
    if (!el) {
      console.warn("[draggable] not found tbody");
    }

    (this as SortableVue)._sortable = new Sortable(el, {
      animation: 150,
      handle: ".handle",
      ...this.options,
      dataIdAttr: "data-pk",
      onEnd: () => {
        // 並び替え後のDOMを元にitemsをソートしてemit
        // Sortable.toArrayはdraggableオプションを使った場合にdraggableに一致しない行が返却されないため使用できない
        let list = [] as Item[];
        el.querySelectorAll("[data-pk]").forEach((item) => {
          const pk = item.getAttribute("data-pk");
          list.push(this.indexedData[pk!]);
        });
        this.$emit("input", list);
      },
    });
  },
  destroyed() {
    (this as SortableVue)._sortable?.destroy();
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"個別サービス設定","classes":"modal-medium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"modal-content"},[_c('validation-provider',{attrs:{"name":"名前","rules":{
              required: true,
              is_half_width_alpha_numeric: true,
              max: 63,
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"名前","msg":_vm._f("msg")('info_alpha_num',{ maxLength: '63' }),"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.serviceSettingInf.serviceName),callback:function ($$v) {_vm.$set(_vm.serviceSettingInf, "serviceName", $$v)},expression:"serviceSettingInf.serviceName"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("プロトコル")]},proxy:true}],null,true)},[_c('b-form-radio-group',{attrs:{"options":_vm.protocolOptions},model:{value:(_vm.serviceSettingInf.protocol),callback:function ($$v) {_vm.$set(_vm.serviceSettingInf, "protocol", $$v)},expression:"serviceSettingInf.protocol"}})],1),_c('validation-provider',{attrs:{"name":"Port","rules":{
              required: true,
              is_custom_port: true,
              is_custom_port_length: 10,
              max: 119,
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"Port","msg":_vm._f("msg")('info_custom_port'),"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.serviceSettingInf.portNumber),callback:function ($$v) {_vm.$set(_vm.serviceSettingInf, "portNumber", $$v)},expression:"serviceSettingInf.portNumber"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"備考","rules":{ max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"備考","isRequired":false,"msg":_vm._f("msg")('info_max_length',{ maxLength: '20' }),"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.serviceSettingInf.description),callback:function ($$v) {_vm.$set(_vm.serviceSettingInf, "description", $$v)},expression:"serviceSettingInf.description"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.serviceSetting ? "変更" : "追加"))]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
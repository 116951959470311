import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import { Type1Zone } from "@/apis/Type1SiteApi";
/**
 * ゾーン接続重複チェックバリデーション.
 * 指定された一覧の中のいずれとも組み合わせが一致しないこと
 */

export default {
  params: ["srcZone", "relation"],
  validate(
    dstZone: Type1Zone | undefined | null,
    {
      srcZone,
      relation,
    }: {
      srcZone: Type1Zone | undefined | null;
      relation: ZoneRelationItem[];
    }
  ): boolean {
    if (!dstZone || !srcZone) {
      return true;
    }
    const relationFlag = relation.some(
      (e) => e.srcZoneId === srcZone.zoneId && e.dstZoneId === dstZone.zoneId
    );
    return !relationFlag;
  },
} as ValidationRuleSchema;

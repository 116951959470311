import {
  AlertPlugin,
  BadgePlugin,
  BFormInvalidFeedback,
  BFormTimepicker,
  BIconApp,
  BIconArrowsExpand,
  BIconArrowRepeat,
  BIconBezier2,
  BIconBook,
  BIconBoundingBox,
  BIconBox,
  BIconCardHeading,
  BIconCaretDown,
  BIconCaretUp,
  BIconCheck,
  BIconChevronBarUp,
  BIconChevronDown,
  BIconClockHistory,
  BIconCloudPlus,
  BIconClouds,
  BIconCloudUpload,
  BIconColumnsGap,
  BIconDash,
  BIconDiagram2,
  BIconDiagram3,
  BIconDownload,
  BIconEnvelope,
  BIconExclamationTriangle,
  BIconFileEarmarkArrowUp,
  BIconFileEarmarkText,
  BIconFileEarmarkTextFill,
  BIconGear,
  BIconGeoFill,
  BIconGlobe,
  BIconGraphUp,
  BIconInfoCircle,
  BIconJournalRichtext,
  BIconJournalCheck,
  BIconJoystick,
  BIconJustify,
  BIconKey,
  BIconLayerBackward,
  BIconList,
  BIconListUl,
  BIconPeople,
  BIconPlus,
  BIconReception4,
  BIconSearch,
  BIconShare,
  BIconShieldLock,
  BIconSnow,
  BIconStack,
  BIconStickies,
  BIconStickiesFill,
  BIconStoplights,
  BIconUiRadios,
  BImg,
  ButtonGroupPlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTagsPlugin,
  InputGroupPlugin,
  LinkPlugin,
  ListGroupPlugin,
  NavbarPlugin,
  OverlayPlugin,
  PaginationPlugin,
  PopoverPlugin,
  ProgressPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  TooltipPlugin,
} from "bootstrap-vue";
import { Vue as _Vue } from "vue/types/vue";
import { PluginObject } from "vue/types/plugin";
import AppBFormInput from "@/components/AppBFormInput.vue";
import AppBFormTextarea from "@/components/AppBFormTextarea.vue";

const AppBootstrapPlugin: PluginObject<undefined> = {
  install(Vue: typeof _Vue): void {
    // componentの追加
    Vue.use(AlertPlugin)
      .use(BadgePlugin)
      .use(ButtonGroupPlugin)
      .use(ButtonPlugin)
      .use(CardPlugin)
      .use(CollapsePlugin)
      .use(DropdownPlugin)
      .use(FormCheckboxPlugin)
      .use(FormDatepickerPlugin)
      .use(FormFilePlugin)
      .use(FormGroupPlugin)
      // ラッパーcomponentがあるので不要
      // .use(FormInputPlugin)
      .use(FormPlugin)
      .use(FormRadioPlugin)
      .use(FormSelectPlugin)
      .use(FormTagsPlugin)
      // ラッパーcomponentがあるので不要
      // .use(FormTextareaPlugin)
      .use(InputGroupPlugin)
      .use(InputGroupPlugin)
      // LinkPluginは不要になるかも
      .use(LinkPlugin)
      .use(ListGroupPlugin)
      .use(NavbarPlugin)
      .use(OverlayPlugin)
      .use(PaginationPlugin)
      .use(PopoverPlugin)
      .use(ProgressPlugin)
      .use(SpinnerPlugin)
      .use(TablePlugin)
      .use(TabsPlugin)
      .use(TooltipPlugin);

    // ラッパーcomponent
    Vue.component("b-form-input", AppBFormInput);
    Vue.component("b-form-textarea", AppBFormTextarea);

    // アイコンの追加
    Vue.component("BFormInvalidFeedback", BFormInvalidFeedback);
    Vue.component("BFormTimepicker", BFormTimepicker);
    Vue.component("BIconArrowsExpand", BIconArrowsExpand);
    Vue.component("BIconArrowRepeat", BIconArrowRepeat);
    Vue.component("BIconApp", BIconApp);
    Vue.component("BIconBezier2", BIconBezier2);
    Vue.component("BIconBoundingBox", BIconBoundingBox);
    Vue.component("BIconBook", BIconBook);
    Vue.component("BIconBox", BIconBox);
    Vue.component("BIconCardHeading", BIconCardHeading);
    Vue.component("BIconCaretUp", BIconCaretUp);
    Vue.component("BIconCaretDown", BIconCaretDown);
    Vue.component("BIconChevronBarUp", BIconChevronBarUp);
    Vue.component("BIconChevronDown", BIconChevronDown);
    Vue.component("BIconClockHistory", BIconClockHistory);
    Vue.component("BIconCloudPlus", BIconCloudPlus);
    Vue.component("BIconCloudUpload", BIconCloudUpload);
    Vue.component("BIconClouds", BIconClouds);
    Vue.component("BIconColumnsGap", BIconColumnsGap);
    Vue.component("BIconDiagram2", BIconDiagram2);
    Vue.component("BIconDiagram3", BIconDiagram3);
    Vue.component("BIconDash", BIconDash);
    Vue.component("BIconDownload", BIconDownload);
    Vue.component("BIconEnvelope", BIconEnvelope);
    Vue.component("BIconExclamationTriangle", BIconExclamationTriangle);
    Vue.component("BIconFileEarmarkArrowUp", BIconFileEarmarkArrowUp);
    Vue.component("BIconFileEarmarkText", BIconFileEarmarkText);
    Vue.component("BIconFileEarmarkTextFill", BIconFileEarmarkTextFill);
    Vue.component("BIconGear", BIconGear);
    Vue.component("BIconGeoFill", BIconGeoFill);
    Vue.component("BIconGraphUp", BIconGraphUp);
    Vue.component("BIconGlobe", BIconGlobe);
    Vue.component("BIconInfoCircle", BIconInfoCircle);
    Vue.component("BIconJournalRichtext", BIconJournalRichtext);
    Vue.component("BIconJournalCheck", BIconJournalCheck);
    Vue.component("BIconJoystick", BIconJoystick);
    Vue.component("BIconJustify", BIconJustify);
    Vue.component("BIconKey", BIconKey);
    Vue.component("BIconLayerBackward", BIconLayerBackward);
    Vue.component("BIconList", BIconList);
    Vue.component("BIconListUl", BIconListUl);
    Vue.component("BIconPeople", BIconPeople);
    Vue.component("BIconPlus", BIconPlus);
    Vue.component("BIconReception4", BIconReception4);
    Vue.component("BIconSearch", BIconSearch);
    Vue.component("BIconShare", BIconShare);
    Vue.component("BIconShieldLock", BIconShieldLock);
    Vue.component("BIconSnow", BIconSnow);
    Vue.component("BIconStack", BIconStack);
    Vue.component("BIconStickies", BIconStickies);
    Vue.component("BIconStickiesFill", BIconStickiesFill);
    Vue.component("BIconStoplights", BIconStoplights);
    Vue.component("BIconUiRadios", BIconUiRadios);
    Vue.component("BIconCheck", BIconCheck);
    Vue.component("BImg", BImg);
  },
};

export default AppBootstrapPlugin;

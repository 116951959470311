import AbstractBaseApi from "@/apis/AbstractBaseApi";

export interface PutPassword {
  /** 現在のパスワード */
  currentPassword: string;
  /** 新しいパスワード */
  newPassword: string;
  /** 新しいパスワード（確認用） */
  confirmation: string;
}

export interface PostOnetime {
  /** ログインID */
  loginId: string;
  /** メールアドレス */
  email: string;
  /**
   * ワンタイム認証URL.
   * URLを指定する場合は、設定する。
   * 決められたドメイン以外は受け付けない。
   */
  oneTimeUrl: string | null;
}

export interface ReIssuance {
  /** お問い合わせ先名 */
  contact: string;
  /** お問い合わせ先電話番号 */
  contactPhone: string;
  /** 受付時間 */
  acceptanceDateTime: string;
  /**
   * ワンタイム認証結果コード.
   * NOT_EXISTS: ワンタイムURL識別キーが存在しません,
   * TIMEOUT_KDDI: ワンタイムURL識別キーが有効期間切れ（KDDI運用者）
   * TIMEOUT_CORP: ワンタイムURL識別キーが有効期間切れ（法人管理者）
   * VALID: ワンタイムURL識別キーが有効
   */
  oneTimeResultCode: "NOT_EXISTS" | "TIMEOUT_KDDI" | "TIMEOUT_CORP" | "VALID";
}

export interface PostReIssuance {
  /** ログインID */
  loginId: string;
  /** メールアドレス */
  email: string;
  /**
   * ログインURL.
   * URLを指定する場合は、設定する。
   * 決められたドメイン以外は受け付けない。
   */
  loginUrl: string | null;
}

/** パスワードAPI */
export default class PasswordApi extends AbstractBaseApi {
  /**
   * パスワード変更を行う
   * @param data リクエストデータ
   */
  async putPassword(data: PutPassword): Promise<void> {
    await this.api.post(`/v1/password/edit`, data);
  }

  /**
   * ワンタイムURL発行
   * @param data リクエストデータ
   */
  async postOnetime(data: PostOnetime): Promise<void> {
    await this.api.post(`/v1/password/onetime`, data);
  }

  /**
   * ワンタイム認証キーによる認証を行い、その結果を返却する
   * @param identifyKey ワンタイムURL識別キー. 30桁のユニークなキー
   */
  async getReIssuance(identifyKey: string): Promise<ReIssuance> {
    return (
      await this.api.get(`/v1/password/reissueance`, {
        params: { identifyKey },
      })
    ).data;
  }

  /**
   * パスワード再発行メールを送信する
   * @param identifyKey ワンタイムURL識別キー. 30桁のユニークなキー
   * @param data リクエストデータ
   */
  async postReIssuance(
    identifyKey: string,
    data: PostReIssuance
  ): Promise<ReIssuance> {
    return (
      await this.api.post(`/v1/password/reissueance`, data, {
        params: { identifyKey },
      })
    ).data;
  }
}

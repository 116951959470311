import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";
/**
 * IPアドレスから数字に変換
 * 例：192.168.1.1 → 3232235777
 */
function ip2int(ip: string) {
  return (
    ip.split(".").reduce(function (ipInt, octet) {
      return (ipInt << 8) + parseInt(octet, 10);
    }, 0) >>> 0
  );
}

/**
 * WANアドレスとピアIPアドレスの相関チェック.
 * チェック内容：
 * ピアIPアドレスはWANアドレスと同じネットワークアドレス内であること
 * ピアIPアドレスはWANアドレス+1であること
 */
export default {
  params: ["wan"],
  validate(
    value: string | undefined | null,
    { wan }: { wan: string }
  ): boolean {
    if (!value || !wan) {
      return true;
    }
    let wanCidr: [ipaddr.IPv4, number];
    try {
      wanCidr = ipaddr.IPv4.parseCIDR(wan);
    } catch (e) {
      return true;
    }
    let peerAddress: ipaddr.IPv4;
    try {
      peerAddress = ipaddr.IPv4.parse(value);
    } catch (e) {
      return true;
    }
    // WANアドレスとピアIPアドレスが連番ではないと、エラー
    const wanNumber = ip2int(wanCidr[0].toString());
    const peerNumber = ip2int(peerAddress.toString());
    return peerNumber - wanNumber === 1;
  },
} as ValidationRuleSchema;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-large-plus","title":"エクストラサイト設定(Type4)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [(_vm.isLoaded)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"col-5 p-0 my-2"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":_vm.extraSite === null ? '新規サイト名' : 'サイト名',"msg":_vm._f("msg")('info_max_length',{ maxLength: 20 }),"placement":"right"}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"サイト名","rules":{
                  max: 20,
                  required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","state":_vm._f("validState")(validContext),"disabled":_vm.isPolicy && _vm.extraSite !== null},model:{value:(_vm.extraData.siteName),callback:function ($$v) {_vm.$set(_vm.extraData, "siteName", $$v)},expression:"extraData.siteName"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1)],1),_c('b-form-group',[_c('div',{staticClass:"row mb-2"},[_c('div',{class:_vm.extraSite === null ? 'col-5 ml-1' : 'col-4 ml-1'},[_c('app-badge',{attrs:{"item":"アドレス名","msg":_vm._f("msg")('info_max_length',{ maxLength: 20 }),"isRequired":false,"placement":"right"}})],1),_c('div',{class:_vm.extraSite === null ? 'col-5 mr-4' : 'col-4 mr-4'},[_c('app-badge',{attrs:{"item":"アドレス","msg":_vm._f("msg")('info_cidr_ip_address_of_network_address',{
                        ipaddress1: '192.168.0.0/24',
                        genre: 'アドレス',
                        subnet1: '1',
                        subnet2: '30',
                        ipaddress2: '192.168.0.1/32',
                      }),"is-required":_vm.hasInputIpAddressName ||
                    _vm.extraData.addressList.length !== 1,"placement":"right"}})],1),(_vm.extraSite !== null)?_c('div',{staticClass:"col mr-5"},[_c('app-badge',{attrs:{"item":"ポリシー使用有無","isRequired":false,"is-info":false}})],1):_vm._e()]),_vm._l((_vm.extraData.addressList),function(e,index){return _c('div',{key:index,staticClass:"row align-items-center mb-2"},[_c('validation-provider',{class:_vm.extraSite === null ? 'col-5' : 'col-4',attrs:{"name":'アドレス名' + index,"rules":{
                  max: 20,
                }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"disabled":e.isPolicyInUse || (_vm.isPolicy && e.ipAddressId !== null),"state":_vm._f("validState")(validContext)},model:{value:(e.ipAddressName),callback:function ($$v) {_vm.$set(e, "ipAddressName", $$v)},expression:"e.ipAddressName"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}),_c('validation-provider',{class:_vm.extraSite === null ? 'col-5 ml-2' : 'col-4 ml-2',attrs:{"name":"アドレス","vid":("address" + index),"rules":{
                  required:
                    !!e.ipAddressName || _vm.extraData.addressList.length !== 1,
                  is_ip_subnet: { min: 1, max: 32, exclude: [31] },
                  is_not_current_ip: true,
                  is_not_loopback_ip: true,
                  is_not_multicast_ip: true,
                  is_not_class_e_ip: true,
                  is_not_broadcast_ip: true,
                  is_network_address: true,
                  duplicate: { list: _vm.existsIpAddress(index) },
                }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"disabled":e.isPolicyInUse || (_vm.isPolicy && e.ipAddressId !== null),"state":_vm._f("validState")(validContext),"placeholder":_vm.$msg('placeholder_ip_address_of_private_network_address')},model:{value:(e.ipAddress),callback:function ($$v) {_vm.$set(e, "ipAddress", $$v)},expression:"e.ipAddress"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}),_c('div',{staticClass:"col mt-2"},[(_vm.extraSite !== null)?_c('span',[_vm._v(_vm._s(_vm._f("enumTo")(e.isPolicyInUse,"isPolicyInUse")))]):_vm._e()]),(
                  (!e.isPolicyInUse && !_vm.isPolicy) ||
                  (e.ipAddressId === null && _vm.isPolicy)
                )?_c('div',{staticClass:"col-1 mr-2"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.removeIPAddress(e)}}},[_c('b-icon-dash')],1)],1):_vm._e()],1)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-1 mr-2"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.addIPAddress}},[_c('b-icon-plus')],1)],1)])],2)],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.extraSite === null ? "追加" : "変更"))]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)])]}}],null,true)}):_vm._e()]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

// 入力値がCIDR形式になっているか検証
// ----------------------------------------
// チェック内容
// 1.文字列に"/"があるかチェック
// 2.IP部分が"xx.xx.xx.xx"の形式であるかチェック
// 3.CIDR形式であるかチェック
// 4.ネットワークアドレスであることを確認
//
// cidr: 192.168.255.0/24
// ipアドレスをlong(binary)変換:
// 0b 11000000 10101000 11111111 00000000
// ホスト部マスクを作成:
// 0b 00000000 00000000 00000000 11111111
// ビット論理和が0になるかチェック
// 0b 11000000 10101000 11111111 00000000
// &
// 0b 00000000 00000000 00000000 11111111
// =
// 0b 00000000 00000000 00000000 00000000
// ホスト部が0なのでtrue
export default {
  validate(value: string | null | undefined): boolean {
    if (!value) return true;

    const cidrArray = value.split("/");
    if (cidrArray.length !== 2) return false;

    if (!ipaddr.IPv4.isValidFourPartDecimal(cidrArray[0])) return false;

    let cidr: [ipaddr.IPv4, number];
    try {
      cidr = ipaddr.IPv4.parseCIDR(value);
    } catch (e) {
      return false;
    }

    const ipLong = parseInt(
      cidr[0]
        .toByteArray()
        .map((e) => e.toString(2).padStart(8, "0"))
        .join(""),
      2
    );
    const hostMaskLong = parseInt(
      String("").padStart(cidr[1], "0").padEnd(32, "1"),
      2
    );
    const hostAddr = ipLong & hostMaskLong;
    return hostAddr === 0;
  },
} as ValidationRuleSchema;

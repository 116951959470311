











import Vue, { PropType } from "vue";
import { IntraPolicy } from "@/apis/IntranetfwPolicyApi";

/** ポリシー設定(イントラネットFW)の宛先アドレスを表示するコンポーネント */
export default Vue.extend({
  name: "IntranetPolicyDstAddress",
  props: {
    /** 宛先アドレスリスト */
    ipAddressList: {
      type: Array as PropType<IntraPolicy["dstAddressList"]>,
      required: true,
    },
  },
  computed: {
    /**
     * ソート済みの宛先アドレスを返却する
     * アドレス名・アドレスの昇順
     */
    sortedIpAddressList() {
      return this.ipAddressList.sortBy([
        (e) => e.ipAddressName ?? e.ipAddress,
        "asc",
      ]);
    },
  },
});
























































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  BandWidth,
  RouteAggregation,
  VpnVnCode as CloudVpnVnCode,
} from "@/apis/CloudVnMsApi";
import { CloudLine } from "@/apis/CloudLineApi";
import cloneDeep from "lodash/cloneDeep";
import { VpnVnCode } from "@/apis/InformationApi";
import {
  CloudVnOracleDetail,
  CloudVnOraclePost,
  CloudVnOraclePut,
} from "@/apis/CloudVnOracleApi";
import MultiCloudOracleVnConnectionConfirm from "@/modals/multicloud/oracle/MultiCloudOracleVnConnectionConfirm.vue";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";

export default Vue.extend({
  name: "MultiCloudOracleVnLineEdit",
  props: {
    /** クラウド回線SEQ */
    cloudLineSeq: {
      type: String as PropType<string>,
      required: true,
    },
    // 変更の場合用
    cloudVnOracleDetail: {
      type: Object as PropType<CloudVnOracleDetail>,
      default: undefined,
    },
    // 追加の場合用
    cloudVnOracleKeyId: {
      type: String as PropType<string>,
      default: undefined,
    },
  },
  data() {
    // 新規/更新のformデータを作成
    const form:
      | CloudVnOraclePost
      | (CloudVnOraclePut & {
          cloudVnConnectSby: { serviceKey: string; asNumber: number };
        }) = (() => {
      if (this.cloudVnOracleDetail) {
        // 変更
        const cloudVnOracleDetailCopy = cloneDeep(this.cloudVnOracleDetail);
        const form: CloudVnOraclePut & {
          cloudVnConnectSby: { serviceKey: string; asNumber: number };
        } = {
          cloudVnConnectCommon: cloudVnOracleDetailCopy.cloudVnConnectCommon,
          cloudVnConnectAct: cloudVnOracleDetailCopy.cloudVnConnectAct,
          cloudVnConnectSby: cloudVnOracleDetailCopy.cloudVnConnectSby
            ? cloudVnOracleDetailCopy.cloudVnConnectSby
            : ({
                asNumber: 31898,
                maximumPrefix: 100,
              } as CloudVnOraclePut["cloudVnConnectSby"] & {
                serviceKey: string;
                asNumber: number;
              }),
          cloudVnOracleKeyId: cloudVnOracleDetailCopy.cloudVnOracleKeyId,
        };
        return form;
      } else {
        // 追加
        const form: CloudVnOraclePost = {
          cloudVnConnectCommon: {
            bandwidthType: "PF",
            routeAggregation: "OFF",
            routeAggregationAddressList: [""],
            location: "EAST_JAPAN",
          } as CloudVnOraclePost["cloudVnConnectCommon"],
          cloudVnConnectAct: {
            asNumber: 31898,
            maximumPrefix: 100,
          } as CloudVnOraclePost["cloudVnConnectAct"],
          cloudVnConnectSby: {
            asNumber: 31898,
            maximumPrefix: 100,
          } as CloudVnOraclePost["cloudVnConnectSby"],
          cloudVnOracleKeyId: this.cloudVnOracleKeyId,
        };
        return form;
      }
    })();

    return {
      form,
      isLoaded: false,
      vpnVnCodeList: [] as VpnVnCode[],
      cloudLineMaxBandwidth: {} as CloudLine["bandwidth"],
      selectedVpnVn: null as null | { value: CloudVpnVnCode; text: string },
      bandwidthOptions: [
        { text: "10Mbps", value: { value: 10, unit: "MBPS" } },
        { text: "20Mbps", value: { value: 20, unit: "MBPS" } },
        { text: "30Mbps", value: { value: 30, unit: "MBPS" } },
        { text: "40Mbps", value: { value: 40, unit: "MBPS" } },
        { text: "50Mbps", value: { value: 50, unit: "MBPS" } },
        { text: "60Mbps", value: { value: 60, unit: "MBPS" } },
        { text: "70Mbps", value: { value: 70, unit: "MBPS" } },
        { text: "80Mbps", value: { value: 80, unit: "MBPS" } },
        { text: "90Mbps", value: { value: 90, unit: "MBPS" } },
        { text: "100Mbps", value: { value: 100, unit: "MBPS" } },
        { text: "200Mbps", value: { value: 200, unit: "MBPS" } },
        { text: "300Mbps", value: { value: 300, unit: "MBPS" } },
        { text: "400Mbps", value: { value: 400, unit: "MBPS" } },
        { text: "500Mbps", value: { value: 500, unit: "MBPS" } },
        { text: "600Mbps", value: { value: 600, unit: "MBPS" } },
        { text: "700Mbps", value: { value: 700, unit: "MBPS" } },
        { text: "800Mbps", value: { value: 800, unit: "MBPS" } },
        { text: "900Mbps", value: { value: 900, unit: "MBPS" } },
        { text: "1Gbps", value: { value: 1, unit: "GBPS" } },
        { text: "2Gbps", value: { value: 2, unit: "GBPS" } },
        { text: "3Gbps", value: { value: 3, unit: "GBPS" } },
        { text: "4Gbps", value: { value: 4, unit: "GBPS" } },
        { text: "5Gbps", value: { value: 5, unit: "GBPS" } },
        { text: "6Gbps", value: { value: 6, unit: "GBPS" } },
        { text: "7Gbps", value: { value: 7, unit: "GBPS" } },
        { text: "8Gbps", value: { value: 8, unit: "GBPS" } },
        { text: "9Gbps", value: { value: 9, unit: "GBPS" } },
        { text: "10Gbps", value: { value: 10, unit: "GBPS" } },
      ] as { text: string; value: BandWidth }[],
      routeOptions: [
        { value: "ON", text: "ON" },
        { value: "OFF", text: "OFF" },
        { value: "CUSTOM", text: "CUSTOM" },
      ],
      locationOptions: [
        { value: "EAST_JAPAN", text: "東日本" },
        { value: "WEST_JAPAN", text: "西日本" },
      ],
      /** Maximum Prefix上限値(変更の場合用) */
      maximumPrefixLimit: null as UpperLimitEntity | null,
      /** 1G超VNコネクト */
      vnConnects1G: null as UpperLimitEntity | null,
    };
  },
  computed: {
    /** VNコネクト(Act)名編集可否 */
    isVnConnectNameActEnable() {
      if (this.isAdd) {
        return true;
      }
      return !this.cloudVnOracleDetail!.cloudVnConnectAct.isVnConnectStatus;
    },

    /** VNコネクト(Sby)名編集可否 */
    isVnConnectNameSbyEnable() {
      // 追加あるいはSby自体が存在しない場合
      if (this.isAdd || !this.cloudVnOracleDetail!.cloudVnConnectSby) {
        return true;
      }
      return !this.cloudVnOracleDetail.cloudVnConnectSby.isVnConnectStatus;
    },
    /** 相関チェック
     * false: Sbyの登録とAct、Commonの編集は同時に行う場合 */
    multipleOption(): boolean {
      if (
        this.isAdd ||
        this.cloudVnOracleDetail.cloudVnConnectSby ||
        !this.form.cloudVnConnectSby?.serviceKey
      ) {
        return true;
      }
      let changed = this.$crossValidation.objectChanged(
        this.cloudVnOracleDetail.cloudVnConnectCommon,
        this.form.cloudVnConnectCommon!
      );
      if (!changed) {
        changed = this.$crossValidation.objectChanged(
          this.cloudVnOracleDetail.cloudVnConnectAct,
          this.form.cloudVnConnectAct!
        );
      }
      return !changed;
    },
    currentLength(): number {
      return this.form.cloudVnConnectCommon!.routeAggregationAddressList
        ? this.form.cloudVnConnectCommon!.routeAggregationAddressList.length
        : 0;
    },
    /** true: 新規, false: 変更 */
    isAdd(): boolean {
      return !this.cloudVnOracleDetail;
    },

    vpnVnCodeSelectList(): {
      value: string;
      text: string;
    }[] {
      return this.vpnVnCodeList.map((v) => {
        if (v.vnName) {
          return {
            value: v.vpnVnCode,
            text: v.vnName + ":" + v.vpnVnCode + "(L3)",
          };
        } else {
          return { value: v.vpnVnCode, text: v.vpnVnCode };
        }
      });
    },
    /** 品目の選択肢 */
    bandwidthSelectList(): { text: string; value: BandWidth }[] {
      // クラウド帯域値
      const limit1 = this.$filter.bandwidthToBps(this.cloudLineMaxBandwidth);
      // 1G超VNコネクト
      const limit2 = this.$filter.bandwidthToBps({
        value: this.vnConnects1G!.upperLimit,
        unit: "GBPS",
      });
      return this.bandwidthOptions.filter((v) => {
        const bps = this.$filter.bandwidthToBps(v.value)!;
        return bps <= limit1! && bps <= limit2!;
      });
    },
    /** Maximum Prefixの選択肢 */
    maximumPrefixSelectList(): { text: string; value: number }[] {
      if (this.isAdd) {
        return [{ value: 100, text: "100" }];
      } else {
        return [
          { value: 100, text: "100" },
          { value: 200, text: "200" },
          { value: 300, text: "300" },
          { value: 400, text: "400" },
          { value: 500, text: "500" },
          { value: 1000, text: "1000" },
          { value: 1500, text: "1500" },
          { value: 2000, text: "2000" },
        ].filter((v) => v.value <= this.maximumPrefixLimit!.upperLimit);
      }
    },
    vpnVnName(): string {
      if (
        this.cloudVnOracleDetail.cloudVnConnectCommon.vpnVnCode.startsWith("D")
      ) {
        return (
          this.cloudVnOracleDetail.cloudVnConnectCommon.vnName +
          ":" +
          this.cloudVnOracleDetail.cloudVnConnectCommon.vpnVnCode +
          "(L3)"
        );
      } else {
        return this.cloudVnOracleDetail.cloudVnConnectCommon.vpnVnCode;
      }
    },
    /** true: Sbyに関する項目は非活性にする */
    isDisableRoutingSby(): boolean {
      return !(
        (this.cloudVnOracleDetail &&
          this.cloudVnOracleDetail.cloudVnConnectSby) ||
        this.form.cloudVnConnectSby?.serviceKey
      );
    },
    /** true: Sbyに関する項目はValidation必要 */
    isRequireRoutingSby(): boolean {
      // Sby情報はdisabledの場合、false
      return !this.isDisableRoutingSby;
    },
    /** true:回線詳細にSby情報が登録済みの場合 */
    hasSby(): boolean {
      return !!(
        this.cloudVnOracleDetail && this.cloudVnOracleDetail.cloudVnConnectSby
      );
    },
    /** 経路集約アドレスの重複チェック用リスト作成 */
    routingAddressList() {
      return (index: number) => {
        return this.form.cloudVnConnectCommon!.routeAggregationAddressList
          ? this.form.cloudVnConnectCommon!.routeAggregationAddressList.filter(
              (_, num) => num !== index
            )
          : [];
      };
    },
  },
  watch: {
    /** 経路集約がCUSTOMに変更されて初期値が存在しない場合は初期化 */
    "form.cloudVnConnectCommon.routeAggregation"(newValue: RouteAggregation) {
      if (
        newValue === "CUSTOM" &&
        (!this.form.cloudVnConnectCommon!.routeAggregationAddressList ||
          this.form.cloudVnConnectCommon!.routeAggregationAddressList.length ===
            0)
      ) {
        this.form.cloudVnConnectCommon!.routeAggregationAddressList = [""];
      }
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      // 回線情報に紐づくクラウド回線情報を取得APIを呼び出す
      const lines = (await this.$api.cloudLine.getCloudLines()).lineList;
      const cloudLines = lines.flatMap((v) => v.cloudLineList);
      // クラウド回線の帯域最大値(品目の上限値制御用)
      this.cloudLineMaxBandwidth = cloudLines.find(
        (v) => v.cloudLineSeq === this.cloudLineSeq
      )!.bandwidth;
      // 1G超VNコネクトの値を取得（品目の上限値制御用）
      this.vnConnects1G = (
        await this.$api.upperLimit.getUpperLimitLine({
          upperLimitLineManageUnit: "MULTI_CLOUD",
          cloudLineSeq: this.cloudLineSeq,
        })
      ).cloudLine!.upperLimitList!.find(
        (v) => v.upperLimitItemName === "1G超VNコネクト"
      )!;

      if (this.isAdd) {
        this.vpnVnCodeList = (await this.$api.information.getVpnVnCodeList())
          .filter((v) => v.vnType === "L3" || v.vnType === null)
          .sortBy(["vpnVnCode", "asc"]);
      } else {
        // maximumPrefixの上限値を取得
        this.maximumPrefixLimit = (
          await this.$api.upperLimit.getUpperLimitLine({
            upperLimitLineManageUnit: "MULTI_CLOUD",
            cloudLineSeq: this.cloudLineSeq,
            wnumber: this.cloudVnOracleDetail!.cloudVnConnectAct.wnumber,
          })
        ).cloudLine!.upperLimitList!.find(
          (v) => v.upperLimitItemName === "BGP4の最大経路数"
        )!;
      }
    },

    /** 経路集約アドレス削除 */
    removeRouteAddress(index: number) {
      const list = this.form.cloudVnConnectCommon!.routeAggregationAddressList!;
      if (list.length > 1) {
        list.splice(index, 1);
      } else {
        this.form.cloudVnConnectCommon!.routeAggregationAddressList = [""];
      }
    },

    /** 経路集約アドレス追加 */
    addRouteAddress() {
      this.form.cloudVnConnectCommon!.routeAggregationAddressList = [
        ...this.form.cloudVnConnectCommon!.routeAggregationAddressList!,
        "",
      ];
    },

    async submit() {
      const form = {
        ...this.form,
        cloudVnConnectCommon: {
          ...this.form.cloudVnConnectCommon,
          routeAggregationAddressList:
            this.form.cloudVnConnectCommon!.routeAggregation === "ON"
              ? []
              : this.form.cloudVnConnectCommon!.routeAggregation === "OFF"
              ? null
              : this.form.cloudVnConnectCommon!.routeAggregationAddressList,
        },
      } as
        | CloudVnOraclePost
        | (CloudVnOraclePut & {
            cloudVnConnectSby: { serviceKey: string; asNumber: number };
          });

      if (this.isAdd) {
        // 追加の場合
        // 確認モーダルの表示
        let displayVnName;
        const vnSbyServiceKey = form.cloudVnConnectSby?.serviceKey;
        (form as CloudVnOraclePost).cloudVnConnectCommon!.vpnVnCode =
          this.selectedVpnVn!.value;
        displayVnName = this.vpnVnCodeList.find(
          (v) =>
            v.vpnVnCode ===
            (form as CloudVnOraclePost).cloudVnConnectCommon!.vpnVnCode
        )!.vnName;
        await this.$modal.show(MultiCloudOracleVnConnectionConfirm, {
          cloudVnOracleDetail: {
            cloudVnConnectCommon: {
              ...form.cloudVnConnectCommon,
              vnName: displayVnName,
              routingType: "BGP4",
            },
            cloudVnConnectAct: {
              ...form.cloudVnConnectAct,
              localPreference: "HIGH_PRIORITY",
              med: "LOW_PRIORITY",
            },
            cloudVnConnectSby: vnSbyServiceKey
              ? {
                  ...form.cloudVnConnectSby,
                  localPreference: "LOW_PRIORITY",
                  med: "HIGH_PRIORITY",
                }
              : null,
          },
          displayType: "A",
        });
        await this.$api.cloudVnOracle.postVnConnect(
          this.cloudLineSeq,
          (form.cloudVnConnectSby?.serviceKey
            ? form
            : { ...form, cloudVnConnectSby: null }) as CloudVnOraclePost
        );
        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      } else {
        if (
          !this.cloudVnOracleDetail.cloudVnConnectSby?.serviceKey &&
          form.cloudVnConnectSby?.serviceKey
        ) {
          // Sbyのみ追加の場合
          // 確認モーダルの表示
          await this.$modal.show(MultiCloudOracleVnConnectionConfirm, {
            cloudVnOracleDetail: {
              cloudVnConnectCommon: form.cloudVnConnectCommon,
              cloudVnConnectAct: form.cloudVnConnectAct,
              cloudVnConnectSby: {
                ...form.cloudVnConnectSby,
                localPreference: "LOW_PRIORITY",
                med: "HIGH_PRIORITY",
              },
            },
            displayType: "M",
          });
          await this.$api.cloudVnOracle.postVnConnect(this.cloudLineSeq, {
            cloudVnConnectCommon: null,
            cloudVnConnectAct: null,
            cloudVnConnectSby: {
              ...form.cloudVnConnectSby,
              pairCloudVnConnectSeq:
                this.cloudVnOracleDetail.cloudVnConnectAct.cloudVnConnectSeq,
            },
            cloudVnOracleKeyId: this.cloudVnOracleDetail!.cloudVnOracleKeyId,
          } as CloudVnOraclePost);
        } else {
          // 変更のみの場合
          // 確認モーダルの表示
          const vnSbyServiceKey = form.cloudVnConnectSby?.serviceKey;
          await this.$modal.show(MultiCloudOracleVnConnectionConfirm, {
            cloudVnOracleDetail: {
              ...form,
              cloudVnConnectSby: vnSbyServiceKey
                ? form.cloudVnConnectSby
                : null,
            },
            // 確認画面で品目をGbpsからGbpsへ変更した場合に注意文言を表示するため、変更前の品目の値を格納
            beforeBandWidth:
              this.cloudVnOracleDetail?.cloudVnConnectCommon.bandwidth,
            displayType: "M",
          });
          await this.$api.cloudVnOracle.putVnConnect(
            this.cloudLineSeq,
            this.cloudVnOracleDetail.cloudVnConnectAct.wnumber,
            (this.cloudVnOracleDetail.cloudVnConnectSby?.serviceKey
              ? form
              : { ...form, cloudVnConnectSby: null }) as CloudVnOraclePut
          );
        }

        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },
  },
});

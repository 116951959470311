import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 連続したsymbolの値が使用できないこと
 */
export default {
  params: ["symbol"],
  validate(
    value: string | undefined | null,
    { symbol }: { symbol: string }
  ): boolean {
    if (!value) {
      return true;
    }

    /** 連続したsymbolの値が入力できないこと */
    return !value.includes(symbol + symbol);
  },
} as ValidationRuleSchema;

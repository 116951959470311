




























































































































































































import Vue, { PropType } from "vue";
import {
  PutType1InternetAddress,
  Type1ExtraSiteModify,
  Type1ExtraSiteRegister,
  Type1InternetAddress,
  Type1SiteEdit,
  Type1Zone,
} from "@/apis/Type1SiteApi";

export default Vue.extend({
  name: "ExtraSiteEdit",
  props: {
    extraSite: {
      type: Object as PropType<Type1SiteEdit | null>,
      default: null,
    },
    extraZone: {
      type: Object as PropType<Type1Zone | null>,
      default: null,
    },
    type1SiteZoneKeyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      /** 詳細データ */
      extraData: {} as Type1SiteEdit,
      extraZoneId: this.extraZone?.zoneId || "none",
      isLoaded: false,
    };
  },
  computed: {
    /** 既存のIPアドレス */
    existsAddress() {
      // 既存のアドレスリストに同じIPアドレスが存在した場合はエラーとする
      return (index: number) => {
        return this.extraData.addressList
          .filter((_, num) => num !== index)
          .map((v) => v.ipAddress);
      };
    },
    /** 関連項目チェック:zoneがnull場合ゾーン配置をチェック */
    noExtraZone(): boolean {
      return !this.extraData.addressList.some((item) => item.zoneId === "none");
    },
    /** アドレス名入力済みの場合は,true */
    hasInputIpAddressName(): boolean {
      return this.extraData.addressList.some((v) => v.ipAddressName);
    },
    /** ゾーン配置チェック済みの場合は,true */
    hasCheckZone(): boolean {
      return this.extraData.addressList.some((v) => v.zoneId);
    },
    hasCheckIsDeploy(): boolean {
      return this.extraData.addressList.some((v) => v.isDeploy);
    },
  },

  mounted() {
    this.load();
    this.isLoaded = true;
  },

  methods: {
    /**
     * 初期化機能
     * システム上の配置状態追加(addressList)
     */
    load() {
      // 追加モードdefault値の設定
      if (this.extraSite === null) {
        this.extraData = {
          siteId: null,
          siteName: "",
          addressTotal: 0,
          addressList: [
            {
              ipAddressId: null,
              ipAddressName: "",
              ipAddress: "",
              zoneId: null,
              isDeploy: false,
            },
          ],
        };
      } else {
        // 変更モード値のコピー
        const data: PutType1InternetAddress[] = this.extraSite.addressList.map(
          (e) => ({
            ...e,
            isDeploy: !!e.zoneId,
          })
        );
        this.extraData = Object.assign({}, this.extraSite);
        this.extraData.addressList = data;
        if (this.extraData.addressList.length < 1) this.addIPAddress();
      }
    },
    /** 追加、変更機能 */
    async submit() {
      if (this.extraData.siteId) {
        // 変更機能
        await this.$confirm(
          "エクストラサイト設定を変更します。よろしいですか？"
        );
        // 変更データの設定
        const reqData: Type1ExtraSiteModify = {
          siteName: this.extraData.siteName,
          addressList:
            this.extraData.addressList.length === 1 &&
            !this.extraData.addressList[0].ipAddress
              ? []
              : this.extraData.addressList.map((e) => ({
                  ipAddressId: e.ipAddressId,
                  ipAddressName: e.ipAddressName || null,
                  ipAddress: e.ipAddress,
                  zoneId: e.zoneId,
                })),
          type1SiteZoneKeyId: this.type1SiteZoneKeyId,
        };
        await this.$api.type1SiteZone.postType1ExtraSiteModify(
          reqData,
          this.extraData.siteId
        );
      } else {
        // 追加機能
        await this.$confirm("エクストラサイトを追加します。よろしいですか？");
        // 追加データの設定
        const reqData: Type1ExtraSiteRegister = {
          siteName: this.extraData.siteName,
          addressList:
            this.extraData.addressList.length === 1 &&
            !this.extraData.addressList[0].ipAddress
              ? []
              : this.extraData.addressList.map((e) => ({
                  ipAddressName: e.ipAddressName || null,
                  ipAddress: e.ipAddress,
                  zoneId: e.zoneId,
                })),
          type1SiteZoneKeyId: this.type1SiteZoneKeyId,
        };
        await this.$api.type1SiteZone.postType1ExtraSiteRegister(reqData);
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * IPAddress追加機能
     * 押下時一行追加
     */
    addIPAddress() {
      this.extraData.addressList.push({
        ipAddressId: null,
        ipAddressName: "",
        ipAddress: "",
        zoneId: null,
        isDeploy: false,
      });
    },
    /**
     * IPAddress削除機能
     * 押下時その行削除
     * 一行しかない場合その行クリア
     */
    removeIPAddress(target: Type1InternetAddress) {
      if (this.extraData.addressList.length > 1) {
        this.extraData.addressList.splice(
          this.extraData.addressList.indexOf(target),
          1
        );
      } else {
        this.extraData.addressList = [
          {
            ipAddressId: null,
            ipAddressName: "",
            ipAddress: "",
            zoneId: null,
            isDeploy: false,
          },
        ];
      }
    },
  },
});

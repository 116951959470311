import messages_json from "../config/message/messages.json";
// eslint-disable-next-line no-restricted-imports
import { template } from "lodash";
export type Messages = typeof messages_json;

/**
 * message keyを変換定義に従って画面表示用の文字列に変換
 * @param msgKey messageのキー
 * @param value 補間用データ、ない場合指定しない
 * @example
 *        - messages.json: "max_vn_connects": "{{ maxCount }}件以上の登録はできません。"
 *        - typescript: this.$filter.message("max_vn_connects", {maxCount: 50}) => 50件以上の登録はできません。
 */
export default function message(
  msgKey: keyof Messages,
  value: Record<string, string | number> | undefined = undefined
): string {
  const msgTemplateStr = messages_json[msgKey];
  if (msgTemplateStr) {
    const compiled = template(msgTemplateStr, {
      interpolate: /{{([\s\S]+?)}}/g,
    });
    return compiled(value);
  }
  return "the message key is incorrect!!!";
}

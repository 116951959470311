






















































































































































































































































import Vue, { PropType } from "vue";
import AccessLineModifyConfirm from "@/modals/accessLine/AccessLineModifyConfirm.vue";
import { BandWidthEntity, BandwidthListGetEntity } from "@/apis/AccessLineApi";
import cloneDeep from "lodash/cloneDeep";
import { diff } from "deep-object-diff";
import { AppTableData } from "@/components/AppTable.vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";

export type BandwidthList = Omit<BandwidthListGetEntity, "bandwidthType"> & {
  bandwidthType:
    | "BANDWIDTH_SECURED"
    | "TRAFFICFREE"
    | "BURST"
    | "BESTEFFORT"
    | null;
  burst: "BURST10" | "BURST100" | null;
};

export type BandwidthOption =
  | { value: BandWidthEntity; text: string }
  | { value: string; text: string };

export default Vue.extend({
  name: "AccessLineModify",
  props: {
    /** 変更対象アクセス回線リスト */
    accessList: {
      type: Array as PropType<BandwidthListGetEntity[]>,
      required: true,
    },
    /** 排他制御キー */
    bandwidthKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      /** 一覧の表示内容の定義 */
      accessLine: {
        fields: [
          {
            key: "enumberAct",
            label: "回線番号",
          },
          {
            key: "menu",
            label: "メニュー",
          },
          {
            key: "bandwidthType",
            label: "帯域タイプ",
          },
          {
            key: "fixingBandwidth",
            label: "帯域固定",
          },
          {
            key: "bandwidth",
            label: "帯域値",
          },
          {
            key: "qos",
            label: "QoS",
          },
          {
            key: "vlanControl",
            label: "VLAN制御",
          },
          {
            key: "office",
            label: "事業所名",
          },
        ],
        items: cloneDeep(this.accessList).map((e) => ({
          ...e,
          bandwidthType:
            e.bandwidthType === "BURST10" || e.bandwidthType === "BURST100"
              ? "BURST"
              : e.bandwidthType,
          burst:
            e.bandwidthType === "BURST10" || e.bandwidthType === "BURST100"
              ? e.bandwidthType
              : null,
        })),
      } as AppTableData<BandwidthList>,
      /** 帯域タイプがバースト時の帯域値選択肢 */
      burstOptions: [
        { value: "BURST10", text: "バースト10" },
        { value: "BURST100", text: "バースト100" },
      ],
      /** 帯域固定、QOS、VLAN制御の選択肢 */
      onOffOptions: [
        { value: "ON", text: "ON" },
        { value: "OFF", text: "OFF" },
      ],
      /** 変更前リスト */
      originalAccessLine: [] as BandwidthList[],
      /** 変更後リスト */
      changedAccessLine: {} as Record<string, BandwidthList>,
      /** 契約上限値のデータマップ */
      contractUpperLimitMap: {} as Record<string, UpperLimitEntity>,
      /** 初期通信完了フラグ */
      isLoaded: false,
    };
  },
  computed: {
    /** 法人ユーザーの場合、true */
    isNormalUser(): boolean {
      return this.$service.permission.isNormalUser();
    },
    objectChanged(): boolean {
      const changedObject: string[] = Object.keys(
        diff(this.originalMap, this.changedAccessLine)
      );
      return changedObject && changedObject.length > 0;
    },
    /** QOSと帯域タイプの併用できない場合、enumberリストを生成 */
    inValidenumber(): string[] {
      return this.accessLine.items
        .filter((v) => v.bandwidthType === "TRAFFICFREE" && v.qos === "ON")
        .map((v) => v.enumberAct);
    },
    /** 一覧の表示項目 */
    displayColumns(): BvTableFieldArray {
      if (this.isNormalUser) {
        // 法人ユーザーの場合、帯域固定は非表示
        return this.accessLine.fields.filter(
          (v) => (v as { key: string }).key !== "fixingBandwidth"
        );
      }
      return this.accessLine.fields;
    },
    /** 変更前のデータマップ */
    originalMap(): Record<string, BandwidthList> {
      return this.originalAccessLine.toMap((v) => v.lineSeq);
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.originalAccessLine = cloneDeep(this.accessLine.items);
      this.changedAccessLine = cloneDeep(this.originalMap);
      const promises = this.originalAccessLine.map(async (e) => {
        if (e.menu === "MULTI_CLOUD_GW") {
          const upperLimitLine = await this.$api.upperLimit.getUpperLimitLine({
            upperLimitLineManageUnit: "MULTI_CLOUD",
            enumber: e.enumberAct,
          });
          this.contractUpperLimitMap[e.enumberAct] =
            upperLimitLine.cloudLine!.upperLimitList!.find(
              (v) => v.upperLimitItemName === "契約上限値"
            )!;
        }
      });
      if (promises.length !== 0) {
        await Promise.all(promises);
      }
    },

    /** 帯域タイプの選択肢を取得
     * 要件の特記事項1を参考 */
    bandwidthTypeOptions(
      item: BandwidthList
    ): BandwidthList["bandwidthType"][] {
      if (availableBandwidthType[item.menu]) {
        const bandwidthType = availableBandwidthType[item.menu].find((e) => {
          return e.is(item);
        });
        if (bandwidthType) {
          return bandwidthType.bandwidthType;
        } else {
          // 想定外の回線パターン、開発の場合は多分モックデータがおかしい
          console.warn(item.menu);
          throw new Error(`not defined bandwidthType by accessType`);
        }
      } else {
        console.warn(item.menu);
        throw new Error(`not defined accessType`);
      }
    },
    /** 帯域値の選択肢を取得
     * 要件の特記事項1を参考 */
    bandwidthOptions(item: BandwidthList) {
      const bandwidth = bandwidthList[item.menu].find((e) => {
        return e.is(item);
      });
      if (bandwidth) {
        let optLst: BandwidthOption[] = bandwidth.bandwidths;
        // マルチクラウドの場合、「契約条件値」以下の値しか表示されない
        if (item.menu === "MULTI_CLOUD_GW") {
          // APIに「契約上限値」が存在しない場合、上限値を50Gbpsにする
          const multiCloudLimit = this.contractUpperLimitMap[item.enumberAct]
            .upperLimit
            ? this.$filter.bandwidthToBps({
                value: this.contractUpperLimitMap[item.enumberAct].upperLimit,
                unit: this.contractUpperLimitMap[
                  item.enumberAct
                ].unit.toUpperCase() as "GBPS" | "MBPS",
              }) ?? 0
            : this.$filter.bandwidthToBps({
                value: 50,
                unit: "GBPS",
              }) ?? 0;
          optLst = bandwidth.bandwidths.filter((v) => {
            let bandWidthEntityValue = v as {
              value: BandWidthEntity;
              text: string;
            };
            const bps =
              this.$filter.bandwidthToBps({
                value: bandWidthEntityValue.value.value,
                unit: bandWidthEntityValue.value.unit,
              }) ?? 0;
            return bps <= multiCloudLimit!;
          });
        }
        // 変更前のbandwidthが{value: 0 ,unit: null}の場合、選択肢に0Mbpsを追加
        const beforeItem = this.originalMap[item.lineSeq];
        if (
          beforeItem &&
          beforeItem.bandwidth &&
          beforeItem.bandwidth.value === 0
        ) {
          return [
            { value: { value: 0, unit: null }, text: "0Mbps" },
            ...optLst,
          ];
        }
        return optLst;
      } else {
        console.warn(item.menu, item.bandwidthType);
        throw new Error(
          `not defined bindwidth by accessType and bandwidthType`
        );
      }
    },
    /**
     * ETHERNET2/ETHERNET2_PFで且つ広帯域(10G/100G)の場合、true
     */
    isEthernet2Broadband(item: BandwidthListGetEntity): boolean {
      return (
        (item.menu === "ETHERNET2" || item.menu === "ETHERNET2_PF") &&
        (item.physicalInterface === "IF_10G" ||
          item.physicalInterface === "IF_100G")
      );
    },

    async submit() {
      const noteTxt: string | null = await this.$modal.show(
        AccessLineModifyConfirm,
        {
          accessList: this.originalAccessLine,
          modifiedAccessList: this.accessLine.items,
        }
      );

      await this.$api.accessLine.update({
        bandwidthList: this.accessLine.items.map((v) => {
          return {
            lineSeq: v.lineSeq,
            bandwidthType:
              v.bandwidthType === "BURST" ? v.burst! : v.bandwidthType!,
            fixingBandwidth: v.fixingBandwidth,
            bandwidth:
              v.bandwidth && v.bandwidth.value % 1 !== 0
                ? this.$service.bandwidth.convertUnit(v.bandwidth, "KBPS")
                : v.bandwidth,
            qos: v.qos,
            vlanControl: v.vlanControl,
          };
        }),
        note: noteTxt || null,
        bandwidthKeyId: this.bandwidthKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
  watch: {
    "accessLine.items": {
      handler: function (newValue: BandwidthList[]): void {
        if (newValue?.length > 0) {
          const target = newValue.find((a) => {
            return (
              a.bandwidthType !==
              this.changedAccessLine[a.lineSeq].bandwidthType
            );
          });

          if (target) {
            target.bandwidth = null;
            target.burst = null;
          }
          // 変更済リストを上書き
          this.changedAccessLine = cloneDeep(newValue).toMap((v) => v.lineSeq);
        }
      },
      deep: true,
    },
  },
});

/** (WVS2UI03-02)アクセス回線申込 - 特記事項1 */
const availableBandwidthType: Record<
  BandwidthListGetEntity["menu"],
  {
    /** true: 対象の回線, false: 対象の回線ではない */
    is: (e: BandwidthList) => boolean;
    bandwidthType: BandwidthList["bandwidthType"][];
  }[]
> = {
  EXTEND_ETHERNET: [
    { is: () => true, bandwidthType: ["BANDWIDTH_SECURED", "BURST"] },
  ],
  ETHERNET2: [
    {
      is: (e) =>
        e.physicalInterface === "IF_10G" || e.physicalInterface === "IF_100G",
      bandwidthType: ["BANDWIDTH_SECURED"],
    },
    {
      is: (e) => !e.isUno,
      bandwidthType: ["BANDWIDTH_SECURED", "TRAFFICFREE"],
    },
    { is: (e) => e.isUno, bandwidthType: ["BANDWIDTH_SECURED"] },
  ],
  BROADBAND_ACCESS3: [{ is: () => true, bandwidthType: ["BESTEFFORT"] }],
  WIRELESS_ACCESS2: [{ is: () => true, bandwidthType: ["BESTEFFORT"] }],
  ETHERNET_LIGHT: [{ is: () => true, bandwidthType: ["BESTEFFORT"] }],
  PF_ACCESS_GW: [{ is: () => true, bandwidthType: ["BANDWIDTH_SECURED"] }],
  PF_ACCESS_GW2: [{ is: () => true, bandwidthType: ["BANDWIDTH_SECURED"] }],
  ETHERNET2_PF: [{ is: () => true, bandwidthType: ["BANDWIDTH_SECURED"] }],
  MULTI_CLOUD_GW: [{ is: () => true, bandwidthType: ["BANDWIDTH_SECURED"] }],
};

/** (WVS2UI03-02)アクセス回線申込 - 特記事項1 */
const bandwidthList: Record<
  BandwidthList["menu"],
  {
    /** true: 対象の回線, false: 対象の回線ではない */
    is: (e: BandwidthList) => boolean;
    /** 選択可能帯域値 */
    bandwidths: BandwidthOption[];
  }[]
> = {
  EXTEND_ETHERNET: [
    {
      is: (e) => e.bandwidthType === "BURST",
      bandwidths: [
        { value: "BURST10", text: "バースト10" },
        { value: "BURST100", text: "バースト100" },
      ],
    },
    {
      is: (e) => e.bandwidthType === "BANDWIDTH_SECURED",
      bandwidths: [
        { value: { value: 0.5, unit: "MBPS" }, text: "0.5Mbps" },
        { value: { value: 1, unit: "MBPS" }, text: "1Mbps" },
        { value: { value: 2, unit: "MBPS" }, text: "2Mbps" },
        { value: { value: 5, unit: "MBPS" }, text: "5Mbps" },
        { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
        { value: { value: 20, unit: "MBPS" }, text: "20Mbps" },
        { value: { value: 50, unit: "MBPS" }, text: "50Mbps" },
        { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
      ],
    },
  ],
  PF_ACCESS_GW: [
    {
      is: (e) => e.bandwidthType === "BANDWIDTH_SECURED",
      bandwidths: [
        { value: { value: 200, unit: "MBPS" }, text: "200Mbps" },
        { value: { value: 300, unit: "MBPS" }, text: "300Mbps" },
        { value: { value: 400, unit: "MBPS" }, text: "400Mbps" },
        { value: { value: 500, unit: "MBPS" }, text: "500Mbps" },
        { value: { value: 600, unit: "MBPS" }, text: "600Mbps" },
        { value: { value: 700, unit: "MBPS" }, text: "700Mbps" },
        { value: { value: 800, unit: "MBPS" }, text: "800Mbps" },
        { value: { value: 900, unit: "MBPS" }, text: "900Mbps" },
        { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
      ],
    },
  ],
  ETHERNET2: [
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        !e.isUno &&
        e.physicalInterface === "IF_100M",
      bandwidths: [
        { value: { value: 0.5, unit: "MBPS" }, text: "0.5Mbps" },
        { value: { value: 1, unit: "MBPS" }, text: "1Mbps" },
        { value: { value: 2, unit: "MBPS" }, text: "2Mbps" },
        { value: { value: 3, unit: "MBPS" }, text: "3Mbps" },
        { value: { value: 4, unit: "MBPS" }, text: "4Mbps" },
        { value: { value: 5, unit: "MBPS" }, text: "5Mbps" },
        { value: { value: 6, unit: "MBPS" }, text: "6Mbps" },
        { value: { value: 7, unit: "MBPS" }, text: "7Mbps" },
        { value: { value: 8, unit: "MBPS" }, text: "8Mbps" },
        { value: { value: 9, unit: "MBPS" }, text: "9Mbps" },
        { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
        { value: { value: 20, unit: "MBPS" }, text: "20Mbps" },
        { value: { value: 30, unit: "MBPS" }, text: "30Mbps" },
        { value: { value: 40, unit: "MBPS" }, text: "40Mbps" },
        { value: { value: 50, unit: "MBPS" }, text: "50Mbps" },
        { value: { value: 60, unit: "MBPS" }, text: "60Mbps" },
        { value: { value: 70, unit: "MBPS" }, text: "70Mbps" },
        { value: { value: 80, unit: "MBPS" }, text: "80Mbps" },
        { value: { value: 90, unit: "MBPS" }, text: "90Mbps" },
        { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "TRAFFICFREE" &&
        !e.isUno &&
        e.physicalInterface === "IF_100M",
      bandwidths: [
        { value: { value: 0.5, unit: "MBPS" }, text: "0.5Mbps" },
        { value: { value: 1, unit: "MBPS" }, text: "1Mbps" },
        { value: { value: 2, unit: "MBPS" }, text: "2Mbps" },
        { value: { value: 3, unit: "MBPS" }, text: "3Mbps" },
        { value: { value: 4, unit: "MBPS" }, text: "4Mbps" },
        { value: { value: 5, unit: "MBPS" }, text: "5Mbps" },
        { value: { value: 6, unit: "MBPS" }, text: "6Mbps" },
        { value: { value: 7, unit: "MBPS" }, text: "7Mbps" },
        { value: { value: 8, unit: "MBPS" }, text: "8Mbps" },
        { value: { value: 9, unit: "MBPS" }, text: "9Mbps" },
        { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
        { value: { value: 20, unit: "MBPS" }, text: "20Mbps" },
        { value: { value: 30, unit: "MBPS" }, text: "30Mbps" },
        { value: { value: 40, unit: "MBPS" }, text: "40Mbps" },
        { value: { value: 50, unit: "MBPS" }, text: "50Mbps" },
        { value: { value: 60, unit: "MBPS" }, text: "60Mbps" },
        { value: { value: 70, unit: "MBPS" }, text: "70Mbps" },
        { value: { value: 80, unit: "MBPS" }, text: "80Mbps" },
        { value: { value: 90, unit: "MBPS" }, text: "90Mbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        !e.isUno &&
        e.physicalInterface === "IF_1G",
      bandwidths: [
        { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
        { value: { value: 200, unit: "MBPS" }, text: "200Mbps" },
        { value: { value: 300, unit: "MBPS" }, text: "300Mbps" },
        { value: { value: 400, unit: "MBPS" }, text: "400Mbps" },
        { value: { value: 500, unit: "MBPS" }, text: "500Mbps" },
        { value: { value: 600, unit: "MBPS" }, text: "600Mbps" },
        { value: { value: 700, unit: "MBPS" }, text: "700Mbps" },
        { value: { value: 800, unit: "MBPS" }, text: "800Mbps" },
        { value: { value: 900, unit: "MBPS" }, text: "900Mbps" },
        { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "TRAFFICFREE" &&
        !e.isUno &&
        e.physicalInterface === "IF_1G",
      bandwidths: [{ value: { value: 100, unit: "MBPS" }, text: "100Mbps" }],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        !e.isUno &&
        e.physicalInterface === "IF_10G",
      bandwidths: [
        { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
        { value: { value: 2, unit: "GBPS" }, text: "2Gbps" },
        { value: { value: 3, unit: "GBPS" }, text: "3Gbps" },
        { value: { value: 4, unit: "GBPS" }, text: "4Gbps" },
        { value: { value: 5, unit: "GBPS" }, text: "5Gbps" },
        { value: { value: 6, unit: "GBPS" }, text: "6Gbps" },
        { value: { value: 7, unit: "GBPS" }, text: "7Gbps" },
        { value: { value: 8, unit: "GBPS" }, text: "8Gbps" },
        { value: { value: 9, unit: "GBPS" }, text: "9Gbps" },
        { value: { value: 10, unit: "GBPS" }, text: "10Gbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        !e.isUno &&
        e.physicalInterface === "IF_100G",
      bandwidths: [
        { value: { value: 10, unit: "GBPS" }, text: "10Gbps" },
        { value: { value: 20, unit: "GBPS" }, text: "20Gbps" },
        { value: { value: 30, unit: "GBPS" }, text: "30Gbps" },
        { value: { value: 40, unit: "GBPS" }, text: "40Gbps" },
        { value: { value: 50, unit: "GBPS" }, text: "50Gbps" },
        { value: { value: 60, unit: "GBPS" }, text: "60Gbps" },
        { value: { value: 70, unit: "GBPS" }, text: "70Gbps" },
        { value: { value: 80, unit: "GBPS" }, text: "80Gbps" },
        { value: { value: 90, unit: "GBPS" }, text: "90Gbps" },
        { value: { value: 100, unit: "GBPS" }, text: "100Gbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        e.isUno &&
        e.physicalInterface === "IF_10M",
      bandwidths: [
        { value: { value: 1, unit: "MBPS" }, text: "1Mbps" },
        { value: { value: 2, unit: "MBPS" }, text: "2Mbps" },
        { value: { value: 3, unit: "MBPS" }, text: "3Mbps" },
        { value: { value: 5, unit: "MBPS" }, text: "5Mbps" },
        { value: { value: 7, unit: "MBPS" }, text: "7Mbps" },
        { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        e.isUno &&
        e.physicalInterface === "IF_100M",
      bandwidths: [
        { value: { value: 1, unit: "MBPS" }, text: "1Mbps" },
        { value: { value: 2, unit: "MBPS" }, text: "2Mbps" },
        { value: { value: 3, unit: "MBPS" }, text: "3Mbps" },
        { value: { value: 5, unit: "MBPS" }, text: "5Mbps" },
        { value: { value: 7, unit: "MBPS" }, text: "7Mbps" },
        { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
        { value: { value: 20, unit: "MBPS" }, text: "20Mbps" },
        { value: { value: 30, unit: "MBPS" }, text: "30Mbps" },
        { value: { value: 50, unit: "MBPS" }, text: "50Mbps" },
        { value: { value: 70, unit: "MBPS" }, text: "70Mbps" },
        { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
      ],
    },
  ],
  ETHERNET2_PF: [
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        !e.isUno &&
        e.physicalInterface === "IF_100M",
      bandwidths: [
        { value: { value: 0.5, unit: "MBPS" }, text: "0.5Mbps" },
        { value: { value: 1, unit: "MBPS" }, text: "1Mbps" },
        { value: { value: 2, unit: "MBPS" }, text: "2Mbps" },
        { value: { value: 3, unit: "MBPS" }, text: "3Mbps" },
        { value: { value: 4, unit: "MBPS" }, text: "4Mbps" },
        { value: { value: 5, unit: "MBPS" }, text: "5Mbps" },
        { value: { value: 6, unit: "MBPS" }, text: "6Mbps" },
        { value: { value: 7, unit: "MBPS" }, text: "7Mbps" },
        { value: { value: 8, unit: "MBPS" }, text: "8Mbps" },
        { value: { value: 9, unit: "MBPS" }, text: "9Mbps" },
        { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
        { value: { value: 20, unit: "MBPS" }, text: "20Mbps" },
        { value: { value: 30, unit: "MBPS" }, text: "30Mbps" },
        { value: { value: 40, unit: "MBPS" }, text: "40Mbps" },
        { value: { value: 50, unit: "MBPS" }, text: "50Mbps" },
        { value: { value: 60, unit: "MBPS" }, text: "60Mbps" },
        { value: { value: 70, unit: "MBPS" }, text: "70Mbps" },
        { value: { value: 80, unit: "MBPS" }, text: "80Mbps" },
        { value: { value: 90, unit: "MBPS" }, text: "90Mbps" },
        { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        !e.isUno &&
        e.physicalInterface === "IF_1G",
      bandwidths: [
        { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
        { value: { value: 200, unit: "MBPS" }, text: "200Mbps" },
        { value: { value: 300, unit: "MBPS" }, text: "300Mbps" },
        { value: { value: 400, unit: "MBPS" }, text: "400Mbps" },
        { value: { value: 500, unit: "MBPS" }, text: "500Mbps" },
        { value: { value: 600, unit: "MBPS" }, text: "600Mbps" },
        { value: { value: 700, unit: "MBPS" }, text: "700Mbps" },
        { value: { value: 800, unit: "MBPS" }, text: "800Mbps" },
        { value: { value: 900, unit: "MBPS" }, text: "900Mbps" },
        { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        !e.isUno &&
        e.physicalInterface === "IF_10G",
      bandwidths: [
        { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
        { value: { value: 2, unit: "GBPS" }, text: "2Gbps" },
        { value: { value: 3, unit: "GBPS" }, text: "3Gbps" },
        { value: { value: 4, unit: "GBPS" }, text: "4Gbps" },
        { value: { value: 5, unit: "GBPS" }, text: "5Gbps" },
        { value: { value: 6, unit: "GBPS" }, text: "6Gbps" },
        { value: { value: 7, unit: "GBPS" }, text: "7Gbps" },
        { value: { value: 8, unit: "GBPS" }, text: "8Gbps" },
        { value: { value: 9, unit: "GBPS" }, text: "9Gbps" },
        { value: { value: 10, unit: "GBPS" }, text: "10Gbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        !e.isUno &&
        e.physicalInterface === "IF_100G",
      bandwidths: [
        { value: { value: 10, unit: "GBPS" }, text: "10Gbps" },
        { value: { value: 20, unit: "GBPS" }, text: "20Gbps" },
        { value: { value: 30, unit: "GBPS" }, text: "30Gbps" },
        { value: { value: 40, unit: "GBPS" }, text: "40Gbps" },
        { value: { value: 50, unit: "GBPS" }, text: "50Gbps" },
        { value: { value: 60, unit: "GBPS" }, text: "60Gbps" },
        { value: { value: 70, unit: "GBPS" }, text: "70Gbps" },
        { value: { value: 80, unit: "GBPS" }, text: "80Gbps" },
        { value: { value: 90, unit: "GBPS" }, text: "90Gbps" },
        { value: { value: 100, unit: "GBPS" }, text: "100Gbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        e.isUno &&
        e.physicalInterface === "IF_10M",
      bandwidths: [
        { value: { value: 1, unit: "MBPS" }, text: "1Mbps" },
        { value: { value: 2, unit: "MBPS" }, text: "2Mbps" },
        { value: { value: 3, unit: "MBPS" }, text: "3Mbps" },
        { value: { value: 5, unit: "MBPS" }, text: "5Mbps" },
        { value: { value: 7, unit: "MBPS" }, text: "7Mbps" },
        { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        e.isUno &&
        e.physicalInterface === "IF_100M",
      bandwidths: [
        { value: { value: 1, unit: "MBPS" }, text: "1Mbps" },
        { value: { value: 2, unit: "MBPS" }, text: "2Mbps" },
        { value: { value: 3, unit: "MBPS" }, text: "3Mbps" },
        { value: { value: 5, unit: "MBPS" }, text: "5Mbps" },
        { value: { value: 7, unit: "MBPS" }, text: "7Mbps" },
        { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
        { value: { value: 20, unit: "MBPS" }, text: "20Mbps" },
        { value: { value: 30, unit: "MBPS" }, text: "30Mbps" },
        { value: { value: 50, unit: "MBPS" }, text: "50Mbps" },
        { value: { value: 70, unit: "MBPS" }, text: "70Mbps" },
        { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
      ],
    },
  ],
  PF_ACCESS_GW2: [
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        e.physicalInterface === "IF_1G",
      bandwidths: [
        { value: { value: 200, unit: "MBPS" }, text: "200Mbps" },
        { value: { value: 300, unit: "MBPS" }, text: "300Mbps" },
        { value: { value: 400, unit: "MBPS" }, text: "400Mbps" },
        { value: { value: 500, unit: "MBPS" }, text: "500Mbps" },
        { value: { value: 600, unit: "MBPS" }, text: "600Mbps" },
        { value: { value: 700, unit: "MBPS" }, text: "700Mbps" },
        { value: { value: 800, unit: "MBPS" }, text: "800Mbps" },
        { value: { value: 900, unit: "MBPS" }, text: "900Mbps" },
        { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
      ],
    },
    {
      is: (e) =>
        e.bandwidthType === "BANDWIDTH_SECURED" &&
        e.physicalInterface === "IF_10G",
      bandwidths: [
        { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
        { value: { value: 2, unit: "GBPS" }, text: "2Gbps" },
        { value: { value: 3, unit: "GBPS" }, text: "3Gbps" },
        { value: { value: 4, unit: "GBPS" }, text: "4Gbps" },
        { value: { value: 5, unit: "GBPS" }, text: "5Gbps" },
        { value: { value: 6, unit: "GBPS" }, text: "6Gbps" },
        { value: { value: 7, unit: "GBPS" }, text: "7Gbps" },
        { value: { value: 8, unit: "GBPS" }, text: "8Gbps" },
        { value: { value: 9, unit: "GBPS" }, text: "9Gbps" },
        { value: { value: 10, unit: "GBPS" }, text: "10Gbps" },
      ],
    },
  ],
  MULTI_CLOUD_GW: [
    {
      is: (e) => e.bandwidthType === "BANDWIDTH_SECURED",
      bandwidths: [
        { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
        { value: { value: 20, unit: "MBPS" }, text: "20Mbps" },
        { value: { value: 30, unit: "MBPS" }, text: "30Mbps" },
        { value: { value: 40, unit: "MBPS" }, text: "40Mbps" },
        { value: { value: 50, unit: "MBPS" }, text: "50Mbps" },
        { value: { value: 60, unit: "MBPS" }, text: "60Mbps" },
        { value: { value: 70, unit: "MBPS" }, text: "70Mbps" },
        { value: { value: 80, unit: "MBPS" }, text: "80Mbps" },
        { value: { value: 90, unit: "MBPS" }, text: "90Mbps" },
        { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
        { value: { value: 200, unit: "MBPS" }, text: "200Mbps" },
        { value: { value: 300, unit: "MBPS" }, text: "300Mbps" },
        { value: { value: 400, unit: "MBPS" }, text: "400Mbps" },
        { value: { value: 500, unit: "MBPS" }, text: "500Mbps" },
        { value: { value: 600, unit: "MBPS" }, text: "600Mbps" },
        { value: { value: 700, unit: "MBPS" }, text: "700Mbps" },
        { value: { value: 800, unit: "MBPS" }, text: "800Mbps" },
        { value: { value: 900, unit: "MBPS" }, text: "900Mbps" },
        { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
        { value: { value: 2, unit: "GBPS" }, text: "2Gbps" },
        { value: { value: 3, unit: "GBPS" }, text: "3Gbps" },
        { value: { value: 4, unit: "GBPS" }, text: "4Gbps" },
        { value: { value: 5, unit: "GBPS" }, text: "5Gbps" },
        { value: { value: 6, unit: "GBPS" }, text: "6Gbps" },
        { value: { value: 7, unit: "GBPS" }, text: "7Gbps" },
        { value: { value: 8, unit: "GBPS" }, text: "8Gbps" },
        { value: { value: 9, unit: "GBPS" }, text: "9Gbps" },
        { value: { value: 10, unit: "GBPS" }, text: "10Gbps" },
        { value: { value: 11, unit: "GBPS" }, text: "11Gbps" },
        { value: { value: 12, unit: "GBPS" }, text: "12Gbps" },
        { value: { value: 13, unit: "GBPS" }, text: "13Gbps" },
        { value: { value: 14, unit: "GBPS" }, text: "14Gbps" },
        { value: { value: 15, unit: "GBPS" }, text: "15Gbps" },
        { value: { value: 16, unit: "GBPS" }, text: "16Gbps" },
        { value: { value: 17, unit: "GBPS" }, text: "17Gbps" },
        { value: { value: 18, unit: "GBPS" }, text: "18Gbps" },
        { value: { value: 19, unit: "GBPS" }, text: "19Gbps" },
        { value: { value: 20, unit: "GBPS" }, text: "20Gbps" },
        { value: { value: 21, unit: "GBPS" }, text: "21Gbps" },
        { value: { value: 22, unit: "GBPS" }, text: "22Gbps" },
        { value: { value: 23, unit: "GBPS" }, text: "23Gbps" },
        { value: { value: 24, unit: "GBPS" }, text: "24Gbps" },
        { value: { value: 25, unit: "GBPS" }, text: "25Gbps" },
        { value: { value: 26, unit: "GBPS" }, text: "26Gbps" },
        { value: { value: 27, unit: "GBPS" }, text: "27Gbps" },
        { value: { value: 28, unit: "GBPS" }, text: "28Gbps" },
        { value: { value: 29, unit: "GBPS" }, text: "29Gbps" },
        { value: { value: 30, unit: "GBPS" }, text: "30Gbps" },
        { value: { value: 31, unit: "GBPS" }, text: "31Gbps" },
        { value: { value: 32, unit: "GBPS" }, text: "32Gbps" },
        { value: { value: 33, unit: "GBPS" }, text: "33Gbps" },
        { value: { value: 34, unit: "GBPS" }, text: "34Gbps" },
        { value: { value: 35, unit: "GBPS" }, text: "35Gbps" },
        { value: { value: 36, unit: "GBPS" }, text: "36Gbps" },
        { value: { value: 37, unit: "GBPS" }, text: "37Gbps" },
        { value: { value: 38, unit: "GBPS" }, text: "38Gbps" },
        { value: { value: 39, unit: "GBPS" }, text: "39Gbps" },
        { value: { value: 40, unit: "GBPS" }, text: "40Gbps" },
        { value: { value: 41, unit: "GBPS" }, text: "41Gbps" },
        { value: { value: 42, unit: "GBPS" }, text: "42Gbps" },
        { value: { value: 43, unit: "GBPS" }, text: "43Gbps" },
        { value: { value: 44, unit: "GBPS" }, text: "44Gbps" },
        { value: { value: 45, unit: "GBPS" }, text: "45Gbps" },
        { value: { value: 46, unit: "GBPS" }, text: "46Gbps" },
        { value: { value: 47, unit: "GBPS" }, text: "47Gbps" },
        { value: { value: 48, unit: "GBPS" }, text: "48Gbps" },
        { value: { value: 49, unit: "GBPS" }, text: "49Gbps" },
        { value: { value: 50, unit: "GBPS" }, text: "50Gbps" },
      ],
    },
  ],
  BROADBAND_ACCESS3: [],
  WIRELESS_ACCESS2: [],
  ETHERNET_LIGHT: [],
};

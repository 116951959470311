





























































































import Vue, { PropType } from "vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import {
  IntranetFwPolicy,
  IntraPolicy,
  ZonePolicyInfo,
} from "@/apis/IntranetfwPolicyApi";
import IntranetPolicySrcAddress from "@/components/policy/IntranetPolicySrcAddress.vue";
import IntranetPolicyDstAddress from "@/components/policy/IntranetPolicyDstAddress.vue";
import IntranetPolicyService from "@/components/policy/IntranetPolicyService.vue";
import IntranetFwPolicyRuleViewRD from "@/modals/applianceContractSetting/IntranetFwPolicyRuleViewRD.vue";
import { IntranetfwServiceMenu } from "@/apis/IntranetfwServiceMenuApi";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "IntranetFwPolicyList",
  props: {
    /** 遷移元で選択したゾーン接続 */
    zoneRelationItem: {
      type: Object as PropType<ZoneRelationItem>,
      required: true,
    },
    /** イントラネットFWポリシー */
    policy: {
      type: Object as PropType<IntranetFwPolicy>,
      required: true,
    },
    /** イントラネット契約情報(設定反映の表示用) */
    contractInfo: {
      type: Object as PropType<
        Pick<IntranetfwServiceMenu, "vpnCode" | "intranetFwBandwidth">
      >,
      required: true,
    },
  },
  components: {
    IntranetPolicySrcAddress,
    IntranetPolicyDstAddress,
    IntranetPolicyService,
  },
  data() {
    const clonePolicy = cloneDeep(this.policy);
    /** 該当のリレーションIDを持つポリシー情報が存在しない場合はポリシー0件のオブジェクトを作成 */
    const createEmptyPolicyInfo = (
      list: ZonePolicyInfo[]
    ): ZonePolicyInfo[] => {
      if (
        !list.find(
          (v) => v.zoneRelationId === this.zoneRelationItem.zoneRelationId
        )
      ) {
        return [
          ...list,
          {
            zoneRelationId: this.zoneRelationItem.zoneRelationId,
            total: 0,
            policyList: [],
          },
        ];
      } else {
        return list;
      }
    };
    const srcZoneType = this.zoneRelationItem.srcZoneType;
    const dstZoneType = this.zoneRelationItem.dstZoneType;
    if (srcZoneType === "PRIVATE" && dstZoneType === "PRIVATE") {
      clonePolicy.privateToPrivateList = createEmptyPolicyInfo(
        clonePolicy.privateToPrivateList
      );
    } else if (srcZoneType === "EXTRA" && dstZoneType === "PRIVATE") {
      clonePolicy.extraToPrivateList = createEmptyPolicyInfo(
        clonePolicy.extraToPrivateList
      );
    } else if (srcZoneType === "PRIVATE" && dstZoneType === "EXTRA") {
      clonePolicy.privateToExtraList = createEmptyPolicyInfo(
        clonePolicy.privateToExtraList
      );
    }

    return {
      clonePolicy,
      policyTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddress", label: "送信元アドレス" },
          { key: "dstAddress", label: "宛先アドレス" },
          { key: "service", label: "サービス" },
          { key: "actionType", label: "アクション" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
    };
  },
  computed: {
    /** ゾーン接続方向の表示情報 */
    zoneRelationToDisplay(): string {
      return (
        this.$filter.enumTo(this.zoneRelationItem.srcZoneType, "zoneType") +
        "/" +
        this.zoneRelationItem.srcZoneName +
        " → " +
        this.$filter.enumTo(this.zoneRelationItem.dstZoneType, "zoneType") +
        "/" +
        this.zoneRelationItem.dstZoneName
      );
    },
    /** 選択されたゾーンのポリシーリストを返却する */
    intranetFwPolicy(): ZonePolicyInfo {
      if (
        this.zoneRelationItem.srcZoneType === "PRIVATE" &&
        this.zoneRelationItem.dstZoneType === "PRIVATE"
      ) {
        // 遷移元で選択した行がプライベートゾーンからプライベートゾーンの場合
        return this.clonePolicy.privateToPrivateList.find(
          (v) => v.zoneRelationId === this.zoneRelationItem.zoneRelationId
        )!;
      } else if (
        this.zoneRelationItem.srcZoneType === "EXTRA" &&
        this.zoneRelationItem.dstZoneType === "PRIVATE"
      ) {
        // 遷移元で選択した行がエクストラゾーンからプライベートゾーンの場合
        return this.clonePolicy.extraToPrivateList.find(
          (v) => v.zoneRelationId === this.zoneRelationItem.zoneRelationId
        )!;
      } else if (
        this.zoneRelationItem.srcZoneType === "PRIVATE" &&
        this.zoneRelationItem.dstZoneType === "EXTRA"
      ) {
        // 遷移元で選択した行がプライベートゾーンからエクストラゾーンの場合
        return this.clonePolicy.privateToExtraList.find(
          (v) => v.zoneRelationId === this.zoneRelationItem.zoneRelationId
        )!;
      } else {
        // インターネットゾーンやエクストラとエクストラの組み合わせは存在しない想定
        throw new Error(
          "supported only `PRIVATE → PRIVATE`, `EXTRA → PRIVATE`, `PRIVATE → EXTRA`"
        );
      }
    },
  },
  methods: {
    /**
     * ポリシーテーブルのtrクラス.
     * 編集不可デフォルトポリシーの場合は特定のクラスを返す
     */
    policyRowClass(
      item: Pick<IntraPolicy, "policyCategoryInfo">
    ): string | null {
      return item.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY"
        ? "not-editable-policy"
        : null;
    },
    /** ポリシー編集モーダルを表示 */
    async showPolicyRuleEdit() {
      // ポリシー編集モーダルの表示 ポリシー編集で変更した内容を受け取り一覧画面に反映
      this.intranetFwPolicy.policyList = await this.$modal.show(
        IntranetFwPolicyRuleViewRD,
        {
          zone: this.zoneRelationItem,
          policyList: this.intranetFwPolicy.policyList,
          intranetPolicy: this.clonePolicy,
          contractInfo: this.contractInfo,
        }
      );
    },
    /**
     * 戻るボタン・×を押下した時の処理
     * モーダルを閉じて遷移元に編集したポリシーを返却する
     */
    savePolicy() {
      (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(
        this.clonePolicy
      );
    },
  },
});

import { AxiosResponse } from "axios";

import AbstractBaseApi from "./AbstractBaseApi";

/** パケットフィルタリング有無
 * FILTERING: 設定有り
 * NO_FILTERING: 設定無し
 */
export type FilterringSettingStatus = "FILTERING" | "NO_FILTERING";

/** フィルタリング有効/無効
 * ENABLED: 有効
 * DISABLED: 無効
 */
export type FilteringStatus = "ENABLED" | "DISABLED";

/** プロトコル
 * TCP: TCP
 * UDP: UDP
 * ANY: Any
 */
export type Protocol = "TCP" | "UDP" | "ANY";

/** アクション
 * PERMIT: Permit
 * DENY: Deny
 */
export type Action = "PERMIT" | "DENY";

/** VLAN
  UNTAG: IDの指定なし
  OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
*/
export type VlanIdType = "UNTAG" | "OTHER" | "SPECIFIED";

/**
 * パケットフィルタリング用VNコネクト一覧のAPI用インターフェイス
 */
export interface VnConnectPktFilterGetEntity {
  total: number;
  /** VNコネクト一覧 */
  packetFilterList: VnConnectPktFilterEntity[];
  /** キーID */
  vnConnectPacketFilterKeyId: string;
}

/** VNコネクト一覧用インターフェイス */
export interface VnConnectPktFilterEntity {
  /** VNコネクト番号 */
  wnumber: string;
  /** VNコネクト名 */
  vnConnectName: string;
  /** 回線番号（E番号）*/
  enumberAct: string;
  enumberSby: string | null;
  /** サイト名 */
  siteName: string;
  /** メニュー
    ETHERNET2:イーサネット方式Ⅱ
    ETHERNET2_PF:イーサネット方式Ⅱ PF
    BROADBAND_ACCESS3:ブロードバンドアクセス方式Ⅲ
    ETHERNET_LIGHT:イーサネットライト
    WIRELESS_ACCESS2:ワイヤレスアクセス方式Ⅱ
    PF_ACCESS_GW2:PFアクセスGWⅡ
  */
  menu:
    | "ETHERNET2"
    | "ETHERNET2_PF"
    | "BROADBAND_ACCESS3"
    | "ETHERNET_LIGHT"
    | "WIRELESS_ACCESS2"
    | "PF_ACCESS_GW2";
  /** VLAN
    UNTAG: IDの指定なし
    OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
    SPECIFIED: 指定ID
  */
  vlanIdType: VlanIdType;
  /** VLAN=指定の場合、IDを設定 */
  vlanId: number | null;
  /** 仮想ネットワーク名 */
  vnName: string;
  /** 接続先VPN/VN */
  dstVpnVnCode: string;
  /** パケットフィルタリング有無 */
  filterringSettingStatus: FilterringSettingStatus;
}

/**
 * パケットフィルタリング用クラウドVNコネクト一覧のAPI用インターフェイス
 */
export interface CloudPktFilterGetEntity {
  total: number;
  /** VNコネクト一覧 */
  packetFilterList: CloudPktFilterEntity[];
  /** キーID */
  vnConnectPacketFilterKeyId: string;
}

/** クラウドVNコネクト一覧用インターフェイス */
export interface CloudPktFilterEntity {
  /** W番号(Act) */
  wnumberAct: string;
  /** W番号(Sby) */
  wnumberSby: string | null;
  /** VNコネクト名(Act) */
  vnConnectNameAct: string;
  /** VNコネクト名(Sby) */
  vnConnectNameSby: string | null;
  /** E番号 */
  enumber: string;
  /** 仮想ネットワーク名 */
  vnName: string | null;
  /** 接続先VPN/VNコード */
  dstVpnVnCode: string;
  /** クラウド回線SEQ */
  cloudLineSeq: string;
  /** クラウドサービス種別
   * AWS: AWS
   * GCP: GCP
   * IBM: IBM
   * PRIVATE_PEERING: PRIVATE_PEERING
   * ORACLE: Oracle
   * SFDC: SFDC
   */
  cloudServiceType:
    | "AWS"
    | "GCP"
    | "IBM"
    | "PRIVATE_PEERING"
    | "ORACLE"
    | "SFDC";
  /** パケットフィルタリング有無 */
  filterringSettingStatus: FilterringSettingStatus;
}

/**
 * パケットフィルタリング用アクセス回線一覧のAPI用インターフェイス
 */
export interface AcLinePktFilterGetEntity {
  total: number;
  /** アクセス回線一覧 */
  packetFilterList: AcLinePktFilterEntity[];
  /** キーID */
  accessLinePacketFilterKeyId: string;
}

/** アクセス回線一覧用インターフェイス */
export interface AcLinePktFilterEntity {
  /** 回線番号（E番号） */
  enumber: string;
  /** メニュー
    ETHERNET:イーサネット方式
    BROADBAND_ACCESS2:ブロードバンドアクセス方式Ⅱ
    WIRELESS_ACCESS:ワイヤレスアクセス方式
    REMORE_ACCESS_GW:リモートアクセスGW
    PLATFORM_GATEWAY:PFGW
    EXTEND_ETHERNET:エクステンドイーサ方式
    IPSEC:IPsec方式
  */
  menu:
    | "ETHERNET"
    | "BROADBAND_ACCESS2"
    | "WIRELESS_ACCESS"
    | "REMORE_ACCESS_GW"
    | "PLATFORM_GATEWAY"
    | "EXTEND_ETHERNET"
    | "IPSEC";
  /** パケットフィルタリング有無 */
  filterringSettingStatus: FilterringSettingStatus;
}

/**
 * パケットフィルタリング一覧のAPI用インターフェイス
 */
export interface PacketFilterGetEntity {
  total: number;
  /** パケットフィルタリング一覧 */
  packetFilterRuleList: PacketFilterEntity[];
}

export interface PacketFilterEntity {
  /** パケットフィルタリングルールSEQ */
  packetFilterRuleSeq: string | null;
  /** ルール種別
   * true: デフォルトルール（編集不可）
   * false: ユーザ設定フィルタルール
   */
  isDefaultRule: boolean;
  /**
   * プロトコル
   * ユーザ設定フィルタルールの場合、TCP, UDP, ANYのいずれかを指定する。
   * デフォルトルールの場合、TCP, UDP, ANYの他、NULLを許容する。
   */
  protocol: Protocol | null;
  /**
   * プロトコル番号
   * デフォルトルールでプロトコルを指定しなかった場合のみ、プロトコル番号を指定する。
   */
  protocolNumber: number | null;
  /** アクション */
  action: Action;
  /** 送信元アドレス */
  srcAddress: string | null;
  /** 送信元ポート */
  srcPort: number | null;
  /** 宛先アドレス */
  dstAddress: string | null;
  /** 宛先アドレス */
  dstPort: number | null;
  /** フィルタリング有効/無効 */
  filteringStatus: FilteringStatus;
  /** 備考 */
  description: string | null;
}

/**
 * パケットフィルタリング削除API用インターフェイス（VNコネクト）
 */
// export interface VnConnectPktFilterDeleteEntity {
//   /** 削除対象VNコネクト番号 */
//   wnumber: string;
//   /** オーダー備考 */
//   note?: string;
//   /** キーID */
//   vnConnectPacketFilterKeyId: string;
// }

/**
 * パケットフィルタリング設定API用インターフェイス（VNコネクト）
 */
export interface VnConnectPktFilterUpdateEntity {
  /** パケットフィルタリング一覧 */
  packetFilterRuleList: PacketFilterEntity[];
  /** オーダー備考 */
  note?: string;
  /** キーID */
  vnConnectPacketFilterKeyId: string;
}

/**
 * パケットフィルタリング削除API用インターフェイス（クラウドVNコネクト）
 */
// export interface CloudPktFilterDeleteEntity {
//   /** 削除対象クラウドVNコネクト番号 */
//   wnumberAct: string;
//   /** オーダー備考 */
//   note?: string;
//   /** キーID */
//   vnConnectPacketFilterKeyId: string;
// }

/**
 * パケットフィルタリング設定API用インターフェイス（VNコネクト）
 */
export interface CloudPktFilterUpdateEntity {
  /** パケットフィルタリング一覧 */
  packetFilterRuleList: PacketFilterEntity[];
  /** オーダー備考 */
  note?: string;
  /** キーID */
  vnConnectPacketFilterKeyId: string;
}

/**
 * パケットフィルタリング削除API用インターフェイス（アクセス回線）
 */
// export interface AcLinePktFilterDeleteEntity {
//   /** 削除対象アクセス回線番号 */
//   enumber: string;
//   /** オーダー備考 */
//   note?: string;
//   /** キーID */
//   accessLinePacketFilterKeyId: string;
// }

/**
 * パケットフィルタリング設定API用インターフェイス（アクセス回線）
 */
export interface AcLinePktFilterUpdateEntity {
  /** パケットフィルタリング一覧 */
  packetFilterRuleList: PacketFilterEntity[];
  /** オーダー備考 */
  note?: string;
  /** キーID */
  accessLinePacketFilterKeyId: string;
}

/** パケットフィルタリング契約情報取得API用インタフェース */
export interface ContractGetPacketFiltering {
  /** 排他確認用パラメータ */
  contractPacketFilterKeyId: string;
  /** パケットフィルタリング契約状態(true：ON、false：OFF) */
  packetFilterEnabled: boolean;
  /** フィルタルール数 */
  packetFilterRuleCount: number;
}

/** パケットフィルタリング契約情報設定API用インタフェース */
export interface ContractChangePacketFiltering {
  /** 排他確認用パラメータ */
  contractPacketFilterKeyId: string;
  /** パケットフィルタリング契約状態(true：ON、false：OFF) */
  packetFilterEnabled: boolean;
  /** オーダー備考 */
  note: string | null;
}

export default class PacketFilterApi extends AbstractBaseApi {
  /** 一覧ダウンロードAPI */
  getCsvURL(): string {
    return this.getFullURL("/v1/network/vnetwork/packetfilters/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
  getCloudCsvURL(enumber: string): string {
    return this.getFullURL(
      "/v1/network/vnetwork/packetfilters/cloud/csv/packetfilter",
      {
        params: {
          tokenId: this.store.state.user.token,
          enumber: enumber,
        },
      }
    );
  }

  // VNコネクト APIs
  /** パケットフィルタ用VNコネクト一覧を取得 */
  async getVnConnects(): Promise<VnConnectPktFilterGetEntity> {
    return (await this.api.get("/v1/network/vnetwork/packetfilters/vnconnect"))
      .data;
  }
  /** VNコネクト用パケットフィルタのパケットフィルタルール一覧を取得
   * @param wnumber VNコネクトのパスパラメータ
   */
  async getVnConnectPktFilter(wnumber: string): Promise<PacketFilterGetEntity> {
    return (
      await this.api.get(
        `/v1/network/vnetwork/packetfilters/vnconnect/${wnumber}`
      )
    ).data;
  }
  // API設計にはあるので念の為残しておく
  // deleteVnConnectPktFilter(
  //   entity: VnConnectPktFilterDeleteEntity
  // ): Promise<AxiosResponse> {
  //   return appAxios.post(
  //     `/network/vnetwork/packetfilters/vnconnect/delete`,
  //     entity
  //   );
  // }
  /** VNコネクト用パケットフィルタのパケットフィルタルールを変更
   * @param data リクエストデータ
   * @param wnumber VNコネクトのパスパラメータ
   */
  async updateVnConnectPktFilter(
    wnumber: string,
    data: VnConnectPktFilterUpdateEntity
  ): Promise<AxiosResponse> {
    return (
      await this.api.post(
        `/v1/network/vnetwork/packetfilters/vnconnect/${wnumber}`,
        data
      )
    ).data;
  }

  // クラウドVNコネクトAPIs
  /** パケットフィルタ用クラウドVNコネクト一覧を取得 */
  async getCloudVnConnects(): Promise<CloudPktFilterGetEntity> {
    return (await this.api.get("/v2/network/vnetwork/packetfilters/cloud"))
      .data;
  }
  /** クラウドVNコネクト用パケットフィルタのパケットフィルタルール一覧を取得
   * @param wnumberAct VNコネクトのパスパラメータ
   */
  async getCloudPktFilter(wnumberAct: string): Promise<PacketFilterGetEntity> {
    return (
      await this.api.get(
        `/v1/network/vnetwork/packetfilters/cloud/${wnumberAct}`
      )
    ).data;
  }
  // API設計にはあるので念の為残しておく
  // deleteCloudPktFilter(
  //   entity: CloudPktFilterDeleteEntity
  // ): Promise<AxiosResponse> {
  //   return appAxios.post(`/network/vnetwork/packetfilters/cloud/delete`,entity);
  // }
  /** クラウドVNコネクト用パケットフィルタのパケットフィルタルールを変更
   * @param wnumberAct VNコネクトのパスパラメータ
   * @param data リクエストデータ
   */
  async updateCloudPktFilter(
    wnumberAct: string,
    data: CloudPktFilterUpdateEntity
  ): Promise<AxiosResponse> {
    return (
      await this.api.post(
        `/v1/network/vnetwork/packetfilters/cloud/${wnumberAct}`,
        data
      )
    ).data;
  }

  // アクセス回線 APIs
  /** パケットフィルタ用アクセス回線一覧を取得 */
  async getAcLines(): Promise<AcLinePktFilterGetEntity> {
    return (
      await this.api.get("/v1/network/vnetwork/packetfilters/access-line")
    ).data;
  }
  /** アクセス回線用パケットフィルタのパケットフィルタルール一覧を取得
   * @param enumber アクセス回線のパスパラメータ
   */
  async getAcLinePktFilter(enumber: string): Promise<PacketFilterGetEntity> {
    return (
      await this.api.get(
        `/v1/network/vnetwork/packetfilters/access-line/${enumber}`
      )
    ).data;
  }
  // API設計にはあるので念の為残しておく
  // deleteAcLinePktFilter(
  //   entity: AcLinePktFilterDeleteEntity
  // ): Promise<AxiosResponse> {
  //   return appAxios.post(`/v1/network/vnetwork/packetfilters/access-line/delete`, entity);
  // }
  /** アクセス回線用パケットフィルタのパケットフィルタルールを変更
   * @param data リクエストデータ
   * @param enumber アクセス回線のパスパラメータ
   */
  async updateAcLinePktFilter(
    enumber: string,
    data: AcLinePktFilterUpdateEntity
  ): Promise<AxiosResponse> {
    return (
      await this.api.post(
        `/v1/network/vnetwork/packetfilters/access-line/${enumber}`,
        data
      )
    ).data;
  }
  /** パケットフィルタリング契約情報を取得 */
  async getContractPacketFiltering(): Promise<ContractGetPacketFiltering> {
    return (
      await this.api.get("/v1/contract/service/appliance/packetfiltering")
    ).data;
  }
  /** パケットフィルタリング契約情報を更新 */
  async postContractPacketFiltering(
    data: ContractChangePacketFiltering
  ): Promise<void> {
    await this.api.post("/v1/contract/service/appliance/packetfiltering", data);
  }
}

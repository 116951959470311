import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import moment from "moment";

/**
 * 開始日と終了日が指定した日数以内であること
 * 計算方法：当日を含む期間となる
 * 例)指定日数(days)が3の場合、3日以内となり、当日、翌日、翌々日までとなる。
 *    開始日が9/1であれば、9/3まで可能
 */
export default {
  params: ["from", "days"],
  validate(
    value: string | undefined | null,
    { from, days }: { from: string | undefined | null; days: number }
  ): boolean {
    if (!value || !from) {
      return true;
    }

    return days > moment(value).diff(moment(from), "days");
  },
} as ValidationRuleSchema;

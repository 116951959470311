



















































































































































































































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { ApplianceInfo } from "@/apis/ContractApi";
import {
  Type4ExtraList,
  Type4ExtraSite,
  Type4ExtraSiteList,
  Type4InternetAddress,
  Type4InternetFQDN,
  Type4InternetSite,
} from "@/apis/Type4SiteApi";
import enumTo from "@/filters/enum-to.filter";
import { SortCompareFunc } from "@/services/SortService";
import Type4InternetSiteReference from "@/modals/type4/Type4InternetSiteReference.vue";
import internetStatusTo from "@/filters/internet-status-to.filter";
import applianceTypeTo from "@/filters/appliance-type-to.filter";
import Type4ExtraSiteEdit from "@/modals/type4/Type4ExtraSiteEdit.vue";
import Type4ExtraSiteReference from "@/modals/type4/Type4ExtraSiteReference.vue";

export default Vue.extend({
  name: "Type4SiteList",
  data() {
    return {
      isLoaded: false,
      tabMode: 1 as 1 | 2,
      isLoadedTabArea: false,
      selectedItem: null as null | ApplianceInfo,
      applianceObjectTable: {
        fields: applianceObjectListFields,
        items: [],
      } as Pick<AppTableData<ApplianceInfo>, "fields" | "items">,

      internetSite: {} as Type4InternetSite,
      extraSite: {} as Type4ExtraSite,
      /** インターネットアドレス */
      addressList: {
        fields: FIELDS.address,
        items: [],
        selected: [],
        search: {
          ipAddressName: {
            type: "text",
            label: "アドレス名",
          },
          ipAddress: {
            type: "text",
            label: "アドレス",
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          ipAddressName: "like",
          ipAddress: "like",
        },
      } as AppTableData<Type4InternetAddress>,
      /** インターネットFQDN */
      fqdnList: {
        fields: FIELDS.fqdn,
        items: [],
        selected: [],
        search: {
          fqdnName: {
            type: "text",
            label: "FQDN名",
          },
          fqdn: {
            type: "text",
            label: "FQDN",
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          fqdnName: "like",
          fqdn: "like",
        },
      } as AppTableData<Type4InternetFQDN>,
      /** エクストラサイト */
      extra: {
        fields: FIELDS.extra,
        selected: [],
        search: {
          siteName: {
            type: "text",
            label: "サイト名",
          },
          ipAddressName: {
            type: "text",
            label: "アドレス名",
          },
          ipAddress: {
            type: "text",
            label: "アドレス",
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          siteName: "like",
          ipAddressName: "like",
          ipAddress: "like",
        },
      } as Omit<AppTableData<Type4ExtraSiteList>, "items">,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    /** インターネットアドレスカスタムソート */
    customSortAddress(): SortCompareFunc<Type4InternetAddress> {
      return this.$service.tableSortCompare(
        ["ipAddressName", { nullLast: true }],
        ["ipAddress", "asc"]
      );
    },
    /** インターネットFqdnカスタムソート */
    customSortFqdn(): SortCompareFunc<Type4InternetFQDN> {
      return this.$service.tableSortCompare(
        ["fqdnName", { nullLast: true }],
        ["fqdn", "asc"]
      );
    },
    /** エクストラカスタムソート */
    customSortExtra(): SortCompareFunc<Type4ExtraList> {
      return this.$service.tableSortCompare(
        "siteName",
        ["ipAddressName", "asc", { nullLast: true }],
        ["ipAddress", "asc"]
      );
    },
  },

  methods: {
    /** 初期化機能 */
    async load() {
      // アプライアンス一覧の取得APIを呼び出し、データ取得を行う
      this.applianceObjectTable.items = [];
      const data: ApplianceInfo[] = (await this.$api.contract.getAppliances())
        .applianceInfoList;
      data.forEach((item) => {
        if (item.internetType === "TYPE4") {
          this.applianceObjectTable.items.push(item);
        }
      });
      if (this.selectedItem) {
        let selectedRow = this.applianceObjectTable.items.find(
          (v) => v.type4Id === this.selectedItem?.type4Id
        );

        if (selectedRow) {
          await this.setSelectedItem(selectedRow);
        } else {
          this.isLoadedTabArea = false;
          this.selectedItem = null;
        }
      }
    },
    /** インターネットタブ一覧更新 */
    async reloadInternet() {
      // インターネットサイト情報を取得用APIを呼び出し、データ取得を行う
      await this.getInternetList();
    },
    /** エクストラサイトタブ一覧更新 */
    async reloadExtranet() {
      // エクストラサイト情報を取得用APIを呼び出し、データ取得を行う
      await this.getExtraList();
    },
    /** 選択した行のデータをセットする */
    async setSelectedItem(row: ApplianceInfo) {
      this.selectedItem = row;
      await this.callApis();
    },
    /** 選択した行の背景色を変更する処理 */
    rowHighlight(item: ApplianceInfo, type: string) {
      if (!item || type !== "row") return;
      if (item.type4Id === this.selectedItem?.type4Id) return "table-success";
    },
    /** タブエリアの情報を取得する */
    async callApis(): Promise<void> {
      // 情報の再取得を行う際に一度タブエリアを非表示にする
      if (this.isLoadedTabArea) this.isLoadedTabArea = false;
      // インターネットサイト情報を取得用APIを呼び出し、データ取得を行う
      await this.getInternetList();
      // エクストラサイト情報を取得用APIを呼び出し、データ取得を行う
      await this.getExtraList();

      this.isLoadedTabArea = true;
    },
    /** インターネット一覧のデータ取得を行う*/
    async getInternetList() {
      this.internetSite = await this.$api.type4SiteApi.getType4InternetSite(
        this.selectedItem!.type4Id
      );
      // 取得した配列のソート
      this.addressList.items = this.internetSite.addressList.sort((a, b) => {
        return this.$service.sortCompare(a, b, [
          ["ipAddressName", "asc", { nullLast: true }],
          ["ipAddress", "asc"],
        ]);
      });
      // 取得した配列のソート
      this.fqdnList.items = this.internetSite.fqdnList.sort((a, b) => {
        return this.$service.sortCompare(a, b, [
          ["fqdnName", "asc", { nullLast: true }],
          ["fqdn", "asc"],
        ]);
      });
    },
    /** エクストラ一覧のデータ取得を行う*/
    async getExtraList() {
      this.extraSite = await this.$api.type4SiteApi.getType4ExtraSite(
        this.selectedItem!.type4Id
      );
      /**
       * 取得IPアドレス配列のソート
       */
      this.extraSite.siteList.forEach((e) => {
        e.addressList.sort((a, b) => {
          return this.$service.sortCompare(a, b, [
            ["ipAddressName", "asc", { nullLast: true }],
            ["ipAddress", "asc"],
          ]);
        });
      });
    },
    /** 編集機能 */
    // インターネットサイト編集
    async showInternetSiteEdit() {
      await this.$modal.show(Type4InternetSiteReference, {
        internetSite: this.internetSite,
        type4Id: this.selectedItem!.type4Id,
      });
      await this.callApis();
      this.tabMode = 1;
    },
    /** 追加機能 */
    async toAddSite() {
      await this.$modal.show(Type4ExtraSiteEdit, {
        type4Id: this.selectedItem!.type4Id,
        type4SiteKeyId: this.extraSite.type4SiteKeyId,
      });
      await this.callApis();
      this.tabMode = 2;
    },
    /** 行選択機能 */
    async extraSelected(item: Type4ExtraSiteList) {
      await this.$modal.show(Type4ExtraSiteReference, {
        extraSite: item,
        type4Id: this.selectedItem!.type4Id,
        type4SiteKeyId: this.extraSite.type4SiteKeyId,
      });
      await this.callApis();
      this.tabMode = 2;
    },
  },
});

/** 列のデータ(アプライアンスオブジェクト一覧) */
const applianceObjectListFields = [
  {
    key: "lineNumber",
    label: "回線番号",
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: false,
    formatter: (value: null, key: null, item: ApplianceInfo) => {
      return item.wnumberMainAct + " " + item.wnumberMainSby;
    },
  },
  { key: "vpnVnCode", label: "VPN/VNコード", sortable: true },
  { key: "internetType", label: "Type", sortable: true },
  { key: "type4Id", label: "Type4ID", sortable: true },
  { key: "type4Name", label: "Type4名", sortable: true },
  {
    key: "mainSite",
    label: "メインサイト",
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: false,
    formatter: (value: string) => {
      return enumTo(value, "mainSite");
    },
  },
  {
    key: "appliance",
    label: "アプライアンス",
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: false,
    formatter: (value: null, key: null, item: ApplianceInfo) => {
      return applianceTypeTo(item.applianceType, item.bandwidth);
    },
  },
  {
    key: "status",
    label: "状態",
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: false,
    formatter: (value: null, key: null, item: ApplianceInfo) => {
      return internetStatusTo(item.isStart, item.isInternetPause);
    },
  },
];

/** テーブルfields*/
const FIELDS = {
  address: [
    {
      key: "ipAddressName",
      label: "アドレス名",
      sortable: true,
    },
    {
      key: "ipAddress",
      label: "アドレス",
      sortable: true,
    },
    {
      key: "isPolicyInUse",
      label: "ポリシー使用有無",
      sortable: true,
    },
  ],
  fqdn: [
    {
      key: "fqdnName",
      label: "FQDN名",
      sortable: true,
    },
    {
      key: "fqdn",
      label: "FQDN",
      sortable: true,
    },
    {
      key: "isPolicyInUse",
      label: "ポリシー使用有無",
      sortable: true,
    },
  ],
  extra: [
    {
      key: "siteName",
      label: "サイト名",
      sortable: true,
    },
    {
      key: "ipAddressName",
      label: "アドレス名",
      sortable: false,
      filterByFormatted: true,
      formatter: (value: null, key: null, item: Type4ExtraSiteList) => {
        return item.addressList.map((e) => e.ipAddressName);
      },
    },
    {
      key: "ipAddress",
      label: "アドレス",
      sortable: false,
      filterByFormatted: true,
      formatter: (value: null, key: null, item: Type4ExtraSiteList) => {
        return item.addressList.map((e) => e.ipAddress);
      },
    },
    {
      key: "isPolicyInUse",
      label: "ポリシー使用有無",
      sortable: false,
      filterByFormatted: false,
      formatter: (value: null, key: null, item: Type4ExtraSiteList) => {
        return item.addressList.map((e) => e.isPolicyInUse);
      },
    },
  ],
};























import { UpperLimitEntity } from "@/apis/UpperLimitApi";
import Vue, { PropType } from "vue";
import { ApiNotFoundError } from "@/error/errors";

export default Vue.extend({
  name: "UpperLimit",
  props: {
    /** 上限値管理単位 */
    upperLimitManageUnit: {
      type: String as PropType<
        "ACCESS" | "PFGW" | "VNL2L3" | "VN" | "APPLIANCE" | "MULTI_CLOUD"
      >,
      required: true,
    },
    /** E番号 (upperLimitManageUnit=ACCESS, VNL2L3 の場合は必須) */
    enumber: {
      type: String as PropType<string>,
      required: false,
    },
    /** W番号 (upperLimitManageUnit=VN, MULTI_CLOUD の場合は必須) */
    wnumber: {
      type: String as PropType<string>,
      required: false,
    },
    /** クラウド回線SEQ (上限値管理単位がマルチクラウド接続の場合は必須) */
    cloudLineSeq: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      limitTb: {
        fields: [
          { key: "upperLimitItemName", label: "設定項目" },
          { key: "current", label: "現在設定数" },
          { key: "upperLimit", label: "上限値" },
        ],
        items: [] as UpperLimitEntity[],
      },
    };
  },
  async mounted() {
    try {
      const limit = await this.$api.upperLimit.getUpperLimitLine({
        upperLimitLineManageUnit: this.upperLimitManageUnit,
        enumber: this.enumber,
        wnumber: this.wnumber,
        cloudLineSeq: this.cloudLineSeq,
      });

      switch (this.upperLimitManageUnit) {
        /** アクセス回線情報画面・IPsec情報画面 */
        case "ACCESS":
          this.limitTb.items = limit.accessLine!.upperLimitList;
          break;
        /** VNコネクト情報画面 */
        case "VN":
          this.limitTb.items = limit.vnConnect!.upperLimitList;
          break;
        /** VNL2/L3コネクト情報画面 */
        case "VNL2L3":
          this.limitTb.items = limit.vnl2l3Line!.upperLimitList;
          break;
        /** マルチクラウド情報画面 */
        case "MULTI_CLOUD":
          this.limitTb.items = limit.cloudLine!.upperLimitList;
          break;
      }
    } catch (e) {
      // 回線によっては上限値がそもそも存在しない場合がある
      // その際は404が返ってくるため上限値0件として表示する
      if (e instanceof ApiNotFoundError) {
        this.limitTb.items = [];
        return;
      }
      throw e;
    }
  },
});

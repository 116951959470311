






























































































import Vue, { PropType } from "vue";
import { AgreementInfoList, AgreementDetail } from "@/apis/AgreementApi.ts";
import AgreeStatusChangeConfirm from "@/modals/agreement/AgreeStatusChangeConfirm.vue";

export default Vue.extend({
  name: "AgreementReference",
  props: {
    /** 同意情報 */
    agreementItem: {
      type: Object as PropType<AgreementInfoList>,
      required: true,
    },
    /** 同意排他情報 */
    agreementKeyId: {
      type: String as PropType<string>,
      required: false,
    },
    /** 最新同意判定 */
    isLatest: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
  },
  data() {
    return {
      agreement: {} as AgreementDetail,
    };
  },
  computed: {
    /**
     * 下記に一致する場合のみ対象
     * ※権限はディレクティブで実施
     * 最新のレコードである
     * 状態が「同意済み」でない
     */
    isAgreeChangeTarget(): boolean {
      return this.isLatest && this.agreementItem.agreementStatus !== "AGREED";
    },
  },

  async mounted() {
    this.agreement = await this.$api.agreement.getAgreementDetail(
      this.agreementItem.agreementSeq
    );
  },
  methods: {
    /** 同意/同意保留画面表示 */
    async AgreeStatusChange() {
      await this.$modal.show(AgreeStatusChangeConfirm, {
        agreementItem: this.agreementItem,
        agreementKeyId: this.agreementKeyId,
        agreementText: this.agreement.agreementText,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 同意保留 */
    async hold() {
      await this.$confirm(
        `当内容に対する同意を「保留」とします。よろしいですか？\n保留にした場合、同意一覧から同意可能です。`
      );
      await this.$api.agreement.postAgreementHold(
        this.agreementItem.agreementSeq,
        {
          agreementKeyId: this.agreementKeyId,
        }
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

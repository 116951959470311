import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * ログインIDのバリデーション.
 * ・先頭2桁は大文字小文字混在のアルファベット、残り5桁は数字
 * ・先頭1桁は大文字小文字混在のアルファベット、残り6桁は数字
 */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) return true;
    return (
      !!value.match(/^[A-Z][a-z][0-9]{5}$/g) ||
      !!value.match(/^[a-z][A-Z][0-9]{5}$/g) ||
      !!value.match(/^[A-Z][0-9]{6}$/g) ||
      !!value.match(/^[a-z][0-9]{6}$/g)
    );
  },
} as ValidationRuleSchema;

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 半角英数字 or 全角文字であること
 * （正規表現的には半角記号系や半角カタカナなどを禁止する）
 * @see https://seiai.ed.jp/sys/text/java/utf8table.html
 */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }

    return !!value.match(
      // eslint-disable-next-line no-control-regex
      /^[^\x01-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E\uFF66-\uFF9D]*$/
    );
  },
} as ValidationRuleSchema;







































































































































































































































































































































































import Vue, { PropType } from "vue";
import { CloudVnAwsDetail } from "@/apis/CloudVnAwsApi";
export default Vue.extend({
  name: "MultiCloudAwsVnConnectionConfirm",
  props: {
    cloudVnAwsDetail: {
      type: Object as PropType<CloudVnAwsDetail>,
      required: true,
    },
    /** 画面表示モード：
     * A - 追加確認
     * M - 編集確認
     * D - 削除確認
     */
    displayType: {
      type: String as PropType<"A" | "M" | "D">,
      required: true,
    },
    connectionMode: {
      type: String as PropType<"HOSTED" | "VIF">,
      required: true,
    },
    /**
     * 品目帯域.
     */
    bandwidthDisplayName: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  computed: {
    noticeOptions(): { text: string; value: string }[] {
      switch (this.displayType) {
        case "A":
          return [
            {
              text: "VNコネクトのAS番号が、AWSのDirect Connect Gatewayまたは仮想プライベートゲートウェイのAS番号と一致してること",
              value: "CHECKED_AS",
            },
            {
              text: "VNコネクト作成中はAWSの設定変更は実施しないこと",
              value: "CHECKED_NOT_CHANGE",
            },
          ];
        case "D":
          return this.connectionMode === "HOSTED"
            ? [
                {
                  text: "VNコネクトのホスト接続に紐付けを行なったAWS TransitVIFを削除していること",
                  value: "CHECKED_TransitVIF",
                },
              ]
            : [];
        case "M":
        default:
          return [];
      }
    },
    showNotice(): boolean {
      switch (this.displayType) {
        case "A":
          return true;
        case "D":
          return this.connectionMode === "HOSTED";
        case "M":
        default:
          return false;
      }
    },
    confirmMessage(): string {
      switch (this.displayType) {
        case "A":
          return "こちらの内容でクラウド接続向けVN回線を追加します。よろしいですか？";
        case "M":
          return "こちらの内容でクラウド接続向けVN回線を変更します。よろしいですか？";
        case "D":
          return "こちらの内容でクラウド接続向けVN回線を削除します。よろしいですか？";
        default:
          throw new Error("wrong displayType!");
      }
    },
  },
  data() {
    return {
      checkedItems: [],
    };
  },
});












































import Vue, { PropType } from "vue";
import { ContractGetPacketFiltering } from "@/apis/PacketFilteringApi";

export default Vue.extend({
  name: "PacketFilteringContractConfirm",
  props: {
    contractGetPacketFiltering: {
      type: Object as PropType<ContractGetPacketFiltering>,
      required: true,
    },
  },
});

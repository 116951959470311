

















































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { CpaContract, CpaNwDetail, CpaNwOptions } from "@/apis/Cpa5gSaApi";

export default Vue.extend({
  name: "SaNwSettingInfo",
  props: {
    /** CPA契約情報 */
    cpaContractInfo: {
      type: Object as PropType<CpaContract>,
      required: true,
    },
    /** CPA NW 詳細情報 */
    nwSettingDetail: {
      type: Object as PropType<CpaNwDetail>,
      required: true,
    },
    /** CPA特殊項目設定情報 */
    nwOptions: {
      type: Object as PropType<CpaNwOptions>,
      required: true,
    },
    /** データ更新要反映の赤文字表示する為のフラグ */
    isUpdateRequiredRed: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /** 帯域情報表示フラグ */
    isShowBandwidthInfo: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  computed: {
    /**
     * その他項目（タイトルも含めて）表示・非表示フラグ
     * true: 非表示 false: 表示
     */
    hideOtherOptions(): boolean {
      return (
        (this.cpaContractInfo.contractType === "CPA_NO_LIMIT" ||
          this.cpaContractInfo.contractType === "CPA_LIMIT_AUDIO") &&
        (this.cpaContractInfo.authenticationType === "RADIUS" ||
          this.cpaContractInfo.authenticationType === "RADIUS_IMEI") &&
        !this.nwOptions.wrapCommunication &&
        !this.nwOptions.ftpSendSetting &&
        !this.nwOptions.cusconClientIp &&
        !this.nwOptions.alwaysOn &&
        !this.nwOptions.longTimeCallDisconnectTimer
      );
    },
  },
});

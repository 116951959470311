import {
  ValidationRuleResult,
  ValidationRuleSchema,
} from "vee-validate/dist/types/types";

/** 値が入力されており0より大きい */
export default {
  validate(value: string | number | undefined | null): ValidationRuleResult {
    if (!value) {
      return { valid: false, required: true };
    }

    return {
      valid: Number(value) > 0,
      required: true,
    };
  },
  computesRequired: true,
} as ValidationRuleSchema;



















































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { ExtranetInfo } from "@/apis/ExtranetApi";
import ExtranetConnectRegister from "@/modals/extranet/ExtranetConnectRegister.vue";
import ExtranetConnectReference from "@/modals/extranet/ExtranetConnectReference.vue";

export default Vue.extend({
  name: "ExtranetConnectList",
  data() {
    return {
      extranet: {
        fields: [
          {
            key: "srcVNumber",
            label: "接続元V番号",
            sortable: true,
          },
          {
            key: "srcVpnVnCode",
            label: "接続元VPN/VNコード",
            sortable: true,
          },
          {
            key: "dstVNumber",
            label: "接続先V番号",
            sortable: true,
          },
          {
            key: "dstVpnVnCode",
            label: "接続先VPN/VNコード",
            sortable: true,
          },
          {
            key: "pinCode",
            label: "PINコード",
          },
          {
            key: "approvalState",
            label: "承認ステータス",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (
              val: ExtranetInfo["approvalState"],
              _k,
              item: ExtranetInfo
            ) => {
              // 承認側(接続元V番号とユーザーのV番号が異なる)で申請中の場合は未承認、それ以外は通常の区分値を表示
              if (
                this.$store.state.user.contractSummary.vnumber !==
                  item.srcVNumber &&
                val === "APPLIED"
              ) {
                return "未承認";
              } else {
                return this.$filter.enumTo(val, "approvalState");
              }
            },
          },
          {
            key: "requestDateTime",
            label: "申請日時",
          },
          {
            key: "expiredDateTime",
            label: "有効期限",
          },
          {
            key: "approvalDateTime",
            label: "承認日時",
          },
        ],
        items: [],
        search: {
          srcVNumber: {
            type: "text",
            label: "接続元V番号",
          },
          srcVpnVnCode: {
            type: "text",
            label: "接続元VPN/VNコード",
          },
          dstVNumber: {
            type: "text",
            label: "接続先V番号",
          },
          dstVpnVnCode: {
            type: "text",
            label: "接続先VPN/VNコード",
          },
          approvalState: {
            type: "select",
            label: "承認ステータス",
            items: [
              { value: "完了", text: "完了" },
              { value: "申請中", text: "申請中" },
              { value: "未承認", text: "未承認" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          srcVNumber: "like",
          srcVpnVnCode: "like",
          dstVNumber: "like",
          dstVpnVnCode: "like",
          approvalState: "equal",
        },
      } as Omit<AppTableData<ExtranetInfo>, "selected">,
      extranetKeyId: "",
      isLoaded: false,
      downloadUrl: this.$api.extranetApi.getCsvExtranetURL(),
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    async load() {
      // エクストラネット接続一覧取得APIを呼び出す
      const res = await this.$api.extranetApi.getExtranets();
      this.extranet.items = res.extranetInfo;
      this.extranetKeyId = res.extranetKeyId;

      // 一覧データの絞り込み
      this.filterItems();
    },
    /** 承認ステータスが未承認、完了のデータのみ抽出する処理 */
    filterItems() {
      this.extranet.items = this.extranet.items.filter((item) => {
        return (
          item.approvalState === "COMPLETE" || item.approvalState === "APPLIED"
        );
      });
    },
    /**
     * エクストラネットのリストの初期ソート処理
     * ソート条件：接続元V番号の降順、接続元VPNコード降順、接続元VNコードの降順
     * テーブルの方でsort-descをtrueにしているので、このソートの結果は反転するようになっています
     * そのため、コメント内容とif分岐の内容が反対になっています
     */
    defaultSort(
      a: ExtranetInfo,
      b: ExtranetInfo,
      key: keyof ExtranetInfo
    ): number | null {
      // 接続元V番号だけカスタムソート それ以外は通常のソートを行う
      if (key === "srcVNumber") {
        // デフォルトの状態でnullが最後に来るようにする
        if (a.srcVNumber === null) {
          return -1;
        } else if (b.srcVNumber === null) {
          return 1;
        }

        // 接続元V番号の降順にソート
        if (a.srcVNumber !== b.srcVNumber) {
          if (a.srcVNumber < b.srcVNumber) {
            return -1;
          } else {
            return 1;
          }
        }

        // 接続元VPN/VNコードをVPNコード、VNコードの順にソート
        // NOTE：VPNコードは接頭辞がB、VNコードは接頭辞がCまたはD
        const aPrefix = a.srcVpnVnCode.charAt(0);
        const bPrefix = b.srcVpnVnCode.charAt(0);
        if (aPrefix !== bPrefix) {
          // VPNが先、VNが後になるようにソート
          if (aPrefix.startsWith("B")) {
            return 1;
          } else {
            return -1;
          }
        }
        // VN(L3)、VN(L2)の順にソート: D始まりのVNコード、C始まりのVNコードの順番
        if (aPrefix !== bPrefix) {
          if (aPrefix < bPrefix) {
            return -1;
          } else {
            return 1;
          }
        }

        // VPN/VN順のソートは済んでいるので、接尾辞を抜き出し降順にソート
        const aSuffix = a.srcVpnVnCode.substr(1);
        const bSuffix = b.srcVpnVnCode.substr(1);
        if (aSuffix !== bSuffix) {
          if (aSuffix < bSuffix) {
            return -1;
          } else {
            return 1;
          }
        }

        // 接続先VPN/VNコードの降順にソート
        if (a.dstVpnVnCode !== b.dstVpnVnCode) {
          if (a.dstVpnVnCode < b.dstVpnVnCode) {
            return -1;
          } else {
            return 1;
          }
        }
        return 0;
      } else {
        return null;
      }
    },
    /** エクストラネット接続申請を表示する */
    async showExtraConnectReg() {
      await this.$modal.show(ExtranetConnectRegister, {
        extranetKeyId: this.extranetKeyId,
      });
      await this.load();
    },

    /**
     * エクストラネット参照モーダルを表示する
     * @param item 押下した行のデータ
     */
    async onRowSelected(item: ExtranetInfo) {
      await this.$modal.show(ExtranetConnectReference, {
        connectInfo: item,
        extranetKeyId: this.extranetKeyId,
      });
      await this.load();
    },
  },
});

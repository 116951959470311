import { render, staticRenderFns } from "./OneTimeUrlIssue.vue?vue&type=template&id=5feb5fa5&scoped=true&"
import script from "./OneTimeUrlIssue.vue?vue&type=script&lang=ts&"
export * from "./OneTimeUrlIssue.vue?vue&type=script&lang=ts&"
import style0 from "./OneTimeUrlIssue.vue?vue&type=style&index=0&id=5feb5fa5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5feb5fa5",
  null
  
)

export default component.exports
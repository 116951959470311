

























import Vue from "vue";
import MixinSideMenu from "@/mixins/MixinSideMenu.vue";
import {
  SidebarComponentItem,
  SidebarHeaderItem,
  SidebarItem,
} from "vue-sidebar-menu";
import {
  ContractAppliances,
  ContractInfo,
  ContractSummary,
} from "@/apis/ContractApi";
import { mapState } from "vuex";
import PermissionService from "@/services/PermissionService";

/** NW設定メニューを取得 */
export function getNWSettingMenus(
  contractList: ContractInfo["contractList"],
  contractSummary: ContractSummary,
  applianceInfoList: ContractAppliances["applianceInfoList"],
  permission: PermissionService
): Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem> {
  const hasMultiCloudGW = contractList.some(
    (e) => e.accessType === "MULTI_CLOUD_GW"
  );
  const hasIPsec = contractList.some((e) => e.accessType === "IPSEC");
  const hasType1orIntranet = applianceInfoList.some(
    (e) => e.internetType === "TYPE1" || e.internetType === "INTRANET"
  );
  const isViewDelegate = permission.isViewDelegate();
  const hasCpa5Gsa = contractList.some((e) => e.accessType === "CPA_5GSA");
  return [
    {
      header: true,
      title: "アクセス回線",
      hiddenOnCollapse: true,
    },
    {
      href: "/access",
      title: "アクセス回線",
      icon: { element: "b-icon-diagram2" },
    },
    {
      header: true,
      title: "仮想ネットワーク設定",
      hiddenOnCollapse: true,
    },
    {
      href: "/vnetwork",
      title: "仮想ネットワーク",
      icon: { element: "b-icon-stack" },
    },
    {
      href: "/vnetwork/site",
      title: "サイト",
      icon: { element: "b-icon-stickies" },
      hidden: isViewDelegate,
    },
    {
      href: "/vnetwork/connect",
      title: "VNコネクト",
      icon: { element: "b-icon-diagram3" },
      hidden: isViewDelegate,
    },
    {
      href: "/vnetwork/qos",
      title: "QoS",
      icon: { element: "b-icon-ui-radios" },
      hidden: isViewDelegate,
    },

    {
      href: "/vnetwork/packet-filtering",
      title: "パケットフィルタリング",
      icon: { element: "b-icon-stoplights" },
      hidden: isViewDelegate,
    },
    {
      header: true,
      title: "マルチクラウド",
      hiddenOnCollapse: true,
      hidden: !hasMultiCloudGW,
    },
    {
      href: "/multicloud",
      title: "マルチクラウド",
      icon: { element: "b-icon-clouds" },
      hidden: !hasMultiCloudGW || isViewDelegate,
    },
    {
      href: "/multicloud/saas",
      title: "SaaS利用",
      icon: { element: "b-icon-cloud-plus" },
      hidden: !hasMultiCloudGW,
    },
    {
      href: "/multicloud/microsoft/napt",
      title: "Microsoft Peering向けNAPT",
      icon: { element: "b-icon-globe" },
      hidden: !hasMultiCloudGW,
    },
    {
      href: "/multicloud/microsoft/nat",
      title: "Microsoft Peering向けNAT",
      icon: { element: "b-icon-layer-backward" },
      hidden: !hasMultiCloudGW,
    },
    {
      href: "/multicloud/qos",
      title: "クラウド向けQoS",
      icon: { element: "b-icon-stickies-fill" },
      hidden: !hasMultiCloudGW || isViewDelegate,
    },
    {
      href: "/multicloud/packet-filtering",
      title: "クラウド向けパケットフィルタリング",
      icon: { element: "b-icon-stoplights" },
      hidden: !hasMultiCloudGW || isViewDelegate,
    },
    {
      header: true,
      title: "IPsec回線",
      hiddenOnCollapse: true,
      hidden: !hasIPsec,
    },
    {
      href: "/ipsec/gateway",
      title: "IPsecGW",
      icon: { element: "b-icon-key" },
      hidden: !hasIPsec,
    },
    {
      href: "/ipsec/line",
      title: "IPsec回線",
      icon: { element: "b-icon-share" },
      hidden: !hasIPsec || isViewDelegate,
    },
    {
      header: true,
      title: "リモートアクセスGW",
      hiddenOnCollapse: true,
      hidden: !hasCpa5Gsa,
    },
    {
      href: "/remote/access",
      title: "CPA 5G SA",
      icon: { element: "b-icon-reception-4" },
      hidden: !hasCpa5Gsa,
    },
    {
      header: true,
      title: "インターネット/アプライアンス",
      hiddenOnCollapse: true,
      hidden: contractSummary.isLargeScaleContract,
    },
    {
      href: "/network/site/type1",
      title: "サイトゾーン(Type1)",
      icon: { element: "b-icon-geo-fill" },
      hidden:
        !hasType1orIntranet ||
        contractSummary.isLargeScaleContract ||
        isViewDelegate,
    },
    {
      href: "/network/site/type4",
      title: "サイト(Type4)",
      icon: { element: "b-icon-joystick" },
      hidden: contractSummary.isLargeScaleContract || isViewDelegate,
    },
    {
      href: "/network/object",
      title: "アプライアンスオブジェクト",
      icon: { element: "b-icon-columns-gap" },
      hidden: contractSummary.isLargeScaleContract || isViewDelegate,
    },
    {
      href: "/network/contract",
      title: "契約・設定",
      icon: { element: "b-icon-shield-lock" },
      hidden: contractSummary.isLargeScaleContract,
    },
    {
      header: true,
      title: "",
      hiddenOnCollapse: true,
    },
    {
      href: "/extranet",
      title: "エクストラネット接続",
      icon: { element: "b-icon-bounding-box" },
      hidden: isViewDelegate,
    },
    {
      href: "/vnetwork/l2l3/connect",
      title: "VN L2/L3コネクト",
      icon: { element: "b-icon-arrows-expand" },
      hidden: isViewDelegate,
    },
    {
      href: "/hubspoke",
      title: "Hub&Spoke接続",
      icon: { element: "b-icon-snow" },
      hidden: isViewDelegate,
    },
  ] as Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem>;
}

export default Vue.extend({
  name: "NWSettingMain",
  mixins: [MixinSideMenu],
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
      contractSummary: "contractSummary",
      contractAppliances: "contractAppliances",
    }),
    menu(): Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem> {
      return getNWSettingMenus(
        this.contractInfo.contractList,
        this.contractSummary,
        this.contractAppliances.applianceInfoList,
        this.$service.permission
      );
    },
  },
});

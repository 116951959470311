
























































































































import Vue from "vue";
import { NotificationEntity } from "@/apis/NotificationApi";
import { mapState } from "vuex";

export default Vue.extend({
  name: "notificationContactEdit",
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
    validReceivingTypeRequired(): boolean {
      return !(
        this.form.receivesNotifications &&
        !this.form.receivingType.receivesNotice
      );
    },
  },
  data() {
    return {
      notificationContactInfo: {} as NotificationEntity,
      form: {
        receivingType: {},
        notificationMailList: [""],
      } as NotificationEntity,
      modeOptions: [
        { value: true, text: "ON" },
        { value: false, text: "OFF" },
      ],
    };
  },
  async mounted() {
    if (this.$route.params.notificationContactInfo) {
      this.notificationContactInfo = JSON.parse(
        this.$route.params.notificationContactInfo
      );
      this.form = this.notificationContactInfo;
    } else {
      await this.$router.push("/notification-contact");
    }
  },
  methods: {
    /** 重複チェック用メール一覧 */
    emailList(index: number) {
      return this.form.notificationMailList
        ? this.form.notificationMailList.filter((_, num) => num !== index)
        : [];
    },
    addEmail() {
      this.form.notificationMailList.push("");
    },
    removeEmail(index: number) {
      if (this.form.notificationMailList.length > 1) {
        this.form.notificationMailList.splice(index, 1);
      } else {
        this.form.notificationMailList = [""];
      }
    },
    async submit() {
      await this.$confirm("通知連絡先情報を登録します。よろしいですか？");
      await this.$api.notification.update(this.form);
      await this.$router.push({ path: "/notification-contact" });
    },
  },
});








































































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "MultiCloudVNConnectStatusModify",
  props: {
    /** true: 変更後の状態が有効, false: 変更後の状態が無効 */
    enable: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /** 状態変更対象の回線一覧 */
    vnConnects: {
      type: Array as PropType<
        {
          cloudVnConnectAct: {
            wnumber: string;
            isVnConnectStatus: boolean;
          };
          cloudVnConnectSby?: {
            wnumber: string;
            isVnConnectStatus: boolean;
          };
        }[]
      >,
      required: true,
    },
  },
  data() {
    return {
      // 変更できるレコードのみチェックをつける
      selected: this.vnConnects
        .flatMap((e) => {
          return e.cloudVnConnectSby
            ? [e.cloudVnConnectAct, e.cloudVnConnectSby]
            : [e.cloudVnConnectAct];
        })
        .filter((e) => e.isVnConnectStatus !== this.enable)
        .map((e) => e.wnumber),
      alertMsg: null as null | string,
    };
  },
  methods: {
    ok() {
      if (this.selected.length === 0) {
        this.alertMsg = this.$msg("no_select_data");
        return;
      } else {
        this.alertMsg = null;
      }

      (this.$refs.modal as Vue & { ok: (r: unknown) => void }).ok(
        this.selected
      );
    },
  },
});

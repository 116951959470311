



















































































































































































































































































import Vue, { PropType } from "vue";
import { Category, SetUrlProfile, UrlProfile } from "@/apis/UrlProfileApi";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "ProfileUrlFilteringEdit",
  props: {
    urlProfile: {
      type: Object as PropType<UrlProfile | null>,
      required: false,
      default: null,
    },
    profileUrlSeq: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    type4Id: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    profileUrlKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      form: {
        blackList: [""],
        blackListAction: "ALERT",
        whiteList: [""],
      } as UrlProfile,
    };
  },
  computed: {
    /** ブラックリストの重複チェック用リスト作成 */
    blacklistList() {
      return (index: number) => {
        return this.form.blackList.filter((_, num) => num !== index);
      };
    },
    /** ホワイトリストの重複チェック用リスト作成 */
    whitelistList() {
      return (index: number) => {
        return this.form.whiteList.filter((_, num) => num !== index);
      };
    },
  },
  async mounted() {
    if (this.urlProfile) {
      // 変更時 階層があるオブジェクトのためlodashのcloneDeepで対応
      this.form = cloneDeep(this.urlProfile);
      if (this.form.blackList.length === 0) {
        this.form.blackList.push("");
      }
      if (this.form.whiteList.length === 0) {
        this.form.whiteList.push("");
      }
    } else {
      // 追加時 カテゴリは既存のデータを初期設定する
      await this.load();
    }
    this.isLoaded = true;
  },
  methods: {
    async load() {
      // 追加の場合は取得したカテゴリを設定する カテゴリの一括設定がリアクティブになるように$setを利用
      this.$set(
        this.form,
        "categoryList",
        (await this.$api.urlProfileApi.getCategories()).categoryList
      );
    },

    async submit() {
      // 確認ダイアログの表示
      const action = this.urlProfile ? "変更" : "追加";
      await this.$confirm(`プロファイルを${action}します。よろしいですか？`);

      // データの設定
      // ブラックリスト1件かつ未入力は空配列を設定
      // ホワイトリスト1件かつ未入力は空配列を設定
      const reqData: SetUrlProfile = {
        profileUrlName: this.form.profileUrlName,
        blackList: this.isEmptyList(this.form.blackList)
          ? []
          : this.form.blackList,
        blackListAction: this.form.blackListAction,
        whiteList: this.isEmptyList(this.form.whiteList)
          ? []
          : this.form.whiteList,
        categoryList: this.form.categoryList,
        description: this.form.description || null,
        profileUrlKeyId: this.profileUrlKeyId,
        note: null,
      };

      // // プロファイル(URLフィルタリング)の登録・更新API呼び出し
      if (this.urlProfile) {
        await this.modUrlProfile(reqData);
      } else {
        await this.addUrlProfile(reqData);
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /**
     * ブラックリスト・ホワイトリストの入力フォームが1個かつ、未入力の場合trueを返す
     * @param list チェック対象のリスト
     */
    isEmptyList(list: string[]): boolean {
      return list.length === 1 && !list[0];
    },

    /** プロファイル(URLフィルタリング)の追加処理 */
    async addUrlProfile(reqData: SetUrlProfile) {
      // Type4IDの有無により呼び出すAPIをハンドリング
      if (!this.type4Id) {
        await this.$api.urlProfileApi.postType1UrlFiltering(reqData);
      } else {
        await this.$api.urlProfileApi.postType4UrlFiltering(
          this.type4Id,
          reqData
        );
      }
    },

    /** プロファイル(URLフィルタリング)の更新処理 */
    async modUrlProfile(reqData: SetUrlProfile) {
      // Type4IDの有無により呼び出すAPIをハンドリング
      if (!this.type4Id) {
        await this.$api.urlProfileApi.putType1UrlFiltering(
          this.profileUrlSeq,
          reqData
        );
      } else {
        await this.$api.urlProfileApi.putType4UrlFiltering(
          this.type4Id,
          this.profileUrlSeq,
          reqData
        );
      }
    },

    /** ブラックリストURL追加 */
    addBlackListURL() {
      this.form.blackList.push("");
    },

    /** ブラックリストURL削除 */
    removeBlackListURL(idx: number) {
      // 入力フォームが全て無くなる状態はポリシーに反するので、最後の1つは空文字にする
      if (this.form.blackList.length > 1) {
        this.form.blackList.splice(idx, 1);
      } else {
        this.form.blackList = [""];
      }
    },

    /** ホワイトリストURL追加 */
    addWhiteListURL() {
      this.form.whiteList.push("");
    },

    /** ホワイトリストURL削除 */
    removeWhiteListURL(idx: number) {
      // 入力フォームが全て無くなる状態はポリシーに反するので、最後の1つは空文字にする
      if (this.form.whiteList.length > 1) {
        this.form.whiteList.splice(idx, 1);
      } else {
        this.form.whiteList = [""];
      }
    },

    /** カテゴリのアクションを一括変更 */
    allChangeCategoryAction(action: Category["actionType"]) {
      // 全てのアクションを押下したボタンのアクションに変更する
      this.form.categoryList.forEach((v) => (v.actionType = action));
    },
  },
});

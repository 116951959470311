import moment from "moment";

/**
 * 時刻情報を日形式でフォーマット
 * @param value 時刻情報
 */
export default function date(value?: string | null | Date): string | null {
  if (!value) {
    return "-";
  }
  const date = moment(value);
  return date.isValid() ? date.format("YYYY/MM/DD") : null;
}

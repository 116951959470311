

















































import Vue, { PropType } from "vue";
import { ExtranetInfo, PutExtranetInfo } from "@/apis/ExtranetApi";
import ConnectionInfoTable from "@/components/extranet/ConnectionInfoTable.vue";
import ExtranetConnectApproval from "@/modals/extranet/ExtranetConnectApproval.vue";
import ExtranetDisconnectConfirm from "@/modals/extranet/ExtranetDisconnectConfirm.vue";

export default Vue.extend({
  name: "ExtranetConnectReference",
  components: { ConnectionInfoTable },
  props: {
    connectInfo: {
      type: Object as PropType<ExtranetInfo>,
      required: true,
    },
    extranetKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  computed: {
    /** 承認ステータスの変更要求 */
    approvalRequestType(): PutExtranetInfo["approvalRequest"] {
      // 承認ステータスが完了の場合は、変更要求が切断となる
      if (this.connectInfo.approvalState === "COMPLETE") {
        return "DISCONNECT";
      } else {
        // 接続元V番号とユーザーのV番号が一致する状態は申請側、一致しない場合は承認側となる
        if (
          this.connectInfo.srcVNumber ===
          this.$store.state.user.contractSummary.vnumber
        ) {
          // 承認ステータスが申請中の場合は、変更要求が取り下げとなる
          return "DROP";
        } else {
          // 承認ステータスが未承認(承認側でステータスが申請中)の場合は、変更要求が承認もしくは却下となる
          return "APPROVE";
        }
      }
    },
  },
  methods: {
    /**
     * エクストラネットの承認・取り下げ・切断を行う
     */
    async statusChange() {
      if (this.approvalRequestType === "APPROVE") {
        // 承認確認
        await this.$modal.show(ExtranetConnectApproval, {
          confirmData: this.connectInfo,
          extranetKeyId: this.extranetKeyId,
        });
      } else {
        // 取り下げ・切断
        await this.$modal.show(ExtranetDisconnectConfirm, {
          confirmData: this.connectInfo,
        });
        // APIに送信するデータの設定
        const data: PutExtranetInfo = {
          pinCode: null,
          approvalRequest: this.approvalRequestType,
          note: null,
          extranetKeyId: this.extranetKeyId,
        };
        // APIによるエクストラネット取り下げ処理
        await this.$api.extranetApi.putExtranet(
          this.connectInfo.extranetSeq,
          data
        );
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** エクストラネット接続申請を却下する */
    async rejection() {
      await this.$confirm(
        `エクストラネット接続申請を却下します。よろしいですか？`
      );

      // APIに送信するデータの設定
      const data: PutExtranetInfo = {
        pinCode: null,
        approvalRequest: "REJECTED",
        note: null,
        extranetKeyId: this.extranetKeyId,
      };

      // APIによるエクストラネット却下処理
      await this.$api.extranetApi.putExtranet(
        this.connectInfo.extranetSeq,
        data
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

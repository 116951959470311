
























































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { IpSecLineGetEntity, IpSecLineListEntity } from "@/apis/IpSecLineApi";
import { IpSecGWEntity } from "@/apis/IpSecGwApi";
import IpSecLineReference from "@/modals/ipSecLine/IpSecLineReference.vue";
import IpSecLineEdit from "@/modals/ipSecLine/IpSecLineEdit.vue";
import IpSecLineViewCD from "@/modals/ipSecLine/IpSecLineViewCD.vue";

/** IPsec回線 + IPsecGW */
export type IpSecLineWithGW = IpSecLineListEntity & {
  // IPsec回線に紐作りIPsecGWは必ずサフィックスが存在する（サフィックスがないと追加できない）
  gw: Omit<IpSecGWEntity, "suffix"> & { suffix: string };
};

export default Vue.extend({
  name: "IpSecLineList",
  data() {
    return {
      alertMsg: null as string | null,
      ipSec: {
        fields: [
          {
            key: "xnumber",
            label: "IPsec回線番号",
            sortable: true,
          },
          {
            key: "ipsecLineName",
            label: "IPsec回線名",
            sortable: true,
          },
          {
            key: "enumber",
            label: "IPsecGW回線番号",
            sortable: true,
          },
          {
            key: "suffix",
            label: "サフィックス",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, key: null, item: IpSecLineWithGW) => {
              return item.gw.suffix;
            },
          },
          {
            key: "gwLineSize",
            label: "同一IPsecGW回線数",
            tdClass: "text-right",
            sortable: true,
            sortByFormatted: true,
            formatter: (value: string, key: null, item: IpSecLineWithGW) => {
              return `${item.gw.numberOfIpsec}/${item.gw.upperLimitIpsec}`;
            },
          },
          {
            key: "userAccount",
            label: "USER-FQDN",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, key: null, item: IpSecLineWithGW) => {
              return `${item.userAccount}@${item.gw.suffix}`;
            },
          },
          {
            key: "ifStatus",
            label: "IF状態",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) =>
              this.$filter.enumTo(value, "cloudVn_ifStatus"),
          },
        ],
        selected: [],
        search: {
          xnumber: { type: "text", label: "IPsec回線番号" },
          ipsecLineName: { type: "text", label: "IPsec回線名" },
          enumber: { type: "text", label: "IPsecGW回線番号" },
          suffix: { type: "text", label: "サフィックス" },
          userAccount: { type: "text", label: "USER-FQDN" },
          ifStatus: {
            type: "select",
            label: "IF状態",
            items: [
              { value: "NO_SHUTDOWN", text: "no shutdown" },
              { value: "SHUTDOWN", text: "shutdown" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          xnumber: "like",
          ipsecLineName: "like",
          enumber: "like",
          suffix: "like",
          userAccount: "like",
          ifStatus: "equal",
        },
      } as Omit<AppTableData<IpSecLineWithGW>, "items">,
      ipSecLine: {} as IpSecLineGetEntity,
      ipSecGws: [] as IpSecGWEntity[],
      isLoaded: false,
    };
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
    /** IPsec回線 + IPsecGW */
    items(): IpSecLineWithGW[] {
      const gwByEnumber = this.ipSecGws.toMap((e) => e.enumber);
      return this.ipSecLine.ipsecList.map((e) => ({
        ...e,
        gw: gwByEnumber[e.enumber] as IpSecLineWithGW["gw"],
      }));
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.alertMsg = null;
      this.ipSecLine = await this.$api.ipSecLine.getAll();
      this.ipSecGws = (await this.$api.ipSecGw.getAll()).ipsecGatewayList;
    },
    /** IPsec回線追加 */
    async addIpSec() {
      this.alertMsg = null;
      await this.$modal.show(IpSecLineEdit);
      await this.load();
    },

    /** IPsec回線削除 */
    async deleteIpSec() {
      if (this.ipSec.selected.length > 0) {
        this.alertMsg = null;
        const note: string | null = await this.$modal.show(IpSecLineViewCD, {
          mode: "delete",
          lines: this.ipSec.selected,
        });
        await this.$api.ipSecLine.delete({
          ipsecList: this.ipSec.selected.map((x) => x.xnumber),
          acLineKeyId: this.ipSecLine.acLineKeyId,
          note: note,
        });
        await this.load();
      } else {
        this.alertMsg = this.$msg("no_select_data");
      }
    },

    /** IPsec回線情報画面表示 */
    async showDetails(entity: IpSecLineWithGW) {
      this.alertMsg = null;
      await this.$modal.show(IpSecLineReference, {
        ipSecLine: entity,
        keyId: this.ipSecLine.acLineKeyId,
      });
      await this.load();
    },
  },
});







































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  Bandwidth,
  GlobalIpAddress,
  Maintenance,
  Type4Service,
  Type4ServiceOptions,
  Type4ServicePutRequest,
} from "@/apis/ContractChangeType4Api";
import InternetType4ContractModifyConfirm from "@/modals/applianceContractSetting/InternetType4ContractModifyConfirm.vue";
import DefaultPolicyOnewaySp from "@/components/applianceContractSetting/DefaultPolicyOnewaySp.vue";
import DefaultPolicyOnewayAny from "@/components/applianceContractSetting/DefaultPolicyOnewayAny.vue";
import DefaultPolicyInteractiveAny from "@/components/applianceContractSetting/DefaultPolicyInteractiveAny.vue";
import cloneDeep from "lodash/cloneDeep";
import { ApplianceInfo } from "@/apis/ContractApi";

type typeType4ServicePutRequest = Type4ServicePutRequest & {
  globalIpAddressList?: GlobalIpAddress[];
  securityAppliance?: string;
};

export default Vue.extend({
  name: "InternetType4ContractModify",
  components: {
    DefaultPolicyInteractiveAny,
    DefaultPolicyOnewayAny,
    DefaultPolicyOnewaySp,
  },
  props: {
    selectedItem: {
      type: Object as PropType<Type4Service>,
      required: true,
    },
    type4Items: {
      type: Array as PropType<ApplianceInfo[]>,
      required: true,
    },
  },
  data() {
    return {
      /** 初期通信完了フラグ */
      isLoaded: false,
      user: this.$store.state.user,
      bandwidthOptions: [] as { value: number; text: string }[],
      lowBandwidth: [
        { value: 10, text: " 10 " },
        { value: 20, text: " 20 " },
        { value: 30, text: " 30 " },
        { value: 40, text: " 40 " },
        { value: 50, text: " 50 " },
        { value: 60, text: " 60 " },
        { value: 70, text: " 70 " },
        { value: 80, text: " 80 " },
        { value: 90, text: " 90 " },
        { value: 100, text: " 100 " },
        { value: 200, text: " 200 " },
        { value: 300, text: " 300 " },
        { value: 400, text: " 400 " },
        { value: 500, text: " 500 " },
        { value: 600, text: " 600 " },
        { value: 700, text: " 700 " },
        { value: 800, text: " 800 " },
        { value: 900, text: " 900 " },
        { value: 1000, text: " 1000 " },
      ],
      wideBandwidth: [
        { value: 1, text: " 1 " },
        { value: 2, text: " 2 " },
        { value: 3, text: " 3 " },
        { value: 4, text: " 4 " },
        { value: 5, text: " 5 " },
        { value: 6, text: " 6 " },
        { value: 7, text: " 7 " },
        { value: 8, text: " 8 " },
        { value: 9, text: " 9 " },
        { value: 10, text: " 10 " },
      ],
      unit: "Mbps",
      form: {
        policyType: "ALLOW_SPECIFIC_ONE_WAY" as
          | "ALLOW_SPECIFIC_ONE_WAY"
          | "ALLOW_ALL_ONE_WAY"
          | "ALLOW_ALL_TWO_WAY",
        type4Name: "",
        isMicrosoft365: false,
        maintenance: {
          company: "",
          department: "",
          name: "",
          phone: "",
          email: "",
        } as Maintenance,
        contractType: "BEST_EFFORT" as "BEST_EFFORT" | "SECURING",
        applianceType: "UTM" as "IFW" | "UTM",
        contractType4KeyId: "",
        bandwidth: null as Bandwidth | null,
        numberOfAddGlobalIpAddress: (this.selectedItem.globalIpAddressList
          .length === 0
          ? 1
          : 0) as number | null,
        deleteGlobalIpAddressSeqList: null as string[] | null,
        globalIpAddressList: [] as GlobalIpAddress[],
        securityAppliance: "IFW",
        note: null,
      },
      paidGlobalIpCount: this.selectedItem.globalIpAddressList.length,
      cloneItem: {} as Type4ServicePutRequest,
      isCollapsed_OnewaySp: false,
      isCollapsed_OnewayAny: false,
      isCollapsed_InteractiveAny: false,
      type4Options: {} as Type4ServiceOptions,
      selectedUnit: null as ApplianceInfo | null,
      maintenanceInfoErr: false,
    };
  },
  computed: {
    /** true: 削除可能, false: 削除不可 */
    canRemove(): boolean {
      return (
        this.form.globalIpAddressList.length +
          this.form.numberOfAddGlobalIpAddress! >
        1
      );
    },
    /** true: 追加可能, false: 追加不可 */
    canAdd(): boolean {
      return (
        this.paidGlobalIpCount + this.form.numberOfAddGlobalIpAddress! < 32
      );
    },
    objectChanged(): boolean {
      //帯域確保型以外の場合には、bandwidthを変更検知から除外する
      const bfItem = cloneDeep(this.cloneItem);
      const targetItem = cloneDeep(this.form);
      if (this.form.securityAppliance !== "UTM_SECURING") {
        bfItem.bandwidth = null;
        targetItem.bandwidth = null;
      }
      return this.$crossValidation.objectChanged(bfItem, targetItem);
    },
    /** true: UTM ⇄ IFWの変更あり, false: UTM ⇄ IFWの変更なし */
    applianceChanged(): boolean {
      return this.selectedItem.applianceType !== this.form.applianceType;
    },
    sortFilterType4Items(): ApplianceInfo[] | null {
      return this.type4Items
        .filter(
          (item) => item.wnumberMainAct !== this.selectedItem.wnumberMainAct
        )
        .sortBy(["vpnVnCode", "asc"]);
    },
  },
  watch: {
    "form.securityAppliance": function (val: string, oldVal: string) {
      switch (val) {
        case "IFW":
          /**
           * 関連項目チェック
           * アプライアンス種別がインターネットFWの場合、契約種別がベストエフォートであること
           * ここで値設定しているので関連項目チェック不要
           */
          this.form.applianceType = "IFW";
          this.form.contractType = "BEST_EFFORT";
          this.form.policyType = this.selectedItem.policyType;
          break;
        case "UTM_BEST_EFFORT":
          this.form.applianceType = "UTM";
          this.form.contractType = "BEST_EFFORT";
          if (oldVal === "IFW") {
            this.form.policyType = this.selectedItem.policyType;
          }
          break;
        case "UTM_SECURING":
          this.form.applianceType = "UTM";
          this.form.contractType = "SECURING";
          if (oldVal === "IFW") {
            this.form.policyType = this.selectedItem.policyType;
          }
          break;
      }
    },
    /** 選択値変更時にエラーメッセージを非表示 */
    selectedUnit() {
      this.maintenanceInfoErr = false;
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.type4Options =
        await this.$api.contract_change_type4.getType4ServiceOptions(
          this.selectedItem.type4Id
        );
      if (this.type4Options.bandwidthType === "LOW_BANDWIDTH") {
        this.bandwidthOptions = this.lowBandwidth;
        this.unit = "Mbps";
        this.form.bandwidth = { unit: "MBPS", value: 0 };
      } else if (this.type4Options.bandwidthType === "WIDE_BANDWIDTH") {
        this.bandwidthOptions = this.wideBandwidth.filter(
          (i) =>
            this.type4Options.lowerLimitBandwidth &&
            this.type4Options.upperLimitBandwidth &&
            this.type4Options.lowerLimitBandwidth.value <= i.value &&
            i.value <= this.type4Options.upperLimitBandwidth.value
        );
        this.unit = "Gbps";
        this.form.bandwidth = { unit: "GBPS", value: 0 };
      }
      if (this.selectedItem.applianceType === "IFW") {
        this.form.securityAppliance = "IFW";
      } else {
        if (this.selectedItem.contractType === "BEST_EFFORT") {
          this.form.securityAppliance = "UTM_BEST_EFFORT";
        } else {
          this.form.securityAppliance = "UTM_SECURING";
        }
      }
      this.form.policyType = this.selectedItem.policyType;
      this.form.type4Name = this.selectedItem.type4Name;
      this.form.isMicrosoft365 = this.selectedItem.isMicrosoft365;
      this.form.contractType = this.selectedItem.contractType;
      this.form.applianceType = this.selectedItem.applianceType;
      this.form.contractType4KeyId = this.selectedItem.contractType4KeyId;
      if (this.selectedItem.maintenance) {
        this.form.maintenance.company = this.selectedItem.maintenance.company;
        this.form.maintenance.department =
          this.selectedItem.maintenance.department;
        this.form.maintenance.name = this.selectedItem.maintenance.name;
        this.form.maintenance.phone = this.selectedItem.maintenance.phone;
        this.form.maintenance.email = this.selectedItem.maintenance.email;
      }
      /** bandwidth.unitの変換 */
      if (this.selectedItem.bandwidth) {
        this.form.bandwidth = { ...this.selectedItem.bandwidth };
        /** 低帯域かつunitがGBPSの場合は「Mbps」に変換 */
        if (
          this.type4Options.bandwidthType === "LOW_BANDWIDTH" &&
          this.form.bandwidth.unit === "GBPS"
        ) {
          if (this.form.bandwidth.value === 1) {
            this.form.bandwidth.value = 1000;
            this.form.bandwidth.unit = "MBPS";
          } else {
            this.form.bandwidth.value = 0;
            this.form.bandwidth.unit = "MBPS";
          }
        }
        /** 広帯域かつunitがMBPSの場合は「Gbps」に変換 */
        if (
          this.type4Options.bandwidthType === "WIDE_BANDWIDTH" &&
          this.form.bandwidth.unit === "MBPS"
        ) {
          if (this.form.bandwidth.value === 1000) {
            this.form.bandwidth.value = 1;
            this.form.bandwidth.unit = "GBPS";
          } else {
            this.form.bandwidth.value = 0;
            this.form.bandwidth.unit = "GBPS";
          }
        }
      }
      this.form.globalIpAddressList = this.selectedItem.globalIpAddressList.map(
        (e) => ({ ...e })
      );
      this.cloneItem = cloneDeep(this.form);
    },
    /** 確認モーダルの表示 */
    async submit() {
      await this.$modal.show(InternetType4ContractModifyConfirm, {
        selectedItem: this.selectedItem,
        putItem: this.form,
        globalIpAddressList: this.form.globalIpAddressList,
      });
      /**
       * 関連項目チェック
       * 契約種別が帯域確保の場合、利用帯域が設定されていること。
       * また、 契約種別がベストエフォートの場合、利用帯域が設定されていないこと
       * ここでnull設定しているので関連項目チェック不要
       */
      if (this.form.securityAppliance !== "UTM_SECURING") {
        this.form.bandwidth = null;
      }
      if (this.form.numberOfAddGlobalIpAddress === 0) {
        this.form.numberOfAddGlobalIpAddress = null;
      }
      this.form.note = this.form.note || null;
      // 不要な項目の削除(objectChangeでエラーとなるので値を詰め替えてから削除)
      const postForm: typeType4ServicePutRequest = cloneDeep(this.form);
      delete postForm.securityAppliance;
      delete postForm.globalIpAddressList;
      await this.$api.contract_change_type4.putType4Service(
        this.selectedItem.type4Id,
        postForm
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 登録済のグローバルIPを削除する
     @param globalIpAddressSeq 登録済のIPアドレスID
     */
    removeGlobalIpAddress(globalIpAddressSeq: string) {
      if (!this.form.deleteGlobalIpAddressSeqList) {
        this.form.deleteGlobalIpAddressSeqList = [];
      }
      this.form.deleteGlobalIpAddressSeqList.push(globalIpAddressSeq);
      const index = this.form.globalIpAddressList.findIndex(
        (e) => e.globalIpAddressSeq === globalIpAddressSeq
      );
      this.form.globalIpAddressList.splice(index, 1);
    },
    labelUnit({ vpnVnCode, type4Name, wnumberMainAct }: ApplianceInfo): string {
      return `${vpnVnCode} / ${type4Name} / ${wnumberMainAct}`;
    },
    async setProperty() {
      if (this.selectedUnit) {
        const setForm = (
          await this.$api.contract_change_type4.getType4Service(
            this.selectedUnit.type4Id!
          )
        ).maintenance;
        if (setForm) {
          this.maintenanceInfoErr = false;
          this.form.maintenance = setForm;
        } else {
          this.maintenanceInfoErr = true;
        }
      }
    },
  },
});

























































































































































































































































































import Vue, { PropType } from "vue";
import {
  CloudQosDetail,
  PutCloudQosDetail,
  CloudQosBandwidth,
} from "@/apis/CloudQosApi";
import CloudQosViewRC from "@/modals/cloudQos/CloudQosViewRC.vue";

export default Vue.extend({
  name: "CloudQosEdit",
  props: {
    vpnVnCode: {
      type: String as PropType<string>,
      required: true,
    },
    vnName: {
      type: String as PropType<string>,
      required: true,
    },
    isNew: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    cloudQosDetail: {
      type: Object as PropType<CloudQosDetail>,
      required: true,
    },
  },
  data() {
    return {
      putCloudQosDetail: {
        qosType: "NONE",
        priorityTarget: "NONE",
        cloudQosAbsolute: {
          priority0: "LOW",
          priority1: "LOW",
          priority2: "NORMAL",
          priority3: "NORMAL",
          priority4: "MEDIUM",
          priority5: "HIGH",
          priority6: "LOW",
          priority7: "LOW",
        },
      } as PutCloudQosDetail,
      cloudQosBandwidthList: [] as Omit<CloudQosBandwidth, "bestEffort">[],
      absolutePriorityRowNames: [
        { key: "HIGH", value: "High" },
        { key: "MEDIUM", value: "Medium" },
        { key: "NORMAL", value: "Normal" },
        { key: "LOW", value: "Low" },
      ],
      absolutePriorityColNames: ["0", "10", "18", "24", "34", "46", "-", "-"],
      absolutePriorityPropNames: [
        "priority0",
        "priority1",
        "priority2",
        "priority3",
        "priority4",
        "priority5",
        "priority6",
        "priority7",
      ],
      bandwidthColNames: ["18", "24", "34", "46", "ベストエフォート"],
      bandwidthRowNames: [
        { key: "PATTERN_A", value: "パターンA" },
        { key: "PATTERN_A", value: "" },
        { key: "PATTERN_B", value: "パターンB" },
        { key: "PATTERN_B", value: "" },
      ],
      bandwidthValueOptions: [
        { value: null, text: "" },
        { value: 5, text: "5%" },
        { value: 10, text: "10%" },
        { value: 15, text: "15%" },
        { value: 20, text: "20%" },
        { value: 25, text: "25%" },
        { value: 30, text: "30%" },
        { value: 35, text: "35%" },
        { value: 40, text: "40%" },
        { value: 45, text: "45%" },
        { value: 50, text: "50%" },
        { value: 55, text: "55%" },
        { value: 60, text: "60%" },
        { value: 65, text: "65%" },
        { value: 70, text: "70%" },
        { value: 75, text: "75%" },
        { value: 80, text: "80%" },
        { value: 85, text: "85%" },
        { value: 90, text: "90%" },
      ],
      onOff: [
        { value: true, text: "ON" },
        { value: false, text: "OFF" },
      ],
    };
  },
  computed: {
    crossValidation1(): boolean {
      return this.putCloudQosDetail.qosType === "BANDWIDTH" &&
        this.cloudQosBandwidthList
        ? this.cloudQosBandwidthList.some(
            (e) =>
              (e.dscp18Value || 0) +
                (e.dscp24Value || 0) +
                (e.dscp34Value || 0) +
                (e.dscp46Value || 0) >
              0
          )
        : true;
    },
    crossValidation2(): boolean {
      return this.putCloudQosDetail.qosType === "BANDWIDTH" &&
        this.cloudQosBandwidthList
        ? !this.cloudQosBandwidthList.some(
            (e) =>
              (e.dscp18Value || 0) +
                (e.dscp24Value || 0) +
                (e.dscp34Value || 0) +
                (e.dscp46Value || 0) >
              90
          )
        : true;
    },
    crossValidation3(): boolean {
      return this.putCloudQosDetail.qosType === "BANDWIDTH" &&
        this.cloudQosBandwidthList
        ? !this.cloudQosBandwidthList.some(
            (e) =>
              e.isDscp46TopPriority &&
              ((e.dscp46Value || 0) < 5 || 50 < (e.dscp46Value || 0))
          )
        : true;
    },
  },
  watch: {
    "putCloudQosDetail.qosType": function (val: string) {
      switch (val) {
        case "ABSOLUTE":
          this.putCloudQosDetail.priorityTarget = "IPPRECEDENCE_DSCP";
          break;
        case "BANDWIDTH":
          this.putCloudQosDetail.priorityTarget = "DSCP";
          break;
      }
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.putCloudQosDetail.cloudQosKeyId = this.cloudQosDetail.cloudQosKeyId;
      this.putCloudQosDetail.qosType = this.cloudQosDetail.qosType;
      this.putCloudQosDetail.priorityTarget =
        this.cloudQosDetail.priorityTarget;

      if (this.putCloudQosDetail.qosType === "NONE") {
        this.intBandwidthList();
      }

      if (this.putCloudQosDetail.qosType === "ABSOLUTE") {
        if (this.cloudQosDetail.cloudQosAbsolute) {
          this.putCloudQosDetail.cloudQosAbsolute = {
            ...this.cloudQosDetail.cloudQosAbsolute,
          };
        }
        this.intBandwidthList();
      }
      if (this.putCloudQosDetail.qosType === "BANDWIDTH") {
        ["PATTERN_A", "PATTERN_B"].forEach((pattern) => {
          const item = this.cloudQosDetail.cloudQosBandwidthList?.find(
            (e) => e.patternName === pattern
          );
          if (item) {
            this.cloudQosBandwidthList.push({
              patternName: item.patternName as "PATTERN_A" | "PATTERN_B",
              dscp18Value: item.dscp18Value,
              dscp24Value: item.dscp24Value,
              dscp34Value: item.dscp34Value,
              dscp46Value: item.dscp46Value,
              isDscp46TopPriority: item.isDscp46TopPriority,
            });
          } else {
            this.cloudQosBandwidthList.push({
              patternName: pattern as "PATTERN_A" | "PATTERN_B",
              dscp18Value: null,
              dscp24Value: null,
              dscp34Value: null,
              dscp46Value: null,
              isDscp46TopPriority: false,
            });
          }
        });
      }
    },
    intBandwidthList() {
      ["PATTERN_A", "PATTERN_B"].forEach((pattern) => {
        this.cloudQosBandwidthList.push({
          patternName: pattern as "PATTERN_A" | "PATTERN_B",
          dscp18Value: null,
          dscp24Value: null,
          dscp34Value: null,
          dscp46Value: null,
          isDscp46TopPriority: false,
        });
      });
    },
    /**
     * 帯域制御方式の優先タイプ値の合計を算出
     * @param item クラウドQoS帯域設定地情報
     */
    sumDscpValue(item: Omit<CloudQosBandwidth, "bestEffort">): number {
      return (
        (item.dscp18Value || 0) +
        (item.dscp24Value || 0) +
        (item.dscp34Value || 0) +
        (item.dscp46Value || 0)
      );
    },
    /**
     * 帯域制御方式のベストエフォートを算出
     * @param item クラウドQoS帯域設定地情報
     */
    calcBestEffort(item: Omit<CloudQosBandwidth, "bestEffort">): string {
      return (item.dscp18Value || 0) +
        (item.dscp24Value || 0) +
        (item.dscp34Value || 0) +
        (item.dscp46Value || 0) >
        0
        ? `${
            100 -
            ((item.dscp18Value || 0) +
              (item.dscp24Value || 0) +
              (item.dscp34Value || 0) +
              (item.dscp46Value || 0))
          }%`
        : "-";
    },
    async submit() {
      this.putCloudQosDetail.cloudQosBandwidthList = this.cloudQosBandwidthList;

      await this.$modal.show(CloudQosViewRC, {
        mode: "C",
        isNew: this.isNew,
        cloudQosDetail: this.putCloudQosDetail,
      });

      /** null又は空配列設定 */
      if (this.putCloudQosDetail.qosType === "ABSOLUTE") {
        this.putCloudQosDetail.cloudQosBandwidthList = [];
      }
      if (this.putCloudQosDetail.qosType === "BANDWIDTH") {
        this.putCloudQosDetail.cloudQosAbsolute = null;
        if (this.cloudQosBandwidthList) {
          this.putCloudQosDetail.cloudQosBandwidthList =
            this.cloudQosBandwidthList;
        }
      }

      await this.$api.cloudQosApi.putCloudQosDetail(
        this.vpnVnCode,
        this.putCloudQosDetail
      );

      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

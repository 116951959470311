




































































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { IpMapping } from "@/apis/IpMappingApi";
import IpMappingType4Edit from "@/modals/applianceObject/IpMappingType4Edit.vue";
import IpMappingReference from "@/modals/applianceObject/IpMappingReference.vue";

export default Vue.extend({
  name: "Type4IpMapping",
  props: {
    /** Type4ID */
    type4Id: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      ipMappingAlertMsg: "",
      ipMappingKeyId: "",
      ipMapping: {
        fields: [
          { key: "ipMappingName", label: "名前", sortable: true },
          { key: "globalIpAddress", label: "グローバルIP", sortable: true },
          { key: "privateIpAddress", label: "プライベートIP", sortable: true },
          { key: "isPortForward", label: "ポートフォワード", sortable: true },
          {
            key: "description",
            label: "備考",
            tdClass: "text-pre-wrap",
            sortable: true,
          },
          { key: "isPolicyInUse", label: "ポリシー使用有無", sortable: true },
        ],
        items: [],
        selected: [],
        search: {
          ipMappingName: { type: "text", label: "名前" },
          globalIpAddress: { type: "text", label: "グローバルIP" },
          privateIpAddress: { type: "text", label: "プライベートIP" },
          isPortForward: {
            type: "select",
            label: "ポートフォワード",
            items: [
              { value: true, text: "有効" },
              { value: false, text: "無効" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          ipMappingName: "like",
          globalIpAddress: "like",
          privateIpAddress: "like",
          isPortForward: "equal",
        },
      } as AppTableData<IpMapping>,
      isLoaded: false,
    };
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      const res = await this.$api.ipMapping.getType4IpMappingList(this.type4Id);
      this.ipMapping.items = res.ipMappingList;
      this.ipMappingKeyId = res.ipMappingKeyId;

      // チェックボックスの状態はデータを再取得しても残るので、ここでクリアしておく
      if (this.ipMapping.selected.length) {
        this.ipMapping.selected = [];
      }
    },

    /**
     * IPマッピング参照モーダルを表示する
     * @param row テーブルで選択された行データ
     */
    async showIpMappingReference(row: IpMapping) {
      this.ipMappingAlertMsg = "";
      await this.$modal.show(IpMappingReference, {
        ipMapping: row,
        ipMappingKeyId: this.ipMappingKeyId,
        type4Id: this.type4Id,
      });
      // 各タブの情報を再取得する
      this.$emit("reload");
    },

    /** IPマッピング追加モーダルを表示する */
    async showIpMappingRegister() {
      this.ipMappingAlertMsg = "";
      await this.$modal.show(IpMappingType4Edit, {
        ipMappingKeyId: this.ipMappingKeyId,
        type4Id: this.type4Id,
      });
      // 各タブの情報を再取得する
      this.$emit("reload");
    },

    /** IPマッピング削除確認モーダルを表示する */
    async deleteIpMapping() {
      // チェックボックスが選択1つも選択されていない場合、アラート表示して処理終了
      if (this.ipMapping.selected.length <= 0) {
        // アラートを表示する
        this.ipMappingAlertMsg = this.$filter.message("no_select_data");
        return;
      } else {
        // アラートを非表示にする
        this.ipMappingAlertMsg = "";
      }

      await this.$confirm(
        `IPマッピングを${this.ipMapping.selected.length}件削除します。よろしいですか？`
      );
      await this.$api.ipMapping.deleteType4IpMapping(this.type4Id, {
        ipMappingList: this.ipMapping.selected.map(
          (e) => e.virtualIpAddressSeq
        ),
        ipMappingKeyId: this.ipMappingKeyId,
        note: null,
      });
      // 各タブの情報を再取得する
      this.$emit("reload");
    },

    /** エラーメッセージを初期化する(親画面から呼び出される) */
    async alertClear() {
      this.ipMappingAlertMsg = "";
    },
  },
});

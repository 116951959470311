











































































































































import Vue, { PropType } from "vue";
import { CloudQosDetail } from "@/apis/CloudQosApi";
import { ControlPattern } from "@/apis/VNConnectApi";

export default Vue.extend({
  name: "CloudQoSSetting",
  props: {
    /** VPN/VNコード */
    vpnVnCode: {
      type: String as PropType<string>,
      required: true,
    },
    /** QoS帯域制御パターン。指定した場合はそのパターンのみが表示される */
    bandControlPattern: {
      type: String as PropType<ControlPattern>,
      required: false,
    },
  },
  data() {
    return {
      absolutePriorityRowNames: [
        { key: "HIGH", value: "High" },
        { key: "MEDIUM", value: "Medium" },
        { key: "NORMAL", value: "Normal" },
        { key: "LOW", value: "Low" },
      ],
      absolutePriorityColNames: ["0", "10", "18", "24", "34", "46", "-", "-"],
      absolutePriorityPropNames: [
        "priority0",
        "priority1",
        "priority2",
        "priority3",
        "priority4",
        "priority5",
        "priority6",
        "priority7",
      ],
      bandwidthColNames: ["18", "24", "34", "46", "ベストエフォート"],
      bandwidthRowNames: [
        { key: "PATTERN_A", value: "パターンA" },
        { key: "PATTERN_A", value: "" },
        { key: "PATTERN_B", value: "パターンB" },
        { key: "PATTERN_B", value: "" },
      ],
      bandwidthPropNames: [
        "dscp18Value",
        "dscp24Value",
        "dscp34Value",
        "dscp46Value",
        "bestEffort",
      ],
      cloudQosDetail: {} as CloudQosDetail,
    };
  },
  async mounted() {
    this.cloudQosDetail = await this.$api.cloudQosApi.getCloudQosDetail(
      this.vpnVnCode
    );
  },
  methods: {
    /**
     * 帯域制御方式の値を取得
     * @param patternName パターン名
     * @param propName プロパティ名
     * */
    getBandwidthValue: function (patternName: string, propName: string) {
      const item = this.cloudQosDetail.cloudQosBandwidthList?.find(
        (e) => e.patternName === patternName
      );
      if (item) {
        if (propName === "bestEffort") {
          return (item.dscp18Value || 0) +
            (item.dscp24Value || 0) +
            (item.dscp34Value || 0) +
            (item.dscp46Value || 0) >
            0
            ? `${
                100 -
                ((item.dscp18Value || 0) +
                  (item.dscp24Value || 0) +
                  (item.dscp34Value || 0) +
                  (item.dscp46Value || 0))
              }%`
            : "-";
        } else {
          switch (propName) {
            case "dscp18Value":
              return item.dscp18Value ? `${item.dscp18Value}%` : "-";
            case "dscp24Value":
              return item.dscp24Value ? `${item.dscp24Value}%` : "-";
            case "dscp34Value":
              return item.dscp34Value ? `${item.dscp34Value}%` : "-";
            case "dscp46Value":
              return item.dscp46Value ? `${item.dscp46Value}%` : "-";
          }
        }
      } else {
        return "-";
      }
    },
    /**
     * 帯域制御方式の最優先設定フラグを取得
     * @param patternName パターン名
     * */
    isDscp46TopPriority(patternName: string) {
      const item = this.cloudQosDetail.cloudQosBandwidthList?.find(
        (e) => e.patternName === patternName
      );
      if (item) {
        return item.isDscp46TopPriority ? "ON" : "OFF";
      } else {
        return "-";
      }
    },
  },
});

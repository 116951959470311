





























































































































































































































import Vue, { PropType } from "vue";
import { SiteInfo, VNConnectForm } from "@/modals/vnConnect/VNConnectEdit.vue";
import { VpnVnCode } from "@/apis/InformationApi";
import { Type1PrivateInfo, Type1PrivateLine } from "@/apis/Type1SiteApi";
import {
  AccessLineGetEntity,
  BandwidthListGetEntity,
} from "@/apis/AccessLineApi";
import { Bandwidth, BandwidthType, VNConnect } from "@/apis/VNConnectApi";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";
import { QosInfo } from "@/apis/QosApi";

export default Vue.extend({
  name: "VNConnectEditStepper1",
  props: {
    /** true: 編集, false: 追加 */
    isEdit: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /** Form情報 */
    value: {
      type: Object as PropType<VNConnectForm>,
      required: true,
    },
    /** VPN/VNコードの一覧 */
    vpnVnCode: {
      type: Array as PropType<VpnVnCode[]>,
      required: true,
    },
    /** Type1プライベートサイトゾーン情報 */
    type1Site: {
      type: Object as PropType<Type1PrivateInfo>,
      required: true,
    },
    /** アクセス回線の契約一覧情報 */
    accessLine: {
      type: Object as PropType<AccessLineGetEntity>,
      required: true,
    },
    /** VNコネクト一覧。追加時のみ必須 */
    vnConnects: {
      type: Array as PropType<VNConnect[]>,
      required: false,
    },
    /** QoS一覧。選択されているサイトがPFアクセスGWⅡ(QoSパスなし)の場合のみ必須 */
    qosList: {
      type: Array as PropType<QosInfo[]>,
      required: false,
    },
    /**
     * 回線の設定上限値.
     * サイト未選択や回線情報のサービス開始日が未来日の場合はundefined
     */
    upperLimitList: {
      type: Array as PropType<UpperLimitEntity[]>,
      required: false,
    },
  },
  data() {
    return {
      form: this.value,
    };
  },
  computed: {
    /** サイト一覧 */
    sites(): SiteInfo[] {
      return (
        this.type1Site.lineList
          .flatMap((e1) => {
            return e1.siteList.map((e2) => ({
              enumber: e1.enumber,
              accessType: e1.accessType,
              lineBandwidth: this.accessLine.bandwidthList.find(
                (ac) =>
                  ac.enumberAct === e1.enumber || ac.enumberSby === e1.enumber
              ),
              ...e2,
            }));
          })
          // リモートアクセスGW系はVNコネクトを作成できないので除外
          .filter(
            (e) =>
              e.accessType !== "REMOTE_ACCESS_GW_CPA" &&
              e.accessType !== "REMOTE_ACCESS_GW_CRG" &&
              e.accessType !== "REMOTE_ACCESS_GW_FRE"
          )
      );
    },
    /** サイト名選択肢 */
    siteNameOptions(): {
      siteNameLabel: { enumber: string; siteName: string; vlan: string };
      siteNameValues: SiteInfo[];
    }[] {
      return this.sites.length !== 0
        ? [
            {
              siteNameLabel: {
                enumber: "E番号",
                siteName: "サイト名",
                vlan: "VLAN",
              },
              siteNameValues: this.sites,
            },
          ]
        : [];
    },
    /** 仮想ネットワーク名選択肢 */
    vpnVnCodeOptions(): VpnVnCode[] {
      if (this.form.siteInfo) {
        // 特記事項5 - サイト種別・回線状況ごとの選択可能ネットワーク
        const options = ((): VpnVnCode[] => {
          const sn = availableNetwork[this.form.siteInfo.accessType];
          // 配列の場合は回線状況によって異なるため定義を特定
          let avv: AvailableVpnVn;
          if (Array.isArray(sn)) {
            const e = sn.find((e) => e.is(this.form.siteInfo!.lineBandwidth!));
            if (e) {
              avv = e.data;
            } else {
              throw new Error(
                `not defined lineBandwidth. line: ${
                  this.form.siteInfo!.accessType
                }, bandwidth: ${this.form.siteInfo!.lineBandwidth!}`
              );
            }
          } else {
            avv = sn;
          }

          // 定義に従いVPN/VNコードをフィルタリング
          const targets = avv[this.form.siteInfo.vlanIdType];
          if (targets) {
            return this.vpnVnCode.filter((vpnVn) => {
              return targets.some((e) => {
                // 定義のVLAN種別のみ
                switch (e.vpnVn) {
                  case "VPN":
                    if (vpnVn.vnType !== null) {
                      return false;
                    }
                    break;
                  case "L3":
                  case "L2":
                    if (vpnVn.vnType !== e.vpnVn) {
                      return false;
                    }
                    break;
                }

                // 定義のVLANのみ
                if (e.vlan) {
                  if (e.vlan === "EQ") {
                    // EQの場合はサイトのVLAN IDと一致してる場合のみ
                    return vpnVn.vlanId === this.form.siteInfo!.vlanId;
                  } else {
                    // EQ以外は指定されたVLAN種別のみ
                    return vpnVn.vlanIdType === e.vlan;
                  }
                }

                return true;
              });
            });
          } else {
            throw new Error(
              `not defined VLAN type. line: ${
                this.form.siteInfo!.accessType
              }, type: ${this.form.siteInfo.vlanIdType}`
            );
          }
        })();

        // 特記事項5 - 回線種別が「PFアクセスGWⅡ(QoSパスなし)」の場合、QoS設定が紐づくネットワークは表示しない
        if (this.qosList) {
          const qosByVpnVnCode = this.qosList.toMap((e) => e.vpnVnCode);
          return options.filter((e) => {
            const qos = qosByVpnVnCode[e.vpnVnCode];
            return qos ? qos.qosType === "NONE" : true;
          });
        } else {
          return options;
        }
      } else {
        return [];
      }
    },
    /** true: ペア回線表示, false: 非表示 */
    isShowPairLine(): boolean {
      return this.form.siteInfo
        ? availablePairLine[this.form.siteInfo.accessType]
        : false;
    },
    /** ペア回線選択肢（選択されたサイトと同一の回線種別のみ） */
    pairLines(): string[] {
      const siteByEnumber = this.sites.toMap((e) => e.enumber);
      if (this.form.siteInfo) {
        // enumber = nullはPFGW（インターネット）。作成できないので対象から除外
        return this.vnConnects!.filter((e) => e.enumber !== null)
          .filter(
            (e) =>
              // E番号連携のVNコネクトなどサイト一覧で存在しないE番号のケースが存在する
              siteByEnumber[e.enumber!] &&
              availablePairLine[siteByEnumber[e.enumber!].accessType]
          )
          .map((e) => e.wnumber);
      } else {
        return [];
      }
    },
    /** 品目選択肢 */
    bandwidthTypes(): BandwidthType[] {
      if (this.form.siteInfo) {
        return availableBandwidthType[this.form.siteInfo.accessType].find(
          (e) => e.is?.(this.form.siteInfo!.lineBandwidth) ?? true
        )!.types;
      } else {
        return [];
      }
    },
    /** true: トラフィックフリーは選択不可（イーサネット方式ⅡでTF無し or UNOの場合）, false: 選択可 */
    disabledTrafficFree(): boolean {
      // 特記事項3
      if (this.form.siteInfo) {
        return (
          this.form.siteInfo.accessType === "ETHERNET2" &&
          (this.form.siteInfo.lineBandwidth!.bandwidthType !== "TRAFFICFREE" ||
            this.form.siteInfo.lineBandwidth!.isUno)
        );
      } else {
        return false;
      }
    },
    /** true: 帯域表示（品目が帯域確保、PF、TF）, false: 非表示 */
    isShowBandwidth(): boolean {
      switch (this.form.bandwidthType) {
        case "BANDWIDTH_SECURED":
        case "PF":
        case "TRAFFICFREE":
          return true;
        case "BURST":
        case "BESTEFFORT":
        default:
          return false;
      }
    },
    /** 選択しているサイトの回線情報に一致する帯域定義 */
    bandwidthInfo(): { bandwidths: number[] } {
      const siteInfo = this.form.siteInfo;
      if (siteInfo && bandwidthList[siteInfo.accessType].length > 0) {
        const bandwidth = bandwidthList[siteInfo.accessType].find((e) => {
          return e.is(siteInfo.lineBandwidth);
        });
        if (bandwidth) {
          return bandwidth;
        } else {
          // 想定外の回線パターン、開発の場合は多分モックデータがおかしい
          console.warn(
            siteInfo.accessType,
            siteInfo.lineBandwidth,
            bandwidthList[siteInfo.accessType]
          );
          throw new Error(`not defined bandwidth by site info`);
        }
      } else {
        return { bandwidths: [] };
      }
    },
    /** 選択可能帯域一覧 */
    bandwidthOptions(): {
      value: number;
      unit: string;
      uniqueKey: string;
    }[] {
      const lineBandwidth = this.form.siteInfo?.lineBandwidth;
      if (lineBandwidth) {
        // 定義された帯域一覧から回線の帯域値以下のみにフィルター
        const maxValue = this.bandwidthInfo.bandwidths.filter((mbps) => {
          // true: 回線契約の帯域値以内, false: より大きい
          const inAccessLineLimit = ((): boolean => {
            if (
              this.form.siteInfo!.accessType === "EXTEND_ETHERNET" ||
              this.form.siteInfo!.accessType === "PF_ACCESS_GW"
            ) {
              // エクステンドイーサー、PFアクセスGWは回線の帯域値に依存しない
              return true;
            }

            if (
              this.form.siteInfo!.accessType === "PF_ACCESS_GW2" &&
              !this.form.siteInfo!.lineBandwidth!.isQosPath
            ) {
              // PFアクセスGWⅡでQoSパス無し（オーバサブ有り）は回線の帯域値に依存しない
              return true;
            }

            if (lineBandwidth.bandwidthType !== "BANDWIDTH_SECURED") {
              // 回線が帯域確保ではない場合は回線の帯域値に依存しない
              return true;
            }

            if (!lineBandwidth.bandwidth) {
              // 帯域値未設定
              return true;
            }

            if (!lineBandwidth.bandwidth.unit) {
              // 帯域値0
              return false;
            }

            switch (lineBandwidth.bandwidth.unit) {
              case "KBPS":
                return mbps <= lineBandwidth.bandwidth.value / 1000;
              case "MBPS":
                return mbps <= lineBandwidth.bandwidth.value;
              case "GBPS":
                return mbps <= lineBandwidth.bandwidth.value * 1000;
            }
          })();

          // true: 上限値の帯域値以内, false: より大きい
          const inUpperLimit = ((): boolean => {
            if (
              this.upperLimitList &&
              (this.form.siteInfo!.accessType === "PF_ACCESS_GW2" ||
                this.form.siteInfo!.accessType === "ETHERNET2" ||
                this.form.siteInfo!.accessType === "ETHERNET2_PF") &&
              (this.form.siteInfo!.lineBandwidth!.physicalInterface ===
                "IF_10G" ||
                this.form.siteInfo!.lineBandwidth!.physicalInterface ===
                  "IF_100G")
            ) {
              // PFアクセスGWⅡで物理IF(10G)またはイーサネット方式Ⅱ/イーサネット方式Ⅱ PFで物理IF(10G/100G)の場合は上限値設定(1G超VN)以下まで設定可能
              const upperLimit = this.upperLimitList!.find(
                (e) => e.upperLimitItemName === "1G超VNコネクト"
              )!.upperLimit;
              return mbps <= upperLimit * 1000;
            }
            return true;
          })();

          return inAccessLineLimit && inUpperLimit;
        });

        return maxValue.map((e) => {
          const _value = e < 1000 ? e : e / 1000;
          const _unit = e < 1000 ? "MBPS" : "GBPS";
          return {
            value: _value,
            unit: _unit,
            uniqueKey: _value + _unit,
          };
        });
      } else {
        return [];
      }
    },

    /** true: ルーティング種別を表示（接続先がL3VPNまたはL3であること）, false: 非表示 */
    isShowRoutingType(): boolean {
      if (this.form.vpnVnCodeInfo) {
        return this.form.vpnVnCodeInfo.vnType !== "L2";
      } else {
        return false;
      }
    },
    /** bandwidthのgetter,setter（マルチセレクトのtrack-byに必要なユニークキーを追加） */
    bandwidthUnique: {
      get(): (Bandwidth & { uniqueKey: string }) | null {
        return this.form.bandwidth
          ? {
              ...this.form.bandwidth,
              uniqueKey:
                this.form.bandwidth.value + (this.form.bandwidth.unit || ""),
            }
          : null;
      },
      set(newValue: Bandwidth & { uniqueKey: string }) {
        this.form.bandwidth = { value: newValue.value, unit: newValue.unit };
      },
    },
  },
  watch: {
    /** 親からのForm情報更新を反映 */
    value(newValue: VNConnectForm) {
      this.form = newValue;
    },
    /** 親へForm情報更新を通知 */
    form: {
      deep: true,
      handler(newValue: VNConnectForm) {
        this.$emit("input", newValue);
      },
    },
    /** サイトが変更された場合は初期化 */
    "form.siteInfo.siteId"() {
      // 新規の場合のみ。変更だとmountedの初期化の際にも実行されて困ったことになるので
      if (!this.isEdit) {
        // 選択肢が変更するものは初期化
        this.form.vpnVnCodeInfo = null;
        this.form.pairLine = null;
        this.form.bandwidth = null;
        if (this.form.siteInfo?.accessType === "IPSEC") {
          this.form.l3!.routingType = "STATIC";
        } else {
          this.form.l3!.routingType = "BGP4";

          // PFGWの場合はAS番号が固定になるので設定
          if (this.form.siteInfo?.accessType === "PLATFORM_GATEWAY") {
            this.form.l3!.bgp4!.asNumber = "64952";
          } else if (this.form.l3!.bgp4!.asNumber === "64952") {
            // "PLATFORM_GATEWAY"以外の場合、64952が入力不可なので、空白にする
            this.form.l3!.bgp4!.asNumber = "";
          }
        }

        // VLAN制御が表示できる場合はデフォルトON、出来ない場合はOFF
        this.form.l2!.isVlanControl =
          this.form.siteInfo?.lineBandwidth?.vlanControl === "ON" &&
          this.form.siteInfo?.vlanIdType === "UNTAG";
      }
    },
    /** 選択可能な品目が変化した場合に初期値をクリア */
    bandwidthTypes(newValue: BandwidthType[]) {
      const types = newValue.filter(
        // トラフィックフリーが非活性の場合は初期値の選択肢から除外
        (e) => !(e === "TRAFFICFREE" && this.disabledTrafficFree)
      );
      if (types[0]) {
        this.form.bandwidthType = types[0];
      }
    },
  },
  methods: {
    /** サイト名（選択済みラベル表示） */
    labelSite({ enumber, siteName, vlanIdType, vlanId }: SiteInfo) {
      return `${enumber}:${siteName} (${
        vlanIdType === "SPECIFIED"
          ? vlanId
          : this.$filter.enumTo(vlanIdType, "vlanIdType")
      })`;
    },
    /** 接続先仮想ネットワーク名（選択後、表示形式） */
    labelVpnVnCode({ vnName, vpnVnCode, vnType }: VpnVnCode) {
      return vnName ? `${vnName}:${vpnVnCode}(${vnType})` : vpnVnCode;
    },
    /** 帯域のラベル表示形式 */
    bandWidthLabel(option: Bandwidth) {
      return `${option.value}${option.unit === "GBPS" ? "Gbps" : "Mbps"}`;
    },
  },
});

type AvailableVpnVn = Record<
  "SPECIFIED" | "UNTAG" | "OTHER",
  | {
      vpnVn: "VPN" | "L3" | "L2";
      /** EQはサイトと同じID指定の場合のみ選択可 */
      vlan?: "SPECIFIED" | "UNTAG" | "OTHER" | "EQ";
    }[]
  | null
>;
const BasicOther: AvailableVpnVn["OTHER"] = [{ vpnVn: "L2", vlan: "OTHER" }];
/** (WVS2UI30-31)VN回線設定(STEP1新 - 特記事項5 + α */
const availableNetwork: Record<
  Type1PrivateLine["accessType"],
  | AvailableVpnVn
  | { is: (e: BandwidthListGetEntity) => boolean; data: AvailableVpnVn }[]
> = {
  ETHERNET_L2: {
    SPECIFIED: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "EQ" }],
    UNTAG: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "UNTAG" }],
    OTHER: BasicOther,
  },
  ETHERNET_L3: {
    SPECIFIED: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "EQ" }],
    UNTAG: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "UNTAG" }],
    OTHER: null,
  },
  BROADBAND_ACCESS2_L2: {
    SPECIFIED: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "EQ" }],
    UNTAG: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "UNTAG" }],
    OTHER: BasicOther,
  },
  BROADBAND_ACCESS2_L3: {
    SPECIFIED: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "EQ" }],
    UNTAG: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "UNTAG" }],
    OTHER: null,
  },
  WIRELESS_ACCESS_L2: {
    SPECIFIED: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "EQ" }],
    UNTAG: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "UNTAG" }],
    OTHER: BasicOther,
  },
  WIRELESS_ACCESS_L3: {
    SPECIFIED: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "EQ" }],
    UNTAG: [{ vpnVn: "L3" }, { vpnVn: "L2", vlan: "UNTAG" }],
    OTHER: null,
  },
  // リモートアクセス系は設定できない、そもそもサイト名で選択させない
  REMOTE_ACCESS_GW_CPA: [],
  REMOTE_ACCESS_GW_CRG: [],
  REMOTE_ACCESS_GW_FRE: [],
  EXTEND_ETHERNET: {
    SPECIFIED: [{ vpnVn: "VPN" }, { vpnVn: "L3" }, { vpnVn: "L2", vlan: "EQ" }],
    UNTAG: [{ vpnVn: "VPN" }, { vpnVn: "L3" }, { vpnVn: "L2", vlan: "UNTAG" }],
    OTHER: BasicOther,
  },
  IPSEC: {
    SPECIFIED: null,
    UNTAG: [{ vpnVn: "L3" }],
    OTHER: null,
  },
  PLATFORM_GATEWAY: {
    SPECIFIED: null,
    UNTAG: [{ vpnVn: "L3" }],
    OTHER: null,
  },
  PF_ACCESS_GW: {
    SPECIFIED: [{ vpnVn: "L3" }],
    UNTAG: null,
    OTHER: null,
  },
  PF_ACCESS_GW2: [
    {
      is: (e) => !e.isPfagw2L2,
      data: {
        SPECIFIED: [{ vpnVn: "VPN" }, { vpnVn: "L3" }],
        UNTAG: null,
        OTHER: null,
      },
    },
    {
      is: (e) => e.isPfagw2L2,
      data: {
        SPECIFIED: [{ vpnVn: "L2", vlan: "EQ" }],
        UNTAG: null,
        OTHER: null,
      },
    },
  ],
  ETHERNET2_PF: [
    {
      is: (e) =>
        e.physicalInterface === "IF_10G" || e.physicalInterface === "IF_100G",
      data: {
        SPECIFIED: [{ vpnVn: "VPN" }, { vpnVn: "L3" }],
        UNTAG: [{ vpnVn: "VPN" }, { vpnVn: "L3" }],
        OTHER: null,
      },
    },
    {
      is: (e) => e.vlanControl !== "ON",
      data: {
        SPECIFIED: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "EQ" },
        ],
        UNTAG: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "UNTAG" },
        ],
        OTHER: BasicOther,
      },
    },
    {
      is: (e) => e.vlanControl === "ON",
      data: {
        SPECIFIED: null,
        UNTAG: [{ vpnVn: "L2", vlan: "SPECIFIED" }],
        OTHER: null,
      },
    },
  ],
  ETHERNET2: [
    {
      is: (e) =>
        e.physicalInterface === "IF_10G" || e.physicalInterface === "IF_100G",
      data: {
        SPECIFIED: [{ vpnVn: "VPN" }, { vpnVn: "L3" }],
        UNTAG: [{ vpnVn: "VPN" }, { vpnVn: "L3" }],
        OTHER: null,
      },
    },
    {
      is: (e) => e.vlanControl !== "ON",
      data: {
        SPECIFIED: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "EQ" },
        ],
        UNTAG: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "UNTAG" },
        ],
        OTHER: BasicOther,
      },
    },
    {
      is: (e) => e.vlanControl === "ON",
      data: {
        SPECIFIED: null,
        UNTAG: [{ vpnVn: "L2", vlan: "SPECIFIED" }],
        OTHER: null,
      },
    },
  ],
  WIRELESS_ACCESS2: [
    {
      is: (e) => e.vlanControl !== "ON",
      data: {
        SPECIFIED: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "EQ" },
        ],
        UNTAG: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "UNTAG" },
        ],
        OTHER: BasicOther,
      },
    },
    {
      is: (e) => e.vlanControl === "ON",
      data: {
        SPECIFIED: null,
        UNTAG: [{ vpnVn: "L2", vlan: "SPECIFIED" }],
        OTHER: null,
      },
    },
  ],
  // リモートアクセス系は設定できない、そもそもサイト名で選択させない
  REMOTE_ACCESS_GW2_CPA: [],
  REMOTE_ACCESS_GW2_CRG: [],
  REMOTE_ACCESS_GW2_FRE: [],
  BROADBAND_ACCESS3: [
    {
      is: (e) => e.vlanControl !== "ON",
      data: {
        SPECIFIED: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "EQ" },
        ],
        UNTAG: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "UNTAG" },
        ],
        OTHER: BasicOther,
      },
    },
    {
      is: (e) => e.vlanControl === "ON",
      data: {
        SPECIFIED: null,
        UNTAG: [{ vpnVn: "L2", vlan: "SPECIFIED" }],
        OTHER: null,
      },
    },
  ],
  ETHERNET_LIGHT: [
    {
      is: (e) => e.vlanControl !== "ON",
      data: {
        SPECIFIED: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "EQ" },
        ],
        UNTAG: [
          { vpnVn: "VPN" },
          { vpnVn: "L3" },
          { vpnVn: "L2", vlan: "UNTAG" },
        ],
        OTHER: BasicOther,
      },
    },
    {
      is: (e) => e.vlanControl === "ON",
      data: {
        SPECIFIED: null,
        UNTAG: [{ vpnVn: "L2", vlan: "SPECIFIED" }],
        OTHER: null,
      },
    },
  ],
};

/** (WVS2UI30-31)VN回線設定(STEP1新 - ペア回線 表示・非表示条件 */
export const availablePairLine: Record<
  Type1PrivateLine["accessType"],
  boolean
> = {
  ETHERNET_L2: true,
  ETHERNET_L3: true,
  BROADBAND_ACCESS2_L2: true,
  BROADBAND_ACCESS2_L3: true,
  WIRELESS_ACCESS_L2: true,
  WIRELESS_ACCESS_L3: true,
  EXTEND_ETHERNET: true,
  IPSEC: true,
  // リモートアクセス系は設定できない、そもそもサイト名で選択させない
  REMOTE_ACCESS_GW_CPA: false,
  REMOTE_ACCESS_GW_CRG: false,
  REMOTE_ACCESS_GW_FRE: false,
  PLATFORM_GATEWAY: true,
  PF_ACCESS_GW: true,
  PF_ACCESS_GW2: false,
  ETHERNET2_PF: false,
  ETHERNET2: false,
  WIRELESS_ACCESS2: false,
  // リモートアクセス系は設定できない、そもそもサイト名で選択させない
  REMOTE_ACCESS_GW2_CPA: false,
  REMOTE_ACCESS_GW2_CRG: false,
  REMOTE_ACCESS_GW2_FRE: false,
  BROADBAND_ACCESS3: false,
  ETHERNET_LIGHT: false,
};

/** (WVS2UI30-31)VN回線設定(STEP1新 - 特記事項3 */
const availableBandwidthType: Record<
  Type1PrivateLine["accessType"],
  | {
      /** true: 対象の回線, false: 対象の回線ではない */
      is?: (e?: BandwidthListGetEntity) => boolean;
      /** 品目選択肢 */
      types: BandwidthType[];
    }[]
> = {
  ETHERNET_L2: [{ types: ["BURST"] }],
  ETHERNET_L3: [{ types: ["BURST"] }],
  BROADBAND_ACCESS2_L2: [{ types: ["BURST"] }],
  BROADBAND_ACCESS2_L3: [{ types: ["BURST"] }],
  WIRELESS_ACCESS_L2: [{ types: ["BURST"] }],
  WIRELESS_ACCESS_L3: [{ types: ["BURST"] }],
  EXTEND_ETHERNET: [{ types: ["BURST", "BANDWIDTH_SECURED"] }],
  IPSEC: [{ types: ["BURST"] }],
  // リモートアクセス系は設定できない、そもそもサイト名で選択させない
  REMOTE_ACCESS_GW_CPA: [],
  REMOTE_ACCESS_GW_CRG: [],
  REMOTE_ACCESS_GW_FRE: [],
  PLATFORM_GATEWAY: [{ types: ["BURST"] }],
  PF_ACCESS_GW: [{ types: ["BURST", "BANDWIDTH_SECURED"] }],
  PF_ACCESS_GW2: [{ types: ["PF", "BANDWIDTH_SECURED"] }],
  ETHERNET2_PF: [{ types: ["PF", "BANDWIDTH_SECURED"] }],
  ETHERNET2: [
    {
      is: (e) => e!.bandwidthType === "TRAFFICFREE",
      types: ["TRAFFICFREE", "BANDWIDTH_SECURED"],
    },
    {
      is: (e) => e!.bandwidthType === "BANDWIDTH_SECURED",
      types: ["BANDWIDTH_SECURED"],
    },
  ],
  WIRELESS_ACCESS2: [{ types: ["BESTEFFORT"] }],
  // リモートアクセス系は設定できない、そもそもサイト名で選択させない
  REMOTE_ACCESS_GW2_CPA: [],
  REMOTE_ACCESS_GW2_CRG: [],
  REMOTE_ACCESS_GW2_FRE: [],
  BROADBAND_ACCESS3: [{ types: ["BESTEFFORT"] }],
  ETHERNET_LIGHT: [{ types: ["BESTEFFORT"] }],
};

/** (WVS2UI30-31)VN回線設定(STEP1新 - 特記事項4 */
const M1_10 = Array.range(1, 11);
const M20_90 = Array.range(20, 91, 10);
const M20_100 = Array.range(20, 101, 10);
const M200_1000 = Array.range(200, 1001, 100);
const M2000_10000 = Array.range(2000, 10001, 1000);
const M20000_100000 = Array.range(20000, 100001, 10000);
const bandwidthList: Record<
  Type1PrivateLine["accessType"],
  {
    /** true: 対象の回線, false: 対象の回線ではない */
    is: (e?: BandwidthListGetEntity) => boolean;
    /** 選択可能帯域（Mbps）。ただし回線の帯域上限以上のものは除かれる */
    bandwidths: number[];
  }[]
> = {
  ETHERNET_L2: [],
  ETHERNET_L3: [],
  BROADBAND_ACCESS2_L2: [],
  BROADBAND_ACCESS2_L3: [],
  WIRELESS_ACCESS_L2: [],
  WIRELESS_ACCESS_L3: [],
  EXTEND_ETHERNET: [
    {
      is: (e) => e!.bandwidthType === "BURST10",
      bandwidths: [0.5, 1],
    },
    {
      is: (e) => e!.bandwidthType === "BURST100",
      bandwidths: [0.5, ...M1_10],
    },
    {
      is: (e) => e!.bandwidthType === "BANDWIDTH_SECURED",
      bandwidths: [0.5, ...M1_10, ...M20_100],
    },
  ],
  IPSEC: [],
  REMOTE_ACCESS_GW_CPA: [],
  REMOTE_ACCESS_GW_CRG: [],
  REMOTE_ACCESS_GW_FRE: [],
  PLATFORM_GATEWAY: [],
  PF_ACCESS_GW: [{ is: () => true, bandwidths: [0.5, ...M1_10, ...M20_100] }],
  PF_ACCESS_GW2: [
    {
      is: (e) => e!.physicalInterface === "IF_1G",
      bandwidths: [0.5, ...M1_10, ...M20_100, ...M200_1000],
    },
    {
      is: (e) => e!.physicalInterface === "IF_10G",
      bandwidths: [0.5, ...M1_10, ...M20_100, ...M200_1000, ...M2000_10000],
    },
  ],
  ETHERNET2_PF: [
    {
      is: (e) => e!.physicalInterface === "IF_100M",
      bandwidths: [0.5, ...M1_10, ...M20_100],
    },
    {
      is: (e) => e!.physicalInterface === "IF_1G",
      bandwidths: [0.5, ...M1_10, ...M20_100, ...M200_1000],
    },
    {
      is: (e) =>
        e!.physicalInterface === "IF_10G" && e!.bandwidthType !== "TRAFFICFREE",
      bandwidths: [0.5, ...M1_10, ...M20_100, ...M200_1000, ...M2000_10000],
    },
    {
      is: (e) =>
        e!.physicalInterface === "IF_100G" &&
        e!.bandwidthType !== "TRAFFICFREE",
      bandwidths: [
        0.5,
        ...M1_10,
        ...M20_100,
        ...M200_1000,
        ...M2000_10000,
        ...M20000_100000,
      ],
    },
    {
      is: (e) => e!.physicalInterface === "IF_10M" && e!.isUno,
      bandwidths: [0.5, ...M1_10],
    },
    {
      is: (e) => e!.physicalInterface === "IF_100M" && e!.isUno,
      bandwidths: [0.5, ...M1_10, ...M20_90],
    },
  ],
  ETHERNET2: [
    {
      is: (e) =>
        e!.physicalInterface === "IF_100M" &&
        !e!.isUno &&
        e!.bandwidthType === "TRAFFICFREE",
      bandwidths: [0.5, ...M1_10, ...M20_90],
    },
    {
      is: (e) =>
        e!.physicalInterface === "IF_100M" &&
        !e!.isUno &&
        e!.bandwidthType !== "TRAFFICFREE",
      bandwidths: [0.5, ...M1_10, ...M20_100],
    },
    {
      is: (e) =>
        e!.physicalInterface === "IF_1G" && e!.bandwidthType === "TRAFFICFREE",
      bandwidths: [0.5, ...M1_10, ...M20_100],
    },
    {
      is: (e) =>
        e!.physicalInterface === "IF_1G" && e!.bandwidthType !== "TRAFFICFREE",
      bandwidths: [0.5, ...M1_10, ...M20_100, ...M200_1000],
    },
    {
      is: (e) =>
        e!.physicalInterface === "IF_10G" && e!.bandwidthType !== "TRAFFICFREE",
      bandwidths: [0.5, ...M1_10, ...M20_100, ...M200_1000, ...M2000_10000],
    },
    {
      is: (e) =>
        e!.physicalInterface === "IF_100G" &&
        e!.bandwidthType !== "TRAFFICFREE",
      bandwidths: [
        0.5,
        ...M1_10,
        ...M20_100,
        ...M200_1000,
        ...M2000_10000,
        ...M20000_100000,
      ],
    },
    {
      is: (e) => e!.physicalInterface === "IF_10M" && e!.isUno,
      bandwidths: [0.5, ...M1_10],
    },
    {
      is: (e) => e!.physicalInterface === "IF_100M" && e!.isUno,
      bandwidths: [0.5, ...M1_10, ...M20_90],
    },
  ],
  WIRELESS_ACCESS2: [],
  REMOTE_ACCESS_GW2_CPA: [],
  REMOTE_ACCESS_GW2_CRG: [],
  REMOTE_ACCESS_GW2_FRE: [],
  BROADBAND_ACCESS3: [],
  ETHERNET_LIGHT: [],
};

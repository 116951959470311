import { ValidationRuleSchema } from "vee-validate/dist/types/types";
// eslint-disable-next-line no-restricted-imports
import get from "lodash/get";

/**
 * is_notルールと同じようなチェックが実施される
 * 異なる点は比較対象を値ではなくフィールドで指定すること
 * エラーメッセージも比較対象フィールド名が使用される
 * 比較対象がオブジェクト型の場合、オブジェクトパスを引数で渡してもらい、指定した値を判定する
 * @see https://vee-validate.logaretm.com/v3/guide/rules.html#is-not
 */
export default {
  params: [{ name: "target", isTarget: true }, "path"],
  validate(
    value: string | undefined | null,
    { target, path }: { target: unknown; path?: string }
  ): boolean {
    if (!value) {
      return true;
    }
    if (typeof target === "object" && path) {
      return value !== get(target, path);
    } else {
      return value !== target;
    }
  },
} as ValidationRuleSchema;

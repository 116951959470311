var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoaded)?_c('div',{staticClass:"row align-items-center justify-content-center bg-light",attrs:{"id":"login"}},[_c('b-card',[_c('b-card-body',[_c('b-alert',{attrs:{"variant":"warning"},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" ログインIDとパスワードの組み合わせが異なります。 ")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',[_c('validation-provider',{attrs:{"name":"ログインID","rules":{ required: true, max: 18 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{attrs:{"label":"ログインID"}},[_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.loginId),callback:function ($$v) {_vm.$set(_vm.form, "loginId", $$v)},expression:"form.loginId"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"name":"パスワード","rules":{
                required: true,
                between_length: { min: 8, max: 20 },
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{attrs:{"label":"パスワード"}},[_c('b-form-input',{attrs:{"type":"password","state":_vm._f("validState")(validContext)},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1),_c('div',[_c('router-link',{attrs:{"to":"/password/one-time-url","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var navigate = ref.navigate;
return [_c('span',{staticClass:"item-link",attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("パスワードの再発行はこちらへ")])]}}],null,true)})],1),_c('div',{staticClass:"mt-3 mr-0 row justify-content-end"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("ログイン")])],1)])]}}],null,false,2644733374)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }



















































































































































import Vue, { PropType } from "vue";
import { ApplianceType, LineElement, LineType } from "@/apis/TrafficApi";
import {
  LineInfoEntity,
  ThresholdEntity,
  ThresholdInfoGetEntity,
  ThresholdInfoUpdateEntity,
} from "@/apis/ThresholdSettingApi";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "NotificationModify",
  props: {
    /** サービス */
    lineType: {
      type: String as PropType<LineType>,
      required: true,
    },
    /** 回線
     * NATの場合、ここまで遷移できないので、除外する */
    line: {
      type: Object as PropType<
        LineElement & { applianceType: Exclude<ApplianceType, "NAT"> | null }
      >,
      required: true,
    },
    /** 閾値超過通知設定 */
    thresholdInfo: {
      type: Object as PropType<ThresholdInfoGetEntity>,
      required: true,
    },
  },
  data() {
    const cloneInfo = cloneDeep(this.thresholdInfo);
    const thresholdToNum = (threshold: ThresholdEntity): number => {
      switch (threshold.unit) {
        case "KBPS":
          return threshold.value / 1000;
        case "MBPS":
          return threshold.value;
        case "GBPS":
          return threshold.value * 1000;
        default:
          return 0;
      }
    };
    return {
      form: {
        ...cloneInfo,
        mailAddressList:
          cloneInfo.mailAddressList.length === 0
            ? [""]
            : cloneInfo.mailAddressList,
      } as Omit<ThresholdInfoUpdateEntity, "lineInfo">,
      formSendThreshold: thresholdToNum(cloneInfo.sendThreshold),
      formReceiveThreshold: thresholdToNum(cloneInfo.receiveThreshold),
    };
  },
  computed: {
    /** POSTリクエス用回線情報 */
    lineInfo(): LineInfoEntity {
      switch (this.lineType) {
        case "ACCESS":
          return {
            lineType: this.lineType,
            enumber: this.line.enumberMainAct,
            applianceType: this.line.applianceType,
          };
        case "INTERNET":
          return {
            lineType: this.lineType,
            enumber:
              this.line.internetType === "TYPE1"
                ? this.line.enumberMainAct
                : null,
            wnumber:
              this.line.internetType === "TYPE4"
                ? this.line.wnumberMainAct
                : null,
            internetType: this.line.internetType,
            type4Id:
              this.line.internetType === "TYPE4" ? this.line.type4Id : null,
            applianceType: this.line.applianceType,
          };
        case "VN":
        case "CLOUD":
        case "VNL2L3":
          return {
            lineType: this.lineType,
            wnumber: this.line.wnumberMainAct,
          };
        case "MULTICLOUD":
          return {
            lineType: this.lineType,
            enumber: this.line.wnumberMainAct ? null : this.line.enumberMainAct,
            wnumber: this.line.wnumberMainAct ? this.line.wnumberMainAct : null,
            cloudServiceMenu: this.line.wnumberMainAct
              ? null
              : this.line.cloudServiceMenu,
          };
        case "CPA_5GSA":
          return {
            lineType: this.lineType,
            wnumber: this.line.wnumberMainAct,
          };
        default:
          throw new Error(`unknown line type. ${this.lineType}`);
      }
    },
    /** true: メールアドレス非活性, false: 活性 */
    isDisabledEmail(): boolean {
      // 送信・受信の両方が0または未入力の場合は非活性
      return (
        (this.formReceiveThreshold === null ||
          this.formReceiveThreshold === 0) &&
        (this.formSendThreshold === null || this.formSendThreshold === 0)
      );
    },
  },
  methods: {
    async submit() {
      await this.$confirm(`通知する閾値と連絡先を設定します。よろしいですか？`);
      await this.$api.thresholdSetting.update({
        ...this.form,
        sendThreshold:
          this.formSendThreshold && this.formSendThreshold !== 0
            ? { value: this.formSendThreshold, unit: "MBPS" }
            : null,
        receiveThreshold:
          this.formReceiveThreshold && this.formReceiveThreshold !== 0
            ? { value: this.formReceiveThreshold, unit: "MBPS" }
            : null,
        lineInfo: this.lineInfo,
        mailAddressList: this.isDisabledEmail ? [] : this.form.mailAddressList,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    addEmail() {
      this.form.mailAddressList = [...this.form.mailAddressList, ""];
    },

    removeEmail(index: number) {
      if (this.form.mailAddressList.length > 1) {
        this.form.mailAddressList.splice(index, 1);
      } else {
        this.form.mailAddressList = [""];
      }
    },

    /** 重複チェック用メール一覧 */
    emailList(index: number) {
      return this.form.mailAddressList
        ? this.form.mailAddressList.filter((_, num) => num !== index)
        : [];
    },
  },
});










































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  Type4Service,
  Type4ServicePostRequest,
} from "@/apis/ContractChangeType4Api";
import InternetType4ContractRegisterConfirm from "@/modals/applianceContractSetting/InternetType4ContractRegisterConfirm.vue";
import DefaultPolicyOnewaySp from "@/components/applianceContractSetting/DefaultPolicyOnewaySp.vue";
import DefaultPolicyOnewayAny from "@/components/applianceContractSetting/DefaultPolicyOnewayAny.vue";
import DefaultPolicyInteractiveAny from "@/components/applianceContractSetting/DefaultPolicyInteractiveAny.vue";
import { ApplianceInfo } from "@/apis/ContractApi";

export default Vue.extend({
  name: "InternetType4ContractRegister",
  props: {
    type4Items: {
      type: Array as PropType<ApplianceInfo[]>,
      required: true,
    },
  },
  components: {
    DefaultPolicyInteractiveAny,
    DefaultPolicyOnewayAny,
    DefaultPolicyOnewaySp,
  },
  data() {
    return {
      /** 初期通信完了フラグ */
      isLoaded: false,
      type4Service: {} as Type4Service,
      postItem: {
        vpnVnCode: "",
        mainSite: "TOKYO",
        type4Name: "",
        applianceType: "UTM",
        policyType: "ALLOW_SPECIFIC_ONE_WAY",
        isMicrosoft365: false,
        maintenance: {
          company: "",
          department: "",
          name: "",
          phone: "",
          email: "",
        },
        note: null,
      } as Type4ServicePostRequest,
      isCollapsed_OnewaySp: false,
      isCollapsed_OnewayAny: false,
      isCollapsed_InteractiveAny: false,
      vpnVnList: [] as { value: string | null; text: string | null }[],
      selectedUnit: null as ApplianceInfo | null,
      maintenanceInfoErr: false,
    };
  },
  computed: {
    sortType4Items(): ApplianceInfo[] | null {
      return this.type4Items.sortBy(["vpnVnCode", "asc"]);
    },
  },
  watch: {
    /** 選択値変更時にエラーメッセージを非表示 */
    selectedUnit() {
      this.maintenanceInfoErr = false;
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.vpnVnList = (
        await this.$api.contract_change_type4.getType4VpnVnList()
      ).map((e) => ({
        value: e.vpnVnCode,
        text: e.vnName ? `${e.vpnVnCode} (${e.vnName})` : `${e.vpnVnCode}`,
      }));
    },
    /** 確認モーダルの表示 */
    async submit() {
      await this.$modal.show(InternetType4ContractRegisterConfirm, {
        vpnVnCode: this.vpnVnList.find(
          (e) => e.value === this.postItem.vpnVnCode
        )?.text,
        postItem: this.postItem,
      });
      /** null設定 */
      this.postItem.note = this.postItem.note || null;

      await this.$api.contract_change_type4.postType4Service(this.postItem);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    labelUnit({ vpnVnCode, type4Name, wnumberMainAct }: ApplianceInfo): string {
      return `${vpnVnCode} / ${type4Name} / ${wnumberMainAct}`;
    },
    async setProperty() {
      if (this.selectedUnit) {
        const setForm = (
          await this.$api.contract_change_type4.getType4Service(
            this.selectedUnit.type4Id!
          )
        ).maintenance;
        if (setForm) {
          this.maintenanceInfoErr = false;
          this.postItem.maintenance = setForm;
        } else {
          this.maintenanceInfoErr = true;
        }
      }
    },
  },
});














































































































































































































































































































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { ApplianceInfo } from "@/apis/ContractApi";

import { IpPool, IpPoolDelete } from "@/apis/IpPoolApi";
import IpPoolReference from "@/modals/applianceObject/IpPoolReference.vue";
import IpPoolEdit from "@/modals/applianceObject/IpPoolEdit.vue";

import ProfileInternetFwEdit from "@/modals/applianceObject/ProfileInternetFwEdit.vue";
import ProfileUrlFilteringEdit from "@/modals/applianceObject/ProfileUrlFilteringEdit.vue";
import ProfileInternetFwReference from "@/modals/applianceObject/ProfileInternetFwReference.vue";
import ProfileUrlFilteringReference from "@/modals/applianceObject/ProfileUrlFilteringReference.vue";
import { InternetFwProfile, UrlFilteringProfile } from "@/apis/ProfileApi";

import ServiceSettingReference from "@/modals/applianceObject/ServiceSettingReference.vue";
import ServiceSettingEdit from "@/modals/applianceObject/ServiceSettingEdit.vue";
import {
  CustomServiceDelete,
  CustomServiceInfo,
} from "@/apis/CustomServiceApi";
import enumTo from "@/filters/enum-to.filter";
import applianceTypeTo from "@/filters/appliance-type-to.filter";
import internetStatusTo from "@/filters/internet-status-to.filter";
import Type4IpMapping from "@/views/applianceObject/Type4IpMapping.vue";
import Type1IpMapping from "@/views/applianceObject/Type1IpMapping.vue";

/**
 * contractSeqは主キーではないため別途主キー相当のプロパティを用意
 * type1、type4、イントラネットで分類してtype4は複数あるのでtype4Idで区別する
 */
export type ApplianceInfoWithId = ApplianceInfo & { _id: string };

export default Vue.extend({
  name: "ApplianceObjectList",
  components: { Type1IpMapping, Type4IpMapping },
  data() {
    return {
      isLoaded: false,
      isLoadedTabArea: false,
      selectedItem: null as ApplianceInfoWithId | null | undefined,
      applianceObjectTable: {
        fields: applianceObjectListFields,
        items: [],
      } as Pick<AppTableData<ApplianceInfoWithId>, "fields" | "items">,
      ipPoolAlertMsg: "",
      ipPool: {
        fields: ipPoolListFields,
        items: [],
        selected: [],
        search: {
          ipPoolName: { type: "text", label: "名前" },
          globalIpAddress: { type: "text", label: "グローバルIP" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          ipPoolName: "like",
          globalIpAddress: "like",
        },
      } as AppTableData<IpPool>,
      ipPoolKeyId: "",
      internetFwTable: {
        fields: [
          { key: "profileInternetFwName", label: "名前", sortable: true },
          { key: "isPolicyInUse", label: "ポリシー使用有無", sortable: true },
        ],
        items: [],
        search: {
          profileInternetFwName: { type: "text", label: "名前" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: { profileInternetFwName: "like" },
      } as Omit<AppTableData<InternetFwProfile>, "selected">,
      profileInternetFwKeyId: "",
      urlFilteringTable: {
        fields: [
          { key: "profileUrlName", label: "名前", sortable: true },
          {
            key: "description",
            label: "備考",
            tdClass: "text-pre-wrap",
            sortable: true,
          },
          { key: "isPolicyInUse", label: "ポリシー使用有無", sortable: true },
        ],
        items: [],
        search: {
          profileUrlName: { type: "text", label: "名前" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: { profileUrlName: "like" },
      } as Omit<AppTableData<UrlFilteringProfile>, "selected">,
      profileUrlKeyId: "",
      serviceAlertMsg: "",
      customServiceKeyId: "",
      serviceSetting: {
        fields: serviceSettingListFields,
        items: [],
        selected: [],
        search: {
          serviceName: { type: "text", label: "名前" },
          portNumber: { type: "text", label: "Port" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          serviceName: "like",
          portNumber: "like",
        },
      } as AppTableData<CustomServiceInfo>,
      intraCustomServiceKeyId: "",
      tabIdx: 0,
      profileTabIdx: 0,
    };
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      // アプライアンス一覧の取得APIを呼び出し、データ取得を行う
      this.applianceObjectTable.items = (
        await this.$api.contract.getAppliances()
      ).applianceInfoList
        // イントラネットは別の画面
        .filter((e) => e.internetType !== "INTRANET")
        .map((e) => {
          // テーブルの主キー相当を生成
          switch (e.internetType) {
            case "TYPE1":
              return { ...e, _id: "type1" };
            case "TYPE4":
              return { ...e, _id: `type4_${e.type4Id}` };
            case "INTRANET":
              throw new Error("filtered INTRANET");
          }
        });
    },
    /** 一覧更新 */
    async reload() {
      await this.load();
      if (this.selectedItem) {
        const selectedRow = this.applianceObjectTable.items.find(
          (v) => v._id === this.selectedItem?._id
        );

        if (selectedRow) {
          await this.setSelectedItem(selectedRow);
        } else {
          this.isLoadedTabArea = false;
          this.selectedItem = null;
        }
      }
    },

    /** 選択した行のデータをセットする */
    async setSelectedItem(row: ApplianceInfoWithId) {
      this.selectedItem = row;
      // 別の契約が選択された際に、テーブルの検索状態が残らないようクリアする
      this.clearFilter();
      // 別の契約が選択された際に、アクティブなタブを初期の状態に戻す
      if (this.tabIdx !== 0) this.tabIdx = 0;
      if (this.profileTabIdx !== 0) this.profileTabIdx = 0;
      await this.callApis();
      await this.alertClear();
    },
    /** テーブルの検索状態のクリア */
    clearFilter() {
      this.ipPool.filter = {};
      this.internetFwTable.filter = {};
      this.urlFilteringTable.filter = {};
      this.serviceSetting.filter = {};
    },
    /** 選択した行の背景色を変更する処理 */
    rowHighlight(item: ApplianceInfoWithId, type: string) {
      if (!item || type !== "row") return;
      if (item._id === this.selectedItem?._id) return "table-success";
    },
    /** タブエリアの情報を取得する */
    async callApis(): Promise<void> {
      // 情報の再取得を行う際に一度タブエリアを非表示にする
      if (this.isLoadedTabArea) this.isLoadedTabArea = false;

      // IP Pool取得のAPIを呼び出す
      await this.getIpPoolList();

      // プロファイル取得APIを呼び出す
      await this.getProfileInternetFw();
      await this.getProfileUrlFiltering();

      // 個別サービス取得APIを呼び出す
      await this.getServiceSetting();
      this.isLoadedTabArea = true;
    },

    /** IP Poolのデータを取得する */
    async getIpPoolList(): Promise<void> {
      // 選択された行のインターネットタイプにより呼ぶAPIを変える
      if (this.selectedItem?.internetType === "TYPE1") {
        // IP Pool(Type1)のデータをAPIから取得する
        const res = await this.$api.ipPool.getIpPoolType1();
        this.ipPool.items = res.ipPoolList;
        this.ipPoolKeyId = res.ipPoolKeyId;
      } else if (this.selectedItem?.internetType === "TYPE4") {
        // IP Pool(Type4)のデータをAPIから取得する
        const res = await this.$api.ipPool.getIpPoolType4(
          this.selectedItem.type4Id
        );
        this.ipPool.items = res.ipPoolList;
        this.ipPoolKeyId = res.ipPoolKeyId;
      }

      // チェックボックスの状態はデータを再取得しても残るので、ここでクリアしておく
      if (this.ipPool.selected.length) this.ipPool.selected = [];
    },
    /**
     * IP Pool参照モーダルを表示する
     * @param row テーブルで選択された行データ
     */
    async showIpPoolReference(row: IpPool): Promise<void> {
      this.ipPoolAlertMsg = "";
      await this.$modal.show(IpPoolReference, {
        ipPool: row,
        ipPoolKeyId: this.ipPoolKeyId,
        type4Id: this.selectedItem!.type4Id,
      });
      // 各タブの情報を再取得する
      await this.callApis();
    },
    /**
     * IP Pool追加モーダルを表示する
     */
    async showIpPoolRegister(): Promise<void> {
      this.ipPoolAlertMsg = "";
      await this.$modal.show(IpPoolEdit, {
        ipPoolKeyId: this.ipPoolKeyId,
        type4Id: this.selectedItem!.type4Id,
      });
      // 各タブの情報を再取得する
      await this.callApis();
    },
    /**
     * IP Pool削除確認モーダルを表示する
     */
    async deleteIpPool(): Promise<void> {
      // IP Poolの削除が可能か判定を行う
      if (!this.canDeleteIpPool()) return;

      // 確認ダイアログの表示
      await this.$confirm(
        `IP Poolを${this.ipPool.selected.length}件削除します。よろしいですか？`
      );

      // リクエスト用データの作成
      const reqData: IpPoolDelete = {
        ipPoolList: this.ipPool.selected.map((e) => e.ipPoolSeq),
        note: null,
        ipPoolKeyId: this.ipPoolKeyId,
      };

      // IP Pool削除API呼び出す
      if (this.selectedItem?.internetType === "TYPE1") {
        await this.$api.ipPool.deleteIpPoolType1(reqData);
      } else if (this.selectedItem?.internetType === "TYPE4") {
        await this.$api.ipPool.deleteIpPoolType4(
          reqData,
          this.selectedItem!.type4Id
        );
      }
      // 各タブの情報を再取得する
      await this.callApis();
    },
    /** IP Poolが削除可能であるか判定を行う */
    canDeleteIpPool(): boolean {
      // チェックボックスが選択1つも選択されていない場合、アラート表示して処理終了
      if (this.ipPool.selected.length <= 0) {
        // アラートを表示する
        this.ipPoolAlertMsg = this.$filter.message("no_select_data");
        return false;
      } else {
        // アラートを非表示にする
        this.ipPoolAlertMsg = "";
      }
      return true;
    },

    /** プロファイル情報の取得(インターネットFW) */
    async getProfileInternetFw() {
      // 選択された行のインターネットタイプにより呼ぶAPIを変える
      if (this.selectedItem?.internetType === "TYPE1") {
        // プロファイルインターネットFW(Type1)のデータをAPIから取得する
        const res = await this.$api.profileApi.getType1Profile();
        this.internetFwTable.items = res.profileList;
        this.profileInternetFwKeyId = res.profileInternetFwKeyId;
      } else if (this.selectedItem?.internetType === "TYPE4") {
        // プロファイルインターネット(Type4)のデータをAPIから取得する
        const res = await this.$api.profileApi.getType4Profile(
          this.selectedItem.type4Id
        );
        this.internetFwTable.items = res.profileList;
        this.profileInternetFwKeyId = res.profileInternetFwKeyId;
      }
    },

    /** インターネットFWを追加 */
    async showInternetFwRegister() {
      await this.$modal.show(ProfileInternetFwEdit, {
        type4Id: this.selectedItem!.type4Id,
        profileInternetFwKeyId: this.profileInternetFwKeyId,
      });
      await this.callApis();
    },

    /** インターネットFWを参照 参照画面から編集画面へ遷移 削除も参照画面から */
    async showInternetFwReference(entity: InternetFwProfile) {
      await this.$modal.show(ProfileInternetFwReference, {
        profileInternetFwSeq: entity.profileInternetFwSeq,
        type4Id: this.selectedItem!.type4Id,
      });
      await this.callApis();
    },

    /** プロファイル情報の取得(URLフィルタリング) */
    async getProfileUrlFiltering() {
      // 選択された行のインターネットタイプにより呼ぶAPIを変える
      if (this.selectedItem?.internetType === "TYPE1") {
        // プロファイルURLフィルタリング(Type1)のデータをAPIから取得する
        const res = await this.$api.profileApi.getType1UrlProfile();
        this.urlFilteringTable.items = res.profileList;
        this.profileUrlKeyId = res.profileUrlKeyId;
      } else if (this.selectedItem?.internetType === "TYPE4") {
        // プロファイルURLフィルタリング(Type4)のデータをAPIから取得する
        const res = await this.$api.profileApi.getType4UrlProfile(
          this.selectedItem?.type4Id
        );
        this.urlFilteringTable.items = res.profileList;
        this.profileUrlKeyId = res.profileUrlKeyId;
      }
    },

    /** URLフィルタリングを追加 */
    async showUrlFilteringRegister() {
      await this.$modal.show(ProfileUrlFilteringEdit, {
        type4Id: this.selectedItem?.type4Id,
        profileUrlKeyId: this.profileUrlKeyId,
      });
      await this.callApis();
    },

    /** URLフィルタリングを編集 */
    async showUrlFilteringReference(entity: UrlFilteringProfile) {
      await this.$modal.show(ProfileUrlFilteringReference, {
        profileUrlSeq: entity.profileUrlSeq,
        type4Id: this.selectedItem?.type4Id,
      });
      await this.callApis();
    },

    /** 個別サービス一覧を取得する */
    async getServiceSetting(): Promise<void> {
      // 選択された行のインターネットタイプにより呼ぶAPIを変える
      if (this.selectedItem?.internetType === "TYPE1") {
        // サービス設定(Type1)のデータをAPIから取得する
        const res = await this.$api.customServiceApi.getType1CustomService();
        this.serviceSetting.items = res.customServiceList;
        this.customServiceKeyId = res.customServiceKeyId;
      } else if (this.selectedItem?.internetType === "TYPE4") {
        // サービス設定(Type4)のデータをAPIから取得する
        const res = await this.$api.customServiceApi.getType4CustomService(
          this.selectedItem.type4Id
        );
        this.serviceSetting.items = res.customServiceList;
        this.customServiceKeyId = res.customServiceKeyId;
      }

      // チェックボックスの状態はデータを再取得しても残るので、ここでクリアしておく
      if (this.serviceSetting.selected.length) {
        this.serviceSetting.selected = [];
      }
    },
    /**
     * サービス設定参照モーダルを表示する
     * @param row 選択した行のデータ
     */
    async showServiceSettingReference(row: CustomServiceInfo) {
      this.serviceAlertMsg = "";
      await this.$modal.show(ServiceSettingReference, {
        serviceSetting: row,
        customServiceKeyId: this.customServiceKeyId,
        type4Id: this.selectedItem!.type4Id,
      });
      // 各タブの情報を再取得する
      await this.callApis();
    },
    /**
     * サービス設定登録モーダルを表示する
     */
    async showServiceSettingRegister() {
      this.serviceAlertMsg = "";
      await this.$modal.show(ServiceSettingEdit, {
        customServiceKeyId: this.customServiceKeyId,
        type4Id: this.selectedItem!.type4Id,
      });
      // 各タブの情報を再取得する
      await this.callApis();
    },
    /**
     * サービス設定の一括削除処理
     */
    async deleteServiceSetting(): Promise<void> {
      // チェックボックスが選択1つも選択されていない場合、アラート表示して処理終了
      if (this.serviceSetting.selected.length <= 0) {
        // アラートを表示する
        this.serviceAlertMsg = this.$filter.message("no_select_data");
        return;
      } else {
        // アラートを非表示にする
        this.serviceAlertMsg = "";
      }

      // 削除確認ダイアログを削除件数とともに表示する
      await this.$confirm(
        `個別サービスを${this.serviceSetting.selected.length}件削除します。よろしいですか？`
      );

      // リクエスト用データの作成
      const reqData: CustomServiceDelete = {
        customServiceSeqList: this.serviceSetting.selected.map(
          (e) => e.customServiceSeq
        ),
        customServiceKeyId: this.customServiceKeyId,
      };

      // 個別サービス削除API呼び出す
      if (this.selectedItem?.internetType === "TYPE1") {
        await this.$api.customServiceApi.deleteType1CustomService(reqData);
      } else if (this.selectedItem?.internetType === "TYPE4") {
        await this.$api.customServiceApi.deleteType4CustomService(
          this.selectedItem.type4Id,
          reqData
        );
      }
      // 各タブの情報を再取得する
      await this.callApis();
    },

    /** エラーメッセージを初期化する */
    async alertClear() {
      (this.$refs.ipMap as Vue & { alertClear: () => void }).alertClear();
      this.ipPoolAlertMsg = "";
      this.serviceAlertMsg = "";
    },
  },
});

/** 列のデータ(アプライアンスオブジェクト一覧) */
const applianceObjectListFields = [
  {
    key: "lineNumber",
    label: "回線番号",
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: false,
    formatter: (value: null, key: null, item: ApplianceInfo) => {
      return item.internetType === "TYPE1"
        ? item.enumberMainAct + " " + item.enumberMainSby
        : item.internetType === "TYPE4"
        ? item.wnumberMainAct + " " + item.wnumberMainSby
        : null;
    },
  },
  { key: "vpnVnCode", label: "VPN/VNコード", sortable: true },
  {
    key: "internetType",
    label: "Type",
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: false,
    formatter: (value: string) => {
      return enumTo(value, "internetType");
    },
  },
  {
    key: "type4Id",
    label: "Type4ID",
    sortable: true,
  },
  {
    key: "type4Name",
    label: "Type4名",
    sortable: true,
  },
  {
    key: "mainSite",
    label: "メインサイト",
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: false,
    formatter: (value: string) => {
      return value ? enumTo(value, "mainSite") : null;
    },
  },
  {
    key: "appliance",
    label: "アプライアンス",
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: false,
    formatter: (value: null, key: null, item: ApplianceInfo) => {
      return applianceTypeTo(item.applianceType, item.bandwidth);
    },
  },
  {
    key: "status",
    label: "状態",
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: false,
    formatter: (value: null, key: null, item: ApplianceInfo) => {
      return internetStatusTo(item.isStart, item.isInternetPause) === "-"
        ? null
        : internetStatusTo(item.isStart, item.isInternetPause);
    },
  },
];

/** 列のデータ(IP Pool) */
const ipPoolListFields = [
  { key: "ipPoolName", label: "名前", sortable: true },
  { key: "globalIpAddress", label: "グローバルIP", sortable: true },
  {
    key: "description",
    label: "備考",
    tdClass: "text-pre-wrap",
    sortable: true,
  },
  { key: "isPolicyInUse", label: "ポリシー使用有無", sortable: true },
];

/** 列のデータ(個別サービス) */
const serviceSettingListFields = [
  { key: "serviceName", label: "名前", sortable: true },
  { key: "protocol", label: "プロトコル", sortable: true },
  { key: "portNumber", label: "Port", sortable: true },
  {
    key: "description",
    label: "備考",
    tdClass: "text-pre-wrap",
    sortable: true,
  },
  { key: "isPolicyInUse", label: "ポリシー使用有無", sortable: true },
];

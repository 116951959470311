




























































import Vue, { PropType } from "vue";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import { Type1DestinationNatPolicy } from "@/modals/applianceContractSetting/InternetType1PolicyRuleDestNatViewRD.vue";

export default Vue.extend({
  name: "InternetType1PolicyRuleDestNatConfirm",
  components: { PolicySrcAddress },
  props: {
    policy: {
      type: Object as PropType<Type1DestinationNatPolicy>,
      required: true,
    },
  },
});

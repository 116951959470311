










































































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  BandWidth,
  RouteAggregation,
  VpnVnCode as CloudVpnVnCode,
} from "@/apis/CloudVnMsApi";
import { CloudLine } from "@/apis/CloudLineApi";
import cloneDeep from "lodash/cloneDeep";
import {
  CloudVnAwsCommon,
  CloudVnAwsActSby,
  CloudVnAwsDetail,
  CloudVnAwsPost,
  CloudVnAwsPut,
} from "@/apis/CloudVnAwsApi";
import { VpnVnCode } from "@/apis/InformationApi";
import MultiCloudAwsVnConnectionConfirm from "@/modals/multicloud/aws/MultiCloudAwsVnConnectionConfirm.vue";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";

export default Vue.extend({
  name: "MultiCloudAwsVnLineEdit",
  props: {
    /** クラウド回線SEQ */
    cloudLineSeq: {
      type: String as PropType<string>,
      required: true,
    },
    // 変更の場合用
    cloudVnAwsDetail: {
      type: Object as PropType<CloudVnAwsDetail>,
      default: undefined,
    },
    // 追加の場合用
    cloudVnAwsKeyId: {
      type: String as PropType<string>,
      default: undefined,
    },
  },
  data() {
    // 新規/更新のformデータを作成
    const form:
      | (CloudVnAwsPost & {
          bandItem: string | null;
        })
      | (CloudVnAwsPut & {
          cloudVnConnectCommon: Pick<
            CloudVnAwsCommon,
            "directConnectType" | "location"
          >;
          bandItem: string | null;
        }) = (() => {
      if (this.cloudVnAwsDetail) {
        // 変更
        const cloudVnAwsDetailCopy = cloneDeep(this.cloudVnAwsDetail);
        const form: CloudVnAwsPut & {
          cloudVnConnectCommon: Pick<
            CloudVnAwsCommon,
            "directConnectType" | "location"
          >;
          bandItem: string | null;
        } = {
          cloudVnConnectCommon: cloudVnAwsDetailCopy.cloudVnConnectCommon,
          cloudVnConnectAct: cloudVnAwsDetailCopy.cloudVnConnectAct,
          cloudVnConnectSby: cloudVnAwsDetailCopy.cloudVnConnectSby
            ? cloudVnAwsDetailCopy.cloudVnConnectSby
            : ({
                maximumPrefix: 100,
              } as CloudVnAwsPut["cloudVnConnectSby"]),
          cloudVnAwsKeyId: cloudVnAwsDetailCopy.cloudVnAwsKeyId,
          // 変更の場合、名前でラベルを表示するので、初期値をブランクにする
          bandItem: null,
        };
        return form;
      } else {
        // 追加
        const form: CloudVnAwsPost & {
          bandItem: string | null;
        } = {
          cloudVnConnectCommon: {
            bandwidthType: "PF",
            routeAggregation: "OFF",
            routeAggregationAddressList: [""],
            location: "EAST_JAPAN",
            directConnectType: "HOSTED_VIFS",
          } as CloudVnAwsPost["cloudVnConnectCommon"],
          cloudVnConnectAct: {
            maximumPrefix: 100,
          } as CloudVnAwsPost["cloudVnConnectAct"],
          cloudVnConnectSby: {
            maximumPrefix: 100,
          } as CloudVnAwsPost["cloudVnConnectSby"],
          cloudVnAwsKeyId: this.cloudVnAwsKeyId,
          // 追加の場合、初期値を「低帯域」にする
          bandItem: "LOW",
        };
        return form;
      }
    })();

    return {
      form: form,
      isLoaded: false,
      vpnVnCodeList: [] as VpnVnCode[],
      cloudLineMaxBandwidth: {} as CloudLine["bandwidth"],
      selectedVpnVn: null as null | { value: CloudVpnVnCode; text: string },
      bandwidthOptions: [
        { text: "10Mbps", value: { value: 10, unit: "MBPS" } },
        { text: "20Mbps", value: { value: 20, unit: "MBPS" } },
        { text: "30Mbps", value: { value: 30, unit: "MBPS" } },
        { text: "40Mbps", value: { value: 40, unit: "MBPS" } },
        { text: "50Mbps", value: { value: 50, unit: "MBPS" } },
        { text: "60Mbps", value: { value: 60, unit: "MBPS" } },
        { text: "70Mbps", value: { value: 70, unit: "MBPS" } },
        { text: "80Mbps", value: { value: 80, unit: "MBPS" } },
        { text: "90Mbps", value: { value: 90, unit: "MBPS" } },
        { text: "100Mbps", value: { value: 100, unit: "MBPS" } },
        { text: "200Mbps", value: { value: 200, unit: "MBPS" } },
        { text: "300Mbps", value: { value: 300, unit: "MBPS" } },
        { text: "400Mbps", value: { value: 400, unit: "MBPS" } },
        { text: "500Mbps", value: { value: 500, unit: "MBPS" } },
        { text: "600Mbps", value: { value: 600, unit: "MBPS" } },
        { text: "700Mbps", value: { value: 700, unit: "MBPS" } },
        { text: "800Mbps", value: { value: 800, unit: "MBPS" } },
        { text: "900Mbps", value: { value: 900, unit: "MBPS" } },
        { text: "1Gbps", value: { value: 1, unit: "GBPS" } },
        { text: "2Gbps", value: { value: 2, unit: "GBPS" } },
        { text: "3Gbps", value: { value: 3, unit: "GBPS" } },
        { text: "4Gbps", value: { value: 4, unit: "GBPS" } },
        { text: "5Gbps", value: { value: 5, unit: "GBPS" } },
        { text: "6Gbps", value: { value: 6, unit: "GBPS" } },
        { text: "7Gbps", value: { value: 7, unit: "GBPS" } },
        { text: "8Gbps", value: { value: 8, unit: "GBPS" } },
        { text: "9Gbps", value: { value: 9, unit: "GBPS" } },
        { text: "10Gbps", value: { value: 10, unit: "GBPS" } },
      ] as { text: string; value: BandWidth }[],
      routeOptions: [
        { value: "ON", text: "ON" },
        { value: "OFF", text: "OFF" },
        { value: "CUSTOM", text: "CUSTOM" },
      ],
      locationOptions: [
        { value: "EAST_JAPAN", text: "東日本" },
        { value: "WEST_JAPAN", text: "西日本" },
      ],
      directConnectOptions: [
        { value: "HOSTED_VIFS", text: "ホスト型VIF(Private)" },
        { value: "HOSTED_CONNECTIONS", text: "ホスト接続" },
      ],
      /** Maximum Prefix上限値(変更の場合用) */
      maximumPrefixLimit: null as UpperLimitEntity | null,
      /** 1G超VNコネクト */
      vnConnects1G: null as UpperLimitEntity | undefined | null,
      /** 東日本1G超VNコネクト(ホスト型VIF) */
      eastVnConnects1G: null as UpperLimitEntity | undefined | null,
      /** 西日本1G超VNコネクト(ホスト型VIF) */
      westVnConnects1G: null as UpperLimitEntity | undefined | null,
      /** 1G超VNコネクト(ホスト型接続) */
      vnConnects1GHost: null as UpperLimitEntity | undefined | null,
      /** VNコネクト(ホスト型接続) */
      vnConnectsHost: null as UpperLimitEntity | undefined | null,
      /** AWSホスト型接続が利用可能か  */
      availableStatus: false,
    };
  },
  computed: {
    /** VNコネクト(Act)名編集可否 */
    isVnConnectNameActEnable() {
      if (this.isAdd) {
        return true;
      }
      return !this.cloudVnAwsDetail!.cloudVnConnectAct.isVnConnectStatus;
    },
    /** VNコネクト(Sby)名編集可否 */
    isVnConnectNameSbyEnable() {
      // 追加あるいはSby自体が存在しない場合
      if (this.isAdd || !this.cloudVnAwsDetail!.cloudVnConnectSby) {
        return true;
      }
      return !this.cloudVnAwsDetail.cloudVnConnectSby.isVnConnectStatus;
    },

    /** 相関チェック
     * false: Sbyの登録とAct、Commonの編集は同時に行う場合 */
    multipleOption(): boolean {
      if (
        this.isAdd ||
        this.cloudVnAwsDetail.cloudVnConnectSby ||
        !this.form.cloudVnConnectSby?.vnConnectName
      ) {
        return true;
      }
      let changed = this.$crossValidation.objectChanged(
        this.cloudVnAwsDetail.cloudVnConnectCommon,
        this.form.cloudVnConnectCommon!
      );
      if (!changed) {
        changed = this.$crossValidation.objectChanged(
          this.cloudVnAwsDetail.cloudVnConnectAct,
          this.form.cloudVnConnectAct!
        );
      }
      return !changed;
    },
    currentLength(): number {
      return this.form.cloudVnConnectCommon!.routeAggregationAddressList
        ? this.form.cloudVnConnectCommon!.routeAggregationAddressList.length
        : 0;
    },
    /** true: 新規, false: 変更 */
    isAdd(): boolean {
      return !this.cloudVnAwsDetail;
    },
    /** 品目(帯域)ラジオボタン表示 */
    isBandwidthItemShow(): boolean {
      // 「接続サービス」=「ホスト型VIF(Private)」、「ロケーション」=「東日本」、「東日本1G超VNコネクト(ホスト型VIF)」が存在
      // 「東日本1G超VNコネクト(ホスト型VIF)」が存在しない場合、「低帯域/広帯域」を表示しないように
      return (
        this.form.cloudVnConnectCommon!.directConnectType === "HOSTED_VIFS" &&
        this.form.cloudVnConnectCommon!.location === "EAST_JAPAN" &&
        this.eastVnConnects1G != null
      );
    },
    /** 品目(帯域)の「広帯域」選択肢の非活性化 */
    highBandwidthDisable(): boolean {
      // 「東日本1G超VNコネクト(ホスト型VIF)」が存在して、かつ、上限値が1Gbps以下の場合
      const bandwitdhBps =
        this.$filter.bandwidthToBps({
          value: this.eastVnConnects1G ? this.eastVnConnects1G.upperLimit : 10,
          unit: "GBPS",
        }) ?? 0;
      // クラウド帯域値
      const limit1 = this.$filter.bandwidthToBps(this.cloudLineMaxBandwidth);
      return bandwitdhBps <= 1000000000 || limit1! <= 1000000000;
    },
    /** 品目(帯域)ラベル表示名 */
    bandItemName(): string | null {
      if (!this.isAdd && this.isBandwidthItemShow) {
        const bandwidth =
          this.$filter.bandwidthToBps(
            this.form.cloudVnConnectCommon!.bandwidth
          ) ?? 0;
        // 1Gbps以下の場合、「低帯域」ラベルで表示する
        if (bandwidth <= 1000000000) {
          return "低帯域";
        } else {
          // 上記以外の場合、「広帯域」ラベルで表示する
          return "広帯域";
        }
      }
      return null;
    },
    vpnVnCodeSelectList(): {
      value: string;
      text: string;
    }[] {
      return this.vpnVnCodeList.map((v) => {
        if (v.vnName) {
          return {
            value: v.vpnVnCode,
            text: v.vnName + ":" + v.vpnVnCode + "(L3)",
          };
        } else {
          return { value: v.vpnVnCode, text: v.vpnVnCode };
        }
      });
    },
    /** 接続サービスの選択肢 */
    directConnectTypeSelectList(): {
      text: string;
      value: string;
    }[] {
      // クラウド帯域値
      const limit1 = this.$filter.bandwidthToBps(this.cloudLineMaxBandwidth);
      // 50Mbps未満の場合（50Mbpsは含まない）
      if (limit1! < 50000000) {
        // 「ホスト型VIF(Private)」しか表示されない
        return this.directConnectOptions.filter(
          (v) => v.value === "HOSTED_VIFS"
        );
      }
      return this.directConnectOptions;
    },
    /** 品目の選択肢 */
    bandwidthSelectList(): { text: string; value: BandWidth }[] {
      // クラウド帯域値
      const limit1 = this.$filter.bandwidthToBps(this.cloudLineMaxBandwidth);

      // 設定上限値
      let limit2_value = 0;
      // ホスト型VIF(Private)
      if (this.form.cloudVnConnectCommon?.directConnectType === "HOSTED_VIFS") {
        // 「1G超VNコネクト」
        //  存在しない場合、制限なしで、default値を1Gbpsにする
        limit2_value = this.vnConnects1G ? this.vnConnects1G.upperLimit : 1;
        if (
          this.form.cloudVnConnectCommon?.location === "EAST_JAPAN" &&
          this.eastVnConnects1G
        ) {
          // 東日本 かつ 「東日本1G超VNコネクト(ホスト型VIF)」が存在する場合
          limit2_value = this.eastVnConnects1G!.upperLimit;
        } else if (
          this.form.cloudVnConnectCommon?.location === "WEST_JAPAN" &&
          this.westVnConnects1G
        ) {
          // 西日本 かつ 「西日本1G超VNコネクト(ホスト型VIF)」が存在する場合
          limit2_value = this.westVnConnects1G!.upperLimit;
        }
      } else {
        // 「ホスト型接続」の場合
        // 「1G超VNコネクト(ホスト型接続)」
        limit2_value = this.vnConnects1GHost
          ? this.vnConnects1GHost.upperLimit
          : 1;
        if (this.vnConnectsHost) {
          // 「VNコネクト(ホスト型接続)」が存在する場合、「VNコネクト(ホスト型接続)」にする
          limit2_value = this.vnConnectsHost!.upperLimit;
        }
      }

      // 帯域ObjectをBPS単位に変換する
      const limit2 = this.$filter.bandwidthToBps({
        value: limit2_value,
        unit:
          this.form.cloudVnConnectCommon?.directConnectType === "HOSTED_VIFS"
            ? "GBPS"
            : this.vnConnects1GHost
            ? "GBPS"
            : "MBPS",
      });

      // 品目(帯域)ラジオボタンに「低帯域」選択場合の上限値：1G
      let limit3 = 10000000000;
      // 広帯域の最小帯域
      let upper = 0;
      // 品目(帯域)ラジオボタンが表示される場合、選択肢より、品目プルダウン表示内容も変わる
      if (this.isBandwidthItemShow) {
        if (this.form.bandItem === "LOW" || this.bandItemName === "低帯域") {
          // 1Gbps
          limit3 = 1000000000;
        } else {
          // 2Gbps
          upper = 2000000000;
        }
      }

      // 選択肢内容を処理する
      const bandwidthOptions = this.bandwidthOptions.filter((v) => {
        const bps = this.$filter.bandwidthToBps(v.value)!;
        return (
          bps <= limit1! && bps <= limit2! && bps <= limit3! && bps >= upper
        );
      });

      return this.form.cloudVnConnectCommon!.directConnectType === "HOSTED_VIFS"
        ? bandwidthOptions
        : bandwidthOptions.filter(
            (v) =>
              (v.value.unit === "MBPS" &&
                [50, 100, 200, 300, 400, 500].includes(v.value.value)) ||
              (v.value.unit === "GBPS" && [1, 2, 5, 10].includes(v.value.value))
          );
    },
    /** Maximum Prefixの選択肢 */
    maximumPrefixSelectList(): { text: string; value: number }[] {
      if (this.isAdd) {
        return [{ value: 100, text: "100" }];
      } else {
        return [
          { value: 100, text: "100" },
          { value: 200, text: "200" },
          { value: 300, text: "300" },
          { value: 400, text: "400" },
          { value: 500, text: "500" },
          { value: 1000, text: "1000" },
          { value: 1500, text: "1500" },
          { value: 2000, text: "2000" },
        ].filter((v) => v.value <= this.maximumPrefixLimit!.upperLimit);
      }
    },

    vpnVnName(): string {
      if (
        this.cloudVnAwsDetail.cloudVnConnectCommon.vpnVnCode.startsWith("D")
      ) {
        return (
          this.cloudVnAwsDetail.cloudVnConnectCommon.vnName +
          ":" +
          this.cloudVnAwsDetail.cloudVnConnectCommon.vpnVnCode +
          "(L3)"
        );
      } else {
        return this.cloudVnAwsDetail.cloudVnConnectCommon.vpnVnCode;
      }
    },
    /** true: Sbyに関する項目は非活性にする */
    isDisableRoutingSby(): boolean {
      return !(
        (this.cloudVnAwsDetail && this.cloudVnAwsDetail.cloudVnConnectSby) ||
        this.form.cloudVnConnectSby?.vnConnectName
      );
    },
    /** true: Sbyに関する項目はValidation必要 */
    isRequireRoutingSby(): boolean {
      // Sby情報はdisabledの場合、false
      return !this.isDisableRoutingSby;
    },
    /** true:回線詳細にSby情報が登録済みの場合 */
    hasSby(): boolean {
      return !!(
        this.cloudVnAwsDetail && this.cloudVnAwsDetail.cloudVnConnectSby
      );
    },
    /** 経路集約アドレスの重複チェック用リスト作成 */
    routingAddressList() {
      return (index: number) => {
        return this.form.cloudVnConnectCommon!.routeAggregationAddressList
          ? this.form.cloudVnConnectCommon!.routeAggregationAddressList.filter(
              (_, num) => num !== index
            )
          : [];
      };
    },
    /** 変更モード、ホスト型接続の判断. */
    isHostedConnections(): boolean {
      return (
        !this.isAdd &&
        this.form.cloudVnConnectCommon!.directConnectType ===
          `HOSTED_CONNECTIONS`
      );
    },
    /** 接続サービスによって品目で表示するinfo文言を変更 */
    itemMessage() {
      switch (this.form.cloudVnConnectCommon!.directConnectType) {
        case "HOSTED_VIFS":
          return `・品目は「低帯域（10Mbps～1Gbps）」または「広帯域（2Gbps～10Gbps）」からご選択ください。
          ・低帯域⇔広帯域の変更はできません。既存VNコネクトの削除後に新規作成の手続きが必要です。`;
        case "HOSTED_CONNECTIONS":
          return "品目の変更は出来ません。既存VNコネクトの削除後に新規作成の手続きが必要です。";
        default:
          return "";
      }
    },
  },
  watch: {
    /** 経路集約がCUSTOMに変更されて初期値が存在しない場合は初期化 */
    "form.cloudVnConnectCommon.routeAggregation"(newValue: RouteAggregation) {
      if (
        newValue === "CUSTOM" &&
        (!this.form.cloudVnConnectCommon!.routeAggregationAddressList ||
          this.form.cloudVnConnectCommon!.routeAggregationAddressList.length ===
            0)
      ) {
        this.form.cloudVnConnectCommon!.routeAggregationAddressList = [""];
      }
    },
    /** 接続サービスの値が変更された場合、品目の値を初期化 */
    "form.cloudVnConnectCommon.directConnectType"() {
      if (this.form.cloudVnConnectCommon!.bandwidth) {
        this.form.cloudVnConnectCommon!.bandwidth = { value: 0, unit: "MBPS" };
      }
    },
    /** 帯域が変更されましたら、既存値をクリアする */
    "form.bandItem"() {
      if (this.form.cloudVnConnectCommon!.bandwidth) {
        this.form.cloudVnConnectCommon!.bandwidth = { value: 0, unit: "MBPS" };
      }
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      // 回線情報に紐づくクラウド回線情報を取得APIを呼び出す
      const lines = (await this.$api.cloudLine.getCloudLines()).lineList;
      const cloudLines = lines.flatMap((v) => v.cloudLineList);
      // クラウド回線の帯域最大値(品目の上限値制御用)
      this.cloudLineMaxBandwidth = cloudLines.find(
        (v) => v.cloudLineSeq === this.cloudLineSeq
      )!.bandwidth;

      // 1G超VNコネクトの値を取得（品目の上限値制御用）
      const getVnConnects1G = await this.$api.upperLimit.getUpperLimitLine({
        upperLimitLineManageUnit: "MULTI_CLOUD",
        cloudLineSeq: this.cloudLineSeq,
      });
      this.vnConnects1G = getVnConnects1G.cloudLine!.upperLimitList!.find(
        (v) => v.upperLimitItemName === "1G超VNコネクト"
      );
      this.eastVnConnects1G = getVnConnects1G.cloudLine!.upperLimitList!.find(
        (v) => v.upperLimitItemName === "東日本1G超VNコネクト(ホスト型VIF)"
      );
      this.westVnConnects1G = getVnConnects1G.cloudLine!.upperLimitList!.find(
        (v) => v.upperLimitItemName === "西日本1G超VNコネクト(ホスト型VIF)"
      );
      this.vnConnects1GHost = getVnConnects1G.cloudLine!.upperLimitList!.find(
        (v) => v.upperLimitItemName === "1G超VNコネクト(ホスト型接続)"
      );
      this.vnConnectsHost = getVnConnects1G.cloudLine!.upperLimitList!.find(
        (v) => v.upperLimitItemName === "VNコネクト(ホスト型接続)"
      );

      // 設定上限値情報のチェック
      if (
        (!this.vnConnects1GHost && !this.vnConnectsHost) ||
        (this.vnConnects1GHost && this.vnConnectsHost)
      ) {
        // 「1G超VNコネクト(ホスト型接続)」「VNコネクト(ホスト型接続)」が両方存在しない又は両方存在する：システムエラー
        throw new Error(
          "Only one of the upper bounds must exist.. Items:1G超VNコネクト(ホスト型接続),VNコネクト(ホスト型接続)"
        );
      } else if (
        (!this.vnConnects1G && !this.eastVnConnects1G) ||
        (this.vnConnects1G && this.eastVnConnects1G)
      ) {
        // 「1G超VNコネクト」「東日本1G超VNコネクト(ホスト型VIF)」が両方存在しない又は両方存在する：システムエラー
        throw new Error(
          "Only one of the upper bounds must exist.. Items: 1G超VNコネクト,東日本1G超VNコネクト(ホスト型VIF)"
        );
      } else if (
        (!this.vnConnects1G && !this.westVnConnects1G) ||
        (this.vnConnects1G && this.westVnConnects1G)
      ) {
        // 「1G超VNコネクト」「西日本1G超VNコネクト(ホスト型VIF)」が両方存在しない又は両方存在する：システムエラー
        throw new Error(
          "Only one of the upper bounds must exist.. Items: 1G超VNコネクト,西日本1G超VNコネクト(ホスト型VIF)"
        );
      }

      if (this.isAdd) {
        this.vpnVnCodeList = (await this.$api.information.getVpnVnCodeList())
          .filter((v) => v.vnType === "L3" || v.vnType === null)
          .sortBy(["vpnVnCode", "asc"]);
        // 追加の場合、AWSホスト型接続が利用可能かを取得
        this.availableStatus = (
          await this.$api.cloudVnAws.getAwsHostConnectAvailable()
        ).availableStatus;
      } else {
        // maximumPrefixの上限値を取得
        this.maximumPrefixLimit = (
          await this.$api.upperLimit.getUpperLimitLine({
            upperLimitLineManageUnit: "MULTI_CLOUD",
            cloudLineSeq: this.cloudLineSeq,
            wnumber: this.cloudVnAwsDetail!.cloudVnConnectAct.wnumber,
          })
        ).cloudLine!.upperLimitList!.find(
          (v) => v.upperLimitItemName === "BGP4の最大経路数"
        )!;
      }
    },

    /** 経路集約アドレス削除 */
    removeRouteAddress(index: number) {
      const list = this.form.cloudVnConnectCommon!.routeAggregationAddressList!;
      if (list.length > 1) {
        list.splice(index, 1);
      } else {
        this.form.cloudVnConnectCommon!.routeAggregationAddressList = [""];
      }
    },

    /** 経路集約アドレス追加 */
    addRouteAddress() {
      this.form.cloudVnConnectCommon!.routeAggregationAddressList = [
        ...this.form.cloudVnConnectCommon!.routeAggregationAddressList!,
        "",
      ];
    },
    async submit() {
      const form = {
        ...this.form,
        cloudVnConnectCommon: {
          ...this.form.cloudVnConnectCommon,
          routeAggregationAddressList:
            this.form.cloudVnConnectCommon!.routeAggregation === "ON"
              ? []
              : this.form.cloudVnConnectCommon!.routeAggregation === "OFF"
              ? null
              : this.form.cloudVnConnectCommon!.routeAggregationAddressList,
        },
      } as
        | (CloudVnAwsPost & {
            bandItem: string | null;
          })
        | (CloudVnAwsPut & {
            cloudVnConnectSby: Pick<CloudVnAwsActSby, "asNumber">;
            cloudVnConnectAct: Pick<CloudVnAwsActSby, "asNumber">;
            bandItem: string | null;
          });
      //AS番号(act)の値をAS番号(sby)に代入する
      form.cloudVnConnectSby!.asNumber = form.cloudVnConnectAct!.asNumber;
      if (this.isAdd) {
        // 追加の場合
        // 確認モーダルの表示
        let displayVnName;
        const vnSbyName = form.cloudVnConnectSby?.vnConnectName;
        (form as CloudVnAwsPost).cloudVnConnectCommon!.vpnVnCode =
          this.selectedVpnVn!.value;
        displayVnName = this.vpnVnCodeList.find(
          (v) =>
            v.vpnVnCode ===
            (form as CloudVnAwsPost).cloudVnConnectCommon!.vpnVnCode
        )!.vnName;
        const bandwidthDisplayName =
          this.isBandwidthItemShow && form.bandItem
            ? form.bandItem === "LOW"
              ? "低帯域"
              : "広帯域"
            : null;
        await this.$modal.show(MultiCloudAwsVnConnectionConfirm, {
          cloudVnAwsDetail: {
            cloudVnConnectCommon: {
              ...form.cloudVnConnectCommon,
              vnName: displayVnName,
              routingType: "BGP4",
            },
            cloudVnConnectAct: {
              ...form.cloudVnConnectAct,
              localPreference: "HIGH_PRIORITY",
              med: "LOW_PRIORITY",
            },
            cloudVnConnectSby: vnSbyName
              ? {
                  ...form.cloudVnConnectSby,
                  localPreference: "LOW_PRIORITY",
                  med: "HIGH_PRIORITY",
                }
              : null,
          },
          displayType: "A",
          connectionMode: ((directConnectType) => {
            switch (directConnectType) {
              case "HOSTED_VIFS":
                return "VIF";
              case "HOSTED_CONNECTIONS":
                return "HOSTED";
              default:
                throw new Error(
                  `unsupported directConnectType: ${directConnectType}`
                );
            }
          })(this.form.cloudVnConnectCommon?.directConnectType),
          bandwidthDisplayName: bandwidthDisplayName,
        });
        await this.$api.cloudVnAws.postVnConnect(
          this.cloudLineSeq,
          (form.cloudVnConnectSby?.vnConnectName
            ? form
            : { ...form, cloudVnConnectSby: null }) as CloudVnAwsPost
        );
        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      } else {
        if (
          !this.cloudVnAwsDetail.cloudVnConnectSby?.vnConnectName &&
          form.cloudVnConnectSby?.vnConnectName
        ) {
          // 確認モーダルの表示
          await this.$modal.show(MultiCloudAwsVnConnectionConfirm, {
            cloudVnAwsDetail: {
              cloudVnConnectCommon: form.cloudVnConnectCommon,
              cloudVnConnectAct: form.cloudVnConnectAct,
              cloudVnConnectSby: {
                ...form.cloudVnConnectSby,
                localPreference: "LOW_PRIORITY",
                med: "HIGH_PRIORITY",
              },
            },
            displayType: "M",
            connectionMode: ((directConnectType) => {
              switch (directConnectType) {
                case "HOSTED_VIFS":
                  return "VIF";
                case "HOSTED_CONNECTIONS":
                  return "HOSTED";
                default:
                  throw new Error(
                    `unsupported directConnectType: ${directConnectType}`
                  );
              }
            })(this.form.cloudVnConnectCommon?.directConnectType),
            bandwidthDisplayName: this.bandItemName,
          });
          // Sbyのみ追加の場合
          await this.$api.cloudVnAws.postVnConnect(this.cloudLineSeq, {
            cloudVnConnectCommon: null,
            cloudVnConnectAct: null,
            cloudVnConnectSby: {
              ...form.cloudVnConnectSby,
              pairCloudVnConnectSeq:
                this.cloudVnAwsDetail.cloudVnConnectAct.cloudVnConnectSeq,
            },
            cloudVnAwsKeyId: this.cloudVnAwsDetail!.cloudVnAwsKeyId,
          } as CloudVnAwsPost);
        } else {
          // 確認モーダルの表示
          const vnSbyName = form.cloudVnConnectSby?.vnConnectName;
          await this.$modal.show(MultiCloudAwsVnConnectionConfirm, {
            cloudVnAwsDetail: {
              ...form,
              cloudVnConnectSby: vnSbyName ? form.cloudVnConnectSby : null,
            },
            displayType: "M",
            bandwidthDisplayName: this.bandItemName,
          });
          // 変更のみの場合
          await this.$api.cloudVnAws.putVnConnect(
            this.cloudLineSeq,
            this.cloudVnAwsDetail.cloudVnConnectAct.wnumber,
            (this.cloudVnAwsDetail.cloudVnConnectSby?.vnConnectName
              ? form
              : { ...form, cloudVnConnectSby: null }) as CloudVnAwsPut
          );
        }

        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },
  },
});












































































































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "DefaultPolicyOnewayAny",
  props: {
    applianceType: {
      type: String as PropType<string>,
      required: true,
    },
  },
});

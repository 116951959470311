import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import moment from "moment";

/**
 * 指定されたの時刻文字列の分数以前か
 * 指定時刻が未指定の場合は現在時刻が使用される
 */
export default {
  params: ["date", "time"],
  validate(value, { date, time }: { date?: string; time?: number }) {
    if (!value) {
      return true;
    }
    if (date && time) {
      return moment(value).isSameOrBefore(moment(date).add(-time, "minutes"));
    } else {
      return moment(value).isSameOrBefore(moment());
    }
  },
} as ValidationRuleSchema;













































































































import Vue, { PropType } from "vue";
import { PostReIssuance, ReIssuance } from "@/apis/PasswordApi";

export default Vue.extend({
  name: "PasswordReset",
  props: {
    /** ワンタイム認証キー */
    identifykey: {
      type: String as PropType<string>,
      default: null,
    },
  },
  data() {
    return {
      form: {
        loginId: "",
        email: "",
        loginUrl: process.env.VUE_APP_LOGIN_URL,
      } as PostReIssuance,
      reIssuance: {} as ReIssuance,
      isLoaded: false,
    };
  },
  async mounted() {
    this.reIssuance = await this.$api.password.getReIssuance(this.identifykey);
    this.isLoaded = true;
  },
  methods: {
    async submit() {
      await this.$api.password.postReIssuance(this.identifykey, this.form);
      await this.$router.push("/password/reset/complete");
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-medium","title":"パスワード変更"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"現在のパスワード","isRequired":true,"isInfo":false}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"現在のパスワード","rules":{
                required: true,
                alpha_num_both: true,
                between_length: { min: 8, max: 20 },
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"type":"password","state":_vm._f("validState")(validContext)},model:{value:(_vm.form.currentPassword),callback:function ($$v) {_vm.$set(_vm.form, "currentPassword", $$v)},expression:"form.currentPassword"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"新しいパスワード","msg":"・8文字以上20文字以内で、半角英字と数字を必ず１文字以上組み合わせてください。\n                     ・ひとつ前のパスワードは設定できません。","isRequired":true}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"vid":"password","name":"新しいパスワード","rules":{
                required: true,
                alpha_num_both: true,
                between_length: { min: 8, max: 20 },
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"type":"password","state":_vm._f("validState")(validContext)},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"新しいパスワード(確認)","isRequired":true,"isInfo":false}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"新しいパスワード(確認)","rules":{
                required: true,
                alpha_num_both: true,
                between_length: { min: 8, max: 20 },
                confirmed: 'password',
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"type":"password","onpaste":"return false","state":_vm._f("validState")(validContext)},model:{value:(_vm.form.confirmation),callback:function ($$v) {_vm.$set(_vm.form, "confirmation", $$v)},expression:"form.confirmation"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1)],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("変更")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
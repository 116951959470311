import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 *  メッセージ等に使用
 *  ひらがな(\u3040-\u309F)、カタカナ(\u30A0-\u30FF)、漢字(\u4E00-\u9FFF)数字、英字、
 *  記号(_ . , : ; ? < > + - * / = # % & $ @)で入力されること
 **/
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }
    return /^[0-9a-z*-Z_.,:;?<>+-/=#%&$@\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]*$/.test(
      value
    );
  },
} as ValidationRuleSchema;

/**
 * Type1サイトゾーンインターネットサイト一覧の項目オブジェクト
 */
import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { AxiosResponse } from "axios";

/** GET インターネットサイトゾーン情報を取得用 */
export interface Type1InternetInfo {
  /** 総件数（インターネットアドレスの総件数） */
  addressTotal: number;
  /**
   * アドレスリスト
   * 0件の場合は空配列
   */
  addressList: Type1InternetAddress[];
  /** 総件数（インターネットFQDNの総件数) */
  fqdnTotal: number;
  /**
   * FQDNリスト
   * 0件の場合は空配列
   */
  fqdnList: Type1InternetFQDN[];
  /** ゾーン */
  zone: Type1Zone | null;
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/**
 * PUT Type1インターネットサイト設定情報を一括更新（追加/変更/削除）用
 * 変更を行う際のパターンは以下の通りとする。
 * (1) アドレス情報の追加、変更、削除をする場合：変更しない設定を含め、設定更新後のaddressListを指定する。
 * アドレス追加時はipAddressIdは非必須となり、アドレスや名称変更時はipAddressIdは必須となる。
 * アドレス削除時はaddressListに含めない。
 * (2) FQDN情報の追加、変更、削除をする場合：変更しない設定を含め、設定更新後のfqdnListを指定する。
 * FQDN追加時はfqdnIdは非必須となり、FQDNや名称変更時はfqdnIdは必須となる。
 * FQDN削除時はfqdnListに含めない。
 */
export interface PostType1InternetSite {
  /**
   * アドレスリスト
   * 0件の場合は空配列
   */
  addressList: PutType1InternetAddress[];
  /**
   * FQDNリスト
   * 0件の場合は空配列
   */
  fqdnList: PutType1InternetFQDN[];
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/** GET エクストラサイトゾーン取得情報を取得用 */
export interface Type1ExtraInfo {
  /** 総件数(エクストラサイト一覧表示対象の総件数) */
  siteTotal: number;
  /**
   * サイトリスト
   * 0件の場合は空配列
   */
  siteList: Type1Site[];
  /** ゾーン */
  zone: Type1Zone | null;
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/** エクストラサイト一覧表示用 */
export interface Type1ExtraList {
  /** サイト名 */
  siteName: string;
  /**
   * IPアドレスID
   * アドレスオブジェクトの主キー ・"obj"+"数値"の形式
   */
  ipAddressId: string;
  /** IPアドレス名 */
  ipAddressName: string | null;
  /**
   * IPアドレス
   * ネットワークアドレスを入力
   */
  ipAddress: string | null;
  /**
   * ゾーンID
   * ゾーンオブジェクトの主キー ・"obj"+"数値"の形式
   */
  zoneId: string | null;
}

/** GET プライベートサイトゾーン取得情報を取得用 */
export interface Type1PrivateInfo {
  /** 総件数(回線の総件数) */
  lineTotal: number;
  /**
   * 回線リスト
   * 0件の場合は空配列
   */
  lineList: Type1PrivateLine[];
  /** 総件数(ゾーンの総件数) */
  zoneTotal: number;
  /**
   * ゾーンリスト
   * 0件の場合は空配列
   */
  zoneList: Type1Zone[];
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/** プライベートサイト一覧表示用 */
export interface Type1PrivateList {
  /**
   * サイトID
   * サイトオブジェクトの主キー ・"obj"+"数値"の形式
   */
  siteId: string;
  /** E番号 */
  enumber: string;
  /** サイト名 */
  siteName: string;
  /**
   * VLAN
   * vlanIdTypeがUNTAG: IDの指定なし
   * vlanIdTypeがSPECIFIED: 指定ID
   */
  vlan: string | number | null;
  /** IPアドレス名リスト */
  ipAddressName: (string | null)[] | null;
  /**
   * IPアドレスリスト
   * ネットワークアドレスを入力
   */
  ipAddress: string[] | null;
  /**
   * ゾーンIDスリスト
   * ゾーンオブジェクトの主キー ・"obj"+"数値"の形式
   */
  zoneId: (string | null)[] | null;
}

/** アドレスリスト(ipAddressId必須) */
export interface Type1InternetAddress {
  /**
   * IPアドレスID
   * アドレスオブジェクトの主キー ・"obj"+"数値"の形式
   */
  ipAddressId: string;
  /** IPアドレス名 */
  ipAddressName: string | null;
  /**
   * IPアドレス
   * ネットワークアドレスを入力
   */
  ipAddress: string;
  /**
   * ゾーンID
   * ゾーンオブジェクトの主キー ・"obj"+"数値"の形式
   */
  zoneId: string | null;
}

/** アドレスリストPUT用(ipAddressId非必須) */
export interface PutType1InternetAddress {
  /**
   * IPアドレスID
   * アドレスオブジェクトの主キー ・"obj"+"数値"の形式
   */
  ipAddressId: string | null;
  /** IPアドレス名 */
  ipAddressName: string | null;
  /**
   * IPアドレス
   * ネットワークアドレスを入力
   */
  ipAddress: string;
  /**
   * ゾーンID
   * ゾーンオブジェクトの主キー ・"obj"+"数値"の形式
   */
  zoneId: string | null;
  /**
   * ゾーン配置
   * システム上ではゾーン配置状態
   * 「ー」ボタンの制御用
   */
  isDeploy?: boolean;
}

/** FQDNリスト(fqdnId必須) */
export interface Type1InternetFQDN {
  /**
   * FQDN ID
   * アドレスオブジェクトの主キー ・"obj"+"数値"の形式
   */
  fqdnId: string;
  /** FQDN名 */
  fqdnName: string | null;
  /** FQDN */
  fqdn: string;
  /**
   * ゾーンID
   * ゾーンオブジェクトの主キー ・"obj"+"数値"の形式
   */
  zoneId: string | null;
}

/** FQDNリストPUT用(fqdnId非必須) */
export interface PutType1InternetFQDN {
  /**
   * FQDN ID
   * アドレスオブジェクトの主キー ・"obj"+"数値"の形式
   */
  fqdnId: string | null;
  /** FQDN名 */
  fqdnName: string | null;
  /** FQDN */
  fqdn: string;
  /**
   * ゾーンID
   * ゾーンオブジェクトの主キー ・"obj"+"数値"の形式
   */
  zoneId: string | null;
  /**
   * ゾーン配置
   * システム上ではゾーン配置状態
   * 「ー」ボタンの制御用
   */
  isDeploy?: boolean;
}

/** ゾーン */
export interface Type1Zone {
  /**
   * ゾーンID
   * ゾーンオブジェクトの主キー ・"obj"+"数値"の形式
   */
  zoneId: string;
  /**
   * ゾーン名
   * 未設定で作られたゾーン名の場合は「Unassigned」を返却
   **/
  zoneName: string;
}

/** ゾーン追加・編集用 */
export interface Type1ZoneEdit {
  /**
   * ゾーン名
   * 未設定で作られたゾーン名の場合は「Unassigned」を返却
   **/
  zoneName: string;
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/** サイト */
export interface Type1Site {
  /**
   * サイトID
   * サイトオブジェクトの主キー ・"obj"+"数値"の形式
   */
  siteId: string;
  /** サイト名 */
  siteName: string;
  /** 総件数(一覧表示対象の総件数)*/
  addressTotal: number;
  /**
   * アドレスリスト
   * 「大規模向け個別画面利用」がON、またはアドレスリストが0件の場合は空配列を返却
   */
  addressList: Type1InternetAddress[];
}
/** プライベート用サイト */
export interface Type1PrivateSite extends Type1Site {
  /**
   * VLAN IDのType指定
   * UNTAG: IDの指定なし
   * OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
   * SPECIFIED: 指定ID
   */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED";
  /**
   * VLAN ID
   * vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに必須項目
   */
  vlanId: number | null;
}

/** エクストラサイト追加・変更表示用 */
export interface Type1SiteEdit {
  /**
   * サイトID
   * サイトオブジェクトの主キー ・"obj"+"数値"の形式
   */
  siteId: string | null;
  /** サイト名 */
  siteName: string;
  /** 総件数(一覧表示対象の総件数)*/
  addressTotal: number;
  /**
   * アドレスリスト
   * 0件の場合は空配列
   */
  addressList: PutType1InternetAddress[];
}

/** 回線 */
export interface Type1PrivateLine {
  /** E番号 */
  enumber: string;
  /**
   * アクセス品目
   * ETHERNET_L2: イーサネット方式(L2モード)
   * ETHERNET_L3: イーサネット方式(L3モード)
   * BROADBAND_ACCESS2_L2: ブロードバンドアクセス方式Ⅱ(L2モード)
   * BROADBAND_ACCESS2_L3: ブロードバンドアクセス方式Ⅱ(L3モード)
   * WIRELESS_ACCESS_L2: ワイヤレスアクセス方式(L2モード)
   * WIRELESS_ACCESS_L3: ワイヤレスアクセス方式(L3モード)
   * REMOTE_ACCESS_GW_FRE: リモートアクセスGW(FRE)
   * REMOTE_ACCESS_GW_CPA: リモートアクセスGW(CPA)
   * REMOTE_ACCESS_GW_CRG: リモートアクセスGW(CRG)
   * PLATFORM_GATEWAY: PFGW
   * PF_ACCESS_GW: PFアクセスGW
   * EXTEND_ETHERNET: エクステンドイーサネット方式
   * IPSEC: IPsec方式
   * ETHERNET2: イーサネット方式Ⅱ
   * ETHERNET2_PF: イーサネット方式Ⅱ PF
   * BROADBAND_ACCESS3: ブロードバンドアクセス方式Ⅲ
   * WIRELESS_ACCESS2: ワイヤレスアクセス方式Ⅱ
   * REMOTE_ACCESS_GW2_FRE: リモートアクセスGW2(FRE)
   * REMOTE_ACCESS_GW2_CPA: リモートアクセスGW2(CPA)
   * REMOTE_ACCESS_GW2_CRG: リモートアクセスGW2(CRG)
   * PF_ACCESS_GW2: PFアクセスGWⅡ
   * ETHERNET_LIGHT: イーサネットライト
   */
  accessType:
    | "ETHERNET_L2"
    | "ETHERNET_L3"
    | "BROADBAND_ACCESS2_L2"
    | "BROADBAND_ACCESS2_L3"
    | "WIRELESS_ACCESS_L2"
    | "WIRELESS_ACCESS_L3"
    | "REMOTE_ACCESS_GW_FRE"
    | "REMOTE_ACCESS_GW_CPA"
    | "REMOTE_ACCESS_GW_CRG"
    | "PLATFORM_GATEWAY"
    | "PF_ACCESS_GW"
    | "EXTEND_ETHERNET"
    | "IPSEC"
    | "ETHERNET2"
    | "ETHERNET2_PF"
    | "BROADBAND_ACCESS3"
    | "WIRELESS_ACCESS2"
    | "REMOTE_ACCESS_GW2_FRE"
    | "REMOTE_ACCESS_GW2_CPA"
    | "REMOTE_ACCESS_GW2_CRG"
    | "PF_ACCESS_GW2"
    | "ETHERNET_LIGHT";

  /**
   * サイト作成可否フラグ
   * サイト作成不可：「false」、
   * サイト作成可:「true」
   * PFアクセスGWⅡのスタンバイ回線のみサイト作成不可となる。
   */
  isEnable: boolean;
  /** 総件数（サイトリストの総件数) */
  siteTotal: number;
  /** サイトリスト */
  siteList: Type1PrivateSite[];
}

/**
 * エクストラサイト追加用reqData
 * 追加を行う際のパターンは以下の通りとする。
 * (1) サイトのみを追加する場合：siteNameを指定する。
 * (2) サイトとアドレスを追加する場合：siteNameとaddressListのipAddressを指定する。
 * (3) サイトとアドレスの追加と、既存のゾーンを紐づける場合：(2)に加えzoneIdを指定する。
 */
export interface Type1ExtraSiteRegister {
  /** サイト名 */
  siteName: string;
  /**
   * アドレスリスト
   * 0件の場合は空配列
   */
  addressList: Type1ExtraAddress[];
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/** エクストラサイト追加用アドレスリスト */
interface Type1ExtraAddress {
  /** IPアドレス名 */
  ipAddressName: string | null;
  /**
   * IPアドレス
   * ネットワークアドレスを入力
   */
  ipAddress: string;
  /**
   * ゾーンID
   * ゾーンオブジェクトの主キー ・"obj"+"数値"の形式
   */
  zoneId: string | null;
}

/**
 * エクストラサイト変更用reqData
 * 変更を行う際のパターンは以下の通りとする。
 * (1) サイト名を変更する場合：siteIdとsiteNameを指定する。アドレスが紐づいている場合はaddressListも指定する。
 * (2) アドレスの追加、変更、削除をする場合：変更しない設定を含め、設定更新後のaddressListを指定する。
 * アドレス追加時はipAddressIdは非必須となり、アドレスや名称変更時はipAddressIdは必須となる。
 * アドレス削除時はaddressListに含めない。
 */
export interface Type1ExtraSiteModify {
  /** サイト名 */
  siteName: string;
  /**
   * アドレスリスト
   * 0件の場合は空配列
   */
  addressList: PutType1InternetAddress[];
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/** エクストラサイト削除用 */
export interface Type1ExtraSiteDelete {
  /**
   * siteIdを指定し、サイトを削除する。
   * サイトへ紐づくアドレスが残存していた場合、エラーが発生する。
   */
  siteList: string[];
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/**
 * プライベートサイト追加用reqData
 */
export interface Type1PrivateSiteRegister {
  /** サイト */
  site: Type1PrivateSiteRegisterSite;
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/**
 * プライベートサイト変更用reqData
 * 変更を行う際のパターンは以下の通りとするが、「大規模向け個別画面利用」がONの場合、addressListはNULLを設定する。
 * ：(1) サイト名を変更する場合：siteIdとsiteNameを指定する。アドレスが紐づいている場合はaddressListも指定する。
 *   (2) サイト名を削除する場合：siteIdを指定し、siteNameはNULLを指定する。アドレスが紐づいている場合はaddressListも指定する。
 *   (3) アドレスの追加、変更、削除をする場合：変更しない設定を含め、設定更新後のaddressListを指定する。
 *   アドレス追加時はipAddressIdは非必須となり、アドレスや名称変更時はipAddressIdは必須となる。
 *   アドレス削除時はaddressListに含めない。
 */
export interface Type1PrivateSiteModify {
  /** サイト */
  site: Type1PrivateSiteModifySite;
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/**
 * プライベートサイト削除用reqData
 * サイトへ紐づくアドレスが残存していた場合、エラーが発生する。
 */
export interface Type1PrivateSiteDelete {
  /** 回線リスト */
  lineList: Type1PrivateSiteDeleteLine[];
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/**
 * プライベートサイト追加用site
 */
interface Type1PrivateSiteRegisterSite {
  /**
   * サイト名
   * vlanIdTypeがSPECIFIEDの場合、非必須となり
   * サイト名未設定でサイトの作成を行うとvlanIdをサイト名に設定する。
   */
  siteName: string;
  /**
   * VLAN IDのType指定
   * UNTAG: IDの指定なし
   * OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
   * SPECIFIED: 指定ID
   */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED";
  /**
   * VLAN ID
   * vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに必須項目
   */
  vlanId: number | null;
}

/**
 * プライベートサイト変更用site
 */
interface Type1PrivateSiteModifySite {
  /**
   * サイト名
   * vlanIdTypeがSPECIFIEDの場合、非必須となり
   * サイト名未設定でサイトの作成を行うとvlanIdをサイト名に設定する。
   */
  siteName: string;
  /**
   * アドレスリスト
   * 「大規模向け個別画面利用」がON、またはアドレスリストが0件の場合は空配列を設定する。
   */
  addressList: PutType1InternetAddress[];
}

/**
 * プライベートサイト削除用line
 */
export interface Type1PrivateSiteDeleteLine {
  /** E番号 */
  enumber: string;
  /**
   * サイトID
   * サイトオブジェクトの主キー"obj"+"数値"の形式
   */
  siteIdList: string[];
}

/** プライベートゾーン削除用 */
export interface Type1PrivateZoneDelete {
  /**
   * ゾーンIDのリスト
   **/
  zoneIdList: string[];
  /**
   * サイトゾーン一覧(Type1)排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  type1SiteZoneKeyId: string;
}

/** Type1SiteZone用API */
export default class Type1SiteApi extends AbstractBaseApi {
  /**
   * Type1インターネットサイトゾーン情報を取得
   */
  async getType1InternetList(): Promise<Type1InternetInfo> {
    return (await this.api.get("/v1/network/sitezones/internet/type1")).data;
  }

  /**
   * Type1インターネットサイト設定情報を一括更新（追加/変更/削除）
   */
  async postType1InternetSite(data: PostType1InternetSite): Promise<void> {
    await this.api.post("/v1/network/sites/internet/type1", data);
  }

  /**
   * Type1グローバルゾーンを追加
   */
  async postType1GlobalZoneRegister(data: Type1ZoneEdit): Promise<void> {
    await this.api.post("/v1/network/zone/internet/type1", data);
  }

  /**
   * Type1グローバルゾーンを変更
   */
  async postType1GlobalZoneModify(
    data: Type1ZoneEdit,
    zoneId: Type1Zone["zoneId"]
  ): Promise<void> {
    await this.api.post(`/v1/network/zone/${zoneId}/internet/type1`, data);
  }

  /**
   * Type1グローバルゾーンを削除
   */
  async postType1GlobalZoneDelete(
    data: Type1ZoneEdit["type1SiteZoneKeyId"],
    zoneId: Type1Zone["zoneId"]
  ): Promise<void> {
    await this.api.post(
      `/v1/network/zone/${zoneId}/internet/type1/delete`,
      data
    );
  }

  /**
   * Type1エクストラサイトゾーン情報を取得
   */
  async getType1ExtraList(): Promise<Type1ExtraInfo> {
    return (await this.api.get("/v1/network/sitezones/extra/type1")).data;
  }

  /**
   * Type1エクストラサイト設定情報を追加
   */
  async postType1ExtraSiteRegister(
    data: Type1ExtraSiteRegister
  ): Promise<void> {
    await this.api.post("/v1/network/site/extra/type1", data);
  }

  /**
   * Type1エクストラサイト設定情報を変更
   */
  async postType1ExtraSiteModify(
    data: Type1ExtraSiteModify,
    siteId: Type1Site["siteId"]
  ): Promise<void> {
    await this.api.post(`/v1/network/site/${siteId}/extra/type1`, data);
  }

  /**
   * Type1エクストラサイト設定情報を削除
   */
  async postType1ExtraSiteDelete(data: Type1ExtraSiteDelete): Promise<void> {
    await this.api.post("/v1/network/sites/extra/type1/delete", data);
  }

  /**
   * Type1エクストラゾーンを追加
   */
  async postType1ExtraZoneRegister(data: Type1ZoneEdit): Promise<void> {
    await this.api.post("/v1/network/zone/extra/type1", data);
  }

  /**
   * Type1エクストラゾーンを変更
   */
  async postType1ExtraZoneModify(
    data: Type1ZoneEdit,
    zoneId: Type1Zone["zoneId"]
  ): Promise<void> {
    await this.api.post(`/v1/network/zone/${zoneId}/extra/type1`, data);
  }

  /**
   * Type1エクストラゾーンを削除
   */
  async postType1ExtraZoneDelete(
    data: Type1ZoneEdit["type1SiteZoneKeyId"],
    zoneId: Type1Zone["zoneId"]
  ): Promise<void> {
    await this.api.post(`/v1/network/zone/${zoneId}/extra/type1/delete`, data);
  }

  /**
   * Type1プライベートサイトゾーン情報を取得
   */
  async getType1PrivateList(): Promise<Type1PrivateInfo> {
    return (await this.api.get("/v1/network/sitezones/private/type1")).data;
  }

  /**
   * Type1プライベートサイト設定情報を追加
   */
  async postType1PrivateSiteRegister(
    data: Type1PrivateSiteRegister,
    enumber: Type1PrivateLine["enumber"]
  ): Promise<void> {
    await this.api.post(`/v1/network/${enumber}/site/private/type1`, data);
  }

  /**
   * Type1プライベートサイト設定情報を変更
   */
  async postType1PrivateSiteModify(
    data: Type1PrivateSiteModify,
    enumber: Type1PrivateLine["enumber"],
    siteId: Type1Site["siteId"]
  ): Promise<void> {
    await this.api.post(
      `/v1/network/${enumber}/site/${siteId}/private/type1`,
      data
    );
  }

  /**
   * Type1プライベートサイト設定情報を削除
   */
  async postType1PrivateSiteDelete(
    data: Type1PrivateSiteDelete
  ): Promise<void> {
    await this.api.post("/v1/network/site/private/type1/delete", data);
  }

  /**
   * Type1プライベートゾーンを追加
   */
  async postType1PrivateZoneRegister(data: Type1ZoneEdit): Promise<void> {
    await this.api.post("/v1/network/zone/private/type1", data);
  }

  /**
   * Type1プライベートゾーンを変更
   */
  async postType1PrivateZoneModify(
    data: Type1ZoneEdit,
    zoneId: Type1Zone["zoneId"]
  ): Promise<void> {
    await this.api.post(`/v1/network/zone/${zoneId}/private/type1`, data);
  }

  /**
   * Type1プライベートゾーンを削除
   */
  async postType1PrivateZoneDelete(
    data: Type1PrivateZoneDelete
  ): Promise<void> {
    await this.api.post("/v1/network/zones/private/type1/delete", data);
  }

  /**
   * NW設計情報の一覧のCSVを取得
   */
  async getNetWorkInformation(): Promise<AxiosResponse> {
    return await this.api.get("/v1/network/information/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
      responseType: "arraybuffer",
    });
  }
}






























































































































































































































































import Vue, { PropType } from "vue";
import { PacketFilterEntity, Protocol } from "@/apis/PacketFilteringApi";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "PacketFilteringRuleEdit",
  props: {
    /** ルール設定（変更時必須） */
    packetData: {
      type: Object as PropType<PacketFilterEntity>,
      required: false,
    },
  },
  data() {
    return {
      // APIに渡さない画面表示用
      srcAddress: this.packetData?.srcAddress ? "address" : null,
      dstAddress: this.packetData?.dstAddress ? "address" : null,
      srcPort: this.packetData?.srcPort ? "port" : null,
      dstPort: this.packetData?.dstPort ? "port" : null,

      form: cloneDeep(this.packetData) ?? {
        packetFilterRuleSeq: null,
        isDefaultRule: false,
        protocol: "TCP",
        action: "PERMIT",
        srcAddress: null,
        dstAddress: null,
        srcPort: null,
        dstPort: null,
        filteringStatus: "DISABLED",
      },
      addrOptions: [
        { value: null, text: "any" },
        { value: "address", text: "アドレス指定" },
      ],
      portOptions: [
        { value: null, text: "any" },
        { value: "port", text: "ポート指定" },
      ],
      protocolOptions: [
        { value: "TCP", text: "TCP" },
        { value: "UDP", text: "UDP" },
        { value: "ANY", text: "any" },
      ],
      actionOptions: [
        { value: "PERMIT", text: "Permit" },
        { value: "DENY", text: "Deny" },
      ],
      statusOptions: filterStatusItems,
    };
  },
  computed: {
    /** true: 編集, false: 新規 */
    isEdit(): boolean {
      return !!this.packetData;
    },
  },
  watch: {
    /** プロトコルでanyが選択された場合にポートをanyに変更 */
    "form.protocol"(newValue: Protocol) {
      if (newValue === "ANY") {
        this.srcPort = null;
        this.dstPort = null;
      }
    },
  },
  methods: {
    submit() {
      (this.$refs.modal as Vue & { ok: (r: PacketFilterEntity) => void }).ok({
        ...this.form,
        srcAddress: this.srcAddress === "address" ? this.form.srcAddress : null,
        dstAddress: this.dstAddress === "address" ? this.form.dstAddress : null,
        srcPort: this.srcPort === "port" ? this.form.srcPort : null,
        dstPort: this.dstPort === "port" ? this.form.dstPort : null,
      });
    },
  },
});

/** フィルタリング有効/無効 */
const filterStatusItems = [
  { value: "ENABLED", text: "有効" },
  { value: "DISABLED", text: "無効" },
];

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * ブロードキャストアドレス、リミテッドブロードキャストアドレスではないことを確認
 * @param value チェック対象IPアドレス
 *              (例：
 *                   IPのみの場合：192.168.1.1 or
 *                   CIDRの場合：192.168.1.1/30
 *               )
 * @param range CIDRの形式でアドレス範囲指定（例：192.168.1.1/30）ない時、設定しない
 * チェック内容：
 *  valueはサブネット無しのIP(XXX.XXX.XXX.XXX)の場合 → リミテッドブロードキャストアドレス（255.255.255.255）ではないことを確認
 *  valueはサブネット無しのIP(XXX.XXX.XXX.XXX) + アドレス範囲がrange(XXX.XXX.XXX.XXX/XX)で指定の場合 → リミテッドブロードキャストアドレス、ブロードキャストアドレスではないことを確認(× 192.168.1.3 + 192.168.1.1/30)
 *  valueはサブネット付きのIPの場合、リミテッドブロードキャストアドレス、ブロードキャストアドレスではないことを確認
 */
export default {
  params: ["range"],
  validate: function (
    value: string | undefined | null,
    { range }: { range: string | null }
  ): boolean {
    if (!value) {
      return true;
    }

    if (ipaddr.IPv4.isValidFourPartDecimal(value)) {
      // サブネット無しのIP
      const ip = ipaddr.IPv4.parse(value);
      if (!range) {
        // valueはサブネット無しのIP(XXX.XXX.XXX.XXX)の場合 → リミテッドブロードキャストアドレス（255.255.255.255）ではないことを確認
        return value !== "255.255.255.255";
      }
      // Rangeありの場合
      let broadcastAddress;
      try {
        broadcastAddress =
          ipaddr.IPv4.broadcastAddressFromCIDR(range).toString();
      } catch (e) {
        return true;
      }
      // valueはサブネット無しのIP(XXX.XXX.XXX.XXX) + アドレス範囲がrange(XXX.XXX.XXX.XXX/XX)で指定の場合 → リミテッドブロードキャストアドレス、ブロードキャストアドレスではないことを確認
      return (
        ip.toString() !== broadcastAddress &&
        ip.toString() !== "255.255.255.255"
      );
    } else {
      // サブネット付きのIP
      let ip: [ipaddr.IPv4, number];
      try {
        ip = ipaddr.IPv4.parseCIDR(value);
      } catch (e) {
        return true;
      }

      if (ip[1] === 32) {
        return ip[0].toString() !== "255.255.255.255";
      } else {
        const broadcastAddress =
          ipaddr.IPv4.broadcastAddressFromCIDR(value).toString();
        return ip[0].toString() !== broadcastAddress;
      }
    }
  },
} as ValidationRuleSchema;

































































import Vue, { PropType } from "vue";
import { Type4IpMasqueradePolicy } from "@/modals/applianceContractSetting/InternetType4PolicyRuleIpMasqViewRD.vue";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicyIpPool from "@/components/policy/PolicyIpPool.vue";

export default Vue.extend({
  name: "InternetType4PolicyRuleIpMasqConfirm",
  components: { PolicyIpPool, PolicyDstAddress, PolicySrcAddress },
  props: {
    /** ポリシー */
    policy: {
      type: Object as PropType<Type4IpMasqueradePolicy>,
      required: true,
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 重複チェックバリデーション.
 * 指定された一覧の中のいずれとも値が一致しないこと
 */
export default {
  params: ["list"],
  validate(
    value: string | undefined | null,
    { list = [] }: { list: string[] }
  ): boolean {
    if (!value) {
      return true;
    }

    return !list.find((e) => e === value);
  },
} as ValidationRuleSchema;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-large","title":"VNコネクト設定"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-card-title',{staticClass:"modal-title"},[_vm._v("VNコネクト設定")]),_c('div',{staticClass:"text-right"},[_c('div',{staticClass:"btn-container"},[(_vm.active !== 3)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.downloadConfig}},[_vm._v("設定ダウンロード")]):_vm._e(),(_vm.active === 1)?[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.uploadConfig}},[_vm._v("設定アップロード")])]:_vm._e(),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":cancel}},[_vm._v(" × ")])],2)])]}},{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [(_vm.isLoaded)?_c('app-stepper',{attrs:{"size":3,"titles":['基本設定', 'NW設定', '設定確認']},on:{"input":function($event){_vm.active = $event}},scopedSlots:_vm._u([{key:"content(1)",fn:function(ref){
var next = ref.next;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.nextStepper1(next); })}}},[_c('v-n-connect-edit-stepper1',{attrs:{"is-edit":_vm.isEdit,"vpn-vn-code":_vm.vpnVnCode,"type1-site":_vm.type1Site,"access-line":_vm.accessLine,"vn-connects":_vm.vnConnects,"qos-list":_vm.qosList,"upper-limit-list":_vm.upperLimitList},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"warning","show":!!_vm.uploadAlert}},[_vm._v(_vm._s(_vm.uploadAlert))]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("戻る ")]),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("次へ")])],1)],1)]}}],null,true)})]}},{key:"content(2)",fn:function(ref){
var next = ref.next;
var prev = ref.prev;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(next)}}},[_c('v-n-connect-edit-stepper2',{attrs:{"is-edit":_vm.isEdit,"qos":_vm.qosDetail,"upper-limit-list":_vm.upperLimitList},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":prev}},[_vm._v("戻る ")]),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("次へ")])],1)],1)]}}],null,true)})]}},{key:"content(3)",fn:function(ref){
var prev = ref.prev;
return [_c('v-n-connect-edit-stepper3',{attrs:{"is-edit":_vm.isEdit,"value":_vm.form,"qos":_vm.qosDetail}}),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":prev}},[_vm._v("戻る")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.register}},[_vm._v("設定")])],1)]}}],null,true)}):_vm._e()]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
// 最終的にContractApi.tsにマージされるかも

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 * 契約回線一覧のAPI用インターフェイス
 */
export interface ContractLineGetEntity {
  total: number;
  /** 契約回線SEQ  */
  contractSeq: string;
  /** V番号 */
  vnumber: string;
  /** VPNコード */
  vpnCode: string;
  /** メールアドレス */
  email: string;
  /** [テーブル]契約回線一覧のAPI用インターフェイス */
  contractList: ContractListEntity[];
}

/**
 * [テーブル]契約回線一覧のAPI用インターフェイス
 */
export interface ContractListEntity {
  /** 契約回線SEQ */
  contractDetailSeq: string;
  /** 回線番号 */
  enumber: string;
  /** 契約者名 */
  contractor: string;
  /** エンドユーザ名 */
  endUserName: string;
  /** 事業所名（拠点名） */
  office: string;
  /** メニュー
   * INTERNET: インターネット
   * ETHERNET_L2: イーサネット方式(L2モード)
   * ETHERNET_L3: イーサネット方式(L3モード)
   * BROADBAND_ACCESS2_L2: ブロードバンドアクセス方式Ⅱ(L2モード)
   * BROADBAND_ACCESS2_L3: ブロードバンドアクセス方式Ⅱ(L3モード)
   * WIRELESS_ACCESS_L2: ワイヤレスアクセス方式(L2モード)
   * WIRELESS_ACCESS_L3: ワイヤレスアクセス方式(L3モード)
   * REMOTE_ACCESS_GW_FRE: リモートアクセスGW(FRE)
   * REMOTE_ACCESS_GW_CPA: リモートアクセスGW(CPA)
   * REMOTE_ACCESS_GW_CRG: リモートアクセスGW(CRG)
   * PLATFORM_GATEWAY: PFGW
   * PF_ACCESS_GW: PFアクセスGW
   * EXTEND_ETHERNET: エクステンドイーサネット方式
   * IPSEC: IPsec方式
   * ETHERNET2: イーサネット方式Ⅱ
   * ETHERNET2_PF: イーサネット方式Ⅱ PF
   * BROADBAND_ACCESS3: ブロードバンドアクセス方式Ⅲ
   * WIRELESS_ACCESS2: ワイヤレスアクセス方式Ⅱ
   * PF_ACCESS_GW2: PFアクセスGWⅡ
   * VN_L2L3_CONNECT: VN L2/L3コネクト
   * REMOTE_ACCESS_GW2_FRE: リモートアクセスGW2(FRE)
   * REMOTE_ACCESS_GW2_CPA: リモートアクセスGW2(CPA)
   * REMOTE_ACCESS_GW2_CRG: リモートアクセスGW2(CRG)
   * ETHERNET_LIGHT: イーサネットライト
   * MULTI_CLOUD_GW: マルチクラウドGW
   * CPA_5GSA: 5GSA CPA
   *
   */
  accessType:
    | "INTERNET"
    | "ETHERNET_L2"
    | "ETHERNET_L3"
    | "BROADBAND_ACCESS2_L2"
    | "BROADBAND_ACCESS2_L3"
    | "WIRELESS_ACCESS_L2"
    | "WIRELESS_ACCESS_L3"
    | "REMOTE_ACCESS_GW_FRE"
    | "REMOTE_ACCESS_GW_CPA"
    | "REMOTE_ACCESS_GW_CRG"
    | "PLATFORM_GATEWAY"
    | "PF_ACCESS_GW"
    | "EXTEND_ETHERNET"
    | "IPSEC"
    | "ETHERNET2"
    | "ETHERNET2_PF"
    | "BROADBAND_ACCESS3"
    | "WIRELESS_ACCESS2"
    | "PF_ACCESS_GW2"
    | "VN_L2L3_CONNECT"
    | "REMOTE_ACCESS_GW2_FRE"
    | "REMOTE_ACCESS_GW2_CPA"
    | "REMOTE_ACCESS_GW2_CRG"
    | "ETHERNET_LIGHT"
    | "MULTI_CLOUD_GW"
    | "CPA_5GSA";
  /** サービス開廃日 */
  startEndDate: string;
  /** セキュリティ監視オプションフラグ. 有りの場合は「true」、無しの場合は「false」を設定する。 */
  isSecurityOption: boolean;
  /** 設定代行契約 */
  delegatedContract: DelegatedContractEntity | null;
  /** 住所 */
  address: string | null;
  /** ビル名 */
  building: string | null;
  /**
   * 帯域タイプ. 以下のいずれかを設定する。
   * BANDWIDTH_SECURED:帯域確保
   * TRAFFICFREE:トラフィックフリー
   * BURST10:バースト10
   * BURST100:バースト100
   * BESTEFFORT:ベストエフォート
   */
  bandwidthType:
    | "BANDWIDTH_SECURED"
    | "TRAFFICFREE"
    | "BURST10"
    | "BURST100"
    | "NONE";
  /** 帯域値 */
  bandwidth: BandWidthEntity | null;
  /** Qos
   * ON: 有
   * OFF: 無
   * QoS設定不可の場合、nullを指定
   */
  qos: "ON" | "OFF" | null;
  /** VLAN制御
   * ON: ON
   * OFF: OFF
   * VLAN制御設定不可の場合、nullを指定
   */
  vlanControl: "ON" | "OFF" | null;
  /** 最終更新年月日 */
  updateDate: string;
}

/**
 * 設定代行契約
 */
export interface DelegatedContractEntity {
  /** 設定代行 */
  settingTimes: string;
  /** 変更管理代行 */
  contractTimes: string;
}

/**
 * 帯域値
 */
export interface BandWidthEntity {
  /** 設定値 */
  value: number;
  /** 単位 */
  /**
   * 単位. 以下のいずれかを設定する。
   * KBPS: Kbps
   * MBPS: Mbps
   * GBPS: Gbps
   */
  unit: "KBPS" | "MBPS" | "GBPS" | null;
}

export default class ContractLineApi extends AbstractBaseApi {
  /**
   * 契約回線情報を取得
   */
  async getAll(): Promise<ContractLineGetEntity> {
    return (await this.api.get("/v1/contract/information")).data;
  }

  /**
   * ログインしているアカウントの契約情報をCSVで一覧取得する。
   */
  getCsvURL(): string {
    return this.getFullURL("/v1/contract/information/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
}

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import moment from "moment";

/**
 * 終了時刻が開始時刻より未来の時刻であること.
 * 終了時刻側にこのバリデーションを設定する
 * formatを使って任意の時刻形式を設定可能
 */
export default {
  params: [{ name: "from", isTarget: true }, "format"],
  validate(
    to: string | undefined | null,
    { from, format }: { from: string | undefined | null; format?: string }
  ): boolean {
    if (!to || !from) {
      return true;
    }
    return moment(to, format).isAfter(moment(from, format));
  },
} as ValidationRuleSchema;

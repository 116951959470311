

























































































































import Vue, { PropType } from "vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import MultiCloudVNConnectStatusModify from "@/modals/multicloud/MultiCloudVNConnectStatusModify.vue";
import { CloudVnSfdcList } from "@/apis/CloudVnSfdcApi";
import MultiCloudSfdcVnConnectionReference from "@/modals/multicloud/salesforce/MultiCloudSfdcVnConnectionReference.vue";
import MultiCloudSfdcVnLineEdit from "@/modals/multicloud/salesforce/MultiCloudSfdcVnLineEdit.vue";
import { Line } from "@/apis/CloudLineApi";

type CloudVnSfdcItem = CloudVnSfdcList["cloudVnConnectsList"][0];

export default Vue.extend({
  name: "MultiCloudSfdcVnConnectionList",
  props: {
    /** クラウド回線SEQ */
    cloudLineSeq: {
      type: String as PropType<string>,
    },
    /** 該当クラウドメニューの設定未完了VNコネクト有無 */
    hasCloudVnResetting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /** アクセス回線メニューの帯域 */
    accessLineMenuBandwidth: {
      type: Object as PropType<Line["bandwidth"]>,
      required: true,
    },
  },
  data() {
    return {
      vnConnectList: {} as CloudVnSfdcList,
      vnConnects: {
        fields: [
          {
            key: "wnumber",
            label: "VNコネクト番号\n(Act/Sby)",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnSfdcItem) => {
              return item.cloudVnConnectAct.wnumber;
            },
          },
          {
            key: "vnConnectName",
            label: "VNコネクト名\n(Act/Sby)",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnSfdcItem) => {
              return item.cloudVnConnectAct.vnConnectName;
            },
          },
          {
            key: "isVnConnectStatus",
            label: "有効/無効\n(Act/Sby)",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnSfdcItem) => {
              return this.$filter.enumTo(
                item.cloudVnConnectAct.isVnConnectStatus,
                "enable"
              );
            },
          },
          {
            key: "vpnVnCode",
            label: "接続先VPN/VNコード",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnSfdcItem) => {
              if (item.cloudVnConnectCommon.vpnVnCode.startsWith("D")) {
                return `${item.cloudVnConnectCommon.vpnVnCode}(
                  ${item.cloudVnConnectCommon.vnType}
                )`;
              }
              return item.cloudVnConnectCommon.vpnVnCode;
            },
          },
          {
            key: "bandwidth",
            label: "品目",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnSfdcItem) => {
              return this.$filter.bandwidthToBps(
                item.cloudVnConnectCommon.bandwidth
              );
            },
          },
          { key: "routingType", label: "ルーティング種別" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
        selected: [] as CloudVnSfdcList["cloudVnConnectsList"],
      },
      alertMsg: null as string | null,
      isLoaded: false,
    };
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
    /** 残りの設定可能帯域 */
    remainingBandwidth(): { value: number; unit: "MBPS" | "GBPS" } {
      const limitBandwidthMbps =
        (this.$filter.bandwidthToBps(this.vnConnectList.bandwidth) ?? 0) /
        1000 /
        1000;
      const sumBandwidthMbps = this.vnConnectList.cloudVnConnectsList.reduce(
        (a, e) =>
          a +
          (this.$filter.bandwidthToBps(e.cloudVnConnectCommon.bandwidth) ?? 0) /
            1000 /
            1000,
        0
      );
      const diffBandwidthMbps = limitBandwidthMbps - sumBandwidthMbps;
      if (diffBandwidthMbps >= 1000 || diffBandwidthMbps <= -1000) {
        return { value: diffBandwidthMbps / 1000, unit: "GBPS" };
      } else {
        return { value: diffBandwidthMbps, unit: "MBPS" };
      }
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** 読み込み */
    async load() {
      this.vnConnectList = await this.$api.cloudVnSfdc.getVnConnects(
        this.cloudLineSeq
      );
    },
    /** 有効/無効 */
    async modifyStatus(isEnabled: boolean) {
      if (this.vnConnects.selected.length === 0) {
        this.alertMsg = this.$msg("no_select_data");
        return;
      } else {
        this.alertMsg = null;
      }

      const wNumbers: string[] = await this.$modal.show(
        MultiCloudVNConnectStatusModify,
        {
          enable: isEnabled,
          vnConnects: this.vnConnects.selected,
        }
      );

      if (isEnabled) {
        await this.$api.cloudVnSfdc.putEnableVnConnect({
          cloudVnConnectList: wNumbers,
          cloudVnSfdcKeyId: this.vnConnectList.cloudVnSfdcKeyId,
        });
      } else {
        await this.$api.cloudVnSfdc.putDisableVnConnect({
          cloudVnConnectList: wNumbers,
          cloudVnSfdcKeyId: this.vnConnectList.cloudVnSfdcKeyId,
        });
      }
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * Salesforce向けVNコネクト情報の表示
     * @param row 選択した行のデータ
     */
    async showConnectionDetail(row: CloudVnSfdcItem) {
      this.alertMsg = null;
      await this.$modal.show(MultiCloudSfdcVnConnectionReference, {
        wnumber: row.cloudVnConnectAct.wnumber,
        cloudLineSeq: this.cloudLineSeq,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    async showVnConnectRegister() {
      this.alertMsg = null;
      await this.$modal.show(MultiCloudSfdcVnLineEdit, {
        cloudLineSeq: this.cloudLineSeq,
        cloudVnSfdcKeyId: this.vnConnectList.cloudVnSfdcKeyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

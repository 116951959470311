/**
 * インターネットの接続状態を判定し結果を返却する
 * @param isStart true:インターネット開通済み false:インターネット未開通
 * @param isInternetPause true:インターネット停止 false:インターネット稼働中
 */
export default function internetStatusTo(
  isStart: boolean | null,
  isInternetPause: boolean | null
): string {
  // イントラネットの場合はハイフンを返却する
  if (isStart === null || isInternetPause === null) return "-";

  // 接続開始していない場合は未開通
  if (!isStart) {
    return "未開通";
  } else {
    if (isInternetPause) {
      // 接続開始していて、停止している場合は停止
      return "停止";
    } else {
      // 接続開始していて、停止していない場合は停止
      return "稼働中";
    }
  }
}

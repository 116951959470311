import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";
/**
 * Wan範囲内相関チェック.
 */
export default {
  params: ["wan"],
  validate(
    value: string | undefined | null,
    { wan }: { wan: string }
  ): boolean {
    if (!value || !wan) {
      return true;
    }
    let wanCidr: [ipaddr.IPv4, number];
    try {
      wanCidr = ipaddr.IPv4.parseCIDR(wan);
    } catch (e) {
      // wanの方が正しくない場合、スキップする(Wanの単項目Validationの役割ので)
      return true;
    }
    let peerAddress: ipaddr.IPv4;
    try {
      peerAddress = ipaddr.IPv4.parse(value);
    } catch (e) {
      // peerの方が正しくない場合、スキップする(Peerの単項目Validationの役割ので)
      return true;
    }
    return peerAddress.match(wanCidr);
  },
} as ValidationRuleSchema;

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * 対になるアドレス同士が同じセグメントのアドレス帯になっていること
 * ・正しいIPV4形式であることは別のバリデーションで実施が必要
 * ・CIDR形式のみ受付可能
 * ・あくまでもアドレス帯の確認のため、CIDR値とIPアドレスが両方とも同じであれば正常としている
 */
export default {
  params: [{ name: "ip", isTarget: true }],
  validate(
    value: string | undefined | null,
    { ip }: { ip: string | undefined | null }
  ): boolean {
    if (!value || !ip) {
      return true;
    }

    // 異なるCIDR値の場合、false
    try {
      if (ipaddr.IPv4.parseCIDR(value)[1] !== ipaddr.IPv4.parseCIDR(ip)[1])
        return false;
    } catch (e) {
      return true;
    }

    // 同じCIDR値でネットワークアドレスが異なる場合、false(上記でCIDR値であることは保証されているのでここではtry-catchは不要)
    if (
      ipaddr.IPv4.networkAddressFromCIDR(value).toString() !==
      ipaddr.IPv4.networkAddressFromCIDR(ip).toString()
    )
      return false;

    return true;
  },
} as ValidationRuleSchema;

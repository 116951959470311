


























































































































































































































































































































import Vue, { PropType } from "vue";
import PolicyIpPool from "@/components/policy/PolicyIpPool.vue";
import PolicyProfile from "@/components/policy/PolicyProfile.vue";
import PolicyService from "@/components/policy/PolicyService.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import { GetPolicyType4 } from "@/apis/NwPolicyApi";
import { Type4Service } from "@/apis/ContractChangeType4Api";

export default Vue.extend({
  name: "InternetType4PolicyIfwConfirm",
  components: {
    PolicyIpPool,
    PolicyProfile,
    PolicyService,
    PolicyDstAddress,
    PolicySrcAddress,
  },
  props: {
    /** type4ポリシー全体 */
    type4Policy: {
      type: Object as PropType<GetPolicyType4>,
      required: true,
    },
    /** サービスメニュー(インターネットType4) */
    type4Service: {
      type: Object as PropType<Type4Service>,
      required: true,
    },
  },
});

// 必ず最初にimportすること
import "./extensions";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSidebarMenu from "vue-sidebar-menu";
import VueFinalModal from "vue-final-modal";
import AppPlugin from "@/plugins/app.plugin";
import AppFilterPlugin from "@/plugins/app-filter.plugin";
import "@/css/main.scss";
import AppBootstrapPlugin from "@/plugins/app-bootstrap.plugin";
import MomentPlugin from "@/plugins/moment.plugin";
import VeeValidationPlugin from "@/plugins/vee-validation.plugin";
import appErrorHandler from "@/error/app-error-handler";

// define the setup options
declare module "vue/types/vue" {}

Vue.config.productionTip = false;

Vue.use(VueSidebarMenu);
Vue.use(AppBootstrapPlugin);
Vue.use(MomentPlugin);
Vue.use(VeeValidationPlugin);
Vue.use(VueFinalModal());
Vue.use(AppPlugin, { store });
Vue.use(AppFilterPlugin);

Vue.config.errorHandler = appErrorHandler;

(async () => {
  const tokenId = new URLSearchParams(window.location.search).get("tokenid");
  if (tokenId) {
    await store.dispatch("user/login", { tokenId });
    window.history.replaceState(null, "", "/");
  }

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})();





































































































































































































import Vue, { PropType } from "vue";
import TrafficApi, {
  ApplianceType,
  DisplayRange,
  LineElement,
  LineType,
  SessionResponse,
  SortPattern,
  StatisticReportType,
  StatisticsResponse,
  TrafficInfo,
  TrafficResponse,
} from "@/apis/TrafficApi";
import { AppTableData } from "@/components/AppTable.vue";
import { ThresholdInfoGetEntity } from "@/apis/ThresholdSettingApi";
import NotificationModify from "@/modals/common/NotificationModify.vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { GraphData, GraphLine } from "@/components/AppGraph.vue";
import { createThresholdInfoParams } from "@/views/trafficSessionStatistics/TrafficSessionStatistics.vue";
import { Moment } from "moment/moment";
import { ApiInternalServerError, ApiNotFoundError } from "@/error/errors";
import ApiErrorModal from "@/modals/error/ApiErrorModal.vue";

/** グラフ種類の選択肢 */
export type trafficOption =
  | "通信速度"
  | "非TF通信速度"
  | "通信速度(平均)"
  | "通信速度(最大)"
  | "非TF通信速度(平均)"
  | "非TF通信速度(最大)";

type sessionOption = "セッション数(平均)" | "セッション数(最大)";

export default Vue.extend({
  name: "TrafficSessionStatisticsGraph",
  props: {
    /** サービス */
    lineType: {
      type: String as PropType<LineType>,
      required: true,
    },
    /** 対象回線 */
    lines: {
      type: Array as PropType<LineElement[]>,
      required: true,
    },
    /** 表示範囲 */
    displayRange: {
      type: String as PropType<DisplayRange>,
      required: true,
    },
    /** 期間（形式: YYYY-MM-DD or YYYY-MM-DDTHH:mm） */
    period: {
      type: String as PropType<string>,
      required: true,
    },
    /** 統計情報-レポート種別（統計情報取得がONではに場合はundefined） */
    statisticReportType: {
      type: String as PropType<StatisticReportType>,
      default: undefined,
    },
    /** 統計情報-ソート順（統計情報取得がONではに場合はundefined） */
    sortPattern: {
      type: String as PropType<SortPattern | null>,
      default: undefined,
    },
    /** 回線毎のトラフィック情報、セッション情報、統計情報、閾値超過情報 */
    graphs: {
      type: Array as PropType<
        {
          line: LineElement;
          traffic?: null | TrafficResponse;
          session?: null | SessionResponse;
          // 統計情報が存在しない場合、404が返却されたため
          statistics?: null | StatisticsResponse | ApiNotFoundError;
          thresholdInfo?: null | ThresholdInfoGetEntity;
        }[]
      >,
      required: true,
    },
    /** true: 表示、false:非表示 */
    showLegend: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** 短縮Legendを表示するため
     * マルチクラウドタブ系の場合のみ指定 */
    shortLegend: {
      type: Array as PropType<{ wnumber: string; label: string }[]>,
      default: undefined,
    },
    /** Legendの表示位置 */
    legendPosition: {
      type: String as PropType<"bottom" | "right">,
      default: "right",
    },
    /** 画面幅制限よりX軸を減便表示したい場合、trueを指定
     * trueの場合、年次グラフのX軸の表示件数が6件まで表示 */
    reduceType: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      /** 閾値一覧の項目(特記事項２を参照) */
      threshold: {
        fields: [
          {
            key: "lineNumber",
            label: "回線番号",
            formatter: (
              value: string,
              key: null,
              item: ThresholdInfoGetEntity & LineElement
            ) => {
              switch (this.lineType) {
                case "ACCESS":
                  if (item.applianceType) {
                    return `${item.enumberMainAct}(${this.$filter.enumTo(
                      item.applianceType,
                      "trafficApplianceType"
                    )})`;
                  } else {
                    return item.enumberMainAct;
                  }
                case "VN":
                case "VNL2L3":
                  return item.wnumberMainAct;
                case "CLOUD":
                  if (item.isAssociate) {
                    return item.enumberMainAct;
                  } else {
                    return item.wnumberMainAct;
                  }
                case "INTERNET":
                  if (item.internetType === "TYPE1") {
                    if (item.applianceType) {
                      return `Type1(${this.$filter.enumTo(
                        item.applianceType,
                        "trafficApplianceType"
                      )})`;
                    } else {
                      return "Type1";
                    }
                  } else {
                    if (item.applianceType) {
                      return `Type4(${
                        item.wnumberMainAct
                      })(${this.$filter.enumTo(
                        item.applianceType,
                        "trafficApplianceType"
                      )})`;
                    } else {
                      return `Type4(${item.wnumberMainAct})`;
                    }
                  }
                case "MULTICLOUD":
                  if (item.wnumberMainAct) {
                    // マルチクラウド画面のタブ表示時、cloudServiceMenuを指定しないので、ガイドする
                    if (item.cloudServiceMenu) {
                      // VNコネクト単位
                      if (item.cloudServiceMenu?.startsWith("MS")) {
                        return `${this.$filter.cloudServiceMenuTo(
                          item.cloudServiceMenu!
                        )}${
                          item.isMsPeering
                            ? " Microsoft Peering"
                            : " Private Peering"
                        }(${item.wnumberMainAct})`;
                      } else {
                        return `${this.$filter.cloudServiceMenuTo(
                          item.cloudServiceMenu!
                        )}(${item.wnumberMainAct})`;
                      }
                    } else {
                      return item.wnumberMainAct;
                    }
                  } else {
                    // クラウド帯域単位
                    return `${this.$filter.cloudServiceMenuTo(
                      item.cloudServiceMenu!
                    )}(${item.enumberMainAct})`;
                  }
                case "CPA_5GSA":
                  return item.wnumberMainAct + `(${item.domain})`;
              }
            },
          },
          {
            key: "receiveThreshold",
            label: "通知閾値帯域(受信)",
            tdClass: "text-right",
            formatter: (
              value: string,
              key: null,
              item: ThresholdInfoGetEntity & LineElement
            ) => {
              if (item.receiveThreshold.value === 0) {
                return "-";
              } else {
                return this.$filter.bandwidthTo(item.receiveThreshold);
              }
            },
          },
          {
            key: "sendThreshold",
            label: "通知閾値帯域(送信)",
            tdClass: "text-right",
            formatter: (
              value: string,
              key: null,
              item: ThresholdInfoGetEntity & LineElement
            ) => {
              if (item.sendThreshold.value === 0) {
                return "-";
              } else {
                return this.$filter.bandwidthTo(item.sendThreshold);
              }
            },
          },
          {
            key: "mailAddressList",
            label: "通知先",
            formatter: (_v, _k, item: ThresholdInfoGetEntity & LineElement) => {
              if (item.mailAddressList.length === 0) {
                return "-";
              } else {
                return item.mailAddressList.join(", ");
              }
            },
          },
        ],
        items: [],
      } as Pick<
        AppTableData<ThresholdInfoGetEntity & LineElement>,
        "fields" | "items"
      >,
      /** 統計情報一覧の表示項目はソースコードで生成する */
      statisticsTable: {
        fields: [] as BvTableFieldArray,
      },
      /** 表示する受信トラフィックログ情報の選択値リスト */
      selectedReceiveGraph: [] as trafficOption[],
      /** 表示する送信トラフィックログ情報の選択値リスト */
      selectedSendGraph: [] as trafficOption[],
      /** 表示するセッション情報の選択値リスト */
      selectedSessionGraph: [] as sessionOption[],
      timeList: [] as string[],
    };
  },
  computed: {
    /** true: 変更可能, false: 変更不可 */
    changeable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
    /** 統計情報 */
    statistics(): (
      | (StatisticsResponse & {
          sessionMax: number | null | undefined;
          byteMax: bigint | null | undefined;
          detectMax: number | null | undefined;
        })
      | (ApiNotFoundError & LineElement)
    )[] {
      // 統計情報がnullではない回線を対象する
      const stat = this.graphs.filter((v) => v.statistics);
      if (stat.length === 0) {
        return [];
      } else {
        return stat.map((v) => {
          if (v.statistics instanceof ApiNotFoundError) {
            return { ...v.statistics, ...v.line };
          }
          let sessionMax, byteMax, detectMax;
          switch (this.statisticReportType) {
            case "APPLICATION":
            case "DESTINATION_ADDRESS":
            case "DESTINATION":
              sessionMax = v
                .statistics!.statisticsList.map((e) => e.sessionCount)
                .reduce((acc, value) => {
                  if (!value) {
                    return acc;
                  } else {
                    return acc === null ? value : Math.max(acc, value);
                  }
                }, null);
              byteMax = v
                .statistics!.statisticsList.map((e) => e.byteCount)
                .reduce((acc: bigint | null, value) => {
                  if (!value) {
                    return acc;
                  } else {
                    return acc === null
                      ? BigInt(value)
                      : acc > BigInt(value)
                      ? acc
                      : BigInt(value);
                  }
                }, null);
              break;
            case "SOURCE_ADDRESS_SESSIONS_BYTE":
              byteMax = v
                .statistics!.statisticsList.map((e) => e.byteCount)
                .reduce((acc: bigint | null, value) => {
                  if (!value) {
                    return acc;
                  } else {
                    return acc === null
                      ? BigInt(value)
                      : acc > BigInt(value)
                      ? acc
                      : BigInt(value);
                  }
                }, null);
              break;
            case "THREAT":
            case "SOURCE_ADDRESS_DETECTIONS":
            case "BLOCK_SOURCE_ADDRESS":
            case "VIRUS":
            case "BLOCK_URL":
              detectMax = v
                .statistics!.statisticsList.map((e) => e.detectCount)
                .reduce((acc, value) => {
                  if (!value) {
                    return acc;
                  } else {
                    return acc === null ? value : Math.max(acc, value);
                  }
                }, null);
              break;
            default:
              throw new Error(
                `unsupported statisticReportType: ${this.statisticReportType}`
              );
          }
          return { ...v.statistics!, sessionMax, byteMax, detectMax };
        });
      }
    },

    /** 表示するトラフィックログ情報の選択肢(受信) 特記事項4を参照 */
    trafficReceiveKindOptions(): trafficOption[] {
      if (this.displayRange === "DAILY") {
        if (this.lineType === "ACCESS" || this.lineType === "VN") {
          return ["通信速度", "非TF通信速度"];
        } else {
          return ["通信速度"];
        }
      } else {
        if (this.lineType === "ACCESS" || this.lineType === "VN") {
          return [
            "通信速度(平均)",
            "通信速度(最大)",
            "非TF通信速度(平均)",
            "非TF通信速度(最大)",
          ];
        } else {
          return ["通信速度(平均)", "通信速度(最大)"];
        }
      }
    },
    /** 表示するトラフィックログ情報の選択肢(送信) 特記事項4を参照 */
    trafficSendKindOptions(): trafficOption[] {
      if (this.displayRange === "DAILY") {
        return ["通信速度"];
      } else {
        return ["通信速度(平均)", "通信速度(最大)"];
      }
    },
    /** 表示するトラフィックログ情報の選択肢(送信) */
    /** トラフィックデータ(受信) */
    getTrafficReceiveData(): GraphData {
      // 表示できるグラフデータの取得
      const graph = this.graphs.filter(
        (v) => v.traffic && v.traffic.trafficInfoList.length > 0
      );
      if (graph.length === 0) {
        // グラフで表示できるデータがない場合
        return {
          timeList: [],
          dataListOdd: [],
          dataListEven: [],
          displayRange: this.displayRange,
        };
      } else {
        // 奇数色のデータリスト
        let dataListOdd: GraphLine[] = [];
        // 偶数色のデータリスト
        let dataListEven: GraphLine[] = [];
        this.selectedReceiveGraph.forEach((v, index) => {
          if (index === 0) {
            dataListOdd = graph.map((data) => {
              return {
                label: this.initGraphLabel(data.line, v),
                data: this.timeList.map((t) => {
                  const traffic = data.traffic!.trafficInfoList.find(
                    (d) => d.trafficDateTime === t
                  );
                  if (traffic) {
                    return this.getTrafficData("Receive", v, traffic);
                  } else {
                    return null;
                  }
                }),
              };
            });
          } else {
            dataListEven = graph.map((data) => {
              return {
                label: this.initGraphLabel(data.line, v),
                data: this.timeList.map((t) => {
                  const traffic = data.traffic!.trafficInfoList.find(
                    (d) => d.trafficDateTime === t
                  );
                  if (traffic) {
                    return this.getTrafficData("Receive", v, traffic);
                  } else {
                    return null;
                  }
                }),
              };
            });
          }
        });
        return {
          timeList: this.timeList,
          dataListOdd,
          dataListEven,
          displayRange: this.displayRange,
        };
      }
    },
    /** トラフィックデータ（送信） */
    getTrafficSendData(): GraphData {
      // 表示できるグラフデータの取得
      const graph = this.graphs.filter(
        (v) => v.traffic && v.traffic.trafficInfoList.length > 0
      );
      if (graph.length === 0) {
        // グラフで表示できるデータがない場合
        return {
          timeList: [],
          dataListOdd: [],
          dataListEven: [],
          displayRange: this.displayRange,
        };
      } else {
        // 奇数色のデータリスト
        let dataListOdd: GraphLine[] = [];
        // 偶数色のデータリスト
        let dataListEven: GraphLine[] = [];
        this.selectedSendGraph.forEach((v, index) => {
          if (index === 0) {
            dataListOdd = graph.map((data) => {
              return {
                label: this.initGraphLabel(data.line, v),
                data: this.timeList.map((t) => {
                  const traffic = data.traffic!.trafficInfoList.find(
                    (d) => d.trafficDateTime === t
                  );
                  if (traffic) {
                    return this.getTrafficData("Send", v, traffic);
                  } else {
                    return null;
                  }
                }),
              };
            });
          } else {
            dataListEven = graph.map((data) => {
              return {
                label: this.initGraphLabel(data.line, v),
                data: this.timeList.map((t) => {
                  const traffic = data.traffic!.trafficInfoList.find(
                    (d) => d.trafficDateTime === t
                  );
                  if (traffic) {
                    return this.getTrafficData("Send", v, traffic);
                  } else {
                    return null;
                  }
                }),
              };
            });
          }
        });
        return {
          timeList: this.timeList,
          dataListOdd,
          dataListEven,
          displayRange: this.displayRange,
        };
      }
    },
    /** セッションデータ */
    getSessionGraphData(): GraphData {
      // 表示できるグラフデータの取得
      let graph = this.graphs.filter(
        (v) => v.session && v.session.sessionInfoList.length > 0
      );
      if (graph.length === 0) {
        // グラフで表示できるデータがない場合
        return {
          timeList: [],
          dataListOdd: [],
          dataListEven: [],
          yLabel: "セッション",
          displayRange: this.displayRange,
        };
      } else {
        // 奇数色のデータリスト
        let dataListOdd: GraphLine[] = [];
        // 偶数色のデータリスト
        let dataListEven: GraphLine[] = [];
        if (this.lineType === "ACCESS") {
          // アクセス回線の場合、データは全く一緒なので、Sessionグラフは1件のみ保留
          graph = graph.slice(0, 1);
        } else if (this.lineType === "MULTICLOUD") {
          // マルチクラウドの場合、VPN/VNコードは同じレコードは、Sessionグラフは1件のみ保留
          graph = graph.uniq((v) => {
            return v.line.vpnVnCode;
          });
        }
        this.selectedSessionGraph.forEach((v, index) => {
          if (index === 0) {
            dataListOdd = graph.map((data) => {
              return {
                label: this.initSessionGraphLabel(data.line, v),
                data: this.timeList.map((t) => {
                  const session = data.session!.sessionInfoList.find(
                    (d) => d.sessionDateTime === t
                  );
                  if (session) {
                    return this.getSessionData(v, session);
                  } else {
                    return null;
                  }
                }),
              };
            });
          } else {
            dataListEven = graph.map((data) => {
              return {
                label: this.initSessionGraphLabel(data.line, v),
                data: this.timeList.map((t) => {
                  const session = data.session!.sessionInfoList.find(
                    (d) => d.sessionDateTime === t
                  );
                  if (session) {
                    return this.getSessionData(v, session);
                  } else {
                    return null;
                  }
                }),
              };
            });
          }
        });
        return {
          timeList: this.timeList,
          dataListOdd,
          dataListEven,
          yLabel: "セッション",
          displayRange: this.displayRange,
        };
      }
    },

    /** トラフィック、セッション、統計毎の回線一覧 */
    graphLines(): {
      traffic: LineElement[];
      session: LineElement[];
      statistics: LineElement[];
      thresholdInfo: LineElement[];
    } {
      return this.graphs.reduce(
        (acc, e) => ({
          traffic: e.traffic ? [...acc.traffic, e.line] : acc.traffic,
          session: e.session ? [...acc.session, e.line] : acc.session,
          statistics: e.statistics
            ? [...acc.statistics, e.line]
            : acc.statistics,
          thresholdInfo: e.thresholdInfo
            ? [...acc.thresholdInfo, e.line]
            : acc.thresholdInfo,
        }),
        {
          traffic: [] as LineElement[],
          session: [] as LineElement[],
          statistics: [] as LineElement[],
          thresholdInfo: [] as LineElement[],
        }
      );
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      // 画面の入力時刻からグラフデータの時刻リストを作成
      // バックのデータへ依存できないので、Frontでやる
      if (this.displayRange === "DAILY") {
        const inputEndDt = this.$moment(this.period);
        const inputStartDt = inputEndDt.clone().add(-24, "hours");
        // 12:01 -> 12:05、12:06 -> 12:10
        const ROUNDING = 5 * 60 * 1000;
        const backStart = this.$moment(
          Math.ceil(+inputStartDt / ROUNDING) * ROUNDING
        );
        // バック返却データが5分間隔で24時間のデータ
        const backEnd = this.$moment(
          Math.ceil(+inputEndDt / ROUNDING) * ROUNDING
        );
        this.timeList = inputStartDt.isBefore(backStart)
          ? [inputStartDt.format("YYYY-MM-DDTHH:mm:ssZ")]
          : [];
        for (let d = backStart.clone(); d <= backEnd; d.add(5, "minutes")) {
          this.timeList.push(d.format("YYYY-MM-DDTHH:mm:ssZ"));
        }
      } else if (this.displayRange === "WEEKLY") {
        const inputEndDt = this.$moment(this.period).startOf("day");
        const inputStartDt = inputEndDt.clone().add(-6, "days");
        const backStart = inputStartDt;
        // バック返却データが30分間隔で7日間のデータ
        const backEnd = inputEndDt
          .clone()
          .set({ hour: 23, minute: 30, second: 0, millisecond: 0 });
        for (let d = backStart.clone(); d <= backEnd; d.add(30, "minutes")) {
          this.timeList.push(d.format("YYYY-MM-DDTHH:mm:ssZ"));
        }
      } else if (this.displayRange === "MONTHLY") {
        const inputEndDt = this.$moment(this.period).startOf("day");
        const inputStartDt = inputEndDt.clone().add(-29, "days");
        const backStart = inputStartDt;
        // バック返却データが2時間間隔で30日間のデータ
        const backEnd = inputEndDt
          .clone()
          .set({ hour: 22, minute: 0, second: 0, millisecond: 0 });
        for (let d = backStart.clone(); d <= backEnd; d.add(2, "hours")) {
          this.timeList.push(d.format("YYYY-MM-DDTHH:mm:ssZ"));
        }
      } else if (this.displayRange === "YEARLY") {
        const inputEndDt = this.$moment().startOf("day");
        const inputStartDt = inputEndDt.clone().add(-364, "days");
        const backStart = inputStartDt;
        // バック返却データが24時間間隔で365日間のデータ
        const backEnd = inputEndDt;
        for (let d = backStart.clone(); d <= backEnd; d.add(1, "days")) {
          this.timeList.push(d.format("YYYY-MM-DDTHH:mm:ssZ"));
        }
      }

      // Traffic受信プルダウンの初期値
      this.selectedReceiveGraph = [this.trafficReceiveKindOptions[0]];
      // Traffic送信プルダウンの初期値
      this.selectedSendGraph = [this.trafficSendKindOptions[0]];
      // トラフィック閾値超過設定一覧のデータ設定
      this.threshold.items = this.graphs
        .filter((v) => v.thresholdInfo)
        .map((v) => {
          return { ...v.thresholdInfo!, ...v.line };
        });
      // セッショングラフのプルダウンの初期値
      if (this.graphLines.session.length > 0) {
        this.selectedSessionGraph = ["セッション数(平均)"];
      }
      // 統計情報一覧の表示項目設定
      if (this.graphLines.statistics.length > 0) {
        this.initStatisticReport();
      }
    },

    /** レポート種別により統計情報の出力項目を設定 */
    initGraphLabel(
      line: LineElement,
      option: trafficOption | sessionOption
    ): string {
      switch (this.lineType) {
        case "ACCESS":
          if (line.applianceType) {
            return `${line.enumberMainAct}(${this.$filter.enumTo(
              line.applianceType,
              "trafficApplianceType"
            )})${option}`;
          } else {
            return `${line.enumberMainAct} ${option}`;
          }
        case "INTERNET":
          if (line.internetType === "TYPE1") {
            if (line.applianceType) {
              return `Type1(${this.$filter.enumTo(
                line.applianceType,
                "trafficApplianceType"
              )})${option}`;
            } else {
              return `Type1 ${option}`;
            }
          } else {
            if (line.applianceType) {
              return `Type4(${line.wnumberMainAct})(${this.$filter.enumTo(
                line.applianceType,
                "trafficApplianceType"
              )})${option}`;
            } else {
              return `Type4(${line.wnumberMainAct}) ${option}`;
            }
          }
        case "VN":
          return `${line.wnumberMainAct}(${line.vpnVnCode}) ${option}`;
        case "CLOUD":
          if (line.isAssociate) {
            return `${line.enumberMainAct}(${line.wnumberMainAct}) ${option}`;
          } else {
            return `${line.wnumberMainAct} ${option}`;
          }
        case "VNL2L3":
          return `${line.wnumberMainAct}(${line.enumberMainAct},${line.l2VnCode}⇔${line.l3VpnVnCode}) ${option}`;
        case "MULTICLOUD":
          if (this.shortLegend) {
            // 短縮表示がある場合
            return `${
              this.shortLegend.find((v) => v.wnumber === line.wnumberMainAct)!
                .label
            } ${option}`;
          } else {
            if (line.wnumberMainAct) {
              // VNコネクト単位の場合
              if (line.cloudServiceMenu!.startsWith("MS")) {
                return `${this.$filter.cloudServiceMenuTo(
                  line.cloudServiceMenu!
                )}${
                  line.isMsPeering ? " Microsoft Peering" : " Private Peering"
                }(${line.wnumberMainAct}) ${option}`;
              } else {
                return `${this.$filter.cloudServiceMenuTo(
                  line.cloudServiceMenu!
                )}(${line.wnumberMainAct}) ${option}`;
              }
            } else {
              // クラウド帯域単位の場合
              return `${this.$filter.cloudServiceMenuTo(
                line.cloudServiceMenu!
              )}(${line.enumberMainAct}) ${option}`;
            }
          }
        case "CPA_5GSA":
          return `${line.wnumberMainAct}(${line.domain}) ${option}`;
      }
    },
    /** レポート種別によりSessionグラフの凡例の出力項目を設定 */
    initSessionGraphLabel(
      line: LineElement,
      option: trafficOption | sessionOption
    ) {
      switch (this.lineType) {
        case "ACCESS":
          return `${option}`;
        case "INTERNET":
          if (line.internetType === "TYPE1") {
            return `Type1(${this.$filter.enumTo(
              line.applianceType,
              "trafficApplianceType"
            )})${option}`;
          } else {
            return `Type4(${line.wnumberMainAct})(${this.$filter.enumTo(
              line.applianceType,
              "trafficApplianceType"
            )})${option}`;
          }
        case "MULTICLOUD":
          // VN単位 + MSの場合のみセッションが表示
          return `${line.vpnVnCode}${option}`;
        default:
          console.warn(this.lineType);
          throw new Error(`unsupported line Type`);
      }
    },
    /** レポート種別により統計情報の出力項目を設定 */
    initStatisticReport() {
      /** レポート種別により統計情報の出力条件 */
      switch (this.statisticReportType) {
        case "APPLICATION":
          this.statisticsTable.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "risk",
              label: "リスク",
            },
            {
              key: "applicationName",
              label: "アプリケーション名",
            },
            {
              key: "sessionCount",
              label: "セッション数(セッション/日)",
            },
            {
              key: "byteCount",
              label: "バイト(バイト/日)",
            },
          ];
          break;
        /** 通信元アドレス */
        case "SOURCE_ADDRESS_SESSIONS_BYTE":
          this.statisticsTable.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "srcIpAddress",
              label: "通信元アドレス",
            },
            {
              key: "byteCount",
              label: "バイト(バイト/日)",
            },
          ];
          break;
        /** 通信先アドレス */
        case "DESTINATION_ADDRESS":
          this.statisticsTable.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "dstIpAddress",
              label: "通信先アドレス",
            },
            {
              key: "sessionCount",
              label: "セッション数(セッション/日)",
            },
            {
              key: "byteCount",
              label: "バイト(バイト/日)",
            },
          ];
          break;
        /** 通信先（国別） */
        case "DESTINATION":
          this.statisticsTable.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "dstCountry",
              label: "通信先(国別)",
            },
            {
              key: "sessionCount",
              label: "セッション数(セッション/日)",
            },
            {
              key: "byteCount",
              label: "バイト(バイト/日)",
            },
          ];
          break;
        /** 脅威 */
        case "THREAT":
          this.statisticsTable.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "risk",
              label: "リスク",
            },
            {
              key: "threatName",
              label: "脅威名",
            },
            {
              key: "threatId",
              label: "TID",
            },
            {
              key: "threatType",
              label: "脅威種別",
            },
            {
              key: "detectCount",
              label: "検知回数",
            },
          ];
          break;
        /** 通信元アドレス(検知回数) */
        case "SOURCE_ADDRESS_DETECTIONS":
          this.statisticsTable.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "srcIpAddress",
              label: "通信元アドレス",
            },
            {
              key: "detectCount",
              label: "検知回数",
            },
          ];
          break;
        /** ブロック通信元アドレス */
        case "BLOCK_SOURCE_ADDRESS":
          this.statisticsTable.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "srcIpAddress",
              label: "通信元アドレス",
            },
            {
              key: "detectCount",
              label: "検知回数",
            },
          ];
          break;
        /** ウイルス */
        case "VIRUS":
          this.statisticsTable.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "risk",
              label: "リスク",
            },
            {
              key: "threatName",
              label: "脅威名",
            },
            {
              key: "threatId",
              label: "TID",
            },
            {
              key: "threatType",
              label: "脅威種別",
            },
            {
              key: "detectCount",
              label: "検知回数",
            },
          ];
          break;
        /** ブロック URL */
        case "BLOCK_URL":
          this.statisticsTable.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "blockUrl",
              label: "ブロックURL",
            },
            {
              key: "categoryName",
              label: "カテゴリ名",
            },
            {
              key: "detectCount",
              label: "検知回数",
            },
          ];
          break;
        default:
          throw new Error(
            `unsupported statisticReportType: ${this.statisticReportType}`
          );
      }
    },
    getTrafficData(
      sendOrReceive: "Send" | "Receive",
      trafficKind: trafficOption,
      trafficInfo: TrafficInfo["trafficInfoList"][0]
    ) {
      if (sendOrReceive === "Receive") {
        switch (trafficKind) {
          case "通信速度":
            return trafficInfo.receiveAverage;
          case "非TF通信速度":
            return trafficInfo.receiveNonTfAverage;
          case "通信速度(平均)":
            return trafficInfo.receiveAverage;
          case "通信速度(最大)":
            return trafficInfo.receiveMax;
          case "非TF通信速度(平均)":
            return trafficInfo.receiveNonTfAverage;
          case "非TF通信速度(最大)":
            return trafficInfo.receiveNonTfMax;
          default:
            return null;
        }
      } else {
        switch (trafficKind) {
          case "通信速度":
            return trafficInfo.sendAverage;
          case "通信速度(平均)":
            return trafficInfo.sendAverage;
          case "通信速度(最大)":
            return trafficInfo.sendMax;
          default:
            return null;
        }
      }
    },
    getSessionData(
      sessionKind: sessionOption,
      sessionInfo: SessionResponse["sessionInfoList"][0]
    ) {
      switch (sessionKind) {
        case "セッション数(平均)":
          return sessionInfo.averageCount;
        case "セッション数(最大)":
          return sessionInfo.maxCount;
        default:
          return null;
      }
    },
    /** バイト単位変換
     * 少数点第2位を四捨五入して、第一位まで表示 特記事項13*/
    convertByteUnit(count: string | null): string {
      if (!count) {
        return "";
      }
      const base = 1000;
      // Byte
      if (BigInt(count) < base) {
        return `${count}B`;
      }
      // KByte
      if (BigInt(count) < Math.pow(base, 2)) {
        return `${(Math.round((Number(count) / base) * 10) / 10).toFixed(1)}KB`;
      }
      // MByte
      if (BigInt(count) < Math.pow(base, 3)) {
        return `${(
          Math.round((Number(count) / Math.pow(base, 2)) * 10) / 10
        ).toFixed(1)}MB`;
      }
      // GByte
      return `${(
        Math.round(Number(BigInt(count) / BigInt(Math.pow(base, 3))) * 10) / 10
      ).toFixed(1)}GB`;
    },

    /** 通知先変更 */
    async showNotificationModify(
      info: ThresholdInfoGetEntity &
        LineElement & { applianceType: Exclude<ApplianceType, "NAT"> | null },
      index: number
    ) {
      if (!this.changeable) {
        return;
      }
      await this.$modal.show(NotificationModify, {
        lineType: this.lineType,
        line: info,
        thresholdInfo: info,
      });
      this.$set(this.threshold.items, index, {
        ...info,
        ...(await this.$api.thresholdSetting.getThresholdInfo(
          createThresholdInfoParams(this.lineType, info)
        )),
      });
    },

    /** トラフィックCSVダウンロード */
    async downloadTraffic() {
      await this.download(
        createCsvReqBody(
          this.lineType,
          "TRAFFIC",
          this.graphLines.traffic,
          this.displayRange,
          undefined,
          undefined,
          this.$moment(this.period)
        )
      );
    },

    /** セッションCSVダウンロード */
    async downloadSession() {
      let sessionTargetLines: LineElement[] = [];
      if (this.lineType === "ACCESS") {
        sessionTargetLines = [
          this.graphLines.session.find(
            (v) => v.applianceType === "INTRANET_FW"
          )!,
        ];
      } else if (this.lineType === "MULTICLOUD") {
        sessionTargetLines = this.graphLines.session.uniq("vpnVnCode");
      } else {
        sessionTargetLines = this.graphLines.session;
      }
      await this.download(
        createCsvReqBody(
          this.lineType,
          "SESSION",
          sessionTargetLines,
          this.displayRange,
          undefined,
          undefined,
          this.$moment(this.period)
        )
      );
    },

    /** 統計情報CSVダウンロード */
    async downloadStatistics() {
      await this.download(
        createCsvReqBody(
          this.lineType,
          "STATISTICS",
          this.graphLines.statistics,
          this.displayRange,
          this.statisticReportType,
          this.sortPattern,
          this.$moment(this.period)
        )
      );
    },

    /** トラフィック、セッション、統計のグラフのCSVダウンロード */
    async download(params: Parameters<TrafficApi["getTrafficCsv"]>[0]) {
      try {
        const trafficCsv = await this.$api.traffic.getTrafficCsv(params);
        this.$service.file.download(
          this.$service.file.getFileName(trafficCsv)!,
          trafficCsv.data,
          trafficCsv.headers["content-type"]
        );
      } catch (e) {
        // 500 トラフィック情報の取得に失敗 | セッション情報の取得に失敗 | 統計情報の取得に失敗;
        if (
          e instanceof ApiInternalServerError &&
          (e.data.errorCode === "A722023W" ||
            e.data.errorCode === "A722024W" ||
            e.data.errorCode === "A722025W")
        ) {
          await this.$modal.show(ApiErrorModal, { error: e });
          return;
        }
        throw e;
      }
    },

    /** NotFoundErrorかどうかの判定 */
    isApiNotFound(statistic: unknown): boolean {
      return (
        typeof statistic === "object" &&
        statistic !== null &&
        "data" in statistic
      );
    },
  },
});

/** CSVダウンロードAPIのボディを生成 */
function createCsvReqBody(
  lineType: LineType,
  outputType: "TRAFFIC" | "SESSION" | "STATISTICS",
  lines: LineElement[],
  displayRange: DisplayRange,
  statisticReportType: StatisticReportType | undefined,
  sortPattern: SortPattern | null | undefined,
  period: Moment
): Parameters<TrafficApi["getTrafficCsv"]>[0] {
  return {
    lineType: lineType,
    outputType: outputType,
    searchKeyCsv: lines.map((line) => {
      switch (lineType) {
        case "ACCESS":
          return {
            enumberMainAct:
              outputType === "SESSION" ? null : line.enumberMainAct,
            applianceType:
              line.applianceType === "INTRANET_FW" ? "INTRANET_FW" : undefined,
          };
        case "INTERNET":
          return {
            internetType: line.internetType,
            type4Id: line.type4Id,
            applianceType: line.applianceType,
          };
        case "VN":
        case "CLOUD":
        case "VNL2L3":
        case "CPA_5GSA":
          return { wnumberMainAct: line.wnumberMainAct };
        case "MULTICLOUD":
          if (outputType === "TRAFFIC") {
            return {
              enumberMainAct: line.wnumberMainAct
                ? undefined
                : line.enumberMainAct,
              wnumberMainAct: line.wnumberMainAct,
              cloudServiceMenu: line.wnumberMainAct
                ? undefined
                : line.cloudServiceMenu,
            };
          } else {
            // ルチクラウド種別の場合、NAT固定
            return {
              applianceType: "NAT",
              vpnVnCode: line.vpnVnCode,
            };
          }
      }
    }),
    statisticReportType: statisticReportType,
    sortPattern: sortPattern,
    displayRange: displayRange,
    ...(displayRange === "DAILY"
      ? {
          periodDate: period.format("YYYY-MM-DD"),
          periodTime: period.format("HH:mm"),
        }
      : displayRange === "YEARLY"
      ? { periodDate: null, periodTime: null }
      : { periodDate: period.format("YYYY-MM-DD"), periodTime: null }),
  };
}

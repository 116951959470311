












































































































































































































































































import Vue, { PropType } from "vue";
import { VNConnectDetail } from "@/apis/VNConnectApi";
import { BandwidthListGetEntity } from "@/apis/AccessLineApi";

export default Vue.extend({
  name: "VNConnectDeleteConfirm",
  props: {
    wnumber: {
      type: String as PropType<string>,
      required: true,
    },
    connect: {
      type: Object as PropType<VNConnectDetail>,
      required: true,
    },
    /** 回線契約状況、回線種別によっては存在しないのでその場合はなし */
    lineBandwidth: {
      type: Object as PropType<BandwidthListGetEntity>,
      required: false,
    },
  },
});









































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { IpMapping, IpMappings } from "@/apis/IpMappingApi";
import IpMappingReference from "@/modals/applianceObject/IpMappingReference.vue";
import {
  Type1ExtraInfo,
  Type1PrivateInfo,
  Type1Zone,
} from "@/apis/Type1SiteApi";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import IpMappingType1Edit from "@/modals/applianceObject/IpMappingType1Edit.vue";

export type Type1IpMappingItem = IpMappings["ipMappingList"][0] & {
  dstZone: {
    type: "EXTRA" | "PRIVATE";
    zone: Type1Zone;
  } | null;
};

export default Vue.extend({
  name: "Type1IpMapping",
  data() {
    return {
      ipMappingAlertMsg: "",
      ipMapping: {
        fields: [
          { key: "ipMappingName", label: "名前", sortable: true },
          { key: "globalIpAddress", label: "グローバルIP", sortable: true },
          {
            key: "dstZone",
            label: "宛先ゾーン",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (
              value: Type1IpMappingItem["dstZone"]
            ): string | null => {
              if (value) {
                return value.zone.zoneName;
              } else {
                return null;
              }
            },
          },
          { key: "privateIpAddress", label: "プライベートIP", sortable: true },
          { key: "isPortForward", label: "ポートフォワード", sortable: true },
          {
            key: "description",
            label: "備考",
            tdClass: "text-pre-wrap",
            sortable: true,
          },
          { key: "isPolicyInUse", label: "ポリシー使用有無", sortable: true },
        ],
        selected: [],
        search: {
          ipMappingName: { type: "text", label: "名前" },
          globalIpAddress: { type: "text", label: "グローバルIP" },
          dstZone: { type: "text", label: "宛先ゾーン" },
          privateIpAddress: { type: "text", label: "プライベートIP" },
          isPortForward: {
            type: "select",
            label: "ポートフォワード",
            items: [
              { value: true, text: "有効" },
              { value: false, text: "無効" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          ipMappingName: "like",
          globalIpAddress: "like",
          dstZone: "like",
          privateIpAddress: "like",
          isPortForward: "equal",
        },
      } as Omit<AppTableData<IpMapping>, "items">,
      zoneList: [] as ZoneRelationItem[],
      type1IpMapping: {} as IpMappings,
      type1Extra: {} as Type1ExtraInfo,
      type1Private: {} as Type1PrivateInfo,
      isLoaded: false,
    };
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
    /** テーブル一覧 */
    items(): Type1IpMappingItem[] {
      const extraAddressById = this.type1Extra.siteList
        .flatMap((e) => e.addressList)
        .toMap((e) => e.ipAddressId);
      const privateZoneById = this.type1Private.zoneList.toMap((e) => e.zoneId);
      const privateAddressById = this.type1Private.lineList
        .flatMap((e1) => e1.siteList.flatMap((e2) => e2.addressList))
        .toMap((e) => e.ipAddressId);

      // バックエンドのバグ、サイト内にアドレスが重複して設定された場合、
      // 後に追加したアドレスのゾーンに宛先ゾーンが変わってしまう またはゾーン未配置になることがある。
      // そのためIPマッピングで設定されたアドレスがサイト内で重複して存在する場合、宛先ゾーンにnullを返却
      return this.type1IpMapping.ipMappingList.map((e) => {
        const duplicationCnt =
          Object.values(extraAddressById).filter(
            (e1) => e1.ipAddress === e.privateIpAddress
          ).length +
          Object.values(privateAddressById).filter(
            (e1) => e1.ipAddress === e.privateIpAddress
          ).length;
        // エクストラサイトとプライベートサイトに同じアドレスが複数存在する場合は宛先ゾーンをnull
        if (duplicationCnt > 1) {
          return {
            ...e,
            dstZone: null,
          };
        } else {
          // プライベートIPアドレスがエクストラとプライベートのどちらに所属しているか確認してマッピング
          // エクストラ/プライベートサイトにアドレスがひとつのみ存在する場合、宛先ゾーンを返却
          if (extraAddressById[e.ipAddressId]) {
            return {
              ...e,
              dstZone: { type: "EXTRA", zone: this.type1Extra.zone! },
            };
          } else {
            const zoneId = privateAddressById[e.ipAddressId]!.zoneId!;
            return {
              ...e,
              dstZone: { type: "PRIVATE", zone: privateZoneById[zoneId] },
            };
          }
        }
      });
    },
    /** true: グローバル → エクストラ or プライベートのゾーン接続が存在する, false: 存在しない */
    hasZone(): boolean {
      return this.zoneList.some(
        (e) =>
          e.srcZoneType === "INTERNET" &&
          (e.dstZoneType === "EXTRA" || e.dstZoneType === "PRIVATE")
      );
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.zoneList = (
        await this.$api.zoneRelation.getZoneRelation()
      ).zoneRelationList;
      this.type1IpMapping = await this.$api.ipMapping.getType1IpMappingList();
      this.type1Extra = await this.$api.type1SiteZone.getType1ExtraList();
      this.type1Private = await this.$api.type1SiteZone.getType1PrivateList();

      // チェックボックスの状態はデータを再取得しても残るので、ここでクリアしておく
      if (this.ipMapping.selected.length) {
        this.ipMapping.selected = [];
      }
    },

    /**
     * IPマッピング参照モーダルを表示する
     * @param row テーブルで選択された行データ
     */
    async showIpMappingReference(row: Type1IpMappingItem) {
      this.ipMappingAlertMsg = "";
      await this.$modal.show(IpMappingReference, {
        ipMapping: row,
        ipMappingKeyId: this.type1IpMapping.ipMappingKeyId,
      });
      // 各タブの情報を再取得する
      this.$emit("reload");
    },

    /** IPマッピング追加モーダルを表示する */
    async showIpMappingRegister() {
      if (!this.hasZone) {
        this.ipMappingAlertMsg =
          "ゾーン接続設定後に、IPマッピング設定を行ってください。";
        return;
      } else {
        this.ipMappingAlertMsg = "";
      }

      await this.$modal.show(IpMappingType1Edit, {
        ipMappingKeyId: this.type1IpMapping.ipMappingKeyId,
      });
      // 各タブの情報を再取得する
      this.$emit("reload");
    },

    /** IPマッピング削除確認モーダルを表示する */
    async deleteIpMapping() {
      // チェックボックスが選択1つも選択されていない場合、アラート表示して処理終了
      if (this.ipMapping.selected.length <= 0) {
        // アラートを表示する
        this.ipMappingAlertMsg = this.$filter.message("no_select_data");
        return;
      } else {
        // アラートを非表示にする
        this.ipMappingAlertMsg = "";
      }

      await this.$confirm(
        `IPマッピングを${this.ipMapping.selected.length}件削除します。よろしいですか？`
      );
      await this.$api.ipMapping.deleteType1IpMapping({
        ipMappingList: this.ipMapping.selected.map(
          (e) => e.virtualIpAddressSeq
        ),
        ipMappingKeyId: this.type1IpMapping.ipMappingKeyId,
        note: null,
      });
      // 各タブの情報を再取得する
      this.$emit("reload");
    },

    /** エラーメッセージを初期化する(親画面から呼び出される) */
    async alertClear() {
      this.ipMappingAlertMsg = "";
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-medium","title":"SaaS利用設定"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"VPN/VNコード","isInfo":false,"isRequired":!_vm.saas}})]},proxy:true}],null,true)},[(!_vm.saas)?_c('validation-provider',{attrs:{"name":"VPN/VNコード","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('app-multiselect',{attrs:{"options":_vm.vpnVnCodeOptions,"label":"vpnVnCode","track-by":"vpnVnCode","group-label":"vpnVnCodeLabel","group-values":"vpnVnCodeValues","multiple":false,"custom-label":_vm.formatConnectDest,"state":_vm._f("validState")(validContext)},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [(option.$isLabel)?[_c('div',{staticClass:"multiselect-thead"},[_c('p',{staticClass:"col-3 p-0"},[_vm._v(" "+_vm._s(option.$groupLabel.vpnVnCode)+" ")]),_c('p',{staticClass:"col-8 p-0"},[_vm._v(_vm._s(option.$groupLabel.vnName))])])]:_vm._e(),_c('div',{staticClass:"multiselect-tbody"},[_c('p',{staticClass:"col-3 p-0"},[_vm._v(_vm._s(option.vpnVnCode))]),_c('p',{staticClass:"col-8 p-0"},[_vm._v(_vm._s(option.vnName))])])]}}],null,true),model:{value:(_vm.form.connectDest),callback:function ($$v) {_vm.$set(_vm.form, "connectDest", $$v)},expression:"form.connectDest"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}):_c('span',{staticClass:"ml-3"},[(_vm.saas.saasCommon.vnName)?_c('span',[_vm._v(_vm._s(_vm.saas.saasCommon.vnName + " : "))]):_vm._e(),_vm._v(" "+_vm._s(_vm.saas.saasCommon.vpnVnCode)+" ")])],1),_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Microsoft Peering")]},proxy:true}],null,true)},[_c('b-form-radio-group',{attrs:{"options":_vm.usageOptions},model:{value:(_vm.form.hasMSPeering),callback:function ($$v) {_vm.$set(_vm.form, "hasMSPeering", $$v)},expression:"form.hasMSPeering"}})],1),_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"NAPTアドレス上限数","isRequired":_vm.form.hasMSPeering,"isInfo":_vm.form.hasMSPeering,"msg":_vm._f("msg")('info_half_width_number')}})]},proxy:true}],null,true)},[(_vm.form.hasMSPeering)?_c('validation-provider',{attrs:{"name":"NAPTアドレス上限数","rules":{
              required: true,
              numeric: true,
              between: { min: 1, max: 100 },
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input-number',{staticClass:"col-4",attrs:{"disabled":!_vm.form.hasMSPeering,"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.msUpperLimitNapt),callback:function ($$v) {_vm.$set(_vm.form, "msUpperLimitNapt", _vm._n($$v))},expression:"form.msUpperLimitNapt"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}):_c('b-form-input',{staticClass:"col-4",attrs:{"disabled":""}})],1),_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"NATアドレス上限数","isRequired":_vm.form.hasMSPeering,"isInfo":_vm.form.hasMSPeering,"msg":_vm._f("msg")('info_half_width_number')}})]},proxy:true}],null,true)},[(_vm.form.hasMSPeering)?_c('validation-provider',{attrs:{"name":"NATアドレス上限数","rules":{
              required: true,
              numeric: true,
              between: { min: 0, max: 100 },
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input-number',{staticClass:"col-4",attrs:{"disabled":!_vm.form.hasMSPeering,"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.msUpperLimitNat),callback:function ($$v) {_vm.$set(_vm.form, "msUpperLimitNat", _vm._n($$v))},expression:"form.msUpperLimitNat"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}):_c('b-form-input',{staticClass:"col-4",attrs:{"disabled":""}})],1),_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Salesforce")]},proxy:true}],null,true)},[_c('b-form-radio-group',{attrs:{"options":_vm.usageOptions},model:{value:(_vm.form.hasSfdc),callback:function ($$v) {_vm.$set(_vm.form, "hasSfdc", $$v)},expression:"form.hasSfdc"}})],1),_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"NAPTアドレス上限数","isRequired":_vm.form.hasSfdc,"isInfo":_vm.form.hasSfdc,"msg":_vm._f("msg")('info_half_width_number')}})]},proxy:true}],null,true)},[(_vm.form.hasSfdc)?_c('validation-provider',{attrs:{"name":"NAPTアドレス上限数","rules":{
              required: true,
              numeric: true,
              between: { min: 1, max: 5 },
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input-number',{staticClass:"col-4",attrs:{"disabled":!_vm.form.hasSfdc,"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.sfdcUpperLimitNapt),callback:function ($$v) {_vm.$set(_vm.form, "sfdcUpperLimitNapt", _vm._n($$v))},expression:"form.sfdcUpperLimitNapt"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}):_c('b-form-input',{staticClass:"col-4",attrs:{"disabled":""}})],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saas ? "変更" : "追加"))]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)],1)]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
















































































import Vue, { PropType } from "vue";
import { CustomServiceIntranet } from "@/apis/CustomServiceApi";
import { AppTableData } from "@/components/AppTable.vue";
import ServiceIntranetFwEdit from "@/modals/applianceObject/ServiceIntranetFwEdit.vue";
import ServiceIntranetFwReference from "@/modals/applianceObject/ServiceIntranetFwReference.vue";

export default Vue.extend({
  name: "IntranetFwCustomServiceList",
  props: {
    /**
     * ポリシー種別
     * INTRANET_FW ：イントラネットFW
     * INTRANET_FW_PFGW ：イントラネットFW（PFGW）
     */
    policyType: {
      type: String as PropType<"INTRANET_FW" | "INTRANET_FW_PFGW">,
      required: true,
    },
    /**
     * E番号(ACT)
     * ポリシー種別でINTRANET_FW_PFGWを指定時必須
     */
    enumberAct: {
      type: String as PropType<string>,
      default: undefined,
    },
  },
  data() {
    return {
      service: {
        fields: [
          { key: "serviceName", label: "名前", sortable: true },
          { key: "protocol", label: "プロトコル", sortable: true },
          {
            key: "protocolInfo",
            label: "Port/プロトコル番号/Type(Code)",
            sortable: true,
            formatter: (_, __, item: CustomServiceIntranet) => {
              if (item.protocol === "IP") {
                // プロトコルがIPの場合にはプロトコル番号を表示
                return item.protocolNumber;
              } else if (item.protocol === "ICMP") {
                // プロトコルがICMPの場合にはICMPタイプ,ICMPコードの形式で表示
                return `${item.icmpType}(${item.icmpCode})`;
              } else {
                // プロトコルがTCPかUDPの場合にはポート番号を表示
                return item.portNumber;
              }
            },
            sortByFormatted: true,
            filterByFormatted: true,
          },
          {
            key: "description",
            label: "備考",
            tdClass: "text-pre-wrap",
            sortable: true,
          },
          { key: "isPolicyInUse", label: "ポリシー使用有無", sortable: true },
        ],
        items: [],
        selected: [],
        search: {
          serviceName: { type: "text", label: "名前" },
          protocolInfo: {
            type: "text",
            label: "Port/プロトコル番号/Type(Code)",
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          serviceName: "like",
          protocolInfo: "like",
        },
      } as AppTableData<CustomServiceIntranet>,
      intraCustomServiceKeyId: "",
      alertMsg: "",
      isLoaded: false,
    };
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      const res = await this.$api.customServiceApi.getIntranetfwCustomService(
        this.policyType,
        this.enumberAct
      );
      this.service.items = res.customServiceIntranetList;
      this.intraCustomServiceKeyId = res.customServiceKeyId;
    },

    /** サービス（イントラネットFW）設定登録モーダルを表示する */
    async showRegister() {
      this.alertMsg = "";
      await this.$modal.show(ServiceIntranetFwEdit, {
        policyType: this.policyType,
        enumberAct: this.enumberAct,
        customServiceKeyId: this.intraCustomServiceKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /**
     * サービス（イントラネットFW）設定参照モーダルを表示する
     * @param entity 選択した行のデータ
     */
    async showReference(entity: CustomServiceIntranet) {
      this.alertMsg = "";
      await this.$modal.show(ServiceIntranetFwReference, {
        policyType: this.policyType,
        enumberAct: this.enumberAct,
        customServiceIntranet: entity,
        customServiceKeyId: this.intraCustomServiceKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** サービス（イントラネットFW）設定の一括削除処理 */
    async deleteService() {
      // チェックボックスが選択1つも選択されていない場合、アラート表示して処理終了
      if (this.service.selected.length === 0) {
        // アラートを表示する
        this.alertMsg = this.$filter.message("no_select_data");
        return;
      } else {
        // アラートを非表示にする
        this.alertMsg = "";
      }

      await this.$confirm(
        `個別サービスを${this.service.selected.length}件削除します。よろしいですか？`
      );
      await this.$api.customServiceApi.deleteIntranetfwCustomService(
        this.policyType,
        this.enumberAct,
        {
          customServiceSeqList: this.service.selected.map(
            (e) => e.customServiceSeq
          ),
          customServiceKeyId: this.intraCustomServiceKeyId,
        }
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});





















































import Vue, { PropType } from "vue";
import {
  IntranetFwPolicy,
  IntraPolicy,
  PostZonePolicyInfo,
  ZonePolicyInfo,
} from "@/apis/IntranetfwPolicyApi";
import IntranetFwPfgwPolicyList from "@/modals/applianceContractSetting/IntranetFwPfgwPolicyList.vue";
import IntranetFwPfgwPolicyConfirm from "@/modals/applianceContractSetting/IntranetFwPfgwPolicyConfirm.vue";
import { UploadError } from "@/services/ConfigDownloadService";
import { Pfgw } from "@/apis/IntranetfwServiceMenuApi";
import { PfgwZone } from "@/apis/PfgwZoneApi";
import cloneDeep from "lodash/cloneDeep";

/** PFGWゾーン */
export type PfgwZoneType = "WVS2" | "KCPS";

export default Vue.extend({
  name: "IntranetFwPfgwZoneSelectList",
  props: {
    /** PFGW */
    pfgw: {
      type: Object as PropType<Pfgw>,
      required: true,
    },
    /** VPNコード */
    vpnCode: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      pfgwZone: {} as PfgwZone,
      intranetFwPolicy: {} as IntranetFwPolicy,
      zoneSelect: {
        fields: [
          {
            key: "srcZone",
            label: "送信元ゾーン",
          },
          { key: "arrow", label: "" },
          {
            key: "dstZone",
            label: "宛先ゾーン",
          },
          { key: "space", label: "" },
        ],
      },
      /** 初期通信完了フラグ */
      isLoaded: false,
      uploadAlert: null as string | null,
    };
  },
  computed: {
    /** WVS2 → KCPSのポリシー情報. ゾーンIDが一致するものがない場合はundefined */
    wvs2ToKcpsPolicyInfo():
      | (ZonePolicyInfo & { enumberAct: string })
      | undefined {
      if (
        this.pfgwZone.zoneIdList.find(
          (e) => e.srcZoneId === this.pfgwZone.wvs2ZoneId
        )
      ) {
        const policyInfo = this.intranetFwPolicy.wvs2ToKcpsList.find(
          (e) => e.enumberAct === this.pfgw.enumberAct
        );
        if (policyInfo) {
          return policyInfo;
        } else {
          // ゾーン接続はあるが一度も設定していない場合はGETで取得できない
          // ポリシー0件でデータを作成
          return {
            enumberAct: this.pfgw.enumberAct,
            zoneRelationId: this.pfgw.wvs2ToKcpsZoneRelationId!,
            total: 0,
            policyList: [],
          };
        }
      } else {
        // ゾーン接続が存在しない
        return undefined;
      }
    },
    /** KCPS → WVS2のポリシー情報. ゾーンIDが一致するものがない場合はundefined */
    kcpsToWvs2PolicyInfo():
      | (ZonePolicyInfo & { enumberAct: string })
      | undefined {
      if (
        this.pfgwZone.zoneIdList.find(
          (e) => e.srcZoneId === this.pfgwZone.kcpsZoneId
        )
      ) {
        const policyInfo = this.intranetFwPolicy.kcpsToWvs2List.find(
          (e) => e.enumberAct === this.pfgw.enumberAct
        );
        if (policyInfo) {
          return policyInfo;
        } else {
          // ゾーン接続はあるが一度も設定していない場合はGETで取得できない
          // ポリシー0件でデータを作成
          return {
            enumberAct: this.pfgw.enumberAct,
            zoneRelationId: this.pfgw.kcpsToWvs2ZoneRelationId!,
            total: 0,
            policyList: [],
          };
        }
      } else {
        // ゾーン接続が存在しない
        return undefined;
      }
    },
    /** WVS2 ←→ KCPSの一覧 */
    items(): {
      zoneRelationId: string;
      srcZone: PfgwZoneType;
      dstZone: PfgwZoneType;
    }[] {
      let items: {
        zoneRelationId: string;
        srcZone: PfgwZoneType;
        dstZone: PfgwZoneType;
      }[] = [];
      if (this.wvs2ToKcpsPolicyInfo) {
        items.push({
          zoneRelationId: this.wvs2ToKcpsPolicyInfo.zoneRelationId,
          srcZone: "WVS2",
          dstZone: "KCPS",
        });
      }
      if (this.kcpsToWvs2PolicyInfo) {
        items.push({
          zoneRelationId: this.kcpsToWvs2PolicyInfo.zoneRelationId,
          srcZone: "KCPS",
          dstZone: "WVS2",
        });
      }
      return items;
    },
  },
  async mounted() {
    this.pfgwZone = await this.$api.pfgwZoneRelation.getPfgwZone(
      this.pfgw.enumberAct
    );
    this.intranetFwPolicy =
      await this.$api.intranetFwPolicy.getPolicyIntranetFw("INTRANET_FW_PFGW");
    this.isLoaded = true;
  },
  methods: {
    /**
     * ポリシー画面へ遷移
     * @param srcZone 送信元ゾーン
     */
    async showPolicyModal({ srcZone }: { srcZone: PfgwZoneType }) {
      this.uploadAlert = null;
      // ポリシー一覧モーダルを表示 遷移先から編集後のポリシーを受け取る
      const policy: IntraPolicy[] = await this.$modal.show(
        IntranetFwPfgwPolicyList,
        {
          policyList:
            srcZone === "WVS2"
              ? this.wvs2ToKcpsPolicyInfo!.policyList
              : this.kcpsToWvs2PolicyInfo!.policyList,
          intranetFwPolicy: this.intranetFwPolicy,
          srcZone: srcZone,
          pfgw: this.pfgw,
        }
      );
      if (srcZone === "WVS2") {
        this.wvs2ToKcpsPolicyInfo!.policyList = policy;
      } else {
        this.kcpsToWvs2PolicyInfo!.policyList = policy;
      }
    },
    /** ポリシー設定反映 */
    async putPolicy() {
      this.uploadAlert = null;
      // 設定反映確認モーダルを表示
      await this.$modal.show(IntranetFwPfgwPolicyConfirm, {
        pfgwPolicy: {
          wvs2ToKcps: this.wvs2ToKcpsPolicyInfo,
          kcpsToWvs2: this.kcpsToWvs2PolicyInfo,
        },
      });
      // ポリシー更新APIの呼び出し
      await this.$api.intranetFwPolicy.postPolicyIntranetFw(
        "INTRANET_FW_PFGW",
        this.pfgw.enumberAct,
        {
          privateToPrivateList: [],
          extraToPrivateList: [],
          privateToExtraList: [],
          wvs2ToKcps: createReqPolicyInfo(this.wvs2ToKcpsPolicyInfo),
          kcpsToWvs2: createReqPolicyInfo(this.kcpsToWvs2PolicyInfo),
          note: null,
          policyIntranetFwKeyId: this.intranetFwPolicy.policyIntranetFwKeyId,
        }
      );
      // モーダルを閉じる
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 設定ダウンロード */
    downloadConfig() {
      // GETポリシーに編集中のポリシーをマージ
      const downloadPolicy = cloneDeep(this.intranetFwPolicy);
      if (this.wvs2ToKcpsPolicyInfo) {
        const info = downloadPolicy.wvs2ToKcpsList.find(
          (e) => e.enumberAct === this.wvs2ToKcpsPolicyInfo!.enumberAct
        );
        if (info) {
          info.policyList = this.wvs2ToKcpsPolicyInfo.policyList;
        } else {
          // 初回時はGETにポリシーが存在しないため追加
          downloadPolicy.wvs2ToKcpsList = [
            ...downloadPolicy.wvs2ToKcpsList,
            this.wvs2ToKcpsPolicyInfo,
          ];
        }
      }
      if (this.kcpsToWvs2PolicyInfo) {
        const info = downloadPolicy.kcpsToWvs2List.find(
          (e) => e.enumberAct === this.kcpsToWvs2PolicyInfo!.enumberAct
        );
        if (info) {
          info.policyList = this.kcpsToWvs2PolicyInfo.policyList;
        } else {
          // 初回時はGETにポリシーが存在しないため追加
          downloadPolicy.kcpsToWvs2List = [
            ...downloadPolicy.kcpsToWvs2List,
            this.kcpsToWvs2PolicyInfo,
          ];
        }
      }

      this.$service.configDownload.downloadIntranetFwPfgwPolicy(
        this.vpnCode,
        this.pfgw.enumberAct,
        downloadPolicy
      );
    },
    /** 設定アップロード */
    async uploadConfig() {
      try {
        const uploadPolicy =
          await this.$service.configDownload.uploadIntranetFwPfgwPolicy(
            this.vpnCode,
            this.pfgw.enumberAct,
            this.intranetFwPolicy
          );
        this.uploadAlert = null;
        await this.$confirm(
          "ポリシー設定を上書きします。設定反映していない設定は破棄されます。よろしいですか？"
        );
        this.intranetFwPolicy = uploadPolicy;
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },
  },
});

/** ポリシー更新APIの設定データ作成 */
function createReqPolicyInfo(
  info: ZonePolicyInfo | undefined
): PostZonePolicyInfo | null {
  return info
    ? {
        zoneRelationId: info.zoneRelationId,
        policyList: info.policyList
          .filter((e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY")
          .map((e) => {
            return {
              ...e,
              description: e.description || null,
              srcAddressList: e.srcAddressList.map((v) => v.ipAddressId),
              dstAddressList: e.dstAddressList.map((v) => v.ipAddressId),
              serviceList: e.serviceList.map((v) => v.serviceSeq),
              customServiceList: e.customServiceList.map(
                (v) => v.customServiceSeq
              ),
            };
          }),
      }
    : null;
}

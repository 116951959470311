import AbstractBaseApi from "@/apis/AbstractBaseApi";

export interface CloudVnResetting {
  /**
   * 総件数（クラウドVN回線）
   * 一覧表示対象の総件数
   */
  cloudVnConnectTotal: number;
  /** 取得した再設定をするクラウドVN回線 */
  cloudVnConnectsList: CloudVnConnects[];
}

export interface CloudVnConnects {
  cloudLineInfo: CloudLineInfo;
  cloudVnConnectCommon: CloudVnConnectCommon;
  cloudVnConnectAct: CloudVnConnectActSby;
  cloudVnConnectSby: CloudVnConnectActSby;
}

export interface CloudLineInfo {
  /** E番号 */
  enumber: string;
  /**
   * アクセス品目
   * ルチクラウドGWを設定する。MULTI_CLOUD_GW：マルチクラウドGW
   */
  accessType: "MULTI_CLOUD_GW";
  /** 帯域 */
  bandwidth: BandWidth;
  /**
   * 事業所名
   */
  office: string | null;
  /**
   * クラウドサービスメニュー
   */
  cloudServiceMenu: "AWS" | string | "GCP" | "Oracle" | "IBM" | "SFDC";
}

export interface CloudVnConnectCommon {
  /**
   * クラウドサービス種別
   * クラウドサービスメニューがMSの場合、必須とする。
   * 下記のいずれかを設定する。
   * PRIVATE_PEERING:Private-Peering
   * MICROSOFT_PEERING:Microsoft-Peering
   */
  cloudServiceType: "PRIVATE_PEERING" | "MICROSOFT_PEERING" | null;
  /**
   * クラウドサービス利用拠点
   * 下記を設定する。
   * EAST_JAPAN: 東日本
   * WEST_JAPAN: 西日本
   * クラウドサービスメニューがSFDCの場合、東日本のみ設定可能とする。
   */
  location: "EAST_JAPAN" | "WEST_JAPAN";
  /**
   * 接続サービス
   * クラウドサービスメニューがAWSの場合、必須とする。
   * 下記のいずれかを設定する。
   * HOSTED_VIFS: ホスト型VIF(Private)
   * HOSTED_CONNECTIONS: ホスト型接続
   */
  directConnectType: "HOSTED_VIFS" | "HOSTED_CONNECTIONS" | null;
  /** VPN/VNコード */
  vpnVnCode: string;
  /**
   * 仮想ネットワークタイプ
   * L3固定
   */
  vnType: "L3";
  /**
   * 仮想ネットワーク名
   * VPN/VNコードがVPNの場合、NULLを設定する。
   */
  vnName: string | null;
  /**
   * ルーティング種別
   * BGP4固定
   */
  routingType: "BGP4";
  /**
   * 帯域タイプ
   * PF固定
   */
  bandwidthType: "PF";
  /** 帯域 */
  bandwidth: BandWidth;
  /**
   * 経路集約
   * クラウドサービス種別がMicrosoft-Peering以外の場合、必須とする。
   * OFF、ON、CUSTOMのいずれかを設定する。
   * OFF：経路集約を非表示
   * ON：経路集約を表示
   * CUSTOM：経路集約アドレスを入力
   */
  routeAggregation: "OFF" | "ON" | "CUSTOM" | null;
  /**
   * 経路集約アドレスリスト.
   * 経路集約が「CUSTOM」選択時は必須項目
   * 登録順でソートする。
   */
  routeAggregationAddressList: Array<string> | null;
  /**
   * クラウドサービスメニューがSFDCの場合、必須とする
   * NAPTグローバルIPアドレス登録数
   */
  naptGlobalIpTotal: number | null;
  /**
   * NAPTグローバルIPアドレスリスト
   * クラウドサービスメニューがSFDCの場合、必須とする。
   * グローバルIPアドレスの払出順にソートする。
   */
  naptGlobalIpList: Array<string> | null;
  /**
   * QoS
   * VNコードがQoS設定と紐づいていない場合は省略する。
   */
  qos: {
    /**
     * QoS設定フラグ
     * クラウドサービス種別がMicrosoft-Peeringの場合、ONを設定する。その他はfalseを設定する。
     * true:ON
     * false:OFF
     */
    isQos: boolean;
    /**
     * QoS帯域制御パターン
     * 以下のいずれかを設定する。
     * PATTERN_A:パターンA
     * PATTERN_B:パターンB
     * クラウドサービス種別がMicrosoft-Peeringの場合のみ設定可能となる。
     * VNコードに紐づいたQoS設定が帯域制御の場合は必須
     */
    qosBandControlPattern: "PATTERN_A" | "PATTERN_B" | null;
  };
  /**
   * 設定値の説明
   */
  description: string | null;
}

export interface CloudVnConnectActSby {
  /**
   * クラウドVNコネクトSEQ
   * クラウドサービスメニューがAWS、GCP、Oracle、IBMの場合、必須とする。
   */
  cloudVnConnectSeq: string | null;
  /**
   * クラウドサービスキー情報
   * クラウドサービスメニューがSFDC以外の場合、必須となる。
   */
  serviceKey: string | null;
  /** VNコネクト名 */
  vnConnectName: string;
  /**
   * W番号
   * 主キー
   */
  wnumber: string;
  /**
   * AS番号
   * ACTに設定した値でSBYの値は設定する。
   */
  asNumber: number;
  /**
   * WANアドレス
   * クラウドサービスメニューがAWS、Oracle、SFDCの場合、必須となる。
   * クラウドサービス種別がPRIVATE_PEERINGの場合、必須となる。
   */
  wanAddress: string | null;
  /**
   * ピアIPアドレス
   * クラウドサービスメニューがAWS、Oracle、SFDCの場合、必須となる。
   * クラウドサービス種別がPRIVATE_PEERINGの場合、必須となる。
   */
  peerIpAddress: string | null;
  /**
   * MD5文字列
   * クラウドサービスメニューがAWS、MS、Oracle、SFDCの場合、必須となる。
   */
  md5Key: string | null;
  /**
   * Maximum prefix(設定値上限取得APIのBGP4の最大経路数)
   * クラウドサービス種別がMicrosoft-Peering以外の場合、必須とする。
   * 設定値上限APIのGETから上限値を取得し、その上限値以下の値を設定可能とする。
   * 値は100,200,300,400,500,1000,1500,2000から設定する。
   * ACTに設定した値でSBYの値は設定する。
   */
  maximumPrefix: number | null;
  /**
   * ローカルプリファレンス
   * HIGH_PRIORITY:優先(200)
   * LOW_PRIORITY：OFF:(100)
   * ACTの場合はHIGH_PRIORITY、
   * SBYの場合はLOW_PRIORITY固定となる。
   */
  localPreference: "HIGH_PRIORITY" | "LOW_PRIORITY";
  /**
   * MED
   * HIGH_PRIORITY:非優先(100)
   * LOW_PRIORITY:OFF(0)
   * ACTの場合はLOW_PRIORITY、
   * SBYの場合はHIGH_PRIORITY固定となる。
   */
  med: "HIGH_PRIORITY" | "LOW_PRIORITY";
  /**
   * VLAN_ID
   * 1～4094の値が設定可能。
   * 接続サービスがホスト型接続の場合、必須とする。
   * その他の場合、NULLを設定する。
   */
  vlanId: number | null;
}

/** 帯域 */
export interface BandWidth {
  /** 設定値 */
  value: number;
  /**
   * 単位. 以下のいずれかを設定する。
   * KBPS: Kbps
   * MBPS: Mbps
   * GBPS: Gbps
   */
  unit: "KBPS" | "MBPS" | "GBPS";
}

/** クラウドVN回線再設定API */
export default class CloudVnResettingApi extends AbstractBaseApi {
  /**
   * 再設定をするクラウドVN回線を取得
   */
  async getCloudVnResetting(): Promise<CloudVnResetting> {
    return (await this.api.get(`/v1/network/cloud/line/vnconnects/resetting`))
      .data;
  }

  /**
   * クラウドVN回線を再設定する
   * @param wnumber W番号
   */
  async postCloudVnResetting(wnumber: string): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/vnconnects/resetting/${wnumber}`
    );
  }
}

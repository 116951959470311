var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"イントラネットFW ポリシー設定","classes":"modal-full"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-card-title',{staticClass:"modal-title"},[_vm._v("イントラネットFW ポリシー設定")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.savePolicy}},[_vm._v(" × ")])]},proxy:true}])},'app-modal',_vm.$attrs,false),_vm.$listeners),[[_c('div',{staticClass:"modal-content"},[_c('span',{staticClass:"font-weight-bold mb-3"},[_vm._v("ゾーン一覧画面で、反映ボタンを押さないと機器には反映されません")]),_c('br'),_vm._v(" "+_vm._s(_vm.zoneRelationToDisplay)+" "),_c('div',{staticClass:"btn-container justify-content-between align-items-center my-2"},[_c('span',[_vm._v(_vm._s(_vm.intranetFwPolicy.policyList.length)+"件を表示")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.showPolicyRuleEdit}},[_vm._v(" ポリシー編集 ")])],1),_c('app-table',{attrs:{"small":"","fields":_vm.policyTable.fields,"items":_vm.intranetFwPolicy.policyList,"pagination":false,"primary-key":"policyId","thead-class":"text-center small","tbody-tr-class":_vm.policyRowClass},scopedSlots:_vm._u([{key:"cell(srcAddress)",fn:function(ref){
var item = ref.item;
return [_c('intranet-policy-src-address',{attrs:{"ip-address-list":item.srcAddressList}})]}},{key:"cell(dstAddress)",fn:function(ref){
var item = ref.item;
return [_c('intranet-policy-dst-address',{attrs:{"ip-address-list":item.dstAddressList}})]}},{key:"cell(service)",fn:function(ref){
var item = ref.item;
return [_c('intranet-policy-service',{attrs:{"custom-service-list":item.customServiceList,"default-service-list":item.serviceList}})]}},{key:"cell(actionType)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"allow_deny"))+" ")]}},{key:"cell(isPolicyStatus)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}}])})],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.savePolicy}},[_vm._v("戻る")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }



























































import Vue, { PropType } from "vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { ZoneRelation, ZoneRelationItem } from "@/apis/ZoneRelationApi";
import {
  GetDstAddress1,
  GetDstAddress2,
  GetPolicy,
  GetPolicyType1,
  GetSrcAddress1,
  GetSrcAddress2,
  PolicyType,
  PutDstAddress1,
  PutDstAddress2,
  PutPolicyType1,
  PutSrcAddress1,
  PutSrcAddress2,
} from "@/apis/NwPolicyApi";
import InternetType1PolicyUtmList from "@/modals/applianceContractSetting/InternetType1PolicyUtmList.vue";
import InternetType1PolicyUtmConfirm from "@/modals/applianceContractSetting/InternetType1PolicyUtmConfirm.vue";
import { Type1Service } from "@/apis/ContractChangeType1Api";
import InternetType1PolicyIfwList from "@/modals/applianceContractSetting/InternetType1PolicyIfwList.vue";
import InternetType1PolicyIfwConfirm from "@/modals/applianceContractSetting/InternetType1PolicyIfwConfirm.vue";
import { UploadError } from "@/services/ConfigDownloadService";
import { mapState } from "vuex";

export default Vue.extend({
  name: "InternetType1ZoneSelectReference",
  props: {
    /** サービスメニュー(インターネットType1) */
    type1Service: {
      type: Object as PropType<Type1Service>,
      required: true,
    },
  },
  data() {
    return {
      /** 初期通信完了フラグ */
      isLoaded: false,
      relations: {} as ZoneRelation,
      policy: {} as GetPolicyType1,
      zoneRelations: {
        fields: [
          {
            key: "zone_src",
            label: "送信元ゾーン/ゾーン名",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: ZoneRelationItem) => {
              return (
                this.$filter.enumTo(item.srcZoneType, "zoneType") +
                "/" +
                item.srcZoneName
              );
            },
          },
          {
            key: "direction",
            label: "",
          },
          {
            key: "zone_dest",
            label: "宛先ゾーン/ゾーン名",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: ZoneRelationItem) => {
              return (
                this.$filter.enumTo(item.dstZoneType, "zoneType") +
                "/" +
                item.dstZoneName
              );
            },
          },
        ] as BvTableFieldArray,
      },
      uploadAlert: null as string | null,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
    displayRelationList(): ZoneRelationItem[] {
      // global -> private
      // global -> extra
      // extra -> global
      // private -> global
      // 上記のみ表示
      return this.relations.zoneRelationList.filter(
        (v) =>
          (v.srcZoneType === "INTERNET" &&
            (v.dstZoneType === "PRIVATE" || v.dstZoneType === "EXTRA")) ||
          ((v.srcZoneType === "PRIVATE" || v.srcZoneType === "EXTRA") &&
            v.dstZoneType === "INTERNET")
      );
    },
    /** ポリシー種別 */
    policyType(): PolicyType {
      if (this.type1Service.applianceType === "UTM") {
        return this.contractSummary.isNat
          ? "UTM_IPMASQUERADE_NAT"
          : "UTM_IPMASQUERADE";
      } else {
        return this.contractSummary.isNat
          ? "IFW_IPMASQUERADE_NAT"
          : "IFW_IPMASQUERADE";
      }
    },
  },
  methods: {
    /** データ取得 */
    async load() {
      this.relations = await this.$api.zoneRelation.getZoneRelation();
      this.policy = await this.$api.nwPolicy.getType1Policies(this.policyType);
    },
    /** 設定ダウンロード */
    downloadConfig() {
      this.$service.configDownload.downloadInternetType1Policy(
        this.type1Service.vpnCode,
        this.type1Service.applianceType,
        this.policy,
        this.relations.zoneRelationList
      );
    },
    /** 設定アップロード */
    async uploadConfig() {
      try {
        const uploadPolicy =
          await this.$service.configDownload.uploadInternetType1Policy(
            this.type1Service.vpnCode,
            this.type1Service.applianceType,
            this.policy,
            this.relations.zoneRelationList
          );
        this.uploadAlert = null;
        await this.$confirm(
          "ポリシー設定を上書きします。設定反映していない設定は破棄されます。よろしいですか？"
        );
        this.policy = uploadPolicy;
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },
    async putPolicy() {
      this.uploadAlert = null;
      await this.$modal.show(
        this.type1Service.applianceType === "UTM"
          ? InternetType1PolicyUtmConfirm
          : InternetType1PolicyIfwConfirm,
        {
          type1Policy: this.policy,
          type1Service: this.type1Service,
          zoneRelationList: this.relations.zoneRelationList,
        }
      );
      await this.$api.nwPolicy.putType1Policies(
        get2putPolicy(this.policy),
        this.policyType
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    async showPolicy(item: ZoneRelationItem) {
      this.uploadAlert = null;
      this.policy = await this.$modal.show(
        this.type1Service.applianceType === "UTM"
          ? InternetType1PolicyUtmList
          : InternetType1PolicyIfwList,
        {
          zoneRelationItem: item,
          policy: this.policy,
          type1Service: this.type1Service,
        }
      );
    },
  },
});

/** type1ポリシーGETをPUT用のポリシーに変換 */
export function get2putPolicy(type1policy: GetPolicyType1): PutPolicyType1 {
  const toSrcAddress1 = (src: GetSrcAddress1): PutSrcAddress1 => ({
    srcAddressList: src.srcAddressList.map((e) => e.ipAddressId!),
    srcFqdnList: src.srcFqdnList.map((e) => e.fqdnId),
    srcCountryList: src.srcCountryList.map((e) => e.countrySeq),
  });
  const toDstAddress1 = (dst: GetDstAddress1): PutDstAddress1 => ({
    dstAddressList: dst.dstAddressList.map((e) => e.ipAddressId!),
  });
  const toSrcAddress2 = (src: GetSrcAddress2): PutSrcAddress2 => ({
    srcAddressList: src.srcAddressList.map((e) => e.ipAddressId!),
  });
  const toDstAddress2 = (dst: GetDstAddress2): PutDstAddress2 => ({
    dstAddressList: dst.dstAddressList.map((e) => e.ipAddressId!),
    dstFqdnList: dst.dstFqdnList.map((e) => e.fqdnId),
    dstCountryList: dst.dstCountryList.map((e) => e.countrySeq),
  });
  const toDefaultService = (s: GetPolicy["defaultServiceList"]): string[] =>
    s.map((e) => e.serviceSeq);
  const toCustomService = (s: GetPolicy["customServiceList"]): string[] =>
    s.map((e) => e.customServiceSeq);

  return {
    policyUtmType1: type1policy.policyUtmType1
      ? {
          internetToAccessPointList:
            type1policy.policyUtmType1.internetToAccessPointList.map((v) => {
              return {
                zoneRelationId: v.zoneRelationId,
                policyList: v.policyList
                  .filter(
                    (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
                  )
                  .map((policy) => ({
                    ...policy,
                    srcAddress: toSrcAddress1(policy.srcAddress),
                    dstAddress: toDstAddress1(policy.dstAddress),
                    defaultServiceList: toDefaultService(
                      policy.defaultServiceList
                    ),
                    customServiceList: toCustomService(
                      policy.customServiceList
                    ),
                  })),
              };
            }),
          accessPointToInternetList:
            type1policy.policyUtmType1.accessPointToInternetList.map((v) => {
              return {
                zoneRelationId: v.zoneRelationId,
                policyList: v.policyList
                  .filter(
                    (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
                  )
                  .map((policy) => ({
                    ...policy,
                    srcAddress: toSrcAddress2(policy.srcAddress),
                    dstAddress: toDstAddress2(policy.dstAddress),
                    defaultServiceList: toDefaultService(
                      policy.defaultServiceList
                    ),
                    customServiceList: toCustomService(
                      policy.customServiceList
                    ),
                  })),
              };
            }),
        }
      : null,
    policyIfwType1: type1policy.policyIfwType1
      ? {
          internetToAccessPointList:
            type1policy.policyIfwType1.internetToAccessPointList.map((v) => {
              return {
                zoneRelationId: v.zoneRelationId,
                policyList: v.policyList
                  .filter(
                    (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
                  )
                  .map((policy) => ({
                    ...policy,
                    srcAddress: toSrcAddress1(policy.srcAddress),
                    dstAddress: toDstAddress1(policy.dstAddress),
                    defaultServiceList: toDefaultService(
                      policy.defaultServiceList
                    ),
                    customServiceList: toCustomService(
                      policy.customServiceList
                    ),
                  })),
              };
            }),
          accessPointToInternetList:
            type1policy.policyIfwType1.accessPointToInternetList.map((v) => {
              return {
                zoneRelationId: v.zoneRelationId,
                policyList: v.policyList
                  .filter(
                    (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
                  )
                  .map((policy) => ({
                    ...policy,
                    srcAddress: toSrcAddress2(policy.srcAddress),
                    dstAddress: toDstAddress2(policy.dstAddress),
                    defaultServiceList: toDefaultService(
                      policy.defaultServiceList
                    ),
                    customServiceList: toCustomService(
                      policy.customServiceList
                    ),
                  })),
              };
            }),
        }
      : null,
    policyIPMasqueradeType1List: type1policy.policyIPMasqueradeType1List.map(
      (v) => {
        return {
          zoneRelationId: v.zoneRelationId,
          policyList: v.policyList
            .filter((e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY")
            .map((policy) => ({
              ...policy,
              srcAddressList: policy.srcAddressList.map((e) => e.ipAddressId!),
              dstAddressList: policy.dstAddressList.map((e) => e.ipAddressId!),
              ipPoolList: policy.ipPoolList.map((e) => e.ipPoolSeq),
            })),
        };
      }
    ),
    policyNatType1List: type1policy.policyNatType1List.map((v) => {
      return {
        zoneRelationId: v.zoneRelationId,
        policyList: v.policyList.map((policy) => ({
          ...policy,
          srcAddressList: policy.srcAddressList.map((e) => e.ipAddressId!),
        })),
      };
    }),
    policyKeyId: type1policy.policyKeyId,
  };
}




































































import Vue, { PropType } from "vue";
import {
  Type1InternetAddress,
  Type1InternetFQDN,
  Type1InternetInfo,
} from "@/apis/Type1SiteApi";
import { AppTableData } from "@/components/AppTable.vue";
import InternetSiteModify from "@/modals/type1/InternetSiteModify.vue";

export default Vue.extend({
  name: "InternetSiteReference",
  props: {
    internetSiteZone: {
      type: Object as PropType<Type1InternetInfo>,
      required: true,
    },
  },
  data() {
    return {
      addressList: {
        fields: [
          {
            key: "ipAddressName",
            label: "アドレス名",
          },
          {
            key: "ipAddress",
            label: "アドレス",
          },
          {
            key: "zoneId",
            label: "ゾーン配置",
          },
        ],
        items: [],
      } as Pick<AppTableData<Type1InternetAddress>, "fields" | "items">,
      fqdnList: {
        fields: [
          {
            key: "fqdnName",
            label: "FQDN名",
          },
          {
            key: "fqdn",
            label: "FQDN",
          },
          {
            key: "zoneId",
            label: "ゾーン配置",
          },
        ],
        items: [],
      } as Pick<AppTableData<Type1InternetFQDN>, "fields" | "items">,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    /** 初期化機能 */
    load() {
      this.addressList.items = this.internetSiteZone.addressList;
      this.fqdnList.items = this.internetSiteZone.fqdnList;
    },
    /** 変更機能 */
    async toChange() {
      await this.$modal.show(InternetSiteModify, {
        internetSiteZone: this.internetSiteZone,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});



















































import Vue, { PropType } from "vue";
import { ContractChangeType1Appliance } from "@/apis/ContractChangeType1Api";

export default Vue.extend({
  name: "InternetType1ContractApplianceConfirm",
  props: {
    contractChangeType1Appliance: {
      type: Object as PropType<ContractChangeType1Appliance>,
      required: true,
    },
    /**
     * UTM契約、インターネットFW契約のどちらに該当するか判断
     * UTM: UTM契約
     * IFW: インターネットFW契約
     */
    isUtmOrIfw: {
      type: String as PropType<string>,
      required: true,
    },
    /** アプライアンスが解約の場合にtrue */
    applianceChange: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /** NAT契約状態に変更があった場合にtrue */
    natChange: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { NonNullableProperty } from "@/shims-vue";
import { IgnoreLoading } from "@/apis/api-interceptors";

/** 帯域0 */
export interface BandWidthEmpty {
  /** 設定値 */
  value: 0;
  /** 単位. */
  unit: null;
}

/** 帯域 */
export interface BandWidth {
  /** 設定値 */
  value: number;
  /**
   * 単位. 以下のいずれかを設定する。
   * KBPS: Kbps
   * MBPS: Mbps
   * GBPS: Gbps
   */
  unit: "KBPS" | "MBPS" | "GBPS";
}

/**
 * VPN/VNコード
 * Dから始まる - VNコード
 * Bから始まる - VPNコード
 */
export type VpnVnCode = `D${string}` | `B${string}`;

/**
 * 経路集約.
 * OFF、ON、CUSTOMのいずれかを指定する。
 * OFF：経路集約を非表示
 * ON：経路集約を表示
 * CUSTOM：経路集約アドレスを入力
 */
export type RouteAggregation = "OFF" | "ON" | "CUSTOM" | null;

/**
 * IF状態. VNコネクト状態が無効の場合省略
 * NO_SHUTDOWN: 開放状態
 * SHUTDOWN: 閉塞状態
 */
export type IfStatus = "NO_SHUTDOWN" | "SHUTDOWN" | null;

/**
 * Maximum prefix(設定値上限取得APIのBGP4の最大経路数).
 * 値は100,200,300,400,500,1000,1500,2000から指定する。
 */
export type MaximumPrefix = 100 | 200 | 300 | 400 | 500 | 1000 | 1500 | 2000;

/**
 * HIGH_PRIORITY:優先
 * LOW_PRIORITY:非優先
 */
export type Priority = "HIGH_PRIORITY" | "LOW_PRIORITY";

export interface VpnVnCodes {
  /**
   * 総件数（クラウドVN回線）.
   * 一覧表示対象の総件数
   */
  cloudVnConnectTotal: number;
  cloudVnConnectsList: {
    /** VPN/VNコード. */
    vpnVnCode: VpnVnCode;
    /** Microsoftサービス申込フラグ. */
    isMsService: boolean | null;
  }[];
}

export interface CloudVnMsInfo {
  /** E番号. */
  enumber: string;
  /**
   * アクセス品目.
   * 以下のいずれかを指定する。
   * MULTI_CLOUD_GW：マルチクラウドGW
   */
  accessType: "MULTI_CLOUD_GW";
  /** 帯域. GET時に登録された帯域が存在しない場合は「0」を指定する。 */
  bandwidth: BandWidth | BandWidthEmpty;
  /** 事業所名. */
  office: string | null;
  /**
   * クラウドサービスキー取得状態. 以下のいずれかが選択される。
   * UNEXECUTED：未取得
   * ACQUIRING：取得中
   * SUCCESS：取得済み
   * FAILED：取得失敗
   */
  keyStatus: "UNEXECUTED" | "ACQUIRING" | "SUCCESS" | "FAILED";
  /** クラウドサービスキー情報. */
  serviceKey: string | null;
  /**
   * クラウドサービス利用拠点. 下記のいずれかを指定する。
   * EAST_JAPAN: 東日本
   * WEST_JAPAN: 西日本
   */
  location: "EAST_JAPAN" | "WEST_JAPAN" | null;
  /**
   * クラウドVN回線(MS)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnMsKeyId: string;
}

export type CloudVnMsList = CloudVnMsInfo & {
  /** 総件数（クラウドVN回線）. 一覧表示対象の総件数 */
  cloudVnConnectTotal: number;
  /** クラウドサービスキー情報が取得済みでない場合、空リストとなる。*/
  cloudVnConnectsList: CloudVnMs[];
};

export interface CloudVnMs {
  cloudVnConnectCommon: {
    /**
     * クラウドサービス. 下記のいずれかを指定する。
     * PRIVATE_PEERING:Azure(Private-Peering)
     * MICROSOFT_PEERING:Azure(Microsoft-Peering)
     */
    cloudServiceType: "PRIVATE_PEERING" | "MICROSOFT_PEERING";
    /** VPN/VNコード */
    vpnVnCode: VpnVnCode;
    /**
     * 仮想ネットワークタイプ. L3固定
     */
    vnType: "L3";
    /** ルーティング種別. BGP4固定 */
    routingType: "BGP4";
    /** 帯域タイプ. PF固定 */
    bandwidthType: "PF";
    /** 帯域 */
    bandwidth: BandWidth;
    /** 設定値の説明 */
    description: string | null;
  };
  cloudVnConnectAct: Pick<
    CloudVnConnectActSby,
    "vnConnectName" | "wnumber" | "ifStatus" | "isVnConnectStatus"
  >;
  cloudVnConnectSby: CloudVnMs["cloudVnConnectAct"];
}

/**
 * 1つめのVNコネクト設定はPrivate Peeringが作成されるため、クラウドサービスには「PRIVATE_PEERING」を設定する。
 * 2つめのVNコネクト設定はMicrosoft Peeringが作成されるため、クラウドサービスには「MICROSOFT_PEERING」を設定する。
 * また以下の項目は固定値となり、API内部で設定するため省略する。
 * ・MED ACT回線の場合は「HIGH_PRIORITY」固定 SBY回線の場合は「LOW_PRIORITY」固定
 * ・ローカルプレファレンス ACT回線の場合は「HIGH_PRIORITY」固定 SBY回線の場合は「LOW_PRIORITY」固定
 * ・ルーティング種別 BGP4固定
 * ・md5文字列 「kddicloud」固定
 */
export interface CloudVnMsPost {
  cloudVnConnectCommon: {
    /**
     * クラウドサービス.
     * 下記のいずれかを指定する。
     * PRIVATE_PEERING:Azure(Private-Peering)
     * MICROSOFT_PEERING:Azure(Microsoft-Peering)
     */
    cloudServiceType: "PRIVATE_PEERING" | "MICROSOFT_PEERING";
    /** VPN/VNコード */
    vpnVnCode: VpnVnCode;
    /** 帯域タイプ. PF固定 */
    bandwidthType: "PF";
    /** 帯域 */
    bandwidth: BandWidth;
    /**
     * 経路集約.
     * OFF、ON、CUSTOMのいずれかを指定する。
     * OFF：経路集約を非表示
     * ON：経路集約を表示
     * CUSTOM：経路集約アドレスを入力
     */
    routeAggregation: RouteAggregation;
    /**
     * 経路集約アドレスリスト.
     * 経路集約が「CUSTOM」選択時は必須項目
     * 登録順でソートする。
     */
    routeAggregationAddressList: Array<string> | null;
    /** 設定値の説明  */
    description: string | null;
    /**
     * QoS帯域制御パターン.
     * 以下のいずれかを指定する。
     * PATTERN_A:パターンA
     * PATTERN_B:パターンB
     *
     * クラウドサービスが「MICROSOFT_PEERING」の場合のみ設定可能となる。
     * VNコードに紐づいたQoS設定が帯域制御の場合は必須
     */
    qosBandControlPattern: "PATTERN_A" | "PATTERN_B" | null;
  };
  cloudVnConnectAct: Pick<
    CloudVnConnectActSby,
    | "vnConnectName"
    | "asNumber"
    | "wanAddress"
    | "peerIpAddress"
    | "maximumPrefix"
  >;
  cloudVnConnectSby: CloudVnMsPost["cloudVnConnectAct"];
  /** 操作履歴の備考 */
  note?: string;
  /**
   * クラウドVN回線(MS)排他情報.
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnMsKeyId: string;
}

export interface CloudVnMsPut {
  cloudVnConnectCommon: Pick<
    CloudVnMsPost["cloudVnConnectCommon"],
    | "bandwidth"
    | "routeAggregation"
    | "routeAggregationAddressList"
    | "description"
    | "qosBandControlPattern"
  >;
  cloudVnConnectAct: Pick<
    CloudVnConnectActSby,
    "vnConnectName" | "maximumPrefix"
  >;
  cloudVnConnectSby: Pick<
    CloudVnConnectActSby,
    "wnumber" | "vnConnectName" | "maximumPrefix"
  >;
  /** 操作履歴の備考 */
  note?: string;
  /**
   * クラウドVN回線(MS)排他情報.
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnMsKeyId: string;
}

export type PostServiceKey = NonNullableProperty<
  Pick<CloudVnMsList, "serviceKey" | "location">
>;

export interface CloudVnMsEnableDisable {
  /** W番号一覧. 主キー */
  cloudVnConnectList: string[];
  /**
   * クラウドVN回線(MS)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnMsKeyId: string;
}

export interface CloudVnMsDelete {
  /**
   * W番号一覧. 主キー
   * ACT/SBYを必ずセットで削除する。その際、ACT/SBYは1セットのみ入力可能とする。
   */
  cloudVnConnectList: Array<string>;
  /** 操作履歴の備考 */
  note?: string;
  /**
   * クラウドVN回線(MS)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnMsKeyId: string;
}

/**
 * WANアドレス、ピアIPアドレスの払い出し/登録処理が失敗した場合
 * ロールバックは行わず、WANアドレス、ピアIPアドレスをNULLで返却する。
 */
export type CloudVnMsDetail = CloudVnMsInfo & {
  cloudVnConnectCommon: {
    /**
     * クラウドサービス.
     * 下記のいずれかを指定する。
     * PRIVATE_PEERING:Azure(Private-Peering)
     * MICROSOFT_PEERING:Azure(Microsoft-Peering)
     */
    cloudServiceType: "PRIVATE_PEERING" | "MICROSOFT_PEERING";
    /** VPN/VNコード */
    vpnVnCode: VpnVnCode;
    /**
     * 仮想ネットワークタイプ. L3固定
     */
    vnType: "L3";
    /** 仮想ネットワーク名. */
    vnName: string;
    /** ルーティング種別. BGP4固定 */
    routingType: "BGP4";
    /** 帯域タイプ. PF固定 */
    bandwidthType: "PF";
    /** 帯域 */
    bandwidth: BandWidth;
    /**
     * 経路集約.
     * OFF、ON、CUSTOMのいずれかを指定する。
     * OFF：経路集約を非表示
     * ON：経路集約を表示
     * CUSTOM：経路集約アドレスを入力
     */
    routeAggregation: RouteAggregation;
    /**
     * 経路集約アドレスリスト.
     * 経路集約が「CUSTOM」選択時は必須項目
     * 登録順でソートする。
     */
    routeAggregationAddressList: Array<string> | null;
    /** VNコードがQoS設定と紐づいていない場合は省略する。 */
    qos: {
      /**
       * QoS設定フラグ.
       * MSPeeringの場合、ONを設定する。その他はfalseを設定する。
       * true:ON
       * false:OFF
       */
      isQos: boolean;
      /**
       * QoS帯域制御パターン.
       * 以下のいずれかを指定する。
       * PATTERN_A:パターンA
       * PATTERN_B:パターンB
       *
       * クラウドサービスが「MICROSOFT_PEERING」の場合のみ設定可能となる。
       * VNコードに紐づいたQoS設定が帯域制御の場合は必須
       */
      qosBandControlPattern: "PATTERN_A" | "PATTERN_B" | null;
    } | null;
    /** 設定値の説明  */
    description: string | null;
  };
  cloudVnConnectAct: CloudVnConnectActSby;
  cloudVnConnectSby: CloudVnConnectActSby;
};

export interface CloudVnConnectActSby {
  /** VNコネクト名. */
  vnConnectName: string;
  /**
   * W番号.
   * 主キー
   */
  wnumber: string;
  /**
   * IF状態. VNコネクト状態が無効の場合省略
   * NO_SHUTDOWN: 開放状態
   * SHUTDOWN: 閉塞状態
   */
  ifStatus: IfStatus;
  /** VNコネクト有効/無効フラグ. true:有効 false:無効 */
  isVnConnectStatus: boolean;
  /**
   * AS番号.
   * 12076固定
   */
  asNumber: 12076;
  /** WANアドレス. */
  wanAddress: string | null;
  /** ピアIPアドレス */
  peerIpAddress: string | null;
  /**
   * MD5文字列.
   * 「Kddicloud」固定
   */
  md5Key: "Kddicloud";
  /**
   * Maximum prefix(設定値上限取得APIのBGP4の最大経路数).
   * クラウドサービスが「MICROSOFT_PEERING」である場合は非必須となる。
   * 設定値上限APIのGETから上限値を取得し、その上限値以下の値を設定可能とする。
   * 値は100,200,300,400,500,1000,1500,2000から指定する。
   */
  maximumPrefix: MaximumPrefix | null;
  /**
   * ローカルプリファレンス.
   * HIGH_PRIORITY:優先
   * LOW_PRIORITY:非優先
   * ACT回線識別フラグがtrueの場合:「HIGH_PRIORITY」固定
   * ACT回線識別フラグがfalseの場合:「LOW_PRIORITY」固定
   */
  localPreference: Priority;
  /**
   * MED.
   * HIGH_PRIORITY:優先
   * LOW_PRIORITY:非優先
   * ACT回線識別フラグがtrueの場合:「HIGH_PRIORITY」固定
   * ACT回線識別フラグがfalseの場合:「LOW_PRIORITY」固定
   */
  med: Priority;
}

/** クラウドVN回線(MS)API */
export default class CloudVnMsApi extends AbstractBaseApi {
  /**
   * MS365申込済み仮想ネットワーク情報を取得(プルダウン用)
   * @param serviceKey サービスキー
   */
  async getVpnVnCodes(
    serviceKey: string
  ): Promise<VpnVnCodes["cloudVnConnectsList"]> {
    const { cloudVnConnectsList } = (
      await this.api.get<VpnVnCodes>("/v1/network/cloud/line/ms/vpn-vn-code", {
        params: {
          serviceKey: serviceKey,
        },
      })
    ).data;
    return cloudVnConnectsList;
  }

  /**
   * クラウドVN回線設定を取得
   * @param cloudLineSeq クラウド回線SEQ
   * @param loading true: ローディング表示, false: ローディング非表示
   */
  async getVnConnects(
    cloudLineSeq: string,
    loading = true
  ): Promise<CloudVnMsList> {
    return (
      await this.api.get(
        `/v1/network/cloud/line/${cloudLineSeq}/ms/vnconnects`,
        loading ? undefined : IgnoreLoading
      )
    ).data;
  }

  /**
   * クラウドVN回線設定を追加
   * @param cloudLineSeq クラウド回線SEQ
   * @param data クラウドVN回線設定
   */
  async postVnConnect(
    cloudLineSeq: string,
    data: CloudVnMsPost
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/ms/vnconnects`,
      data
    );
  }

  /**
   * クラウドVN回線設定を変更
   * @param cloudLineSeq クラウド回線SEQ
   * @param wnumber W番号. ACT回線のW番号を指定する
   * @param data クラウドVN回線設定
   */
  async putVnConnect(
    cloudLineSeq: string,
    wnumber: string,
    data: CloudVnMsPut
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/ms/vnconnects/${wnumber}`,
      data
    );
  }

  /**
   * クラウドVN回線設定のロケーション情報を設定
   * @param cloudLineSeq クラウド回線SEQ
   * @param data ロケーション情報
   */
  async postServiceKey(
    cloudLineSeq: string,
    data: PostServiceKey
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/ms/servicekey`,
      data
    );
  }

  /**
   * クラウドVNコネクトの有効/無効状態を有効に変更
   * @param data 有効情報
   */
  async putEnableVnConnect(data: CloudVnMsEnableDisable): Promise<void> {
    await this.api.post("/v1/network/cloud/line/ms/vnconnects/enable", data);
  }

  /**
   * クラウドVNコネクトの有効/無効状態を無効に変更
   * @param data 無効情報
   */
  async putDisableVnConnect(data: CloudVnMsEnableDisable): Promise<void> {
    await this.api.post("/v1/network/cloud/line/ms/vnconnects/disable", data);
  }

  /**
   * クラウドVN回線設定の詳細情報を取得
   * @param wnumber W番号. ACT回線のW番号を指定する
   */
  async getVnConnect(wnumber: string): Promise<CloudVnMsDetail> {
    return (
      await this.api.get(`/v1/network/cloud/line/ms/vnconnect/${wnumber}`)
    ).data;
  }

  /**
   * クラウドVN回線設定を削除
   * @param cloudLineSeq クラウド回線SEQ
   * @param data 削除情報
   */
  async deleteVnConnect(
    cloudLineSeq: string,
    data: CloudVnMsDelete
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/ms/vnconnects/delete`,
      data
    );
  }
}

import AbstractBaseApi from "./AbstractBaseApi";

export interface AgreementInfo {
  total: number;
  /** 同意排他情報 */
  agreementKeyId: string;
  /** 同意状況一覧 */
  agreementList: AgreementInfoList[];
}

/**
 * 同意マスタ取得API用インタフェース
 */
export interface AgreementInfoList {
  /** 同意マスタSEQ  */
  agreementSeq: string;
  /** 掲載開始日 */
  postStartDate: string;
  /** 同意タイトル */
  agreementTitle: string;
  /** 同意日時 */
  signedDate: string | null;
  /** 同意者名 */
  signer: string | null;
  /** 同意状況
   *  以下のいずれかを設定する。
   *  Null: 未同意
   *  AGREED: 同意
   *  ON_HOLD: 保留
   */
  agreementStatus: null | "AGREED" | "ON_HOLD";
}

/**
 * 同意、同意保留API用インタフェース
 */
export interface AgreementEntity {
  /** 同意排他情報 */
  agreementKeyId: string;
}

/**
 * 同意一覧API用インタフェース
 */
export interface AgreementDetail {
  /** 同意マスタSEQ  */
  agreementSeq: string;
  /** 掲載開始日 */
  postStartDate: string;
  /** 同意タイトル */
  agreementTitle: string;
  /** 同意内容 */
  agreementText: string;
}

export default class AgreementApi extends AbstractBaseApi {
  /**
   * 同意状況一覧情報取得
   */
  async getAll(): Promise<AgreementInfo> {
    return (await this.api.get("v1/information/agreements")).data;
  }
  /**
   * 同意マスタ情報を取得
   * @param agreementSeq 同意マスタSEQ(主キー)
   */
  async getAgreementDetail(agreementSeq: string): Promise<AgreementDetail> {
    return (await this.api.get(`/v1/information/agreement/${agreementSeq}`))
      .data;
  }

  async postAgreement(
    agreementSeq: string,
    data: AgreementEntity
  ): Promise<void> {
    return (
      await this.api.post(`/v1/information/agreement/${agreementSeq}`, data)
    ).data;
  }

  async postAgreementHold(
    agreementSeq: string,
    data: AgreementEntity
  ): Promise<void> {
    return await this.api.post(
      `/v1/information/agreement/${agreementSeq}/hold`,
      data
    );
  }
}

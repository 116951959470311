import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import moment from "moment";

/**
 * 入力された日付が指定された日付以前であること
 */
export default {
  params: [
    {
      name: "date",
      cast(max: number): string {
        return moment().add(max, "days").format("YYYY/MM/DD");
      },
    },
  ],
  validate(value: string | undefined | null, { date }: { date: string }) {
    if (!value) {
      return true;
    }

    return moment(value).isSameOrBefore(date);
  },
} as ValidationRuleSchema;











import Vue, { PropType } from "vue";

type Value = Record<string | number, unknown>;

/**
 * b-form-selectでvalueがobjectの場合にoptionsとvalueが単純な等価比較で一致しない場合用のラッパー
 * label（表示文字列）とtrackBy（主キー）というpropが追加されておりoptionsとvalueの比較では主キーのtrackByが使用される
 */
export default Vue.extend({
  name: "BFormSelectObj",
  props: {
    /** 値 */
    value: {
      type: Object as PropType<Value>,
      default: null,
    },
    /** 選択肢 */
    options: {
      type: Array as PropType<Value[]>,
      required: true,
    },
    /** 表示文字列のプロパティ名 */
    label: {
      type: String as PropType<string>,
      required: true,
    },
    /** 主キーのプロパティ名 */
    trackBy: {
      type: String as PropType<string>,
      required: true,
    },
  },
  computed: {
    selectOptions(): { text: unknown; value: unknown }[] {
      return this.options.map((e) => ({
        ...e,
        text: e[this.label],
        value: e[this.trackBy],
      }));
    },
    optionsMap(): Record<string | number, Value> {
      return this.options.toMap((e) => e[this.trackBy] as string | number);
    },
    modelValue: {
      get(): unknown {
        return this.value ? this.value[this.trackBy] : null;
      },
      set(newValue: string | number | null) {
        if (newValue) {
          this.$emit("input", this.optionsMap[newValue]);
        } else {
          this.$emit("input", null);
        }
      },
    },
  },
});

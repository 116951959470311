import { cloudLineChanged } from "./cloudLine-changed";
import { cloudLineNotChangedMoreThanOne } from "./cloudLine-not-changed-more-than-one";
import { objectChanged } from "./object-changed";
import { requiredSome } from "@/validators/cross/required-some";
import { maxConcatArray } from "@/validators/cross/max-concat-array";

export default class CrossValidation {
  /** クラウド上限値設定画面用変更有無Validation */
  readonly cloudLineChanged = cloudLineChanged;

  /** クラウド上限値設定画面用複数変更有無Validation */
  readonly cloudLineNotChangedMoreThanOne = cloudLineNotChangedMoreThanOne;

  /** Object変更有無Validation */
  readonly objectChanged = objectChanged;
  /** いずれか必須 */
  readonly requiredSome = requiredSome;
  /** 複数の配列で最大数 */
  readonly maxConcatArray = maxConcatArray;
}

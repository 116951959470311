import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import is_ip_subnet from "@/validators/is-ip-subnet";
import is_ipv4 from "@/validators/is-ipv4";

/**
 * IPアドレスの形式チェック
 *
 * チェック内容:
 *    サブネットがない場合、IPアドレスの形式チェックのみ実施
 *    サブネットがある場合、「IPアドレス/サブネット」の形式チェック、サブネットマスクは指定範囲チェックを実施
 *
 * @param value 入力値
 * @param min サブネットマスクの最小値
 * @param max サブネットマスクの最大値
 * @param exclude 除外アドレス
 * @param eq サブネットマスクの固定値
 * 範囲チェック時はmin,maxの両方を指定、例：{min:16, max:32, exclude: [31]}
 * 固定値チェック時はeqを指定、例：{eq: 20}
 */
export default {
  params: ["min", "max", "exclude", "eq"],
  validate: function (
    value: string | null | undefined,
    {
      min,
      max,
      exclude,
      eq,
    }: {
      min: number | null;
      max: number | null;
      exclude: number[] | null;
      eq: number | null;
    }
  ): boolean {
    if (!value) return true;

    const cidrArray = value.split("/");
    if (cidrArray.length === 1) {
      // subnetがない場合、is_ipv4にお任せ
      return is_ipv4.validate!(value, {}) as boolean;
    } else if (cidrArray.length === 2) {
      // subnetがある場合、is_ip_subnetにお任せ
      return is_ip_subnet.validate!(value, {
        min,
        max,
        exclude,
        eq,
      }) as boolean;
    } else {
      return false;
    }
  },
} as ValidationRuleSchema;











































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  BandWidth,
  CloudVnMsDetail,
  CloudVnMsInfo,
  CloudVnMsList,
  CloudVnMsPost,
  CloudVnMsPut,
  RouteAggregation,
  VpnVnCode,
  VpnVnCodes,
} from "@/apis/CloudVnMsApi";
import { CloudLine } from "@/apis/CloudLineApi";
import { CloudQosDetail } from "@/apis/CloudQosApi";
import MultiCloudVNConnectionConfirmMS from "@/modals/multicloud/ms/MultiCloudMsVnConnectionConfirm.vue";
import cloneDeep from "lodash/cloneDeep";
import CloudQosViewRC from "@/modals/cloudQos/CloudQosViewRC.vue";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";
import { SaaSServiceGetEntity } from "@/apis/SaasApi";

export default Vue.extend({
  name: "MultiCloudMsVnLineEdit",
  props: {
    /** クラウド回線SEQ */
    cloudLineSeq: {
      type: String as PropType<string>,
      required: true,
    },
    // 新規の場合用
    cloudVnMsInformation: {
      type: Object as PropType<CloudVnMsList>,
      default: undefined,
    },
    // 変更の場合用
    cloudVnMsDetail: {
      type: Object as PropType<CloudVnMsDetail>,
      default: undefined,
    },
  },
  data() {
    // 新規/更新のformデータを作成
    const form: CloudVnMsPost | CloudVnMsPut = (() => {
      if (this.cloudVnMsInformation) {
        const isPrivatePeering =
          this.cloudVnMsInformation.cloudVnConnectTotal === 0;
        // 新規
        const form: CloudVnMsPost = {
          cloudVnConnectCommon: {
            cloudServiceType: isPrivatePeering
              ? "PRIVATE_PEERING"
              : "MICROSOFT_PEERING",
            bandwidthType: "PF",
            routeAggregation: isPrivatePeering ? "OFF" : null,
            routeAggregationAddressList: isPrivatePeering ? [""] : null,
          } as CloudVnMsPost["cloudVnConnectCommon"],
          cloudVnConnectAct: (isPrivatePeering
            ? {
                asNumber: 12076,
                maximumPrefix: 100,
              }
            : {
                asNumber: 12076,
                maximumPrefix: null,
              }) as CloudVnMsPost["cloudVnConnectAct"],
          cloudVnConnectSby: (isPrivatePeering
            ? {
                asNumber: 12076,
                maximumPrefix: 100,
              }
            : {
                asNumber: 12076,
                maximumPrefix: null,
              }) as CloudVnMsPost["cloudVnConnectSby"],
          cloudVnMsKeyId: this.cloudVnMsInformation.cloudVnMsKeyId,
        };
        return form;
      } else {
        const cloudVnMsDetailCopy: CloudVnMsDetail = cloneDeep(
          this.cloudVnMsDetail
        );
        // 変更
        const common = cloudVnMsDetailCopy.cloudVnConnectCommon;
        const form: CloudVnMsPut = {
          cloudVnConnectCommon: {
            ...common,
            qosBandControlPattern: common.qos?.qosBandControlPattern ?? null,
          },
          cloudVnConnectAct: cloudVnMsDetailCopy.cloudVnConnectAct,
          cloudVnConnectSby: cloudVnMsDetailCopy.cloudVnConnectSby,
          cloudVnMsKeyId: cloudVnMsDetailCopy.cloudVnMsKeyId,
        };
        return form;
      }
    })();

    return {
      form,
      isLoaded: false,
      privatePeeringDetail: {} as CloudVnMsDetail,
      vpnVnCodeList: [] as VpnVnCodes["cloudVnConnectsList"],
      saasServiceMst: {} as SaaSServiceGetEntity,
      cloudLineMaxBandwidth: {} as CloudLine["bandwidth"],
      qos: {} as CloudQosDetail,
      /**手動入力チェックボックス*/
      isWanIpManual: false,
      selectedVpnVn: null as null | { value: VpnVnCode; text: string },
      patternOptions: [
        { value: "PATTERN_A", text: "パターンA" },
        { value: "PATTERN_B", text: "パターンB" },
      ],
      bandwidthOptions: [
        { text: "10Mbps", value: { value: 10, unit: "MBPS" } },
        { text: "20Mbps", value: { value: 20, unit: "MBPS" } },
        { text: "30Mbps", value: { value: 30, unit: "MBPS" } },
        { text: "40Mbps", value: { value: 40, unit: "MBPS" } },
        { text: "50Mbps", value: { value: 50, unit: "MBPS" } },
        { text: "60Mbps", value: { value: 60, unit: "MBPS" } },
        { text: "70Mbps", value: { value: 70, unit: "MBPS" } },
        { text: "80Mbps", value: { value: 80, unit: "MBPS" } },
        { text: "90Mbps", value: { value: 90, unit: "MBPS" } },
        { text: "100Mbps", value: { value: 100, unit: "MBPS" } },
        { text: "200Mbps", value: { value: 200, unit: "MBPS" } },
        { text: "300Mbps", value: { value: 300, unit: "MBPS" } },
        { text: "400Mbps", value: { value: 400, unit: "MBPS" } },
        { text: "500Mbps", value: { value: 500, unit: "MBPS" } },
        { text: "600Mbps", value: { value: 600, unit: "MBPS" } },
        { text: "700Mbps", value: { value: 700, unit: "MBPS" } },
        { text: "800Mbps", value: { value: 800, unit: "MBPS" } },
        { text: "900Mbps", value: { value: 900, unit: "MBPS" } },
        { text: "1Gbps", value: { value: 1, unit: "GBPS" } },
        { text: "2Gbps", value: { value: 2, unit: "GBPS" } },
        { text: "3Gbps", value: { value: 3, unit: "GBPS" } },
        { text: "4Gbps", value: { value: 4, unit: "GBPS" } },
        { text: "5Gbps", value: { value: 5, unit: "GBPS" } },
        { text: "6Gbps", value: { value: 6, unit: "GBPS" } },
        { text: "7Gbps", value: { value: 7, unit: "GBPS" } },
        { text: "8Gbps", value: { value: 8, unit: "GBPS" } },
        { text: "9Gbps", value: { value: 9, unit: "GBPS" } },
        { text: "10Gbps", value: { value: 10, unit: "GBPS" } },
      ] as { text: string; value: BandWidth }[],
      routeOptions: [
        { value: "ON", text: "ON" },
        { value: "OFF", text: "OFF" },
        { value: "CUSTOM", text: "CUSTOM" },
      ],
      /** Maximum Prefix上限値(変更の場合用) */
      maximumPrefixLimit: null as UpperLimitEntity | null,
      /** 1G超VNコネクト */
      vnConnects1G: null as UpperLimitEntity | null,
    };
  },
  computed: {
    /** VNコネクト(Act)名編集可否 */
    isVnConnectNameActEnable() {
      if (this.isAdd) {
        return true;
      }
      return !this.cloudVnMsDetail!.cloudVnConnectAct.isVnConnectStatus;
    },
    /** VNコネクト(Sby)名編集可否 */
    isVnConnectNameSbyEnable() {
      if (this.isAdd) {
        return true;
      }
      return !this.cloudVnMsDetail!.cloudVnConnectSby.isVnConnectStatus;
    },
    currentLength(): number {
      return this.form.cloudVnConnectCommon.routeAggregationAddressList
        ? this.form.cloudVnConnectCommon.routeAggregationAddressList.length
        : 0;
    },
    /** true: 新規, false: 変更 */
    isAdd(): boolean {
      return this.cloudVnMsInformation !== undefined;
    },
    /** 新規/変更共通情報 */
    cloudVnMsInfo(): CloudVnMsInfo {
      return this.isAdd ? this.cloudVnMsInformation! : this.cloudVnMsDetail!;
    },
    /** Private Peering / Microsoft Peering */
    cloudService(): "PRIVATE_PEERING" | "MICROSOFT_PEERING" {
      if (this.isAdd) {
        // 追加の場合
        return this.cloudVnMsInformation.cloudVnConnectTotal === 0
          ? "PRIVATE_PEERING"
          : "MICROSOFT_PEERING";
      } else {
        // 変更の場合
        return this.cloudVnMsDetail.cloudVnConnectCommon.cloudServiceType;
      }
    },
    vpnVnCodeSelectList(): {
      value: string;
      text: string;
    }[] {
      return this.vpnVnCodeList.map((v) => {
        if (v.vpnVnCode.startsWith("D")) {
          const mst = this.saasServiceMst.saasServiceList.find(
            (item) => item.saasCommon.vpnVnCode === v.vpnVnCode
          );
          return mst
            ? {
                value: v.vpnVnCode,
                text:
                  mst.saasCommon.vnName +
                  ":" +
                  mst.saasCommon.vpnVnCode +
                  "(L3)",
              }
            : { value: v.vpnVnCode, text: v.vpnVnCode };
        } else {
          return { value: v.vpnVnCode, text: v.vpnVnCode };
        }
      });
    },
    bandwidthSelectList(): { text: string; value: BandWidth }[] {
      // クラウド帯域値
      const limit1 = this.$filter.bandwidthToBps(this.cloudLineMaxBandwidth);
      // 1G超VNコネクト
      const limit2 = this.$filter.bandwidthToBps({
        value: this.vnConnects1G!.upperLimit,
        unit: "GBPS",
      });
      return this.bandwidthOptions.filter((v) => {
        const bps = this.$filter.bandwidthToBps(v.value)!;
        return bps <= limit1! && bps <= limit2!;
      });
    },
    /** Maximum Prefixの選択肢 */
    maximumPrefixSelectList(): { text: string; value: number | null }[] {
      if (this.cloudService === "MICROSOFT_PEERING") {
        // MICROSOFT_PEERINGの場合、'-'のみ表示
        return [{ value: null, text: "-" }];
      } else {
        if (this.isAdd) {
          return [{ value: 100, text: "100" }];
        } else {
          return [
            { value: 100, text: "100" },
            { value: 200, text: "200" },
            { value: 300, text: "300" },
            { value: 400, text: "400" },
            { value: 500, text: "500" },
            { value: 1000, text: "1000" },
            { value: 1500, text: "1500" },
            { value: 2000, text: "2000" },
          ].filter((v) => v.value <= this.maximumPrefixLimit!.upperLimit);
        }
      }
    },

    showManualCheckBox(): boolean {
      return this.cloudService === "MICROSOFT_PEERING" && this.isAdd;
    },

    canInputWanIpAndPeerIP(): boolean {
      if (this.isAdd) {
        return (
          this.cloudService === "PRIVATE_PEERING" ||
          (this.cloudService === "MICROSOFT_PEERING" && this.isWanIpManual)
        );
      } else {
        return false;
      }
    },

    vpnVnName(): string {
      if (this.isAdd) {
        // 追加の場合
        return this.getVpnVnName({
          vpnVnCode: this.privatePeeringDetail.cloudVnConnectCommon.vpnVnCode,
          vnName: this.privatePeeringDetail.cloudVnConnectCommon.vnName,
        });
      } else {
        // 変更の場合
        return this.getVpnVnName({
          vpnVnCode: this.cloudVnMsDetail.cloudVnConnectCommon.vpnVnCode,
          vnName: this.cloudVnMsDetail.cloudVnConnectCommon.vnName,
        });
      }
    },
    isBandWithType(): boolean {
      return this.qos && this.qos.qosType === "BANDWIDTH";
    },
    /** 経路集約アドレスの重複チェック用リスト作成 */
    routingAddressList() {
      return (index: number) => {
        return this.form.cloudVnConnectCommon!.routeAggregationAddressList
          ? this.form.cloudVnConnectCommon!.routeAggregationAddressList.filter(
              (_, num) => num !== index
            )
          : [];
      };
    },
  },
  watch: {
    /** 経路集約がCUSTOMに変更されて初期値が存在しない場合は初期化 */
    "form.cloudVnConnectCommon.routeAggregation"(newValue: RouteAggregation) {
      if (
        newValue === "CUSTOM" &&
        (!this.form.cloudVnConnectCommon.routeAggregationAddressList ||
          this.form.cloudVnConnectCommon.routeAggregationAddressList.length ===
            0)
      ) {
        this.form.cloudVnConnectCommon.routeAggregationAddressList = [""];
      }
    },
  },
  async mounted() {
    await this.load();

    if (this.isAdd && this.cloudService === "MICROSOFT_PEERING") {
      // 追加&MICROSOFT_PEERINGの場合はPRIVATE_PEERINGのvpnを設定
      (this.form as CloudVnMsPost).cloudVnConnectCommon.vpnVnCode =
        this.privatePeeringDetail.cloudVnConnectCommon.vpnVnCode;
    }

    this.isLoaded = true;
  },
  methods: {
    async load() {
      // 回線情報に紐づくクラウド回線情報を取得APIを呼び出す
      const lines = (await this.$api.cloudLine.getCloudLines()).lineList;
      const cloudLines = lines.flatMap((v) => v.cloudLineList);
      // クラウド回線の帯域最大値(品目の上限値制御用)
      this.cloudLineMaxBandwidth = cloudLines.find(
        (v) => v.cloudLineSeq === this.cloudLineSeq
      )!.bandwidth;

      // 1G超VNコネクトの値を取得（品目の上限値制御用）
      this.vnConnects1G = (
        await this.$api.upperLimit.getUpperLimitLine({
          upperLimitLineManageUnit: "MULTI_CLOUD",
          cloudLineSeq: this.cloudLineSeq,
        })
      ).cloudLine!.upperLimitList!.find(
        (v) => v.upperLimitItemName === "1G超VNコネクト"
      )!;
      if (this.isAdd) {
        // 追加の場合
        if (this.cloudService === "MICROSOFT_PEERING") {
          // MICROSOFT_PEERINGの場合
          this.privatePeeringDetail = await this.$api.cloudVnMs.getVnConnect(
            this.cloudVnMsInformation.cloudVnConnectsList[0].cloudVnConnectAct
              .wnumber
          );
          this.qos = await this.$api.cloudQosApi.getCloudQosDetail(
            this.privatePeeringDetail.cloudVnConnectCommon.vpnVnCode
          );
        } else {
          // PRIVATE_PEERINGの場合
          this.vpnVnCodeList = (
            await this.$api.cloudVnMs.getVpnVnCodes(
              this.cloudVnMsInformation.serviceKey!
            )
          ).sortBy(["vpnVnCode", "asc"]);
          this.saasServiceMst = await this.$api.saas.getAll();
        }
      } else {
        // 変更の場合
        if (
          this.cloudVnMsDetail.cloudVnConnectCommon.cloudServiceType ===
          "MICROSOFT_PEERING"
        ) {
          this.qos = await this.$api.cloudQosApi.getCloudQosDetail(
            this.cloudVnMsDetail.cloudVnConnectCommon.vpnVnCode
          );
        } else {
          // maximumPrefixの上限値を取得
          this.maximumPrefixLimit = (
            await this.$api.upperLimit.getUpperLimitLine({
              upperLimitLineManageUnit: "MULTI_CLOUD",
              cloudLineSeq: this.cloudLineSeq,
              wnumber: this.cloudVnMsDetail!.cloudVnConnectAct.wnumber,
            })
          ).cloudLine!.upperLimitList!.find(
            (v) => v.upperLimitItemName === "BGP4の最大経路数"
          )!;
        }
      }
    },

    async qosSettingInfo() {
      await this.$modal.show(CloudQosViewRC, {
        mode: "R",
        isNew: false,
        cloudQosDetail: this.qos,
      });
    },

    /** 経路集約アドレス削除 */
    removeRouteAddress(index: number) {
      const list = this.form.cloudVnConnectCommon.routeAggregationAddressList!;
      if (list.length > 1) {
        list.splice(index, 1);
      } else {
        this.form.cloudVnConnectCommon.routeAggregationAddressList = [""];
      }
    },

    /** 経路集約アドレス追加 */
    addRouteAddress() {
      this.form.cloudVnConnectCommon.routeAggregationAddressList = [
        ...this.form.cloudVnConnectCommon.routeAggregationAddressList!,
        "",
      ];
    },

    getVpnVnName(info: { vpnVnCode: string; vnName: string }) {
      if (info.vpnVnCode.startsWith("D")) {
        return info.vnName + ":" + info.vpnVnCode + "(L3)";
      } else {
        return info.vpnVnCode;
      }
    },

    async submit() {
      const form = {
        ...this.form,
        cloudVnConnectCommon: {
          ...this.form.cloudVnConnectCommon,
          routeAggregationAddressList:
            this.form.cloudVnConnectCommon!.routeAggregation === "ON"
              ? []
              : this.form.cloudVnConnectCommon!.routeAggregation === "OFF"
              ? null
              : this.form.cloudVnConnectCommon!.routeAggregationAddressList,
        },
        cloudVnConnectAct: {
          ...this.form.cloudVnConnectAct,
          ...(this.showManualCheckBox && !this.isWanIpManual
            ? { wanAddress: null, peerIpAddress: null }
            : {}),
        },
        cloudVnConnectSby: {
          ...this.form.cloudVnConnectSby,
          ...(this.showManualCheckBox && !this.isWanIpManual
            ? { wanAddress: null, peerIpAddress: null }
            : {}),
        },
      } as CloudVnMsPost | CloudVnMsPut;

      if (this.isAdd) {
        // 追加の場合
        let displayVnName;
        let qosInfo: CloudVnMsDetail["cloudVnConnectCommon"]["qos"] = null;
        if (this.cloudService === "MICROSOFT_PEERING") {
          displayVnName = this.privatePeeringDetail.cloudVnConnectCommon.vnName;
          qosInfo = {
            isQos: true,
            qosBandControlPattern:
              form.cloudVnConnectCommon.qosBandControlPattern,
          };
        } else {
          (form as CloudVnMsPost).cloudVnConnectCommon!.vpnVnCode =
            this.selectedVpnVn!.value;
          const mst = this.saasServiceMst.saasServiceList.find(
            (item) =>
              item.saasCommon.vpnVnCode ===
              (form as CloudVnMsPost).cloudVnConnectCommon.vpnVnCode
          );
          displayVnName = mst?.saasCommon.vnName;
        }

        await this.$modal.show(MultiCloudVNConnectionConfirmMS, {
          cloudVnMsDetail: {
            serviceKey: this.cloudVnMsInfo.serviceKey,
            location: this.cloudVnMsInfo.location,
            cloudVnConnectCommon: {
              ...form.cloudVnConnectCommon,
              vnName: displayVnName,
              routingType: "BGP4",
              qos: qosInfo,
            },
            cloudVnConnectAct: {
              ...form.cloudVnConnectAct,
              localPreference: "HIGH_PRIORITY",
              med: "LOW_PRIORITY",
            },
            cloudVnConnectSby: {
              ...form.cloudVnConnectSby,
              localPreference: "LOW_PRIORITY",
              med: "HIGH_PRIORITY",
            },
          },
          displayType: "A",
        });

        await this.$api.cloudVnMs.postVnConnect(
          this.cloudLineSeq,
          form as CloudVnMsPost
        );
        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      } else {
        let qosInfo: CloudVnMsDetail["cloudVnConnectCommon"]["qos"] = null;
        if (this.cloudService === "MICROSOFT_PEERING") {
          qosInfo = {
            isQos: true,
            qosBandControlPattern:
              form.cloudVnConnectCommon.qosBandControlPattern,
          };
        }
        await this.$modal.show(MultiCloudVNConnectionConfirmMS, {
          cloudVnMsDetail: {
            serviceKey: this.cloudVnMsInfo.serviceKey,
            location: this.cloudVnMsInfo.location,
            cloudVnConnectCommon: {
              ...form.cloudVnConnectCommon,
              qos: qosInfo,
            },
            cloudVnConnectAct: form.cloudVnConnectAct,
            cloudVnConnectSby: form.cloudVnConnectSby,
          },
          displayType: "M",
        });
        // 変更の場合
        await this.$api.cloudVnMs.putVnConnect(
          this.cloudLineSeq,
          this.cloudVnMsDetail.cloudVnConnectAct.wnumber,
          form as CloudVnMsPut
        );
        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },
  },
});

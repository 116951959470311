




































import Vue, { PropType } from "vue";
import { CpaContract, CpaNwDetail, CpaNwOptions } from "@/apis/Cpa5gSaApi";

export default Vue.extend({
  name: "SaNwContractDeleteConfirm",
  props: {
    /** CPA契約情報 */
    cpaContractInfo: {
      type: Object as PropType<CpaContract>,
      required: true,
    },
    /** CPA NW 詳細情報 */
    nwSettingDetail: {
      type: Object as PropType<CpaNwDetail>,
      required: true,
    },
    /** CPA特殊項目設定情報 */
    nwOptions: {
      type: Object as PropType<CpaNwOptions>,
      required: true,
    },
  },
});































































































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { GetPolicyType1 } from "@/apis/NwPolicyApi";
import cloneDeep from "lodash/cloneDeep";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import { Type1NatPolicyList } from "@/modals/applianceContractSetting/InternetType1PolicyUtmList.vue";
import InternetType1PolicyRuleDestNatEdit from "@/modals/applianceContractSetting/InternetType1PolicyRuleDestNatEdit.vue";
import { Type1Service } from "@/apis/ContractChangeType1Api";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";

export type Type1DestinationNatPolicy = Type1NatPolicyList["policyList"][0];

export default Vue.extend({
  name: "InternetType1PolicyRuleDestNatViewRD",
  components: { PolicySrcAddress },
  props: {
    /** ゾーン */
    zone: {
      type: Object as PropType<ZoneRelationItem>,
      required: true,
    },
    /** ポリシーリスト */
    policyList: {
      type: Array as PropType<Type1DestinationNatPolicy[]>,
      required: true,
    },
    /** type1ポリシー全体 */
    type1Policy: {
      type: Object as PropType<GetPolicyType1>,
      required: true,
    },
    /** サービスメニュー(インターネットType1) */
    type1Service: {
      type: Object as PropType<Type1Service>,
      required: true,
    },
  },
  data() {
    return {
      table: {
        fields: [
          { key: "_handle", label: "↑↓", tdClass: "handle" },
          { key: "policyId", label: "ID" },
          { key: "srcAddressList", label: "送信元アドレス" },
          { key: "ipMapping", label: "IPマッピング" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
          { key: "delete", label: "" },
        ],
        items: cloneDeep(this.policyList),
      } as Pick<AppTableData<Type1DestinationNatPolicy>, "fields" | "items">,
      natPolicyLimit: [] as UpperLimitEntity[],
      isLoaded: false,
    };
  },
  computed: {
    /** true: 作成できるポリシー数の上限, false: 上限ではない */
    limitPolicy(): boolean {
      // NAT側のカスタムポリシー数
      const totalPolicy =
        (this.latestType1Policy.policyIPMasqueradeType1List
          ?.flatMap((e) => e.policyList)
          .filter((e) => e.policyCategoryInfo === "CUSTOM_POLICY").length ??
          0) +
        (this.latestType1Policy.policyNatType1List?.flatMap((e) => e.policyList)
          .length ?? 0);
      return (
        totalPolicy >=
        this.natPolicyLimit!.filter(
          (e) => e.upperLimitItemName === "ポリシー数(NAT)"
        )[0]!.upperLimit
      );
    },
    /** 最新の全体ポリシー（全体ポリシー + 編集中のポリシー ）*/
    latestType1Policy(): GetPolicyType1 {
      return {
        ...this.type1Policy,
        policyNatType1List:
          this.type1Policy.policyNatType1List?.map((e) => {
            return e.zoneRelationId === this.zone.zoneRelationId
              ? { ...e, policyList: this.table.items }
              : e;
          }) ?? [],
      };
    },
  },
  async mounted() {
    /** NATの設定上限値を取得 */
    this.natPolicyLimit = (
      await this.$api.upperLimit.getUpperLimitLine({
        upperLimitLineManageUnit: "APPLIANCE",
        applianceType: "NAT",
        enumber: this.type1Service.enumberMainAct,
      })
    ).internetLine!.upperLimitList!;
    this.isLoaded = true;
  },
  methods: {
    /** ポリシー追加 */
    async addPolicy() {
      const addPolicy: Type1DestinationNatPolicy = await this.$modal.show(
        InternetType1PolicyRuleDestNatEdit,
        {
          zone: this.zone,
          type1Policy: this.latestType1Policy,
        }
      );
      this.table.items.splice(this.table.items.length, 0, addPolicy);
    },

    /** ポリシー編集 */
    async editPolicy(policy: Type1DestinationNatPolicy, index: number) {
      const editPolicy: Type1DestinationNatPolicy = await this.$modal.show(
        InternetType1PolicyRuleDestNatEdit,
        {
          zone: this.zone,
          policy,
          type1Policy: this.latestType1Policy,
        }
      );
      this.$set(this.table.items, index, editPolicy);
    },

    /** ポリシー削除 */
    async deletePolicy(index: number) {
      this.table.items.splice(index, 1);
    },

    /** ポリシー編集完了 */
    async submit() {
      await this.$confirm(`ポリシーを保存します。よろしいですか？`);
      (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(
        this.table.items
      );
    },
  },
});

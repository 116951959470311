import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import moment from "moment";

/**
 * 現在の日時より過去の日時であること
 */
export default {
  params: ["date"],
  validate(
    value: string | undefined | null,
    { date }: { date?: string }
  ): boolean {
    if (!value || !date) {
      return true;
    }

    const timeElements = value.split(":");

    if (timeElements.length === 2) {
      if (moment(`${date}T${value}:00`).isBefore(moment())) {
        return true;
      }
    } else if (timeElements.length === 3) {
      if (moment(`${date}T${value}`).isBefore(moment())) {
        return true;
      }
    }
    return false;
  },
} as ValidationRuleSchema;

import { ApplianceInfo } from "@/apis/ContractApi";

/**
 * アプライアンスの表示値を作成する
 * @param applianceType アプライアンス種別
 * @param bandwidth 帯域情報
 */
export default function applianceTypeTo(
  applianceType: ApplianceInfo["applianceType"],
  bandwidth: ApplianceInfo["bandwidth"]
): string {
  // アプライアンス種別がBEなら種別(BE)で返し、それ以外なら、種別(帯域値 + 単位)を返す
  switch (applianceType) {
    case "UTM_BE":
      return "UTM(BE)";
    case "UTM_CONTRACT_BAND":
      return `UTM(${bandwidth?.value}${bandwidth?.unit.charAt(0)})`;
    case "IFW_BE":
      return "IFW(BE)";
    case "IFW_CONTRACT_BAND":
      return `IFW(${bandwidth?.value}${bandwidth?.unit.charAt(0)})`;
    case "INTRANET_FW":
      return `イントラネットFW(${bandwidth?.value}${bandwidth?.unit.charAt(
        0
      )})`;
    default:
      return "-";
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"エクストラネット承認","classes":"modal-medium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"detail-container border-bottom"},[_c('p',{staticClass:"font-weight-bold my-2"},[_vm._v(" 誤って承認した場合、情報漏洩やお客様の通信に支障を及ぼす可能性があります。 ")]),_c('connection-info-table',{attrs:{"connectionInfo":_vm.confirmData,"is-extranet-approval":true}})],1),_c('p',{staticClass:"font-weight-bold mt-3"},[_vm._v(" 申請されたPINコードを入力してください。 ")]),_c('validation-provider',{attrs:{"name":"PINコード","rules":{
              required: true,
              length: 4,
              is_half_width_alpha_numeric: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"PINコード","msg":_vm._f("msg")('info_pin_code'),"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.confirmPINCode),callback:function ($$v) {_vm.confirmPINCode=$$v},expression:"confirmPINCode"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"オーダー備考","rules":{ max: 200 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"オーダー備考","msg":_vm._f("msg")('info_max_length',{ maxLength: 200 }),"placement":"right","isRequired":false}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.confirmNote),callback:function ($$v) {_vm.confirmNote=$$v},expression:"confirmNote"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("承認")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
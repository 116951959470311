import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * メールアドレスのバリデーション
 * ドメイン独自のチェック形式で標準とは異なる
 * @see https://vee-validate.logaretm.com/v3/guide/rules.html#email
 */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) return true;
    // WVS2UI-8730 メールアドレスを入力時、文字数が多くなると反映に時間がかかる
    // バックトラックによって文字数に応じてに比較処理が指数関数的に増加している
    // 上記の対策のために先読みを使用（破壊的なバックトラック）
    // https://ja.javascript.info/regexp-catastrophic-backtracking
    return !!value.match(
      /^((?=(?<local>[a-zA-Z0-9!#$%&*+\-/=?^_`{|}~]+))\k<local>\.?)*[a-zA-Z0-9!#$%&*+\-/=?^_`{|}~]+@\w+([-.]\w+)*\.\w+([-.]\w+)*/
    );
  },
} as ValidationRuleSchema;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoaded)?_c('div',[_c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"インターネットType4経路設定","classes":"modal-large-plus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('b-form-group',[_c('div',{staticClass:"d-flex mb-2 text-center"},[_c('div',{staticClass:"col-4 ml-3 th_header"},[_c('app-badge',{attrs:{"item":"宛先","msg":_vm._f("msg")('info_cidr_ip_address_of_network_address',{
                          ipaddress1: '61.200.163.0/24',
                          genre: '宛先',
                          subnet1: '1',
                          subnet2: '30',
                          ipaddress2: '61.200.163.35/32',
                        }),"placement":"right","is-info":_vm.changeable,"is-required":_vm.changeable}})],1),_c('div',{staticClass:"col-5 th_header"},[_vm._v("説明")])]),_vm._l((_vm.route.type4RouteList),function(item,index){return _c('div',{key:index,staticClass:"row align-items-center mb-2"},[_c('div',{staticClass:"col-4"},[_c('validation-provider',{attrs:{"vid":("internetRoute" + index),"name":"宛先","rules":{
                      required_if: _vm.route.type4RouteList.length > 1,
                      is_ip_subnet: { min: 0, max: 32, exclude: [31] },
                      is_not_current_ip: true,
                      is_not_loopback_ip: true,
                      is_not_multicast_ip: true,
                      is_not_class_e_ip: true,
                      is_not_broadcast_ip: true,
                      is_network_address: true,
                      duplicate: { list: _vm.dstAddressList(index) },
                    }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"placeholder":!_vm.changeable
                          ? null
                          : _vm.$msg(
                              'placeholder_global_ip_address_with_subnet32'
                            ),"state":_vm._f("validState")(validContext),"disabled":!_vm.changeable},model:{value:(item.internetAddress),callback:function ($$v) {_vm.$set(item, "internetAddress", $$v)},expression:"item.internetAddress"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('div',{staticClass:"col-5 mt-2"},[(item.isMicrosoft365)?[_vm._v(" M365用自動設定 ")]:_vm._e()],2),_c('div',{staticClass:"col-1"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.removeRoute(index)}}},[_c('b-icon-dash')],1)],1)])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-9"}),_c('div',{staticClass:"col-1"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.addRoute}},[_c('b-icon-plus')],1)],1)])],2)],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v("設定")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)])]}}],null,true)})]}}],null,false,3967212096)},'app-modal',_vm.$attrs,false),_vm.$listeners))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
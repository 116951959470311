var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"確認","classes":"modal-full"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"detail-container mt-2"},[_c('div',{staticClass:"font-weight-bold mb-3"},[_vm._v(" ポリシーを設定反映します。よろしいですか？ ")]),_c('div',[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"VPNコード"}},[_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.contractInfo.vpnCode))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"セキュリティアプライアンス"}},[_c('span',{staticClass:"ml-3"},[_vm._v("イントラネットFW("+_vm._s(_vm.contractInfo.intranetFwBandwidth.value)+_vm._s(_vm._f("enumTo")(_vm.contractInfo.intranetFwBandwidth.unit,"bandwidth_unit"))+")")])])],1),_vm._l((_vm.displayPolicyList),function(zonePolicy,i){return _c('b-form-group',{key:i,attrs:{"label":_vm.$filter.enumTo(zonePolicy.srcZoneType, 'zoneType') +
              '/' +
              zonePolicy.srcZoneName +
              ' → ' +
              _vm.$filter.enumTo(zonePolicy.dstZoneType, 'zoneType') +
              '/' +
              zonePolicy.dstZoneName,"label-class":"font-weight-bold"}},[_c('b-table-simple',{attrs:{"small":""}},[_c('b-thead',{staticClass:"text-center small",attrs:{"head-variant":"light"}},[_c('b-tr',[_c('b-th',[_vm._v("ID")]),_c('b-th',[_vm._v("送信元アドレス")]),_c('b-th',[_vm._v("宛先アドレス")]),_c('b-th',[_vm._v("サービス")]),_c('b-th',[_vm._v("アクション")]),_c('b-th',[_vm._v("有効/無効")]),_c('b-th',[_vm._v("備考")])],1)],1),_c('b-tbody',_vm._l((zonePolicy.policyList),function(policy,i){return _c('b-tr',{key:i,class:{
                    'not-editable-policy':
                      policy.policyCategoryInfo ===
                      'UNEDITABLE_DEFAULT_POLICY',
                  }},[_c('b-td',[_vm._v(_vm._s(policy.policyId))]),_c('b-td',[_c('intranet-policy-src-address',{attrs:{"ip-address-list":policy.srcAddressList}})],1),_c('b-td',[_c('intranet-policy-dst-address',{attrs:{"ip-address-list":policy.dstAddressList}})],1),_c('b-td',[_c('intranet-policy-service',{attrs:{"default-service-list":policy.serviceList,"custom-service-list":policy.customServiceList}})],1),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("enumTo")(policy.actionType,"allow_deny"))+" ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("enumTo")(policy.isPolicyStatus,"enable"))+" ")]),_c('b-td',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(_vm._f("emptyTo")(policy.description,"-")))])],1)}),1)],1)],1)})],2)])]),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":ok}},[_vm._v("はい")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("いいえ")])],1)]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
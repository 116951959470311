

























































































































































































import Vue, { PropType } from "vue";
import { ApplianceInfo } from "@/apis/ContractApi";
import cloneDeep from "lodash/cloneDeep";
import {
  PostType4ExtraSite,
  PostType4ExtraSiteAddress,
  ResultPostType4ExtraSite,
  Type4ExtraSite,
  Type4ExtraSiteList,
  Type4SiteEdit,
  Type4SiteEditAddressList,
} from "@/apis/Type4SiteApi";
import Type4ExtraSiteConfirmModal from "@/modals/type4/Type4ExtraSiteConfirmModal.vue";

export default Vue.extend({
  name: "Type4ExtraSiteEdit",
  props: {
    extraSite: {
      type: Object as PropType<Type4ExtraSiteList | null>,
      default: null,
    },
    type4Id: {
      type: String as PropType<ApplianceInfo["type4Id"]>,
      required: true,
    },
    type4SiteKeyId: {
      type: String as PropType<Type4ExtraSite["type4SiteKeyId"]>,
      required: true,
    },
  },

  data() {
    return {
      /** 詳細データ */
      extraData: {} as Type4SiteEdit,
      keyId4AddrRegister: "",
      isLoaded: false,
    };
  },
  computed: {
    /** 既存のIPアドレス */
    existsIpAddress() {
      // 既存のアドレスリストに同じIPアドレスが存在した場合はエラーとする
      return (index: number) => {
        return this.extraData.addressList
          .filter((_, num) => num !== index)
          .map((v) => v.ipAddress);
      };
    },
    /** 入力済みIPアドレス一覧 */
    inputIpAddresses(): Type4SiteEditAddressList[] {
      return this.extraData.addressList.filter((e) => e.ipAddress);
    },
    /** アドレス名入力済みの場合は,true */
    hasInputIpAddressName(): boolean {
      return this.extraData.addressList.some((v) => v.ipAddressName);
    },
    /** 遷移元がポリシー設定の場合は,true */
    isPolicy(): boolean {
      return this.$route.path === "/network/contract";
    },
  },
  mounted() {
    this.load();
    this.isLoaded = true;
  },

  methods: {
    /**
     * 初期化機能
     * システム上の配置状態追加(addressList)
     */
    load() {
      // 追加モードdefault値の設定
      if (this.extraSite === null) {
        this.extraData = {
          siteId: "",
          siteName: "",
          addressList: [
            {
              ipAddressId: null,
              ipAddressName: "",
              ipAddress: "",
              isPolicyInUse: false,
            },
          ],
        };
      } else {
        // 変更モード値のコピー
        this.extraData = cloneDeep(this.extraSite);
        if (this.extraData.addressList.length < 1) this.addIPAddress();
        else if (this.isPolicy) this.addIPAddress();
      }
    },
    /** 追加、変更機能 */
    async submit() {
      if (this.extraData.siteId) {
        this.keyId4AddrRegister = this.keyId4AddrRegister
          ? this.keyId4AddrRegister
          : this.type4SiteKeyId;
        // 変更機能
        await this.$modal.show(Type4ExtraSiteConfirmModal, {
          message: "エクストラサイト設定を変更します。よろしいですか？",
        });
        // 変更APIを呼び出す
        await this.postExtraSiteModifyApi();
      } else {
        // 追加機能
        await this.$confirm("エクストラサイトを追加します。よろしいですか？");
        // 追加データの設定
        const postData: PostType4ExtraSite = {
          siteName: this.extraData.siteName,
          type4SiteKeyId: this.type4SiteKeyId,
        };
        // レスポンスデータの取得
        const resultData: ResultPostType4ExtraSite =
          await this.$api.type4SiteApi.postType4ExtraSiteRegister(
            this.type4Id,
            postData
          );

        this.extraData.siteId = resultData.siteId;
        this.keyId4AddrRegister = resultData.type4SiteKeyId;

        // 変更APIを呼び出す
        if (this.inputIpAddresses.length > 0) {
          await this.postExtraSiteModifyApi();
        }
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * エクストラサイト変更APIを呼び出す
     */
    async postExtraSiteModifyApi() {
      // 変更APIデータの設定
      const reqData: PostType4ExtraSiteAddress = {
        siteName: this.extraData.siteName,
        addressList: this.inputIpAddresses.map((e) => ({
          ipAddressId: e.ipAddressId,
          ipAddressName: e.ipAddressName || null,
          ipAddress: e.ipAddress,
        })),
        type4SiteKeyId: this.keyId4AddrRegister,
      };
      await this.$api.type4SiteApi.postType4ExtraSiteModify(
        this.extraData.siteId,
        this.type4Id,
        reqData
      );
    },
    /**
     * IPAddress追加機能
     * 押下時一行追加
     */
    addIPAddress() {
      this.extraData.addressList.push({
        ipAddressId: null,
        ipAddressName: "",
        ipAddress: "",
        isPolicyInUse: false,
      });
    },
    /**
     * IPAddress削除機能
     * 押下時その行削除
     * 一行しかない場合その行クリア
     */
    removeIPAddress(target: Type4SiteEditAddressList) {
      if (this.extraData.addressList.length > 1) {
        this.extraData.addressList.splice(
          this.extraData.addressList.indexOf(target),
          1
        );
      } else {
        this.extraData.addressList = [
          {
            ipAddressId: null,
            ipAddressName: "",
            ipAddress: "",
            isPolicyInUse: false,
          },
        ];
      }
    },
  },
});

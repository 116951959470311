import { render, staticRenderFns } from "./IntranetPolicySrcAddress.vue?vue&type=template&id=04baf11a&"
import script from "./IntranetPolicySrcAddress.vue?vue&type=script&lang=ts&"
export * from "./IntranetPolicySrcAddress.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports






















import Vue, { PropType } from "vue";
import { IntraPolicy } from "@/apis/IntranetfwPolicyApi";

/**
 * ポリシー用カスタムサービスの型
 * サービス名のプロパティ名がAPIによって異なるので、それぞれに合うよう型定義
 */
type CustomServiceIntranet = IntraPolicy["customServiceList"][0] & {
  serviceName: string;
};

/** ポリシー設定(イントラネットFW)のサービスを表示するコンポーネント */
export default Vue.extend({
  name: "IntranetPolicyService",
  props: {
    /** デフォルトサービスリスト */
    defaultServiceList: {
      type: Array as PropType<IntraPolicy["serviceList"]>,
      required: true,
    },
    /** カスタムサービスリスト */
    customServiceList: {
      type: Array as PropType<CustomServiceIntranet[]>,
      required: true,
    },
  },
  computed: {
    /** 昇順ソート済みのデフォルトサービスリスト */
    defaultServices(): IntraPolicy["serviceList"] {
      return this.defaultServiceList.sortBy(["serviceName", "asc"]);
    },
    /** 昇順ソート済みのカスタムサービスリスト */
    customServices(): CustomServiceIntranet[] {
      return this.customServiceList.sortBy([
        (e) => e.customServiceName ?? e.serviceName,
        "asc",
      ]);
    },
  },
});






















































































































































import Vue, { PropType } from "vue";
import { BandwidthListGetEntity } from "@/apis/AccessLineApi";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AccessLineReference",
  props: {
    /** 表示対象のアクセス回線情報 */
    accessLineItem: {
      type: Object as PropType<BandwidthListGetEntity>,
      required: true,
    },
    /** サービス開始済フラグ：開始済の場合はtrue */
    isServiceStarted: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-large","title":"Microsoft Peering向けNAT設定"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"VPN/VNコード"}},[_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.natSettingDetails.vpnVnCode))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"NAT用グローバルIP"}},[_c('div',{staticClass:"pl-3"},[_c('span',[_vm._v(" 現在 "+_vm._s(_vm.natSettingDetails.usedNat)+"個 / 上限 "+_vm._s(_vm.natSettingDetails.upperLimitNat)+"個 "),_c('br'),_vm._v(" 追加グローバルIP数 "+_vm._s(_vm.newIp.length)+" 個 ")])])]),_c('b-table-simple',{staticClass:"nat-table",attrs:{"borderless":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("グローバルIP")]),_c('b-th',[_c('app-badge',{attrs:{"item":"プライベートIP","msg":'・' +
                      _vm.$msg('info_cidr_ip_v4_with_subnet', { subnet: '32' }) +
                      '\n' +
                      '・' +
                      _vm.$msg('info_private_ip_subnet', { subnet: '32' })}})],1),_c('b-th',[_c('app-badge',{attrs:{"item":"備考","msg":"20文字以内","isRequired":false}})],1),_c('th')],1)],1),_c('b-tbody',[_vm._l((_vm.natSetting),function(item,index){return _c('tr',{key:index + 'paid'},[_c('td',[_vm._v(_vm._s(item.globalIpAddress))]),_c('td',[_c('validation-provider',{attrs:{"vid":("ip_" + index),"name":"プライベートIP","rules":{
                      required: true,
                      is_ip_subnet: { eq: 32 },
                      is_not_current_ip: true,
                      is_not_loopback_ip: true,
                      is_not_multicast_ip: true,
                      is_not_class_e_ip: true,
                      is_not_broadcast_ip: true,
                      duplicate: { list: _vm.privateIpList(index) },
                    }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext),"disabled":_vm.canNotEdit,"placeholder":_vm.canNotEdit
                          ? null
                          : _vm.$msg('placeholder_ip_address_with_subnet')},model:{value:(item.privateIpAddress),callback:function ($$v) {_vm.$set(item, "privateIpAddress", $$v)},expression:"item.privateIpAddress"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"name":"備考","rules":{ max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext),"disabled":_vm.canNotEdit},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('td',[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary","disabled":_vm.isEdited},on:{"click":function($event){return _vm.removeNat(index)}}},[_c('b-icon-dash')],1)],1)])}),_vm._l((_vm.newIp),function(item,index){return _c('tr',{key:index + 'new'},[_c('td',[_vm._v("設定完了後にIPが払い出されます")]),_c('td',[_c('validation-provider',{attrs:{"vid":("ip_" + index),"name":"プライベートIP","rules":{
                      required: true,
                      is_ip_subnet: { eq: 32 },
                      is_not_current_ip: true,
                      is_not_loopback_ip: true,
                      is_not_multicast_ip: true,
                      is_not_class_e_ip: true,
                      is_not_broadcast_ip: true,
                      duplicate: {
                        list: _vm.privateIpList(index + _vm.natSetting.length),
                      },
                    }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext),"placeholder":_vm._f("msg")('placeholder_ip_address_with_subnet32')},model:{value:(_vm.newIp[index].privateIpAddress),callback:function ($$v) {_vm.$set(_vm.newIp[index], "privateIpAddress", $$v)},expression:"newIp[index].privateIpAddress"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"name":"備考","rules":{ max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.newIp[index].description),callback:function ($$v) {_vm.$set(_vm.newIp[index], "description", $$v)},expression:"newIp[index].description"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('td',[(
                      !(_vm.natSettingDetails.usedNat === 0 && _vm.newIp.length === 1)
                    )?_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.removeNewIp(index)}}},[_c('b-icon-dash')],1):_vm._e()],1)])}),_c('tr',[_c('td',{attrs:{"colspan":"3"}}),_c('td',[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary","disabled":!_vm.canAdd || _vm.isEdited},on:{"click":_vm.addNewIp}},[_c('b-icon-plus')],1)],1)])],2)],1)],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("設定")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }





























































import Vue, { PropType } from "vue";
import { GetProfile } from "@/apis/NwPolicyApi";
import { NullableProperty } from "@/shims-vue";

/** インターネットType1,4のポリシーのプロファイル表示が出来るコンポーネント */
export default Vue.extend({
  name: "PolicyProfile",
  props: {
    /** プロファイル（UTM/IFW） */
    profile: {
      type: Object as PropType<
        | GetProfile
        | NullableProperty<
            GetProfile,
            | "idsIps"
            | "urlFiltering"
            | "webAntiVirus"
            | "mailAntiVirus"
            | "isAntiSpyware"
          >
        | Pick<GetProfile, "internetFW" | "profileInternetFw">
      >,
      required: true,
    },
  },
  computed: {
    /** true: プロファイルの設定が全てOFF系, false: いずれかはON */
    isAllOff(): boolean {
      if ("idsIps" in this.profile) {
        // UTM
        return (
          this.profile.internetFW === "OFF" &&
          (this.profile.idsIps ?? "OFF") === "OFF" &&
          (this.profile.urlFiltering ?? "OFF") === "OFF" &&
          (this.profile.webAntiVirus ?? "OFF") === "OFF" &&
          (this.profile.mailAntiVirus ?? "OFF") === "OFF" &&
          !(this.profile.isAntiSpyware ?? false)
        );
      } else {
        // IFW
        return this.profile.internetFW === "OFF";
      }
    },
  },
});

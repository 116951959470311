import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 半角英数 + 数字でどちらも1文字以上含むこと */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }

    return (
      /^[a-zA-Z0-9]+$/.test(value) &&
      /[a-zA-Z]+/.test(value) &&
      /[0-9]+/.test(value)
    );
  },
} as ValidationRuleSchema;

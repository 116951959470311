import { Vue as _Vue } from "vue/types/vue";
import AppModal from "@/components/AppModal.vue";
import { PluginObject } from "vue";
import ConfirmModal from "@/modals/ConfirmModal.vue";
import AppTable from "@/components/AppTable.vue";
import AppGraph from "@/components/AppGraph.vue";
import AppSearch from "@/components/AppSearch.vue";
import AppStepper from "@/components/AppStepper.vue";
import AppMultiSelect from "@/components/AppMultiSelect.vue";
import AppInvalidFeedback from "@/components/AppInvalidFeedback.vue";
import { DynamicModalOptions } from "vue-final-modal";
import AppBadge from "@/components/AppBadge.vue";
import AppTableDraggable from "@/components/AppTableDraggable.vue";
import ApiClient from "@/apis/ApiClient";
import { Store } from "vuex";
import { RootState } from "@/store";
import { SystemError } from "@/error/errors";
import { PermissionDirective } from "@/directives/permission.directive";
import AppService from "@/services/AppService";
import message from "@/filters/message.filter";
import OperationCommand from "@/components/operation-tool/OperationCommand.vue";
import UpperLimit from "@/components/operation-tool/UpperLimit.vue";
import QoSSetting from "@/components/operation-tool/QoSSetting.vue";
import ProvisioningStatus from "@/components/ProvisioningStatus.vue";
import CrossValidation from "@/validators/cross/cross-validation";
import BFormSelectObj from "@/components/BFormSelectObj.vue";
import CloudQoSSetting from "@/components/operation-tool/CloudQoSSetting.vue";
import Traffic from "@/components/operation-tool/Traffic.vue";
import SaNwContractInfo from "@/components/cpa5gSa/SaNwContractInfo.vue";
import SaNwSettingInfo from "@/components/cpa5gSa/SaNwSettingInfo.vue";
import BFormInputNumber from "@/components/BFormInputNumber.vue";
import FileUpload from "@/components/FileUpload.vue";
import ManualButton from "@/components/ManualButton.vue";
import AppSelect from "@/components/AppSelect.vue";
import ProvisioningQosStatus from "@/components/ProvisioningQosStatus.vue";

const AppPlugin: PluginObject<{ store: Store<RootState> }> = {
  install(Vue: typeof _Vue, options): void {
    if (!options) {
      throw new SystemError("app plugin required options");
    }

    Vue.component("AppModal", AppModal);
    Vue.component("AppTable", AppTable);
    Vue.component("AppGraph", AppGraph);
    Vue.component("AppSearch", AppSearch);
    Vue.component("AppStepper", AppStepper);

    Vue.component("AppMultiselect", AppMultiSelect);
    Vue.component("AppSelect", AppSelect);
    Vue.component("BFormInputNumber", BFormInputNumber);
    Vue.component("BFormSelectObj", BFormSelectObj);
    Vue.component("AppInvalidFeedback", AppInvalidFeedback);
    Vue.component("AppBadge", AppBadge);
    Vue.component("AppTableDraggable", AppTableDraggable);
    Vue.component("OperationCommand", OperationCommand);
    Vue.component("SaNwContractInfo", SaNwContractInfo);
    Vue.component("SaNwSettingInfo", SaNwSettingInfo);
    Vue.component("UpperLimit", UpperLimit);
    Vue.component("QoSSetting", QoSSetting);
    Vue.component("CloudQoSSetting", CloudQoSSetting);
    Vue.component("ProvisioningStatus", ProvisioningStatus);
    Vue.component("ProvisioningQosStatus", ProvisioningQosStatus);
    Vue.component("Traffic", Traffic);
    Vue.component("FileUpload", FileUpload);
    Vue.component("ManualButton", ManualButton);

    // カスタムディレクティブ
    Vue.directive("permission", PermissionDirective);

    // APIクライアント
    Vue.prototype.$api = new ApiClient(options.store);
    // サービス（業務ロジック）
    Vue.prototype.$service = new AppService(options.store);

    //確認モーダル
    Vue.prototype.$confirm = (
      message: string,
      title?: string,
      isClose?: boolean
    ): Promise<void> => {
      return new Promise((resolve) => {
        Vue.prototype.$vfm.show({
          component: ConfirmModal,
          bind: { message, title, isClose },
          on: {
            ok() {
              resolve();
            },
          },
        });
      });
    };

    // モーダル（汎用）
    Vue.prototype.$modal = {
      show: (
        component: DynamicModalOptions["component"],
        bind?: DynamicModalOptions["bind"]
      ): Promise<unknown> => {
        return new Promise((resolve) => {
          Vue.prototype.$vfm.show({
            component: component,
            bind: bind,
            on: { ok: resolve },
          });
        });
      },
    };
    // メッセージ管理
    Vue.prototype.$msg = message;
    // 相関チェック用クラス
    Vue.prototype.$crossValidation = new CrossValidation();
  },
};
export default AppPlugin;

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** WANアドレスと同一の値ではないことをチェック */
export default {
  params: ["wan"],
  validate(
    value: string | undefined | null,
    { wan }: { wan: string | undefined | null }
  ): boolean {
    if (!value || !wan) {
      return true;
    }
    return value !== wan;
  },
} as ValidationRuleSchema;

import { render, staticRenderFns } from "./IntranetFwPfgwZoneRelationList.vue?vue&type=template&id=6a302850&scoped=true&"
import script from "./IntranetFwPfgwZoneRelationList.vue?vue&type=script&lang=ts&"
export * from "./IntranetFwPfgwZoneRelationList.vue?vue&type=script&lang=ts&"
import style0 from "./IntranetFwPfgwZoneRelationList.vue?vue&type=style&index=0&id=6a302850&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a302850",
  null
  
)

export default component.exports






























































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { ZoneRelation, ZoneRelationItem } from "@/apis/ZoneRelationApi";
import {
  IntranetFwPolicy,
  PostIntranetPolicy,
} from "@/apis/IntranetfwPolicyApi";
import IntranetFwPolicyList from "@/modals/applianceContractSetting/IntranetFwPolicyList.vue";
import IntranetFwPolicyConfirm from "@/modals/applianceContractSetting/IntranetFwPolicyConfirm.vue";
import { IntranetfwServiceMenu } from "@/apis/IntranetfwServiceMenuApi";
import { UploadError } from "@/services/ConfigDownloadService";

export default Vue.extend({
  name: "IntranetFwPolicyZoneList",
  props: {
    /** イントラネット契約情報(設定反映の表示用) */
    contractInfo: {
      type: Object as PropType<
        Pick<IntranetfwServiceMenu, "vpnCode" | "intranetFwBandwidth">
      >,
      required: true,
    },
  },
  data() {
    return {
      /** 通信完了フラグ */
      isLoaded: false,
      /** ゾーン接続情報 */
      zoneRelation: {} as ZoneRelation,
      zoneSelect: {
        fields: [
          {
            key: "srcZoneName",
            label: "送信元ゾーン/ゾーン名",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: ZoneRelationItem) => {
              return (
                this.$filter.enumTo(item.srcZoneType, "zoneType") +
                `/${item.srcZoneName}`
              );
            },
          },
          { key: "arrow", label: "" },
          {
            key: "dstZoneName",
            label: "宛先ゾーン/ゾーン名",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: ZoneRelationItem) => {
              return (
                this.$filter.enumTo(item.dstZoneType, "zoneType") +
                `/${item.dstZoneName}`
              );
            },
          },
        ],
        items: [],
      } as Pick<AppTableData<ZoneRelationItem>, "fields" | "items">,
      /** ポリシー情報(イントラネットFW) */
      intranetFwPolicy: {} as IntranetFwPolicy,
      uploadAlert: null as string | null,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    async load() {
      // ゾーン接続情報をAPIから取得する
      this.zoneRelation = await this.$api.zoneRelation.getZoneRelation();
      // イントラネットFWのポリシー情報をAPIから取得する
      this.intranetFwPolicy =
        await this.$api.intranetFwPolicy.getPolicyIntranetFw("INTRANET_FW");
      // INTERNETのデータは非表示
      this.zoneSelect.items = this.zoneRelation.zoneRelationList.filter(
        (item) => {
          return (
            item.srcZoneType !== "INTERNET" && item.dstZoneType !== "INTERNET"
          );
        }
      );
    },
    /**
     * ポリシー一覧(イントラネットFW)の表示
     * @param row 選択した行のデータ
     */
    async ShowIntranetFwPolicyList(row: ZoneRelationItem) {
      this.uploadAlert = null;
      this.intranetFwPolicy = await this.$modal.show(IntranetFwPolicyList, {
        zoneRelationItem: row,
        policy: this.intranetFwPolicy,
        contractInfo: this.contractInfo,
      });
    },
    /** 設定ダウンロード */
    downloadConfig() {
      this.$service.configDownload.downloadIntranetFwPolicy(
        this.contractInfo.vpnCode,
        this.intranetFwPolicy,
        this.zoneRelation.zoneRelationList
      );
    },
    /** 設定アップロード */
    async uploadConfig() {
      try {
        const uploadPolicy =
          await this.$service.configDownload.uploadIntranetFwPolicy(
            this.contractInfo.vpnCode,
            this.intranetFwPolicy,
            this.zoneRelation.zoneRelationList
          );
        this.uploadAlert = null;
        await this.$confirm(
          "ポリシー設定を上書きします。設定反映していない設定は破棄されます。よろしいですか？"
        );
        this.intranetFwPolicy = uploadPolicy;
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },
    /** ポリシー設定反映 */
    async putIntranetFwPolicy() {
      this.uploadAlert = null;
      // 設定反映確認モーダルを表示
      await this.$modal.show(IntranetFwPolicyConfirm, {
        intranetFwPolicy: this.intranetFwPolicy,
        zoneList: this.zoneSelect.items,
        contractInfo: this.contractInfo,
      });
      // APIに設定するデータを作成
      const reqData = createReqData(this.intranetFwPolicy);
      // ポリシー更新APIの呼び出し
      await this.$api.intranetFwPolicy.postPolicyIntranetFw(
        "INTRANET_FW",
        reqData
      );
      // モーダルを閉じる
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

/** ポリシー更新APIの設定データ作成 */
function createReqData(policy: IntranetFwPolicy): PostIntranetPolicy {
  return {
    privateToPrivateList: policy.privateToPrivateList.map((v) => {
      return {
        zoneRelationId: v.zoneRelationId,
        policyList: v.policyList
          .filter((e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY")
          .map((e) => {
            // ポリシーカテゴリは更新時に不要なので含めない
            return {
              ...e,
              description: e.description || null,
              // アドレス・サービスはID、シーケンスのみ抽出する
              srcAddressList: e.srcAddressList.map((v) => v.ipAddressId),
              dstAddressList: e.dstAddressList.map((v) => v.ipAddressId),
              serviceList: e.serviceList.map((v) => v.serviceSeq),
              customServiceList: e.customServiceList.map(
                (v) => v.customServiceSeq
              ),
            };
          }),
      };
    }),
    extraToPrivateList: policy.extraToPrivateList.map((v) => {
      return {
        zoneRelationId: v.zoneRelationId,
        policyList: v.policyList
          .filter((e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY")
          .map((e) => {
            return {
              ...e,
              description: e.description || null,
              srcAddressList: e.srcAddressList.map((v) => v.ipAddressId),
              dstAddressList: e.dstAddressList.map((v) => v.ipAddressId),
              serviceList: e.serviceList.map((v) => v.serviceSeq),
              customServiceList: e.customServiceList.map(
                (v) => v.customServiceSeq
              ),
            };
          }),
      };
    }),
    privateToExtraList: policy.privateToExtraList.map((v) => {
      return {
        zoneRelationId: v.zoneRelationId,
        policyList: v.policyList
          .filter((e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY")
          .map((e) => {
            return {
              ...e,
              description: e.description || null,
              srcAddressList: e.srcAddressList.map((v) => v.ipAddressId),
              dstAddressList: e.dstAddressList.map((v) => v.ipAddressId),
              serviceList: e.serviceList.map((v) => v.serviceSeq),
              customServiceList: e.customServiceList.map(
                (v) => v.customServiceSeq
              ),
            };
          }),
      };
    }),
    // イントラネットFW指定なのでnullを設定
    wvs2ToKcps: null,
    kcpsToWvs2: null,
    note: null,
    policyIntranetFwKeyId: policy.policyIntranetFwKeyId,
  };
}

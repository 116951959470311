import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** ご利用可能ログ容量と現在の使用量*/
export interface ApplianceLogInformation {
  /** 使用容量. GB単位(整数値)で出力 */
  capacityUsed: number;
  /** ご利用可能ログ容量. GB単位(整数値)で出力 */
  applianceLogCapacity: number;
}

export default class ApplianceLogInformationApi extends AbstractBaseApi {
  /**
   * ご利用可能ログ容量と、現在の使用量を表示
   */
  async getApplianceLogInformation(): Promise<ApplianceLogInformation> {
    return (await this.api.get("/v1/report/appliancelog/capacity-info")).data;
  }
}

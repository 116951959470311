






















































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  AccessLine,
  IntranetfwServiceMenu,
  IntranetfwServiceMenuDelete,
  IntranetfwServiceMenuPost,
} from "@/apis/IntranetfwServiceMenuApi";
import IntranetFwContractConfirm from "@/modals/applianceContractSetting/IntranetFwContractConfirm.vue";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "IntranetFwContractModify",
  props: {
    intranetFwContractItem: {
      type: Object as PropType<IntranetfwServiceMenu>,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false as boolean,
      /** イントラネットFW契約変更表示用 */
      intranetFwContract: {} as IntranetfwServiceMenu,
      /** イントラネットFW契約変更ON/OFFフラグ */
      intranetFwContractFlag: true as boolean,
      /** イントラネットFW契約変更表示用reqData */
      postItem: {} as IntranetfwServiceMenuPost,
      /** イントラネットFW契約帯域用オプション */
      speedOptions: [
        ...[
          ...Array.range(10, 101, 10),
          ...Array.range(200, 1001, 100),
          ...Array.range(1500, 10001, 500),
        ].map((e) => ({
          value: { value: e, unit: "MBPS" },
          text: e.toString(),
        })),
      ],
    };
  },
  computed: {
    /** 関連項目チェック:帯域の合計値は契約帯域を超えないこと */
    moreThenValue(): boolean {
      let isNumber = 0;
      if (this.isLoaded) {
        for (const num of this.intranetFwContract.accessLineList) {
          if (num.isCheck) {
            isNumber = this.totalValue(isNumber, num.bandwidth.value);
          }
        }
        for (const num of this.intranetFwContract.remoteAccessGwList) {
          if (num.isCheck) {
            isNumber = this.totalValue(isNumber, num.bandwidthActSby.value);
          }
        }
        for (const num of this.intranetFwContract.pfgwList) {
          if (num.isCheck) {
            isNumber = this.totalValue(isNumber, num.bandwidthActSby.value);
          }
        }
      }
      // OFFのラジオを選択した場合
      if (!this.intranetFwContractFlag) {
        return isNumber === 0;
      }
      return this.isLoaded
        ? isNumber <= this.intranetFwContract.intranetFwBandwidth.value
        : true;
    },
    /** 関連項目チェック:初期表示した内容から設定内容に変更があること */
    objectChanged(): boolean {
      if (!this.intranetFwContractFlag) return true;
      return this.$crossValidation.objectChanged(
        this.intranetFwContractItem,
        this.intranetFwContract
      );
    },
  },

  mounted() {
    this.intranetFwContract = cloneDeep(this.intranetFwContractItem);
    this.isLoaded = true;
  },
  methods: {
    /** イントラネットFW契約変更確認モーダルを表示 */
    async showIntranetFwConfirm() {
      await this.$modal.show(IntranetFwContractConfirm, {
        intranetFwContract: this.intranetFwContract,
        intranetFwContractFlag: this.intranetFwContractFlag,
        postItem: this.postItem,
      });
      if (this.intranetFwContractFlag) {
        // イントラネットFWの契約変更
        /**
         * 変更データの設定
         * MbpsからKbps,Mbpsへ変換
         * 削除部分の脱ぎ出し
         */
        this.postItem.contractIntranetFwKeyId =
          this.intranetFwContract.contractIntranetFwKeyId;
        this.postItem.intranetFwBandwidth = this.mbpsToBandwidth(
          this.intranetFwContract.intranetFwBandwidth.value
        );
        this.postItem.accessLineList = this.intranetFwContract.accessLineList
          .filter((item) => {
            return item.isCheck;
          })
          .map((e) => ({
            bandwidth: this.mbpsToBandwidth(e.bandwidth.value),
            enumber: e.enumber,
          }));
        this.postItem.remoteAccessGwList =
          this.intranetFwContract.remoteAccessGwList
            .filter((item) => {
              return item.isCheck;
            })
            .map((e) => ({
              bandwidthActSby: this.mbpsToBandwidth(e.bandwidthActSby.value),
              enumberAct: e.enumberAct,
            }));
        this.postItem.pfgwList = this.intranetFwContract.pfgwList
          .filter((item) => {
            return item.isCheck;
          })
          .map((e) => ({
            bandwidthActSby: this.mbpsToBandwidth(e.bandwidthActSby.value),
            enumberAct: e.enumberAct,
          }));
        this.postItem.note = this.postItem.note || null;

        await this.$api.intranetfwServiceMenu.postIntranetfwServiceMenu(
          this.postItem
        );
      } else {
        /** イントラネットFWの契約削除 */
        // 削除データの設定
        const reqData: IntranetfwServiceMenuDelete = {
          contractIntranetFwKeyId:
            this.intranetFwContract.contractIntranetFwKeyId,
          note: this.postItem.note || null,
        };
        await this.$api.intranetfwServiceMenu.deleteIntranetfwServiceMenu(
          reqData
        );
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * イントラネットFW割当帯域変更用MbpsからKbps、Mbpsへ
     */
    mbpsToBandwidth(item: number): AccessLine["bandwidth"] {
      if (item % 1 === 0) {
        return { value: item, unit: "MBPS" };
      } else {
        return { value: item * 1000, unit: "KBPS" };
      }
    },
    /**
     * 帯域値の合計算用、小数後一位まで
     */
    totalValue(valA: number, valB: number): number {
      return Math.round((valA + valB) * 10) / 10;
    },
  },
});

import InformationApi, { ProvisioningStatus } from "@/apis/InformationApi";
import { Module } from "vuex";
import { RootState } from "@/store/index";
import { AxiosResponse } from "axios";

const provisioningState = {
  data: {
    /**
     * プロビジョニングステータスのステータスコード.
     * nullの場合は未取得
     */
    messageCode: null as null | ProvisioningStatus["messageCode"],
    /**
     * プロビジョニングステータスのメッセージ.
     * nullの場合は未取得
     */
    message: null as null | string,
    /**
     * プロビジョニングステータスコンポーネント現在の表示数.
     * 0 → 1の場合は初回としてステータスの取得を行う必要があるためその管理用
     */
    count: 0,
    /** 自動再取得処理のpooling */
    pooling: null as null | number,
    /**
     * true: プロビジョニング完了時に更新通知をする, false: 更新通知しない
     * 特殊な事情で更新通知を表示させたくない場合用
     */
    enabledRefresh: true,
  },
  /** プロビジョニングステータスを取得する用 */
  getProvisioningStatus: {} as InstanceType<
    typeof InformationApi
  >["getProvisioningStatus"],
  /** プロビ完了後の実行イベント */
  completedEvent: [] as (() => unknown)[],
};

export type ProvisioningState = typeof provisioningState;

export type MountData = {
  getProvisioningStatus: ProvisioningState["getProvisioningStatus"];
};

export const provisioningStoreModule: Module<ProvisioningState, RootState> = {
  namespaced: true,
  state: provisioningState,
  mutations: {
    mount(state, payload: MountData) {
      state.data.count += 1;
      state.getProvisioningStatus = payload.getProvisioningStatus;
    },
    unmount(state) {
      state.data.count -= 1;
    },
    updateStatus(state, payload: { status: ProvisioningStatus }) {
      state.data.messageCode = payload.status.messageCode;
      state.data.message = payload.status.message;
    },
    setPooling(state, payload: { pooling: null | number }) {
      state.data.pooling = payload.pooling;
    },
    addCompletedEvent(state, payload: ProvisioningState["completedEvent"][0]) {
      state.completedEvent = [...state.completedEvent, payload];
    },
    clearCompletedEvent(state) {
      state.completedEvent = [];
    },
    setEnabledRefresh(state, payload: boolean) {
      state.data.enabledRefresh = payload;
    },
  },
  actions: {
    /** プロビジョニングステータスコンポーネント表示時 */
    async mount({ state, commit, dispatch }, data: MountData) {
      const isFirst = state.data.count === 0;
      commit("mount", data);
      // 初回のステータス表示の場合はデータを取得
      if (isFirst) {
        dispatch("updateStatus");
      }
    },
    /** プロビジョニングステータスコンポーネント破棄時 */
    unmount({ state, commit }) {
      commit("unmount");
      // ポーリングを停止
      if (state.data.pooling !== null) {
        clearInterval(state.data.pooling!);
        commit("setPooling", { pooling: null });
      }
    },
    /** APIレスポンスを通知 */
    async notifyApiResponse({ dispatch }, response: AxiosResponse) {
      const method = response.config.method?.toUpperCase();
      // 変更系かつ非同期ステータスの場合のみ
      if (method === "POST" || method === "PUT" || method === "DELETE") {
        if (response.status === 202) {
          dispatch("updateStatus");
        }
      }
    },
    /** 最新のステータスを取得. ステータス表示の場合は自動更新処理を開始, 非表示は停止 */
    async updateStatus({ state, commit, dispatch }) {
      // コンポーネントが存在する場合はプロビジョニングステータスを再取得
      if (state.data.count > 0) {
        const status = await state.getProvisioningStatus();

        if (
          isShowProvisioningStatus(state.data.messageCode) &&
          !isShowProvisioningStatus(status.messageCode)
        ) {
          // 表示 → 非表示に切り替わった場合は予約されたイベント実行
          state.completedEvent.forEach((f) => f());
          commit("clearCompletedEvent");
        }

        commit("updateStatus", { status });

        if (isShowProvisioningStatus(status.messageCode)) {
          // 自動更新処理を開始
          if (state.data.pooling !== null) {
            clearInterval(state.data.pooling!);
            commit("setPooling", { pooling: null });
          }
          const pooling = setInterval(() => dispatch("updateStatus"), 10000);
          commit("setPooling", { pooling });
        } else if (state.data.pooling !== null) {
          // 自動更新処理を停止
          clearInterval(state.data.pooling!);
          commit("setPooling", { pooling: null });
        }
      }
    },
    /**
     * プロビジョニングステータスが表示 → 非表示に切り替わった際に実行したいイベントを登録
     * 基本的には使用することはない、レアケース
     * @param commit
     * @param event 実行したいイベント
     */
    addCompletedEvent(
      { commit },
      event: ProvisioningState["completedEvent"][0]
    ) {
      commit("addCompletedEvent", event);
    },
    /** 更新通知の有効/無効切り替え */
    setEnabledRefresh({ commit }, enabled: boolean) {
      commit("setEnabledRefresh", enabled);
    },
  },
};

/**
 * true: プロビジョニングステータスを表示, false: 非表示
 * @param code コード
 */
export function isShowProvisioningStatus(
  code: null | ProvisioningStatus["messageCode"]
): boolean {
  switch (code) {
    // エラー or 設定中の場合は表示
    case "CC99015E":
    case "CC99018W":
    case "CC99016W":
      return true;
    default:
      return false;
  }
}

import VueRouter, { RouteConfig } from "vue-router";
import AccessLineList from "@/views/accessLine/AccessLineList.vue";
import ApplianceLogList from "@/views/applianceLog/ApplianceLogList.vue";
import ApplianceContractSettingList from "@/views/applianceContractSetting/ApplianceContractSettingList.vue";
import ApplianceObjectList from "@/views/applianceObject/ApplianceObjectList.vue";
import CloudQosSettingList from "@/views/cloudQos/CloudQosSettingList.vue";
import CloudPacketFilteringList from "@/views/cloudPacketFiltering/CloudPacketFilteringList.vue";
import ContractLineList from "@/views/contractLine/ContractLineList.vue";
import ContractSettingList from "@/views/contractSettingLimit/ContractSettingList.vue";
import CpaAuthIdList from "@/views/cpaAuthId/CpaAuthIdList.vue";
import DownloadList from "@/views/downloadList/DownloadList.vue";
import ExtranetConnectList from "@/views/extranet/ExtranetConnectList.vue";
import HubAndSpokeList from "@/views/hubAndSpoke/HubAndSpokeList.vue";
import IpSecGWList from "@/views/ipSecGw/IpSecGWList.vue";
import IpSecLineList from "@/views/ipSecLine/IpSecLineList.vue";
import Login from "@/views/Login.vue";
import MSNaptSettingList from "@/views/msNaptSetting/MSNaptSettingList.vue";
import notificationContactEdit from "@/views/notificationContact/notificationContactEdit.vue";
import notificationContactReference from "@/views/notificationContact/notificationContactReference.vue";
import SaaSServiceApplicationList from "@/views/saas/SaaSServiceApplicationList.vue";
import FailureMaintenanceList from "@/views/failureMaintenance/FailureMaintenanceList.vue";
import MultiCloudList from "@/views/multicloud/MultiCloudList.vue";
import NWSettingMain from "@/views/nwSetting/NWSettingMain.vue";
import MSNatSettingList from "@/views/msNatSetting/MSNatSettingList.vue";
import NwInformationMain from "@/views/nwInformation/NwInformationMain.vue";
import OneTimeUrlIssue from "@/views/password/OneTimeUrlIssue.vue";
import OperationHistoryList from "@/views/operationHistory/OperationHistoryList.vue";
import AgreementHistoryList from "@/views/agreement/AgreementHistory.vue";
import PacketFilteringList from "@/views/packetFiltering/PacketFilteringList.vue";
import PasswordReset from "@/views/password/PasswordReset.vue";
import QosSettingList from "@/views/qos/QosSettingList.vue";
import Cpa5gSaContractList from "../views/cpa5gSa/Cpa5gSaContractList.vue";
import SiteList from "@/views/site/SiteList.vue";
import Type4SiteList from "@/views/type4/Type4SiteList.vue";
import Type1SiteZoneList from "@/views/type1/Type1SiteZoneList.vue";
import TopPage from "@/views/TopPage.vue";
import TrafficLogMain from "@/views/trafficLog/TrafficLogMain.vue";
import TrafficSessionStatistics from "@/views/trafficSessionStatistics/TrafficSessionStatistics.vue";
import UserList from "@/views/user/UserList.vue";
import OthersMain from "@/views/others/OthersMain.vue";
import VNConnectList from "@/views/vnConnect/VNConnectList.vue";
import VNL2L3ConnectList from "@/views/vnL2L3Connect/VNL2L3ConnectList.vue";
import VirtualNetworkList from "@/views/virtualNetwork/VirtualNetworkList.vue";
import Vue from "vue";
import store from "@/store";
import OneTimeUrlIssueComplete from "@/views/password/OneTimeUrlIssueComplete.vue";
import PasswordResetComplete from "@/views/password/PasswordResetComplete.vue";
import SystemErrorView from "@/views/error/SystemErrorView.vue";
import NotFoundView from "@/views/error/NotFoundView.vue";
import SamlAuthFailedView from "@/views/error/SamlAuthFailedView.vue";
import SsoFailedView from "@/views/error/SsoFailedView.vue";
import SsoMultipleLoginView from "@/views/error/SsoMultipleLoginView.vue";
import NetworkErrorView from "@/views/error/NetworkErrorView.vue";

Vue.use(VueRouter);

// 整形されると見にくいのでOFF。ただし綺麗に書くこと
// prettier-ignore
const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Main",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Main.vue"),
    beforeEnter: (to, from, next) => {
      // ログインしていない場合はログイン画面へ遷移
      next(store.state.user.isLogged ? undefined : "/login");
    },
    children: [
      { path: "", component: TopPage },
      // NW設定
      {
        path: "",
        component: NWSettingMain,
        children: [
          // アクセス回線
          { path: "/access", component: AccessLineList },
          // 仮想ネットワーク設定
          { path: "/vnetwork", component: VirtualNetworkList },
          { path: "/vnetwork/site", component: SiteList },
          { path: "/vnetwork/connect", component: VNConnectList },
          { path: "/vnetwork/qos", component: QosSettingList },
          { path: "/vnetwork/packet-filtering", component: PacketFilteringList },
          // マルチクラウド
          { path: "/multicloud", component: MultiCloudList },
          { path: "/multicloud/saas", component: SaaSServiceApplicationList },
          { path: "/multicloud/microsoft/napt", component: MSNaptSettingList },
          { path: "/multicloud/microsoft/nat", component: MSNatSettingList },
          { path: "/multicloud/qos", component: CloudQosSettingList },
          { path: "/multicloud/packet-filtering", component: CloudPacketFilteringList },
          // IPsec回線
          { path: "/ipsec/gateway", component: IpSecGWList },
          { path: "/ipsec/line", component: IpSecLineList },
          // リモートアクセスGW
          { path: "/remote/access", component: Cpa5gSaContractList },
          // インターネット/アプライアンス
          { path: "/network/site/type1", component: Type1SiteZoneList },
          { path: "/network/site/type4", component: Type4SiteList },
          { path: "/network/object", component: ApplianceObjectList },
          { path: "/network/contract", component: ApplianceContractSettingList },
          // エクストラネット接続
          { path: "/extranet", component: ExtranetConnectList },
          // VN L2/L3コネクト
          { path: "/vnetwork/l2l3/connect", component: VNL2L3ConnectList },
          // Hub&Spoke接続
          { path: "/hubspoke", component: HubAndSpokeList },
        ],
      },
      // トラフィック/ログ
      {
        path: "",
        component: TrafficLogMain,
        children: [
          { path: "/report", component: TrafficSessionStatistics },
          { path: "/report/appliance-log", component: ApplianceLogList },
          { path: "/report/remote/access/account", component: CpaAuthIdList },
        ],
      },
      // NW情報
      {
        path: "",
        component: NwInformationMain,
        children: [
          { path: "/info/contract/line", component: ContractLineList },
          { path: "/info/limit", component: ContractSettingList },
          { path: "/info/operation/history", component: OperationHistoryList },
          { path: "/info/maintenance", component: FailureMaintenanceList },
          { path: "/info/agreement/history", component: AgreementHistoryList },
        ],
      },
      // 各種設定
      {
        path: "",
        component: OthersMain,
        children: [
          { path: "/user", component: UserList },
          { path: "/notification-contact", component: notificationContactReference },
          { path: "/notification-contact", name:"notification-contact-edit",  component: notificationContactEdit },
          { path: "/download", component: DownloadList },
        ],
      },

    ],
  },
  // 共通
  { path: "/login", component: Login },
  {
    path: "/password/reset",
    component: PasswordReset,
    props: (route) => ({
      identifykey: route.query.identifykey,
    }),
  },
  {
    path: "/password/one-time-url",
    component: OneTimeUrlIssue,
    props: (route) => ({
      expired: Array.isArray(route.query.expired)
        ? route.query.expired
        : route.query.expired?.toBoolean(),
      loginId: route.query.loginId,
    }),
  },
  { path: "/password/one-time-url/complete", component: OneTimeUrlIssueComplete },
  { path: "/password/reset/complete", component: PasswordResetComplete },
  // エラー
  { path: "/system/error", component: SystemErrorView },
  { path: "/not-found", component: NotFoundView },
  { path: "/saml-auth/failed", component: SamlAuthFailedView },
  { path: "/single-sign-on/failed", component: SsoFailedView },
  { path: "/single-sign-on/multiple-login", component: SsoMultipleLoginView },
  { path: "/network/error", component: NetworkErrorView },
  // メンテナンス応答は静的HTMLなのでrouterには記載不要
  // 上記のいずれにも一致しない場合
  { path: "*", redirect: "/not-found" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 画面移動時はモーダルを閉じる
router.afterEach(() => {
  Vue.nextTick(() => {
    router.app.$vfm.hideAll();
  });
});

export default router;

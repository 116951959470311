





















































































































































































































































import Vue, { PropType } from "vue";
import {
  Type4Service,
  Type4ServiceDeletePostRequest,
} from "@/apis/ContractChangeType4Api";

export default Vue.extend({
  name: "InternetType4ContractDeleteConfirm",
  props: {
    selectedItem: {
      type: Object as PropType<Type4Service>,
      required: true,
    },
    putItem: {
      type: Object as PropType<Type4ServiceDeletePostRequest>,
      required: true,
    },
  },
  data() {
    return {
      accepted: false,
      user: this.$store.state.user,
    };
  },
  methods: {
    /** 「はい」選択時の挙動 */
    submit() {
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

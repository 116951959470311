






import Vue, { PropType } from "vue";

/** provide/injectはtypescriptがほとんど考慮されていないっぽいので型定義 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ThisVue = any & {
  addValidateState: (key: string, passed: boolean) => void;
  provideData: {
    submitted: boolean;
  };
};

/**
 * 相関チェックを行うコンポーネント.
 * 1コンポーネント = 1種類の相関チェックとなる
 * CrossValidationObserverとセットで使用すること
 */
export default Vue.extend({
  name: "CrossValidationProvider",
  props: {
    /**
     * 相関チェックの識別子.
     * 同一フォーム内で一意な文字列であれば良い
     */
    vid: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * true: 相関チェッククリア, false: 相関チェックエラー
     */
    passed: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  inject: ["addValidateState", "provideData"],
  watch: {
    passed() {
      (this as ThisVue).addValidateState(this.vid, this.passed);
    },
  },
  mounted() {
    (this as ThisVue).addValidateState(this.vid, this.passed);
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

// サービス設定用に入力された複数してされたPort番号の数をチェックする
// ---------------------------------------------------------------------------------------------
export default {
  params: ["length"],
  validate(
    value: string | undefined | null,
    { length }: { length: number }
  ): boolean {
    if (!value) return true;

    const portCommaArray = value.split(",");
    // 指定数を超える場合エラー
    if (portCommaArray.length > length) return false;

    return true;
  },
} as ValidationRuleSchema;



















































































import Vue, { PropType } from "vue";
import {
  ContractChangeOption,
  ContractChangeOptionPut,
} from "@/apis/ContractChangeOptionApi";
import ContractOptionConfirm from "@/modals/applianceContractSetting/ContractOptionConfirm.vue";

export default Vue.extend({
  name: "ContractOptionModify",
  props: {
    contractOption: {
      type: Object as PropType<ContractChangeOption>,
      required: true,
    },
  },
  data() {
    return {
      /** ユーザ設定 */
      user: this.$store.state.user,
      cloneItem: {
        ...this.contractOption,
        additionalLog:
          this.contractOption.additionalLogCapacity === 0 ? "OFF" : "ON",
      },
      form: {
        ...this.contractOption,
        additionalLog:
          this.contractOption.additionalLogCapacity === 0 ? "OFF" : "ON",
      },
      logStorageOptions: [
        { value: 10, text: " 10GB " },
        { value: 20, text: " 20GB " },
        { value: 30, text: " 30GB " },
        { value: 40, text: " 40GB " },
        { value: 50, text: " 50GB " },
        { value: 60, text: " 60GB " },
        { value: 70, text: " 70GB " },
        { value: 80, text: " 80GB " },
        { value: 90, text: " 90GB " },
      ],
    };
  },
  computed: {
    /** 現在ログ保存容量設定 */
    currentLogSize(): number {
      if (
        this.form.additionalLog === "ON" &&
        this.form &&
        this.form.additionalLogCapacity
      ) {
        return 10 + this.form.additionalLogCapacity;
      }
      return 10;
    },
    objectChanged(): boolean {
      if (this.form.additionalLog === "OFF") {
        return this.$crossValidation.objectChanged(
          {
            additionalLog: this.cloneItem.additionalLog,
            isAllowLog: this.cloneItem.isAllowLog,
            isStatistics: this.cloneItem.isStatistics,
          },
          {
            additionalLog: this.form.additionalLog,
            isAllowLog: this.form.isAllowLog,
            isStatistics: this.form.isStatistics,
          }
        );
      } else {
        return this.$crossValidation.objectChanged(this.cloneItem, this.form);
      }
    },
  },
  watch: {
    "form.additionalLog": function (newVal: string, oldVal: string) {
      if (oldVal === "OFF" && newVal === "ON") {
        if (this.form.additionalLogCapacity === 0) {
          this.form.additionalLogCapacity = 10;
        }
      }
    },
  },
  methods: {
    /** 変更ボタン押下時の挙動*/
    async submit() {
      /** オプション契約変更確認モーダルの表示 */
      await this.$modal.show(ContractOptionConfirm, {
        contractOption: this.form,
        additionalLog: this.form.additionalLog,
      });
      const putItem: ContractChangeOptionPut = {
        contractOptionKeyId: this.form.contractOptionKeyId,
        additionalLogCapacity:
          this.form.additionalLog === "OFF"
            ? 0
            : this.form.additionalLogCapacity,
        isAllowLog: this.form.isAllowLog,
        isStatistics: this.form.isStatistics,
        note: null,
      };
      await this.$api.contractChangeOption.putContractOptions(putItem);
      // 契約情報が変更されるためプロビジョニング完了後に再取得してvuexのデータを更新
      await this.$store.dispatch("provisioning/addCompletedEvent", async () => {
        await this.$store.dispatch("user/updateLoginInfo", {
          contractSummary: await this.$api.contract.getSummary(),
        });
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});




















































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import {
  CommonFailure,
  FailureListResponse,
  FailureUpdateInformation,
} from "@/apis/InformationApi";
import FailureMaintenanceReference from "@/modals/failureMaintenance/FailureMaintenanceReference.vue";

export default Vue.extend({
  name: "FailureMaintenanceList",
  data() {
    return {
      maintenance: {
        fields: [
          {
            key: "number",
            label: "回線番号",
            sortable: true,
          },
          {
            key: "failure",
            label: "障害情報",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (v: FailureListEntity["failure"]) => {
              return v ? "有り" : "無し";
            },
          },
          {
            key: "maintenance",
            label: "メンテナンス情報",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (v: FailureListEntity["maintenance"]) => {
              return v ? "有り" : "無し";
            },
          },
          {
            key: "service",
            label: "サービス",
            sortable: true,
            sortByFormatted: true,
            formatter: (value: string) =>
              this.$filter.optionText(value, serviceItems),
          },
          {
            key: "vnConnectName",
            label: "VNコネクト名",
            sortable: true,
          },
          {
            key: "enumber",
            label: "紐づけE番号",
            sortable: true,
          },
          {
            key: "vpnVnCode",
            label: "接続先VPN/VNコード",
            sortable: true,
          },
          {
            key: "vlan",
            label: "VLAN",
            tdClass: "text-right",
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v, _k, item: FailureListEntity) => {
              if (item.vlanIdType) {
                switch (item.vlanIdType) {
                  case "SPECIFIED":
                    return item.vlanId;
                  case "OTHER":
                  case "UNTAG":
                    return this.$filter.enumTo(item.vlanIdType, "vlanIdType");
                }
              } else {
                return null;
              }
            },
          },
        ],
        items: [],
        search: {
          number: {
            type: "text",
            label: "回線番号",
          },
          failure: {
            type: "select",
            label: "障害情報",
            items: [
              { value: "有り", text: "有り" },
              { value: "無し", text: "無し" },
            ],
          },
          maintenance: {
            type: "select",
            label: "メンテナンス情報",
            items: [
              { value: "有り", text: "有り" },
              { value: "無し", text: "無し" },
            ],
          },
          service: {
            type: "select",
            label: "サービス",
            items: serviceItems,
          },
          vnConnectName: {
            type: "text",
            label: "VNコネクト名",
          },
          enumber: {
            type: "text",
            label: "紐づけE番号",
          },
          vpnVnCode: {
            type: "text",
            label: "接続先VPN/VNコード",
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          number: "like",
          failure: "equal",
          maintenance: "equal",
          service: "equal",
          vnConnectName: "like",
          enumber: "like",
          vpnVnCode: "like",
        },
      } as Omit<AppTableData<FailureListEntity>, "items" | "selected">,
      failureInfo: {} as FailureUpdateInformation,
      /** 障害情報一覧。nullの場合は取得中（テーブルはbusy表示） */
      failureList: null as FailureListResponse | null,
      isLoaded: false,
      /** ポーリング情報. nullの場合はポーリングしてない */
      pooling: null as null | number,
    };
  },
  computed: {
    /** 一覧表示データ */
    items(): FailureListEntity[] {
      if (this.failureList) {
        return [
          // VNコネクト情報
          ...this.failureList.vnConnectList.map<FailureListEntity>((e) => ({
            number: e.wnumber,
            failure: e.failure,
            maintenance: e.maintenance,
            service: "VN",
            vnConnectName: e.vnConnectName,
            enumber: e.enumber,
            vpnVnCode: e.vpnVnCode,
            vlanIdType: e.vlanIdType,
            vlanId: e.vlanId,
          })),
          // IPsec回線
          ...this.failureList.ipsecList.map<FailureListEntity>((e) => ({
            number: e.xnumber,
            failure: e.failure,
            maintenance: e.maintenance,
            service: "IPSEC",
            vnConnectName: null,
            enumber: e.enumber,
            vpnVnCode: null,
            vlanIdType: null,
            vlanId: null,
          })),
          // CPA 5G SA
          ...this.failureList.cpaList.map<FailureListEntity>((e) => ({
            number: `${this.$filter.emptyTo(e.wnumber?.wnumberAct, "-")}(${
              e.domain
            })`,
            failure: e.failure,
            maintenance: e.maintenance,
            service: "CPA_5GSA",
            vnConnectName: null,
            enumber: null,
            vpnVnCode: null,
            vlanIdType: null,
            vlanId: null,
          })),
          // VNL2/L3コネクト
          ...this.failureList.vnl2l3ConnectList.map<FailureListEntity>((e) => ({
            number: e.wnumber,
            failure: e.failure,
            maintenance: e.maintenance,
            service: "VNL2L3",
            vnConnectName: e.vnConnectName,
            enumber: e.enumber,
            vpnVnCode: null,
            vlanIdType: e.l3VlanType,
            vlanId: e.l3VlanId,
          })),
          // マルチクラウド
          ...this.failureList.cloudList.map<FailureListEntity>((e) => ({
            number: e.wnumber,
            failure: e.failure,
            maintenance: e.maintenance,
            service: "MULTICLOUD",
            vnConnectName: e.vnConnectName,
            enumber: e.enumber,
            vpnVnCode: e.vpnVnCode,
            vlanIdType: null,
            vlanId: null,
          })),
          // TYPE4インターネット
          ...this.failureList.type4List.map<FailureListEntity>((e) => ({
            number: e.wnumber,
            failure: e.failure,
            maintenance: e.maintenance,
            service: "TYPE4",
            vnConnectName: e.vnConnectName,
            enumber: null,
            vpnVnCode: e.vpnVnCode,
            vlanIdType: null,
            vlanId: null,
          })),
        ];
      }
      return [];
    },
  },
  async mounted() {
    this.failureInfo = await this.$api.information.getFailureInformation();
    this.isLoaded = true;
    // メンテナンス情報一覧は別のローディング表示となるためisLoaded=trueの後
    await this.loadFailureList();
    // 更新中の場合はポーリングを起動
    if (this.failureInfo.failureStatus === "UPDATING") {
      await this.poolFailure();
    }
  },
  destroyed() {
    if (this.pooling) {
      clearInterval(this.pooling);
    }
  },
  methods: {
    /** 障害情報一覧取得。取得中はテーブルをbusy表示 */
    async loadFailureList() {
      this.failureList = null;
      this.failureList = await this.$api.information.getFailureList(
        undefined,
        false
      );
    },

    /** 障害情報の取得が完了するまで30秒毎に状態を確認。取得完了後に障害情報一覧を取得 */
    async poolFailure() {
      if (this.failureInfo.failureStatus === "UPDATING") {
        // 取得が完了するまで30秒毎に状態を確認。取得完了後に障害情報一覧を取得
        this.pooling = setInterval(async () => {
          this.failureInfo = await this.$api.information.getFailureInformation(
            false
          );
          if (this.failureInfo.failureStatus === "COMPLETED") {
            clearInterval(this.pooling!);
            this.pooling = null;
            await this.loadFailureList();
          }
        }, 30 * 1000);
      } else {
        // 取得完了しているので障害一覧を取得
        await this.loadFailureList();
      }
    },

    /** 最新の障害情報の取得を指示して取得が完了したら障害情報一覧を取得 */
    async update() {
      // 最新の障害情報の取得を指示
      await this.$api.information.updateFailureInformation({
        failureKeyId: this.failureInfo.failureKeyId,
      });
      this.failureInfo = await this.$api.information.getFailureInformation();
      await this.poolFailure();
    },

    /** フロント側のみでのCSVダウンロード */
    downloadCsv() {
      // 障害情報とメンテナンス情報はフォーマット不要
      const csvFields = (
        [...this.maintenance.fields] as { label: string; key: string }[]
      ).map((v) => {
        if (v.key === "failure" || v.key === "maintenance") {
          return { label: v.label, key: v.key };
        } else {
          return v;
        }
      });

      this.$service.csvService.appTableCsvDownLoad(
        "failure_maintenance",
        this.items.sortBy(["number", "asc"]),
        this.$service.csvService.genFieldList(csvFields)
      );
    },

    /** 行選択した障害/メンテナンス詳細情報画面を表示 */
    async handleRowClicked($event: FailureListEntity) {
      await this.$modal.show(FailureMaintenanceReference, {
        failureMaintenanceItem: $event,
      });
    },
  },
});

/**サービス表示マッピング用 */
export const serviceItems = [
  { value: "VN", text: "VNコネクト" },
  { value: "IPSEC", text: "IPsec" },
  { value: "VNL2L3", text: "VN L2/L3コネクト" },
  { value: "CPA_5GSA", text: "CPA 5G SA" },
  { value: "MULTICLOUD", text: "マルチクラウドGW" },
  { value: "TYPE4", text: "インターネットType4" },
];

/** 障害メンテナンス情報一覧情報表示用インターフェース */
type FailureListEntity = Pick<CommonFailure, "maintenance" | "failure"> & {
  /** 回線番号 */
  number: string;
  /** サービス種別 */
  service: "VN" | "IPSEC" | "VNL2L3" | "CPA_5GSA" | "MULTICLOUD" | "TYPE4";
  /** VNコネクト名 */
  vnConnectName: string | null;
  /** 紐づけE番号 */
  enumber: string | null;
  /** 接続先VPN/VNコード */
  vpnVnCode: string | null;
  /** VLAN IDのType指定 */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED" | null;
  /** VLAN ID */
  vlanId: number | null;
};

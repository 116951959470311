


































































import Vue, { PropType } from "vue";
import { ContractChangeOption } from "@/apis/ContractChangeOptionApi";

export default Vue.extend({
  name: "ContractOptionConfirm",
  props: {
    contractOption: {
      type: Object as PropType<ContractChangeOption>,
      required: true,
    },
    additionalLog: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      /** ユーザ設定 */
      user: this.$store.state.user,
    };
  },
  methods: {
    /** 「はい」選択時の挙動 */
    submit() {
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

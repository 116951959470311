






































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  CommonProfile,
  Country,
  GetDstAddress2,
  GetPolicy,
  GetPolicyType1,
  GetSrcAddress1,
} from "@/apis/NwPolicyApi";
import { InternetfwProfileList } from "@/apis/ProfileApi";
import cloneDeep from "lodash/cloneDeep";
import { mapState } from "vuex";
import { DefalutService } from "@/apis/ServiceApi";
import { CustomServiceList } from "@/apis/CustomServiceApi";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import {
  Type1ExtraInfo,
  Type1InternetAddress,
  Type1InternetFQDN,
  Type1PrivateInfo,
} from "@/apis/Type1SiteApi";
import MultiSelectTag from "@/components/MultiSelectTag.vue";
import { Type1IFWPolicy } from "@/modals/applianceContractSetting/InternetType1PolicyIfwList.vue";
import InternetType1PolicyRuleIfwConfirm from "@/modals/applianceContractSetting/InternetType1PolicyRuleIfwConfirm.vue";
import { Type1UTMPolicy } from "@/modals/applianceContractSetting/InternetType1PolicyUtmList.vue";

// デフォルト/カスタムを識別するためのIDを付与した型
type EditDefaultService = Type1IFWPolicy["defaultServiceList"][0] & {
  _id: string;
};
type EditCustomService = Type1IFWPolicy["customServiceList"][0] & {
  _id: string;
};

type EditType1IFWPolicy = Omit<
  Type1IFWPolicy,
  "defaultServiceList" | "customServiceList"
> & {
  /** デフォルトサービスリスト & カスタムサービスリスト */
  serviceList: (EditDefaultService | EditCustomService)[];
};

export default Vue.extend({
  name: "InternetType1PolicyRuleIfwEdit",
  components: { MultiSelectTag },
  props: {
    /** ゾーン */
    zone: {
      type: Object as PropType<ZoneRelationItem>,
      required: true,
    },
    /** 変更対象ポリシー. 新規作成の場合は不要 */
    policy: {
      type: Object as PropType<Type1IFWPolicy>,
      default: undefined,
    },
    /** type1ポリシー全体 */
    type1Policy: {
      type: Object as PropType<GetPolicyType1>,
      required: true,
    },
  },
  data() {
    const clonePolicy = cloneDeep(this.policy);
    const form = (
      clonePolicy
        ? // 更新
          {
            ...clonePolicy,
            serviceList: [
              ...clonePolicy.customServiceList.map((e) => ({
                _id: `custom_${e.customServiceSeq}`,
                _sortKey: "custom",
                ...e,
              })),
              ...clonePolicy.defaultServiceList.map((e) => ({
                _id: `default_${e.serviceSeq}`,
                _sortKey: "default",
                ...e,
              })),
            ],
          }
        : // 新規
          {
            srcAddress: {
              srcAddressList: [],
              srcFqdnList: [],
              srcCountryList: [],
            },
            dstAddress: {
              dstAddressList: [],
              dstFqdnList: [],
              dstCountryList: [],
            },
            serviceOption: "ANY",
            serviceList: [],
            profile: {
              internetFW: "OFF",
            },
            actionType: "ALLOW",
            isLogOutput: false,
            isPolicyStatus: true,
            policyCategoryInfo: "CUSTOM_POLICY",
          }
    ) as EditType1IFWPolicy;

    const partialSrcAddress = form.srcAddress as Partial<GetSrcAddress1>;
    const partialDstAddress = form.dstAddress as Partial<GetDstAddress2>;

    return {
      form,
      // 送信元、宛先（any/アドレス）
      // 送信元：アクセス回線→インターネット時、SELECTで固定、以外の場合、初期値はform設定内容から判断（新規の場合はany）
      radioSrcAddress:
        this.zone.srcZoneType !== "INTERNET"
          ? "SELECT"
          : ((partialSrcAddress.srcAddressList!.length === 0 &&
            (partialSrcAddress.srcFqdnList?.length ?? 0) === 0 &&
            (partialSrcAddress.srcCountryList?.length ?? 0) === 0
              ? "ANY"
              : "SELECT") as "ANY" | "SELECT"),
      // 宛先：インターネット→アクセス回線時、SELECTで固定、以外の場合、初期値はform設定内容から判断（新規の場合はany）
      radioDstAddress:
        this.zone.srcZoneType === "INTERNET"
          ? "SELECT"
          : ((partialDstAddress.dstAddressList!.length === 0 &&
            (partialDstAddress.dstFqdnList?.length ?? 0) === 0 &&
            (partialDstAddress.dstCountryList?.length ?? 0) === 0
              ? "ANY"
              : "SELECT") as "ANY" | "SELECT"),
      // エクストラサイト（アドレス一覧）
      extraOrPrivateSiteAddresses: [] as
        | Type1ExtraInfo["siteList"][0]["addressList"]
        | Type1PrivateInfo["lineList"][0]["siteList"][0]["addressList"],
      // インターネット（アドレス、FQDN、国一覧）
      internetAddresses: [] as Type1InternetAddress[],
      internetFqdns: [] as Type1InternetFQDN[],
      internetCountries: [] as Country["countryList"],
      // デフォルトサービス,カスタムサービス
      defaultServices: [] as DefalutService["defaultServiceList"],
      customServices: [] as CustomServiceList["customServiceList"],
      // インターネットFWプロファイル、URLプロファイル
      internetFWProfiles: [] as InternetfwProfileList["profileList"],

      isLoaded: false,
    };
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
    /** true: Internet発 */
    isInternetToAccess(): boolean {
      return this.zone.srcZoneType === "INTERNET";
    },
    /** true: 編集, false: 新規 */
    isEdit(): boolean {
      return !!this.policy;
    },
    /** 送信元アドレス選択肢。インターネット発の場合はインターネットサイト、アクセス拠点発の場合はエクストラサイトまたはプライベートサイト */
    srcAddresses(): {
      label: string;
      values: Type1InternetAddress[];
    }[] {
      if (this.isInternetToAccess) {
        return this.internetAddresses.length !== 0
          ? [
              {
                label: "全選択",
                values: this.internetAddresses,
              },
            ]
          : [];
      } else {
        return this.extraOrPrivateSiteAddresses.length !== 0
          ? [
              {
                label: "全選択",
                values: this.extraOrPrivateSiteAddresses,
              },
            ]
          : [];
      }
    },
    /** 宛先アドレス選択肢。インターネット発の場合はエクストラサイト、アクセス拠点発の場合はインターネットサイト */
    dstAddresses(): {
      label: string;
      values: Type1InternetAddress[];
    }[] {
      if (!this.isInternetToAccess) {
        return this.internetAddresses.length !== 0
          ? [
              {
                label: "全選択",
                values: this.internetAddresses,
              },
            ]
          : [];
      } else {
        return this.extraOrPrivateSiteAddresses.length !== 0
          ? [
              {
                label: "全選択",
                values: this.extraOrPrivateSiteAddresses,
              },
            ]
          : [];
      }
    },
    /** FQDNのSelectの選択肢 */
    fqdnOptions(): {
      label: string;
      values: Type1InternetFQDN[];
    }[] {
      return this.internetFqdns.length !== 0
        ? [
            {
              label: "全選択",
              values: this.internetFqdns,
            },
          ]
        : [];
    },
    /** 国と地域のSelectの選択肢 */
    countryOptions(): {
      label: string;
      values: Country["countryList"];
    }[] {
      return this.internetCountries.length !== 0
        ? [
            {
              label: "全選択",
              values: this.internetCountries,
            },
          ]
        : [];
    },
    /** デフォルトサービス/個別サービスSelectの選択肢 */
    serviceOptions(): {
      label: string;
      values: EditDefaultService[] | EditCustomService[];
    }[] {
      const options = [];
      // 個別サービス
      if (this.customServices.length !== 0) {
        options.push({
          label: "個別サービス（全選択）",
          values: this.customServices.map((e) => ({
            _id: `custom_${e.customServiceSeq}`,
            ...e,
          })),
        });
      }
      // デフォルトサービス
      if (this.defaultServices.length !== 0) {
        options.push({
          label: "定義済みサービス（全選択）",
          values: this.defaultServices.map((e) => ({
            _id: `default_${e.serviceSeq}`,
            ...e,
          })),
        });
      }

      return options;
    },
    /** anyを除外済みのインターネットFWプロファイル一覧 */
    excludeAnyInternetFWProfiles(): InternetfwProfileList["profileList"] {
      return this.internetFWProfiles.filter(
        (e) => e.profileInternetFwName !== "any"
      );
    },
    /** true: サービスオプションのApplication-Default選択不可, false: 選択可能 */
    isDisabledServiceAppDefault(): boolean {
      return this.form.profile.internetFW === "ANY";
    },
    /** true: ログ出力のON選択不可, false: 選択可能 */
    isDisabledLogOutputON(): boolean {
      return (
        this.form.actionType === "ALLOW" && !this.contractSummary.isAllowLog
      );
    },
    /** 既存のtype1ポリシーID一覧 */
    existsPolicyIds(): string[] {
      const p = this.type1Policy;
      return [
        ...(p.policyIfwType1?.accessPointToInternetList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyIfwType1?.internetToAccessPointList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyIPMasqueradeType1List ?? []).flatMap((v) => v.policyList),
        ...(p.policyNatType1List ?? []).flatMap((v) => v.policyList),
      ].map((e) => e.policyId);
    },
    /** true: 送信元アドレスOK（SELECTかつインタネット発の場合の場合にいずれかが選択されていること）, false: NG */
    validSrcAddressRequired(): boolean {
      if (this.radioSrcAddress !== "SELECT" || !this.isInternetToAccess) {
        return true;
      }
      const address = this.form.srcAddress as Partial<GetSrcAddress1>;
      return this.$crossValidation.requiredSome(
        address.srcAddressList,
        address.srcFqdnList,
        address.srcCountryList
      );
    },
    /** true: 宛先アドレスOK（SELECTかつアクセス拠点発の場合にいずれかが選択されていること）, false: NG */
    validDstAddressRequired(): boolean {
      if (this.radioDstAddress !== "SELECT" || this.isInternetToAccess) {
        return true;
      }
      const address = this.form.dstAddress as Partial<GetDstAddress2>;
      return this.$crossValidation.requiredSome(
        address.dstAddressList,
        address.dstFqdnList,
        address.dstCountryList
      );
    },
    internetZoneId(): string {
      if (this.zone.srcZoneType === "INTERNET") {
        return this.zone.srcZoneId;
      } else {
        return this.zone.dstZoneId;
      }
    },
    privateZoneId(): string | undefined {
      if (this.zone.srcZoneType === "PRIVATE") {
        return this.zone.srcZoneId;
      } else if (this.zone.dstZoneType === "PRIVATE") {
        return this.zone.dstZoneId;
      } else {
        return undefined;
      }
    },
    extraZoneId(): string | undefined {
      if (this.zone.srcZoneType === "EXTRA") {
        return this.zone.srcZoneId;
      } else if (this.zone.dstZoneType === "EXTRA") {
        return this.zone.dstZoneId;
      } else {
        return undefined;
      }
    },
  },
  watch: {
    /** any選択 + サービスオプションがdefaultの場合はサービスオプションをanyに変更 */
    "form.profile.internetFW"(newValue: CommonProfile["internetFW"]) {
      if (newValue === "ANY" && this.form.serviceOption === "DEFAULT") {
        this.form.serviceOption = "ANY";
      }
    },
    /** アクション変更時はログ出力をOFFにクリア */
    "form.actionType"() {
      this.form.isLogOutput = false;
    },
  },
  async mounted() {
    // 選択肢などの必要情報を取得
    // PrivateZoneの場合、PrivateSiteからアドレスを取得、以外の場合、ExtraSiteからアドレスを取得
    if (this.privateZoneId) {
      // IPアドレスで昇順ソートするために、プライベートサイト情報を一時保存
      this.extraOrPrivateSiteAddresses = (
        await this.$api.type1SiteZone.getType1PrivateList()
      ).lineList
        .flatMap((v) => v.siteList)
        .flatMap((v) => v.addressList)
        .filter((v) => v.zoneId === this.privateZoneId)
        .sortBy(["ipAddress", "asc"]);
    } else {
      // IPアドレスで昇順ソートするために、エクストラサイト情報を一時保存
      this.extraOrPrivateSiteAddresses = (
        await this.$api.type1SiteZone.getType1ExtraList()
      ).siteList
        .flatMap((v) => v.addressList)
        .filter((v) => v.zoneId === this.extraZoneId)
        .sortBy(["ipAddress", "asc"]);
    }
    const internetSite = await this.$api.type1SiteZone.getType1InternetList();
    // zoneIdが一致しているアドレスリストを表示
    this.internetAddresses = internetSite.addressList
      .filter((v) => v.zoneId === this.internetZoneId)
      .sortBy(["ipAddress", "asc"]);
    this.internetFqdns = internetSite.fqdnList
      .filter((v) => v.zoneId === this.internetZoneId)
      .sortBy([(e) => e.fqdnName ?? e.fqdn, "asc"]);
    this.internetCountries = (
      await this.$api.nwPolicy.getCountries()
    ).countryList.sortBy(["country", "asc"]);

    this.defaultServices = (
      await this.$api.serviceApi.getDefaultService()
    ).defaultServiceList.sortBy(["serviceName", "asc"]);
    this.customServices = (
      await this.$api.customServiceApi.getType1CustomService()
    ).customServiceList.sortBy(["serviceName", "asc"]);

    this.internetFWProfiles = (
      await this.$api.profileApi.getType1Profile()
    ).profileList.sortBy(["profileInternetFwName", "asc"]);

    this.isLoaded = true;
  },
  methods: {
    /** ルール編集の完了 */
    async submit() {
      const policy = {
        ...this.form,
        srcAddress: {
          ...(this.radioSrcAddress === "ANY"
            ? { srcAddressList: [], srcFqdnList: [], srcCountryList: [] }
            : this.form.srcAddress),
        },
        dstAddress: {
          ...(this.radioDstAddress === "ANY"
            ? { dstAddressList: [], dstFqdnList: [], dstCountryList: [] }
            : this.form.dstAddress),
        },
        // デフォルト、カスタムサービスリストを分解
        defaultServiceList:
          this.form.serviceOption === "SELECT"
            ? (this.form.serviceList.filter(
                (e) => "serviceSeq" in e
              ) as GetPolicy["defaultServiceList"])
            : [],
        customServiceList:
          this.form.serviceOption === "SELECT"
            ? (this.form.serviceList.filter(
                (e) => "customServiceSeq" in e
              ) as GetPolicy["customServiceList"])
            : [],
        serviceList: undefined,
        profile: {
          ...this.form.profile,
          profileInternetFw:
            this.form.profile.internetFW === "USER"
              ? this.form.profile.profileInternetFw
              : null,
        },
      } as Type1UTMPolicy & { serviceList: undefined };
      await this.$modal.show(InternetType1PolicyRuleIfwConfirm, { policy });
      (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(policy);
    },
    /** アドレス選択肢ラベル名 */
    addressLabel({
      ipAddressName,
      ipAddress,
    }:
      | Type1InternetAddress
      | Type1ExtraInfo["siteList"][0]["addressList"][0]
      | Type1PrivateInfo["lineList"][0]["siteList"][0]["addressList"][0]): string {
      return `${ipAddressName} ${ipAddress}`;
    },
    /** FQDN選択肢ラベル名 */
    fqdnLabel({ fqdnName, fqdn }: Type1InternetFQDN): string {
      return fqdnName ?? fqdn;
    },
    /** FQDNマルチセレクトソート用func*/
    fqdnSort(
      item: GetSrcAddress1["srcFqdnList"][0] | GetDstAddress2["dstFqdnList"][0]
    ): string | null {
      return item.fqdnName ?? item.fqdn;
    },
    /** サービスラベル名（検索用） */
    serviceNameLabel({
      serviceName,
      protocol,
      portNumber,
    }: EditDefaultService | EditCustomService): string {
      return `${serviceName} ${protocol} ${portNumber}`;
    },
  },
});

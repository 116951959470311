import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** アプリケーション情報 */
export interface InternetfwApplication {
  /** アプリケーションSEQ. 主キー */
  applicationSeq: string;
  /** アプリケーション名 */
  applicationName: string;
  /** カテゴリ */
  category: string;
  /** サブカテゴリ */
  subcategory: string | null;
  /** テクノロジー */
  technology: string | null;
  /** リスク. 数字 */
  risk: number;
}

/** インターネットFWプロファイル情報 */
export interface InternetfwProfileInfo {
  /** インターネットFWのプロファイル名 */
  profileInternetFwName: string;
  /** アプリケーション情報リスト
   * アプリケーション情報が0件の場合は空配列を返却
   */
  applicationList: InternetfwApplication[];
  /** ポリシー使用フラグ. ポリシー使用有「true」、ポリシー使用無「false」 */
  isPolicyInUse: boolean;
  /** 設定値の説明 */
  description: string | null;
  /**
   * インターネットFWのプロファイル設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  profileInternetFwKeyId: string;
}

export type SetInternetfwProfile = Pick<
  InternetfwProfileInfo,
  "profileInternetFwName" | "profileInternetFwKeyId" | "description"
> & {
  /** アプリケーション情報リスト
   * アプリケーション情報が0件の場合は空配列を設定
   */
  applicationSeqList: string[];
  /** 操作履歴の備考 */
  note: string | null;
};

export interface DeleteInternetfwProfile {
  /** インターネットFWのプロファイルSEQリスト */
  profileInternetFwSeqList: string[];
  /** インターネットFWのプロファイル設定排他情報. 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  profileInternetFwKeyId: string;
  /** 操作履歴の備考 */
  note: string | null;
}

export default class InternetfwProfileApi extends AbstractBaseApi {
  /**
   * アプリケーション情報を取得
   */
  async getInternetfwApplications(): Promise<InternetfwApplication[]> {
    return (
      await this.api.get("/v1/network/policy/profile/internetfw/applications")
    ).data;
  }

  /**
   * Type1プロファイル情報（インターネットFW）を取得
   * @param profileInternetFwSeq プロファイルSeq
   */
  async getType1Profile(
    profileInternetFwSeq: string
  ): Promise<InternetfwProfileInfo> {
    return (
      await this.api.get(
        `/v1/network/policy/profile/internetfw/type1/${profileInternetFwSeq}`
      )
    ).data;
  }
  /**
   * Type4プロファイル情報（インターネットFW）を取得
   * @param type4Id type4ID
   * @param profileInternetFwSeq プロファイルSeq
   */
  async getType4Profile(
    type4Id: string,
    profileInternetFwSeq: string
  ): Promise<InternetfwProfileInfo> {
    return (
      await this.api.get(
        `/v1/network/policy/profile/internetfw/type4/${type4Id}/${profileInternetFwSeq}`
      )
    ).data;
  }

  /**
   * Type1プロファイル情報（インターネットFW）を登録
   * @param postData 登録情報
   */
  async postType1Profile(postData: SetInternetfwProfile): Promise<void> {
    await this.api.post(
      "/v1/network/policy/profile/internetfw/type1",
      postData
    );
  }
  /**
   * Type4プロファイル情報（インターネットFW）を登録
   * @param type4Id type4Id
   * @param postData 登録情報
   */
  async postType4Profile(
    type4Id: string,
    postData: SetInternetfwProfile
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/profile/internetfw/type4/${type4Id}`,
      postData
    );
  }

  /**
   * Type1プロファイル情報（インターネットFW）を変更
   * @param profileInternetFwSeq プロファイルSeq
   * @param data 更新情報
   */
  async putType1Profile(
    profileInternetFwSeq: string,
    data: SetInternetfwProfile
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/profile/internetfw/type1/${profileInternetFwSeq}`,
      data
    );
  }
  /**
   * Type4プロファイル情報（インターネットFW）を変更
   * @param type4Id Type4Id
   * @param profileInternetFwSeq プロファイルSeq
   * @param data 更新情報
   */
  async putType4Profile(
    type4Id: string,
    profileInternetFwSeq: string,
    data: SetInternetfwProfile
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/profile/internetfw/type4/${type4Id}/${profileInternetFwSeq}`,
      data
    );
  }

  /**
   * Type1プロファイル情報（インターネットFW）を削除
   * @param data 削除情報
   */
  async deleteType1Profile(data: DeleteInternetfwProfile): Promise<void> {
    await this.api.post(
      "/v1/network/policy/profiles/internetfw/type1/delete",
      data
    );
  }
  /**
   * Type4プロファイル情報（インターネットFW）を削除
   * @param type4Id type4ID
   * @param data 削除情報
   */
  async deleteType4Profile(
    type4Id: string,
    data: DeleteInternetfwProfile
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/profiles/internetfw/type4/${type4Id}/delete`,
      data
    );
  }
}

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 00～23までの2桁であること
 */
export default {
  validate(value: string | undefined | null) {
    if (!value) {
      return true;
    }

    return /^[01][0-9]$|^2[0-3]$/.test(value);
  },
} as ValidationRuleSchema;

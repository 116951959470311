import axios, { AxiosInstance } from "axios";
import {
  interceptorHandleError,
  interceptorLoading,
  interceptorMethodOverride,
  interceptorNotifyProvisioning,
  interceptorPostBodyClean,
  interceptorSetHeaders,
} from "@/apis/api-interceptors";
import { RootState } from "@/store";
import InformationApi from "@/apis/InformationApi";
import ContractApi from "@/apis/ContractApi";
import CloudVnMsApi from "@/apis/CloudVnMsApi";
import CloudLineApi from "@/apis/CloudLineApi";
import AuthApi from "@/apis/AuthApi";
import NwOperationToolApi from "@/apis/NwOperationToolApi";
import ContractChangeType1Api from "@/apis/ContractChangeType1Api";
import ContractChangeType4Api from "@/apis/ContractChangeType4Api";

import PacketFilteringApi from "@/apis/PacketFilteringApi";
import UserApi from "@/apis/UserApi";
import MsPeeringApi from "@/apis/MsPeeringApi";
import VirtualNetworkApi from "@/apis/VirtualNetworkApi";
import ContractLineApi from "@/apis/ContractLineApi";
import MSPeeringIpApi from "@/apis/MSPeeringIpApi";
import IpSecGwApi from "@/apis/IpSecGwApi";
import IpSecLineApi from "@/apis/IpSecLineApi";
import Cpa5gSaApi from "@/apis/Cpa5gSaApi";
import AccessLineApi from "@/apis/AccessLineApi";
import ThresholdSettingApi from "@/apis/ThresholdSettingApi";
import UpperLimitApi from "@/apis/UpperLimitApi";

import InternetPauseApi from "@/apis/InternetPauseApi";
import { Store } from "vuex";
import CloudVnAwsApi from "@/apis/CloudVnAwsApi";
import ContractChangeOptionApi from "@/apis/ContractChangeOptionApi";
import IntranetfwServiceMenuApi from "@/apis/IntranetfwServiceMenuApi";
import ZoneRelationApi from "@/apis/ZoneRelationApi";
import PfgwZoneApi from "@/apis/PfgwZoneApi";
import ServiceApi from "@/apis/ServiceApi";
import CustomServiceApi from "@/apis/CustomServiceApi";
import ProfileApi from "@/apis/ProfileApi";
import InternetfwProfileApi from "@/apis/InternetfwProfileApi";
import UrlProfileApi from "@/apis/UrlProfileApi";
import ApplianceLogInformationApi from "@/apis/ApplianceLogInformationApi";
import ApplianceLogApi from "@/apis/ApplianceLogApi";
import CpaIdApi from "@/apis/CpaIdApi";
import QosApi from "@/apis/QosApi";
import HubAndSpokeApi from "@/apis/HubAndSpokeApi";
import Type4RouteApi from "@/apis/Type4RouteApi";
import Type4SiteApi from "@/apis/Type4SiteApi";
import CloudVnGcpApi from "@/apis/CloudVnGcpApi";
import CloudVnIbmApi from "@/apis/CloudVnIbmApi";
import CloudVnOracleApi from "@/apis/CloudVnOracleApi";
import CloudQosApi from "./CloudQosApi";
import VNConnectApi from "@/apis/VNConnectApi";
import Type1SiteApi from "@/apis/Type1SiteApi";
import IpPoolApi from "@/apis/IpPoolApi";
import IpMappingApi from "@/apis/IpMappingApi";
import PasswordApi from "@/apis/PasswordApi";
import VNL2L3ConnectApi from "@/apis/VNL2L3ConnectApi";
import NwPolicyApi from "@/apis/NwPolicyApi";
import TrafficApi from "@/apis/TrafficApi";
import OperationHistoryApi from "@/apis/OperationHistoryApi";
import ExtranetApi from "@/apis/ExtranetApi";
import PolicyIntranetFw from "@/apis/IntranetfwPolicyApi";
import HealthCheckApi from "@/apis/HealthCheckApi";
import CloudVnSfdcApi from "@/apis/CloudVnSfdcApi";
import SaasApi from "@/apis/SaasApi";
import CloudVnResettingApi from "@/apis/CloudVnResettingApi";
import NotificationApi from "@/apis/NotificationApi";
import AgreementApi from "@/apis/AgreementApi";

/**
 * Axiosインスタンス生成
 * @param store Vuex
 */
function createAxios(store: Store<RootState>): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
  });

  axiosInstance.interceptors.response.use(
    interceptorLoading.response(store),
    interceptorLoading.responseError(store)
  );
  axiosInstance.interceptors.request.use(interceptorLoading.request(store));
  axiosInstance.interceptors.request.use(interceptorMethodOverride);
  axiosInstance.interceptors.request.use(interceptorSetHeaders(store));
  axiosInstance.interceptors.request.use(interceptorPostBodyClean);
  axiosInstance.interceptors.response.use(interceptorNotifyProvisioning(store));
  axiosInstance.interceptors.response.use(
    (config) => config,
    interceptorHandleError
  );

  return axiosInstance;
}

export default class ApiClient {
  private readonly api: AxiosInstance = createAxios(this.store);

  /**
   * @param store Vuex
   */
  constructor(private store: Store<RootState>) {}

  /** ヘルスチェックAPI */
  readonly healthCheck = new HealthCheckApi(this.api, this.store);
  /** 認証API */
  readonly auth = new AuthApi(this.api, this.store);
  /** お知らせ、ログイン、各種ダウンロードAPI */
  readonly information = new InformationApi(this.api, this.store);
  /** パスワードAPI */
  readonly password = new PasswordApi(this.api, this.store);
  /** 契約API */
  readonly contract = new ContractApi(this.api, this.store);
  /** クラウドVN回線(MS)API */
  readonly cloudVnMs = new CloudVnMsApi(this.api, this.store);
  /** クラウドVN回線(AWS)API */
  readonly cloudVnAws = new CloudVnAwsApi(this.api, this.store);
  /** クラウドVN回線(GCP)API */
  readonly cloudVnGcp = new CloudVnGcpApi(this.api, this.store);
  /** クラウドVN回線(IBM)API */
  readonly cloudVnIbm = new CloudVnIbmApi(this.api, this.store);
  /** クラウドVN回線(ORACLE)API */
  readonly cloudVnOracle = new CloudVnOracleApi(this.api, this.store);
  /** クラウドVN回線(Salesforce)API */
  readonly cloudVnSfdc = new CloudVnSfdcApi(this.api, this.store);
  /** 回線に紐づくクラウド情報API */
  readonly cloudLine = new CloudLineApi(this.api, this.store);
  /** クラウドVN回線再設定API */
  readonly cloudVnResetting = new CloudVnResettingApi(this.api, this.store);
  /** 運用ツール系API */
  readonly nwOperationTool = new NwOperationToolApi(this.api, this.store);
  /** サービスメニュー Type1 */
  readonly contract_change_type1 = new ContractChangeType1Api(
    this.api,
    this.store
  );
  /** サービスメニュー Type4 */
  readonly contract_change_type4 = new ContractChangeType4Api(
    this.api,
    this.store
  );
  /** ユーザAPI */
  readonly user = new UserApi(this.api, this.store);
  /** Microsoftサービス申込API */
  readonly msPeering = new MsPeeringApi(this.api, this.store);
  /** SaaS利用API */
  readonly saas = new SaasApi(this.api, this.store);
  /** 仮想ネットワークAPI */
  readonly virtualNetwork = new VirtualNetworkApi(this.api, this.store);
  /** 契約回線API */
  readonly contractLine = new ContractLineApi(this.api, this.store);
  /** MS向けNAPT設定API */
  readonly msPeeringIp = new MSPeeringIpApi(this.api, this.store);
  /** IPsecGWAPI */
  readonly ipSecGw = new IpSecGwApi(this.api, this.store);
  /** IPsec回線API */
  readonly ipSecLine = new IpSecLineApi(this.api, this.store);
  /** CPA 5G SA API */
  readonly cpa5gSa = new Cpa5gSaApi(this.api, this.store);
  /** パケットフィルタリングAPI */
  readonly packetFilter = new PacketFilteringApi(this.api, this.store);
  /** アクセス回線API */
  readonly accessLine = new AccessLineApi(this.api, this.store);
  /** 閾値設定API */
  readonly thresholdSetting = new ThresholdSettingApi(this.api, this.store);
  /** 設定上限値API */
  readonly upperLimit = new UpperLimitApi(this.api, this.store);
  /** VNコネクトAPI */
  readonly vNConnect = new VNConnectApi(this.api, this.store);
  /**
   * VNL2L3コネクトAPI
   */
  readonly vNL2L3Connect = new VNL2L3ConnectApi(this.api, this.store);
  /** インターネット一時停止 */
  readonly internet_pause = new InternetPauseApi(this.api, this.store);

  /** オプション契約API */
  readonly contractChangeOption = new ContractChangeOptionApi(
    this.api,
    this.store
  );
  /** イントラネットFW契約API */
  readonly intranetfwServiceMenu = new IntranetfwServiceMenuApi(
    this.api,
    this.store
  );
  /** イントラネットポリシー設定API */
  readonly intranetFwPolicy = new PolicyIntranetFw(this.api, this.store);
  /** ゾーンリレーションAPI */
  readonly zoneRelation = new ZoneRelationApi(this.api, this.store);
  /** PFGWゾーンリレーションAPI */
  readonly pfgwZoneRelation = new PfgwZoneApi(this.api, this.store);
  /** サービスAPI */
  readonly serviceApi = new ServiceApi(this.api, this.store);
  /** カスタムサービスAPI */
  readonly customServiceApi = new CustomServiceApi(this.api, this.store);
  /** プロファイルAPI */
  readonly profileApi = new ProfileApi(this.api, this.store);
  /** プロファイルインターネットFW API */
  readonly internetfwProfileApi = new InternetfwProfileApi(
    this.api,
    this.store
  );
  /** プロファイルUrlフィルタリング API */
  readonly urlProfileApi = new UrlProfileApi(this.api, this.store);
  /** エクストラネットAPI */
  readonly extranetApi = new ExtranetApi(this.api, this.store);
  /** アプライアンスログ取得API */
  readonly applianceLogApi = new ApplianceLogApi(this.api, this.store);
  /** アプライアンスログ取得API */
  readonly cpaIdApi = new CpaIdApi(this.api, this.store);
  /** アプライアンスログ情報取得API */
  readonly applianceLogInformationApi = new ApplianceLogInformationApi(
    this.api,
    this.store
  );
  /** QoS設定情報取得API */
  readonly qosApi = new QosApi(this.api, this.store);
  /** HubAndSpoke API*/
  readonly hubAndSpoke = new HubAndSpokeApi(this.api, this.store);
  /** Type4経路設定API */
  readonly type4Route = new Type4RouteApi(this.api, this.store);
  /** Type4サイトApI */
  readonly type4SiteApi = new Type4SiteApi(this.api, this.store);

  /** CloudQoS API */
  readonly cloudQosApi = new CloudQosApi(this.api, this.store);
  /** Type1サイトゾーンAPI */
  readonly type1SiteZone = new Type1SiteApi(this.api, this.store);
  /** ネットワークポリシー（type1,type4）設定API */
  readonly nwPolicy = new NwPolicyApi(this.api, this.store);
  /** IP Pool */
  readonly ipPool = new IpPoolApi(this.api, this.store);
  /** IP マッピング */
  readonly ipMapping = new IpMappingApi(this.api, this.store);
  /**
   * トラフィックAPI
   */
  readonly traffic = new TrafficApi(this.api, this.store);
  /** 操作履歴API */
  readonly operationHistory = new OperationHistoryApi(this.api, this.store);
  /** 通知先情報API */
  readonly notification = new NotificationApi(this.api, this.store);
  /** 同意情報API */
  readonly agreement = new AgreementApi(this.api, this.store);
}

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

// 入力値がネットワークアドレスであることをチェックする
// ----------------------------------------
// チェック内容
// 入力値のIPv6アドレスの4ブロック目以降が「0000」でない場合エラー
//
// ※「割当帯域アドレス(IPv6)」専用のValidation
//   割当帯域アドレス(IPv6)のサブネットマスクは「/48」固定のため
//   IPv6の入力値の4ブロック目以降が「0000」で判定している
export default {
  validate(value: string | null | undefined): boolean {
    if (!value) return true;

    const ipArray = value.split("/")[0].split(":");
    return !(
      ipArray[3] !== "0000" ||
      ipArray[4] !== "0000" ||
      ipArray[5] !== "0000" ||
      ipArray[6] !== "0000" ||
      ipArray[7] !== "0000"
    );
  },
} as ValidationRuleSchema;

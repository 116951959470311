


























import Vue from "vue";
import { FileInfo } from "@/apis/InformationApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import ApiNotFoundModal from "@/modals/error/ApiNotFoundModal.vue";

export default Vue.extend({
  name: "DownloadList",
  data() {
    return {
      // 各種ダウンロード
      download: {
        fields: [
          { key: "fileName", label: "ファイル名" },
          { key: "downloadTitle", label: "説明" },
        ] as BvTableFieldArray,
        items: [] as FileInfo[],
      },
      isLoaded: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** 読み込み */
    async load() {
      this.download.items = await this.$api.information.getDownloadList();
    },
    /** 各種ファイルダウンロード */
    async downloadFile(entity: FileInfo) {
      /** ダウンロードする前にダウンロード対象のデータが存在するかの確認 */
      const fileCheckItem: boolean = (
        await this.$api.information.getDownloadList()
      ).some((e) => e.downloadSeq === entity.downloadSeq);
      if (fileCheckItem) {
        window.open(
          this.$api.information.getInformationDownloadUrl(entity.downloadSeq),
          "_blank"
        );
      } else {
        await this.$modal.show(ApiNotFoundModal);
      }
    },
  },
});






































































































import Vue, { PropType } from "vue";
import { DeleteIpMapping, IpMapping } from "@/apis/IpMappingApi";
import IpMappingType4Edit from "@/modals/applianceObject/IpMappingType4Edit.vue";
import { Type1IpMappingItem } from "@/views/applianceObject/Type1IpMapping.vue";
import IpMappingType1Edit from "@/modals/applianceObject/IpMappingType1Edit.vue";

export default Vue.extend({
  name: "IpMappingReference",
  props: {
    /** type1 IPマッピング or type4 IPマッピング */
    ipMapping: {
      type: Object as PropType<Type1IpMappingItem | IpMapping>,
      required: true,
    },
    /** 排他制御 */
    ipMappingKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    /** Type4 ID。Type4のIPマッピングの場合は必須 */
    type4Id: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  methods: {
    /**
     * IP Poolの削除処理
     */
    async deleteIpMapping(): Promise<void> {
      // ポリシー設定有無が有りの場合は処理を終了
      if (this.ipMapping.isPolicyInUse) return;

      // 確認ダイアログの表示
      await this.$confirm("IPマッピングを削除します。よろしいですか？");

      // リクエスト用データの作成
      const reqData: DeleteIpMapping = {
        ipMappingList: [this.ipMapping.virtualIpAddressSeq],
        ipMappingKeyId: this.ipMappingKeyId,
        note: null,
      };

      // IPマッピング削除APIを呼び出す
      if (!this.type4Id) {
        await this.$api.ipMapping.deleteType1IpMapping(reqData);
      } else {
        await this.$api.ipMapping.deleteType4IpMapping(this.type4Id, reqData);
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * 変更モーダルを表示する
     */
    async showModifyIpMappingModal(): Promise<void> {
      if (this.type4Id) {
        await this.$modal.show(IpMappingType4Edit, {
          ipMapping: this.ipMapping,
          ipMappingKeyId: this.ipMappingKeyId,
          type4Id: this.type4Id,
        });
      } else {
        await this.$modal.show(IpMappingType1Edit, {
          ipMapping: this.ipMapping,
          ipMappingKeyId: this.ipMappingKeyId,
        });
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

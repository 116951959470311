var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"個別サービス設定","classes":"modal-medium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('validation-provider',{attrs:{"name":"名前","vid":"serviceName","rules":{
              required: true,
              is_half_width_alpha_numeric: true,
              max: 63,
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"名前","msg":_vm._f("msg")('info_alpha_num',{ maxLength: 63 }),"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.serviceName),callback:function ($$v) {_vm.$set(_vm.form, "serviceName", $$v)},expression:"form.serviceName"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("プロトコル")]},proxy:true}],null,true)},[_c('b-form-radio-group',{model:{value:(_vm.form.protocol),callback:function ($$v) {_vm.$set(_vm.form, "protocol", $$v)},expression:"form.protocol"}},[_c('b-form-radio',{attrs:{"value":"TCP"}},[_vm._v("TCP")]),_c('b-form-radio',{attrs:{"value":"UDP"}},[_vm._v("UDP")]),_c('b-form-radio',{attrs:{"value":"IP"}},[_vm._v("IP")]),_c('b-form-radio',{attrs:{"value":"ICMP"}},[_vm._v("ICMP")])],1)],1),(_vm.form.protocol === 'TCP' || _vm.form.protocol === 'UDP')?_c('div',[_c('validation-provider',{attrs:{"name":"Port","vid":"portNumber","rules":{
                required: true,
                is_custom_port: true,
                is_custom_port_length: 10,
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"Port","msg":_vm._f("msg")('info_custom_port'),"isRequired":true,"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.portNumber),callback:function ($$v) {_vm.$set(_vm.form, "portNumber", $$v)},expression:"form.portNumber"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1):(_vm.form.protocol === 'IP')?_c('div',[_c('validation-provider',{attrs:{"name":"プロトコル番号","vid":"protocolNumber","rules":{
                required: true,
                numeric: true,
                between: { min: 0, max: 254 },
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"プロトコル番号","msg":_vm._f("msg")('info_num_max',{ max: 254 }),"isRequired":true,"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input-number',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.protocolNumber),callback:function ($$v) {_vm.$set(_vm.form, "protocolNumber", _vm._n($$v))},expression:"form.protocolNumber"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1):(_vm.form.protocol === 'ICMP')?_c('div',[_c('validation-provider',{attrs:{"name":"ICMP Type","vid":"icmpType","rules":{
                required: true,
                numeric: true,
                between: { min: 0, max: 255 },
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"ICMP Type","msg":_vm._f("msg")('info_num_max',{ max: 255 }),"isRequired":true,"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input-number',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.icmpType),callback:function ($$v) {_vm.$set(_vm.form, "icmpType", _vm._n($$v))},expression:"form.icmpType"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ICMP Code","vid":"icmpCode","rules":{
                required: true,
                numeric: true,
                between: { min: 0, max: 255 },
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"ICMP Code","msg":_vm._f("msg")('info_num_max',{ max: 255 }),"isRequired":true,"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input-number',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.icmpCode),callback:function ($$v) {_vm.$set(_vm.form, "icmpCode", _vm._n($$v))},expression:"form.icmpCode"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1):_vm._e(),_c('validation-provider',{attrs:{"vid":"description","name":"備考","rules":{ max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"備考","msg":_vm._f("msg")('info_max_length',{ maxLength: 20 }),"isRequired":false,"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.customServiceIntranet ? "変更" : "追加")+" ")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }






























































import {
  UpperLimitOptionGetEntity,
  UpperLimitOptionUpdateEntity,
} from "@/apis/UpperLimitApi";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "LimitModify",
  props: {
    /** IPsec回線の上限値変更行う場合: ipSecLine
     * VNコネクトの上限値変更行う場合: vnConnect */
    settingType: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      form: {
        currentLimit: 0,
        limit: 0,
      },
      limit: {} as UpperLimitOptionGetEntity,
    };
  },
  async mounted() {
    /** 契約単位（オプション契約）の設定上限値（最大値と現在値）取得API呼び出す処理 */
    this.limit = await this.$api.upperLimit.getUpperLimitOption();

    if (this.settingType === "vnConnect") {
      this.form.currentLimit = this.limit.vnConnect?.numberOfVnConnect || 0;
      this.form.limit = this.limit.vnConnect?.upperLimitVnConnect || 0;
    } else if (this.settingType === "ipSecLine") {
      this.form.currentLimit = this.limit.ipsec?.numberOfIpsec || 0;
      this.form.limit = this.limit.ipsec?.upperLimitIpsec || 0;
    }
  },
  methods: {
    async submit() {
      /** 確認モーダル表示 */
      await this.$confirm(`上限値を変更します。よろしいですか？`);

      const data: UpperLimitOptionUpdateEntity = {
        upperLimitContractKeyId: this.limit.upperLimitContractKeyId,
        upperLimitVnConnect:
          this.settingType === "vnConnect"
            ? this.form.limit
            : this.limit.vnConnect?.upperLimitVnConnect ?? null,
        upperLimitIpsec:
          this.settingType === "ipSecLine"
            ? this.form.limit
            : this.limit.ipsec?.upperLimitIpsec ?? null,
      };

      /** 契約単位（オプション契約）の設定上限値（最大値と現在値）更新API呼び出す処理 */
      await this.$api.upperLimit.updateUpperLimitOption(data);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

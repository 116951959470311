import { render, staticRenderFns } from "./InternetType1PolicyRuleIfwViewRD.vue?vue&type=template&id=02623cdc&scoped=true&"
import script from "./InternetType1PolicyRuleIfwViewRD.vue?vue&type=script&lang=ts&"
export * from "./InternetType1PolicyRuleIfwViewRD.vue?vue&type=script&lang=ts&"
import style0 from "./InternetType1PolicyRuleIfwViewRD.vue?vue&type=style&index=0&id=02623cdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02623cdc",
  null
  
)

export default component.exports
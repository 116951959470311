import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { NullableProperty } from "@/shims-vue";

/**
 * ポリシー種別.
 * UTM ：UTM
 * IFW ：IFW
 * IPMASQUERADE ：IP Masquerade
 * NAT ：Destination NAT
 * UTM_IPMASQUERADE ：UTM,IP Masquerade
 * UTM_NAT ：UTM,Destination NAT
 * UTM_IPMASQUERADE_NAT ：UTM,IP Masquerade,Destination NAT
 * IFW_IPMASQUERADE ：IFW,IP Masquerade
 * IFW_NAT ：IFW,Destination NAT
 * IFW_IPMASQUERADE_NAT ：IFW,IP Masquerade,Destination NAT
 * IPMASQUERADE_NAT ：IP Masquerade,Destination NAT
 */
export type PolicyType =
  | "UTM"
  | "IFW"
  | "IPMASQUERADE"
  | "NAT"
  | "UTM_IPMASQUERADE"
  | "UTM_NAT"
  | "UTM_IPMASQUERADE_NAT"
  | "IFW_IPMASQUERADE"
  | "IFW_NAT"
  | "IFW_IPMASQUERADE_NAT"
  | "IPMASQUERADE_NAT";

export interface GetSrcAddress1 {
  /**
   * 送信元アドレスリスト.
   * 送信元アドレスが無い場合は空リスト
   */
  srcAddressList: {
    /**
     * IPアドレスID.
     * アドレスオブジェクトの主キー
     * \"obj\"+\"数値\"の形式
     */
    ipAddressId: null | string;
    /** IPアドレス名. */
    ipAddressName: null | string;
    /**
     * アドレス.
     * ネットワークアドレスを設定
     */
    ipAddress: null | string;
  }[];
  /**
   * 送信元FQDNリスト.
   * 送信元FQDNリストが無い場合は空リスト
   */
  srcFqdnList: {
    /**
     * FQDN ID.
     * アドレスオブジェクトの主キー
     * \"obj\"+\"数値\"の形式
     */
    fqdnId: string;
    /** FQDN名. */
    fqdnName: null | string;
    /** FQDN. */
    fqdn: string;
  }[];
  /**
   * 送信元国リスト.
   * 送信元国が無い場合は空リスト
   */
  srcCountryList: Country["countryList"];
}

export interface GetSrcAddress2 {
  /**
   * 送信元アドレスリスト.
   * 送信元アドレスが無い場合は空リスト
   */
  srcAddressList: {
    /**
     * IPアドレスID.
     * アドレスオブジェクトの主キー
     * \"obj\"+\"数値\"の形式
     */
    ipAddressId: null | string;
    /** IPアドレス名. */
    ipAddressName: null | string;
    /**
     * アドレス.
     * ネットワークアドレスを設定
     */
    ipAddress: null | string;
  }[];
}

export interface GetDstAddress1 {
  /**
   * 送信先アドレスリスト.
   * 送信先アドレスが無い場合は空リスト
   */
  dstAddressList: {
    /**
     * IPアドレスID.
     * アドレスオブジェクトの主キー
     * \"obj\"+\"数値\"の形式
     */
    ipAddressId: null | string;
    /** IPアドレス名. */
    ipAddressName: null | string;
    /**
     * アドレス.
     * ネットワークアドレスを設定
     */
    ipAddress: null | string;
  }[];
}

export interface GetDstAddress2 {
  /**
   * 送信先アドレスリスト.
   * 送信先アドレスが無い場合は空リスト
   */
  dstAddressList: {
    /**
     * IPアドレスID.
     * アドレスオブジェクトの主キー
     * \"obj\"+\"数値\"の形式
     */
    ipAddressId: null | string;
    /** IPアドレス名. */
    ipAddressName: null | string;
    /**
     * アドレス.
     * ネットワークアドレスを設定
     */
    ipAddress: null | string;
  }[];
  /**
   * 送信先FQDNリスト.
   * 送信先FQDNリストが無い場合は空リスト
   */
  dstFqdnList: {
    /**
     * FQDN ID.
     * アドレスオブジェクトの主キー
     * \"obj\"+\"数値\"の形式
     */
    fqdnId: string;
    /** FQDN名. */
    fqdnName: null | string;
    /** FQDN. */
    fqdn: string;
  }[];
  /**
   * 送信先国リスト.
   * 送信先国が無い場合は空リスト
   */
  dstCountryList: Country["countryList"];
}

export interface PutSrcAddress1 {
  /**
   * 送信元アドレスリスト.
   * 送信元アドレスが無い場合は空リスト
   */
  srcAddressList: string[];
  /**
   * 送信元FQDNリスト.
   * 送信元FQDNリストが無い場合は空リスト
   */
  srcFqdnList: string[];
  /**
   * 送信元国リスト.
   * 送信元国が無い場合は空リスト
   */
  srcCountryList: string[];
}

export interface PutSrcAddress2 {
  /**
   * 送信元アドレスリスト.
   * 送信元アドレスが無い場合は空リスト
   */
  srcAddressList: string[];
}

export interface PutDstAddress1 {
  /**
   * 送信先アドレスリスト.
   * 送信先アドレスが無い場合は空リスト
   */
  dstAddressList: string[];
}

export interface PutDstAddress2 {
  /**
   * 送信先アドレスリスト.
   * 送信先アドレスが無い場合は空リスト
   */
  dstAddressList: string[];
  /**
   * 送信先FQDNリスト.
   * 送信先FQDNリストが無い場合は空リスト
   */
  dstFqdnList: string[];
  /**
   * 送信先国リスト.
   * 送信先国が無い場合は空リスト
   */
  dstCountryList: string[];
}

export interface CommonProfile {
  /**
   * インターネットFW.
   * OFF：OFF
   * ANY：any
   * USER：ユーザープロファイル
   * MAINTENANCE：メンテナンス（編集不可デフォルトポリシーのみ使用）
   */
  internetFW: "OFF" | "ANY" | "USER" | "MAINTENANCE";
  /**
   * IDS/IPS.
   * OFF：OFF
   * DEFAULT：Default
   * IDS：IDS
   * IPS：IPS
   */
  idsIps: "OFF" | "DEFAULT" | "IDS" | "IPS";
  /**
   * URLフィルタリング.
   * OFF：OFF
   * USER：ユーザープロファイル
   */
  urlFiltering: "OFF" | "USER";
  /**
   * Webアンチウイルス.
   * OFF：OFF
   * DEFAULT：Default
   * ALERT：Alert
   * BLOCK：Block
   */
  webAntiVirus: "OFF" | "DEFAULT" | "ALERT" | "BLOCK";
  /**
   * メールアンチウイルス.
   * OFF：OFF
   * DEFAULT：Default
   * ALERT：Alert
   * BLOCK：Block
   */
  mailAntiVirus: "OFF" | "DEFAULT" | "ALERT" | "BLOCK";
  /**
   * アンチスパイウェアフラグ.
   * true：有効(ON)
   * false：無効(OFF)
   */
  isAntiSpyware: boolean;
}

export type GetProfile = CommonProfile & {
  profileInternetFw: null | {
    /**
     * インターネットFWのプロファイルSEQ.
     * インターネットFWがUSERの場合に設定
     */
    profileInternetFwSeq: string;
    /**
     * インターネットFWのプロファイル名.
     * インターネットFWがUSERの場合に設定
     */
    profileInternetFwName: string | "any";
  };
  profileUrl: null | {
    /**
     * URLフィルタリングのプロファイルSEQ.
     * URLフィルタリングがUSERの場合に設定
     */
    profileUrlSeq: string;
    /**
     * URLフィルタリンプロファイル名.
     * URLフィルタリングがUSERの場合に設定
     */
    profileUrlName: string;
  };
};

export type PutProfile = CommonProfile & {
  /** */
  profileInternetFw: null | {
    /**
     * インターネットFWのユーザープロファイルSEQ.
     * インターネットFWがUSERの場合に設定
     * プロファイルAPI（インターネットFW）で取得
     */
    profileInternetFwSeq: null | string;
  };
  /** */
  profileUrl: null | {
    /**
     * URLフィルタリングのユーザープロファイルSEQ.
     * URLフィルタリングがUSERの場合に設定
     * プロファイルAPI（URLフィルタリング）で取得
     */
    profileUrlSeq: null | string;
  };
};

export interface CommonPolicy {
  /** ポリシーID. */
  policyId: string;
  /**
   * サービス選択オプション.
   * ANY：any
   * DEFAULT：Application-default
   * SELECT：select
   */
  serviceOption: "ANY" | "DEFAULT" | "SELECT";
  /**
   * アクション.
   * ALLOW：許可
   * DENY：拒否
   */
  actionType: "ALLOW" | "DENY";
  /**
   * ログ出力フラグ.
   * true ：出力する
   * false：出力しない
   */
  isLogOutput: boolean;
  /**
   * ポリシー有効/無効フラグ.
   * true ：有効
   * false：無効
   */
  isPolicyStatus: boolean;
  /**
   * ポリシーカテゴリ情報.
   * ポリシーのカテゴリ情報を返却する。
   * UNEDITABLE_DEFAULT_POLICY : 編集不可デフォルトポリシー
   * EDITABLE_DEFAULT_POLICY : 編集可能デフォルトポリシー
   * CUSTOM_POLICY : カスタムポリシー
   */
  policyCategoryInfo:
    | "UNEDITABLE_DEFAULT_POLICY"
    | "EDITABLE_DEFAULT_POLICY"
    | "CUSTOM_POLICY";
  /** 備考 */
  description: null | string;
}

export type GetPolicy = CommonPolicy & {
  /**
   * ポリシーオブジェクトID.
   * 主キー
   */
  policyObjectId: null | string;
  /**
   * デフォルトサービスリスト.
   * デフォルトサービスが無い場合は空リスト
   */
  defaultServiceList: {
    /**
     * サービスSEQ.
     * 主キー
     */
    serviceSeq: string;
    /** サービス名. */
    serviceName: string;
    /**
     * プロトコル.
     * TCP：TCP
     * UDP：UDP
     */
    protocol: "TCP" | "UDP";
    /**
     * ポート番号.
     * Port番号は0～65535が指定可能
     * "-"で範囲、","複数指定可能
     * TCP、またはUDP選択時必須
     */
    portNumber: string;
  }[];
  /**
   * カスタムサービスリスト.
   * カスタムサービスが無い場合は空リスト
   */
  customServiceList: {
    /**
     * カスタムサービスSEQ.
     * 主キー
     */
    customServiceSeq: string;
    /** サービス名. */
    serviceName: string;
    /**
     * プロトコル.
     * TCP：TCP
     * UDP：UDP
     */
    protocol: "TCP" | "UDP";
    /**
     * ポート番号.
     * Port番号は0～65535が指定可能
     * "-"で範囲、","複数指定可能
     * TCP、またはUDP選択時必須
     */
    portNumber: string;
  }[];
  /**
   * ipプールリスト.
   * ipプールが無い場合は空リスト
   */
  ipPoolList: {
    /**
     * IPプールSEQ.
     * 主キー
     */
    ipPoolSeq: string;
    /** IPプール名. */
    ipPoolName: string;
    /** グローバルIPアドレス. */
    globalIpAddress: string;
    /** 備考. */
    description: null | string;
  }[];
  /** IPマッピング. */
  ipMapping: {
    /** バーチャルIPアドレスSEQ. */
    virtualIpAddressSeq: string;
    /** IPマッピング名. */
    ipMappingName: string;
    /** 設定値の説明. */
    description: null | string;
  };
};

export type PutPolicy = CommonPolicy & {
  /**
   * ポリシーオブジェクトID.
   * 主キー
   * ポリシー更新時に設定
   */
  policyObjectId?: null | string;
  /**
   * 送信元アドレスリスト.
   * 送信元アドレスが無い場合は空リスト
   */
  srcAddressList: string[];
  /**
   * 送信先アドレスリスト.
   * 送信先アドレスが無い場合は空リスト
   */
  dstAddressList: string[];
  /**
   * ipプールリスト.
   * ipプールが無い場合は空リスト
   */
  ipPoolList: string[];
  /**
   * IPマッピング.
   */
  ipMapping: {
    /**
     * バーチャルIPアドレスSEQ.
     * 主キー
     * IPマッピングAPIのGETより取得
     */
    virtualIpAddressSeq: string;
  };
  /**
   * デフォルトサービスリスト.
   * デフォルトサービス選択オプションが「ANY」「DEFAULT」の場合、又はサービスが無い場合は空リスト
   */
  defaultServiceList: string[];
  /**
   * カスタムサービスリスト.
   * カスタムサービス選択オプションが「ANY」「DEFAULT」の場合、又はカスタムサービスが無い場合は空リスト
   */
  customServiceList: string[];
};

export interface GetPolicyType4 {
  /**
   * Type4ポリシー(UTM).
   * クエリパラメータのポリシー種別の対象外の場合とType4ポリシー(IFW)契約時はNULLを返却
   */
  policyUtmType4: null | {
    /** ポリシー総件数. インターネットからアクセス拠点向けのポリシー総数. */
    internetToAccessPointTotal: number;
    /**
     * ポリシーリスト.
     * インターネットからアクセス拠点向けのポリシーが無い場合、空リストを返却
     */
    internetToAccessPointPolicyList: (Pick<
      GetPolicy,
      | "policyObjectId"
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元ゾーンのsrcAddressList, srcFqdnListを返却
       */
      srcAddress: GetSrcAddress1;
      /**
       * 送信先アドレス.
       * 送信先ゾーンのdstAddressListを返却
       */
      dstAddress: GetDstAddress1;
      /** プロファイル。編集不可デフォルトポリシーの場合は一部のプロパティでnullが返される可能性がある */
      profile: NullableProperty<
        GetProfile,
        | "idsIps"
        | "urlFiltering"
        | "webAntiVirus"
        | "mailAntiVirus"
        | "isAntiSpyware"
      >;
    })[];
    /** ポリシー総件数. アクセス拠点からインターネット向けのポリシー総数 */
    accessPointToInternetTotal: number;
    /**
     * ポリシーリスト.
     * アクセス拠点からインターネット向けのポリシーが無い場合、空リストを返却
     */
    accessPointToInternetPolicyList: (Pick<
      GetPolicy,
      | "policyObjectId"
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元ゾーンのsrcAddressListを返却
       */
      srcAddress: GetSrcAddress2;
      /**
       * 送信先アドレス.
       * 送信先ゾーンのdstAddressListを返却
       */
      dstAddress: GetDstAddress2;
      /** プロファイル。編集不可デフォルトポリシーの場合は一部のプロパティでnullが返される可能性がある */
      profile: NullableProperty<
        GetProfile,
        | "idsIps"
        | "urlFiltering"
        | "webAntiVirus"
        | "mailAntiVirus"
        | "isAntiSpyware"
      >;
    })[];
  };
  /**
   * Type1ポリシー(IFW).
   * クエリパラメータのポリシー種別の対象外の場合とType1ポリシー(UTM)契約時はNULLを返却
   */
  policyIfwType4: null | {
    /** ポリシー総件数. インターネットからアクセス拠点向けのポリシー総数 */
    internetToAccessPointTotal: number;
    /**
     * ポリシーリスト.
     * インターネットからアクセス拠点向けのポリシーが無い場合、空リストを返却
     */
    internetToAccessPointPolicyList: (Pick<
      GetPolicy,
      | "policyObjectId"
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元ゾーンのsrcAddressList, srcFqdnListを返却
       */
      srcAddress: GetSrcAddress1;
      /**
       * 送信先アドレス.
       * 送信先ゾーンのdstAddressListを返却
       */
      dstAddress: GetDstAddress1;
      /** */
      profile: Pick<GetProfile, "internetFW" | "profileInternetFw">;
    })[];
    /** ポリシー総件数. アクセス拠点からインターネット向けのポリシー総数 */
    accessPointToInternetTotal: number;
    /**
     * ポリシーリスト.
     * アクセス拠点からインターネット向けのポリシーが無い場合、空リストを返却
     */
    accessPointToInternetPolicyList: (Pick<
      GetPolicy,
      | "policyObjectId"
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元ゾーンのsrcAddressListを返却
       */
      srcAddress: GetSrcAddress2;
      /**
       * 送信先アドレス.
       * 送信先ゾーンのdstAddressListを返却
       */
      dstAddress: GetDstAddress2;
      /** */
      profile: Pick<GetProfile, "internetFW" | "profileInternetFw">;
    })[];
  };
  /**
   * Type4ポリシー(IP Masquerade).
   * クエリパラメータのポリシー種別の対象外の場合はNULLを返却
   */
  policyIPMasqueradeType4: null | {
    /**
     * 総件数.
     * 一覧表示対象の総件数
     */
    total: number;
    /**
     * ポリシーリスト.
     * ポリシーが無い場合、空リストを返却
     */
    policyList: (Pick<
      GetPolicy,
      | "policyObjectId"
      | "policyId"
      | "ipPoolList"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元アドレスが無い場合は空リスト
       */
      srcAddressList: GetSrcAddress2["srcAddressList"];
      /**
       * 送信先アドレス.
       * 送信先アドレスが無い場合は空リスト
       */
      dstAddressList: GetDstAddress2["dstAddressList"];
    })[];
  };
  /**
   * Type4ポリシー(Destination NAT).
   * クエリパラメータのポリシー種別の対象外の場合はNULLを返却
   */
  policyNatType4: null | {
    /**
     * 総件数.
     * 一覧表示対象の総件数
     */
    total: number;
    /**
     * ポリシーリスト.
     * ポリシーが無い場合、空リストを返却
     */
    policyList: (Pick<
      GetPolicy,
      | "policyObjectId"
      | "policyId"
      | "ipMapping"
      | "isPolicyStatus"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元アドレスが無い場合は空リスト
       */
      srcAddressList: GetSrcAddress2["srcAddressList"];
    })[];
  };
  /**
   * ポリシー設定排他情報.
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  policyKeyId: string;
}

export interface PutPolicyType4 {
  /**
   * Type4ポリシー(UTM).
   * クエリパラメータのポリシー種別の対象外の場合とType4ポリシー(IFW)契約時はNULLを設定
   */
  policyUtmType4: null | {
    /**
     * ポリシーリスト.
     * インターネットからアクセス拠点向けのポリシーを削除する場合、空リストを設定
     */
    internetToAccessPointPolicyList: (Pick<
      PutPolicy,
      | "policyObjectId"
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元ゾーンのsrcAddressListが指定可能
       */
      srcAddress: PutSrcAddress1;
      /**
       * 送信先アドレス.
       * 送信先ゾーンのdstAddressListが指定可能
       */
      dstAddress: PutDstAddress1;
      /** */
      profile: NullableProperty<
        PutProfile,
        // TODO: API仕様書が後から更新される予定
        | "idsIps"
        | "urlFiltering"
        | "webAntiVirus"
        | "mailAntiVirus"
        | "isAntiSpyware"
      >;
    })[];
    /**
     * ポリシーリスト.
     * アクセス拠点からインターネット向けのポリシーを削除する場合、空リストを設定
     */
    accessPointToInternetPolicyList: (Pick<
      PutPolicy,
      | "policyObjectId"
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元ゾーンのsrcAddressListが指定可能
       */
      srcAddress: PutSrcAddress2;
      /**
       * 送信先アドレス.
       * 送信先ゾーンのdstAddressList、dstFqdnListが指定可能
       */
      dstAddress: PutDstAddress2;
      /** */
      profile: NullableProperty<
        PutProfile,
        // TODO: API仕様書が後から更新される予定
        | "idsIps"
        | "urlFiltering"
        | "webAntiVirus"
        | "mailAntiVirus"
        | "isAntiSpyware"
      >;
    })[];
  };
  /**
   * Type4ポリシー(IFW).
   * クエリパラメータのポリシー種別の対象外の場合とType4ポリシー(UTM)契約時はNULLを設定
   */
  policyIfwType4: null | {
    /**
     * ポリシーリスト.
     * インターネットからアクセス拠点向けのポリシーを削除する場合、空リストを設定
     */
    internetToAccessPointPolicyList: (Pick<
      PutPolicy,
      | "policyObjectId"
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元ゾーンのsrcAddressListが指定可能
       */
      srcAddress: PutSrcAddress1;
      /**
       * 送信先アドレス.
       * 送信先ゾーンのdstAddressListが指定可能
       */
      dstAddress: PutDstAddress1;
      /** */
      profile: Pick<PutProfile, "internetFW" | "profileInternetFw">;
    })[];
    /**
     * ポリシーリスト.
     * アクセス拠点からインターネット向けのポリシーを削除する場合、空リストを設定
     */
    accessPointToInternetPolicyList: (Pick<
      PutPolicy,
      | "policyObjectId"
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    > & {
      /**
       * 送信元アドレス.
       * 送信元ゾーンのsrcAddressListが指定可能
       */
      srcAddress: PutSrcAddress2;
      /**
       * 送信先アドレス.
       * 送信先ゾーンのdstAddressListが指定可能
       */
      dstAddress: PutDstAddress2;
      /** */
      profile: Pick<PutProfile, "internetFW" | "profileInternetFw">;
    })[];
  };
  /**
   * Type4ポリシー(IP Masquerade).
   * クエリパラメータのポリシー種別の対象外の場合はNULLを設定
   */
  policyIPMasqueradeType4: null | {
    /**
     * ポリシーリスト.
     * ポリシーを削除する場合、空リストを設定
     */
    policyList: Pick<
      PutPolicy,
      | "policyObjectId"
      | "policyId"
      | "srcAddressList"
      | "dstAddressList"
      | "ipPoolList"
      | "isPolicyStatus"
      | "policyCategoryInfo"
      | "description"
    >[];
  };
  /**
   * Type4ポリシー(Destination NAT).
   * クエリパラメータのポリシー種別の対象外の場合はNULLを設定
   */
  policyNatType4: null | {
    /**
     * ポリシーリスト.
     * ポリシーを削除する場合、空リストを設定
     */
    policyList: Pick<
      PutPolicy,
      | "policyObjectId"
      | "policyId"
      | "srcAddressList"
      | "ipMapping"
      | "isPolicyStatus"
      | "description"
    >[];
  };
  /** 操作履歴の備考. */
  note?: string;
  /**
   * ポリシー設定排他情報.
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  policyKeyId: string;
}

export interface GetPolicyType1 {
  /**
   * Type1ポリシー(UTM).
   * クエリパラメータのポリシー種別の対象外の場合とType1ポリシー(IFW)契約時はNULLを返却
   */
  policyUtmType1: null | {
    /**
     * インターネットからアクセス拠点向けのポリシーリスト.
     * ゾーン関連IDが無い場合、空リストを返却
     */
    internetToAccessPointList: {
      /**
       * ゾーン関連ID.
       * Type1のゾーン間の連携を特定するキー
       */
      zoneRelationId: string;
      /**
       * 総件数.
       * 一覧表示対象の総件数
       */
      total: number;
      /**
       * ポリシーリスト.
       * ポリシーが無い場合、空リストを返却
       */
      policyList: NonNullable<
        GetPolicyType4["policyUtmType4"]
      >["internetToAccessPointPolicyList"];
    }[];
    /**
     * アクセス拠点からインターネット向けのポリシーリスト.
     * ゾーン関連IDが無い場合、空リストを返却
     */
    accessPointToInternetList: {
      /**
       * ゾーン関連ID.
       * Type1のゾーン間の連携を特定するキー
       */
      zoneRelationId: string;
      /**
       * 総件数.
       * 一覧表示対象の総件数
       */
      total: number;
      /**
       * ポリシーリスト.
       * ポリシーが無い場合、空リストを返却
       */
      policyList: NonNullable<
        GetPolicyType4["policyUtmType4"]
      >["accessPointToInternetPolicyList"];
    }[];
  };
  /**
   * Type1ポリシー(IFW).
   * クエリパラメータのポリシー種別の対象外の場合とType1ポリシー(UTM)契約時はNULLを返却
   */
  policyIfwType1: null | {
    /**
     * インターネットからアクセス拠点向けのポリシーリスト.
     * ゾーン関連IDが無い場合、空リストを返却
     */
    internetToAccessPointList: {
      /**
       * ゾーン関連ID.
       * Type1のゾーン間の連携を特定するキー
       */
      zoneRelationId: string;
      /**
       * 総件数.
       * 一覧表示対象の総件数
       */
      total: number;
      /**
       * ポリシーリスト.
       * ポリシーが無い場合、空リストを返却
       */
      policyList: NonNullable<
        GetPolicyType4["policyIfwType4"]
      >["internetToAccessPointPolicyList"];
    }[];
    /**
     * アクセス拠点からインターネット向けのポリシーリスト.
     * ゾーン関連IDが無い場合、空リストを返却
     */
    accessPointToInternetList: {
      /**
       * ゾーン関連ID.
       * Type1のゾーン間の連携を特定するキー
       */
      zoneRelationId: string;
      /**
       * 総件数.
       * 一覧表示対象の総件数
       */
      total: number;
      /**
       * ポリシーリスト.
       * ポリシーが無い場合、空リストを返却
       */
      policyList: NonNullable<
        GetPolicyType4["policyIfwType4"]
      >["accessPointToInternetPolicyList"];
    }[];
  };
  policyIPMasqueradeType1List:
    | {
        /**
         * ゾーン関連ID.
         * Type1のゾーン間の連携を特定するキー
         */
        zoneRelationId: string;
        /**
         * 総件数.
         * 一覧表示対象の総件数
         */
        total: number;
        /**
         * ポリシーリスト.
         * ポリシーが無い場合、空リストを返却
         */
        policyList: NonNullable<
          GetPolicyType4["policyIPMasqueradeType4"]
        >["policyList"];
      }[];
  policyNatType1List:
    | {
        /**
         * ゾーン関連ID.
         * Type1のゾーン間の連携を特定するキー
         */
        zoneRelationId: string;
        /**
         * 総件数.
         * 一覧表示対象の総件数
         */
        total: number;
        /**
         * ポリシーリスト.
         * ポリシーが無い場合、空リストを返却
         */
        policyList: NonNullable<GetPolicyType4["policyNatType4"]>["policyList"];
      }[];
  /**
   * ポリシー設定排他情報.
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  policyKeyId: string;
}

export interface PutPolicyType1 {
  /**
   * Type1ポリシー(UTM).
   * クエリパラメータのポリシー種別の対象外の場合とType1ポリシー(IFW)契約時はNULLを設定
   */
  policyUtmType1: null | {
    /**
     * インターネットからアクセス拠点向けのポリシーリスト.
     * ゾーン関連IDが無い場合、空リストを設定
     */
    internetToAccessPointList: {
      /**
       * ゾーン関連ID.
       * Type1のゾーン間の連携を特定するキー
       */
      zoneRelationId: string;
      /**
       * ポリシーリスト.
       * ポリシーを削除する場合、空リストを設定
       */
      policyList: NonNullable<
        PutPolicyType4["policyUtmType4"]
      >["internetToAccessPointPolicyList"];
    }[];
    /**
     * アクセス拠点からインターネット向けのポリシーリスト.
     * ゾーン関連IDが無い場合、空リストを設定
     */
    accessPointToInternetList: {
      /**
       * ゾーン関連ID.
       * Type1のゾーン間の連携を特定するキー
       */
      zoneRelationId: string;
      /**
       * ポリシーリスト.
       * ポリシーを削除する場合、空リストを設定
       */
      policyList: NonNullable<
        PutPolicyType4["policyUtmType4"]
      >["accessPointToInternetPolicyList"];
    }[];
  };
  /**
   * Type1ポリシー(IFW).
   * クエリパラメータのポリシー種別の対象外の場合とType1ポリシー(UTM)契約時はNULLを設定
   */
  policyIfwType1: null | {
    /**
     * インターネットからアクセス拠点向けのポリシーリスト.
     * ゾーン関連IDが無い場合、空リストを設定
     */
    internetToAccessPointList: {
      /**
       * ゾーン関連ID.
       * Type1のゾーン間の連携を特定するキー
       */
      zoneRelationId: string;
      /**
       * ポリシーリスト.
       * ポリシーを削除する場合、空リストを設定
       */
      policyList: NonNullable<
        PutPolicyType4["policyIfwType4"]
      >["internetToAccessPointPolicyList"];
    }[];
    /**
     * アクセス拠点からインターネット向けのポリシーリスト.
     * ゾーン関連IDが無い場合、空リストを設定
     */
    accessPointToInternetList: {
      /**
       * ゾーン関連ID.
       * Type1のゾーン間の連携を特定するキー
       */
      zoneRelationId: string;
      /**
       * ポリシーリスト.
       * ポリシーを削除する場合、空リストを設定
       */
      policyList: NonNullable<
        PutPolicyType4["policyIfwType4"]
      >["accessPointToInternetPolicyList"];
    }[];
  };
  /**
   * Type1ポリシー(IP Masquerade)のリスト.
   * クエリパラメータのポリシー種別の対象外の場合、空配列を設定
   */
  policyIPMasqueradeType1List:
    | {
        /**
         * ゾーン関連ID.
         * Type1のゾーン間の連携を特定するキー
         */
        zoneRelationId: string;
        /**
         * ポリシーリスト.
         * ポリシーを削除する場合、空リストを設定
         */
        policyList: NonNullable<
          PutPolicyType4["policyIPMasqueradeType4"]
        >["policyList"];
      }[];
  /**
   * Type1ポリシー(Destination NAT)のリスト.
   * クエリパラメータのポリシー種別の対象外の場合、空配列を設定
   */
  policyNatType1List:
    | {
        /**
         * ゾーン関連ID.
         * Type1のゾーン間の連携を特定するキー
         */
        zoneRelationId: string;
        /**
         * ポリシーリスト.
         * ポリシーを削除する場合、空リストを設定
         */
        policyList: NonNullable<PutPolicyType4["policyNatType4"]>["policyList"];
      }[];
  /** 操作履歴の備考. */
  note?: string;
  /**
   * ポリシー設定排他情報.
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  policyKeyId: string;
}

export interface Country {
  countryList: {
    /**
     * 国Seq.
     * 国をユニークに識別するためのキー
     */
    countrySeq: string;
    /** 国名. */
    country: string;
  }[];
}

/** ネットワークポリシー（type1,type4）設定API */
export default class NwPolicyApi extends AbstractBaseApi {
  /**
   * Type4のポリシー設定の一覧を取得する
   * @param type4Id Type4Id. 主キー
   * @param policyType ポリシー種別. アプライアンスの組み合わせを指定する。指定省略時、type4Idに紐づくすべての状態取得を行う
   */
  async getType4Policies(
    type4Id: string,
    policyType?: PolicyType
  ): Promise<GetPolicyType4> {
    return (
      await this.api.get(`/v1/network/policy/type4/${type4Id}`, {
        params: { policyType },
      })
    ).data;
  }

  /**
   * Type4のポリシー設定を更新する
   * @param type4Id Type4Id. 主キー
   * @param policyType ポリシー種別. アプライアンスの組み合わせを指定する。
   * @param data ポリシー設定
   */
  async putType4Policies(
    type4Id: string,
    data: PutPolicyType4,
    policyType: PolicyType
  ): Promise<void> {
    await this.api.post(`/v1/network/policy/type4/${type4Id}`, data, {
      params: { policyType },
    });
  }

  /**
   * Type1のポリシー設定の一覧を取得する
   * @param policyType ポリシー種別. アプライアンスの組み合わせを指定する。指定省略時、全取得を行う。
   */
  async getType1Policies(policyType?: PolicyType): Promise<GetPolicyType1> {
    return (
      await this.api.get(`/v1/network/policy/type1`, {
        params: { policyType },
      })
    ).data;
  }

  /**
   * Type1のポリシー設定を更新する
   * @param data ポリシー設定
   * @param policyType ポリシー種別. アプライアンスの組み合わせを指定する。
   */
  async putType1Policies(
    data: PutPolicyType1,
    policyType: PolicyType
  ): Promise<void> {
    await this.api.post(`/v1/network/policy/type1`, data, {
      params: { policyType },
    });
  }

  /**
   * 国名の一覧を取得する.
   * 出力する一覧情報は下記の通りにソートして出力する。
   * 1:国名のアルファベット昇順
   */
  async getCountries(): Promise<Country> {
    return (await this.api.get("/v1/network/policy/country")).data;
  }
}

































































































import Vue, { PropType } from "vue";
import { VisibleIpSecGW } from "@/views/ipSecGw/IpSecGWList.vue";
import { IpSecGWUpdateEntity } from "@/apis/IpSecGwApi";

export default Vue.extend({
  name: "IpSecGWModify",
  props: {
    /** IPsecGW */
    ipsecGW: {
      type: Object as PropType<VisibleIpSecGW>,
      required: true,
    },
    /** IPsecGW排他情報 */
    keyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      form: {
        suffix: "",
        ipsecGatewayKeyId: this.keyId,
      } as IpSecGWUpdateEntity,
    };
  },
  methods: {
    async submit() {
      await this.$confirm(
        "IPsecGWの設定を行います。この設定は後から変更できません\n実施してよろしいですか？"
      );
      await this.$api.ipSecGw.update(this.ipsecGW.enumber, this.form);
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

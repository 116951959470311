












































































































import Vue, { PropType } from "vue";
import IntranetPolicySrcAddress from "@/components/policy/IntranetPolicySrcAddress.vue";
import IntranetPolicyDstAddress from "@/components/policy/IntranetPolicyDstAddress.vue";
import IntranetPolicyService from "@/components/policy/IntranetPolicyService.vue";
import { IntranetFwPolicy, ZonePolicyInfo } from "@/apis/IntranetfwPolicyApi";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import { IntranetfwServiceMenu } from "@/apis/IntranetfwServiceMenuApi";

/**
 * 設定反映画面表示用のオブジェクト
 * ゾーンの接続とポリシーの情報を結合した形に
 */
type ZonePolicy = Pick<ZonePolicyInfo, "policyList"> &
  Pick<
    ZoneRelationItem,
    "srcZoneType" | "srcZoneName" | "dstZoneType" | "dstZoneName"
  >;

export default Vue.extend({
  name: "IntranetFwPolicyConfirm",
  components: {
    IntranetPolicySrcAddress,
    IntranetPolicyDstAddress,
    IntranetPolicyService,
  },
  props: {
    /** ポリシー */
    intranetFwPolicy: {
      type: Object as PropType<IntranetFwPolicy>,
      required: true,
    },
    /** ゾーン接続 */
    zoneList: {
      type: Array as PropType<ZoneRelationItem[]>,
      required: true,
    },
    /** イントラネット契約情報 */
    contractInfo: {
      type: Object as PropType<
        Pick<IntranetfwServiceMenu, "vpnCode" | "intranetFwBandwidth">
      >,
      required: true,
    },
  },
  computed: {
    /** 設定反映画面表示用に整形した情報 */
    displayPolicyList(): ZonePolicy[] {
      // 各方向のゾーン + ポリシー情報を1つの配列として返却
      return [
        ...this.intranetFwPolicy.extraToPrivateList.map((v) => {
          const zone = this.zoneList.find(
            (e) => v.zoneRelationId === e.zoneRelationId
          )!;
          return {
            policyList: v.policyList,
            srcZoneType: zone.srcZoneType,
            srcZoneName: zone.srcZoneName,
            dstZoneType: zone.dstZoneType,
            dstZoneName: zone.dstZoneName,
          };
        }),
        ...this.intranetFwPolicy.privateToExtraList.map((v) => {
          const zone = this.zoneList.find(
            (e) => v.zoneRelationId === e.zoneRelationId
          )!;
          return {
            policyList: v.policyList,
            srcZoneType: zone.srcZoneType,
            srcZoneName: zone.srcZoneName,
            dstZoneType: zone.dstZoneType,
            dstZoneName: zone.dstZoneName,
          };
        }),
        ...this.intranetFwPolicy.privateToPrivateList.map((v) => {
          const zone = this.zoneList.find(
            (e) => v.zoneRelationId === e.zoneRelationId
          )!;
          return {
            policyList: v.policyList,
            srcZoneType: zone.srcZoneType,
            srcZoneName: zone.srcZoneName,
            dstZoneType: zone.dstZoneType,
            dstZoneName: zone.dstZoneName,
          };
        }),
      ].sortBy(["srcZoneType", "asc"], ["srcZoneName", "asc"]);
    },
  },
});

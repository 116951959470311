import AbstractBaseApi from "@/apis/AbstractBaseApi";
import {
  BandWidth,
  BandWidthEmpty,
  IfStatus,
  MaximumPrefix,
  Priority,
  RouteAggregation,
  VpnVnCode,
} from "@/apis/CloudVnMsApi";

export interface SfdcVpnVnCodeGetRes {
  /**
   * 総件数（クラウドVN回線）
   * 一覧表示対象の総件数
   */
  cloudVnConnectTotal: number;
  cloudVnConnectsList: {
    /** VPN/VNコード */
    vpnVnCode: VpnVnCode;
    /**
     * 仮想ネットワーク名
     * VPN/VNコードがVPNの場合、NULLを設定する。
     */
    vnName: string | null;
  }[];
}

export interface CloudVnSfdc {
  /** E番号. */
  enumber: string;
  /**
   * アクセス品目.
   * 以下のいずれかを指定する。
   * MULTI_CLOUD_GW：マルチクラウドGW
   */
  accessType: "MULTI_CLOUD_GW";
  /** 帯域. GET時に登録された帯域が存在しない場合は「0」を指定する。 */
  bandwidth: BandWidth | BandWidthEmpty;
  /** 事業所名. */
  office: string | null;
  /**
   * クラウドVN回線(SFDC)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnSfdcKeyId: string;
}

export interface CloudVnSfdcCommon {
  /**
   * クラウドサービス利用拠点.
   * 下記を指定する。
   * EAST_JAPAN: 東日本
   */
  location: "EAST_JAPAN";
  /** VPN/VNコード */
  vpnVnCode: VpnVnCode;
  /** 仮想ネットワークタイプ. L3固定 */
  vnType: "L3";
  /** 仮想ネットワーク名. */
  vnName: string | null;
  /** ルーティング種別. BGP4固定 */
  routingType: "BGP4";
  /** 帯域タイプ. PF固定 */
  bandwidthType: "PF";
  /** 帯域 */
  bandwidth: BandWidth;
  /**
   * 経路集約.
   * OFF、ON、CUSTOMのいずれかを指定する。
   * OFF：経路集約を非表示
   * ON：経路集約を表示
   * CUSTOM：経路集約アドレスを入力
   */
  routeAggregation: NonNullable<RouteAggregation>;
  /**
   * 経路集約アドレスリスト.
   * 経路集約が「CUSTOM」選択時は必須項目
   * 登録順でソートする。
   */
  routeAggregationAddressList: Array<string> | null;
  /**
   * NAPTグローバルIPアドレス登録数
   */
  naptGlobalIpTotal: number;
  /**
   * NAPTグローバルIPアドレスリスト
   * グローバルIPアドレスの払出順に取得する。
   */
  naptGlobalIpList: string[];
  /**
   * NAPTグローバルIPアドレス払出個数
   * SaaS利用APIのGETからNAPTアドレス上限数を取得し、その上限値以下の値を設定可能とする。
   */
  addNaptNum: number;
  /** 設定値の説明  */
  description: string | null;
}

export interface CloudVnSfdcActSby {
  /** VNコネクト名. */
  vnConnectName: string;
  /**
   * W番号.
   * 主キー
   */
  wnumber: string;
  /**
   * IF状態. VNコネクト状態が無効の場合省略
   * NO_SHUTDOWN: 開放状態
   * SHUTDOWN: 閉塞状態
   */
  ifStatus: IfStatus;
  /** VNコネクト有効/無効フラグ. true:有効 false:無効 */
  isVnConnectStatus: boolean;
  /**
   * AS番号
   * 1～8073、8076～9995、9997～12075、12077～23455、23457～64495、64512～64949、64970～65501、65504～65514、65521～65534の値を設定可能とする。
   * ACTで指定した値でSBYの値は設定する。
   */
  asNumber: number;
  /** WANアドレス. */
  wanAddress: string;
  /** ピアIPアドレス */
  peerIpAddress: string;
  /**
   * MD5文字列.
   * 「Kddicloud」固定
   */
  md5Key: "Kddicloud";
  /**
   * Maximum prefix(設定値上限取得APIのBGP4の最大経路数).
   * 設定値上限APIのGETから上限値を取得し、その上限値以下の値を設定可能とする。
   * 値は100,200,300,400,500,1000,1500,2000から指定する。
   * ACTで指定した値でSBYの値は設定する。
   */
  maximumPrefix: MaximumPrefix;
  /**
   * ローカルプリファレンス.
   * HIGH_PRIORITY:優先(200)
   * LOW_PRIORITY:OFF(100)
   * ACTの場合はHIGH_PRIORITY、SBYの場合はLOW_PRIORITY固定となる。
   */
  localPreference: Priority;
  /**
   * MED.
   * HIGH_PRIORITY:非優先(100)
   * LOW_PRIORITY:OFF(0)
   * ACTの場合はLOW_PRIORITY、SBYの場合はHIGH_PRIORITY固定となる。
   */
  med: Priority;
}

export type CloudVnSfdcList = CloudVnSfdc & {
  /**
   * 総件数（クラウドVN回線）.
   * 一覧表示対象の総件数
   */
  cloudVnConnectTotal: number;
  cloudVnConnectsList: {
    cloudVnConnectCommon: Pick<
      CloudVnSfdcCommon,
      | "location"
      | "vpnVnCode"
      | "vnType"
      | "routingType"
      | "bandwidthType"
      | "bandwidth"
      | "description"
    >;
    cloudVnConnectAct: Pick<
      CloudVnSfdcActSby,
      "vnConnectName" | "wnumber" | "ifStatus" | "isVnConnectStatus"
    >;
    cloudVnConnectSby: Pick<
      CloudVnSfdcActSby,
      "vnConnectName" | "wnumber" | "ifStatus" | "isVnConnectStatus"
    >;
  }[];
};

export type CloudVnSfdcDetail = CloudVnSfdc & {
  cloudVnConnectCommon: Omit<CloudVnSfdcCommon, "addNaptNum">;
  cloudVnConnectAct: CloudVnSfdcActSby;
  cloudVnConnectSby: CloudVnSfdcActSby;
};

/**
 * 接続先VPN/VNリストのプルダウンメニューの項目は、本APIのSFDC申込済み仮想ネットワーク情報取得のGETから検出する。
 * また以下の項目は固定値となり、API内部で設定するため省略する。
 * ・MED
 * ACT回線の場合は「LOW_PRIORITY」固定
 * SBY回線の場合は「HIGH_PRIORITY」固定
 * ・ローカルプレファレンス
 * ACT回線の場合は「HIGH_PRIORITY」固定
 * SBY回線の場合は「LOW_PRIORITY」固定
 * ・ルーティング種別 BGP4固定
 * ・md5文字列 「kddicloud」固定
 */
export interface CloudVnSfdcPost {
  cloudVnConnectCommon: Pick<
    CloudVnSfdcCommon,
    | "vpnVnCode"
    | "bandwidthType"
    | "bandwidth"
    | "routeAggregation"
    | "routeAggregationAddressList"
    | "addNaptNum"
    | "description"
  >;
  cloudVnConnectAct: Pick<
    CloudVnSfdcActSby,
    | "vnConnectName"
    | "asNumber"
    | "wanAddress"
    | "peerIpAddress"
    | "maximumPrefix"
  >;
  cloudVnConnectSby: Pick<
    CloudVnSfdcActSby,
    | "vnConnectName"
    | "asNumber"
    | "wanAddress"
    | "peerIpAddress"
    | "maximumPrefix"
  >;
  /** 操作履歴の備考 */
  note: string | null;
  /**
   * クラウドVN回線(SFDC)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnSfdcKeyId: string;
}

export interface CloudVnSfdcPut {
  cloudVnConnectCommon: Pick<
    CloudVnSfdcCommon,
    "routeAggregation" | "routeAggregationAddressList" | "description"
  >;
  cloudVnConnectAct: Pick<CloudVnSfdcActSby, "vnConnectName" | "maximumPrefix">;
  cloudVnConnectSby: Pick<
    CloudVnSfdcActSby,
    "wnumber" | "vnConnectName" | "maximumPrefix"
  >;
  /** 操作履歴の備考 */
  note: string | null;
  /**
   * クラウドVN回線(SFDC)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnSfdcKeyId: string;
}

export interface CloudVnSfdcOrder {
  /** W番号. 主キー */
  cloudVnConnectList: string[];
  /**
   * クラウドVN回線(SFDC)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnSfdcKeyId: string;
}

export type CloudVnSfdcDelete = CloudVnSfdcOrder & {
  /** 操作履歴の備考 */
  note: string | null;
};

/** クラウドVN回線(Salesforce)API */
export default class CloudVnSfdcApi extends AbstractBaseApi {
  /**
   * SFDC申込済み仮想ネットワーク情報を取得(プルダウン用)
   * SFDC設定済みのVPN/VNコードを返却する。 SFDC用のクラウドVNコネクト作成済みVPN/VNコードは返却しないこととする。
   */
  async getVpnVnCodes(): Promise<SfdcVpnVnCodeGetRes["cloudVnConnectsList"]> {
    const { cloudVnConnectsList } = (
      await this.api.get<SfdcVpnVnCodeGetRes>(
        "/v1/network/cloud/line/sfdc/vpn-vn-code"
      )
    ).data;
    return cloudVnConnectsList;
  }

  /**
   * クラウドVN回線設定を取得
   * @param cloudLineSeq クラウド回線SEQ
   */
  async getVnConnects(cloudLineSeq: string): Promise<CloudVnSfdcList> {
    return (
      await this.api.get(
        `/v1/network/cloud/line/${cloudLineSeq}/sfdc/vnconnects`
      )
    ).data;
  }

  /**
   * クラウドVN回線設定を追加
   * @param cloudLineSeq クラウド回線SEQ
   * @param data クラウドVN回線設定
   */
  async postVnConnect(
    cloudLineSeq: string,
    data: CloudVnSfdcPost
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/sfdc/vnconnects`,
      data
    );
  }

  /**
   * クラウドVN回線設定を変更
   * @param cloudLineSeq クラウド回線SEQ
   * @param wnumber W番号. ACT回線のW番号を指定する
   * @param data クラウドVN回線設定
   */
  async putVnConnect(
    cloudLineSeq: string,
    wnumber: string,
    data: CloudVnSfdcPut
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/sfdc/vnconnects/${wnumber}`,
      data
    );
  }

  /**
   * クラウドVNコネクトの有効/無効状態を有効に変更
   * @param data 有効情報
   */
  async putEnableVnConnect(data: CloudVnSfdcOrder): Promise<void> {
    await this.api.post(`/v1/network/cloud/line/sfdc/vnconnects/enable`, data);
  }

  /**
   * クラウドVNコネクトの有効/無効状態を無効に変更
   * @param data 無効情報
   */
  async putDisableVnConnect(data: CloudVnSfdcOrder): Promise<void> {
    await this.api.post(`/v1/network/cloud/line/sfdc/vnconnects/disable`, data);
  }

  /**
   * クラウドVN回線設定の詳細情報を取得
   * @param wnumber W番号. ACT回線のW番号を指定する。
   */
  async getVnConnect(wnumber: string): Promise<CloudVnSfdcDetail> {
    return (
      await this.api.get(`/v1/network/cloud/line/sfdc/vnconnect/${wnumber}`)
    ).data;
  }

  /**
   * クラウドVN回線設定を削除
   * @param cloudLineSeq クラウド回線SEQ
   * @param data ACT/SBYを必ずセットで削除する。その際、ACT/SBYは1セットのみ入力可能とする。
   */
  async deleteVnConnect(
    cloudLineSeq: string,
    data: CloudVnSfdcDelete
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/sfdc/vnconnects/delete`,
      data
    );
  }
}

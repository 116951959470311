import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 入力されたIPアドレスが省略表記でないこと(各フィールドが4桁であること)
 * ・IPV6形式であることは別のバリデーションで実施が必要
 */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }

    let count = 0;
    value
      .split("/")[0]
      .split(":")
      .map((e) => {
        if (e.length !== 4) {
          count++;
        }
      });
    return count === 0;
  },
} as ValidationRuleSchema;

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** アプライアンスログレポート作成可能な一覧情報 */
export interface ApplianceLogCreatableList {
  /** 総件数 一覧表示対象の総件数 */
  total: number;
  /** アプライアンスリスト 空の場合は空配列 */
  applianceList: {
    /**
     * E番号
     * インターネットType1回線のE番号(Main_ACT)
     * アプライアンス種別がイントラネットFWの場合、nullを設定
     */
    enumber: string | null;
    /**
     * W番号
     * インターネットType4回線のW番号(Main_ACT)
     * アプライアンス種別がイントラネットFWの場合、nullを設定
     */
    wnumber: string | null;
    /**
     * アプライアンス種別
     * INTERNET_FW：インターネットFW
     * UTM：UTM
     * INTRANET_FW：イントラネットFW
     * ※イントラネットFWはインターネットType1の場合のみ選択可
     */
    applianceType: "INTERNET_FW" | "UTM" | "INTRANET_FW";
  }[];
}

/**
 * アプライアンスログ一覧の項目型定義
 */
export type Appliance = ApplianceLogCreatableList["applianceList"][0] & {
  type4Id: string | null;
  lineNum: string | null;
};

/** アプライアンスログ作成依頼情報 */
export interface CreateApplianceLog {
  /**
   * E番号
   * インターネットType1回線のE番号(Main_ACT)
   * インターネットType1回線の場合、必須  ただし、解約されている場合はnull
   * インターネットType4回線またはアプライアンス種別がイントラネットFWの場合、nullを設定   */
  enumber: string | null;
  /**
   * W番号
   * インターネットType4回線のW番号(Main_ACT)
   * インターネットType4回線の場合、必須  ただし、解約されている場合はnull
   * インターネットType1回線またはアプライアンス種別がイントラネットFWの場合、nullを設定   */
  wnumber: string | null;
  /**
   * アプライアンス種別
   * INTERNET_FW：インターネットFW
   * UTM：UTM
   * INTRANET_FW：イントラネットFW
   * ※イントラネットFWはインターネットType1の場合のみ選択可能
   */
  applianceType: "INTERNET_FW" | "UTM" | "INTRANET_FW";
  /**
   * 開始日時
   * ($date-time) example: 2021-12-01T23:59:59+09:00
   */
  startDateTime: string;
  /**
   * 終了日時
   * ($date-time) example: 2021-12-01T23:59:59+09:00
   */
  endDateTime: string;
}

export interface ApplianceLogList {
  /** 総件数. 一覧表示対象の総件数*/
  total: number;
  /** アプライアンスログリスト. 空の場合は空配列 */
  applianceLogList: {
    /**
     * レポート対象日
     * ($date) example: 2021-12-01
     */
    reportDate: string;
    /**
     * E番号
     * インターネットType1回線のE番号(Main_ACT)
     * インターネットType1回線の場合、必須  ただし、解約されている場合はnull
     * インターネットType4回線またはアプライアンス種別がイントラネットFWの場合、nullを設定   */
    enumber: string | null;
    /**
     * W番号
     * インターネットType4回線のW番号(Main_ACT)
     * インターネットType4回線の場合、必須  ただし、解約されている場合はnull
     * インターネットType1回線またはアプライアンス種別がイントラネットFWの場合、nullを設定   */
    wnumber: string | null;
    /**
     * アプライアンス種別
     * INTERNET_FW：インターネットFW
     * UTM：UTM
     * INTRANET_FW：イントラネットFW
     * ※イントラネットFWはインターネットType1の場合のみ選択可能
     */
    applianceType: "INTERNET_FW" | "UTM" | "INTRANET_FW";
    /** ファイル名 */
    fileName: string;
    /** ファイルサイズ. KB単位 */
    fileSize: number;
  }[];
}

/**
 * アプライアンスログ一覧の項目型定義
 */
export type ApplianceLog = ApplianceLogList["applianceLogList"][0];

export default class ApplianceLogApi extends AbstractBaseApi {
  /** アプライアンスログ作成依頼が可能なアプライアンスの一覧を取得する */
  async getApplianceLogCreatableList(): Promise<ApplianceLogCreatableList> {
    return (await this.api.get("/v1/report/appliancelog/create")).data;
  }

  /**
   * アプライアンスログレポ―ト作成依頼
   * @param data 作成依頼情報
   */
  async postCreateApplianceLog(data: CreateApplianceLog): Promise<void> {
    await this.api.post("/v1/report/appliancelog/create", data);
  }

  /**
   * アプライアンスログ一覧
   */
  async getApplianceLog(): Promise<ApplianceLogList> {
    return (await this.api.get(`/v1/report/appliancelog/search`)).data;
  }

  /**
   * ファイルのダウンロードAPI
   * @param fileName fileName
   */
  downloadFile(fileName: string): string {
    return this.getFullURL(
      `/v1/report/appliancelogs/download?fileName=${fileName}`,
      {
        params: {
          tokenId: this.store.state.user.token,
        },
      }
    );
  }

  /**
   * アプライアンスログのログ削除
   * @param data 削除対象のファイル名のリスト。ファイル名はアプライアンスログ一覧APIから取得
   */
  async deleteApplianceLog(data: string[]): Promise<void> {
    await this.api.post("/v1/report/appliancelogs/delete", data);
  }
}

import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { ApplianceInfo } from "@/apis/ContractApi";

/** IP Pool取得のAPI用インターフェイス */
export interface IpPoolGetRes {
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  /**
   * IPプールリスト
   * 取得したIP Pool設定情報。データが存在しない場合、[](空の状態)になります。
   */
  ipPoolList: IpPool[];
  /**
   * IPPool排他情報
   * 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  ipPoolKeyId: string;
}

/**
 * IPプールリストの項目定義
 */
export interface IpPool {
  /** IPプールSEQ. 主キー */
  ipPoolSeq: string;
  /** IPプール名 */
  ipPoolName: string;
  /**
   * グローバルIPアドレス
   * 追加・変更時に選択する候補値はTYPE１の時にはサービスメニューAPI（インターネットType1）、
   * TYPE4の時にはサービスメニューAPI(インターネットType4)より取得する。
   */
  globalIpAddress: string;
  /** 設定値の説明 */
  description: string | null;
  /**
   * ポリシー使用フラグ
   * 有りの場合は「true」、無しの場合は「false」を設定する。
   */
  isPolicyInUse: boolean;
}

/** IP Pool削除のAPI用インターフェイス */
export interface IpPoolDelete {
  /** IPプールSEQ. 主キー */
  ipPoolList: IpPool["ipPoolSeq"][];
  /** 操作履歴の備考 */
  note: string | null;
  /**
   * IPPool排他情報
   * 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  ipPoolKeyId: string;
}

/** IP Pool更新API用型定義 */
export type IpPoolPut = Pick<IpPoolGetRes, "ipPoolKeyId"> &
  Pick<IpPool, "globalIpAddress" | "description"> &
  Pick<IpPoolDelete, "note">;

/** IP Pool登録API用型定義 */
export type IpPoolPost = IpPoolPut & Pick<IpPool, "ipPoolName">;

/**
 * IP PoolのAPIクラス
 */
export default class IpPoolApi extends AbstractBaseApi {
  /** IP Pool(Type1)リストの取得 */
  async getIpPoolType1(): Promise<IpPoolGetRes> {
    return (await this.api.get("/v1/network/policy/ip-pools/type1")).data;
  }

  /**
   * IP Pool(Type4)リストの取得
   * @param type4Id 取得対象のType4ID
   */
  async getIpPoolType4(
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<IpPoolGetRes> {
    return (await this.api.get(`/v1/network/policy/ip-pools/type4/${type4Id}`))
      .data;
  }

  /**
   * IP Pool(Type1)の追加
   * @param data 追加データ
   */
  async postIpPoolType1(data: IpPoolPost): Promise<void> {
    await this.api.post("/v1/network/policy/ip-pool/type1", data);
  }

  /**
   * IP Pool(Type4)の追加
   * @param data 追加データ
   * @param type4Id 追加対象のType4ID
   */
  async postIpPoolType4(
    data: IpPoolPost,
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<void> {
    await this.api.post(`/v1/network/policy/ip-pool/type4/${type4Id}`, data);
  }

  /**
   * IP Pool(Type1)の更新
   * @param data 更新データ
   * @param ipPoolSeq 更新対象の主キー
   */
  async putIpPoolType1(
    data: IpPoolPut,
    ipPoolSeq: string | undefined
  ): Promise<void> {
    await this.api.post(`/v1/network/policy/ip-pool/type1/${ipPoolSeq}`, data);
  }

  /**
   * IP Pool(Type4)の更新
   * @param data 更新データ
   * @param ipPoolSeq 更新対象の主キー
   * @param type4Id 更新対象のType4ID
   */
  async putIpPoolType4(
    data: IpPoolPut,
    ipPoolSeq: string | undefined,
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/ip-pool/type4/${type4Id}/${ipPoolSeq}`,
      data
    );
  }

  /**
   * IP Pool(Type1)の削除
   * @param data 削除対象のデータ
   */
  async deleteIpPoolType1(data: IpPoolDelete): Promise<IpPoolDelete> {
    return (
      await this.api.post("/v1/network/policy/ip-pools/type1/delete", data)
    ).data;
  }

  /**
   * IP Pool(Type4)の削除
   * @param data 削除対象のデータ
   * @param type4Id 削除対象のType4ID
   */
  async deleteIpPoolType4(
    data: IpPoolDelete,
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<IpPoolDelete> {
    return (
      await this.api.post(
        `/v1/network/policy/ip-pools/type4/${type4Id}/delete`,
        data
      )
    ).data;
  }
}






import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import {
  isShowProvisioningStatus,
  MountData,
} from "@/store/provisioning-store";
import ProvisioningConfirmModal from "@/modals/ProvisioningConfirmModal.vue";

/**
 * 非同期の変更が可能なデータはプロビジョニングステータスを持っている
 * このプロビジョニングステータスを画面に表示/非表示するコンポーネント.
 * 変更系のAPIを実行した場合はコンポーネント内で自動でプロビジョニングステータスを再取得する
 * @see 別紙_プロビジョニング同時実行マトリクス.xlsx
 */
export default Vue.extend({
  name: "ProvisioningStatus",
  props: {
    /** QoS/クラウドQoS設定時の識別用. それ以外の画面では未指定で良い */
    qosType: {
      type: String as PropType<"QOS" | "CLOUD_QOS">,
      required: false,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    ...mapState("provisioning", {
      provisioning: "data",
    }),

    /** true: メッセージ表示, false: 非表示 */
    isShow(): boolean {
      return isShowProvisioningStatus(this.provisioning.messageCode);
    },
  },
  watch: {
    async isShow(newValue: boolean, oldValue: boolean) {
      if (!this.provisioning.enabledRefresh) {
        return;
      }

      if (!newValue && oldValue) {
        await this.$modal.show(ProvisioningConfirmModal);
        this.$vfm.hideAll();
        this.$emit("refresh");
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("provisioning/mount", {
      getProvisioningStatus: async () => {
        return await this.$api.information.getProvisioningStatus(
          { qosType: this.qosType },
          false
        );
      },
    } as MountData);
    this.isLoaded = true;
  },
  async destroyed() {
    await this.$store.dispatch("provisioning/unmount");
  },
});





































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  BandWidth,
  RouteAggregation,
  VpnVnCode as CloudVpnVnCode,
} from "@/apis/CloudVnMsApi";
import { CloudLine } from "@/apis/CloudLineApi";
import cloneDeep from "lodash/cloneDeep";
import { VpnVnCode } from "@/apis/InformationApi";
import {
  CloudVnIbmDetail,
  CloudVnIbmPost,
  CloudVnIbmPut,
} from "@/apis/CloudVnIbmApi";
import MultiCloudIbmVnConnectionConfirm from "@/modals/multicloud/ibm/MultiCloudIbmVnConnectionConfirm.vue";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";

export default Vue.extend({
  name: "MultiCloudIbmVnLineEdit",
  props: {
    /** クラウド回線SEQ */
    cloudLineSeq: {
      type: String as PropType<string>,
      required: true,
    },
    // 変更の場合用
    cloudVnIbmDetail: {
      type: Object as PropType<CloudVnIbmDetail>,
      default: undefined,
    },
    // 追加の場合用
    cloudVnIbmKeyId: {
      type: String as PropType<string>,
      default: undefined,
    },
  },
  data() {
    // 新規/更新のformデータを作成
    const form:
      | CloudVnIbmPost
      | (CloudVnIbmPut & {
          cloudVnConnectSby: { asNumber: number };
        }) = (() => {
      if (this.cloudVnIbmDetail) {
        // 変更
        const cloudVnIbmDetailCopy = cloneDeep(this.cloudVnIbmDetail);
        return {
          cloudVnConnectCommon: cloudVnIbmDetailCopy.cloudVnConnectCommon,
          cloudVnConnectAct: cloudVnIbmDetailCopy.cloudVnConnectAct,
          cloudVnConnectSby: cloudVnIbmDetailCopy.cloudVnConnectSby
            ? cloudVnIbmDetailCopy.cloudVnConnectSby
            : ({
                maximumPrefix: 100,
                asNumber: 13884,
              } as CloudVnIbmPut["cloudVnConnectSby"] & {
                asNumber: number;
              }),
          cloudVnIbmKeyId: cloudVnIbmDetailCopy.cloudVnIbmKeyId,
        };
      } else {
        // 追加
        const form: CloudVnIbmPost = {
          cloudVnConnectCommon: {
            bandwidthType: "PF",
            routeAggregation: "OFF",
            routeAggregationAddressList: [""],
            location: "EAST_JAPAN",
          } as CloudVnIbmPost["cloudVnConnectCommon"],
          cloudVnConnectAct: {
            maximumPrefix: 100,
            asNumber: 13884,
          } as CloudVnIbmPost["cloudVnConnectAct"],
          cloudVnConnectSby: {
            maximumPrefix: 100,
            asNumber: 13884,
          } as CloudVnIbmPost["cloudVnConnectSby"],
          cloudVnIbmKeyId: this.cloudVnIbmKeyId,
        };
        return form;
      }
    })();

    return {
      form,
      isLoaded: false,
      vpnVnCodeList: [] as VpnVnCode[],
      cloudLineMaxBandwidth: {} as CloudLine["bandwidth"],
      selectedVpnVn: null as null | { value: CloudVpnVnCode; text: string },
      bandwidthOptions: [
        { text: "10Mbps", value: { value: 10, unit: "MBPS" } },
        { text: "20Mbps", value: { value: 20, unit: "MBPS" } },
        { text: "30Mbps", value: { value: 30, unit: "MBPS" } },
        { text: "40Mbps", value: { value: 40, unit: "MBPS" } },
        { text: "50Mbps", value: { value: 50, unit: "MBPS" } },
        { text: "60Mbps", value: { value: 60, unit: "MBPS" } },
        { text: "70Mbps", value: { value: 70, unit: "MBPS" } },
        { text: "80Mbps", value: { value: 80, unit: "MBPS" } },
        { text: "90Mbps", value: { value: 90, unit: "MBPS" } },
        { text: "100Mbps", value: { value: 100, unit: "MBPS" } },
        { text: "200Mbps", value: { value: 200, unit: "MBPS" } },
        { text: "300Mbps", value: { value: 300, unit: "MBPS" } },
        { text: "400Mbps", value: { value: 400, unit: "MBPS" } },
        { text: "500Mbps", value: { value: 500, unit: "MBPS" } },
        { text: "600Mbps", value: { value: 600, unit: "MBPS" } },
        { text: "700Mbps", value: { value: 700, unit: "MBPS" } },
        { text: "800Mbps", value: { value: 800, unit: "MBPS" } },
        { text: "900Mbps", value: { value: 900, unit: "MBPS" } },
        { text: "1Gbps", value: { value: 1, unit: "GBPS" } },
        { text: "2Gbps", value: { value: 2, unit: "GBPS" } },
        { text: "3Gbps", value: { value: 3, unit: "GBPS" } },
        { text: "4Gbps", value: { value: 4, unit: "GBPS" } },
        { text: "5Gbps", value: { value: 5, unit: "GBPS" } },
        { text: "6Gbps", value: { value: 6, unit: "GBPS" } },
        { text: "7Gbps", value: { value: 7, unit: "GBPS" } },
        { text: "8Gbps", value: { value: 8, unit: "GBPS" } },
        { text: "9Gbps", value: { value: 9, unit: "GBPS" } },
        { text: "10Gbps", value: { value: 10, unit: "GBPS" } },
      ] as { text: string; value: BandWidth }[],
      routeOptions: [
        { value: "ON", text: "ON" },
        { value: "OFF", text: "OFF" },
        { value: "CUSTOM", text: "CUSTOM" },
      ],
      locationOptions: [
        { value: "EAST_JAPAN", text: "東日本" },
        { value: "WEST_JAPAN", text: "西日本" },
      ],
      /** Maximum Prefix上限値(変更の場合用) */
      maximumPrefixLimit: null as UpperLimitEntity | null,
      /** 1G超VNコネクト */
      vnConnects1G: null as UpperLimitEntity | null,
    };
  },
  computed: {
    /** VNコネクト(Act)名編集可否 */
    isVnConnectNameActEnable() {
      if (this.isAdd) {
        return true;
      }
      return !this.cloudVnIbmDetail!.cloudVnConnectAct.isVnConnectStatus;
    },
    /** VNコネクト(Sby)名編集可否 */
    isVnConnectNameSbyEnable() {
      // 追加あるいはSby自体が存在しない場合
      if (this.isAdd || !this.cloudVnIbmDetail!.cloudVnConnectSby) {
        return true;
      }
      return !this.cloudVnIbmDetail.cloudVnConnectSby.isVnConnectStatus;
    },
    /** 相関チェック
     * false: Sbyの登録とAct、Commonの編集は同時に行う場合 */
    multipleOption(): boolean {
      if (
        this.isAdd ||
        this.cloudVnIbmDetail.cloudVnConnectSby ||
        !this.form.cloudVnConnectSby?.vnConnectName
      ) {
        return true;
      }
      let changed = this.$crossValidation.objectChanged(
        this.cloudVnIbmDetail.cloudVnConnectCommon,
        this.form.cloudVnConnectCommon!
      );
      if (!changed) {
        changed = this.$crossValidation.objectChanged(
          this.cloudVnIbmDetail.cloudVnConnectAct,
          this.form.cloudVnConnectAct!
        );
      }
      return !changed;
    },
    currentLength(): number {
      return this.form.cloudVnConnectCommon!.routeAggregationAddressList
        ? this.form.cloudVnConnectCommon!.routeAggregationAddressList.length
        : 0;
    },
    /** true: 新規, false: 変更 */
    isAdd(): boolean {
      return !this.cloudVnIbmDetail;
    },

    vpnVnCodeSelectList(): {
      value: string;
      text: string;
    }[] {
      return this.vpnVnCodeList.map((v) => {
        if (v.vnName) {
          return {
            value: v.vpnVnCode,
            text: v.vnName + ":" + v.vpnVnCode + "(L3)",
          };
        } else {
          return { value: v.vpnVnCode, text: v.vpnVnCode };
        }
      });
    },
    /** 品目の選択肢 */
    bandwidthSelectList(): { text: string; value: BandWidth }[] {
      // クラウド帯域値
      const limit1 = this.$filter.bandwidthToBps(this.cloudLineMaxBandwidth);
      // 1G超VNコネクト
      const limit2 = this.$filter.bandwidthToBps({
        value: this.vnConnects1G!.upperLimit,
        unit: "GBPS",
      });
      return this.bandwidthOptions.filter((v) => {
        const bps = this.$filter.bandwidthToBps(v.value)!;
        return bps <= limit1! && bps <= limit2!;
      });
    },
    /** Maximum Prefixの選択肢 */
    maximumPrefixSelectList(): { text: string; value: number }[] {
      if (this.isAdd) {
        return [{ value: 100, text: "100" }];
      } else {
        return [
          { value: 100, text: "100" },
          { value: 200, text: "200" },
          { value: 300, text: "300" },
          { value: 400, text: "400" },
          { value: 500, text: "500" },
          { value: 1000, text: "1000" },
          { value: 1500, text: "1500" },
          { value: 2000, text: "2000" },
        ].filter((v) => v.value <= this.maximumPrefixLimit!.upperLimit);
      }
    },
    vpnVnName(): string {
      if (
        this.cloudVnIbmDetail.cloudVnConnectCommon.vpnVnCode.startsWith("D")
      ) {
        return (
          this.cloudVnIbmDetail.cloudVnConnectCommon.vnName +
          ":" +
          this.cloudVnIbmDetail.cloudVnConnectCommon.vpnVnCode +
          "(L3)"
        );
      } else {
        return this.cloudVnIbmDetail.cloudVnConnectCommon.vpnVnCode;
      }
    },
    /** true: Sbyに関する項目は非活性にする */
    isDisableRoutingSby(): boolean {
      return !(
        (this.cloudVnIbmDetail && this.cloudVnIbmDetail.cloudVnConnectSby) ||
        this.form.cloudVnConnectSby?.vnConnectName
      );
    },
    /** true: Sbyに関する項目はValidation必要 */
    isRequireRoutingSby(): boolean {
      // Sby情報はdisabledの場合、false
      return !this.isDisableRoutingSby;
    },
    /** true: Act WanAddressが入力不可 */
    disableInputActWanIp(): boolean {
      return !!(
        this.cloudVnIbmDetail &&
        this.cloudVnIbmDetail.cloudVnConnectAct.wanAddress
      );
    },
    /** true: Act PeeringAddressが入力不可 */
    disableInputActPeeringIp(): boolean {
      return !!(
        this.cloudVnIbmDetail &&
        this.cloudVnIbmDetail.cloudVnConnectAct.peerIpAddress
      );
    },
    /** true: Sby WanAddressが入力不可 */
    disableInputSbyWanIp(): boolean {
      return !!(
        this.cloudVnIbmDetail.cloudVnConnectSby &&
        this.cloudVnIbmDetail.cloudVnConnectSby.wanAddress
      );
    },
    /** true: Sby PeeringAddressが入力不可 */
    disableInputSbyPeeringIp(): boolean {
      return !!(
        this.cloudVnIbmDetail.cloudVnConnectSby &&
        this.cloudVnIbmDetail.cloudVnConnectSby.peerIpAddress
      );
    },
    /** true:回線詳細にSby情報が登録済みの場合 */
    hasSby(): boolean {
      return !!(
        this.cloudVnIbmDetail && this.cloudVnIbmDetail.cloudVnConnectSby
      );
    },
    /** 経路集約アドレスの重複チェック用リスト作成 */
    routingAddressList() {
      return (index: number) => {
        return this.form.cloudVnConnectCommon!.routeAggregationAddressList
          ? this.form.cloudVnConnectCommon!.routeAggregationAddressList.filter(
              (_, num) => num !== index
            )
          : [];
      };
    },
  },
  watch: {
    /** 経路集約がCUSTOMに変更されて初期値が存在しない場合は初期化 */
    "form.cloudVnConnectCommon.routeAggregation"(newValue: RouteAggregation) {
      if (
        newValue === "CUSTOM" &&
        (!this.form.cloudVnConnectCommon!.routeAggregationAddressList ||
          this.form.cloudVnConnectCommon!.routeAggregationAddressList.length ===
            0)
      ) {
        this.form.cloudVnConnectCommon!.routeAggregationAddressList = [""];
      }
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      // 回線情報に紐づくクラウド回線情報を取得APIを呼び出す
      const lines = (await this.$api.cloudLine.getCloudLines()).lineList;
      const cloudLines = lines.flatMap((v) => v.cloudLineList);
      // クラウド回線の帯域最大値
      this.cloudLineMaxBandwidth = cloudLines.find(
        (v) => v.cloudLineSeq === this.cloudLineSeq
      )!.bandwidth;

      // 1G超VNコネクトの値を取得（品目の上限値制御用）
      this.vnConnects1G = (
        await this.$api.upperLimit.getUpperLimitLine({
          upperLimitLineManageUnit: "MULTI_CLOUD",
          cloudLineSeq: this.cloudLineSeq,
        })
      ).cloudLine!.upperLimitList!.find(
        (v) => v.upperLimitItemName === "1G超VNコネクト"
      )!;

      if (this.isAdd) {
        this.vpnVnCodeList = (await this.$api.information.getVpnVnCodeList())
          .filter((v) => v.vnType === "L3" || v.vnType === null)
          .sortBy(["vpnVnCode", "asc"]);
      } else {
        // maximumPrefixの上限値を取得
        this.maximumPrefixLimit = (
          await this.$api.upperLimit.getUpperLimitLine({
            upperLimitLineManageUnit: "MULTI_CLOUD",
            cloudLineSeq: this.cloudLineSeq,
            wnumber: this.cloudVnIbmDetail!.cloudVnConnectAct.wnumber,
          })
        ).cloudLine!.upperLimitList!.find(
          (v) => v.upperLimitItemName === "BGP4の最大経路数"
        )!;
      }
    },

    /** 経路集約アドレス削除 */
    removeRouteAddress(index: number) {
      const list = this.form.cloudVnConnectCommon!.routeAggregationAddressList!;
      if (list.length > 1) {
        list.splice(index, 1);
      } else {
        this.form.cloudVnConnectCommon!.routeAggregationAddressList = [""];
      }
    },

    /** 経路集約アドレス追加 */
    addRouteAddress() {
      this.form.cloudVnConnectCommon!.routeAggregationAddressList = [
        ...this.form.cloudVnConnectCommon!.routeAggregationAddressList!,
        "",
      ];
    },

    async submit() {
      const form = {
        ...this.form,
        cloudVnConnectCommon: {
          ...this.form.cloudVnConnectCommon,
          routeAggregationAddressList:
            this.form.cloudVnConnectCommon!.routeAggregation === "ON"
              ? []
              : this.form.cloudVnConnectCommon!.routeAggregation === "OFF"
              ? null
              : this.form.cloudVnConnectCommon!.routeAggregationAddressList,
        },
      } as
        | CloudVnIbmPost
        | (CloudVnIbmPut & { cloudVnConnectSby: { asNumber: number } });

      if (this.isAdd) {
        // 追加の場合
        // 確認モーダルの表示
        let displayVnName;
        const vnSbyName = form.cloudVnConnectSby?.vnConnectName;
        (form as CloudVnIbmPost).cloudVnConnectCommon!.vpnVnCode =
          this.selectedVpnVn!.value;
        displayVnName = this.vpnVnCodeList.find(
          (v) =>
            v.vpnVnCode ===
            (form as CloudVnIbmPost).cloudVnConnectCommon!.vpnVnCode
        )!.vnName;
        await this.$modal.show(MultiCloudIbmVnConnectionConfirm, {
          cloudVnIbmDetail: {
            cloudVnConnectCommon: {
              ...form.cloudVnConnectCommon,
              vnName: displayVnName,
              routingType: "BGP4",
            },
            cloudVnConnectAct: {
              ...form.cloudVnConnectAct,
              localPreference: "HIGH_PRIORITY",
              med: "LOW_PRIORITY",
            },
            cloudVnConnectSby: vnSbyName
              ? {
                  ...form.cloudVnConnectSby,
                  localPreference: "LOW_PRIORITY",
                  med: "HIGH_PRIORITY",
                }
              : null,
          },
          displayType: "A",
        });
        await this.$api.cloudVnIbm.postVnConnect(
          this.cloudLineSeq,
          (form.cloudVnConnectSby?.vnConnectName
            ? form
            : { ...form, cloudVnConnectSby: null }) as CloudVnIbmPost
        );
        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      } else {
        if (
          !this.cloudVnIbmDetail.cloudVnConnectSby?.vnConnectName &&
          form.cloudVnConnectSby?.vnConnectName
        ) {
          // Sbyのみ追加の場合
          // 確認モーダルの表示
          await this.$modal.show(MultiCloudIbmVnConnectionConfirm, {
            cloudVnIbmDetail: {
              cloudVnConnectCommon: form.cloudVnConnectCommon,
              cloudVnConnectAct: form.cloudVnConnectAct,
              cloudVnConnectSby: {
                ...form.cloudVnConnectSby,
                localPreference: "LOW_PRIORITY",
                med: "HIGH_PRIORITY",
              },
            },
            displayType: "M",
          });
          await this.$api.cloudVnIbm.postVnConnect(this.cloudLineSeq, {
            cloudVnConnectCommon: null,
            cloudVnConnectAct: null,
            cloudVnConnectSby: {
              ...form.cloudVnConnectSby,
              pairCloudVnConnectSeq:
                this.cloudVnIbmDetail.cloudVnConnectAct.cloudVnConnectSeq,
            },
            cloudVnIbmKeyId: this.cloudVnIbmDetail!.cloudVnIbmKeyId,
          } as CloudVnIbmPost);
        } else {
          // 変更のみの場合
          const vnSbyName = form.cloudVnConnectSby?.vnConnectName;
          await this.$modal.show(MultiCloudIbmVnConnectionConfirm, {
            cloudVnIbmDetail: {
              ...form,
              cloudVnConnectSby: vnSbyName ? form.cloudVnConnectSby : null,
            },
            displayType: "M",
          });
          await this.$api.cloudVnIbm.putVnConnect(
            this.cloudLineSeq,
            this.cloudVnIbmDetail.cloudVnConnectAct.wnumber,
            (this.cloudVnIbmDetail.cloudVnConnectSby?.vnConnectName
              ? form
              : { ...form, cloudVnConnectSby: null }) as CloudVnIbmPut
          );
        }

        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },
  },
});

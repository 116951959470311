















































































































































































































import Vue, { PropType } from "vue";
import {
  CustomServiceIntranet,
  CustomServiceIntranetfw,
} from "@/apis/CustomServiceApi";

export default Vue.extend({
  name: "ServiceIntranetFwEdit",
  props: {
    /**
     * ポリシー種別
     * INTRANET_FW ：イントラネットFW
     * INTRANET_FW_PFGW ：イントラネットFW（PFGW）
     */
    policyType: {
      type: String as PropType<"INTRANET_FW" | "INTRANET_FW_PFGW">,
      required: true,
    },
    /**
     * E番号(ACT)
     * ポリシー種別でINTRANET_FW_PFGWを指定時必須
     */
    enumberAct: {
      type: String as PropType<string>,
      default: undefined,
    },
    customServiceIntranet: {
      type: Object as PropType<CustomServiceIntranet | undefined>,
      default: undefined,
    },
    customServiceKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      form: { protocol: "TCP" } as CustomServiceIntranet,
    };
  },
  async mounted() {
    // 更新であればフォームのデータを設定
    if (this.customServiceIntranet) {
      this.form = { ...this.customServiceIntranet };
    }
  },
  methods: {
    /** サービス設定(イントラネットFW)の追加・変更 */
    async submit() {
      // 確認ダイアログの表示 追加・変更でメッセージを変える
      const action = this.customServiceIntranet ? "変更" : "追加";
      await this.$confirm(`個別サービスを${action}します。よろしいですか？`);

      // プロトコルの値により使用しないプロパティのクリアを行う
      this.clearValue();

      // リクエストデータの設定
      const reqData: CustomServiceIntranetfw = {
        serviceName: this.form.serviceName,
        protocol: this.form.protocol,
        portNumber: this.form.portNumber,
        protocolNumber: this.form.protocolNumber,
        icmpType: this.form.icmpType,
        icmpCode: this.form.icmpCode,
        description: this.form.description || null,
        customServiceKeyId: this.customServiceKeyId,
        note: null,
      };

      // サービス設定(イントラネットFW)の追加・変更
      if (this.customServiceIntranet) {
        await this.$api.customServiceApi.putIntranetfwCustomService(
          this.policyType,
          this.enumberAct,
          this.form.customServiceSeq,
          reqData
        );
      } else {
        await this.$api.customServiceApi.postIntranetfwCustomService(
          this.policyType,
          this.enumberAct,
          reqData
        );
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** プロトコルの値により不要なプロパティの値をnullにする */
    clearValue() {
      if (this.form.protocol === "IP") {
        this.form.portNumber = null;
        this.form.icmpType = null;
        this.form.icmpCode = null;
      } else if (this.form.protocol === "ICMP") {
        this.form.portNumber = null;
        this.form.protocolNumber = null;
      } else {
        this.form.protocolNumber = null;
        this.form.icmpType = null;
        this.form.icmpCode = null;
      }
    },
  },
});

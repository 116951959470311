























































































import Vue, { PropType } from "vue";
import { SiteListEntity } from "@/views/site/SiteList.vue";
import SiteEdit from "@/modals/site/SiteEdit.vue";
import { AppTableData } from "@/components/AppTable.vue";
import { VNConnect } from "@/apis/VNConnectApi";
import {
  Type1PrivateSiteDelete,
  Type1PrivateSiteDeleteLine,
} from "@/apis/Type1SiteApi";
import SiteFlowListModify from "@/modals/site/SiteFlowListModify.vue";
import { FlowResponse } from "@/apis/VirtualNetworkApi";

export type FlowEntity = FlowResponse["flowList"][0];

export default Vue.extend({
  name: "SiteReference",
  props: {
    siteData: {
      type: Object as PropType<SiteListEntity>,
      required: true,
    },
    type1SiteZoneKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      flow: {
        fields: [
          {
            key: "destWnumVpnCode",
            formatter: (value: string, key: null, item: FlowEntity) => {
              if (item.wnumber) {
                return `${item.vnConnectName}(${item.wnumber})`;
              } else {
                return `L3VPN(${item.vpnCode})`;
              }
            },
          },
          {
            key: "srcAddress",
            label: "送信元アドレス",
            formatter: (value: string) => this.$filter.emptyTo(value, "any"),
          },
          {
            key: "dstAddress",
            label: "宛先アドレス",
            formatter: (value: string) => this.$filter.emptyTo(value, "any"),
          },
          {
            key: "description",
            label: "備考",
            tdClass: "text-pre-wrap",
            formatter: (value: string) => this.$filter.emptyTo(value, "-"),
          },
          {
            key: "flowStatus",
            label: "有効/無効",
            formatter: (value: string) =>
              this.$filter.enumTo(value, "flowStatusItems"),
          },
          {
            key: "ifStatus",
            label: "IF状態",
            formatter: (value: string) =>
              this.$filter.enumTo(value, "cloudVn_ifStatus"),
          },
        ],
        items: [],
      } as Pick<AppTableData<FlowEntity>, "fields" | "items">,
      flowKeyId: "",
      vnConnectList: [] as VNConnect[],
    };
  },
  computed: {
    /** true: フロー表示, false: 非表示 */
    isShowFlow(): boolean {
      // 特定の回線種別 + VNコネクトが存在する + VPN/VNがL3/L3VPNのみでL2が存在しない
      switch (this.siteData.accessType) {
        case "ETHERNET_L2":
        case "ETHERNET_L3":
        case "BROADBAND_ACCESS2_L2":
        case "BROADBAND_ACCESS2_L3":
        case "WIRELESS_ACCESS_L2":
        case "WIRELESS_ACCESS_L3":
        case "EXTEND_ETHERNET":
        case "IPSEC":
        case "PLATFORM_GATEWAY":
        case "PF_ACCESS_GW":
          break;
        default:
          return false;
      }

      const siteVnConnects = this.vnConnectList.filter(
        (e) =>
          e.siteName === this.siteData.siteName &&
          e.vlanIdType === this.siteData.vlanIdType &&
          e.vlanId === this.siteData.vlanId
      );
      return (
        siteVnConnects.some((e) => e.vnType !== "L2") &&
        !siteVnConnects.some((e) => e.vnType === "L2")
      );
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.vnConnectList = (
        await this.$api.vNConnect.getVNConnects()
      ).vnConnectList;

      if (this.isShowFlow) {
        // TODO: 404が返ってくる？？ハンドリングは出来るがflowKeyIdが取得出来ないと変更できない
        const flowData = await this.$api.virtualNetwork.getFlowSettings({
          enumber: this.siteData.enumber,
          vlanIdType: this.siteData.vlanIdType,
          vlanId: this.siteData.vlanId,
        });
        this.flow.items = flowData.flowList;
        this.flowKeyId = flowData.flowKeyId;
      }
    },

    /** フロー変更 */
    async modifyFlow() {
      await this.$modal.show(SiteFlowListModify, {
        siteData: this.siteData,
        flowData: this.flow.items,
        flowKeyId: this.flowKeyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** サイト変更 */
    async modifySiteInfo() {
      await this.$modal.show(SiteEdit, {
        siteData: this.siteData,
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** サイト削除 */
    async deleteSiteInfo() {
      /** 確認ダイアログの表示 */
      await this.$confirm(`サイトを削除します。よろしいですか？`);

      /** APIリクエストデータのマッピング処理 */
      const lineList = [] as Type1PrivateSiteDeleteLine[];
      if (lineList.find((i) => i.enumber === this.siteData.enumber)) {
        const idx = lineList.findIndex(
          (i) => i.enumber === this.siteData.enumber
        );
        lineList[idx].siteIdList.push(this.siteData.siteId);
      } else {
        lineList.push({
          enumber: this.siteData.enumber,
          siteIdList: [this.siteData.siteId],
        });
      }
      const deletedData: Type1PrivateSiteDelete = {
        lineList: lineList,
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      };
      /** APIによるサイト削除処理 */
      await this.$api.type1SiteZone.postType1PrivateSiteDelete(deletedData);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

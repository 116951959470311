

















































































































import Vue, { PropType } from "vue";
import {
  AcLinePktFilterEntity,
  PacketFilterEntity,
  VnConnectPktFilterEntity,
  CloudPktFilterEntity,
} from "@/apis/PacketFilteringApi";
import PacketFilteringEdit from "@/modals/packetFiltering/PacketFilteringEdit.vue";

export default Vue.extend({
  name: "PacketFilteringReference",
  props: {
    /** パケットフィルタリング設定情報 */
    packetFilteringItem: {
      type: Array as PropType<PacketFilterEntity[]>,
      required: true,
    },
    /** 選択行のVNコネクト情報 */
    clickRowVnConItem: {
      type: Object as PropType<VnConnectPktFilterEntity>,
      required: false,
    },
    /** 選択行のアクセス回線情報 */
    clickRowAcLineItem: {
      type: Object as PropType<AcLinePktFilterEntity>,
      required: false,
    },
    /** 選択行クラウドVNコネクト情報 */
    clickRowCloudVnConItem: {
      type: Object as PropType<CloudPktFilterEntity>,
      required: false,
    },
    packetFilterKeyId: {
      type: String,
      required: true,
    },
    /** クラウド向けパケットフィルタリング設定情報一覧ダウンロードURL */
    downloadUrl: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      packetFields: [
        {
          key: "protocol",
          label: "プロトコル",
        },
        {
          key: "action",
          label: "アクション",
        },
        {
          key: "srcAddress",
          label: "送信元アドレス",
        },
        {
          key: "srcPort",
          label: "送信元ポート",
        },
        {
          key: "dstAddress",
          label: "宛先アドレス",
        },
        {
          key: "dstPort",
          label: "宛先ポート",
        },
        {
          key: "filteringStatus",
          label: "フィルタリング有効/無効",
        },
        {
          key: "description",
          label: "備考",
          tdClass: "text-pre-wrap",
        },
      ],
    };
  },
  methods: {
    /** パケットフィルタ設定(VNコネクト)を表示 */
    async modifyVnConPackets() {
      await this.$modal.show(PacketFilteringEdit, {
        packets: this.packetFilteringItem,
        wnumberAct: this.clickRowVnConItem.wnumber,
        vnConnectNameAct: this.clickRowVnConItem.vnConnectName,
        menu: this.clickRowVnConItem.menu,
        vnConnectPacketFilterKeyId: this.packetFilterKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** パケットフィルタ設定(アクセス回線)を表示 */
    async modifyAcLinePackets() {
      await this.$modal.show(PacketFilteringEdit, {
        packets: this.packetFilteringItem,
        enumber: this.clickRowAcLineItem.enumber,
        menu: this.clickRowAcLineItem.menu,
        accessLinePacketFilterKeyId: this.packetFilterKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** クラウド向けパケットフィルタ設定を表示 */
    async modifyCloudPktRules() {
      await this.$modal.show(PacketFilteringEdit, {
        packets: this.packetFilteringItem,
        wnumberAct: this.clickRowCloudVnConItem!.wnumberAct,
        vnConnectNameAct: this.clickRowCloudVnConItem!.vnConnectNameAct,
        wnumberSby: this.clickRowCloudVnConItem!.wnumberSby,
        vnConnectNameSby: this.clickRowCloudVnConItem!.vnConnectNameSby,
        menu: "MULTI_CLOUD_GW",
        vnConnectPacketFilterKeyId: this.packetFilterKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

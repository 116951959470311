import AbstractBaseApi from "@/apis/AbstractBaseApi";

export interface Category {
  /** 個別カテゴリ名 */
  categoryName: string;
  /** 動作種別 ALLOW : allow ALERT : alert BLOCK : block CONTINUE : continue */
  actionType: "ALLOW" | "ALERT" | "BLOCK" | "CONTINUE";
}

/** UrlProfileの情報 */
export interface UrlProfile {
  /** URLフィルタリングのプロファイル名 */
  profileUrlName: string;
  /** ブラックリスト. ユーザの登録順にソート 0件の場合は空配列 */
  blackList: string[];
  /** ブラックリストの動作. ALERT : alert BLOCK : block CONTINUE : continue */
  blackListAction: "ALERT" | "BLOCK" | "CONTINUE";
  /** ホワイトリスト. ユーザの登録順にソート 0件の場合は空配列 */
  whiteList: string[];
  /** Urlプロファイルのカテゴリ情報
   * 個別カテゴリリスト
   * 登録時にはすべての個別カテゴリを指定すること。
   */
  categoryList: Category[];
  /** 設定値の説明 */
  description: string | null;
  /** ポリシー使用フラグ. ポリシー使用有「true」、ポリシー使用無「false」 */
  isPolicyInUse: boolean;
  /** URLフィルタリングのプロファイル設定排他情報. 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  profileUrlKeyId: string;
}

/** UrlProfileの設定/更新時の情報 */
export type SetUrlProfile = Omit<UrlProfile, "isPolicyInUse"> &
  Pick<DeleteUrlProfile, "note">;

/** UrlProfileの削除情報 */
export interface DeleteUrlProfile {
  /** URLフィルタリングのプロファイルSEQリスト */
  profileUrlSeqList: string[];
  /** URLフィルタリングのプロファイル設定排他情報. 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  profileUrlKeyId: string;
  /** 操作履歴の備考  */
  note: string | null;
}

export default class UrlProfileApi extends AbstractBaseApi {
  /**
   * プロファイル（URLフィルタリング）のカテゴリ情報の一覧を取得
   */
  async getCategories(): Promise<Pick<UrlProfile, "categoryList">> {
    return (
      await this.api.get("/v1/network/policy/profile/urlfiltering/categories")
    ).data;
  }

  /**
   * Type1プロファイル（URLフィルタリング）の詳細情報を取得
   * @param profileUrlSeq プロファイルSeq
   */
  async getType1UrlFiltering(profileUrlSeq: string): Promise<UrlProfile> {
    return (
      await this.api.get(
        `/v1/network/policy/profile/urlfiltering/type1/${profileUrlSeq}`
      )
    ).data;
  }

  /**
   * Type4プロファイル（URLフィルタリング）の詳細情報を取得
   * @param type4Id type4Id
   * @param profileUrlSeq プロファイルSeq
   */
  async getType4UrlFiltering(
    type4Id: string,
    profileUrlSeq: string
  ): Promise<UrlProfile> {
    return (
      await this.api.get(
        `/v1/network/policy/profile/urlfiltering/type4/${type4Id}/${profileUrlSeq}`
      )
    ).data;
  }

  /**
   * Type1プロファイル（URLフィルタリング）情報を変更
   * @param profileUrlSeq プロファイルSeq
   * @param data プロファイル（URLフィルタリング）設定情報
   */
  async putType1UrlFiltering(
    profileUrlSeq: string,
    data: SetUrlProfile
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/profile/urlfiltering/type1/${profileUrlSeq}`,
      data
    );
  }

  /**
   * Type4プロファイル（URLフィルタリング）情報を変更
   * @param type4Id type4Id
   * @param profileUrlSeq プロファイルSeq
   * @param data プロファイル（URLフィルタリング）設定情報
   */
  async putType4UrlFiltering(
    type4Id: string,
    profileUrlSeq: string,
    data: SetUrlProfile
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/profile/urlfiltering/type4/${type4Id}/${profileUrlSeq}`,
      data
    );
  }

  /**
   * Type1 プロファイル（URLフィルタリング）情報を登録
   * @param data プロファイル（URLフィルタリング）設定情報
   */
  async postType1UrlFiltering(data: SetUrlProfile): Promise<void> {
    await this.api.post(`/v1/network/policy/profile/urlfiltering/type1`, data);
  }

  /**
   * Type4 プロファイル（URLフィルタリング）情報を登録
   * @param type4Id type4Id
   * @param data プロファイル（URLフィルタリング）設定情報
   */
  async postType4UrlFiltering(
    type4Id: string,
    data: SetUrlProfile
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/profile/urlfiltering/type4/${type4Id}`,
      data
    );
  }

  /**
   * Type1プロファイル(URLフィルタリング)情報を削除
   * @param data 削除情報
   */
  async deleteType1UrlFiltering(data: DeleteUrlProfile): Promise<void> {
    await this.api.post(
      `/v1/network/policy/profiles/urlfiltering/type1/delete`,
      data
    );
  }

  /**
   * Type4プロファイル(URLフィルタリング)情報を削除
   * @param type4Id
   * @param data
   */
  async deleteType4UrlFiltering(
    type4Id: string,
    data: DeleteUrlProfile
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/profiles/urlfiltering/type4/${type4Id}/delete`,
      data
    );
  }
}

import { ApplianceInfo } from "@/apis/ContractApi";
import {
  CloudVnConnectActSby,
  CloudVnMsList,
  CloudVnMsPost,
} from "@/apis/CloudVnMsApi";
import { ObjectOf } from "@/shims-vue";
import { CommandInfo } from "@/apis/NwOperationToolApi";
import { Type4Service } from "@/apis/ContractChangeType4Api";
import { AlarmInfoEntity } from "@/apis/ThresholdSettingApi";
import { CloudQosInfo } from "@/apis/CloudQosApi";
import { QosBandwidth, QosInfo } from "@/apis/QosApi";
import { CommonPolicy } from "@/apis/NwPolicyApi";
import { Category } from "@/apis/UrlProfileApi";
import { BandwidthListGetEntity } from "@/apis/AccessLineApi";
import { OperationListEntity } from "@/apis/OperationHistoryApi";
import { HubSpokeInfo } from "@/apis/HubAndSpokeApi";
import { ZoneType } from "@/apis/ZoneRelationApi";
import { CreateApplianceLog } from "@/apis/ApplianceLogApi";
import { ExtranetInfo, PutExtranetInfo } from "@/apis/ExtranetApi";
import { Bgp4Peer, VNL2L3Connect } from "@/apis/VNL2L3ConnectApi";
import {
  AcLinePktFilterEntity,
  PacketFilterEntity,
  VnConnectPktFilterEntity,
} from "@/apis/PacketFilteringApi";
import { UserEntity } from "@/apis/UserApi";
import { FlowResponse } from "@/apis/VirtualNetworkApi";
import { RemoteAccessGw } from "@/apis/IntranetfwServiceMenuApi";
import { ContractListEntity } from "@/apis/ContractLineApi";
import { ApplianceType, DisplayRange, LineElement } from "@/apis/TrafficApi";
import { QoS, VNConnectDetail } from "@/apis/VNConnectApi";
import { InternetLineEntity } from "@/apis/UpperLimitApi";
import { CpaIdFile } from "@/apis/CpaIdApi";
import {
  CpaContract,
  RegistrationResult,
  CpaNwDetail,
  RadiusSetting,
} from "@/apis/Cpa5gSaApi";
import { CloudVnAwsCommon } from "@/apis/CloudVnAwsApi";
import { AgreementInfoList } from "@/apis/AgreementApi";
import { ContractGetPacketFiltering } from "@/apis/PacketFilteringApi";

/** 変換定義の一覧 */
const enumList: {
  qos_band_control_pattern: ObjectOf<
    CloudVnMsPost["cloudVnConnectCommon"]["qosBandControlPattern"]
  >;
  location: ObjectOf<CloudVnMsList["location"]>;
  cloudVn_accessType: ObjectOf<CloudVnMsList["accessType"]>;
  cloudVn_keyStatus: ObjectOf<CloudVnMsList["keyStatus"]>;
  cloudVn_ifStatus: ObjectOf<CloudVnConnectActSby["ifStatus"]>;
  localPreference: ObjectOf<CloudVnConnectActSby["localPreference"]>;
  med: ObjectOf<CloudVnConnectActSby["med"]>;
  bandwidth_unit: ObjectOf<CloudVnMsList["bandwidth"]["unit"]>;
  commandInfo_commandType: ObjectOf<CommandInfo["commandType"]>;
  commandInfo_commandType_detail: ObjectOf<CommandInfo["commandType"]>;
  commandInfo_commandStatus: ObjectOf<CommandInfo["commandStatus"]>;
  enable: { true: string; false: string };
  internetType: ObjectOf<ApplianceInfo["internetType"]>;
  mainSite: ObjectOf<ApplianceInfo["mainSite"]>;
  on_off: { true: string; false: string };
  policyType: ObjectOf<Type4Service["policyType"]>;
  status: ObjectOf<Type4Service["status"]>;
  isPolicyInUse: { true: string; false: string };
  qosControl: ObjectOf<CloudQosInfo["qosControl"]>;
  qosType: ObjectOf<CloudQosInfo["qosType"]>;
  cloudQos_PriorityTarget: ObjectOf<CloudQosInfo["priorityTarget"]>;
  qos_PriorityTarget: ObjectOf<QosInfo["priorityTarget"]>;
  trafficThreshold_direction: ObjectOf<AlarmInfoEntity["direction"]>;
  trafficThreshold_alarmType: ObjectOf<AlarmInfoEntity["alarmType"]>;
  qos_PriorityType: ObjectOf<QosBandwidth["priorityType1"]>;
  allow_deny: ObjectOf<CommonPolicy["actionType"]>;
  actionType: ObjectOf<Category["actionType"]>;
  actionTypeVariant: ObjectOf<Category["actionType"]>;
  accessLineMenu: ObjectOf<BandwidthListGetEntity["menu"]>;
  accessLineBandwidthType: ObjectOf<BandwidthListGetEntity["bandwidthType"]>;
  operationOrderStatus: ObjectOf<OperationListEntity["orderStatus"]>;
  hubSpokeStatus: ObjectOf<HubSpokeInfo["hubSpokeStatus"]>;
  zoneType: ObjectOf<ZoneType>;
  applianceType: ObjectOf<CreateApplianceLog["applianceType"]>;
  approvalState: ObjectOf<ExtranetInfo["approvalState"]>;
  approvalRequest: ObjectOf<PutExtranetInfo["approvalRequest"]>;
  routingType: ObjectOf<VNL2L3Connect["routingType"]>;
  vlanIdType: Omit<
    ObjectOf<VnConnectPktFilterEntity["vlanIdType"]>,
    "SPECIFIED"
  >;
  filterringSettingStatus: ObjectOf<
    VnConnectPktFilterEntity["filterringSettingStatus"]
  >;
  protocol: ObjectOf<PacketFilterEntity["protocol"]>;
  action: ObjectOf<PacketFilterEntity["action"]>;
  filteringStatus: ObjectOf<PacketFilterEntity["filteringStatus"]>;
  vnConPktFilterMenu: ObjectOf<VnConnectPktFilterEntity["menu"]>;
  acLinePktFilterMenu: ObjectOf<AcLinePktFilterEntity["menu"]>;
  operationType: ObjectOf<UserEntity["operationType"]>;
  remoteAccessGw: ObjectOf<RemoteAccessGw["menu"]>;
  contractQos: ObjectOf<ContractListEntity["qos"]>;
  contractLineAccessType: ObjectOf<ContractListEntity["accessType"]>;
  contractLineIsSecurityOption: ObjectOf<
    ContractListEntity["isSecurityOption"]
  >;
  displayRange: ObjectOf<DisplayRange>;
  trafficInternetType: ObjectOf<LineElement["internetType"]>;
  vnConBandwidthType: ObjectOf<VNConnectDetail["bandwidthType"]>;
  vnConControlPattern: ObjectOf<QoS["qosBandControlPattern"]>;
  flowStatusItems: ObjectOf<FlowResponse["flowList"][0]["flowStatus"]>;
  upperLimit_applianceType: ObjectOf<InternetLineEntity["applianceType"]>;
  peerName: ObjectOf<Bgp4Peer["peerName"]>;
  trafficApplianceType: ObjectOf<ApplianceType>;
  cpaContractType: ObjectOf<CpaContract["contractType"]>;
  cpaAuthenticationType: ObjectOf<CpaContract["authenticationType"]>;
  cpaAddressAssignMethod: ObjectOf<CpaContract["addressAssignmentMethod"]>;
  cpaContractStatus: ObjectOf<CpaContract["status"]>;
  cpaRedundancy: ObjectOf<CpaContract["redundancy"]>;
  cpaBandwidthType: ObjectOf<CpaContract["bandwidthType"]>;
  cpaIpAddressType: ObjectOf<CpaContract["ipAddressType"]>;
  cpaIdFileType: ObjectOf<CpaIdFile["fileType"]>;
  cpaIdAuthorityType: ObjectOf<CpaIdFile["authorityType"]>;
  cpaIdSendType: ObjectOf<RegistrationResult["sendType"]>;
  cpaIdStatus: ObjectOf<RegistrationResult["status"]>;
  cpaSettingFlg: ObjectOf<CpaNwDetail["isWrapCommunication"]>;
  cpaUdpPort: ObjectOf<RadiusSetting["udpPort"]>;
  cpaCallingStation: ObjectOf<RadiusSetting["callingStationIdTransfer"]>;
  cpaImeiTransfer: ObjectOf<RadiusSetting["isImeiTransfer"]>;
  cpaIdFileStatus: ObjectOf<CpaIdFile["status"]>;
  awsConnectType: ObjectOf<CloudVnAwsCommon["directConnectType"]>;
  agreementStatusItems: ObjectOf<AgreementInfoList["agreementStatus"]>;
  contractStatus: ObjectOf<ContractGetPacketFiltering["packetFilterEnabled"]>;
  contractChangeStatus: ObjectOf<
    ContractGetPacketFiltering["packetFilterEnabled"]
  >;
} = {
  qos_band_control_pattern: {
    PATTERN_A: "パターンA",
    PATTERN_B: "パターンB",
  },
  location: {
    EAST_JAPAN: "東日本",
    WEST_JAPAN: "西日本",
  },
  cloudVn_accessType: {
    MULTI_CLOUD_GW: "マルチクラウドGW",
  },
  cloudVn_keyStatus: {
    UNEXECUTED: "空白",
    ACQUIRING: "取得中",
    SUCCESS: "取得済み",
    FAILED: "取得失敗",
  },
  cloudVn_ifStatus: {
    NO_SHUTDOWN: "no shutdown",
    SHUTDOWN: "shutdown",
  },
  localPreference: {
    HIGH_PRIORITY: "優先(200)",
    LOW_PRIORITY: "OFF(100)",
  },
  med: {
    HIGH_PRIORITY: "非優先(100)",
    LOW_PRIORITY: "OFF(0)",
  },
  bandwidth_unit: {
    KBPS: "Kbps",
    MBPS: "Mbps",
    GBPS: "Gbps",
  },
  enable: {
    true: "有効",
    false: "無効",
  },
  commandInfo_commandType: {
    PING: "ping",
    TRACEROUTE: "traceroute",
    CLOUD: "クラウド回線情報取得",
    ROUTE: "経路情報参照",
    BGP: "BGPネイバー参照",
    ARP: "ARP参照",
    MAC: "MACテーブル参照",
    OSPF: "OSPFネイバー参照",
    GATEWAY: "ゲートウェイ経路情報参照",
    SA: "SA状態参照",
  },
  commandInfo_commandType_detail: {
    PING: "ping",
    TRACEROUTE: "traceroute",
    CLOUD: "show_cloud_line_status",
    ROUTE: "show_route_table",
    BGP: "show_bgp_neighbor",
    ARP: "show_arp",
    MAC: "show_vpls_mac-table",
    OSPF: "show_ospf",
    GATEWAY: "show_ip_route",
    SA: "show_crypto",
  },
  commandInfo_commandStatus: {
    EXECUTION: "実行中",
    SUCCESS: "完了",
    FAILURE: "失敗",
  },
  internetType: {
    TYPE1: "Type1",
    TYPE4: "Type4",
    INTRANET: "イントラネットFW",
  },
  mainSite: {
    TOKYO: "東京",
    OSAKA: "大阪",
  },
  on_off: {
    true: "ON",
    false: "OFF",
  },
  policyType: {
    ALLOW_SPECIFIC_ONE_WAY: "編集可能デフォルトポリシー片方向 (特定サービス)",
    ALLOW_ALL_ONE_WAY: "編集可能デフォルトポリシー片方向 (any)",
    ALLOW_ALL_TWO_WAY: "編集可能デフォルトポリシー双方向 (any)",
  },
  status: {
    UNCONNECTED: "未開通",
    ACTIVE: "稼働中",
    INACTIVE: "停止",
  },
  isPolicyInUse: {
    true: "有り",
    false: "無し",
  },
  qosControl: {
    ENABLE: "可",
    DISABLE: "不可",
  },
  qosType: {
    ABSOLUTE: "絶対優先方式",
    BANDWIDTH: "帯域制御方式",
    NONE: "-",
  },
  cloudQos_PriorityTarget: {
    IPPRECEDENCE_DSCP: "IP Precedence/DSCP",
    DSCP: "DSCP",
    NONE: "-",
  },
  qos_PriorityTarget: {
    IPPRECEDENCE: "IP Precedence",
    COS: "CoS",
    NONE: "-",
  },
  trafficThreshold_direction: {
    SEND: "送信",
    RECEIVE: "受信",
  },
  trafficThreshold_alarmType: {
    EXCESS: "超過",
    RESTORATION: "復旧",
  },
  qos_PriorityType: {
    VALUE_0: "0",
    VALUE_1: "1",
    VALUE_2: "2",
    VALUE_3: "3",
    VALUE_4TO7: "4～7",
  },
  allow_deny: {
    ALLOW: "Allow",
    DENY: "Deny",
  },
  actionType: {
    ALLOW: "Allow",
    ALERT: "Alert",
    BLOCK: "Block",
    CONTINUE: "Continue",
  },
  actionTypeVariant: {
    ALLOW: "primary",
    ALERT: "warning",
    BLOCK: "danger",
    CONTINUE: "success",
  },
  accessLineMenu: {
    PF_ACCESS_GW: "PFアクセスGW",
    EXTEND_ETHERNET: "エクステンドイーサ",
    ETHERNET2: "イーサネット方式Ⅱ",
    ETHERNET2_PF: "イーサネット方式Ⅱ PF",
    BROADBAND_ACCESS3: "ブロードバンドアクセス方式Ⅲ",
    ETHERNET_LIGHT: "イーサネットライト",
    WIRELESS_ACCESS2: "ワイヤレスアクセス方式Ⅱ",
    PF_ACCESS_GW2: "PFアクセスGWⅡ",
    MULTI_CLOUD_GW: "マルチクラウドGW",
  },
  accessLineBandwidthType: {
    BANDWIDTH_SECURED: "帯域確保",
    TRAFFICFREE: "トラフィックフリー",
    BURST10: "バースト",
    BURST100: "バースト",
    BESTEFFORT: "ベストエフォート",
  },
  operationOrderStatus: {
    SUCCESS: "成功",
    FAILURE: "失敗",
    RESERVATION: "予約中",
    SETTING: "設定中",
    ACCEPTED: "受付完了、処理待ち",
  },
  hubSpokeStatus: {
    REQUEST: "承認待ち",
    COMPLETE: "完了",
    DISCONNECTED: "切断",
    PROVISIONING_FAILURE: "失敗",
  },
  zoneType: {
    INTERNET: "グローバルゾーン",
    EXTRA: "エクストラゾーン",
    PRIVATE: "プライベートゾーン",
  },
  applianceType: {
    UTM: "UTM",
    INTERNET_FW: "インターネットFW",
    INTRANET_FW: "イントラネットFW",
  },
  trafficApplianceType: {
    UTM: "UTM",
    INTERNET_FW: "インターネットFW",
    INTRANET_FW: "イントラネットFW",
    NAT: "NAT",
  },
  approvalState: {
    APPLIED: "申請中",
    COMPLETE: "完了",
    EXPIRED: "有効期限切れ",
    DROP: "取り下げ",
    REJECTED: "却下",
    DISCONNECT: "切断",
    FAILED: "失敗",
  },
  approvalRequest: {
    APPROVE: "承認",
    DROP: "取り下げ",
    REJECTED: "却下",
    DISCONNECT: "切断",
  },
  routingType: {
    BGP4: "BGP4",
    STATIC: "Static",
    OSPF: "OSPF",
  },
  operationType: {
    ADMIN: "法人管理者",
    SETTING: "設定権限",
    VIEWING: "閲覧権限",
  },
  vlanIdType: {
    UNTAG: "Untag",
    OTHER: "Other",
  },
  filterringSettingStatus: {
    FILTERING: "有り",
    NO_FILTERING: "無し",
  },
  protocol: {
    TCP: "TCP",
    UDP: "UDP",
    ANY: "any",
  },
  action: {
    PERMIT: "Permit",
    DENY: "Deny",
  },
  filteringStatus: {
    ENABLED: "有効",
    DISABLED: "無効",
  },
  vnConPktFilterMenu: {
    ETHERNET2: "イーサネット方式Ⅱ",
    ETHERNET2_PF: "イーサネット方式Ⅱ PF",
    BROADBAND_ACCESS3: "ブロードバンドアクセス方式Ⅲ",
    ETHERNET_LIGHT: "イーサネットライト",
    WIRELESS_ACCESS2: "ワイヤレスアクセス方式Ⅱ",
    PF_ACCESS_GW2: "PFアクセスGWⅡ",
  },
  acLinePktFilterMenu: {
    ETHERNET: "イーサネット方式",
    BROADBAND_ACCESS2: "ブロードバンドアクセス方式Ⅱ",
    WIRELESS_ACCESS: "ワイヤレスアクセス方式",
    EXTEND_ETHERNET: "エクステンドイーサ方式",
    IPSEC: "IPsec方式",
    PLATFORM_GATEWAY: "PFGW",
    REMORE_ACCESS_GW: "リモートアクセスGW",
  },
  remoteAccessGw: {
    REMOTE_ACCESS_GW2_FRE: "FRE",
    REMOTE_ACCESS_GW2_CRG: "CRG",
    REMOTE_ACCESS_GW2_CPA: "CPA",
  },
  contractQos: {
    ON: "有",
    OFF: "無",
  },
  contractLineAccessType: {
    INTERNET: "インターネット",
    ETHERNET_L2: "イーサネット方式(L2モード)",
    ETHERNET_L3: "イーサネット方式(L3モード)",
    BROADBAND_ACCESS2_L2: "ブロードバンドアクセス方式Ⅱ(L2モード)",
    BROADBAND_ACCESS2_L3: "ブロードバンドアクセス方式Ⅱ(L3モード)",
    WIRELESS_ACCESS_L2: "ワイヤレスアクセス方式(L2モード)",
    WIRELESS_ACCESS_L3: "ワイヤレスアクセス方式(L3モード)",
    REMOTE_ACCESS_GW_FRE: "リモートアクセスGW(FRE)",
    REMOTE_ACCESS_GW_CPA: "リモートアクセスGW(CPA)",
    REMOTE_ACCESS_GW_CRG: "リモートアクセスGW(CRG)",
    PLATFORM_GATEWAY: "PFGW",
    PF_ACCESS_GW: "PFアクセスGW",
    EXTEND_ETHERNET: "エクステンドイーサネット方式",
    IPSEC: "IPsec方式",
    ETHERNET2: "イーサネット方式Ⅱ",
    ETHERNET2_PF: "イーサネット方式Ⅱ PF",
    BROADBAND_ACCESS3: "ブロードバンドアクセス方式Ⅲ",
    WIRELESS_ACCESS2: "ワイヤレスアクセス方式Ⅱ",
    PF_ACCESS_GW2: "PFアクセスGWⅡ",
    VN_L2L3_CONNECT: "VN L2/L3コネクト",
    REMOTE_ACCESS_GW2_FRE: "リモートアクセスGW2(FRE)",
    REMOTE_ACCESS_GW2_CPA: "リモートアクセスGW2(CPA)",
    REMOTE_ACCESS_GW2_CRG: "リモートアクセスGW2(CRG)",
    ETHERNET_LIGHT: "イーサネットライト",
    MULTI_CLOUD_GW: "マルチクラウドGW",
    CPA_5GSA: "CPA 5G SA",
  },
  contractLineIsSecurityOption: {
    true: "有り",
    false: "無し",
  },
  displayRange: {
    DAILY: "日次",
    WEEKLY: "週次",
    MONTHLY: "月次",
    YEARLY: "年次",
  },
  trafficInternetType: {
    TYPE1: "インターネットType1",
    TYPE4: "インターネットType4",
  },
  vnConBandwidthType: {
    BURST: "バースト",
    BANDWIDTH_SECURED: "帯域確保",
    TRAFFICFREE: "トラフィックフリー",
    PF: "PF",
    BESTEFFORT: "ベストエフォート",
  },
  vnConControlPattern: {
    PATTERN_A: "パターンA",
    PATTERN_B: "パターンB",
    PATTERN_C: "パターンC",
    PATTERN_D: "パターンD",
    PATTERN_E: "パターンE",
  },
  flowStatusItems: {
    NOTHING: "-",
    VALID: "有効",
    INVALID: "無効",
  },
  upperLimit_applianceType: {
    PACKET_FILTERING: "パケットフィルタリング",
    INTERNET_FW: "インターネットFW",
    UTM: "UTM",
    INTRANET_FW: "イントラネットFW",
    NAT: "NAT",
  },
  peerName: {
    PEER1: "Peer1",
    PEER2: "Peer2",
  },
  cpaContractType: {
    CPA_NO_LIMIT: "端末速度制限無し",
    CPA_LIMIT_MODULE: "端末速度制限有り(モジュール専用)",
    CPA_LIMIT_AUDIO: "端末速度制限有り(モジュール専用/音声有り)",
  },
  cpaAuthenticationType: {
    RADIUS: "RADIUS認証代行(端末認証あり)",
    RADIUS_IMEI: "RADIUS認証代行(端末認証あり+IMEI認証あり)",
    HOMERADIUS: "HomeRADIUS認証",
    HOME: "端末認証(Home)",
  },
  cpaAddressAssignMethod: {
    FIXED_IP: "固定IP割り当て(ユーザアサイン)",
    DYNAMIC_IP: "動的IP割り当て(キャリアアサイン)",
  },
  cpaContractStatus: {
    ACTIVE: "運用中",
    ACTIVE_UPDATE: "運用中(未反映データあり)",
    NW_SETTING_PREPARATION: "NW設定準備中",
    INACTIVE: "利用開始前",
  },
  cpaRedundancy: {
    EAST_ACT_WEST_SBY: "東日本ACT/西日本SBY",
    WEST_ACT_EAST_SBY: "西日本ACT/東日本SBY",
  },
  cpaBandwidthType: {
    BEST_EFFORT: "ベストエフォート",
    SECURING: "帯域確保",
  },
  cpaIpAddressType: {
    IPV4: "IPv4",
    IPV6: "IPv6",
    IPV4_IPV6: "IPv4 / IPv6",
  },
  cpaIdFileType: {
    ACCOUNT_LIST: "アカウント一覧",
    ACCOUNTING_LOG: "アカウンティングログ",
  },
  cpaIdAuthorityType: {
    CUSTOMER: "法人権限",
    OPERATOR: "代行権限",
  },
  cpaIdSendType: {
    IMMEDIATE: "即時",
    RESERVE: "送信日時指定",
  },
  cpaIdStatus: {
    COMPLETE: "登録完了",
    REGISTER: "登録中",
    FAILURE: "登録エラー",
    RESERVE: "予約中",
    CANCEL: "予約キャンセル",
  },
  cpaSettingFlg: {
    true: "有",
    false: "無",
  },
  cpaUdpPort: {
    PORT_1645_1646: "1645/1646",
    PORT_1812_1813: "1812/1813",
  },
  cpaCallingStation: {
    ARIB: "ARIB転送",
    MSISDN: "MSISDN転送",
  },
  cpaImeiTransfer: {
    true: "IMEI転送あり",
    false: "IMEI転送なし",
  },
  cpaIdFileStatus: {
    EXECUTION: "作成中",
    SUCCESS: "成功",
    FAILURE: "失敗",
    NODATA: "該当データ無し",
  },
  awsConnectType: {
    HOSTED_VIFS: "ホスト型VIF(Private)",
    HOSTED_CONNECTIONS: "ホスト接続",
  },
  agreementStatusItems: {
    AGREED: "同意済み",
    ON_HOLD: "同意保留",
  },
  contractStatus: {
    true: "契約中",
    false: "未契約",
  },
  contractChangeStatus: {
    true: "契約",
    false: "解約",
  },
};

/**
 * APIのenumを変換定義に従って画面表示用の文字列に変換
 * @param value 値
 * @param key enum変換定義の種類
 * @param defaultValue 変換できない場合の表示値
 */
export default function enumTo(
  value: string | number | boolean | null,
  key: keyof typeof enumList,
  defaultValue = "-"
): unknown {
  return (enumList[key] as Record<string, unknown>)[`${value}`] ?? defaultValue;
}

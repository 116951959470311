










import Vue, { PropType } from "vue";

/** b-form-input + 特定の文字列を入力出来ないようにするコンポーネント */
export default Vue.extend({
  name: "BFormInputNumber",
  props: {
    /** true: 0、自然数のみ入力可能 */
    natural: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** true: 小数点入力可能 */
    decimal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  mounted() {
    // Mac Safari用。keydownでpreventDefaultが効かないのでbeforeinputでpreventDefaultする
    (this.$refs.input as Vue).$el.addEventListener(
      "beforeinput",
      (e: Event) => {
        return this.disableInput((e as InputEvent).data, e);
      }
    );
  },
  methods: {
    disableInput(key: string | null, e: Event): boolean {
      if (key) {
        if (key.length > 1) {
          // 1文字以上は特殊キー（Backspaceとか）なので何もしない
          return true;
        }

        if (this.natural) {
          if (!isNaN(parseInt(key))) {
            return true;
          }
        }

        if (this.decimal) {
          if (key === ".") {
            return true;
          }
        }

        e.preventDefault();
        return false;
      } else {
        return true;
      }
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * 入力されたIPアドレスのセグメントが重複しないこと
 * ・正しいIPV6形式であることは別のバリデーションで実施が必要
 * ・CIDR形式のみ受付可能
 * ・サブネットマスクが同じであること
 */
export default {
  params: ["list"],
  validate(
    value: string | undefined | null,
    { list = [] }: { list: string[] | undefined | null }
  ): boolean {
    if (!value || !list) {
      return true;
    }

    for (const strIpv6 of list) {
      try {
        const valueIpv6 = ipaddr.IPv6.parseCIDR(value);
        let targetIpv6;
        // リスト内の値がIPv6形式でない場合、次ループへ
        try {
          targetIpv6 = ipaddr.IPv6.parseCIDR(strIpv6);
        } catch (e) {
          continue;
        }

        // サブネットマスクが異なる場合、当バリデーターを使用しても意味無いので強制falseにしておく
        if (valueIpv6[1] !== targetIpv6[1]) {
          return false;
        }

        const networkValue = valueIpv6[0].parts
          .map((e) => e.toString(2).padStart(16, "0"))
          .join("");
        const targetValue = targetIpv6[0].parts
          .map((e) => e.toString(2).padStart(16, "0"))
          .join("");

        if (networkValue.substring(0, 48) === targetValue.substring(0, 48)) {
          return false;
        }
      } catch (e) {
        return true;
      }
    }
    return true;
  },
} as ValidationRuleSchema;

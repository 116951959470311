import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 追加式フォーム用の配列最大数バリデーション
 * valueは利用せずに、パラメータとしてリストを受け取りその件数が最大数を超えないかのチェックを行う
 * stringかnumberの配列のみ受け入れ可能としている。
 * 設定アップロード対象画面では、上限値を超えた設定が出来てしまうので、抑止のために当バリデーションが必要
 * (設定アップロード対象でない画面の場合には、プラスボタンの表示制御のみで問題はない)
 */
export default {
  params: ["list", "max"],
  validate(
    value: string | number | undefined | null,
    {
      list,
      max,
    }: {
      list: string[] | number[] | null | undefined;
      max: number | undefined | null;
    }
  ): boolean {
    if (!list || !max) {
      return true;
    }
    return list.length <= max;
  },
} as ValidationRuleSchema;













import Vue, { PropType } from "vue";

/** 画面右上に設置されているマニュアルボタンのコンポーネント */
export default Vue.extend({
  name: "ManualButton",
  props: {
    /** 「https://wvs2document.com」以降のマニュアルURLパス */
    path: {
      type: String as PropType<string>,
      required: true,
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 *   IPV6-ACT-SBY構成において、相手方と同じIPアドレスでないことの検証
 *  ・CIDR形式のみ受付可能
 *  ・正しいIPV6形式であることは別のバリデーションで実施が必要
 *  ・同じCIDR値であることは別のバリデーションで実施済みのため、CIDR値も含めた文字列比較とする。
 *  ・比較対象は複数指定可
 */
export default {
  // act_sbyはエラーメッセージ埋め込み文字(ACT or SBY)
  params: ["targetList", "act_sby"],
  validate(
    value: string | undefined | null,
    {
      targetList,
      act_sby,
    }: { targetList: string[] | null | undefined; act_sby: "ACT" | "SBY" }
  ): boolean {
    if (!value || !targetList) {
      return true;
    }
    // valueがIPV6形式でない場合、true
    let valueIpv6: [ipaddr.IPv6, number];
    try {
      valueIpv6 = ipaddr.IPv6.parseCIDR(value);
    } catch (e) {
      return true;
    }

    for (const strIpv6 of targetList) {
      let targetIpv6: [ipaddr.IPv6, number];
      try {
        targetIpv6 = ipaddr.IPv6.parseCIDR(strIpv6);
      } catch (e) {
        //  targetListにIPV4形式で無い文字列が存在した場合、次のループ
        continue;
      }
      // CIDR値を含めた文字列比較をし、一緒のものが存在した場合、false
      if (valueIpv6.toString() === targetIpv6.toString()) {
        return false;
      }
    }
    return true;
  },
} as ValidationRuleSchema;

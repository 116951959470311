import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 前頭「0」が無いことを確認(「0」は許可、「01」「001」「011」は不可)
 * 半角英字、全角文字、記号、半角スペースを入力しないことを確認
 */

export default {
  validate: function (value: string | null | undefined): boolean {
    if (!value) {
      return true;
    }

    return !(value !== "0" && value.startsWith("0"));
  },
} as ValidationRuleSchema;

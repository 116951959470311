import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 末尾がendCharの文字列で終わることができないこと
 */
export default {
  params: ["endChar"],
  validate(
    value: string | undefined | null,
    { endChar }: { endChar: string }
  ): boolean {
    if (!value) {
      return true;
    }

    /** 末尾がendCharの文字列で終わることができないこと */
    return !value.endsWith(endChar);
  },
} as ValidationRuleSchema;













import Vue, { PropType } from "vue";
import { IntraPolicy } from "@/apis/IntranetfwPolicyApi";

/** ポリシー設定(イントラネットFW)の送信元アドレスを表示するコンポーネント */
export default Vue.extend({
  name: "PolicyIntranetSrcAddress",
  props: {
    /** 送信元アドレスリスト */
    ipAddressList: {
      type: Array as PropType<IntraPolicy["srcAddressList"]>,
      required: true,
    },
  },
  computed: {
    /**
     * ソート済みの送信元アドレスを返却する
     * アドレス名・アドレスの昇順
     */
    sortedIpAddressList(): IntraPolicy["srcAddressList"] {
      return this.ipAddressList.sortBy([
        (e) => e.ipAddressName ?? e.ipAddress,
        "asc",
      ]);
    },
  },
});























































































import Vue, { PropType } from "vue";
import { ExtranetInfo, PutExtranetInfo } from "@/apis/ExtranetApi";
import ConnectionInfoTable from "@/components/extranet/ConnectionInfoTable.vue";

export default Vue.extend({
  name: "ExtranetConnectApproval",
  components: { ConnectionInfoTable },
  props: {
    confirmData: {
      type: Object as PropType<ExtranetInfo>,
      required: true,
    },
    extranetKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      confirmPINCode: "",
      confirmNote: null,
    };
  },
  methods: {
    async submit() {
      await this.$confirm(`エクストラネット接続を承認します。よろしいですか？

        承認した場合、申請されたネットワークと接続されます。
        誤って承認した場合、情報漏洩やお客様の通信に支障を及ぼす可能性があります。
      `);

      // APIで送信するデータの設定
      const data: PutExtranetInfo = {
        pinCode: this.confirmPINCode,
        approvalRequest: "APPROVE",
        note: this.confirmNote || null,
        extranetKeyId: this.extranetKeyId,
      };

      // APIによるエクストラネット承認処理
      await this.$api.extranetApi.putExtranet(
        this.confirmData.extranetSeq,
        data
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stepper-content"},[_c('app-badge',{staticClass:"font-weight-bold h5 mb-2",attrs:{"item":"保守連絡先①","is-info":false}}),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"会社名/部署名","is-required":false,"msg":_vm._f("msg")('info_full_width_alpha_sign_num_max',{
              sign: '. _ ! ? { } + - * / =  % $ & ^ ` ~ | @ ( ) : ; < > [ ]',
              max: 20,
            }),"placement":"right"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"会社名/部署名","vid":"company1","rules":{ required: true, is_not_include_special_char: true, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.cpaNwSetting.maintenanceContact1.company),callback:function ($$v) {_vm.$set(_vm.cpaNwSetting.maintenanceContact1, "company", $$v)},expression:"cpaNwSetting.maintenanceContact1.company"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}])})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"担当者名","is-required":false,"msg":_vm._f("msg")('info_full_width_alpha_sign_num_max',{
              sign: '. _ ! ? { } + - * / =  % $ & ^ ` ~ | @ ( ) : ; < > [ ]',
              max: 20,
            }),"placement":"right"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"担当者名","vid":"name1","rules":{ required: true, is_not_include_special_char: true, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.cpaNwSetting.maintenanceContact1.name),callback:function ($$v) {_vm.$set(_vm.cpaNwSetting.maintenanceContact1, "name", $$v)},expression:"cpaNwSetting.maintenanceContact1.name"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}])})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"電話番号","is-required":false,"msg":_vm._f("msg")('info_tel',{ max: 11 }),"placement":"right"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"電話番号","vid":"phone1","rules":{ required: true, is_phone_number: 11 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.cpaNwSetting.maintenanceContact1.phone),callback:function ($$v) {_vm.$set(_vm.cpaNwSetting.maintenanceContact1, "phone", $$v)},expression:"cpaNwSetting.maintenanceContact1.phone"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}])})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"メールアドレス","is-required":false,"msg":_vm._f("msg")('info_sign_num_max',{
              sign: '. _ ! ? { } + - * / = # % $ & ^ ` ~ | @',
              max: 127,
            }),"placement":"right"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"メールアドレス","vid":"email1","rules":{ required: true, max: 127, is_email: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.cpaNwSetting.maintenanceContact1.email),callback:function ($$v) {_vm.$set(_vm.cpaNwSetting.maintenanceContact1, "email", $$v)},expression:"cpaNwSetting.maintenanceContact1.email"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}])})],1),_c('h5',{staticClass:"font-weight-bold mt-5 mb-2"},[_vm._v("保守連絡先②")]),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"会社名/部署名","is-required":false,"msg":_vm._f("msg")('info_full_width_alpha_sign_num_max',{
              sign: '. _ ! ? { } + - * / =  % $ & ^ ` ~ | @ ( ) : ; < > [ ]',
              max: 20,
            }),"placement":"right"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"会社名/部署名","vid":"company2","rules":{ is_not_include_special_char: true, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.cpaNwSetting.maintenanceContact2.company),callback:function ($$v) {_vm.$set(_vm.cpaNwSetting.maintenanceContact2, "company", $$v)},expression:"cpaNwSetting.maintenanceContact2.company"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}])})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"担当者名","is-required":false,"msg":_vm._f("msg")('info_full_width_alpha_sign_num_max',{
              sign: '. _ ! ? { } + - * / =  % $ & ^ ` ~ | @ ( ) : ; < > [ ]',
              max: 20,
            }),"placement":"right"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"担当者名","vid":"name2","rules":{ is_not_include_special_char: true, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.cpaNwSetting.maintenanceContact2.name),callback:function ($$v) {_vm.$set(_vm.cpaNwSetting.maintenanceContact2, "name", $$v)},expression:"cpaNwSetting.maintenanceContact2.name"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}])})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"電話番号","is-required":false,"msg":_vm._f("msg")('info_tel',{ max: 11 }),"placement":"right"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"電話番号","vid":"phone2","rules":{ is_phone_number: 11 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.cpaNwSetting.maintenanceContact2.phone),callback:function ($$v) {_vm.$set(_vm.cpaNwSetting.maintenanceContact2, "phone", $$v)},expression:"cpaNwSetting.maintenanceContact2.phone"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}])})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"メールアドレス","is-required":false,"msg":_vm._f("msg")('info_sign_num_max',{
              sign: '. _ ! ? { } + - * / = # % $ & ^ ` ~ | @',
              max: 127,
            }),"placement":"right"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"メールアドレス","vid":"email2","rules":{ max: 127, is_email: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.cpaNwSetting.maintenanceContact2.email),callback:function ($$v) {_vm.$set(_vm.cpaNwSetting.maintenanceContact2, "email", $$v)},expression:"cpaNwSetting.maintenanceContact2.email"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}])})],1),_c('cross-validation-provider',{staticClass:"mt-3",attrs:{"passed":_vm.isFilledAllField,"vid":"isFilledAllField"}},[_vm._v("会社名/部署名 / 担当者名 / 電話番号 / メールアドレスはすべて入力してください。")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }











































































































































































import Vue, { PropType } from "vue";
import { CloudQosDetail } from "@/apis/CloudQosApi";
import CloudQosEdit from "@/modals/cloudQos/CloudQosEdit.vue";

export default Vue.extend({
  name: "CloudQosViewRC",
  props: {
    // 変更画面で使用
    vpnVnCode: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    // 変更画面で使用
    vnName: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    // E(編集) or C(確認) or R(参照)
    mode: {
      type: String as PropType<string>,
      required: false,
      default: "E",
    },
    isNew: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    cloudQosDetail: {
      type: Object as PropType<CloudQosDetail>,
      required: true,
    },
  },
  data() {
    return {
      absolutePriorityRowNames: [
        { key: "HIGH", value: "High" },
        { key: "MEDIUM", value: "Medium" },
        { key: "NORMAL", value: "Normal" },
        { key: "LOW", value: "Low" },
      ],
      absolutePriorityColNames: ["0", "10", "18", "24", "34", "46", "-", "-"],
      absolutePriorityPropNames: [
        "priority0",
        "priority1",
        "priority2",
        "priority3",
        "priority4",
        "priority5",
        "priority6",
        "priority7",
      ],
      bandwidthColNames: ["18", "24", "34", "46", "ベストエフォート"],
      bandwidthRowNames: [
        { key: "PATTERN_A", value: "パターンA" },
        { key: "PATTERN_A", value: "" },
        { key: "PATTERN_B", value: "パターンB" },
        { key: "PATTERN_B", value: "" },
      ],
      bandwidthPropNames: [
        "dscp18Value",
        "dscp24Value",
        "dscp34Value",
        "dscp46Value",
        "bestEffort",
      ],
    };
  },
  methods: {
    async showModifyModal() {
      await this.$modal.show(CloudQosEdit, {
        vpnVnCode: this.vpnVnCode,
        vnName: this.vnName,
        isNew: this.isNew,
        cloudQosDetail: this.cloudQosDetail,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * 帯域制御方式の値を取得
     * @param patternName パターン名
     * @param propName プロパティ名
     * */
    getBandwidthValue: function (patternName: string, propName: string) {
      const item = this.cloudQosDetail.cloudQosBandwidthList?.find(
        (e) => e.patternName === patternName
      );
      if (item) {
        if (propName === "bestEffort") {
          return (item.dscp18Value || 0) +
            (item.dscp24Value || 0) +
            (item.dscp34Value || 0) +
            (item.dscp46Value || 0) >
            0
            ? `${
                100 -
                ((item.dscp18Value || 0) +
                  (item.dscp24Value || 0) +
                  (item.dscp34Value || 0) +
                  (item.dscp46Value || 0))
              }%`
            : "-";
        } else {
          switch (propName) {
            case "dscp18Value":
              return item.dscp18Value ? `${item.dscp18Value}%` : "-";
            case "dscp24Value":
              return item.dscp24Value ? `${item.dscp24Value}%` : "-";
            case "dscp34Value":
              return item.dscp34Value ? `${item.dscp34Value}%` : "-";
            case "dscp46Value":
              return item.dscp46Value ? `${item.dscp46Value}%` : "-";
          }
        }
      } else {
        return "-";
      }
    },
    /**
     * 帯域制御方式の最優先設定フラグを取得
     * @param patternName パターン名
     * */
    isDscp46TopPriority(patternName: string) {
      const item = this.cloudQosDetail.cloudQosBandwidthList?.find(
        (e) => e.patternName === patternName
      );
      if (item) {
        return item.isDscp46TopPriority ? "ON" : "OFF";
      } else {
        return "-";
      }
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import is_port from "./is-port";

// サービス設定用に入力されたPort番号の妥当性（半角数字、範囲指定、複数指定）をチェックする
// ---------------------------------------------------------------------------------------------
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) return true;

    const portCommaArray = value.split(",");

    for (const port of portCommaArray) {
      // portが未入力の場合エラー
      if (!port) return false;
      // port番号妥当性チェック(範囲指定/最大値)
      if (!is_port.validate?.(port, { minVal: 1 })) return false;
    }
    return true;
  },
} as ValidationRuleSchema;

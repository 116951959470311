









import Vue from "vue";

/**
 * データ全体を見なければいけない相関チェックを行う場合に使用するValidationProvider.
 * vee-validateのValidationProviderのラッパーなので置き換えて使用する（propsやslotプロパティも同一）
 *
 * このコンポーネント内でCrossValidationProviderが使用できるようになる
 * 相関チェックでエラーが発生するとhandlerSubmitをコールしないようにしてくれる
 */
export default Vue.extend({
  // provideを使うとVueのTypescriptが壊れるので別にしとく。。。
  /* eslint-disable @typescript-eslint/no-explicit-any */
  provide() {
    const provideData = {};

    Object.defineProperty(provideData, "submitted", {
      enumerable: true,
      get: () => (this as any).submitted,
    });

    return {
      addValidateState: Vue.observable((this as any).addValidateState),
      provideData,
    };
  },
  /* eslint-enable @typescript-eslint/no-explicit-any */
}).extend({
  name: "CrossValidationObserver",
  data() {
    return {
      /** true: submitされた, false: submitされてない */
      submitted: false,
      /** vid毎の相関チェックの成否 */
      passed: {} as Record<string, boolean>,
    };
  },
  methods: {
    /**
     * validation-observerのhandleSubmitをoverrideするhandle.
     * 相関チェック結果の有無によってsubmitする/しないを制御する
     * @param handleSubmit 既存のhandleSubmit
     * @param submit 画面のsubmit
     */
    async handleCrossSubmit(
      handleSubmit: (submit?: unknown) => Promise<void>,
      submit: () => void
    ) {
      this.submitted = true;
      // 通常のバリデーションを起動させるためにhandleSubmitを引数なしで呼び出す
      await handleSubmit();

      this.$nextTick(async () => {
        // 相関チェックでエラーが1件でも存在する場合はsubmitをキャンセル
        if (Object.values(this.passed).filter((e) => !e).length >= 1) {
          return;
        }
        await handleSubmit(submit);
      });
    },

    /**
     * 相関チェックの結果を記録
     * @param vid 識別子
     * @param passed true: valid: false: invalid
     */
    addValidateState(vid: string, passed: boolean) {
      this.passed[vid] = passed;
    },
  },
});

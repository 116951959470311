import { render, staticRenderFns } from "./QoSSetting.vue?vue&type=template&id=6093b6b3&"
import script from "./QoSSetting.vue?vue&type=script&lang=ts&"
export * from "./QoSSetting.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
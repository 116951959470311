






















































































































import Vue, { PropType } from "vue";
import { Type4Service } from "@/apis/ContractChangeType4Api";
import { PutType4Route, Type4Route } from "@/apis/Type4RouteApi";

export default Vue.extend({
  name: "InternetType4RouteModify",
  props: {
    selectedItem: {
      type: Object as PropType<Type4Service>,
      required: true,
    },
  },
  data() {
    return {
      /** 初期通信完了フラグ */
      isLoaded: false,
      route: {} as Type4Route,
    };
  },
  computed: {
    /** true: 変更可能, false: 変更不可 */
    changeable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
    /**宛先の重複チェック用リスト作成 */
    dstAddressList() {
      return (index: number) => {
        return this.route.type4RouteList
          .filter((_, num) => num !== index)
          .map((e) => e.internetAddress);
      };
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.route = await this.$api.type4Route.getType4Route(
        this.selectedItem.type4Id
      );
      // 宛先アドレスの初期ソート対応
      this.route.type4RouteList.sort((a, b) => {
        return this.$service.sortCompare(a, b, [["internetAddress", "asc"]]);
      });

      // Type4経路情報リストが0件の場合、初期化
      if (this.route.type4RouteList.length === 0 && this.changeable) {
        this.route.type4RouteList = [
          {
            internetAddress: "",
            isMicrosoft365: false,
          },
        ];
      }
    },
    /** 経路設定を行う */
    async submit() {
      await this.$confirm("インターネット経路を変更します。よろしいですか？");
      const data: PutType4Route = {
        type4RouteKeyId: this.route.type4RouteKeyId,
        type4RouteList: this.route.type4RouteList,
      };
      if (
        data.type4RouteList.length === 1 &&
        !data.type4RouteList[0].internetAddress
      ) {
        data.type4RouteList = [];
      }
      await this.$api.type4Route.putType4Route(this.selectedItem.type4Id, data);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 経路設定の追加 */
    addRoute: function () {
      this.route.type4RouteList.push({
        internetAddress: "",
        isMicrosoft365: false,
      });
    },
    /**
     * 経路設定の削除
     * @param index 対象インデックス
     */
    removeRoute(index: number) {
      if (this.route.type4RouteList.length > 1) {
        this.route.type4RouteList.splice(index, 1);
      } else {
        this.route.type4RouteList[0].internetAddress = "";
        this.route.type4RouteList[0].isMicrosoft365 = false;
      }
    },
  },
});

/**
 * リスクを表示形式に変換する
 * 1    → informational
 * 2    → low
 * 3    → medium
 * 4    → high
 * 5    → critical
 * null → -
 * 上記以外の場合、そのまま返却
 * @param risk リスク
 */
export default function riskTo(risk: string | null): string {
  if (risk) {
    switch (risk) {
      case "1":
        return "informational";
      case "2":
        return "low";
      case "3":
        return "medium";
      case "4":
        return "high";
      case "5":
        return "critical";
      default:
        return risk;
    }
  } else {
    return "-";
  }
}









































































import Vue, { PropType } from "vue";
import SaaSServiceUsageEdit from "@/modals/saas/SaaSServiceUsageEdit.vue";
import { SaaSListEntity } from "@/views/saas/SaaSServiceApplicationList.vue";

export default Vue.extend({
  name: "SaaSServiceUsageReference",
  props: {
    /** SaaS設定情報 */
    saas: {
      type: Object as PropType<SaaSListEntity>,
      required: true,
    },
    /** 設定追加・変更時、使用する排他キー */
    saasKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  methods: {
    /** サービス設定変更画面表示 */
    async modifyMSService() {
      await this.$modal.show(SaaSServiceUsageEdit, {
        saas: this.saas,
        saasKeyId: this.saasKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    async delMSService() {
      await this.$confirm("SaaS利用設定を削除します。よろしいですか？");

      /** APIによるSaaS利用設定削除処理 */
      await this.$api.saas.delete({
        vpnVnCode: this.saas.saasCommon.vpnVnCode,
        saasKeyId: this.saasKeyId,
      });

      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

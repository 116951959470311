



































































import Vue from "vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { CloudQosInfo } from "@/apis/CloudQosApi";
import CloudQosViewRC from "@/modals/cloudQos/CloudQosViewRC.vue";

export default Vue.extend({
  name: "CloudQosSettingList",
  data() {
    return {
      cloudQos: {
        fields: [
          { key: "vpnVnCode", label: "VPN/VNコード", sortable: true },
          {
            key: "vnName",
            label: "仮想ネットワーク名",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, key: null, item: CloudQosInfo) => {
              return item.vpnVnCode.startsWith("B") ? null : value;
            },
          },
          {
            key: "qosType",
            label: "QoS方式",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) => {
              if (value === "NONE") {
                return "-";
              } else {
                return this.$filter.enumTo(value, "qosType");
              }
            },
          },
          {
            key: "priorityTarget",
            label: "優先度参照先",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) => {
              if (value === "NONE") {
                return "-";
              } else {
                return this.$filter.enumTo(value, "cloudQos_PriorityTarget");
              }
            },
          },
          { key: "line", label: "回線数" },
          { key: "qosControl", label: "設定可/不可", tdClass: "text-center" },
        ] as BvTableFieldArray,
        items: [] as CloudQosInfo[],
        search: {
          vpnVnCode: { type: "text", label: "VPN/VNコード" },
          vnName: { type: "text", label: "仮想ネットワーク名" },
          qosType: {
            type: "select",
            label: "QoS方式",
            items: [
              { value: "ABSOLUTE", text: "絶対優先方式" },
              { value: "BANDWIDTH", text: "帯域制御方式" },
              { value: "NONE", text: "-" },
            ],
          },
          priorityTarget: {
            type: "select",
            label: "優先度参照先",
            items: [
              { value: "IPPRECEDENCE_DSCP", text: "IP Precedence/DSCP" },
              { value: "DSCP", text: "DSCP" },
              { value: "NONE", text: "-" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          vpnVnCode: "like",
          vnName: "like",
          qosType: "equal",
          priorityTarget: "equal",
        },
      },
      // 初期通信完了フラグ
      isLoaded: false,
      // download用URL
      downloadUrl: this.$api.cloudQosApi.getCsvCloudQosURL(),
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.cloudQos.items = await this.$api.cloudQosApi.getCloudQosInfoList();
    },
    async showDetails(item: CloudQosInfo) {
      const cloudQosDetail = await this.$api.cloudQosApi.getCloudQosDetail(
        item.vpnVnCode
      );
      await this.$modal.show(CloudQosViewRC, {
        vpnVnCode: item.vpnVnCode,
        vnName: item.vnName,
        isNew: cloudQosDetail.qosType === "NONE",
        mode: "E",
        cloudQosDetail: cloudQosDetail,
      });
      await this.load();
    },
  },
});





























































































































import Vue, { PropType } from "vue";
import { IpSecLineWithGW } from "@/views/ipSecLine/IpSecLineList.vue";

export default Vue.extend({
  name: "IpSecLineViewCD",
  props: {
    /** 確認モード（register, modify, delete） */
    mode: {
      type: String as PropType<"register" | "modify" | "delete">,
      required: true,
    },
    /** 表示対象IPsec回線 + IPsecGW（複数） */
    lines: {
      type: Array as PropType<IpSecLineWithGW[]>,
      required: true,
    },
  },
  data() {
    return {
      note: "",
    };
  },
  computed: {
    /** key: E番号, value: 追加、変更、削除した場合の想定回線件数 */
    gwOfIpsec(): Record<string, number> {
      // 現在のIPsec回線数
      const nowIpsec = this.lines
        .uniq((e) => e.enumber)
        .toMap(
          (e) => e.enumber,
          (e) => e.gw.numberOfIpsec
        );
      if (this.mode === "modify") {
        // 変更は回線件数に変更はないので現在数を返却
        return nowIpsec;
      }

      // E番号ごとに想定回線件数を集計
      return this.lines.reduce((acc, e) => {
        const ipsec: number | undefined = acc[e.enumber];
        if (ipsec) {
          return {
            ...acc,
            [e.enumber]: this.mode === "register" ? ipsec + 1 : ipsec - 1,
          };
        } else {
          return {
            ...acc,
            [e.enumber]:
              this.mode === "register"
                ? nowIpsec[e.enumber] + 1
                : nowIpsec[e.enumber] - 1,
          };
        }
      }, {} as Record<string, number>);
    },
  },
  methods: {
    async submit() {
      (this.$refs.modal as Vue & { ok: (node: string | null) => void }).ok(
        this.note ? this.note : null
      );
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-medium","title":"契約回線詳細情報"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"detail-container mt-2"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("契約情報")]),_c('div',[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"回線番号"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.contractReference.enumber))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"メニュー"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm._f("enumTo")(_vm.contractReference.accessType,"contractLineAccessType")))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"契約者名"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.contractReference.contractor))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"エンドユーザ名"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.contractReference.endUserName))])]),(
              _vm.contractReference.accessType !== 'REMOTE_ACCESS_GW_FRE' &&
              _vm.contractReference.accessType !== 'REMOTE_ACCESS_GW_CPA' &&
              _vm.contractReference.accessType !== 'REMOTE_ACCESS_GW_CRG' &&
              _vm.contractReference.accessType !== 'REMOTE_ACCESS_GW2_FRE' &&
              _vm.contractReference.accessType !== 'REMOTE_ACCESS_GW2_CPA' &&
              _vm.contractReference.accessType !== 'REMOTE_ACCESS_GW2_CRG'
            )?[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"事務所名(拠点名)"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.contractReference.office))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"住所"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm._f("emptyTo")(_vm.contractReference.address,"-")))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"ビル名"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm._f("emptyTo")(_vm.contractReference.building,"-")))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"帯域値"}},[(_vm.contractReference.bandwidth === null)?_c('span',{staticClass:"content"},[_vm._v(" - ")]):_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm._f("bandwidthTo")(_vm.contractReference.bandwidth)))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"QoS"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm._f("enumTo")(_vm.contractReference.qos,"contractQos")))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"VLAN制御"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm._f("emptyTo")(_vm.contractReference.vlanControl,"-")))])])]:_vm._e()],2)])]),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("戻る")])],1)]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
























































































































































import Vue, { PropType } from "vue";
import { CpaContract } from "@/apis/Cpa5gSaApi";

export default Vue.extend({
  name: "SaNwContractInfo",
  props: {
    /** CPA契約情報 */
    cpaContractInfo: {
      type: Object as PropType<CpaContract>,
      required: true,
    },
  },
});

import { Store } from "vuex";
import { RootState } from "@/store";
import PermissionService from "@/services/PermissionService";
import FileService from "@/services/FileService";
import sortCompare, { tableSortCompare } from "@/services/SortService";
import { ConfigDownloadService } from "@/services/ConfigDownloadService";
import BandwidthService from "@/services/BandwidthService";
import ImportConfigService from "@/services/ImportConfigService";
import { CsvService } from "@/services/CsvService";

/** 業務ロジック */
export default class AppService {
  constructor(private store: Store<RootState>) {}

  /** 権限系 */
  readonly permission = new PermissionService(this.store);
  /** ファイル操作系 */
  readonly file = new FileService();
  /** ソート（Array.prototype.sort向け） */
  readonly sortCompare = sortCompare;
  /** ソート（BootstrapVue Tableのsort-compare向け） */
  readonly tableSortCompare = tableSortCompare;
  /** 設定ダウンロード・アップロード */
  readonly configDownload = new ConfigDownloadService(this.file);
  /** 帯域オブジェクトの操作 */
  readonly bandwidth = new BandwidthService();
  /** 設定インポート */
  readonly importConfig = new ImportConfigService();
  /** CSV関連の操作 */
  readonly csvService = new CsvService(this.file);
}

/** GET 操作履歴一覧情報を取得 */
import AbstractBaseApi from "@/apis/AbstractBaseApi";

export interface OperationHistoryEntity {
  total: number;
  /**
   * 操作履歴排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   * */
  operationHistoryKeyId: string;
  /**
   * 取得した操作履歴情報全件。データが存在しない場合、[](空の状態)になる
   * */
  operationList: OperationListEntity[];
}

export interface OperationListEntity {
  /** 操作SEQ 主キー*/
  operationSeq: string;
  /** ソースSEQ
   * プロビジョニング履歴の表示制御に使用するパラメータ
   */
  sourceSeq: string | null;
  /**
   * 内容。以下のいずれかを返却する。
   * ポリシー設定
   * サービス申込
   * 契約取込
   * 手動契約取込
   * 収容変更
   * インターネット一時停止
   * 仮想NW申込
   * VNコネクト設定
   * フロー設定
   * VN回線有効無効設定
   * インターフェース設定
   * サービス申込(Type3)
   * アクセス品目申込
   * IPsec設定
   * エクストラネット接続申込
   * Type3ポリシー設定
   * Type3経路設定
   * ポリシー設定(プラットフォームゲートウェイ)
   * VN回線有効無効設定（収容変更）
   * フロー設定（収容変更）
   * VN回線設定（収容変更）
   * インターフェース設定（収容変更）
   * VN回線収容変更
   * Hub&Spoke接続申込
   * VN L2/L3コネクト設定
   * トラフィック情報取得開始
   * VNコネクト一括設定
   * クラウド毎帯域設定
   * サービスタグ取得
   * サービス申込(Microsoftサービス)
   * クラウド向けVNコネクト設定
   * クラウド向けVN回線有効無効設定
   * クラウド向けインターフェース設定
   * サービス申込(Type4)
   * Type4ポリシー設定
   * Type4経路設定
   * CPA 5G SA 契約変更
   * CPA 5G SA NW設定
   * CPA 5G SA 利用開始
   * CPA 5G SA 認証ID一括登録
   * サービス申込(SaaS)
   */
  content: string;
  /** 実行アカウント */
  account: string;
  /** 受付日時開始時刻 */
  acceptDateTime: string;
  /** 終了日時開始時刻 */
  endDateTime: string | null;
  /**
   * ステータス
   * 以下のいずれかを指定する
   * SUCCESS: 成功
   * FAILURE: 失敗
   * RESERVATION: 予約中
   * SETTING: 設定中
   * ACCEPTED: 受付完了、処理待ち
   */
  orderStatus: "SUCCESS" | "FAILURE" | "RESERVATION" | "SETTING" | "ACCEPTED";
  /** オーダー備考 */
  note: string | null;
  /**
   * お客様用履歴フラグ
   * 画面表示する場合: 「true」
   * 画面表示しない場合:「false」
   */
  isCustomer: boolean;
}

/**
 * GET操作履歴情報詳細を取得
 * データが存在しない場合、[](空の状態)になります。
 */
export interface ReferenceListEntity {
  /**
   * 内容。以下のいずれかを返却する。
   * ポリシー設定
   * 契約変更
   * 契約取込（インターネット開通要求、アクセス回線制御要求（Step1、Step2））
   * 手動プロビ
   * アプライアンス収容変更
   * インターネット一時停止
   * 仮想NW申込
   * VN回線設定
   * フロー設定
   * VN回線有効無効設定
   * ifshutdown設定
   * インターネットType3回線制御
   * アクセス品目申込
   * IPsec設定
   * エクストラネット接続申請承認
   * 契約変更（Type3）
   * IPsec VC回線設定
   * Type3ポリシー設定
   * Type3経路設定
   * PFGWポリシー設定
   * VN回線有効無効設定（収容変更）
   * フロー設定（収容変更）
   * VN回線設定（収容変更）
   * ifshutdown設定（収容変更）
   * 契約取込（解約要求）
   * VN収容変更
   * Hub&Spoke接続申込
   * VN L2/L3コネクト設定
   * トラフィック情報取得開始
   * VNコネクト一括設定
   * クラウド毎帯域設定
   * サービスタグ取得
   * サービス申込(Microsoft Peering)
   * クラウド向けVNコネクト設定
   * クラウド向けVN回線有効無効設定
   * クラウド向けifshutdown設定
   * サービス申込(Type4)
   * Type4ポリシー設定
   * Type4経路設定
   * CPA 契約変更
   * CPA NW設定
   * CPA 利用開始
   * 認証ID操作
   * CPA ID情報生成
   */
  content: string;
  /**
   * GET操作履歴情報詳細を取得[テーブル]
   * データが存在しない場合、[](空の状態)になります。
   */
  orderList: OrderListEntity[];
}

export interface OrderListEntity {
  /** 種別。DB上の操作履歴より操作対象の種別を取得し返却する */
  orderType: string;
  /** 対象。DB上の操作履歴より操作対象を取得し返却する */
  orderTarget: string | null;
  /**
   * ステータス
   * 以下のいずれかを指定する
   * SUCCESS: 成功
   * FAILURE: 失敗
   * RESERVATION: 予約中
   * SETTING: 設定中
   * ACCEPTED: 受付完了、処理待ち
   */
  orderStatus: "SUCCESS" | "FAILURE" | "RESERVATION" | "SETTING" | "ACCEPTED";
  /** 付加情報。DB上の操作履歴情報に紐づくコードを取得しメッセージを返却する */
  additionalInformation: string | null;
  /**
   * 取得した操作履歴情報詳細の変更前/変更後情報
   * データが存在しない場合、[](空の状態)になる。
   */
  orderDetailList: OrderDetailListEntity[];
}

export interface OrderDetailListEntity {
  /** 項目。値を変更した項目の名称 */
  orderItem: string;
  /**
   * 変更前
   * 変更前の値（変更後情報の1つ前の情報）
   * 新規登録だった場合は変更前情報は存在しない。
   */
  before: string | null;
  /** 変更後。変更後の値 */
  after: string | null;
}

/**
 * 操作履歴情報の表示制御の状態を更新
 * ※お客様非開示
 */
export interface PutOperationListEntity {
  operationList: {
    /** 操作SEQ 主キー */
    operationSeq: string;
    /**
     * お客様用履歴フラグ
     * 法人ユーザー、代行権限者が確認できる場合: 「true」
     * 代行権限者のみ確認できる場合: 「false」
     */
    isCustomer: boolean;
  }[];
  /**
   * 操作履歴排他情報。排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  operationHistoryKeyId: string;
}

export default class OperationHistoryApi extends AbstractBaseApi {
  /**
   * 操作履歴一覧情報取得
   */
  async getAll(): Promise<OperationHistoryEntity> {
    return (await this.api.get("/v1/information/operation-histories")).data;
  }
  /**
   * 操作履歴情報詳細取得
   * @param operationSeq 操作SEQ
   */
  async getReferenceList(operationSeq: string): Promise<ReferenceListEntity> {
    return (
      await this.api.get(`/v1/information/operation-history/${operationSeq}`)
    ).data;
  }

  /**
   * 操作履歴情報の表示制御の状態を更新 ※お客様非開示
   * @param data 更新情報
   */
  async update(data: PutOperationListEntity): Promise<void> {
    await this.api.post("/v1/information/operation-histories", data);
  }
  /**
   * 操作履歴一覧情報をCSVにてダウンロードする。
   *
   */
  getDownloadUrl(): string {
    return this.getFullURL("/v1/information/operation-history/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
}

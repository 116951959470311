








































































































import Vue from "vue";
import { PutPassword } from "@/apis/PasswordApi";

export default Vue.extend({
  name: "PasswordModify",
  data() {
    return {
      form: {} as PutPassword,
    };
  },
  methods: {
    async submit() {
      await this.$confirm(`パスワードを変更します。よろしいですか？`);
      await this.$api.password.putPassword(this.form);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

import { CloudLine } from "@/apis/CloudLineApi";

/**
 * クラウドサービスメニューの表示値を作成する
 * @param cloudServiceMenu クラウドサービスメニュー
 */
export default function cloudServiceMenuTo(
  cloudServiceMenu: CloudLine["cloudServiceMenu"]
): string {
  if (cloudServiceMenu.startsWith("MS")) {
    return "Microsoft" + cloudServiceMenu.substr(2);
  }
  switch (cloudServiceMenu) {
    case "AWS":
      return "AWS";
    case "GCP":
      return "Google Cloud";
    case "Oracle":
      return "Oracle Cloud";
    case "IBM":
      return "IBM Cloud";
    case "SFDC":
      return "Salesforce";
    default:
      return "-";
  }
}










































































import Vue from "vue";
import { ContractChangeOption } from "@/apis/ContractChangeOptionApi";
import ContractOptionModify from "@/modals/applianceContractSetting/ContractOptionModify.vue";

export default Vue.extend({
  name: "ContractOptionReference",
  data() {
    return {
      /** 初期通信完了フラグ */
      isLoaded: false,
      /** ユーザ設定 */
      user: this.$store.state.user,
      contractOption: {} as ContractChangeOption,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    async load() {
      this.contractOption =
        await this.$api.contractChangeOption.getContractOptions();
    },
    /** オプション契約変更モーダルを表示 */
    async showOptionModify() {
      await this.$modal.show(ContractOptionModify, {
        contractOption: this.contractOption,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

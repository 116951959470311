






















































































import Vue, { PropType } from "vue";
import ProfileInternetFwEdit from "@/modals/applianceObject/ProfileInternetFwEdit.vue";
import {
  DeleteInternetfwProfile,
  InternetfwProfileInfo,
} from "@/apis/InternetfwProfileApi";

export default Vue.extend({
  name: "ProfileInternetFwReference",
  props: {
    profileInternetFwSeq: {
      type: String as PropType<string>,
      required: true,
    },
    type4Id: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isLoaded: false,
      internetFwProfileInfo: {} as InternetfwProfileInfo,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** プロファイル(インターネットFW)情報の取得 */
    async load() {
      // プロファイル(インターネットFW)詳細データの取得
      if (!this.type4Id) {
        this.internetFwProfileInfo =
          await this.$api.internetfwProfileApi.getType1Profile(
            this.profileInternetFwSeq
          );
      } else {
        this.internetFwProfileInfo =
          await this.$api.internetfwProfileApi.getType4Profile(
            this.type4Id,
            this.profileInternetFwSeq
          );
      }
      // アプリケーション配列のソート対応
      this.internetFwProfileInfo.applicationList.sort((a, b) => {
        return this.$service.sortCompare(a, b, [["applicationName", "asc"]]);
      });
    },
    /** インターネットFWを編集 */
    async showProfileInternetFwModify() {
      await this.$modal.show(ProfileInternetFwEdit, {
        internetFwProfileInfo: this.internetFwProfileInfo,
        type4Id: this.type4Id,
        profileInternetFwSeq: this.profileInternetFwSeq,
        profileInternetFwKeyId:
          this.internetFwProfileInfo.profileInternetFwKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** インターネットFWを削除 */
    async deleteInternetFw() {
      // 確認ダイアログの表示
      await this.$confirm("プロファイルを削除します。よろしいですか？");

      // リクエストデータの設定
      const reqData: DeleteInternetfwProfile = {
        profileInternetFwSeqList: [this.profileInternetFwSeq],
        profileInternetFwKeyId:
          this.internetFwProfileInfo.profileInternetFwKeyId,
        note: null,
      };

      // プロファイル(インターネットFW)削除APIを呼び出す
      if (!this.type4Id) {
        await this.$api.internetfwProfileApi.deleteType1Profile(reqData);
      } else {
        await this.$api.internetfwProfileApi.deleteType4Profile(
          this.type4Id,
          reqData
        );
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

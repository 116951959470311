/** Hub and Spoke 情報*/
import AbstractBaseApi from "@/apis/AbstractBaseApi";

export interface HubSpokeInfos {
  /** 総件数 Hub&Spoke接続一覧全件取得 */
  total: number;
  /** 取得データが存在しない場合には空配列を返却する。 */
  hubSpokeInfoList: HubSpokeInfo[];
}

export interface HubSpokeInfo {
  /** Hub&Spoke接続申請SEQ */
  hubSpokeSeq: string;
  /** Hub側V番号 hubVpnVnCodeがVPNコード指定だった場合にはNULLが渡される。 */
  hubVNumber: string | null;
  /** Hub側VPN/VNコード */
  hubVpnVnCode: string;
  /** Spoke側V番号 spokeVpnVnCodeがVPNコード指定だった場合にはNULLが渡される。 */
  spokeVNumber: string | null;
  /** Spoke側VPN/VNコード */
  spokeVpnVnCode: string;
  /** 広報先VPNコード */
  publicVpnCode: string;
  /**
   *  状態
   * コードの内容は下記になります。
   * REQUEST: Hub&Spokeの接続申請が承認待ちの場合
   * COMPLETE: Hub&Spokeの接続申請が完了した場合
   * DISCONNECTED: Hub&Spokeの接続申請が切断した場合
   * PROVISIONING_FAILURE: Hub&Spokeの接続申請が失敗した場合
   */
  hubSpokeStatus:
    | "REQUEST"
    | "COMPLETE"
    | "DISCONNECTED"
    | "PROVISIONING_FAILURE";
  /** 申請日時. ($date-time) example: 2021-11-23T00:09:44+09:00 */
  requestDateTime: string;
  /** 承認日時. ($date-time) example: 2021-11-23T00:09:44+09:00 */
  approvalDateTime: string | null;
}

export default class HubAndSpokeApi extends AbstractBaseApi {
  /** Hub&Spoke接続申請情報の一覧を取得 */
  async getHubAndSpoke(): Promise<HubSpokeInfo[]> {
    return ((await this.api.get("/v1/network/hub-spoke")).data as HubSpokeInfos)
      .hubSpokeInfoList;
  }

  /**
   * Hub&Spoke接続申請情報をCSVにてダウンロードする。
   */
  getCsvHubAndSpokeURL(): string {
    return this.getFullURL("/v1/network/hub-spoke/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
}

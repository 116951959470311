





















































































































































































import Vue, { PropType } from "vue";
import {
  Type1Service,
  Bandwidth,
  GlobalIpAddress,
  ContractChangeType1PutRequest,
} from "@/apis/ContractChangeType1Api";
import InternetType1ContractConfirm from "@/modals/applianceContractSetting/InternetType1ContractConfirm.vue";
import cloneDeep from "lodash/cloneDeep";

type typeContractChangeType1PutRequest = ContractChangeType1PutRequest & {
  globalIpAddressList?: GlobalIpAddress[];
};

export default Vue.extend({
  name: "InternetType1ContractModify",
  props: {
    selectedItem: {
      type: Object as PropType<Type1Service>,
      required: true,
    },
  },
  data() {
    return {
      user: this.$store.state.user,
      /** 関連項目チェック
       * インターネットFWの帯域確保における帯域値が（数値+単位）が適切な範囲であること
       * 選択形式なので範囲外の値の設定は不可。
       */
      bandwidth: [
        { value: 10, text: " 10 " },
        { value: 20, text: " 20 " },
        { value: 30, text: " 30 " },
        { value: 40, text: " 40 " },
        { value: 50, text: " 50 " },
        { value: 60, text: " 60 " },
        { value: 70, text: " 70 " },
        { value: 80, text: " 80 " },
        { value: 90, text: " 90 " },
        { value: 100, text: " 100 " },
        { value: 200, text: " 200 " },
        { value: 300, text: " 300 " },
        { value: 400, text: " 400 " },
        { value: 500, text: " 500 " },
        { value: 600, text: " 600 " },
        { value: 700, text: " 700 " },
        { value: 800, text: " 800 " },
        { value: 900, text: " 900 " },
        { value: 1000, text: " 1000 " },
      ],
      form: {
        numberOfAddGlobalIpAddress: (this.selectedItem.globalIpAddressList
          .length === 0
          ? 1
          : 0) as number | null,
        deleteGlobalIpAddressSeqList: null as string[] | null,
        note: null,
        contractType1KeyId: "",
        bandwidth: {} as Bandwidth | null,
        globalIpAddressList: [] as GlobalIpAddress[],
      },
      paidGlobalIpCount: this.selectedItem.globalIpAddressList.length,
      cloneItem: {},
    };
  },
  computed: {
    /** true: 削除可能, false: 削除不可 */
    canRemove(): boolean {
      return (
        this.form.globalIpAddressList.length +
          this.form.numberOfAddGlobalIpAddress! >
        1
      );
    },
    /** true: 追加可能, false: 追加不可 */
    canAdd(): boolean {
      return (
        this.paidGlobalIpCount + this.form.numberOfAddGlobalIpAddress! < 32
      );
    },
    /** 関連項目チェック
     * 初期表示した内容から設定内容に変更があること。
     */
    objectChanged(): boolean {
      return this.$crossValidation.objectChanged(this.cloneItem, this.form);
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      /** bandwidth.unitがGBPSの場合は「Mbps」に変換 */
      if (this.selectedItem.bandwidth) {
        this.form.bandwidth = { ...this.selectedItem.bandwidth };
        if (this.form.bandwidth.unit === "GBPS") {
          this.form.bandwidth.value = 1000;
          this.form.bandwidth.unit = "MBPS";
        }
      }
      this.form.contractType1KeyId = this.selectedItem.contractType1KeyId;
      this.form.globalIpAddressList = this.selectedItem.globalIpAddressList.map(
        (e) => ({ ...e })
      );
      this.cloneItem = cloneDeep(this.form);
    },
    /** 確認モーダルの表示 */
    async submit() {
      await this.$modal.show(InternetType1ContractConfirm, {
        selectedItem: this.selectedItem,
        putItem: this.form,
        globalIpAddressList: this.form.globalIpAddressList,
      });
      /** ここでnull設定を行う */
      if (this.selectedItem.applianceType === "UTM") {
        this.form.bandwidth = null;
      }
      this.form.note = this.form.note || null;
      if (this.form.numberOfAddGlobalIpAddress === 0) {
        this.form.numberOfAddGlobalIpAddress = null;
      }
      // 不要な項目の削除(objectChangeでエラーとなるので値を詰め替えてから削除)
      const postForm: typeContractChangeType1PutRequest = cloneDeep(this.form);
      delete postForm.globalIpAddressList;
      await this.$api.contract_change_type1.putType1Service(postForm);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 登録済のグローバルIPを削除する
     @param globalIpAddressSeq 登録済のIPアドレスID
     */
    removeGlobalIpAddress(globalIpAddressSeq: string) {
      if (!this.form.deleteGlobalIpAddressSeqList) {
        this.form.deleteGlobalIpAddressSeqList = [];
      }
      this.form.deleteGlobalIpAddressSeqList.push(globalIpAddressSeq);
      const index = this.form.globalIpAddressList.findIndex(
        (e) => e.globalIpAddressSeq === globalIpAddressSeq
      );
      this.form.globalIpAddressList.splice(index, 1);
    },
  },
});


















































































































































































































































































































































import Vue, { PropType } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { Type1IpMappingItem } from "@/views/applianceObject/Type1IpMapping.vue";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import { Type1ExtraInfo, Type1PrivateInfo } from "@/apis/Type1SiteApi";

export default Vue.extend({
  name: "IpMappingType1Edit",
  props: {
    ipMapping: {
      type: Object as PropType<Type1IpMappingItem>,
      required: false,
      default: null,
    },
    ipMappingKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      ipMappingInfo: this.ipMapping
        ? cloneDeep(this.ipMapping)
        : ({
            isPortForward: false,
            protocol: "TCP",
          } as Type1IpMappingItem),
      globalIpList: [] as string[],
      zoneList: [] as ZoneRelationItem[],
      type1Extra: {} as Type1ExtraInfo,
      type1Private: {} as Type1PrivateInfo,
      portForwardOptions: [
        { text: "無効", val: false },
        { text: "有効", val: true },
      ],
      protocolOptions: [
        { text: "TCP", val: "TCP" },
        { text: "UDP", val: "UDP" },
      ],
      isLoaded: false,
    };
  },
  computed: {
    /** 宛先ゾーン選択肢 */
    dstZone(): Type1IpMappingItem["dstZone"][] {
      return this.zoneList
        .filter(
          (e) =>
            e.srcZoneType === "INTERNET" &&
            (e.dstZoneType === "EXTRA" || e.dstZoneType === "PRIVATE")
        )
        .map((e) => ({
          type: e.dstZoneType as "EXTRA" | "PRIVATE",
          zone: { zoneId: e.dstZoneId, zoneName: e.dstZoneName },
        }));
    },

    /** プライベートIP選択肢 */
    privateAddresses(): { ipAddressId: string; ipAddress: string }[] {
      // 選択された宛先ゾーンに属しているIPアドレス、ゾーン配置済み、/32のみ
      if (this.ipMappingInfo.dstZone) {
        if (this.ipMappingInfo.dstZone.type === "EXTRA") {
          return this.type1Extra.siteList
            .flatMap((e) => e.addressList)
            .filter((e) => e.zoneId)
            .filter((e) => e.ipAddress.endsWith("/32"))
            .sortBy([(e) => e.ipAddress, "asc"]);
        } else {
          return this.type1Private.lineList
            .flatMap((e1) => e1.siteList.flatMap((e2) => e2.addressList))
            .filter(
              (e) =>
                e.zoneId && e.zoneId === this.ipMappingInfo.dstZone!.zone.zoneId
            )
            .filter((e) => e.ipAddress.endsWith("/32"))
            .sortBy([(e) => e.ipAddress, "asc"]);
        }
      } else {
        return [];
      }
    },
    isPrivateByRange(): boolean | undefined {
      return this.ipMappingInfo.privatePort?.includes("-");
    },
    isGlobalByRange(): boolean | undefined {
      return this.ipMappingInfo.globalPort?.includes("-");
    },
  },
  async mounted() {
    if (!this.ipMapping) {
      this.globalIpList = (
        await this.$api.contract_change_type1.getType1Service()
      ).globalIpAddressList
        .map((e) => e.globalIpAddress)
        .sortBy([(e) => e, "asc"]);
      this.zoneList = (
        await this.$api.zoneRelation.getZoneRelation()
      ).zoneRelationList;
    }
    this.type1Extra = await this.$api.type1SiteZone.getType1ExtraList();
    this.type1Private = await this.$api.type1SiteZone.getType1PrivateList();
    this.isLoaded = true;
  },
  methods: {
    /**
     * ゾーン配置済で/32のアドレスリストを返却
     */
    zonePlacedAddresses(): { ipAddressId: string; ipAddress: string }[] {
      // ゾーン配置済み、/32のみ
      return this.type1Extra.siteList
        .flatMap((e) => e.addressList)
        .filter((e) => e.zoneId)
        .filter((e) => e.ipAddress.endsWith("/32"))
        .concat(
          this.type1Private.lineList
            .flatMap((e1) => e1.siteList.flatMap((e2) => e2.addressList))
            .filter((e) => e.zoneId)
            .filter((e) => e.ipAddress.endsWith("/32"))
        );
    },
    /**
     * IPマッピングの追加・変更を行う
     */
    async onSubmit() {
      // propsから受け取るデータの有無で更新・追加処理をハンドリング
      if (this.ipMapping) {
        await this.$confirm("IPマッピングを変更します。よろしいですか？");
        // プライベートIPに設定されているIPアドレスがサイトに複数存在する場合、
        // ゾーン未配置のipAddressIdが渡される場合があるため
        // ゾーン配置済のipAddressIdに塗り替える必要がある

        // IPアドレス重複で且つipAddressIdがゾーン未配置の場合
        if (
          !this.ipMappingInfo.dstZone &&
          !this.zonePlacedAddresses().find(
            (e) => e.ipAddressId === this.ipMappingInfo.ipAddressId
          )
        ) {
          // ゾーン配置済のipAddressIdに塗り替える
          this.ipMappingInfo.ipAddressId = this.zonePlacedAddresses().find(
            (e) => e.ipAddress === this.ipMappingInfo.privateIpAddress
          )!.ipAddressId;
        }

        await this.$api.ipMapping.putType1IpMapping(
          this.ipMappingInfo.virtualIpAddressSeq,
          {
            description: this.ipMappingInfo.description || null,
            ipAddressId: this.ipMappingInfo.ipAddressId,
            ipMappingKeyId: this.ipMappingKeyId,
            note: null,
          }
        );
      } else {
        await this.$confirm("IPマッピングを追加します。よろしいですか？");
        await this.$api.ipMapping.postType1IpMapping({
          description: this.ipMappingInfo.description || null,
          ipMappingName: this.ipMappingInfo.ipMappingName,
          globalIpAddress: this.ipMappingInfo.globalIpAddress,
          ipAddressId: this.ipMappingInfo.ipAddressId,
          isPortForward: this.ipMappingInfo.isPortForward,
          protocol: this.ipMappingInfo.isPortForward
            ? this.ipMappingInfo.protocol
            : null,
          globalPort: this.ipMappingInfo.isPortForward
            ? this.ipMappingInfo.globalPort
            : null,
          privatePort: this.ipMappingInfo.isPortForward
            ? this.ipMappingInfo.privatePort
            : null,
          ipMappingKeyId: this.ipMappingKeyId,
          note: null,
        });
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

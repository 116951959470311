

























































import Vue, { PropType } from "vue";
import { ExtranetInfo } from "@/apis/ExtranetApi";

export default Vue.extend({
  name: "ConnectionInfoTable",
  props: {
    connectionInfo: {
      type: Object as PropType<ExtranetInfo>,
      required: true,
    },
    /**
     * 承認画面から呼び出されているか判定用
     * true:承認画面から呼び出し false:それ以外から呼び出し
     */
    isExtranetApproval: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
  },
  computed: {
    /** 承認側であればtrueを返却する */
    isApproval(): boolean {
      return (
        this.$store.state.user.contractSummary.vnumber !==
        this.connectionInfo.srcVNumber
      );
    },
  },
});

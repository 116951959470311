



































































import Vue, { PropType } from "vue";
import { CloudVnConnects, CloudVnResetting } from "@/apis/CloudVnResettingApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";

export default Vue.extend({
  name: "CloudVnResettingList",
  props: {
    /** VNコネクト設定未完了一覧情報 */
    cloudVnResetting: {
      type: Object as PropType<CloudVnResetting>,
      required: true,
    },
  },
  data() {
    return {
      vnConnects: {
        fields: [
          {
            key: "enumber",
            label: "回線番号",
          },
          {
            key: "cloudServiceMenu",
            label: "メニュー",
          },
          {
            key: "wnumber",
            label: "VNコネクト番号\n(Act/Sby)",
          },
          {
            key: "vnConnectName",
            label: "VNコネクト名\n(Act/Sby)",
          },
          {
            key: "vpnVnCode",
            label: "接続先VPN/VNコード",
          },
        ] as BvTableFieldArray,
        selected: [] as CloudVnResetting["cloudVnConnectsList"],
      },
      alertMsg: null as string | null,
    };
  },
  mounted() {
    this.cloudVnResetting.cloudVnConnectsList.sort((a, b) => {
      // 第一優先のenumberの降順
      if (a.cloudLineInfo.enumber < b.cloudLineInfo.enumber) {
        return 1;
      } else if (a.cloudLineInfo.enumber === b.cloudLineInfo.enumber) {
        // 第二優先のcloudServiceMenuの順：AWS、MS、GCP、Oracle、IBM、SFDCの順
        const sortBy = (e: CloudVnConnects): number => {
          switch (e.cloudLineInfo.cloudServiceMenu) {
            case "AWS":
              return 1;
            case "GCP":
              return 3;
            case "Oracle":
              return 4;
            case "IBM":
              return 5;
            case "SFDC":
              return 6;
            default:
              return 2;
          }
        };
        const sortA = sortBy(a);
        const sortB = sortBy(b);
        if (sortA === 2 && sortB === 2) {
          return (
            +a.cloudLineInfo.cloudServiceMenu!.replace("MS", "") -
            +b.cloudLineInfo.cloudServiceMenu!.replace("MS", "")
          );
        }
        return sortA - sortB;
      } else {
        return -1;
      }
    });
  },
  methods: {
    async resetting() {
      if (this.vnConnects.selected.length !== 1) {
        this.alertMsg = "VNコネクトを１つ選択してください。";
        return;
      }
      this.alertMsg = null;
      const cloudServiceMenu = this.$filter.cloudServiceMenuTo(
        this.vnConnects.selected[0].cloudLineInfo.cloudServiceMenu
      );
      await this.$confirm(
        `${cloudServiceMenu}のVNコネクト再設定を行います。よろしいですか？`
      );
      await this.$api.cloudVnResetting.postCloudVnResetting(
        this.vnConnects.selected[0].cloudVnConnectAct.wnumber
      );
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"イントラネットFW ゾーン接続 追加","classes":"modal-medium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [(_vm.isLoaded)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content my-3"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"送信元ゾーン","isInfo":false}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"vid":"sourceZone","name":"送信元ゾーン","rules":{
                required: true,
                is_not_eq: 'destinationZone',
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-select',{attrs:{"options":_vm.selectZoneOptions,"state":_vm._f("validState")(validContext)},model:{value:(_vm.srcZone),callback:function ($$v) {_vm.srcZone=$$v},expression:"srcZone"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"宛先ゾーン","isInfo":false}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"vid":"destinationZone","name":"宛先ゾーン","rules":{
                required: true,
                is_not_eq: 'sourceZone',
                duplicate_zone_relation: {
                  srcZone: _vm.srcZone,
                  relation: _vm.zoneRelation.zoneRelationList,
                },
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-select',{attrs:{"options":_vm.selectZoneOptions,"state":_vm._f("validState")(validContext)},model:{value:(_vm.dstZone),callback:function ($$v) {_vm.dstZone=$$v},expression:"dstZone"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1)],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("追加")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)])]}}],null,true)}):_vm._e()]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }

















































































































































































































































































































































































import Vue, { PropType } from "vue";
import { CloudVnGcpDetail } from "@/apis/CloudVnGcpApi";
import MultiCloudGcpVnLineEdit from "@/modals/multicloud/gcp/MultiCloudGcpVnLineEdit.vue";
import MultiCloudGcpVnConnectionConfirm from "@/modals/multicloud/gcp/MultiCloudGcpVnConnectionConfirm.vue";
import { mapState } from "vuex";
import { ContractInfo } from "@/apis/ContractApi";

export default Vue.extend({
  name: "MultiCloudGcpVnConnectionReference",
  props: {
    wnumber: {
      type: String as PropType<string>,
      required: true,
    },
    cloudLineSeq: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      cloudDetail: {} as CloudVnGcpDetail,
      isLoaded: false,
      isChanged: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
      contractSummary: "contractSummary",
    }),
    /** 開始日が未来日の場合,true*/
    isFutureDayContract(): boolean {
      return !this.contractInfo.contractList.find(
        (e: ContractInfo["contractList"][0]) =>
          e.enumber === this.cloudDetail.enumber
      );
    },
  },
  methods: {
    async load() {
      this.cloudDetail = await this.$api.cloudVnGcp.getVnConnect(this.wnumber);
    },
    async showVNConnectDelete() {
      await this.alertClear();
      // 削除確認モーダルの表示
      await this.$modal.show(MultiCloudGcpVnConnectionConfirm, {
        cloudVnGcpDetail: this.cloudDetail,
        displayType: "D",
      });
      // 削除処理
      await this.$api.cloudVnGcp.deleteVnConnect(this.cloudLineSeq, {
        cloudVnConnectList: this.cloudDetail.cloudVnConnectSby?.wnumber
          ? [
              this.cloudDetail.cloudVnConnectAct.wnumber,
              this.cloudDetail.cloudVnConnectSby.wnumber,
            ]
          : [this.cloudDetail.cloudVnConnectAct.wnumber],
        cloudVnGcpKeyId: this.cloudDetail.cloudVnGcpKeyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    async showVNConnectModify() {
      await this.alertClear();
      await this.$modal.show(MultiCloudGcpVnLineEdit, {
        cloudLineSeq: this.cloudLineSeq,
        cloudVnGcpDetail: this.cloudDetail,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    async executed() {
      this.isChanged = true;
      await this.load();
    },
    /** エラーメッセージを初期化する */
    async alertClear() {
      (
        this.$refs.operationCommandAct as Vue & { alertClear: () => void }
      ).alertClear();
      if (this.cloudDetail.cloudVnConnectSby) {
        (
          this.$refs.operationCommandSby as Vue & { alertClear: () => void }
        ).alertClear();
      }
    },
  },
});
























































































































































import Vue, { PropType } from "vue";
import {
  PutType1InternetAddress,
  Type1PrivateSite,
  Type1PrivateSiteModify,
  Type1Zone,
} from "@/apis/Type1SiteApi";

export default Vue.extend({
  name: "PrivateSiteModify",
  props: {
    enumber: {
      type: String,
      required: true,
    },
    siteList: {
      type: [] as PropType<Type1PrivateSite>,
      required: true,
    },
    zoneList: {
      type: [] as PropType<Type1Zone[]>,
      required: true,
    },
    type1SiteZoneKeyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /** 詳細データ */
      privateData: [] as PutType1InternetAddress[],
      /** ゾーン用オプション */
      zoneOptions: [] as { value: string | null; text: string }[],
    };
  },

  computed: {
    /** 既存のIPアドレス */
    existsAddress() {
      // 既存のアドレスリストに同じIPアドレスが存在した場合はエラーとする
      return (index: number) => {
        return this.privateData
          .filter((_, num) => num !== index)
          .map((v) => v.ipAddress);
      };
    },
    /** アドレス名入力済みの場合は,true */
    hasInputIpAddressName(): boolean {
      return this.privateData.some((v) => v.ipAddressName);
    },
    /** ゾーン配置選択済みの場合は,true */
    hasCheckZone(): boolean {
      return this.privateData.some((v) => v.zoneId);
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    /**
     * 初期化機能
     * システム上の配置状態追加(addressList)
     */
    load() {
      // アドレス０件の場合デフォルトリスト追加
      if (this.siteList.addressList.length < 1) {
        this.privateData = [
          {
            ipAddressId: null,
            ipAddressName: "",
            ipAddress: "",
            zoneId: null,
            isDeploy: false,
          },
        ];
      } else {
        // システム上の配置状態追加
        this.privateData = this.siteList.addressList.map((e) => ({
          ...e,
          isDeploy: !!e.zoneId,
        }));
      }
      // ゾーン配置プルダウンの設定
      this.zoneOptions = this.zoneList.map((e) => ({
        value: e.zoneId,
        text: e.zoneName,
      }));
      this.zoneOptions.unshift({ value: null, text: "" });
    },
    /** 変更機能 */
    async submit() {
      await this.$confirm(
        "プライベートサイト設定を変更します。よろしいですか？"
      );
      if (this.privateData.length === 1 && !this.privateData[0].ipAddress) {
        this.privateData = [];
      }
      // 変更データの設定
      const reqData: Type1PrivateSiteModify = {
        site: {
          siteName: this.siteList.siteName,
          addressList: this.privateData.map((e) => ({
            ipAddressId: e.ipAddressId,
            ipAddressName: e.ipAddressName || null,
            ipAddress: e.ipAddress,
            zoneId: e.zoneId,
          })),
        },
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      };
      await this.$api.type1SiteZone.postType1PrivateSiteModify(
        reqData,
        this.enumber,
        this.siteList.siteId
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * IPAddress追加機能
     * 押下時一行追加
     */
    addIPAddress() {
      this.privateData.push({
        ipAddressId: null,
        ipAddressName: "",
        ipAddress: "",
        zoneId: null,
        isDeploy: false,
      });
    },
    /**
     * IPAddress削除機能
     * 押下時その行削除
     * 一行しかない場合その行クリア
     */
    removeIPAddress(target: PutType1InternetAddress) {
      if (this.privateData.length > 1) {
        this.privateData.splice(this.privateData.indexOf(target), 1);
      } else {
        this.privateData = [
          {
            ipAddressId: null,
            ipAddressName: "",
            ipAddress: "",
            zoneId: null,
            isDeploy: false,
          },
        ];
      }
    },
  },
});

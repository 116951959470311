

















































































































































































































































































































import Vue, { PropType } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { DefaultServiceIntranetfw } from "@/apis/ServiceApi";
import {
  CustomServiceIntranet,
  CustomServiceIntranetfwList,
} from "@/apis/CustomServiceApi";
import MultiSelectTag from "@/components/MultiSelectTag.vue";
import { IntraPolicy } from "@/apis/IntranetfwPolicyApi";
import IntranetFwPolicyRuleConfirm from "@/modals/applianceContractSetting/IntranetFwPolicyRuleConfirm.vue";
import { PfgwZoneType } from "@/modals/applianceContractSetting/IntranetFwPfgwZoneSelectList.vue";
import { PfgwZone } from "@/apis/PfgwZoneApi";

// デフォルト/カスタムを識別するためのIDを付与した型
type EditDefaultService = DefaultServiceIntranetfw["defaultServiceList"][0] & {
  _id: string;
};
type EditCustomService = CustomServiceIntranet & {
  _id: string;
};

/** ルール編集のフォーム用に定義した型 */
type EditIntranetFwPolicy = Omit<
  IntraPolicy,
  "serviceList" | "customServiceList" | "policyObjectId"
> & {
  /** ポリシーオブジェクトID そのままだとnull許容しないので再度定義 */
  policyObjectId: string | null;
  /** デフォルトサービスリスト & カスタムサービスリスト */
  serviceList: (EditDefaultService | EditCustomService)[];
};

export default Vue.extend({
  name: "IntranetFwPfgwPolicyRuleEdit",
  components: { MultiSelectTag },
  props: {
    /** 送信元ゾーン */
    srcZone: {
      type: String as PropType<PfgwZoneType>,
      required: true,
    },
    /** 変更対象ポリシー 新規作成の場合は不要 */
    policy: {
      type: Object as PropType<IntraPolicy | undefined>,
      required: false,
      default: undefined,
    },
    /** E番号(ACT) */
    enumberAct: {
      type: String as PropType<string>,
      required: true,
    },
    /** ポリシー全体 ポリシーIDの重複チェックで必要 変更時は不要 */
    allPolicy: {
      type: Array as PropType<IntraPolicy[] | undefined>,
      required: false,
      default: undefined,
    },
  },
  data() {
    const clonePolicy = cloneDeep(this.policy);
    const form = (
      clonePolicy
        ? // 更新
          {
            ...clonePolicy,
            serviceList: [
              ...clonePolicy.customServiceList.map((e) => ({
                _id: `custom_${e.customServiceSeq}`,
                serviceName: e.customServiceName,
                _sortKey: "custom",
                ...e,
              })),
              ...clonePolicy.serviceList.map((e) => ({
                _id: `default_${e.serviceSeq}`,
                _sortKey: "default",
                ...e,
              })),
            ],
          }
        : // 新規
          {
            policyObjectId: null,
            policyId: "",
            srcAddressList: [],
            dstAddressList: [],
            serviceList: [],
            actionType: "ALLOW",
            isPolicyStatus: true,
            description: "",
            policyCategoryInfo: "CUSTOM_POLICY",
          }
    ) as EditIntranetFwPolicy;
    return {
      form: form,
      // 送信元、宛先（any/アドレス）
      srcAddresses: [] as
        | PfgwZone["wvs2AddressList"]
        | PfgwZone["kcpsAddressList"],
      dstAddresses: [] as
        | PfgwZone["wvs2AddressList"]
        | PfgwZone["kcpsAddressList"],
      // デフォルトサービス,カスタムサービス
      defaultServices: [] as DefaultServiceIntranetfw["defaultServiceList"],
      customServices:
        [] as CustomServiceIntranetfwList["customServiceIntranetList"],
      serviceOption: (!clonePolicy || form.serviceList.length === 0
        ? "ANY"
        : "SELECT") as "ANY" | "SELECT",
      isLoaded: false,
    };
  },
  computed: {
    /** true: 編集, false: 新規 */
    isEdit(): boolean {
      return !!this.policy;
    },
    /** 送信元アドレスのSelectの選択肢 */
    srcAddressOptions(): {
      label: string;
      values: PfgwZone["wvs2AddressList"] | PfgwZone["kcpsAddressList"];
    }[] {
      return this.srcAddresses.length !== 0
        ? [
            {
              label: "全選択",
              values: this.srcAddresses,
            },
          ]
        : [];
    },
    /** 宛先アドレスのSelectの選択肢 */
    dstAddressOptions(): {
      label: string;
      values: PfgwZone["wvs2AddressList"] | PfgwZone["kcpsAddressList"];
    }[] {
      return this.dstAddresses.length !== 0
        ? [
            {
              label: "全選択",
              values: this.dstAddresses,
            },
          ]
        : [];
    },
    /** デフォルトサービス/個別サービスSelectの選択肢 */
    serviceOptions(): {
      label: string;
      values: EditDefaultService[] | EditCustomService[];
    }[] {
      const options = [];
      // 個別サービス
      if (this.customServices.length !== 0) {
        options.push({
          label: "個別サービス（全選択）",
          values: this.customServices.map((e) => ({
            _id: `custom_${e.customServiceSeq}`,
            ...e,
          })),
        });
      }
      // デフォルトサービス
      if (this.defaultServices.length !== 0) {
        options.push({
          label: "定義済みサービス（全選択）",
          values: this.defaultServices.map((e) => ({
            _id: `default_${e.serviceSeq}`,
            ...e,
          })),
        });
      }

      return options;
    },
    /** 既存のポリシーID */
    existsPolicyIds(): string[] {
      // 既存のポリシーに同じポリシーIDが存在した場合はエラーとする
      return this.allPolicy!.map((v) => v.policyId);
    },
  },
  async mounted() {
    // 送信元アドレス・宛先アドレスの取得
    await this.getPfgwAddress();
    // デフォルト・カスタムサービスの取得
    await this.getServiceList();
    this.isLoaded = true;
  },
  methods: {
    /** PFGWのアドレス情報を取得 */
    async getPfgwAddress() {
      // PFGWゾーンAPIからアドレス情報を取得する
      const { wvs2AddressList, kcpsAddressList } =
        await this.$api.pfgwZoneRelation.getPfgwZone(this.enumberAct);

      if (this.srcZone === "WVS2") {
        // 送信元ゾーンがWVS2の場合は、送信元アドレスリストにWVS2アドレス、宛先アドレスリストにKCPSのアドレスを設定
        this.srcAddresses = wvs2AddressList.sortBy(["ipAddress", "asc"]);
        this.dstAddresses = kcpsAddressList.sortBy(["ipAddress", "asc"]);
      } else {
        // 送信元ゾーンがKCPSの場合は、送信元アドレスリストにKCPSアドレス、宛先アドレスリストにWVS2のアドレスを設定
        this.srcAddresses = kcpsAddressList.sortBy(["ipAddress", "asc"]);
        this.dstAddresses = wvs2AddressList.sortBy(["ipAddress", "asc"]);
      }
    },

    /** デフォルト・カスタムサービスリストの取得 */
    async getServiceList() {
      // デフォルトサービスの取得 サービス名の昇順にソート
      this.defaultServices = (
        await this.$api.serviceApi.getDefaultServiceIntranetfw()
      ).defaultServiceList.sortBy(["serviceName", "asc"]);
      // カスタムサービスの取得 サービス名の昇順にソート
      this.customServices = (
        await this.$api.customServiceApi.getIntranetfwCustomService(
          "INTRANET_FW_PFGW",
          this.enumberAct
        )
      ).customServiceIntranetList.sortBy(["serviceName", "asc"]);
    },

    /** ルール追加・編集の完了 */
    async submit() {
      // 選択したデータのバックアップ
      const bkSrcAddresses = this.form.srcAddressList;
      const bkDstAddresses = this.form.dstAddressList;
      const bkServiceList = this.form.serviceList;

      // anyを選択している場合にリストをクリアする
      if (this.serviceOption === "ANY") this.form.serviceList = [];

      // 更新用にデータを整形
      const policy = {
        ...this.form,
        // デフォルト、カスタムサービスリストを分解
        serviceList: this.form.serviceList.filter((e) => "serviceSeq" in e),
        customServiceList: this.form.serviceList.filter(
          (e) => "customServiceSeq" in e
        ),
      };

      // anyを選択して確認画面でキャンセルしても、選択した値が保持されるようにバックアップした値から復元する
      this.form.srcAddressList = bkSrcAddresses;
      this.form.dstAddressList = bkDstAddresses;
      this.form.serviceList = bkServiceList;

      // 確認モーダルの表示
      await this.$modal.show(IntranetFwPolicyRuleConfirm, {
        policy: policy,
      });

      // モーダルを閉じて編集したデータを遷移元に渡す
      (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(policy);
    },

    /** サービスラベル名（検索用） */
    serviceNameLabel(option: EditCustomService | EditDefaultService): string {
      if (option.protocol === "IP") {
        return `${option.serviceName} ${option.protocol} ${option.protocolNumber}`;
      } else if (option.protocol === "ICMP") {
        return `${option.serviceName} ${option.protocol} ${option.icmpType}(${option.icmpCode})`;
      } else {
        return `${option.serviceName} ${option.protocol} ${option.portNumber}`;
      }
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** ヘルスチェックAPI */
export default class HealthCheckApi extends AbstractBaseApi {
  async healthCheck(): Promise<void> {
    // TODO: 商用で問題が出ているため一時的に戻し
    await this.api.get("/healthcheck");
    // // メンテナンス中はAPIが503を返してくるがCORSのOPTIONSまで503を返してくるためシステムエラーになってしまう
    // // 上記を回避するためヘルスチェックだけは自ドメインからリバースプロキシしてもらっている
    // await this.api.get(process.env.VUE_APP_HEALTH_CHECK, { baseURL: "/" });
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-large","title":"VNコネクト設定未完了一覧"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"m-3"},[_c('div',{staticClass:"btn-container justify-content-end mb-2"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.resetting}},[_vm._v("VNコネクト再設定")])],1),_c('b-alert',{attrs:{"variant":"warning","show":!!_vm.alertMsg}},[_vm._v(_vm._s(_vm.alertMsg))]),_c('app-table',{attrs:{"primary-key":"cloudVnConnectAct.wnumber","fields":_vm.vnConnects.fields,"items":_vm.cloudVnResetting.cloudVnConnectsList,"selectable":true,"hover":true,"pagination":false,"thead-class":"text-center"},scopedSlots:_vm._u([{key:"cell(enumber)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cloudLineInfo.enumber)+" ")]}},{key:"cell(cloudServiceMenu)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cloudServiceMenuTo")(item.cloudLineInfo.cloudServiceMenu))+" ")]}},{key:"cell(wnumber)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cloudVnConnectAct.wnumber)),_c('br'),(item.cloudVnConnectSby)?[_vm._v(_vm._s(item.cloudVnConnectSby.wnumber))]:[_vm._v("-")]]}},{key:"cell(vnConnectName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cloudVnConnectAct.vnConnectName)),_c('br'),(item.cloudVnConnectSby)?[_vm._v(_vm._s(item.cloudVnConnectSby.vnConnectName))]:[_vm._v("-")]]}},{key:"cell(vpnVnCode)",fn:function(ref){
var item = ref.item;
return [(item.cloudVnConnectCommon.vpnVnCode.charAt(0) !== 'B')?[_vm._v(" "+_vm._s(((item.cloudVnConnectCommon.vpnVnCode) + "(" + (item.cloudVnConnectCommon.vnType) + ")"))+" ")]:[_vm._v(" "+_vm._s(item.cloudVnConnectCommon.vpnVnCode)+" ")]]}}],null,true),model:{value:(_vm.vnConnects.selected),callback:function ($$v) {_vm.$set(_vm.vnConnects, "selected", $$v)},expression:"vnConnects.selected"}})],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("戻る")])],1)]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
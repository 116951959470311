




















































































































































































































import Vue, { PropType } from "vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { BandwidthList } from "@/modals/accessLine/AccessLineModify.vue";
import { BandwidthListGetEntity } from "@/apis/AccessLineApi";

export default Vue.extend({
  name: "AccessLineModifyConfirm",
  props: {
    /** 変更前リスト */
    accessList: {
      type: Array as PropType<BandwidthList[]>,
      required: true,
    },
    /** 変更後リスト */
    modifiedAccessList: {
      type: Array as PropType<BandwidthList[]>,
      required: true,
    },
  },
  data() {
    return {
      accessLine: {
        fields: [
          {
            key: "enumberAct",
            label: "回線番号",
          },
          {
            key: "menu",
            label: "メニュー",
          },
          {
            key: "bandwidthType",
            label: "帯域タイプ",
          },
          {
            key: "fixingBandwidth",
            label: "帯域固定",
          },
          {
            key: "bandwidth",
            label: "帯域値",
          },
          {
            key: "qos",
            label: "QoS",
          },
          {
            key: "vlanControl",
            label: "VLAN制御",
          },
          {
            key: "office",
            label: "事業所名",
          },
        ],
        items: [] as BandwidthList[],
      },
      burstType: [
        { value: "BURST10", text: "バースト10" },
        { value: "BURST100", text: "バースト100" },
      ],
      /** オーダー備考 */
      note: null as string | null,
    };
  },
  mounted() {
    this.accessLine.items = this.modifiedAccessList;
  },
  computed: {
    /** 一覧の表示項目 */
    displayFields(): BvTableFieldArray {
      if (this.$service.permission.isNormalUser()) {
        /** 法人権限で帯域固定非表示処理 */
        return this.accessLine.fields.filter(
          (v) => (v as { key: string }).key !== "fixingBandwidth"
        );
      }
      return this.accessLine.fields;
    },
  },
  methods: {
    /** 帯域値赤字処理 */
    changedBandwidth(index: number): string {
      return this.accessList[index].bandwidth?.value !==
        this.accessLine.items[index].bandwidth?.value ||
        this.accessList[index].bandwidth?.unit !==
          this.accessLine.items[index].bandwidth?.unit ||
        this.accessList[index].bandwidthType !==
          this.accessLine.items[index].bandwidthType
        ? "changed-content"
        : "content";
    },
    /**
     * ETHERNET2/ETHERNET2_PFで且つ広帯域(10G/100G)の場合、true
     */
    isEthernet2Broadband(item: BandwidthListGetEntity): boolean {
      return (
        (item.menu === "ETHERNET2" || item.menu === "ETHERNET2_PF") &&
        (item.physicalInterface === "IF_10G" ||
          item.physicalInterface === "IF_100G")
      );
    },
    async submit() {
      (this.$refs.modal as Vue & { ok: (e: string | null) => void }).ok(
        this.note
      );
    },
  },
});

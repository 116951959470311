





























import Vue, { PropType } from "vue";
import { ApiRequestError } from "@/error/errors";

export default Vue.extend({
  name: "ApiNotFoundModal",
  props: {
    /** エラー. 指定が無い場合は固定メッセージ表示 */
    error: {
      type: ApiRequestError as PropType<ApiRequestError>,
      required: false,
    },
  },
  computed: {
    /** true: GETリクエスト or エラー指定無し, false: GETリクエストではない */
    isGET(): boolean {
      return this.error
        ? this.error.axiosError.config.method?.toUpperCase() === "GET"
        : true;
    },
  },
  methods: {
    refresh() {
      window.location.reload();
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** イントラネットFW契約情報 */
export interface IntranetfwServiceMenu {
  /**
   * イントラネットFW契約排他情報
   * 排他確認用パラメータ。内容はブラックボックス。
   * PUTメソッドにて変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  contractIntranetFwKeyId: string;
  /** VPNコード */
  vpnCode: string;
  /** 帯域値. イントラネットFWの帯域を指定する。 */
  intranetFwBandwidth: {
    /** 設定値 */
    value: number;
    /** 単位. KBPS:Kbps MBPS:Mbps GBPS:Gbps*/
    unit: "KBPS" | "MBPS" | "GBPS";
  };

  /**
   * アクセス回線リスト
   * アクセス回線が存在しない場合、空のリストとなる。
   * E番号で昇順ソートされて格納される。
   */
  accessLineList: AccessLine[];

  /**
   * リモートアクセスゲートウェイリスト
   * リモートアクセスゲートウェイが存在しない場合、空のリストとなる。
   * E番号(ACT)で昇順ソートされて格納される。
   */
  remoteAccessGwList: RemoteAccessGw[];

  /**
   * プラットフォームゲートウェイリスト
   * プラットフォームゲートウェイが存在しない場合、空のリストとなる。
   * E番号(ACT)で昇順ソートされて格納される。
   */
  pfgwList: Pfgw[];
}

/** イントラネットFW契約更新 */
export interface IntranetfwServiceMenuPost {
  /**
   * イントラネットFW契約排他情報
   * 排他確認用パラメータ。内容はブラックボックス。
   * PUTメソッドにて変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  contractIntranetFwKeyId: string;
  /** 帯域値. イントラネットFWの帯域を指定する。 */
  intranetFwBandwidth: {
    /** 設定値 */
    value: number;
    /** 単位. KBPS:Kbps MBPS:Mbps GBPS:Gbps*/
    unit: "KBPS" | "MBPS" | "GBPS";
  };

  /**
   * アクセス回線リスト
   * アクセス回線の割り当て帯域の変更を行う。
   * 変更する回線および変更なしの回線をリストに含める。
   * 削除する回線は、リストに含めない。
   * 全アクセス回線廃止済みの場合は、空のリストとなる。
   */
  accessLineList: AccessLine[];
  /**
   * リモートアクセスゲートウェイリスト
   * リモートアクセスゲートウェイの割り当て帯域の変更を行う。
   * 変更する回線および変更なしの回線をリストに含める。
   * 削除する回線は、リストに含めない。
   * 全リモートアクセスゲートウェイ廃止済みの場合は、空のリストとなる。
   */
  remoteAccessGwList: Pick<RemoteAccessGw, "bandwidthActSby" | "enumberAct">[];

  /**
   * アクセス回線リスト
   * アクセス回線の割り当て帯域の変更を行う。
   * 変更する回線および変更なしの回線をリストに含める。
   * 削除する回線は、リストに含めない。
   * 全アクセス回線廃止済みの場合は、空のリストとなる。
   */
  pfgwList: Pick<Pfgw, "bandwidthActSby" | "enumberAct">[];

  /** 操作履歴の備考 */
  note: string | null;
}

/** イントラネットFW契約削除 */
export type IntranetfwServiceMenuDelete = Pick<
  IntranetfwServiceMenuPost,
  "contractIntranetFwKeyId" | "note"
>;

/** アクセス回線 */
export interface AccessLine {
  /** 帯域値. 割当帯域を指定する。 */
  bandwidth: {
    /** 設定値*/
    value: number;
    /** 単位. KBPS:Kbps MBPS:Mbps GBPS:Gbps */
    unit: "KBPS" | "MBPS" | "GBPS";
  };
  /** E番号 */
  enumber: string;
  /** 変更時チェックボックス用 */
  isCheck?: boolean;
}

/** リモートアクセスGW */
export interface RemoteAccessGw {
  /**
   * 帯域値
   * ActとSbyで共有される割当帯域を指定する。
   * ※Actの割当帯域値、SbyはActと共有される。
   */
  bandwidthActSby: {
    /** 設定値*/
    value: number;
    /** 単位. KBPS:Kbps MBPS:Mbps GBPS:Gbps */
    unit: "KBPS" | "MBPS" | "GBPS";
  };
  /** E番号(ACT) */
  enumberAct: string;
  /** E番号(SBY) */
  enumberSby: string;
  /**
   * メニュー
   * REMOTE_ACCESS_GW2_FRE：リモートアクセスGW(FRE)
   * REMOTE_ACCESS_GW2_CRG：リモートアクセスGW(CRG)
   * REMOTE_ACCESS_GW2_CPA：リモートアクセスGW(CPA)
   */
  menu:
    | "REMOTE_ACCESS_GW2_FRE"
    | "REMOTE_ACCESS_GW2_CRG"
    | "REMOTE_ACCESS_GW2_CPA";
  /**
   * 契約種別
   * BESTEFFORT：ベストエフォート
   * BANDWIDTH_SECURED：帯域確保
   */
  contractType: "BESTEFFORT" | "BANDWIDTH_SECURED";
  /** 帯域値. 契約帯域 帯域確保の場合は必須となる。 */
  contractBandwidth: {
    /** 設定値*/
    value: number;
    /** 単位. KBPS:Kbps MBPS:Mbps GBPS:Gbps */
    unit: "KBPS" | "MBPS" | "GBPS";
  } | null;
  /** 変更時チェックボックス用 */
  isCheck?: boolean;
}

/** PFGW */
export interface Pfgw {
  /** 帯域値
   * ActとSbyに設定する割当帯域を指定する。
   * ※Act/Sbyに同じ値が設定される。(設定値の2倍の帯域が必要)
   */
  bandwidthActSby: {
    /** 設定値*/
    value: number;
    /** 単位. KBPS:Kbps MBPS:Mbps GBPS:Gbps */
    unit: "KBPS" | "MBPS" | "GBPS";
  };
  /** E番号(ACT) */
  enumberAct: string;
  /** E番号(SBY) */
  enumberSby: string;
  /** 事業所名(拠点名) */
  office: string;
  /** WVS2-KCPS間ゾーン関係ID. WVS2ゾーンからKCPSゾーン方向への結線ID */
  wvs2ToKcpsZoneRelationId: string | null;
  /** KCPS-WVS2間ゾーン関係ID. KCPSゾーンからWVS2ゾーン方向への結線ID */
  kcpsToWvs2ZoneRelationId: string | null;
  /** 変更時チェックボックス用 */
  isCheck?: boolean;
}

export default class IntranetfwServiceMenuApi extends AbstractBaseApi {
  /**
   * アプライアンス（イントラネットFW）の契約情報を取得
   */
  async getIntranetfwServiceMenu(): Promise<IntranetfwServiceMenu> {
    return (await this.api.get("/v1/contract/service/appliance/intranetfw"))
      .data;
  }

  /**
   * アプライアンス（イントラネットFW）の契約変更
   * @param data アプライアンス契約更新情報
   */
  async postIntranetfwServiceMenu(
    data: IntranetfwServiceMenuPost
  ): Promise<void> {
    await this.api.post("/v1/contract/service/appliance/intranetfw", data);
  }

  /**
   * アプライアンス（イントラネットFW）の契約削除
   * @param data 削除情報
   */
  async deleteIntranetfwServiceMenu(
    data: IntranetfwServiceMenuDelete
  ): Promise<void> {
    await this.api.post(
      "/v1/contract/service/appliance/intranetfw/delete",
      data
    );
  }
}








import Vue, { PropType } from "vue";
import c3, { ChartAPI } from "c3";
import { DisplayRange } from "@/apis/TrafficApi";

export interface GraphData {
  /** x軸：表示時間のリスト */
  timeList: string[];
  /** 奇数色で表示するデータリスト */
  dataListOdd?: GraphLine[];
  /** 偶数色で表示するデータリスト */
  dataListEven?: GraphLine[];
  /** y軸のラベル Trafficグラフの場合、設定不要*/
  yLabel?: string;
  /** 表示範囲("DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY") */
  displayRange: DisplayRange;
}

export interface GraphLine {
  /** 線の表示名称 */
  label: string;
  /** グラフに表示したい値 */
  data: (string | number | null)[];
}

export default Vue.extend({
  name: "AppGraph",
  inheritAttrs: false,
  props: {
    /** グラフ表示用データの生成と各線の色を指定
     * timeList    ：timestampのリスト（X軸用）
     * dataListOdd ：奇数色線用データ、最大５件
     * dataListEven：偶数色線用データ、最大５件
     *
     *
     * 例：
     *   {
     *     timeList: ["2021-12-01T23:59:59+09:00", "2021-12-02T23:59:59+09:00", "2021-12-03T23:59:59+09:00"],
     *     dataListOdd: [
     *       { label: "E000000010 : office : 通信速度(平均)", data: [10, 20, 30] },
     *       { label: "E000000011 : office : 通信速度(平均)", data: [30, 40, 50] },
     *       ...
     *       ５件まで
     *     ]
     *    dataListEven: [
     *       { label: "E000000010 : office : 通信速度(最大)", data: [40, 40, 60] },
     *       { label: "E000000011 : office : 通信速度(最大)", data: [60, 80, 100] },
     *       ...
     *       ５件まで
     *     ],
     *     yLabel: "Kbps",
     *     displayRange: "DAILY";
     *   }
     *
     *   注意：
     *   １：歯抜けデータの処理は親画面の方で実施する想定
     *   ２：要件のY軸の単位変換は各要件より親画面で実施する想定
     *   ３：X軸の表示フォーマットはdisplayRangeより異なる
     *   ４：奇数色系のデータリスト(dataListOdd)、偶数色系のデータリスト(dataListEven)それぞれ５件まで設定可能
     *
     * */
    graphData: {
      type: Object as PropType<GraphData>,
      required: true,
    },
    /** true: TRAFFIC用、false:TRAFFIC以外 */
    isTraffic: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** true: 表示、false:非表示 */
    showLegend: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Legendの表示位置 */
    legendPosition: {
      type: String as PropType<"bottom" | "right">,
      default: "right",
    },
    /** 画面幅制限よりX軸を減便表示したい場合、trueを指定
     * trueの場合、年次グラフのX軸の表示件数が6件まで表示 */
    reduceType: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      // Chart Object
      chartApi: {} as ChartAPI,
      // Chart表示用のデータ
      displayData: [] as Array<[string, ...(string | number | null)[]]>,
      // Chart表示用のColor(表示データ生成時設定)
      displayColor: {} as { [key: string]: string },
      // Colorグループ奇数
      colorGroupOdd: ["#5AD3DB", "#00ff22", "#e8a48e", "#ab72fc", "#ff1f2c"],
      // Colorグループ偶数
      colorGroupEven: ["#6183B1", "#007200", "#da6c47", "#752bfa", "#ff747c"],
      // Y軸の目盛リスト
      yTickList: [] as string[],
    };
  },
  mounted() {
    this.generateChartData();
    // Y軸目盛生成
    this.generateYTick();
    this.drawGraph();
  },
  computed: {
    /** Y軸のラベル(Trafficグラフのみ使用) */
    trafficYLabel(): string {
      if (this.maxValue >= 5000001) {
        return "Mbps";
      } else {
        return "Kbps";
      }
    },
    /** X軸の表示ラベル生成用データリスト */
    timesLabel(): string[] {
      if (this.graphData.displayRange === "DAILY") {
        // 日次グラフの場合、バックエンドからもらったデータが5分単位
        // 2時間間隔で横軸を生成する
        const start = this.$moment(this.graphData.timeList[0]);
        return Array.range(12).map((value, index) => {
          if (index === 0) {
            return start.format("YYYY-MM-DDTHH:mm:ssZ");
          } else {
            return start.add(2, "hours").format("YYYY-MM-DDTHH:mm:ssZ");
          }
        });
      } else if (this.graphData.displayRange === "WEEKLY") {
        // 週次グラフの場合、バックエンドからもらったデータが30分単位
        // 1日間隔で横軸を生成する
        const indexList = Array.range(0, 335, 48);
        return this.graphData.timeList.filter((v, index) => {
          return indexList.some((i) => i === index);
        });
      } else if (this.graphData.displayRange === "MONTHLY") {
        // 月次グラフの場合、バックエンドからもらったデータが2時間単位
        // 3日間隔で横軸を生成する
        const indexList = Array.range(0, 359, 36);
        return this.graphData.timeList.filter((v, index) => {
          return indexList.some((i) => i === index);
        });
      } else if (this.graphData.displayRange === "YEARLY") {
        // 年次グラフの場合、バックエンドからもらったデータが日次単位
        // 31日間隔または61間隔で横軸を生成する
        const indexList = Array.range(0, 364, this.reduceType ? 61 : 31);
        return this.graphData.timeList.filter((v, index) => {
          return indexList.some((i) => i === index);
        });
      } else {
        throw new Error(
          `unsupported display range: ${this.graphData.displayRange}`
        );
      }
    },
    /** 横軸のフォーマット生成 */
    timeFormat(): string {
      switch (this.graphData.displayRange) {
        case "DAILY":
          return "%H:%M";
        case "WEEKLY":
        case "MONTHLY":
        case "YEARLY":
          return "%Y-%m-%d";
        default:
          throw new Error(
            `unsupported display range: ${this.graphData.displayRange}`
          );
      }
    },
    /** 表示データの最大値 */
    maxValue(): bigint {
      let dataList: bigint[] = [];
      if (this.graphData.dataListEven) {
        dataList = this.graphData.dataListEven
          .flatMap((v) => v.data)
          .filter((v) => v)
          .map((v) => BigInt(v));
      }
      if (this.graphData.dataListOdd) {
        dataList = [
          ...dataList,
          ...this.graphData.dataListOdd
            .flatMap((v) => v.data)
            .filter((v) => v)
            .map((v) => BigInt(v)),
        ];
      }
      if (dataList.length > 0) {
        return dataList.reduce((m, e) => (e > m ? e : m));
      } else {
        return 0n;
      }
    },
  },
  watch: {
    /** PropertyのgraphDataを監視し、変更があれば、グラフを再表示 */
    graphData() {
      // 表示用データ生成
      this.generateChartData();
      // Y軸目盛生成
      this.generateYTick();
      // グラフ表示
      this.drawGraph();
    },
  },
  methods: {
    /** Y軸の目盛を生成する */
    generateYTick() {
      if (this.isTraffic) {
        // トラフィックの場合
        // 機能仕様書_カスコン.docの表 112 目盛を参照して、Y軸の目盛を生成する
        if (this.maxValue < 10000) {
          // 10Kbps以降の場合、目盛固定
          this.yTickList = [0, 2000, 4000, 6000, 8000, 10000].map((v) =>
            v.toFixed(1)
          );
        } else {
          // 10000以上の場合、目盛を生成する
          this.yTickList = this.yTickList = this.roundOff();
        }
      } else {
        // トラフィック以外の場合
        if (this.maxValue <= 9) {
          // 目盛固定
          this.yTickList = [0, 2, 4, 6, 8, 10].map((v) =>
            v.toFixed(this.isTraffic ? 1 : 0)
          );
        } else {
          // 10以上の場合、目盛を生成する
          this.yTickList = this.roundOff();
        }
      }
    },
    /** Y軸の四捨五入
     * 上から2桁目が0~4の場合、2桁目数字が5にする
     * 上から2桁目が5~9の場合、2桁目数字を切り上げる */
    roundOff(): string[] {
      const digit = 10 ** (String(this.maxValue).length - 1);
      // bigint同士の割り算を行うと小数以下が切り捨てられてしまう.
      // そのため、上から二桁目を消されないようにさらに0.1倍したもので計算する.
      const digitish = digit / 10;
      const decimal = Number(this.maxValue / BigInt(digitish)) / 10;
      const round = Math.round(decimal);
      // nはY軸の最大値ですが、最大値がNumber型のMax:9007199254740991を超える場合、精度が落ちることがある
      const n = (round <= decimal ? round + 0.5 : round) * digit;
      return [0.0, n * 0.2, n * 0.4, n * 0.6, n * 0.8, n].map((v) =>
        v.toFixed(this.isTraffic ? 1 : 0)
      );
    },
    /** グラフ表示用データの生成と各線の色を指定
     * 生成元：
     *   {
     *     timeList: ["2021-12-01T23:59:59+09:00", "2021-12-02T23:59:59+09:00", "2021-12-03T23:59:59+09:00"],
     *     dataListOdd: [
     *       { label: "E000000010 : office : 通信速度(平均)", data: [10, 20, 30] },
     *       { label: "E000000011 : office : 通信速度(平均)", data: [30, 40, 50] },
     *       { label: "E000000012 : office : 通信速度(平均)", data: [40, 50, 60] },
     *     ]
     *    dataListEven: [
     *       { label: "E000000010 : office : 通信速度(最大)", data: [40, 40, 60] },
     *       { label: "E000000011 : office : 通信速度(最大)", data: [60, 80, 100] },
     *       { label: "E000000012 : office : 通信速度(最大)", data: [80, 100, 120] },
     *     ]
     *   }
     *
     * 生成後：
     *   [
     *    ["time", "2021-12-01T23:59:59+09:00", "2021-12-02T23:59:59+09:00", "2021-12-03T23:59:59+09:00"],
     *    ["E000000010 : office : 通信速度(平均)", 10, 20, 30],
     *    ["E000000011 : office : 通信速度(平均)", 30, 40, 50],
     *    ["E000000012 : office : 通信速度(平均)", 40, 50, 60],
     *   ]
     * */
    generateChartData() {
      if (this.graphData) {
        let dataArray: Array<[string, ...(string | number | null)[]]> = [];
        // let colorArray: {[key: string]: string} = {};
        if (this.graphData.dataListOdd) {
          dataArray = [
            ...dataArray,
            ...this.graphData.dataListOdd.map((v) => {
              // mapメソッド経由の戻り値ですが、asしないと認識できないので
              return [v.label, ...v.data] as [
                string,
                ...(string | number | null)[]
              ];
            }),
          ];
          this.graphData.dataListOdd.forEach((v, i) => {
            this.displayColor[v.label] = this.colorGroupOdd[i];
          });
        }
        if (this.graphData.dataListEven) {
          dataArray = [
            ...dataArray,
            ...this.graphData.dataListEven.map((v) => {
              // mapメソッド経由の戻り値ですが、asしないと認識できないので
              return [v.label, ...v.data] as [
                string,
                ...(string | number | null)[]
              ];
            }),
          ];
          this.graphData.dataListEven.forEach((v, i) => {
            this.displayColor[v.label] = this.colorGroupEven[i];
          });
        }
        this.displayData = [["time", ...this.graphData.timeList], ...dataArray];
      } else {
        // 受け取ったデータがない場合、データなしとする
        this.displayData = [];
      }
    },
    drawGraph() {
      this.chartApi = c3.generate({
        bindto: this.$refs.chart as HTMLElement,
        data: {
          x: "time",
          // stringからDateへ変換するフォーマット
          xFormat: "%Y-%m-%dT%H:%M:%S%Z",
          columns: this.displayData,
          empty: { label: { text: "データはありません" } },
          colors: this.displayColor,
        },
        axis: {
          x: {
            type: "timeseries",
            tick: {
              fit: true,
              values: this.timesLabel,
              // x軸表示用Dateからstringへ変換するフォーマット
              format: this.timeFormat,
            },
            padding: { left: 0 },
          },
          y: {
            // 最小値は0を表示できるため
            min: 0,
            // 全ての縦軸が表示できるため
            max: parseFloat(this.yTickList[this.yTickList.length - 1]),
            padding: { bottom: 0 },
            label: {
              text: this.isTraffic
                ? this.trafficYLabel
                : this.graphData.yLabel ?? "",
              position: "outer-middle",
            },
            tick: {
              values: this.yTickList,
              format: (d) => {
                if (this.isTraffic) {
                  if (this.maxValue >= 5000001) {
                    // Mbpsへ
                    return (d / 1000000).toFixed(1);
                  } else {
                    // Kbpsへ
                    return (d / 1000).toFixed(1);
                  }
                } else {
                  return d;
                }
              },
            },
          },
        },
        line: {
          connectNull: true,
        },
        legend: {
          show: this.showLegend,
          position: this.legendPosition as "bottom" | "right",
        },
        zoom: {
          enabled: true,
          type: "scroll",
        },
        grid: {
          x: {
            lines: this.timesLabel.map((v) => {
              return { value: v };
            }),
          },
          y: {
            show: true,
          },
        },
        tooltip: {
          format: {
            title: (x) => {
              // tooltipで表示する時刻のフォーマット
              return (
                (x as Date).toLocaleDateString() +
                " " +
                (x as Date).toLocaleTimeString()
              );
            },
            value: (x) => {
              if (this.isTraffic) {
                if (this.maxValue >= 5000001) {
                  // 5000001以上の場合、Mbpsへ変換、小数第3位まで表示
                  return `${((x as number) / 1000000).toFixed(3)}Mbps`;
                } else {
                  //  Kbpsへ変換、小数第3位まで表示
                  return `${((x as number) / 1000).toFixed(3)}Kbps`;
                }
              } else {
                return x as string;
              }
            },
          },
        },
      });
    },
  },
});








































































































import Vue from "vue";
import MultiCloudMsVnConnectionList from "@/modals/multicloud/ms/MultiCloudMsVnConnectionList.vue";
import MicrosoftRegister from "@/modals/multicloud/MicrosoftRegister.vue";
import MultiCloudBandwidthLimitModify from "@/modals/multicloud/MultiCloudBandwidthLimitModify.vue";
import { CloudLine, Line, LineList } from "@/apis/CloudLineApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import MultiCloudAwsVnConnectionList from "@/modals/multicloud/aws/MultiCloudAwsVnConnectionList.vue";
import MultiCloudOracleVnConnectionList from "@/modals/multicloud/oracle/MultiCloudOracleVnConnectionList.vue";
import MultiCloudGcpVnConnectionList from "@/modals/multicloud/gcp/MultiCloudGcpVnConnectionList.vue";
import MultiCloudIbmVnConnectionList from "@/modals/multicloud/ibm/MultiCloudIbmVnConnectionList.vue";
import MultiCloudSfdcVnConnectionList from "@/modals/multicloud/salesforce/MultiCloudSfdcVnConnectionList.vue";
import { CloudVnResetting } from "@/apis/CloudVnResettingApi";
import CloudVnResettingList from "@/modals/multicloud/CloudVnResettingList.vue";

export default Vue.extend({
  name: "MultiCloudList",
  data() {
    return {
      searchResult: {} as LineList,
      selectedLineInfo: null as null | Line,
      lineList: {
        fields: [
          { key: "enumber", label: "回線番号" },
          { key: "accessType", label: "アクセス回線メニュー" },
        ],
        items: [] as Line[],
      },
      lineCloudList: {
        fields: [
          { key: "cloudServiceMenu", label: "メニュー" },
          { key: "bandwidth", label: "帯域上限値" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
        items: [] as CloudLine[],
        selected: [] as CloudLine[],
      },
      cloudVnResettingResult: {} as CloudVnResetting,
      // 初期通信完了フラグ
      isLoaded: false,
      // エラーメッセージ
      alertMsg: null as string | null,
      // download用URL
      downloadUrl: this.$api.cloudLine.getCsvVnConnectURL(),
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.alertMsg = null;
      this.searchResult = await this.$api.cloudLine.getCloudLines();
      this.lineList.items = this.searchResult.lineList;
      // 選択済の回線情報があれば、データが変わった可能性があ流ので、再度指定する
      if (this.selectedLineInfo) {
        const currentLine = this.lineList.items.find(
          (v) => v.lineSeq === this.selectedLineInfo!.lineSeq
        );
        if (currentLine) {
          this.showCloudServices(currentLine);
        } else {
          this.selectedLineInfo = null;
        }
      }
      this.cloudVnResettingResult =
        await this.$api.cloudVnResetting.getCloudVnResetting();
    },
    showCloudServices(entity: Line) {
      this.alertMsg = null;
      this.selectedLineInfo = entity;
      this.lineCloudList.items = this.selectedLineInfo.cloudLineList;
    },
    rowHighlight(item: Line) {
      if (item.lineSeq === this.selectedLineInfo?.lineSeq)
        return "table-success";
    },
    async addMS() {
      if (this.lineCloudList.items.length >= 50) {
        this.alertMsg = this.$filter.message("max_vn_connects", {
          maxCount: 50,
        });
        return;
      }
      this.alertMsg = null;
      await this.$modal.show(MicrosoftRegister, {
        enumber: this.selectedLineInfo!.enumber,
        cloudLineList: [...this.selectedLineInfo!.cloudLineList],
        cloudLineKeyId: this.searchResult.cloudLineKeyId,
      });
      await this.load();
    },
    async deleteMS() {
      if (
        this.lineCloudList.selected.length === 0 ||
        this.lineCloudList.selected.length > 1
      ) {
        this.alertMsg = "Microsoftを１つ選択してください。";
        return;
      }
      if (
        this.lineCloudList.selected.length === 1 &&
        this.lineCloudList.items.filter((v) =>
          v.cloudServiceMenu.startsWith("MS")
        ).length === 1
      ) {
        this.alertMsg = "Microsoftが１つしかない場合、削除できません。";
        return;
      }
      this.alertMsg = null;
      const cloudServiceMenu = this.$filter.cloudServiceMenuTo(
        this.lineCloudList.selected[0].cloudServiceMenu
      );
      await this.$confirm(`${cloudServiceMenu}を削除します。よろしいですか？`);
      await this.$api.cloudLine.deleteMsCloudLine(
        this.lineCloudList.selected[0].cloudLineSeq,
        { cloudLineKeyId: this.searchResult.cloudLineKeyId }
      );
      await this.load();
    },
    async showBandwidthLimit() {
      this.alertMsg = null;
      await this.$modal.show(MultiCloudBandwidthLimitModify, {
        enumber: this.selectedLineInfo!.enumber,
        bandwidth: this.selectedLineInfo!.bandwidth,
        cloudLineList: this.selectedLineInfo!.cloudLineList,
        cloudLineKeyId: this.searchResult.cloudLineKeyId,
      });
      await this.load();
    },
    rowClass(item: CloudLine) {
      return item.bandwidth.value === 0 ? "un-clickable" : null;
    },
    showDetails(item: CloudLine) {
      if (item.bandwidth.value === 0) {
        return;
      }
      this.alertMsg = null;
      // 該当クラウドメニューの設定未完了VNコネクト有無を判断する
      const hasCloudVnResetting =
        this.cloudVnResettingResult.cloudVnConnectsList.some((v) => {
          return (
            v.cloudLineInfo.cloudServiceMenu === item.cloudServiceMenu &&
            v.cloudLineInfo.enumber === this.selectedLineInfo!.enumber
          );
        });
      const bindData = {
        cloudLineSeq: item.cloudLineSeq,
        hasCloudVnResetting: hasCloudVnResetting,
        cloudServiceMenu: item.cloudServiceMenu,
        accessLineMenuBandwidth: this.selectedLineInfo!.bandwidth,
      };
      if (item.cloudServiceMenu.startsWith("MS")) {
        this.$modal.show(MultiCloudMsVnConnectionList, bindData);
        return;
      }
      switch (item.cloudServiceMenu) {
        case "AWS":
          this.$modal.show(MultiCloudAwsVnConnectionList, bindData);
          break;
        case "Oracle":
          this.$modal.show(MultiCloudOracleVnConnectionList, bindData);
          break;
        case "GCP":
          this.$modal.show(MultiCloudGcpVnConnectionList, bindData);
          break;
        case "IBM":
          this.$modal.show(MultiCloudIbmVnConnectionList, bindData);
          break;
        case "SFDC":
          this.$modal.show(MultiCloudSfdcVnConnectionList, bindData);
          break;
      }
    },
    async showCloudVnResetting() {
      this.alertMsg = null;
      await this.$modal.show(CloudVnResettingList, {
        cloudVnResetting: this.cloudVnResettingResult,
      });
      await this.load();
    },
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
    hasSelectedLine(): boolean {
      return !!this.selectedLineInfo;
    },
  },
});

// 帯域上限値の選択肢(以降の画面に使うので)
export const maxBandwidthOptions: {
  value: { value: number; unit: "KBPS" | "MBPS" | "GBPS" | null };
  text: string;
}[] = [
  { value: { value: 0, unit: null }, text: "0Mbps" },
  { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
  { value: { value: 20, unit: "MBPS" }, text: "20Mbps" },
  { value: { value: 30, unit: "MBPS" }, text: "30Mbps" },
  { value: { value: 40, unit: "MBPS" }, text: "40Mbps" },
  { value: { value: 50, unit: "MBPS" }, text: "50Mbps" },
  { value: { value: 60, unit: "MBPS" }, text: "60Mbps" },
  { value: { value: 70, unit: "MBPS" }, text: "70Mbps" },
  { value: { value: 80, unit: "MBPS" }, text: "80Mbps" },
  { value: { value: 90, unit: "MBPS" }, text: "90Mbps" },
  { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
  { value: { value: 200, unit: "MBPS" }, text: "200Mbps" },
  { value: { value: 300, unit: "MBPS" }, text: "300Mbps" },
  { value: { value: 400, unit: "MBPS" }, text: "400Mbps" },
  { value: { value: 500, unit: "MBPS" }, text: "500Mbps" },
  { value: { value: 600, unit: "MBPS" }, text: "600Mbps" },
  { value: { value: 700, unit: "MBPS" }, text: "700Mbps" },
  { value: { value: 800, unit: "MBPS" }, text: "800Mbps" },
  { value: { value: 900, unit: "MBPS" }, text: "900Mbps" },
  { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
  { value: { value: 2, unit: "GBPS" }, text: "2Gbps" },
  { value: { value: 3, unit: "GBPS" }, text: "3Gbps" },
  { value: { value: 4, unit: "GBPS" }, text: "4Gbps" },
  { value: { value: 5, unit: "GBPS" }, text: "5Gbps" },
  { value: { value: 6, unit: "GBPS" }, text: "6Gbps" },
  { value: { value: 7, unit: "GBPS" }, text: "7Gbps" },
  { value: { value: 8, unit: "GBPS" }, text: "8Gbps" },
  { value: { value: 9, unit: "GBPS" }, text: "9Gbps" },
  { value: { value: 10, unit: "GBPS" }, text: "10Gbps" },
  { value: { value: 11, unit: "GBPS" }, text: "11Gbps" },
  { value: { value: 12, unit: "GBPS" }, text: "12Gbps" },
  { value: { value: 13, unit: "GBPS" }, text: "13Gbps" },
  { value: { value: 14, unit: "GBPS" }, text: "14Gbps" },
  { value: { value: 15, unit: "GBPS" }, text: "15Gbps" },
  { value: { value: 16, unit: "GBPS" }, text: "16Gbps" },
  { value: { value: 17, unit: "GBPS" }, text: "17Gbps" },
  { value: { value: 18, unit: "GBPS" }, text: "18Gbps" },
  { value: { value: 19, unit: "GBPS" }, text: "19Gbps" },
  { value: { value: 20, unit: "GBPS" }, text: "20Gbps" },
  { value: { value: 21, unit: "GBPS" }, text: "21Gbps" },
  { value: { value: 22, unit: "GBPS" }, text: "22Gbps" },
  { value: { value: 23, unit: "GBPS" }, text: "23Gbps" },
  { value: { value: 24, unit: "GBPS" }, text: "24Gbps" },
  { value: { value: 25, unit: "GBPS" }, text: "25Gbps" },
  { value: { value: 26, unit: "GBPS" }, text: "26Gbps" },
  { value: { value: 27, unit: "GBPS" }, text: "27Gbps" },
  { value: { value: 28, unit: "GBPS" }, text: "28Gbps" },
  { value: { value: 29, unit: "GBPS" }, text: "29Gbps" },
  { value: { value: 30, unit: "GBPS" }, text: "30Gbps" },
  { value: { value: 31, unit: "GBPS" }, text: "31Gbps" },
  { value: { value: 32, unit: "GBPS" }, text: "32Gbps" },
  { value: { value: 33, unit: "GBPS" }, text: "33Gbps" },
  { value: { value: 34, unit: "GBPS" }, text: "34Gbps" },
  { value: { value: 35, unit: "GBPS" }, text: "35Gbps" },
  { value: { value: 36, unit: "GBPS" }, text: "36Gbps" },
  { value: { value: 37, unit: "GBPS" }, text: "37Gbps" },
  { value: { value: 38, unit: "GBPS" }, text: "38Gbps" },
  { value: { value: 39, unit: "GBPS" }, text: "39Gbps" },
  { value: { value: 40, unit: "GBPS" }, text: "40Gbps" },
  { value: { value: 41, unit: "GBPS" }, text: "41Gbps" },
  { value: { value: 42, unit: "GBPS" }, text: "42Gbps" },
  { value: { value: 43, unit: "GBPS" }, text: "43Gbps" },
  { value: { value: 44, unit: "GBPS" }, text: "44Gbps" },
  { value: { value: 45, unit: "GBPS" }, text: "45Gbps" },
  { value: { value: 46, unit: "GBPS" }, text: "46Gbps" },
  { value: { value: 47, unit: "GBPS" }, text: "47Gbps" },
  { value: { value: 48, unit: "GBPS" }, text: "48Gbps" },
  { value: { value: 49, unit: "GBPS" }, text: "49Gbps" },
  { value: { value: 50, unit: "GBPS" }, text: "50Gbps" },
];

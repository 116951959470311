import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 半角の英数字、symbolの値以外入力できないこと
 */
export default {
  params: ["symbol"],
  validate(
    value: string | undefined | null,
    { symbol }: { symbol: string }
  ): boolean {
    if (!value) {
      return true;
    }

    /** 半角の英数字、symbolの値以外の入力できないこと */
    const reg = new RegExp("[^0-9a-zA-Z" + symbol + "]");

    return !reg.test(value);
  },
} as ValidationRuleSchema;

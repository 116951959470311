import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 先頭Dから始まる半角英字2文字+半角数字4桁で入力されること */

export default {
  validate(value: string | undefined | null): boolean {
    if (value) {
      return /^D[A-Z]\d{4}$/.test(value);
    }
    return true;
  },
} as ValidationRuleSchema;

import { diff } from "deep-object-diff";

/**
 * Object変更有無Validation
 * @param original 変更前Object
 * @param target 変更後Object
 * @returns true:変更がある false:変更がない
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function objectChanged<T extends object>(
  original: T,
  target: T
): boolean {
  const changedObject: string[] = Object.keys(diff(original, target));
  return changedObject && changedObject.length > 0;
}

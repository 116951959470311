























import Vue, { PropType } from "vue";

/** ファイルアップロードエリアを表示する。アップロードされたファイルはinputでemitされる */
export default Vue.extend({
  name: "FileUpload",
  props: {
    /** アップロード可能なファイル拡張子, カンマ区切り可能 */
    ext: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      alert: null as null | string,
    };
  },
  methods: {
    input(event: Event | DragEvent) {
      this.alert = null;

      const file: File | null = ((): File | null => {
        if (event instanceof DragEvent) {
          return event.dataTransfer?.files?.[0] ?? null;
        } else {
          return (event.target as HTMLInputElement | null)?.files?.[0] ?? null;
        }
      })();

      if (!file) {
        this.$emit("input", null);
        return;
      }
      if (file.size === 0) {
        this.alert =
          "指定されたファイルが存在しません。あるいは0バイトのファイルです。";
        return;
      }
      if (
        this.ext &&
        !this.ext
          .split(",")
          .some((ext) => file.name.toLowerCase().endsWith(ext))
      ) {
        this.alert = "ファイルの形式が正しくありません。";
        return;
      }

      this.$emit("input", file);
    },
  },
});

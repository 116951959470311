/** 空データを別の値に変換して表示したい用 */
export default function emptyTo(
  value: unknown,
  defaultValue: unknown
): unknown {
  // TODO: 必要になったら配列とかも対応するかも
  if (value) {
    return value;
  } else {
    return defaultValue;
  }
}

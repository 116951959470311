



































import Vue, { PropType } from "vue";
import ConnectionInfoTable from "@/components/extranet/ConnectionInfoTable.vue";
import { ExtranetInfo } from "@/apis/ExtranetApi";

export default Vue.extend({
  name: "ExtranetDisconnectConfirm",
  components: { ConnectionInfoTable },
  props: {
    confirmData: {
      type: Object as PropType<ExtranetInfo>,
      required: true,
    },
  },
});


import Vue from "vue";

export default Vue.extend({
  name: "MixinSideMenu",
  data() {
    return {
      collapse: localStorage.getItem("sideMenu.collapse") === "true",
    };
  },
  watch: {
    /** 開閉情報をLocalStorageに保存 */
    collapse(newValue: boolean) {
      localStorage.setItem("sideMenu.collapse", newValue.toString());
    },
  },
});

























































































































































































































































































































































































































































































































































































import Vue from "vue";
import MultiSelectTag from "@/components/MultiSelectTag.vue";
import {
  QueryParamEntity,
  UpperLimitEntity,
  UpperLimitLineGetEntity,
  UpperLimitLineSearchGetEntity,
  UpperLimitSearchAccessLine,
  UpperLimitSearchCloud,
  UpperLimitSearchInternet,
  UpperLimitSearchPfgw,
  UpperLimitSearchVn,
  UpperLimitSearchVnl2l3,
} from "@/apis/UpperLimitApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { mapState } from "vuex";
import { ContractAppliances, ContractInfo } from "@/apis/ContractApi";
import { ApiNotFoundError } from "@/error/errors";

export default Vue.extend({
  name: "ContractSettingList",
  components: { MultiSelectTag },
  data() {
    return {
      /** 上限値項目選択肢 */
      // prettier-ignore
      units: [
        { unit: "CONTRACT_NUMBER",            l1: "契約", l2: "V番号" },
        { unit: "ACCESS_NUMBER",              l1: "アクセス回線", l2: "E番号"},
        { unit: "ACCESS_NUMBER_VLAN",         l1: "アクセス回線", l2: "E番号", l3: "VLAN"},
        { unit: "APPLIANCE_PF",               l1: "アプライアンス", l2: undefined, l3: "パケットフィルタリング"},
        { unit: "APPLIANCE_INTRANET",         l1: "アプライアンス", l2: "インターネットType1", l3: "イントラネットFW"},
        { unit: "APPLIANCE_NUMBER_INTERNET",  l1: "アプライアンス", l2: "インターネット(E番号/W番号)", l3: "インターネットFW"},
        { unit: "APPLIANCE_NUMBER_UTM",       l1: "アプライアンス", l2: "インターネット(E番号/W番号)", l3: "UTM"},
        { unit: "APPLIANCE_NUMBER_NAT",       l1: "アプライアンス", l2: "インターネット(E番号/W番号)", l3: "NAT"},
        { unit: "PFGW_NUMBER",                l1: "PFGW(イントラ)", l2: "E番号(Act/Sby)"},
        { unit: "VN_NUMBER",                  l1: "VNコネクト", l2: "W番号"},
        { unit: "VNL2L3_NUMBER",              l1: "VN L2/L3コネクト", l2: "E番号"},
        { unit: "MULTICLOUD_CLOUD",           l1: "マルチクラウド", l2: "クラウド"},
        { unit: "MULTICLOUD_CLOUD_NUMBER",    l1: "マルチクラウド", l2: "クラウド", l3: "W番号"},
        { unit: "MULTICLOUD_CLOUD_ENUMBER",   l1: "マルチクラウド", l2: "E番号"},
        { unit: "INTERNET4_NUMBER",           l1: "インターネットType4", l2: "W番号"},
      ] as { unit: UpperUnit; l1: string; l2?: string, l3?: string }[],
      selectedUnit: null as null | { unit: UpperUnit },
      selectedLineNumbers: [] as { data: unknown }[],
      upperLimitSearch: null as null | UpperLimitLineSearchGetEntity,
      /** 契約 - V番号用 */
      upperLimit1: {
        fields: [
          { key: "upperLimitItemName", label: "設定項目" },
          { key: "current", label: "現在設定数" },
          { key: "upperLimit", label: "上限値" },
        ] as BvTableFieldArray,
        items: null as null | UpperLimitEntity[],
      },
      /** 契約 - V番号 以外用 */
      upperLimit2: {
        items: null as null | UpperLimitLineGetEntity[],
      },
      isLoaded: false,
    };
  },
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
      contractSummary: "contractSummary",
      contractAppliances: "contractAppliances",
    }),
    /** 表示条件に従いフィルタリングされた上限値項目 */
    displayUnits(): {
      unit: UpperUnit;
      l1: string;
      l2?: string;
      l3?: string;
    }[] {
      return this.units.filter((e) => {
        switch (e.unit) {
          case "ACCESS_NUMBER":
          case "ACCESS_NUMBER_VLAN":
            return this.isShowAccess;
          case "APPLIANCE_PF":
            return this.isShowPacketFiltering;
          case "APPLIANCE_INTRANET":
            return this.isShowIntranetFW;
          case "APPLIANCE_NUMBER_INTERNET":
            return this.isShowInternetFW;
          case "APPLIANCE_NUMBER_UTM":
            return this.isShowInternetUTM;
          case "APPLIANCE_NUMBER_NAT":
            return this.isShowInternetFW || this.isShowInternetUTM;
          case "PFGW_NUMBER":
            return this.isShowPFGW;
          case "VNL2L3_NUMBER":
            return this.isShowVNL2L3;
          case "MULTICLOUD_CLOUD":
          case "MULTICLOUD_CLOUD_NUMBER":
          case "MULTICLOUD_CLOUD_ENUMBER":
            return this.isShowMulticloud;
          case "INTERNET4_NUMBER":
            return this.isShowInternetType4;
          default:
            return true;
        }
      });
    },
    /**
     * 回線番号の選択肢.
     * 選択された上限値項目によって表示方法や導出元が異なる
     */
    lineNumberOptions(): {
      labels: {
        item1: string;
        item2?: string;
        item3?: string;
      };
      values: {
        index: number;
        data: unknown;
        label1: string;
        label2?: string | number;
        label3?: string;
      }[];
    }[] {
      if (!this.selectedUnit || !this.upperLimitSearch) {
        return [];
      }

      switch (this.selectedUnit.unit) {
        case "CONTRACT_NUMBER":
        case "APPLIANCE_PF":
        case "APPLIANCE_INTRANET":
          throw new Error(`unexpected unit`);
        case "ACCESS_NUMBER":
          return [
            {
              labels: { item1: "回線番号" },
              values: this.upperLimitSearch.accessLineList.map((e, i) => ({
                index: i,
                data: e,
                label1: e.enumber,
              })),
            },
          ];
        case "ACCESS_NUMBER_VLAN":
          return [
            {
              labels: { item1: "回線番号", item2: "VLAN" },
              values: this.upperLimitSearch.accessLineList
                .flatMap((e) =>
                  e.siteList.map((site) => ({ enumber: e.enumber, ...site }))
                )
                .map((e, i) => ({
                  index: i,
                  data: e,
                  label1: e.enumber,
                  label2:
                    e.vlanIdType === "SPECIFIED"
                      ? e.vlanId!
                      : e.vlanIdType === "OTHER"
                      ? "Other"
                      : "Untag",
                })),
            },
          ];
        case "APPLIANCE_NUMBER_INTERNET":
        case "APPLIANCE_NUMBER_UTM":
        case "APPLIANCE_NUMBER_NAT":
          return [
            {
              labels: { item1: "E番号/W番号" },
              values: this.upperLimitSearch.internetLineList
                .map((e, i) => ({
                  index: i,
                  data: e,
                  label1: e.type1
                    ? e.type1.enumberMainAct
                    : e.type4!.wnumberMainAct,
                }))
                .filter((e) => {
                  switch (this.selectedUnit!.unit) {
                    case "APPLIANCE_NUMBER_INTERNET":
                      return e.data.applianceType === "INTERNET_FW";
                    case "APPLIANCE_NUMBER_UTM":
                      return e.data.applianceType === "UTM";
                    case "APPLIANCE_NUMBER_NAT":
                      return e.data.applianceType === "NAT";
                    case "APPLIANCE_PF":
                    case "APPLIANCE_INTRANET":
                    default:
                      throw new Error(
                        `unknown unit. ${this.selectedUnit!.unit}`
                      );
                  }
                }),
            },
          ];
        case "PFGW_NUMBER":
          return [
            {
              labels: { item1: "E番号(Act)", item2: "E番号(Sby)" },
              values: this.upperLimitSearch.pfgwLineList.map((e, i) => ({
                index: i,
                data: e,
                label1: e.enumberAct,
                label2: e.enumberSby,
              })),
            },
          ];
        case "VN_NUMBER":
          return [
            {
              labels: { item1: "W番号" },
              values: this.upperLimitSearch.vnLineList.map((e, i) => ({
                index: i,
                data: e,
                label1: e.wnumber,
              })),
            },
          ];
        case "VNL2L3_NUMBER":
          return [
            {
              labels: { item1: "E番号" },
              values: this.upperLimitSearch.vnl2l3LineList.map((e, i) => ({
                index: i,
                data: e,
                label1: e.enumber,
              })),
            },
          ];
        case "MULTICLOUD_CLOUD":
          return [
            {
              labels: { item1: "E番号", item2: "クラウド" },
              values: this.upperLimitSearch.cloudLineList.map((e, i) => ({
                index: i,
                data: e,
                label1: e.enumber,
                label2:
                  e.cloudServiceMenu === "SFDC"
                    ? "Salesforce"
                    : e.cloudServiceMenu,
              })),
            },
          ];
        case "MULTICLOUD_CLOUD_NUMBER":
          return [
            {
              labels: { item1: "E番号", item2: "クラウド", item3: "W番号" },
              values: this.upperLimitSearch.cloudLineList
                .flatMap((e) =>
                  e.vnConnectList.map((vn) => ({
                    cloudLineSeq: e.cloudLineSeq,
                    enumber: e.enumber,
                    cloudServiceMenu: e.cloudServiceMenu,
                    ...vn,
                  }))
                )
                .map((e, i) => ({
                  index: i,
                  data: e,
                  label1: e.enumber,
                  label2:
                    e.cloudServiceMenu === "SFDC"
                      ? "Salesforce"
                      : e.cloudServiceMenu,
                  label3: e.wnumberAct,
                })),
            },
          ];
        case "MULTICLOUD_CLOUD_ENUMBER": {
          const set = new Set(
            this.upperLimitSearch.cloudLineList.map((e) => e.enumber)
          );
          const uniqueENumber = [...set];

          return [
            {
              labels: { item1: "E番号" },
              values: uniqueENumber.map((e, i) => ({
                index: i,
                data: { enumber: e },
                label1: e,
              })),
            },
          ];
        }
        case "INTERNET4_NUMBER":
          return [
            {
              labels: { item1: "W番号" },
              values: this.upperLimitSearch.internetType4List.map((e, i) => ({
                index: i,
                data: e,
                label1: e.wnumberMainAct,
              })),
            },
          ];
        default:
          throw new Error(`unknown unit. ${this.selectedUnit.unit}`);
      }
    },
    /** true: 上限値単位「アクセス回線」を表示, false: 非表示 */
    isShowAccess(): boolean {
      return this.contractInfo.contractList.some(
        (e: ContractInfo["contractList"][0]) =>
          e.accessType !== "PLATFORM_GATEWAY" &&
          e.accessType !== "VN_L2L3_CONNECT"
      );
    },
    /** true: 上限値単位「アプライアンス ... パケットフィルタリング」を表示, false: 非表示 */
    isShowPacketFiltering(): boolean {
      return this.contractSummary.isPacketFiltering;
    },
    /** true: 上限値単位「アプライアンス ... インターネットFW」を表示, false: 非表示 */
    isShowInternetFW(): boolean {
      return (
        this.contractAppliances.applianceInfoList.some(
          (e: ContractAppliances["applianceInfoList"][0]) =>
            e.internetType === "TYPE1" || e.internetType === "TYPE4"
        ) &&
        this.contractAppliances.applianceInfoList.some(
          (e: ContractAppliances["applianceInfoList"][0]) =>
            e.applianceType === "IFW_BE" ||
            e.applianceType === "IFW_CONTRACT_BAND"
        )
      );
    },
    /** true: 上限値単位「アプライアンス ... UTM」を表示, false: 非表示 */
    isShowInternetUTM(): boolean {
      return (
        this.contractAppliances.applianceInfoList.some(
          (e: ContractAppliances["applianceInfoList"][0]) =>
            e.internetType === "TYPE1" || e.internetType === "TYPE4"
        ) &&
        this.contractAppliances.applianceInfoList.some(
          (e: ContractAppliances["applianceInfoList"][0]) =>
            e.applianceType === "UTM_BE" ||
            e.applianceType === "UTM_CONTRACT_BAND"
        )
      );
    },
    /** true: 上限値単位「アプライアンス ... イントラネットFW」を表示, false: 非表示 */
    isShowIntranetFW(): boolean {
      return this.contractAppliances.applianceInfoList.some(
        (e: ContractAppliances["applianceInfoList"][0]) =>
          e.internetType === "INTRANET"
      );
    },
    /** true: 上限値単位「PFGW(イントラ)」を表示, false: 非表示 */
    isShowPFGW(): boolean {
      return this.contractInfo.contractList.some(
        (e: ContractInfo["contractList"][0]) =>
          e.accessType === "PLATFORM_GATEWAY"
      );
    },
    /** true: 上限値単位「VN L2/L3コネクト」を表示, false: 非表示 */
    isShowVNL2L3(): boolean {
      return this.contractInfo.contractList.some(
        (e: ContractInfo["contractList"][0]) =>
          e.accessType === "VN_L2L3_CONNECT"
      );
    },
    /** true: 上限値単位「マルチクラウド」を表示, false: 非表示 */
    isShowMulticloud(): boolean {
      return this.contractInfo.contractList.some(
        (e: ContractInfo["contractList"][0]) =>
          e.accessType === "MULTI_CLOUD_GW"
      );
    },
    /** true: 上限値単位「インターネットType4」を表示, false: 非表示 */
    isShowInternetType4(): boolean {
      return this.contractAppliances.applianceInfoList.some(
        (e: ContractAppliances["applianceInfoList"][0]) =>
          e.internetType === "TYPE4"
      );
    },
  },
  watch: {
    /**
     * 上限値項目が変更された際に各種変数を初期化し必要な情報を再取得する.
     * 契約-V番号: 契約単位の設定上限値を取得
     * その他: 回線の選択肢を表示するために回線情報を取得
     */
    async selectedUnit(newValue: null | { unit: UpperUnit }) {
      // 各種情報を初期化
      this.selectedLineNumbers = [];
      this.upperLimit1.items = null;
      this.upperLimit2.items = null;
      this.upperLimitSearch = null;

      if (!newValue) {
        return;
      }

      switch (newValue.unit) {
        case "CONTRACT_NUMBER":
          try {
            this.upperLimit1.items = (
              await this.$api.upperLimit.getUpperLimit()
            ).upperLimitList;
          } catch (e) {
            // 回線によっては上限値がそもそも存在しない場合がある
            // その際は404が返ってくるため上限値0件として表示する
            if (e instanceof ApiNotFoundError) {
              this.upperLimit1.items = [];
              return;
            }
            throw e;
          }
          break;
        case "ACCESS_NUMBER":
        case "ACCESS_NUMBER_VLAN":
          this.upperLimitSearch =
            await this.$api.upperLimit.getUpperLimitLineSearch("ACCESS");
          break;
        case "APPLIANCE_PF":
          try {
            this.upperLimit2.items = [
              await this.$api.upperLimit.getUpperLimitLine({
                upperLimitLineManageUnit: "APPLIANCE",
                applianceType: "PACKET_FILTERING",
              }),
            ];
          } catch (e) {
            // 回線によっては上限値がそもそも存在しない場合がある
            // その際は404が返ってくるため上限値0件として表示する
            if (e instanceof ApiNotFoundError) {
              this.upperLimit2.items = [
                {
                  accessLine: null,
                  cloudLine: null,
                  pfgwLine: null,
                  vnConnect: null,
                  vnl2l3Line: null,
                  internetLine: {
                    applianceType: "PACKET_FILTERING",
                    type1: null,
                    type4: null,
                    upperLimitList: [],
                  },
                },
              ];
              return;
            }
            throw e;
          }
          break;
        case "APPLIANCE_INTRANET":
          try {
            this.upperLimit2.items = [
              await this.$api.upperLimit.getUpperLimitLine({
                upperLimitLineManageUnit: "APPLIANCE",
                applianceType: "INTRANET_FW",
              }),
            ];
          } catch (e) {
            // 回線によっては上限値がそもそも存在しない場合がある
            // その際は404が返ってくるため上限値0件として表示する
            if (e instanceof ApiNotFoundError) {
              this.upperLimit2.items = [
                {
                  accessLine: null,
                  cloudLine: null,
                  pfgwLine: null,
                  vnConnect: null,
                  vnl2l3Line: null,
                  internetLine: {
                    applianceType: "INTRANET_FW",
                    type1: null,
                    type4: null,
                    upperLimitList: [],
                  },
                },
              ];
              return;
            }
            throw e;
          }
          break;
        case "APPLIANCE_NUMBER_INTERNET":
        case "APPLIANCE_NUMBER_UTM":
        case "APPLIANCE_NUMBER_NAT":
          this.upperLimitSearch =
            await this.$api.upperLimit.getUpperLimitLineSearch("APPLIANCE");
          break;
        case "PFGW_NUMBER":
          this.upperLimitSearch =
            await this.$api.upperLimit.getUpperLimitLineSearch("PFGW");
          break;
        case "VN_NUMBER":
          this.upperLimitSearch =
            await this.$api.upperLimit.getUpperLimitLineSearch("VN");
          break;
        case "VNL2L3_NUMBER":
          this.upperLimitSearch =
            await this.$api.upperLimit.getUpperLimitLineSearch("VNL2L3");
          break;
        case "MULTICLOUD_CLOUD":
        case "MULTICLOUD_CLOUD_NUMBER":
        case "MULTICLOUD_CLOUD_ENUMBER":
          this.upperLimitSearch =
            await this.$api.upperLimit.getUpperLimitLineSearch("MULTI_CLOUD");
          break;
        case "INTERNET4_NUMBER":
          this.upperLimitSearch =
            await this.$api.upperLimit.getUpperLimitLineSearch(
              "INTERNET_TYPE4"
            );
          break;
        default:
          throw new Error(`unknown unit. ${newValue.unit}`);
      }
    },
  },
  async mounted() {
    this.isLoaded = true;
  },
  methods: {
    /** リセットボタン */
    reset() {
      this.selectedLineNumbers = [];
    },
    /** 上限値表示 */
    async submit() {
      this.upperLimit2.items = null;

      const limits = ((): Promise<UpperLimitLineGetEntity>[] => {
        switch (this.selectedUnit!.unit) {
          case "CONTRACT_NUMBER":
          case "APPLIANCE_PF":
          case "APPLIANCE_INTRANET":
            throw new Error(`unexpected unit`);
          case "ACCESS_NUMBER":
            return this.selectedLineNumbers.map((e) => {
              const data = e.data as UpperLimitSearchAccessLine;
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "ACCESS",
                  enumber: data.enumber,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      accessLine: {
                        enumber: data.enumber,
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
          case "ACCESS_NUMBER_VLAN":
            return this.selectedLineNumbers.map((e) => {
              const data = e.data as {
                enumber: string;
              } & UpperLimitSearchAccessLine["siteList"][0];
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "ACCESS",
                  enumber: data.enumber,
                  vlanIdType: data.vlanIdType,
                  vlanId: data.vlanId,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      accessLine: {
                        enumber: data.enumber,
                        vlanId: data.vlanId,
                        vlanIdType: data.vlanIdType,
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
          case "APPLIANCE_NUMBER_INTERNET":
          case "APPLIANCE_NUMBER_UTM":
          case "APPLIANCE_NUMBER_NAT":
            return this.selectedLineNumbers.map((e) => {
              const data = e.data as UpperLimitSearchInternet;
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "APPLIANCE",
                  applianceType: ((): QueryParamEntity["applianceType"] => {
                    switch (this.selectedUnit!.unit) {
                      case "APPLIANCE_NUMBER_INTERNET":
                        return "INTERNET_FW";
                      case "APPLIANCE_NUMBER_UTM":
                        return "UTM";
                      case "APPLIANCE_NUMBER_NAT":
                        return "NAT";
                    }
                  })(),
                  enumber: data.type1?.enumberMainAct ?? undefined,
                  wnumber: data.type4?.wnumberMainAct ?? undefined,
                  type4Id: data.type4?.type4Id ?? undefined,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      internetLine: {
                        applianceType: data.applianceType,
                        type1: data.type1,
                        type4: data.type4,
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
          case "PFGW_NUMBER":
            return this.selectedLineNumbers.map((e) => {
              const data = e.data as UpperLimitSearchPfgw;
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "PFGW",
                  enumber: data.enumberAct,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      pfgwLine: {
                        enumberAct: data.enumberAct,
                        enumberSby: data.enumberSby,
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
          case "VN_NUMBER":
            return this.selectedLineNumbers.map((e) => {
              const data = e.data as UpperLimitSearchVn;
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "VN",
                  wnumber: data.wnumber,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      vnConnect: {
                        wnumber: data.wnumber,
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
          case "VNL2L3_NUMBER":
            return this.selectedLineNumbers.map((e) => {
              const data = e.data as UpperLimitSearchVnl2l3;
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "VNL2L3",
                  enumber: data.enumber,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      vnl2l3Line: {
                        enumber: data.enumber,
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
          case "MULTICLOUD_CLOUD":
            return this.selectedLineNumbers.map((e) => {
              const data = e.data as UpperLimitSearchCloud;
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "MULTI_CLOUD",
                  cloudLineSeq: data.cloudLineSeq,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      cloudLine: {
                        enumber: data.enumber,
                        cloudServiceMenu: data.cloudServiceMenu,
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
          case "MULTICLOUD_CLOUD_NUMBER":
            return this.selectedLineNumbers.map((e) => {
              const data = e.data as {
                cloudLineSeq: string;
              } & UpperLimitSearchCloud["vnConnectList"][0];
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "MULTI_CLOUD",
                  cloudLineSeq: data.cloudLineSeq,
                  wnumber: data.wnumberAct,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      cloudLine: {
                        wnumberAct: data.wnumberAct,
                        wnumberSby: data.wnumberSby,
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
          case "MULTICLOUD_CLOUD_ENUMBER":
            return this.selectedLineNumbers.map((e) => {
              const data = e.data as UpperLimitSearchCloud;
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "MULTI_CLOUD",
                  enumber: data.enumber,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      cloudLine: {
                        enumber: data.enumber,
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
          case "INTERNET4_NUMBER":
            return this.selectedLineNumbers.map((e) => {
              const data =
                e.data as UpperLimitLineSearchGetEntity["internetType4List"][0];
              return this.$api.upperLimit
                .getUpperLimitLine({
                  upperLimitLineManageUnit: "INTERNET_TYPE4",
                  type4Id: data.type4Id,
                })
                .catch((error: Error) => {
                  if (error instanceof ApiNotFoundError) {
                    return {
                      internetLine: {
                        type4: { ...data },
                        upperLimitList: [],
                      },
                    };
                  }
                  throw error;
                });
            });
        }
      })();
      this.upperLimit2.items = await Promise.all(limits);
    },

    /** 上限値項目ラベル */
    labelUnit(e: { l1: string; l2?: string; l3?: string }) {
      if (e.l2 && e.l3) {
        return `${e.l1} / ${e.l2} / ${e.l3}`;
      } else if (e.l2 && !e.l3) {
        return `${e.l1} / ${e.l2}`;
      } else if (!e.l2 && e.l3) {
        return `${e.l1} / - / ${e.l3}`;
      } else {
        return e.l1;
      }
    },
    /** 回線番号項目ラベル（検索用） */
    labelLineNumber(e: { label1: string; label2?: string; label3?: string }) {
      return `${e.label1} ${e.label2 ?? ""} ${e.label3 ?? ""}`;
    },
  },
});

/**
 * 上限値項目種別
 * CONTRACT_NUMBER:           契約 / V番号
 * ACCESS_NUMBER:             アクセス回線 / E番号
 * ACCESS_NUMBER_VLAN:        アクセス回線 / E番号 / VLAN
 * APPLIANCE_PF:              アプライアンス / - / パケットフィルタリング
 * APPLIANCE_INTRANET:        アプライアンス / インターネットType1 / イントラネットFW
 * APPLIANCE_NUMBER_INTERNET: アプライアンス / インターネット(E番号/W番号) / インターネットFW
 * APPLIANCE_NUMBER_UTM:      アプライアンス / インターネット(E番号/W番号) / UTM
 * APPLIANCE_NUMBER_NAT:      アプライアンス / インターネット(E番号/W番号) / NAT
 * PFGW_NUMBER:               PFGW（イントラ） / E番号（Act/Sby）
 * VN_NUMBER:                 VNコネクト / W番号
 * VNL2L3_NUMBER:             VN L2/L3コネクト / E番号
 * MULTICLOUD_CLOUD:          マルチクラウド / クラウド
 * MULTICLOUD_CLOUD_NUMBER:   マルチクラウド / クラウド / W番号
 * MULTICLOUD_CLOUD_ENUMBER:   マルチクラウド / E番号
 * INTERNET4_NUMBER:          インターネットType4 / W番号
 */
type UpperUnit =
  | "CONTRACT_NUMBER"
  | "ACCESS_NUMBER"
  | "ACCESS_NUMBER_VLAN"
  | "APPLIANCE_PF"
  | "APPLIANCE_INTRANET"
  | "APPLIANCE_NUMBER_INTERNET"
  | "APPLIANCE_NUMBER_UTM"
  | "APPLIANCE_NUMBER_NAT"
  | "PFGW_NUMBER"
  | "VN_NUMBER"
  | "VNL2L3_NUMBER"
  | "MULTICLOUD_CLOUD"
  | "MULTICLOUD_CLOUD_NUMBER"
  | "MULTICLOUD_CLOUD_ENUMBER"
  | "INTERNET4_NUMBER";

import { render, staticRenderFns } from "./IntranetFwPolicyRuleViewRD.vue?vue&type=template&id=27ab24fe&scoped=true&"
import script from "./IntranetFwPolicyRuleViewRD.vue?vue&type=script&lang=ts&"
export * from "./IntranetFwPolicyRuleViewRD.vue?vue&type=script&lang=ts&"
import style0 from "./IntranetFwPolicyRuleViewRD.vue?vue&type=style&index=0&id=27ab24fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ab24fe",
  null
  
)

export default component.exports
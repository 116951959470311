



































































































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { AgreementInfo, AgreementInfoList } from "@/apis/AgreementApi.ts";
import AgreementReference from "@/modals/agreement/AgreementReference.vue";

export default Vue.extend({
  name: "AgreementHistory",
  data() {
    return {
      agreement: {
        fields: [
          { key: "postStartDate", label: "掲載開始日時", sortable: true },
          {
            key: "agreementTitle",
            label: "タイトル",
            sortable: true,
            tdClass: "text-pre-wrap",
          },
          {
            key: "signedDate",
            label: "同意日時",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v, _k, item: AgreementInfoList) => {
              if (item.agreementStatus === "ON_HOLD") {
                return null;
              } else {
                return item.signedDate;
              }
            },
          },
          {
            key: "agreementStatus",
            label: "状態",
            sortable: true,
          },
        ],
        items: [],
        search: {
          postStartDate: { type: "custom", label: "掲載開始日時" },
          agreementTitle: { type: "text", label: "タイトル" },
          signedDate: { type: "custom", label: "同意日時" },
          agreementStatus: {
            type: "select",
            label: "状態",
            items: [
              { value: null, text: "未同意" },
              { value: "AGREED", text: "同意済み" },
              { value: "ON_HOLD", text: "同意保留" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          postStartDate: (item, value: { start: string; end: string }) => {
            return this.$moment(item.postStartDate).isBetween(
              this.$moment(value.start),
              this.$moment(value.end),
              undefined,
              "[]"
            );
          },
          signedDate: (item, value: { start: string; end: string }) => {
            return this.$moment(
              item.agreementStatus === "ON_HOLD" ? null : item.signedDate
            ).isBetween(
              this.$moment(value.start),
              this.$moment(value.end),
              undefined,
              "[]"
            );
          },
          agreementStatus: "equal",
          agreementTitle: "like",
        },
      } as Omit<AppTableData<AgreementInfoList>, "selected">,
      agreements: {} as AgreementInfo,
      isLoaded: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.agreements = await this.$api.agreement.getAll();
      this.agreement.items = this.agreements.agreementList;
    },
    /** 同意詳細画面を表示*/
    async historyDetails(item: AgreementInfoList) {
      await this.$modal.show(AgreementReference, {
        agreementItem: item,
        agreementKeyId: this.agreements.agreementKeyId,
        // 最新のレコードか否かを設定
        isLatest:
          this.agreement.items.sortBy(["postStartDate", "desc"])[0]
            .agreementSeq === item.agreementSeq,
      });
      await this.load();
    },
  },
});























































import Vue, { PropType } from "vue";
import { PfgwZone, PfgwZonePut } from "@/apis/PfgwZoneApi";
import IntranetFwPfgwZoneRelationDelete from "@/modals/applianceContractSetting/IntranetFwPfgwZoneRelationDelete.vue";
import IntranetFwPfgwZoneRelationRegister from "@/modals/applianceContractSetting/IntranetFwPfgwZoneRelationRegister.vue";

export default Vue.extend({
  name: "IntranetFwPfgwZoneRelationList",
  props: {
    enumberAct: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      pfgwZone: {} as PfgwZone,
      zoneSelect: {
        fields: [
          {
            key: "srcZone",
            label: "送信元ゾーン",
          },
          { key: "arrow", label: "" },
          {
            key: "dstZone",
            label: "宛先ゾーン",
          },
          { key: "space", label: "" },
          { key: "zoneDelete", label: "" },
        ],
        items: [] as {
          _primary: string;
          srcZoneId: string;
          dstZoneId: string;
        }[],
      },
      /** 初期通信完了フラグ */
      isLoaded: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    async load() {
      this.pfgwZone = await this.$api.pfgwZoneRelation.getPfgwZone(
        this.enumberAct
      );
      this.zoneSelect.items = this.pfgwZone.zoneIdList.map((e) => ({
        _primary: `${e.srcZoneId}_${e.dstZoneId}`,
        ...e,
      }));
    },
    /** ゾーン接続削除 */
    async toDelete(item: { srcZoneId: string; dstZoneId: string }) {
      await this.$modal.show(IntranetFwPfgwZoneRelationDelete, {
        srcZoneId: item.srcZoneId,
        dstZoneId: item.dstZoneId,
        wvs2ZoneId: this.pfgwZone.wvs2ZoneId,
        kcpsZoneId: this.pfgwZone.kcpsZoneId,
      });
      this.zoneSelect.items.splice(
        this.zoneSelect.items.findIndex(
          (e) =>
            e.srcZoneId === item.srcZoneId && e.dstZoneId === item.dstZoneId
        ),
        1
      );
      const reqData: PfgwZonePut = {
        wvs2AddressList: this.pfgwZone.wvs2AddressList.map((e) => e.ipAddress),
        kcpsAddressList: this.pfgwZone.kcpsAddressList.map((e) => e.ipAddress),
        zoneIdList: this.zoneSelect.items,
        pfgwAddressKeyId: this.pfgwZone.pfgwAddressKeyId,
      };
      await this.$api.pfgwZoneRelation.putPfgwZone(this.enumberAct, reqData);
      this.isLoaded = false;
      await this.load();
      this.isLoaded = true;
    },
    /** ゾーン接続追加モーダルを表示 */
    async toRegister() {
      await this.$modal.show(IntranetFwPfgwZoneRelationRegister, {
        enumberAct: this.enumberAct,
        pfgwZone: this.pfgwZone,
      });
      this.isLoaded = false;
      await this.load();
      this.isLoaded = true;
    },
  },
});





























































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { ApplianceInfo } from "@/apis/ContractApi";

import {
  Type1Service,
  ContractGetType1Appliance,
} from "@/apis/ContractChangeType1Api";
import { Type4Service } from "@/apis/ContractChangeType4Api";
import InternetType1ContractApplianceModify from "@/modals/applianceContractSetting/InternetType1ContractApplianceModify.vue";
import InternetType1ContractModify from "@/modals/applianceContractSetting/InternetType1ContractModify.vue";
import ContractOptionReference from "@/modals/applianceContractSetting/ContractOptionReference.vue";
import InternetType4ContractDeleteConfirm from "@/modals/applianceContractSetting/InternetType4ContractDeleteConfirm.vue";
import InternetType4RouteModify from "@/modals/applianceContractSetting/InternetType4RouteModify.vue";
import InternetType4ContractModify from "@/modals/applianceContractSetting/InternetType4ContractModify.vue";
import InternetType4ContractRegister from "@/modals/applianceContractSetting/InternetType4ContractRegister.vue";
import InternetType4PolicyUtmList from "@/modals/applianceContractSetting/InternetType4PolicyUtmList.vue";
import InternetType4PolicyIfwList from "@/modals/applianceContractSetting/InternetType4PolicyIfwList.vue";
import InternetType1ZoneSelectReference from "@/modals/applianceContractSetting/InternetType1ZoneSelectReference.vue";
import {
  AccessLine,
  IntranetfwServiceMenu,
  Pfgw,
} from "@/apis/IntranetfwServiceMenuApi";
import IntranetFwZoneRelationList from "@/modals/applianceContractSetting/IntranetFwZoneRelationList.vue";
import IntranetFwContractModify from "@/modals/applianceContractSetting/IntranetFwContractModify.vue";
import IntranetFwPolicyZoneList from "@/modals/applianceContractSetting/IntranetFwPolicyZoneList.vue";
import InternetType1ZoneRelationSettingList from "@/modals/applianceContractSetting/InternetType1ZoneRelationSettingList.vue";

import IntranetFwPfgwZoneRelationList from "@/modals/applianceContractSetting/IntranetFwPfgwZoneRelationList.vue";
import IntranetFwPfgwZoneAddressEdit from "@/modals/applianceContractSetting/IntranetFwPfgwZoneAddressEdit.vue";
import IntranetFwPfgwZoneSelectList from "@/modals/applianceContractSetting/IntranetFwPfgwZoneSelectList.vue";
import { ApplianceInfoWithId } from "@/views/applianceObject/ApplianceObjectList.vue";
import IntranetFwCustomServiceList from "@/modals/applianceContractSetting/IntranetFwCustomServiceList.vue";
import InternetType4ImportConfig from "@/modals/applianceContractSetting/InternetType4ImportConfig.vue";

export default Vue.extend({
  name: "ApplianceObjectContractSettingList",
  data() {
    return {
      applianceObjectTable: {
        fields: [
          {
            key: "lineNumber",
            label: "回線番号",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: ApplianceInfo) => {
              if (item.internetType === "TYPE1") {
                return `${item.enumberMainAct}\n${item.enumberMainSby}`;
              } else if (item.internetType === "TYPE4") {
                return `${item.wnumberMainAct}\n${item.wnumberMainSby}`;
              } else {
                return null;
              }
            },
          },
          { key: "vpnVnCode", label: "VPN/VNコード", sortable: true },
          {
            key: "internetType",
            label: "Type",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v) => {
              return this.$filter.enumTo(_v, "internetType");
            },
          },
          {
            key: "type4Id",
            label: "Type4ID",
            sortable: true,
          },
          {
            key: "type4Name",
            label: "Type4名",
            sortable: true,
          },
          {
            key: "mainSite",
            label: "メインサイト",
            sortable: true,
          },
          {
            key: "appliance",
            label: "アプライアンス",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: ApplianceInfo) => {
              return this.$filter.applianceTypeTo(
                item.applianceType,
                item.bandwidth
              );
            },
          },
          {
            key: "isSecurityOption",
            label: "セキュリティ監視",
            sortable: true,
          },
          {
            key: "status",
            label: "状態",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: ApplianceInfo) => {
              if (item.internetType === "INTRANET") {
                return null;
              } else {
                return this.$filter.internetStatusTo(
                  item.isStart,
                  item.isInternetPause
                );
              }
            },
          },
        ],
        items: [],
      } as Pick<AppTableData<ApplianceInfoWithId>, "fields" | "items">,

      selectedItem: null as null | ApplianceInfoWithId,
      type1SelectedItem: {} as Type1Service,
      type4SelectedItem: {} as Type4Service,
      intranetFwSelectedItem: {} as IntranetfwServiceMenu,
      /** 初期通信完了フラグ */
      isLoaded: false,
      /** 詳細通信完了フラグ */
      isDetailLoaded: false,
      /** ユーザ設定 */
      user: this.$store.state.user,
      contractGetType1Appliance: {} as ContractGetType1Appliance,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      if (!this.user.contractSummary.isSecuritySetting) {
        /** セキュリティ監視を非表示 */
        this.applianceObjectTable.fields.splice(7, 1);
      }
      /** アプライアンス一覧の取得APIを呼び出し、データ取得を行う */
      this.applianceObjectTable.items = (
        await this.$api.contract.getAppliances()
      ).applianceInfoList.map((e) => {
        // テーブルの主キー相当を生成
        switch (e.internetType) {
          case "TYPE1":
            return { ...e, _id: "type1" };
          case "TYPE4":
            return { ...e, _id: `type4_${e.type4Id}` };
          case "INTRANET":
            return { ...e, _id: `intranet` };
        }
      });
    },
    async reload() {
      await this.load();
      if (this.selectedItem) {
        const currentLine = this.applianceObjectTable.items.find(
          (v) => v._id === this.selectedItem!._id
        );
        if (currentLine) {
          await this.setSelectedItem(currentLine);
        } else {
          this.isDetailLoaded = false;
          this.selectedItem = null;
        }
      }
    },
    /** 選択した行の背景色を変更する処理 */
    rowHighlight(item: ApplianceInfoWithId, type: string) {
      if (!item || type !== "row") return;
      if (item._id === this.selectedItem?._id) return "table-success";
    },

    /** 契約インポート */
    async showImportContract() {
      await this.$modal.show(InternetType4ImportConfig);
      await this.reload();
    },

    /** 「追加」ボタン */
    async showPolicyRegister() {
      await this.$modal.show(InternetType4ContractRegister, {
        type4Items: this.applianceObjectTable.items.filter(
          (v) => v.internetType === "TYPE4"
        ),
      });
      await this.reload();
    },

    /** 「オプション設定」ボタン */
    async showOptionReference() {
      await this.$modal.show(ContractOptionReference);
    },

    /** 一覧の行選択 */
    async setSelectedItem(row: ApplianceInfoWithId) {
      this.selectedItem = row;
      this.isDetailLoaded = false;
      switch (row.internetType) {
        case "TYPE1":
          this.type1SelectedItem =
            await this.$api.contract_change_type1.getType1Service();
          this.contractGetType1Appliance =
            await this.$api.contract_change_type1.getContractType1Appliance();
          break;
        case "TYPE4":
          if (this.selectedItem.type4Id) {
            this.type4SelectedItem =
              await this.$api.contract_change_type4.getType4Service(
                this.selectedItem.type4Id
              );
            if (
              this.type4SelectedItem.bandwidth &&
              this.type4SelectedItem.bandwidth.value >= 1000
            ) {
              this.type4SelectedItem.bandwidth = {
                value: this.type4SelectedItem.bandwidth.value / 1000,
                unit: "GBPS",
              };
            }
          }
          break;
        case "INTRANET":
          this.intranetFwSelectedItem =
            await this.$api.intranetfwServiceMenu.getIntranetfwServiceMenu();
          /** イントラネットFW帯域の変換と変更画チェックボックスの追加処理 */
          this.intranetFwSelectedItem.intranetFwBandwidth =
            this.bandwidthToMbps(
              this.intranetFwSelectedItem.intranetFwBandwidth
            );
          // 帯域は0の回線が非表示なので、フィルタリングする
          this.intranetFwSelectedItem.accessLineList =
            this.intranetFwSelectedItem.accessLineList.filter(
              (e) => e.bandwidth.value !== 0
            );
          this.intranetFwSelectedItem.accessLineList.forEach((e) => {
            e.bandwidth = this.bandwidthToMbps(e.bandwidth);
            e.isCheck = true;
          });
          this.intranetFwSelectedItem.remoteAccessGwList.forEach((e) => {
            e.bandwidthActSby = this.bandwidthToMbps(e.bandwidthActSby);
            e.contractBandwidth = e.contractBandwidth
              ? this.bandwidthToMbps(e.contractBandwidth)
              : null;
            e.isCheck = true;
          });
          this.intranetFwSelectedItem.pfgwList.forEach((e) => {
            e.bandwidthActSby = this.bandwidthToMbps(e.bandwidthActSby);
            e.isCheck = true;
          });
          break;
      }
      this.isDetailLoaded = true;
    },
    /**
     * イントラネットFW割当帯域変更用Kbps、GbpsからMbpsへ
     */
    bandwidthToMbps(
      bandwidthItem: AccessLine["bandwidth"]
    ): AccessLine["bandwidth"] {
      switch (bandwidthItem.unit) {
        case "KBPS":
          return { value: bandwidthItem.value / 1000, unit: "MBPS" };
        case "MBPS":
          return { value: bandwidthItem.value, unit: "MBPS" };
        case "GBPS":
          return { value: bandwidthItem.value * 1000, unit: "MBPS" };
      }
    },

    /** Type1 ポリシー設定 */
    async showType1PolicySetting() {
      await this.$modal.show(InternetType1ZoneSelectReference, {
        type1Service: this.type1SelectedItem,
      });
    },
    /** Type1 ゾーン接続設定 */
    async showType1ZoneRelationSettingList() {
      await this.$modal.show(InternetType1ZoneRelationSettingList, {
        type1Service: this.type1SelectedItem,
      });
    },

    /** Type1 インターネット接続/一時停止 */
    async changeType1InternetStatus() {
      if (this.type1SelectedItem.status === "INACTIVE") {
        await this.$confirm(
          "インターネットの利用を再開します。よろしいですか？"
        );
        await this.$api.internet_pause.putType1InternetPause({
          isInternetPause: false,
          note: null,
          contractType1KeyId: this.type1SelectedItem.contractType1KeyId,
        });
      } else {
        await this.$confirm(
          "インターネットの利用を一時停止します。よろしいですか？"
        );
        await this.$api.internet_pause.putType1InternetPause({
          isInternetPause: true,
          note: null,
          contractType1KeyId: this.type1SelectedItem.contractType1KeyId,
        });
      }
      await this.reload();
    },

    /** Type1アプライアンス契約 変更 */
    async showType1ContractApplianceModify() {
      await this.$modal.show(InternetType1ContractApplianceModify, {
        contractGetType1Appliance: this.contractGetType1Appliance,
      });
      await this.reload();
    },

    /** Type1 変更 */
    async showType1ContractModify() {
      await this.$modal.show(InternetType1ContractModify, {
        selectedItem: this.type1SelectedItem,
      });
      await this.reload();
    },

    /** Type1 設定情報ダウンロード */
    async downloadNetWorkInformation() {
      const csvData = await this.$api.type1SiteZone.getNetWorkInformation();
      this.$service.file.download(
        this.$service.file.getFileName(csvData)!,
        csvData.data,
        csvData.headers["content-type"]
      );
    },

    /** 設定インポート */
    async showImportConfig() {
      await this.$modal.show(InternetType4ImportConfig, {
        type4Service: this.type4SelectedItem,
      });
    },

    /** Type4 ポリシー設定 */
    async showType4PolicySetting() {
      if (this.type4SelectedItem.applianceType === "UTM") {
        await this.$modal.show(InternetType4PolicyUtmList, {
          type4Id: this.type4SelectedItem.type4Id,
          type4Service: this.type4SelectedItem,
        });
      }
      if (this.type4SelectedItem.applianceType === "IFW") {
        await this.$modal.show(InternetType4PolicyIfwList, {
          type4Id: this.type4SelectedItem.type4Id,
          type4Service: this.type4SelectedItem,
        });
      }
    },

    /** Type4 経路設定 */
    async showType4Route() {
      await this.$modal.show(InternetType4RouteModify, {
        selectedItem: this.type4SelectedItem,
      });
    },

    /** Type4 インターネット接続/一時停止 */
    async changeType4InternetStatus() {
      if (this.type4SelectedItem.status === "UNCONNECTED") {
        await this.$confirm(
          "インターネットの利用を開始します。よろしいですか？"
        );
        await this.$api.contract_change_type4.putType4ServiceStart(
          this.type4SelectedItem.type4Id,
          {
            contractType4KeyId: this.type4SelectedItem.contractType4KeyId,
          }
        );
      } else if (this.type4SelectedItem.status === "INACTIVE") {
        await this.$confirm(
          "インターネットの利用を再開します。よろしいですか？"
        );
        await this.$api.internet_pause.putType4InternetPause(
          this.type4SelectedItem.type4Id,
          {
            isInternetPause: false,
            contractType4KeyId: this.type4SelectedItem.contractType4KeyId,
          }
        );
      } else {
        await this.$confirm(
          "インターネットの利用を一時停止します。よろしいですか？\nインターネット一時停止では課金は停止しないため、課金を停止する場合は契約の解約（削除）をお願いします。"
        );
        await this.$api.internet_pause.putType4InternetPause(
          this.type4SelectedItem.type4Id,
          {
            isInternetPause: true,
            contractType4KeyId: this.type4SelectedItem.contractType4KeyId,
          }
        );
      }
      await this.reload();
    },

    /** Type4 変更 */
    async showType4ContractModify() {
      await this.$modal.show(InternetType4ContractModify, {
        selectedItem: this.type4SelectedItem,
        type4Items: this.applianceObjectTable.items.filter(
          (v) => v.internetType === "TYPE4"
        ),
      });
      await this.reload();
    },

    /** Type4 削除 */
    async showType4PolicyDelete() {
      const putItem = {
        contractType4KeyId: this.type4SelectedItem.contractType4KeyId,
        note: null,
      };
      await this.$modal.show(InternetType4ContractDeleteConfirm, {
        selectedItem: this.type4SelectedItem,
        putItem: putItem,
      });
      await this.$api.contract_change_type4.deleteType4Service(
        this.type4SelectedItem.type4Id,
        putItem
      );
      await this.reload();
    },

    // IntranetFW用メソッド
    /** IntranetFW 契約変更 */
    async showIntranetFwContractChange() {
      await this.$modal.show(IntranetFwContractModify, {
        intranetFwContractItem: this.intranetFwSelectedItem,
      });
      await this.reload();
    },

    /** IntranetFW ゾーン接続設定 */
    async showIntranetFwZoneRelation() {
      await this.$modal.show(IntranetFwZoneRelationList);
      await this.reload();
    },

    /** IntranetFW サービス設定 */
    async showServiceSetting(
      policyType: "INTRANET_FW" | "INTRANET_FW_PFGW",
      enumberAct?: string
    ) {
      await this.$modal.show(IntranetFwCustomServiceList, {
        policyType: policyType,
        enumberAct: enumberAct,
      });
    },

    /** IntranetFW ポリシー設定 */
    async showIntranetFwPolicySetting() {
      await this.$modal.show(IntranetFwPolicyZoneList, {
        contractInfo: {
          vpnCode: this.intranetFwSelectedItem.vpnCode,
          intranetFwBandwidth: this.intranetFwSelectedItem.intranetFwBandwidth,
        },
      });
    },

    /** PFGW ゾーン接続設定 */
    async showIntranetFwPfgwZoneRelationList(pfgw: Pfgw) {
      await this.$modal.show(IntranetFwPfgwZoneRelationList, {
        enumberAct: pfgw.enumberAct,
      });
    },

    /** PFGW アドレス設定 */
    async showIntranetFwPfgwZoneAddressEdit(pfgw: Pfgw) {
      await this.$modal.show(IntranetFwPfgwZoneAddressEdit, {
        enumberAct: pfgw.enumberAct,
      });
    },

    /** PFGW ポリシー設定 */
    async showIntranetFwPfgwZoneSelectList(pfgw: Pfgw) {
      await this.$modal.show(IntranetFwPfgwZoneSelectList, {
        pfgw: pfgw,
        vpnCode: this.intranetFwSelectedItem.vpnCode,
      });
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"インターネットアプライアンス ゾーン接続追加","classes":"modal-medium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [(_vm.isLoaded)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('validation-provider',{attrs:{"name":"送信元ゾーン","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"送信元ゾーン","isInfo":false,"isRequired":true}})]},proxy:true}],null,true)},[_c('b-form-select',{staticClass:"mt-2",attrs:{"options":_vm.zoneOptions,"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.srcZone),callback:function ($$v) {_vm.$set(_vm.form, "srcZone", $$v)},expression:"form.srcZone"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"宛先ゾーン","rules":{
              required: true,
              duplicate_zone_relation: {
                srcZone: _vm.form.srcZone,
                relation: _vm.relations.zoneRelationList,
              },
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"宛先ゾーン","isInfo":false,"isRequired":true}})]},proxy:true}],null,true)},[_c('b-form-select',{staticClass:"mt-2",attrs:{"options":_vm.dstZoneOptions,"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.dstZone),callback:function ($$v) {_vm.$set(_vm.form, "dstZone", $$v)},expression:"form.dstZone"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" 追加 ")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v(" キャンセル ")])],1)])]}}],null,true)}):_vm._e()]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
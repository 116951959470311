







import Vue, { PropType } from "vue";
import { GetPolicy } from "@/apis/NwPolicyApi";

/** インターネットType4のポリシーのIP Pool表示が出来るコンポーネント */
export default Vue.extend({
  name: "PolicyIpPool",
  props: {
    /** IPプールリスト */
    ipPoolList: {
      type: Array as PropType<GetPolicy["ipPoolList"]>,
      required: true,
    },
  },
  computed: {
    /** IPプールリストを昇順でソートした一覧 */
    poolList(): GetPolicy["ipPoolList"] {
      return this.ipPoolList.sortBy(["ipPoolName", "asc"]);
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 数字のみの入力ができないこと
 */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }

    /** 数字のみの入力ができないこと */
    return !/^\d+$/.test(value);
  },
} as ValidationRuleSchema;

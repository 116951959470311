var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-full","title":"アクセス回線申込"},scopedSlots:_vm._u([(_vm.isLoaded)?{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('cross-validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-body modal-content"},[_c('app-table',{attrs:{"primary-key":"lineSeq","fields":_vm.displayColumns,"items":_vm.accessLine.items,"thead-class":"text-center","sticky-header":"55vh"},scopedSlots:_vm._u([{key:"cell(enumberAct)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.enumberAct)),_c('br'),(
                  data.item.menu === 'PF_ACCESS_GW2' && data.item.enumberSby
                )?[_vm._v(_vm._s(data.item.enumberSby)+" ")]:_vm._e()]}},{key:"cell(menu)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("enumTo")(data.value,"accessLineMenu")))])]}},{key:"head(bandwidthType)",fn:function(){return [_c('app-badge',{attrs:{"item":"帯域タイプ","isInfo":false}})]},proxy:true},{key:"cell(bandwidthType)",fn:function(data){return [_c('validation-provider',{attrs:{"vid":data.item.lineSeq + 'bandwidthType',"name":"帯域タイプ","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-select',{attrs:{"state":_vm._f("validState")(validContext),"disabled":_vm.isNormalUser && data.item.fixingBandwidth === 'ON'},model:{value:(data.item.bandwidthType),callback:function ($$v) {_vm.$set(data.item, "bandwidthType", $$v)},expression:"data.item.bandwidthType"}},_vm._l((_vm.bandwidthTypeOptions(data.item)),function(item){return _c('b-form-select-option',{key:item,attrs:{"value":item}},[(item === 'BURST')?[_vm._v("バースト")]:[_vm._v(_vm._s(_vm._f("enumTo")(item,"accessLineBandwidthType")))]],2)}),1),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})]}},{key:"cell(fixingBandwidth)",fn:function(data){return [(
                  data.item.menu === 'ETHERNET2' ||
                  data.item.menu === 'ETHERNET2_PF'
                )?[_c('b-form-select',{attrs:{"options":_vm.onOffOptions},model:{value:(data.item.fixingBandwidth),callback:function ($$v) {_vm.$set(data.item, "fixingBandwidth", $$v)},expression:"data.item.fixingBandwidth"}})]:[_vm._v(" - ")]]}},{key:"head(bandwidth)",fn:function(){return [_c('app-badge',{attrs:{"item":"帯域値","isInfo":false}})]},proxy:true},{key:"cell(bandwidth)",fn:function(data){return [(data.item.bandwidthType === 'BESTEFFORT')?[_vm._v(" - ")]:[_c('validation-provider',{attrs:{"vid":data.item.lineSeq + 'bandwidth',"name":"帯域値","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [(data.item.bandwidthType === 'BURST')?[_c('b-form-select',{attrs:{"options":_vm.burstOptions,"state":_vm._f("validState")(validContext),"disabled":_vm.isNormalUser && data.item.fixingBandwidth === 'ON'},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}})]},proxy:true}],null,true),model:{value:(data.item.burst),callback:function ($$v) {_vm.$set(data.item, "burst", $$v)},expression:"data.item.burst"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]:[_c('app-select',{attrs:{"options":_vm.bandwidthOptions(data.item),"disabled":_vm.isNormalUser && data.item.fixingBandwidth === 'ON',"label":"text","reduce":function (option) { return option.value; },"appendToBody":true,"searchable":true,"state":_vm._f("validState")(validContext)},model:{value:(data.item.bandwidth),callback:function ($$v) {_vm.$set(data.item, "bandwidth", $$v)},expression:"data.item.bandwidth"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]]}}],null,true)})]]}},{key:"head(qos)",fn:function(){return [_c('app-badge',{attrs:{"item":"QoS","isInfo":false}})]},proxy:true},{key:"cell(qos)",fn:function(data){return [(
                  data.item.menu === 'PF_ACCESS_GW' ||
                  data.item.menu === 'EXTEND_ETHERNET' ||
                  data.item.menu === 'BROADBAND_ACCESS3' ||
                  data.item.menu === 'WIRELESS_ACCESS2' ||
                  data.item.menu === 'ETHERNET_LIGHT' ||
                  data.item.menu === 'MULTI_CLOUD_GW' ||
                  (data.item.menu === 'PF_ACCESS_GW2' &&
                    data.item.isQosPath === false)
                )?[_vm._v(" - ")]:[_c('validation-provider',{attrs:{"vid":data.item.lineSeq + 'qos',"name":"QoS","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-select',{attrs:{"options":_vm.onOffOptions,"state":_vm._f("validState")(validContext),"disabled":_vm.isEthernet2Broadband(data.item)},model:{value:(data.item.qos),callback:function ($$v) {_vm.$set(data.item, "qos", $$v)},expression:"data.item.qos"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})]]}},{key:"head(vlanControl)",fn:function(){return [_c('app-badge',{attrs:{"item":"VLAN制御","isInfo":false}})]},proxy:true},{key:"cell(vlanControl)",fn:function(data){return [(
                  data.item.menu === 'PF_ACCESS_GW' ||
                  data.item.menu === 'EXTEND_ETHERNET' ||
                  data.item.menu === 'PF_ACCESS_GW2' ||
                  data.item.menu === 'MULTI_CLOUD_GW' ||
                  _vm.isEthernet2Broadband(data.item)
                )?[_vm._v(" - ")]:[_c('validation-provider',{attrs:{"vid":data.item.lineSeq + 'vlanControl',"name":"VLAN制御","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-select',{attrs:{"options":_vm.onOffOptions,"state":_vm._f("validState")(validContext)},model:{value:(data.item.vlanControl),callback:function ($$v) {_vm.$set(data.item, "vlanControl", $$v)},expression:"data.item.vlanControl"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})]]}},{key:"cell(office)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("emptyTo")(data.value,"-")))])]}}],null,true)})],1),[_c('cross-validation-provider',{attrs:{"passed":_vm.objectChanged,"vid":"no_change"}},[_vm._v(" "+_vm._s(_vm._f("msg")("no_change_data"))+" ")])],[_c('cross-validation-provider',{attrs:{"passed":_vm.inValidenumber.length === 0,"vid":"bandwidthTypeQos"}},[_vm._v(" 「トラフィックフリー」と、QoSは併用できません。"+_vm._s(("" + _vm.inValidenumber))+"の帯域タイプを変更するか、QoSを「OFF」にしてください。 ")])],_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("変更")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)],2)]}}],null,true)})]}}:null],null,true)},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
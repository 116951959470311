import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 半角英数記号
 */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }
    return !/[^0-9a-zA-Z -/:-@[-~]/.test(value);
  },
} as ValidationRuleSchema;

























































































































































































































































































































































































import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import { ContractInfo } from "@/apis/ContractApi";
import { CloudVnSfdcDetail } from "@/apis/CloudVnSfdcApi";
import MultiCloudSfdcVnLineEdit from "@/modals/multicloud/salesforce/MultiCloudSfdcVnLineEdit.vue";
import MultiCloudSfdcVnConnectionConfirm from "@/modals/multicloud/salesforce/MultiCloudSfdcVnConnectionConfirm.vue";

export default Vue.extend({
  name: "MultiCloudSfdcVnConnectionReference",
  props: {
    wnumber: {
      type: String as PropType<string>,
      required: true,
    },
    cloudLineSeq: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      cloudDetail: {} as CloudVnSfdcDetail,
      isLoaded: false,
      isChanged: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
      contractSummary: "contractSummary",
    }),
    /** 開始日が未来日の場合,true*/
    isFutureDayContract(): boolean {
      return !this.contractInfo.contractList.find(
        (e: ContractInfo["contractList"][0]) =>
          e.enumber === this.cloudDetail.enumber
      );
    },
  },
  methods: {
    async load() {
      this.cloudDetail = await this.$api.cloudVnSfdc.getVnConnect(this.wnumber);
    },
    async executed() {
      this.isChanged = true;
      await this.load();
    },
    async deleteVnConnect() {
      await this.$modal.show(MultiCloudSfdcVnConnectionConfirm, {
        cloudVnSfdcDetail: this.cloudDetail,
        displayType: "D",
      });
      await this.$api.cloudVnSfdc.deleteVnConnect(this.cloudLineSeq, {
        cloudVnConnectList: [
          this.cloudDetail.cloudVnConnectAct.wnumber,
          this.cloudDetail.cloudVnConnectSby.wnumber,
        ],
        note: null,
        cloudVnSfdcKeyId: this.cloudDetail.cloudVnSfdcKeyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    async showVnConnectModify() {
      await this.alertClear();
      await this.$modal.show(MultiCloudSfdcVnLineEdit, {
        cloudLineSeq: this.cloudLineSeq,
        cloudVnSfdcDetail: this.cloudDetail,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** エラーメッセージを初期化する */
    async alertClear() {
      (
        this.$refs.operationCommandAct as Vue & { alertClear: () => void }
      ).alertClear();
      (
        this.$refs.operationCommandSby as Vue & { alertClear: () => void }
      ).alertClear();
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 *「-」「_」「.」「!」「~」「*」「'」「(」「)」「a～zとA～Zと0～9」「/」「?」「:」「@」「&」「=」「+」「$」「,」「%」「#」「;」「[」「]」以外含まれないこと
 *「*」の前または後が「&」「.」「+」「/」「=」「?」以外であること
 */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }

    if (/[^0-9a-zA-Z-_.!~*'()/?:@&=+$,%#;[\]]/.test(value)) {
      return false;
    }

    if (/[^[^&.+/=?]\*|[^&.+/=?]\*[^&.+/=?]|\*[^&.+/=?\]]/.test(value)) {
      return false;
    }

    return true;
  },
} as ValidationRuleSchema;






































































import Vue, { PropType } from "vue";
import { NatNaptDetailEntity, NatIpEntity } from "@/apis/MSPeeringIpApi";

export default Vue.extend({
  name: "MSNatSettingModifyConfirm",
  props: {
    /** NAT設定情報 */
    currentInfo: {
      type: Object as PropType<NatNaptDetailEntity>,
      required: true,
    },
    /** 払出済みのNAT設定情報 */
    natSetting: {
      type: Array as PropType<NatIpEntity[]>,
      required: true,
    },
    /** 払出していないNAT設定情報 */
    newIp: {
      type: Array as PropType<NatIpEntity[]>,
      required: true,
    },
  },
});












































































































































































































































































































































import Vue, { PropType } from "vue";
import InternetType4PolicyUtmConfirm from "@/modals/applianceContractSetting/InternetType4PolicyUtmConfirm.vue";
import InternetType4PolicyRuleUtmViewRD from "@/modals/applianceContractSetting/InternetType4PolicyRuleUtmViewRD.vue";
import InternetType4PolicyRuleIpMasqViewRD from "@/modals/applianceContractSetting/InternetType4PolicyRuleIpMasqViewRD.vue";
import InternetType4PolicyRuleDestNatViewRD from "@/modals/applianceContractSetting/InternetType4PolicyRuleDestNatViewRD.vue";
import {
  GetDstAddress1,
  GetDstAddress2,
  GetPolicy,
  GetPolicyType4,
  GetSrcAddress1,
  GetSrcAddress2,
  PutDstAddress1,
  PutDstAddress2,
  PutPolicyType4,
  PutSrcAddress1,
  PutSrcAddress2,
} from "@/apis/NwPolicyApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicyService from "@/components/policy/PolicyService.vue";
import PolicyProfile from "@/components/policy/PolicyProfile.vue";
import { Type4Service } from "@/apis/ContractChangeType4Api";
import PolicyIpPool from "@/components/policy/PolicyIpPool.vue";
import { UploadError } from "@/services/ConfigDownloadService";

export default Vue.extend({
  name: "InternetType4PolicyUtmList",
  components: {
    PolicyIpPool,
    PolicyProfile,
    PolicyService,
    PolicyDstAddress,
    PolicySrcAddress,
  },
  props: {
    /** Type4Id. 主キー */
    type4Id: {
      type: String as PropType<string>,
      required: true,
    },
    /** サービスメニュー(インターネットType4) */
    type4Service: {
      type: Object as PropType<Type4Service>,
      required: true,
    },
  },
  data() {
    return {
      direction: "accessPointToInternet",
      policy: {} as GetPolicyType4,
      i2aNatTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddressList", label: "送信元アドレス" },
          { key: "ipMapping", label: "IPマッピング" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      i2aUtmTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddress", label: "送信元アドレス" },
          { key: "dstAddress", label: "宛先アドレス" },
          { key: "service", label: "サービス" },
          { key: "profile", label: "プロファイル" },
          { key: "actionType", label: "アクション" },
          { key: "isLogOutput", label: "ログ出力" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      a2iNatTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddressList", label: "送信元アドレス" },
          { key: "dstAddressList", label: "宛先アドレス" },
          { key: "ipPoolList", label: "IP Pool" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      a2iUtmTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddress", label: "送信元アドレス" },
          { key: "dstAddress", label: "宛先アドレス" },
          { key: "service", label: "サービス" },
          { key: "profile", label: "プロファイル" },
          { key: "actionType", label: "アクション" },
          { key: "isLogOutput", label: "ログ出力" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      uploadAlert: null as string | null,
      isLoaded: false,
    };
  },
  async mounted() {
    this.policy = await this.$api.nwPolicy.getType4Policies(
      this.type4Id,
      "UTM_IPMASQUERADE_NAT"
    );
    this.isLoaded = true;
  },
  methods: {
    /** ポリシー設定反映 */
    async putPolicy() {
      this.uploadAlert = null;
      // ポリシー画面からオブジェクトを追加するとkeyIdを更新する必要があるため以下で再取得
      this.isLoaded = false;
      this.policy.policyKeyId = (
        await this.$api.nwPolicy.getType4Policies(
          this.type4Id,
          "UTM_IPMASQUERADE_NAT"
        )
      ).policyKeyId;
      this.isLoaded = true;
      await this.$modal.show(InternetType4PolicyUtmConfirm, {
        type4Policy: this.policy,
        type4Service: this.type4Service,
      });
      await this.$api.nwPolicy.putType4Policies(
        this.type4Id,
        get2putPolicy(this.policy),
        "UTM_IPMASQUERADE_NAT"
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 設定ダウンロード */
    downloadConfig() {
      this.$service.configDownload.downloadInternetType4Policy(
        this.type4Id,
        "UTM",
        this.policy
      );
    },

    /** 設定アップロード */
    async uploadConfig() {
      try {
        const uploadPolicy =
          await this.$service.configDownload.uploadInternetType4Policy(
            this.type4Id,
            "UTM",
            this.policy
          );
        this.uploadAlert = null;
        await this.$confirm(
          "ポリシー設定を上書きします。設定反映していない設定は破棄されます。よろしいですか？"
        );
        this.policy = uploadPolicy;
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },

    /** インターネット→アクセス拠点 NATのルールを編集 */
    async editPolicyInternetToAccessNAT() {
      this.uploadAlert = null;
      this.policy.policyNatType4!.policyList = await this.$modal.show(
        InternetType4PolicyRuleDestNatViewRD,
        {
          type4Id: this.type4Id,
          policyList: this.policy.policyNatType4!.policyList,
          type4Policy: this.policy,
          type4Service: this.type4Service,
        }
      );
    },

    /** インターネット→アクセス拠点 UTMのルールを編集 */
    async editPolicyInternetToAccessUTM() {
      this.uploadAlert = null;
      this.policy.policyUtmType4!.internetToAccessPointPolicyList =
        await this.$modal.show(InternetType4PolicyRuleUtmViewRD, {
          type4Id: this.type4Id,
          from: "INTERNET",
          policyList:
            this.policy.policyUtmType4!.internetToAccessPointPolicyList,
          type4Policy: this.policy,
          type4Service: this.type4Service,
        });
    },

    /** アクセス拠点→インターネットNATのルールを編集 */
    async editPolicyAccessToInternetNAT() {
      this.uploadAlert = null;
      this.policy.policyIPMasqueradeType4!.policyList = await this.$modal.show(
        InternetType4PolicyRuleIpMasqViewRD,
        {
          type4Id: this.type4Id,
          policyList: this.policy.policyIPMasqueradeType4!.policyList,
          type4Policy: this.policy,
          type4Service: this.type4Service,
        }
      );
    },

    /** アクセス拠点→インターネットUTMのルールを編集 */
    async editPolicyAccessToInternetUTM() {
      this.uploadAlert = null;
      this.policy.policyUtmType4!.accessPointToInternetPolicyList =
        await this.$modal.show(InternetType4PolicyRuleUtmViewRD, {
          type4Id: this.type4Id,
          from: "ACCESS_POINT",
          policyList:
            this.policy.policyUtmType4!.accessPointToInternetPolicyList,
          type4Policy: this.policy,
          type4Service: this.type4Service,
        });
    },
    /**
     * ポリシーテーブルのtrクラス.
     * 編集不可デフォルトポリシーの場合は特定のクラスを返す
     */
    policyRowClass(item: Pick<GetPolicy, "policyCategoryInfo">): string | null {
      return item.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY"
        ? "not-editable-policy"
        : null;
    },
  },
});

/** type4ポリシーGETをPUT用のポリシーに変換 */
export function get2putPolicy(type4policy: GetPolicyType4): PutPolicyType4 {
  const toSrcAddress1 = (src: GetSrcAddress1): PutSrcAddress1 => ({
    srcAddressList: src.srcAddressList.map((e) => e.ipAddressId!),
    srcFqdnList: src.srcFqdnList.map((e) => e.fqdnId),
    srcCountryList: src.srcCountryList.map((e) => e.countrySeq),
  });
  const toDstAddress1 = (dst: GetDstAddress1): PutDstAddress1 => ({
    dstAddressList: dst.dstAddressList.map((e) => e.ipAddressId!),
  });
  const toSrcAddress2 = (src: GetSrcAddress2): PutSrcAddress2 => ({
    srcAddressList: src.srcAddressList.map((e) => e.ipAddressId!),
  });
  const toDstAddress2 = (dst: GetDstAddress2): PutDstAddress2 => ({
    dstAddressList: dst.dstAddressList.map((e) => e.ipAddressId!),
    dstFqdnList: dst.dstFqdnList.map((e) => e.fqdnId),
    dstCountryList: dst.dstCountryList.map((e) => e.countrySeq),
  });
  const toDefaultService = (s: GetPolicy["defaultServiceList"]): string[] =>
    s.map((e) => e.serviceSeq);
  const toCustomService = (s: GetPolicy["customServiceList"]): string[] =>
    s.map((e) => e.customServiceSeq);

  return {
    policyUtmType4: type4policy.policyUtmType4
      ? {
          internetToAccessPointPolicyList:
            type4policy.policyUtmType4.internetToAccessPointPolicyList
              .filter(
                (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
              )
              .map((policy) => ({
                ...policy,
                srcAddress: toSrcAddress1(policy.srcAddress),
                dstAddress: toDstAddress1(policy.dstAddress),
                defaultServiceList: toDefaultService(policy.defaultServiceList),
                customServiceList: toCustomService(policy.customServiceList),
              })),
          accessPointToInternetPolicyList:
            type4policy.policyUtmType4.accessPointToInternetPolicyList
              .filter(
                (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
              )
              .map((policy) => ({
                ...policy,
                srcAddress: toSrcAddress2(policy.srcAddress),
                dstAddress: toDstAddress2(policy.dstAddress),
                defaultServiceList: toDefaultService(policy.defaultServiceList),
                customServiceList: toCustomService(policy.customServiceList),
              })),
        }
      : null,
    policyIfwType4: type4policy.policyIfwType4
      ? {
          internetToAccessPointPolicyList:
            type4policy.policyIfwType4.internetToAccessPointPolicyList
              .filter(
                (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
              )
              .map((policy) => ({
                ...policy,
                srcAddress: toSrcAddress1(policy.srcAddress),
                dstAddress: toDstAddress1(policy.dstAddress),
                defaultServiceList: toDefaultService(policy.defaultServiceList),
                customServiceList: toCustomService(policy.customServiceList),
              })),
          accessPointToInternetPolicyList:
            type4policy.policyIfwType4.accessPointToInternetPolicyList
              .filter(
                (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
              )
              .map((policy) => ({
                ...policy,
                srcAddress: toSrcAddress2(policy.srcAddress),
                dstAddress: toDstAddress2(policy.dstAddress),
                defaultServiceList: toDefaultService(policy.defaultServiceList),
                customServiceList: toCustomService(policy.customServiceList),
              })),
        }
      : null,
    policyIPMasqueradeType4: type4policy.policyIPMasqueradeType4
      ? {
          policyList: type4policy.policyIPMasqueradeType4.policyList
            .filter((e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY")
            .map((policy) => ({
              ...policy,
              srcAddressList: policy.srcAddressList.map((e) => e.ipAddressId!),
              dstAddressList: policy.dstAddressList.map((e) => e.ipAddressId!),
              ipPoolList: policy.ipPoolList.map((e) => e.ipPoolSeq),
            })),
        }
      : null,
    policyNatType4: type4policy.policyNatType4
      ? {
          policyList: type4policy.policyNatType4.policyList.map((policy) => ({
            ...policy,
            srcAddressList: policy.srcAddressList.map((e) => e.ipAddressId!),
          })),
        }
      : null,
    policyKeyId: type4policy.policyKeyId,
  };
}

import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { AxiosResponse } from "axios";

/** ログインAPIリクエスト */
export interface LoginRequest {
  /** ログインID */
  loginId: string;
  /** パスワード */
  password: string;
  /**
   * 強制ログインフラグ
   * false: 通常時, true: 強制時
   */
  isForceLogin: boolean;
}

/** ログインAPIレスポンス */
export interface LoginResponse {
  /** トークンID */
  tokenId: string;
  /**
   * パスワード有効期限
   * 日時形式: YYYY-MM-DD
   */
  passwordExpiredDate: string;
  /**
   * パスワード有効日数
   * 有効日数がパスワード有効期限通知値(90日)を上回る場合、0を返す
   */
  passwordLimit: number;
}

export default class AuthApi extends AbstractBaseApi {
  /**
   * 通常ログイン
   * @param data リクエストデータ
   */
  async login(data: LoginRequest): Promise<LoginResponse> {
    return (await this.api.post("/v1/auth/login", data)).data;
  }

  /** ログアウト */
  async logout(): Promise<AxiosResponse> {
    return await this.api.post("/v1/auth/logout");
  }

  /** 代行ユーザを削除 */
  async logoutProxy(): Promise<void> {
    await this.api.post("/v1/auth/logout/cancel-proxy");
  }
}

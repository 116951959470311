import AbstractBaseApi from "@/apis/AbstractBaseApi";
import {
  BandWidth,
  BandWidthEmpty,
  IfStatus,
  MaximumPrefix,
  Priority,
  RouteAggregation,
  VpnVnCode,
} from "@/apis/CloudVnMsApi";

export interface CloudVnGcp {
  /** E番号. */
  enumber: string;
  /**
   * アクセス品目.
   * 以下のいずれかを指定する。
   * MULTI_CLOUD_GW：マルチクラウドGW
   */
  accessType: "MULTI_CLOUD_GW";
  /** 帯域. GET時に登録された帯域が存在しない場合は「0」を指定する。 */
  bandwidth: BandWidth | BandWidthEmpty;
  /** 事業所名. */
  office: string | null;
  /**
   * クラウドVN回線(GCP)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnGcpKeyId: string;
}

export interface CloudVnGcpCommon {
  /**
   * クラウドサービス利用拠点.
   * 下記のいずれかを指定する。
   * EAST_JAPAN: 東日本
   * WEST_JAPAN: 西日本
   */
  location: "EAST_JAPAN" | "WEST_JAPAN";
  /** VPN/VNコード */
  vpnVnCode: VpnVnCode;
  /** 仮想ネットワークタイプ. L3固定 */
  vnType: "L3";
  /** 仮想ネットワーク名. */
  vnName: string | null;
  /** ルーティング種別. BGP4固定 */
  routingType: "BGP4";
  /** 帯域タイプ. PF固定 */
  bandwidthType: "PF";
  /** 帯域 */
  bandwidth: BandWidth;
  /**
   * 経路集約.
   * OFF、ON、CUSTOMのいずれかを指定する。
   * OFF：経路集約を非表示
   * ON：経路集約を表示
   * CUSTOM：経路集約アドレスを入力
   */
  routeAggregation: RouteAggregation;
  /**
   * 経路集約アドレスリスト.
   * 経路集約が「CUSTOM」選択時は必須項目
   * 登録順でソートする。
   */
  routeAggregationAddressList: Array<string> | null;
  /** 設定値の説明  */
  description: string | null;
}

export interface CloudVnGcpActSby {
  /** クラウドVNコネクトSEQ. */
  cloudVnConnectSeq: string;
  /** VNコネクト名. */
  vnConnectName: string;
  /**
   * W番号.
   * 主キー
   */
  wnumber: string;
  /**
   * IF状態. VNコネクト状態が無効の場合省略
   * NO_SHUTDOWN: 開放状態
   * SHUTDOWN: 閉塞状態
   */
  ifStatus: IfStatus;
  /** VNコネクト有効/無効フラグ. true:有効 false:無効 */
  isVnConnectStatus: boolean;
  /** クラウドサービスキー情報. GCPサービスキー */
  serviceKey: string;
  /** AS番号. */
  asNumber: number;
  /** WANアドレス. */
  wanAddress: string | null;
  /** ピアIPアドレス */
  peerIpAddress: string | null;
  /**
   * MD5文字列.
   * null固定
   */
  md5Key: null;
  /**
   * Maximum prefix(設定値上限取得APIのBGP4の最大経路数).
   * 設定値上限APIのGETから上限値を取得し、その上限値以下の値を設定可能とする。
   * 値は100,200,300,400,500,1000,1500,2000から指定する。
   */
  maximumPrefix: MaximumPrefix;
  /**
   * ローカルプリファレンス.
   * HIGH_PRIORITY:優先
   * LOW_PRIORITY:非優先
   * ACT回線識別フラグがtrueの場合:「HIGH_PRIORITY」固定
   * ACT回線識別フラグがfalseの場合:「LOW_PRIORITY」固定
   */
  localPreference: Priority;
  /**
   * MED.
   * HIGH_PRIORITY:優先
   * LOW_PRIORITY:非優先
   * ACT回線識別フラグがtrueの場合:「HIGH_PRIORITY」固定
   * ACT回線識別フラグがfalseの場合:「LOW_PRIORITY」固定
   */
  med: Priority;
}

export type CloudVnGcpList = CloudVnGcp & {
  /**
   * 総件数（クラウドVN回線）.
   * 一覧表示対象の総件数
   */
  cloudVnConnectTotal: number;
  cloudVnConnectsList: {
    cloudVnConnectCommon: Pick<
      CloudVnGcpCommon,
      | "location"
      | "vpnVnCode"
      | "vnType"
      | "routingType"
      | "bandwidthType"
      | "bandwidth"
      | "description"
    >;
    cloudVnConnectAct: Pick<
      CloudVnGcpActSby,
      | "cloudVnConnectSeq"
      | "vnConnectName"
      | "wnumber"
      | "ifStatus"
      | "isVnConnectStatus"
      | "serviceKey"
    >;
    cloudVnConnectSby:
      | CloudVnGcpList["cloudVnConnectsList"][0]["cloudVnConnectAct"]
      | null;
  }[];
};

/**
 * WANアドレス、ピアIPアドレスの払い出し/登録処理が失敗した場合
 * ロールバックは行わず、WANアドレス、ピアIPアドレスをNULLで返却する。
 */
export type CloudVnGcpDetail = CloudVnGcp & {
  cloudVnConnectCommon: CloudVnGcpCommon;
  cloudVnConnectAct: CloudVnGcpActSby;
  cloudVnConnectSby: CloudVnGcpActSby | null;
};

/**
 * 設定追加には以下の3パターンがある。
 *
 * ACT/SBY回線を同時に追加するパターン
 * ACT回線のみを追加するパターン
 * 作成済みのACT回線に紐づくSBY回線を追加するパターン
 * また以下の項目は固定値となり、API内部で設定するため省略する。
 * ・MED
 *  ACT回線の場合は「HIGH_PRIORITY」固定
 *  SBY回線の場合は「LOW_PRIORITY」固定
 * ・ローカルプレファレンス
 *  ACT回線の場合は「HIGH_PRIORITY」固定
 *  SBY回線の場合は「LOW_PRIORITY」固定
 * ・ルーティング種別
 *  BGP4固定
 * ・md5文字列
 *  「」空白固定
 */
export interface CloudVnGcpPost {
  /** ACT/SBY回線を同時に追加するパターン、ACT回線のみを追加するパターンでは必須となる。 */
  cloudVnConnectCommon: Pick<
    CloudVnGcpCommon,
    | "location"
    | "vpnVnCode"
    | "bandwidthType"
    | "bandwidth"
    | "routeAggregation"
    | "routeAggregationAddressList"
    | "description"
  > | null;
  /** ACT/SBY回線を同時に追加するパターン、ACT回線のみを追加するパターンでは必須となる。 */
  cloudVnConnectAct: Pick<
    CloudVnGcpActSby,
    "serviceKey" | "vnConnectName" | "asNumber" | "maximumPrefix"
  > | null;
  cloudVnConnectSby:
    | (CloudVnGcpPost["cloudVnConnectAct"] & {
        /**
         * ペアクラウドVNコネクトSEQ.
         * SBY回線のみの設定の場合必須となる。
         * その場合ACT回線のクラウドVNコネクトSEQを指定する。
         */
        pairCloudVnConnectSeq: string | null;
      })
    | null;
  /** 操作履歴の備考 */
  note?: string;
  /**
   * クラウドVN回線(GCP)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnGcpKeyId: string;
}

export interface CloudVnGcpPut {
  cloudVnConnectCommon: Pick<
    CloudVnGcpCommon,
    | "bandwidth"
    | "routeAggregation"
    | "routeAggregationAddressList"
    | "description"
  >;
  cloudVnConnectAct: Pick<CloudVnGcpActSby, "vnConnectName" | "maximumPrefix">;
  cloudVnConnectSby: Pick<
    CloudVnGcpActSby,
    "wnumber" | "vnConnectName" | "maximumPrefix"
  > | null;
  /** 操作履歴の備考 */
  note?: string;
  /**
   * クラウドVN回線(GCP)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnGcpKeyId: string;
}

export interface CloudVnGcpOrder {
  /** W番号. 主キー */
  cloudVnConnectList: string[];
  /**
   * クラウドVN回線(GCP)排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudVnGcpKeyId: string;
}

export type CloudVnGcpDelete = CloudVnGcpOrder & {
  /** 操作履歴の備考 */
  note?: string;
};

/** クラウドVN回線(GCP)API */
export default class CloudVnGcpApi extends AbstractBaseApi {
  /**
   * クラウドVN回線設定を取得
   * @param cloudLineSeq クラウド回線SEQ
   */
  async getVnConnects(cloudLineSeq: string): Promise<CloudVnGcpList> {
    return (
      await this.api.get(
        `/v1/network/cloud/line/${cloudLineSeq}/gcp/vnconnects`
      )
    ).data;
  }

  /**
   * クラウドVN回線設定を追加
   * @param cloudLineSeq クラウド回線SEQ
   * @param data クラウドVN回線設定
   */
  async postVnConnect(
    cloudLineSeq: string,
    data: CloudVnGcpPost
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/gcp/vnconnects`,
      data
    );
  }

  /**
   * クラウドVN回線設定を変更
   * @param cloudLineSeq クラウド回線SEQ
   * @param wnumber W番号. ACT回線のW番号を指定する
   * @param data クラウドVN回線設定
   */
  async putVnConnect(
    cloudLineSeq: string,
    wnumber: string,
    data: CloudVnGcpPut
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/gcp/vnconnects/${wnumber}`,
      data
    );
  }

  /**
   * クラウドVNコネクトの有効/無効状態を有効に変更
   * @param data 有効情報
   */
  async putEnableVnConnect(data: CloudVnGcpOrder): Promise<void> {
    await this.api.post(`/v1/network/cloud/line/gcp/vnconnects/enable`, data);
  }

  /**
   * クラウドVNコネクトの有効/無効状態を無効に変更
   * @param data 無効情報
   */
  async putDisableVnConnect(data: CloudVnGcpOrder): Promise<void> {
    await this.api.post(`/v1/network/cloud/line/gcp/vnconnects/disable`, data);
  }

  /**
   * クラウドVN回線設定の詳細情報を取得
   * @param wnumber W番号. ACT回線のW番号を指定する。
   */
  async getVnConnect(wnumber: string): Promise<CloudVnGcpDetail> {
    return (
      await this.api.get(`/v1/network/cloud/line/gcp/vnconnect/${wnumber}`)
    ).data;
  }

  /**
   * クラウドVN回線設定を削除
   * @param cloudLineSeq
   * @param data
   */
  async deleteVnConnect(
    cloudLineSeq: string,
    data: CloudVnGcpDelete
  ): Promise<void> {
    await this.api.post(
      `/v1/network/cloud/line/${cloudLineSeq}/gcp/vnconnects/delete`,
      data
    );
  }
}




































































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import {
  Type4InternetAddress,
  Type4InternetFQDN,
  Type4InternetSite,
} from "@/apis/Type4SiteApi";
import { ApplianceInfo } from "@/apis/ContractApi";
import Type4InternetSiteModify from "@/modals/type4/Type4InternetSiteModify.vue";

export default Vue.extend({
  name: "Type4InternetSiteReference",
  props: {
    internetSite: {
      type: Object as PropType<Type4InternetSite>,
      required: true,
    },
    type4Id: {
      type: String as PropType<ApplianceInfo["type4Id"]>,
      required: true,
    },
  },
  data() {
    return {
      addressList: {
        fields: [
          {
            key: "ipAddressName",
            label: "アドレス名",
          },
          {
            key: "ipAddress",
            label: "アドレス",
          },
          {
            key: "isPolicyInUse",
            label: "ポリシー使用有無",
          },
        ],
        items: [],
      } as Pick<AppTableData<Type4InternetAddress>, "fields" | "items">,
      fqdnList: {
        fields: [
          {
            key: "fqdnName",
            label: "FQDN名",
          },
          {
            key: "fqdn",
            label: "FQDN",
          },
          {
            key: "isPolicyInUse",
            label: "ポリシー使用有無",
          },
        ],
        items: [],
      } as Pick<AppTableData<Type4InternetFQDN>, "fields" | "items">,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    /** 初期化機能 */
    load() {
      this.addressList.items = this.internetSite.addressList;
      this.fqdnList.items = this.internetSite.fqdnList;
    },
    /** 変更機能 */
    async toChange() {
      await this.$modal.show(Type4InternetSiteModify, {
        internetSite: this.internetSite,
        type4Id: this.type4Id,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});





































































import Vue from "vue";
import { UserEntity, UserGetEntity } from "@/apis/UserApi";
import UserEdit from "@/modals/user/UserEdit.vue";
import { AppTableData } from "@/components/AppTable.vue";
import { SearchOption } from "@/components/AppSearch.vue";
import sortCompare from "@/services/SortService";
import { BvTableField } from "bootstrap-vue/src/components/table";
import { BvTableFormatterCallback } from "bootstrap-vue/esm";
import UserReference from "@/modals/user/UserReference.vue";

export default Vue.extend({
  name: "UserList",
  data() {
    return {
      alertMsg: null as string | null,
      table: {
        fields: [
          {
            key: "loginId",
            label: "ログインID",
            sortable: true,
          },
          {
            key: "Name",
            label: "氏名",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v: string, _k: null, item: UserEntity) => {
              return `${item.familyName} ${item.givenName}`;
            },
          },
          {
            key: "NameKana",
            label: "氏名(カナ)",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v: string, _k: null, item: UserEntity) => {
              if (item.familyNameKana === null && item.givenNameKana === null) {
                return null;
              } else if (item.familyNameKana === null) {
                return `${item.givenNameKana}`;
              } else if (item.givenNameKana === null) {
                return `${item.familyNameKana}`;
              } else {
                return `${item.familyNameKana} ${item.givenNameKana}`;
              }
            },
          },
          {
            key: "group",
            label: "所属グループ",
            sortable: true,
          },
          {
            key: "phone",
            label: "連絡先電話番号",
            sortable: true,
          },
          {
            key: "email",
            label: "連絡先メールアドレス",
            sortable: true,
          },
          {
            key: "operationType",
            label: "運用種別",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (_v: string) => this.$filter.enumTo(_v, "operationType"),
          },
        ],
        selected: [],
        filter: {} as Record<string, unknown>,
        filter_option: {
          loginId: "like",
          Name: "like",
          group: "equal",
          email: "like",
          operationType: "equal",
        },
      } as Omit<AppTableData<UserEntity>, "items" | "search">,
      users: {} as UserGetEntity,
      isLoaded: false,
    };
  },
  computed: {
    /** 検索コンポーネントのオプション */
    searchOption(): SearchOption<UserEntity> {
      return {
        loginId: { type: "text", label: "ログインID" },
        Name: { type: "text", label: "氏名" },
        group: {
          type: "select",
          label: "所属グループ",
          // 必要の場合のみ選択肢の最後に-を追加
          items: this.users.userList
            .map((e) => e.group)
            .filter((e) => e)
            .uniq()
            .sortBy("asc")
            .map<{
              text: string;
              value: string | null;
            }>((e) => ({ text: e!, value: e! }))
            .concat(
              this.users.userList.find((e) => !e.group)
                ? [{ text: "-", value: null }]
                : []
            ),
        },
        email: { type: "text", label: "連絡先メールアドレス" },
        operationType: {
          type: "select",
          label: "運用種別",
          items: [
            { text: "法人管理者", value: "ADMIN" },
            { text: "設定権限", value: "SETTING" },
            { text: "閲覧権限", value: "VIEWING" },
          ],
        },
      };
    },
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    /** 法人権限の場合、APIより自分以下の権限のユーザのみ返却される */
    async load() {
      this.alertMsg = null;
      this.users = await this.$api.user.getAll();
      // 認証IPアドレスの初期ソート対応
      this.users.userList.forEach((e) => {
        e.ipAuthAddressList.sort();
      });
    },

    /** 第二優先を必ずログインIDの昇順にするソート */
    customSortUser(
      a: UserEntity,
      b: UserEntity,
      key: string,
      sortDesc: boolean
    ): number | null {
      // formatterが存在する場合はそちらを使用する
      const formatterByField = (
        this.table.fields as Array<{ key: string } & BvTableField>
      ).find((e) => {
        return e.key === key && e.sortByFormatted && e.formatter;
      });

      return sortCompare(a, b, [
        [
          formatterByField
            ? (e) =>
                (formatterByField.formatter as BvTableFormatterCallback)(
                  e[key as keyof UserEntity],
                  key,
                  e
                )
            : (key as keyof UserEntity),
          "asc",
        ],
        [(e) => e.loginId, sortDesc ? "desc" : "asc"],
      ]);
    },

    /** 削除 */
    async del() {
      if (this.table.selected.length > 0) {
        this.alertMsg = null;
        await this.$confirm(`ユーザを削除します。よろしいですか？`);
        await this.$api.user.delete({
          userSeqList: this.table.selected.map((e) => e.userSeq),
          userKeyId: this.users.userKeyId,
        });
        await this.load();
      } else {
        this.alertMsg = this.$msg("no_select_data");
      }
    },
    /** 参照へ */
    async userDetails(user: UserEntity) {
      this.alertMsg = null;
      await this.$modal.show(UserReference, {
        user: user,
        userKeyId: this.users.userKeyId,
      });
      await this.load();
    },
    /** 追加 */
    async showUserRegister() {
      this.alertMsg = null;
      await this.$modal.show(UserEdit, {
        vnumber: this.users.vnumber,
      });
      await this.load();
    },
    /** フロント側のみでのCSVダウンロード */
    downloadCsv() {
      this.$service.csvService.appTableCsvDownLoad(
        "user",
        this.users.userList.sortBy(["loginId", "asc"]),
        this.$service.csvService.genFieldList(this.table.fields)
      );
    },
  },
});


























































































































import Vue, { PropType } from "vue";
import { CloudQosInfo } from "@/apis/CloudQosApi";
import { QosDetail } from "@/apis/QosApi";
import { ControlPattern } from "@/apis/VNConnectApi";

export default Vue.extend({
  name: "QoSSetting",
  props: {
    /** VPN/VNコード */
    vpnVnCode: {
      type: String as PropType<string>,
      required: true,
    },
    /** QoS帯域制御パターン。指定した場合はそのパターンのみが表示される */
    bandControlPattern: {
      type: String as PropType<ControlPattern>,
      required: false,
    },
  },
  data() {
    return {
      absolutePriorityRowNames: [
        { key: "HIGH", value: "High" },
        { key: "MEDIUM", value: "Medium" },
        { key: "NORMAL", value: "Normal" },
        { key: "LOW", value: "Low" },
      ],
      absolutePriorityPropNames: [
        "priority0",
        "priority1",
        "priority2",
        "priority3",
        "priority4",
        "priority5",
        "priority6",
        "priority7",
      ],
      bandwidthRowNames: [
        { key: "PATTERN_A", value: "パターンA" },
        { key: "PATTERN_A", value: "" },
        { key: "PATTERN_B", value: "パターンB" },
        { key: "PATTERN_B", value: "" },
        { key: "PATTERN_C", value: "パターンC" },
        { key: "PATTERN_C", value: "" },
        { key: "PATTERN_D", value: "パターンD" },
        { key: "PATTERN_D", value: "" },
        { key: "PATTERN_E", value: "パターンE" },
        { key: "PATTERN_E", value: "" },
      ],
      bandwidthPropNames: [
        "priorityType1Value",
        "priorityType2Value",
        "priorityType3Value",
        "bestEffort",
      ],
      qosDetail: {} as QosDetail,
      cloudQosSettingInfo: {} as CloudQosInfo,
    };
  },
  async mounted() {
    this.qosDetail = await this.$api.qosApi.getQosDetail(this.vpnVnCode);
  },
  methods: {
    /**
     * 帯域制御方式の値を取得
     * @param patternName パターン名
     * @param propName プロパティ名
     * */
    getBandwidthValue: function (patternName: string, propName: string) {
      const item = this.qosDetail.qosBandwidth?.qosBandwidthList?.find(
        (e) => e.patternName === patternName
      );
      if (item) {
        if (propName === "bestEffort") {
          return (item.priorityType1Value || 0) +
            (item.priorityType2Value || 0) +
            (item.priorityType3Value || 0)
            ? `${
                100 -
                ((item.priorityType1Value || 0) +
                  (item.priorityType2Value || 0) +
                  (item.priorityType3Value || 0))
              }%`
            : "-";
        } else {
          switch (propName) {
            case "priorityType1Value":
              return item.priorityType1Value
                ? `${item.priorityType1Value}%`
                : "-";
            case "priorityType2Value":
              return item.priorityType2Value
                ? `${item.priorityType2Value}%`
                : "-";
            case "priorityType3Value":
              return item.priorityType3Value
                ? `${item.priorityType3Value}%`
                : "-";
          }
        }
      } else {
        return "-";
      }
    },
    /**
     * 帯域制御方式の最優先設定フラグを取得
     * @param patternName パターン名
     * */
    isType1TopPriority(patternName: string) {
      const item = this.qosDetail.qosBandwidth?.qosBandwidthList?.find(
        (e) => e.patternName === patternName
      );
      if (item) {
        return item.isType1TopPriority ? "ON" : "OFF";
      } else {
        return "-";
      }
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * CPA NW設定 テストIP専用バリデーション
 * 第4オクテットが1であるかつ、端末割当アドレスに含まれるホストアドレスかどうかチェックする
 * 例: 端末割当アドレスの値が「192.168.201.0/24」の場合
 * 192.168.201.1/32: OK
 * 192.168.202.1/32: NG
 * 192.168.201.2/32: NG
 */
export default {
  params: ["ipv4List"],
  validate(
    value: string | undefined | null,
    { ipv4List }: { ipv4List: string[] }
  ): string | boolean {
    if (!value) return true;
    let ipAddress: [ipaddr.IPv4, number];
    try {
      ipAddress = ipaddr.IPv4.parseCIDR(value);
    } catch (e) {
      // IPv4形式になっている前提なので、このバリデーターとしてはtrue
      return true;
    }

    // 第4オクテットが1でない場合はエラーとする
    if (ipAddress[0].octets[3] !== 1) {
      return "第4オクテットは1で入力してください";
    }

    // 端末割当アドレスは複数件あるため、ループでチェックしていく
    for (const ipv4 of ipv4List) {
      let ueIpAllocationIp: [ipaddr.IPv4, number];
      try {
        ueIpAllocationIp = ipaddr.IPv4.parseCIDR(ipv4);
      } catch (e) {
        // IPv4形式になっている前提なので、このバリデーターとしてはtrue
        return true;
      }
      // 端末割当アドレスに含まれるホストアドレスであればOKとし終了
      const result = ipAddress[0].match(ueIpAllocationIp);
      if (result) return true;
    }
    // 端末割当アドレスに含まれないホストアドレスはエラー
    return "端末割当アドレスに含まれるホストアドレスを入力してください";
  },
} as ValidationRuleSchema;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-medium","title":"サフィックス登録"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"IPsecGW回線番号"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.ipsecGW.enumber))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"IPsec回線数/IPsec回線接続上限数"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.ipsecGW.numberOfIpsec)+"/"+_vm._s(_vm.ipsecGW.upperLimitIpsec)+"回線")])]),_c('b-form-group',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"サフィックス","rules":{
                  required: true,
                  max: 32,
                  is_half_width_alpha_numeric_symbol2: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('app-badge',{attrs:{"msg":_vm._f("msg")('info_sign_num_max',{ sign: '.', max: 32 }),"isRequired":true,"isInfo":true,"item":"サフィックス"}}),_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.form.suffix),callback:function ($$v) {_vm.$set(_vm.form, "suffix", $$v)},expression:"form.suffix"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"WANアドレス"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.ipsecGW.wanAddress))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"IPsecGWアドレス"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.ipsecGW.ipsecGatewayAddress))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"管理用アドレス①"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.ipsecGW.manageAddress1))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"管理用アドレス②"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.ipsecGW.manageAddress2))])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"管理用アドレス③"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.ipsecGW.manageAddress3))])])],1)]),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("登録")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** URLに「*&*」「*+*」「*.*」「*／*」「*;*」「*=*」「*?*」のいずれかが含まれないこと */

export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }

    return !/\*&\*|\*\+\*|\*\.\*|\*\/\*|\*;\*|\*=\*|\*\?\*/.test(value);
  },
} as ValidationRuleSchema;

























































































import Vue, { PropType } from "vue";
import InternetType1ContractApplianceConfirm from "@/modals/applianceContractSetting/InternetType1ContractApplianceConfirm.vue";
import {
  ContractGetType1Appliance,
  ContractChangeType1Appliance,
} from "@/apis/ContractChangeType1Api";

export default Vue.extend({
  name: "InternetType1ContractApplianceModify",
  props: {
    contractGetType1Appliance: {
      type: Object as PropType<ContractGetType1Appliance>,
      required: true,
    },
  },
  data() {
    return {
      alertMsg: null as string | null,
      contractChangeType1Appliance: {
        ...this.contractGetType1Appliance,
        note: null,
      } as ContractChangeType1Appliance,
      /**
       * UTMorIFWどちらの契約を実施しているか判定を格納
       * UTM:UTM契約
       * IFW:IFW契約
       */
      isUtmOrIfw: "",
      /**
       * アプライアンスを解約したかを格納
       * true:解約
       * false:契約
       */
      applianceAccepted: false,
      natChange: false,
    };
  },
  computed: {
    /** 関連項目チェック
     * 初期表示した内容から設定内容に変更があること。
     */
    objectChanged(): boolean {
      // アプライアンスを解約した場合、無条件で変更あり
      if (this.applianceAccepted) {
        return true;
        // NAT契約を変更した場合、変更あり
      } else if (
        this.contractGetType1Appliance.natEnabled !==
        this.contractChangeType1Appliance.natEnabled
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    /**
     *  アプライアンス解約にチェック
     *    NAT契約を解約にする
     *    アプライアンス(UTM/IFW)を解約にする
     *  アプライアンス解約のチェックを外す
     *    NAT契約/アプライアンス(UTM/IFW)を変更前の値に戻す
     * */
    applianceAccepted: function (val: boolean) {
      if (val) {
        this.contractChangeType1Appliance.natEnabled = false;
        this.contractChangeType1Appliance.utmEnabled = false;
        this.contractChangeType1Appliance.ifwEnabled = false;
      } else {
        this.contractChangeType1Appliance.natEnabled =
          this.contractGetType1Appliance.natEnabled;
        this.contractChangeType1Appliance.utmEnabled =
          this.contractGetType1Appliance.utmEnabled;
        this.contractChangeType1Appliance.ifwEnabled =
          this.contractGetType1Appliance.ifwEnabled;
      }
    },
  },
  async mounted() {
    /** UTM契約、IFW契約どちらの契約が対象か判断する */
    if (this.contractGetType1Appliance.utmEnabled) {
      this.isUtmOrIfw = "UTM";
    } else {
      this.isUtmOrIfw = "IFW";
    }
  },
  methods: {
    /** 契約変更確認画面を表示 */
    async submit() {
      this.contractChangeType1Appliance.contractType1KeyId =
        this.contractGetType1Appliance.contractType1KeyId;
      // 確認モーダルを表示
      await this.$modal.show(InternetType1ContractApplianceConfirm, {
        contractChangeType1Appliance: this.contractChangeType1Appliance,
        isUtmOrIfw: this.isUtmOrIfw,
        applianceChange: this.applianceAccepted ? true : false,
        natChange:
          this.contractGetType1Appliance.natEnabled !==
          this.contractChangeType1Appliance.natEnabled,
      });
      // 情報更新
      await this.$api.contract_change_type1.postContractType1Appliance(
        this.contractChangeType1Appliance
      );
      if (
        !this.contractChangeType1Appliance.utmEnabled &&
        !this.contractChangeType1Appliance.ifwEnabled
      ) {
        // アプライアンス契約(UTM/IFW)が解約された場合、プロビジョニング完了後に再取得してvuexのデータを更新
        await this.$store.dispatch(
          "provisioning/addCompletedEvent",
          async () => {
            await this.$store.dispatch("user/updateLoginInfo", {
              contractSummary: await this.$api.contract.getSummary(),
            });
          }
        );
      } else {
        // NATのみ変更された場合、プロビジョニング走らないため、そのまま再取得してvuexのデータを更新
        await this.$store.dispatch("user/updateLoginInfo", {
          contractSummary: await this.$api.contract.getSummary(),
        });
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"インターネットセキュリティアプライアンスポリシー設定","classes":"modal-full"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-card-title',{staticClass:"modal-title"},[_vm._v("インターネットセキュリティアプライアンスポリシー設定")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.submit}},[_vm._v(" × ")])]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"modal-content"},[_c('span',{staticClass:"font-weight-bold mb-3"},[_vm._v("ゾーン一覧画面で、反映ボタンを押さないと機器には反映されません")]),_c('br'),_vm._v(" "+_vm._s(_vm.zoneRelationToDisplay)+" "),_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"fill":"","small":"","card":"","justified":""}},[(_vm.contractSummary.isNat)?_c('b-tab',{attrs:{"title":_vm.isInternetToAccess ? 'NAT(IP Forwarding)' : 'NAT(IP Masquerade)',"active":""}},[(_vm.isInternetToAccess)?[_c('div',{staticClass:"btn-container justify-content-between align-items-center my-2"},[_c('span',[_vm._v(_vm._s(_vm.natPolicyList.policyList.length)+"件を表示")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.editPolicyDestinationNAT}},[_vm._v(" ポリシー編集 ")])],1),_c('app-table',{attrs:{"small":"","fields":_vm.destinationNatTable.fields,"items":_vm.natPolicyList.policyList,"pagination":false,"primary-key":"policyId","thead-class":"text-center small"},scopedSlots:_vm._u([{key:"cell(srcAddressList)",fn:function(ref){
var value = ref.value;
return [_c('policy-src-address',{attrs:{"src-address":{ srcAddressList: value }}})]}},{key:"cell(ipMapping)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.ipMappingName)+" ")]}},{key:"cell(isPolicyStatus)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}}],null,false,2674472551)})]:[_c('div',{staticClass:"btn-container justify-content-between align-items-center my-2"},[_c('span',[_vm._v(_vm._s(_vm.ipMasqueradePolicyList.policyList.length)+"件を表示")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.editPolicyIpMasquerade}},[_vm._v(" ポリシー編集 ")])],1),_c('app-table',{attrs:{"small":"","fields":_vm.ipMasqueradeTable.fields,"items":_vm.ipMasqueradePolicyList.policyList,"pagination":false,"primary-key":"policyId","thead-class":"text-center small","tbody-tr-class":_vm.policyRowClass},scopedSlots:_vm._u([{key:"cell(srcAddressList)",fn:function(ref){
var value = ref.value;
return [_c('policy-src-address',{attrs:{"src-address":{ srcAddressList: value }}})]}},{key:"cell(dstAddressList)",fn:function(ref){
var value = ref.value;
return [_c('policy-dst-address',{attrs:{"dst-address":{ dstAddressList: value }}})]}},{key:"cell(ipPoolList)",fn:function(ref){
var value = ref.value;
return [_c('policy-ip-pool',{attrs:{"ip-pool-list":value}})]}},{key:"cell(isPolicyStatus)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}}],null,false,2181902702)})]],2):_vm._e(),_c('b-tab',{attrs:{"title":"UTM","active":""}},[_c('div',{staticClass:"btn-container justify-content-between align-items-center my-2"},[_c('span',[_vm._v(_vm._s(_vm.utmPolicyList.policyList.length)+"件を表示")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.editPolicyUTM}},[_vm._v(" ポリシー編集 ")])],1),_c('app-table',{attrs:{"small":"","fields":_vm.utmTable.fields,"items":_vm.utmPolicyList.policyList,"pagination":false,"primary-key":"policyId","thead-class":"text-center small","tbody-tr-class":_vm.policyRowClass},scopedSlots:_vm._u([{key:"cell(srcAddress)",fn:function(ref){
var value = ref.value;
return [_c('policy-src-address',{attrs:{"src-address":value}})]}},{key:"cell(dstAddress)",fn:function(ref){
var value = ref.value;
return [_c('policy-dst-address',{attrs:{"dst-address":value}})]}},{key:"cell(service)",fn:function(ref){
var item = ref.item;
return [_c('policy-service',{attrs:{"service-option":item.serviceOption,"default-service-list":item.defaultServiceList,"custom-service-list":item.customServiceList}})]}},{key:"cell(profile)",fn:function(ref){
var value = ref.value;
return [_c('policy-profile',{attrs:{"profile":value}})]}},{key:"cell(actionType)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"allow_deny"))+" ")]}},{key:"cell(isLogOutput)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"on_off"))+" ")]}},{key:"cell(isPolicyStatus)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}}])})],1)],1)],1)],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.submit}},[_vm._v("戻る")])],1)]},proxy:true}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
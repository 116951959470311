



























































































































































































































































































































































import Vue from "vue";
import {
  CheckError,
  CsvCheckSuccessResponse,
  VNConnectCsvCheck,
} from "@/apis/VNConnectApi";
import Encoding from "encoding-japanese";

export default Vue.extend({
  name: "VNConnectLumpRegister",
  data() {
    return {
      showCsvSuccess: false,
      showCsvError: false,
      alertMsg: null as string | null,
      form: {
        fileType: "L2VN" as "L2VN" | "L3VN_STATIC" | "L3VN_BGP4",
      },
      fileTypeOptions: [
        { text: "L2VN", value: "L2VN" },
        { text: "L3VN(Static)", value: "L3VN_STATIC" },
        { text: "L3VN(BGP)", value: "L3VN_BGP4" },
      ],
      csvCheckSuccess: {
        fields: vnL2Fields,
        items: [] as CsvCheckSuccessResponse[],
      },
      csvCheckError: {
        fields: [
          { key: "rowNumber", label: "行番号", class: "text-center" },
          { key: "error", label: "エラー内容" },
        ],
        items: [] as CheckError[],
      },
      csvCheckResponse: {} as VNConnectCsvCheck,
    };
  },
  methods: {
    async fileCheck(file: File | null) {
      if (!file) {
        this.showCsvSuccess = false;
        this.showCsvError = false;
        this.alertMsg = this.$msg("csv_no_select");
        return;
      }
      if (file.size === 0) {
        this.alertMsg = this.$msg("file_no_size");
        return;
      }
      if (!file.name.endsWith("csv")) {
        this.alertMsg = this.$msg("file_invalid_format");
        return;
      }

      const fileBuffer = await this.$service.file.readAsArrayBuffer(file);
      const encode = Encoding.detect(new Uint8Array(fileBuffer));
      if (encode !== "UTF8") {
        this.alertMsg =
          "ファイルの文字コードがutf-8ではないため、アップロードできませんでした。";
        return;
      }

      this.alertMsg = null;

      // VNコネクト一括登録情報の登録チェックAPI呼び出し処理
      this.csvCheckResponse = await this.$api.vNConnect.checkVNConnectCsv(
        this.form.fileType,
        file.name,
        new TextDecoder().decode(fileBuffer)
      );

      switch (this.form.fileType) {
        case "L2VN":
          this.csvCheckSuccess.fields = vnL2Fields;
          break;
        case "L3VN_STATIC":
          this.csvCheckSuccess.fields = vnL3StaticFields;
          break;
        case "L3VN_BGP4":
          this.csvCheckSuccess.fields = vnL3BGP4Fields;
          break;
        default:
          throw new Error(`unknown file type ${this.form.fileType}`);
      }

      if (this.csvCheckResponse.error === null) {
        /** CSVチェック成功時 */
        this.csvCheckSuccess.items =
          this.csvCheckResponse.success!.vnConnectCsvList;
        this.showCsvSuccess = true;
        this.showCsvError = false;
      } else if (this.csvCheckResponse.success === null) {
        /** CSVチェックエラー発生時 */
        this.csvCheckError.items = this.csvCheckResponse.error.checkErrorList;
        this.showCsvError = true;
        this.showCsvSuccess = false;
      }
    },
    async submit() {
      if (this.showCsvSuccess) {
        this.alertMsg = null;
        await this.$confirm(`こちらの内容で追加します。よろしいですか？`);
        await this.$api.vNConnect.registerVNConnectCsv({
          vnConnectCsvList: this.csvCheckResponse.success!.vnConnectCsvList,
          vnConnectCsvKeyId: this.csvCheckResponse.success!.vnConnectCsvKeyId,
        });
        (this.$refs.modal as Vue & { ok: () => void }).ok();
      } else {
        this.showCsvSuccess = false;
        this.alertMsg = this.$msg("csv_no_select");
      }
    },
  },
});

/** ファイルチェック成功時表示する項目 */
const vnL2Fields = [
  { key: "enumber", label: "E番号" },
  { key: "siteName", label: "サイト名" },
  { key: "vlan", label: "VLAN" },
  { key: "vpnVnCode", label: "接続先VPN/VNコード" },
  { key: "vnConnectName", label: "VNコネクト名" },
  { key: "description", label: "備考", tdClass: "text-pre-wrap" },
  { key: "bandwidthType", label: "品目" },
  { key: "bandwidth", label: "指定帯域" },
  { key: "qos", label: "QoS設定" },
  { key: "vlanControl", label: "VLAN制御" },
];
const vnL3BGP4Fields = [
  { key: "enumber", label: "E番号" },
  { key: "siteName", label: "サイト名" },
  { key: "vlan", label: "VLAN" },
  { key: "vpnVnCode", label: "接続先VPN/VNコード" },
  { key: "vnConnectName", label: "VNコネクト名" },
  { key: "description", label: "備考", tdClass: "text-pre-wrap" },
  { key: "bandwidthType", label: "品目" },
  { key: "bandwidth", label: "指定帯域" },
  { key: "primaryAddress", label: "DHCPプライマリサーバ" },
  { key: "secondaryAddress", label: "DHCPセカンダリサーバ" },
  { key: "wanAddress", label: "WANアドレス" },
  { key: "asNumber", label: "AS番号" },
  { key: "peerIpAddress", label: "ピアIPアドレス" },
  { key: "md5Key", label: "MD5キー" },
  { key: "med", label: "MED" },
  { key: "localPreference", label: "Local Preference" },
  { key: "routeAggregation", label: "経路集約" },
  { key: "routeAggregationAddress1", label: "経路集約アドレス1" },
  { key: "routeAggregationAddress2", label: "経路集約アドレス2" },
  { key: "routeAggregationAddress3", label: "経路集約アドレス3" },
  { key: "routeAggregationAddress4", label: "経路集約アドレス4" },
  { key: "routeAggregationAddress5", label: "経路集約アドレス5" },
  { key: "qos", label: "QoS設定" },
  { key: "qosPattern", label: "割当帯域パターン" },
];

const vnL3StaticFields = [
  { key: "enumber", label: "E番号" },
  { key: "siteName", label: "サイト名" },
  { key: "vlan", label: "VLAN" },
  { key: "vpnVnCode", label: "接続先VPN/VNコード" },
  { key: "vnConnectName", label: "VNコネクト名" },
  { key: "description", label: "備考", tdClass: "text-pre-wrap" },
  { key: "bandwidthType", label: "品目" },
  { key: "bandwidth", label: "指定帯域" },
  { key: "primaryAddress", label: "DHCPプライマリサーバ" },
  { key: "secondaryAddress", label: "DHCPセカンダリサーバ" },
  { key: "wanAddress", label: "WANアドレス" },
  { key: "lanAddress1", label: "LANアドレス1" },
  { key: "nextHopAddress1", label: "ネクストホップアドレス1" },
  { key: "lanAddress2", label: "LANアドレス2" },
  { key: "nextHopAddress2", label: "ネクストホップアドレス2" },
  { key: "lanAddress3", label: "LANアドレス3" },
  { key: "nextHopAddress3", label: "ネクストホップアドレス3" },
  { key: "lanAddress4", label: "LANアドレス4" },
  { key: "nextHopAddress4", label: "ネクストホップアドレス4" },
  { key: "lanAddress5", label: "LANアドレス5" },
  { key: "nextHopAddress5", label: "ネクストホップアドレス5" },
  { key: "qos", label: "QoS設定" },
  { key: "qosPattern", label: "割当帯域パターン" },
];

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":("ルール" + (_vm.isEdit ? '編集' : '追加')),"subtitle":"NAT(IP Forwarding) インターネット → アクセス拠点","classes":"modal-large"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [(_vm.isLoaded)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[(_vm.isEdit)?[_c('div',{staticClass:"detail-container"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"ID"}},[_c('span',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.form.policyId)+" ")])])],1)])]:[_c('validation-provider',{attrs:{"name":"ID","rules":{
                required: true,
                numeric: true,
                is_not_zero_padding: true,
                between: { min: 1001, max: 9999 },
                duplicate: { list: _vm.existsPolicyIds },
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"ID","msg":_vm._f("msg")('info_policy_id'),"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.policyId),callback:function ($$v) {_vm.$set(_vm.form, "policyId", $$v)},expression:"form.policyId"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],_c('b-card',[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"送信元アドレス","msg":"アドレスを選択する場合は、１つ以上指定してください。","placement":"right","isRequired":_vm.radioSrcAddress === 'SELECT'}})]},proxy:true}],null,true)},[_c('div',[_c('b-form-radio-group',{attrs:{"stacked":""},model:{value:(_vm.radioSrcAddress),callback:function ($$v) {_vm.radioSrcAddress=$$v},expression:"radioSrcAddress"}},[_c('b-form-radio',{attrs:{"value":"ANY"}},[_vm._v("any")]),_c('b-form-radio',{attrs:{"value":"SELECT"}},[_vm._v("アドレス")])],1)],1)]),(_vm.radioSrcAddress === 'SELECT')?[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"送信元アドレス","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('app-multiselect',{attrs:{"options":_vm.srcAddresses,"multiple":true,"custom-label":_vm.addressLabel,"track-by":"ipAddressId","sort-orders":[
                          ['ipAddressName', 'asc'],
                          ['ipAddress', 'asc'] ],"group-label":"label","group-values":"values","closeOnSelect":false,"group-select":true,"selectGroupLabel":"全てを選択します","deselectGroupLabel":"全選択前に戻します","state":_vm._f("validState")(validContext)},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
                        var option = ref.option;
                        var remove = ref.remove;
return [_c('multi-select-tag',{attrs:{"option":option,"remove":remove}},[_c('div',{staticClass:"multiselect-tbody"},[_c('p',{staticClass:"col-3 p-0"},[_vm._v(_vm._s(option.ipAddress))]),_c('p',{staticClass:"col-9 p-0"},[_vm._v(" "+_vm._s(option.ipAddressName)+" ")])])])]}},{key:"option",fn:function(ref){
                        var option = ref.option;
return [_vm._v(" "+_vm._s(option.$groupLabel)+" "),_c('div',{staticClass:"multiselect-tbody"},[_c('p',{staticClass:"col-3 p-0"},[_vm._v(_vm._s(option.ipAddress))]),_c('p',{staticClass:"col-8 p-0"},[_vm._v(" "+_vm._s(option.ipAddressName)+" ")])])]}}],null,true),model:{value:(_vm.form.srcAddressList),callback:function ($$v) {_vm.$set(_vm.form, "srcAddressList", $$v)},expression:"form.srcAddressList"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1),_c('div',{staticClass:"justify-content-end mr-3"},[_c('b-icon-gear',{staticClass:"mt-2",attrs:{"variant":"secondary","font-scale":"1.5","role":"button"},on:{"click":_vm.showInternetSiteEdit}})],1)])]}}],null,true)})],1)]:_vm._e()],2),_c('b-card',{staticClass:"mt-3"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"IPマッピング","isInfo":false,"isRequired":true}})]},proxy:true}],null,true)},[_c('div',{staticClass:"container row p-0 m-0"},[_c('div',{staticClass:"col p-0"},[_c('validation-provider',{attrs:{"name":"IPマッピング","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('app-multiselect',{attrs:{"options":_vm.ipMappings,"multiple":false,"custom-label":_vm.ipMappingLabel,"track-by":"virtualIpAddressSeq","state":_vm._f("validState")(validContext)},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
                        var option = ref.option;
return [_c('div',{staticClass:"multiselect-tbody"},[_c('p',{staticClass:"col-6 p-0"},[_vm._v(_vm._s(option.ipMappingName))]),_c('p',[_vm._v(_vm._s(_vm._f("emptyTo")(option.description,"-")))])])]}},{key:"option",fn:function(ref){
                        var option = ref.option;
return [_c('div',{staticClass:"multiselect-tbody"},[_c('p',{staticClass:"col-6 p-0"},[_vm._v(_vm._s(option.ipMappingName))]),_c('p',{staticClass:"col-5 p-0"},[_vm._v(" "+_vm._s(_vm._f("emptyTo")(option.description,"-"))+" ")])])]}}],null,true),model:{value:(_vm.form.ipMapping),callback:function ($$v) {_vm.$set(_vm.form, "ipMapping", $$v)},expression:"form.ipMapping"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1)])])],1),_c('b-card',{staticClass:"mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-4 m-0 p-0"},[_vm._v("有効/無効")]),_c('div',{staticClass:"col"},[_c('b-form-radio-group',{attrs:{"stacked":""},model:{value:(_vm.form.isPolicyStatus),callback:function ($$v) {_vm.$set(_vm.form, "isPolicyStatus", $$v)},expression:"form.isPolicyStatus"}},[_c('b-form-radio',{attrs:{"value":true}},[_vm._v("有効")]),_c('b-form-radio',{attrs:{"value":false}},[_vm._v("無効")])],1)],1)])])])]),_c('b-card',{staticClass:"my-3"},[_c('validation-provider',{attrs:{"name":"備考","rules":{ max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"備考","msg":"20文字以内","isRequired":false}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1)],2),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("保存")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)])]}}],null,true)}):_vm._e()]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
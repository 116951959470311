











































































































































import Vue, { PropType } from "vue";
import {
  VirtualNetworkEntity,
  VirtualNetworkPostEntity,
  VirtualNetworkUpdateEntity,
} from "@/apis/VirtualNetworkApi";
import { ApiNotFoundError } from "@/error/errors";

export default Vue.extend({
  name: "VirtualNetworkEdit",
  props: {
    /** 変更対象の仮想ネットワーク情報（変更時のみ必須） */
    virtualNetwork: {
      type: Object as PropType<VirtualNetworkEntity>,
      required: false,
    },
    /** 仮想ネットワーク設定排他情報 */
    vnKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    const form: VirtualNetworkUpdateEntity | VirtualNetworkPostEntity = this
      .virtualNetwork
      ? // 変更
        {
          vnName: this.virtualNetwork.vnName,
          vnKeyId: this.vnKeyId,
        }
      : // 新規
        {
          vnType: "L3",
          vnName: "",
          vlanIdType: "SPECIFIED",
          vlanId: null,
          vnKeyId: this.vnKeyId,
        };

    return {
      form,
      modeOptions: [
        { item: "L3", name: "L3" },
        { item: "L2", name: "L2" },
      ],
      upperLimitL2: 100,
      upperLimitL3: 500,
    };
  },
  async mounted() {
    try {
      const vnConnectUpperLimit = (await this.$api.upperLimit.getUpperLimit())
        .upperLimitList;
      // 仮想ネットワークあたりのVNコネクト数(L2)が存在しない場合は100固定
      const vnConnectLimit2 = vnConnectUpperLimit.find(
        (e) =>
          e.upperLimitItemName === "仮想ネットワークあたりのVNコネクト数(L2)"
      );
      this.upperLimitL2 = vnConnectLimit2 ? vnConnectLimit2.upperLimit : 100;
      // 仮想ネットワークあたりのVNコネクト数(L3)が存在しない場合は500固定
      const vnConnectLimit3 = vnConnectUpperLimit.find(
        (e) =>
          e.upperLimitItemName === "仮想ネットワークあたりのVNコネクト数(L3)"
      );
      this.upperLimitL3 = vnConnectLimit3 ? vnConnectLimit3.upperLimit : 500;
    } catch (e) {
      // 404が返ってきた場合はデフォルト値のままとする。
      if (e instanceof ApiNotFoundError) {
        return;
      }
      throw e;
    }
  },
  computed: {
    /** true: 変更, false: 新規 */
    isEdit(): boolean {
      return !!this.virtualNetwork;
    },
    /** VNコネクト数(上限数) */
    upperLimit(): number {
      if (this.virtualNetwork) {
        return this.virtualNetwork.upperLimitVnConnect;
      } else {
        const vnType = (this.form as VirtualNetworkPostEntity).vnType;
        switch (vnType) {
          case "L2":
            return this.upperLimitL2;
          case "L3":
            return this.upperLimitL3;
          default:
            throw new Error(`unknown vnType. ${vnType}`);
        }
      }
    },
  },
  methods: {
    async submit() {
      if (this.isEdit) {
        await this.$confirm(
          "仮想ネットワーク情報を変更します。よろしいですか？"
        );
        await this.$api.virtualNetwork.update(
          this.virtualNetwork.vnCode,
          this.form as VirtualNetworkUpdateEntity
        );
      } else {
        await this.$confirm("仮想ネットワークを追加します。よろしいですか？");
        const form = this.form as VirtualNetworkPostEntity;
        await this.$api.virtualNetwork.post({
          ...form,
          vlanIdType: form.vnType === "L2" ? form.vlanIdType : null,
          vlanId:
            form.vnType === "L2" && form.vlanIdType === "SPECIFIED"
              ? form.vlanId
              : null,
        });
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

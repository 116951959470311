var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-title',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"flex-fill"},[_vm._v("障害/メンテナンス情報一覧")]),_c('manual-button',{attrs:{"path":"/nwinfo-infomation/"}})],1)]),(_vm.isLoaded)?_c('b-card-body',[_c('div',{staticClass:"row"},[_c('b-form-group',{attrs:{"label":"最終更新日時"}},[_c('span',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.failureInfo.lastUpdateDateTime,{ s: true }))+" ")])]),_c('b-button',{staticClass:"updateBtn",attrs:{"variant":"primary","disabled":_vm.failureInfo.failureStatus === 'UPDATING'},on:{"click":_vm.update}},[_vm._v(_vm._s(_vm.failureInfo.failureStatus === "UPDATING" ? "更新中" : "情報更新"))])],1),_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col"},[_c('app-search',{attrs:{"search":_vm.maintenance.search},model:{value:(_vm.maintenance.filter),callback:function ($$v) {_vm.$set(_vm.maintenance, "filter", $$v)},expression:"maintenance.filter"}})],1),_c('div',{staticClass:"justify-content-end mr-3"},[_c('div',{staticClass:"btn-container"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.downloadCsv}},[_vm._v("一覧ダウンロード")])],1)])]),_c('div',[_c('app-table',{attrs:{"primary-key":"number","fields":_vm.maintenance.fields,"items":_vm.items,"filter":_vm.maintenance.filter,"filter-option":_vm.maintenance.filter_option,"hover":true,"thead-class":"text-center","sort-by":"number","busy":!_vm.failureList,"sticky-header":"45vh"},on:{"row-clicked":_vm.handleRowClicked},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head(vpnVnCode)",fn:function(){return [_vm._v("接続先"),_c('br'),_vm._v("VPN/VNコード")]},proxy:true},{key:"cell(vnConnectName)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}},{key:"cell(enumber)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}},{key:"cell(vpnVnCode)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}},{key:"cell(vlan)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}}],null,false,3859127164)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * プライベートアドレスであることを検証
 *  ・正しいIPV4形式であることは別のバリデーションで実施が必要
 *  ・CIDR表記でもIPアドレスのみでも利用可能
 */

export default {
  validate(value: string | null | undefined): boolean {
    if (!value) return true;

    let ipV4: ipaddr.IPv4 | [ipaddr.IPv4, number];
    try {
      if (value.indexOf("/") !== -1) {
        ipV4 = ipaddr.IPv4.parseCIDR(value);
      } else {
        ipV4 = ipaddr.IPv4.parse(value);
      }
    } catch (e) {
      return true;
    }

    if (Array.isArray(ipV4)) {
      return ipV4[0].range() === "private";
    } else {
      return ipV4.range() === "private";
    }
  },
} as ValidationRuleSchema;

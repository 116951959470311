
























import Vue, { PropType } from "vue";

/**
 * formのlabel要素コンポーネント
 * props
 *  item:項目名
 *  isRequired(必須マーク):true(表示-default),false(非表示)
 *  isInfo(infoマーク):true(表示-default),false(非表示)
 *  placement(infoの表示位置):top(default),bottom,left,right
 *  msg:infoに表示するメッセージ
 *
 * content slot-othersはinfo-必須以外が必要な場合に利用してください。
 */
export default Vue.extend({
  name: "AppBadge",
  inheritAttrs: false,
  props: {
    // 項目名
    item: {
      type: String as PropType<string>,
      required: true,
    },
    // 必須マーク
    // true:必要
    // false:不要
    isRequired: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    // infoマーク
    // true:必要
    // false:不要
    isInfo: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    // infoの表示位置
    // top/bottom/right/left
    placement: {
      type: String as PropType<string>,
      required: false,
      default: "top",
    },
    // infoに表示する文言
    msg: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** デフォルトサービス情報 */
export interface DefalutService {
  /** 総件数 一覧表示対象の総件数 */
  total: number;
  defaultServiceList: {
    /** サービスSEQ. 主キー 本APIでDBから取得 */
    serviceSeq: string;
    /** サービス名 */
    serviceName: string;
    /** プロトコル. TCP : TCP UDP : UDP */
    protocol: "TCP" | "UDP";
    /** ポート番号. TCP、またはUDP選択時 Port番号は0～65535が指定可能 "-"で範囲、","複数指定可能 */
    portNumber: string;
  }[];
}

/** イントラネットFWデフォルトサービス情報 */
export interface DefaultServiceIntranetfw {
  /** 総件数 一覧表示対象の総件数 */
  total: number;
  defaultServiceList: {
    /** サービスSEQ. 主キー 本APIでDBから取得 */
    serviceSeq: string;
    /** サービス名 */
    serviceName: string;
    /** プロトコル. TCP : TCP UDP : UDP */
    protocol: "TCP" | "UDP" | "IP" | "ICMP";
    /** ポート番号. TCP、またはUDP選択時 Port番号は0～65535が指定可能 "-"で範囲、","複数指定可能 */
    portNumber: string | null;
    /** プロトコル番号. IP選択時 0～254の範囲で入力 */
    protocolNumber: number | null;
    /** ICMPタイプ ICMP選択時 0～255の範囲で入力 */
    icmpType: number | null;
    /** ICMPコード ICMP選択時 0～255の範囲で入力 */
    icmpCode: number | null;
  }[];
}

export default class ServiceApi extends AbstractBaseApi {
  /** デフォルトサービス情報（インターネットFW）を取得 */
  async getDefaultService(): Promise<DefalutService> {
    return (await this.api.get("/v1/network/services/default/internetfw")).data;
  }
  /** デフォルトサービス情報（イントラネットFW）を取得 */
  async getDefaultServiceIntranetfw(): Promise<DefaultServiceIntranetfw> {
    return (await this.api.get("/v1/network/services/default/intranetfw")).data;
  }
}
















































import Vue, { PropType } from "vue";
import {
  PutType1InternetAddress,
  Type1SiteEdit,
  Type1Zone,
} from "@/apis/Type1SiteApi";
import { AppTableData } from "@/components/AppTable.vue";
import ExtraSiteEdit from "@/modals/type1/ExtraSiteEdit.vue";

export default Vue.extend({
  name: "ExtraSiteReference",
  props: {
    extraSite: {
      type: Object as PropType<Type1SiteEdit>,
      required: true,
    },
    extraZone: {
      type: Object as PropType<Type1Zone | null>,
      default: null,
    },
    type1SiteZoneKeyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      extraData: {
        fields: [
          {
            key: "ipAddressName",
            label: "アドレス名",
          },
          {
            key: "ipAddress",
            label: "アドレス",
          },
          {
            key: "zoneId",
            label: "ゾーン配置",
          },
        ],
        items: [],
      } as Pick<AppTableData<PutType1InternetAddress>, "fields" | "items">,
    };
  },
  async mounted() {
    await this.load();
  },
  methods: {
    /** 初期化機能 */
    async load() {
      this.extraData.items = this.extraSite.addressList;
    },
    /** 変更機能 */
    async toChange() {
      await this.$modal.show(ExtraSiteEdit, {
        extraSite: this.extraSite,
        extraZone: this.extraZone,
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

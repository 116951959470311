


































































import Vue, { PropType } from "vue";
import ServiceSettingEdit from "@/modals/applianceObject/ServiceSettingEdit.vue";
import {
  CustomServiceDelete,
  CustomServiceInfo,
} from "@/apis/CustomServiceApi";

export default Vue.extend({
  name: "ServiceSettingReference",
  props: {
    serviceSetting: {
      type: Object as PropType<CustomServiceInfo>,
      required: true,
    },
    customServiceKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    type4Id: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  methods: {
    /**
     * サービス設定の削除処理
     */
    async deleteServiceSetting(): Promise<void> {
      // 確認ダイアログの表示
      await this.$confirm("個別サービスを削除します。よろしいですか？");

      // リクエストデータの作成
      const reqData: CustomServiceDelete = {
        customServiceSeqList: [this.serviceSetting.customServiceSeq],
        customServiceKeyId: this.customServiceKeyId,
      };

      // サービス設定削除APIを呼び出す
      if (!this.type4Id) {
        await this.$api.customServiceApi.deleteType1CustomService(reqData);
      } else {
        await this.$api.customServiceApi.deleteType4CustomService(
          this.type4Id,
          reqData
        );
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * サービス設定更新のモーダルを表示する
     */
    async showServiceSettingModify(): Promise<void> {
      await this.$modal.show(ServiceSettingEdit, {
        serviceSetting: this.serviceSetting,
        customServiceKeyId: this.customServiceKeyId,
        type4Id: this.type4Id,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

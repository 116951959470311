import { CloudLine } from "@/apis/CloudLineApi";

/**
 * クラウド上限値設定画面用変更有無Validation
 * @param original 変更元データリスト
 * @param target 変更後データリスト
 * @returns true:変更がある false:変更がない
 */
export function cloudLineChanged(
  original: CloudLine[],
  target: CloudLine[]
): boolean {
  return (
    target.filter((v, index) => {
      const newBandwidth: string =
        v.bandwidth.value.toString() + v.bandwidth.unit;
      const oldBandwidth: string =
        original[index].bandwidth.value.toString() +
        original[index].bandwidth.unit;
      return (
        v.description !== original[index].description ||
        newBandwidth !== oldBandwidth
      );
    }).length > 0
  );
}

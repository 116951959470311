












































































































































































import Vue, { PropType } from "vue";
import { SiteListEntity } from "@/views/site/SiteList.vue";
import { ContractListEntity } from "@/apis/ContractLineApi";
import {
  AccessLineGetEntity,
  BandwidthListGetEntity,
} from "@/apis/AccessLineApi";
import { VlanType } from "@/apis/VNConnectApi";
import { Type1PrivateInfo } from "@/apis/Type1SiteApi";

type VlanTypes = ("SPECIFIED" | "UNTAG" | "OTHER")[];
export default Vue.extend({
  name: "SiteEdit",
  props: {
    /** サイトゾーン(Type1)排他情報 */
    type1SiteZoneKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    /** 変更場合のみ、値ある */
    siteData: {
      type: Object as PropType<SiteListEntity>,
      required: false,
    },
  },
  data() {
    return {
      isLoaded: false,
      /** Type1プライベート */
      type1PrivateInfo: {} as Type1PrivateInfo,
      /** アクセス回線情報 */
      accessLineInfo: {} as AccessLineGetEntity,
      /** 契約回線情報 */
      contractInfo: [] as ContractListEntity[],
      /** サイト情報追加・変更フォーム */
      form: this.siteData
        ? {
            /** サイト名 */
            siteName: this.siteData.siteName,
          }
        : {
            // 追加の場合
            /** 回線番号（選択されるデータ）*/
            siteSelect: null as LineNumEntity | null,
            /** サイト名 */
            siteName: "",
            /** VLAN */
            vlan: "SPECIFIED" as VlanType,
            /** 指定VLAN */
            specifiedVlan: null as number | null,
          },
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    /** 追加の場合、true */
    isAdd(): boolean {
      return !this.siteData;
    },
    /** 回線番号選択肢のOptions */
    lineNumOptions(): {
      lineNumLabel: { enumber: string; menu: string; office: string };
      lineNumValues: LineNumEntity[];
    }[] {
      return this.siteList.length !== 0
        ? [
            {
              lineNumLabel: {
                enumber: "E番号",
                menu: "メニュー",
                office: "事業所名",
              },
              lineNumValues: this.siteList,
            },
          ]
        : [];
    },
    /** サイトの選択肢リスト */
    siteList(): LineNumEntity[] {
      const bandwidthByEnumber = {
        ...this.accessLineInfo.bandwidthList.toMap((e) => e.enumberAct),
        ...this.accessLineInfo.bandwidthList
          .filter((v) => v.enumberSby)
          .toMap((e) => e.enumberSby!),
      };
      return (
        this.type1PrivateInfo.lineList
          .filter((e) => {
            const availableSite =
              availableSites[e.accessType as SiteAccessType];
            if (availableSite) {
              if (availableSite.length === 0) {
                // 物理IF判定不要の回線
                return true;
              } else {
                // 物理IF判定
                return availableSite.some((fn) =>
                  fn(bandwidthByEnumber[e.enumber])
                );
              }
            } else {
              // 表示対象外の回線
              return false;
            }
          })
          .map((e) => ({
            enumber: e.enumber,
            office:
              this.contractInfo.find((v) => v.enumber === e.enumber)?.office ??
              null,
            // 回線種別プルダウンの表示用
            menu: e.accessType as SiteAccessType,
            bandwidth: bandwidthByEnumber[e.enumber],
          }))
          // 個別直収(Sby)は表示から除外
          .filter(
            (e) =>
              !(
                e.bandwidth?.enumberSby === e.enumber &&
                e.bandwidth?.isPfagw2L2 === true
              )
          )
      );
    },
    /** サイトによって選択可能なVLAN種別の一覧 */
    vlanTypes(): VlanTypes {
      if (this.form.siteSelect) {
        const types = vlanTypeByAccessType[this.form.siteSelect.menu];
        if (typeof types[0] === "string") {
          // アクセス回線種別毎のVLAN種別
          return types as VlanTypes;
        } else {
          // 回線状況によって異なる場合は一致するVLAN種別
          return (
            types as {
              is: (e: BandwidthListGetEntity) => boolean;
              types: VlanTypes;
            }[]
          ).find((e) => e.is(this.form.siteSelect!.bandwidth!))!.types;
        }
      } else {
        return ["SPECIFIED", "UNTAG", "OTHER"];
      }
    },
  },

  watch: {
    /** VLAN一覧変更時時、VLAN ID Typeを初期化する */
    vlanTypes(types: VlanTypes) {
      this.form.vlan = types[0];
    },
  },

  methods: {
    async load() {
      if (this.isAdd) {
        // Type1プライベート情報を取得し、回線番号の選択肢に設定
        this.type1PrivateInfo =
          await this.$api.type1SiteZone.getType1PrivateList();
        // アクセス回線の情報取得
        this.accessLineInfo = await this.$api.accessLine.getAll();
        // 事業所名を取得するため、契約回線の情報を取得
        this.contractInfo = (
          await this.$api.contractLine.getAll()
        ).contractList;
      }
    },

    /** サイト追加・サイト情報変更（フロー設定変更） */
    async submit() {
      // 確認ダイアログの表示
      await this.$confirm(
        this.isAdd
          ? `サイトを追加します。よろしいですか？`
          : `サイト情報を変更します。よろしいですか？`
      );
      if (this.isAdd) {
        /** サイト作成 */
        await this.$api.type1SiteZone.postType1PrivateSiteRegister(
          {
            site: {
              siteName: this.form.siteName,
              vlanIdType: this.form.vlan!,
              vlanId:
                this.form.vlan === "SPECIFIED" ? this.form.specifiedVlan : null,
            },
            type1SiteZoneKeyId: this.type1SiteZoneKeyId,
          },
          this.form.siteSelect!.enumber
        );
      } else {
        /** サイト情報変更 */
        await this.$api.type1SiteZone.postType1PrivateSiteModify(
          {
            site: {
              siteName: this.form.siteName,
              addressList: this.siteData.addressList,
            },
            type1SiteZoneKeyId: this.type1SiteZoneKeyId,
          },
          this.siteData.enumber,
          this.siteData.siteId
        );
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 回線番号検索付きプルダウンの選択後表示形式 */
    lineNumSelectLabel({ enumber, menu, office }: LineNumEntity) {
      return `${enumber} / ${this.$filter.enumTo(
        menu,
        "contractLineAccessType"
      )} / ${office ?? ""}`;
    },
  },
});
// 回線番号検索付きプルダウンの項目
export interface LineNumEntity {
  /** E番号 */
  enumber: string;
  /** 事業所名 */
  office: string | null;
  /** VLAN表示区分用 */
  menu: SiteAccessType;
  bandwidth?: BandwidthListGetEntity;
}

/** サイトで操作可能な回線種別 */
type SiteAccessType = Extract<
  ContractListEntity["accessType"],
  | "ETHERNET_L2"
  | "ETHERNET_L3"
  | "BROADBAND_ACCESS2_L2"
  | "BROADBAND_ACCESS2_L3"
  | "WIRELESS_ACCESS_L2"
  | "WIRELESS_ACCESS_L3"
  | "REMOTE_ACCESS_GW_FRE"
  | "REMOTE_ACCESS_GW_CPA"
  | "REMOTE_ACCESS_GW_CRG"
  | "PLATFORM_GATEWAY"
  | "PF_ACCESS_GW"
  | "EXTEND_ETHERNET"
  | "IPSEC"
  | "ETHERNET2"
  | "ETHERNET2_PF"
  | "BROADBAND_ACCESS3"
  | "WIRELESS_ACCESS2"
  | "PF_ACCESS_GW2"
  | "ETHERNET_LIGHT"
>;

/** サイト毎で選択可能なVLAN種別 */
const vlanTypeByAccessType: Record<
  SiteAccessType,
  | VlanTypes
  | {
      is: (e: BandwidthListGetEntity) => boolean;
      types: VlanTypes;
    }[]
> = {
  ETHERNET_L2: ["SPECIFIED", "UNTAG", "OTHER"],
  ETHERNET_L3: ["SPECIFIED", "UNTAG"],
  BROADBAND_ACCESS2_L2: ["SPECIFIED", "UNTAG", "OTHER"],
  BROADBAND_ACCESS2_L3: ["SPECIFIED", "UNTAG"],
  WIRELESS_ACCESS_L2: ["SPECIFIED", "UNTAG", "OTHER"],
  WIRELESS_ACCESS_L3: ["SPECIFIED", "UNTAG"],
  EXTEND_ETHERNET: ["SPECIFIED", "UNTAG", "OTHER"],
  IPSEC: ["UNTAG"],
  PLATFORM_GATEWAY: ["UNTAG"],
  PF_ACCESS_GW: ["SPECIFIED"],
  PF_ACCESS_GW2: ["SPECIFIED"],
  ETHERNET2_PF: [
    {
      is: (e) =>
        e.physicalInterface === "IF_10G" || e.physicalInterface === "IF_100G",
      types: ["SPECIFIED", "UNTAG"],
    },
    {
      is: (e) => e.vlanControl !== "ON",
      types: ["SPECIFIED", "UNTAG", "OTHER"],
    },
    {
      is: (e) => e.vlanControl === "ON",
      types: ["UNTAG"],
    },
  ],
  ETHERNET2: [
    {
      is: (e) =>
        e.physicalInterface === "IF_10G" || e.physicalInterface === "IF_100G",
      types: ["SPECIFIED", "UNTAG"],
    },
    {
      is: (e) => e.vlanControl !== "ON",
      types: ["SPECIFIED", "UNTAG", "OTHER"],
    },
    {
      is: (e) => e.vlanControl === "ON",
      types: ["UNTAG"],
    },
  ],
  WIRELESS_ACCESS2: [
    {
      is: (e) => e.vlanControl !== "ON",
      types: ["SPECIFIED", "UNTAG", "OTHER"],
    },
    {
      is: (e) => e.vlanControl === "ON",
      types: ["UNTAG"],
    },
  ],
  BROADBAND_ACCESS3: [
    {
      is: (e) => e.vlanControl !== "ON",
      types: ["SPECIFIED", "UNTAG", "OTHER"],
    },
    {
      is: (e) => e.vlanControl === "ON",
      types: ["UNTAG"],
    },
  ],
  ETHERNET_LIGHT: [
    {
      is: (e) => e.vlanControl !== "ON",
      types: ["SPECIFIED", "UNTAG", "OTHER"],
    },
    {
      is: (e) => e.vlanControl === "ON",
      types: ["UNTAG"],
    },
  ],
  REMOTE_ACCESS_GW_FRE: ["UNTAG"],
  REMOTE_ACCESS_GW_CPA: ["UNTAG"],
  REMOTE_ACCESS_GW_CRG: ["UNTAG"],
};

/**
 * サイト一覧で操作可能の回線のマトリクス（要件の特記事項1）
 * @see {@link https://agile.kddi.com/confluence/pages/viewpage.action?pageId=408422465}
 */
const availableSites: Record<
  SiteAccessType,
  ((e: BandwidthListGetEntity) => boolean)[]
> = {
  ETHERNET_L2: [],
  ETHERNET_L3: [],
  BROADBAND_ACCESS2_L2: [],
  BROADBAND_ACCESS2_L3: [],
  WIRELESS_ACCESS_L2: [],
  WIRELESS_ACCESS_L3: [],
  EXTEND_ETHERNET: [],
  IPSEC: [],
  PLATFORM_GATEWAY: [],
  PF_ACCESS_GW: [],
  PF_ACCESS_GW2: [
    (e) => e.physicalInterface === "IF_1G",
    (e) => e.physicalInterface === "IF_10G",
  ],
  ETHERNET2: [
    (e) => e.physicalInterface === "IF_100M",
    (e) => e.physicalInterface === "IF_1G",
    (e) => e.physicalInterface === "IF_10G",
    (e) => e.physicalInterface === "IF_100G",
    (e) => e.physicalInterface === "IF_10M" && e.isUno,
  ],
  ETHERNET2_PF: [
    (e) => e.physicalInterface === "IF_100M",
    (e) => e.physicalInterface === "IF_1G",
    (e) => e.physicalInterface === "IF_10G",
    (e) => e.physicalInterface === "IF_100G",
    (e) => e.physicalInterface === "IF_10M" && e.isUno,
  ],
  WIRELESS_ACCESS2: [],
  BROADBAND_ACCESS3: [],
  ETHERNET_LIGHT: [],
  REMOTE_ACCESS_GW_FRE: [],
  REMOTE_ACCESS_GW_CPA: [],
  REMOTE_ACCESS_GW_CRG: [],
};

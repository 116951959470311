var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"ポリシー編集","subtitle":("NAT(IP Forwarding) " + (_vm.$filter.enumTo(
    _vm.zone.srcZoneType,
    'zoneType'
  )) + "/" + (_vm.zone.srcZoneName) + " → " + (_vm.$filter.enumTo(_vm.zone.dstZoneType, 'zoneType')) + "/" + (_vm.zone.dstZoneName)),"classes":"modal-full"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var cancel = ref.cancel;
return [(_vm.isLoaded)?_c('b-form',{attrs:{"novalidate":""}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"btn-container justify-content-between align-items-center mb-2"},[_c('span',[_vm._v(_vm._s(_vm.table.items.length)+"件を表示")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.limitPolicy},on:{"click":_vm.addPolicy}},[_vm._v("ルール追加")])],1),_c('app-table-draggable',{attrs:{"primary-key":"policyId"},model:{value:(_vm.table.items),callback:function ($$v) {_vm.$set(_vm.table, "items", $$v)},expression:"table.items"}},[_c('app-table',{attrs:{"small":"","fields":_vm.table.fields,"items":_vm.table.items,"pagination":false,"primary-key":"policyId","hover":true,"sticky-header":"500px","thead-class":"text-center small"},on:{"row-clicked":_vm.editPolicy},scopedSlots:_vm._u([{key:"cell(_handle)",fn:function(){return [_c('b-icon-list')]},proxy:true},{key:"cell(srcAddressList)",fn:function(ref){
  var value = ref.value;
return [_c('policy-src-address',{attrs:{"src-address":{ srcAddressList: value }}})]}},{key:"cell(ipMapping)",fn:function(ref){
  var value = ref.value;
return [_vm._v(" "+_vm._s(value.ipMappingName)+" ")]}},{key:"cell(isPolicyStatus)",fn:function(ref){
  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}},{key:"cell(delete)",fn:function(ref){
  var index = ref.index;
return [_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.deletePolicy(index)}}},[_vm._v("削除 ")])]}}],null,true)})],1)],1),_c('div',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("保存")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)]):_vm._e()]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import moment from "moment";

/**
 * 入力された時刻がHH:mmまたはHH:mm:ssの形式になっているかを確認するためのバリデーター
 */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }

    const timeElements = value.split(":");
    if (
      timeElements.length > 1 &&
      timeElements[0] === "24" &&
      timeElements[1] === "00"
    ) {
      return false;
    }

    return (
      moment(value, "HH:mm:ss", true).isValid() ||
      moment(value, "HH:mm", true).isValid()
    );
  },
} as ValidationRuleSchema;

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * ループバックアドレスではないことを確認
 * @see {@link https://ja.wikipedia.org/wiki/Localhost}
 *
 * チェック内容:
 *    サブネット無しのIPの場合 → ループバックアドレス（127.0.0.0/8）ではないことを確認
 *    サブネット付きのIPの場合 → ループバックアドレス（127.0.0.0/8）以外のアドレスがないことを確認
 */
export default {
  validate: function (value: string | null | undefined): boolean {
    if (!value) return true;

    let ipV4: ipaddr.IPv4 | [ipaddr.IPv4, number];
    try {
      if (value.indexOf("/") !== -1) {
        ipV4 = ipaddr.IPv4.parseCIDR(value);
      } else {
        ipV4 = ipaddr.IPv4.parse(value);
      }
    } catch (e) {
      return true;
    }
    // サブネット付きのIPの場合
    if (Array.isArray(ipV4)) {
      // ループバックアドレス以外のアドレスがないことを確認
      return !(
        ipaddr.IPv4.networkAddressFromCIDR(value).range() === "loopback" &&
        ipaddr.IPv4.broadcastAddressFromCIDR(value).range() === "loopback"
      );
    } else {
      // サブネット無しのIPの場合 → ループバックアドレスではないことを確認
      return ipV4.range() !== "loopback";
    }
  },
} as ValidationRuleSchema;

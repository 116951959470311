




import Vue from "vue";

export default Vue.extend({
  name: "AppInvalidFeedback",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
});

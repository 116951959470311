





















































































































import Vue, { PropType } from "vue";
import { CustomService, CustomServiceInfo } from "@/apis/CustomServiceApi";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "ServiceSettingEdit",
  props: {
    serviceSetting: {
      type: Object as PropType<CustomServiceInfo>,
      required: false,
      default: null,
    },
    customServiceKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    type4Id: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      serviceSettingInf: { protocol: "TCP" } as CustomServiceInfo,
      protocolOptions: ["TCP", "UDP"],
    };
  },
  mounted() {
    // 更新であればフォームのデータを設定
    if (this.serviceSetting) {
      this.serviceSettingInf = cloneDeep(this.serviceSetting);
    }
  },
  methods: {
    /** サービス設定の登録・変更 */
    async onSubmit() {
      // 可変のメッセージを設定
      const action = this.serviceSetting ? "変更" : "追加";

      // 確認ダイアログの表示
      await this.$confirm(`個別サービスを${action}します。よろしいですか？`);

      // 登録・更新データの設定
      const reqData: CustomService = {
        serviceName: this.serviceSettingInf.serviceName,
        protocol: this.serviceSettingInf.protocol,
        portNumber: this.serviceSettingInf.portNumber,
        description: this.serviceSettingInf.description || null,
        customServiceKeyId: this.customServiceKeyId,
        note: null,
      };

      // サービス設定の登録・更新
      if (this.serviceSetting) {
        await this.modService(reqData);
      } else {
        await this.addService(reqData);
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * サービス設定登録APIを呼び出す
     * @param reqData 登録データ
     */
    async addService(reqData: CustomService) {
      // Type4IDの有無により呼び出すAPIをハンドリング
      if (!this.type4Id) {
        await this.$api.customServiceApi.postType1CustomService(reqData);
      } else {
        await this.$api.customServiceApi.postType4CustomService(
          this.type4Id,
          reqData
        );
      }
    },
    /**
     * サービス設定変更APIを呼び出す
     * @param reqData 変更データ
     */
    async modService(reqData: CustomService) {
      // Type4IDの有無により呼び出すAPIをハンドリング
      if (!this.type4Id) {
        await this.$api.customServiceApi.putType1CustomService(
          this.serviceSettingInf.customServiceSeq,
          reqData
        );
      } else {
        await this.$api.customServiceApi.putType4CustomService(
          this.type4Id,
          this.serviceSettingInf.customServiceSeq,
          reqData
        );
      }
    },
  },
});


































































































































import Vue, { PropType } from "vue";
import { Type4ExtraSite, Type4InternetSite } from "@/apis/Type4SiteApi";
import {
  CommonPolicy,
  Country,
  GetPolicy,
  GetPolicyType4,
  PutDstAddress1,
  PutDstAddress2,
  PutPolicy,
  PutPolicyType4,
  PutProfile,
  PutSrcAddress1,
  PutSrcAddress2,
} from "@/apis/NwPolicyApi";
import { DefalutService } from "@/apis/ServiceApi";
import { CustomServiceList } from "@/apis/CustomServiceApi";
import { IpPoolGetRes } from "@/apis/IpPoolApi";
import { IpMappings } from "@/apis/IpMappingApi";
import { InternetfwProfileList, UrlProfileList } from "@/apis/ProfileApi";
import { read } from "xlsx";
import {
  LoadAllowLog,
  LoadType4Contract,
  LoadType4CustomService,
  LoadType4ExtraSite,
  LoadType4internetFwProfile,
  LoadType4InternetSite,
  LoadType4IpMapping,
  LoadType4IpPool,
  LoadType4Policy,
  LoadType4UrlProfile,
} from "@/services/ImportConfigService";
import cloneDeep from "lodash/cloneDeep";
import { Type4Service } from "@/apis/ContractChangeType4Api";
import { mapState } from "vuex";
import { isShowProvisioningStatus } from "@/store/provisioning-store";
import { ApiBadRequestError } from "@/error/errors";

type ConfigAccumulate = {
  /** Type4契約 */
  type4Service: Type4Service;
  /** エクストラサイト（サイト一覧） */
  extraSite: Type4ExtraSite["siteList"];
  /** インターネット（アドレス、FQDN、国一覧） */
  internetAddresses: Type4InternetSite["addressList"];
  internetFqdns: Type4InternetSite["fqdnList"];
  internetCountries: Country["countryList"];
  /** IP Pool */
  ipPools: IpPoolGetRes["ipPoolList"];
  /** IPマッピング */
  ipMappings: IpMappings["ipMappingList"];
  /** デフォルトサービス,カスタムサービス */
  defaultServices: DefalutService["defaultServiceList"];
  customServices: CustomServiceList["customServiceList"];
  /** インターネットFWプロファイル、URLプロファイル */
  internetFWProfiles: InternetfwProfileList["profileList"];
  urlProfiles: UrlProfileList["profileList"];
};

/** ステータス */
type ImportStatus = "WAIT" | "IN_PROGRESS" | "COMPLETED" | "SKIPPED" | "ERROR";

type ConfigState = { progress: number; status: ImportStatus };
type ImportItem<T> = {
  /** 実行可否選択可能 */
  selectable: boolean;
  /** 実行可否選択 */
  scheduled: boolean;
  /** 設定項目名 */
  name: string;
  /** 進捗（最大10） */
  progress: number;
  /** Excelから読み込んだ設定 */
  config: T;
  /** 設定処理 */
  configure: (
    scheduled: boolean,
    acc: ConfigAccumulate,
    config: T,
    state: ConfigState
  ) => Promise<ConfigAccumulate>;
  /** ステータス */
  status: ImportStatus;
  /** エラーメッセージ */
  error?: string;
};
/** 不要オブジェクト削除用コンフィグ */
type RemoveUnnecessaryConfig = {
  route: string[];
  internetSite: LoadType4InternetSite;
  extraSite: LoadType4ExtraSite;
  customService: LoadType4CustomService;
  ipPool: LoadType4IpPool;
  ipMapping: LoadType4IpMapping;
  internetFqProfile: LoadType4internetFwProfile;
  urlProfile: LoadType4UrlProfile;
};

export default Vue.extend({
  name: "InternetType4ImportConfig",
  props: {
    /**
     * Type4契約.
     * 指定されていない場合: 契約インポート. Allowログ設定 ~ インターネットType4契約
     * 指定されている場合: 設定インポート. 経路設定 ~ 不要オブジェクト削除
     */
    type4Service: {
      type: Object as PropType<Type4Service>,
    },
  },
  data() {
    return {
      isShowUpload: true,
      inProgress: false,
      isChanged: false,
      imports: null as
        | null
        | (
            | ImportItem<LoadAllowLog>
            | ImportItem<LoadType4Contract>
            | ImportItem<string[]>
            | ImportItem<LoadType4InternetSite>
            | ImportItem<LoadType4ExtraSite>
            | ImportItem<LoadType4CustomService>
            | ImportItem<LoadType4IpPool>
            | ImportItem<LoadType4IpMapping>
            | ImportItem<LoadType4internetFwProfile>
            | ImportItem<LoadType4UrlProfile>
            | ImportItem<LoadType4Policy>
            | ImportItem<RemoveUnnecessaryConfig>
          )[],
      alertMsg: null as string | null,
      allSchedule: true,
    };
  },
  computed: {
    ...mapState("provisioning", {
      provisioning: "data",
    }),
    /** true: 設定項目のセレクトボックスの表示あり, false: 表示なし */
    showSelectable(): boolean {
      return this.imports!.some((e) => e.selectable);
    },
  },
  watch: {
    /** 一括選択/選択解除 */
    allSchedule(isCheck: boolean) {
      this.imports = this.imports!.map((e) => {
        return e.selectable ? { ...e, scheduled: isCheck } : e;
      });
    },
  },
  created() {
    // ローディング表示とプロビジョニングの更新通知をOFF
    this.$store.dispatch("meta/setEnabledLoading", false);
    this.$store.dispatch("provisioning/setEnabledRefresh", false);
  },
  destroyed() {
    this.$store.dispatch("meta/setEnabledLoading", true);
    this.$store.dispatch("provisioning/setEnabledRefresh", true);
  },
  methods: {
    async uploadFile(file: File | null) {
      if (!file) {
        return;
      }

      this.isShowUpload = false;
      const data1 = await file.arrayBuffer();
      const wb = read(data1);

      const $importConfig = this.$service.importConfig;
      const initial = { progress: 0, status: "WAIT" as ImportStatus };
      if (this.type4Service) {
        const configContract = $importConfig.loadType4Contract(wb);
        if (this.type4Service.vpnVnCode !== configContract.vpnVnCode) {
          this.alertMsg =
            "インポート先のType4契約と設定ファイルのVPN/VNコードが一致していません";
          return;
        } else {
          this.alertMsg = null;
        }

        const configRoute = $importConfig.loadType4Route(wb);
        const configInternetSite = $importConfig.loadType4InternetSite(wb);
        const configExtraSite = $importConfig.loadType4ExtraSite(wb);
        const configCustomService = $importConfig.loadType4CustomService(wb);
        const configIpPool = $importConfig.loadType4IpPool(wb);
        const configIpMapping = $importConfig.loadType4IpMapping(wb);
        const configInternetFwProfile =
          $importConfig.loadType4InternetFwProfile(wb);
        const configUrlProfile = $importConfig.loadType4UrlProfile(wb);
        this.imports = [
          {
            ...cloneDeep(initial),
            selectable: true,
            scheduled: true,
            name: "経路設定",
            config: configRoute,
            configure: this.configureType4Route,
          },
          {
            ...cloneDeep(initial),
            selectable: true,
            scheduled: true,
            name: "アドレス・FQDN（インターネット）設定",
            config: configInternetSite,
            configure: this.configureType4InternetSite,
          },
          {
            ...cloneDeep(initial),
            selectable: true,
            scheduled: true,
            name: "サイト・アドレス（エクストラ）設定",
            config: configExtraSite,
            configure: this.configureType4ExtraSite,
          },
          {
            ...cloneDeep(initial),
            selectable: true,
            scheduled: true,
            name: "個別サービス設定",
            config: configCustomService,
            configure: this.configureCustomService,
          },
          {
            ...cloneDeep(initial),
            selectable: true,
            scheduled: true,
            name: "IP Pool設定",
            config: configIpPool,
            configure: this.configureType4IpPool,
          },
          {
            ...cloneDeep(initial),
            selectable: true,
            scheduled: true,
            name: "IPマッピング設定",
            config: configIpMapping,
            configure: this.configureType4IpMapping,
          },
          {
            ...cloneDeep(initial),
            selectable: true,
            scheduled: true,
            name: "インターネットFWプロファイル設定",
            config: configInternetFwProfile,
            configure: this.configureType4InternetFwProfile,
          },
          {
            ...cloneDeep(initial),
            selectable: true,
            scheduled: true,
            name: "URLフィルタリングプロファイル設定",
            config: configUrlProfile,
            configure: this.configureType4UrlProfile,
          },
          {
            ...cloneDeep(initial),
            selectable: true,
            scheduled: true,
            name: "ポリシー設定",
            config: $importConfig.loadType4Policy(wb),
            configure: this.configureType4Policy,
          },
          {
            ...cloneDeep(initial),
            selectable: false,
            scheduled: true,
            name: "不要オブジェクト削除",
            config: {
              route: configRoute,
              internetSite: configInternetSite,
              extraSite: configExtraSite,
              customService: configCustomService,
              ipPool: configIpPool,
              ipMapping: configIpMapping,
              internetFqProfile: configInternetFwProfile,
              urlProfile: configUrlProfile,
            },
            configure: this.removeUnnecessaryObject,
          },
        ];
      } else {
        this.imports = [
          {
            ...cloneDeep(initial),
            selectable: false,
            scheduled: true,
            name: "Allowログ設定",
            config: $importConfig.loadAllowLog(wb),
            configure: this.configureAllowLog,
          },
          {
            ...cloneDeep(initial),
            selectable: false,
            scheduled: true,
            name: "インターネットType4契約",
            config: $importConfig.loadType4Contract(wb),
            configure: this.configureType4Contract,
          },
        ];
      }
    },

    /** 設定 */
    async startImport() {
      this.inProgress = true;
      try {
        let acc = {
          type4Service: this.type4Service,
          internetAddresses: [] as ConfigAccumulate["internetAddresses"],
          internetFqdns: [] as ConfigAccumulate["internetFqdns"],
          extraSite: [] as ConfigAccumulate["extraSite"],
          ipMappings: [] as ConfigAccumulate["ipMappings"],
          customServices: [] as ConfigAccumulate["customServices"],
          internetFWProfiles: [] as ConfigAccumulate["internetFWProfiles"],
          urlProfiles: [] as ConfigAccumulate["urlProfiles"],
        } as ConfigAccumulate;
        for (const item of this.imports!) {
          item.status = "IN_PROGRESS";
          try {
            acc = await item.configure(
              item.scheduled,
              acc,
              item.config as never,
              item
            );
            item.progress = 10;
            item.status = item.scheduled ? "COMPLETED" : "SKIPPED";
          } catch (e) {
            console.error(e);
            item.progress = 10;
            item.status = "ERROR";
            item.error = e.data?.errorMessage
              ? e.data?.errorMessage
              : e.message;
            break;
          }
        }
      } finally {
        this.inProgress = false;
        this.isChanged = true;
      }
    },

    /** Allowログ */
    async configureAllowLog(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadAllowLog,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      const contractOption =
        await this.$api.contractChangeOption.getContractOptions();

      state.progress = 4;

      // Allowログに変更がない場合は変更しない
      // パートナー画面表示変更で「非表示」の時に値が設定されていた場合はエラーを表示
      // パートナー画面表示変更で「表示」の時に値が設定されていない場合はエラーを表示
      if (contractOption.isAllowLog === config.isAllowLog) {
        return acc;
      } else if (
        contractOption.isAllowLog === null &&
        config.isAllowLog !== null
      ) {
        throw Error(
          "Allowログを契約する場合は、パートナー「画面表示変更」の「Allowログ(バッチタイプ)」を表示に変更してください。"
        );
      } else if (
        contractOption.isAllowLog !== null &&
        config.isAllowLog === null
      ) {
        throw Error("AllowログはONまたはOFFで指定してください。");
      }

      await this.$api.contractChangeOption.putContractOptions({
        ...contractOption,
        isAllowLog: config.isAllowLog,
        note: null,
      });

      state.progress = 6;

      await this.waitProvisioning();

      return acc;
    },

    /** インターネットType4契約 */
    async configureType4Contract(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadType4Contract,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      // インターネットType4契約追加
      await this.$api.contract_change_type4.postType4Service({
        ...config!,
        note: null,
      });
      state.progress = 2;

      await this.waitProvisioning();
      state.progress = 4;

      const appliances = await this.$api.contract.getAppliances();
      const type4Appliance = appliances.applianceInfoList
        // 同一名が許可されているのでW番号の降順で最初を追加したものとみなす。
        .sortBy(["wnumberMainAct", "desc"])
        .find((e) => e.type4Name === config.type4Name)!;
      let type4Service = await this.$api.contract_change_type4.getType4Service(
        type4Appliance.type4Id!
      );
      state.progress = 6;

      // 追加グローバルIPアドレス数
      if (config.numberOfAddGlobalIpAddress > 0) {
        await this.$api.contract_change_type4.putType4Service(
          type4Service.type4Id,
          {
            ...type4Service,
            numberOfAddGlobalIpAddress: config.numberOfAddGlobalIpAddress,
            deleteGlobalIpAddressSeqList: null,
            note: null,
          }
        );
        await this.waitProvisioning();

        type4Service = await this.$api.contract_change_type4.getType4Service(
          type4Service.type4Id
        );
      }
      state.progress = 8;

      await this.waitProvisioning();

      return { ...acc, type4Service };
    },

    /** 経路設定 */
    async configureType4Route(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: string[],
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      if (!scheduled) {
        return acc;
      }

      const type4Route = await this.$api.type4Route.getType4Route(
        acc.type4Service.type4Id
      );
      state.progress = 2;

      await ignoreNoChange(async () => {
        await this.$api.type4Route.putType4Route(acc.type4Service.type4Id, {
          type4RouteKeyId: type4Route.type4RouteKeyId,
          type4RouteList: [
            ...type4Route.type4RouteList.filter((e) => e.isMicrosoft365),
            ...config.map((address) => ({
              internetAddress: address,
              isMicrosoft365: false,
            })),
          ],
        });
        state.progress = 6;

        await this.waitProvisioning();
      });

      return acc;
    },

    /** アドレス・FQDN（インターネット）設定 */
    async configureType4InternetSite(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadType4InternetSite,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      if (
        !scheduled ||
        (config.addressList.length === 0 && config.fqdnList.length === 0)
      ) {
        const internetSite = await this.$api.type4SiteApi.getType4InternetSite(
          acc.type4Service.type4Id
        );
        return {
          ...acc,
          internetAddresses: internetSite.addressList,
          internetFqdns: internetSite.fqdnList,
        };
      }

      let internetSite = await this.$api.type4SiteApi.getType4InternetSite(
        acc.type4Service.type4Id
      );
      state.progress = 2;

      await ignoreNoChange(async () => {
        await this.$api.type4SiteApi.postType4InternetSite(
          acc.type4Service.type4Id,
          {
            type4SiteKeyId: internetSite.type4SiteKeyId,
            addressList: [
              // 既存で同一のアドレス名またはIPアドレスが存在する場合は変更
              ...internetSite.addressList.map((e1) => {
                const findAddress = config.addressList.find(
                  (e2) =>
                    (e1.ipAddressName &&
                      e1.ipAddressName === e2.ipAddressName) ||
                    e1.ipAddress === e2.ipAddress
                );
                return findAddress ? { ...e1, ...findAddress } : e1;
              }),
              // 既存に存在しないIPアドレスは新規作成
              ...config.addressList
                .filter(
                  (e1) =>
                    !internetSite.addressList.some(
                      (e2) =>
                        (e1.ipAddressName &&
                          e1.ipAddressName === e2.ipAddressName) ||
                        e1.ipAddress === e2.ipAddress
                    )
                )
                .map((e) => ({ ...e, ipAddressId: null })),
            ],
            fqdnList: [
              // 既存で同一のFQDN名またはFQDNが存在する場合は変更
              ...internetSite.fqdnList.map((e1) => {
                const findFqdn = config.fqdnList.find(
                  (e2) =>
                    (e1.fqdnName && e1.fqdnName === e2.fqdnName) ||
                    e1.fqdn === e2.fqdn
                );
                return findFqdn ? { ...e1, ...findFqdn } : e1;
              }),
              // 既存に存在しないFQDNは新規作成
              ...config.fqdnList
                .filter(
                  (e1) =>
                    !internetSite.fqdnList.some(
                      (e2) =>
                        (e1.fqdnName && e1.fqdnName === e2.fqdnName) ||
                        e1.fqdn === e2.fqdn
                    )
                )
                .map((e) => ({ ...e, fqdnId: null })),
            ],
          }
        );
        state.progress = 6;

        await this.waitProvisioning();
      });

      internetSite = await this.$api.type4SiteApi.getType4InternetSite(
        acc.type4Service.type4Id
      );

      return {
        ...acc,
        internetAddresses: internetSite.addressList,
        internetFqdns: internetSite.fqdnList,
      };
    },

    /** アドレス・FQDN（エクストラ）設定 */
    async configureType4ExtraSite(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadType4ExtraSite,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      if (!scheduled || config.length === 0) {
        const extraSite = (
          await this.$api.type4SiteApi.getType4ExtraSite(
            acc.type4Service.type4Id
          )
        ).siteList;
        return { ...acc, extraSite };
      }

      for (let i = 0; i < config.length; i++) {
        const extraSite = await this.$api.type4SiteApi.getType4ExtraSite(
          acc.type4Service.type4Id
        );

        // 存在しないサイト名の場合は新規作成
        if (
          !extraSite.siteList.some((e) => e.siteName === config[i].siteName)
        ) {
          await this.$api.type4SiteApi.postType4ExtraSiteRegister(
            acc.type4Service.type4Id,
            {
              type4SiteKeyId: extraSite.type4SiteKeyId,
              siteName: config[i].siteName,
            }
          );
          await this.waitProvisioning();
        }

        const extraSite2 = await this.$api.type4SiteApi.getType4ExtraSite(
          acc.type4Service.type4Id
        );

        const site = extraSite2.siteList.find(
          (e) => e.siteName === config[i].siteName
        )!;

        await ignoreNoChange(async () => {
          await this.$api.type4SiteApi.postType4ExtraSiteModify(
            site.siteId,
            acc.type4Service.type4Id,
            {
              type4SiteKeyId: extraSite2.type4SiteKeyId,
              siteName: config[i].siteName,
              addressList: [
                // 既存で同一のアドレス名またはIPアドレスが存在する場合は変更
                ...site.addressList.map((e1) => {
                  const findAddress = config[i].addressList.find(
                    (e2) =>
                      (e1.ipAddressName &&
                        e1.ipAddressName === e2.ipAddressName) ||
                      e1.ipAddress === e2.ipAddress
                  );
                  return findAddress ? { ...e1, ...findAddress } : e1;
                }),
                // 既存に存在しないIPアドレスは新規作成
                ...config[i].addressList
                  .filter(
                    (e1) =>
                      !site.addressList.some(
                        (e2) =>
                          (e1.ipAddressName &&
                            e1.ipAddressName === e2.ipAddressName) ||
                          e1.ipAddress === e2.ipAddress
                      )
                  )
                  .map((e) => ({
                    ...e,
                    ipAddressId: null,
                  })),
              ],
            }
          );
        });

        await this.waitProvisioning();

        state.progress = ((i + 1) / config.length) * 10;
      }

      const extraSite = (
        await this.$api.type4SiteApi.getType4ExtraSite(acc.type4Service.type4Id)
      ).siteList;

      return { ...acc, extraSite };
    },

    /** 個別サービス設定 */
    async configureCustomService(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadType4CustomService,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      if (!scheduled || config.length === 0) {
        const customServices = (
          await this.$api.customServiceApi.getType4CustomService(
            acc.type4Service.type4Id
          )
        ).customServiceList;
        return { ...acc, customServices };
      }

      for (let i = 0; i < config.length; i++) {
        const customService =
          await this.$api.customServiceApi.getType4CustomService(
            acc.type4Service.type4Id
          );

        const modifyService = customService.customServiceList.find(
          (e) => e.serviceName === config[i].serviceName
        );
        if (modifyService) {
          // 同一のサービス名が存在する場合は変更
          await ignoreNoChange(async () => {
            await this.$api.customServiceApi.putType4CustomService(
              acc.type4Service.type4Id,
              modifyService.customServiceSeq,
              {
                customServiceKeyId: customService.customServiceKeyId,
                ...config[i],
                note: null,
              }
            );
            await this.waitProvisioning();
          });
        } else {
          // 同一のサービス名が存在しない場合は新規
          await this.$api.customServiceApi.postType4CustomService(
            acc.type4Service.type4Id,
            {
              customServiceKeyId: customService.customServiceKeyId,
              ...config[i],
              note: null,
            }
          );
          await this.waitProvisioning();
        }

        state.progress = ((i + 1) / config.length) * 10;
      }

      const customServices = (
        await this.$api.customServiceApi.getType4CustomService(
          acc.type4Service.type4Id
        )
      ).customServiceList;

      return { ...acc, customServices };
    },

    /** IP Pool設定 */
    async configureType4IpPool(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadType4IpPool,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      if (!scheduled || config.length === 0) {
        const ipPools = (
          await this.$api.ipPool.getIpPoolType4(acc.type4Service.type4Id)
        ).ipPoolList;
        return { ...acc, ipPools };
      }

      for (let i = 0; i < config.length; i++) {
        const ipPool = await this.$api.ipPool.getIpPoolType4(
          acc.type4Service.type4Id
        );

        const initialIp = acc.type4Service.globalIpAddressList.find(
          (e) => e.isInitialAddress
        )!;
        const modifyIpPool = ipPool.ipPoolList.find(
          (e) => e.ipPoolName === config[i].ipPoolName
        );
        if (modifyIpPool?.globalIpAddress === initialIp.globalIpAddress) {
          // デフォルトIP Poolは変更不可のため何もしない
        } else if (modifyIpPool) {
          // 同一のIP Pool名が存在する場合は変更
          await ignoreNoChange(async () => {
            await this.$api.ipPool.putIpPoolType4(
              {
                ipPoolKeyId: ipPool.ipPoolKeyId,
                ...config[i],
                note: null,
              },
              modifyIpPool.ipPoolSeq,
              acc.type4Service.type4Id
            );
            await this.waitProvisioning();
          });
        } else {
          // 同一のIP Pool名が存在しない場合は新規
          await this.$api.ipPool.postIpPoolType4(
            {
              ipPoolKeyId: ipPool.ipPoolKeyId,
              ...config[i],
              note: null,
            },
            acc.type4Service.type4Id
          );
          await this.waitProvisioning();
        }

        state.progress = ((i + 1) / config.length) * 10;
      }

      const ipPools = (
        await this.$api.ipPool.getIpPoolType4(acc.type4Service.type4Id)
      ).ipPoolList;

      return { ...acc, ipPools };
    },

    /** IPマッピング設定 */
    async configureType4IpMapping(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadType4IpMapping,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      if (!scheduled || config.length === 0) {
        const ipMappings = (
          await this.$api.ipMapping.getType4IpMappingList(
            acc.type4Service.type4Id
          )
        ).ipMappingList;
        return { ...acc, ipMappings };
      }

      for (let i = 0; i < config.length; i++) {
        const ipMapping = await this.$api.ipMapping.getType4IpMappingList(
          acc.type4Service.type4Id
        );

        // プライベートアドレス一覧から該当のアドレスを取得
        const address = acc.extraSite
          .flatMap((e) => e.addressList)
          .find((f) => f.ipAddress === config[i].ipAddress);
        if (!address) {
          throw new Error(
            `${config[i].ipMappingName}のプライベートIPがエクストラサイトに存在しません。`
          );
        }

        const modifyIpMapping = ipMapping.ipMappingList.find(
          (e) => e.ipMappingName === config[i].ipMappingName
        );
        if (modifyIpMapping) {
          // 同一のIPマッピング名が存在する場合は変更
          await ignoreNoChange(async () => {
            await this.$api.ipMapping.putType4IpMapping(
              acc.type4Service.type4Id,
              modifyIpMapping.virtualIpAddressSeq,
              {
                ipMappingKeyId: ipMapping.ipMappingKeyId,
                ipAddressId: address.ipAddressId,
                ...config[i],
                note: null,
              }
            );
            await this.waitProvisioning();
          });
        } else {
          // 同一のIPマッピング名が存在しない場合は新規
          await this.$api.ipMapping.postType4IpMapping(
            acc.type4Service.type4Id,
            {
              ipMappingKeyId: ipMapping.ipMappingKeyId,
              ipAddressId: address.ipAddressId,
              ...config[i],
              note: null,
            }
          );
          await this.waitProvisioning();
        }

        state.progress = ((i + 1) / config.length) * 10;
      }

      const ipMappings = (
        await this.$api.ipMapping.getType4IpMappingList(
          acc.type4Service.type4Id
        )
      ).ipMappingList;

      return { ...acc, ipMappings };
    },

    /** インターネットFWプロファイル設定 */
    async configureType4InternetFwProfile(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadType4internetFwProfile,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      if (!scheduled || config.length === 0) {
        const internetFWProfiles = (
          await this.$api.profileApi.getType4Profile(acc.type4Service.type4Id)
        ).profileList;
        return { ...acc, internetFWProfiles };
      }
      const applicationList =
        await this.$api.internetfwProfileApi.getInternetfwApplications();

      for (let i = 0; i < config.length; i++) {
        const internetFwProfile = await this.$api.profileApi.getType4Profile(
          acc.type4Service.type4Id
        );

        // 選択アプリケーションをapplicationSeqに変更
        const applicationSeqList = config[i].applicationNameList.map((e) => {
          const app = applicationList.find((v) => e === v.applicationName);
          if (app) {
            return app.applicationSeq;
          } else {
            throw new Error(
              `${config[i].profileInternetFwName}の選択アプリケーション(${e})が存在しません。`
            );
          }
        });

        const modifyProfile = internetFwProfile.profileList.find(
          (e) => e.profileInternetFwName === config[i].profileInternetFwName
        );
        if (modifyProfile?.profileInternetFwName === "any") {
          // 変更不可のanyの場合は何もしない
        } else if (modifyProfile) {
          // 同一のプロファイル名が存在する場合は変更
          await ignoreNoChange(async () => {
            await this.$api.internetfwProfileApi.putType4Profile(
              acc.type4Service.type4Id,
              modifyProfile.profileInternetFwSeq,
              {
                profileInternetFwKeyId:
                  internetFwProfile.profileInternetFwKeyId,
                profileInternetFwName: config[i].profileInternetFwName,
                applicationSeqList: applicationSeqList,
                note: null,
                description: null,
              }
            );
            await this.waitProvisioning();
          });
        } else {
          // 同一のプロファイル名が存在しない場合は新規
          await this.$api.internetfwProfileApi.postType4Profile(
            acc.type4Service.type4Id,
            {
              profileInternetFwKeyId: internetFwProfile.profileInternetFwKeyId,
              profileInternetFwName: config[i].profileInternetFwName,
              applicationSeqList: applicationSeqList,
              note: null,
              description: null,
            }
          );
          await this.waitProvisioning();
        }

        state.progress = ((i + 1) / config.length) * 10;
      }

      const internetFWProfiles = (
        await this.$api.profileApi.getType4Profile(acc.type4Service.type4Id)
      ).profileList;

      return { ...acc, internetFWProfiles };
    },

    /** URLフィルタリングプロファイル設定 */
    async configureType4UrlProfile(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadType4UrlProfile,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      if (!scheduled || config.length === 0) {
        const urlProfiles = (
          await this.$api.profileApi.getType4UrlProfile(
            acc.type4Service.type4Id
          )
        ).profileList;
        return { ...acc, urlProfiles };
      }

      for (let i = 0; i < config.length; i++) {
        // ブラックリストの重複チェック
        const blackListSet = new Set(config[i].blackList);
        if (blackListSet.size !== config[i].blackList.length) {
          throw new Error("ブラックリストが重複しています");
        }
        // ホワイトリストの重複チェック
        const whiteListSet = new Set(config[i].whiteList);
        if (whiteListSet.size !== config[i].whiteList.length) {
          throw new Error("ホワイトリストが重複しています");
        }

        const urlFilteringProfile =
          await this.$api.profileApi.getType4UrlProfile(
            acc.type4Service.type4Id
          );

        const modifyProfile = urlFilteringProfile.profileList.find(
          (e) => e.profileUrlName === config[i].profileUrlName
        );
        if (modifyProfile) {
          // 同一のプロファイル名が存在する場合は変更
          await ignoreNoChange(async () => {
            await this.$api.urlProfileApi.putType4UrlFiltering(
              acc.type4Service.type4Id,
              modifyProfile.profileUrlSeq,
              {
                profileUrlKeyId: urlFilteringProfile.profileUrlKeyId,
                note: null,
                ...config[i],
              }
            );
            await this.waitProvisioning();
          });
        } else {
          // 同一のプロファイル名が存在しない場合は新規
          await this.$api.urlProfileApi.postType4UrlFiltering(
            acc.type4Service.type4Id,
            {
              profileUrlKeyId: urlFilteringProfile.profileUrlKeyId,
              note: null,
              ...config[i],
            }
          );
          await this.waitProvisioning();
        }

        state.progress = ((i + 1) / config.length) * 10;
      }

      const urlProfiles = (
        await this.$api.profileApi.getType4UrlProfile(acc.type4Service.type4Id)
      ).profileList;

      return { ...acc, urlProfiles };
    },

    /** ポリシー設定 */
    async configureType4Policy(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: LoadType4Policy,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      if (!scheduled) {
        return acc;
      }

      const getPolicy = await this.$api.nwPolicy.getType4Policies(
        acc.type4Service.type4Id
      );
      acc.internetCountries = (
        await this.$api.nwPolicy.getCountries()
      ).countryList;
      acc.defaultServices = (
        await this.$api.serviceApi.getDefaultService()
      ).defaultServiceList;

      const putPolicy: PutPolicyType4 = putPolicyType4(acc, config, getPolicy);
      state.progress = 4;

      switch (acc.type4Service.applianceType) {
        case "UTM":
          await this.$api.nwPolicy.putType4Policies(
            acc.type4Service.type4Id,
            {
              ...putPolicy,
              policyIfwType4: null,
            },
            "UTM_IPMASQUERADE_NAT"
          );
          state.progress = 6;

          await this.waitProvisioning();

          break;
        case "IFW":
          await this.$api.nwPolicy.putType4Policies(
            acc.type4Service.type4Id,
            {
              ...putPolicy,
              policyUtmType4: null,
            },
            "IFW_IPMASQUERADE_NAT"
          );
          state.progress = 6;

          await this.waitProvisioning();

          break;
      }
      return acc;
    },

    /** 不要オブジェクト削除 */
    async removeUnnecessaryObject(
      scheduled: boolean,
      acc: ConfigAccumulate,
      config: RemoveUnnecessaryConfig,
      state: ConfigState
    ): Promise<ConfigAccumulate> {
      const total = this.imports!.filter(
        (e) => e.name !== "ポリシー設定" && e.name !== "不要オブジェクト削除"
      ).filter((e) => e.scheduled).length;
      let count = 0;
      const type4Id = acc.type4Service.type4Id;

      // URLフィルタリングプロファイル設定
      if (
        this.imports!.find(
          (e) => e.name === "URLフィルタリングプロファイル設定"
        )!.scheduled
      ) {
        await wrapRemoveAction(
          "URLフィルタリングプロファイル設定",
          async () => {
            // 削除対象のプロファイルが存在する場合は削除
            const removeProfiles = acc.urlProfiles.filter(
              (e1) =>
                !config.urlProfile.some(
                  (e2) => e1.profileUrlName === e2.profileUrlName
                )
            );

            if (removeProfiles.length > 0) {
              await this.$api.urlProfileApi.deleteType4UrlFiltering(type4Id, {
                profileUrlSeqList: removeProfiles.map((e) => e.profileUrlSeq),
                profileUrlKeyId: (
                  await this.$api.profileApi.getType4UrlProfile(type4Id)
                ).profileUrlKeyId,
                note: null,
              });
              await this.waitProvisioning();
            }

            state.progress = (count++ / total) * 10;
          }
        );
      }

      // インターネットFWプロファイル設定
      if (
        this.imports!.find(
          (e) => e.name === "インターネットFWプロファイル設定"
        )!.scheduled
      ) {
        await wrapRemoveAction("インターネットFWプロファイル設定", async () => {
          // 削除対象のプロファイルが存在する場合は削除. anyは削除出来ないので除く
          const removeProfiles = acc.internetFWProfiles
            .filter((e) => e.profileInternetFwName !== "any")
            .filter(
              (e1) =>
                !config.internetFqProfile.some(
                  (e2) => e1.profileInternetFwName === e2.profileInternetFwName
                )
            );

          if (removeProfiles.length > 0) {
            await this.$api.internetfwProfileApi.deleteType4Profile(type4Id, {
              profileInternetFwSeqList: removeProfiles.map(
                (e) => e.profileInternetFwSeq
              ),
              profileInternetFwKeyId: (
                await this.$api.profileApi.getType4Profile(type4Id)
              ).profileInternetFwKeyId,
              note: null,
            });
            await this.waitProvisioning();
          }

          state.progress = (count++ / total) * 10;
        });
      }

      // IPマッピング設定
      if (this.imports!.find((e) => e.name === "IPマッピング設定")!.scheduled) {
        await wrapRemoveAction("IPマッピング設定", async () => {
          // 削除対象のIPマッピングが存在する場合は削除
          const removeMappings = acc.ipMappings.filter(
            (e1) =>
              !config.ipMapping.some(
                (e2) => e1.ipMappingName === e2.ipMappingName
              )
          );

          if (removeMappings.length > 0) {
            await this.$api.ipMapping.deleteType4IpMapping(type4Id, {
              ipMappingList: removeMappings.map((e) => e.virtualIpAddressSeq),
              ipMappingKeyId: (
                await this.$api.ipMapping.getType4IpMappingList(type4Id)
              ).ipMappingKeyId,
              note: null,
            });
            await this.waitProvisioning();
          }

          state.progress = (count++ / total) * 10;
        });
      }

      // IP Pool設定
      if (this.imports!.find((e) => e.name === "IP Pool設定")!.scheduled) {
        await wrapRemoveAction("IP Pool設定", async () => {
          // 削除対象のIP Poolが存在する場合は削除、ただしデフォルトのIP Poolは除外
          const initialIp = acc.type4Service.globalIpAddressList.find(
            (e) => e.isInitialAddress
          )!;
          const removePools = acc.ipPools
            .filter((e) => e.globalIpAddress !== initialIp.globalIpAddress)
            .filter(
              (e1) =>
                !config.ipPool.some((e2) => e1.ipPoolName === e2.ipPoolName)
            );

          if (removePools.length > 0) {
            await this.$api.ipPool.deleteIpPoolType4(
              {
                ipPoolList: removePools.map((e) => e.ipPoolSeq),
                ipPoolKeyId: (
                  await this.$api.ipPool.getIpPoolType4(type4Id)
                ).ipPoolKeyId,
                note: null,
              },
              type4Id
            );
            await this.waitProvisioning();
          }

          state.progress = (count++ / total) * 10;
        });
      }

      // 個別サービス設定
      if (this.imports!.find((e) => e.name === "個別サービス設定")!.scheduled) {
        await wrapRemoveAction("個別サービス設定", async () => {
          // 削除対象の個別サービスが存在する場合は削除
          const removeServices = acc.customServices.filter(
            (e1) =>
              !config.customService.some(
                (e2) => e1.serviceName === e2.serviceName
              )
          );

          if (removeServices.length > 0) {
            await this.$api.customServiceApi.deleteType4CustomService(type4Id, {
              customServiceSeqList: removeServices.map(
                (e) => e.customServiceSeq
              ),
              customServiceKeyId: (
                await this.$api.customServiceApi.getType4CustomService(type4Id)
              ).customServiceKeyId,
            });
            await this.waitProvisioning();
          }

          state.progress = (count++ / total) * 10;
        });
      }

      // サイト・アドレス（エクストラ）設定
      if (
        this.imports!.find(
          (e) => e.name === "サイト・アドレス（エクストラ）設定"
        )!.scheduled
      ) {
        await wrapRemoveAction(
          "サイト・アドレス（エクストラ）設定",
          async () => {
            // サイトで削除対象のアドレスが存在する場合は削除
            for (const site of acc.extraSite) {
              const removedAddressList = site.addressList.filter((e1) =>
                config.extraSite
                  .filter((e2) => site.siteName === e2.siteName)
                  .flatMap((e2) => e2.addressList)
                  .some((e2) => e1.ipAddress === e2.ipAddress)
              );
              if (removedAddressList.length !== site.addressList.length) {
                await this.$api.type4SiteApi.postType4ExtraSiteModify(
                  site.siteId,
                  type4Id,
                  {
                    type4SiteKeyId: (
                      await this.$api.type4SiteApi.getType4ExtraSite(type4Id)
                    ).type4SiteKeyId,
                    siteName: site.siteName,
                    addressList: removedAddressList,
                  }
                );
                await this.waitProvisioning();
              }
            }

            // 削除対象のサイトが存在する場合は削除
            const removeSites = acc.extraSite.filter(
              (e1) =>
                !config.extraSite.some((e2) => e1.siteName === e2.siteName)
            );
            if (removeSites.length > 0) {
              await this.$api.type4SiteApi.postType4ExtraSiteDelete(type4Id, {
                siteList: removeSites.map((e) => e.siteId),
                type4SiteKeyId: (
                  await this.$api.type4SiteApi.getType4ExtraSite(type4Id)
                ).type4SiteKeyId,
              });
              await this.waitProvisioning();
            }

            state.progress = (count++ / total) * 10;
          }
        );
      }

      // アドレス・FQDN（インターネット）設定
      if (
        this.imports!.find(
          (e) => e.name === "アドレス・FQDN（インターネット）設定"
        )!.scheduled
      ) {
        await wrapRemoveAction(
          "アドレス・FQDN（インターネット）設定",
          async () => {
            // アドレスまたはFQDNで削除対象が存在する場合は削除して設定変更
            const removedAddresses = acc.internetAddresses.filter((e1) =>
              config.internetSite.addressList.some(
                (e2) => e1.ipAddress === e2.ipAddress
              )
            );
            const removedFqdns = acc.internetFqdns.filter((e1) =>
              config.internetSite.fqdnList.some((e2) => e1.fqdn === e2.fqdn)
            );

            if (
              removedAddresses.length !== acc.internetAddresses.length ||
              removedFqdns.length !== acc.internetFqdns.length
            ) {
              const internetSite =
                await this.$api.type4SiteApi.getType4InternetSite(type4Id);
              await this.$api.type4SiteApi.postType4InternetSite(type4Id, {
                type4SiteKeyId: internetSite.type4SiteKeyId,
                addressList: removedAddresses,
                fqdnList: removedFqdns,
              });
              await this.waitProvisioning();
            }

            state.progress = (count++ / total) * 10;
          }
        );
      }

      // 経路設定
      if (this.imports!.find((e) => e.name === "経路設定")!.scheduled) {
        // 不要オブジェクト削除処理が不要なのでカウントアップのみ
        state.progress = (count++ / total) * 10;
      }

      return acc;
    },

    /**
     * プロビジョニングが完了状態になるまでポーリングを行う
     * @param interval ポーリング頻度(ms)
     * @param timeout タイムアウト(ms)
     */
    async waitProvisioning(
      interval = 3 * 1000,
      timeout = 60 * 1000 * 30
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          reject("Provisioning timeout");
        }, timeout);
        const intervalId = setInterval(() => {
          if (!isShowProvisioningStatus(this.provisioning.messageCode)) {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            resolve();
          }
        }, interval);
      });
    },
  },
});

export function putPolicyType4(
  acc: ConfigAccumulate,
  type4policy: LoadType4Policy,
  getPolicy: GetPolicyType4
): PutPolicyType4 {
  const toSrcAddress1 = (
    src: { srcAddress: PutSrcAddress1 } & Pick<PutPolicy, "policyId">
  ): PutSrcAddress1 => ({
    srcAddressList: src.srcAddress.srcAddressList.map((e) => {
      const address = acc.internetAddresses.find((v) => e === v.ipAddress);
      if (address) {
        return address.ipAddressId;
      } else {
        throw new Error(
          `ID:${src.policyId}の送信元アドレス(${e})がインターネットサイトに存在しません。`
        );
      }
    }),
    srcFqdnList: src.srcAddress.srcFqdnList.map((e) => {
      const fqdn = acc.internetFqdns.find((v) => e === v.fqdn);
      if (fqdn) {
        return fqdn.fqdnId;
      } else {
        throw new Error(
          `ID:${src.policyId}の送信元FQDN(${e})がインターネットサイトに存在しません。`
        );
      }
    }),
    srcCountryList: src.srcAddress.srcCountryList.map((e) => {
      const country = acc.internetCountries.find((v) => e === v.country);
      if (country) {
        return country.countrySeq;
      } else {
        throw new Error(
          `ID:${src.policyId}の送信元アドレスの国(${e})が存在しません。`
        );
      }
    }),
  });
  const toDstAddress1 = (
    dst: { dstAddress: PutDstAddress1 } & Pick<PutPolicy, "policyId">
  ): PutDstAddress1 => ({
    dstAddressList: dst.dstAddress.dstAddressList.map((e) => {
      const address = acc.extraSite
        .flatMap((v) => v.addressList)
        .find((v) => e === v.ipAddress);
      if (address) {
        return address.ipAddressId;
      } else {
        throw new Error(
          `ID:${dst.policyId}の宛先アドレス(${e})がエクストラサイトに存在しません。`
        );
      }
    }),
  });
  const toSrcAddress2 = (
    src: { srcAddress: PutSrcAddress2 } & Pick<PutPolicy, "policyId">
  ): PutSrcAddress2 => ({
    srcAddressList: src.srcAddress.srcAddressList.map((e) => {
      const address = acc.extraSite
        .flatMap((v) => v.addressList)
        .find((v) => e === v.ipAddress);
      if (address) {
        return address.ipAddressId;
      } else {
        throw new Error(
          `ID:${src.policyId}の送信元アドレス(${e})がエクストラサイトに存在しません。`
        );
      }
    }),
  });
  const toDstAddress2 = (
    dst: { dstAddress: PutDstAddress2 } & Pick<PutPolicy, "policyId">
  ): PutDstAddress2 => ({
    dstAddressList: dst.dstAddress.dstAddressList.map((e) => {
      const address = acc.internetAddresses.find((v) => e === v.ipAddress);
      if (address) {
        return address.ipAddressId;
      } else {
        throw new Error(
          `ID:${dst.policyId}の宛先アドレス(${e})がインターネットサイトに存在しません。`
        );
      }
    }),
    dstFqdnList: dst.dstAddress.dstFqdnList.map((e) => {
      const address = acc.internetFqdns.find((v) => e === v.fqdn);
      if (address) {
        return address.fqdnId;
      } else {
        throw new Error(
          `ID:${dst.policyId}の宛先FQDN(${e})がインターネットサイトに存在しません。`
        );
      }
    }),
    dstCountryList: dst.dstAddress.dstCountryList.map((e) => {
      const address = acc.internetCountries.find((v) => e === v.country);
      if (address) {
        return address.countrySeq;
      } else {
        throw new Error(
          `ID:${dst.policyId}の宛先アドレスの国(${e})が存在しません。`
        );
      }
    }),
  });
  const toDefaultService = (
    s: { defaultServiceList: PutPolicy["defaultServiceList"] } & Pick<
      PutPolicy,
      "policyId"
    >
  ): string[] =>
    s.defaultServiceList.map((e) => {
      const service = acc.defaultServices.find((v) => e === v.serviceName);
      if (service) {
        return service.serviceSeq;
      } else {
        throw new Error(
          `ID:${s.policyId}のデフォルトサービス(${e})が存在しません。`
        );
      }
    });
  const toCustomService = (
    s: { customServiceList: PutPolicy["customServiceList"] } & Pick<
      PutPolicy,
      "policyId"
    >
  ): string[] =>
    s.customServiceList.map((e) => {
      const service = acc.customServices.find((v) => e === v.serviceName);
      if (service) {
        return service.customServiceSeq;
      } else {
        throw new Error(`ID:${s.policyId}の個別サービス(${e})が存在しません。`);
      }
    });
  const toProfileInternetFw = (
    p: {
      profile: {
        profileInternetFw: null | { profileInternetFwName: string | null };
      };
    } & Pick<PutPolicy, "policyId">
  ): PutProfile["profileInternetFw"] => {
    if (!p.profile.profileInternetFw) {
      return null;
    }
    const profile = acc.internetFWProfiles.find(
      (e) =>
        e.profileInternetFwName ===
        p.profile.profileInternetFw!.profileInternetFwName
    );
    if (profile) {
      return profile;
    } else {
      throw new Error(
        `ID:${p.policyId}のインターネットFWプロファイルが存在しません。`
      );
    }
  };
  const toProfileUrl = (
    p: {
      profile: {
        profileUrl: null | { profileUrlName: string | null };
      };
    } & Pick<PutPolicy, "policyId">
  ): PutProfile["profileUrl"] => {
    if (!p.profile.profileUrl) {
      return null;
    }
    const profile = acc.urlProfiles.find(
      (e) => e.profileUrlName === p.profile.profileUrl!.profileUrlName
    );
    if (profile) {
      return profile;
    } else {
      throw new Error(
        `ID:${p.policyId}のURLフィルタリングプロファイルが存在しません。`
      );
    }
  };
  const toIpMapping = (
    m: {
      ipMapping: { virtualIpAddressName: string | null };
    } & Pick<PutPolicy, "policyId">
  ): PutPolicy["ipMapping"] => {
    const ipMap = acc.ipMappings.find(
      (e) => e.ipMappingName === m.ipMapping.virtualIpAddressName
    );
    if (ipMap) {
      return ipMap;
    } else {
      throw new Error(`ID:${m.policyId}のIPマッピングが存在しません。`);
    }
  };
  const toPolicyObjectId = (
    policy: Pick<CommonPolicy, "policyId">,
    policyList?: Pick<GetPolicy, "policyId" | "policyObjectId">[]
  ): string | null => {
    return (
      policyList?.find((e) => e.policyId === policy.policyId)?.policyObjectId ??
      null
    );
  };
  // 既存のポリシー一覧で一致するポリシーIDが存在する場合はそのポリシーカテゴリー情報を返す
  // 存在しない場合は新規としてカスタムポリシーを返す
  const toPolicyCategoryInfo = (
    policy: Pick<CommonPolicy, "policyId">,
    policyList?: Pick<CommonPolicy, "policyId" | "policyCategoryInfo">[]
  ): CommonPolicy["policyCategoryInfo"] => {
    return (
      policyList?.find((e) => e.policyId === policy.policyId)
        ?.policyCategoryInfo ?? "CUSTOM_POLICY"
    );
  };

  return {
    policyUtmType4: type4policy.policyUtmType4
      ? {
          internetToAccessPointPolicyList:
            type4policy.policyUtmType4.internetToAccessPointPolicyList
              // 対象が編集不可ポリシーの場合は除外
              .filter(
                (policy) =>
                  toPolicyCategoryInfo(
                    policy,
                    getPolicy.policyUtmType4?.internetToAccessPointPolicyList
                  ) !== "UNEDITABLE_DEFAULT_POLICY"
              )
              .map((policy) => ({
                ...policy,
                srcAddress: toSrcAddress1(policy),
                dstAddress: toDstAddress1(policy),
                defaultServiceList: toDefaultService(policy),
                customServiceList: toCustomService(policy),
                profile: {
                  ...policy.profile,
                  profileInternetFw: toProfileInternetFw(policy),
                  profileUrl: toProfileUrl(policy),
                },
                policyObjectId: toPolicyObjectId(
                  policy,
                  getPolicy.policyUtmType4?.internetToAccessPointPolicyList
                ),
              })),
          accessPointToInternetPolicyList:
            type4policy.policyUtmType4.accessPointToInternetPolicyList
              // 対象が編集不可ポリシーの場合は除外
              .filter(
                (policy) =>
                  toPolicyCategoryInfo(
                    policy,
                    getPolicy.policyUtmType4?.accessPointToInternetPolicyList
                  ) !== "UNEDITABLE_DEFAULT_POLICY"
              )
              .map((policy) => ({
                ...policy,
                srcAddress: toSrcAddress2(policy),
                dstAddress: toDstAddress2(policy),
                defaultServiceList: toDefaultService(policy),
                customServiceList: toCustomService(policy),
                profile: {
                  ...policy.profile,
                  profileInternetFw: toProfileInternetFw(policy),
                  profileUrl: toProfileUrl(policy),
                },
                policyObjectId: toPolicyObjectId(
                  policy,
                  getPolicy.policyUtmType4?.accessPointToInternetPolicyList
                ),
                policyCategoryInfo: toPolicyCategoryInfo(
                  policy,
                  getPolicy.policyUtmType4?.accessPointToInternetPolicyList
                ),
              })),
        }
      : null,
    policyIfwType4: type4policy.policyIfwType4
      ? {
          internetToAccessPointPolicyList:
            type4policy.policyIfwType4.internetToAccessPointPolicyList
              // 対象が編集不可ポリシーの場合は除外
              .filter(
                (policy) =>
                  toPolicyCategoryInfo(
                    policy,
                    getPolicy.policyIfwType4?.internetToAccessPointPolicyList
                  ) !== "UNEDITABLE_DEFAULT_POLICY"
              )
              .map((policy) => ({
                ...policy,
                srcAddress: toSrcAddress1(policy),
                dstAddress: toDstAddress1(policy),
                defaultServiceList: toDefaultService(policy),
                customServiceList: toCustomService(policy),
                profile: {
                  ...policy.profile,
                  profileInternetFw: toProfileInternetFw(policy),
                },
                policyObjectId: toPolicyObjectId(
                  policy,
                  getPolicy.policyIfwType4?.internetToAccessPointPolicyList
                ),
              })),
          accessPointToInternetPolicyList:
            type4policy.policyIfwType4.accessPointToInternetPolicyList
              // 対象が編集不可ポリシーの場合は除外
              .filter(
                (policy) =>
                  toPolicyCategoryInfo(
                    policy,
                    getPolicy.policyIfwType4?.accessPointToInternetPolicyList
                  ) !== "UNEDITABLE_DEFAULT_POLICY"
              )
              .map((policy) => ({
                ...policy,
                srcAddress: toSrcAddress2(policy),
                dstAddress: toDstAddress2(policy),
                defaultServiceList: toDefaultService(policy),
                customServiceList: toCustomService(policy),
                profile: {
                  ...policy.profile,
                  profileInternetFw: toProfileInternetFw(policy),
                },
                policyObjectId: toPolicyObjectId(
                  policy,
                  getPolicy.policyIfwType4?.accessPointToInternetPolicyList
                ),
                policyCategoryInfo: toPolicyCategoryInfo(
                  policy,
                  getPolicy.policyIfwType4?.accessPointToInternetPolicyList
                ),
              })),
        }
      : null,
    policyIPMasqueradeType4: type4policy.policyIPMasqueradeType4
      ? {
          policyList: type4policy.policyIPMasqueradeType4.policyList
            // 対象が編集不可ポリシーの場合は除外
            .filter(
              (policy) =>
                toPolicyCategoryInfo(
                  policy,
                  getPolicy.policyIPMasqueradeType4?.policyList
                ) !== "UNEDITABLE_DEFAULT_POLICY"
            )
            .map((policy) => ({
              ...policy,
              srcAddressList: policy.srcAddressList.map((e) => {
                const address = acc.extraSite
                  .flatMap((v) => v.addressList)
                  .find((v) => e === v.ipAddress);
                if (address) {
                  return address.ipAddressId;
                } else {
                  throw new Error(
                    `ID:${policy.policyId}の送信元アドレス(${e})がエクストラサイトに存在しません。`
                  );
                }
              }),
              dstAddressList: policy.dstAddressList.map((e) => {
                const address = acc.internetAddresses.find(
                  (v) => e === v.ipAddress
                );
                if (address) {
                  return address.ipAddressId;
                } else {
                  throw new Error(
                    `ID:${policy.policyId}の宛先アドレス(${e})がインターネットサイトに存在しません。`
                  );
                }
              }),
              ipPoolList: policy.ipPoolList.map((e) => {
                const pool = acc.ipPools.find((v) => e === v.ipPoolName);
                if (pool) {
                  return pool.ipPoolSeq;
                } else {
                  throw new Error(
                    `ID:${policy.policyId}のIP Pool(${e})が存在しません。`
                  );
                }
              }),
              policyObjectId: toPolicyObjectId(
                policy,
                getPolicy.policyIPMasqueradeType4?.policyList
              ),
              policyCategoryInfo: toPolicyCategoryInfo(
                policy,
                getPolicy.policyIPMasqueradeType4?.policyList
              ),
            })),
        }
      : null,
    policyNatType4: type4policy.policyNatType4
      ? {
          policyList: type4policy.policyNatType4.policyList.map((policy) => ({
            ...policy,
            srcAddressList: policy.srcAddressList.map((e) => {
              const address = acc.internetAddresses.find(
                (v) => e === v.ipAddress
              );
              if (address) {
                return address.ipAddressId;
              } else {
                throw new Error(
                  `ID:${policy.policyId}の送信元アドレス(${e})がインターネットサイトに存在しません。`
                );
              }
            }),
            ipMapping: toIpMapping(policy),
            policyObjectId: toPolicyObjectId(
              policy,
              getPolicy.policyNatType4?.policyList
            ),
          })),
        }
      : null,
    policyKeyId: getPolicy.policyKeyId,
  };
}

/**
 * 変更APIで設定内容に変更が無い場合のエラーを無視する
 * @param action 変更API処理
 */
async function ignoreNoChange(action: () => Promise<void>) {
  try {
    await action();
  } catch (err) {
    if (
      err instanceof ApiBadRequestError &&
      err.data.errorCode === "CC99013W"
    ) {
      console.debug(`ignore no change. msg: ${err.data.errorMessage}`);
    } else {
      throw err;
    }
  }
}

/**
 * 不要オブジェクト削除でエラーが発生した際にメッセージに設定項目名を付与する
 * @param category 設定項目名
 * @param action 不要オブジェクト削除処理
 */
async function wrapRemoveAction(category: string, action: () => Promise<void>) {
  try {
    await action();
  } catch (e) {
    if (e.data?.errorMessage) {
      e.data.errorMessage = `${category}\n${e.data.errorMessage}`;
    } else {
      e.message = `${category}\n${e.message}`;
    }
    throw e;
  }
}


























































































































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { GetPolicy, GetPolicyType1 } from "@/apis/NwPolicyApi";
import cloneDeep from "lodash/cloneDeep";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicyService from "@/components/policy/PolicyService.vue";
import PolicyProfile from "@/components/policy/PolicyProfile.vue";
import { Type1UTMPolicy } from "@/modals/applianceContractSetting/InternetType1PolicyUtmList.vue";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import InternetType1PolicyRuleUtmEdit from "@/modals/applianceContractSetting/InternetType1PolicyRuleUtmEdit.vue";
import { Type1Service } from "@/apis/ContractChangeType1Api";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";

export default Vue.extend({
  name: "InternetType1PolicyRuleUtmViewRD",
  components: {
    PolicyProfile,
    PolicyService,
    PolicyDstAddress,
    PolicySrcAddress,
  },
  props: {
    /** ゾーン */
    zone: {
      type: Object as PropType<ZoneRelationItem>,
      required: true,
    },
    /** ポリシーリスト（インターネットからアクセス拠点向け/アクセス拠点からインターネット向け） */
    policyList: {
      type: Array as PropType<Type1UTMPolicy[]>,
      required: true,
    },
    /** type1ポリシー全体 */
    type1Policy: {
      type: Object as PropType<GetPolicyType1>,
      required: true,
    },
    /** サービスメニュー(インターネットType1) */
    type1Service: {
      type: Object as PropType<Type1Service>,
      required: true,
    },
  },
  data() {
    return {
      table: {
        fields: [
          { key: "_handle", label: "↑↓", tdClass: "handle" },
          { key: "policyId", label: "ID" },
          { key: "srcAddress", label: "送信元アドレス" },
          { key: "dstAddress", label: "宛先アドレス" },
          { key: "service", label: "サービス" },
          { key: "profile", label: "プロファイル" },
          { key: "actionType", label: "アクション" },
          { key: "isLogOutput", label: "ログ出力" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
          { key: "delete", label: "" },
        ],
        items: cloneDeep(this.policyList),
      } as Pick<AppTableData<Type1UTMPolicy>, "fields" | "items">,
      utmPolicyLimit: [] as UpperLimitEntity[],
      isLoaded: false,
    };
  },
  computed: {
    /** true: 作成できるポリシー数の上限, false: 上限ではない */
    limitPolicy(): boolean {
      // UTM側のカスタムポリシー数（ゾーン横断）
      const totalPolicy =
        (this.latestType1Policy.policyUtmType1?.accessPointToInternetList
          .flatMap((e) => e.policyList)
          .filter((e) => e.policyCategoryInfo === "CUSTOM_POLICY").length ??
          0) +
        (this.latestType1Policy.policyUtmType1?.internetToAccessPointList
          .flatMap((e) => e.policyList)
          .filter((e) => e.policyCategoryInfo === "CUSTOM_POLICY").length ?? 0);
      return (
        totalPolicy >=
        this.utmPolicyLimit!.filter(
          (e) => e.upperLimitItemName === "ポリシー数(UTM)"
        )[0]!.upperLimit
      );
    },
    /** 最新の全体ポリシー（全体ポリシー + 編集中のポリシー ）*/
    latestType1Policy(): GetPolicyType1 {
      return {
        ...this.type1Policy,
        policyUtmType1: {
          internetToAccessPointList:
            this.type1Policy.policyUtmType1?.internetToAccessPointList.map(
              (e) => {
                return e.zoneRelationId === this.zone.zoneRelationId
                  ? {
                      ...e,
                      policyList: this.table.items as typeof e.policyList,
                    }
                  : e;
              }
            ) ?? [],
          accessPointToInternetList:
            this.type1Policy.policyUtmType1?.accessPointToInternetList.map(
              (e) => {
                return e.zoneRelationId === this.zone.zoneRelationId
                  ? {
                      ...e,
                      policyList: this.table.items as typeof e.policyList,
                    }
                  : e;
              }
            ) ?? [],
        },
      };
    },
  },
  async mounted() {
    /** UTMポリシー設定上限値の取得 */
    this.utmPolicyLimit = (
      await this.$api.upperLimit.getUpperLimitLine({
        upperLimitLineManageUnit: "APPLIANCE",
        applianceType: "UTM",
        enumber: this.type1Service.enumberMainAct,
      })
    ).internetLine!.upperLimitList!;
    this.isLoaded = true;
  },
  methods: {
    /** ポリシー追加 */
    async addPolicy() {
      const addPolicy: Type1UTMPolicy = await this.$modal.show(
        InternetType1PolicyRuleUtmEdit,
        {
          zone: this.zone,
          type1Policy: this.latestType1Policy,
        }
      );
      const last = this.table.items
        .map((e) => e.policyCategoryInfo)
        .lastIndexOf("UNEDITABLE_DEFAULT_POLICY");
      this.table.items.splice(
        // 最後 or 編集不可の一つ前
        last === -1 ? this.table.items.length : last,
        0,
        addPolicy
      );
    },

    /** ポリシー編集 */
    async editPolicy(policy: Type1UTMPolicy, index: number) {
      if (policy.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY") {
        return;
      }
      const editPolicy: Type1UTMPolicy = await this.$modal.show(
        InternetType1PolicyRuleUtmEdit,
        {
          zone: this.zone,
          policy,
          type1Policy: this.type1Policy,
        }
      );
      this.$set(this.table.items, index, editPolicy);
    },

    /** ポリシー削除 */
    async deletePolicy(index: number) {
      this.table.items.splice(index, 1);
    },

    /** ポリシー編集完了 */
    async submit() {
      await this.$confirm(`ポリシーを保存します。よろしいですか？`);
      (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(
        this.table.items
      );
    },

    /** ポリシーテーブルのtrクラス. */
    policyRowClass(
      item: Pick<GetPolicy, "policyCategoryInfo">
    ): string[] | null {
      switch (item.policyCategoryInfo) {
        case "CUSTOM_POLICY":
          // ドラッグ可能
          return ["draggable"];
        case "EDITABLE_DEFAULT_POLICY":
          // ドラッグ可能
          return ["draggable"];
        case "UNEDITABLE_DEFAULT_POLICY":
          // ドラッグ不可 + 背景色グレーアウト
          return ["not-editable-policy", "un-clickable"];
      }
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 *   入力されたIPV6-IPアドレス(範囲なし)が別に存在するCIDR形式のサブネットの範囲リストに包含されていないことの検証
 *  ・CIDR形式のみ受付可能
 *  ・正しいIPV6形式であることは別のバリデーションで実施が必要
 *  ・比較対象は複数指定可
 */
export default {
  params: ["targetList"],
  validate(
    value: string | undefined | null,
    { targetList }: { targetList: string[] | null | undefined }
  ): boolean {
    if (!value || !targetList) {
      return true;
    }

    // valueがIPV6形式でない場合、true
    let valueIpv6: [ipaddr.IPv6, number];
    try {
      valueIpv6 = ipaddr.IPv6.parseCIDR(value);
    } catch (e) {
      return true;
    }

    for (const strIpv6 of targetList) {
      let targetIpv6: [ipaddr.IPv6, number];
      try {
        targetIpv6 = ipaddr.IPv6.parseCIDR(strIpv6);
      } catch (e) {
        //  targetListにIPV6形式で無い文字列が存在した場合、次のループ
        continue;
      }
      // targetListの範囲に含まれている場合、エラー
      if (valueIpv6[0].match(targetIpv6)) {
        return false;
      }
    }
    return true;
  },
} as ValidationRuleSchema;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"ポリシー編集","subtitle":("イントラネットFW プラットフォームゲートウェイ " + _vm.srcZone + " → " + (_vm.srcZone === 'WVS2' ? 'KCPS' : 'WVS2')),"classes":"modal-full"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var cancel = ref.cancel;
return [(_vm.isLoaded)?_c('b-form',{attrs:{"novalidate":""}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"btn-container justify-content-between align-items-center mb-2"},[_c('span',[_vm._v(_vm._s(_vm.intranetPolicyTable.items.length)+"件を表示")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.limitPolicy},on:{"click":_vm.showIntraPolicyRegister}},[_vm._v("ルール追加 ")])],1),_c('app-table-draggable',{attrs:{"primary-key":"policyId","options":{ draggable: '.draggable' }},model:{value:(_vm.intranetPolicyTable.items),callback:function ($$v) {_vm.$set(_vm.intranetPolicyTable, "items", $$v)},expression:"intranetPolicyTable.items"}},[_c('app-table',{attrs:{"small":"","fields":_vm.intranetPolicyTable.fields,"items":_vm.intranetPolicyTable.items,"pagination":false,"primary-key":"policyId","hover":true,"thead-class":"text-center small","sticky-header":"500px","tbody-tr-class":_vm.policyRowClass},on:{"row-clicked":_vm.showIntraPolicyModify},scopedSlots:_vm._u([{key:"cell(_handle)",fn:function(){return [_c('b-icon-list')]},proxy:true},{key:"cell(srcAddress)",fn:function(ref){
  var item = ref.item;
return [_c('intranet-policy-src-address',{attrs:{"ip-address-list":item.srcAddressList}})]}},{key:"cell(dstAddress)",fn:function(ref){
  var item = ref.item;
return [_c('intranet-policy-dst-address',{attrs:{"ip-address-list":item.dstAddressList}})]}},{key:"cell(service)",fn:function(ref){
  var item = ref.item;
return [_c('intranet-policy-service',{attrs:{"custom-service-list":item.customServiceList,"default-service-list":item.serviceList}})]}},{key:"cell(actionType)",fn:function(ref){
  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"allow_deny"))+" ")]}},{key:"cell(isPolicyStatus)",fn:function(ref){
  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}},{key:"cell(delete)",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [(item.policyCategoryInfo !== 'UNEDITABLE_DEFAULT_POLICY')?_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.deletePolicy(index)}}},[_vm._v("削除 ")]):_vm._e()]}}],null,true)})],1)],1),_c('div',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("保存")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)]):_vm._e()]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }



























































































































































































































































































import Vue, { PropType } from "vue";
import { IpMapping } from "@/apis/IpMappingApi";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "IpMappingType4Edit",
  props: {
    ipMapping: {
      type: Object as PropType<IpMapping>,
      required: false,
      default: null,
    },
    ipMappingKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    type4Id: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      ipMappingInfo: this.ipMapping
        ? cloneDeep(this.ipMapping)
        : ({
            isPortForward: false,
            protocol: "TCP",
          } as IpMapping),
      globalIpList: [] as string[],
      privateIpList: [] as { ipAddressId: string; ipAddress: string }[],
      portForwardOptions: [
        { text: "無効", val: false },
        { text: "有効", val: true },
      ],
      protocolOptions: [
        { text: "TCP", val: "TCP" },
        { text: "UDP", val: "UDP" },
      ],
      isLoaded: false,
    };
  },
  async mounted() {
    if (!this.ipMapping) {
      await this.getGlobalIpList();
    }
    await this.getPrivateIpList();
    this.isLoaded = true;
  },
  computed: {
    isPrivateByRange(): boolean | undefined {
      return this.ipMappingInfo.privatePort?.includes("-");
    },
    isGlobalByRange(): boolean | undefined {
      return this.ipMappingInfo.globalPort?.includes("-");
    },
  },
  methods: {
    /**
     * グローバルIPリストをAPIから取得する
     */
    async getGlobalIpList() {
      // Type4IDがある場合、Type4用の取得APIを呼び出す
      const globalIpList = (
        await this.$api.contract_change_type4.getType4Service(this.type4Id)
      ).globalIpAddressList;

      // 取得結果から必要なプロバティのみ抽出して、昇順にソートする
      this.globalIpList = globalIpList
        .map((e) => e.globalIpAddress)
        .sortBy([(e) => e, "asc"]);
    },
    async getPrivateIpList() {
      const extraIpList = (
        await this.$api.type4SiteApi.getType4ExtraSite(this.type4Id)
      ).siteList;
      // 取得結果から必要なプロバティのみ抽出する
      extraIpList.forEach((e) =>
        e.addressList.forEach((a) => {
          this.privateIpList.push({
            ipAddressId: a.ipAddressId,
            ipAddress: a.ipAddress,
          });
        })
      );
      // プライベートIPのipAddress昇順にソートする、/32のみ表示
      this.privateIpList = this.privateIpList
        .filter((e) => e.ipAddress.endsWith("/32"))
        .sortBy([(e) => e.ipAddress, "asc"]);
    },
    /**
     * IPマッピングの追加・変更を行う
     */
    async onSubmit() {
      // propsから受け取るデータの有無で更新・追加処理をハンドリング
      if (this.ipMapping) {
        await this.$confirm("IPマッピングを変更します。よろしいですか？");
        await this.$api.ipMapping.putType4IpMapping(
          this.type4Id,
          this.ipMappingInfo.virtualIpAddressSeq,
          {
            description: this.ipMappingInfo.description || null,
            ipAddressId: this.ipMappingInfo.ipAddressId,
            ipMappingKeyId: this.ipMappingKeyId,
            note: null,
          }
        );
      } else {
        await this.$confirm("IPマッピングを追加します。よろしいですか？");
        await this.$api.ipMapping.postType4IpMapping(this.type4Id, {
          description: this.ipMappingInfo.description || null,
          ipMappingName: this.ipMappingInfo.ipMappingName,
          globalIpAddress: this.ipMappingInfo.globalIpAddress,
          ipAddressId: this.ipMappingInfo.ipAddressId,
          isPortForward: this.ipMappingInfo.isPortForward,
          protocol: this.ipMappingInfo.isPortForward
            ? this.ipMappingInfo.protocol
            : null,
          globalPort: this.ipMappingInfo.isPortForward
            ? this.ipMappingInfo.globalPort
            : null,
          privatePort: this.ipMappingInfo.isPortForward
            ? this.ipMappingInfo.privatePort
            : null,
          ipMappingKeyId: this.ipMappingKeyId,
          note: null,
        });
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { ApplianceInfo } from "@/apis/ContractApi";

export interface Type4InternetSite {
  /** 総件数 インターネットアドレスの総件数 */
  addressTotal: number;
  /** アドレスリスト IPアドレス名の辞書順 0件の場合は空配列 */
  addressList: Type4InternetAddress[];
  /** 総件数 インターネットFQDNの総件数 */
  fqdnTotal: number;
  /** FQDNリスト FQDN名の辞書順 0件の場合は空配列 */
  fqdnList: Type4InternetFQDN[];
  /** サイト一覧(Type4)排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  type4SiteKeyId: string;
}

/**
 * 変更を行う際のパターンは以下の通りとする。
 * (1) アドレス情報の追加、変更、削除をする場合：変更しない設定を含め、設定更新後のaddressListを指定する。
 * アドレス追加時はipAddressIdは非必須となり、アドレスや名称変更時はipAddressIdは必須となる。
 * アドレス削除時はaddressListに含めない。
 * (2) FQDN情報の追加、変更、削除をする場合：変更しない設定を含め、設定更新後のfqdnListを指定する。
 * FQDN追加時はfqdnIdは非必須となり、FQDNや名称変更時はfqdnIdは必須となる。
 * FQDN削除時はfqdnListに含めない。*/
export interface PostType4InternetSite {
  /** アドレスリスト IPアドレス名の辞書順 0件の場合は空配列 */
  addressList: {
    /** IPアドレスID アドレスオブジェクトの主キー "obj"+"数値"の形式 */
    ipAddressId: string | null;
    /** IPアドレス名 */
    ipAddressName: string | null;
    /** IPアドレス ネットワークアドレスを入力 */
    ipAddress: string;
  }[];
  /** FQDNリスト. FQDN名の辞書順 0件の場合は空配列 */
  fqdnList: {
    /** FQDN ID. アドレスオブジェクトの主キー "obj"+"数値"の形式 */
    fqdnId: string | null;
    /** FQDN名 */
    fqdnName: string | null;
    /** FQDN */
    fqdn: string;
  }[];
  /** サイト一覧(Type4)排他情報. 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  type4SiteKeyId: string;
}

/** アドレスリスト */
export interface Type4InternetAddress {
  /** IPアドレスID アドレスオブジェクトの主キー "obj"+"数値"の形式 */
  ipAddressId: string;
  /** IPアドレス名 */
  ipAddressName: string | null;
  /** IPアドレス ネットワークアドレスを入力 */
  ipAddress: string;
  /** ポリシー使用フラグ ポリシーで使用されている場合はtrue */
  isPolicyInUse: boolean;
}

/** FQDNリスト */
export interface Type4InternetFQDN {
  /** FQDN ID アドレスオブジェクトの主キー "obj"+"数値"の形式 */
  fqdnId: string;
  /** FQDN名 */
  fqdnName: string | null;
  /** FQDN */
  fqdn: string;
  /** ポリシー使用フラグ ポリシーで使用されている場合はtrue */
  isPolicyInUse: boolean;
}

/** Type4 エクストラサイト情報 */
export interface Type4ExtraSite {
  /** 総件数. 一覧表示対象の総件数 */
  siteTotal: number;
  /** サイトリスト. サイト名の辞書順 0件の場合は空配列 */
  siteList: Type4ExtraSiteList[];
  /** サイト一覧(Type4)排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  type4SiteKeyId: string;
}

export interface Type4ExtraSiteList {
  /** サイトID. サイトオブジェクトの主キー "obj"+"数値"の形式 */
  siteId: string;
  /** サイト名. */
  siteName: string;
  /** 総件数. 一覧表示対象の総件数 */
  addressTotal: number;
  /** アドレスリスト. IPアドレス名の辞書順 0件の場合は空配列 */
  addressList: Type4InternetAddress[];
}

/** エクストラサイト一覧表示用 */
export interface Type4ExtraList {
  /** サイト名 */
  siteName: string;
  /** IPアドレスID. アドレスオブジェクトの主キー "obj"+"数値"の形式*/
  ipAddressId: string;
  /** IPアドレス名 */
  ipAddressName: string | null;
  /** IPアドレス. ネットワークアドレスを入力 */
  ipAddress: string | null;
  /** ポリシー使用フラグ ポリシーで使用されている場合はtrue */
  isPolicyInUse: null | boolean;
}

/** エクストラサイト追加・変更表示用 */
export interface Type4SiteEdit {
  /**
   * サイトID
   * サイトオブジェクトの主キー ・"obj"+"数値"の形式
   */
  siteId: string;
  /** サイト名 */
  siteName: string;
  /**
   * アドレスリスト
   * 0件の場合は空配列
   */
  addressList: Type4SiteEditAddressList[];
}

/** エクストラサイト追加・変更表示用アドレスリスト */
export interface Type4SiteEditAddressList {
  /** IPアドレスID アドレスオブジェクトの主キー "obj"+"数値"の形式 */
  ipAddressId: string | null;
  /** IPアドレス名 */
  ipAddressName: string | null;
  /** IPアドレス ネットワークアドレスを入力 */
  ipAddress: string;
  /** ポリシー使用フラグ ポリシーで使用されている場合はtrue */
  isPolicyInUse: boolean;
}

/** Type4エクストラサイト設定情報を追加用 */
export interface PostType4ExtraSite {
  /** サイト名 */
  siteName: string;
  /** サイト一覧(Type4)排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  type4SiteKeyId: string;
}

export interface ResultPostType4ExtraSite {
  /** サイトID. サイトオブジェクトの主キー "obj"+"数値"の形式 */
  siteId: string;
  /** サイト一覧(Type4)排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  type4SiteKeyId: string;
}

/**
 * エクストラサイトアドレスの変更情報
 * 変更を行う際のパターンは以下の通りとする。
 * (1) サイト名を変更する場合：siteIdとsiteNameを指定する。アドレスが紐づいている場合はaddressListも指定する。
 * (2) アドレスの追加、変更、削除をする場合：変更しない設定を含め、設定更新後のaddressListを指定する。
 * アドレス追加時はipAddressIdは非必須となり、アドレスや名称変更時はipAddressIdは必須となる。
 * アドレス削除時はaddressListに含めない。
 */
export interface PostType4ExtraSiteAddress {
  /** サイト名. */
  siteName: string;
  /** アドレスリスト 0件の場合は空配列 */
  addressList: {
    /** IPアドレスID. アドレスオブジェクトの主キー "obj"+"数値"の形式*/
    ipAddressId: string | null;
    /** IPアドレス名 */
    ipAddressName: string | null;
    /** IPアドレス. ネットワークアドレスを入力 */
    ipAddress: string;
  }[];
  /** サイト一覧(Type4)排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  type4SiteKeyId: string;
}

/** siteIdを指定し、サイトを削除する。 サイトへ紐づくアドレスが残存していた場合、エラーが発生する。 */
export interface DeleteType4ExtraSite {
  /** IPアドレスID. アドレスオブジェクトの主キー "obj"+"数値"の形式*/
  siteList: string[];
  /** サイト一覧(Type4)排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  type4SiteKeyId: string;
}

export default class Type4SiteApi extends AbstractBaseApi {
  /**
   * Type4インターネットサイト情報を取得
   * @param type4Id type4Id
   */
  async getType4InternetSite(
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<Type4InternetSite> {
    return (await this.api.get(`/v1/network/sites/internet/type4/${type4Id}`))
      .data;
  }

  /**
   * Type4インターネットサイト設定情報を一括更新（追加/変更/削除）
   * @param type4Id type4Id
   * @param data インターネットサイト更新情報
   */
  async postType4InternetSite(
    type4Id: ApplianceInfo["type4Id"],
    data: PostType4InternetSite
  ): Promise<void> {
    await this.api.post(`/v1/network/site/internet/type4/${type4Id}`, data);
  }

  /**
   * Type4エクストラサイト情報を取得
   * @param type4Id type4Id
   */
  async getType4ExtraSite(
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<Type4ExtraSite> {
    return (await this.api.get(`/v1/network/sites/extra/type4/${type4Id}`))
      .data;
  }

  /**
   * Type4エクストラサイト設定情報を追加
   * @param type4Id type4Id
   * @param postData type4エクストラサイト追加情報
   */
  async postType4ExtraSiteRegister(
    type4Id: ApplianceInfo["type4Id"],
    postData: PostType4ExtraSite
  ): Promise<ResultPostType4ExtraSite> {
    return (
      await this.api.post(`/v1/network/site/extra/type4/${type4Id}`, postData)
    ).data;
  }

  /**
   * Type4エクストラサイト設定情報を変更
   * @param siteId siteId
   * @param type4Id type4Id
   * @param data type4エクストラサイト更新情報
   */
  async postType4ExtraSiteModify(
    siteId: string,
    type4Id: ApplianceInfo["type4Id"],
    data: PostType4ExtraSiteAddress
  ): Promise<void> {
    await this.api.post(
      `/v1/network/site/${siteId}/extra/type4/${type4Id}`,
      data
    );
  }

  /**
   * Type4エクストラサイト設定情報を削除
   * @param type4Id type4Id
   * @param data type4エクストラサイト削除情報
   */
  async postType4ExtraSiteDelete(
    type4Id: ApplianceInfo["type4Id"],
    data: DeleteType4ExtraSite
  ): Promise<void> {
    await this.api.post(
      `/v1/network/sites/extra/type4/${type4Id}/delete`,
      data
    );
  }
}

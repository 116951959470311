












































































import Vue, { PropType } from "vue";
import { PostOnetime } from "@/apis/PasswordApi";

export default Vue.extend({
  name: "OneTimeUrlIssue",
  props: {
    /** true: パスワード有効期限切れの場合（メッセージを画面に表示） */
    expired: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** ログインIDの入力初期値 */
    loginId: {
      type: String as PropType<string>,
      default: "",
    },
  },
  data() {
    return {
      form: {
        loginId: this.loginId,
        email: "",
        oneTimeUrl: process.env.VUE_APP_ONETIME_URL,
      } as PostOnetime,
    };
  },
  methods: {
    async submit() {
      await this.$api.password.postOnetime(this.form);
      await this.$router.push("/password/one-time-url/complete");
    },
  },
});

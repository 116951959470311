var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"イントラネットFW プラットフォームゲートウェイ WVS2/KCPS アドレス設定","classes":"modal-large-plus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('cross-validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[(_vm.isLoaded)?_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"row"},[_c('b-card',{staticClass:"col-6 border-0",attrs:{"border-variant":"light"}},[_c('h5',{staticClass:"card-title"},[_vm._v("WVS2ゾーン")]),_c('b-card-body',[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"WVS2アドレス","msg":_vm._f("msg")('info_cidr_ip_address_of_network_address',{
                            ipaddress1: '192.168.0.0/24',
                            genre: 'WVS2アドレス',
                            subnet1: '1',
                            subnet2: '30',
                            ipaddress2: '192.168.0.1/32',
                          }),"placement":"topright","is-info":_vm.changeable,"is-required":_vm.changeable}})]},proxy:true}],null,true)},_vm._l((_vm.wvs2AddressList),function(e,index){return _c('div',{key:index,staticClass:"row align-items-center mt-2"},[_c('validation-provider',{staticClass:"col",attrs:{"vid":("wvs2_" + index),"name":"アドレス","rules":{
                        required_if: _vm.wvs2AddressList.length > 1,
                        is_ip_subnet: { min: 1, max: 32, exclude: [31] },
                        is_not_current_ip: true,
                        is_not_loopback_ip: true,
                        is_not_multicast_ip: true,
                        is_not_class_e_ip: true,
                        is_not_broadcast_ip: true,
                        is_network_address: true,
                        duplicate: { list: _vm.existswvs2Address(index) },
                      }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"placeholder":!_vm.changeable
                            ? null
                            : _vm.$msg(
                                'placeholder_ip_address_of_private_network_address'
                              ),"state":_vm._f("validState")(validContext),"disabled":!_vm.changeable},model:{value:(_vm.wvs2AddressList[index]),callback:function ($$v) {_vm.$set(_vm.wvs2AddressList, index, $$v)},expression:"wvs2AddressList[index]"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}),_c('div',{staticClass:"col-auto"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.removeWvs2(e)}}},[_c('b-icon-dash')],1)],1)],1)}),0),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-end"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.addWvs2}},[_c('b-icon-plus')],1)],1)])],1)],1),_c('b-card',{staticClass:"col-6 border-0",attrs:{"border-variant":"light"}},[_c('h5',{staticClass:"card-title"},[_vm._v("KCPSゾーン")]),_c('b-card-body',[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"KCPSアドレス","msg":_vm._f("msg")('info_cidr_ip_address_of_network_address',{
                            ipaddress1: '192.168.0.0/24',
                            genre: 'KCPSアドレス',
                            subnet1: '1',
                            subnet2: '30',
                            ipaddress2: '192.168.0.1/32',
                          }),"placement":"topright","is-info":_vm.changeable,"is-required":_vm.changeable}})]},proxy:true}],null,true)},_vm._l((_vm.kcpsAddressList),function(e,index){return _c('div',{key:index,staticClass:"row align-items-center mt-2"},[_c('validation-provider',{staticClass:"col",attrs:{"vid":("kcps_" + index),"name":"アドレス","rules":{
                        required_if: _vm.kcpsAddressList.length > 1,
                        is_ip_subnet: { min: 1, max: 32, exclude: [31] },
                        is_not_current_ip: true,
                        is_not_loopback_ip: true,
                        is_not_multicast_ip: true,
                        is_not_class_e_ip: true,
                        is_not_broadcast_ip: true,
                        is_network_address: true,
                        duplicate: { list: _vm.existskcpsAddress(index) },
                      }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"placeholder":!_vm.changeable
                            ? null
                            : _vm.$msg(
                                'placeholder_ip_address_of_private_network_address'
                              ),"state":_vm._f("validState")(validContext),"disabled":!_vm.changeable},model:{value:(_vm.kcpsAddressList[index]),callback:function ($$v) {_vm.$set(_vm.kcpsAddressList, index, $$v)},expression:"kcpsAddressList[index]"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}),_c('div',{staticClass:"col-auto"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.removeKcps(e)}}},[_c('b-icon-dash')],1)],1)],1)}),0),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-end"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.addKcps}},[_c('b-icon-plus')],1)],1)])],1)],1)],1),_c('cross-validation-provider',{staticClass:"mt-2",attrs:{"passed":_vm.isChanged,"vid":"changed"}},[_vm._v(_vm._s(_vm._f("msg")("no_change_data")))])],1):_vm._e(),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v("設定")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-medium","title":"通知先設定"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("回線情報")]),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"回線番号"}},[_c('span',{staticClass:"content"},[(_vm.line.wnumberMainAct)?[_vm._v(" "+_vm._s(_vm.line.wnumberMainAct)+" ")]:[_vm._v(" "+_vm._s(_vm.line.enumberMainAct)+" ")]],2)])],1),_c('h5',{staticClass:"font-weight-bold"},[_vm._v("通知設定")]),_c('validation-provider',{attrs:{"name":"通知閾値帯域(受信)","rules":{
              numeric: true,
              between: { min: 0, max: 10000 },
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"通知閾値帯域(受信)","msg":_vm._f("msg")('info_num_max',{ max: 10000 }),"isRequired":false}})]},proxy:true}],null,true)},[_c('b-form-input-number',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.formReceiveThreshold),callback:function ($$v) {_vm.formReceiveThreshold=_vm._n($$v)},expression:"formReceiveThreshold"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"通知閾値帯域(送信)","rules":{
              numeric: true,
              between: { min: 0, max: 10000 },
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"通知閾値帯域(送信)","msg":_vm._f("msg")('info_num_max',{ max: 10000 }),"isRequired":false}})]},proxy:true}],null,true)},[_c('b-form-input-number',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.formSendThreshold),callback:function ($$v) {_vm.formSendThreshold=_vm._n($$v)},expression:"formSendThreshold"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"通知先メールアドレス","msg":_vm._f("msg")('info_mail'),"isRequired":false}})]},proxy:true}],null,true)},[_vm._l((_vm.form.mailAddressList),function(_,index){return _c('div',{key:index},[_c('validation-provider',{staticClass:"row align-items-center mb-2",attrs:{"vid":("mail_" + index),"name":"通知先メールアドレス","rules":{
                  required: !_vm.isDisabledEmail,
                  is_email: true,
                  max: 127,
                  duplicate: { list: _vm.emailList(index) },
                }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('div',{staticClass:"col mr-6"},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext),"disabled":_vm.isDisabledEmail},model:{value:(_vm.form.mailAddressList[index]),callback:function ($$v) {_vm.$set(_vm.form.mailAddressList, index, $$v)},expression:"form.mailAddressList[index]"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1),_c('div',{staticClass:"mr-3 ml-2"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary","disabled":_vm.isDisabledEmail},on:{"click":function($event){return _vm.removeEmail(index)}}},[_c('b-icon-dash')],1)],1)]}}],null,true)})],1)}),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary","disabled":_vm.isDisabledEmail || _vm.form.mailAddressList.length >= 5},on:{"click":_vm.addEmail}},[_c('b-icon-plus')],1)],1)])],2)],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("設定")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }









































































import Vue, { PropType } from "vue";
import { ZoneRelation, ZoneRelationPost } from "@/apis/ZoneRelationApi";
import { Type1ExtraInfo, Type1PrivateInfo } from "@/apis/Type1SiteApi";
import { Type1ZoneOption } from "@/modals/applianceContractSetting/InternetType1ZoneRelationRegister.vue";

export default Vue.extend({
  name: "IntranetFwZoneRelationRegister",
  props: {
    zoneRelationKeyId: {
      type: String as PropType<ZoneRelation["zoneRelationKeyId"]>,
      required: true,
    },
    zoneRelation: {
      type: Object as PropType<ZoneRelation>,
      required: true,
    },
  },
  data() {
    return {
      // 送信元ゾーン
      srcZone: null as null | Type1ZoneOption,
      // 宛先ゾーン
      dstZone: null as null | Type1ZoneOption,
      // ゾーンオプション
      selectZoneOptions: [] as { value: Type1ZoneOption; text: string }[],
      // 通信完了フラグ
      isLoaded: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    async load() {
      // エクストラゾーン取得
      const extraZone: Type1ExtraInfo["zone"] = (
        await this.$api.type1SiteZone.getType1ExtraList()
      ).zone;
      // プライベートゾーン取得
      const privateZone: Type1PrivateInfo["zoneList"] = (
        await this.$api.type1SiteZone.getType1PrivateList()
      ).zoneList;

      if (privateZone.length > 0) {
        const zoneOptions = privateZone.map((e) => ({
          value: { ...e, type: "PRIVATE" } as Type1ZoneOption,
          text: "プライベートゾーン/" + e.zoneName,
        }));
        // オプションのソート
        this.selectZoneOptions = zoneOptions.sortBy([(e) => e.text, "asc"]);
      }

      if (extraZone !== null) {
        this.selectZoneOptions.unshift({
          value: { ...extraZone, type: "EXTRA" },
          text: "エクストラゾーン/" + extraZone.zoneName,
        });
      }
    },
    /** 追加ボタン押下時の挙動*/
    async submit() {
      await this.$confirm("ゾーン接続を追加します。よろしいですか？");
      const reqData: ZoneRelationPost = {
        srcZoneId: this.srcZone!.zoneId,
        dstZoneId: this.dstZone!.zoneId,
        zoneRelationKeyId: this.zoneRelationKeyId,
      };
      await this.$api.zoneRelation.postZoneRelation(reqData);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});



































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { HubSpokeInfo } from "@/apis/HubAndSpokeApi";

export default Vue.extend({
  name: "HubAndSpokeList",
  data() {
    return {
      hubAndSpoke: {
        fields: HubAndSpokeFields,
        items: [],
      } as Pick<AppTableData<HubSpokeInfo>, "fields" | "items">,
      // 初期通信完了フラグ
      isLoaded: false,
      // download用URL
      downloadUrl: this.$api.hubAndSpoke.getCsvHubAndSpokeURL(),
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.hubAndSpoke.items = await this.$api.hubAndSpoke.getHubAndSpoke();
    },
  },
});

// 列のデータ
const HubAndSpokeFields = [
  {
    key: "hubVNumber",
    label: "Hub側V番号",
  },
  {
    key: "hubVpnVnCode",
    label: "Hub側VPN/VNコード",
  },
  {
    key: "spokeVNumber",
    label: "Spoke側V番号",
  },
  {
    key: "spokeVpnVnCode",
    label: "Spoke側VPN/VNコード",
  },
  {
    key: "publicVpnCode",
    label: "広報先VPNコード",
  },
  {
    key: "hubSpokeStatus",
    label: "状態",
  },
  {
    key: "requestDateTime",
    label: "申請日時",
  },
  {
    key: "approvalDateTime",
    label: "承認日時",
  },
];

import {
  ApiBadGatewayError,
  ApiBadRequestError,
  ApiClientError,
  ApiConflictError,
  ApiForbiddenError,
  ApiInternalServerError,
  ApiMethodNotAllowedError,
  ApiNotFoundError,
  ApiRequestTimeoutError,
  ApiServerError,
  ApiServiceUnavailableError,
  ApiUnauthorizedError,
  NetworkError,
  SystemError,
} from "@/error/errors";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { RootState } from "@/store";
import { Store } from "vuex";
import UrlPattern from "url-pattern";
import PostBodyFormats from "./assets/post-body-formats.json";
import cloneDeep from "lodash/cloneDeep";

/**
 * 社内規約的にGET/POST以外のメソッドは使用してはダメなので該当するメソッドはPOSTに変換
 * オリジナルのメソッドはHTTPヘッダーに格納してバックが認識できるようにしておく
 */
export function interceptorMethodOverride(
  config: AxiosRequestConfig
): AxiosRequestConfig {
  const httpMethod = config.method?.toUpperCase();
  switch (httpMethod) {
    case "PUT":
    case "DELETE":
    case "PATCH":
      config.method = "POST";
      config.headers = {
        ...config.headers,
        "X-HTTP-Method-Override": httpMethod,
      };
      break;
    default:
      break;
  }
  return config;
}

/** 各種共通ヘッダー設定 */
export function interceptorSetHeaders(store: Store<RootState>) {
  return (config: AxiosRequestConfig): AxiosRequestConfig => {
    // ログイン済みの場合はAPI認証トークンをヘッダーにセット
    if (store.state.user.isLogged) {
      config.headers = {
        ...config.headers,
        "X-API-TOKEN": store.state.user.token,
      };
    }
    return config;
  };
}

/** APIリクエスト時に発生したエラーを該当のErrorに変換するInterceptor */
export function interceptorHandleError(error: AxiosError): unknown {
  if (process.env.NODE_ENV === "development") {
    console.warn("[DEV warn] interceptorHandleError", error.toJSON());
  }
  if (error.response) {
    switch (error.response.status) {
      case 400:
        throw new ApiBadRequestError(error);
      case 401:
        throw new ApiUnauthorizedError(error);
      case 403:
        throw new ApiForbiddenError(error);
      case 404:
        throw new ApiNotFoundError(error);
      case 405:
        throw new ApiMethodNotAllowedError(error);
      case 407:
        throw new NetworkError("Network Error");
      case 408:
      case 504:
        throw new ApiRequestTimeoutError(error);
      case 409:
        throw new ApiConflictError(error);
      case 500:
        throw new ApiInternalServerError(error);
      case 502:
        throw new ApiBadGatewayError(error);
      case 503:
        throw new ApiServiceUnavailableError(error);
      default:
        // 想定外のステータスコード
        if (400 <= error.response.status && error.response.status < 500) {
          throw new ApiClientError(error);
        } else if (error.response.status >= 500) {
          throw new ApiServerError(error);
        }
        throw new SystemError(error.message);
    }
  } else {
    // Chrome,Edgeはプロキシ認証エラーの場合、エラーコード「407」ではなくundefinedが返却され、エラーメッセージ「Network Error」が返却される
    if (error.message === "Network Error") {
      throw new NetworkError(error.message);
    }
  }
  throw error;
}

/** ローディング表示用にStateに通信中のリクエスト情報を追加/削除する */
export const interceptorLoading = {
  request(store: Store<RootState>) {
    return async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      // ローディング表示無効化が指定されている場合はStateに追加しない
      if (config.headers["x-ignore-loading"]) {
        delete config.headers["x-ignore-loading"];
        return config;
      }
      await store.dispatch(
        "meta/addRunningRequests",
        `${config.method?.toUpperCase()} ${config.url}`
      );
      return config;
    };
  },
  response(store: Store<RootState>) {
    return async (response: AxiosResponse): Promise<AxiosResponse> => {
      await store.dispatch(
        "meta/removeRunningRequests",
        `${response.config.method?.toUpperCase()} ${response.config.url}`
      );
      return response;
    };
  },
  responseError(store: Store<RootState>) {
    return async (error: AxiosError): Promise<AxiosError> => {
      await store.dispatch(
        "meta/removeRunningRequests",
        `${error.config.method?.toUpperCase()} ${error.config.url}`
      );
      throw error;
    };
  },
};

/** ローディング表示を無効化するリクエストコンフィグ */
export const IgnoreLoading: AxiosRequestConfig = {
  headers: {
    "x-ignore-loading": true,
  },
};

/** プロビジョニングステータスへAPIレスポンスを通知 */
export function interceptorNotifyProvisioning(store: Store<RootState>) {
  return (response: AxiosResponse): AxiosResponse => {
    store.dispatch("provisioning/notifyApiResponse", response);
    return response;
  };
}

/**
 * API仕様書に定義されていないプロパティをリクエスト時に除去
 * 定義ファイルを元に除去処理を行っている定義ファイルの出力に関してはsystem-design側を参照
 */
export function interceptorPostBodyClean(
  config: AxiosRequestConfig
): AxiosRequestConfig {
  // 対象はPOSTのみ
  if (config.method?.toUpperCase() !== "POST") {
    return config;
  }

  // 先頭に「/」がない場合もあるので確実に付与
  const url = config.url!.startsWith("/") ? config.url! : `/${config.url!}`;

  // リクエストボディの形式を定義ファイルから特定
  const format = PostBodyFormats.find((e) => new UrlPattern(e.url).match(url));
  if (!format) {
    // ここに入るのはおかしい。考えられる原因は以下
    // 1. 定義ファイルが古い → 最新のAPI仕様書から生成する
    // 2. 実装側のURL誤り → 実装バグ
    // 3. URLのマッチング処理にバグ → 有識者連絡
    // 4. 定義ファイルの生成処理にバグ → 有識者連絡
    if (process.env.NODE_ENV === "development") {
      console.error("undefined url", url);
      window.alert(
        "開発モード時のみの表示です\nAPIの定義ファイルに存在しないURLです。要確認"
      );
    } else {
      console.warn("undefined url", url);
    }
    return config;
  }
  if (format.requestBody === null) {
    // リクエストボディが無いパターンは何もしない
    return config;
  }

  // デバッグ用。
  // console.log(url, format, config.data);
  return {
    ...config,
    data: deleteUndefinedProperty(cloneDeep(config.data), format.requestBody),
  };
}

/**
 * リクエストトボディから未定義のプロパティを削除したオブジェクトを返す
 * @param request リクエストボディ
 * @param format プロパティ定義情報
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export function deleteUndefinedProperty(request: unknown, format: any): any {
  if (Array.isArray(request)) {
    // 配列内を全て再帰処理
    return request.map((e) => deleteUndefinedProperty(e, format[0]));
  } else if (request === null) {
    return request;
  } else if (typeof request === "object") {
    // オブジェクトの中を確認して定義に存在しないキーは削除
    const formatKeys = new Set(Object.keys(format));
    return Object.entries(request!).reduce((acc, e) => {
      const [key, value] = e;
      if (formatKeys.has(key)) {
        if (Array.isArray(value) || typeof value === "object") {
          // 値が配列やオブジェクトの場合は再帰処理
          return { ...acc, [key]: deleteUndefinedProperty(value, format[key]) };
        } else {
          return { ...acc, [key]: value };
        }
      } else {
        if (process.env.NODE_ENV === "development") {
          console.warn(`[deleteUndefinedProperty] key: ${key}`);
        }
        // 存在しないキーなので削除
        return acc;
      }
    }, {});
  } else {
    return request;
  }
}
































import Vue, { PropType } from "vue";
import Multiselect from "vue-multiselect";

/**
 * Vue-multiselectのラッパーコンポーネント
 * Bootstrap-vueやVeeValidateの兼ね合いで色々調整しないといけないので
 */
export default Vue.extend({
  name: "AppMultiselect",
  components: { Multiselect },
  props: {
    /** 選択肢 */
    options: {
      type: Array as PropType<unknown[]>,
      required: true,
    },
    /** プルダウンの展開方向(デフォルトはbottom)
     * "auto": 自動
     * top：上へ展開
     * bottom: 下へ展開
     * */
    openDirection: {
      type: String as PropType<"auto" | "top" | "bottom">,
      required: false,
      default: "bottom",
    },
    /** vee-validateのバリデーションState */
    state: {
      type: Boolean as PropType<boolean | undefined | unknown>,
      required: false,
      default: undefined,
    },
    /**
     * searchの際にdefaultだとlabel部分しか検索対象にならないので
     * 自由にカスタマイズ出来るためのfunction
     * @see https://github.com/shentao/vue-multiselect/issues/244
     * 文字列で検索対象のパスを渡せば使う側は楽だけどコンパイラーが検知出来なくなるので悩みどころ
     */
    filter: {
      type: [Function] as PropType<
        undefined | ((query: string, options: unknown[]) => unknown[])
      >,
      required: false,
      default: undefined,
    },
    /**
     * 配列のソートが必要な場合のみ設定する
     */
    sortOrders: {
      type: [] as PropType<
        [string, "asc" | "desc", { nullLast?: boolean }][] | undefined
      >,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      /** 検索文字列 */
      query: "",
    };
  },
  /**
   * 画面初期表示に配列のソートを実施する。
   * ※初期表示のみでその後変更があってもソートしない。
   */
  created() {
    if (
      this.$attrs.value &&
      this.sortOrders &&
      Array.isArray(this.$attrs.value)
    ) {
      // 追加の場合には配列0件、変更でも1件の場合は、ソート不要
      if (this.$attrs.value.length >= 2) {
        this.$attrs.value.sort((a, b) => {
          return this.$service.sortCompare(a, b, this.sortOrders!);
        });
      }
    }
  },
  computed: {
    /**
     * vue-multiselectのpropsにデフォルト値を設定. 外部指定を優先
     * @see https://vue-multiselect.js.org/#sub-props
     */
    attrsOnDefault(): { [key: string]: unknown } {
      return {
        placeholder: "",
        // 単一選択: 選択されたらドロップダウンを閉じる, 複数選択: 選択されてもドロップダウンを閉じない
        closeOnSelect: (this.$attrs.multiple as unknown) !== true,
        selectLabel: "選択",
        selectedLabel: "選択済み",
        deselectLabel: "選択解除",
        ...this.$attrs,
      };
    },

    /** フィルタリング済みのoptionを返却 */
    filteredOptions(): unknown[] {
      if (this.filter && this.query) {
        return this.filter(this.query, this.options);
      } else {
        return this.options;
      }
    },
  },
  methods: {
    /** 検索条件変更 */
    searchChange(query: string): void {
      if (this.filter !== undefined) {
        this.query = query;
      }
    },
  },
});






























import Vue, { PropType } from "vue";
import { ApiRequestError } from "@/error/errors";

export default Vue.extend({
  name: "UnauthorizedErrorModal",
  props: {
    /** エラー */
    error: {
      type: ApiRequestError as PropType<ApiRequestError>,
      required: true,
    },
  },
  computed: {
    /** true: 現在ログインページ, false: ログインページではない */
    isLoginPage(): boolean {
      return this.$route.path === "/login";
    },
  },
  methods: {
    async ok() {
      (this.$refs.modal as Vue & { ok: () => void }).ok();
      await this.$store.dispatch("user/logout");
      await this.$router.push({ path: "/login" });
    },
  },
});

















































































































































import Vue, { PropType } from "vue";
import {
  CpaContract,
  CpaNwDetail,
  CpaNwOptions,
  RadiusSetting,
  UpdateCpaNw,
} from "@/apis/Cpa5gSaApi";
import SaNwEditStepper1 from "@/modals/cpa5g/SaNwEditStepper1.vue";
import SaNwEditStepper2 from "@/modals/cpa5g/SaNwEditStepper2.vue";
import SaNwEditStepper3 from "@/modals/cpa5g/SaNwEditStepper3.vue";
import cloneDeep from "lodash/cloneDeep";
import { UploadError } from "@/services/ConfigDownloadService";
import { NonNullableProperty } from "@/shims-vue";

export default Vue.extend({
  name: "SaNwEdit",
  components: { SaNwEditStepper1, SaNwEditStepper2, SaNwEditStepper3 },
  props: {
    /** CPA5G契約情報 */
    cpaContract: {
      type: Object as PropType<CpaContract>,
      required: true,
    },
    /** CPA5G NW設定情報 */
    cpaNw: {
      type: Object as PropType<CpaNwDetail>,
      required: true,
    },
    /** CPA5G特殊項目設定情報 */
    cpaNwOption: {
      type: Object as PropType<CpaNwOptions>,
      required: true,
    },
  },
  data() {
    return {
      cpaNwSetting: cloneDeep(this.cpaNw) as CpaNwDetail,
      active: 1,
      uploadAlert: null as string | null,
    };
  },
  computed: {
    /**
     * ステップ3「その他項目」の無効フラグ
     * true: 非表示 false: 表示
     */
    isStep3Disable(): boolean {
      return (
        (this.cpaContract.contractType === "CPA_NO_LIMIT" ||
          this.cpaContract.contractType === "CPA_LIMIT_AUDIO") &&
        (this.cpaContract.authenticationType === "RADIUS" ||
          this.cpaContract.authenticationType === "RADIUS_IMEI") &&
        !this.cpaNwOption.wrapCommunication &&
        !this.cpaNwOption.ftpSendSetting &&
        !this.cpaNwOption.cusconClientIp &&
        !this.cpaNwOption.alwaysOn &&
        !this.cpaNwOption.longTimeCallDisconnectTimer
      );
    },
    /** ステッパータイトル一覧 */
    stepperTitles(): string[] {
      return this.isStep3Disable
        ? ["NW設定", "連絡先情報", "設定確認"]
        : ["NW設定", "連絡先情報", "その他項目", "設定確認"];
    },
  },
  methods: {
    /**
     * ステッパー3で「次へ」を押下した時の処理
     * @param next 共通ステッパーの次へ
     */
    async handleAction(next: () => void) {
      if (this.isStep3Disable) {
        await this.postCpaNw();
      } else {
        next();
      }
    },
    /**
     * ステッパー1で「次へ」を押下した時の処理
     * 設定アップロードで発生したエラーメッセージを非表示にする
     * @param next 共通ステッパーの次へ
     */
    async handleActionFromStep1(next: () => void) {
      this.uploadAlert = null;
      next();
    },
    /** 設定ダウンロード */
    downloadConfig() {
      this.$service.configDownload.downloadCpaNwSetting(
        this.$store.state.user.vnumber,
        this.cpaContract,
        this.cpaNwSetting
      );
    },
    /** 設定アップロード */
    async uploadConfig() {
      try {
        const uploadConfig =
          await this.$service.configDownload.uploadCpaNwSetting(
            this.$store.state.user.vnumber,
            this.cpaContract,
            this.cpaNwSetting
          );
        this.uploadAlert = null;
        await this.$confirm("CPA 5G SA NW設定を上書きします。よろしいですか？");
        this.cpaNwSetting = uploadConfig;

        if (this.cpaContract.ipAddressType !== "IPV6") {
          this.cpaNwSetting.ueIpAllocationIpv4List =
            this.cpaNwSetting.ueIpAllocationIpv4List &&
            this.cpaNwSetting.ueIpAllocationIpv4List.length > 0
              ? this.cpaNwSetting.ueIpAllocationIpv4List
              : [""];
        }
        if (this.cpaContract.ipAddressType !== "IPV4") {
          this.cpaNwSetting.ueIpAllocationIpv6List =
            this.cpaNwSetting.ueIpAllocationIpv6List &&
            this.cpaNwSetting.ueIpAllocationIpv6List.length > 0
              ? this.cpaNwSetting.ueIpAllocationIpv6List
              : [""];
        }
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },
    /** CPA NW設定の登録・更新処理 */
    async postCpaNw() {
      // APIに設定するデータを作成
      const cpaNwPostData: UpdateCpaNw = createCpaNwPostObj(this.cpaNwSetting);

      // 端末割当アドレス(IPv4)は契約タイプがIPv6の場合はnullを設定
      cpaNwPostData.ueIpAllocationIpv4List =
        this.cpaContract.ipAddressType === "IPV6"
          ? null
          : this.cpaNwSetting.ueIpAllocationIpv4List;

      // 端末割当アドレス(IPv6)は契約タイプがIPv4の場合はnullを設定
      cpaNwPostData.ueIpAllocationIpv6List =
        this.cpaContract.ipAddressType === "IPV4"
          ? null
          : this.cpaNwSetting.ueIpAllocationIpv6List;

      if (this.cpaContract.ipAddressType === "IPV4") {
        // 契約タイプがIPv4の場合はNW設定のIPv6項目をnullにする
        cpaNwPostData.networkIpSettingAct = {
          ...cpaNwPostData.networkIpSettingAct,
          wanIpv6Pe: null,
          wanIpv6Gw: null,
          dnsIpv6: null,
        };
        cpaNwPostData.networkIpSettingSby = {
          ...cpaNwPostData.networkIpSettingSby,
          wanIpv6Pe: null,
          wanIpv6Gw: null,
          dnsIpv6: null,
        };
      } else if (this.cpaContract.ipAddressType === "IPV6") {
        // 契約タイプがIPv6の場合はNW設定のIPv4項目をnullにする
        cpaNwPostData.networkIpSettingAct = {
          ...cpaNwPostData.networkIpSettingAct,
          wanIpv4Pe: null,
          wanIpv4Gw: null,
          dnsIpv4: null,
        };
        cpaNwPostData.networkIpSettingSby = {
          ...cpaNwPostData.networkIpSettingSby,
          wanIpv4Pe: null,
          wanIpv4Gw: null,
          dnsIpv4: null,
        };
      }

      // 契約タイプがIPv6の場合はテストアカウントをnullにする
      if (
        cpaNwPostData.radiusSetting &&
        this.cpaContract.ipAddressType === "IPV6"
      ) {
        cpaNwPostData.radiusSetting.testAccount = null;
      }

      // 帯域タイプがベストエフォートの場合、bandwidthをnullで設定
      cpaNwPostData.bandwidth =
        this.cpaContract.bandwidthType === "BEST_EFFORT"
          ? null
          : cpaNwPostData.bandwidth;

      // 保守連絡先2の情報を設定 全て値なしの場合はnullを設定する
      cpaNwPostData.maintenanceContact2 = Object.values(
        this.cpaNwSetting.maintenanceContact2!
      ).every((v) => !v)
        ? null
        : this.cpaNwSetting.maintenanceContact2;

      // 案件コード設定可能(nullでない)かつ、初期が空配列の場合
      if (this.cpaNw.dealCodeList && this.cpaNw.dealCodeList.length === 0) {
        // 初期と変わらず空配列の場合、追加無しなのでnullを設定
        cpaNwPostData.dealCodeAddList =
          this.cpaNwSetting.dealCodeList!.length === 0
            ? null
            : this.cpaNwSetting.dealCodeList;
      } else if (
        this.cpaNw.dealCodeList &&
        this.cpaNw.dealCodeList.length > 0
      ) {
        // 設定可能かつ案件コードが1件以上の場合は、編集前と編集後の差分を確認して追加と削除リストを作成 0件の場合はnull
        const addList = this.cpaNwSetting.dealCodeList!.filter(
          (v) => !this.cpaNw.dealCodeList!.includes(v)
        );
        cpaNwPostData.dealCodeAddList = addList.length === 0 ? null : addList;
        const delList = this.cpaNw.dealCodeList.filter(
          (v) => !this.cpaNwSetting.dealCodeList!.includes(v)
        );
        cpaNwPostData.dealCodeDeleteList =
          delList.length === 0 ? null : delList;
      }

      // NW設定の登録・更新API呼び出し
      await this.$api.cpa5gSa.updateCpaNw(
        this.cpaContract.cpaContractSeq,
        cpaNwPostData
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
  beforeMount() {
    /* 新規の場合にプロパティが参照出来ずエラーが発生するため、初期化処理を行う */
    // 帯域値が0の場合はbandwidthごとnullで初期化しておく(必須チェックを通過しないようにするため)
    if (
      this.cpaNwSetting.bandwidth &&
      this.cpaNwSetting.bandwidth.value === 0
    ) {
      this.cpaNwSetting.bandwidth = null;
    }
    // グローバルアドレス利用がnullの場合は、デフォルト値を「無」にするためにfalseを代入する
    this.cpaNwSetting.isGlobalIpUse = this.cpaNwSetting.isGlobalIpUse ?? false;

    // 端末割当アドレス(IPv4)がnullの場合は、空文字が入った配列を初期値とする
    this.cpaNwSetting.ueIpAllocationIpv4List = this.cpaNwSetting
      .ueIpAllocationIpv4List ?? [""];
    // 端末割当アドレス(IPv6)がnullの場合は、空文字が入った配列を初期値とする
    this.cpaNwSetting.ueIpAllocationIpv6List = this.cpaNwSetting
      .ueIpAllocationIpv6List ?? [""];

    // NW設定(ACT)がnullの場合は、全プロパティをnullで初期化したオブジェクトを設定
    this.cpaNwSetting.networkIpSettingAct = this.cpaNwSetting
      .networkIpSettingAct ?? {
      wanIpv4Pe: null,
      wanIpv4Gw: null,
      dnsIpv4: null,
      wanIpv6Pe: null,
      wanIpv6Gw: null,
      dnsIpv6: null,
    };
    // NW設定(SBY)がnullの場合は、全プロパティをnullで初期化したオブジェクトを設定
    this.cpaNwSetting.networkIpSettingSby = this.cpaNwSetting
      .networkIpSettingSby ?? {
      wanIpv4Pe: null,
      wanIpv4Gw: null,
      dnsIpv4: null,
      wanIpv6Pe: null,
      wanIpv6Gw: null,
      dnsIpv6: null,
    };

    // 保守連絡先がnullの場合は、全プロパティを空文字で初期化したオブジェクトを設定
    this.cpaNwSetting.maintenanceContact1 = this.cpaNwSetting
      .maintenanceContact1 ?? { company: "", name: "", phone: "", email: "" };
    this.cpaNwSetting.maintenanceContact2 = this.cpaNwSetting
      .maintenanceContact2 ?? { company: "", name: "", phone: "", email: "" };

    // RADIUS項目がnullの場合は、プロパティをデフォルト値、null、空文字で初期化しておく
    // 設定可能(認証がHomeRADIUS認証、端末認証(Home))な場合のみ
    if (
      this.cpaContract.authenticationType === "HOMERADIUS" ||
      this.cpaContract.authenticationType === "HOME"
    ) {
      this.cpaNwSetting.radiusSetting = this.cpaNwSetting.radiusSetting ?? {
        ipAddressPrimary: null,
        ipAddressSecondary: null,
        callingStationIdTransfer: null,
        isImeiTransfer: null,
        secretKey: null,
        isDomainInfoSending: true,
        isBillingPacketSending: true,
        udpPort: "PORT_1645_1646",
        testAccount: { testUserId: null, testPassword: null, testIp: null },
      };

      // RADIUS項目はあるが、テストアカウントがnullの場合は以下の内容で初期化
      if (
        this.cpaNwSetting.radiusSetting &&
        !this.cpaNwSetting.radiusSetting.testAccount
      ) {
        this.cpaNwSetting.radiusSetting.testAccount = {
          testUserId: null,
          testPassword: null,
          testIp: null,
        };
      }

      // ドメイン情報送出がnullの場合以下の内容で初期化
      if (this.cpaNwSetting.radiusSetting.isDomainInfoSending == null) {
        this.cpaNwSetting.radiusSetting.isDomainInfoSending = true;
      }
      // 課金パケット送出がnullの場合以下の内容で初期化
      if (this.cpaNwSetting.radiusSetting.isBillingPacketSending == null) {
        this.cpaNwSetting.radiusSetting.isBillingPacketSending = true;
      }
      // UDPポート番号がnullの場合以下の内容で初期化
      if (this.cpaNwSetting.radiusSetting.udpPort == null) {
        this.cpaNwSetting.radiusSetting.udpPort = "PORT_1645_1646";
      }
    }

    // 案件コードがnullであれば空配列を設定しておく
    this.cpaNwSetting.dealCodeList = this.cpaNwSetting.dealCodeList ?? [];
  },
});

/**
 * CPA NW情報のGET用のオブジェクトPOST用オブジェクトに変形する
 * 設定確認とデータ反映で設定値に差異がある箇所は個別に設定する
 * @param cpaNwSetting NW情報
 */
export function createCpaNwPostObj(cpaNwSetting: CpaNwDetail): UpdateCpaNw {
  // 相関エラーとかあった時のことを考え、クローンしたもの設定データとして返す
  const cloneCpaNwSetting = cloneDeep(cpaNwSetting) as CpaNwDetail;

  /** 不要な項目を任意にしたRADIUS設定 */
  type RadiusOptional = Partial<
    Pick<
      RadiusSetting,
      | "ipAddressPrimary"
      | "ipAddressSecondary"
      | "callingStationIdTransfer"
      | "isImeiTransfer"
    >
  > &
    Omit<
      NonNullableProperty<RadiusSetting>,
      | "ipAddressPrimary"
      | "ipAddressSecondary"
      | "callingStationIdTransfer"
      | "isImeiTransfer"
      | "testAccount"
    > &
    Pick<RadiusSetting, "testAccount">;

  // コンパイラに型を認識させてからPOSTで不要な項目を削除 nullの場合はそのまま設定するのでスルーします
  const radiusSetting = cloneCpaNwSetting.radiusSetting as RadiusOptional;
  if (radiusSetting) {
    delete radiusSetting.ipAddressPrimary;
    delete radiusSetting.ipAddressSecondary;
    delete radiusSetting.callingStationIdTransfer;
    delete radiusSetting.isImeiTransfer;
  }

  return {
    cpaNetworkKeyId: cloneCpaNwSetting.cpaNetworkKeyId,
    endUserName: cloneCpaNwSetting.endUserName!,
    isGlobalIpUse: cloneCpaNwSetting.isGlobalIpUse!,
    ueIpAllocationIpv4List: cloneCpaNwSetting.ueIpAllocationIpv4List,
    ueIpAllocationIpv6List: cloneCpaNwSetting.ueIpAllocationIpv6List,
    networkIpSettingAct: cloneCpaNwSetting.networkIpSettingAct!,
    networkIpSettingSby: cloneCpaNwSetting.networkIpSettingSby!,
    maintenanceContact1: cloneCpaNwSetting.maintenanceContact1!,
    maintenanceContact2: cloneCpaNwSetting.maintenanceContact2,
    radiusSetting,
    bandwidth: cloneCpaNwSetting.bandwidth,
    // データ反映から遷移した場合、案件コードに変動無いので、nullを設定
    dealCodeAddList: null,
    dealCodeDeleteList: null,
  };
}














































































































import Vue, { PropType } from "vue";
import { IpPool, IpPoolPost, IpPoolPut } from "@/apis/IpPoolApi";

export default Vue.extend({
  name: "IpPoolEdit",
  props: {
    ipPool: {
      type: Object as PropType<IpPool>,
      required: false,
      default: null,
    },
    ipPoolKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    type4Id: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      ipPoolInfo: {} as IpPool,
      globalIpList: [] as string[],
      isLoaded: false,
    };
  },
  async mounted() {
    // 更新であればフォームのデータを設定
    if (this.ipPool) this.ipPoolInfo = { ...this.ipPool };
    await this.getGlobalIpList();
    this.isLoaded = true;
  },
  methods: {
    /**
     * グローバルIPリストをAPIから取得する
     */
    async getGlobalIpList() {
      if (!this.type4Id) {
        // Type4IDが無い場合、Type1用の取得APIを呼び出す
        const globalIpList = (
          await this.$api.contract_change_type1.getType1Service()
        ).globalIpAddressList;

        // 取得結果から必要なプロバティのみ抽出して、昇順にソートする
        this.globalIpList = globalIpList
          .map((e) => e.globalIpAddress)
          .sortBy([(e) => e, "asc"]);
      } else {
        // Type4IDがある場合、Type4用の取得APIを呼び出す
        const globalIpList = (
          await this.$api.contract_change_type4.getType4Service(this.type4Id)
        ).globalIpAddressList;

        // 取得結果から必要なプロバティのみ抽出して、昇順にソートする
        this.globalIpList = globalIpList
          .map((e) => e.globalIpAddress)
          .sortBy([(e) => e, "asc"]);
      }
    },
    /**
     * IP Poolの追加・変更を行う
     */
    async onSubmit() {
      // propsから受け取るデータの有無で更新・追加処理をハンドリング
      if (this.ipPool) {
        await this.$confirm("IP Poolを変更します。よろしいですか？");
        await this.modIpPool();
      } else {
        await this.$confirm("IP Poolを追加します。よろしいですか？");
        await this.addIpPool();
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** IP Pool追加APIを呼び出す */
    async addIpPool() {
      // 追加データの設定
      const reqData: IpPoolPost = {
        ipPoolName: this.ipPoolInfo.ipPoolName,
        globalIpAddress: this.ipPoolInfo.globalIpAddress,
        description: this.ipPoolInfo.description || null,
        note: null,
        ipPoolKeyId: this.ipPoolKeyId,
      };

      // IP Pool追加APIを呼び出す
      if (!this.type4Id) {
        // Type4IDが無い場合、Type1用の追加APIを呼び出す
        await this.$api.ipPool.postIpPoolType1(reqData);
      } else {
        // Type4IDがある場合、Type4用の追加APIを呼び出す
        await this.$api.ipPool.postIpPoolType4(reqData, this.type4Id);
      }
    },
    /** IP Pool更新APIを呼び出す */
    async modIpPool() {
      // 更新データの設定
      const reqData: IpPoolPut = {
        globalIpAddress: this.ipPoolInfo.globalIpAddress,
        description: this.ipPoolInfo.description || null,
        note: null,
        ipPoolKeyId: this.ipPoolKeyId,
      };

      if (!this.type4Id) {
        // Type4IDが無い場合、Type1用の更新APIを呼び出す
        await this.$api.ipPool.putIpPoolType1(
          reqData,
          this.ipPoolInfo.ipPoolSeq
        );
      } else {
        // Type4IDがある場合、Type4用の更新APIを呼び出す
        await this.$api.ipPool.putIpPoolType4(
          reqData,
          this.ipPoolInfo.ipPoolSeq,
          this.type4Id
        );
      }
    },
  },
});

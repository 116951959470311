import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import moment from "moment";

/**
 * 日付形式チェックバリデーション.
 * 指定された日付の形式の文字列かチェック
 */
export default {
  params: ["format"],
  validate(
    value: [] | undefined | null,
    { format }: { format: string }
  ): boolean {
    if (!value) {
      return true;
    }
    return moment(value, format, true).isValid();
  },
} as ValidationRuleSchema;

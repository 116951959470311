import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 半角英数、記号(-+._)を含んで入力されること */

export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }
    return /^[-+._a-zA-Z0-9]+$/.test(value);
  },
} as ValidationRuleSchema;













































































































import Vue, { PropType } from "vue";
import ServiceIntranetFwEdit from "@/modals/applianceObject/ServiceIntranetFwEdit.vue";
import { CustomServiceIntranet } from "@/apis/CustomServiceApi";

export default Vue.extend({
  name: "ServiceIntranetFwReference",
  props: {
    /**
     * ポリシー種別
     * INTRANET_FW ：イントラネットFW
     * INTRANET_FW_PFGW ：イントラネットFW（PFGW）
     */
    policyType: {
      type: String as PropType<"INTRANET_FW" | "INTRANET_FW_PFGW">,
      required: true,
    },
    /**
     * E番号(ACT)
     * ポリシー種別でINTRANET_FW_PFGWを指定時必須
     */
    enumberAct: {
      type: String as PropType<string>,
      default: undefined,
    },
    customServiceIntranet: {
      type: Object as PropType<CustomServiceIntranet>,
      required: true,
    },
    customServiceKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  methods: {
    /** サービス(イントラネットFW)変更モーダルを表示 */
    async showServiceIntranetFwModify() {
      await this.$modal.show(ServiceIntranetFwEdit, {
        policyType: this.policyType,
        enumberAct: this.enumberAct,
        customServiceIntranet: this.customServiceIntranet,
        customServiceKeyId: this.customServiceKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** サービス(イントラネットFW)を削除 */
    async deleteServiceSetting() {
      await this.$confirm(`個別サービスを削除します。よろしいですか？`);
      await this.$api.customServiceApi.deleteIntranetfwCustomService(
        this.policyType,
        this.enumberAct,
        {
          customServiceSeqList: [this.customServiceIntranet.customServiceSeq],
          customServiceKeyId: this.customServiceKeyId,
        }
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

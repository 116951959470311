import { ValidationRuleSchema } from "vee-validate/dist/types/types";

// 入力されたMD5をチェックする
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) return true;

    const pattern = /^[a-zA-Z0-9!#$%&'()*+\-.<=>?@[\]^_`{|}~]{6,80}$/;

    return pattern.test(value);
  },
} as ValidationRuleSchema;


























































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import ContractLineReference from "@/modals/contractLine/ContractLineReference.vue";
import {
  ContractLineGetEntity,
  ContractListEntity,
} from "@/apis/ContractLineApi";

export default Vue.extend({
  name: "ContractLineList",
  data() {
    return {
      // 初期通信完了フラグ
      isLoaded: false,
      userInfo: {} as ContractLineGetEntity,
      contractList: {
        fields: [
          {
            key: "enumber",
            label: "回線番号",
            sortable: true,
          },
          {
            key: "contractor",
            label: "契約者名",
            sortable: true,
          },
          {
            key: "endUserName",
            label: "エンドユーザ名",
            sortable: true,
          },
          {
            key: "office",
            label: "事業所名(拠点名)",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, _k, item: ContractListEntity) => {
              return item.accessType === "REMOTE_ACCESS_GW_FRE" ||
                item.accessType === "REMOTE_ACCESS_GW_CPA" ||
                item.accessType === "REMOTE_ACCESS_GW_CRG" ||
                item.accessType === "REMOTE_ACCESS_GW2_FRE" ||
                item.accessType === "REMOTE_ACCESS_GW2_CPA" ||
                item.accessType === "REMOTE_ACCESS_GW2_CRG"
                ? "-"
                : value;
            },
          },
          {
            key: "accessType",
            label: "メニュー",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) =>
              this.$filter.enumTo(value, "contractLineAccessType"),
          },
          {
            key: "startEndDate",
            label: "サービス開廃日",
            sortable: true,
            sortByFormatted: true,
            formatter: (value: string) => this.$filter.date(value),
          },
          {
            key: "isSecurityOption",
            label: "セキュリティ監視契約",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) => {
              return this.$filter.enumTo(value, "contractLineIsSecurityOption");
            },
          },
        ],
        items: [],
        search: {
          enumber: { type: "text", label: "回線番号" },
          contractor: { type: "text", label: "契約者名" },
          endUserName: { type: "text", label: "エンドユーザ名" },
          office: { type: "text", label: "事業所名(拠点名)" },
          accessType: {
            type: "select",
            label: "メニュー",
            items: accessTypeItems.map((v) => {
              return {
                text: this.$filter.enumTo(v, "contractLineAccessType"),
                value: v,
              };
            }),
          },
          isSecurityOption: {
            type: "select",
            label: "セキュリティ監視契約",
            items: isSecurityOptionItems,
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          enumber: "like",
          contractor: "like",
          endUserName: "like",
          office: "like",
          accessType: "equal",
          isSecurityOption: "equal",
        },
      } as Omit<AppTableData<ContractListEntity>, "selected">,
      isSecurityOptionItems: isSecurityOptionItems,
      accessTypeItems: accessTypeItems,
      downloadUrl: this.$api.contractLine.getCsvURL(),
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** 一覧を取得 */
    async load() {
      // 契約回線情報取得APIを呼ぶ
      this.userInfo = await this.$api.contractLine.getAll();
      this.contractList.items = this.userInfo.contractList;
    },
    /** 契約回線情報モーダル */
    contractLineDetails(item: ContractListEntity) {
      this.$modal.show(ContractLineReference, {
        contractReference: item,
      });
    },
  },
});

/** 契約回線種別の定数 */
/** 「CPA 5GSA」ご用意してない理由は「CPA 5GSA」は10月リリースに入らないので、フィルターの選択肢からは削除 */
export const accessTypeItems: ContractListEntity["accessType"][] = [
  "INTERNET",
  "ETHERNET_L2",
  "ETHERNET_L3",
  "BROADBAND_ACCESS2_L2",
  "BROADBAND_ACCESS2_L3",
  "WIRELESS_ACCESS_L2",
  "WIRELESS_ACCESS_L3",
  "REMOTE_ACCESS_GW_FRE",
  "REMOTE_ACCESS_GW_CPA",
  "REMOTE_ACCESS_GW_CRG",
  "PLATFORM_GATEWAY",
  "PF_ACCESS_GW",
  "EXTEND_ETHERNET",
  "IPSEC",
  "ETHERNET2",
  "ETHERNET2_PF",
  "BROADBAND_ACCESS3",
  "WIRELESS_ACCESS2",
  "PF_ACCESS_GW2",
  "VN_L2L3_CONNECT",
  "REMOTE_ACCESS_GW2_FRE",
  "REMOTE_ACCESS_GW2_CPA",
  "REMOTE_ACCESS_GW2_CRG",
  "ETHERNET_LIGHT",
  "MULTI_CLOUD_GW",
  "CPA_5GSA",
];

export const isSecurityOptionItems: { value: boolean; text: string }[] = [
  { value: true, text: "有り" },
  { value: false, text: "無し" },
];

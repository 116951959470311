























































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { CloudPktFilterEntity } from "@/apis/PacketFilteringApi";
import { statusItems } from "@/views/packetFiltering/PacketFilteringList.vue";
import PacketFilteringReference from "@/modals/packetFiltering/PacketFilteringReference.vue";

const cloudPktMenus = [{ value: "MULTI_CLOUD_GW", text: "マルチクラウドGW" }];

export default Vue.extend({
  name: "CloudPacketFilteringList",
  data() {
    return {
      isLoaded: false,
      statusItems: statusItems,
      cloudPktMenus: cloudPktMenus,
      cloudPackets: {
        fields: [
          {
            key: "wnumberAct",
            label: `VNコネクト番号\n(Act/Sby)`,
            sortable: true,
            sortByFormatted: true,
            formatter: (_v: string, _k: string, item: CloudPktFilterEntity) => {
              return !item.wnumberSby
                ? item.wnumberAct
                : `${item.wnumberAct}\n${item.wnumberSby}`;
            },
          },
          {
            key: "vnConnectNameAct",
            label: "VNコネクト名\n(Act/Sby)",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v: string, _k: string, item: CloudPktFilterEntity) => {
              return !item.vnConnectNameSby
                ? item.vnConnectNameAct
                : `${item.vnConnectNameAct}\n${item.vnConnectNameSby}`;
            },
          },
          {
            key: "enumber",
            label: "回線番号(E番号)",
            sortable: true,
          },
          {
            key: "menu",
            label: "メニュー",
          },
          {
            key: "dstVpnVnCode",
            label: "接続先VPN/VNコード",
            sortable: true,
            filterByFormatted: true,
            formatter: (_v: string, _k: string, item: CloudPktFilterEntity) => {
              return item.vnName
                ? `${item.vnName}(${item.dstVpnVnCode})`
                : item.dstVpnVnCode;
            },
          },
          {
            key: "filterringSettingStatus",
            label: "パケットフィルタリング設定有無",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) =>
              this.$filter.enumTo(value, "filterringSettingStatus"),
          },
        ],
        items: [],
        search: {
          wnumberAct: { type: "text", label: "VNコネクト番号" },
          vnConnectNameAct: { type: "text", label: "VNコネクト名" },
          enumber: { type: "text", label: "回線番号(E番号)" },
          dstVpnVnCode: { type: "text", label: "接続先VPN/VNコード" },
          filterringSettingStatus: {
            type: "select",
            label: "パケットフィルタリング設定有無",
            items: statusItems,
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          wnumberAct: (item, value: unknown) => {
            if (`${value}` === "-") {
              return (
                `${item.wnumberAct}`.includesIgnoreCase(`${value}`) ||
                `${item.wnumberSby}`.includesIgnoreCase(`${value}`) ||
                !item.wnumberSby
              );
            } else {
              return (
                `${item.wnumberAct}`.includesIgnoreCase(`${value}`) ||
                `${item.wnumberSby}`.includesIgnoreCase(`${value}`)
              );
            }
          },
          vnConnectNameAct: (item, value: unknown) => {
            if (`${value}` === "-") {
              return (
                `${item.vnConnectNameAct}`.includesIgnoreCase(`${value}`) ||
                `${item.vnConnectNameSby}`.includesIgnoreCase(`${value}`) ||
                !item.vnConnectNameSby
              );
            } else {
              return (
                `${item.vnConnectNameAct}`.includesIgnoreCase(`${value}`) ||
                `${item.vnConnectNameSby}`.includesIgnoreCase(`${value}`)
              );
            }
          },
          enumber: "like",
          dstVpnVnCode: "like",
          filterringSettingStatus: "equal",
        },
      } as Omit<AppTableData<CloudPktFilterEntity>, "selected">,
      vnConnectPacketFilterKeyId: "",
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      /** クラウド向けパケットフィルタリング一覧を取得 */
      const cloudData = await this.$api.packetFilter.getCloudVnConnects();
      this.cloudPackets.items = cloudData.packetFilterList.map(
        (e) =>
          ({
            ...e,
            menu: "MULTI_CLOUD_GW",
          } as CloudPktFilterEntity)
      );
      this.vnConnectPacketFilterKeyId = cloudData.vnConnectPacketFilterKeyId;
    },
    /** パケットフィルタルール表示 */
    async showCloudPktRules(item: CloudPktFilterEntity) {
      const data = (
        await this.$api.packetFilter.getCloudPktFilter(item.wnumberAct)
      ).packetFilterRuleList;
      const downloadUrl = this.$api.packetFilter.getCloudCsvURL(item.enumber);

      await this.$modal.show(PacketFilteringReference, {
        packetFilteringItem: data,
        clickRowCloudVnConItem: item,
        packetFilterKeyId: this.vnConnectPacketFilterKeyId,
        downloadUrl: downloadUrl,
      });
      await this.load();
    },
  },
});

































































import Vue from "vue";
import packetFilteringContractConfirm from "@/modals/packetFiltering/PacketFilteringContractConfirm.vue";
import { ContractGetPacketFiltering } from "@/apis/PacketFilteringApi";

export default Vue.extend({
  name: "PacketFilteringContractReference",
  data() {
    return {
      alertMsg: null as string | null,
      /** 初期通信完了フラグ */
      isLoaded: false,
      contractGetPacketFiltering: {} as ContractGetPacketFiltering,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    async load() {
      this.contractGetPacketFiltering =
        await this.$api.packetFilter.getContractPacketFiltering();
    },
    /** 契約変更確認画面を表示 */
    async contractReference() {
      if (this.contractGetPacketFiltering.packetFilterRuleCount !== 0) {
        this.alertMsg = this.$msg("packet_filtering_rule_count");
      } else {
        // 確認モーダルを表示
        await this.$modal.show(packetFilteringContractConfirm, {
          contractGetPacketFiltering: this.contractGetPacketFiltering,
        });
        // 情報更新
        await this.$api.packetFilter.postContractPacketFiltering({
          contractPacketFilterKeyId:
            this.contractGetPacketFiltering.contractPacketFilterKeyId,
          packetFilterEnabled:
            !this.contractGetPacketFiltering.packetFilterEnabled,
          note: null,
        });
        // 契約情報が変更されるためサマリー情報を再取得してvuexのデータを更新
        await this.$store.dispatch("user/updateLoginInfo", {
          contractSummary: await this.$api.contract.getSummary(),
        });
        (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";
import { binirizedIp } from "@/validators/is-ip-smaller";

/**
 * IPアドレスの第4オクテットがパラメータのIPアドレスより大きい(老番)であることを検証する
 * ・正しいIPV4形式であることは別のバリデーションで実施が必要
 * ・CIDR形式のみ受付可能
 * ・同じCIDR値であることは別のバリデーションで実施が必要(is-same-segment)
 */
export default {
  params: [{ name: "ip", isTarget: true }],
  validate(
    value: string | undefined | null,
    { ip }: { ip: string | undefined | null }
  ): boolean {
    if (!value || !ip) {
      return true;
    }

    let valueIpv4: [ipaddr.IPv4, number];
    let targetIpv4: [ipaddr.IPv4, number];
    try {
      valueIpv4 = ipaddr.IPv4.parseCIDR(value);
      targetIpv4 = ipaddr.IPv4.parseCIDR(ip);
      // CIDR値が異なる場合は、強制的に正常とする
      if (valueIpv4[1] !== targetIpv4[1]) {
        return true;
      }
    } catch (e) {
      return true;
    }

    return (
      parseInt(binirizedIp(valueIpv4[0]).substring(valueIpv4[1]), 2) >
      parseInt(binirizedIp(targetIpv4[0]).substring(targetIpv4[1]), 2)
    );
  },
} as ValidationRuleSchema;
























































































































































































import Vue, { PropType } from "vue";
import MultiSelectTag from "@/components/MultiSelectTag.vue";
import {
  InternetfwApplication,
  InternetfwProfileInfo,
  SetInternetfwProfile,
} from "@/apis/InternetfwProfileApi";

export default Vue.extend({
  name: "ProfileInternetFwEdit",
  components: { MultiSelectTag },
  props: {
    internetFwProfileInfo: {
      type: Object as PropType<InternetfwProfileInfo>,
      default: null,
      required: false,
    },
    type4Id: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    profileInternetFwSeq: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    profileInternetFwKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      form: {} as InternetfwProfileInfo,
      isSelectApp: false,
      appCategories: [] as string[],
      appCategoriesSelected: [] as string[],
      appSubcategories: [] as string[],
      appSubcategoriesSelected: [] as string[],
      // 全件のサブカテゴリリスト（カテゴリクリアした場合、再計算不要のため）
      allSubcategories: [] as string[],
      appTechnologies: [] as string[],
      appTechnologiesSelected: [] as string[],
      appRisks: [] as number[],
      appRisksSelected: [] as number[],
      applications: [] as InternetfwApplication[],
      filterApplications: [] as InternetfwApplication[],
    };
  },
  async mounted() {
    if (this.internetFwProfileInfo) {
      // 更新の場合は遷移元のデータを設定
      this.form = { ...this.internetFwProfileInfo };
      // アプリケーションが選択か判定しブール値で保持
      this.isSelectApp = !!this.form.applicationList.length;
    }
    await this.load();
    // フィルター用にアプリケーションリストをコピー
    this.filterApplications = this.applications.sortBy([
      (e) => e.applicationName,
      "asc",
    ]);
    this.isLoaded = true;
  },
  watch: {
    appCategoriesSelected() {
      // サブカテゴリ選択肢の切り替え
      if (this.appCategoriesSelected.length > 0) {
        this.appSubcategories = [
          ...new Set(
            this.applications
              .filter((e) =>
                this.appCategoriesSelected.some((v) => v === e.category)
              )
              .map((e) => e.subcategory || "-")
          ),
        ].sortBy([(e) => e, "asc"]);
      } else {
        this.appSubcategories = this.allSubcategories;
      }
      // 選択肢リストに存在しないサブカテゴリのクリア
      this.appSubcategoriesSelected = this.appSubcategoriesSelected.filter(
        (v) => this.appSubcategories.some((e) => v === e)
      );
    },
  },
  methods: {
    /** 初期表示時の処理 */
    async load() {
      // アプリケーションの取得
      this.applications =
        await this.$api.internetfwProfileApi.getInternetfwApplications();

      // カテゴリから重複する項目を除去して、昇順にソートする
      this.appCategories = [
        ...new Set(this.applications.map((e) => e.category)),
      ].sortBy([(e) => e, "asc"]);

      // サブカテゴリから重複する項目を除去して、昇順にソートする
      this.allSubcategories = [
        ...new Set(this.applications.map((e) => e.subcategory || "-")),
      ].sortBy([(e) => e, "asc"]);
      this.appSubcategories = this.allSubcategories;

      // テクノロジーから重複する項目を除去して、昇順にソートする
      this.appTechnologies = [
        ...new Set(this.applications.map((e) => e.technology || "-")),
      ].sortBy([(e) => e, "asc"]);

      // リスクから重複する項目を除去して、昇順にソートする
      this.appRisks = [...new Set(this.applications.map((e) => e.risk))].sortBy(
        [(e) => e, "asc"]
      );
    },
    /** プロファイル情報の登録・更新処理 */
    async submit() {
      // 確認ダイアログの表示
      const action = this.internetFwProfileInfo ? "変更" : "追加";
      await this.$confirm(`プロファイルを${action}します。よろしいですか？`);

      // 登録・更新データの設定
      const reqData: SetInternetfwProfile = {
        profileInternetFwName: this.form.profileInternetFwName,
        applicationSeqList: this.isSelectApp
          ? this.form.applicationList.map((e) => e.applicationSeq)
          : [],
        description: this.form.description || null,
        profileInternetFwKeyId: this.profileInternetFwKeyId,
        note: null,
      };

      // プロファイル(インターネットFW)の登録・更新
      if (this.internetFwProfileInfo) {
        await this.modifyProfile(reqData);
      } else {
        await this.registerProfile(reqData);
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * プロファイル(インターネットFW)の登録処理
     * @param reqData 登録データ
     */
    async registerProfile(reqData: SetInternetfwProfile) {
      // Type4IDの有無により呼び出すAPIをハンドリング
      if (!this.type4Id) {
        await this.$api.internetfwProfileApi.postType1Profile(reqData);
      } else {
        await this.$api.internetfwProfileApi.postType4Profile(
          this.type4Id,
          reqData
        );
      }
    },
    /**
     * プロファイル(インターネットFW)の更新処理
     * @param reqData 更新データ
     */
    async modifyProfile(reqData: SetInternetfwProfile) {
      // Type4IDの有無により呼び出すAPIをハンドリング
      if (!this.type4Id) {
        await this.$api.internetfwProfileApi.putType1Profile(
          this.profileInternetFwSeq,
          reqData
        );
      } else {
        await this.$api.internetfwProfileApi.putType4Profile(
          this.type4Id,
          this.profileInternetFwSeq,
          reqData
        );
      }
    },
    /** アプリケーションリストをプルダウンからフィルターする際の処理 */
    filterApplication() {
      this.filterApplications = this.applications
        .filter((app) =>
          this.appCategoriesSelected.length > 0
            ? this.appCategoriesSelected.some((e) => e === app.category)
            : true
        )
        .filter((app) =>
          this.appSubcategoriesSelected.length > 0
            ? this.appSubcategoriesSelected.some((e) => {
                if (e === "-") return null === app.subcategory;
                return e === app.subcategory;
              })
            : true
        )
        .filter((app) =>
          this.appTechnologiesSelected.length > 0
            ? this.appTechnologiesSelected.some((e) => {
                if (e === "-") return null === app.technology;
                return e === app.technology;
              })
            : true
        )
        .filter((app) =>
          this.appRisksSelected.length > 0
            ? this.appRisksSelected.some((e) => e === app.risk)
            : true
        );
    },
  },
});

















































































































































































































































































import Vue, { PropType } from "vue";
import {
  PostType4InternetSite,
  Type4InternetAddress,
  Type4InternetFQDN,
  Type4InternetSite,
} from "@/apis/Type4SiteApi";
import { ApplianceInfo } from "@/apis/ContractApi";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "Type4InternetSiteModify",
  props: {
    internetSite: {
      type: Object as PropType<Type4InternetSite>,
      required: true,
    },
    type4Id: {
      type: String as PropType<ApplianceInfo["type4Id"]>,
      required: true,
    },
  },
  data() {
    return {
      addressList: [] as Type4InternetAddress[],
      fqdnList: [] as Type4InternetFQDN[],
    };
  },

  computed: {
    /** 既存のIPアドレス */
    existsAddress() {
      // 既存のアドレスリストに同じIPアドレスが存在した場合はエラーとする
      return (index: number) => {
        return this.addressList
          .filter((_, num) => num !== index)
          .map((v) => v.ipAddress);
      };
    },
    /** 既存のFQDN */
    existsFqdn() {
      // 既存のFQDNリストに同じFQDNが存在した場合はエラーとする
      return (index: number) => {
        return this.fqdnList
          .filter((_, num) => num !== index)
          .map((v) => v.fqdn);
      };
    },
    /** 遷移元がポリシー設定の場合は,true */
    isPolicy(): boolean {
      return this.$route.path === "/network/contract";
    },
    /** アドレス名入力済みの場合は,true */
    hasInputIpAddressName(): boolean {
      return this.addressList.some((v) => v.ipAddressName);
    },
    /** FQDN名入力済みの場合は,true */
    hasInputFqdnName(): boolean {
      return this.fqdnList.some((v) => v.fqdnName);
    },
  },

  async mounted() {
    await this.load();
  },

  methods: {
    /**
     * 初期化機能
     * システム上の配置状態追加
     */
    async load() {
      this.addressList = cloneDeep(this.internetSite.addressList);
      this.fqdnList = cloneDeep(this.internetSite.fqdnList);
      // リストが０件の場合デフォルトを追加
      if (this.addressList.length < 1) {
        this.addIPAddress();
      }
      if (this.fqdnList.length < 1) {
        this.addFqdn();
      }
    },
    /** 変更機能 */
    async submit() {
      await this.$confirm(
        "インターネットサイト設定を変更します。よろしいですか？"
      );
      // リストが０件の場合空配列に
      if (this.addressList.length === 1 && !this.addressList[0].ipAddress) {
        this.addressList = [];
      }
      if (this.fqdnList.length === 1 && !this.fqdnList[0].fqdn) {
        this.fqdnList = [];
      }
      // 変更データの設定
      const reqData: PostType4InternetSite = {
        addressList:
          this.addressList.length === 1 && !this.addressList[0].ipAddress
            ? []
            : this.addressList.map((e) => ({
                ipAddressId: e.ipAddressId || null,
                ipAddressName: e.ipAddressName || null,
                ipAddress: e.ipAddress,
              })),
        fqdnList:
          this.fqdnList.length === 1 && !this.fqdnList[0].fqdn
            ? []
            : this.fqdnList.map((e) => ({
                fqdnId: e.fqdnId || null,
                fqdnName: e.fqdnName || null,
                fqdn: e.fqdn,
              })),
        type4SiteKeyId: this.internetSite.type4SiteKeyId,
      };
      await this.$api.type4SiteApi.postType4InternetSite(this.type4Id, reqData);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * IPAddress追加機能
     * 押下時一行追加
     */
    addIPAddress() {
      this.addressList.push({
        ipAddressId: "",
        ipAddressName: "",
        ipAddress: "",
        isPolicyInUse: false,
      });
    },
    /**
     * fqdn追加機能
     * 押下時一行追加
     */
    addFqdn() {
      this.fqdnList.push({
        fqdnId: "",
        fqdnName: "",
        fqdn: "",
        isPolicyInUse: false,
      });
    },
    /**
     * IPAddress削除機能
     * 押下時その行削除
     * 一行しかない場合その行クリア
     */
    removeIPAddress(target: Type4InternetAddress) {
      if (this.addressList.length > 1) {
        this.addressList.splice(this.addressList.indexOf(target), 1);
      } else {
        this.addressList = [
          {
            ipAddressId: "",
            ipAddressName: "",
            ipAddress: "",
            isPolicyInUse: false,
          },
        ];
      }
    },
    /**
     * fqdn削除機能
     * 押下時その行削除
     * 一行しかない場合その行クリア
     */
    removeFqdn(target: Type4InternetFQDN) {
      if (this.fqdnList.length > 1) {
        this.fqdnList.splice(this.fqdnList.indexOf(target), 1);
      } else {
        this.fqdnList = [
          {
            fqdnId: "",
            fqdnName: "",
            fqdn: "",
            isPolicyInUse: false,
          },
        ];
      }
    },
  },
});

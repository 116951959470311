














































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { ZoneRelation, ZoneRelationItem } from "@/apis/ZoneRelationApi";
import IntranetFwZoneRelationDelete from "@/modals/applianceContractSetting/IntranetFwZoneRelationDelete.vue";
import IntranetFwZoneRelationRegister from "@/modals/applianceContractSetting/IntranetFwZoneRelationRegister.vue";

export default Vue.extend({
  name: "IntranetFwZoneRelationList",
  data() {
    return {
      /** 初期通信完了フラグ */
      isLoaded: false,
      zoneRelation: {} as ZoneRelation,
      zoneSelect: {
        fields: [
          {
            key: "srcZoneName",
            label: "送信元ゾーン/ゾーン名",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: null, key: null, item: ZoneRelationItem) => {
              return (
                this.$filter.enumTo(item.srcZoneType, "zoneType") +
                `/${item.srcZoneName}`
              );
            },
          },
          { key: "arrow", label: "" },
          {
            key: "dstZoneName",
            label: "宛先ゾーン/ゾーン名",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: null, key: null, item: ZoneRelationItem) => {
              return (
                this.$filter.enumTo(item.dstZoneType, "zoneType") +
                `/${item.dstZoneName}`
              );
            },
          },
          { key: "zoneDelete", label: "" },
        ],
        items: [],
      } as Pick<AppTableData<ZoneRelationItem>, "fields" | "items">,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    async load() {
      this.zoneRelation = await this.$api.zoneRelation.getZoneRelation();
      // INTERNETのデータは非表示
      this.zoneSelect.items = this.zoneRelation.zoneRelationList.filter(
        (item) => {
          return (
            item.srcZoneType !== "INTERNET" && item.dstZoneType !== "INTERNET"
          );
        }
      );
    },
    /** ゾーン接続削除 */
    async toDelete(row: ZoneRelationItem) {
      await this.$modal.show(IntranetFwZoneRelationDelete, {
        data: row,
      });
      await this.$api.zoneRelation.deleteZoneRelation(row.zoneRelationId, {
        zoneRelationKeyId: this.zoneRelation.zoneRelationKeyId,
      });
      this.isLoaded = false;
      await this.load();
      this.isLoaded = true;
    },
    /** ゾーン接続追加モーダルを表示 */
    async toRegister() {
      await this.$modal.show(IntranetFwZoneRelationRegister, {
        zoneRelationKeyId: this.zoneRelation.zoneRelationKeyId,
        zoneRelation: this.zoneRelation,
      });
      this.isLoaded = false;
      await this.load();
      this.isLoaded = true;
    },
  },
});

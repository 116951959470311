export {};

declare global {
  interface String {
    /**
     * 文字列が検索値を含んでいれば、true。含んでいれば、false。
     * 部分一致+大文字/小文字は無視される
     * @param searchString str 内で検索される文字列。
     * @param position 文字列内で searchString を検索し始める位置です。 (既定値は 0 です。)
     * @see {@link String#includes}
     */
    includesIgnoreCase(searchString: string, position?: number): boolean;

    /**
     * 文字列が別の文字列の文字で始まるか判断し、その結果に応じて true か false を返す。
     * 前方一致+大文字/小文字は無視される。
     * @param searchString 参照文字列の始まりで検索される文字列。
     * @param position 文字列内で searchString を検索し始める参照文字列の位置。デフォルトでは、0です。
     *
     * */
    startsWithtoLowerCase(searchString: string, position?: number): boolean;

    /** booleanに変換 */
    toBoolean(): boolean;
  }
}

String.prototype.includesIgnoreCase = function (
  searchString: string,
  position?: number
): boolean {
  return this.toLowerCase().includes(searchString.toLowerCase(), position);
};

String.prototype.startsWithtoLowerCase = function (
  searchString: string,
  position?: number
): boolean {
  return this.toLowerCase().startsWith(searchString.toLowerCase(), position);
};

String.prototype.toBoolean = function (): boolean {
  switch (this.toLowerCase()) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      throw Error(`boolean parse error. str: ${this}`);
  }
};

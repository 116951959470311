import { AxiosResponse } from "axios";

import AbstractBaseApi from "./AbstractBaseApi";

/**
 * IPsec回線一覧のAPI用インターフェイス
 */

export interface IpSecLineGetEntity {
  total: number;
  /** IPsec回線一覧 */
  ipsecList: IpSecLineListEntity[];
  /** IPsec回線排他情報 */
  acLineKeyId: string;
}

export interface IpSecLineListEntity {
  /** IPsec回線シーケンス */
  acLineSeq: string;
  /** IPsecゲートウェイシーケンス */
  vcLineSeq: string;
  /** IPsec回線名 */
  ipsecLineName: string;
  /** 事前共有鍵 */
  preSharedKey: string;
  /** 疎通試験用IPアドレス */
  connectTestIpAddress: string;
  /** 備考 */
  description: string | null;
  /** IPsec回線番号 - X番号 */
  xnumber: string;
  /** IF状態
   * SHUTDOWN: 閉塞状態
   * NO_SHUTDOWN: 開放状態
   */
  ifStatus: "SHUTDOWN" | "NO_SHUTDOWN";
  /** IPsecGw回線番号 */
  enumber: string;
  /** ユーザアカウント(USER-FQDN) */
  userAccount: string;
  /** LANアドレス */
  lanIpAddressList: LanEntry[];
}

// LANアドレスインタフェース
export interface LanEntry {
  /** アドレスシーケンス */
  acLineIpAddressSeq: string | null;
  /** IPアドレス */
  ipAddress: string;
}

/**
 * IPsec回線追加のAPI用インターフェイス
 */

export interface IpSecLinePostEntity {
  /** IPsec回線名 */
  ipsecLineName: string;
  /** 事前共有鍵 */
  preSharedKey: string;
  /** 疎通試験用IPアドレス */
  connectTestIpAddress: string;
  /** 備考 */
  description: string | null;
  /** IPsecGw回線番号 */
  enumber: string;
  /** ユーザアカウント(USER-FQDN) */
  userAccount: string;
  /** LANアドレス */
  lanIpAddressList: Pick<LanEntry, "ipAddress">[];
  /** オーダー備考 */
  note: string | null;
}

/**
 * IPsec回線更新のAPI用インターフェイス
 */
export interface IpSecLineUpdateEntity {
  /** IPsec回線名 */
  ipsecLineName: string;
  /** 事前共有鍵 */
  preSharedKey: string;
  /** 疎通試験用IPアドレス */
  connectTestIpAddress: string;
  /** 備考 */
  description: string | null;
  /** LANアドレス */
  lanIpAddressList: LanEntry[];
  /** オーダー備考 */
  note: string | null;
  /** IPsec回線排他情報 */
  acLineKeyId: string;
}

/**
 * IPsec回線削除のAPI用インターフェイス
 */
export interface IpSecLineDeleteEntity {
  /** 削除対象IPsec回線 */
  ipsecList: string[];
  /** オーダー備考 */
  note: string | null;
  /** IPsec回線排他情報 */
  acLineKeyId: string;
}

export default class IpSecLineApi extends AbstractBaseApi {
  /**
   * IPsec情報を取得
   */
  async getAll(): Promise<IpSecLineGetEntity> {
    return (await this.api.get("v1/network/ipsecs")).data;
  }

  /**
   * IPsec情報を削除
   * @param data 削除対象データ
   */
  async delete(data: IpSecLineDeleteEntity): Promise<AxiosResponse> {
    return (await this.api.post("v1/network/ipsecs/delete", data)).data;
  }

  /**
   * IPsec情報を追加
   * @param data 追加データ
   */
  async post(data: IpSecLinePostEntity): Promise<AxiosResponse> {
    return (await this.api.post("v1/network/ipsec", data)).data;
  }

  /**
   * IPsec情報を変更
   * @param xnumber パスパラメータ
   * @param data 変更対象データ
   */
  async update(
    xnumber: string,
    data: IpSecLineUpdateEntity
  ): Promise<AxiosResponse> {
    return (await this.api.post(`v1/network/ipsec/${xnumber}`, data)).data;
  }
}

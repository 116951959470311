









































































import Vue from "vue";
import { CpaContract } from "@/apis/Cpa5gSaApi";
import SaContractRegister from "@/modals/cpa5g/SaContractRegister.vue";
import SaNwReference from "@/modals/cpa5g/SaNwReference.vue";
import { AppTableData } from "@/components/AppTable.vue";
import SaNwServiceStartUsingConfirm from "@/modals/cpa5g/SaNwServiceStartUsingConfirm.vue";

export default Vue.extend({
  name: "Cpa5gSaContractList",
  data() {
    return {
      isLoaded: false,
      alertMsg: null as string | null,
      downloadUrl: this.$api.cpa5gSa.downloadCpaContractCsvURL(),
      cpa5gSa: {
        fields: [
          {
            key: "wnumber",
            label: "CPA管理番号(Act/Sby)",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, key: null, item: CpaContract) => {
              if (item.wnumber) {
                return `${item.wnumber.wnumberAct}\n${item.wnumber.wnumberSby}`;
              } else {
                return "要NW設定";
              }
            },
          },
          {
            key: "domain",
            label: "ドメイン",
            sortable: true,
          },
          {
            key: "contractType",
            label: "契約タイプ",
            sortable: true,
            sortByFormatted: true,
            formatter: (value: string) => {
              return this.$filter.enumTo(value, "cpaContractType");
            },
          },
          {
            key: "authenticationType",
            label: "認証タイプ",
            sortable: true,
            sortByFormatted: true,
            formatter: (value: string) => {
              return this.$filter.enumTo(value, "cpaAuthenticationType");
            },
          },
          {
            key: "bandwidthType",
            label: "品目",
            sortable: true,
            sortByFormatted: true,
            formatter: (value: string, key: null, item: CpaContract) => {
              let bandwidth;
              if (item.bandwidthType === "BEST_EFFORT") {
                bandwidth = "ベストエフォート:1Gbps";
              } else {
                if (!item.wnumber) {
                  bandwidth = "帯域確保";
                } else {
                  bandwidth = `帯域確保:${item.bandwidth!.value}`;
                  if (item.bandwidth?.unit) {
                    bandwidth += this.$filter.enumTo(
                      item.bandwidth.unit,
                      "bandwidth_unit"
                    );
                  }
                }
              }
              return bandwidth;
            },
          },
          {
            key: "addressAssignmentMethod",
            label: "アドレスアサイン方式",
            sortable: true,
            sortByFormatted: true,
            formatter: (value: string) => {
              return this.$filter.enumTo(value, "cpaAddressAssignMethod");
            },
          },
          {
            key: "endUserName",
            label: "エンドユーザ名",
            sortable: true,
          },
          {
            key: "status",
            label: "ステータス",
            sortable: true,
            sortByFormatted: true,
            formatter: (value: string) => {
              return this.$filter.enumTo(value, "cpaContractStatus");
            },
          },
        ],
        items: [] as CpaContract[],
        selected: [],
        search: {
          wnumber: { type: "text", label: "CPA管理番号(Act/Sby)" },
          domain: { type: "text", label: "ドメイン" },
          contractType: {
            type: "select",
            label: "契約タイプ",
            items: optionList.contractOptions,
          },
          authenticationType: {
            type: "select",
            label: "認証タイプ",
            items: optionList.authOptions,
          },
          bandwidthType: {
            type: "select",
            label: "品目",
            items: optionList.itemOptions,
          },
          addressAssignmentMethod: {
            type: "select",
            label: "アドレスアサイン方式",
            items: optionList.infoAddressAssignOptions,
          },
          endUserName: { type: "text", label: "エンドユーザ名" },
          status: {
            type: "select",
            label: "ステータス",
            items: optionList.contractStatusOptions,
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          wnumber: "like",
          domain: "like",
          contractType: "equal",
          authenticationType: "equal",
          bandwidthType: "equal",
          addressAssignmentMethod: "equal",
          endUserName: "like",
          status: "equal",
        },
      } as AppTableData<CpaContract>,
    };
  },
  /** true: 一覧選択可能, false: 不可 */
  computed: {
    selectable(): boolean {
      return (
        this.$service.permission.hasAuthority("ADMIN") &&
        this.$service.permission.isDelegateUser()
      );
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.cpa5gSa.items = (
        await this.$api.cpa5gSa.getCpaContracts()
      ).cpaContractList;
    },

    // 一件のみチェック可能なチェックボックスの実装
    selectChange(item: CpaContract) {
      // チェック済の場合にはチェックを外す為に配列に含まれるかを確認
      if (!this.cpa5gSa.selected.includes(item)) {
        this.cpa5gSa.selected.splice(0, this.cpa5gSa.selected.length);
      } else {
        this.cpa5gSa.selected.splice(0, this.cpa5gSa.selected.length);
        this.cpa5gSa.selected.push(item);
      }
    },

    /** 新規契約 */
    async newContract() {
      this.alertMsg = null;
      await this.$modal.show(SaContractRegister);
      await this.load();
    },

    /** 利用開始 */
    async startUsing() {
      // チェックが0件の場合アラート表示
      if (this.cpa5gSa.selected.length === 0) {
        this.alertMsg = this.$msg("no_select_data");
        return;
      } else {
        this.alertMsg = null;
        const selected = this.cpa5gSa.selected[0];
        if (selected.status !== "INACTIVE") {
          this.alertMsg = "利用開始前の回線を選択してください。";
        } else {
          await this.$modal.show(SaNwServiceStartUsingConfirm, {
            cpaContractInfo: selected,
          });
          await this.$api.cpa5gSa.startCpaContract(selected.cpaContractSeq, {
            cpaContractKeyId: selected.cpaContractKeyId,
          });
          await this.load();
        }
      }
    },

    /** NW設定情報参照 */
    async nwDetails(entity: CpaContract) {
      this.alertMsg = null;
      await this.$modal.show(SaNwReference, {
        cpaContractInfo: entity,
      });
      await this.load();
    },
  },
});

/** オプションをここにまとめる */
export const optionList = {
  contractOptions: [
    { value: "CPA_NO_LIMIT", text: "端末速度制限無し" },
    { value: "CPA_LIMIT_MODULE", text: "端末速度制限有り(モジュール専用)" },
    {
      value: "CPA_LIMIT_AUDIO",
      text: "端末速度制限有り(モジュール専用/音声有り)",
    },
  ],
  authOptions: [
    { value: "RADIUS", text: "RADIUS認証代行(端末認証あり)" },
    { value: "RADIUS_IMEI", text: "RADIUS認証代行(端末認証あり+IMEI認証あり)" },
    { value: "HOMERADIUS", text: "HomeRADIUS認証" },
    { value: "HOME", text: "端末認証(Home)" },
  ],
  redundancyOptions: [
    { value: "EAST_ACT_WEST_SBY", text: "東日本ACT/西日本SBY" },
    { value: "WEST_ACT_EAST_SBY", text: "西日本ACT/東日本SBY" },
  ],
  itemOptions: [
    { value: "BEST_EFFORT", text: "ベストエフォート" },
    { value: "SECURING", text: "帯域確保" },
  ],
  infoAddressAssignOptions: [
    { value: "FIXED_IP", text: "固定IP割り当て(ユーザアサイン)" },
    { value: "DYNAMIC_IP", text: "動的IP割り当て(キャリアアサイン)" },
  ],
  ipAddressOptions: [
    { value: "IPV4", text: "IPv4" },
    { value: "IPV6", text: "IPv6" },
    { value: "IPV4_IPV6", text: "IPv4/IPv6" },
  ],
  contractStatusOptions: [
    { value: "ACTIVE", text: "運用中" },
    { value: "ACTIVE_UPDATE", text: "運用中(未反映データあり)" },
    { value: "NW_SETTING_PREPARATION", text: "NW設定準備中" },
    { value: "INACTIVE", text: "利用開始前" },
  ],
  bpsOptions: [
    { value: "0.5", text: "0.5Mbps" },
    { value: "1", text: "1Mbps" },
    { value: "2", text: "2Mbps" },
    { value: "3", text: "3Mbps" },
    { value: "4", text: "4Mbps" },
    { value: "5", text: "5Mbps" },
    { value: "6", text: "6Mbps" },
    { value: "7", text: "7Mbps" },
    { value: "8", text: "8Mbps" },
    { value: "9", text: "9Mbps" },
    { value: "10", text: "10Mbps" },
    { value: "20", text: "20Mbps" },
    { value: "30", text: "30Mbps" },
    { value: "40", text: "40Mbps" },
    { value: "50", text: "50Mbps" },
    { value: "60", text: "60Mbps" },
    { value: "70", text: "70Mbps" },
    { value: "80", text: "80Mbps" },
    { value: "90", text: "90Mbps" },
    { value: "100", text: "100Mbps" },
    { value: "200", text: "200Mbps" },
    { value: "300", text: "300Mbps" },
    { value: "400", text: "400Mbps" },
    { value: "500", text: "500Mbps" },
    { value: "600", text: "600Mbps" },
    { value: "700", text: "700Mbps" },
    { value: "800", text: "800Mbps" },
    { value: "900", text: "900Mbps" },
    { value: "1000", text: "1Gbps" },
  ],
  udpOptions: [
    { value: "PORT_1645_1646", text: "1645/1646" },
    { value: "PORT_1812_1813", text: "1812/1813" },
  ],
  idleTimerList: [
    { value: "960", text: "960秒" },
    { value: "3900", text: "3900秒" },
    { value: "19800", text: "19800秒" },
    { value: "86400", text: "86400秒" },
  ],
};

/**
 * いずれかが必須
 * @param values 必須対象一覧
 */
export function requiredSome(...values: unknown[]): boolean {
  return !!values.find((e) => {
    if (Array.isArray(e)) {
      return e.length > 0;
    }

    return !!e;
  });
}






































import Vue, { PropType } from "vue";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";

export default Vue.extend({
  name: "IntranetFwZoneRelationDelete",
  props: {
    data: {
      type: Object as PropType<ZoneRelationItem>,
      required: true,
    },
  },
});

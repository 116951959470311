import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 配列の最大個数チェックバリデーション.
 * 配列長が指定の長さ以内であること
 */
export default {
  params: ["max"],
  validate(value: [] | undefined | null, { max }: { max: number }): boolean {
    if (!value) {
      return true;
    }

    return value.length <= max;
  },
} as ValidationRuleSchema;





















import { ApiRequestError } from "@/error/errors";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ApiErrorModal",
  props: {
    /** エラー */
    error: {
      type: ApiRequestError as PropType<ApiRequestError>,
      required: true,
    },
  },
});














































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "VNConnectStatusModify",
  props: {
    wnumbers: {
      type: Array as PropType<string[]>,
      required: true,
    },
    status: {
      type: String as PropType<string>,
      required: true,
    },
  },
});

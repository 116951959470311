















































import Vue, { PropType } from "vue";
import { NaptGlobalIpEntity, NatNaptDetailEntity } from "@/apis/MSPeeringIpApi";

export default Vue.extend({
  name: "MSNaptSettingModifyConfirm",
  props: {
    /** 追加グローバルIP数 */
    addNapt: {
      type: Number as PropType<number>,
      required: true,
    },
    /** NAPT用グローバルIP設定情報 */
    naptSetting: {
      type: Object as PropType<NatNaptDetailEntity>,
      required: true,
    },
    /** Microsoft 払出済グローバルIP情報 */
    msNaptGlobalIpList: {
      type: Array as PropType<NaptGlobalIpEntity[]>,
      required: true,
    },
  },
});













































































import Vue, { PropType } from "vue";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyProfile from "@/components/policy/PolicyProfile.vue";
import PolicyService from "@/components/policy/PolicyService.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import { Type1UTMPolicy } from "@/modals/applianceContractSetting/InternetType1PolicyUtmList.vue";

export default Vue.extend({
  name: "InternetType1PolicyRuleIfwConfirm",
  components: {
    PolicyProfile,
    PolicyService,
    PolicyDstAddress,
    PolicySrcAddress,
  },
  props: {
    /** ポリシー */
    policy: {
      type: Object as PropType<Type1UTMPolicy>,
      required: true,
    },
  },
});

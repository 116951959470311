












































import Vue, { PropType } from "vue";
import { AgreementInfoList } from "@/apis/AgreementApi.ts";

export default Vue.extend({
  name: "AgreeStatusChangeConfirm",
  props: {
    /** 同意情報 */
    agreementItem: {
      type: Object as PropType<AgreementInfoList>,
      required: true,
    },
    /** 同意排他情報 */
    agreementKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    /** 同意内容情報 */
    agreementText: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      accepted: false,
      isScrollDown: false,
    };
  },
  computed: {
    agreeUserName(): string {
      return this.$store.state.user.me?.familyName
        ? this.$store.state.user.me?.familyName +
            this.$store.state.user.me?.givenName
        : "";
    },
    isAgree(): boolean {
      return this.agreementItem.agreementStatus === null;
    },
  },
  async mounted() {
    /**
     * nextTickではDOM構造が更新されたことを保証されるが、
     * DOMレンダリングが完了することが保証されるわけではなく、高さが取得出来ない。
     * そのため、setTimeoutを利用してDOMレンダリングが完了後にスクロールチェックを実施する。
     */
    setTimeout(() => {
      this.checkScrollArea();
    });
  },
  methods: {
    /**
     * 同意内容が最後まで表示された場合に同意するチェックボックスを押下可能にする
     */
    checkScrollArea() {
      const element = this.$refs.agree as HTMLElement;
      // 最下部までスクロールしたかの判定
      if (
        element.scrollHeight - (element.clientHeight + element.scrollTop) <
        1
      ) {
        this.isScrollDown = true;
      }
    },
    /** 同意 */
    async agree() {
      await this.$api.agreement.postAgreement(this.agreementItem.agreementSeq, {
        agreementKeyId: this.agreementKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 同意保留 */
    async hold() {
      await this.$api.agreement.postAgreementHold(
        this.agreementItem.agreementSeq,
        {
          agreementKeyId: this.agreementKeyId,
        }
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});
















































































import Vue from "vue";
import { PostOnetime } from "@/apis/PasswordApi";
import OneTimeUrlIssueModalComplete from "@/modals/password/OneTimeUrlIssueModalComplete.vue";

export default Vue.extend({
  name: "OneTimeUrlIssueModal",
  data() {
    return {
      form: {
        loginId: "",
        email: "",
        oneTimeUrl: process.env.VUE_APP_ONETIME_URL,
      } as PostOnetime,
    };
  },
  methods: {
    async submit() {
      await this.$api.password.postOnetime(this.form);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
      await this.$modal.show(OneTimeUrlIssueModalComplete);
    },
  },
});

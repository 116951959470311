/**
 * 指定された配列の長さを合計した値が最大数以下か
 * @param max 最大数
 * @param values 配列（複数可能）
 */
export function maxConcatArray(
  max: number,
  ...values: (unknown[] | null | undefined)[]
): boolean {
  const concatLength = values.reduce((acc, e) => (e ? acc + e.length : acc), 0);
  return concatLength <= max;
}

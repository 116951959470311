import enumTo from "./enum-to.filter";

/**
 * 帯域（bandwidth）を表示形式に変換する
 * 例1：bandwidth:{value: 100, unit: MBPS} -> 100Mbps
 * 例2：bandwidth:{value: 0, unit: null} -> 0
 * @param bandwidthItem 帯域値
 * @param defaultValue 変換できない場合の表示値
 */
export default function bandwidthTo(
  bandwidthItem: {
    value: number;
    unit: "KBPS" | "MBPS" | "GBPS" | null;
  },
  defaultValue = ""
): string {
  return `${bandwidthItem.value}${enumTo(
    bandwidthItem.unit,
    "bandwidth_unit",
    defaultValue
  )}`;
}

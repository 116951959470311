


































































































































































import Vue, { PropType } from "vue";
import { IpSecLineWithGW } from "@/views/ipSecLine/IpSecLineList.vue";
import IpSecLineViewCD from "@/modals/ipSecLine/IpSecLineViewCD.vue";
import IpSecLineEdit from "@/modals/ipSecLine/IpSecLineEdit.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "IpSecLineReference",
  props: {
    /** IPsec回線 + IPsecGW */
    ipSecLine: {
      type: Object as PropType<IpSecLineWithGW>,
      required: true,
    },
    /** IPsec回線排他情報 */
    keyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      localIpSecLine: this.ipSecLine,
      localKeyId: this.keyId,
      isChanged: false,
    };
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
  },
  methods: {
    // IPsec回線情報変更
    async modifyIpSec() {
      await this.alertClear();
      await this.$modal.show(IpSecLineEdit, {
        ipSecLine: this.localIpSecLine,
        acLineKeyId: this.localKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    // IPsec回線削除
    async deleteIpSec() {
      await this.alertClear();
      const note: string | null = await this.$modal.show(IpSecLineViewCD, {
        mode: "delete",
        lines: [this.localIpSecLine],
      });
      await this.$api.ipSecLine.delete({
        ipsecList: [this.localIpSecLine.xnumber],
        acLineKeyId: this.localKeyId,
        note: note,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    async executed() {
      this.isChanged = true;
      // 排他キーやifStatusを更新するために一覧を取得
      const latestIpsecLine = await this.$api.ipSecLine.getAll();
      const latestIpsec = latestIpsecLine.ipsecList.find(
        (e) => e.xnumber === this.localIpSecLine.xnumber
      )!;
      this.localIpSecLine = {
        ...latestIpsec,
        gw: this.localIpSecLine.gw,
      };
      this.localKeyId = latestIpsecLine.acLineKeyId;
    },

    /** エラーメッセージを初期化する */
    async alertClear() {
      (
        this.$refs.operationCommand as Vue & { alertClear: () => void }
      ).alertClear();
    },
  },
});

import { Priority, VlanType } from "@/apis/VNConnectApi";
import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 * IF状態
 * NO_SHUTDOWN: 開放状態
 * SHUTDOWN: 閉塞状態
 */
type IfStatus = "NO_SHUTDOWN" | "SHUTDOWN";

export interface Bgp4Peer {
  /**
   * ピア設定名
   * PEER1: Peer1
   * PEER2: Peer2
   */
  peerName: "PEER1" | "PEER2";
  /**
   * ピアIPアドレス
   * サブネットマスクなし
   */
  peerIpAddress: string | null;
  /**
   * AS番号
   * 1～9995、9997～64949、64970～65501、65504～65535が指定可能
   */
  asNumber: number | null | string;
  /**
   * MD5キー
   * NULLの場合は無効扱い
   */
  md5Key: string | null;
  /**
   * 最大プレフィックス
   * 上限値設定の最大値までが設定可能
   */
  maximumPrefix: 100 | 200 | 300 | 400 | 500 | 1000 | 1500 | 2000;
  /**
   * MED
   * HIGH_PRIORITY:優先
   * LOW_PRIORITY:非優先
   */
  med: Priority;
  /**
   * Local Preference
   * HIGH_PRIORITY:優先
   * LOW_PRIORITY:非優先
   */
  localPreference: Priority;
  /**
   * 経路集約フラグ
   * true: ON
   * false: OFF
   */
  isRouteAggregation: boolean;
}

/**
 * Staticルーティングの設定
 */
export interface StaticRouting {
  /**
   * LANアドレス
   * ネクストホップアドレスとセットで設定する.
   */
  lanAddress: string;
  /**
   * ネクストホップアドレス
   * サブネットマスクなしで設定する.また設定する際はLANアドレスとセットで設定する.
   */
  nextHopAddress: string;
}

/**
 * OSPFの設定
 */
export interface Ospf {
  /** エリア番号 */
  areaNumber: string;
}

/**
 * ルーティング種別
 * 必須項目
 * BGP4：BGP4ルーティング
 * STATIC：静的ルーティング
 * OSPF：OSPFルーティング
 */
type RoutingType = "BGP4" | "STATIC" | "OSPF";

export interface VNL2L3ConnectsResponse {
  /** VNL2L3コネクトの総件数 */
  total: number;
  /**
   * VNL2L3コネクトリスト
   * VNL2L3コネクトの情報がない場合空配列になる.
   * */
  vnl2l3ConnectList: VNL2L3Connect[];
  /** VNL2L3コネクト設定排他情報 */
  vnl2l3ConnectKeyId: string;
}

export interface VNL2L3Connect {
  /** E番号 */
  enumber: string | null;
  /** W番号 */
  wnumber: string;
  /** VNL2L3コネクト名 */
  vnConnectName: string;
  /** ルーティング種別 */
  routingType: RoutingType;
  /** L2仮想ネットワークのVLAN IDのType指定 */
  l2VnVlanIdType: VlanType;
  /** L2仮想ネットワークのVLAN ID */
  l2VnVlanId: number | null;
  /** VN L2/L3コネクトのVLAN IDのType指定 */
  vnl2l3VlanIdType: Exclude<VlanType, "OTHER">;
  /** VN L2/L3コネクトのVLAN ID */
  vnl2l3VnVlanId: number | null;
  /** 設定値の説明 */
  description: string | null;
  /** L2仮想ネットワーク名 */
  l2VnName: string;
  /** L2VNコード */
  l2VnCode: string;
  /** L3仮想ネットワーク名 */
  l3VpnVnName: string;
  /**
   * L3VPN/VNコード
   * Bから始まる場合はVPN.それ以外の場合はVN.
   * */
  l3VpnVnCode: string;
}

export interface VNL2L3ConnectDetail {
  /**
   * VNL2L3コネクト設定排他情報
   * 排他確認用パラメータ.
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと.
   */
  vnl2l3ConnectKeyId: string;
  /** L2仮想ネットワーク名 */
  l2VnName: string;
  /** L2VNコード */
  l2VnCode: string;
  /** L3仮想ネットワーク名 */
  l3VpnVnName: string;
  /**
   * L3VPN/VNコード
   * Bから始まる場合はVPN.それ以外の場合はVN
   */
  l3VpnVnCode: string;
  /** VNL2L3コネクト名 */
  vnConnectName: string;
  /**
   * L3VN_BGP4
   * ルーティング種別で「BGP4」以外を選択した場合NULLになる
   */
  bgp4: Bgp4Peer[] | null;
  /**
   * L3VN_STATIC
   * ルーティング種別で「STATIC」以外を選択した場合,または「LANアドレス」と「ネクストホップアドレス」を登録しない場合は空配列になる
   */
  static: StaticRouting[];
  /**
   * L3VN_OSPF
   * ルーティング種別で「OSPF」以外を選択した場合NULLになる
   */
  ospf: Ospf | null;
  /** 設定値の説明 */
  description: string | null;
  /** E番号 */
  enumber: string;
  /**
   * L2仮想ネットワークのVLAN IDのType指定
   * 必須項目
   * UNTAG: IDの指定なし
   * OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
   * SPECIFIED: 指定ID
   */
  l2VnVlanIdType: VlanType;
  /**
   * L2仮想ネットワークのVLAN ID
   * l2VnVlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに必須項目
   */
  l2VnVlanId: number | null;
  /**
   * VN L2/L3コネクトのVLAN IDのType指定
   * l2VnVlanIdTypeで「OTHER」を選択しているときに必須項目
   * 「OTHER」以外が選択されている場合、l2VnVlanIdTypeと同値を返却
   * UNTAG: IDの指定なし
   * SPECIFIED: 指定ID
   */
  vnl2l3VlanIdType: VlanType;
  /**
   * VN L2/L3コネクトのVLAN ID
   * vnl2l3VlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに必須項目
   */
  vnl2l3VnVlanId: number | null;
  /** ルーティング種別 */
  routingType: RoutingType;
  /** WANアドレス */
  wanAddress: string;
  /** IF状態 */
  ifStatus: IfStatus;
}

export type CreateVNL2L3ConnectRequest = Pick<
  VNL2L3ConnectDetail,
  | "vnl2l3ConnectKeyId"
  | "l2VnCode"
  | "l3VpnVnCode"
  | "vnConnectName"
  | "bgp4"
  | "static"
  | "ospf"
  | "description"
  | "enumber"
  | "vnl2l3VnVlanId"
  | "routingType"
  | "wanAddress"
> & { note: string | null } & { vnl2l3VlanIdType: "UNTAG" | "SPECIFIED" };

export type UpdateVNL2L3ConnectRequest = Pick<
  VNL2L3ConnectDetail,
  | "vnl2l3ConnectKeyId"
  | "vnConnectName"
  | "bgp4"
  | "static"
  | "ospf"
  | "description"
  | "routingType"
> & { note?: string };

/**
 * VNL2L3コネクト系API
 */
export interface VNL2L3ConnectKeyRequest {
  /**
   * VNL2L3コネクト設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと.
   */
  vnl2l3ConnectKeyId?: string;
}

/**VNL2L3コネクト回線リストAPI*/
export interface VNL2L3ConnectLines {
  lineList: string[];
}

export default class VNL2L3ConnectApi extends AbstractBaseApi {
  /** VN L2/L3回線を取得 */
  async getVNL2L3lines(): Promise<VNL2L3ConnectLines> {
    return (await this.api.get("/v1/network/vnetwork/vnl2l3lines")).data;
  }
  /**
   * VNL2L3コネクトを一覧取得
   */
  async getVNL2L3Connects(): Promise<VNL2L3ConnectsResponse> {
    return (await this.api.get("/v1/network/vnetwork/vnl2l3connects")).data;
  }
  /**
   * VN L2/L3コネクト設定を行う(追加)
   * @param data VNL2L3コネクト設定情報
   */
  async createVNL2L3Connect(data: CreateVNL2L3ConnectRequest): Promise<void> {
    await this.api.post("/v1/network/vnetwork/vnl2l3connect", data);
  }

  /**
   * VNL2L3コネクト情報詳細を取得
   * @param wnumber 詳細取得対象のW番号
   */
  async getVNL2L3ConnectDetail(wnumber: string): Promise<VNL2L3ConnectDetail> {
    return (await this.api.get(`/v1/network/vnetwork/vnl2l3connect/${wnumber}`))
      .data;
  }

  /**
   * VN L2/L3コネクト設定を行う(変更)
   * @param wnumber 変更対象のW番号
   * @param data VNL2L3コネクト設定情報
   */
  async updateVNL2L3Connect(
    wnumber: string,
    data: UpdateVNL2L3ConnectRequest
  ): Promise<void> {
    await this.api.post(`/v1/network/vnetwork/vnl2l3connect/${wnumber}`, data);
  }

  /**
   * VN L2/L3コネクト設定を行う(削除)
   * @param wnumber VNコネクトを削除したいW番号
   * @param data VNL2L3コネクト設定排他情報
   */
  async deleteVNL2L3Connect(
    wnumber: string,
    data: VNL2L3ConnectKeyRequest
  ): Promise<void> {
    await this.api.post(
      `/v1/network/vnetwork/vnl2l3connect/${wnumber}/delete`,
      data
    );
  }

  /**
   * VNL2L3コネクト一覧のCSV取得用のURLを返却
   */
  getVNL2L3connectCsvUrl(): string {
    return this.getFullURL("/v1/network/vnetwork/vnl2l3connects/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
}

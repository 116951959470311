

















import Vue, { PropType } from "vue";
import { CommonPolicy, GetPolicy } from "@/apis/NwPolicyApi";

/** インターネットType1,4のポリシーのサービス表示が出来るコンポーネント */
export default Vue.extend({
  name: "PolicyService",
  props: {
    /** サービス選択オプション */
    serviceOption: {
      type: String as PropType<CommonPolicy["serviceOption"]>,
      required: true,
    },
    /** デフォルトサービスリスト */
    defaultServiceList: {
      type: Array as PropType<GetPolicy["defaultServiceList"]>,
      required: true,
    },
    /** カスタムサービスリスト */
    customServiceList: {
      type: Array as PropType<GetPolicy["customServiceList"]>,
      required: true,
    },
  },
  computed: {
    /** 昇順ソート済みのデフォルトサービスリスト */
    defaultServices(): GetPolicy["defaultServiceList"] {
      return this.defaultServiceList.sortBy(["serviceName", "asc"]);
    },
    /** 昇順ソート済みのカスタムサービスリスト */
    customServices(): GetPolicy["customServiceList"] {
      return this.customServiceList.sortBy(["serviceName", "asc"]);
    },
  },
});

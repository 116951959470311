import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** QoSの設定情報 */
export interface QosInfoList {
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  /** QoS情報 データが存在しない場合、[](空の状態)になります。 */
  qosInfoList: QosInfo[];
}

export interface QosInfo {
  /** VPN/VNコード 主キー */
  vpnVnCode: string;
  /** 仮想ネットワーク名 */
  vnName: string | null;
  /**
   * VLAN IDのType指定
   * L2回線のときのみ適用項目
   * UNTAG: IDの指定なし,
   * OTHER: 他の仮想ネットワークで指定されていないID,もしくは指定なし,
   * SPECIFIED: 指定ID
   */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED" | null;
  /** VLAN ID. L2回線のときかつ、vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに取得可能 */
  vlanId: number | null;
  /** QoS方式
   * 以下のいずれかを返す。
   * BANDWIDTH: 帯域制御方式
   * ABSOLUTE: 絶対優先方式
   * NONE: 未設定 */
  qosType: "BANDWIDTH" | "ABSOLUTE" | "NONE";
  /**
   * 優先度参照先
   * 以下のいずれかを返す。
   * IPPRECEDENCE: IP Precedence
   * COS: CoS
   * NONE: 未設定
   */
  priorityTarget: "IPPRECEDENCE" | "COS" | "NONE";
  /** VNコネクト数 */
  numberOfVnConnect: number;
  /** VNコネクト数 最大値 */
  upperLimitVnConnect: number;
  /** 設定可否情報. ENABLE、DISABLEのいずれかを指定する。 */
  qosControl: "ENABLE" | "DISABLE";
}

/** QoSの設定詳細情報 */
export interface QosDetail {
  /** QoS方式. 以下のいずれかを返す。 BANDWIDTH: 帯域制御方式, ABSOLUTE: 絶対優先方式, NONE: 未設定 */
  qosType: "BANDWIDTH" | "ABSOLUTE" | "NONE";
  /** 優先度参照先. 以下のいずれかを返す。 IPPRECEDENCE: IP Precedence, COS: CoS, NONE: 未設定 */
  priorityTarget: "IPPRECEDENCE" | "COS" | "NONE";
  /** 絶対優先設定値情報. 絶対優先方式の設定値情報。qosTypeがABSOLUTEの場合は必須、それ以外の場合はNULLになります。 */
  qosAbsolute: {
    /** 絶対優先方式 優先度0設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority0: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度1設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority1: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度2設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority2: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度3設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority3: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度4設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority4: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度5設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority5: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度6設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority6: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度7設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority7: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
  } | null;
  /** 帯域制御設定値情報(詳細取得用) 帯域制御方式の設定値情報。qosTypeがBANDWIDTHの場合は必須、それ以外の場合はNULLになります。 */
  qosBandwidth: QosBandwidth | null;

  /**
   * QoS設定排他情報
   * 排他確認用パラメータ
   * PUTメソッドにて変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  qosKeyId: string;
}

/**
 * 登録用QoSの設定情報
 * 取得用との差分はqosBandwidth.qosBandwidthList[*].bestEffort のプロパティが無い
 */
export type PutQosDetail = Omit<QosDetail, "qosBandwidth"> & {
  qosBandwidth:
    | (Omit<QosBandwidth, "qosBandwidthList"> & {
        qosBandwidthList: Omit<QosBandwidthItem, "bestEffort">[];
      })
    | null;
};

/** 帯域制御設定値情報 */
export interface QosBandwidth {
  /** 帯域制御方式 優先度タイプ1設定種別. 0, 1, 2, 3, 4～7のいずれかを指定する。 */
  priorityType1:
    | "VALUE_0"
    | "VALUE_1"
    | "VALUE_2"
    | "VALUE_3"
    | "VALUE_4TO7"
    | null;
  /** 帯域制御方式 優先度タイプ2設定種別. 0, 1, 2, 3, 4～7のいずれかを指定する。 */
  priorityType2:
    | "VALUE_0"
    | "VALUE_1"
    | "VALUE_2"
    | "VALUE_3"
    | "VALUE_4TO7"
    | null;
  /** 帯域制御方式 優先度タイプ3設定種別. 0, 1, 2, 3, 4～7のいずれかを指定する。 */
  priorityType3:
    | "VALUE_0"
    | "VALUE_1"
    | "VALUE_2"
    | "VALUE_3"
    | "VALUE_4TO7"
    | null;
  qosBandwidthList: QosBandwidthItem[];
}

/** 各パターンの帯域制御設定値情報 */
export interface QosBandwidthItem {
  /** パターン名. パターンA～パターンEのいずれかを指定する。 */
  patternName:
    | "PATTERN_A"
    | "PATTERN_B"
    | "PATTERN_C"
    | "PATTERN_D"
    | "PATTERN_E";
  /** 帯域制御方式 優先度タイプ1設定値. 帯域制御方式 上段項目に設定する値を指定する。 */
  priorityType1Value: number | null;
  /** 帯域制御方式 優先度タイプ2設定値. 帯域制御方式 中段項目に設定する値を指定する。 */
  priorityType2Value: number | null;
  /** 帯域制御方式 優先度タイプ3設定値. 帯域制御方式 下段項目に設定する値を指定する。 */
  priorityType3Value: number | null;
  /**
   * 帯域制御方式 ベストエフォート値
   * 帯域制御方式 ベストエフォート項目に設定する値を指定する。
   * 「100 - (上段～下段のすべての設定値の合計)」の値となる。
   * GET：計算・設定済みの内容を返却
   * PUT：APIにて各設定値から計算し、設定を行うため通知不要*/
  bestEffort: number;
  /** 帯域制御方式 優先度タイプ1最優先設定フラグ. ON: true、OFF: falseのいずれかを指定する。 */
  isType1TopPriority: boolean;
}

/** 絶対優先デフォルトパターン */
export interface AbsoluteDefaultPattern {
  patternA: {
    /** 絶対優先方式-デフォルトパターンA-優先度0設定値. (NORMAL固定) */
    priority0: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンA-優先度1設定値. (LOW固定) */
    priority1: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンA-優先度2設定値. (LOW固定) */
    priority2: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンA-優先度3設定値. (NORMAL固定) */
    priority3: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンA-優先度4設定値. (MEDIUM固定) */
    priority4: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンA-優先度5設定値. (MEDIUM固定) */
    priority5: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンA-優先度6設定値. (HIGH固定) */
    priority6: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンA-優先度7設定値. (HIGH固定) */
    priority7: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
  };
  patternBCos: {
    /** 絶対優先方式-デフォルトパターンB(CoS)-優先度0設定値. (LOW固定) */
    priority0: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(CoS)-優先度1設定値. (LOW固定) */
    priority1: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(CoS)-優先度2設定値. (NORMAL固定) */
    priority2: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(CoS)-優先度3設定値. (LOW固定) */
    priority3: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(CoS)-優先度4設定値. (MEDIUM固定) */
    priority4: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(CoS)-優先度5設定値. (HIGH固定) */
    priority5: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(CoS)-優先度6設定値. (MEDIUM固定) */
    priority6: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(CoS)-優先度7設定値. (MEDIUM固定) */
    priority7: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
  };
  patternBIp: {
    /** 絶対優先方式-デフォルトパターンB(IP precedence)-優先度0設定値. (LOW固定) */
    priority0: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(IP precedence)-優先度1設定値. (LOW固定) */
    priority1: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(IP precedence)-優先度2設定値. (NORMAL固定) */
    priority2: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(IP precedence)-優先度3設定値. (LOW固定) */
    priority3: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(IP precedence)-優先度4設定値. (MEDIUM固定) */
    priority4: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(IP precedence)-優先度5設定値. (HIGH固定) */
    priority5: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(IP precedence)-優先度6設定値. (HIGH固定) */
    priority6: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式-デフォルトパターンB(IP precedence)-優先度7設定値. (MEDIUM固定) */
    priority7: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
  };
}

export default class QosApi extends AbstractBaseApi {
  /** QoS設定情報を取得 */
  async getQoSInfoList(): Promise<QosInfo[]> {
    return (
      (await this.api.get("/v1/network/vnetwork/qos")).data as QosInfoList
    ).qosInfoList;
  }
  /** 絶対優先方式におけるQoS設定のデフォルトパターン情報を取得 */
  async getAbsoluteDefaultPatterns(): Promise<AbsoluteDefaultPattern> {
    return (
      await this.api.get("/v1/network/vnetwork/qos/absolute/default-patterns")
    ).data;
  }
  /**
   * QoS設定詳細を1件取得
   * @param vpnVnCode
   */
  async getQosDetail(vpnVnCode: string): Promise<QosDetail> {
    return (await this.api.get(`/v1/network/vnetwork/${vpnVnCode}/qos`)).data;
  }
  /**
   * QoS設定の変更を行う
   * @param vpnVnCode vpnVnCode
   * @param data QoS設定情報
   */
  async putQosDetail(vpnVnCode: string, data: PutQosDetail): Promise<void> {
    await this.api.post(`/v1/network/vnetwork/${vpnVnCode}/qos`, data);
  }

  /**
   * QoS設定情報をCSVにてダウンロードする。
   */
  getCsvQosURL(): string {
    return this.getFullURL("/v1/network/vnetwork/qos/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
}

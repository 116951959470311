var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-large-plus","title":"インターネットサイト設定(Type4)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('b-tabs',{attrs:{"card":"","content-class":"mt-3","fill":""}},[_c('b-tab',{attrs:{"title":"アドレス","active":""}},[_c('div',[_c('b-form-group',[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4 ml-1"},[_c('app-badge',{attrs:{"item":"アドレス名","msg":_vm._f("msg")('info_max_length',{ maxLength: 20 }),"isRequired":false,"placement":"right"}})],1),_c('div',{staticClass:"col-4 mr-4"},[_c('app-badge',{attrs:{"item":"アドレス","msg":_vm._f("msg")('info_cidr_ip_address_of_network_address',{
                            ipaddress1: '61.200.163.0/24',
                            genre: 'アドレス',
                            subnet1: '1',
                            subnet2: '30',
                            ipaddress2: '61.200.163.35/32',
                          }),"placement":"right","is-required":_vm.hasInputIpAddressName || _vm.addressList.length !== 1}})],1),_c('div',{staticClass:"col mr-5"},[_c('app-badge',{attrs:{"item":"ポリシー使用有無","isRequired":false,"is-info":false}})],1)]),_vm._l((_vm.addressList),function(e,index){return _c('div',{key:index,staticClass:"row align-items-center mb-2"},[_c('validation-provider',{staticClass:"col-4",attrs:{"name":'アドレス名' + index,"rules":{
                      max: 20,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"disabled":e.isPolicyInUse || (_vm.isPolicy && e.ipAddressId !== ''),"state":_vm._f("validState")(validContext)},model:{value:(e.ipAddressName),callback:function ($$v) {_vm.$set(e, "ipAddressName", $$v)},expression:"e.ipAddressName"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}),_c('validation-provider',{staticClass:"col-4 ml-2",attrs:{"name":"アドレス","vid":("address" + index),"rules":{
                      required: !!e.ipAddressName || _vm.addressList.length !== 1,
                      is_ip_subnet: { min: 1, max: 32, exclude: [31] },
                      is_not_current_ip: true,
                      is_not_loopback_ip: true,
                      is_not_multicast_ip: true,
                      is_not_class_e_ip: true,
                      is_not_broadcast_ip: true,
                      is_network_address: true,
                      duplicate: { list: _vm.existsAddress(index) },
                    }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"disabled":e.isPolicyInUse || (_vm.isPolicy && e.ipAddressId !== ''),"state":_vm._f("validState")(validContext),"placeholder":e.isPolicyInUse
                          ? null
                          : _vm.$msg(
                              'placeholder_global_ip_address_with_subnet32'
                            )},model:{value:(e.ipAddress),callback:function ($$v) {_vm.$set(e, "ipAddress", $$v)},expression:"e.ipAddress"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}),_c('div',{staticClass:"col ml-5 mt-2"},[_c('span',[_vm._v(_vm._s(_vm._f("enumTo")(e.isPolicyInUse,"isPolicyInUse")))])]),(
                      (!e.isPolicyInUse && !_vm.isPolicy) ||
                      (e.ipAddressId === '' && _vm.isPolicy)
                    )?_c('div',{staticClass:"col-0 mr-3"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.removeIPAddress(e)}}},[_c('b-icon-dash')],1)],1):_vm._e()],1)}),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.addIPAddress}},[_c('b-icon-plus')],1)],1)])],2)],1)]),_c('b-tab',{attrs:{"title":"FQDN"}},[_c('div',[_c('b-form-group',[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4 ml-1"},[_c('app-badge',{attrs:{"item":"FQDN名","msg":_vm._f("msg")('info_max_length',{ maxLength: 20 }),"isRequired":false,"placement":"right"}})],1),_c('div',{staticClass:"col-4 mr-4"},[_c('app-badge',{attrs:{"item":"FQDN","msg":_vm._f("msg")('info_fqdn'),"placement":"right","is-required":_vm.hasInputFqdnName || _vm.fqdnList.length !== 1}})],1),_c('div',{staticClass:"col mr-5"},[_c('app-badge',{attrs:{"item":"ポリシー使用有無","isRequired":false,"is-info":false}})],1)]),_vm._l((_vm.fqdnList),function(e,index){return _c('div',{key:index,staticClass:"row align-items-center mb-2"},[_c('validation-provider',{staticClass:"col-4",attrs:{"name":'FQDN名' + index,"rules":{
                      max: 20,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"disabled":e.isPolicyInUse || (_vm.isPolicy && e.fqdnId !== ''),"state":_vm._f("validState")(validContext)},model:{value:(e.fqdnName),callback:function ($$v) {_vm.$set(e, "fqdnName", $$v)},expression:"e.fqdnName"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}),_c('validation-provider',{staticClass:"col-4 ml-2",attrs:{"vid":("FQDN" + index),"name":"FQDN","rules":{
                      required: !!e.fqdnName || _vm.fqdnList.length !== 1,
                      is_fqdn: true,
                      is_not_both: ['-', '.'],
                      min: 3,
                      max: 255,
                      duplicate: { list: _vm.existsFqdn(index) },
                    }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"disabled":e.isPolicyInUse || (_vm.isPolicy && e.fqdnId !== ''),"state":_vm._f("validState")(validContext)},model:{value:(e.fqdn),callback:function ($$v) {_vm.$set(e, "fqdn", $$v)},expression:"e.fqdn"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}),_c('div',{staticClass:"col ml-5 mt-2"},[_c('span',[_vm._v(_vm._s(_vm._f("enumTo")(e.isPolicyInUse,"isPolicyInUse")))])]),(
                      (!e.isPolicyInUse && !_vm.isPolicy) ||
                      (e.fqdnId === '' && _vm.isPolicy)
                    )?_c('div',{staticClass:"col-0 mr-3"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.removeFqdn(e)}}},[_c('b-icon-dash')],1)],1):_vm._e()],1)}),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.addFqdn}},[_c('b-icon-plus')],1)],1)])],2)],1)])],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("変更")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)],1)]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
import AbstractBaseApi from "@/apis/AbstractBaseApi";

export interface LineList {
  /** 回線総件数. 一覧表示対象の総件数 */
  lineTotal: number;
  /** 取得した回線情報全件。データが存在しない場合、[](空の状態)になる。*/
  lineList: Line[];
  /**
   * クラウド回線排他情報. 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  cloudLineKeyId: string;
}
export interface Line {
  /** 回線SEQ */
  lineSeq: string;
  /** E番号 */
  enumber: string;
  /**
   * アクセス品目.
   * 以下のいずれかを指定する。
   * MULTI_CLOUD_GW：マルチクラウドGW
   */
  accessType: "MULTI_CLOUD_GW";
  /** 帯域 GET時に登録された帯域が存在しない場合は「0」を指定する。*/
  bandwidth: {
    /** 設定値 */
    value: number;
    /**
     * 単位. 以下のいずれかを設定する。
     * KBPS: Kbps
     * MBPS: Mbps
     * GBPS: Gbps
     */
    unit: "KBPS" | "MBPS" | "GBPS" | null;
  };
  /** 事業所名 */
  office: string | null;
  /** クラウド回線総件数  一覧表示対象の総件数*/
  cloudLineTotal: number;
  /** 取得したクラウド回線情報全件。データが存在しない場合、[](空の状態)になる */
  cloudLineList: CloudLine[];
}

export interface CloudLine {
  /** クラウド回線SEQ 主キー */
  cloudLineSeq: string;
  /** 帯域上限値(単位は「Gbps」固定) */
  maxBandwidth: number;
  /** 項目和名：帯域
   * GET時に登録された帯域が存在しない場合は「0」を指定する。 */
  bandwidth: {
    /** 設定値 */
    value: number;
    /**
     * 単位. 以下のいずれかを設定する。
     * KBPS: Kbps
     * MBPS: Mbps
     * GBPS: Gbps
     */
    unit: "KBPS" | "MBPS" | "GBPS" | null;
  };
  /** 設定値の説明 クラウド回線用の備考 */
  description: string | null;
  /** サービス種別  以下のいずれかを指定する
   * typescriptのバグを避けるため、`MS${number}`の場合は、stringと扱い
   * @see https://github.com/microsoft/TypeScript/pull/41693
   */
  cloudServiceMenu: "AWS" | string | "GCP" | "Oracle" | "IBM" | "SFDC";
}

export interface CloudMsDelete {
  /**
   * 操作履歴の備考
   */
  note?: string;
  /**
   * クラウド回線排他情報
   * 排他確認用パラメータ、設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと
   */
  cloudLineKeyId: string;
}

export interface CloudMsPost {
  /**
   * 帯域上限値
   */
  bandwidth: {
    value: number;
    unit: "KBPS" | "MBPS" | "GBPS" | null;
  };

  /**
   * 設定値の説明 クラウド回線用の備考
   * 排他確認用パラメータ、設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと
   */
  description: string | null;

  /**
   * サービス種別
   * 以下のいずれかを指定する(POSTではMSのみ指定可能)
   */
  cloudServiceMenu: "MS";

  /**
   * 操作履歴の備考
   */
  note: string | null;

  /**
   * クラウド回線排他情報
   * 排他確認用パラメータ、設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと
   */
  cloudLineKeyId: string;
}

export type CloudMsPut = Omit<CloudMsPost, "cloudServiceMenu">;

/** クラウド回線API */
export default class CloudLineApi extends AbstractBaseApi {
  /**
   * 回線情報に紐つくクラウド回線情報を取得
   */
  async getCloudLines(): Promise<LineList> {
    return (await this.api.get(`/v3/network/cloud/lines`)).data;
  }

  /**
   * 回線情報に紐づくクラウド回線情報を追加する。サービス種別がMSの情報のみ追加可能となる。
   * @param enumber E番号
   * @param data MS回線情報
   */
  async postMsCloudLine(enumber: string, data: CloudMsPost): Promise<void> {
    await this.api.post(`/v1/network/cloud/line/ms/${enumber}`, data);
  }

  /**
   * 回線情報に紐づくクラウド回線情報を変更
   * @param cloudLineSeq クラウド回線SEQ
   * @param data MS回線情報
   */
  async putMsCloudLine(cloudLineSeq: string, data: CloudMsPut): Promise<void> {
    await this.api.post(`/v1/network/cloud/line/${cloudLineSeq}`, data);
  }

  /**
   * 回線情報に紐づくクラウド回線情報を削除する。サービス種別がMSの情報のみ削除可能となる。
   * @param cloudLineSeq クラウド回線SEQ
   * @param data 削除情報
   */
  async deleteMsCloudLine(
    cloudLineSeq: string,
    data: CloudMsDelete
  ): Promise<void> {
    await this.api.post(`/v1/network/cloud/line/${cloudLineSeq}/delete`, data);
  }

  /**
   * V番号に紐づくクラウドVNコネクト設定情報をCSVにてダウンロードする。
   */
  getCsvVnConnectURL(): string {
    return this.getFullURL("/v1/network/cloud/lines/csv/vnconnect", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
}

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * クラスEアドレスではないことを確認
 * @see {@link https://www.janog.gr.jp/meeting/janog47/llt5}
 *
 * チェック内容:
 *    サブネット無しのIPの場合 → クラスE（240.0.0.0/4）ではないことを確認
 *    サブネット付きのIPの場合 → クラスE（240.0.0.0/4）以外のアドレスがないことを確認
 */
export default {
  validate: function (value: string | null | undefined): boolean {
    if (!value) return true;

    const specialAddress = ipaddr.IPv4.parseCIDR("240.0.0.0/4");
    let ipV4: ipaddr.IPv4 | [ipaddr.IPv4, number];
    try {
      if (value.indexOf("/") !== -1) {
        ipV4 = ipaddr.IPv4.parseCIDR(value);
      } else {
        ipV4 = ipaddr.IPv4.parse(value);
      }
    } catch (e) {
      return true;
    }
    // サブネット付きのIPの場合
    if (Array.isArray(ipV4)) {
      // クラスE（240.0.0.0/4）以外のアドレスがないことを確認
      return !ipaddr.IPv4.networkAddressFromCIDR(value).match(specialAddress);
    } else {
      // サブネット無しのIPの場合 → クラスE（240.0.0.0/4）ではないことを確認
      return !ipV4.match(specialAddress);
    }
  },
} as ValidationRuleSchema;

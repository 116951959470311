






































































































































































































































import Vue, { PropType } from "vue";
import { GetPolicyType1, GetPolicyType4 } from "@/apis/NwPolicyApi";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicyService from "@/components/policy/PolicyService.vue";
import PolicyProfile from "@/components/policy/PolicyProfile.vue";
import PolicyIpPool from "@/components/policy/PolicyIpPool.vue";
import { Type1Service } from "@/apis/ContractChangeType1Api";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import { Type1IFWPolicy } from "@/modals/applianceContractSetting/InternetType1PolicyIfwList.vue";
import { mapState } from "vuex";

export type Type1NatPolicy =
  | NonNullable<GetPolicyType4["policyIPMasqueradeType4"]>["policyList"][0]
  | NonNullable<GetPolicyType4["policyNatType4"]>["policyList"][0];

export default Vue.extend({
  name: "InternetType1PolicyIfwConfirm",
  components: {
    PolicyIpPool,
    PolicyProfile,
    PolicyService,
    PolicyDstAddress,
    PolicySrcAddress,
  },
  props: {
    /** type1ポリシー全体 */
    type1Policy: {
      type: Object as PropType<GetPolicyType1>,
      required: true,
    },
    /** サービスメニュー(インターネットType1) */
    type1Service: {
      type: Object as PropType<Type1Service>,
      required: true,
    },
    /** ゾーンリレーション情報一覧 */
    zoneRelationList: {
      type: Array as PropType<ZoneRelationItem[]>,
      required: true,
    },
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
    displayRelationList(): ZoneRelationItem[] {
      // global -> private
      // global -> extra
      // extra -> global
      // private -> global
      // 上記のみ表示
      return this.zoneRelationList.filter(
        (v) =>
          (v.srcZoneType === "INTERNET" &&
            (v.dstZoneType === "PRIVATE" || v.dstZoneType === "EXTRA")) ||
          ((v.srcZoneType === "PRIVATE" || v.srcZoneType === "EXTRA") &&
            v.dstZoneType === "INTERNET")
      );
    },
    /** 表示用データ整形 */
    displayZoneInfo(): {
      relation: ZoneRelationItem;
      ifwPolicyList: Type1IFWPolicy[];
      natPolicyList: Type1NatPolicy[] | null;
    }[] {
      return this.displayRelationList.map((v) => {
        return {
          relation: v,
          ifwPolicyList:
            v.srcZoneType === "INTERNET"
              ? this.type1Policy.policyIfwType1?.internetToAccessPointList.find(
                  (item) => item.zoneRelationId === v.zoneRelationId
                )?.policyList ?? []
              : this.type1Policy.policyIfwType1?.accessPointToInternetList.find(
                  (item) => item.zoneRelationId === v.zoneRelationId
                )?.policyList ?? [],
          natPolicyList: this.contractSummary.isNat
            ? v.srcZoneType === "INTERNET"
              ? this.type1Policy.policyNatType1List?.find(
                  (item) => item.zoneRelationId === v.zoneRelationId
                )?.policyList ?? []
              : this.type1Policy.policyIPMasqueradeType1List?.find(
                  (item) => item.zoneRelationId === v.zoneRelationId
                )?.policyList ?? []
            : null,
        };
      });
    },
  },
});


























































import Vue, { PropType } from "vue";
import {
  Type1InternetAddress,
  Type1PrivateSite,
  Type1Zone,
} from "@/apis/Type1SiteApi";
import PrivateSiteModify from "@/modals/type1/PrivateSiteModify.vue";
import { AppTableData } from "@/components/AppTable.vue";

export default Vue.extend({
  name: "PrivateSiteReference",
  props: {
    enumber: {
      type: String,
      required: true,
    },
    siteList: {
      type: [] as PropType<Type1PrivateSite>,
      required: true,
    },
    zoneList: {
      type: [] as PropType<Type1Zone[]>,
      required: true,
    },
    type1SiteZoneKeyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      privateData: {
        fields: [
          {
            key: "ipAddressName",
            label: "アドレス名",
          },
          {
            key: "ipAddress",
            label: "アドレス",
          },
          {
            key: "zoneId",
            label: "ゾーン配置",
          },
        ],
        items: [],
      } as Pick<AppTableData<Type1InternetAddress>, "fields" | "items">,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    /** 初期化機能 */
    load() {
      this.privateData.items = this.siteList.addressList;
    },
    /** 変更機能 */
    async toChange() {
      await this.$modal.show(PrivateSiteModify, {
        enumber: this.enumber,
        siteList: this.siteList,
        zoneList: this.zoneList,
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** IDからNameへ変換 */
    idToName(value: string | null) {
      return (
        this.zoneList.find((item) => item.zoneId === value)?.zoneName ??
        "未配置"
      );
    },
  },
});






































import Vue, { PropType } from "vue";
import { CpaContract, CpaNwDetail, CpaNwOptions } from "@/apis/Cpa5gSaApi";

export default Vue.extend({
  name: "SaNwServiceStartUsingConfirm",
  props: {
    /** CPANW契約情報 */
    cpaContractInfo: {
      type: Object as PropType<CpaContract>,
      required: true,
    },
  },
  data() {
    return {
      nwSettingDetail: {} as CpaNwDetail,
      nwOptions: {} as CpaNwOptions,
      isLoaded: false,
    };
  },
  async mounted() {
    this.nwSettingDetail = await this.$api.cpa5gSa.getCpaNwDetail(
      this.cpaContractInfo.cpaContractSeq
    );
    this.nwOptions = await this.$api.cpa5gSa.getCpaNwOptions(
      this.cpaContractInfo.cpaContractSeq
    );
    this.isLoaded = true;
  },
});

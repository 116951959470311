


















































































































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import cloneDeep from "lodash/cloneDeep";
import IntranetPolicySrcAddress from "@/components/policy/IntranetPolicySrcAddress.vue";
import IntranetPolicyDstAddress from "@/components/policy/IntranetPolicyDstAddress.vue";
import IntranetPolicyService from "@/components/policy/IntranetPolicyService.vue";
import { IntranetFwPolicy, IntraPolicy } from "@/apis/IntranetfwPolicyApi";
import IntranetFwPfgwPolicyRuleEdit from "@/modals/applianceContractSetting/IntranetFwPfgwPolicyRuleEdit.vue";
import { PfgwZoneType } from "@/modals/applianceContractSetting/IntranetFwPfgwZoneSelectList.vue";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";
import { Pfgw } from "@/apis/IntranetfwServiceMenuApi";

export default Vue.extend({
  name: "IntranetFwPfgwPolicyRuleViewRD",
  components: {
    IntranetPolicySrcAddress,
    IntranetPolicyDstAddress,
    IntranetPolicyService,
  },
  props: {
    /** 送信元ゾーン */
    srcZone: {
      type: String as PropType<PfgwZoneType>,
      required: true,
    },
    /** ポリシーリスト */
    policyList: {
      type: Array as PropType<IntraPolicy[]>,
      required: true,
    },
    /** イントラネットPFGWポリシー全体 */
    intranetFwPolicy: {
      type: Object as PropType<IntranetFwPolicy>,
      required: true,
    },
    /** PFGW */
    pfgw: {
      type: Object as PropType<Pfgw>,
      required: true,
    },
  },
  data() {
    return {
      intranetPolicyTable: {
        fields: [
          { key: "_handle", label: "↑↓", tdClass: "handle" },
          { key: "policyId", label: "ID" },
          { key: "srcAddress", label: "送信元アドレス" },
          { key: "dstAddress", label: "宛先アドレス" },
          { key: "service", label: "サービス" },
          { key: "actionType", label: "アクション" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
          { key: "delete", label: "" },
        ],
        items: cloneDeep(this.policyList),
      } as Pick<AppTableData<IntraPolicy>, "fields" | "items">,
      intranetFwPfgwPolicyLimit: [] as UpperLimitEntity[],
      isLoaded: false,
    };
  },
  computed: {
    /** true: 作成できるポリシー数の上限, false: 上限ではない */
    limitPolicy(): boolean {
      // イントラネットFW側のカスタムポリシー数（ゾーン横断）
      const totalPolicy =
        (this.latestIntranetFwPfgwPolicy.wvs2ToKcpsList
          .flatMap((e) => e.policyList)
          .filter((e) => e.policyCategoryInfo === "CUSTOM_POLICY").length ??
          0) +
        (this.latestIntranetFwPfgwPolicy.kcpsToWvs2List
          .flatMap((e) => e.policyList)
          .filter((e) => e.policyCategoryInfo === "CUSTOM_POLICY").length ?? 0);
      return (
        totalPolicy >=
        this.intranetFwPfgwPolicyLimit!.filter(
          (e) => e.upperLimitItemName === "ポリシー数(PFGW(イントラ))"
        )[0]!.upperLimit
      );
    },
    /** 最新の全体ポリシー（全体ポリシー + 編集中のポリシー ）*/
    latestIntranetFwPfgwPolicy(): IntranetFwPolicy {
      return {
        ...this.intranetFwPolicy,
        ...(this.srcZone === "WVS2"
          ? {
              wvs2ToKcpsList: this.intranetFwPolicy.wvs2ToKcpsList.map((e) => {
                if (e.enumberAct === this.pfgw.enumberAct) {
                  return { ...e, policyList: this.intranetPolicyTable.items };
                }
                return e;
              }),
            }
          : {}),
        ...(this.srcZone === "KCPS"
          ? {
              kcpsToWvs2List: this.intranetFwPolicy.kcpsToWvs2List.map((e) => {
                if (e.enumberAct === this.pfgw.enumberAct) {
                  return { ...e, policyList: this.intranetPolicyTable.items };
                }
                return e;
              }),
            }
          : {}),
      };
    },
  },
  async mounted() {
    /** イントラネットPFGWの設定上限値を取得 */
    this.intranetFwPfgwPolicyLimit = (
      await this.$api.upperLimit.getUpperLimitLine({
        upperLimitLineManageUnit: "PFGW",
        enumber: this.pfgw.enumberAct,
      })
    ).pfgwLine!.upperLimitList!;
    this.isLoaded = true;
  },
  methods: {
    /** イントラネットFWポリシー追加モーダルの表示 */
    async showIntraPolicyRegister() {
      // ポリシールール追加の表示 追加後のデータを受け取る
      const policy: IntraPolicy = await this.$modal.show(
        IntranetFwPfgwPolicyRuleEdit,
        {
          srcZone: this.srcZone,
          enumberAct: this.pfgw.enumberAct,
          allPolicy: [
            ...this.latestIntranetFwPfgwPolicy.kcpsToWvs2List,
            ...this.latestIntranetFwPfgwPolicy.wvs2ToKcpsList,
          ].flatMap((e) => e.policyList),
        }
      );

      // 編集不可ポリシーの位置を探索する(最上部・最下部にしか存在しない仕様)
      const last = this.intranetPolicyTable.items
        .map((e) => e.policyCategoryInfo)
        .lastIndexOf("UNEDITABLE_DEFAULT_POLICY");

      // 編集不可ポリシーが最下部にあれば、編集不可ポリシーの1つ前にデータを追加 それ以外は最後にデータを追加
      this.intranetPolicyTable.items.splice(
        last === -1 ? this.intranetPolicyTable.items.length : last,
        0,
        policy
      );
    },

    /** イントラネットFWポリシー変更モーダルの表示 */
    async showIntraPolicyModify(row: IntraPolicy, index: number) {
      // 編集不可デフォルトポリシーの場合は処理終了
      if (row.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY") {
        return;
      }

      // ポリシールール編集の表示 変更後のデータを受け取る
      const policy: IntraPolicy = await this.$modal.show(
        IntranetFwPfgwPolicyRuleEdit,
        {
          srcZone: this.srcZone,
          policy: row,
          enumberAct: this.pfgw.enumberAct,
        }
      );
      // 変更データの反映
      this.$set(this.intranetPolicyTable.items, index, policy);
    },

    /** ポリシー削除 */
    async deletePolicy(index: number) {
      this.intranetPolicyTable.items.splice(index, 1);
    },

    /** ポリシー編集完了 */
    async submit() {
      // 確認ダイアログの表示
      await this.$confirm(`ポリシーを保存します。よろしいですか？`);
      // OKが押された場合、編集されたポリシーを遷移元に返却する
      (this.$refs.modal as Vue & { ok: (e: IntraPolicy[]) => void }).ok(
        this.intranetPolicyTable.items
      );
    },

    /** ポリシーテーブルのtrクラス. */
    policyRowClass(
      item: Pick<IntraPolicy, "policyCategoryInfo">
    ): string[] | null {
      switch (item.policyCategoryInfo) {
        case "CUSTOM_POLICY":
          // ドラッグ可能
          return ["draggable"];
        case "EDITABLE_DEFAULT_POLICY":
          // ドラッグ可能
          return ["draggable"];
        case "UNEDITABLE_DEFAULT_POLICY":
          // ドラッグ不可 + 背景色グレーアウト
          return ["not-editable-policy", "un-clickable"];
      }
    },
  },
});

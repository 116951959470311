import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 通常の必須項目とは別で、特定の値を不可として判定するためのvalidator */
export default {
  params: ["check"],
  validate(
    value: string | number | undefined | null,
    { check }: { check: string | number | undefined | null }
  ): boolean {
    if (!value) {
      return false;
    }
    return value !== check;
  },
} as ValidationRuleSchema;





















































































































































































































































































































import Vue, { PropType } from "vue";
import { GetPolicyType1 } from "@/apis/NwPolicyApi";
import cloneDeep from "lodash/cloneDeep";
import { IpPool } from "@/apis/IpPoolApi";
import MultiSelectTag from "@/components/MultiSelectTag.vue";
import InternetType1PolicyRuleIpMasqConfirm from "@/modals/applianceContractSetting/InternetType1PolicyRuleIpMasqConfirm.vue";
import { Type1IpMasqueradePolicy } from "@/modals/applianceContractSetting/InternetType1PolicyRuleIpMasqViewRD.vue";
import {
  Type1ExtraInfo,
  Type1InternetAddress,
  Type1PrivateInfo,
} from "@/apis/Type1SiteApi";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";

export default Vue.extend({
  name: "InternetType1PolicyRuleIpMasqEdit",
  components: { MultiSelectTag },
  props: {
    /** ゾーン */
    zone: {
      type: Object as PropType<ZoneRelationItem>,
      required: true,
    },
    /** 変更対象ポリシー */
    policy: {
      type: Object as PropType<Type1IpMasqueradePolicy>,
      default: undefined,
    },
    /** type1ポリシー全体 */
    type1Policy: {
      type: Object as PropType<GetPolicyType1>,
      required: true,
    },
  },
  data() {
    const clonePolicy = cloneDeep(this.policy);
    const form: Type1IpMasqueradePolicy = clonePolicy ?? {
      srcAddressList: [],
      dstAddressList: [],
      ipPoolList: [],
      isPolicyStatus: true,
      policyCategoryInfo: "CUSTOM_POLICY",
    };

    return {
      form,
      // 宛先（any/アドレス）
      radioDstAddress: (form.dstAddressList.length === 0 ? "ANY" : "SELECT") as
        | "ANY"
        | "SELECT",
      // インターネット（アドレス一覧）
      internetAddresses: [] as Type1InternetAddress[],
      // IP Pool一覧
      ipPools: [] as IpPool[],
      extraOrPrivateSiteAddresses: [] as
        | Type1ExtraInfo["siteList"][0]["addressList"]
        | Type1PrivateInfo["lineList"][0]["siteList"][0]["addressList"],

      isLoaded: false,
    };
  },
  computed: {
    /** true: 編集, false: 新規 */
    isEdit(): boolean {
      return !!this.policy;
    },
    /** 送信元アドレスのSelectの選択肢 */
    srcAddresses(): {
      label: string;
      values:
        | Type1ExtraInfo["siteList"][0]["addressList"]
        | Type1PrivateInfo["lineList"][0]["siteList"][0]["addressList"];
    }[] {
      return this.extraOrPrivateSiteAddresses.length !== 0
        ? [
            {
              label: "全選択",
              values: this.extraOrPrivateSiteAddresses,
            },
          ]
        : [];
    },
    /** 宛先アドレスのSelectの選択肢 */
    dstAddresses(): {
      label: string;
      values: Type1InternetAddress[];
    }[] {
      return [
        {
          label: "全選択",
          values: this.internetAddresses,
        },
      ];
    },
    /** IP PoolのSelectの選択肢 */
    ipPoolOptions(): {
      label: string;
      values: IpPool[];
    }[] {
      return this.ipPools.length !== 0
        ? [
            {
              label: "全選択",
              values: this.ipPools,
            },
          ]
        : [];
    },
    /** 既存のtype4ポリシーID一覧 */
    existsPolicyIds(): string[] {
      const p = this.type1Policy;
      return [
        ...(p.policyUtmType1?.accessPointToInternetList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyUtmType1?.internetToAccessPointList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyIfwType1?.accessPointToInternetList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyIfwType1?.internetToAccessPointList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyIPMasqueradeType1List ?? []).flatMap((v) => v.policyList),
        ...(p.policyNatType1List ?? []).flatMap((v) => v.policyList),
      ].map((e) => e.policyId);
    },
    internetZoneId(): string {
      // dstはInternentゾーン
      return this.zone.dstZoneId;
    },
  },
  async mounted() {
    // 選択肢などの必要情報を取得
    if (this.zone.srcZoneType === "PRIVATE") {
      // IPアドレスで昇順ソートするために、プライベートサイト情報を一時保存
      this.extraOrPrivateSiteAddresses = (
        await this.$api.type1SiteZone.getType1PrivateList()
      ).lineList
        .flatMap((v) => v.siteList)
        .flatMap((v) => v.addressList)
        .filter((v) => v.zoneId === this.zone.srcZoneId)
        .sortBy(["ipAddress", "asc"]);
    } else {
      // IPアドレス、IPアドレス名で昇順ソートするために、エクストラサイト情報を一時保存
      this.extraOrPrivateSiteAddresses = (
        await this.$api.type1SiteZone.getType1ExtraList()
      ).siteList
        .flatMap((v) => v.addressList)
        .filter((v) => v.zoneId === this.zone.srcZoneId)
        .sortBy(["ipAddress", "asc"]);
    }
    const internetSite = await this.$api.type1SiteZone.getType1InternetList();
    // zoneIdが一致しているアドレスリストを表示
    this.internetAddresses = internetSite.addressList
      .filter((v) => v.zoneId === this.internetZoneId)
      .sortBy(["ipAddressName", "asc"]);
    this.ipPools = (await this.$api.ipPool.getIpPoolType1()).ipPoolList;

    this.isLoaded = true;
  },
  methods: {
    /** ルール編集の完了 */
    async submit() {
      const policy = {
        ...this.form,
        srcAddressList: this.form.srcAddressList,
        dstAddressList:
          this.radioDstAddress === "ANY" ? [] : this.form.dstAddressList,
      };
      await this.$modal.show(InternetType1PolicyRuleIpMasqConfirm, { policy });
      (
        this.$refs.modal as Vue & { ok: (e: Type1IpMasqueradePolicy) => void }
      ).ok(policy);
    },
    /** アドレス選択肢ラベル名 */
    addressLabel({
      ipAddressName,
      ipAddress,
    }:
      | Type1InternetAddress
      | Type1ExtraInfo["siteList"][0]["addressList"][0]
      | Type1PrivateInfo["lineList"][0]["siteList"][0]["addressList"][0]): string {
      return `${ipAddressName}  ${ipAddress}`;
    },
    /** IP Poolラベル名（検索用） */
    ipPoolLabel({ ipPoolName, globalIpAddress, description }: IpPool) {
      return `${ipPoolName} ${globalIpAddress} ${description}`;
    },
  },
});






import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import { MountQosData } from "@/store/provisioning-qos-store";
import ProvisioningConfirmModal from "@/modals/ProvisioningConfirmModal.vue";
import { isShowProvisioningStatus } from "@/store/provisioning-store";

/**
 * 非同期の変更が可能なデータはプロビジョニングステータスを持っている
 * このプロビジョニングステータスを画面に表示/非表示するコンポーネント.
 * 変更系のAPIを実行した場合はコンポーネント内で自動でプロビジョニングステータスを再取得する
 * @see 別紙_プロビジョニング同時実行マトリクス.xlsx
 */
export default Vue.extend({
  name: "ProvisioningQosStatus",
  props: {
    /** QoS/クラウドQoS設定時の識別用. それ以外の画面では未指定で良い */
    qosType: {
      type: String as PropType<"QOS" | "CLOUD_QOS">,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    ...mapState("provisioningQos", {
      provisioning: "data",
    }),

    /** true: メッセージ表示, false: 非表示 */
    isShow(): boolean {
      return isShowProvisioningStatus(
        this.provisioning[this.qosType].data.messageCode
      );
    },
    message(): null | string {
      return this.provisioning[this.qosType].data.message;
    },
  },
  watch: {
    async isShow(newValue: boolean, oldValue: boolean) {
      if (!this.provisioning[this.qosType].data.enabledRefresh) {
        return;
      }

      if (!newValue && oldValue) {
        await this.$modal.show(ProvisioningConfirmModal);
        this.$vfm.hideAll();
        this.$emit("refresh");
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("provisioningQos/mount", {
      getProvisioningStatus: async () => {
        return await this.$api.information.getProvisioningStatus(
          { qosType: this.qosType },
          false
        );
      },
      qosType: this.qosType,
    } as MountQosData);
    this.isLoaded = true;
  },
  async destroyed() {
    await this.$store.dispatch("provisioningQos/unmount", {
      qosType: this.qosType,
    });
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { AxiosResponse } from "axios";

export type ApplianceType = "INTERNET_FW" | "UTM" | "INTRANET_FW" | "NAT";

/** サービス種別  以下のいずれかを指定する
 * typescriptのバグを避けるため、`MS${number}`の場合は、stringと扱い
 * @see https://github.com/microsoft/TypeScript/pull/41693
 */
export type CloudService = "AWS" | string | "GCP" | "Oracle" | "IBM" | "SFDC";

export type InternetType = "TYPE1" | "TYPE4";

export type SortPattern = "SESSION" | "BYTE";

export type LineType =
  | "ACCESS"
  | "INTERNET"
  | "VN"
  | "CLOUD"
  | "VNL2L3"
  | "MULTICLOUD"
  | "CPA_5GSA";

export interface LineElement {
  /**
   * E番号(Main-ACT)
   * アクセス回線、インターネット回線(Type1)、クラウド接続、VNL2L3コネクト、マルチクラウド（クラウド帯域単位）の場合、E番号を指定。
   * isAssociateがtrueの場合、連携E番号を指定する
   * 回線種別が上記以外の場合NULLを設定する。
   */
  enumberMainAct: string | null;
  /**
   * E番号(Main-SBY)
   * アクセス回線(PFアクセスGW2の場合)インターネット回線(Type1)の場合、E番号を指定。
   * 上記以外の場合NULLを設定する
   */
  enumberMainSby: string | null;
  /**
   * E番号(BCP-ACT)
   * インターネット回線(Type1)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する。
   */
  enumberBcpAct: string | null;
  /**
   * E番号(BCP-SBY)
   * インターネット回線(Type1)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する。
   */
  enumberBcpSby: string | null;
  /**
   * W番号(Main-ACT)
   * インターネット回線(Type4)、VNコネクト、クラウド接続、VNL2L3コネクト、マルチクラウド（VNコネクト単位）、CPA 5GSAの場合、W番号を指定.
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberMainAct: string | null;
  /**
   * W番号(Main-SBY)
   * インターネット回線(Type4)の場合、W番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberMainSby: string | null;
  /**
   * W番号(Bcp-ACT)
   * インターネット回線(Type4)の場合、W番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberBcpAct: string | null;
  /**
   * W番号(Bcp-SBY)
   * インターネット回線(Type4)の場合、W番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberBcpSby: string | null;
  /**
   * 事業所名
   * アクセス回線の場合、事業所名を指定
   回線種別がアクセス回線以外の場合NULLを設定する
   */
  office: string | null;
  /**
   * インターネット回線種別
   * 回線種別がインターネット回線の場合以下のいずれかを設定し、それ以外はNULLを設定する。
   * TYPE1 ：インターネットTYPE1
   * TYPE4 ：インターネットTYPE4
   */
  internetType: InternetType | null;
  /**
   * Type4ID
   * internetTypeがTYPE4の場合に指定し、それ以外はNULLを設定する.
   */
  type4Id: string | null;
  /**
   * アプライアンス種別
   */
  applianceType: ApplianceType | null;
  /**
   * VPN/VNコード
   * 回線種別がインターネット回線(Type4)の場合はVNコードを設定し、VNコネクト、マルチクラウド(VNコネクト単位)、CPA 5GSAの場合はVPN/VNコードを設定する.
   * それ以外の場合はNULLを設定する.
   */
  vpnVnCode: string | null;
  /**
   * 仮想ネットワーク名
   * 回線種別がインターネット回線(Type4)、マルチクラウド(VNコネクト単位)の場合は設定し、それ以外の場合はNULLを設定する.
   */
  vnName: string | null;
  /**
   * VNコネクト名
   * 回線種別がVNコネクト、VN L2/L3コネクト、マルチクラウド(VNコネクト単位)、CPA 5GSAの場合は設定し、それ以外の場合はNULLを設定する.
   */
  vnConnectName: string | null;
  /**
   * V番連携フラグ
   * 回線種別がVNコネクト、クラウド種別の場合に設定し、それ以外の場合はNULLを設定する
   */
  isAssociate: boolean | null;
  /**
   * L2VNコード
   * 回線種別がVN L2/L3コネクトの場合に設定し、それ以外の場合はNULLを設定する
   */
  l2VnCode: string | null;
  /**
   * L3VPN/VNコード
   * 回線種別がVN L2/L3コネクトの場合に設定し、それ以外の場合はNULLを設定する
   */
  l3VpnVnCode: string | null;
  /**
   * クラウドサービスメニュー
   * クラウド回線の主キー。回線種別がマルチクラウドの場合のみ設定し、それ以外の場合はNULLを設定する
   */
  cloudServiceMenu: CloudService | null;
  /**
   * Microsoft Peeringフラグ
   * マルチクラウド(VNコネクト単位)におけるpeering種別を判定するフラグ.
   * 回線種別がマルチクラウドの場合のみ以下のいずれかを設定し、それ以外の場合はNULLを設定する.
   * true：MS Peering
   * false：Private Peering
   */
  isMsPeering: boolean | null;
  /**
   * ドメイン名
   * 回線種別がCPA 5GSAの場合のみ設定し、それ以外の場合はNULLを設定する
   */
  domain: string | null;
}

export interface TrafficLineSearchResponse {
  /**
   * 統計情報フラグ
   * 統計情報取得可否を示す。
   * true ：回線種別がインターネット回線の場合は統計情報取得可能
   * false：回線種別がインターネット回線以外の場合は取得不可
   */
  isStatistics: boolean;
  /**
   * 総件数
   * 検索対象の総件数
   */
  total: number;
  /**
   * 回線種別検索リスト
   * 検索結果が0件の場合、空リスト[]
   */
  lineTypeSearchList: {
    /**
     * 回線種別
     * ACCESS ：アクセス回線(enumberを指定)
     * INTERNET ：インターネット回線(enumber(Type1の場合のみ)、wnumber(Type4の場合のみ)、type4Id(Type4の場合のみ)、applianceTypeを指定)
     * VN ：VNコネクト(wnumberを指定)
     * CLOUD ：クラウド接続(wnumberを指定)
     * VNL2L3 ：VN L2/L3コネクト(wnumberを指定)
     * MULTICLOUD：マルチクラウド種別(クラウド帯域単位の場合はenumber、cloudServiceMenuを指定、VNコネクト単位の場合はwnumberを指定)
     * CPA_5GSA ：CPA 5GSA(wnumberを指定)
     */
    lineType: LineType;
    /**
     * 総件数
     * 検索対象の総件数
     */
    total: number;
    /**
     * 回線種別検索リストの要素情報
     * 検索結果が0件の場合、空リスト[]
     */
    elementList: LineElement[];
  }[];
}

export interface TrafficInfo {
  /**
   * 総件数
   * 検索対象の総件数
   */
  total: number;
  /**
   * トラフィックデータ
   * トラフィック情報が1件も無い場合は空配列を返す.
   * 取得した値が部分的に欠損している場合の処理は以下の通りとする
   * データが異常値(マイナス値、数値以外、全角空白等)の場合：不整合発生時のデータは返さない
   * トラフィック情報値が欠損(値なし、半角空白)している場合:receiveAverage, receiveMax, receiveNonTfAverage, receiveNonTfMax, sendAverage, sendMaxを「0」とする
   */
  trafficInfoList: {
    /**
     * トラフィック日時
     * グラフの横軸の値
     */
    trafficDateTime: string;
    /**
     * 受信（平均）トラフィック値
     * 取得値の単位はKbps。表示範囲が日次以外の時に設定され、日次の場合はNULLが設定される
     */
    receiveAverage: string | null;
    /**
     * 受信（最大）トラフィック値
     * 取得値の単位はKbps
     */
    receiveMax: string | null;
    /**
     * 非トラフィックフリー受信（平均）トラフィック値
     * 取得値の単位はKbps
     * 表示範囲が日次かつ回線がアクセス回線またはVNコネクト以外の時に設定され、それ以外の場合はNULLが設定される.
     */
    receiveNonTfAverage: string | null;
    /**
     * 非トラフィックフリー受信（最大）トラフィック値
     * 取得値の単位はKbps.
     * 回線がアクセス回線またはVNコネクト以外の時に設定され、それ以外の場合はNULLが設定される
     */
    receiveNonTfMax: string | null;
    /**
     * 送信（平均）トラフィック値
     * 取得値の単位はKbps.
     * 表示範囲が日次以外の時に設定され、日次の場合はNULLが設定される
     */
    sendAverage: string | null;
    /**
     * 送信（最大）トラフィック値
     * 取得値の単位はKbps
     */
    sendMax: string | null;
  }[];
}

export type TrafficResponse = LineElement &
  TrafficInfo & {
    lineType: LineType;
  };

export type DisplayRange = "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY";

export interface SessionResponse {
  /** 回線種別
   * ACCESS ：アクセス回線(applianceTypeを指定)
   * INTERNET ：インターネット回線(internetTypeとapplianceTypeおよび、Type4の場合はtype4Idを指定)
   * MULTICLOUD：マルチクラウド種別(vpnVnCodeを指定)
   */
  lineType: Extract<LineType, "ACCESS" | "INTERNET" | "MULTICLOUD">;
  /**
   * E番号(Main-ACT)
   * アクセス回線、インターネット回線(Type1)の場合、E番号を指定.
   * 回線種別が上記以外の場合NULLを設定する.
   */
  enumberMainAct: string | null;
  /**
   * E番号(Main-SBY)
   * インターネット回線(Type1)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  enumberMainSby: string | null;
  /**
   * E番号(BCP-ACT)
   * インターネット回線(Type1)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  enumberBcpAct: string | null;
  /**
   * E番号(BCP-SBY)
   * インターネット回線(Type1)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  enumberBcpSby: string | null;
  /**
   * W番号(Main-ACT)
   * インターネット回線(Type4)、マルチクラウド（VNコネクト単位）の場合、W番号を指定.
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberMainAct: string | null;
  /**
   * W番号(Main-SBY)
   * インターネット回線(Type4)の場合、W番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberMainSby: string | null;
  /**
   * W番号(BCP-ACT)
   * インターネット回線(Type4)の場合、W番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberBcpAct: string | null;
  /**
   * W番号(BCP-SBY)
   * インターネット回線(Type4)の場合、W番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberBcpSby: string | null;
  /**
   * 事業所名
   * アクセス回線の場合、事業所名を指定
   * 回線種別がアクセス回線以外の場合NULLを設定する.
   */
  office: string | null;
  /**
   * インターネット回線種別
   * TYPE1 ：インターネットTYPE1
   * TYPE4 ：インターネットTYPE4
   */
  internetType: InternetType | null;
  /**
   * Type4ID
   * internetTypeがTYPE4の場合、指定する.
   * internetTypeがTYPE1の場合NULLを設定する.
   */
  type4Id: string | null;
  /**
   * アプライアンス種別
   * 以下のいずれかを設定する.
   * INTERNET_FW：インターネットFW
   * UTM：UTM
   * INTRANET_FW：イントラネットFW
   * NAT：NAT
   */
  applianceType: ApplianceType;
  /** VPN/VNコード */
  vpnVnCode: string | null;
  /**
   * 仮想ネットワーク名
   * 回線種別がインターネット回線(Type4)、マルチクラウド(VNコネクト単位)の場合は設定し、それ以外の場合はNULLを設定する.
   */
  vnName: string | null;
  /**
   * VNコネクト名
   * 回線種別がマルチクラウド(VNコネクト単位)の場合は設定し、それ以外の場合はNULLを設定する.
   */
  vnConnectName: string | null;
  /**
   * 総件数
   * 検索対象の総件数.
   */
  total: number;
  /**
   * セッション情報リスト
   * セッション情報が1件も無い場合は空配列を返す.
   * 取得した値が部分的に欠損している場合の処理は以下の通りとする
   * データが異常値(マイナス値、数値以外、全角空白等)の場合：不整合発生時のデータは返さない
   * セッション情報値が欠損(値なし、半角空白)している場合:averageCount, maxCountを「0」とする.
   */
  sessionInfoList: {
    /**
     * セッション日時
     * グラフの横軸の値
     */
    sessionDateTime: string;
    /**
     * 平均セッション数
     * 取得値の単位はセッション数
     */
    averageCount: number;
    /**
     * 最大セッション数
     * 取得値の単位はセッション数
     */
    maxCount: number;
  }[];
}

export type StatisticReportType =
  | "APPLICATION"
  | "SOURCE_ADDRESS_SESSIONS_BYTE"
  | "DESTINATION_ADDRESS"
  | "DESTINATION"
  | "THREAT"
  | "SOURCE_ADDRESS_DETECTIONS"
  | "BLOCK_SOURCE_ADDRESS"
  | "BLOCK_URL"
  | "VIRUS";

export interface StatisticsResponse {
  /**
   * E番号(Main-ACT)
   * インターネット回線(Type1)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  enumberMainAct: string | null;
  /**
   * E番号(Main-SBY)
   * インターネット回線(Type1)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  enumberMainSby: string | null;
  /**
   * E番号(BCP-ACT)
   * インターネット回線(Type1)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  enumberBcpAct: string | null;
  /**
   * E番号(BCP-SBY)
   * インターネット回線(Type1)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  enumberBcpSby: string | null;
  /**
   * W番号(Main-ACT)
   * インターネット回線(Type4)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberMainAct: string | null;
  /**
   * W番号(Main-SBY)
   * インターネット回線(Type4)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberMainSby: string | null;
  /**
   * W番号(BCP-ACT)
   * インターネット回線(Type4)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberBcpAct: string | null;
  /**
   * W番号(BCP-SBY)
   * インターネット回線(Type4)の場合、E番号を指定。
   * 回線種別が上記以外の場合NULLを設定する.
   */
  wnumberBcpSby: string | null;
  /**
   * インターネット回線種別
   * TYPE1 ：インターネットTYPE1
   * TYPE4 ：インターネットTYPE4
   */
  internetType: InternetType;
  /**
   * Type4ID
   * internetTypeがTYPE4の場合指定する。
   * internetTypeがTYPE1の場合NULLを設定する
   */
  type4Id: string | null;
  /**
   * アプライアンス種別
   * 以下のいずれかを設定する。
   * INTERNET_FW：インターネットFW
   * UTM：UTM
   */
  applianceType: Extract<ApplianceType, "INTERNET_FW" | "UTM">;
  /**
   * VPN/VNコード
   * 回線種別がインターネット回線(Type4)の場合はVNコードを設定する。
   * それ以外の場合はNULLを設定する。
   */
  vpnVnCode: string | null;
  /**
   * 仮想ネットワーク名
   * 回線種別がインターネット回線(Type4)の場合は設定し、それ以外の場合はNULLを設定する。
   */
  vnName: string | null;
  /**
   * 統計情報レポート種別
   * APPLICATION ：アプリケーション
   * SOURCE_ADDRESS_SESSIONS_BYTE：通信元アドレス(セッション数、バイト数)
   * DESTINATION_ADDRESS ：通信先アドレス
   * DESTINATION ：通信先(国別)
   * THREAT ：脅威
   * SOURCE_ADDRESS_DETECTIONS ：通信元アドレス(検知回数)
   * BLOCK_SOURCE_ADDRESS ：ブロック通信元アドレス
   * BLOCK_URL ：ブロックURL
   * VIRUS ：ウイルス
   */
  statisticReportType: StatisticReportType | null;
  /**
   * ソート順パターン
   * SESSION：セッション数でソート
   * BYTE：バイト数でソート
   * 統計情報レポート種別が以下の場合に指定を行う。それ以外の場合は検知数の降順でのソートとなるためNULLを設定する。
   * アプリケーション、通信元アドレス(セッション数、バイト数)、通信先アドレス、通信先(国別)
   */
  sortPattern: SortPattern | null;
  /**
   * 総件数
   * 検索対象の総件数.
   */
  total: number;
  statisticsList: {
    /** リスク */
    risk: string | null;
    /** アプリケーション名 */
    applicationName: string | null;
    /** 送信元アドレス */
    srcIpAddress: string | null;
    /** 送信先アドレス */
    dstIpAddress: string | null;
    /**
     * 通信先国
     * 通信先の国名
     */
    dstCountry: string | null;
    /** 脅威名 */
    threatName: string | null;
    /** 脅威の識別ID */
    threatId: string | null;
    /** 脅威のタイプ */
    threatType: string | null;
    /**
     * ブロックURL
     * ブロックされたURL
     */
    blockUrl: string | null;
    /**
     * カテゴリ名
     * ブロックURLのカテゴリの名称
     */
    categoryName: string | null;
    /** セッション数 */
    sessionCount: number | null;
    /**
     * バイト数
     * バイト数(単位byte)
     */
    byteCount: string | null;
    /**
     * 検知数
     * 対象項目の検知数
     */
    detectCount: number | null;
  }[];
}

export default class TrafficApi extends AbstractBaseApi {
  /**
   * サービス（回線種別）に対応した回線を取得する.
   * @param query クエリパラメータ
   */
  async getLineSearchList(
    query?: {
      lineType: LineType;
    } | null
  ): Promise<TrafficLineSearchResponse> {
    return (
      await this.api.get("/v2/report/traffic/lines", {
        params: { lineType: query?.lineType },
      })
    ).data;
  }

  /**
   * トラフィック情報を取得する
   * @param queries クエリパラメータ群
   */
  async getTraffic(queries: {
    /**
     * 回線種別
     * ACCESS ：アクセス回線
     * INTERNET ：インターネット回線
     * VN ：VNコネクト
     * CLOUD ：クラウド接続
     * VNL2L3 ：VN L2/L3コネクト
     * MULTICLOUD：マルチクラウド種別
     * CPA_5GSA ：CPA 5GSA
     * ※回線種別を指定しない場合は、全回線種別の情報を一括取得
     */
    lineType: LineType;
    /**
     * E番号
     * アクセス回線、インターネット回線(Type1)、クラウド接続、マルチクラウド（クラウド帯域単位）の場合、E番号を指定.
     * isAssociateがtrueの場合、連携E番号を指定する
     * 回線種別が上記以外の場合NULLを設定する.
     */
    enumberMainAct?: string | null;
    /**
     * W番号
     * VNコネクト、クラウド接続、VNL2L3コネクト、マルチクラウド（VNコネクト単位）、CPA 5GSAの場合、W番号を指定。
     * マルチクラウド（VNコネクト単位）の場合ACT/SBY両方指定可能。それ以外の場合はACTのみ指定可能。
     * 回線種別が上記以外の場合NULLを設定する。
     */
    wnumberMain?: string | null;
    /**
     * インターネット回線種別
     * 回線種別がインターネット回線の場合以下のいずれかを設定し、それ以外はNULLを設定する.
     * TYPE1 ：インターネットTYPE1
     * TYPE4 ：インターネットTYPE4
     */
    internetType?: InternetType | null;
    /**
     * Type4ID
     * internetTypeがTYPE4の場合に指定し、それ以外はNULLを設定する.
     */
    type4Id?: string | null;
    /**
     * クラウドサービス
     * クラウド回線の主キー.
     * 回線種別がマルチクラウド（クラウド帯域単位）の場合のみ設定し、それ以外の場合はNULLを設定する.
     */
    cloudServiceMenu?: CloudService | null;
    /**
     * アプライアンス種別
     * 回線種別がアクセス回線かインターネット回線で、アプライアンス契約がある場合のみ以下のいずれかを設定する.
     * それ以外の場合はNULLを設定する.
     * INTERNET_FW：インターネットFW
     * UTM：UTM
     * INTRANET_FW：イントラネットFW
     */
    applianceType?: Exclude<ApplianceType, "NAT"> | null;
    /**
     * 表示範囲
     * DAILY ：日次(periodDateとperiodTimeを指定)
     * WEEKLY ：週次(periodDateを指定)
     * MONTHLY：月次(periodDateを指定)
     * YEARLY ：年次(指定なし)
     */
    displayRange: DisplayRange;
    /**
     * 表示終端年月日
     * 表示終端の年月日
     * 表示範囲がWEEKLY、MONTHLY、YEARLYの場合NULLを設定する
     */
    periodDate?: string | null;
    /**
     * 表示終端時刻
     * 表示終端の時刻
     * 表示範囲がYEARLYの場合NULLを設定する
     */
    periodTime?: string | null;
  }): Promise<TrafficResponse> {
    return (
      await this.api.get("/v2/report/traffic", { params: { ...queries } })
    ).data;
  }

  /**
   * トラフィック、セッション、統計のグラフのCSVを取得
   * @param data リクエストボディ
   */
  async getTrafficCsv(data: {
    /**
     * 回線種別
     * ACCESS ：アクセス回線(enumberを指定)
     * INTERNET ：インターネット回線(enumber(Type1の場合のみ)、wnumber(Type4の場合のみ)、type4Id(Type4の場合のみ)、applianceTypeを指定)
     * VN ：VNコネクト(wnumberを指定)
     * CLOUD ：クラウド接続(wnumberを指定)
     * VNL2L3 ：VN L2/L3コネクト(wnumberを指定)
     * MULTICLOUD：マルチクラウド種別(クラウド帯域単位の場合はenumber、cloudServiceMenuを指定、VNコネクト単位の場合はwnumberを指定)
     * CPA_5GSA ：CPA 5GSA(wnumberを指定)
     * ※回線種別を指定しない場合は、全回線種別の情報を一括取得
     */
    lineType: LineType;
    /**
     * CSV出力するファイル種別
     * TRAFFIC ：トラフィック情報を出力
     * SESSION ：セッション情報を出力
     * STATISTICS ：統計情報を出力
     * TRAFFIC_SESSION ：トラフィック情報、セッション情報を出力
     * SESSION_STATISTICS：セッション情報、統計情報を出力
     * TRAFFIC_STATISTICS：トラフィック情報、統計情報を出力
     * ※CSV出力するファイル種別を指定しない場合は、全情報を一括取得
     * トラフィック情報は回線種別にアクセス回線、インターネット回線、VNコネクト、クラウド接続、VN L2/L3コネクト、マルチクラウド種別、リモートアクセスGWが指定可能
     * セッション情報は回線種別にアクセス回線、インターネット回線、マルチクラウド種別が指定可能
     * 統計情報は回線種別にインターネット回線が指定可能
     */
    outputType:
      | "TRAFFIC"
      | "SESSION"
      | "STATISTICS"
      | "TRAFFIC_SESSION"
      | "SESSION_STATISTICS"
      | "TRAFFIC_STATISTICS"
      | null;
    searchKeyCsv: {
      /**
       * E番号
       * アクセス回線、インターネット回線(Type1)、クラウド接続、VNL2L3コネクト、マルチクラウド（クラウド帯域単位）の場合、E番号を指定.
       * isAssociateがtrueの場合、連携E番号を指定する
       * 回線種別が上記以外の場合NULLを設定する.
       */
      enumberMainAct?: string | null;
      /**
       * W番号
       * インターネット回線(Type4)、VNコネクト、クラウド接続、VNL2L3コネクト、マルチクラウド（VNコネクト単位）、CPA 5GSAの場合、W番号を指定.
       * 回線種別が上記以外の場合NULLを設定する.
       */
      wnumberMainAct?: string | null;
      /**
       * インターネット回線種別
       * 回線種別がインターネット回線の場合以下のいずれかを設定し、それ以外はNULLを設定する。
       * TYPE1 ：インターネットTYPE1
       * TYPE4 ：インターネットTYPE4
       */
      internetType?: InternetType | null;
      /**
       * Type4ID
       * internetTypeがTYPE4の場合に指定し、それ以外はNULLを設定する.
       */
      type4Id?: string | null;
      /**
       * クラウドサービスメニュー
       * クラウド回線の主キー.
       * 回線種別がマルチクラウド（クラウド帯域単位）の場合のみ設定し、それ以外の場合はNULLを設定する.
       */
      cloudServiceMenu?: CloudService | null;
      /**
       * アプライアンス種別
       * 回線種別がアクセス回線の場合かインターネット回線かマルチクラウド種別(VPN/VNコード単位)で、アプライアンス契約がある場合のみ以下のとおり設定する。
       * アクセス回線：イントラネットFW
       * インターネット回線：インターネットFWもしくはUTM
       * マルチクラウド種別(VPN/VNコード単位)：NAT
       * それ以外の場合はNULLを設定する.
       * INTERNET_FW：インターネットFW
       * UTM：UTM
       * INTRANET_FW：イントラネットFW
       */
      applianceType?: ApplianceType | null;
      /**
       * VPN/VNコード
       * 回線種別がインターネット回線(Type4)の場合はVNコードを設定し、VNコネクト、マルチクラウド(VNコネクト単位)、CPA 5GSAの場合はVPN/VNコードを設定する.
       * それ以外の場合はNULLを設定する.
       */
      vpnVnCode?: string | null;
    }[];
    /**
     * 統計情報レポート種別
     * APPLICATION ：アプリケーション
     * SOURCE_ADDRESS_SESSIONS_BYTE：通信元アドレス(セッション数、バイト数)
     * DESTINATION_ADDRESS ：通信先アドレス
     * DESTINATION ：通信先(国別)
     * THREAT ：脅威
     * SOURCE_ADDRESS_DETECTIONS ：通信元アドレス(検知回数)
     * BLOCK_SOURCE_ADDRESS ：ブロック通信元アドレス
     * BLOCK_URL ：ブロックURL
     * VIRUS ：ウイルス
     */
    statisticReportType?: StatisticReportType;
    /**
     * ソート順パターン
     * SESSION：セッション数でソート
     * BYTE：バイト数でソート
     * statisticReportTypeがAPPLICATION, SOURCE_ADDRESS_SESSIONS_BYTE, DESTINATION_ADDRESS, DESTINATIONの場合に選択可能
     */
    sortPattern?: SortPattern | null;
    /**
     * 表示範囲
     * DAILY ：日次(periodDateとperiodTimeを指定)
     * WEEKLY ：週次(periodDateを指定)
     * MONTHLY：月次(periodDateを指定)
     * YEARLY ：年次(指定なし)
     */
    displayRange: DisplayRange;
    /**
     * 表示終端年月日
     * 表示開始の年月日
     * 表示範囲がWEEKLY、MONTHLY、YEARLYの場合NULLを設定する
     */
    periodDate: string | null;
    /**
     * 表示終端時刻
     * 表示開始の時刻
     * 表示範囲がYEARLYの場合NULLを設定する
     */
    periodTime: string | null;
  }): Promise<AxiosResponse> {
    return await this.api.post("/v2/report/traffic/csv", data, {
      responseType: "arraybuffer",
    });
  }

  /**
   * セッション情報取得を取得する
   * @param queries クエリパラメータ群
   */
  async getSession(queries: {
    /**
     * 回線種別
     * ACCESS ：アクセス回線
     * INTERNET ：インターネット回線
     * MULTICLOUD：マルチクラウド種別
     */
    lineType: Extract<LineType, "ACCESS" | "INTERNET" | "MULTICLOUD">;
    /**
     * インターネット回線種別
     * TYPE1 ：インターネットTYPE1
     * TYPE4 ：インターネットTYPE4
     */
    internetType?: InternetType | null;
    /**
     * Type4ID
     * internetTypeがTYPE4の場合、指定する.
     */
    type4Id?: string | null;
    /**
     * アプライアンス種別
     * 以下のいずれかを設定する.
     * INTERNET_FW：インターネットFW
     * UTM：UTM
     * INTRANET_FW：イントラネットFW
     * NAT：NAT
     */
    applianceType: ApplianceType;
    /**
     * VPN/VNコード
     * 回線種別がマルチクラウドの場合、VPN/VNコードを指定。
     */
    vpnVnCode?: string | null;
    /**
     * 表示範囲
     * DAILY ：日次(periodDateとperiodTimeを指定)
     * WEEKLY ：週次(periodDateを指定)
     * MONTHLY：月次(periodDateを指定)
     * YEARLY ：年次(指定なし)
     */
    displayRange: DisplayRange;
    /**
     * 表示終端年月日
     * 表示終端の年月日
     * 表示範囲がWEEKLY、MONTHLY、YEARLYの場合NULLを設定する
     */
    periodDate?: string | null;
    /**
     * 表示終端時刻
     * 表示終端の時刻
     * 表示範囲がYEARLYの場合NULLを設定する
     */
    periodTime?: string | null;
  }): Promise<SessionResponse> {
    return (
      await this.api.get("/v1/report/session", { params: { ...queries } })
    ).data;
  }

  /**
   * 統計情報取得を取得する
   * @param queries クエリパラメータ群
   */
  async getStatistics(queries: {
    /**
     * インターネット回線種別
     * TYPE1 ：インターネットTYPE1
     * TYPE4 ：インターネットTYPE4
     */
    internetType: InternetType;
    /**
     * Type4ID
     * internetTypeがTYPE4の場合、指定する.
     */
    type4Id?: string | null;
    /**
     * アプライアンス種別
     * 以下のいずれかを設定する.
     * INTERNET_FW：インターネットFW
     * UTM：UTM
     */
    applianceType: Extract<ApplianceType, "INTERNET_FW" | "UTM">;
    /**
     * 統計情報レポート種別
     * APPLICATION ：アプリケーション
     * SOURCE_ADDRESS_SESSIONS_BYTE：通信元アドレス(セッション数、バイト数)
     * DESTINATION_ADDRESS ：通信先アドレス
     * DESTINATION ：通信先(国別)
     * THREAT ：脅威
     * SOURCE_ADDRESS_DETECTIONS ：通信元アドレス(検知回数)
     * BLOCK_SOURCE_ADDRESS ：ブロック通信元アドレス
     * BLOCK_URL ：ブロックURL
     * VIRUS ：ウイルス
     */
    statisticReportType: StatisticReportType;
    /**
     * ソート順パターン
     * SESSION：セッション数でソート
     * BYTE：バイト数でソート
     * statisticReportTypeがAPPLICATION, SOURCE_ADDRESS_SESSIONS_BYTE, DESTINATION_ADDRESS, DESTINATIONの場合に選択可能
     */
    sortPattern?: SortPattern | null;
    /**
     * 表示対象年月日
     * 表示対象の年月日。過去日のみが指定可能。当日指定は不可。
     */
    targetDate: string;
  }): Promise<StatisticsResponse> {
    return (
      await this.api.get("/v1/report/statistics", { params: { ...queries } })
    ).data;
  }
}

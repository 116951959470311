import { ValidationRuleSchema } from "vee-validate/dist/types/types";
//入力された文字数をチェックする
export default {
  params: ["min", "max"],

  validate(
    value: string | undefined | null,
    { min, max }: Record<string, string | undefined | null>
  ): boolean {
    if (value) {
      return value.length >= Number(min) && value.length <= Number(max);
    }
    return true;
  },
} as ValidationRuleSchema;

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 半角英数、記号(. _ ! ? { } + - * / = % $ & ^ ` ~ | @ ( ) : ; < > [ ])で入力されること */

export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }
    return /^[a-zA-Z0-9._!?{}+*/=%$&^`~|@():;<>[\]-]*$/.test(value);
  },
} as ValidationRuleSchema;

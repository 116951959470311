







































































import Vue from "vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { QosInfo } from "@/apis/QosApi";
import QosSettingViewRC from "@/modals/qos/QosSettingViewRC.vue";

export default Vue.extend({
  name: "QosSettingList",
  data() {
    return {
      qos: {
        fields: [
          { key: "vpnVnCode", label: "VPN/VNコード", sortable: true },
          {
            key: "vnName",
            label: "仮想ネットワーク名",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, key: null, item: QosInfo) => {
              return item.vpnVnCode.startsWith("B") ? null : value;
            },
          },
          {
            key: "vLan",
            label: "VLAN",
            sortable: true,
            tdClass: "text-right",
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: null, key: null, item: QosInfo) => {
              if (
                item.vpnVnCode.startsWith("B") ||
                item.vpnVnCode.startsWith("D")
              ) {
                return null;
              } else {
                if (item.vlanIdType === "UNTAG") {
                  return "Untag";
                } else if (item.vlanIdType === "OTHER") {
                  return "Other";
                } else {
                  return `${item.vlanId}`;
                }
              }
            },
          },
          {
            key: "qosType",
            label: "QoS方式",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) => {
              if (value === "NONE") {
                return "-";
              } else {
                return this.$filter.enumTo(value, "qosType");
              }
            },
          },
          {
            key: "priorityTarget",
            label: "優先度参照先",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) => {
              if (value === "NONE") {
                return "-";
              } else {
                return this.$filter.enumTo(value, "qos_PriorityTarget");
              }
            },
          },
          { key: "line", label: "回線数" },
          { key: "qosControl", label: "設定可/不可", tdClass: "text-center" },
        ] as BvTableFieldArray,
        items: [] as QosInfo[],
        search: {
          vpnVnCode: { type: "text", label: "VPN/VNコード" },
          vnName: { type: "text", label: "仮想ネットワーク名" },
          vLan: { type: "text", label: "VLAN" },
          qosType: {
            type: "select",
            label: "QoS方式",
            items: [
              { value: "ABSOLUTE", text: "絶対優先方式" },
              { value: "BANDWIDTH", text: "帯域制御方式" },
              { value: "NONE", text: "-" },
            ],
          },
          priorityTarget: {
            type: "select",
            label: "優先度参照先",
            items: [
              { value: "IPPRECEDENCE", text: "IP Precedence" },
              { value: "COS", text: "CoS" },
              { value: "NONE", text: "-" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          vpnVnCode: "like",
          vnName: "like",
          vLan: "like",
          qosType: "equal",
          priorityTarget: "equal",
        },
      },
      // 初期通信完了フラグ
      isLoaded: false,
      // download用URL
      downloadUrl: this.$api.qosApi.getCsvQosURL(),
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.qos.items = await this.$api.qosApi.getQoSInfoList();
    },
    async showDetails(item: QosInfo) {
      const qosDetail = await this.$api.qosApi.getQosDetail(item.vpnVnCode);
      await this.$modal.show(QosSettingViewRC, {
        vpnVnCode: item.vpnVnCode,
        vnName: item.vnName,
        isNew: qosDetail.qosType === "NONE",
        qosDetail: qosDetail,
      });
      await this.load();
    },
  },
});

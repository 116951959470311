






































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import VirtualNetworkReference from "@/modals/virtualNetwork/VirtualNetworkReference.vue";
import VirtualNetworkEdit from "@/modals/virtualNetwork/VirtualNetworkEdit.vue";
import {
  VirtualNetworkEntity,
  VirtualNetworkGetEntity,
} from "@/apis/VirtualNetworkApi";

export default Vue.extend({
  name: "VirtualNetworkList",
  data() {
    return {
      alertMsg: null as string | null,
      networkLine: {
        fields: [
          {
            key: "vnName",
            label: "仮想ネットワーク(VN)名",
            sortable: true,
          },
          {
            key: "vnCode",
            label: "VNコード",
            sortable: true,
          },
          {
            key: "vnType",
            label: "L2/L3",
            sortable: true,
          },
          {
            key: "vlan",
            label: "VLAN",
            sortable: true,
            tdClass: "text-right",
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v: string, _k: string, item: VirtualNetworkEntity) => {
              if (item.vlanIdType === null) {
                return null;
              } else if (item.vlanIdType === "SPECIFIED") {
                return `${item.vlanId}`;
              } else {
                return this.$filter.enumTo(item.vlanIdType, "vlanIdType");
              }
            },
          },
          {
            key: "numberOfVnConnect",
            label: "VNコネクト数(登録数)",
            tdClass: "text-right",
          },
          {
            key: "upperLimitVnConnect",
            label: "VNコネクト数(上限数)",
            tdClass: "text-right",
          },
        ],
        selected: [],
        search: {
          vnName: { type: "text", label: "仮想ネットワーク(VN)名" },
          vnCode: { type: "text", label: "VNコード" },
          vnType: {
            type: "select",
            label: "L2/L3",
            items: [
              { value: "L2", text: "L2" },
              { value: "L3", text: "L3" },
            ],
          },
          vlan: { type: "text", label: "VLAN" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          vnName: "like",
          vnCode: "like",
          vnType: "equal",
          vlan: "like",
        },
      } as Omit<AppTableData<VirtualNetworkEntity>, "items">,
      vnetwork: {} as VirtualNetworkGetEntity,
      isLoaded: false,
    };
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** 一覧を取得 */
    async load() {
      this.alertMsg = null;
      this.vnetwork = await this.$api.virtualNetwork.getAll();
    },
    /** 追加 */
    async addVirtualNetwork() {
      this.alertMsg = null;
      await this.$modal.show(VirtualNetworkEdit, {
        vnKeyId: this.vnetwork.vnKeyId,
      });
      await this.load();
    },
    /** 削除 */
    async deleteVirtualNetwork() {
      if (this.networkLine.selected.length > 0) {
        this.alertMsg = null;
        await this.$confirm(`仮想ネットワークを削除します。よろしいですか？`);
        await this.$api.virtualNetwork.delete({
          vnCodeList: this.networkLine.selected.map((e) => e.vnCode),
          vnKeyId: this.vnetwork.vnKeyId,
        });
        await this.load();
      } else {
        this.alertMsg = this.$msg("no_select_data");
      }
    },
    /** 詳細*/
    async showVNReference(entity: VirtualNetworkEntity) {
      this.alertMsg = null;
      await this.$modal.show(VirtualNetworkReference, {
        network: entity,
        vnKeyId: this.vnetwork.vnKeyId,
      });
      await this.load();
    },
    /** フロント側のみでのCSVダウンロード */
    downloadCsv() {
      this.$service.csvService.appTableCsvDownLoad(
        "virtual_network_setting",
        this.vnetwork.vnInfoList.sortBy(["vnCode", "desc"]),
        this.$service.csvService.genFieldList(this.networkLine.fields)
      );
    },
  },
});

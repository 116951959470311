




















































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  AbsoluteDefaultPattern,
  PutQosDetail,
  QosBandwidth,
  QosBandwidthItem,
  QosDetail,
} from "@/apis/QosApi";
import QosSettingViewRC from "@/modals/qos/QosSettingViewRC.vue";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "QosSettingEdit",
  props: {
    vpnVnCode: {
      type: String as PropType<string>,
      required: true,
    },
    vnName: {
      type: String as PropType<string>,
      required: false,
    },
    isNew: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    qosDetail: {
      type: Object as PropType<QosDetail>,
      required: true,
    },
  },
  data() {
    return {
      form: {
        qosType: this.qosDetail.qosType,
        priorityTarget: "NONE",
        qosAbsolute: {
          priority0: "HIGH",
          priority1: "HIGH",
          priority2: "HIGH",
          priority3: "HIGH",
          priority4: "HIGH",
          priority5: "HIGH",
          priority6: "HIGH",
          priority7: "HIGH",
        },
      } as PutQosDetail,
      qosAbsolutDefaultPatterns: {} as AbsoluteDefaultPattern,
      qosBandwidth: {
        priorityType1: null,
        priorityType2: null,
        priorityType3: null,
        qosBandwidthList: [],
      } as Omit<QosBandwidth, "qosBandwidthList"> & {
        qosBandwidthList: Omit<QosBandwidthItem, "bestEffort">[];
      },
      absolutePattern: "",
      absolutePriorityRowNames: [
        { key: "HIGH", value: "High" },
        { key: "MEDIUM", value: "Medium" },
        { key: "NORMAL", value: "Normal" },
        { key: "LOW", value: "Low" },
      ],
      absolutePriorityPropNames: [
        "priority0",
        "priority1",
        "priority2",
        "priority3",
        "priority4",
        "priority5",
        "priority6",
        "priority7",
      ],
      bandwidthRowNames: [
        { key: "PATTERN_A", value: "パターンA" },
        { key: "PATTERN_A", value: "" },
        { key: "PATTERN_B", value: "パターンB" },
        { key: "PATTERN_B", value: "" },
        { key: "PATTERN_C", value: "パターンC" },
        { key: "PATTERN_C", value: "" },
        { key: "PATTERN_D", value: "パターンD" },
        { key: "PATTERN_D", value: "" },
        { key: "PATTERN_E", value: "パターンE" },
        { key: "PATTERN_E", value: "" },
      ],
      priorityTypes: [
        { value: null, text: "" },
        { value: "VALUE_0", text: "0" },
        { value: "VALUE_1", text: "1" },
        { value: "VALUE_2", text: "2" },
        { value: "VALUE_3", text: "3" },
        { value: "VALUE_4TO7", text: "4〜7" },
      ],
      bandwidthValueOptions: [
        { value: null, text: "" },
        { value: 5, text: "5%" },
        { value: 10, text: "10%" },
        { value: 15, text: "15%" },
        { value: 20, text: "20%" },
        { value: 25, text: "25%" },
        { value: 30, text: "30%" },
        { value: 35, text: "35%" },
        { value: 40, text: "40%" },
        { value: 45, text: "45%" },
        { value: 50, text: "50%" },
        { value: 55, text: "55%" },
        { value: 60, text: "60%" },
        { value: 65, text: "65%" },
        { value: 70, text: "70%" },
        { value: 75, text: "75%" },
        { value: 80, text: "80%" },
        { value: 85, text: "85%" },
        { value: 90, text: "90%" },
      ],
      onOff: [
        { value: true, text: "ON" },
        { value: false, text: "OFF" },
      ],
      // 初期通信完了フラグ
      isLoaded: false,
    };
  },
  computed: {
    crossValidation1(): boolean {
      return this.form.qosType === "ABSOLUTE" && this.form.qosAbsolute
        ? Object.values(this.form.qosAbsolute).some((value) => value === "HIGH")
        : true;
    },
    crossValidation2(): boolean {
      return this.form.qosType === "BANDWIDTH" &&
        this.qosBandwidth &&
        this.crossValidation4
        ? [
            this.qosBandwidth.priorityType1,
            this.qosBandwidth.priorityType2,
            this.qosBandwidth.priorityType3,
          ].some((value) => value)
        : true;
    },
    crossValidation3(): boolean {
      if (this.form.qosType === "BANDWIDTH" && this.qosBandwidth) {
        // エラーがある時に返す、なかれば、次のチェックに進める
        if (
          this.qosBandwidth.priorityType1 &&
          !this.qosBandwidth.qosBandwidthList
            .map((e) => e.priorityType1Value || 0)
            .some((value) => value > 0)
        ) {
          return false;
        }
        if (
          this.qosBandwidth.priorityType2 &&
          !this.qosBandwidth.qosBandwidthList
            .map((e) => e.priorityType2Value || 0)
            .some((value) => value > 0)
        ) {
          return false;
        }
        if (
          this.qosBandwidth.priorityType3 &&
          !this.qosBandwidth.qosBandwidthList
            .map((e) => e.priorityType3Value || 0)
            .some((value) => value > 0)
        ) {
          return false;
        }
      }
      return true;
    },
    crossValidation4(): boolean {
      if (this.form.qosType === "BANDWIDTH" && this.qosBandwidth) {
        if (!this.qosBandwidth.priorityType1) {
          if (
            this.qosBandwidth.qosBandwidthList
              .map(
                (e) =>
                  e.priorityType1Value ||
                  0 + (e.isType1TopPriority || false ? 1 : 0)
              )
              .reduce((a, c) => a + c) > 0
          )
            return false;
        }
        if (!this.qosBandwidth.priorityType2) {
          if (
            this.qosBandwidth.qosBandwidthList
              .map((e) => e.priorityType2Value || 0)
              .reduce((a, c) => a + c) > 0
          )
            return false;
        }
        if (!this.qosBandwidth.priorityType3) {
          if (
            this.qosBandwidth.qosBandwidthList
              .map((e) => e.priorityType3Value || 0)
              .reduce((a, c) => a + c) > 0
          )
            return false;
        }
      }
      return true;
    },
    crossValidation5(): boolean {
      if (this.form.qosType === "BANDWIDTH" && this.qosBandwidth) {
        if (
          this.qosBandwidth.priorityType1 &&
          this.qosBandwidth.priorityType2 &&
          this.qosBandwidth.priorityType1 === this.qosBandwidth.priorityType2
        ) {
          return false;
        }
        if (
          this.qosBandwidth.priorityType2 &&
          this.qosBandwidth.priorityType3 &&
          this.qosBandwidth.priorityType2 === this.qosBandwidth.priorityType3
        ) {
          return false;
        }
        if (
          this.qosBandwidth.priorityType1 &&
          this.qosBandwidth.priorityType3 &&
          this.qosBandwidth.priorityType1 === this.qosBandwidth.priorityType3
        ) {
          return false;
        }
      }
      return true;
    },
    crossValidation6(): boolean {
      return this.form.qosType === "BANDWIDTH" && this.qosBandwidth
        ? !this.qosBandwidth.qosBandwidthList
            .map(
              (e) =>
                (e.priorityType1Value || 0) +
                (e.priorityType2Value || 0) +
                (e.priorityType3Value || 0)
            )
            .some((value) => 90 < value)
        : true;
    },
    crossValidation7(): boolean {
      return this.form.qosType === "BANDWIDTH" && this.qosBandwidth
        ? !this.qosBandwidth.qosBandwidthList.some(
            (e) =>
              e.isType1TopPriority &&
              ((e.priorityType1Value || 0) < 5 ||
                50 <= (e.priorityType1Value || 0))
          )
        : true;
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.qosAbsolutDefaultPatterns =
        await this.$api.qosApi.getAbsoluteDefaultPatterns();

      this.form.qosKeyId = this.qosDetail.qosKeyId;
      this.form.qosType = this.qosDetail.qosType;
      this.form.priorityTarget = this.qosDetail.priorityTarget;

      this.qosBandwidth = {
        priorityType1: null,
        priorityType2: null,
        priorityType3: null,
        qosBandwidthList: [],
      };
      ["PATTERN_A", "PATTERN_B", "PATTERN_C", "PATTERN_D", "PATTERN_E"].forEach(
        (pattern) => {
          this.qosBandwidth.qosBandwidthList.push({
            patternName: pattern as
              | "PATTERN_A"
              | "PATTERN_B"
              | "PATTERN_C"
              | "PATTERN_D"
              | "PATTERN_E",
            priorityType1Value: null,
            priorityType2Value: null,
            priorityType3Value: null,
            isType1TopPriority: false,
          });
        }
      );

      if (this.form.qosType === "ABSOLUTE" && this.qosDetail.qosAbsolute) {
        this.form.qosAbsolute = {
          ...this.qosDetail.qosAbsolute,
        };
      }
      if (this.form.qosType === "BANDWIDTH" && this.qosDetail.qosBandwidth) {
        this.qosBandwidth = {
          priorityType1: this.qosDetail.qosBandwidth?.priorityType1,
          priorityType2: this.qosDetail.qosBandwidth?.priorityType2,
          priorityType3: this.qosDetail.qosBandwidth?.priorityType3,
          qosBandwidthList: [],
        };
        [
          "PATTERN_A",
          "PATTERN_B",
          "PATTERN_C",
          "PATTERN_D",
          "PATTERN_E",
        ].forEach((pattern) => {
          const item = this.qosDetail.qosBandwidth?.qosBandwidthList?.find(
            (e) => e.patternName === pattern
          );
          if (item) {
            this.qosBandwidth.qosBandwidthList.push({
              patternName: item.patternName,
              priorityType1Value: item.priorityType1Value,
              priorityType2Value: item.priorityType2Value,
              priorityType3Value: item.priorityType3Value,
              isType1TopPriority: item.isType1TopPriority,
            });
          } else {
            this.qosBandwidth.qosBandwidthList.push({
              patternName: pattern as
                | "PATTERN_A"
                | "PATTERN_B"
                | "PATTERN_C"
                | "PATTERN_D"
                | "PATTERN_E",
              priorityType1Value: null,
              priorityType2Value: null,
              priorityType3Value: null,
              isType1TopPriority: false,
            });
          }
        });
      }
    },
    changeAbsolutePriorities() {
      if (this.form.qosType === "ABSOLUTE" && this.form.qosAbsolute) {
        if (this.form.priorityTarget === "IPPRECEDENCE") {
          this.form.qosAbsolute.priority6 = "HIGH";
        }
        if (this.absolutePattern === "A") {
          if (
            this.form.priorityTarget === "IPPRECEDENCE" ||
            this.form.priorityTarget === "COS"
          ) {
            this.form.qosAbsolute = {
              ...this.qosAbsolutDefaultPatterns.patternA,
            };
          }
        }
        if (this.absolutePattern === "B") {
          if (this.form.priorityTarget === "IPPRECEDENCE") {
            // L2VN面のときのみここのコードが実行される
            // 特記事項
            // IP Precedence_6はHigh固定で、変更不可
            const priority6 = this.form.qosAbsolute.priority6;
            this.form.qosAbsolute = {
              ...this.qosAbsolutDefaultPatterns.patternBIp,
            };
            this.form.qosAbsolute.priority6 = priority6;
          } else if (this.form.priorityTarget === "COS") {
            this.form.qosAbsolute = {
              ...this.qosAbsolutDefaultPatterns.patternBCos,
            };
          }
        }
      }
    },
    /**
     * 帯域制御方式の優先タイプ値の合計を算出
     * @param item QoS帯域設定地情報
     */
    sumPriorityTypeValue(item: Omit<QosBandwidthItem, "bestEffort">): number {
      return (
        (item.priorityType1Value || 0) +
        (item.priorityType2Value || 0) +
        (item.priorityType3Value || 0)
      );
    },
    /**
     * 帯域制御方式のベストエフォートを算出
     * @param item QoS帯域設定地情報
     */
    calcBestEffort(item: Omit<QosBandwidthItem, "bestEffort">): string {
      return (item.priorityType1Value || 0) +
        (item.priorityType2Value || 0) +
        (item.priorityType3Value || 0) >
        0
        ? `${
            100 -
            ((item.priorityType1Value || 0) +
              (item.priorityType2Value || 0) +
              (item.priorityType3Value || 0))
          }%`
        : "-";
    },
    async submit() {
      const putItem: PutQosDetail = cloneDeep(this.form);

      if (putItem.qosType === "ABSOLUTE") {
        putItem.qosBandwidth = null;
      }

      if (putItem.qosType === "BANDWIDTH") {
        putItem.qosAbsolute = null;
        putItem.qosBandwidth = { ...this.qosBandwidth };
      }

      await this.$modal.show(QosSettingViewRC, {
        mode: "C",
        isNew: this.isNew,
        qosDetail: putItem,
      });

      await this.$api.qosApi.putQosDetail(this.vpnVnCode, putItem);

      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 * ファイル種別
 * 以下のいずれかを設定する。
 * ACCOUNT_LIST : アカウント一覧
 * ACCOUNTING_LOG : アカウンティングログ
 */
export type FileType = "ACCOUNT_LIST" | "ACCOUNTING_LOG";

/**
 * ステータス
 * 以下のいずれかを設定する。
 * EXECUTION : 実行中
 * SUCCESS : 成功
 * FAILURE : 失敗
 * NODATA ：該当データ無し
 */
export type Status = "EXECUTION" | "SUCCESS" | "FAILURE" | "NODATA";

/**
 * 権限種別
 * 以下のいずれかを設定する。
 * CUSTOMER : 法人権限
 * OPERATOR : 代行権限
 */
export type AuthorityType = "CUSTOMER" | "OPERATOR";

export interface CpaDomain {
  /** CPA契約SEQ */
  cpaContractSeq: string;
  /** ドメイン */
  domain: string;
}

export interface CpaDomains {
  /** 総件数 */
  total: number;
  /** 開始済みドメインリスト */
  domainList: CpaDomain[];
}

export interface CpaIdFile {
  /** CPA_ID情報SEQ */
  cpaIdInformationSeq: string;
  /** ドメイン */
  domain: string;
  /** ファイル種別 */
  fileType: FileType;
  /** ファイルサイズ(MB単位) */
  fileSize: number;
  /**  ファイル作成期間 */
  fileCreationPeriod: {
    /** 開始日時 */
    startDateTime: string;
    /** 終了日時 */
    endDateTime: string;
  } | null;
  /** 作成実行日時 */
  executeDateTime: string;
  /**
   * 進捗率
   * アカウンティングログの場合のみ使用
   */
  progressRate: number | null;
  /** ステータス */
  status: Status;
  /** 権限種別 */
  authorityType: AuthorityType;
}

export interface CpaAccounts {
  /** 総件数 */
  total: number;
  /** CPA_ID情報ファイルリスト */
  cpaIdFileList: CpaIdFile[];
}

export interface CpaAccountDetail {
  /** ファイル種別 */
  fileType: FileType;
  /** 開始日時 */
  startDateTime: string | null;
  /** 終了日時 */
  endDateTime: string | null;
}

/** レポートAPI */
export default class CpaIdApi extends AbstractBaseApi {
  /** 開始済みのドメイン一覧取得API */
  async getCpaDomains(): Promise<CpaDomains> {
    return (await this.api.get("/v1/report/remoteaccess/cpa/domains")).data;
  }

  /** アカウント一覧とアカウンティングログの生成情報取得API */
  async getCpaAccounts(): Promise<CpaAccounts> {
    return (await this.api.get("/v1/report/remoteaccess/cpa/accounts")).data;
  }

  /**
   * アカウント一覧または、アカウンティングログ生成依頼API
   * @param cpaContractSeq CPA契約SEQ
   * @param data アカウント一覧または、アカウンティングログ情報
   */
  async createCpaContract(
    cpaContractSeq: string,
    data: CpaAccountDetail
  ): Promise<void> {
    await this.api.post(
      `/v1/report/remoteaccess/cpa/${cpaContractSeq}/account`,
      data
    );
  }

  /**
   * アカウント一覧または、アカウンティングログ取得API
   * @param cpaIdInformationSeq CPA_ID情報SEQ
   */
  downloadCpaAccountCsvURL(cpaIdInformationSeq: string): string {
    return this.getFullURL(
      `/v1/report/remoteaccess/cpa/account/${cpaIdInformationSeq}/download`,
      {
        params: {
          tokenId: this.store.state.user.token,
        },
      }
    );
  }
  /** CPA ID情報削除API */
  async deleteCpaAccount(cpaIdInformationSeq: string): Promise<void> {
    await this.api.get(
      `/v1/report/remoteaccess/cpa/account/${cpaIdInformationSeq}/download`,
      {
        params: {
          tokenId: this.store.state.user.token,
          operationType: "DELETE",
        },
      }
    );
  }
}

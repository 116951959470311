var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-large","title":"Microsoft追加"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('app-table',{attrs:{"primary-key":"menu","fields":_vm.msTb.fields,"items":_vm.items,"thead-class":"text-center"},scopedSlots:_vm._u([{key:"cell(cloudServiceMenu)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cloudServiceMenuTo")(item.cloudServiceMenu))),(item.cloudServiceMenu === 'MS')?_c('span',[_vm._v("(新規)")]):_vm._e()]}},{key:"cell(bandwidth)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("bandwidthTo")(item.bandwidth,"Mbps"))+" ")]}},{key:"head(description)",fn:function(){return [_c('app-badge',{attrs:{"item":"備考","isInfo":true,"is-required":false,"msg":_vm._f("msg")('info_max_length',{ maxLength: '200' })}})]},proxy:true},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [(item.cloudServiceMenu === 'MS')?_c('validation-provider',{attrs:{"name":'備考',"rules":{ max: 200 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-textarea',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}):[_vm._v(" "+_vm._s(_vm._f("emptyTo")(item.description,"-"))+" ")]]}}],null,true)})],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("設定")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
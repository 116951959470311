var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.profile.internetFW === 'ANY')?_c('p',[_c('label',[_vm._v("FW:")]),_c('span',[_vm._v("any")])]):(_vm.profile.internetFW === 'MAINTENANCE')?_c('p',[_c('label',[_vm._v("FW:")]),_c('span',[_vm._v("maintenance")])]):(_vm.profile.internetFW === 'USER')?_c('p',[_c('label',[_vm._v("FW:")]),_c('span',[_vm._v(_vm._s(_vm.profile.profileInternetFw.profileInternetFwName))])]):_vm._e(),(
      _vm.profile.idsIps === 'DEFAULT' ||
      _vm.profile.idsIps === 'IDS' ||
      _vm.profile.idsIps === 'IPS'
    )?_c('p',[_c('label',[_vm._v("IDS/IPS")])]):_vm._e(),(_vm.profile.urlFiltering === 'USER')?_c('p',[_c('label',[_vm._v("URL:")]),_c('span',[_vm._v(_vm._s(_vm.profile.profileUrl.profileUrlName))])]):_vm._e(),(
      _vm.profile.webAntiVirus === 'DEFAULT' ||
      _vm.profile.webAntiVirus === 'ALERT' ||
      _vm.profile.webAntiVirus === 'BLOCK'
    )?_c('p',[_c('label',[_vm._v("WebAV")])]):_vm._e(),(
      _vm.profile.mailAntiVirus === 'DEFAULT' ||
      _vm.profile.mailAntiVirus === 'ALERT' ||
      _vm.profile.mailAntiVirus === 'BLOCK'
    )?_c('p',[_c('label',[_vm._v("MailAV")])]):_vm._e(),(_vm.profile.isAntiSpyware)?_c('p',[_c('label',[_vm._v("AntiSpy")])]):_vm._e(),(_vm.isAllOff)?_c('p',[_c('label',[_vm._v("-")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }































































import Vue, { PropType } from "vue";
import VirtualNetworkEdit from "@/modals/virtualNetwork/VirtualNetworkEdit.vue";
import { VirtualNetworkEntity } from "@/apis/VirtualNetworkApi";

export default Vue.extend({
  name: "VirtualNetworkReference",
  props: {
    /** 仮想ネットワーク情報 */
    network: {
      type: Object as PropType<VirtualNetworkEntity>,
      required: true,
    },
    /** 仮想ネットワーク設定排他情報 */
    vnKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  methods: {
    /** 変更*/
    async showNetworkModify() {
      await this.$modal.show(VirtualNetworkEdit, {
        virtualNetwork: this.network,
        vnKeyId: this.vnKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 削除*/
    async showNetworkDel() {
      await this.$confirm("仮想ネットワークを削除します。よろしいですか？");
      await this.$api.virtualNetwork.delete({
        vnCodeList: [this.network.vnCode],
        vnKeyId: this.vnKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

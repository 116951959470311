





























import Vue, { PropType } from "vue";
import { CommandResult, LineCategory } from "@/apis/NwOperationToolApi";

export default Vue.extend({
  name: "OperationCmdReference",
  props: {
    /** 参照回線種別 */
    lineCategory: {
      type: String as PropType<LineCategory>,
      required: true,
    },
    /** X番号/W番号（E番号） */
    number: {
      type: String as PropType<string>,
      required: true,
    },
    /** コマンド登録番号 */
    entryNumbers: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      commands: [] as CommandResult[],
      isLoaded: false,
    };
  },
  computed: {
    /** コマンド名を変換し、実行結果のエスケープ処理を除去した結果の一覧、実施結果がnullのものは返却しない */
    displayCommandContents(): (string | null)[] {
      return this.commands
        .map((e) =>
          e.result
            ? (e.commandInfo.commandType === "PING" ||
              e.commandInfo.commandType === "TRACEROUTE"
                ? this.$filter.enumTo(
                    e.commandInfo.commandType,
                    "commandInfo_commandType_detail"
                  ) +
                  " " +
                  this.$filter.emptyTo(e.commandInfo.ipAddress, "-")
                : this.$filter.enumTo(
                    e.commandInfo.commandType,
                    "commandInfo_commandType_detail"
                  )) +
              "\n" +
              unescape(e.result)
                .replace(/^"/, "")
                .replace(/"$/, "")
                .replace(/\\n/g, "\n")
                .replace(/\\"/g, '"')
            : null
        )
        .filter((e) => e);
    },
  },
  async mounted() {
    for (const entryNumber of this.entryNumbers) {
      this.commands = [
        ...this.commands,
        this.lineCategory === "AC"
          ? await this.$api.nwOperationTool.getCommandResult("AC", entryNumber)
          : await this.$api.nwOperationTool.getCommandResult("VN", entryNumber),
      ];
    }
    this.isLoaded = true;
  },
  methods: {
    /** テキストダウンロード */
    download() {
      let feature = "";
      switch (this.lineCategory) {
        case "AC":
          feature = "IPsec";
          break;
        case "L2L3":
        case "VN":
          feature = this.lineCategory;
          break;
        case "CLOUD":
          feature = "multicloud";
          break;
      }
      this.$service.file.downloadText(
        `${this.number}_${feature}_operation_${this.$moment().format(
          "YYYYMMDDHHmmss"
        )}.log`,
        this.displayCommandContents.map((e) => e).join("\n\n")
      );
    },
  },
});

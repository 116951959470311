import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 文字列の両端が指定された文字列（配列指定で複数文字列禁止も可能）ではないこと. */
export default {
  params: ["notBoth"],
  validate(
    value: string | undefined | null,
    { notBoth }: { notBoth: string | string[] }
  ): boolean {
    if (!value) {
      return true;
    }

    if (typeof notBoth === "string") {
      return !value.startsWith(notBoth) && !value.endsWith(notBoth);
    } else {
      return !notBoth.some((str) => {
        return value.startsWith(str) || value.endsWith(str);
      });
    }
  },
} as ValidationRuleSchema;

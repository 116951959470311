import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { IgnoreLoading } from "@/apis/api-interceptors";

/** C0001：法人管理者 */
export const ROLE_ADMIN = "C0001";
/** C0002：設定権限 */
export const ROLE_CONFIG_USER = "C0002";
/** C0003：閲覧権限 */
export const ROLE_VIEW_USER = "C0003";
/** A0001：法人管理者権限代行(KDDI管理者) */
export const ROLE_DELEGATE_ADMIN_KDDI = "A0001";
/** A0011：法人管理者権限代行(パートナー) */
export const ROLE_DELEGATE_ADMIN_PARTNER = "A0011";
/** A0002：設定権限代行 */
export const ROLE_DELEGATE_CONFIG_USER = "A0002";
/** A0003：閲覧権限代行 */
export const ROLE_DELEGATE_VIEW_USER = "A0003";
/** ユーザ権限コード */
export type RoleCode =
  | typeof ROLE_ADMIN
  | typeof ROLE_CONFIG_USER
  | typeof ROLE_VIEW_USER
  | typeof ROLE_DELEGATE_ADMIN_KDDI
  | typeof ROLE_DELEGATE_ADMIN_PARTNER
  | typeof ROLE_DELEGATE_CONFIG_USER
  | typeof ROLE_DELEGATE_VIEW_USER;

export interface LoginUser {
  /** ユーザ権限コード */
  roleCode: RoleCode;
  /** ログイン日時（ISOフォーマット） */
  loginDateTime: string;
  /** ログアウト日時（ISOフォーマット） */
  logoutDateTime: string;
  /** パスワード有効期限（YYYY-MM-DD） */
  passwordExpiredDate: string;
  /** パスワード有効日数。有効日数がパスワード有効期限通知値(90日)を上回る場合、0を返す */
  passwordLimit: number;
  /** ログインID*/
  loginId: string;
  // TODO: 会社名やユーザー名などの情報を追加してもらう予定
}

export interface ProvisioningStatus {
  /**
   * メッセージコード.
   * CC99015E: 内部エラーが発生したため処理を継続できません。
   * CC99018W: エラーが発生したため処理を継続できません。
   * CC99016W: 只今設定反映中のため、設定できません。
   * CC99017I: 只今、設定が可能です。
   */
  messageCode: "CC99015E" | "CC99018W" | "CC99016W" | "CC99017I";
  /** メッセージ */
  message: string;
}

export interface ProvisioningStatusDetail {
  /**
   * プロビジョニングステータス.
   * 以下のいずれかを設定する。
   * SCHEDULED：予約中
   * STARTED：設定準備中
   * ACCEPTED：設定中
   * REJECTED：拒否
   * SUCCESS：成功
   * FAILED：コントローラー起因によるプロビ失敗
   * SUSPENDED：コントローラー起因によるプロビ失敗(手動的に修正)
   * INTERNAL_ERROR：カスコン内部エラーによるプロビ失敗
   * FAILED_BUT_SITE：コントローラー起因によるプロビ失敗(サイトのみ成功)
   * WAIT_FOR_PERMISSION：承認待ち
   * SUCCESS_NO_PROV：成功(プロビなし)
   * CONFLICTED：競合
   * WAIT_FOR_RESPONSE：受付完了、処理待ち
   * REBUILDING：再作成中(回線解約)
   */
  provisioningStatus?:
    | "SCHEDULED"
    | "STARTED"
    | "ACCEPTED"
    | "REJECTED"
    | "SUCCESS"
    | "FAILED"
    | "SUSPENDED"
    | "INTERNAL_ERROR"
    | "FAILED_BUT_SITE"
    | "WAIT_FOR_PERMISSION"
    | "SUCCESS_NO_PROV"
    | "CONFLICTED"
    | "WAIT_FOR_RESPONSE"
    | "REBUILDING";
  /**
   * プロビジョニング種別.
   * 以下のいずれかを設定する。
   * POLICY_SETTING：ポリシー設定
   * SERVICE_MENU：サービス申込
   * CONTRACT_LOADING：契約取込
   * MANUAL_PROV_BATCH：手動契約取込
   * APPLIANCE_CHANGE：収容変更
   * INTERNET_PAUSE：インターネット一時停止
   * VN_SERVICE_MENU：仮想NW申込
   * VN_LINE_SETTING：VNコネクト設定
   * VN_FLOW_SETTING：フロー設定
   * VN_LINE_VALIDITY_SETTING：VN回線有効無効設定
   * IF_SHUTDOWN：インターフェース設定
   * INTERNET_TYPE3_CONSTRUCT：サービス申込(Type3)
   * BANDWIDTH_SETTING：アクセス品目申込
   * IPSEC_SETTING：IPsec設定
   * EXTRANET_SETTING_ACCEPT：エクストラネット接続申込
   * TYPE3_SERVICE_MENU：サービス申込(Type3)
   * IPSEC_VCLINE_SETTING：IPsec設定
   * TYPE3_POLICY_SETTING：Type3ポリシー設定
   * TYPE3_ROUTE_SETTING：Type3経路設定
   * PFGW_POLICY_SETTING：ポリシー設定(プラットフォームゲートウェイ)
   * VN_LINE_VALIDITY_SETTING_VNSWITCH：VN回線有効無効設定（収容変更）
   * VN_FLOW_SETTING_VNSWITCH：フロー設定（収容変更）
   * VN_LINE_SETTING_VNSWITCH：VN回線設定（収容変更）
   * IF_SHUTDOWN_VNSWITCH：インターフェース設定（収容変更）
   * CONTRACT_CANCEL：契約取込
   * VN_CHANGE：VN回線収容変更
   * HUB_SPOKE_REQUEST：Hub&Spoke接続申込
   * VN_L2_L3_LINE：VN L2/L3コネクト設定
   * TRAFFIC_COLLECT_REQUEST：トラフィック情報取得開始
   * VN_LINE_LUMPING_SETTING：VNコネクト一括設定
   * CLOUD_BANDWIDTH_SETTING：クラウド毎帯域設定
   * SVLAN_GET：サービスタグ取得
   * MS_PEERING：サービス申込(Microsoftサービス)
   * CLOUD_VN_LINE_SETTING：クラウド向けVNコネクト設定
   * CLOUD_VN_LINE_VALIDITY_SETTING：クラウド向けVN回線有効無効設定
   * CLOUD_IF_SHUTDOWN：クラウド向けインターフェース設定
   * TYPE4_SERVICE_MENU：サービス申込(Type4)
   * TYPE4_POLICY_SETTING：Type4ポリシー設定
   * TYPE4_ROUTE_SETTING：Type4経路設定
   * CPA_5GSA_SERVICE_MENU：CPA 5G SA 契約変更
   * CPA_5GSA_NW_SETTING：CPA 5G SA NW設定
   * CPA_5GSA_USE_START：CPA 5G SA 利用開始
   * CPA_5GSA_AUTH_ID_LUMPING_SETTING：CPA 5G SA 認証ID一括登録
   * CPA_5GSA_CREATE_ID_INFORMATION：CPA 5G SA ID情報生成
   */
  provisioningType?:
    | "POLICY_SETTING"
    | "SERVICE_MENU"
    | "CONTRACT_LOADING"
    | "MANUAL_PROV_BATCH"
    | "APPLIANCE_CHANGE"
    | "INTERNET_PAUSE"
    | "VN_SERVICE_MENU"
    | "VN_LINE_SETTING"
    | "VN_FLOW_SETTING"
    | "VN_LINE_VALIDITY_SETTING"
    | "IF_SHUTDOWN"
    | "INTERNET_TYPE3_CONSTRUCT"
    | "BANDWIDTH_SETTING"
    | "IPSEC_SETTING"
    | "EXTRANET_SETTING_ACCEPT"
    | "TYPE3_SERVICE_MENU"
    | "IPSEC_VCLINE_SETTING"
    | "TYPE3_POLICY_SETTING"
    | "TYPE3_ROUTE_SETTING"
    | "PFGW_POLICY_SETTING"
    | "VN_LINE_VALIDITY_SETTING_VNSWITCH"
    | "VN_FLOW_SETTING_VNSWITCH"
    | "VN_LINE_SETTING_VNSWITCH"
    | "IF_SHUTDOWN_VNSWITCH"
    | "CONTRACT_CANCEL"
    | "VN_CHANGE"
    | "HUB_SPOKE_REQUEST"
    | "VN_L2_L3_LINE"
    | "TRAFFIC_COLLECT_REQUEST"
    | "VN_LINE_LUMPING_SETTING"
    | "CLOUD_BANDWIDTH_SETTING"
    | "SVLAN_GET"
    | "MS_PEERING"
    | "CLOUD_VN_LINE_SETTING"
    | "CLOUD_VN_LINE_VALIDITY_SETTING"
    | "CLOUD_IF_SHUTDOWN"
    | "TYPE4_SERVICE_MENU"
    | "TYPE4_POLICY_SETTING"
    | "TYPE4_ROUTE_SETTING"
    | "CPA_5GSA_SERVICE_MENU"
    | "CPA_5GSA_NW_SETTING"
    | "CPA_5GSA_USE_START"
    | "CPA_5GSA_AUTH_ID_LUMPING_SETTING"
    | "CPA_5GSA_CREATE_ID_INFORMATION";
}

export interface NoticeInfoResponse {
  /** お知らせ総件数 */
  noticeInfoTotal: number;
  /** お知らせ情報リスト 掲載開始日時の降順でソートする */
  noticeInfoList: NoticeInfo[];
}

export interface NoticeInfo {
  /** お知らせSEQ 主キー */
  noticeInfoSeq: string;
  /** お知らせタイトル名 */
  noticeInfoTitle: string;
  /** お知らせ種別
   *  以下のいずれかを設定する。
   *  NOTIFICATION: お知らせ
   *  FAILURE_NOTIFICATION: お知らせ(障害)
   *  MAINTENANCE: メンテナンス
   */
  noticeType: "NOTIFICATION" | "FAILURE_NOTIFICATION" | "MAINTENANCE";
  /** 掲載開始日時 (ISOフォーマット 例：2021-11-23T00:09:44+09:00)*/
  postStartDateTime: string;
  /** 既読フラグ
   *  trueの場合は既読、falseの場合は未読とする。
   *  代行ユーザの場合はNULLを返す。
   */
  isRead: boolean | null;
}

export interface NoticeInfoDetail {
  /** お知らせタイトル名 */
  noticeInfoTitle: string;
  /** 掲載開始日時 (ISOフォーマット 例：2021-11-23T00:09:44+09:00)*/
  postStartDateTime: string;
  /** 掲載終了日時 (ISOフォーマット 例：2021-11-23T00:09:44+09:00)*/
  postEndDateTime: string;
  /** 内容(本文) */
  noticeText: string;
  /** 障害発生日時
   *  種別「お知らせ(障害)」以外のお知らせ情報の場合はNULLを返す。
   *  障害調査中フラグがtrueの場合はNULLを返す。
   */
  failureDateTime: string | null;
  /** 障害復旧日時
   *  種別「お知らせ(障害)」以外のお知らせ情報の場合はNULLを返す。
   *  障害復旧種別が復旧未定の場合はNULLを返す。
   */
  recoveryDateTime: string | null;
  /** 障害調査中フラグ
   *  種別「お知らせ(障害)」以外のお知らせ情報の場合はNULLを返す。
   */
  isUnderInvestigation: boolean | null;
  /** 障害復旧種別
   *  種別「お知らせ(障害)」以外のお知らせ情報の場合はNULLを返す。
   *  以下のいずれかを設定する。
   *  UNDECIDED: 復旧未定
   *  PROSPECT: 復旧見込み
   *  COMPLETE: 復旧完了
   */
  recoveryType: "UNDECIDED" | "PROSPECT" | "COMPLETE" | null;
}

export interface FileInfoResponse {
  /** ダウンロード総件数 */
  downloadTotal: number;
  /** ダウンロードファイルリスト */
  downloadList: FileInfo[];
}

export interface FileInfo {
  /** ダウンロードSEQ */
  downloadSeq: string;
  /** ファイル名 */
  fileName: string;
  /** ダウンロードタイトル */
  downloadTitle: string;
}

export interface SpecialNoteResponse {
  /**
   * 特記事項情報のリスト
   * 取得データが存在しない場合には空配列を返却する。
   */
  corporationInfoList: SpecialNote[];
}

export interface SpecialNote {
  /** 特記事項（タイトル） */
  title: string | null;
  /** 特記事項（本文） */
  body: string | null;
  /** 特記事項（リンクタイトル） */
  linkTitle: string | null;
  /** 特記事項（リンク） */
  linkUrl: string | null;
}

export interface VpnVnCodeListResponse {
  /** 総件数 */
  total: number;
  /** VPN/VNコードリスト
   *  VPN/VNコードとVNコードに紐づく仮想ネットワーク名、VLANを返却する。
   *  該当するVPN/VNコードがない場合は空配列となる。
   */
  vpnVnCodeList: VpnVnCode[];
}

export interface VpnVnCode {
  /** VPN/VNコード */
  vpnVnCode: string;
  /** 仮想ネットワーク名
   *  vpnVnCodeがVPNコード指定の場合Null */
  vnName: string | null;
  /** 仮想ネットワークタイプ
   *  L2回線、L3回線どちらかを選択する。vpnVnCodeがVPNコード指定の場合Null
   */
  vnType: "L2" | "L3" | null;
  /** VLAN IDのType指定
   *  vnTypeがL2のときのみ適用項目
   *  vnTypeがL3のときはNull
   *  UNTAG: IDの指定なし
   *  OTHER: 他の仮想ネットワークで指定されていないID,もしくは指定なし
   *  SPECIFIED: 指定ID
   */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED" | null;
  /** VLAN ID
   *  vnTypeがL2かつ、vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときのみ適用項目
   *  vnTypeがL3、もしくはvnTypeがL2でvlanIdTypeがUNTAGかOTHERの場合はnull
   */
  vlanId: number | null;
}

export interface FailureListResponse {
  /**
   * 総件数
   * 一覧表示対象の総件数
   */
  total: number;
  /**
   * VNコネクトの総件数
   * VNコネクト一覧表示対象の総件数
   */
  vnConnectTotal: number;
  /**
   * IPsecの総件数
   * IPSec一覧表示対象の総件数
   */
  ipsecTotal: number;
  /**
   * CPAの総件数
   * CPA一覧表示対象の総件数
   */
  cpaTotal: number;
  /**
   * VNコネクトL2L3の総件数
   * VNコネクトL2L3一覧表示対象の総件数
   */
  l2l3Total: number;
  /**
   * マルチクラウドの総件数
   * マルチクラウド一覧表示対象の総件数
   */
  cloudTotal: number;
  /**
   * type4の総件数
   * type4一覧表示対象の総件数
   */
  type4Total: number;
  /**
   * VNコネクトリスト
   * VNコネクトの情報がない場合空配列になる
   */
  vnConnectList: (VNConnectFailure & CommonFailure)[];
  /**
   * IPsecリスト
   * IPsecの情報がない場合空配列になる
   */
  ipsecList: (IpsecFailure & CommonFailure)[];
  /**
   * 5GSAのCPA回線リスト
   * 5GSAのCPA回線の情報がない場合空配列になる
   */
  cpaList: (CpaFailure & Pick<CommonFailure, "maintenance" | "failure">)[];
  /**
   * VNL2L3コネクトリスト
   * VNコネクトL2L3の情報がない場合空配列になる
   */
  vnl2l3ConnectList: (VNL2L3ConnectFailure & CommonFailure)[];
  /**
   * マルチクラウドリスト
   * マルチクラウドの情報がない場合空配列になる
   */
  cloudList: (CloudFailure & CommonFailure)[];
  /**
   * Type4インターネットリスト
   * インターネットTYPE4の情報がない場合空配列になる
   */
  type4List: (InternetType4Failure &
    Pick<CommonFailure, "maintenance" | "failure">)[];
}

export interface CommonFailure {
  /**
   * IF状態
   * 変更がなければNULLを返す。
   * NO_SHUTDOWN: 開放状態
   * SHUTDOWN: 閉塞状態
   */
  ifStatus: "NO_SHUTDOWN" | "SHUTDOWN" | null;
  /**
   * コマンド状態
   * EXE: 実行中
   * OK: 完了
   * NG: 失敗
   * NO_EXE: 未実行
   */
  commandStatus: "EXE" | "OK" | "NG" | "NO_EXE" | null;
  /**
   * メンテナンス情報
   * 簡易検索の場合にはカスコン内部のデータを取得する
   * カスコン内部にデータがない場合にはNULLになる
   * NULLの場合に画面上メンテナンス情報欄に「なし」と表示する
   */
  maintenance: string | null;
  /**
   * 障害情報
   * 簡易検索の場合にはカスコン内部のデータを取得する
   * カスコン内部にデータがない場合にはNULLになる
   * NULLの場合に画面上障害情報欄に「なし」と表示する
   */
  failure: string | null;
}

interface VNConnectFailure {
  /**
   * W番号
   * 主キー相当
   */
  wnumber: string;
  /**
   * VNコネクト名
   */
  vnConnectName: string;
  /**
   * E番号
   */
  enumber: string;
  /**
   * VPN/VNコード
   */
  vpnVnCode: string;
  /**
   * VLAN IDのType指定
   * UNTAG: IDの指定なし,
   * OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし,
   * SPECIFIED: 指定ID
   */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED";
  /**
   * VLAN ID
   * vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに必須項目
   */
  vlanId: number | null;
  /**
   * VNコネクト有効/無効フラグ
   * true: 有効
   * false: 無効
   */
  isVnConnectStatus: boolean | null;
}

interface IpsecFailure {
  /**
   * X番号
   * 主キー相当
   */
  xnumber: string;
  /** IPsec回線名 */
  ipsecLineName: string;
  /** USER-FQDN */
  userFqdn: string;
  /** E番号 */
  enumber: string;
}

interface CpaFailure {
  /**
   * W番号
   * NW未設定の場合はNULL
   */
  wnumber: {
    /** W番号(ACT) */
    wnumberAct: string;
    /** W番号(SBY) */
    wnumberSby: string;
  } | null;
  /** ドメイン名 */
  domain: string;
}

interface VNL2L3ConnectFailure {
  /**
   * W番号
   * 主キー相当
   */
  wnumber: string;
  /** VNコネクト名 */
  vnConnectName: string;
  /** E番号 */
  enumber: string;
  /** L2仮想ネットワーク名 */
  l2VnName: string;
  /** L2VNコード */
  l2VnCode: string;
  /**
   * L3回線VLAN IDのType指定
   * UNTAG: IDの指定なし,
   * SPECIFIED: 指定ID
   * */
  l3VlanType: "UNTAG" | "SPECIFIED";
  /**
   * VLAN ID
   * vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに必須項目
   */
  l3VlanId: number | null;
  /** L3仮想ネットワーク名 */
  l3VnName: string;
  /** L3VNコード */
  l3VnCode: string;
}

interface CloudFailure {
  /**
   * W番号
   * 主キー相当
   */
  wnumber: string;
  /** VNコネクト名 */
  vnConnectName: string;
  /** E番号 */
  enumber: string;
  /**
   * クラウドサービス種別
   * AWS :AWS
   * PRIVATE_PEERING :Private-Peering
   * MICROSOFT_PEERING :Microsoft-Peering
   * GCP :GCP
   * ORACLE:Oracle
   * IBM :IBM
   * SFDC :SFDC*/
  cloudServiceType:
    | "AWS"
    | "PRIVATE_PEERING"
    | "MICROSOFT_PEERING"
    | "GCP"
    | "ORACLE"
    | "IBM"
    | "SFDC";
  /** VPN/VNコード */
  vpnVnCode: string;
  /**
   * VNコネクト有効/無効フラグ
   * true: 有効
   * false: 無効
   */
  isVnConnectStatus: boolean | null;
}

interface InternetType4Failure {
  /** Type4ID */
  type4Id: string;
  /** W番号(Main_ACT) */
  wnumber: string;
  /** VNコネクト名 */
  vnConnectName: string;
  /** VPN/VNコード */
  vpnVnCode: string;
}

export interface FailureUpdateInformation {
  /**
   * 障害情報取得の排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと.
   */
  failureKeyId: string;
  /**
   * 障害状態
   * UPDATING: 更新中
   * COMPLETED: 完了
   */
  failureStatus: "UPDATING" | "COMPLETED";
  /** 最終更新日時 */
  lastUpdateDateTime: string;
}

export interface FailureKeyRequest {
  /**
   * 障害情報取得の排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと.
   */
  failureKeyId: string;
}

export default class InformationApi extends AbstractBaseApi {
  /** ログインユーザーに紐づく情報を取得 */
  async getLoginUser(): Promise<LoginUser> {
    return (await this.api.get("/v1/information/login-user")).data;
  }

  /** ロゴファイルの取得を行う */
  async getServiceLogo(): Promise<string> {
    return (
      await this.api.get("/v1/information/service-logo", {
        params: {
          tokenId: this.store.state.user.token,
        },
      })
    ).data;
  }

  /**
   * 特記事項を取得
   */
  async getSpecialNotes(): Promise<SpecialNote[]> {
    const { corporationInfoList }: SpecialNoteResponse = (
      await this.api.get("/v1/information/specialnotes")
    ).data;
    return corporationInfoList;
  }

  /**
   * VPN/VNコードの一覧を取得
   */
  async getVpnVnCodeList(): Promise<VpnVnCodeListResponse["vpnVnCodeList"]> {
    const { vpnVnCodeList }: VpnVnCodeListResponse = (
      await this.api.get(`/v1/information/vpn-vn-code`)
    ).data;
    return vpnVnCodeList;
  }

  /**
   * プロビジョニングステータスを取得
   * @param params クエリパラメーター
   *               qosType QoSタイプ
   *                  QoS/クラウドQoS設定時の確認用
   *                  全パターンチェックをする場合にはnullを指定する。
   *                  QOS：QoS
   *                  CLOUD_QOS：クラウドQoS
   * @param loading true: ローディング表示, false: ローディング非表示
   */
  async getProvisioningStatus(
    params: { qosType?: "QOS" | "CLOUD_QOS" } = {},
    loading = true
  ): Promise<ProvisioningStatus> {
    return (
      await this.api.get("/v1/information/provisioning-status", {
        ...(loading ? {} : IgnoreLoading),
        params,
      })
    ).data;
  }

  /**
   * プロビジョニングステータスの詳細情報を取得
   * @param jobId プロビジョニング実行ID.
   *              プロビジョニングを実施する各機能APIのレスポンスで返却されたjobIdを指定する
   */
  async getProvisioningStatusDetail(
    jobId: string
  ): Promise<ProvisioningStatusDetail> {
    return (await this.api.get(`/v1/information/provisioning-status/${jobId}`))
      .data;
  }

  /**
   * お知らせ情報一覧を取得
   */
  async getNotifications(): Promise<NoticeInfoResponse["noticeInfoList"]> {
    const { noticeInfoList }: NoticeInfoResponse = (
      await this.api.get(`/v2/information/notifications`)
    ).data;
    return noticeInfoList;
  }

  /**
   * お知らせ詳細情報を取得
   * @param noticeInfoSeq お知らせSEQ(主キー)
   */
  async getNotificationDetail(
    noticeInfoSeq: string
  ): Promise<NoticeInfoDetail> {
    return (await this.api.get(`/v2/information/notification/${noticeInfoSeq}`))
      .data;
  }

  /**
   * 各種ダウンロード情報一覧を取得
   */
  async getDownloadList(): Promise<FileInfoResponse["downloadList"]> {
    const { downloadList }: FileInfoResponse = (
      await this.api.get(`/v1/information/downloads`)
    ).data;
    return downloadList;
  }

  /**
   * Informationダウンロード用URLを取得
   * @param downloadSeq ダウンロードSEQ
   */
  getInformationDownloadUrl(downloadSeq: string): string {
    return this.getFullURL(`/v1/information/download/${downloadSeq}`, {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }

  /**
   * 障害情報一覧をDBから取得
   * @param queries クエリパラメータ群
   * @param loading true: ローディング表示, false: ローディング非表示
   */
  async getFailureList(
    queries?: {
      /**
       * サービス
       * 指定していなければ全表示する.
       * VN ：VNコネクト
       * IPSEC ：IPsec
       * VNL2L3 ：VN L2/L3コネクト
       * CPA_5GSA ：CPA 5GSA
       * MULTICLOUD：マルチクラウド
       * TYPE4 ：インターネットTYPE4
       */
      service?:
        | "VN"
        | "IPSEC"
        | "VNL2L3"
        | "CPA_5GSA"
        | "MULTICLOUD"
        | "TYPE4"
        | null;
      /**
       * メンテナンス情報
       * true: あり
       * false: なし
       */
      maintenance?: boolean | null;
      /**
       * 障害情報
       * true: あり
       * false: なし
       */
      failure?: boolean | null;
      /**
       * IF状態
       * NO_SHUTDOWN: 開放状態
       * SHUTDOWN: 閉塞状態
       */
      ifStatus?: "NO_SHUTDOWN" | "SHUTDOWN" | null;
      /**
       * W番号
       * サービスがIPsec以外の場合は指定可能.絞り込まない場合は指定しない or NULLを指定する.
       */
      wnumber?: string | null;
      /**
       * ドメイン名
       * サービスがCPA 5GSAの場合は指定可能.絞り込まない場合は指定しない or NULLを指定する.
       */
      domain?: string | null;
      /**
       * VNコネクト名
       * サービスがIPsecとCPA 5GSA以外の場合は指定可能.絞り込まない場合は指定しない or NULLを指定する.
       */
      vnConnectName?: string | null;
      /**
       * E番号
       * サービスがTYPE4とCPA 5GSA以外の場合は指定可能.絞り込まない場合は指定しない or NULLを指定する.
       */
      enumber?: string | null;
      /**
       * VPN/VNコード
       * サービスがVNコネクト、マルチクラウド、TYPE4の場合は指定可能.絞り込まない場合は指定しない or NULLを指定する.
       */
      vpnVnCode?: string | null;
    },
    loading = true
  ): Promise<FailureListResponse> {
    return (
      await this.api.get("/v2/information/failure", {
        params: { ...queries },
        ...(loading ? {} : IgnoreLoading),
      })
    ).data;
  }

  /**
   * 障害情報の更新状態ステータスの情報取得
   * @param loading true: ローディング表示, false: ローディング非表示
   */
  async getFailureInformation(
    loading = true
  ): Promise<FailureUpdateInformation> {
    return (
      await this.api.get(
        "/v1/information/failure/update",
        loading ? undefined : IgnoreLoading
      )
    ).data;
  }

  /**
   * 最新の障害情報をネットワーク装置側から取得しDBを更新する.（非同期更新）
   * @param data 障害情報取得の排他情報
   */
  async updateFailureInformation(data: FailureKeyRequest): Promise<void> {
    await this.api.post("/v1/information/failure/update", data);
  }

  /**
   * お知らせ一覧の既読をDB更新する
   * @param noticeInfoSeq お知らせSEQ
   */
  async putRead(noticeInfoSeq: string): Promise<void> {
    await this.api.post(`/v1/information/notification/${noticeInfoSeq}/read`);
  }
}

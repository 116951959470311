










































































































































































import Vue, { PropType } from "vue";
import {
  CommandInfo,
  LineCategory,
  PostCloudInformationRequest,
  PostConnectivityRequest,
  PostReferenceRequest,
  PutIfStateRequest,
} from "@/apis/NwOperationToolApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import OperationCmdReference from "@/modals/common/OperationCmdReference.vue";
import IFModify from "@/modals/common/IFModify.vue";
import { VNConnectDetail } from "@/apis/VNConnectApi";
import { SystemError } from "@/error/errors";

/** コマンド実行時のemitイベント */
export type EventEmit =
  | "IF_STATE"
  | "REFERENCE"
  | "CONNECTIVITY"
  | "CLOUD_INFORMATION";

/** 運用ツールコマンドタブ */
export default Vue.extend({
  name: "OperationCommand",
  props: {
    /** 参照回線種別 */
    lineCategory: {
      type: String as PropType<LineCategory>,
      required: true,
    },
    /**
     * W番号（E番号）.
     * 参照回線種別がVNコネクト、VNL2L3、マルチクラウドの場合は必須
     */
    wnumber: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    /**
     * X番号.
     * 参照回線種別がACの場合は必須
     */
    xnumber: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    /**
     * true: IF設定表示（デフォルト）
     * VNコネクトがV番連携の場合やクラウドのMSの場合などに非表示
     */
    showIfStatus: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** IF状態 */
    ifStatus: {
      type: String as PropType<"SHUTDOWN" | "NO_SHUTDOWN" | null>,
      default: null,
    },
    /**
     * 排他制御情報.
     * IF状態変更/参照コマンド/疎通確認コマンド/クラウド情報取得の場合必須
     */
    keyId: {
      type: String as PropType<string>,
      required: true,
    },
    /** VNコネクト詳細. 参照回線種別がVNの場合は必須 */
    vnConnectDetail: {
      type: [Object, undefined] as PropType<
        Pick<VNConnectDetail, "vnType" | "l3"> | undefined
      >,
      default: undefined,
    },
    /** L2L3の種別. 参照回線種別がL2L3の場合は必須 */
    routingType: {
      type: String as PropType<"STATIC" | "OSPF" | "BGP4" | undefined>,
      default: undefined,
    },
  },
  data() {
    return {
      form: {
        ifState: {
          wnumber: this.wnumber,
          xnumber: this.xnumber,
          ifStatus: this.ifStatus ?? "SHUTDOWN",
          ifStateKeyId: this.keyId,
        } as PutIfStateRequest,
        reference: {
          wnumber: this.wnumber,
          xnumber: this.xnumber,
          referenceCommandKeyId: this.keyId,
        } as PostReferenceRequest,
        connectivity: {
          wnumber: this.wnumber,
          xnumber: this.xnumber,
          commandType: "PING",
          ipAddress: "",
          connectTestKeyId: this.keyId,
        } as PostConnectivityRequest,
        cloudinformation: {
          wnumber: this.wnumber,
          cloudLineInfomationKeyId: this.keyId,
        } as PostCloudInformationRequest,
      },
      commandResults: {
        fields: [
          { key: "createDateTime", label: "コマンド登録日時" },
          { key: "commandType", label: "コマンド" },
          { key: "ipAddress", label: "指定アドレス" },
          { key: "commandStatus", label: "ステータス" },
          { key: "endDateTime", label: "コマンド応答日時" },
        ] as BvTableFieldArray,
        items: [] as CommandInfo[],
        selected: [] as CommandInfo[],
      },
      alertMsg: null as string | null,
      isLoaded: false,
    };
  },
  watch: {
    keyId(value) {
      this.form.ifState.ifStateKeyId = value;
      this.form.reference.referenceCommandKeyId = value;
      this.form.connectivity.connectTestKeyId = value;
      this.form.cloudinformation.cloudLineInfomationKeyId = value;
    },
  },
  computed: {
    /** 参照コマンド一覧 */
    references(): {
      value: PostReferenceRequest["commandType"];
      text: string;
    }[] {
      const values = ((): PostReferenceRequest["commandType"][] => {
        switch (this.lineCategory) {
          // IPSec回線
          case "AC":
            return ["GATEWAY", "SA"];
          // VNコネクト（L2, L3-Static, L3-BGP4）
          case "VN":
            if (this.vnConnectDetail!.vnType === "L2") {
              // L2
              return ["MAC"];
            } else {
              if (this.vnConnectDetail!.l3?.bgp4) {
                // L3 BGP4
                return ["BGP", "ROUTE", "ARP"];
              } else {
                // L3 Static
                return ["ROUTE", "ARP"];
              }
            }
          case "L2L3":
            switch (this.routingType!) {
              case "STATIC":
                return ["ROUTE", "ARP"];
              case "OSPF":
                return ["OSPF", "ROUTE", "ARP"];
              case "BGP4":
                return ["BGP", "ROUTE", "ARP"];
            }
            break;
          // マルチクラウド
          case "CLOUD":
            return ["BGP", "ROUTE", "ARP"];
        }
      })();

      return values.map((v) => {
        switch (v) {
          case "GATEWAY":
            return { value: v, text: "ゲートウェイ経路情報参照" };
          case "SA":
            return { value: v, text: "SA状態参照" };
          case "MAC":
            return { value: v, text: "MACテーブル参照" };
          case "BGP":
            return { value: v, text: "BGPネイバー参照" };
          case "ROUTE":
            return { value: v, text: "経路情報参照" };
          case "ARP":
            return { value: v, text: "ARP参照" };
          case "OSPF":
            return { value: v, text: "OSPFネイバー参照" };
        }
      });
    },
    /** true: 疎通確認表示, false: 非表示 */
    isShowConnectivity(): boolean {
      // VNコネクトでL2の場合は非表示
      switch (this.lineCategory) {
        case "VN":
          return this.vnConnectDetail!.vnType !== "L2";
        default:
          return true;
      }
    },
  },
  created() {
    // propsのバリデーション
    if (this.lineCategory === "AC" && this.xnumber === undefined) {
      throw new SystemError("required xnumber when AC");
    }
    if (this.lineCategory !== "AC" && this.wnumber === undefined) {
      throw new SystemError("required wnumber when not AC");
    }
    if (this.lineCategory === "VN" && this.vnConnectDetail === undefined) {
      throw new SystemError("required vnConnectDetail when VN");
    }
    if (this.lineCategory === "L2L3" && this.routingType === undefined) {
      throw new SystemError("required routingType when L2L3");
    }

    // 参照コマンドの初期値を最初の選択肢に設定
    this.form.reference.commandType = this.references[0].value;
  },
  async mounted() {
    await this.loadCommandResults();
    this.isLoaded = true;
  },
  methods: {
    /** コマンド実行結果一覧 */
    async loadCommandResults() {
      this.alertMsg = null;
      if (this.lineCategory === "AC") {
        this.commandResults.items =
          await this.$api.nwOperationTool.getCommandResultsAC(
            this.lineCategory,
            this.xnumber!
          );
      } else {
        this.commandResults.items =
          await this.$api.nwOperationTool.getCommandResultsVN(
            this.lineCategory,
            this.wnumber!
          );
      }
    },
    /** Shutdown/No Shutdown設定反映 */
    async execIfState() {
      this.alertMsg = null;
      await this.$modal.show(IFModify, {
        lineCategory: this.lineCategory,
        number: this.lineCategory === "AC" ? this.xnumber : this.wnumber,
        ifStatus: this.form.ifState.ifStatus,
      });
      await this.$api.nwOperationTool.putIfStatus(
        this.lineCategory,
        this.form.ifState
      );
      this.$emit("executed", "IF_STATE");
    },
    /** 参照コマンド実行 */
    async execReference() {
      this.alertMsg = null;
      await this.$api.nwOperationTool.postReference(
        this.lineCategory,
        this.form.reference
      );
      this.$emit("executed", "REFERENCE");
      await this.loadCommandResults();
    },
    /** 疎通確認コマンド実行 */
    async execConnectivity() {
      this.alertMsg = null;
      await this.$api.nwOperationTool.postConnectivity(
        this.lineCategory,
        this.form.connectivity
      );
      this.$emit("executed", "CONNECTIVITY");
      await this.loadCommandResults();
    },
    /** クラウド回線情報取得実行 */
    async execCloudInformation() {
      this.alertMsg = null;
      await this.$api.nwOperationTool.postCloudInformation(
        this.form.cloudinformation
      );
      this.$emit("executed", "CLOUD_INFORMATION");
      await this.loadCommandResults();
    },
    /** 運用コマンド実行結果表示 */
    showResult() {
      if (this.commandResults.selected.length === 0) {
        this.alertMsg = this.$msg("no_select_data");
        return;
      } else {
        this.alertMsg = null;
      }

      this.$modal.show(OperationCmdReference, {
        lineCategory: this.lineCategory,
        number: this.lineCategory === "AC" ? this.xnumber : this.wnumber,
        entryNumbers: this.commandResults.selected.map((e) => e.entryNumber),
      });
    },
    /** エラーメッセージを初期化する(親画面から呼び出される) */
    async alertClear() {
      this.alertMsg = null;
    },
  },
});



































































































































































































































































































































































































import Vue from "vue";
import {
  Type1ExtraInfo,
  Type1ExtraList,
  Type1InternetAddress,
  Type1InternetFQDN,
  Type1InternetInfo,
  Type1PrivateInfo,
  Type1PrivateLine,
  Type1PrivateList,
  Type1Zone,
} from "@/apis/Type1SiteApi";
import ZoneEdit from "@/modals/type1/ZoneEdit.vue";
import PrivateSiteReference from "@/modals/type1/PrivateSiteReference.vue";
import { AppTableData } from "@/components/AppTable.vue";
import InternetSiteReference from "@/modals/type1/InternetSiteReference.vue";
import ExtraSiteList from "@/modals/type1/ExtraSiteList.vue";
import PrivateZoneList from "@/modals/type1/PrivateZoneList.vue";
import { SortCompareFunc } from "@/services/SortService";

export default Vue.extend({
  name: "Type1SiteZoneList",
  data() {
    return {
      isLoaded: false,
      tabMode: 1 as 1 | 2 | 3,
      internetSiteZone: {} as Type1InternetInfo,
      extraSiteZone: {} as Type1ExtraInfo,
      privateSiteZone: {} as Type1PrivateInfo,
      /** グローバルゾーン */
      globalZone: {} as Type1Zone | null,
      /** エクストラゾーン */
      extraZone: {} as Type1Zone | null,
      /** プライベートゾーン */
      privateZoneList: [] as Type1Zone[],
      /** インターネットアドレス */
      addressList: {
        fields: FIELDS.address,
        items: [],
        selected: [],
        search: {
          ipAddressName: {
            type: "text",
            label: "アドレス名",
          },
          ipAddress: {
            type: "text",
            label: "アドレス",
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          ipAddressName: "like",
          ipAddress: "like",
        },
      } as AppTableData<Type1InternetAddress>,
      /** インターネットFQDN */
      fqdnList: {
        fields: FIELDS.fqdn,
        items: [],
        selected: [],
        search: {
          fqdnName: {
            type: "text",
            label: "FQDN名",
          },
          fqdn: {
            type: "text",
            label: "FQDN",
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          fqdnName: "like",
          fqdn: "like",
        },
      } as AppTableData<Type1InternetFQDN>,
      /** エクストラサイト */
      extra: {
        fields: FIELDS.extra,
        items: [],
        selected: [],
        search: {
          siteName: {
            type: "text",
            label: "サイト名",
          },
          ipAddressName: {
            type: "text",
            label: "アドレス名",
          },
          ipAddress: {
            type: "text",
            label: "アドレス",
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          siteName: "like",
          ipAddressName: "like",
          ipAddress: "like",
        },
      } as AppTableData<Type1ExtraList>,
      /** プライベートサイト */
      private: {
        fields: FIELDS.private,
        items: [],
        selected: [],
        search: {
          enumber: {
            type: "text",
            label: "回線番号",
          },
          siteName: {
            type: "text",
            label: "サイト名",
          },
          ipAddressName: {
            type: "text",
            label: "アドレス名",
          },
          ipAddress: {
            type: "text",
            label: "アドレス",
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          enumber: "like",
          siteName: "like",
          ipAddressName: "like",
          ipAddress: "like",
        },
      } as AppTableData<Type1PrivateList>,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    /** インターネットアドレスカスタムソート */
    customSortAddress(): SortCompareFunc<Type1InternetAddress> {
      return this.$service.tableSortCompare(
        ["ipAddressName", { nullLast: true }],
        ["ipAddress", "asc"]
      );
    },
    /** インターネットFqdnカスタムソート */
    customSortFqdn(): SortCompareFunc<Type1InternetFQDN> {
      return this.$service.tableSortCompare(
        ["fqdnName", { nullLast: true }],
        ["fqdn", "asc"]
      );
    },
    /** エクストラカスタムソート */
    customSortExtra(): SortCompareFunc<Type1ExtraList> {
      return this.$service.tableSortCompare(
        "siteName",
        ["ipAddressName", "asc", { nullLast: true }],
        ["ipAddress", "asc"]
      );
    },
    /** プライベートカスタムソート */
    customSortPrivate(): SortCompareFunc<Type1PrivateList> {
      return this.$service.tableSortCompare("enumber", ["siteName", "asc"]);
    },
  },
  methods: {
    /** 初期化機能 */
    async load() {
      // インターネットサイトゾーン情報を取得用APIを呼び出し、データ取得を行う
      await this.getInternetList();
      // エクストラサイトゾーン情報を取得用APIを呼び出し、データ取得を行う
      await this.getExtraList();
      // プライベートサイトゾーン情報を取得用APIを呼び出し、データ取得を行う
      await this.getPrivateList();
    },
    /** インターネットサイトタブ一覧更新 */
    async reloadInternet() {
      await this.getInternetList();
    },
    /** エクストラサイトタブ一覧更新 */
    async reloadExtra() {
      await this.getExtraList();
    },
    /** プライベートサイトタブ一覧更新 */
    async reloadPrivate() {
      await this.getPrivateList();
    },
    /** インターネット一覧のデータ取得を行う*/
    async getInternetList() {
      this.internetSiteZone =
        await this.$api.type1SiteZone.getType1InternetList();
      this.globalZone = this.internetSiteZone.zone;
      // 取得した配列のソート
      this.addressList.items = this.internetSiteZone.addressList.sort(
        (a, b) => {
          return this.$service.sortCompare(a, b, [
            ["ipAddressName", "asc", { nullLast: true }],
            ["ipAddress", "asc"],
          ]);
        }
      );
      // 取得した配列のソート
      this.fqdnList.items = this.internetSiteZone.fqdnList.sort((a, b) => {
        return this.$service.sortCompare(a, b, [
          ["fqdnName", "asc", { nullLast: true }],
          ["fqdn", "asc"],
        ]);
      });
    },
    /** エクストラ一覧のデータ取得を行う*/
    async getExtraList() {
      // リストをクリアする処理
      this.extra.items = [];
      this.extraSiteZone = await this.$api.type1SiteZone.getType1ExtraList();
      this.extraZone = this.extraSiteZone.zone;
      /**
       * 取得IPアドレス配列のソート
       */
      this.extraSiteZone.siteList.forEach((e) => {
        e.addressList.sort((a, b) => {
          return this.$service.sortCompare(a, b, [
            ["ipAddressName", "asc", { nullLast: true }],
            ["ipAddress", "asc"],
          ]);
        });
      });
      /**
       * 一覧表示用に変換
       * ※当画面のみの特殊対応です。
       * Aサイト
       *  L Aアドレス
       *  L Bアドレス
       * 上記の形式のオブジェクトを以下のように表示する必要があるため、
       * コード通りの詰め替え処理をしています。
       * Aサイト - Aアドレス
       * Aサイト - Bアドレス
       */
      this.extraSiteZone.siteList.map((e) => {
        e.addressList.map((i) => {
          this.extra.items.push({
            siteName: e.siteName,
            ipAddressId: i.ipAddressId,
            ipAddressName: i.ipAddressName,
            ipAddress: i.ipAddress,
            zoneId: i.zoneId,
          });
        });
        /**
         * 0件の場合サイト名以外”-”を表示
         * ipAddressIdをキーにしたいので、
         * siteIdを入れます。
         */
        if (e.addressList.length < 1) {
          this.extra.items.push({
            siteName: e.siteName,
            ipAddressId: e.siteId,
            ipAddressName: null,
            ipAddress: null,
            zoneId: null,
          });
        }
      });
    },
    /** プライベート一覧のデータ取得を行う*/
    async getPrivateList() {
      this.private.items = [];
      this.privateSiteZone =
        await this.$api.type1SiteZone.getType1PrivateList();
      this.privateZoneList = this.privateSiteZone.zoneList.sortBy([
        (e) => e.zoneName,
        "asc",
      ]);

      // VNコネクトを利用しているか判定するためデータ取得
      const vnInformationList = (
        await this.$api.vNConnect.getVNConnects()
      ).vnConnectList.map(({ enumber, vlanId }) => ({ enumber, vlanId }));

      /**
       * 一覧表示用に変換
       * ※当画面のみの特殊対応です。
       * A回線
       *  L Aサイト
       *  L Bサイト
       * 上記の形式のオブジェクトを以下のように表示する必要があるため、
       * コード通りの詰め替え処理をしています。
       * A回線 - Aサイト
       *          L Aアドレス
       *          L Bアドレス
       * A回線 - Bサイト
       *          L Aアドレス
       *          L Bアドレス
       */
      this.privateSiteZone.lineList.forEach((e) => {
        e.siteList
          .filter((i) => {
            switch (e.accessType) {
              case "ETHERNET_L3":
              case "BROADBAND_ACCESS2_L3":
              case "WIRELESS_ACCESS_L3":
              case "PF_ACCESS_GW":
                return i.vlanIdType !== "SPECIFIED";
              default:
                return true;
            }
          })
          .forEach((i) => {
            // 取得IPアドレス配列のソート
            i.addressList.sort((a, b) => {
              return this.$service.sortCompare(a, b, [
                ["ipAddressName", "asc", { nullLast: true }],
                ["ipAddress", "asc"],
              ]);
            });
            // 詰め替え処理
            const addressName = i.addressList.map((x) => {
              return x.ipAddressName;
            });

            const address = i.addressList.map((x) => {
              return x.ipAddress;
            });
            const zoneName = i.addressList.map((x) => {
              const era: Type1Zone | undefined = this.privateZoneList.find(
                (item) => item.zoneId === x.zoneId
              );
              return era ? era.zoneName : "未配置";
            });

            let listDisplay = true;
            // 物理回線で利用不可の回線は表示しない処理
            switch (e.accessType) {
              case "ETHERNET2":
              case "PLATFORM_GATEWAY":
              case "ETHERNET2_PF":
              case "BROADBAND_ACCESS3":
              case "ETHERNET_LIGHT":
              case "WIRELESS_ACCESS2":
              case "PF_ACCESS_GW2":
                listDisplay = false;
                break;
              case "ETHERNET_L2":
              case "BROADBAND_ACCESS2_L2":
              case "WIRELESS_ACCESS_L2":
                // イーサネット方式L2・ブロードバンドアクセス方式L2・ワイヤレスアクセス方式L2で回線種別がVLAN指定かつVNコネクト利用ありの場合表示しない
                if (
                  vnInformationList.find(
                    (element) =>
                      i.vlanIdType === "SPECIFIED" &&
                      e.enumber === element.enumber &&
                      i.vlanId === element.vlanId
                  )
                ) {
                  listDisplay = false;
                  break;
                }
                // イーサネット方式L2・ブロードバンドアクセス方式L2・ワイヤレスアクセス方式L2で回線種別がUntagかつVNコネクト利用ありの場合表示しない
                else if (
                  vnInformationList.find(
                    (element) =>
                      i.vlanIdType === "UNTAG" && e.enumber === element.enumber
                  )
                ) {
                  listDisplay = false;
                  break;
                } else {
                  break;
                }
              case "REMOTE_ACCESS_GW_FRE":
              case "REMOTE_ACCESS_GW_CPA":
              case "REMOTE_ACCESS_GW_CRG":
              case "EXTEND_ETHERNET":
                // リモートアクセスGW・エクステンドイーサネット方式で回線種別がVLAN指定の場合表示しない
                if (i.vlanIdType === "SPECIFIED") {
                  listDisplay = false;
                  break;
                }
                // リモートアクセスGW・エクステンドイーサネット方式で回線種別がUntagかつVNコネクト利用ありの場合表示しない
                else if (
                  vnInformationList.find(
                    (element) =>
                      i.vlanIdType === "UNTAG" && e.enumber === element.enumber
                  )
                ) {
                  listDisplay = false;
                  break;
                } else {
                  break;
                }
              case "REMOTE_ACCESS_GW2_FRE":
              case "REMOTE_ACCESS_GW2_CPA":
              case "REMOTE_ACCESS_GW2_CRG":
              case "IPSEC":
                // リモートアクセスGW2・IPSec方式で回線種別がUntagかつVNコネクト利用ありの場合表示しない
                if (
                  vnInformationList.find(
                    (element) =>
                      i.vlanIdType === "UNTAG" && e.enumber === element.enumber
                  )
                ) {
                  listDisplay = false;
                  break;
                } else {
                  break;
                }
            }
            // OTHERの場合表示しない処理。
            if (i.vlanIdType !== "OTHER" && listDisplay === true) {
              // アドレス０件の場合ハイフンを表示。
              if (i.addressList.length < 1) {
                this.private.items.push({
                  siteId: i.siteId,
                  enumber: e.enumber,
                  siteName: i.siteName,
                  vlan: i.vlanIdType === "UNTAG" ? "Untag" : i.vlanId,
                  ipAddressName: null,
                  ipAddress: null,
                  zoneId: null,
                });
              } else {
                this.private.items.push({
                  siteId: i.siteId,
                  enumber: e.enumber,
                  siteName: i.siteName,
                  vlan: i.vlanIdType === "UNTAG" ? "Untag" : i.vlanId,
                  ipAddressName: addressName,
                  ipAddress: address,
                  zoneId: zoneName,
                });
              }
            }
          });
      });
    },
    /** 行選択イベント*/
    // プライベートサイト
    async privateSelected(item: Type1PrivateList) {
      // 選択されたのサイトを洗い出す
      const data: Type1PrivateLine | undefined =
        this.privateSiteZone.lineList.find((e) => e.enumber === item.enumber);
      const siteList = data?.siteList.find((e) => e.siteId === item.siteId);

      await this.$modal.show(PrivateSiteReference, {
        enumber: item.enumber,
        siteList: siteList,
        zoneList: this.privateZoneList,
        type1SiteZoneKeyId: this.privateSiteZone.type1SiteZoneKeyId,
      });
      this.isLoaded = false;
      await this.load();
      this.tabMode = 3;
      this.isLoaded = true;
    },

    /** 編集機能 */
    // グローバルゾーン編集
    async showGlobalZoneEdit() {
      await this.$modal.show(ZoneEdit, {
        zoneMode: "global",
        zoneDate: this.internetSiteZone.zone,
        type1SiteZoneKeyId: this.internetSiteZone.type1SiteZoneKeyId,
      });
      this.isLoaded = false;
      await this.load();
      this.tabMode = 1;
      this.isLoaded = true;
    },
    // インターネットサイト編集
    async showInternetSiteEdit() {
      await this.$modal.show(InternetSiteReference, {
        internetSiteZone: this.internetSiteZone,
      });
      this.isLoaded = false;
      await this.load();
      this.tabMode = 1;
      this.isLoaded = true;
    },
    // エクストラゾーン編集
    async showExtraZoneEdit() {
      await this.$modal.show(ZoneEdit, {
        zoneMode: "extra",
        zoneDate: this.extraSiteZone.zone,
        type1SiteZoneKeyId: this.extraSiteZone.type1SiteZoneKeyId,
      });
      this.isLoaded = false;
      await this.load();
      this.tabMode = 2;
      this.isLoaded = true;
    },
    // エクストラサイト編集
    async showExtraSiteEdit() {
      await this.$modal.show(ExtraSiteList, {
        extraSiteZone: this.extraSiteZone,
      });
      this.isLoaded = false;
      await this.load();
      this.tabMode = 2;
      this.isLoaded = true;
    },
    // プライベートゾーン編集
    async showPrivateZoneEdit() {
      await this.$modal.show(PrivateZoneList, {
        privateZone: this.privateZoneList,
        type1SiteZoneKeyId: this.privateSiteZone.type1SiteZoneKeyId,
      });
      this.isLoaded = false;
      await this.load();
      this.tabMode = 3;
      this.isLoaded = true;
    },
  },
});

/** テーブルfields*/
const FIELDS = {
  address: [
    {
      key: "ipAddressName",
      label: "アドレス名",
      sortable: true,
    },
    {
      key: "ipAddress",
      label: "アドレス",
      sortable: true,
    },
    {
      key: "zoneId",
      label: "ゾーン配置",
      sortable: true,
    },
  ],
  fqdn: [
    {
      key: "fqdnName",
      label: "FQDN名",
      sortable: true,
    },
    {
      key: "fqdn",
      label: "FQDN",
      sortable: true,
    },
    {
      key: "zoneId",
      label: "ゾーン配置",
      sortable: true,
    },
  ],
  extra: [
    {
      key: "siteName",
      label: "サイト名",
      sortable: true,
    },
    {
      key: "ipAddressName",
      label: "アドレス名",
      sortable: true,
    },
    {
      key: "ipAddress",
      label: "アドレス",
      sortable: true,
    },
    {
      key: "zoneId",
      label: "ゾーン配置",
      sortable: true,
      sortByFormatted: true,
      filterByFormatted: false,
      formatter: (value: null, key: null, item: Type1ExtraList) => {
        if (item.ipAddress === null && item.zoneId === null) {
          return null;
        } else if (item.zoneId === null) {
          return "未配置";
        } else {
          return "配置済";
        }
      },
    },
  ],
  private: [
    {
      key: "enumber",
      label: "回線番号",
      sortable: true,
    },
    {
      key: "siteName",
      label: "サイト名",
      sortable: true,
    },
    {
      key: "vlan",
      label: "VLAN",
      sortable: true,
      tdClass: "text-right",
    },
    {
      key: "ipAddressName",
      label: "アドレス名",
      sortable: true,
    },
    {
      key: "ipAddress",
      label: "アドレス",
      sortable: true,
    },
    {
      key: "zoneId",
      label: "ゾーン配置",
      sortable: true,
    },
  ],
};

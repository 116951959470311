import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 半角英数で入力されること
 *  alpha_numは日本語を許容してしまうため、validatorファイルを追加
 */

export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }
    return /^[a-zA-Z0-9]*$/.test(value);
  },
} as ValidationRuleSchema;



















































































import Vue from "vue";
import { NotificationEntity } from "@/apis/NotificationApi";
import { mapState } from "vuex";

export default Vue.extend({
  name: "notificationContactReference",
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
  },
  data() {
    return {
      /** 初期通信完了フラグ */
      isLoaded: false,
      notificationContactInfo: { receivingType: {} } as NotificationEntity,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    async load() {
      this.notificationContactInfo =
        await this.$api.notification.getNotificationInfo();

      // 【24.04対応】メンテナンス情報と障害情報のメール送信を行わないため、falseを代入
      this.notificationContactInfo.receivingType.receivesMaintenance = false;
      this.notificationContactInfo.receivingType.receivesFailure = false;
    },
    /** 編集画面に画面遷移 */
    // サブメニューのアクティブ判定の都合上、pathが「通知連絡先情報」と「通知連絡先登録」で同じとしているため、
    // path指定ではなくname指定とする。
    async editNotificationContact() {
      await this.$router.push({
        name: "notification-contact-edit",
        params: {
          notificationContactInfo: JSON.stringify(this.notificationContactInfo),
        },
      });
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-title',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"flex-fill"},[_vm._v("設定上限値")]),_c('manual-button',{attrs:{"path":"/nwinfo-limit/"}})],1)]),(_vm.isLoaded)?_c('b-card-body',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('b-form-group',{attrs:{"label":"上限値項目"}},[_c('app-multiselect',{attrs:{"options":_vm.displayUnits,"multiple":false,"custom-label":_vm.labelUnit},scopedSlots:_vm._u([{key:"beforeList",fn:function(){return [_c('div',{staticClass:"multiselect-thead"},[_c('p',[_vm._v("管理単位")]),_c('p',[_vm._v("回線番号")]),_c('p',[_vm._v("詳細項目")])])]},proxy:true},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"multiselect-tbody"},[_c('p',[_vm._v(_vm._s(option.l1))]),_c('p',[_vm._v(_vm._s(_vm._f("emptyTo")(option.l2,"-")))]),_c('p',[_vm._v(_vm._s(_vm._f("emptyTo")(option.l3,"-")))])])]}}],null,true),model:{value:(_vm.selectedUnit),callback:function ($$v) {_vm.selectedUnit=$$v},expression:"selectedUnit"}})],1),(_vm.selectedUnit)?[(
                _vm.selectedUnit.unit === 'CONTRACT_NUMBER' && _vm.upperLimit1.items
              )?[_c('app-table',{attrs:{"primary-key":"itemName","head-variant":"light","thead-class":"text-center","bordered":true,"fields":_vm.upperLimit1.fields,"items":_vm.upperLimit1.items,"pagination":false,"empty-text":_vm._f("msg")('no_data'),"show-empty":true},scopedSlots:_vm._u([{key:"cell(current)",fn:function(ref){
              var item = ref.item;
return [(item.current === null)?[_vm._v("-")]:[_vm._v(_vm._s(item.current)+" "+_vm._s(item.unit))]]}},{key:"cell(upperLimit)",fn:function(ref){
              var item = ref.item;
return [(
                      item.upperLimitItemName ===
                        'AWS(ホスト型接続)合計帯域' &&
                      item.upperLimit >= 1000 &&
                      item.unit === 'Mbps'
                    )?[_vm._v(" "+_vm._s(item.upperLimit / 1000)+" Gbps ")]:[_vm._v(" "+_vm._s(item.upperLimit)+" "+_vm._s(item.unit)+" ")]]}}],null,true)})]:_vm._e(),(_vm.upperLimitSearch)?[_c('validation-provider',{attrs:{"name":"回線番号","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{attrs:{"label":"回線番号"}},[_c('app-multiselect',{attrs:{"options":_vm.lineNumberOptions[0].values.length === 0
                        ? []
                        : _vm.lineNumberOptions,"multiple":true,"group-label":"labels","group-values":"values","custom-label":_vm.labelLineNumber,"track-by":"index","max":5,"state":_vm._f("validState")(validContext)},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
                        var option = ref.option;
                        var remove = ref.remove;
return [_c('multi-select-tag',{staticClass:"d-block",attrs:{"option":option,"remove":remove}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"col p-0"},[_vm._v(_vm._s(option.label1))]),(option.label2)?_c('span',{staticClass:"col p-0"},[_vm._v(_vm._s(option.label2))]):_vm._e(),(option.label3)?_c('span',{staticClass:"col p-0"},[_vm._v(_vm._s(option.label3))]):_vm._e()])])]}},{key:"option",fn:function(ref){
                        var option = ref.option;
return [(option.$groupLabel)?_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"col p-0 m-0"},[_vm._v(" "+_vm._s(option.$groupLabel.item1)+" ")]),(option.$groupLabel.item2)?_c('p',{staticClass:"col p-0 m-0"},[_vm._v(" "+_vm._s(option.$groupLabel.item2)+" ")]):_vm._e(),(option.$groupLabel.item3)?_c('p',{staticClass:"col p-0 m-0"},[_vm._v(" "+_vm._s(option.$groupLabel.item3)+" ")]):_vm._e()]):_c('div',{staticClass:"multiselect-tbody"},[_c('p',[_vm._v(_vm._s(option.label1))]),(option.label2)?_c('p',[_vm._v(_vm._s(option.label2))]):_vm._e(),(option.label3)?_c('p',[_vm._v(_vm._s(option.label3))]):_vm._e()])]}}],null,true),model:{value:(_vm.selectedLineNumbers),callback:function ($$v) {_vm.selectedLineNumbers=$$v},expression:"selectedLineNumbers"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('div',{staticClass:"btn-container justify-content-end mr-2"},[_c('b-button',{attrs:{"variant":"outline-primary mr-3"},on:{"click":_vm.reset}},[_vm._v("リセット")]),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("上限値表示")])],1)]:_vm._e(),(_vm.upperLimit2.items)?_c('b-table-simple',{staticClass:"mt-3",attrs:{"bordered":""}},[_c('b-thead',{staticClass:"text-center",attrs:{"head-variant":"light"}},[_c('b-tr',[_c('b-th',{staticClass:"medium-list-item"},[_vm._v("回線番号")]),_c('b-th',{staticClass:"setting-list-item"},[_vm._v("設定項目")]),_c('b-th',{staticClass:"small-list-item"},[_vm._v("現在設定数")]),_c('b-th',{staticClass:"small-list-item"},[_vm._v("上限値")])],1)],1),_c('b-tbody',[_vm._l((_vm.upperLimit2.items),function(e,i1){return [(
                      _vm.selectedUnit.unit === 'ACCESS_NUMBER' ||
                      _vm.selectedUnit.unit === 'ACCESS_NUMBER_VLAN'
                    )?[(e.accessLine.upperLimitList.length === 0)?[_c('b-tr',{key:(i1 + "_0"),staticClass:"border-bottom-double"},[_c('b-td',[_vm._v(" "+_vm._s(e.accessLine.enumber)),_c('br'),(e.accessLine.vlanIdType === 'SPECIFIED')?[_vm._v(" VLAN: "+_vm._s(e.accessLine.vlanId)+" ")]:[_vm._v(" VLAN: "+_vm._s(_vm._f("enumTo")(e.accessLine.vlanIdType,"vlanIdType"))+" ")]],2),_c('b-td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm._f("msg")("no_data")))])],1)]:_vm._l((e.accessLine.upperLimitList),function(limit,i2){return [_c('b-tr',{key:(i1 + "_" + i2),class:{
                          'border-bottom-double':
                            i2 + 1 === e.accessLine.upperLimitList.length,
                        }},[(i2 === 0)?_c('b-td',{attrs:{"rowspan":e.accessLine.upperLimitList.length}},[_vm._v(" "+_vm._s(e.accessLine.enumber)),_c('br'),(e.accessLine.vlanIdType === 'SPECIFIED')?[_vm._v(" VLAN: "+_vm._s(e.accessLine.vlanId)+" ")]:[_vm._v(" VLAN: "+_vm._s(_vm._f("enumTo")(e.accessLine.vlanIdType,"vlanIdType"))+" ")]],2):_vm._e(),_c('b-td',[_vm._v(_vm._s(limit.upperLimitItemName))]),_c('b-td',[(limit.current === null)?[_vm._v("-")]:[_vm._v(_vm._s(limit.current)+" "+_vm._s(limit.unit))]],2),_c('b-td',[_vm._v(_vm._s(limit.upperLimit)+" "+_vm._s(limit.unit))])],1)]})]:_vm._e(),(
                      _vm.selectedUnit.unit === 'APPLIANCE_PF' ||
                      _vm.selectedUnit.unit === 'APPLIANCE_INTRANET' ||
                      _vm.selectedUnit.unit === 'APPLIANCE_NUMBER_INTERNET' ||
                      _vm.selectedUnit.unit === 'APPLIANCE_NUMBER_UTM' ||
                      _vm.selectedUnit.unit === 'APPLIANCE_NUMBER_NAT' ||
                      _vm.selectedUnit.unit === 'INTERNET4_NUMBER'
                    )?[(e.internetLine.upperLimitList.length === 0)?[_c('b-tr',{key:(i1 + "_0"),staticClass:"border-bottom-double"},[_c('b-td',[(e.internetLine.type1)?[_vm._v(" "+_vm._s(e.internetLine.type1.enumberMainAct)),_c('br'),_vm._v(" "+_vm._s(e.internetLine.type1.enumberMainSby)+" ")]:(e.internetLine.type4)?[_vm._v(" "+_vm._s(e.internetLine.type4.wnumberMainAct)),_c('br'),_vm._v(" "+_vm._s(e.internetLine.type4.wnumberMainSby)+" ")]:_vm._e(),(_vm.selectedUnit.unit !== 'INTERNET4_NUMBER')?[_c('p',{staticClass:"p-0 m-0"},[_vm._v(" "+_vm._s(_vm._f("enumTo")(e.internetLine.applianceType,"upperLimit_applianceType"))+" ")])]:_vm._e()],2),_c('b-td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm._f("msg")("no_data")))])],1)]:_vm._l((e.internetLine.upperLimitList),function(limit,i2){return [_c('b-tr',{key:(i1 + "_" + i2),class:{
                          'border-bottom-double':
                            i2 + 1 === e.internetLine.upperLimitList.length,
                        }},[(i2 === 0)?_c('b-td',{attrs:{"rowspan":e.internetLine.upperLimitList.length}},[(e.internetLine.type1)?[_vm._v(" "+_vm._s(e.internetLine.type1.enumberMainAct)),_c('br'),_vm._v(" "+_vm._s(e.internetLine.type1.enumberMainSby)+" ")]:(e.internetLine.type4)?[_vm._v(" "+_vm._s(e.internetLine.type4.wnumberMainAct)),_c('br'),_vm._v(" "+_vm._s(e.internetLine.type4.wnumberMainSby)+" ")]:_vm._e(),(_vm.selectedUnit.unit !== 'INTERNET4_NUMBER')?[_c('p',{staticClass:"p-0 m-0"},[_vm._v(" "+_vm._s(_vm._f("enumTo")(e.internetLine.applianceType,"upperLimit_applianceType"))+" ")])]:_vm._e()],2):_vm._e(),_c('b-td',[_vm._v(_vm._s(limit.upperLimitItemName))]),_c('b-td',[(limit.current === null)?[_vm._v("-")]:[_vm._v(_vm._s(limit.current)+" "+_vm._s(limit.unit))]],2),_c('b-td',[_vm._v(_vm._s(limit.upperLimit)+" "+_vm._s(limit.unit))])],1)]})]:_vm._e(),(_vm.selectedUnit.unit === 'PFGW_NUMBER')?[(e.pfgwLine.upperLimitList.length === 0)?[_c('b-tr',{key:(i1 + "_0"),staticClass:"border-bottom-double"},[_c('b-td',[_vm._v(" "+_vm._s(e.pfgwLine.enumberAct)),_c('br'),_vm._v(" "+_vm._s(e.pfgwLine.enumberSby)),_c('br')]),_c('b-td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm._f("msg")("no_data")))])],1)]:_vm._l((e.pfgwLine.upperLimitList),function(limit,i2){return [_c('b-tr',{key:(i1 + "_" + i2),class:{
                          'border-bottom-double':
                            i2 + 1 === e.pfgwLine.upperLimitList.length,
                        }},[(i2 === 0)?_c('b-td',{attrs:{"rowspan":e.pfgwLine.upperLimitList.length}},[_vm._v(" "+_vm._s(e.pfgwLine.enumberAct)),_c('br'),_vm._v(" "+_vm._s(e.pfgwLine.enumberSby)),_c('br')]):_vm._e(),_c('b-td',[_vm._v(_vm._s(limit.upperLimitItemName))]),_c('b-td',[(limit.current === null)?[_vm._v("-")]:[_vm._v(_vm._s(limit.current)+" "+_vm._s(limit.unit))]],2),_c('b-td',[_vm._v(_vm._s(limit.upperLimit)+" "+_vm._s(limit.unit))])],1)]})]:_vm._e(),(_vm.selectedUnit.unit === 'VN_NUMBER')?[(e.vnConnect.upperLimitList.length === 0)?[_c('b-tr',{key:(i1 + "_0"),staticClass:"border-bottom-double"},[_c('b-td',[_vm._v(" "+_vm._s(e.vnConnect.wnumber)+" ")]),_c('b-td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm._f("msg")("no_data")))])],1)]:_vm._l((e.vnConnect.upperLimitList),function(limit,i2){return [_c('b-tr',{key:(i1 + "_" + i2),class:{
                          'border-bottom-double':
                            i2 + 1 === e.vnConnect.upperLimitList.length,
                        }},[(i2 === 0)?_c('b-td',{attrs:{"rowspan":e.vnConnect.upperLimitList.length}},[_vm._v(" "+_vm._s(e.vnConnect.wnumber)+" ")]):_vm._e(),_c('b-td',[_vm._v(_vm._s(limit.upperLimitItemName))]),_c('b-td',[(limit.current === null)?[_vm._v("-")]:[_vm._v(_vm._s(limit.current)+" "+_vm._s(limit.unit))]],2),_c('b-td',[_vm._v(_vm._s(limit.upperLimit)+" "+_vm._s(limit.unit))])],1)]})]:_vm._e(),(_vm.selectedUnit.unit === 'VNL2L3_NUMBER')?[(e.vnl2l3Line.upperLimitList.length === 0)?[_c('b-tr',{key:(i1 + "_0"),staticClass:"border-bottom-double"},[_c('b-td',[_vm._v(" "+_vm._s(e.vnl2l3Line.enumber)+" ")]),_c('b-td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm._f("msg")("no_data")))])],1)]:_vm._l((e.vnl2l3Line.upperLimitList),function(limit,i2){return [_c('b-tr',{key:(i1 + "_" + i2),class:{
                          'border-bottom-double':
                            i2 + 1 === e.vnl2l3Line.upperLimitList.length,
                        }},[(i2 === 0)?_c('b-td',{attrs:{"rowspan":e.vnl2l3Line.upperLimitList.length}},[_vm._v(" "+_vm._s(e.vnl2l3Line.enumber)+" ")]):_vm._e(),_c('b-td',[_vm._v(_vm._s(limit.upperLimitItemName))]),_c('b-td',[(limit.current === null)?[_vm._v("-")]:[_vm._v(_vm._s(limit.current)+" "+_vm._s(limit.unit))]],2),_c('b-td',[_vm._v(_vm._s(limit.upperLimit)+" "+_vm._s(limit.unit))])],1)]})]:_vm._e(),(
                      _vm.selectedUnit.unit === 'MULTICLOUD_CLOUD' ||
                      _vm.selectedUnit.unit === 'MULTICLOUD_CLOUD_NUMBER' ||
                      _vm.selectedUnit.unit === 'MULTICLOUD_CLOUD_ENUMBER'
                    )?[(e.cloudLine.upperLimitList.length === 0)?[_c('b-tr',{key:(i1 + "_0"),staticClass:"border-bottom-double"},[_c('b-td',[(_vm.selectedUnit.unit === 'MULTICLOUD_CLOUD')?[_vm._v(" "+_vm._s(e.cloudLine.enumber)),_c('br'),_vm._v(" "+_vm._s(e.cloudLine.cloudServiceMenu === "SFDC" ? "Salesforce" : e.cloudLine.cloudServiceMenu)+" ")]:(
                              _vm.selectedUnit.unit === 'MULTICLOUD_CLOUD_NUMBER'
                            )?[_vm._v(" "+_vm._s(e.cloudLine.wnumberAct)),_c('br'),_vm._v(" "+_vm._s(e.cloudLine.wnumberSby)+" ")]:[_vm._v(" "+_vm._s(e.cloudLine.enumber)+" ")]],2),_c('b-td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm._f("msg")("no_data")))])],1)]:_vm._l((e.cloudLine.upperLimitList),function(limit,i2){return [_c('b-tr',{key:(i1 + "_" + i2),class:{
                          'border-bottom-double':
                            i2 + 1 === e.cloudLine.upperLimitList.length,
                        }},[(i2 === 0)?_c('b-td',{attrs:{"rowspan":e.cloudLine.upperLimitList.length}},[(_vm.selectedUnit.unit === 'MULTICLOUD_CLOUD')?[_vm._v(" "+_vm._s(e.cloudLine.enumber)),_c('br'),_vm._v(" "+_vm._s(e.cloudLine.cloudServiceMenu === "SFDC" ? "Salesforce" : e.cloudLine.cloudServiceMenu)+" ")]:(
                              _vm.selectedUnit.unit === 'MULTICLOUD_CLOUD_NUMBER'
                            )?[_vm._v(" "+_vm._s(e.cloudLine.wnumberAct)),_c('br'),_vm._v(" "+_vm._s(e.cloudLine.wnumberSby)+" ")]:[_vm._v(" "+_vm._s(e.cloudLine.enumber)+" ")]],2):_vm._e(),_c('b-td',[_vm._v(_vm._s(limit.upperLimitItemName))]),_c('b-td',[(limit.current === null)?[_vm._v("-")]:[_vm._v(_vm._s(limit.current)+" "+_vm._s(limit.unit))]],2),_c('b-td',[(
                              (limit.upperLimitItemName ===
                                'VNコネクト(ホスト型接続)' ||
                                limit.upperLimitItemName === '契約上限値') &&
                              limit.upperLimit >= 1000 &&
                              limit.unit === 'Mbps'
                            )?[_vm._v(" "+_vm._s(limit.upperLimit / 1000)+" Gbps ")]:[_vm._v(" "+_vm._s(limit.upperLimit)+" "+_vm._s(limit.unit)+" ")]],2)],1)]})]:_vm._e()]})],2)],1):_vm._e()]:_vm._e()],2)]}}],null,false,3016105633)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
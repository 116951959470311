import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * ネットワークアドレスではないことを確認
 * @param value チェック対象IPアドレス
 *              (例：
 *                   IPのみの場合：192.168.1.1 or
 *                   CIDRの場合：192.168.1.1/30
 *               )
 * チェック内容：
 *  valueはサブネット無しのIP(XXX.XXX.XXX.XXX) + アドレス範囲がrange(XXX.XXX.XXX.XXX/XX)で指定の場合 → ネットワークアドレスではないことを確認
 *  valueはサブネット付きのIP(XXX.XXX.XXX.XXX/XX)の場合 → ネットワークアドレスではないことを確認
 */
export default {
  params: ["range"],
  validate: function (
    value: string | undefined | null,
    { range }: { range: string | null }
  ): boolean {
    if (!value) return true;
    if (ipaddr.IPv4.isValidFourPartDecimal(value)) {
      // サブネット無しのIP
      const ip = ipaddr.IPv4.parse(value);
      if (!range) {
        // rangeがない場合、チェックできないので、true
        return true;
      }
      // Rangeありの場合
      let networkAddress;
      try {
        networkAddress = ipaddr.IPv4.networkAddressFromCIDR(range).toString();
      } catch (e) {
        return true;
      }
      // ネットワークアドレスではないことを確認
      return ip.toString() !== networkAddress;
    } else {
      // サブネット付きのIP
      let ip: [ipaddr.IPv4, number];
      try {
        ip = ipaddr.IPv4.parseCIDR(value);
      } catch (e) {
        return true;
      }

      const networkAddress =
        ipaddr.IPv4.networkAddressFromCIDR(value).toString();
      return ip[0].toString() !== networkAddress;
    }
  },
} as ValidationRuleSchema;
















































import Vue, { PropType } from "vue";
import { FetchOptions, RestAllEntity, RestApiOptions } from "@/apis/SampleApi";
import {
  BvTableCtxObject,
  BvTableFieldArray,
} from "bootstrap-vue/src/components/table";
import { debounce } from "throttle-debounce";
import { SearchOption } from "@/components/AppSearch.vue";
import { BvTableFormatterCallback } from "bootstrap-vue/esm";
// eslint-disable-next-line no-restricted-imports
import { get } from "lodash";

/** itemの型 */
type TableItem = Record<string, unknown>;

/**
 * フィルターリング処理時のオプション
 * ・like - 部分一致
 * ・equal - 完全一致
 * ・gt - 以上（日付の値にのみ使用）
 * ・lt - 以下（日付の値にのみ使用）
 * ・(item: T, value: unknown) => boolean - カスタムマッチング
 */
type FilterType<T = unknown> =
  | "like"
  | "equal"
  | "gt"
  | "lt"
  | ((item: T, value: unknown) => boolean);
export type FilterOption<T> = Record<keyof T | string, FilterType<T>>;

/** テーブルで使用するデータの詰め合わせインターフェース定義 */
export interface AppTableData<T> {
  fields: BvTableFieldArray;
  items: T[];
  selected: T[];
  search: SearchOption<T>;
  filter: Record<string, unknown>;
  filter_option: FilterOption<T>;
}

/**
 * App共通のテーブルコンポーネント
 * テーブル、ページング周りの機能を提供してくれる
 */
export default Vue.extend({
  name: "AppTable",
  props: {
    /** @see https://bootstrap-vue.org/docs/components/table */
    fields: {
      type: Array as PropType<BvTableFieldArray>,
      required: true,
    },
    /**
     * @see https://bootstrap-vue.org/docs/components/table
     * サーバーモードの場合はページング情報まで入ったモデルを使用すること
     */
    items: {
      type: [Array, Object] as PropType<
        TableItem[] | RestAllEntity<TableItem> | undefined
      >,
      default: undefined,
      required: false,
      validator: () => true,
    },
    /** items取得処理（サーバーモードの場合のみ） */
    fetchItems: {
      type: Function as PropType<
        | undefined
        | ((
            options: FetchOptions<unknown>
          ) => Promise<RestAllEntity<TableItem>>)
      >,
      default: undefined,
      required: false,
    },
    /**
     * 主キー相当のプロパティ名.
     * 選択チェックボックスの管理などで使用されているので必須
     * プロパティ名が正しくない場合はwarningが出力される
     * 最悪index相当でも良いがその場合は上記の選択チェックボックスの管理は正しく動かないので注意
     * @see https://bootstrap-vue.org/docs/components/table
     */
    primaryKey: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * true: サーバーモード（都度取得）, false: 初回全件取得モード
     * サーバーモードでページ移動時に選択状態はクリアされるのは仕様
     */
    serveMode: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    /** true: ページングON, false: ページングOFF */
    pagination: {
      type: Boolean as PropType<boolean>,
      default: true,
      required: false,
    },
    /** 1ページあたりの表示件数 */
    perPage: {
      type: Number as PropType<number>,
      default: 50,
      required: false,
    },
    /** true: 選択可能, false: 選択不可 */
    selectable: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    /** 選択済みItem一覧 */
    value: {
      type: Array as PropType<TableItem[]>,
      default: () => [],
      required: false,
    },
    /** フィルター条件 */
    filter: {
      type: Object as PropType<RestApiOptions<unknown>["filters"] | undefined>,
      default: undefined,
      required: false,
    },
    /** フィルター処理時の挙動オプション */
    filterOption: {
      type: Object as PropType<FilterOption<unknown> | undefined>,
      default: undefined,
    },
    /** ソート対象 */
    sortBy: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    /** 並び順. true: DESC, false: ASC（デフォルト） */
    sortDesc: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** テーブル高さ */
    stickyHeader: {
      type: String as PropType<string>,
      default: "300px",
    },
  },
  data() {
    return {
      /** items */
      localItems: this.items as
        | TableItem[]
        | RestAllEntity<TableItem>
        | undefined,
      /** 現在のページ番号 */
      currentPage: 1,
      /** ソート対象 */
      localSortBy: this.sortBy,
      /** true: DESC, false: ASC */
      localSortDesc: this.sortDesc,
      /** 選択済みitems */
      tableSelected: this.value,
      /** フィルターリング後の件数 */
      filteredRows: null as null | number,
      /** items取得処理（サーバーモードのみ） */
      emitFetchItems: {} as debounce<() => void>,
    };
  },
  created() {
    // 同時に複数回イベントが発生する場合があるため取得処理をdebounceする
    this.emitFetchItems = debounce(100, async () => {
      if (this.fetchItems == null) {
        throw new Error("cannot convert");
      }
      this.localItems = await this.fetchItems({
        current_page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.localSortBy,
        sort_desc: this.localSortDesc,
        filters: this.filter,
      });
    });
  },
  computed: {
    /** b-tableで使えるlisteners */
    // eslint-disable-next-line @typescript-eslint/ban-types
    bTableListeners(): Record<string, Function | Function[]> {
      return Object.entries(this.$listeners)
        .filter(([key]) => key !== "input")
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    },

    /** table表示定義 */
    tableFields(): BvTableFieldArray {
      // classなど追加設定
      const fields: BvTableFieldArray = this.fields.map((e) => {
        if (typeof e === "string") {
          return { thClass: `th_${e}`, key: e };
        } else {
          return { thClass: `th_${e.key}`, ...e };
        }
      });

      if (this.selectable) {
        return [
          { key: "_selected", label: "", thClass: "th__selected" },
          ...fields,
        ];
      } else {
        return fields;
      }
    },

    /** table表示のitems */
    tableItems(): TableItem[] {
      if (this.localItems) {
        return this.serveMode
          ? (this.localItems as RestAllEntity<TableItem>).body
          : (this.localItems as TableItem[]);
      } else {
        return [];
      }
    },

    /** 主キー一覧 */
    primaryKeySet(): Set<unknown> {
      return new Set(this.tableItems.map((e) => get(e, this.primaryKey)));
    },

    /** itemsの全体件数 */
    totalRows(): number {
      if (this.localItems && Object.keys(this.localItems).length > 0) {
        const rows = this.serveMode
          ? (this.localItems as RestAllEntity<unknown>).total
          : (this.localItems as unknown[]).length;
        // フィルタリング中の場合はフィルタリングの件数を返す。
        // フィルタリングしていない場合はそのまま件数
        if (Object.keys(this.filter ?? {}).length === 0) {
          return rows;
        } else {
          return this.filteredRows!;
        }
      } else {
        return 0;
      }
    },

    /**
     * tableコンポーネンに渡す1ページあたりの表示件数
     * サーバーモードの場合はページネーションを無効化するために0を返す
     */
    tablePerPage(): number {
      return this.serveMode || !this.pagination ? 0 : this.perPage;
    },

    /** 表示中の開始件数 */
    rowStart(): number {
      return this.totalRows === 0
        ? 0
        : 1 + this.perPage * (this.currentPage - 1);
    },

    /** 表示中の終了件数 */
    rowEnd(): number {
      return this.totalRows > this.perPage * this.currentPage
        ? this.perPage * this.currentPage
        : this.totalRows;
    },

    /**
     * 検索用のformatter（関数。文字列は対応してないよ）が定義されているfieldsをマップ化.
     * filterByFormattedがfunction または filterByFormattedがtrue かつ formatterがfunctionが抽出対象
     */
    filterFormatterMap(): Record<
      string,
      { key: string; formatter: BvTableFormatterCallback }
    > {
      return (
        this.fields.flatMap((e) => {
          if (typeof e === "object") {
            if (typeof e.filterByFormatted === "function") {
              return [{ ...e, formatter: e.filterByFormatted }];
            }
            if (
              e.filterByFormatted === true &&
              typeof e.formatter === "function"
            ) {
              return [e];
            }
            return [];
          }
        }) as { key: string; formatter: BvTableFormatterCallback }[]
      ).toMap((e) => e.key);
    },
  },
  watch: {
    /** ページの変更なのでサーバーモードの場合は再取得 */
    currentPage() {
      if (this.serveMode) {
        this.emitFetchItems();
      }
    },
    /** 親から選択済みitemの変更をcheckbox側に反映 */
    value() {
      this.tableSelected = this.value;
    },
    /** checkboxの変更を親の選択済みitemに通知 */
    tableSelected(newValue: unknown[]) {
      this.$emit("input", newValue);
    },
    /** itemsの変更時に存在しなくなったデータは選択済み一覧から削除 */
    items(newValue: TableItem[] | RestAllEntity<TableItem> | undefined) {
      this.localItems = newValue;

      // primary keyが正しくない可能性がある選択状態管理が正しく動かないので要修正
      if (this.tableItems.length !== this.primaryKeySet.size) {
        console.warn(
          `table length !== primary key size. primaryKey: ${this.primaryKey}, table length: ${this.tableItems.length}, ${this.primaryKeySet.size}`,
          this.tableItems
        );
      }

      if (!(newValue && this.selectable)) {
        // 空または選択モードじゃない場合は処理不要
        return;
      }
      // 選択済みitemsを最新の状態に更新
      this.tableSelected = this.tableItems.filter((item) =>
        this.tableSelected.some(
          (selected) =>
            get(item, this.primaryKey) === get(selected, this.primaryKey)
        )
      );
    },
    /**
     * フィルター条件の変更は1ページに戻す
     * サーバーモードの場合は更に再取得
     */
    filter: {
      handler() {
        this.currentPage = 1;
        if (this.serveMode) {
          this.emitFetchItems();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // primary keyが正しくない可能性がある選択状態管理が正しく動かないので要修正
    if (this.tableItems.length !== this.primaryKeySet.size) {
      console.warn(
        `table length !== primary key size. primaryKey: ${this.primaryKey}, table length: ${this.tableItems.length}, ${this.primaryKeySet.size}`,
        this.tableItems
      );
    }
  },
  methods: {
    /** データ取得イベントを発行 */
    async refresh(): Promise<void> {
      if (this.serveMode) {
        await this.emitFetchItems();
      }
    },

    /** ソート条件変更イベント */
    sortChanged(ctx: BvTableCtxObject) {
      this.localSortBy = ctx.localSortBy ?? undefined;
      this.localSortDesc = ctx.localSortDesc;
      this.currentPage = 1;
      if (this.serveMode) {
        this.emitFetchItems();
      }
      this.$emit("sortChanged", ctx);
    },

    /**
     * フィルタリング処理.
     * filterOptionの条件に従ってマッチ処理を行い条件に一致するitemの場合はtrue
     * サーバーモードの場合は呼び出されない
     */
    onFilter(item: TableItem): boolean {
      if (this.filter && this.filterOption) {
        return Object.entries(this.filterOption).every(([key, type]) => {
          const itemValue = item[key];
          if (this.filter === undefined) {
            throw new Error("cannot convert");
          }

          const filterValue = this.filter[key];
          if (filterValue === undefined) {
            return true;
          }

          const formattedValue = this.filterFormatterMap[key]
            ? this.filterFormatterMap[key].formatter(itemValue, key, item)
            : itemValue;
          return filter(type, formattedValue, filterValue, item);
        });
      } else {
        return true;
      }
    },

    /**
     * フィルター適用後イベント.
     * 件数はページネーションで必要なので保持しておく
     */
    onFiltered(items: TableItem[]) {
      this.filteredRows = items.length;
      this.$emit("filtered", items);
    },
  },
});

/**
 * true: 検索条件に一致, false: 不一致
 * @param type 検索条件
 * @param targetValue 検索対象値
 * @param filterValue 検索値
 * @param item 検索対象行
 */
function filter(
  type: FilterType,
  targetValue: unknown,
  filterValue: unknown,
  item: unknown
): boolean {
  // カスタム検索
  if (typeof type === "function") {
    return type(item, filterValue);
  }
  // 同じ項目に複数の値が指定された場合、"or"と扱う
  if (Array.isArray(filterValue)) {
    return filterValue.some((e) => filter(type, targetValue, e, item));
  }

  if (
    targetValue === undefined ||
    targetValue === null ||
    `${targetValue}` === ""
  ) {
    if (filterValue === "-") {
      return true;
    } else {
      return targetValue === filterValue;
    }
  } else if (typeof targetValue === "string") {
    switch (type) {
      case "equal":
        return `${targetValue}` === `${filterValue}`;
      case "like":
        return `${targetValue}`.includesIgnoreCase(`${filterValue}`);
      default:
        console.warn(
          "value is string type. filter supported equal/link/custom only",
          targetValue,
          type
        );
        return false;
    }
  } else if (typeof targetValue === "number") {
    switch (type) {
      case "equal":
        return `${targetValue}` === `${filterValue}`;
      case "like":
        return `${targetValue}`.includes(`${filterValue}`);
      default:
        console.warn(
          "value is number type. filter supported equal/link/custom only",
          targetValue,
          type
        );
        return false;
    }
  } else if (typeof targetValue === "boolean") {
    switch (type) {
      case "equal":
        return targetValue === filterValue;
      default:
        console.warn(
          "value is boolean type. filter supported equal/custom only",
          targetValue,
          type
        );
        return false;
    }
  } else if (Array.isArray(targetValue)) {
    return targetValue.some((e) => filter(type, e, filterValue, item));
  } else if (typeof targetValue === "object") {
    switch (type) {
      case "equal":
        return targetValue === filterValue;
      default:
        console.warn(
          "value is object type. filter supported equal/custom only",
          targetValue,
          type
        );
        return false;
    }
  } else {
    console.warn(" value is unknown type", targetValue);
    return targetValue === filterValue;
  }
}

import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { ApplianceInfo } from "@/apis/ContractApi";

/** プロファイル一覧情報 */
export interface InternetfwProfileList {
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  /** プロファイル設定情報（インターネットFW）のリスト 0件の場合は空配列 */
  profileList: {
    /** プロファイルID（インターネットFW） */
    profileInternetFwSeq: string;
    /** プロファイル名（インターネットFW） */
    profileInternetFwName: string | "any";
    /** ポリシー使用フラグ. ポリシー使用有「true」、ポリシー使用無「false」 */
    isPolicyInUse: boolean;
    /** 設定値の説明 */
    description: string | null;
  }[];
  /** インターネットFWのプロファイル設定排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  profileInternetFwKeyId: string;
}

/** プロファイル(インターネットFW) */
export type InternetFwProfile = InternetfwProfileList["profileList"][0];

/** URLプロファイル一覧情報 */
export interface UrlProfileList {
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  /** プロファイル設定情報（URLフィルタリング）のリスト 0件の場合は空配列 */
  profileList: {
    /** プロファイルID（URLフィルタリング） */
    profileUrlSeq: string;
    /** プロファイル名（URLフィルタリング） */
    profileUrlName: string;
    /** ポリシー使用フラグ. ポリシー使用有「true」、ポリシー使用無「false」 */
    isPolicyInUse: boolean;
    /** 設定値の説明 */
    description: string | null;
  }[];
  /** URLフィルタリングのプロファイル設定排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  profileUrlKeyId: string;
}

/** プロファイル(URLフィルタリング) */
export type UrlFilteringProfile = UrlProfileList["profileList"][0];

export default class ProfileApi extends AbstractBaseApi {
  /** Type1プロファイル(インターネットFW)の一覧情報を取得 */
  async getType1Profile(): Promise<InternetfwProfileList> {
    return (await this.api.get("/v1/network/policy/profiles/internetfw/type1"))
      .data;
  }

  /**
   * Type4プロファイル(インターネットFW)の一覧情報を取得
   * @param type4Id type4Id
   */
  async getType4Profile(
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<InternetfwProfileList> {
    return (
      await this.api.get(
        `/v1/network/policy/profiles/internetfw/type4/${type4Id}`
      )
    ).data;
  }

  /** Type1プロファイル(URLフィルタリング)の一覧情報を取得 */
  async getType1UrlProfile(): Promise<UrlProfileList> {
    return (
      await this.api.get("/v1/network/policy/profiles/urlfiltering/type1")
    ).data;
  }

  /**
   * Type4プロファイル(URLフィルタリング)の一覧情報を取得
   * @param type4Id type4Id
   */
  async getType4UrlProfile(
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<UrlProfileList> {
    return (
      await this.api.get(
        `/v1/network/policy/profiles/urlfiltering/type4/${type4Id}`
      )
    ).data;
  }
}

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** プラットフォームGWアドレス情報 */
export interface PfgwZone {
  /** 総件数.  WVS2ゾーンのアドレスの総件数 */
  wvs2AddressTotal: number;
  /** アドレスリスト(WVS2). 0件の場合は空配列を返却する。*/
  wvs2AddressList: {
    /** IPアドレスID. アドレスオブジェクトの主キー "obj"+"数値"の形式 */
    ipAddressId: string;
    /** アドレス. ネットワークアドレスを入力 */
    ipAddress: string;
  }[];
  /** 総件数. KCPSゾーンのアドレスの総件数 */
  kcpsAddressTotal: number;
  /** アドレスリスト(KCPS). 0件の場合は空配列を返却する。 */
  kcpsAddressList: {
    /** IPアドレスID. アドレスオブジェクトの主キー "obj"+"数値"の形式 */
    ipAddressId: string;
    /** アドレス. ネットワークアドレスを入力 */
    ipAddress: string;
  }[];
  /** 総件数. ゾーンIDリストの総件数 */
  zoneIdTotal: number;
  /** 接続元、接続先ゾーンIDのリスト. リストは最大2件まで。0件の場合は空配列を返却する。 */
  zoneIdList: {
    /** 接続元ゾーンID. WVS2ゾーンID、またはKCPSゾーンID */
    srcZoneId: string;
    /** 接続先ゾーンID. KCPSゾーンID、またはWVS2ゾーンID */
    dstZoneId: string;
  }[];
  /** WVS2ゾーンID. ゾーンオブジェクトの主キー "obj"+"数値"の形式 */
  wvs2ZoneId: string;
  /** KCPSゾーンID. ゾーンオブジェクトの主キー "obj"+"数値"の形式*/
  kcpsZoneId: string;
  /** WVS2-KCPS間ゾーン関連ID. WVS2ゾーンからKCPSゾーン方向への結線ID */
  wvs2ToKcpsZoneRelationId: string | null;
  /** KCPS-WVS2間ゾーン関連ID. KCPSゾーンからWVS2ゾーン方向への結線ID */
  kcpsToWvs2ZoneRelationId: string | null;
  /** プラットフォームゲートウェイアドレス設定排他情報. 排他確認用 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  pfgwAddressKeyId: string;
}

/** PfgwZone情報変更 */
export interface PfgwZonePut {
  /** アドレスリスト(WVS2). 0件の場合は空配列を設定する。 */
  wvs2AddressList: string[];
  /** アドレスリスト(KCPS). 0件の場合は空配列を設定する。 */
  kcpsAddressList: string[];
  /**
   * 接続元、接続先ゾーンIDのリスト
   * WVS2ゾーンID、またはKCPSゾーンIDを設定する。
   * リストは最大2件まで。0件の場合は空配列を設定する。
   */
  zoneIdList: {
    /** 接続元ゾーンID. WVS2ゾーンID、またはKCPSゾーンID */
    srcZoneId: string;
    /** 接続先ゾーンID. KCPSゾーンID、またはWVS2ゾーンID */
    dstZoneId: string;
  }[];
  /** プラットフォームゲートウェイアドレス設定排他情報. 排他確認用 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。*/
  pfgwAddressKeyId: string;
}

export default class PfgwZoneApi extends AbstractBaseApi {
  /**
   * プラットフォームゲートウェイアドレス情報を取得
   * @param eNumberAct Act系E番号
   */
  async getPfgwZone(eNumberAct: string): Promise<PfgwZone> {
    return (await this.api.get(`/v1/network/pfgw/zone/${eNumberAct}`)).data;
  }

  /**
   * プラットフォームゲートウェイ情報（アドレス、ゾーン関係ID）を更新（追加、変更、削除）
   * @param eNumberAct Act系E番号
   * @param data Pfgw設定情報
   */
  async putPfgwZone(eNumberAct: string, data: PfgwZonePut): Promise<void> {
    await this.api.post(`/v1/network/pfgw/zone/${eNumberAct}`, data);
  }
}









































import Vue, { PropType } from "vue";
import { LineCategory, PutIfStateRequest } from "@/apis/NwOperationToolApi";

export default Vue.extend({
  name: "IFModify",
  props: {
    /** 参照回線種別 */
    lineCategory: {
      type: String as PropType<LineCategory>,
      required: true,
    },
    /** W番号/X番号 */
    number: {
      type: String as PropType<string>,
      required: true,
    },
    /** IF状態 */
    ifStatus: {
      type: String as PropType<PutIfStateRequest["ifStatus"]>,
      required: true,
    },
  },
});

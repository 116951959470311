import { AxiosResponse } from "axios";
import AbstractBaseApi from "./AbstractBaseApi";

/** 契約単位の設定上限値取得API用インタフェース */
export interface UpperLimitGetEntity {
  /** V番号 */
  vnumber: string;
  /** 設定上限値一覧 */
  upperLimitList: UpperLimitEntity[];
}

/** 設定上限値情報 */
export interface UpperLimitEntity {
  /** 設定項目 */
  upperLimitItemName: string;
  /** 現在設定数 */
  current: number | null;
  /** 上限値 */
  upperLimit: number;
  /** 単位 */
  unit: string;
}

/** 回線単位の設定上限値取得API用インタフェース */
export interface UpperLimitLineGetEntity {
  /** アクセス回線情報 */
  accessLine: Pick<
    AccessLineEntity,
    "enumber" | "vlanIdType" | "vlanId" | "upperLimitList"
  > | null;
  /** PFGW(イントラ)回線情報 */
  pfgwLine: PfgwLineEntity | null;
  /** VN L2L3コネクト回線情報 */
  vnl2l3Line: Vnl2l3LineEntity | null;
  /** VNコネクト回線情報 */
  vnConnect: VnConnectEntity | null;
  /** インターネット回線情報 */
  internetLine: InternetLineEntity | null;
  /** クラウド回線情報 */
  cloudLine: Pick<
    CloudLineEntity,
    | "cloudLineSeq"
    | "enumber"
    | "cloudServiceMenu"
    | "wnumberAct"
    | "wnumberSby"
    | "upperLimitList"
  > | null;
}

export type UpperLimitSearchAccessLine = Pick<
  AccessLineEntity,
  "enumber" | "siteList"
>;
export type UpperLimitSearchInternet = Pick<
  InternetLineEntity,
  "type1" | "type4" | "applianceType"
>;
export type UpperLimitSearchPfgw = Pick<
  PfgwLineEntity,
  "enumberAct" | "enumberSby"
>;
export type UpperLimitSearchVnl2l3 = Pick<Vnl2l3LineEntity, "enumber">;
export type UpperLimitSearchVn = Pick<VnConnectEntity, "wnumber">;
export type UpperLimitSearchCloud = Pick<
  CloudLineEntity,
  "cloudLineSeq" | "enumber" | "cloudServiceMenu" | "vnConnectList"
>;

/** 上限値管理単位に紐づく回線情報取得API用インタフェース */
export interface UpperLimitLineSearchGetEntity {
  /** 回線情報の総件数 */
  total: number;
  /** アクセス回線情報 */
  accessLineList: UpperLimitSearchAccessLine[];
  /** PFGW(イントラ)回線情報 */
  pfgwLineList: UpperLimitSearchPfgw[];
  /** VN L2L3コネクト回線情報 */
  vnl2l3LineList: UpperLimitSearchVnl2l3[];
  /** VNコネクト回線情報 */
  vnLineList: UpperLimitSearchVn[];
  /** インターネット回線情報 */
  internetLineList: UpperLimitSearchInternet[];
  /** クラウド回線情報 */
  cloudLineList: UpperLimitSearchCloud[];
  /** インターネット(Type4)回線情報 */
  internetType4List: Pick<
    Type4Entity,
    | "type4Id"
    | "wnumberMainAct"
    | "wnumberMainSby"
    | "wnumberBcpAct"
    | "wnumberBcpSby"
  >[];
}

/** アクセス回線情報 */
export interface AccessLineEntity {
  /** E番号 */
  enumber: string;

  /** サイトリスト (上限値管理単位に紐づく回線情報を取得するAPI専用項目) */
  siteList: SiteListEntity[];

  /** 回線単位の設定上限値（最大値と現在値）を取得するAPI専用項目 */
  /** VLAN
    UNTAG: IDの指定なし
    OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
    SPECIFIED: 指定ID
   */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED";
  /** VLAN ID */
  vlanId: number | null;
  /** 設定上限値一覧 （回線単位の設定上限値（最大値と現在値）を取得するAPI専用項目） */
  upperLimitList: UpperLimitEntity[];
}

/** サイトリスト */
export interface SiteListEntity {
  /** VLAN
    UNTAG: IDの指定なし
    OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
    SPECIFIED: 指定ID
   */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED";
  /** VLAN ID */
  vlanId: number | null;
}

/** PFGW(イントラ)回線情報 */
export interface PfgwLineEntity {
  /** E番号(ACT) */
  enumberAct: string;
  /** E番号(SBY)  */
  enumberSby: string;
  /** 設定上限値一覧 （回線単位の設定上限値（最大値と現在値）を取得するAPI専用項目） */
  upperLimitList: UpperLimitEntity[];
}

/** VN L2L3コネクト回線情報 */
export interface Vnl2l3LineEntity {
  /** E番号 */
  enumber: string;
  /** 設定上限値一覧 （回線単位の設定上限値（最大値と現在値）を取得するAPI専用項目） */
  upperLimitList: UpperLimitEntity[];
}

/** VNコネクト回線情報 */
export interface VnConnectEntity {
  /** W番号 */
  wnumber: string;
  /** 設定上限値一覧 （回線単位の設定上限値（最大値と現在値）を取得するAPI専用項目）*/
  upperLimitList: UpperLimitEntity[];
}

/** インターネット回線情報 */
export interface InternetLineEntity {
  /** インターネット(Type1) */
  type1: Type1Entity | null;
  /** インターネット(Type4) */
  type4: Type4Entity | null;

  /** アプライアンスタイプ
    PACKET_FILTERING：パケットフィルタリング
    INTERNET_FW：インターネットFW
    UTM：UTM
    INTRANET_FW：イントラネットFW
    NAT:NAT
  */
  applianceType:
    | "PACKET_FILTERING"
    | "INTERNET_FW"
    | "UTM"
    | "INTRANET_FW"
    | "NAT";
  /** 設定上限値一覧 */
  upperLimitList: UpperLimitEntity[];
}

/** TYPE1 */
export interface Type1Entity {
  /** E番号(Main_ACT) */
  enumberMainAct: string;
  /** E番号(Main_SBY)  */
  enumberMainSby: string;
  /** E番号(BCP_ACT) */
  enumberBcpAct: string;
  /** E番号(BCP_SBY)  */
  enumberBcpSby: string;
}
/** TYPE4 */
export interface Type4Entity {
  /** Type4ID */
  type4Id: string;
  /** W番号(Main_ACT) */
  wnumberMainAct: string;
  /** W番号(Main_SBY) */
  wnumberMainSby: string;
  /** W番号(BCP_ACT) */
  wnumberBcpAct: string;
  /** W番号(BCP_SBY) */
  wnumberBcpSby: string;
  /** VNコード */
  vnCode: string;
}

/** クラウド回線情報 */
export interface CloudLineEntity {
  /** クラウド回線SEQ */
  cloudLineSeq: string;
  /** E番号 */
  enumber: string;
  /** クラウドサービスメニュー */
  cloudServiceMenu: string;

  // 回線単位の設定上限値（最大値と現在値）を取得するAPI専用項目
  /** W番号 */
  wnumberAct: string | null;
  /** W番号（Sby） */
  wnumberSby: string | null;
  /** 設定上限値一覧 */
  upperLimitList: UpperLimitEntity[];

  // 上限値管理単位に紐づく回線情報を取得するAPI専用項目
  vnConnectList: {
    /** W番号 */
    wnumberAct: string;
    /** W番号（Sby） */
    wnumberSby: string | null;
  }[];
}

/** 契約単位（オプション契約）の設定上限値（最大値と現在値）取得API用インタフェース */
export interface UpperLimitOptionGetEntity {
  /** 契約上限値設定排他情報 */
  upperLimitContractKeyId: string;
  /** VNコネクト設定上限値情報 */
  vnConnect: VnConnectUpperLimitEntity | null;
  /** IPsec回線設定上限値情報 */
  ipsec: IpSecUpperLimitEntity | null;
}

/** VNコネクト設定上限値情報 */
export interface VnConnectUpperLimitEntity {
  /** VNコネクト数 上限値 */
  upperLimitVnConnect: number;
  /** VNコネクト数 設定可能上限値 */
  possibleUpperLimitVnConnect: number;
  /** VNコネクト数 */
  numberOfVnConnect: number;
}

/** IPsec回線設定上限値情報 */
export interface IpSecUpperLimitEntity {
  /** IPSec数 上限値 */
  upperLimitIpsec: number;
  /**IPSec数 設定可能上限値 */
  possibleUpperLimitIpsec: number;
  /** IPSec数 */
  numberOfIpsec: number;
}

/** 契約単位（オプション契約）の設定上限値（最大値と現在値）更新API用インタフェース */
export interface UpperLimitOptionUpdateEntity {
  /** 契約上限値設定排他情報 */
  upperLimitContractKeyId: string;
  /** VNコネクト数 上限値 */
  upperLimitVnConnect: number | null;
  /** IPSec数 上限値 */
  upperLimitIpsec: number | null;
}

export interface QueryParamEntity {
  /** 上限値管理単位
   * ACCESS：アクセス回線、"VLAN IDのType指定"で、取得上限値項目名が異なる。
   * PFGW：PFGW(イントラ)
   * VNL2L3：VN L2L3コネクト
   * VN：VNコネクト
   * APPLIANCE：アプライアンス（インターネット回線）、"アプライアンス種別"で、取得上限値項目名が異なる
   * MULTI_CLOUD：マルチクラウド接続 ※"クラウドサービス"で、W番号と取得上限値項目名が異なる
   * INTERNET_TYPE4：インターネット回線Type4
   */
  upperLimitLineManageUnit:
    | "ACCESS"
    | "PFGW"
    | "VNL2L3"
    | "VN"
    | "APPLIANCE"
    | "MULTI_CLOUD"
    | "INTERNET_TYPE4";
  /** アプライアンスタイプ
    PACKET_FILTERING：パケットフィルタリング
    INTERNET_FW：インターネットFW
    UTM：UTM
    INTRANET_FW：イントラネットFW
    NAT:NAT
   */
  applianceType?:
    | "PACKET_FILTERING"
    | "INTERNET_FW"
    | "UTM"
    | "INTRANET_FW"
    | "NAT"
    | null;
  /**
   * E番号
   * 備考：必須/不必須条件は下記になる。
   * ・上限値管理単位がアクセス回線：必須
   * ・上限値管理単位がVN L2L3コネクト：必須
   * ・上限値管理単位がPFGW(イントラ) ：必須, enumberActを指定する。
   * ・上限値管理単位がアプライアンス（インターネット回線Type1かつ、UTMまたはNAT、インターネットFWのいずれかの場合） ：必須, enumberMainActを指定する。
   */
  enumber?: string;
  /** VLAN
    UNTAG: IDの指定なし
    OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
    SPECIFIED: 指定ID
   */
  vlanIdType?: "UNTAG" | "OTHER" | "SPECIFIED";

  /** VLAN ID */
  vlanId?: number;
  /** W番号 */
  wnumber?: string;
  /** Type4ID  */
  type4Id?: string;
  /** クラウド回線SEQ */
  cloudLineSeq?: string;
}

export default class UpperLimitApi extends AbstractBaseApi {
  /** 契約単位の設定上限値取得API呼び出し定義処理 */
  async getUpperLimit(): Promise<UpperLimitGetEntity> {
    return (await this.api.get("v2/contract/upperlimit")).data;
  }

  /** 回線単位の設定上限値取得API呼び出し定義処理 */
  /** クエリパラメータ - 必須（upperLimitManageUnit）、任意（applianceType, enumber, vlanIdType, wnumber, type4Id）*/
  async getUpperLimitLine(
    param: QueryParamEntity
  ): Promise<UpperLimitLineGetEntity> {
    return (
      await this.api.get("v3/contract/upperlimit/line", {
        params: param,
      })
    ).data;
  }

  /** 契約単位（オプション契約）の設定上限値（最大値と現在値）取得API呼び出し定義処理 */
  async getUpperLimitOption(): Promise<UpperLimitOptionGetEntity> {
    return (await this.api.get("v1/contract/upperlimit/option")).data;
  }

  /** 契約単位（オプション契約）の設定上限値（最大値と現在値）更新API呼び出し定義処理 */
  async updateUpperLimitOption(
    data: UpperLimitOptionUpdateEntity
  ): Promise<AxiosResponse> {
    return (await this.api.post("v1/contract/upperlimit/option", data)).data;
  }

  /**
   * 上限値管理単位に紐づく回線情報を取得するAPI
   * @param upperLimitManageUnit 上限値管理単位
   *                             ACCESS：アクセス回線
   *                             PFGW：PFGW(イントラ)
   *                             VNL2L3：VN L2L3コネクト
   *                             VN：VNコネクト
   *                             APPLIANCE：アプライアンス
   *                             MULTI_CLOUD：マルチクラウド接続
   *                             INTERNET_TYPE4：インターネット回線Type4
   */
  async getUpperLimitLineSearch(
    upperLimitManageUnit:
      | "ACCESS"
      | "PFGW"
      | "VNL2L3"
      | "VN"
      | "APPLIANCE"
      | "MULTI_CLOUD"
      | "INTERNET_TYPE4"
  ): Promise<UpperLimitLineSearchGetEntity> {
    return (
      await this.api.get("v2/contract/upperlimit/line/search", {
        params: {
          upperLimitManageUnit,
        },
      })
    ).data;
  }
}

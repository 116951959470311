

















































































































































































































































































































































































import Vue, { PropType } from "vue";
import { CloudVnMsDetail, CloudVnMsList } from "@/apis/CloudVnMsApi";
import MultiCloudVNConnectionConfirmMS from "@/modals/multicloud/ms/MultiCloudMsVnConnectionConfirm.vue";
import MultiCloudMsVnLineEdit from "@/modals/multicloud/ms/MultiCloudMsVnLineEdit.vue";
import { mapState } from "vuex";
import { ContractInfo } from "@/apis/ContractApi";

export default Vue.extend({
  name: "MultiCloudMsVnConnectionReference",
  props: {
    wnumber: {
      type: String as PropType<string>,
      required: true,
    },
    cloudLineSeq: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      cloudList: {} as CloudVnMsList,
      cloudDetail: {} as CloudVnMsDetail,
      isLoaded: false,
      alertMsg: null as string | null,
      isChanged: false,
    };
  },
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
      contractSummary: "contractSummary",
    }),
    /** 開始日が未来日の場合,true*/
    isFutureDayContract(): boolean {
      return !this.contractInfo.contractList.find(
        (e: ContractInfo["contractList"][0]) =>
          e.enumber === this.cloudDetail.enumber
      );
    },
    title(): string {
      if (!this.isLoaded) {
        return "";
      }
      if (
        this.cloudDetail.cloudVnConnectCommon.cloudServiceType ===
        "PRIVATE_PEERING"
      ) {
        return "Microsoft Azure(Private Peering)向けVNコネクト情報";
      } else {
        return "Microsoft Azure(Microsoft Peering)向けVNコネクト情報";
      }
    },
  },

  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.cloudList = await this.$api.cloudVnMs.getVnConnects(
        this.cloudLineSeq
      );
      this.cloudDetail = await this.$api.cloudVnMs.getVnConnect(this.wnumber);
    },
    async showVNConnectDelete() {
      await this.alertClear();
      if (
        this.cloudList.cloudVnConnectsList.length >= 2 &&
        this.cloudDetail.cloudVnConnectCommon.cloudServiceType ===
          "PRIVATE_PEERING"
      ) {
        this.alertMsg = "Microsoft Peering向けVNコネクトから削除してください。";
        return;
      } else {
        this.alertMsg = null;
      }

      // 削除確認モーダルの表示
      await this.$modal.show(MultiCloudVNConnectionConfirmMS, {
        cloudVnMsDetail: this.cloudDetail,
        displayType: "D",
      });
      // 削除処理
      await this.$api.cloudVnMs.deleteVnConnect(this.cloudLineSeq, {
        cloudVnConnectList: [
          this.cloudDetail.cloudVnConnectAct.wnumber,
          this.cloudDetail.cloudVnConnectSby.wnumber,
        ],
        cloudVnMsKeyId: this.cloudList.cloudVnMsKeyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    async showVNConnectModify() {
      await this.alertClear();
      this.alertMsg = null;
      // 変更ダイアログを表示
      await this.$modal.show(MultiCloudMsVnLineEdit, {
        cloudVnMsDetail: this.cloudDetail,
        cloudLineSeq: this.cloudLineSeq,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    async executed() {
      this.isChanged = true;
      await this.load();
    },
    /** エラーメッセージを初期化する */
    async alertClear() {
      (
        this.$refs.operationCommandAct as Vue & { alertClear: () => void }
      ).alertClear();
      (
        this.$refs.operationCommandSby as Vue & { alertClear: () => void }
      ).alertClear();
    },
  },
});

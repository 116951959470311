










































































































































































































































































import Vue, { PropType } from "vue";
import {
  PostType1InternetSite,
  PutType1InternetAddress,
  PutType1InternetFQDN,
  Type1InternetInfo,
} from "@/apis/Type1SiteApi";

export default Vue.extend({
  name: "InternetSiteModify",
  props: {
    internetSiteZone: {
      type: Object as PropType<Type1InternetInfo>,
      required: true,
    },
  },
  data() {
    return {
      globalZoneId: this.internetSiteZone.zone?.zoneId || "none",
      addressList: [] as PutType1InternetAddress[],
      fqdnList: [] as PutType1InternetFQDN[],
    };
  },
  computed: {
    /** 既存のIPアドレス */
    existsAddress() {
      // 既存のアドレスリストに同じIPアドレスが存在した場合はエラーとする
      return (index: number) => {
        return this.addressList
          .filter((_, num) => num !== index)
          .map((v) => v.ipAddress);
      };
    },
    /** 既存のFQDN */
    existsFqdn() {
      // 既存のFQDNリストに同じFQDNが存在した場合はエラーとする
      return (index: number) => {
        return this.fqdnList
          .filter((_, num) => num !== index)
          .map((v) => v.fqdn);
      };
    },
    /** 関連項目チェック:zoneがnull場合ゾーン配置をチェック */
    noGlobalZone(): boolean {
      return !(
        this.addressList.some((item) => item.zoneId === "none") ||
        this.fqdnList.some((item) => item.zoneId === "none")
      );
    },
    /** アドレス名入力済みの場合は,true */
    hasInputIpAddressName(): boolean {
      return this.addressList.some((v) => v.ipAddressName);
    },
    /** ゾーン配置チェック済みの場合は,true */
    hasCheckZone(): boolean {
      return this.addressList.some((v) => v.zoneId);
    },
    /** FQDN名入力済みの場合は,true */
    hasInputFqdnName(): boolean {
      return this.fqdnList.some((v) => v.fqdnName);
    },
    /** FQDNゾーン配置チェック済みの場合は,true */
    hasCheckFqdnZone(): boolean {
      return this.fqdnList.some((v) => v.zoneId);
    },
  },
  mounted() {
    this.load();
  },

  methods: {
    /**
     * 初期化機能
     * システム上の配置状態追加
     */
    load() {
      this.addressList = this.internetSiteZone.addressList.map((e) => ({
        ...e,
        isDeploy: !!e.zoneId,
      }));
      this.fqdnList = this.internetSiteZone.fqdnList.map((e) => ({
        ...e,
        isDeploy: !!e.zoneId,
      }));
      // リストが０件の場合デフォルトを追加
      if (this.addressList.length < 1) {
        this.addIPAddress();
      }
      if (this.fqdnList.length < 1) {
        this.addFqdn();
      }
    },
    /** 変更機能 */
    async submit() {
      await this.$confirm(
        "インターネットサイト設定を変更します。よろしいですか？"
      );
      // リストが０件の場合空配列に
      if (this.addressList.length === 1 && !this.addressList[0].ipAddress) {
        this.addressList = [];
      }
      if (this.fqdnList.length === 1 && !this.fqdnList[0].fqdn) {
        this.fqdnList = [];
      }
      // 変更データの設定
      const reqData: PostType1InternetSite = {
        addressList: this.addressList.map((e) => ({
          ipAddressId: e.ipAddressId,
          ipAddressName: e.ipAddressName || null,
          ipAddress: e.ipAddress,
          zoneId: e.zoneId,
        })),
        fqdnList: this.fqdnList.map((e) => ({
          fqdnId: e.fqdnId,
          fqdnName: e.fqdnName || null,
          fqdn: e.fqdn,
          zoneId: e.zoneId,
        })),
        type1SiteZoneKeyId: this.internetSiteZone.type1SiteZoneKeyId,
      };
      await this.$api.type1SiteZone.postType1InternetSite(reqData);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * IPAddress追加機能
     * 押下時一行追加
     */
    addIPAddress() {
      this.addressList.push({
        ipAddressId: null,
        ipAddressName: "",
        ipAddress: "",
        zoneId: null,
        isDeploy: false,
      });
    },
    /**
     * fqdn追加機能
     * 押下時一行追加
     */
    addFqdn() {
      this.fqdnList.push({
        fqdnId: null,
        fqdnName: "",
        fqdn: "",
        zoneId: null,
        isDeploy: false,
      });
    },
    /**
     * IPAddress削除機能
     * 押下時その行削除
     * 一行しかない場合その行クリア
     */
    removeIPAddress(target: PutType1InternetAddress) {
      if (this.addressList.length > 1) {
        this.addressList.splice(this.addressList.indexOf(target), 1);
      } else {
        this.addressList = [
          {
            ipAddressId: null,
            ipAddressName: "",
            ipAddress: "",
            zoneId: null,
            isDeploy: false,
          },
        ];
      }
    },
    /**
     * fqdn削除機能
     * 押下時その行削除
     * 一行しかない場合その行クリア
     */
    removeFqdn(target: PutType1InternetFQDN) {
      if (this.fqdnList.length > 1) {
        this.fqdnList.splice(this.fqdnList.indexOf(target), 1);
      } else {
        this.fqdnList = [
          {
            fqdnId: null,
            fqdnName: "",
            fqdn: "",
            zoneId: null,
            isDeploy: false,
          },
        ];
      }
    },
  },
});

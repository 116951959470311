var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":_vm.settingType === 'vnConnect'
      ? '課金対象VNコネクト作成上限'
      : 'IPsec回線作成上限',"classes":"modal-medium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"detail-container"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"現在の設定済み回線数"}},[_c('span',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.form.currentLimit)+"回線 ")])])],1)]),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"上限値","msg":"半角数字","isRequired":true}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"上限値","rules":{
                required: true,
                numeric: true,
                between: { min: 0, max: 99999 },
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input-number',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.limit),callback:function ($$v) {_vm.$set(_vm.form, "limit", _vm._n($$v))},expression:"form.limit"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1)],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("変更")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * 「GWルータ」と「GW対向ルータ」が同じセグメントのアドレス帯になっていること(64bitまでをみるのではなく、112bitまでを比較する必要がある)
 * 「GW対向ルータ」の末尾が「0001」であること、「GWルータ」の末尾が「0002」であること
 * ・IPV6形式であることは別のバリデーションで実施が必要
 * ・CIDR形式のみ受付可能
 */
export default {
  params: ["target", "pe_gw"],
  validate(
    value: string | undefined | null,
    { target, pe_gw }: { target: string | undefined | null; pe_gw: "pe" | "gw" }
  ): boolean | string {
    if (!value) {
      return true;
    }
    try {
      const valueIpv6 = ipaddr.IPv6.parseCIDR(value);

      if (pe_gw === "pe" && valueIpv6[0].parts[7] !== 1) {
        return "末尾**:0001で入力してください";
      }
      if (pe_gw === "gw" && valueIpv6[0].parts[7] !== 2) {
        return "末尾**:0002で入力してください";
      }

      if (!target) {
        return true;
      }

      const targetIpv6 = ipaddr.IPv6.parseCIDR(target);

      const valueIp = valueIpv6[0].parts
        .map((e) => e.toString(2).padStart(16, "0"))
        .join("");
      const targetIp = targetIpv6[0].parts
        .map((e) => e.toString(2).padStart(16, "0"))
        .join("");

      if (valueIp.substring(0, 112) !== targetIp.substring(0, 112)) {
        return "「GWルータ」と「GW対向ルータ」の先頭から28桁を同一の値で入力してください。";
      }
    } catch (e) {
      return true;
    }

    return true;
  },
} as ValidationRuleSchema;

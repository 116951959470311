



























import Vue, { PropType } from "vue";
import { CommonFailure } from "@/apis/InformationApi";

export default Vue.extend({
  name: "PacketFilteringReference",
  props: {
    /** 選択行の障害/メンテナンス情報 */
    failureMaintenanceItem: {
      type: Object as PropType<CommonFailure>,
      required: true,
    },
  },
});

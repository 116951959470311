























































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import SaaSServiceUsageEdit from "@/modals/saas/SaaSServiceUsageEdit.vue";
import SaaSServiceUsageReference from "@/modals/saas/SaaSServiceUsageReference.vue";
import { SaaSServiceListEntity } from "@/apis/SaasApi";

export type SaaSListEntity = SaaSServiceListEntity & {
  msServiceName: "Microsoft Peering";
  sfdcServiceName: "Salesforce";
  msPeeringFlag: "利用する" | "利用しない";
  sfdcFlag: "利用する" | "利用しない";
};

export default Vue.extend({
  name: "SaaSServiceApplicationList",
  data() {
    return {
      saas: {
        fields: [
          {
            key: "vpnVnCode",
            label: "VPN/VNコード",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v, _k, item: SaaSListEntity) => {
              return item.saasCommon.vpnVnCode;
            },
          },
          {
            key: "vnName",
            label: "VNネットワーク名",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v, _k, item: SaaSListEntity) => {
              return item.saasCommon.vnName;
            },
          },
          {
            key: "serviceName",
            label: "利用サービス",
          },
          {
            key: "usage",
            label: "利用状況",
          },
          {
            key: "upperLimitNapt",
            label: "NAPTアドレス上限数",
            tdClass: "text-right",
          },
          {
            key: "upperLimitNat",
            label: "NATアドレス上限数",
            tdClass: "text-right",
          },
        ],
        items: [],
        selected: [],
        search: {
          vpnVnCode: {
            type: "text",
            label: "VPN/VNコード",
          },
          vnName: {
            type: "text",
            label: "VNネットワーク名",
          },
          msPeeringFlag: {
            type: "select",
            label: "Microsoft Peering",
            items: usageItems,
          },
          sfdcFlag: {
            type: "select",
            label: "Salesforce",
            items: usageItems,
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          vpnVnCode: "like",
          vnName: "like",
          msPeeringFlag: "equal",
          sfdcFlag: "equal",
        },
      } as AppTableData<SaaSListEntity>,
      keyId: "",
      usageItems: usageItems,
      isLoaded: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      /** SaaS利用情報APIの呼び出し処理 */
      const data = await this.$api.saas.getAll();
      this.saas.items = data.saasServiceList.map((e) => ({
        ...e,
        msServiceName: "Microsoft Peering",
        sfdcServiceName: "Salesforce",
        msPeeringFlag: e.msPeeringInfo?.msPeering ? "利用する" : "利用しない",
        sfdcFlag: e.sfdcInfo?.sfdc ? "利用する" : "利用しない",
      }));
      this.keyId = data.saasKeyId;
    },

    /** サービス追加画面表示 */
    async addSaasService() {
      await this.$modal.show(SaaSServiceUsageEdit, {
        saasKeyId: this.keyId,
        // 一覧項目に既に存在しているVpnVnCodeを設定する
        existVpnVnCodeList: this.saas.items.map((v) => v.saasCommon.vpnVnCode),
      });
      await this.load();
    },

    /** サービス詳細情報画面表示 */
    async serviceDetails(service: SaaSListEntity) {
      await this.$modal.show(SaaSServiceUsageReference, {
        saas: service,
        saasKeyId: this.keyId,
      });
      await this.load();
    },
    /** フロント側のみでのCSVダウンロード */
    downloadCsv() {
      // ダウンロード用データを編集する
      const csvDataArray: Array<Record<string, unknown>> = [];
      this.saas.items
        .sortBy([(e) => e.saasCommon.vpnVnCode, "asc"])
        .map((v) => {
          // Microsoft Peeringのデータを編集する
          csvDataArray.push({
            ...v,
            serviceName: v.msServiceName,
            usage: v.msPeeringFlag,
            upperLimitNapt: v.msPeeringInfo?.msPeering?.upperLimitNapt ?? "",
            upperLimitNat: v.msPeeringInfo?.msPeering?.upperLimitNat ?? "",
          });
          // Salesforceのデータを編集する
          csvDataArray.push({
            ...v,
            serviceName: v.sfdcServiceName,
            usage: v.sfdcFlag,
            upperLimitNapt: v.sfdcInfo?.sfdc?.upperLimitNapt ?? "",
            upperLimitNat: "",
          });
        });
      // csvをダウンロードする
      this.$service.csvService.appTableCsvDownLoad(
        "saas_application_status",
        csvDataArray,
        this.$service.csvService.genFieldList(this.saas.fields)
      );
    },
  },
});

/** Microsoft Peering/Salesforce表示マッピング用 */
export const usageItems = [
  { value: "利用する", text: "利用する" },
  { value: "利用しない", text: "利用しない" },
];

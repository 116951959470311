
































import Vue, { PropType } from "vue";
import { GetDstAddress1, GetDstAddress2 } from "@/apis/NwPolicyApi";

/** インターネットType1,4のポリシーの送信先アドレス表示が出来るコンポーネント */
export default Vue.extend({
  name: "PolicyDstAddress",
  props: {
    /** 送信先アドレス */
    dstAddress: {
      type: Object as PropType<GetDstAddress1 | GetDstAddress2>,
      required: true,
    },
  },
  computed: {
    /** true: GetDstAddress2, false: GetDstAddress1 */
    isAddress2(): boolean {
      return (
        "dstFqdnList" in this.dstAddress && "dstCountryList" in this.dstAddress
      );
    },
    /** アドレス一覧を昇順でソートした一覧 */
    addressList(): GetDstAddress2["dstAddressList"] {
      return [
        ...this.dstAddress.dstAddressList
          .filter((e) => e.ipAddress && !e.ipAddressName)
          .sortBy(["ipAddress", "asc"]),
        ...this.dstAddress.dstAddressList
          .filter((e) => e.ipAddressName)
          .sortBy(["ipAddressName", "asc"]),
      ];
    },
    /** FQDN一覧を昇順でソートした一覧. GetDstAddress2では無い場合は空 */
    fqdnList(): GetDstAddress2["dstFqdnList"] {
      if (this.isAddress2) {
        return (this.dstAddress as GetDstAddress2).dstFqdnList.sortBy([
          (e) => e.fqdnName ?? e.fqdn,
          "asc",
        ]);
      } else {
        return [];
      }
    },
    /** 国一覧を昇順でソートした一覧. GetDstAddress2では無い場合は空 */
    countryList(): GetDstAddress2["dstCountryList"] {
      if (this.isAddress2) {
        return (this.dstAddress as GetDstAddress2).dstCountryList.sortBy([
          "country",
          "asc",
        ]);
      } else {
        return [];
      }
    },
  },
});






























































import Vue, { PropType } from "vue";
import { Type4NatPolicy } from "@/modals/applianceContractSetting/InternetType4PolicyRuleDestNatViewRD.vue";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";

export default Vue.extend({
  name: "InternetType4PolicyRuleDestNatConfirm",
  components: { PolicySrcAddress },
  props: {
    policy: {
      type: Object as PropType<Type4NatPolicy>,
      required: true,
    },
  },
});

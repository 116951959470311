
































































































































import Vue, { PropType } from "vue";
import { PacketFilterEntity } from "@/apis/PacketFilteringApi";
import PacketFilteringRuleEdit from "@/modals/packetFiltering/PacketFilteringRuleEdit.vue";
import { UploadError } from "@/services/ConfigDownloadService";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "PacketFilteringEdit",
  props: {
    /** パケットフィルタリング設定情報 */
    packets: {
      type: Array as PropType<PacketFilterEntity[]>,
      required: true,
    },
    /** W番号ACT（VNコネクトのパケットフィルタ設定のみ必須）*/
    wnumberAct: {
      type: String as PropType<string>,
      required: false,
    },
    /** VNコネクト名ACT（VNコネクトのパケットフィルタ設定のみ必須）*/
    vnConnectNameAct: {
      type: String as PropType<string>,
      required: false,
    },
    /** W番号SBY（VNコネクトのパケットフィルタ設定のみ必須）*/
    wnumberSby: {
      type: String as PropType<string>,
      required: false,
    },
    /** VNコネクト名SBY（VNコネクトのパケットフィルタ設定のみ必須）*/
    vnConnectNameSby: {
      type: String as PropType<string>,
      required: false,
    },
    /** 排他パラメータ（VNコネクトのパケットフィルタ設定のみ必須）*/
    vnConnectPacketFilterKeyId: {
      type: String as PropType<string>,
      required: false,
    },
    /** E番号（アクセス回線のパケットフィルタ設定のみ必須）*/
    enumber: {
      type: String as PropType<string>,
      required: false,
    },
    /** メニュー（アクセス回線のパケットフィルタ設定のみ必須）*/
    menu: {
      type: String as PropType<string>,
      required: true,
    },
    /** 排他パラメータ（アクセス回線のパケットフィルタ設定のみ必須）*/
    accessLinePacketFilterKeyId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    const packFilterClone: PacketFilterEntity[] = cloneDeep(this.packets);
    // 主キー相当の項目が無いため、こちらでインデックスを作成
    packFilterClone?.numbering();
    return {
      uploadAlert: null as string | null,
      rules: {
        fields: [
          { key: "_handle", label: "↑↓", tdClass: "handle" },
          {
            key: "protocol",
            label: "プロトコル",
          },
          {
            key: "action",
            label: "アクション",
          },
          {
            key: "srcAddress",
            label: "送信元アドレス",
          },
          {
            key: "srcPort",
            label: "送信元ポート",
          },
          {
            key: "dstAddress",
            label: "宛先アドレス",
          },
          {
            key: "dstPort",
            label: "宛先ポート",
          },
          {
            key: "filteringStatus",
            label: "フィルタリング 有効/無効",
          },
          {
            key: "description",
            label: "備考",
            tdClass: "text-pre-wrap",
          },
          {
            key: "delete",
            label: "",
            tdClass: "delete",
          },
        ],
        items: packFilterClone,
      },
    };
  },
  methods: {
    /** 設定ダウンロード処理 */
    downLoadConfig() {
      const data = this.rules.items.filter((e) => !e.isDefaultRule);
      this.$service.configDownload.downloadPacketFilter(
        this.menu,
        this.wnumberAct,
        this.enumber,
        data
      );
    },

    /** 設定アップロード処理 */
    async upLoadConfig() {
      try {
        const uploadRules =
          await this.$service.configDownload.uploadPacketFilter(
            this.wnumberAct,
            this.enumber
          );
        this.uploadAlert = null;
        await this.$confirm(
          `パケットフィルタリング設定を上書きします。よろしいですか？`
        );

        /** 既存のユーザルールを削除 */
        this.rules.items = this.rules.items.filter((e) => e.isDefaultRule);
        /** アップロードされデータの追加 */
        let index = this.rules.items.length - 1;
        uploadRules.forEach((e: PacketFilterEntity) => {
          this.rules.items.splice(
            index++,
            0,
            this.rules.items.nextNumber({
              ...e,
              protocol: e.protocol ?? "ANY",
              action: e.action ?? "PERMIT",
              filteringStatus: e.filteringStatus ?? "DISABLED",
              isDefaultRule: false,
            })
          );
        });
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },

    /** ルール追加 */
    async registerPacket() {
      this.uploadAlert = null;
      const add: PacketFilterEntity = await this.$modal.show(
        PacketFilteringRuleEdit
      );
      const lastNotDefaultRule = this.rules.items
        .map((e) => e.isDefaultRule)
        .lastIndexOf(false);
      if (lastNotDefaultRule === -1) {
        // ユーザー作成のルールが存在しない場合は最後の暗黙ルールの一つ前にルールを追加
        this.rules.items.splice(
          this.rules.items.length - 1,
          0,
          this.rules.items.nextNumber({ ...add })
        );
      } else {
        // ユーザー作成のルールが存在する場合はその次にルールを追加
        this.rules.items.splice(
          lastNotDefaultRule + 1,
          0,
          this.rules.items.nextNumber({ ...add })
        );
      }
    },

    /** ルール全削除 */
    deleteUserRules() {
      this.rules.items = this.rules.items.filter((e) => e.isDefaultRule);
    },

    /** ルール削除 */
    deleteRow(index: number) {
      this.rules.items.splice(index, 1);
    },

    /** ルール変更 */
    async editPacket(item: PacketFilterEntity, index: number) {
      if (item.isDefaultRule) {
        return;
      }
      this.uploadAlert = null;
      const edit: PacketFilterEntity = await this.$modal.show(
        PacketFilteringRuleEdit,
        {
          packetData: item,
        }
      );
      this.$set(this.rules.items, index, edit);
    },

    /** 設定反映 */
    async modifyPacket() {
      this.uploadAlert = null;
      await this.$confirm(
        `パケットフィルタリングを設定します。よろしいですか？`
      );

      const noDefaultRules = this.rules.items
        .filter((e) => !e.isDefaultRule)
        .map((e) => ({
          packetFilterRuleSeq: e.packetFilterRuleSeq,
          isDefaultRule: e.isDefaultRule,
          // デフォルトルールは除外しているのでプロトコルは必須、プロトコル番号無し
          protocol: e.protocol!,
          protocolNumber: null,
          action: e.action,
          srcAddress: e.srcAddress,
          srcPort: e.srcPort,
          dstAddress: e.dstAddress,
          dstPort: e.dstPort,
          filteringStatus: e.filteringStatus,
          description: e.description,
        }));

      if (this.wnumberAct) {
        if (this.menu === "MULTI_CLOUD_GW") {
          await this.$api.packetFilter.updateCloudPktFilter(this.wnumberAct, {
            packetFilterRuleList: noDefaultRules,
            vnConnectPacketFilterKeyId: this.vnConnectPacketFilterKeyId,
          });
          (this.$refs.modal as Vue & { ok: () => void }).ok();
        } else {
          await this.$api.packetFilter.updateVnConnectPktFilter(
            this.wnumberAct,
            {
              packetFilterRuleList: noDefaultRules,
              vnConnectPacketFilterKeyId: this.vnConnectPacketFilterKeyId,
            }
          );
          (this.$refs.modal as Vue & { ok: () => void }).ok();
        }
      } else {
        await this.$api.packetFilter.updateAcLinePktFilter(this.enumber, {
          packetFilterRuleList: noDefaultRules,
          accessLinePacketFilterKeyId: this.accessLinePacketFilterKeyId,
        });
        (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },

    /** ルール変更不可・可能 */
    editableClass(
      item: Pick<PacketFilterEntity, "isDefaultRule">
    ): string | string[] | null {
      switch (item.isDefaultRule) {
        case true:
          // ドラッグ不可
          return ["default-rule", "un-clickable"];
        case false:
          // ドラッグ可能
          return "draggable";
      }
    },
  },
});

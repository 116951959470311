










































































































































































































































import Vue, { PropType } from "vue";
import { GetPolicyType1 } from "@/apis/NwPolicyApi";
import cloneDeep from "lodash/cloneDeep";
import { IpMappings } from "@/apis/IpMappingApi";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import { Type1DestinationNatPolicy } from "@/modals/applianceContractSetting/InternetType1PolicyRuleDestNatViewRD.vue";
import {
  Type1ExtraInfo,
  Type1InternetAddress,
  Type1PrivateInfo,
} from "@/apis/Type1SiteApi";
import InternetType1PolicyRuleDestNatConfirm from "@/modals/applianceContractSetting/InternetType1PolicyRuleDestNatConfirm.vue";
import MultiSelectTag from "@/components/MultiSelectTag.vue";

export default Vue.extend({
  name: "InternetType1PolicyRuleDestNatEdit",
  components: { MultiSelectTag },
  props: {
    /** ゾーン */
    zone: {
      type: Object as PropType<ZoneRelationItem>,
      required: true,
    },
    /** 変更対象ポリシー */
    policy: {
      type: Object as PropType<Type1DestinationNatPolicy>,
      default: undefined,
    },
    /** type1ポリシー全体 */
    type1Policy: {
      type: Object as PropType<GetPolicyType1>,
      required: true,
    },
  },
  data() {
    const clonePolicy = cloneDeep(this.policy);
    const form: Type1DestinationNatPolicy = clonePolicy ?? {
      srcAddressList: [],
      ipMapping: null,
      isPolicyStatus: true,
    };

    return {
      form,
      // 送信元、宛先（any/アドレス）
      radioSrcAddress: (form.srcAddressList.length === 0 ? "ANY" : "SELECT") as
        | "ANY"
        | "SELECT",
      // インターネット（アドレス一覧）
      internetAddresses: [] as Type1InternetAddress[],
      // IPマッピング一覧
      ipMappings: [] as IpMappings["ipMappingList"],
      // プライベート/エクストラサイトゾーン情報
      type1PrivateList: {} as Type1PrivateInfo,
      type1ExtraList: {} as Type1ExtraInfo,

      isLoaded: false,
    };
  },
  computed: {
    /** true: 編集, false: 新規 */
    isEdit(): boolean {
      return !!this.policy;
    },
    /** 送信元アドレスのSelectの選択肢 */
    srcAddresses(): {
      label: string;
      values: Type1InternetAddress[];
    }[] {
      return [
        {
          label: "全選択",
          values: this.internetAddresses,
        },
      ];
    },
    /** 既存のtype1ポリシーID一覧 */
    existsPolicyIds(): string[] {
      const p = this.type1Policy;
      return [
        ...(p.policyUtmType1?.accessPointToInternetList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyUtmType1?.internetToAccessPointList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyIfwType1?.accessPointToInternetList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyIfwType1?.internetToAccessPointList ?? []).flatMap(
          (v) => v.policyList
        ),
        ...(p.policyIPMasqueradeType1List ?? []).flatMap((v) => v.policyList),
        ...(p.policyNatType1List ?? []).flatMap((v) => v.policyList),
      ].map((e) => e.policyId);
    },
    /** IPマッピング選択肢（プライベートアドレスが対象のゾーンに属しているもののみ） */
    ipMappingOptions(): IpMappings["ipMappingList"] {
      // 対象ゾーンに属しているアドレス一覧
      const zoneAddresses = ((): Type1InternetAddress[] => {
        if (this.zone.dstZoneType === "PRIVATE") {
          return this.type1PrivateList.lineList
            .flatMap((e) => e.siteList)
            .flatMap((e) => e.addressList)
            .filter((e) => e.zoneId === this.zone.dstZoneId);
        } else if (this.zone.dstZoneType === "EXTRA") {
          return this.type1ExtraList.siteList
            .flatMap((e) => e.addressList)
            .filter((e) => e.zoneId === this.zone.dstZoneId);
        } else {
          throw new Error("unexpected dstZoneType");
        }
      })();

      return this.ipMappings.filter((ipMapping) =>
        zoneAddresses.some(
          (zone) => zone.ipAddress === ipMapping.privateIpAddress
        )
      );
    },
  },
  async mounted() {
    // 選択肢などの必要情報を取得
    this.internetAddresses = (
      await this.$api.type1SiteZone.getType1InternetList()
    ).addressList
      .filter((v) => v.zoneId === this.zone.srcZoneId)
      .sortBy(["ipAddress", "asc"]);
    this.ipMappings = (
      await this.$api.ipMapping.getType1IpMappingList()
    ).ipMappingList.sortBy(["ipMappingName", "asc"]);

    // プライベートアドレスがゾーンに属してるIPマッピングのみを選択表示するためにゾーン情報を取得
    if (this.zone.dstZoneType === "PRIVATE") {
      this.type1PrivateList =
        await this.$api.type1SiteZone.getType1PrivateList();
    } else if (this.zone.dstZoneType === "EXTRA") {
      this.type1ExtraList = await this.$api.type1SiteZone.getType1ExtraList();
    }

    this.isLoaded = true;
  },
  methods: {
    /** ルール編集の完了 */
    async submit() {
      const policy: Type1DestinationNatPolicy = {
        ...this.form,
        srcAddressList:
          this.radioSrcAddress === "ANY" ? [] : this.form.srcAddressList,
      };
      await this.$modal.show(InternetType1PolicyRuleDestNatConfirm, { policy });
      (
        this.$refs.modal as Vue & {
          ok: (entity: Type1DestinationNatPolicy) => void;
        }
      ).ok(policy);
    },
    /** アドレス選択肢ラベル名 */
    addressLabel({ ipAddressName, ipAddress }: Type1InternetAddress): string {
      return `${ipAddressName}  ${ipAddress}`;
    },
    /** IPマッピングラベル名（検索用） */
    ipMappingLabel({
      ipMappingName,
      description,
    }: IpMappings["ipMappingList"][0]): string {
      return `${ipMappingName} ${description}`;
    },
  },
});

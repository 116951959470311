



































































































































































import Vue, { PropType } from "vue";
import { Type4ServicePostRequest } from "@/apis/ContractChangeType4Api";

export default Vue.extend({
  name: "InternetType4ContractRegisterConfirm",
  props: {
    vpnVnCode: {
      type: String as PropType<string>,
      required: true,
    },
    postItem: {
      type: Object as PropType<Type4ServicePostRequest>,
      required: true,
    },
  },
  data() {
    return {
      accepted: false,
    };
  },
  methods: {
    /** 「はい」選択時の挙動 */
    submit() {
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 半角英数4文字で入力されること */

export default {
  validate(value: string | undefined | null): boolean {
    if (value) {
      return /^[A-Za-z0-9]{4}$/.test(value);
    }
    return true;
  },
} as ValidationRuleSchema;



















































import Vue, { PropType } from "vue";
import { Type1Zone, Type1ZoneEdit } from "@/apis/Type1SiteApi";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "ZoneEdit",
  props: {
    /** ゾーンモード */
    zoneMode: {
      type: String as PropType<
        "global" | "extra" | "privateRegister" | "privateModify"
      >,
    },
    /**
     * 表示用ゾーン
     * 新規の場合nullになる
     */
    zoneDate: {
      type: Object as PropType<Type1Zone | null>,
      default: null,
    },
    /** 排他確認用パラメータ */
    type1SiteZoneKeyId: {
      type: String,
    },
  },
  data() {
    return {
      /** モーダル用タイトル */
      zoneTitle: "" as string,
      /** モーダル用ボタン */
      zoneButton: "" as string,
      /** 表示用ゾーン */
      zone: this.zoneDate
        ? cloneDeep(this.zoneDate)
        : ({
            zoneId: "",
            zoneName: "",
          } as Type1Zone),
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    /** 初期化機能 */
    load() {
      this.changeTB();
    },
    /** タイトルとボタンの表示変換 */
    changeTB() {
      switch (this.zoneMode) {
        case "global":
          this.zoneTitle = "グローバルゾーン設定(Type1、イントラネットFW)";
          this.zoneButton = "設定";
          break;
        case "extra":
          this.zoneTitle = "エクストラゾーン設定(Type1、イントラネットFW)";
          this.zoneButton = "設定";
          break;
        case "privateRegister":
          this.zoneTitle = "プライベートゾーン設定(Type1、イントラネットFW)";
          this.zoneButton = "追加";
          break;
        case "privateModify":
          this.zoneTitle = "プライベートゾーン設定(Type1、イントラネットFW)";
          this.zoneButton = "変更";
          break;
      }
    },
    /** 追加、変更、設定機能 */
    async submit() {
      // 追加・変更データの設定
      const reqData: Type1ZoneEdit = {
        zoneName: this.zone.zoneName,
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      };
      // モードによってメソッドを使用
      switch (this.zoneMode) {
        case "global":
          await this.$confirm(
            "グローバルゾーン名を設定します。よろしいですか？"
          );
          if (this.zoneDate) {
            // グローバルゾーン変更
            await this.$api.type1SiteZone.postType1GlobalZoneModify(
              reqData,
              this.zone.zoneId
            );
          } else {
            // グローバルゾーン追加
            await this.$api.type1SiteZone.postType1GlobalZoneRegister(reqData);
          }
          (this.$refs.modal as Vue & { ok: () => void }).ok();
          break;
        case "extra":
          await this.$confirm(
            "エクストラゾーン名を設定します。よろしいですか？"
          );
          if (this.zoneDate) {
            // エクストラゾーン変更
            await this.$api.type1SiteZone.postType1ExtraZoneModify(
              reqData,
              this.zone.zoneId
            );
          } else {
            // エクストラゾーン追加
            await this.$api.type1SiteZone.postType1ExtraZoneRegister(reqData);
          }
          (this.$refs.modal as Vue & { ok: () => void }).ok();
          break;
        case "privateRegister":
          await this.$confirm(
            "プライベートゾーンを追加します。よろしいですか？"
          );
          await this.$api.type1SiteZone.postType1PrivateZoneRegister(reqData);
          (this.$refs.modal as Vue & { ok: () => void }).ok();
          break;
        case "privateModify":
          await this.$confirm(
            "プライベートゾーン名を変更します。よろしいですか？"
          );
          await this.$api.type1SiteZone.postType1PrivateZoneModify(
            reqData,
            this.zone.zoneId
          );
          (this.$refs.modal as Vue & { ok: () => void }).ok();
          break;
      }
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
/**
 * 入力した数字は指定した範囲内であることをチェック
 * @param range 指定範囲、指定なしの場合、trueを返却
 * 例：is_number_range: { range: '16550,64512-65534' }
 */
export default {
  params: ["range"],
  validate(
    value: number | null | undefined,
    { range }: { range: string | null | undefined }
  ): boolean {
    // rangeやvalueが指定してない場合、チェックしない、trueを返す
    if (!range || value === null || value === undefined) {
      return true;
    }
    if (typeof value !== "number") {
      return false;
    }
    const rangeArray = range.split(",");
    return rangeArray.some((v) => {
      if (v.indexOf("-") > -1) {
        const start = v.split("-")[0];
        const end = v.split("-")[1];
        return value >= Number(start) && value <= Number(end);
      } else {
        return value === Number(v);
      }
    });
  },
} as ValidationRuleSchema;

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** ポリシー情報（イントラネットFW（PFGW含む））一覧インターフェイス */
export interface IntranetFwPolicy {
  /**
   * ポリシーリスト:プライベート→プライベート
   * プライベートゾーンからプライベートゾーンのゾーン関係IDが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FW_PFGWを指定した場合、空リストを返却
   */
  privateToPrivateList: ZonePolicyInfo[];
  /**
   * ポリシーリスト:エクストラ→プライベート
   * エクストラゾーンからプライベートゾーンのゾーン関係IDが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FW_PFGWを指定した場合、空リストを返却
   */
  extraToPrivateList: ZonePolicyInfo[];
  /**
   * ポリシーリスト:プライベート→エクストラ
   * プライベートゾーンからエクストラゾーンのゾーン関係IDが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FW_PFGWを指定した場合、空リストを返却
   */
  privateToExtraList: ZonePolicyInfo[];
  /**
   * WVS2ゾーンからKCPSゾーンのポリシー
   * WVS2ゾーンからKCPSゾーンのゾーン関係IDが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FWを指定した場合、空リストを返却
   */
  wvs2ToKcpsList: (ZonePolicyInfo & {
    /**
     * E番号(ACT)
     * 主キー
     * GETメソッドから取得したACT側のE番号
     */
    enumberAct: string;
  })[];
  /**
   * KCPSゾーンからWVS2ゾーンのポリシー
   * KCPSゾーンからWVS2ゾーンのゾーン関係IDが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FWを指定した場合、空リストを返却
   */
  kcpsToWvs2List: (ZonePolicyInfo & {
    /**
     * E番号(ACT)
     * 主キー
     * GETメソッドから取得したACT側のE番号
     */
    enumberAct: string;
  })[];
  /**
   * イントラネットFW設定排他情報
   * 排他確認用パラメータ：設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと
   */
  policyIntranetFwKeyId: string;
}

/** ゾーン内のポリシー情報 */
export interface ZonePolicyInfo {
  /** ゾーン関係ID ゾーン間の連携を特定するキー */
  zoneRelationId: string;
  /** 総件数 一覧表示対象の総件数 */
  total: number;
  /** ポリシーリスト ポリシーが無い場合、空リストを返却 */
  policyList: IntraPolicy[];
}

/** ポリシーリストの情報(取得時) */
export interface IntraPolicy extends CommonIntraPolicy {
  /** 送信元アドレスリスト 送信元アドレスが無い場合、空リスト */
  srcAddressList: AddressInfo[];
  /** 送信先アドレスリスト 送信先アドレスが無い場合、空リスト */
  dstAddressList: AddressInfo[];
  /** サービスリスト サービスが無い場合、空リスト */
  serviceList: ServiceInfo[];
  /** カスタムサービスリスト カスタムサービスが無い場合、空リスト */
  customServiceList: CustomServiceInfo[];
  /**
   * ポリシーカテゴリ情報
   * UNEDITABLE_DEFAULT_POLICY : 編集不可デフォルトポリシー
   * EDITABLE_DEFAULT_POLICY : 編集可能デフォルトポリシー
   * CUSTOM_POLICY : カスタムポリシー
   */
  policyCategoryInfo:
    | "UNEDITABLE_DEFAULT_POLICY"
    | "EDITABLE_DEFAULT_POLICY"
    | "CUSTOM_POLICY";
}

/** ポリシーリストの共通情報 */
interface CommonIntraPolicy {
  /** ポリシーオブジェクトID 主キー */
  policyObjectId: string | null;
  /** ポリシーID */
  policyId: string;
  /**
   * アクション
   * ALLOW：許可
   * DENY：拒否
   */
  actionType: "ALLOW" | "DENY";
  /**
   * ポリシー有効/無効フラグ
   * true ：有効
   * false：無効
   */
  isPolicyStatus: boolean;
  /**
   * ポリシーカテゴリ情報
   * UNEDITABLE_DEFAULT_POLICY : 編集不可デフォルトポリシー
   * EDITABLE_DEFAULT_POLICY : 編集可能デフォルトポリシー
   * CUSTOM_POLICY : カスタムポリシー
   */
  policyCategoryInfo:
    | "UNEDITABLE_DEFAULT_POLICY"
    | "EDITABLE_DEFAULT_POLICY"
    | "CUSTOM_POLICY";
  /** 設定値の説明 備考 */
  description: string | null;
}

/** アドレス情報 */
export interface AddressInfo {
  /** IPアドレスID アドレスオブジェクトの主キー "obj"+"数値"の形式 */
  ipAddressId: string | null;
  /** IPアドレス名
   * PFGWの場合、IPアドレス名の取得と設定は共に不可
   */
  ipAddressName: string | null;
  /** アドレス ネットワークアドレスを入力 */
  ipAddress: string | null;
}

/** サービスリストの共通情報 */
interface ServiceCommonInfo {
  /** プロトコル TCP : TCP UDP : UDP IP : IP ICMP : ICMP */
  protocol: "TCP" | "UDP" | "IP" | "ICMP";
  /** ポート番号. TCP、またはUDP選択時 Port番号は0～65535が指定可能 "-"で範囲、","複数指定可能 */
  portNumber: string | null;
  /** プロトコル番号. IP選択時 0～254の範囲で入力 */
  protocolNumber: number | null;
  /** ICMPタイプ ICMP選択時 0～255の範囲で入力 */
  icmpType: number | null;
  /** ICMPコード ICMP選択時 0～255の範囲で入力 */
  icmpCode: number | null;
}

/** デフォルトサービス情報 */
interface ServiceInfo extends ServiceCommonInfo {
  /** サービスSEQ. 主キー サービスAPIより取得 */
  serviceSeq: string;
  /** サービス名 */
  serviceName: string;
}

/** カスタムサービス情報 */
interface CustomServiceInfo extends ServiceCommonInfo {
  /** カスタムサービスSEQ 主キー カスタムサービスAPIより取得 */
  customServiceSeq: string;
  /** カスタムサービス名 */
  customServiceName: string;
}

/** ポリシー設定（イントラネットFW（PFGW含む））変更用インターフェイス */
export interface PostIntranetPolicy {
  /**
   * ポリシーリスト:プライベート→プライベート
   * プライベートゾーンからプライベートゾーンのゾーン関係IDが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FW_PFGWを指定した場合、空リストを設定
   */
  privateToPrivateList: PostZonePolicyInfo[];
  /**
   * ポリシーリスト:エクストラ→プライベート
   * エクストラゾーンからプライベートゾーンのゾーン関係IDが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FW_PFGWを指定した場合、空リストを設定
   */
  extraToPrivateList: PostZonePolicyInfo[];
  /**
   * ポリシーリスト:プライベート→エクストラ
   * プライベートゾーンからエクストラゾーンのゾーン関係IDが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FW_PFGWを指定した場合、空リストを設定
   */
  privateToExtraList: PostZonePolicyInfo[];
  /**
   * WVS2ゾーンからKCPSゾーンのポリシー
   * WVS2ゾーンからKCPSゾーンのポリシーが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FWを指定した場合、NULLを設定
   */
  wvs2ToKcps: PostZonePolicyInfo | null;
  /**
   * KCPSゾーンからWVS2ゾーンのポリシー
   * KCPSゾーンからWVS2ゾーンのポリシーが無い場合、
   * またはクエリパラメータのポリシー種別にINTRANET_FWを指定した場合、NULLを設定
   */
  kcpsToWvs2: PostZonePolicyInfo | null;
  /** 操作履歴の備考 */
  note: string | null;
  /**
   * イントラネットFW設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと
   */
  policyIntranetFwKeyId: string;
}

/** ゾーン内のポリシー情報(変更時) */
export interface PostZonePolicyInfo {
  /**
   * ゾーン関係ID
   * ゾーン間の連携を特定するキー
   * イントラネットFW：ゾーンAPIで取得したzoneRelationIdを設定
   * イントラネットFW PFGW：プラットフォームゲートウェイゾーンAPIで取得したzoneRelationIdを設定
   */
  zoneRelationId: string;
  /** ポリシーリスト ポリシーを削除する場合、空リストを設定 */
  policyList: PostIntraPolicy[];
}

/** ポリシー設定（イントラネットFW（PFGWを含む））変更の共通情報 */
interface PostIntraPolicy extends CommonIntraPolicy {
  /** 送信元アドレスリスト 送信元アドレスが無い場合、空リスト */
  srcAddressList: (string | null)[];
  /** 送信先アドレスリスト 送信先アドレスが無い場合、空リスト */
  dstAddressList: (string | null)[];
  /** サービスリスト サービスが無い場合、空リスト */
  serviceList: string[];
  /** カスタムサービスリスト カスタムサービスが無い場合、空リスト */
  customServiceList: string[];
}

/** ポリシーAPI(イントラネットFW・PFGW) クラス */
export default class PolicyIntranetFw extends AbstractBaseApi {
  /**
   * ポリシー(イントラネットFW・PFGW)情報の取得
   * @param policyType ポリシー種別. アプライアンスの種別を指定する。
   */
  async getPolicyIntranetFw(
    policyType: "INTRANET_FW" | "INTRANET_FW_PFGW"
  ): Promise<IntranetFwPolicy> {
    return (
      await this.api.get("/v1/network/policy/intranetfw", {
        params: { policyType },
      })
    ).data;
  }

  /**
   * ポリシー(イントラネットFW・PFGW)情報の変更
   * @param policyType ポリシー種別. アプライアンスの種別を指定する。
   * @param data ポリシー情報(イントラネットFW・PFGW)
   */
  async postPolicyIntranetFw(
    policyType: "INTRANET_FW",
    data: PostIntranetPolicy
  ): Promise<void>;
  /**
   * ポリシー(イントラネットFW・PFGW)情報の変更
   * @param policyType ポリシー種別. アプライアンスの種別を指定する。
   * @param enumberAct E番号(ACT). 主キー GETメソッドから取得したACT側のE番号 ポリシー種別でINTRANET_FW_PFGWを指定時必須
   * @param data ポリシー情報(イントラネットFW・PFGW)
   */
  async postPolicyIntranetFw(
    policyType: "INTRANET_FW_PFGW",
    enumberAct: string,
    data: PostIntranetPolicy
  ): Promise<void>;
  async postPolicyIntranetFw(
    policyType: "INTRANET_FW" | "INTRANET_FW_PFGW",
    dataOrEnumberAct: PostIntranetPolicy | string,
    data?: PostIntranetPolicy
  ): Promise<void> {
    if (typeof dataOrEnumberAct === "string") {
      await this.api.post("/v1/network/policy/intranetfw", data, {
        params: { policyType, enumberAct: dataOrEnumberAct },
      });
    } else {
      await this.api.post("/v1/network/policy/intranetfw", dataOrEnumberAct, {
        params: { policyType },
      });
    }
  }
}

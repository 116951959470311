



































































































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { GetPolicy, GetPolicyType4 } from "@/apis/NwPolicyApi";
import cloneDeep from "lodash/cloneDeep";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyIpPool from "@/components/policy/PolicyIpPool.vue";
import InternetType4PolicyRuleIpMasqEdit from "@/modals/applianceContractSetting/InternetType4PolicyRuleIpMasqEdit.vue";
import { Type4Service } from "@/apis/ContractChangeType4Api";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";

export type Type4IpMasqueradePolicy = NonNullable<
  GetPolicyType4["policyIPMasqueradeType4"]
>["policyList"][0];

export default Vue.extend({
  name: "InternetType4PolicyRuleIpMasqModify",
  components: { PolicyIpPool, PolicySrcAddress, PolicyDstAddress },
  props: {
    /** Type4Id. 主キー */
    type4Id: {
      type: String as PropType<string>,
      required: true,
    },
    /** ポリシーリスト */
    policyList: {
      type: Array as PropType<Type4IpMasqueradePolicy[]>,
      required: true,
    },
    /** type4ポリシー全体 */
    type4Policy: {
      type: Object as PropType<GetPolicyType4>,
      required: true,
    },
    /** サービスメニュー(インターネットType1) */
    type4Service: {
      type: Object as PropType<Type4Service>,
      required: true,
    },
  },
  data() {
    return {
      table: {
        fields: [
          { key: "_handle", label: "↑↓", tdClass: "handle" },
          { key: "policyId", label: "ID" },
          { key: "srcAddressList", label: "送信元アドレス" },
          { key: "dstAddressList", label: "宛先アドレス" },
          { key: "ipPoolList", label: "IP Pool" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
          { key: "delete", label: "" },
        ],
        items: cloneDeep(this.policyList),
      } as Pick<AppTableData<Type4IpMasqueradePolicy>, "fields" | "items">,
      natPolicyLimit: [] as UpperLimitEntity[],
      isLoaded: false,
    };
  },
  computed: {
    /** true: 作成できるポリシー数の上限, false: 上限ではない */
    limitPolicy(): boolean {
      // NAT側のカスタムポリシー数
      const totalPolicy =
        (this.latestType4Policy.policyIPMasqueradeType4?.policyList.filter(
          (e) => e.policyCategoryInfo === "CUSTOM_POLICY"
        ).length ?? 0) +
        (this.latestType4Policy.policyNatType4?.policyList.length ?? 0);
      return (
        totalPolicy >=
        this.natPolicyLimit!.filter(
          (e) => e.upperLimitItemName === "ポリシー数(NAT)"
        )[0]!.upperLimit
      );
    },
    /** 最新の全体ポリシー（全体ポリシー + 編集中のポリシー ）*/
    latestType4Policy(): GetPolicyType4 {
      return {
        ...this.type4Policy,
        policyIPMasqueradeType4: {
          ...this.type4Policy.policyIPMasqueradeType4!,
          policyList: this.table.items,
        },
      };
    },
  },
  async mounted() {
    /** NATの設定上限値を取得 */
    this.natPolicyLimit = (
      await this.$api.upperLimit.getUpperLimitLine({
        upperLimitLineManageUnit: "APPLIANCE",
        applianceType: "NAT",
        wnumber: this.type4Service.wnumberMainAct,
        type4Id: this.type4Service.type4Id,
      })
    ).internetLine!.upperLimitList!;
    this.isLoaded = true;
  },
  methods: {
    /** ポリシー追加 */
    async addPolicy() {
      const addPolicy: Type4IpMasqueradePolicy = await this.$modal.show(
        InternetType4PolicyRuleIpMasqEdit,
        {
          type4Id: this.type4Id,
          type4Policy: this.latestType4Policy,
        }
      );
      this.table.items.splice(this.table.items.length, 0, addPolicy);
    },

    /** ポリシー編集 */
    async editPolicy(policy: Type4IpMasqueradePolicy, index: number) {
      if (policy.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY") {
        return;
      }
      const editPolicy: Type4IpMasqueradePolicy = await this.$modal.show(
        InternetType4PolicyRuleIpMasqEdit,
        {
          type4Id: this.type4Id,
          policy,
          type4Policy: this.type4Policy,
        }
      );
      this.$set(this.table.items, index, editPolicy);
    },

    /** ポリシー削除 */
    async deletePolicy(index: number) {
      this.table.items.splice(index, 1);
    },

    /** ルール編集完了 */
    async submit() {
      await this.$confirm(`ポリシーを保存します。よろしいですか？`);
      (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(
        this.table.items
      );
    },

    /** ポリシーテーブルのtrクラス. */
    policyRowClass(
      item: Pick<GetPolicy, "policyCategoryInfo">
    ): string[] | null {
      switch (item.policyCategoryInfo) {
        case "CUSTOM_POLICY":
          // ドラッグ可能
          return ["draggable"];
        case "EDITABLE_DEFAULT_POLICY":
          // ドラッグ可能
          return ["draggable"];
        case "UNEDITABLE_DEFAULT_POLICY":
          // ドラッグ不可 + 背景色グレーアウト
          return ["not-editable-policy", "un-clickable"];
      }
    },
  },
});

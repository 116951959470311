import { AxiosResponse } from "axios";

import AbstractBaseApi from "./AbstractBaseApi";

/**
 * アクセス回線一覧のAPI用インターフェイス
 */
export interface AccessLineGetEntity {
  /** 一覧表示対象の総件数 */
  total: number;
  /** アクセス回線一覧情報
   * 取得データが存在しない場合（アクセス品目契約0件）には空配列を返却する。
   */
  bandwidthList: BandwidthListGetEntity[];
  /** 排他パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  bandwidthKeyId: string;
}

export interface BandwidthListGetEntity {
  /** アクセス回線SEQ 主キー */
  lineSeq: string;
  /** E番号(ACT) */
  enumberAct: string;
  /** E番号(Sby)
   * スタンバイ回線が不要なアクセス品目の場合、NULLを指定する。
   */
  enumberSby: string | null;
  /** メニュー
   * 以下のいずれかを指定する。
   * PF_ACCESS_GW:PFアクセスGW
   * EXTEND_ETHERNET:エクステンドイーサ
   * ETHERNET2:イーサネット方式Ⅱ
   * ETHERNET2_PF:イーサネット方式Ⅱ PF
   * BROADBAND_ACCESS3:ブロードバンドアクセス方式Ⅲ
   * ETHERNET_LIGHT:イーサネットライト
   * WIRELESS_ACCESS2:ワイヤレスアクセス方式Ⅱ
   * PF_ACCESS_GW2:PFアクセスGWⅡ
   * MULTI_CLOUD_GW:マルチクラウドGW
   */
  menu:
    | "PF_ACCESS_GW"
    | "EXTEND_ETHERNET"
    | "ETHERNET2"
    | "ETHERNET2_PF"
    | "BROADBAND_ACCESS3"
    | "ETHERNET_LIGHT"
    | "WIRELESS_ACCESS2"
    | "PF_ACCESS_GW2"
    | "MULTI_CLOUD_GW";
  /**
   * PFアクセスGWⅡ個別直収判定フラグ
   * メニューがPFアクセスGWⅡかつ個別直収である場合はtrueを設定する。それ以外の場合はfalseを設定する。
   */
  isPfagw2L2: boolean;
  /** UNO回線判定フラグ
   * true: UNO回線
   * false: UNO回線以外
   */
  isUno: boolean;
  /** 物理I/F
   * イーサネット方式II、イーサネット方式II PF、PFアクセスGWⅡ選択時、物理I/Fの値を返却する。
   * その他のアクセス種別を選択時、NULLを返却する。
   * IF_10M: 10M-IF
   * IF_100M: 100M-IF
   * IF_1G: 1G-IF
   * IF_10G: 10G-IF
   * IF_100G: 100G-IF
   */
  physicalInterface:
    | "IF_10M"
    | "IF_100M"
    | "IF_1G"
    | "IF_10G"
    | "IF_100G"
    | null;
  /** 帯域タイプ
   * 以下のいずれかを指定する。
   * BANDWIDTH_SECURED:帯域確保
   * TRAFFICFREE:トラフィックフリー
   * BURST10:バースト10
   * BURST100:バースト100
   * BESTEFFORT:ベストエフォート
   */
  bandwidthType:
    | "BANDWIDTH_SECURED"
    | "TRAFFICFREE"
    | "BURST10"
    | "BURST100"
    | "BESTEFFORT";
  /** 帯域固定
   * 以下のいずれかを指定する。
   * ON: 固定あり
   * OFF: 固定なし
   */
  fixingBandwidth: "ON" | "OFF" | null;
  /** 帯域値
   * 帯域タイプがバースト10、バースト100、ベストエフォートの場合、bandwidthはNULLとなる。
   */
  bandwidth: BandWidthEntity | null;
  /** QoSパスの有無
   * PFアクセスGWⅡ選択時、QoSパスの有無を返却する。その他のアクセス種別を選択時、NULLを返却する。
   * true: QoSパスあり
   * false: QoSパスなし
   */
  isQosPath: boolean | null;
  /** QoS
   * ON、OFFのいずれかを設定。
   * ON: 有
   * OFF: 無
   * 以下のメニューの場合、QoSは設定不可となり、NULLを指定する。
   * PFアクセスGW、エクステンドイーサ、ブロードバンドアクセス方式Ⅲ、
   * ワイヤレスアクセス方式Ⅱ、イーサネットライト、PFアクセスGWⅡ(QoSパスなし)、マルチクラウドGW
   */
  qos: "ON" | "OFF" | null;
  /** VLAN制御
   * ON、OFFのいずれかを設定
   * ON: ON
   * OFF: OFF
   * 以下のメニューの場合、VLAN制御機能は設定不可となり、NULLを指定する。
   * PFアクセスGW、エクステンドイーサ、PFアクセスGWⅡ、マルチクラウドGW
   */
  vlanControl: "ON" | "OFF" | null;
  /** 事業所名 */
  office: string | null;
  /** 契約者名 */
  contractor: string | null;
  /** エンドユーザ名 */
  endUserName: string | null;
  /** 住所 */
  address: string | null;
  /** ビル名 */
  building: string | null;
}

export interface BandWidthEntity {
  /** 設定値 */
  value: number;
  /** 単位
   * 以下のいずれかを設定。設定値が「0」の場合、NULLとなる。
   * KBPS: Kbps
   * MBPS: Mbps
   * GBPS: Gbps*/
  unit: "KBPS" | "MBPS" | "GBPS" | null;
}

/**
 * アクセス回線情報更新のAPI用インターフェイス
 */
export interface AccessLineUpdateEntity {
  /** アクセス回線更新情報 */
  bandwidthList: {
    /** アクセス回線SEQ 主キー */
    lineSeq: string;
    /** 帯域タイプ
     * 以下のいずれかを指定する。
     * BANDWIDTH_SECURED:帯域確保
     * TRAFFICFREE:トラフィックフリー
     * BURST10:バースト10
     * BURST100:バースト100
     * BESTEFFORT:ベストエフォート
     * メニューにより、設定可能な帯域タイプが異なる。設定可能な組み合わせは以下の通りとなる。
     * PFアクセスGW: 帯域確保
     * エクステンドイーサ: バースト10、バースト100、帯域確保
     * イーサネット方式Ⅱ UNO BEW: 帯域確保
     * イーサネット方式Ⅱ: 帯域確保、トラフィックフリー
     * イーサネット方式Ⅱ PF: 帯域確保
     * ブロードバンドアクセス方式Ⅲ、イーサネットライト: ベストエフォート
     * ワイヤレスアクセス方式Ⅱ: ベストエフォート
     * PFアクセスGWⅡ: 帯域確保
     * マルチクラウドGW: 帯域確保
     */
    bandwidthType: BandwidthListGetEntity["bandwidthType"];

    /** 帯域固定
     * 以下のいずれかを指定する。
     * ON: 固定あり
     * OFF: 固定なし
     * 設定可能な組み合わせは以下となる。
     * 法人ユーザ: OFF
     * 代行ログイン + アクセス品目「イーサネット方式Ⅱ」: ON/OFF
     * 代行ログイン + アクセス品目「イーサネット方式Ⅱ PF」: ON/OFF
     * 代行ログイン + その他のアクセス品目: OFF
     * 初期値がONとなるのは以下の組み合わせとなる。
     * イーサネット方式Ⅱ UNO BEW：10M, 100M
     * イーサネット方式Ⅱ、イーサネット方式Ⅱ PF：1G
     */
    fixingBandwidth: BandwidthListGetEntity["fixingBandwidth"];
    /** 帯域値 */
    bandwidth: BandWidthEntity | null;
    /** QoS
     * ON、OFFのいずれかを設定。
     * ON: 有
     * OFF: 無
     * 以下のメニューの場合、QoSは設定不可となり、NULLを指定する。
     * PFアクセスGW、エクステンドイーサ、ブロードバンドアクセス方式Ⅲ、
     * ワイヤレスアクセス方式Ⅱ、イーサネットライト、PFアクセスGWⅡ(QoSパスなし)、マルチクラウドGW
     */
    qos: BandwidthListGetEntity["qos"];
    /** VLAN制御
     * ON、OFFのいずれかを設定
     * ON: ON
     * OFF: OFF
     * 以下のメニューの場合、VLAN制御機能は設定不可となり、NULLを指定する。
     * PFアクセスGW、エクステンドイーサ、PFアクセスGWⅡ、マルチクラウドGW
     */
    vlanControl: BandwidthListGetEntity["vlanControl"];
  }[];
  /** オーダー備考 */
  note: string | null;
  /** 排他パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  bandwidthKeyId: string;
}

export default class AccessLineApi extends AbstractBaseApi {
  /**
   * アクセス回線の契約一覧情報を取得
   */
  async getAll(): Promise<AccessLineGetEntity> {
    return (await this.api.get("/v2/contract/accessitem/accesslines")).data;
  }

  /**
   * アクセス回線の契約情報を登録
   */
  async update(data: AccessLineUpdateEntity): Promise<AxiosResponse> {
    return (await this.api.post("/v2/contract/accessitem/accesslines", data))
      .data;
  }

  /**
   * アクセス回線の契約一覧情報のCSVを取得
   */
  getCsvURL(queries?: {
    /**
     * E番号
     * 全E番号を表示する場合には指定しない。
     */
    enumber?: string | null;
    /**
     * メニュー
     * 以下のいずれかを指定する。
     * PF_ACCESS_GW:PFアクセスGW
     * EXTEND_ETHERNET:エクステンドイーサ
     * ETHERNET2:イーサネット方式Ⅱ
     * ETHERNET2_PF:イーサネット方式Ⅱ PF
     * BROADBAND_ACCESS3:ブロードバンドアクセス方式Ⅲ
     * ETHERNET_LIGHT:イーサネットライト
     * WIRELESS_ACCESS2:ワイヤレスアクセス方式Ⅱ
     * PF_ACCESS_GW2:PFアクセスGWⅡ
     * MULTI_CLOUD_GW:マルチクラウドGW
     * ：全メニューを表示する場合には指定しない。
     */
    menu?: BandwidthListGetEntity["menu"] | null;
    /**
     * 帯域タイプ
     * 以下のいずれかを指定する。
     * BANDWIDTH_SECURED:帯域確保
     * TRAFFICFREE:トラフィックフリー
     * BURST10:バースト10
     * BURST100:バースト100
     * BESTEFFORT:ベストエフォート
     * 全帯域タイプを表示する場合には指定しない
     */
    bandwidthType?: BandwidthListGetEntity["bandwidthType"] | null;
    /**
     * 帯域固定
     * ON：固定あり
     * OFF：固定なし
     * 帯域固定ON/OFF両方を表示する場合には指定しない。
     */
    fixingBandwidth?: BandwidthListGetEntity["fixingBandwidth"];
    /**
     * QoS
     * ON: 有
     * OFF: 無
     * QoS ON/OFF両方を表示する場合には指定しない。
     */
    qos?: BandwidthListGetEntity["qos"];
    /**
     * VLAN制御
     * ON: ON
     * OFF：OFF
     * VLAN制御ON/OFF両方を表示する場合には指定しない。
     */
    vlanControl?: BandwidthListGetEntity["vlanControl"];
    /**
     * 事業所名
     * 全事業所名を表示する場合には指定しない。
     */
    office?: string | null;
  }): string {
    return this.getFullURL("/v1/contract/accessitem/accessline/csv", {
      params: {
        tokenId: this.store.state.user.token,
        ...queries,
      },
    });
  }
}

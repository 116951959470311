











































import Vue, { PropType } from "vue";
import { Type1PrivateZoneDelete, Type1Zone } from "@/apis/Type1SiteApi";
import { AppTableData } from "@/components/AppTable.vue";
import ZoneEdit from "@/modals/type1/ZoneEdit.vue";

export default Vue.extend({
  name: "PrivateZoneList",
  props: {
    privateZone: {
      type: [] as PropType<Type1Zone[]>,
      required: true,
    },
    /** 排他確認用パラメータ */
    type1SiteZoneKeyId: {
      type: String,
    },
  },
  data() {
    return {
      showAlert: false as boolean,
      zoneList: {
        fields: [
          {
            key: "zoneName",
            label: "ゾーン名",
          },
        ],
        items: [],
        selected: [],
      } as Pick<AppTableData<Type1Zone>, "fields" | "items" | "selected">,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    /** 初期化機能 */
    load() {
      this.zoneList.items = this.privateZone;
    },
    /** 追加機能 */
    async toAddZone() {
      this.showAlert = false;
      await this.$modal.show(ZoneEdit, {
        zoneMode: "privateRegister",
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 行選択機能 */
    async zoneSelected(item: Type1Zone) {
      this.showAlert = false;
      await this.$modal.show(ZoneEdit, {
        zoneMode: "privateModify",
        zoneDate: item,
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 削除機能 */
    async toDelete() {
      this.showAlert = this.zoneList.selected.length === 0;
      if (this.showAlert) return;

      await this.$confirm(
        `プライベートゾーンを${this.zoneList.selected.length}件削除します。よろしいですか？`
      );

      const reqData: Type1PrivateZoneDelete = {
        zoneIdList: this.zoneList.selected.map((e) => {
          return e.zoneId;
        }),
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      };
      await this.$api.type1SiteZone.postType1PrivateZoneDelete(reqData);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});








































































































































































































































































































import Vue, { PropType } from "vue";
import { VNConnectForm } from "@/modals/vnConnect/VNConnectEdit.vue";
import { QosDetail } from "@/apis/QosApi";
import { availablePairLine } from "@/modals/vnConnect/VNConnectEditStepper1.vue";

export default Vue.extend({
  name: "VNConnectEditStepper3",
  props: {
    /** true: 編集, false: 追加 */
    isEdit: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /** Form情報 */
    value: {
      type: Object as PropType<VNConnectForm>,
      required: true,
    },
    /** VPN/VNに紐づくQoS設定詳細 */
    qos: {
      type: Object as PropType<QosDetail>,
      required: true,
    },
  },
  data() {
    return {
      form: this.value,
    };
  },
  computed: {
    /** true: 帯域表示（品目が帯域確保、PF、TF）, false: 非表示 */
    isShowBandwidth(): boolean {
      switch (this.form.bandwidthType) {
        case "BANDWIDTH_SECURED":
        case "PF":
        case "TRAFFICFREE":
          return true;
        case "BURST":
        case "BESTEFFORT":
        default:
          return false;
      }
    },
    /** true: QoS設定を表示（契約変更が可能 & 回線のQoSがON & VPN/VNのQoS設定がON）, false: 非表示 */
    isShowQos(): boolean {
      return (
        this.form.siteInfo!.lineBandwidth?.qos === "ON" &&
        this.qos.qosType !== "NONE"
      );
    },
    /** true: ペア回線表示, false: 非表示 */
    isShowPairLine(): boolean {
      return availablePairLine[this.form.siteInfo!.accessType];
    },
  },
});


























































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import {
  DeleteType4ExtraSite,
  Type4ExtraSite,
  Type4ExtraSiteList,
  Type4InternetAddress,
} from "@/apis/Type4SiteApi";
import { ApplianceInfo } from "@/apis/ContractApi";
import Type4ExtraSiteEdit from "@/modals/type4/Type4ExtraSiteEdit.vue";
import Type4ExtraSiteConfirmModal from "@/modals/type4/Type4ExtraSiteConfirmModal.vue";

export default Vue.extend({
  name: "Type4ExtraSiteReference",
  props: {
    extraSite: {
      type: Object as PropType<Type4ExtraSiteList>,
      required: true,
    },
    type4Id: {
      type: String as PropType<ApplianceInfo["type4Id"]>,
      required: true,
    },
    type4SiteKeyId: {
      type: String as PropType<Type4ExtraSite["type4SiteKeyId"]>,
      required: true,
    },
  },
  data() {
    return {
      extraData: {
        fields: [
          {
            key: "ipAddressName",
            label: "アドレス名",
          },
          {
            key: "ipAddress",
            label: "アドレス",
          },
          {
            key: "isPolicyInUse",
            label: "ポリシー使用有無",
          },
        ],
        items: [],
      } as Pick<AppTableData<Type4InternetAddress>, "fields" | "items">,
    };
  },
  computed: {
    /** 遷移元がポリシー設定の場合は,true */
    isPolicy(): boolean {
      return this.$route.path === "/network/contract";
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    /** 初期化機能 */
    load() {
      this.extraData.items = this.extraSite.addressList;
    },
    /** 変更機能 */
    async toChange() {
      await this.$modal.show(Type4ExtraSiteEdit, {
        extraSite: this.extraSite,
        type4Id: this.type4Id,
        type4SiteKeyId: this.type4SiteKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 削除機能 */
    async toDelete() {
      await this.$modal.show(Type4ExtraSiteConfirmModal, {
        message: "エクストラサイトを削除します。よろしいですか？",
      });

      const reqData: DeleteType4ExtraSite = {
        siteList: [this.extraSite.siteId],
        type4SiteKeyId: this.type4SiteKeyId,
      };

      await this.$api.type4SiteApi.postType4ExtraSiteDelete(
        this.type4Id,
        reqData
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});






























































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { VNConnectForm } from "@/modals/vnConnect/VNConnectEdit.vue";
import QosSettingViewRC from "@/modals/qos/QosSettingViewRC.vue";
import { QosDetail } from "@/apis/QosApi";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";
import { Bgp4Routing } from "@/apis/VNConnectApi";

export default Vue.extend({
  name: "VNConnectEditStepper2",
  props: {
    /** true: 編集, false: 追加 */
    isEdit: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /** Form情報 */
    value: {
      type: Object as PropType<VNConnectForm>,
      required: true,
    },
    /** VPN/VNに紐づくQoS設定詳細 */
    qos: {
      type: Object as PropType<QosDetail>,
      required: true,
    },
    /**
     * 回線の設定上限値.
     * サイト未選択や回線情報のサービス開始日が未来日の場合はundefined
     */
    upperLimitList: {
      type: Array as PropType<UpperLimitEntity[]>,
      required: false,
    },
  },
  data() {
    return {
      form: this.value,
    };
  },
  computed: {
    /** true: 経路設定が必須, false: 非必須 */
    requiredStaticRouting(): boolean {
      if (this.form.siteInfo) {
        switch (this.form.siteInfo.accessType) {
          case "PLATFORM_GATEWAY":
          case "IPSEC":
            return true;
          default:
            return false;
        }
      } else {
        return false;
      }
    },
    /** MaximumPrefixの選択肢 */
    maximumPrefixOptions(): number[] {
      if (this.upperLimitList) {
        // 設定上限値以下のみにフィルター
        const bgp4Maximum = this.upperLimitList!.find(
          (e) => e.upperLimitItemName === "BGP4の最大経路数"
        )!;
        return [
          100, 200, 300, 400, 500, 1000, 1500, 2000, 3000, 4000, 5000,
        ].filter((e) => e <= bgp4Maximum.upperLimit);
      } else {
        // 設定上限値が取得できない場合（回線のサービス開始日が未来日）は100固定
        return [100];
      }
    },
    /** true: QoS設定を表示（契約変更が可能 & 回線のQoSがON & VPN/VNのQoS設定がON）, false: 非表示 */
    isShowQos(): boolean {
      return (
        this.form.siteInfo!.lineBandwidth?.qos === "ON" &&
        this.qos.qosType !== "NONE"
      );
    },
    /** 経路集約アドレスの重複チェック用リスト作成 */
    routeAddressList() {
      return (index: number) => {
        return this.form.l3!.bgp4!.routeAggregationAddressList
          ? this.form.l3!.bgp4!.routeAggregationAddressList.filter(
              (_, num) => num !== index
            )
          : [];
      };
    },
    /** 経路設定:LANアドレスの重複チェック用リスト作成 */
    lanAddressList() {
      return (index: number) => {
        return this.form.l3!.static
          ? this.form
              .l3!.static.filter((_, num) => num !== index)
              .map((e) => e.lanAddress)
          : [];
      };
    },
    /** true: AS番号が固定値（ラベル）, false: 入力 */
    isFixedAsNumber(): boolean {
      return this.form.siteInfo!.accessType === "PLATFORM_GATEWAY";
    },
  },
  watch: {
    /** 親からのForm情報更新を反映 */
    value(newValue: VNConnectForm) {
      this.form = newValue;
    },
    /** 親へForm情報更新を通知 */
    form: {
      deep: true,
      handler(newValue: VNConnectForm) {
        this.$emit("input", newValue);
      },
    },
    /** 経路集約がカスタムでアドレスリストがnullまたは空の場合は初期化 */
    "form.l3.bgp4.routeAggregation"(newValue: Bgp4Routing["routeAggregation"]) {
      if (
        newValue === "CUSTOM" &&
        (this.form.l3!.bgp4!.routeAggregationAddressList === null ||
          this.form.l3!.bgp4!.routeAggregationAddressList.length === 0)
      ) {
        this.form.l3!.bgp4!.routeAggregationAddressList = [""];
      }
    },
  },
  methods: {
    /** QoS設定情報表示 */
    async qosSettingInfo() {
      await this.$modal.show(QosSettingViewRC, {
        mode: "I",
        isNew: false,
        qosDetail: this.qos,
      });
    },

    /** LANアドレス追加 */
    addLAN() {
      this.form.l3!.static = [
        ...this.form.l3!.static!,
        { lanAddress: "", nextHopAddress: "" },
      ];
    },

    /** LANアドレス削除 */
    removeLAN(index: number) {
      const list = this.form.l3!.static!;
      if (list.length > 1) {
        this.form.l3!.static!.splice(index, 1);
      } else {
        this.form.l3!.static = [{ lanAddress: "", nextHopAddress: "" }];
      }
    },

    /** 経路集約アドレス削除 */
    removeRoute(index: number) {
      const list = this.form.l3!.bgp4!.routeAggregationAddressList!;
      if (list.length > 1) {
        this.form.l3!.bgp4!.routeAggregationAddressList!.splice(index, 1);
      } else {
        this.form.l3!.bgp4!.routeAggregationAddressList = [""];
      }
    },

    /** 経路集約アドレス追加 */
    addRoute() {
      this.form.l3!.bgp4!.routeAggregationAddressList = [
        ...this.form.l3!.bgp4!.routeAggregationAddressList!,
        "",
      ];
    },
  },
});

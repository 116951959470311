
















































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import AccessLineReference from "@/modals/accessLine/AccessLineReference.vue";
import AccessLineModify from "@/modals/accessLine/AccessLineModify.vue";
import {
  AccessLineGetEntity,
  BandwidthListGetEntity,
} from "@/apis/AccessLineApi";
import { SearchOption } from "@/components/AppSearch.vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { ContractListEntity } from "@/apis/ContractLineApi";

export default Vue.extend({
  name: "AccessLineList",
  data() {
    return {
      alertMsg: null as string | null,
      isLoaded: false,
      accessInfo: {} as AccessLineGetEntity,
      contractList: [] as ContractListEntity[],
      accessLine: {
        fields: [
          {
            key: "enumberAct",
            label: "回線番号",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (
              value: string,
              key: null,
              item: BandwidthListGetEntity
            ) => {
              if (item.menu === "PF_ACCESS_GW2" && item.enumberSby) {
                return `${item.enumberAct}\n${item.enumberSby}`;
              } else {
                return item.enumberAct;
              }
            },
          },
          {
            key: "menu",
            label: "メニュー",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) =>
              this.$filter.enumTo(value, "accessLineMenu"),
          },
          {
            key: "bandwidthType",
            label: "帯域タイプ",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string) =>
              this.$filter.enumTo(value, "accessLineBandwidthType"),
          },
          {
            key: "fixingBandwidth",
            label: "帯域固定",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (
              value: string,
              key: null,
              item: BandwidthListGetEntity
            ) => {
              if (item.menu === "ETHERNET2" || item.menu === "ETHERNET2_PF") {
                return value;
              } else {
                // メニューが「イーサネット方式Ⅱ」「イーサネット方式Ⅱ PF」でない場合
                return null;
              }
            },
          },
          {
            key: "bandwidth",
            label: "帯域値",
            sortable: true,
            sortByFormatted: true,
            formatter: (
              value: string,
              key: null,
              item: BandwidthListGetEntity
            ) => {
              if (item.bandwidthType === "BURST10") {
                return "バースト10";
              } else if (item.bandwidthType === "BURST100") {
                return "バースト100";
              } else if (item.bandwidthType === "BESTEFFORT") {
                return "-";
              } else if (item.bandwidth) {
                return this.$filter.bandwidthTo(item.bandwidth, "Mbps");
              } else {
                return "-";
              }
            },
          },
          {
            key: "qos",
            label: "QoS",
            sortable: true,
          },
          {
            key: "vlanControl",
            label: "VLAN制御",
            sortable: true,
          },
          {
            key: "office",
            label: "事業所名",
            sortable: true,
          },
        ],
        items: [],
        selected: [],
        search: {
          enumberAct: { type: "text", label: "回線番号" },
          menu: {
            type: "select",
            label: "メニュー",
            items: [
              {
                value: "PF_ACCESS_GW",
                text: "PFアクセスGW",
              },
              {
                value: "EXTEND_ETHERNET",
                text: "エクステンドイーサ",
              },
              {
                value: "ETHERNET2",
                text: "イーサネット方式Ⅱ",
              },
              {
                value: "ETHERNET2_PF",
                text: "イーサネット方式Ⅱ PF",
              },
              {
                value: "BROADBAND_ACCESS3",
                text: "ブロードバンドアクセス方式Ⅲ",
              },
              {
                value: "ETHERNET_LIGHT",
                text: "イーサネットライト",
              },
              {
                value: "WIRELESS_ACCESS2",
                text: "ワイヤレスアクセス方式Ⅱ",
              },
              {
                value: "PF_ACCESS_GW2",
                text: "PFアクセスGWⅡ",
              },
              {
                value: "MULTI_CLOUD_GW",
                text: "マルチクラウドGW",
              },
            ],
          },
          bandwidthType: {
            type: "select",
            label: "帯域タイプ",
            items: [
              {
                value: "帯域確保",
                text: "帯域確保",
              },
              {
                value: "トラフィックフリー",
                text: "トラフィックフリー",
              },
              {
                value: "バースト",
                text: "バースト",
              },
              {
                value: "ベストエフォート",
                text: "ベストエフォート",
              },
            ],
          },
          fixingBandwidth: {
            type: "select",
            label: "帯域固定",
            items: [
              { value: "ON", text: "ON" },
              { value: "OFF", text: "OFF" },
              { value: null, text: "-" },
            ],
          },
          qos: {
            type: "select",
            label: "QoS",
            items: [
              { value: "ON", text: "ON" },
              { value: "OFF", text: "OFF" },
              { value: null, text: "-" },
            ],
          },
          vlanControl: {
            type: "select",
            label: "VLAN制御",
            items: [
              { value: "ON", text: "ON" },
              { value: "OFF", text: "OFF" },
              { value: null, text: "-" },
            ],
          },
          office: { type: "text", label: "事業所名" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          enumberAct: "like",
          menu: "equal",
          bandwidthType: "equal",
          fixingBandwidth: "equal",
          qos: "equal",
          vlanControl: "equal",
          office: "like",
        },
      } as AppTableData<BandwidthListGetEntity>,
      downloadUrl: this.$api.accessLine.getCsvURL(),
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("ADMIN");
    },
    /** 法人ユーザーの場合、true */
    isNormalUser(): boolean {
      return this.$service.permission.isNormalUser();
    },
    /** 一覧の検索項目 */
    searchOptions(): SearchOption<BandwidthListGetEntity> {
      if (this.isNormalUser) {
        /** 法人権限で帯域固定非表示処理 */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fixingBandwidth, ...newSearchOptions } = this.accessLine.search;
        return newSearchOptions;
      }
      return this.accessLine.search;
    },
    /** 一覧の表示項目 */
    displayFields(): BvTableFieldArray {
      if (this.isNormalUser) {
        /** 法人権限で帯域固定非表示処理 */
        return this.accessLine.fields.filter(
          (v) => (v as { key: string }).key !== "fixingBandwidth"
        );
      }
      return this.accessLine.fields;
    },
  },
  methods: {
    /** 一覧を取得 */
    async load() {
      this.alertMsg = null;
      this.accessInfo = await this.$api.accessLine.getAll();
      this.accessLine.items = this.accessInfo.bandwidthList.map((e) => {
        e.bandwidth =
          e.bandwidth && e.bandwidth.unit === "KBPS"
            ? this.$service.bandwidth.convertUnit(e.bandwidth, "MBPS")
            : e.bandwidth;
        return e;
      });
      //契約回線情報取得APIを呼ぶ
      this.contractList = (await this.$api.contractLine.getAll()).contractList;
    },
    /** アクセス回線変更画面表示 */
    async showModificationModal() {
      if (this.accessLine.selected.length > 0) {
        this.alertMsg = null;
        await this.$modal.show(AccessLineModify, {
          accessList: this.accessLine.selected,
          bandwidthKeyId: this.accessInfo.bandwidthKeyId,
        });
        await this.load();
      } else {
        this.alertMsg = this.$msg("no_select_data");
      }
    },
    /** アクセス回線情報画面表示 */
    async showDetails(item: BandwidthListGetEntity) {
      this.alertMsg = null;
      await this.$modal.show(AccessLineReference, {
        accessLineItem: item,
        // 契約リストに存在するenumberはサービス開始済とする
        isServiceStarted: this.contractList.some(
          (v) => v.enumber === item.enumberAct
        ),
      });
    },
  },
});

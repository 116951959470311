











































import Vue, { PropType } from "vue";

/**
 * お手製Stepper
 * slot
 * ・icon(${ステップ番号}): ステップアイコン
 * ・title(${ステップ番号}): ステップタイトル
 * ・content(${ステップ番号}): ステップの内容
 *
 * content slotではprevとnextがslotプロパティとして渡されるのでそれで移動することが出来ます
 */
export default Vue.extend({
  name: "AppStepper",
  props: {
    /** ステッパーの数 */
    size: {
      type: Number as PropType<number>,
      required: true,
      validator: (value: number) => value > 0,
    },
    /** タイトル一覧 */
    titles: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    /** 表示位置 */
    value: {
      type: Number as PropType<number>,
      default: 1,
    },
  },
  data() {
    return {
      /** 現在表示中のステッパー番号 */
      active: this.value,
      /** true: nextトランジション, false: prevトランジション */
      forward: true,
    };
  },
  methods: {
    /** 次のステッパーに移動 */
    next() {
      if (this.active < this.size) {
        this.forward = true;
        this.active++;
        // 親にアクティブページを伝える
        this.$emit("activePage", this.active);
        // 上記非推奨。表示位置をvalueにしてるのでinputが正道（propの更新見てないけど）
        this.$emit("input", this.active);
      }
    },
    /** 前のステッパーに移動 */
    prev() {
      if (this.active > 1) {
        this.forward = false;
        this.active--;
        // 親にアクティブページを伝える
        this.$emit("activePage", this.active);
        // 上記非推奨。表示位置をvalueにしてるのでinputが正道（propの更新見てないけど）
        this.$emit("input", this.active);
      }
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** VLAN IDのType指定
 * アクセス回線から,サイトを選択してから作成する.
 * VLAN ID TYPE指定は選択したサイト情報に紐づく
 * UNTAG: IDの指定なし,
 * OTHER: 他のVNコネクトで指定されていないID,もしくは指定なし
 * SPECIFIED: 指定ID
 * */
export type VlanType = "UNTAG" | "OTHER" | "SPECIFIED";

/** IF状態
 * VNコネクト有効/無効フラグが無効の時にNULLになる.
 * NO_SHUTDOWN: 開放状態
 * SHUTDOWN: 閉塞状態
 * */
export type IfStatus = "NO_SHUTDOWN" | "SHUTDOWN" | null;

/** 仮想ネットワークタイプ
 * L2:L2回線
 * L3:L3回線
 * */
export type VnType = "L2" | "L3";

/**
 * QoS帯域制御パターン
 * PATTERN_A～PATTERN_Eを設定の場合は帯域制御になる.
 * PATTERN_A: パターンA
 * PATTERN_B: パターンB
 * PATTERN_C: パターンC
 * PATTERN_D: パターンD
 * PATTERN_E: パターンE
 * */
export type ControlPattern =
  | "PATTERN_A"
  | "PATTERN_B"
  | "PATTERN_C"
  | "PATTERN_D"
  | "PATTERN_E";

/** 帯域タイプ
 * @todo enumの区分値が変更される可能性があるため本実装時に要確認
 * 下記の帯域区分になる.
 * BURST:バースト
 * BANDWIDTH_SECURED:帯域確保
 * TRAFFICFREE:トラフィックフリー
 * PF:PF
 * BESTEFFORT:ベストエフォート
 *
 * 回線のアクセス回線種別によって、設定可能な帯域区分は以下になる.
 * 1.アクセス回線種別が「イーサネット方式(L2モード)」:BURST
 * 2.アクセス回線種別が「イーサネット方式(L3モード)」:BURST
 * 3.アクセス回線種別が「ブロードバンドアクセス方式Ⅱ(L2モード)」:BURST
 * 4.アクセス回線種別が「ブロードバンドアクセス方式Ⅱ(L3モード)」:BURST
 * 5.アクセス回線種別が「ワイヤレスアクセス方式(L2モード)」:BURST
 * 6.アクセス回線種別が「ワイヤレスアクセス方式(L3モード)」:BURST
 * 7.アクセス回線種別が「PFGW」:BURST
 * 8.アクセス回線種別が「IPsec方式」:BURST
 * 9.アクセス回線種別が「イーサネット方式Ⅱ(TF有り)」:TRAFFICFREE/BANDWIDTH_SECURED
 * 10.アクセス回線種別が「イーサネット方式Ⅱ (TF無し)」:BANDWIDTH_SECURED
 * 10.アクセス回線種別が「イーサネット方式Ⅱ (UNO)」:BANDWIDTH_SECURED
 * 10.アクセス回線種別が「イーサネット方式Ⅱ PF」:PF/BANDWIDTH_SECURED
 * 10.アクセス回線種別が「イーサネット方式Ⅱ PF(UNO)」:PF/BANDWIDTH_SECURED
 * 11.アクセス回線種別が「ブロードバンドアクセス方式Ⅲ」:BESTEFFORT
 * 12.アクセス回線種別が「ワイヤレスアクセス方式Ⅱ」:BESTEFFORT
 * 13.アクセス回線種別が「イーサネットライト」:BESTEFFORT
 * 14.アクセス回線種別が「PFアクセスGWⅡ」:PF/BANDWIDTH_SECURED
 * 15.アクセス回線種別が「エクステンドイーサネット方式」:BURST/BANDWIDTH_SECURED
 * 16.アクセス回線種別が「PFアクセスGW」:BURST/BANDWIDTH_SECURED
 * その際、VNコネクトを作成する回線の情報はアクセス品目APIのGETから取得する.
 * VNコネクトを作成する回線の帯域タイプ:アクセス品目API.GET.accessType
 * UNO回線判定フラグ:アクセス品目API.GET.isUno
 * なお,TF有り,TF無し,UNOに関しては上記VNコネクトを作成する回線の情報から判定する.
 * */
export type BandwidthType =
  | "BURST"
  | "BANDWIDTH_SECURED"
  | "TRAFFICFREE"
  | "PF"
  | "BESTEFFORT";

/** 帯域
 * 帯域タイプがBURSTの場合は、valueに10または100が入力され,unitはnullとなる.
 * BESTEFFORTが選択された場合,bandwidthはnullとなる.
 */
export interface Bandwidth {
  /**
   * 設定値
   * 帯域タイプがBURSTとBESTEFFORT以外の場合,有効値が入る.
   * @todo ０を含むかどうかは要確認
   */
  value: number;
  /**
   * 単位
   * 以下のいずれかを設定する.
   * KBPS: Kbps
   * MBPS: Mbps
   * GBPS: Gbps
   */
  unit: "KBPS" | "MBPS" | "GBPS" | null;
}

/**
 * QoS設定
 * 設定対象のVPN/VNコードのQoS設定がOFFまたは,ONでQoS方式未設定の場合nullになる.
 * QoS設定がONで絶対優先の場合は,isQoSをtureに設定し,qosBandControlPatternをnullにする.
 * QoS設定がONで帯域優先の他場合は,isQoSをtureに設定し,qosBandControlPatternを合わせて設定する.
 */
export interface QoS {
  /**
   * QoS設定フラグ
   * true: ON
   * false: OFF
   */
  isQoS: boolean;
  /**
   * QoS帯域制御パターン
   * PATTERN_A～PATTERN_Eを設定の場合は帯域制御になる.
   * PATTERN_A: パターンA
   * PATTERN_B: パターンB
   * PATTERN_C: パターンC
   * PATTERN_D: パターンD
   * PATTERN_E: パターンE
   * NULLを設定:優先制御(条件：QoS設定フラグがtrueの場合かつqosBandControlPatternの値がNULLの場合にVPN/VNコードに紐づいたQoS設定(優先制御)になる)
   */
  qosBandControlPattern: ControlPattern | null;
}

/**
 * L2回線
 * L3回線選択の場合NULLになる
 * */
interface L2Config {
  /**
   * VLAN制御機能設定フラグ
   * L2回線選択時,必須項目
   * true: ON
   * false: OFF
   */
  isVlanControl: boolean;
}

/**
 * L3VN_STATIC
 * BGP4の場合,または「LANアドレス」と「ネクストホップアドレス」を登録しない場合は空配列になる.
 * */
interface StaticRouting {
  /**
   * LANアドレス
   * L3回線選択かつSTATIC選択時,選択可能項目
   * 最大5回線まで登録可能で「ネクストホップアドレス」が選択されている場合必須項目になる
   */
  lanAddress: string;
  /**
   * ネクストホップアドレス
   * L3回線選択かつSTATIC選択時,選択可能項目
   * 最大5回線まで登録可能で「LANアドレス」が選択されている場合必須項目になる
   */
  nextHopAddress: string;
}

/**
 * 優先度
 * HIGH_PRIORITY:優先
 * LOW_PRIORITY:非優先
 */
export type Priority = "HIGH_PRIORITY" | "LOW_PRIORITY";

/**
 * 経路集約
 * L3回線選択時,必須項目
 * OFF:経路集約を非表示
 * ON:経路集約を表示
 * CUSTOM:回線種別「PFアクセスGWⅡ」の場合のみ選択
 */
export type RouteAggregation = "ON" | "OFF" | "CUSTOM";

/** L3VN_BGP4
 * ルーティング種別が「STATIC」選択の場合NULLになる
 * */
export interface Bgp4Routing {
  /**
   * 経路集約
   * L3回線選択時,必須項目
   * OFF:経路集約を非表示
   * ON:経路集約を表示
   * CUSTOM:回線種別「PFアクセスGWⅡ」の場合のみ選択
   */
  routeAggregation: RouteAggregation;
  /**
   * 経路集約アドレスリスト
   * L3回線選択時かつ,BGP4選択時かつ,経路集約が「CUSTOM」選択の場合,必須項目
   */
  routeAggregationAddressList: string[] | null;
  /**
   * ピアIPアドレス
   * L3回線選択かつBGP4選択時,必須項目
   */
  peerIpAddress: string;
  /**
   * AS番号
   * L3回線選択かつBGP4選択時,必須項目
   */
  asNumber: string;
  /**
   * MD5キー
   * L3回線選択かつBGP4選択時,入力可能項目ない場合はNULLになる
   */
  md5Key: string | null;
  /**
   * Maximum Prefix上限(設定値条件取得APIのBGP4の最大経路数)
   * L3回線選択かつBGP4選択時,選択可能項目
   * オプション契約毎設定上限値情報APIのGETから上限値を取得し、その上限値以下の値を設定可能とする.
   * 値は100,200,300,400,500,1000,1500,2000から指定する.
   */
  maximumPrefix: number;
  /**
   * MED(MultiExitDisc)優先設定
   * L3回線選択かつ,BGP4選択時,必須項目
   * HIGH_PRIORITY:優先
   * LOW_PRIORITY:非優先
   */
  med: Priority;
  /**
   * ローカルプリファレンス優先設定
   * L3回線選択かつ,BGP4選択時,必須項目
   * HIGH_PRIORITY:優先
   * LOW_PRIORITY:非優先
   */
  localPreference: Priority;
}

/**
 * DHCPリレー機能
 * DHCPリレー機能がOFFの場合,NULLになる
 */
interface DhcpRelay {
  /**
   * プライマリサーバ
   * L3回線選択時,DHCPリレー機能がONの場合必須項目
   */
  primaryAddress: string;
  /**
   * セカンダリサーバ
   * L3回線選択時,DHCPリレー機能がONの場合登録可能項目
   * プライマリサーバ単体の登録はできるが,セカンダリサーバ単体の登録は不可となる.
   */
  secondaryAddress: string | null;
}

/**
 * L3回線
 * L2回線選択の場合NULLになる
 * */
export interface L3Config {
  /**
   * WANアドレス
   * L3回線選択時,必須項目
   */
  wanAddress: string;
  /**
   * L3VN_STATIC
   * BGP4の場合,または「LANアドレス」と「ネクストホップアドレス」を登録しない場合は空配列になる
   */
  static: StaticRouting[] | null;
  /**
   * L3VN_BGP4
   * ルーティング種別が「STATIC」選択の場合NULLになる
   */
  bgp4: Bgp4Routing | null;
  /**
   * DHCPリレー機能
   * DHCPリレー機能がOFFの場合,NULLになる
   */
  dhcpRelay: DhcpRelay | null;
}

export interface VNConnectsResponse {
  /** VNコネクトの総件数 */
  total: number;
  /** VNコネクトリスト
   * VNコネクトの情報がない場合空配列になる.
   * */
  vnConnectList: VNConnect[];
  /** VNコネクト設定排他情報 */
  vnConnectKeyId: string;
}

export interface VNConnect {
  /** W番号
   * 主キー相当
   * */
  wnumber: string;
  /** VNコネクト名 */
  vnConnectName: string;
  /**
   * E番号.
   * PFGW（インターネット）の場合nullとなる。
   * PFGW（インターネット）は現状ユーザーからは作成不可、変更削除出来なくて良い
   */
  enumber: string | null;
  /** V番連携フラグ */
  isAssociate: boolean;
  /** VLAN IDのType指定 */
  vlanIdType: VlanType;
  /** VLAN ID
   * vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに必須項目.
   * */
  vlanId: number | null;
  /** 仮想ネットワーク名 */
  vnName: string | null;
  /** VPN/VNコード */
  vpnVnCode: string;
  /** 仮想ネットワークタイプ */
  vnType: VnType | null;
  /** サイト名（旧カスコン設定の場合にサイト名が空文字？になるケースがある） */
  siteName: string | null;
  /** IF状態 */
  ifStatus: IfStatus;
  /** VNコネクト有効/無効フラグ
   * true:有効
   * false:無効
   * */
  isVnConnectStatus: boolean;
}

export interface CreateVNConnectRequest {
  /**
   * サイトID
   * サイト名特定するために必要
   * "obj"+"数値"の形式
   * Type1プライベートサイトゾーン取得APIのGETより取得
   *
   * VNコネクト作成時のサイト情報はType1プライベートサイトゾーン取得APIのGETから取得する.
   * E番号:Type1プライベートサイトゾーン取得API.GET.enumber
   * サイト名:Type1プライベートサイトゾーン取得API.GET.siteName
   * vlanIdType:Type1プライベートサイトゾーン取得API.GET.vlanIdType
   * vlanId:Type1プライベートサイトゾーン取得API.GET.vlanId
   */
  siteId: string;
  /**
   * 接続先VPN/VNコード
   * VNコードはVPN/VNコード取得APIのGETより取得
   *
   * VNコネクト作成時の接続先ネットワーク(VPN/VN)名はVPN/VNコード取得APIのGETから作成する.
   * VN名:VPN/VNコード取得API.GET.vnName
   * VNコード:VPN/VNコード取得API.GET.vpnVnCode
   * VN回線モード:VPN/VNコード取得.GET.vnType
   */
  vpnVnCode: string;
  /** VNコネクト名 */
  vnConnectName: string;
  /** ペア回線
   * VNコネクトAPIのGETよりW番号のリストを取得
   * ペア回線がなければNULLを設定
   * */
  pairLine: string | null;
  /** 帯域タイプ */
  bandwidthType: BandwidthType;
  /** 帯域 */
  bandwidth: Bandwidth | null;
  /**
   * QoS設定
   * 設定対象のVPN/VNコードのQoS設定がOFFまたは,ONでQoS方式未設定の場合nullになる.
   * QoS設定がONで絶対優先の場合は,isQoSをtureに設定し,qosBandControlPatternをnullにする.
   * QoS設定がONで帯域優先の他場合は,isQoSをtureに設定し,qosBandControlPatternを合わせて設定する.
   */
  qos: QoS | null;
  /**
   * L2回線
   * L3回線選択の場合NULLになる.
   */
  l2: L2Config | null;
  /**
   * L3回線
   * L2回線選択の場合NULLになる.
   */
  l3: L3Config | null;
  /** 備考 */
  description: string | null;
  /**
   * VNコネクト設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと.
   * */
  vnConnectKeyId: string;
  /** オーダー備考 */
  note?: string;
}

export type UpdateVNConnectRequest = Omit<
  CreateVNConnectRequest,
  "siteId" | "vpnVnCode" | "pairLine" | "l2" | "l3"
> & { l3: Omit<L3Config, "wanAddress"> | null };

export interface VNConnectDetail {
  /** VNコネクト名 */
  vnConnectName: string;
  /**
   * E番号.
   * PFGW（インターネット）の場合nullとなる。
   * PFGW（インターネット）は現状ユーザーからは作成不可、変更削除出来なくて良い
   */
  enumber: string | null;
  /** VLAN IDのType指定 */
  vlanIdType: VlanType;
  /** VLAN ID */
  vlanId: number | null;
  /** 帯域タイプ */
  bandwidthType: BandwidthType;
  /** 帯域 */
  bandwidth: Bandwidth | null;
  /** ペア回線
   * ペア回線がなければNULLを設定
   * */
  pairLine: string | null;
  /** IF状態 */
  ifStatus: IfStatus;
  /** VNコネクト有効/無効フラグ
   * true:有効
   * false:無効
   * */
  isVnConnectStatus: true;
  /** 備考 */
  description: string | null;
  /** サイト名（旧カスコン設定の場合にサイト名がnullになるケースがある） */
  siteName: string | null;
  /** VPN/VNコード */
  vpnVnCode: string;
  /** 仮想ネットワーク名 */
  vnName: string | null;
  /** 仮想ネットワークタイプ */
  vnType: VnType | null;
  /** V番連携フラグ */
  isAssociate: boolean;
  /** QoS設定
   * 設定対象のVPN/VNコードのQoS設定がOFFまたは,ONでQoS方式未設定の場合nullになる.
   * QoS設定がONで絶対優先の場合は,isQoSをtureに設定し,qosBandControlPatternをnullにする.
   * QoS設定がONで帯域優先の他場合は,isQoSをtureに設定し,qosBandControlPatternを合わせて設定する.
   * */
  qos: QoS | null;
  /** L2回線
   * L3回線選択の場合NULLになる.
   * */
  l2: L2Config | null;
  /** L3回線
   * L2回線選択の場合NULLになる.
   * */
  l3: L3Config | null;
  /** VNコネクト設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと.
   * */
  vnConnectKeyId: string;
}

export interface VNConnectOrderRequest {
  /**
   * W番号のリスト
   * 主キー相当.
   */
  orderList: string[];
  /**
   * VNコネクト設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと.
   */
  vnConnectKeyId: string;
}

export interface VNConnectKeyRequest {
  /**
   * VNコネクト設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと.
   */
  vnConnectKeyId: string;
}

export type CsvCheckSuccessResponse = Omit<
  VNConnectDetail,
  | "siteName"
  | "pairLine"
  | "ifStatus"
  | "isVnConnectStatus"
  | "vnName"
  | "vnType"
  | "isAssociate"
  | "vnConnectKeyId"
> & { l3: { bgp4: Omit<Bgp4Routing, "maximumPrefix"> } } & {
  siteName: string | null;
};

/** 登録チェック成功時レスポンス */
export interface CsvSuccess {
  total: number;
  /** CSV登録チェック成功時、レスポンスVNコネクト情報 */
  vnConnectCsvList: CsvCheckSuccessResponse[];
  /** VNコネクトCSV設定排他情報 */
  vnConnectCsvKeyId: string;
}

export interface CheckError {
  /** 行番号 */
  rowNumber: number;
  /** 登録チェックエラーメッセージ */
  checkErrorMessageList: string[];
}

/** 登録チェックエラー時レスポンス */
export interface CsvError {
  checkErrorList: CheckError[];
}

export interface VNConnectCsvCheck {
  /** 登録チェックがエラーの場合はNULLになる */
  success: CsvSuccess | null;
  /** 登録チェックが成功の場合はNULLになる */
  error: CsvError | null;
}

export interface VNConnectCsvRegister {
  /** CSV登録するVNコネクト情報 */
  vnConnectCsvList: CsvCheckSuccessResponse[];
  /** VNコネクトCSV設定排他情報 */
  vnConnectCsvKeyId: string;
}

/** VNコネクト系API */
export default class VNConnectApi2 extends AbstractBaseApi {
  /**
   * VNコネクトを一覧取得
   */
  async getVNConnects(): Promise<VNConnectsResponse> {
    return (await this.api.get("/v1/network/vnetwork/vnconnects")).data;
  }

  /**
   * VNコネクトを追加
   * @param data VNコネクト設定情報
   */
  async createVNConnect(data: CreateVNConnectRequest): Promise<void> {
    await this.api.post("/v1/network/vnetwork/vnconnect", data);
  }

  /**
   * VNコネクト情報詳細を取得
   * @param wnumber 詳細取得対象のW番号
   */
  async getVNConnectDetail(wnumber: string): Promise<VNConnectDetail> {
    return (await this.api.get(`/v1/network/vnetwork/vnconnect/${wnumber}`))
      .data;
  }

  /**
   * VNコネクトの詳細内容を変更
   * @param wnumber 変更対象のW番号
   * @param data VNコネクト設定情報
   */
  async updateVNConnect(
    wnumber: string,
    data: UpdateVNConnectRequest
  ): Promise<void> {
    await this.api.post(`/v1/network/vnetwork/vnconnect/${wnumber}`, data);
  }

  /**
   * VNコネクトの有効無効状態を有効に変更
   * ※有効無効状態の変更件数上限は10件まで
   * @param data VNコネクトを有効にしたいW番号リスト
   */
  async postVNConnectEnable(data: VNConnectOrderRequest): Promise<void> {
    await this.api.post("/v1/network/vnetwork/vnconnect/enable", data);
  }

  /**
   * VNコネクトの有効無効状態を無効に変更
   * ※有効無効状態の変更件数上限は10件まで
   * @param data VNコネクトを無効にしたいW番号リスト
   */
  async postVNConnectDisable(data: VNConnectOrderRequest): Promise<void> {
    await this.api.post("/v1/network/vnetwork/vnconnect/disable", data);
  }

  /**
   * VNコネクトを削除
   * @param wnumber VNコネクトを削除したいW番号
   * @param data VNコネクト設定排他情報
   */
  async deleteVNConnect(
    wnumber: string,
    data: VNConnectKeyRequest
  ): Promise<void> {
    await this.api.post(
      `/v1/network/vnetwork/vnconnect/${wnumber}/delete`,
      data
    );
  }

  /**
   * VNコネクト一覧のCSV取得用のURLを返却
   */
  getVnconnectCsvUrl(): string {
    return this.getFullURL("/v1/network/vnetwork/vnconnects/csv", {
      params: { tokenId: this.store.state.user.token },
    });
  }

  /** VNコネクト一括登録情報の登録チェックAPI呼び出し定義処理
   * ファイル種別
   * L2VN : L2回線
   * L3VN_STATIC : L3回線STATIC選択
   * L3VN_BGP4 : L3回線BGP4選択
   * @param fileType
   * @param filename ファイル
   * @param textCsv CSVファイルのテキスト（UTF-8）
   */
  async checkVNConnectCsv(
    fileType: "L2VN" | "L3VN_STATIC" | "L3VN_BGP4",
    filename: string,
    textCsv: string
  ): Promise<VNConnectCsvCheck> {
    return (
      await this.api.post("/v1/network/vnetwork/vnconnect-csv/check", textCsv, {
        headers: {
          "Content-Type": "text/csv",
        },
        params: {
          fileType: fileType,
          fileName: filename,
        },
      })
    ).data;
  }

  /**
   * VNコネクト一括登録情報の登録
   * @param data VNコネクトCSV情報
   */
  async registerVNConnectCsv(data: VNConnectCsvRegister): Promise<void> {
    await this.api.post("/v1/network/vnetwork/vnconnect-csv/register", data);
  }
}

import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import * as custom_rules from "@/validators/index";
import ja from "@/config/validate/ja.json";
import { PluginObject } from "vue/types/plugin";
import { Vue as _Vue } from "vue/types/vue";
import CrossValidationObserver from "@/components/CrossValidationObserver.vue";
import CrossValidationProvider from "@/components/CrossValidationProvider.vue";

const VeeValidationPlugin: PluginObject<undefined> = {
  install(Vue: typeof _Vue): void {
    // https://vee-validate.logaretm.com/v3/

    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);

    Vue.component("CrossValidationProvider", CrossValidationProvider);
    Vue.component("CrossValidationObserver", CrossValidationObserver);

    const messageKeys = Object.keys(ja.messages);
    for (const [rule, validation] of Object.entries(rules)) {
      if (messageKeys.includes(rule)) {
        extend(rule, { ...validation });
      } else {
        extend(rule, { ...validation, message: "有効な値ではありません" });
      }
    }

    for (const [rule, validation] of Object.entries(custom_rules)) {
      if (messageKeys.includes(rule)) {
        extend(rule, { ...validation });
      } else {
        extend(rule, { ...validation, message: "有効な値ではありません" });
      }
    }

    localize({
      ja,
    });
    localize("ja");
  },
};

export default VeeValidationPlugin;

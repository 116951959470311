/** 共通エラークラス */
import { AxiosError } from "axios";

class BaseError extends Error {
  constructor(e?: string) {
    super(e);
    this.name = new.target.name;
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/** APIリクエストでバックから4xx,5xx系のレスポンスが返却された場合のエラー */
export class ApiRequestError extends BaseError {
  /** APIレスポンスボディ */
  readonly data: {
    /** エラーコード */
    errorCode: ErrorCode;
    /** エラーメッセージ */
    errorMessage: string;
  };

  constructor(readonly axiosError: AxiosError) {
    super(axiosError.message);
    if (axiosError.response!.data instanceof ArrayBuffer) {
      const array = new Uint8Array(axiosError.response!.data);
      this.data = JSON.parse(new TextDecoder().decode(array));
    } else {
      this.data = axiosError.response!.data;
    }
  }
}

/** 4xxが返却された場合のエラー */
export class ApiClientError extends ApiRequestError {}

/** 400エラー（リクエストが正しくない） */
export class ApiBadRequestError extends ApiClientError {}

/** 401エラー（認証が必要） */
export class ApiUnauthorizedError extends ApiClientError {}

/** 403エラー（アクセスが禁止されている） */
export class ApiForbiddenError extends ApiClientError {}

/** 404エラー（指定したリソースが見つからない） */
export class ApiNotFoundError extends ApiClientError {}

/** 405エラー（許可されていないメソッド） */
export class ApiMethodNotAllowedError extends ApiClientError {}

/** 407エラー（ネットワークエラー） */
export class NetworkError extends Error {}

/** 408, 504エラー（リクエストタイムアウト） */
export class ApiRequestTimeoutError extends ApiClientError {}

/** 409エラー（排他制御エラー） */
export class ApiConflictError extends ApiClientError {}

/** 5xxが返却された場合のエラー */
export class ApiServerError extends ApiRequestError {}

/** 500エラー（サーバ側で予期しないエラーが発生した） */
export class ApiInternalServerError extends ApiServerError {}

/** 502エラー（サーバが上流のサーバから無効なレスポンスを受け取った） */
export class ApiBadGatewayError extends ApiServerError {}

/** 503エラー（サーバは一時的な過負荷あるいはメンテナンスのため現在リクエストできない） */
export class ApiServiceUnavailableError extends ApiServerError {}

/** 想定外エラー */
export class SystemError extends Error {}

/**
 * APIエラーコード
 * ・A111001W - 多重ログインエラー
 * ・CC01004W - パスワード有効期限超過
 * ・CC99009W - 現在業務規制中
 * ・AZZZ001W - 現在サービス規制中
 * ・ARCC001W - 現在サービス規制中(RACCO)
 * ・CC99012E - ダウンロードに失敗
 * ・CC06013E - ディスク使用量の取得に失敗
 * ・A722023W - トラフィック情報の取得に失敗
 * ・A722024W - セッション情報の取得に失敗
 * ・A722025W - 統計情報の取得に失敗
 */
export type ErrorCode =
  | "A111001W"
  | "CC01004W"
  | "CC99009W"
  | "AZZZ001W"
  | "ARCC001W"
  | "CC99012E"
  | "CC06013E"
  | "A722023W"
  | "A722024W"
  | "A722025W"
  | string;

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 * インターネットType1の開通/停止処理要求リクエスト
 */
interface Type1InternetPausePutRequest {
  /**
   * 一時停止フラグ
   * 備考
   *  true:「一時停止」
   *  false:「開始」
   */
  isInternetPause: boolean;
  /**
   * 操作履歴の備考
   */
  note: string | null;
  /**
   * インターネットType1契約排他情報
   * 備考
   *  サービスメニューAPI（インターネットType1）のGETメソッドから取得する
   */
  contractType1KeyId: string;
}

/**
 * インターネットType4の開通/停止処理要求リクエスト
 */
interface Type4InternetPausePutRequest {
  /**
   * 一時停止フラグ
   * 備考
   *  true:「一時停止」
   *  false:「開始」
   */
  isInternetPause: boolean;
  /**
   * インターネットType1契約排他情報
   * 備考
   *  サービスメニューAPI（インターネットType4）のGETメソッドから取得する
   */
  contractType4KeyId: string;
}

export default class InternetPauseApi extends AbstractBaseApi {
  /** インターネットType1の開通/停止処理要求を行う
   * @param data インターネットType1の開通/停止処理要求リクエスト
   */
  async putType1InternetPause(
    data: Type1InternetPausePutRequest
  ): Promise<void> {
    await this.api.post(`/v1/network/internetpause/type1`, data);
  }

  /** インターネットType4の開通/停止処理要求を行う
   * @param type4Id type4Idを指定
   * @param data インターネットType1の開通/停止処理要求リクエスト
   */
  async putType4InternetPause(
    type4Id: string,
    data: Type4InternetPausePutRequest
  ): Promise<void> {
    await this.api.post(`/v1/network/internetpause/type4/${type4Id}`, data);
  }
}























































































































































































import Vue, { PropType } from "vue";
import {
  IntranetfwServiceMenu,
  IntranetfwServiceMenuPost,
} from "@/apis/IntranetfwServiceMenuApi";

export default Vue.extend({
  name: "IntranetFwContractConfirm",
  props: {
    intranetFwContract: {
      type: Object as PropType<IntranetfwServiceMenu>,
      required: true,
    },
    intranetFwContractFlag: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    postItem: {
      type: Object as PropType<IntranetfwServiceMenuPost>,
      required: true,
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import moment from "moment";

/**
 * 入力された時刻がHH:mm（デフォルト）またはHH:mm:ssの形式になっているかを確認するためのバリデーター
 * 24:00や24:00:00の場合はエラーにするようにしている
 */
export default {
  params: ["second"],
  validate(
    value: string | undefined | null,
    { second = false }: { second: boolean }
  ): boolean {
    if (!value) {
      return true;
    }

    // 24:00及び24:00:00はmoment.jsで適切な時刻として処理されるためそれらをエラーにするためにこのような処理をしている
    const timeElements = value.split(":");
    if (
      timeElements.length > 1 &&
      timeElements[0] === "24" &&
      timeElements[1] === "00"
    ) {
      return false;
    }

    return moment(value, second ? "HH:mm:ss" : "HH:mm", true).isValid();
  },
} as ValidationRuleSchema;


























import Vue, { PropType } from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { createPopper, VirtualElement } from "@popperjs/core";

/**
 * vue-selectのラッパーコンポーネント
 * Bootstrap-vueやVeeValidateの兼ね合いで色々調整しないといけないので
 */
export default Vue.extend({
  name: "AppSelect",
  components: { vSelect },
  props: {
    /** vee-validateのバリデーションState */
    state: {
      type: Boolean as PropType<boolean | undefined | unknown>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  methods: {
    withPopper(
      dropdownList: HTMLElement,
      component: { $refs: { toggle: Element | VirtualElement } },
      sizes: { width: string }
    ) {
      dropdownList.style.width = sizes.width;
      // https://vue-select.org/guide/positioning.html#popper-js-integration
      const popper = createPopper(component.$refs.toggle, dropdownList, {});
      return () => popper.destroy();
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 帯域
  備考
    契約種別がベストエフォートの場合はnull、帯域確保の場合は必須
    低帯域アプライアンスの場合は10~1000Mbps
    広帯域アプライアンスの場合は設定された上限・下限値に従う。
 */
export interface Bandwidth {
  /**
   設定値
   */
  value: number;
  /**
   単位
    備考
      以下のいずれかを設定。
      KBPS:Kbps
      MBPS:Mbps
      GBPS:Gbps
   */
  unit: "KBPS" | "MBPS" | "GBPS";
}

/**
 VPN VNコード
 */
export interface VpnVnCode {
  /**
   VPN/VNコード
   備考
   Bから始まる場合はVPN。それ以外の場合はVN
   */
  vpnVnCode: string;
  /**
   VN名
   備考
   VNの場合必須
   */
  vnName: string | null;
}

interface VpnVnCodeListResponse {
  /** 総件数 */
  total: number;
  /** 
   Type4VPN/VNリスト
    0件の場合は空配列
    インターネットType4が追加可能なVPN/VNリスト   
   * */
  type4VpnVnList: VpnVnCode[];
}

/**
 グローバルIPアドレス
 */
export interface GlobalIpAddress {
  /**
   グローバルIPアドレスSEQ
    備考
      グローバルIPアドレスの主キー
   */
  globalIpAddressSeq: string;
  /**
   グローバルIPアドレス
   */
  globalIpAddress: string;
  /**
   初期アドレスフラグ
    備考
      true：初期グローバルIPアドレス(削除不可)
      false：追加グローバルIPアドレス
   */
  isInitialAddress: boolean;
}

/**
 * 契約情報
 */
export interface ContractInfo {
  /** 契約者名  */
  contractor: string;
  /** エンドユーザ名  */
  endUserName: string;
  /** メールアドレス */
  email: string;
}

/**
 * 保守連絡先情報
 */
export interface Maintenance {
  /** 会社名 */
  company: string;
  /** 部署名 */
  department: string;
  /** 担当者名 */
  name: string;
  /** 電話番号 */
  phone: string;
  /** メールアドレス */
  email: string;
}

/**
 アプライアンス一覧の項目オブジェクト
 */
export interface Type4Service {
  /**
   デフォルトポリシー種別
    備考
      ALLOW_SPECIFIC_ONE_WAY：片方向の特定サービスallow
      ALLOW_ALL_ONE_WAY：片方向の全allow
      ALLOW_ALL_TWO_WAY：双方向の全allow
   */
  policyType:
    | "ALLOW_SPECIFIC_ONE_WAY"
    | "ALLOW_ALL_ONE_WAY"
    | "ALLOW_ALL_TWO_WAY";
  /**
   Type4名
   */
  type4Name: string;
  /**
   Microsoft365フラグ
    備考
      「ON」の場合はtrue、「OFF」の場合はfalseを設定
   */
  isMicrosoft365: boolean;
  /**
   契約種別
    備考
      アプライアンス種別がインターネットFWまたは帯域タイプが帯域指定不可(BEのみ)の場合、
      ベストエフォートのみ選択可能
      BEST_EFFORT：ベストエフォート
      SECURING：帯域確保
   */
  contractType: "BEST_EFFORT" | "SECURING";
  /**
   帯域
    備考
      契約種別がベストエフォートの場合はnull、帯域確保の場合は必須
      低帯域アプライアンスの場合は10~1000Mbps
      広帯域アプライアンスの場合は設定された上限・下限値に従う。
   */
  bandwidth: Bandwidth | null;
  /**
   アプライアンス種別
    備考
      IFW：インターネットFW
      UTM：UTM
   */
  applianceType: "IFW" | "UTM";
  /**
   インターネットType4契約排他情報
    備考
      排他確認用パラメータ
      設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  contractType4KeyId: string;
  /**
   W番号(Main_ACT)
   */
  wnumberMainAct: string;
  /**
   W番号(Main_SBY)
   */
  wnumberMainSby: string;
  /**
   W番号(BCP_ACT)
   */
  wnumberBcpAct: string;
  /**
   W番号(BCP_SBY)
   */
  wnumberBcpSby: string;
  /**
   VPN/VNコード
    備考
      Bから始まる場合はVPN。それ以外の場合はVN
   */
  vpnVnCode: string;
  /**
   VN名
    備考
      VNの場合必須
   */
  vnName: string | null;
  /**
   メインサイト
    備考
      TOKYO：東京
      OSAKA：大阪
   */
  mainSite: "TOKYO" | "OSAKA";
  /**
   Type4ID
   */
  type4Id: string;
  /**
   セキュリティオプションフラグ
    備考
      セキュリティ監視を契約している場合true
   */
  isSecurityOption: boolean;
  /**
   グローバルIPアドレスリスト
    備考
      インターネット契約時に初期グローバルIPアドレスが一つ払い出される。
      初期グローバルIPアドレスが先頭、
      その他のアドレスは追加順でソートされる。
   */
  globalIpAddressList: GlobalIpAddress[];
  /**
   状態
    備考
      UNCONNECTED: 未開通。利用開始前の状態
      ACTIVE: 稼働中。利用開始後かつ、一時停止中ではない状態
      INACTIVE: 停止。利用開始後かつ、一時停止中の状態
   */
  status: "UNCONNECTED" | "ACTIVE" | "INACTIVE";
  /**
   * 契約情報
   */
  contractInfo: ContractInfo;
  /**
   * 保守連絡先情報
   * 新しく追加・変更されるものは必須、既存のものは非必須がありえる。
   */
  maintenance: Maintenance | null;
}

/**
 インターネットType4利用開始リクエスト
 */
interface Type4ServiceStartPutRequest {
  /**
   インターネットType4契約排他情報
    備考
      設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  contractType4KeyId: string;
}

/**
 インターネットType4削除リクエスト
 */
export interface Type4ServiceDeletePostRequest {
  /**
   インターネットType4契約排他情報
    備考
      設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  contractType4KeyId: string;
  /** 操作履歴の備考 */
  note: string | null;
}

/**
 インターネットType4追加リクエスト
 */
export interface Type4ServicePostRequest {
  /**
   デフォルトポリシー種別
   備考
   ALLOW_SPECIFIC_ONE_WAY：片方向の特定サービスallow
   ALLOW_ALL_ONE_WAY：片方向の全allow
   ALLOW_ALL_TWO_WAY：双方向の全allow
   */
  policyType:
    | "ALLOW_SPECIFIC_ONE_WAY"
    | "ALLOW_ALL_ONE_WAY"
    | "ALLOW_ALL_TWO_WAY";
  /**
   Type4名
   */
  type4Name: string;
  /**
   Microsoft365フラグ
   備考
   「ON」の場合はtrue、「OFF」の場合はfalseを設定
   */
  isMicrosoft365: boolean;
  /**
   アプライアンス種別
   備考
   IFW：インターネットFW
   UTM：UTM
   */
  applianceType: "IFW" | "UTM";
  /**
   VPN/VNコード
   備考
   Bから始まる場合はVPN。それ以外の場合はVN
   */
  vpnVnCode: string;
  /**
   メインサイト
   備考
   TOKYO：東京
   OSAKA：大阪
   */
  mainSite: "TOKYO" | "OSAKA";
  /** 操作履歴の備考 */
  note: string | null;
  /** 保守連絡先情報 */
  maintenance: Maintenance;
}

/**
 インターネットType4設定リクエスト
 */
export interface Type4ServicePutRequest {
  /**
   デフォルトポリシー種別
    備考
      ALLOW_SPECIFIC_ONE_WAY：片方向の特定サービスallow
      ALLOW_ALL_ONE_WAY：片方向の全allow
      ALLOW_ALL_TWO_WAY：双方向の全allow
   */
  policyType:
    | "ALLOW_SPECIFIC_ONE_WAY"
    | "ALLOW_ALL_ONE_WAY"
    | "ALLOW_ALL_TWO_WAY";
  /**
   Type4名
   */
  type4Name: string;
  /**
   Microsoft365フラグ
    備考
    「ON」の場合はtrue、「OFF」の場合はfalseを設定
   */
  isMicrosoft365: boolean;
  /**
   契約種別
    備考
      アプライアンス種別がインターネットFWまたは帯域タイプが帯域指定不可(BEのみ)の場合、
      ベストエフォートのみ選択可能
      BEST_EFFORT：ベストエフォート
      SECURING：帯域確保
   */
  contractType: "BEST_EFFORT" | "SECURING";
  /**
   帯域
    備考
      契約種別がベストエフォートの場合はnull、帯域確保の場合は必須
      低帯域アプライアンスの場合は10~1000Mbps
      広帯域アプライアンスの場合は設定された上限・下限値に従う。
   */
  bandwidth: Bandwidth | null;
  /**
   アプライアンス種別
    備考
      IFW：インターネットFW
      UTM：UTM
   */
  applianceType: "IFW" | "UTM";
  /**
   グローバルIP追加数(単位:個)
   備考
    新たに払い出すグローバルIPアドレスの数
    現在払い出し済みのグローバルIPアドレスは数に含めない
    グローバルIPアドレスを新たに追加しない場合はnull
   */
  numberOfAddGlobalIpAddress: number | null;
  /**
   削除グローバルIPアドレスSEQリスト
    備考
      0件の場合はnull
   */
  deleteGlobalIpAddressSeqList: string[] | null;
  /** 操作履歴の備考 */
  note: string | null;
  /**
   インターネットType4契約排他情報
    備考
      排他確認用パラメータ
      設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  contractType4KeyId: string;
  /** 保守連絡先情報 */
  maintenance: Maintenance | null;
}

/**
 インターネットType4帯域アプライアンス許可設定取得用オブジェクト
 */
export interface Type4ServiceOptions {
  /**
   帯域タイプ
    備考
      NO_SECURING :帯域指定不可(BEのみ)
      LOW_BANDWIDTH :帯域指定可(低帯域)
      WIDE_BANDWIDTH:帯域指定可(広帯域)
   */
  bandwidthType: "NO_SECURING" | "LOW_BANDWIDTH" | "WIDE_BANDWIDTH";
  /**
   帯域下限値
    備考
      帯域タイプが帯域指定可(広帯域)の場合必須、それ以外の場合null
   */
  lowerLimitBandwidth: Bandwidth | null;
  /**
   帯域タイプ
    備考
      帯域タイプが帯域指定可(広帯域)の場合必須、それ以外の場合null
   */
  upperLimitBandwidth: Bandwidth | null;
}

export default class ContractChangeType4Api extends AbstractBaseApi {
  /** サービスメニュー(インターネットType4)を取得する */
  async getType4Service(type4Id: string): Promise<Type4Service> {
    return (
      await this.api.get(`/v2/contract/service/appliance/type4/${type4Id}`)
    ).data;
  }

  /** インターネットType4帯域アプライアンス許可設定を取得 */
  async getType4ServiceOptions(type4Id: string): Promise<Type4ServiceOptions> {
    return (
      await this.api.get(
        `/v1/contract/service/appliance/type4/${type4Id}/options`
      )
    ).data;
  }

  /** インターネットType4追加可能なVN面及びVPN面の情報を渡す */
  async getType4VpnVnList(): Promise<VpnVnCodeListResponse["type4VpnVnList"]> {
    const { type4VpnVnList }: VpnVnCodeListResponse = (
      await this.api.get(`/v1/contract/service/appliance/type4/vpn-vn-list`)
    ).data;
    return type4VpnVnList;
  }

  /** サービスメニュー(Type4)追加 */
  async postType4Service(data: Type4ServicePostRequest): Promise<void> {
    await this.api.post(`/v2/contract/service/appliance/type4`, data);
  }

  /** インターネットType4利用開始を行う */
  async putType4ServiceStart(
    type4Id: string,
    data: Type4ServiceStartPutRequest
  ): Promise<void> {
    await this.api.post(
      `/v1/contract/service/appliance/type4/${type4Id}/start`,
      data
    );
  }

  /** サービスメニュー(インターネットType4)を削除する */
  async deleteType4Service(
    type4Id: string,
    data: Type4ServiceDeletePostRequest
  ): Promise<void> {
    await this.api.post(
      `/v1/contract/service/appliance/type4/${type4Id}/delete`,
      data
    );
  }

  /** サービスメニュー(Type4)設定 */
  async putType4Service(
    type4Id: string,
    data: Type4ServicePutRequest
  ): Promise<void> {
    await this.api.post(
      `/v2/contract/service/appliance/type4/${type4Id}`,
      data
    );
  }
}

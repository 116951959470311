










































































































































































import Vue, { PropType } from "vue";
import { Bgp4Peer, VNL2L3ConnectDetail } from "@/apis/VNL2L3ConnectApi";

export default Vue.extend({
  name: "VNL2L3ConnectDelete",
  props: {
    vnL2L3ConnectDetail: {
      type: Object as PropType<VNL2L3ConnectDetail>,
      required: true,
    },
  },
  computed: {
    bgp4List(): Bgp4Peer[] {
      if (this.vnL2L3ConnectDetail.routingType === "BGP4") {
        return this.vnL2L3ConnectDetail.bgp4!.sortBy(["peerName", "asc"]);
      } else {
        return [];
      }
    },
  },
});

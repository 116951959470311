







































































import Vue, { PropType } from "vue";
import { CloudLine, CloudMsPost } from "@/apis/CloudLineApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";

export default Vue.extend({
  name: "MicrosoftRegister",
  props: {
    /** 回線番号 */
    enumber: {
      type: String as PropType<string>,
      required: true,
    },
    /** 取得したクラウド回線情報全件 */
    cloudLineList: {
      type: Array as PropType<CloudLine[]>,
      required: true,
    },
    /** クラウド回線排他情報 */
    cloudLineKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      msTb: {
        fields: [
          { key: "cloudServiceMenu", label: "メニュー" },
          {
            key: "bandwidth",
            label: "帯域上限値",
          },
          {
            key: "description",
            label: "備考",
            tdClass: "text-pre-wrap",
          },
        ] as BvTableFieldArray,
      },
      newMS: {
        cloudServiceMenu: "MS",
        bandwidth: {
          value: 0,
          unit: null,
        },
        description: null,
        note: null,
        cloudLineKeyId: this.cloudLineKeyId,
      } as CloudMsPost,
    };
  },
  computed: {
    items(): (CloudLine | CloudMsPost)[] {
      // 副作用を避けるため、コピー
      const items = [...this.cloudLineList] as (CloudLine | CloudMsPost)[];
      // 既存のMSの後ろにInsert
      let insertIndex =
        items
          .map((v) =>
            v.cloudServiceMenu.startsWith("MS") ? "MS" : v.cloudServiceMenu
          )
          .lastIndexOf("MS") + 1;
      // 既存のMSがない場合、AWSの後ろにInsert
      if (insertIndex === 0) {
        insertIndex =
          items.map((v) => v.cloudServiceMenu).lastIndexOf("AWS") + 1;
      }
      items.splice(insertIndex, 0, this.newMS);
      return items;
    },
  },
  methods: {
    async submit() {
      await this.$confirm(`Microsoftを追加します。よろしいですか？`);
      await this.$api.cloudLine.postMsCloudLine(this.enumber, this.newMS);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

import { Module } from "vuex";
import { RootState } from "@/store/index";

const metaState = {
  /** 通信中のリクエスト一覧 */
  runningRequests: [] as string[],
  /**
   * true: ローディング表示, false: ローディング表示しない
   * 特殊な事情でローディングを表示させたくない場合用
   */
  enabledLoading: true,
};

export type MetaState = typeof metaState;

export const metaStoreModule: Module<MetaState, RootState> = {
  namespaced: true,
  state: metaState,
  getters: {
    /** true: 通信中, false: 非通信中 */
    isLoading: (state): boolean => state.runningRequests.length > 0,
  },
  mutations: {
    addRunningRequests(state, info: string) {
      state.runningRequests.push(info);
    },
    removeRunningRequests(state, info: string) {
      const index = state.runningRequests.indexOf(info);
      if (index > -1) {
        state.runningRequests.splice(index, 1);
      }
    },
    setEnabledLoading(state, enabled: boolean) {
      state.enabledLoading = enabled;
    },
  },
  actions: {
    /**
     * @param commit
     * @param info メソッド + URL
     */
    addRunningRequests({ commit }, info: string) {
      commit("addRunningRequests", info);
    },
    /**
     * @param commit
     * @param info メソッド + URL
     */
    removeRunningRequests({ commit }, info: string) {
      // 直列通信の場合にちらつくので若干delay
      setTimeout(() => {
        commit("removeRunningRequests", info);
      }, 100);
    },
    /** ローディング表示の有効/無効切り替え */
    setEnabledLoading({ commit }, enabled: boolean) {
      commit("setEnabledLoading", enabled);
    },
  },
};







































































import Vue, { PropType } from "vue";
import { OrderListEntity } from "@/apis/OperationHistoryApi";
import { AppTableData } from "@/components/AppTable.vue";

export default Vue.extend({
  name: "HistoryReference",
  props: {
    operationSeq: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      orderList: {
        fields: [
          {
            key: "button",
            label: "",
          },
          {
            key: "orderType",
            label: "種別",
          },
          {
            key: "orderTarget",
            label: "対象",
          },
          {
            key: "orderStatus",
            label: "ステータス",
            formatter: (value: string) =>
              this.$filter.enumTo(value, "operationOrderStatus"),
          },
          {
            key: "additionalInformation",
            label: "付加情報",
          },
        ],
        items: [],
      } as Pick<
        AppTableData<OrderListEntity & { _primary: number }>,
        "fields" | "items"
      >,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.orderList.items = (
        await this.$api.operationHistory.getReferenceList(this.operationSeq)
      ).orderList // 選択を使用しないのでprimaryはindexでも可
        .map((e, i) => ({ ...e, _primary: i }));
    },
  },
});

import { AxiosResponse } from "axios";

import AbstractBaseApi from "./AbstractBaseApi";

/**
 * ユーザ設定情報取得API用インタフェース
 */
export interface UserGetEntity {
  total: number;
  /** V番号 */
  vnumber: string;
  /** ユーザ情報 */
  userList: UserEntity[];
  /** ユーザー管理排他情報  */
  userKeyId: string;
}

/**
 * ユーザ設定情報
 */
export interface UserEntity {
  /** ユーザSEQ  */
  userSeq: string;
  /** ログインID */
  loginId: string;
  /** 姓  */
  familyName: string;
  /** 名  */
  givenName: string;
  /** カナ姓  */
  familyNameKana: string | null;
  /** カナ名  */
  givenNameKana: string | null;
  /** 所属グループ   */
  group: string | null;
  /** 電話番号  */
  phone: string | null;
  /** メールアドレス  */
  email: string;
  /** 運用種別
    ADMIN: 法人管理者
    SETTING: 設定権限
    VIEWING: 閲覧
  */
  operationType: "ADMIN" | "SETTING" | "VIEWING";
  /** IP認証  */
  ipAuthAddressList: string[];
  /** 登録日  */
  createDate: string;
  /** 最終更新日  */
  updateDate: string | null;
}

/**
 * ユーザ設定情報追加API用インタフェース
 */

export interface UserPostEntity {
  /** ログインID */
  loginId: string;
  /** 姓  */
  familyName: string;
  /** 名  */
  givenName: string;
  /** カナ姓  */
  familyNameKana: string | null;
  /** カナ名  */
  givenNameKana: string | null;
  /** 所属グループ   */
  group: string | null;
  /** 電話番号  */
  phone: string | null;
  /** メールアドレス  */
  email: string;
  /** 運用種別
    ADMIN: 法人管理者
    SETTING: 設定権限
    VIEWING: 閲覧
  */
  operationType: "ADMIN" | "SETTING" | "VIEWING";
  /** IP認証  */
  ipAuthAddressList: string[];
  /**
   * ワンタイム認証URL
   * URLを指定する場合は、設定する。
   * 決められたドメイン以外は受け付けない。
   */
  oneTimeUrl: string | null;
}

/**
 * ユーザ設定情報変更API用インタフェース
 */
export interface UserUpdateEntity {
  /** 姓  */
  familyName: string;
  /** 名  */
  givenName: string;
  /** カナ姓  */
  familyNameKana: string | null;
  /** カナ名  */
  givenNameKana: string | null;
  /** 所属グループ   */
  group: string | null;
  /** 電話番号  */
  phone: string | null;
  /** メールアドレス  */
  email: string;
  /** 運用種別
    ADMIN: 法人管理者
    SETTING: 設定権限
    VIEWING: 閲覧
  */
  operationType: "ADMIN" | "SETTING" | "VIEWING";
  /** IP認証  */
  ipAuthAddressList: string[];
  /** ユーザー管理排他情報  */
  userKeyId: string;
}

/**
 * ユーザ設定情報削除API用インタフェース
 */
export interface UserDeleteEntity {
  /** 削除対象ユーザSEQリスト  */
  userSeqList: string[];
  /** ユーザー管理排他情報  */
  userKeyId: string;
}

/**
 * ユーザ設定情報取得API用インタフェース
 */
export interface UserGroupGetEntity {
  total: number;
  /** ユーザグループ情報 */
  userGroupList: string[];
}

export default class UserApi extends AbstractBaseApi {
  async getAll(): Promise<UserGetEntity> {
    return (await this.api.get("v1/users")).data;
  }

  async delete(data: UserDeleteEntity): Promise<AxiosResponse> {
    return (await this.api.post("v1/users/delete", data)).data;
  }

  async post(data: UserPostEntity): Promise<AxiosResponse> {
    return (await this.api.post("v1/user", data)).data;
  }

  async update(
    userSeq: string,
    data: UserUpdateEntity
  ): Promise<AxiosResponse> {
    return (await this.api.post("v1/user" + `/${userSeq}`, data)).data;
  }

  async getGroupList(): Promise<UserGroupGetEntity> {
    return (await this.api.get("v1/user/groups")).data;
  }
}

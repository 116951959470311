




























































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import VNL2L3ConnectReference from "@/modals/vnL2L3Connect/VNL2L3ConnectReference.vue";
import VNL2L3ConnectEdit from "@/modals/vnL2L3Connect/VNL2L3ConnectEdit.vue";
import { VNL2L3Connect } from "@/apis/VNL2L3ConnectApi";
import { SortCompareFunc } from "@/services/SortService";

export default Vue.extend({
  name: "VNL2L3ConnectList",
  data() {
    return {
      isLoaded: false,
      vnl2l3ConnectKeyId: "",
      vnL2L3Connect: {
        fields: [
          {
            key: "enumber",
            label: "回線番号",
            sortable: true,
          },
          {
            key: "wnumber",
            label: "VNコネクト番号",
            sortable: true,
          },
          {
            key: "vnConnectName",
            label: "VNコネクト名",
            sortable: true,
          },
          {
            key: "l2VN",
            label: "L2VN",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v: string, _k: string, item: VNL2L3Connect) => {
              if (item.l2VnVlanIdType === "SPECIFIED") {
                return `${item.l2VnName}:${item.l2VnCode}(${item.l2VnVlanId})`;
              } else {
                return `${item.l2VnName}:${item.l2VnCode}(${this.$filter.enumTo(
                  item.l2VnVlanIdType,
                  "vlanIdType"
                )})`;
              }
            },
          },
          {
            key: "vlan",
            label: "L2/L3コネクト VLAN",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v: string, _k: string, item: VNL2L3Connect) => {
              if (item.vnl2l3VlanIdType === "SPECIFIED") {
                return `${item.vnl2l3VnVlanId}`;
              } else return `Untag`;
            },
          },
          {
            key: "l3VN",
            label: "L3VPN/VN",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (_v: string, _k: string, item: VNL2L3Connect) => {
              if (item.l3VpnVnCode.charAt(0) === "B") {
                return `${item.l3VpnVnCode}`;
              } else {
                return `${item.l3VpnVnName}:${item.l3VpnVnCode}`;
              }
            },
          },
          {
            key: "routingType",
            label: "ルーティング種別",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (_v: string) => {
              return this.$filter.enumTo(_v, "routingType");
            },
          },
          {
            key: "description",
            label: "備考",
            tdClass: "text-pre-wrap",
          },
        ],
        items: [],
        search: {
          enumber: { type: "text", label: "回線番号" },
          wnumber: { type: "text", label: "VNコネクト番号" },
          vnConnectName: { type: "text", label: "VNコネクト名" },
          l2VN: { type: "text", label: "L2VN" },
          vlan: { type: "text", label: "L2/L3コネクト VLAN" },
          l3VN: { type: "text", label: "L3VPN/VN" },
          routingType: {
            type: "select",
            label: "ルーティング種別",
            items: [
              { value: "BGP4", text: "BGP4" },
              { value: "STATIC", text: "Static" },
              { value: "OSPF", text: "OSPF" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          enumber: "like",
          wnumber: "like",
          vnConnectName: "like",
          l2VN: "like",
          vlan: "like",
          l3VN: "like",
          routingType: "equal",
        },
      } as Omit<AppTableData<VNL2L3Connect>, "selected">,
      downloadUrl: this.$api.vNL2L3Connect.getVNL2L3connectCsvUrl(),
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    customSortEnumber(): SortCompareFunc<VNL2L3Connect> {
      return this.$service.tableSortCompare("enumber", ["wnumber", "desc"]);
    },
  },
  methods: {
    /** 一覧を取得 */
    async load() {
      const data = await this.$api.vNL2L3Connect.getVNL2L3Connects();
      this.vnl2l3ConnectKeyId = data.vnl2l3ConnectKeyId;
      this.vnL2L3Connect.items = data.vnl2l3ConnectList;
    },
    /** 追加 */
    async addVNL2L3Connect() {
      await this.$modal.show(VNL2L3ConnectEdit, {
        vnl2l3ConnectKeyId: this.vnl2l3ConnectKeyId,
      });
      await this.load();
    },
    /** 詳細 */
    async showVNL2L3ConnectRef(item: VNL2L3Connect) {
      await this.$modal.show(VNL2L3ConnectReference, {
        wnumber: item.wnumber,
      });
      await this.load();
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 半角大文字V+半角数字9桁(V番号)で入力されること */

export default {
  validate(value: string | undefined | null): boolean {
    if (value) {
      return /^V\d{9}$/.test(value);
    }
    return true;
  },
} as ValidationRuleSchema;

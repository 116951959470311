var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-full","title":"確認"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"pb-3 pl-2"},[_vm._v(" 以下の内容で申し込みます。よろしいですか？"),_c('br'),_vm._v(" 帯域確保品目で減速した場合、通信に影響を与える可能性があります。"),_c('br'),_vm._v(" 帯域値、QoS設定、VLAN制御を変更した場合、ご請求額が変更となります。 ")]),_c('app-table',{attrs:{"primary-key":"lineSeq","fields":_vm.displayFields,"items":_vm.accessLine.items,"pagination":false,"thead-class":"text-center"},scopedSlots:_vm._u([{key:"cell(enumberAct)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.enumberAct)),_c('br'),(
                  data.item.menu === 'PF_ACCESS_GW2' && data.item.enumberSby
                )?[_vm._v(_vm._s(data.item.enumberSby)+" ")]:_vm._e()]}},{key:"cell(menu)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("enumTo")(data.value,"accessLineMenu")))])]}},{key:"cell(bandwidthType)",fn:function(data){return [(data.item.bandwidthType === 'BURST')?[_c('span',{class:data.item.bandwidthType !==
                    _vm.accessList[data.index].bandwidthType
                      ? 'changed-content'
                      : 'content'},[_vm._v(_vm._s(_vm._f("enumTo")(data.item.burst,"accessLineBandwidthType")))])]:[_c('span',{class:data.item.bandwidthType !==
                    _vm.accessList[data.index].bandwidthType
                      ? 'changed-content'
                      : 'content'},[_vm._v(_vm._s(_vm._f("enumTo")(data.item.bandwidthType,"accessLineBandwidthType")))])]]}},{key:"cell(fixingBandwidth)",fn:function(data){return [(
                  data.item.menu === 'ETHERNET2' ||
                  data.item.menu === 'ETHERNET2_PF'
                )?[_c('span',{class:data.item.fixingBandwidth !==
                    _vm.accessList[data.index].fixingBandwidth
                      ? 'changed-content'
                      : 'content'},[_vm._v(_vm._s(data.item.fixingBandwidth))])]:[_vm._v(" - ")]]}},{key:"cell(bandwidth)",fn:function(data){return [(data.item.bandwidthType === 'BURST')?[_c('span',{class:data.item.burst !== _vm.accessList[data.index].burst
                      ? 'changed-content'
                      : 'content'},[_vm._v(" "+_vm._s(_vm._f("optionText")(data.item.burst,_vm.burstType))+" ")])]:(data.item.bandwidthType === 'BESTEFFORT')?[_vm._v(" - ")]:[_c('span',{class:_vm.changedBandwidth(data.index)},[_vm._v(" "+_vm._s(_vm._f("bandwidthTo")(data.item.bandwidth,"Mbps"))+" ")])]]}},{key:"cell(qos)",fn:function(data){return [(
                  data.item.menu === 'PF_ACCESS_GW' ||
                  data.item.menu === 'EXTEND_ETHERNET' ||
                  data.item.menu === 'BROADBAND_ACCESS3' ||
                  data.item.menu === 'WIRELESS_ACCESS2' ||
                  data.item.menu === 'ETHERNET_LIGHT' ||
                  data.item.menu === 'MULTI_CLOUD_GW' ||
                  (data.item.menu === 'PF_ACCESS_GW2' &&
                    data.item.isQosPath === false)
                )?[_vm._v(" - ")]:[_c('span',{class:data.item.qos !== _vm.accessList[data.index].qos
                      ? 'changed-content'
                      : 'content'},[_vm._v(_vm._s(data.item.qos))])]]}},{key:"cell(vlanControl)",fn:function(data){return [(
                  data.item.menu === 'PF_ACCESS_GW' ||
                  data.item.menu === 'EXTEND_ETHERNET' ||
                  data.item.menu === 'PF_ACCESS_GW2' ||
                  data.item.menu === 'MULTI_CLOUD_GW' ||
                  _vm.isEthernet2Broadband(data.item)
                )?[_vm._v(" - ")]:[_c('span',{class:data.item.vlanControl !==
                    _vm.accessList[data.index].vlanControl
                      ? 'changed-content'
                      : 'content'},[_vm._v(_vm._s(data.item.vlanControl))])]]}},{key:"cell(office)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("emptyTo")(data.value,"-")))])]}}],null,true)}),_c('b-form-group',{staticClass:"pl-2",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"オーダー備考","msg":_vm._f("msg")('info_max_length',{ maxLength: 200 }),"is-info":true,"isRequired":false}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"オーダー備考","rules":{ max: 200 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-textarea',{attrs:{"rows":"3","state":_vm._f("validState")(validContext)},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1)],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("はい")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("いいえ")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
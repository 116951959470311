import { Module } from "vuex";
import { RootState } from "@/store/index";
import { LoginResponse } from "@/apis/AuthApi";
import { LoginUser } from "@/apis/InformationApi";
import {
  ContractAppliances,
  ContractInfo,
  ContractSummary,
} from "@/apis/ContractApi";
import { UserEntity, UserGetEntity } from "@/apis/UserApi";

/** トークンの保存先キー */
const KEY_API_TOKEN = "api_token";

const userState = {
  /** true: ログイン済み, false: 未ログイン */
  isLogged: localStorage.getItem(KEY_API_TOKEN) !== null,
  /** APIトークン */
  token: localStorage.getItem(KEY_API_TOKEN),
  // 未ログインの場合は空。nullが正確だが毎回nullチェックするのは手間なので。他も同様
  /** ログインユーザー情報 */
  loginUser: {} as LoginUser,
  /** 契約回線情報 */
  contractInfo: {} as ContractInfo,
  /** 契約に関するサマリー情報 */
  contractSummary: {} as ContractSummary,
  /** アプライアンス情報一覧 */
  contractAppliances: {} as ContractAppliances,
  /** 自ユーザー情報（閲覧代行権限の場合は取得されない） */
  me: null as UserEntity | null,
  /** V番号 */
  vnumber: "",
};

export type UserState = typeof userState;

export interface LoginInfo {
  loginUser: LoginUser;
  contractInfo: ContractInfo;
  contractSummary: ContractSummary;
  contractAppliances: ContractAppliances;
  /** 閲覧代行権限時は不要（APIが権限エラーで取得できない） */
  users: UserGetEntity | undefined;
}

/** 認証ユーザー系Store */
export const userStoreModule: Module<UserState, RootState> = {
  namespaced: true,
  state: userState,
  mutations: {
    login(state, payload: Pick<LoginResponse, "tokenId">) {
      localStorage.setItem(KEY_API_TOKEN, payload.tokenId);
      state.isLogged = true;
      state.token = payload.tokenId;
    },
    logout(state) {
      localStorage.removeItem(KEY_API_TOKEN);
      state.isLogged = false;
      state.token = null;
      state.loginUser = {} as LoginUser;
      state.contractInfo = {} as ContractInfo;
      state.contractSummary = {} as ContractSummary;
      state.contractAppliances = {} as ContractAppliances;
      state.me = {} as UserEntity;
      state.vnumber = "";
    },
    loginInfo(state, payload: LoginInfo) {
      state.loginUser = payload.loginUser;
      state.contractInfo = payload.contractInfo;
      state.contractSummary = payload.contractSummary;
      state.contractAppliances = payload.contractAppliances;
      if (payload.users) {
        state.me = payload.users.userList.find(
          (e) => e.loginId === payload.loginUser.loginId
        )!;
      }
      state.vnumber = payload.contractInfo.vnumber;
    },
    updateInfo(
      state,
      payload: Partial<Omit<LoginInfo, "loginUser" | "users">>
    ) {
      state.contractInfo = payload.contractInfo ?? state.contractInfo;
      state.contractSummary = payload.contractSummary ?? state.contractSummary;
      state.contractAppliances =
        payload.contractAppliances ?? state.contractAppliances;
    },
  },
  actions: {
    /** ログイン */
    login({ commit }, data: Pick<LoginResponse, "tokenId">) {
      commit("login", data);
    },
    /** ログアウト */
    logout({ commit }) {
      commit("logout");
    },
    /** 画面全体で使用するユーザー関連情報を設定*/
    setLoginInfo({ commit }, data: LoginInfo) {
      commit("loginInfo", data);
    },
    /** ユーザー関連情報の一部を更新。プロパティが設定されていない場合その情報は更新されない */
    updateLoginInfo(
      { commit },
      data: Partial<Omit<LoginInfo, "loginUser" | "users">>
    ) {
      commit("updateInfo", data);
    },
  },
};

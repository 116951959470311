import { AxiosResponse } from "axios";

import AbstractBaseApi from "./AbstractBaseApi";

/**
 * IPsecGW一覧のAPI用インターフェイス
 */
export interface IpSecGWGetEntity {
  total: number;
  /** IPsecGW排他情報 */
  ipsecGatewayKeyId: string;
  /** [テーブル]IPsecGW一覧のAPI用インターフェイス */
  ipsecGatewayList: IpSecGWEntity[];
}

/**
 * [テーブル]IPsecGW一覧のAPI用インターフェイス
 */
export interface IpSecGWEntity {
  /** IPsecGW回線番号 */
  enumber: string;
  /** サフィックス */
  suffix: string | null;
  /** IPsec回線数 */
  numberOfIpsec: number;
  /** IPsec回線接続上限数 */
  upperLimitIpsec: number;
  /** WANアドレス */
  wanAddress: string;
  /** IPsecGWアドレス*/
  ipsecGatewayAddress: string | null;
  /** 管理用アドレス① */
  manageAddress1: string | null;
  /** 管理用アドレス② */
  manageAddress2: string | null;
  /** 管理用アドレス③ */
  manageAddress3: string | null;
}

/**
 * IPsecGWサフィックス登録のAPI用インターフェイス
 */
export interface IpSecGWUpdateEntity {
  /** サフィックス */
  suffix: string;
  /** IPsecGW排他情報 */
  ipsecGatewayKeyId: string;
}

export default class IpSecGwApi extends AbstractBaseApi {
  /**
   * IPsecゲートウェイ情報を取得
   */
  async getAll(): Promise<IpSecGWGetEntity> {
    return (await this.api.get("/v1/network/ipsec/ipsecgw")).data;
  }

  /**
   * IPsecゲートウェイ情報を変更
   * @param enumber
   * @param data
   */
  async update(
    enumber: string,
    data: IpSecGWUpdateEntity
  ): Promise<AxiosResponse> {
    return (await this.api.post(`/v1/network/ipsec/ipsecgw/${enumber}`, data))
      .data;
  }

  /**
   * IPsec情報一覧のCSVを取得
   */
  getCsvURL(): string {
    return this.getFullURL("/v1/network/ipsec/ipsecgw/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
}

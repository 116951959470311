























import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ConfirmModal",
  props: {
    message: {
      type: String as PropType<string>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      default: "確認",
    },
    isClose: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});

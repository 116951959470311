
























































import Vue, { PropType } from "vue";
import { NatNaptDetailEntity } from "@/apis/MSPeeringIpApi";
import MSNaptSettingModify from "@/modals/msNaptSetting/MSNaptSettingModify.vue";

export default Vue.extend({
  name: "MSNaptSettingReference",
  props: {
    /** 設定変更時、使用する排他キー */
    keyId: {
      type: String as PropType<string>,
      required: true,
    },
    /** NAPT用グローバルIP設定情報 */
    naptSetting: {
      type: Object as PropType<NatNaptDetailEntity>,
      required: true,
    },
  },
  methods: {
    /** NAPT用グローバルIPの追加・削除 */
    async modifyNaptSetting() {
      await this.$modal.show(MSNaptSettingModify, {
        keyId: this.keyId,
        naptSetting: this.naptSetting,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

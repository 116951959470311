import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import moment from "moment";

/**
 * 対象の時刻文字列が指定した時刻より未来か判定する
 * 指定時刻が未指定の場合は現在時刻が使用される
 * 入力項目を加工してバリデーションしたい場合もあるので、その場合はfrom, toを利用する
 */
export default {
  params: ["from", "to"],
  validate(
    value: string | null | undefined,
    { from, to }: { from?: string; to?: string }
  ): boolean {
    if (from === undefined && to === undefined) {
      // fromとto両方指定無しの場合は、入力値が現在時刻より未来か判定
      if (!value) return true;
      return moment(value).isAfter();
    } else if (from !== undefined && to !== undefined) {
      // fromとto両方指定ありの場合は、toがfromより未来か判定
      if (!(from && to)) return true;
      return moment(to).isAfter(moment(from));
    } else {
      if (to === undefined) {
        // fromのみ指定の場合は、入力値がfromより未来か判定
        if (!value) return true;
        return moment(value).isAfter(moment(from));
      } else {
        // toのみ指定の場合は、toが入力値より未来か判定
        if (!value) return true;
        return moment(to).isAfter(moment(value));
      }
    }
  },
} as ValidationRuleSchema;
















































import Vue, { PropType } from "vue";
import { AuthorityType } from "@/apis/CpaIdApi";

export default Vue.extend({
  name: "CpaIdFileDownloadConfirm",
  props: {
    // ダウンロード確認の場合のみ指定あり
    cpaIdInformationSeq: {
      type: String as PropType<string>,
      required: false,
    },
    authorityType: {
      type: String as PropType<AuthorityType>,
      required: true,
    },
  },
  computed: {
    // 代行権限でログインしている且つ、ダウンロード対象ファイルが法人権限で作成されいるかチェック
    isWarning(): boolean {
      return (
        this.$service.permission.isDelegateUser() &&
        this.authorityType === "CUSTOMER"
      );
    },
  },
  methods: {
    async ok() {
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

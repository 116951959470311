import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import { BandWidth } from "@/apis/CloudVnMsApi";

/**
 * 品目フォーム用のチェックバリデーション
 * リスト内にある値以外が指定されていた場合falseとする
 * vee-validateのoneOfだと、値が指定されていない場合にもエラーとなってしまう
 * 設定変更の場合、エラーとなるためバリデーションを作成
 */
export default {
  params: ["list"],
  validate(
    value: BandWidth | undefined | null,
    { list }: { list: BandWidth[] }
  ): boolean {
    if (!value) {
      // valueがundefinedまたはnullの場合はバリデーションをスキップ
      return true;
    }
    return list.some((item) => {
      return item.value === value.value && item.unit === value.unit;
    });
  },
} as ValidationRuleSchema;

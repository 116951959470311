import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { NonNullableProperty } from "@/shims-vue";

/** エクストラネット情報 */
export interface ExtranetInfo {
  /** エクストラネット接続申請SEQ. 主キー */
  extranetSeq: string;
  /** 接続元V番号 */
  srcVNumber: string | null;
  /** 接続元VPN/VNコード */
  srcVpnVnCode: string;
  /** 接続先V番号 */
  dstVNumber: string | null;
  /** 接続先VPN/VNコード */
  dstVpnVnCode: string;
  /**
   * PINコード
   * GET：承認者側が承認前の時(COMPLETE以外の場合）にはNULLを設定
   * POST:srcVNumberとdstVNumberが異なる場合のみ必須
   * PUT：srcVNumberとdstVNumberが異なる場合かつapprovalRequestがAPPROVEの場合：必須
   * approvalRequestがAPPROVE以外の場合：NULL or undefined(プロパティなし)
   */
  pinCode: string | null;
  /**
   * 承認ステータス
   * 以下のいずれかの内容を指定
   * APPLIED: 申請中/承認待ち
   * COMPLETE: 完了
   * EXPIRED: 有効期限切れ
   * DROP: 取り下げ
   * REJECTED: 却下
   * DISCONNECT: 切断
   * FAILED: 失敗
   */
  approvalState:
    | "APPLIED"
    | "COMPLETE"
    | "EXPIRED"
    | "DROP"
    | "REJECTED"
    | "DISCONNECT"
    | "FAILED";
  /** メッセージ. 全半角、改行、記号(_ . , : ; ? < > + - * / = # % & $ @)の入力チェックが必要 */
  message: string | null;
  /**
   * 申請日時.
   * ($date-time) example: 2021-12-01T23:59:59+09:00 */
  requestDateTime: string;
  /**
   * 有効期限
   * 申請日時より15日経過で有効期限が切れる。
   * GET：承認完了の時(COMPLETEの場合）にはNULLを設定
   * ($date-time) example: 2021-12-01T23:59:59+09:00
   */
  expiredDateTime: string | null;
  /**
   * 承認日時
   * ($date-time) example: 2021-12-01T23:59:59+09:00
   */
  approvalDateTime: string | null;
}

/** エクストラネット情報一覧 */
export interface ExtranetInfoList {
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  /** エクストラネット設定情報. 取得したエクストラネット設定情報。データが存在しない場合、[](空の状態)になります。 */
  extranetInfo: ExtranetInfo[];
  /** エクストラネット設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  extranetKeyId: string;
}

/** エクストラネット登録情報*/
export type PostExtranetInfo = Pick<
  NonNullableProperty<ExtranetInfo>,
  "dstVNumber"
> & { srcVnCode: string; dstVnCode: string } & Pick<
    ExtranetInfo,
    "pinCode" | "message"
  > &
  Pick<ExtranetInfoList, "extranetKeyId">;

/** エクストラネット申請承認・取り下げ・切断情報 */
export interface PutExtranetInfo {
  /**
   * PINコード
   * GET：承認者側が承認前の時(COMPLETE以外の場合）にはNULLを設定
   * POST:srcVNumberとdstVNumberが異なる場合のみ必須
   * PUT：srcVNumberとdstVNumberが異なる場合かつapprovalRequestがAPPROVEの場合：必須
   * approvalRequestがAPPROVE以外の場合：NULL or undefined(プロパティなし)
   */
  pinCode: string | null;
  /** ステータスの変更要求. 以下のいずれかの内容を指定 APPROVE: 承認 DROP: 取り下げ REJECTED: 却下 DISCONNECT: 切断 */
  approvalRequest: "APPROVE" | "DROP" | "REJECTED" | "DISCONNECT";
  /** 操作履歴の備考. approvalRequestがAPPROVEの場合のみパラメータが入ること許容する。 */
  note: string | null;
  /** エクストラネット設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  extranetKeyId: string;
}

export default class ExtranetApi extends AbstractBaseApi {
  /** エクストラネット設定情報を取得 */
  async getExtranets(): Promise<ExtranetInfoList> {
    return (await this.api.get("/v1/network/extranets")).data;
  }

  /**
   * エクストラネット申請
   * @param postData 申請情報
   */
  async postExtranet(postData: PostExtranetInfo): Promise<void> {
    await this.api.post("/v1/network/extranets", postData);
  }

  /**
   * エクストラネット申請を承認・取り下げ、エクストラネットの切断
   * @param extranetSeq エクストラネットSeq
   * @param data エクストラネット申請・取り下げ・切断情報
   */
  async putExtranet(extranetSeq: string, data: PutExtranetInfo): Promise<void> {
    await this.api.post(`/v1/network/extranet/${extranetSeq}`, data);
  }

  /**
   * エクストラネットCSVダウンロード
   */
  getCsvExtranetURL(): string {
    return this.getFullURL("/v1/network/extranets/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
}

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * 全角文字・半角英数字・"#'\,を除く半角記号を許可
 *（正規表現的には"#'\,や半角カタカナなどを禁止する）
 * @see https://seiai.ed.jp/sys/text/java/utf8table.html
 */
export default {
  validate(value: string | null | undefined): boolean {
    if (!value) return true;
    return !!value.match(
      // eslint-disable-next-line no-control-regex
      /^[^\x01-\x20\x22\x23\x27\x2C\x5C\uFF66-\uFF9D]*$/
    );
  },
} as ValidationRuleSchema;

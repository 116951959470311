/**
 * 帯域（bandwidth）valueをBps単位に変換
 * 例1：bandwidth:{value: 1, unit: KBPS} -> 1000
 * 例2：bandwidth:{value: 1, unit: MBPS} -> 1000 * 1000
 * 例3：bandwidth:{value: 1, unit: GBPS} -> 1000 * 1000 * 1000
 * 例4：bandwidth:{value: 0, unit: null} -> null
 * @param bandwidthItem 帯域Object
 */
export default function bandwidthToBps(bandwidthItem: {
  value: number;
  unit: "KBPS" | "MBPS" | "GBPS" | null;
}): number | null {
  switch (bandwidthItem.unit) {
    case "KBPS":
      return bandwidthItem.value * 1000;
    case "MBPS":
      return bandwidthItem.value * 1000 * 1000;
    case "GBPS":
      return bandwidthItem.value * 1000 * 1000 * 1000;
    default:
      return null;
  }
}

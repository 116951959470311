















































































import Vue, { PropType } from "vue";
import { VisibleIpSecGW } from "@/views/ipSecGw/IpSecGWList.vue";
import IpSecGWModify from "@/modals/ipSecGw/IpSecGWModify.vue";

export default Vue.extend({
  name: "IpSecGWReference",
  props: {
    /** IPsecGW */
    ipsecGW: {
      type: Object as PropType<VisibleIpSecGW>,
      required: true,
    },
    /** IPsecGW排他情報 */
    keyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  methods: {
    /** サフィックス登録 */
    async registerSuffix() {
      await this.$modal.show(IpSecGWModify, {
        ipsecGW: this.ipsecGW,
        keyId: this.keyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});












































































































































































































































































































































import Vue from "vue";
import TrafficApi, {
  ApplianceType,
  DisplayRange,
  LineElement,
  LineType,
  SessionResponse,
  SortPattern,
  StatisticReportType,
  StatisticsResponse,
  TrafficLineSearchResponse,
  TrafficResponse,
} from "@/apis/TrafficApi";
import TrafficSessionStatisticsGraph from "@/views/trafficSessionStatistics/TrafficSessionStatisticsGraph.vue";
import { mapState } from "vuex";
import ThresholdSettingApi, {
  ThresholdInfoGetEntity,
} from "@/apis/ThresholdSettingApi";
import cloneDeep from "lodash/cloneDeep";
import { ApiNotFoundError } from "@/error/errors";
import moment from "moment";

export default Vue.extend({
  name: "TrafficSessionStatistics",
  components: { TrafficSessionStatisticsGraph },
  data() {
    /** 期間の制限（現在時刻より15分前） */
    const befroeMinutes = 15;
    const currentTime = moment().toISOString();
    const form = {
      /** サービス */
      lineType: "ACCESS" as LineType,
      /** 対象回線選択 */
      targetLine: [] as LineElement[],
      /** 表示範囲 */
      displayRange: "DAILY" as DisplayRange,
      /** 期間（形式: YYYY-MM-DD or YYYY-MM-DDTHH:mm） */
      period: moment(currentTime)
        .add(-befroeMinutes, "minutes")
        .format("YYYY-MM-DDTHH:mm"),
      /** 統計情報取得チェックボックス  */
      isStatsChecked: false,
      /** 統計情報-レポート種別  */
      statisticReportType: "APPLICATION" as StatisticReportType,
      /** 統計情報-ソート順  */
      sortPattern: "SESSION" as SortPattern | null,
    };
    return {
      form,
      /** グラフコンポーネントへ受け渡し用（表示ボタンを押した場合のみ変化） */
      freezeForm: {} as typeof form,
      befroeMinutes,
      currentTime,
      /** 回線毎のトラフィック情報、セッション情報、統計情報、閾値超過情報 */
      graphs: null as
        | null
        | {
            line: LineElement;
            traffic: null | TrafficResponse;
            session: null | SessionResponse;
            statistics: null | StatisticsResponse | ApiNotFoundError;
            thresholdInfo: null | ThresholdInfoGetEntity;
          }[],

      targetLineSearch: {} as TrafficLineSearchResponse,
      isLoaded: false,
    };
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),

    /** 対象回線選択プルダウン選択肢 */
    targetLineOptions(): {
      label: string[];
      values: LineElement[];
    }[] {
      switch (this.form.lineType) {
        case "ACCESS":
          return [
            {
              label: ["E番号", "事業所名", "イントラネットFW適用"],
              values: this.targetLineSearch.lineTypeSearchList
                .filter((e) => e.lineType === "ACCESS")
                .flatMap((e) => e.elementList),
            },
          ];
        case "INTERNET":
          return [
            {
              label: [
                "E番号/W番号",
                "VPN/VNコード",
                "VNネットワーク名",
                "Type4ID",
                "メニュー",
              ],
              values: this.targetLineSearch.lineTypeSearchList
                .filter((e) => e.lineType === "INTERNET")
                .flatMap((e) => e.elementList)
                // Type4+NATはプルダウンに表示しないようにする
                .filter(
                  (e) =>
                    !(e.internetType === "TYPE4" && e.applianceType === "NAT")
                ),
            },
          ];
        case "VN":
          return [
            {
              label: ["W番号", "VNコネクト名", "VPN/VNコード", "紐づきE番号"],
              values: this.targetLineSearch.lineTypeSearchList
                .filter((e) => e.lineType === "VN")
                .flatMap((e) => e.elementList),
            },
          ];
        case "CLOUD":
          return [
            {
              label: ["E番号", "W番号"],
              values: this.targetLineSearch.lineTypeSearchList
                .filter((e) => e.lineType === "CLOUD")
                .flatMap((e) => e.elementList),
            },
          ];
        case "VNL2L3":
          return [
            {
              label: [
                "W番号",
                "VNコネクト名",
                "E番号",
                "L2VNコード",
                "L3VPN/VNコード",
              ],
              values: this.targetLineSearch.lineTypeSearchList
                .filter((e) => e.lineType === "VNL2L3")
                .flatMap((e) => e.elementList),
            },
          ];
        case "MULTICLOUD":
          return [
            {
              label: [
                "クラウド名",
                "E番号",
                "W番号",
                "VNコネクト名",
                "VNネットワーク名",
                "VPN/VNコード",
              ],
              values: this.targetLineSearch.lineTypeSearchList
                .filter((e) => e.lineType === "MULTICLOUD")
                .flatMap((e) => e.elementList),
            },
          ];
        case "CPA_5GSA":
          return [
            {
              label: ["W番号", "ドメイン名", "VPNコード"],
              values: this.targetLineSearch.lineTypeSearchList
                .filter((e) => e.lineType === "CPA_5GSA")
                .flatMap((e) => e.elementList),
            },
          ];
        default:
          throw new Error(`unknown lineType. ${this.form.lineType}`);
      }
    },

    /** レポート種別の選択肢 */
    reportOptions(): { value: StatisticReportType; text: string }[] {
      if (this.form.lineType === "INTERNET") {
        if (this.form.targetLine.find((e) => e.applianceType === "UTM")) {
          return [
            { value: "APPLICATION", text: "アプリケーション" },
            {
              value: "SOURCE_ADDRESS_SESSIONS_BYTE",
              text: "通信元アドレス",
            },
            { value: "DESTINATION_ADDRESS", text: "通信先アドレス" },
            { value: "DESTINATION", text: "通信先(国別)" },
            { value: "THREAT", text: "脅威" },
            {
              value: "SOURCE_ADDRESS_DETECTIONS",
              text: "通信元アドレス(検知回数)",
            },
            { value: "BLOCK_SOURCE_ADDRESS", text: "ブロック通信元アドレス" },
            { value: "BLOCK_URL", text: "ブロックURL" },
            { value: "VIRUS", text: "ウイルス" },
          ];
        } else {
          return [
            { value: "APPLICATION", text: "アプリケーション" },
            {
              value: "SOURCE_ADDRESS_SESSIONS_BYTE",
              text: "通信元アドレス",
            },
            { value: "DESTINATION_ADDRESS", text: "通信先アドレス" },
            { value: "DESTINATION", text: "通信先(国別)" },
          ];
        }
      } else {
        return [];
      }
    },

    /** true: 統計情報取得チェックボックス非活性, false: 活性 */
    isDisabledStats(): boolean {
      // 表示範囲が日時 + 期間が前日以前の場合のみ活性
      if (this.periodDateTime) {
        // applianceTypeがある且つNAT以外の場合、統計情報が検索可能
        const statisticsTarget = this.form.targetLine.filter(
          (v) => v.applianceType && v.applianceType !== "NAT"
        );
        return (
          this.form.displayRange !== "DAILY" ||
          this.$moment(this.periodDateTime.periodDate).isSameOrAfter(
            this.$moment(),
            "days"
          ) ||
          statisticsTarget.length === 0
        );
      } else {
        return true;
      }
    },

    /** ソート種別 */
    sortOptions(): { value: SortPattern | null; text: string }[] {
      switch (this.form.statisticReportType) {
        case "APPLICATION":
          return [
            { value: "SESSION", text: "セッション数" },
            { value: "BYTE", text: "バイト" },
          ];
        /** 通信元アドレス */
        case "SOURCE_ADDRESS_SESSIONS_BYTE":
          return [{ value: "BYTE", text: "バイト" }];
        /** 通信先アドレス */
        case "DESTINATION_ADDRESS":
          return [
            { value: "SESSION", text: "セッション数" },
            { value: "BYTE", text: "バイト" },
          ];
        /** 通信先（国別） */
        case "DESTINATION":
          return [
            { value: "SESSION", text: "セッション数" },
            { value: "BYTE", text: "バイト" },
          ];
        /** 脅威 */
        case "THREAT":
          return [{ value: null, text: "検知回数" }];
        /** 通信元アドレス(検知回数) */
        case "SOURCE_ADDRESS_DETECTIONS":
          return [{ value: null, text: "検知回数" }];
        /** ブロック通信元アドレス */
        case "BLOCK_SOURCE_ADDRESS":
          return [{ value: null, text: "検知回数" }];
        /** ウイルス */
        case "VIRUS":
          return [{ value: null, text: "検知回数" }];
        /** ブロック URL */
        case "BLOCK_URL":
          return [{ value: null, text: "検知回数" }];
        default:
          throw new Error(
            `unknown statisticReportType. ${this.form.statisticReportType}`
          );
      }
    },

    /** APIで使用できる期間情報 */
    periodDateTime(): { periodDate: string; periodTime: null | string } | null {
      if (this.form.period) {
        const period = this.$moment(this.form.period);
        if (this.form.displayRange === "DAILY") {
          return {
            periodDate: period.format("YYYY-MM-DD"),
            periodTime: period.format("HH:mm"),
          };
        } else {
          return { periodDate: period.format("YYYY-MM-DD"), periodTime: null };
        }
      } else {
        return null;
      }
    },
  },
  watch: {
    /** サービス変更時は対象回線選択を初期化 */
    "form.lineType"() {
      this.form.targetLine = [];
    },

    /** 表示範囲が変更された場合は期間、統計情報取得を初期化 */
    "form.displayRange"(value) {
      switch (value) {
        case "DAILY":
          this.form.period = this.$moment(this.currentTime)
            .add(-this.befroeMinutes, "minutes")
            .format("YYYY-MM-DDTHH:mm");
          return;
        case "WEEKLY":
        case "MONTHLY":
          this.form.period = this.$moment(this.currentTime)
            .add(-this.befroeMinutes, "minutes")
            .format("YYYY-MM-DD");
          return;
        default:
          this.form.period = "";
      }
    },

    /** 統計情報取得チェックボックス活性・非活性が変更された場合はチェック状態を初期化 */
    isDisabledStats() {
      this.form.isStatsChecked = false;
    },

    /** レポート種別の選択肢が変更された場合は期間、レポート種別を初期化 */
    reportOptions() {
      this.form.statisticReportType = "APPLICATION";
    },

    /** ソートの選択肢が変更された場合は期間、ソート順を初期化 */
    sortOptions() {
      this.form.sortPattern = this.sortOptions[0]
        ? this.sortOptions[0].value
        : null;
    },
  },
  async mounted() {
    await this.load();
    // クエリが設定されている場合はサービスと対象回線選択の初期値を設定
    if (this.$route.query.lineType) {
      this.form.lineType = this.$route.query.lineType as LineType;
      // watchが動くので次のtick
      this.$nextTick(() => {
        this.form.targetLine = this.targetLineOptions[0].values.filter(
          (e) =>
            e.enumberMainAct === this.$route.query.enumber ||
            e.wnumberMainAct === this.$route.query.wnumber
        );
      });
    }
    this.isLoaded = true;
  },
  methods: {
    async load() {
      /** 回線選択情報取得 */
      this.targetLineSearch = await this.$api.traffic.getLineSearchList();
      // 主キー相当の項目が無いため、こちらでインデックスを作成
      this.targetLineSearch.lineTypeSearchList
        .flatMap((e) => e.elementList)
        .numbering();
      this.targetLineSearch.lineTypeSearchList.forEach((line) => {
        if (line.lineType === "MULTICLOUD") {
          // AWS > MS（MS内は更に数値の照準） > GCP > Oracle > IBMの順にソート
          line.elementList.sort((a, b) => {
            const sortBy = (e: LineElement): number => {
              switch (e.cloudServiceMenu) {
                case "AWS":
                  return 1;
                case "GCP":
                  return 3;
                case "Oracle":
                  return 4;
                case "IBM":
                  return 5;
                case "SFDC":
                  return 6;
                default:
                  return 2;
              }
            };
            const sortA = sortBy(a);
            const sortB = sortBy(b);
            if (sortA === 2 && sortB === 2) {
              return (
                +a.cloudServiceMenu!.replace("MS", "") -
                +b.cloudServiceMenu!.replace("MS", "")
              );
            }
            return sortA - sortB;
          });
        }
      });
    },

    /** リセットボタン */
    reset() {
      this.form.targetLine = [];
    },

    /** グラフ表示 */
    async showGraphs() {
      this.graphs = null;
      this.freezeForm = cloneDeep(this.form);

      // アクセス回線 + イントラネットは1回だけ取得すれば良いため事前に取得し各回線のデータは同じデータを設定
      let intranetSession: null | Promise<SessionResponse | null> = null;
      if (this.form.lineType === "ACCESS") {
        const intranetLine = this.form.targetLine.find(
          (e) => e.applianceType === "INTRANET_FW"
        );
        if (intranetLine) {
          intranetSession = this.getSession(intranetLine);
        }
      }

      // マルチクラウド回線 + 同じVPN/VNコードは1回だけ取得すれば良いため事前に取得し各回線のデータは同じデータを設定
      let vpnVnSession: {
        vpnVnCode: string;
        value: Promise<SessionResponse | null>;
      }[] =
        this.form.lineType === "MULTICLOUD"
          ? this.form.targetLine.uniq("vpnVnCode").map((e) => ({
              vpnVnCode: e.vpnVnCode!,
              value: this.getSession(e),
            }))
          : [];

      this.graphs = await Promise.all(
        this.form.targetLine.map(async (line) => {
          return {
            line: line,
            traffic: await this.getTraffic(line),
            session: await (intranetSession
              ? intranetSession
              : vpnVnSession.find((v) => v.vpnVnCode === line.vpnVnCode)
              ? vpnVnSession.find((v) => v.vpnVnCode === line.vpnVnCode)!.value
              : this.getSession(line)),
            statistics: await this.getStatistics(line),
            thresholdInfo: await this.getThresholdInfo(line),
          };
        })
      );
    },

    /** トラフィックログ情報取得 */
    getTraffic(line: LineElement): Promise<TrafficResponse | null> {
      // インターネット回線且つNATの場合、トラフィックは検索しない
      if (this.form.lineType === "INTERNET" && line.applianceType === "NAT") {
        return Promise.resolve(null);
      } else {
        return this.$api.traffic.getTraffic(
          createTrafficInfoParams(
            this.form.lineType,
            line as LineElement & {
              applianceType: Exclude<ApplianceType, "NAT"> | null;
            },
            this.form.displayRange,
            this.periodDateTime
          )
        );
      }
    },

    /** セッション情報取得 */
    getSession(line: LineElement): Promise<SessionResponse | null> {
      switch (this.form.lineType) {
        case "ACCESS":
          if (line.applianceType === "INTRANET_FW") {
            return this.$api.traffic.getSession({
              lineType: "ACCESS",
              applianceType: "INTRANET_FW",
              displayRange: this.form.displayRange,
              ...this.periodDateTime,
            });
          } else {
            return Promise.resolve(null);
          }
        case "INTERNET":
          // TYPE1のIFW + UTM + NATまたはTYPE4のIFW + UTMの場合、取得する
          // TYPE4のNATは選択肢にそもそも見えないはずので、ガイドしてない
          if (line.applianceType) {
            return this.$api.traffic.getSession({
              lineType: "INTERNET",
              internetType: line.internetType!,
              type4Id: line.internetType === "TYPE4" ? line.type4Id : null,
              applianceType: line.applianceType,
              displayRange: this.form.displayRange,
              ...this.periodDateTime,
            });
          } else {
            return Promise.resolve(null);
          }
        case "MULTICLOUD":
          // VN単位 + MSの場合のみ取得
          if (line.wnumberMainAct && line.cloudServiceMenu!.startsWith("MS")) {
            return this.$api.traffic.getSession({
              lineType: "MULTICLOUD",
              applianceType: "NAT",
              vpnVnCode: line.vpnVnCode,
              displayRange: this.form.displayRange,
              ...this.periodDateTime,
            });
          } else {
            return Promise.resolve(null);
          }
        case "VN":
        case "CLOUD":
        case "VNL2L3":
        case "CPA_5GSA":
          return Promise.resolve(null);
        default:
          return Promise.reject(`unknown line type. ${this.form.lineType}`);
      }
    },

    /** 閾値超過情報取得 */
    getThresholdInfo(
      line: LineElement
    ): Promise<ThresholdInfoGetEntity | null> {
      // インターネット回線且つNATの場合、閾値は検索しない
      if (this.form.lineType === "INTERNET" && line.applianceType === "NAT") {
        return Promise.resolve(null);
      } else {
        return this.$api.thresholdSetting.getThresholdInfo(
          createThresholdInfoParams(
            this.form.lineType,
            line as LineElement & {
              applianceType: Exclude<ApplianceType, "NAT"> | null;
            }
          )
        );
      }
    },

    /** 統計情報取得 */
    async getStatistics(
      line: LineElement
    ): Promise<StatisticsResponse | null | ApiNotFoundError> {
      if (
        this.form.lineType === "INTERNET" &&
        line.applianceType &&
        line.applianceType !== "NAT" &&
        this.form.isStatsChecked
      ) {
        switch (this.form.statisticReportType) {
          case "APPLICATION":
          case "SOURCE_ADDRESS_SESSIONS_BYTE":
          case "DESTINATION_ADDRESS":
          case "DESTINATION":
            break;
          // 以下のレポート種別は回線種別がUTMの場合のみ
          case "THREAT":
          case "SOURCE_ADDRESS_DETECTIONS":
          case "BLOCK_SOURCE_ADDRESS":
          case "VIRUS":
          case "BLOCK_URL":
            if (line.applianceType !== "UTM") {
              return Promise.resolve(null);
            }
        }
        try {
          const StatisticsResponse = await this.$api.traffic.getStatistics({
            internetType: line.internetType!,
            type4Id: line.internetType === "TYPE4" ? line.type4Id : null,
            applianceType: line.applianceType as "INTERNET_FW" | "UTM",
            statisticReportType: this.form.statisticReportType,
            sortPattern: this.form.sortPattern,
            targetDate: this.periodDateTime!.periodDate,
          });
          return Promise.resolve(StatisticsResponse);
        } catch (e) {
          // 統計情報はない場合404がが返ってくるため、APIエラーメッセージを表示
          if (e instanceof ApiNotFoundError) {
            return Promise.resolve(e);
          }
          throw e;
        }
      }
      return Promise.resolve(null);
    },

    /**対象回線選択プルダウンの複数条件を表示*/
    selectedLineInfo(line: LineElement) {
      switch (this.form.lineType) {
        case "ACCESS":
          return `${
            line.enumberMainSby
              ? `${line.enumberMainAct}/${line.enumberMainSby}`
              : `${line.enumberMainAct} `
          } / ${line.office} / ${this.$filter.enumTo(
            line.applianceType,
            "trafficApplianceType"
          )}`;
        case "INTERNET":
          return `${
            line.internetType === "TYPE1"
              ? line.enumberMainAct
              : line.wnumberMainAct
          } / ${this.$filter.emptyTo(
            line.vpnVnCode,
            "-"
          )} / ${this.$filter.emptyTo(
            line.vnName,
            "-"
          )} / ${this.$filter.emptyTo(
            line.type4Id,
            "-"
          )} / ${this.internetMenuEnumTo(
            line.applianceType,
            line.internetType
          )}`;
        case "VN":
          return `${line.wnumberMainAct} / ${line.vnConnectName} / ${
            line.vpnVnCode
          } / ${this.$filter.emptyTo(line.enumberMainAct, "-")}`;
        case "CLOUD":
          return `${this.$filter.emptyTo(line.enumberMainAct, "-")} / ${
            line.wnumberMainAct
          }`;
        case "VNL2L3":
          return `${line.wnumberMainAct} / ${line.vnConnectName} / ${line.enumberMainAct} / ${line.l2VnCode} / ${line.l3VpnVnCode}`;
        case "MULTICLOUD":
          return `${this.displayCloudMenu(line)} / ${
            line.enumberMainAct
          } / ${this.$filter.emptyTo(
            line.wnumberMainAct,
            "-"
          )} / ${this.$filter.emptyTo(
            line.vnConnectName,
            "-"
          )} / ${this.$filter.emptyTo(
            line.vnName,
            "-"
          )} / ${this.$filter.emptyTo(line.vpnVnCode, "-")} `;
        case "CPA_5GSA":
          return `${line.wnumberMainAct} / ${line.domain} / ${line.vpnVnCode}`;
      }
    },

    /** クラウドメニューの表示文字列を生成 */
    displayCloudMenu(line: LineElement): string {
      switch (line.cloudServiceMenu) {
        case "AWS":
          return "AWS";
        case "GCP":
          return "Google Cloud";
        case "IBM":
          return "IBM Cloud";
        case "Oracle":
          return "Oracle Cloud";
        case "SFDC":
          return "Salesforce";
        default:
          if (line.isMsPeering === null) {
            return line.cloudServiceMenu!.replace("MS", "Microsoft");
          } else if (line.isMsPeering) {
            return `${line.cloudServiceMenu!.replace(
              "MS",
              "Microsoft"
            )} Microsoft Peering`;
          } else {
            return `${line.cloudServiceMenu!.replace(
              "MS",
              "Microsoft"
            )} Private Peering`;
          }
      }
    },

    /** インターネット回線選択時のメニュー項目の値取得 */
    internetMenuEnumTo(
      applianceTypeValue: string | null,
      internetTypeValue: string | null
    ) {
      if (applianceTypeValue) {
        return this.$filter.enumTo(applianceTypeValue, "trafficApplianceType");
      } else {
        return this.$filter.enumTo(internetTypeValue, "trafficInternetType");
      }
    },
  },
});

/**
 * 閾値超過通知設定取得のクエリパラメーターを生成
 * @param lineType サービス種別
 * @param line 回線情報
 */
export function createThresholdInfoParams(
  lineType: LineType,
  line: LineElement & { applianceType: Exclude<ApplianceType, "NAT"> | null }
): Parameters<ThresholdSettingApi["getThresholdInfo"]>[0] {
  switch (lineType) {
    case "ACCESS":
      return {
        lineType: "ACCESS",
        enumber: line.enumberMainAct,
        applianceType: line.applianceType,
      };
    case "INTERNET":
      return {
        lineType: "INTERNET",
        enumber: line.internetType === "TYPE1" ? line.enumberMainAct : null,
        wnumber: line.internetType === "TYPE4" ? line.wnumberMainAct : null,
        internetType: line.internetType,
        type4Id: line.internetType === "TYPE4" ? line.type4Id : null,
        applianceType: line.applianceType,
      };
    case "VN":
      return {
        lineType: "VN",
        wnumber: line.wnumberMainAct,
      };
    case "CLOUD":
      return {
        lineType: "CLOUD",
        wnumber: line.wnumberMainAct,
      };
    case "VNL2L3":
      return {
        lineType: "VNL2L3",
        wnumber: line.wnumberMainAct,
      };
    case "MULTICLOUD":
      return {
        lineType: "MULTICLOUD",
        enumber: line.wnumberMainAct ? null : line.enumberMainAct,
        wnumber: line.wnumberMainAct ? line.wnumberMainAct : null,
        cloudServiceMenu: line.wnumberMainAct ? null : line.cloudServiceMenu,
      };
    case "CPA_5GSA":
      return {
        lineType: "CPA_5GSA",
        wnumber: line.wnumberMainAct,
      };
  }
}

/** トラフィックログ情報取得のクエリパラメーターを生成
 * @param lineType サービス種別
 * @param line 回線情報
 * @param displayRange 表示範囲
 * @param periodDateTime 日時
 * */
export function createTrafficInfoParams(
  lineType: LineType,
  line: LineElement & { applianceType: Exclude<ApplianceType, "NAT"> | null },
  displayRange: DisplayRange,
  periodDateTime: { periodDate: string; periodTime: null | string } | null
): Parameters<TrafficApi["getTraffic"]>[0] {
  switch (lineType) {
    case "ACCESS":
      return {
        lineType: "ACCESS",
        enumberMainAct: line.enumberMainAct,
        applianceType:
          line.applianceType === "INTRANET_FW" ? "INTRANET_FW" : undefined,
        displayRange: displayRange,
        ...periodDateTime,
      };
    case "INTERNET":
      return {
        lineType: "INTERNET",
        internetType: line.internetType,
        type4Id: line.internetType === "TYPE4" ? line.type4Id : null,
        applianceType: line.applianceType,
        displayRange: displayRange,
        ...periodDateTime,
      };
    case "VN":
      return {
        lineType: "VN",
        wnumberMain: line.wnumberMainAct,
        displayRange: displayRange,
        ...periodDateTime,
      };
    case "CLOUD":
      return {
        lineType: "CLOUD",
        wnumberMain: line.wnumberMainAct,
        displayRange: displayRange,
        ...periodDateTime,
      };
    case "VNL2L3":
      return {
        lineType: "VNL2L3",
        wnumberMain: line.wnumberMainAct,
        displayRange: displayRange,
        ...periodDateTime,
      };
    case "MULTICLOUD":
      return {
        lineType: "MULTICLOUD",
        enumberMainAct: line.wnumberMainAct ? null : line.enumberMainAct,
        wnumberMain: line.wnumberMainAct,
        cloudServiceMenu: line.wnumberMainAct ? null : line.cloudServiceMenu,
        displayRange: displayRange,
        ...periodDateTime,
      };
    case "CPA_5GSA":
      return {
        lineType: "CPA_5GSA",
        wnumberMain: line.wnumberMainAct,
        displayRange: displayRange,
        ...periodDateTime,
      };
  }
}

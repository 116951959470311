









































































































































































import Vue, { PropType } from "vue";
import { QosDetail } from "@/apis/QosApi";
import QosSettingEdit from "@/modals/qos/QosSettingEdit.vue";

export default Vue.extend({
  name: "QosSettingViewRC",
  props: {
    // 変更画面で使用
    vpnVnCode: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    // 変更画面で使用
    vnName: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    // R or C or I (Reference or Confirm or Information)
    mode: {
      type: String as PropType<string>,
      required: false,
      default: "R",
    },
    isNew: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    qosDetail: {
      type: Object as PropType<QosDetail>,
      required: true,
    },
  },
  data() {
    return {
      absolutePriorityRowNames: [
        { key: "HIGH", value: "High" },
        { key: "MEDIUM", value: "Medium" },
        { key: "NORMAL", value: "Normal" },
        { key: "LOW", value: "Low" },
      ],
      absolutePriorityPropNames: [
        "priority0",
        "priority1",
        "priority2",
        "priority3",
        "priority4",
        "priority5",
        "priority6",
        "priority7",
      ],
      bandwidthRowNames: [
        { key: "PATTERN_A", value: "パターンA" },
        { key: "PATTERN_A", value: "" },
        { key: "PATTERN_B", value: "パターンB" },
        { key: "PATTERN_B", value: "" },
        { key: "PATTERN_C", value: "パターンC" },
        { key: "PATTERN_C", value: "" },
        { key: "PATTERN_D", value: "パターンD" },
        { key: "PATTERN_D", value: "" },
        { key: "PATTERN_E", value: "パターンE" },
        { key: "PATTERN_E", value: "" },
      ],
      bandwidthPropNames: [
        "priorityType1Value",
        "priorityType2Value",
        "priorityType3Value",
        "bestEffort",
      ],
    };
  },
  methods: {
    async showModifyModal() {
      await this.$modal.show(QosSettingEdit, {
        vpnVnCode: this.vpnVnCode,
        vnName: this.vnName,
        isNew: this.isNew,
        qosDetail: this.qosDetail,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * 帯域制御方式の値を取得
     * @param patternName パターン名
     * @param propName プロパティ名
     * */
    getBandwidthValue: function (patternName: string, propName: string) {
      const item = this.qosDetail.qosBandwidth?.qosBandwidthList?.find(
        (e) => e.patternName === patternName
      );
      if (item) {
        if (propName === "bestEffort") {
          return (item.priorityType1Value || 0) +
            (item.priorityType2Value || 0) +
            (item.priorityType3Value || 0)
            ? `${
                100 -
                ((item.priorityType1Value || 0) +
                  (item.priorityType2Value || 0) +
                  (item.priorityType3Value || 0))
              }%`
            : "-";
        } else {
          switch (propName) {
            case "priorityType1Value":
              return item.priorityType1Value
                ? `${item.priorityType1Value}%`
                : "-";
            case "priorityType2Value":
              return item.priorityType2Value
                ? `${item.priorityType2Value}%`
                : "-";
            case "priorityType3Value":
              return item.priorityType3Value
                ? `${item.priorityType3Value}%`
                : "-";
          }
        }
      } else {
        return "-";
      }
    },
    /**
     * 帯域制御方式の最優先設定フラグを取得
     * @param patternName パターン名
     * */
    isType1TopPriority(patternName: string) {
      const item = this.qosDetail.qosBandwidth?.qosBandwidthList?.find(
        (e) => e.patternName === patternName
      );
      if (item) {
        return item.isType1TopPriority ? "ON" : "OFF";
      } else {
        return "-";
      }
    },
  },
});

import { AxiosInstance, AxiosRequestConfig } from "axios";
import { Store } from "vuex";
import { RootState } from "@/store";

export default abstract class AbstractBaseApi {
  constructor(
    protected api: AxiosInstance,
    protected store: Store<RootState>
  ) {}

  /**
   * baseURLも含むフルURLを取得
   * @param url URLパス
   * @param config リクエストコンフィグ
   */
  getFullURL(url: string, config?: AxiosRequestConfig): string {
    const uri = this.api.getUri({ ...config, url: url });
    return `${this.api.defaults.baseURL}${uri}`;
  }
}






































































































































































































import Vue, { PropType } from "vue";
import { NatIpEntity, NatNaptDetailEntity } from "@/apis/MSPeeringIpApi";
import MSNatSettingModifyConfirm from "@/modals/msNatSetting/MSNatSettingModifyConfirm.vue";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "MSNatSettingModify",
  props: {
    /** NAT用IP設定情報 */
    natSettingDetails: {
      type: Object as PropType<NatNaptDetailEntity>,
      required: true,
    },
    /** 設定変更時、使用する排他キー */
    msPeeringIpKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      natSetting: cloneDeep(this.natSettingDetails.msNatIpList),
      deletedIp: [] as NatIpEntity[],
      newIp:
        this.natSettingDetails.usedNat === 0
          ? [
              {
                privateIpAddress: "",
                description: "",
              },
            ]
          : [],
    };
  },
  computed: {
    /** プライベートIPアドレスの重複チェック用リスト作成 */
    privateIpList() {
      return (index: number) => {
        return [...this.natSetting, ...this.newIp]
          .map((e) => e.privateIpAddress)
          .filter((_, num) => num !== index);
      };
    },
    /** true: 変更不可, false: 変更可能 */
    canNotEdit(): boolean {
      return (
        this.newIp.length > 0 ||
        (this.newIp.length === 0 &&
          this.natSetting.length !== this.natSettingDetails.msNatIpList.length)
      );
    },
    /** true: 追加可能, false: 追加不可 */
    canAdd(): boolean {
      return (
        this.natSettingDetails.msNatIpList.length + this.newIp.length <
        this.natSettingDetails.upperLimitNat
      );
    },
    /** NAT用グルーバルIP追加/削除・払出済みIP設定変更操作の活性・非活性
     * true: 変更箇所有り, false: 変更箇所無し
     */
    isEdited(): boolean {
      const orgPaidIp = this.natSettingDetails.msNatIpList;
      return this.natSetting.length === orgPaidIp.length &&
        this.natSetting.find((v: NatIpEntity, i: number) => {
          return (
            v.privateIpAddress !== orgPaidIp[i].privateIpAddress ||
            v.description !== orgPaidIp[i].description
          );
        })
        ? true
        : false;
    },
  },
  methods: {
    /** NATグローバルIP削除処理(払出ずみ) */
    removeNat(index: number) {
      const deleted = this.natSetting.splice(index, 1);
      this.deletedIp = [...this.deletedIp, ...deleted];
    },
    /** NAT用グローバルIP追加処理 */
    addNewIp() {
      this.newIp = [
        ...this.newIp,
        {
          privateIpAddress: "",
          description: "",
        },
      ];
    },
    /** NATグローバルIP削除処理(払い出してないIP) */
    removeNewIp(index: number) {
      this.newIp.splice(index, 1);
    },

    async submit() {
      await this.$modal.show(MSNatSettingModifyConfirm, {
        natSetting: this.natSetting,
        currentInfo: this.natSettingDetails,
        newIp: this.newIp,
      });

      if (!this.isEdited) {
        /** 払出済みグローバルIP設定削除 + グローバルIP追加 */
        await this.$api.msPeeringIp.updateGlobalIp(
          this.natSettingDetails.msServiceSeq,
          {
            addNapt: null,
            addNat: this.newIp.length,
            msNatAddPrivateIpList: this.newIp.map((e) => ({
              privateIpAddress: e.privateIpAddress,
              description: e.description,
            })),
            deleteMsNaptGlobalIpList: null,
            deleteMsNatGlobalIpList: this.deletedIp.map(
              (e) => e.cloudGlobalIpAddressSeq
            ),
            msPeeringIpKeyId: this.msPeeringIpKeyId,
          }
        );
        (this.$refs.modal as Vue & { ok: () => void }).ok();
      } else {
        /** 払出済みグローバルIP設定変更 */
        await this.$api.msPeeringIp.addNat(
          this.natSettingDetails.msServiceSeq,
          {
            msNatSettingList: this.natSetting.map((e) => ({
              cloudGlobalIpAddressSeq: e.cloudGlobalIpAddressSeq,
              privateIpAddress: e.privateIpAddress,
              description: e.description,
            })),
            msPeeringIpKeyId: this.msPeeringIpKeyId,
          }
        );
        (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('provisioning-status',{on:{"refresh":_vm.load}}),_c('b-card',[_c('b-card-title',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"flex-fill"},[_vm._v("マルチクラウド一覧")]),_c('manual-button',{attrs:{"path":"/multi-cloud-list/"}})],1)]),(_vm.isLoaded)?_c('b-card-body',[_c('div',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("クラウド接続")]),_c('div',{staticClass:"btn-container justify-content-end mb-2"},[(_vm.cloudVnResettingResult.cloudVnConnectTotal > 0)?_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"danger"},on:{"click":_vm.showCloudVnResetting}},[_vm._v("VNコネクト設定未完了一覧")]):_vm._e(),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.load}},[_vm._v("一覧更新")])],1),_c('app-table',{attrs:{"primary-key":"lineSeq","sort-by":"enumber","sort-desc":true,"fields":_vm.lineList.fields,"items":_vm.lineList.items,"hover":true,"tbody-tr-class":_vm.rowHighlight,"thead-class":"text-center"},on:{"row-clicked":_vm.showCloudServices},scopedSlots:_vm._u([{key:"cell(accessType)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(item.accessType,"cloudVn_accessType"))+"("+_vm._s(_vm._f("bandwidthTo")(item.bandwidth,"Mbps"))+") ")]}}],null,false,3521803505)})],1),(_vm.hasSelectedLine)?_c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("クラウドサービス")]),_c('div',{staticClass:"btn-container justify-content-end"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.addMS}},[_vm._v("Microsoft追加 ")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.deleteMS}},[_vm._v("Microsoft削除 ")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.showBandwidthLimit}},[_vm._v("帯域上限値設定 ")]),_c('a',{attrs:{"href":_vm.downloadUrl,"download":""}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v("一覧ダウンロード")])],1)],1),_c('b-alert',{staticClass:"mt-2",attrs:{"variant":"warning","show":!!_vm.alertMsg}},[_vm._v(_vm._s(_vm.alertMsg)+" ")]),_c('div',{staticClass:"mt-4"},[_c('app-table',{attrs:{"primary-key":"cloudLineSeq","fields":_vm.lineCloudList.fields,"items":_vm.lineCloudList.items,"selectable":_vm.selectable,"hover":true,"thead-class":"text-center","tbody-tr-class":_vm.rowClass,"sticky-header":"50vh"},on:{"row-clicked":_vm.showDetails},scopedSlots:_vm._u([{key:"cell(_selected)",fn:function(data){return [(data.item.cloudServiceMenu.startsWith('MS'))?_c('div',[_c('b-form-checkbox',{attrs:{"size":"lg","value":data.item},model:{value:(_vm.lineCloudList.selected),callback:function ($$v) {_vm.$set(_vm.lineCloudList, "selected", $$v)},expression:"lineCloudList.selected"}})],1):_vm._e()]}},{key:"cell(cloudServiceMenu)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cloudServiceMenuTo")(item.cloudServiceMenu))+" ")]}},{key:"cell(bandwidth)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("bandwidthTo")(item.bandwidth,"Mbps"))+" ")]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(item.description,"-"))+" ")]}}],null,false,2112106556),model:{value:(_vm.lineCloudList.selected),callback:function ($$v) {_vm.$set(_vm.lineCloudList, "selected", $$v)},expression:"lineCloudList.selected"}})],1)],1):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
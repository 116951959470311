import { AxiosResponse } from "axios";

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** 閾値超過・復旧情報一覧のAPI用インターフェイス */
export interface AlarmInfoGetEntity {
  /**
   * 総件数
   * 一覧表示対象の総件数
   */
  total: number;
  /**
   * 閾値超過・復旧情報一覧
   * 空の場合は空配列
   */
  alarmInfoList: AlarmInfoEntity[];
}

export interface AlarmInfoEntity {
  /**
   * 回線種別
   * ACCESS    ：アクセス回線(E番号を指定)
   * INTERNET  ：インターネット回線(インターネット回線種別、E番号(Type1の場合のみ)、W番号(Type4の場合のみ)、Type4ID(Type4の場合のみ)、アプライアンス種別を指定)
   * VN        ：VNコネクト(W番号を指定)
   * CLOUD     ：クラウド接続(W番号を指定)
   * VNL2L3    ：VN L2/L3コネクト(W番号を指定)
   * MULTICLOUD：マルチクラウド種別(クラウド帯域単位の場合はE番号、クラウドサービスメニューを指定、VNコネクト単位の場合はW番号を指定)
   * CPA_5GSA  ：CPA 5GSA(W番号を指定)
   */
  lineType:
    | "ACCESS"
    | "INTERNET"
    | "VN"
    | "CLOUD"
    | "VNL2L3"
    | "MULTICLOUD"
    | "CPA_5GSA";
  /**
   * E番号 主キー
   * アクセス回線、インターネット回線(Type1)、マルチクラウド（クラウド帯域単位）の場合、必須とする。
   */
  enumber: string | null;
  /**
   * W番号 主キー
   * インターネット回線(Type4)、VNコネクト、クラウド接続、VNL2L3コネクト、
   * マルチクラウド（VNコネクト単位）、リモートアクセスGWの場合、必須とする。
   */
  wnumber: string | null;
  /**
   * インターネット回線種別
   TYPE1  ：インターネットType1
   TYPE4  ：インターネットType4
   */
  internetType: "TYPE1" | "TYPE4" | null;
  /**
   * Type4ID
   * インターネット回線種別がインターネットType4の場合、指定する。
   */
  type4Id: string | null;
  /**
   * クラウドサービスメニュー
   * クラウド回線の主キー
   * マルチクラウドの場合のみ設定
   * その他の回線種別の場合はnull
   * 以下の値を指定可能
   * AWS、GCP、Oracle、IBM
   * MS1 (数字は1～9999999999まで指定可能)
   */
  cloudServiceMenu: string | null;
  /**
   * アプライアンス種別
   * INTERNET_FW：インターネットFW
   * UTM：UTM
   * INTRANET_FW：イントラネットFW
   ※ 回線種別がアクセス回線の場合、イントラネットFWが選択可能
   * 回線種別がインターネット回線の場合、インターネットFW, UTMが選択可能
   * アプライアンスを指定しない場合はnul
   */
  applianceType: "INTERNET_FW" | "UTM" | "INTRANET_FW" | null;
  /**
   * 送受信種別
   * SEND：送信
   * RECEIVE：受信
   */
  direction: "SEND" | "RECEIVE";
  /**
   * 超過日時
   * 閾値を超過した日時
   */
  exceedThresholdDateTime: string;
  /**
   * 復旧日時
   * 閾値超過を復旧した日時
   */
  restorationDateTime: string | null;
  /**
   * アラーム種別
   * EXCESS：超過
   * RESTORATION：復旧
   */
  alarmType: "EXCESS" | "RESTORATION";
}

/** 閾値超過通知設定取得のAPI用インターフェイス */
export interface ThresholdInfoGetEntity {
  /**
   * 送信閾値
   * GET時に登録された送信閾値が存在しない場合は「0」を指定する。
   * POST時にnullの場合、閾値設定を削除する。
   */
  sendThreshold: ThresholdEntity;
  /**
   * 受信閾値
   * GET時に登録された受信閾値が存在しない場合は「0」を指定する。
   * POST時にnullの場合、閾値設定を削除する。
   */
  receiveThreshold: ThresholdEntity;
  /**
   * メールアドレスリスト
   * 通知先リスト
   * 空の場合は空配列
   */
  mailAddressList: string[];
  /**
   * 閾値設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  thresholdKeyId: string;
}

export interface ThresholdEntity {
  /**
   * 閾値
   * POST時に「0」の場合、閾値設定を削除する。
   */
  value: number;
  /**
   * 単位. 以下のいずれかを設定する。
   * 閾値が「0」の場合、nullとなる
   * KBPS: Kbps
   * MBPS: Mbps
   * GBPS: Gbps
   */
  unit: "KBPS" | "MBPS" | "GBPS" | null;
}

/** 閾値超過通知設定更新のAPI用インターフェイス */
export interface ThresholdInfoUpdateEntity {
  /**
   * 送信閾値
   * GET時に登録された送信閾値が存在しない場合は「0」を指定する。
   * POST時にnullの場合、閾値設定を削除する。
   */
  sendThreshold: ThresholdEntity | null;
  /**
   * 受信閾値
   * GET時に登録された受信閾値が存在しない場合は「0」を指定する。
   * POST時にnullの場合、閾値設定を削除する。
   */
  receiveThreshold: ThresholdEntity | null;
  /**
   * メールアドレスリスト
   * 通知先リスト
   * 空の場合は空配列
   */
  mailAddressList: string[];
  /**
   * 閾値設定排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  thresholdKeyId: string;
  /** 回線情報 */
  lineInfo: LineInfoEntity;
}

export interface LineInfoEntity {
  /**
   * 回線種別
   * ACCESS    ：アクセス回線(E番号を指定)
   * INTERNET  ：インターネット回線(インターネット回線種別、E番号(Type1の場合のみ)、W番号(Type4の場合のみ)、Type4ID(Type4の場合のみ)、アプライアンス種別を指定)
   * VN        ：VNコネクト(W番号を指定)
   * CLOUD     ：クラウド接続(W番号を指定)
   * VNL2L3    ：VN L2/L3コネクト(W番号を指定)
   * MULTICLOUD：マルチクラウド種別(クラウド帯域単位の場合はE番号、クラウドサービスメニューを指定、VNコネクト単位の場合はW番号を指定)
   * CPA_5GSA  ：CPA 5GSA(W番号を指定)
   */
  lineType: AlarmInfoEntity["lineType"];
  /**
   * E番号 主キー
   * アクセス回線、インターネット回線(Type1)、マルチクラウド（クラウド帯域単位）の場合、必須とする。
   */
  enumber?: string | null;
  /**
   * W番号 主キー
   * インターネット回線(Type4)、VNコネクト、クラウド接続、VNL2L3コネクト、
   * マルチクラウド（VNコネクト単位）、リモートアクセスGWの場合、必須とする。
   */
  wnumber?: string | null;
  /**
   * インターネット回線種別
   * TYPE1  ：インターネットType1
   * TYPE4  ：インターネットType4
   */
  internetType?: AlarmInfoEntity["internetType"];
  /**
   * Type4ID
   * インターネット回線種別がインターネットType4の場合、指定する。
   */
  type4Id?: string | null;
  /**
   * クラウドサービスメニュー
   * クラウド回線の主キー
   * マルチクラウドの場合のみ設定
   * その他の回線種別の場合はnull
   * 以下の値を指定可能
   * AWS、GCP、Oracle、IBM
   * MS1 (数字は1～9999999999まで指定可能)
   */
  cloudServiceMenu?: string | null;
  /**
   * アプライアンス種別
   * INTERNET_FW：インターネットFW
   * UTM：UTM
   * INTRANET_FW：イントラネットFW
   * 回線種別がアクセス回線の場合、イントラネットFWが選択可能
   * 回線種別がインターネット回線の場合、インターネットFW, UTMが選択可能
   * アプライアンスを指定しない場合はnul
   */
  applianceType?: AlarmInfoEntity["applianceType"];
}

export default class ThresholdSettingApi extends AbstractBaseApi {
  async getAlarmInfo(): Promise<AlarmInfoEntity[]> {
    const { data } = await this.api.get<AlarmInfoGetEntity>(
      "/v2/report/traffic/threshold/alarmInfo"
    );
    return data.alarmInfoList;
  }

  /** クエリパラメータ - 必須（lineType）, 任意（enumber, wnumber, internetType, type4Id, cloudServiceMenu, applianceType） */
  async getThresholdInfo(queries: {
    /**
     * 回線種別
     * ACCESS    ：アクセス回線(E番号を指定)
     * INTERNET  ：インターネット回線(インターネット回線種別、E番号(Type1の場合のみ)、W番号(Type4の場合のみ)、Type4ID(Type4の場合のみ)、アプライアンス種別を指定)
     * VN        ：VNコネクト(W番号を指定)
     * CLOUD     ：クラウド接続(W番号を指定)
     * VNL2L3    ：VN L2/L3コネクト(W番号を指定)
     * MULTICLOUD：マルチクラウド種別(クラウド帯域単位の場合はE番号、クラウドサービスメニューを指定、VNコネクト単位の場合はW番号を指定)
     * CPA_5GSA  ：CPA 5GSA(W番号を指定)
     */
    lineType: AlarmInfoEntity["lineType"];
    /**
     * E番号 主キー
     * * アクセス回線、インターネット回線(Type1)、マルチクラウド（クラウド帯域単位）の場合、必須とする。
     */
    enumber?: string | null;
    /**
     * W番号 主キー
     * インターネット回線(Type4)、VNコネクト、クラウド接続、VNL2L3コネクト、
     * マルチクラウド（VNコネクト単位）、リモートアクセスGWの場合、必須とする。
     */
    wnumber?: string | null;
    /**
     * インターネット回線種別
     * TYPE1  ：インターネットType1
     * TYPE4  ：インターネットType4
     */
    internetType?: AlarmInfoEntity["internetType"];
    /**
     * Type4ID
     * インターネット回線種別がインターネットType4の場合、指定する。
     */
    type4Id?: string | null;
    /**
     * クラウドサービスメニュー
     * クラウド回線の主キー
     * マルチクラウドの場合のみ設定
     * その他の回線種別の場合はnull
     * 以下の値を指定可能
     * AWS、GCP、Oracle、IBM、SFDC
     * MS1 (数字は1～9999999999まで指定可能)
     */
    cloudServiceMenu?: string | null;
    /** アプライアンス種別
     * INTERNET_FW：インターネットFW
     * UTM：UTM
     * INTRANET_FW：イントラネットFW
     * 回線種別がアクセス回線の場合、イントラネットFWが選択可能
     * 回線種別がインターネット回線の場合、インターネットFW, UTMが選択可能
     * アプライアンスを指定しない場合はnul
     */
    applianceType?: AlarmInfoEntity["applianceType"];
  }): Promise<ThresholdInfoGetEntity> {
    return (
      await this.api.get("/v2/report/traffic/threshold", {
        params: { ...queries },
      })
    ).data;
  }

  async update(data: ThresholdInfoUpdateEntity): Promise<AxiosResponse> {
    return (await this.api.post("/v2/report/traffic/threshold", data)).data;
  }
}

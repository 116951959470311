































































import Vue, { PropType } from "vue";
import { IpPool, IpPoolDelete } from "@/apis/IpPoolApi";
import IpPoolEdit from "@/modals/applianceObject/IpPoolEdit.vue";

export default Vue.extend({
  name: "IpPoolReference",
  props: {
    ipPool: {
      type: Object as PropType<IpPool>,
      required: true,
    },
    ipPoolKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    type4Id: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  methods: {
    /**
     * IP Poolの削除処理
     */
    async deleteIpPool(): Promise<void> {
      // ポリシー設定有無が有りの場合は処理を終了
      if (this.ipPool.isPolicyInUse) return;

      // 確認ダイアログの表示
      await this.$confirm("IP Poolを削除します。よろしいですか？");

      // リクエスト用データの作成
      const reqData: IpPoolDelete = {
        ipPoolList: [this.ipPool.ipPoolSeq],
        note: null,
        ipPoolKeyId: this.ipPoolKeyId,
      };

      // IP Pool削除APIを呼び出す
      if (!this.type4Id) {
        await this.$api.ipPool.deleteIpPoolType1(reqData);
      } else {
        await this.$api.ipPool.deleteIpPoolType4(reqData, this.type4Id);
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /**
     * 変更モーダルを表示する
     */
    async showModifyIpPoolModal(): Promise<void> {
      await this.$modal.show(IpPoolEdit, {
        ipPool: this.ipPool,
        ipPoolKeyId: this.ipPoolKeyId,
        type4Id: this.type4Id,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

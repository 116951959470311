










































































































































































































































































































































import Vue, { PropType } from "vue";
import { GetPolicyType4 } from "@/apis/NwPolicyApi";
import { Type4IpMasqueradePolicy } from "@/modals/applianceContractSetting/InternetType4PolicyRuleIpMasqViewRD.vue";
import {
  Type4ExtraSite,
  Type4InternetAddress,
  Type4InternetSite,
} from "@/apis/Type4SiteApi";
import cloneDeep from "lodash/cloneDeep";
import { IpPool } from "@/apis/IpPoolApi";
import MultiSelectTag from "@/components/MultiSelectTag.vue";
import InternetType4PolicyRuleIpMasqConfirm from "@/modals/applianceContractSetting/InternetType4PolicyRuleIpMasqConfirm.vue";
import Type4ExtraSiteList from "@/modals/type4/Type4ExtraSiteList.vue";
import Type4InternetSiteModify from "@/modals/type4/Type4InternetSiteModify.vue";

export default Vue.extend({
  name: "InternetType4PolicyRuleIpMasqEdit",
  components: { MultiSelectTag },
  props: {
    /** Type4Id. 主キー */
    type4Id: {
      type: String as PropType<string>,
      required: true,
    },
    /** 変更対象ポリシー */
    policy: {
      type: Object as PropType<Type4IpMasqueradePolicy>,
      default: undefined,
    },
    /** type4ポリシー全体 */
    type4Policy: {
      type: Object as PropType<GetPolicyType4>,
      required: true,
    },
  },
  data() {
    const clonePolicy = cloneDeep(this.policy);
    const form: Type4IpMasqueradePolicy = clonePolicy ?? {
      srcAddressList: [],
      dstAddressList: [],
      ipPoolList: [],
      isPolicyStatus: true,
      policyCategoryInfo: "CUSTOM_POLICY",
    };

    return {
      form,
      // 宛先（any/アドレス）
      radioDstAddress: (form.dstAddressList.length === 0 ? "ANY" : "SELECT") as
        | "ANY"
        | "SELECT",
      // エクストラサイト（アドレス一覧）
      extraSiteAddresses: [] as Type4ExtraSite["siteList"][0]["addressList"],
      // インターネット（アドレス一覧）
      internetAddresses: [] as Type4InternetSite["addressList"],
      // IP Pool一覧
      ipPools: [] as IpPool[],

      isLoaded: false,
    };
  },
  computed: {
    /** true: 編集, false: 新規 */
    isEdit(): boolean {
      return !!this.policy;
    },
    /** 送信元アドレスのSelectの選択肢 */
    srcAddresses(): {
      label: string;
      values: Type4ExtraSite["siteList"][0]["addressList"];
    }[] {
      return this.extraSiteAddresses.length !== 0
        ? [
            {
              label: "全選択",
              values: this.extraSiteAddresses,
            },
          ]
        : [];
    },
    /** 宛先アドレスのSelectの選択肢 */
    dstAddresses(): {
      label: string;
      values: Type4InternetSite["addressList"];
    }[] {
      return this.internetAddresses.length !== 0
        ? [
            {
              label: "全選択",
              values: this.internetAddresses,
            },
          ]
        : [];
    },
    /** IP PoolのSelectの選択肢 */
    ipPoolOptions(): {
      label: string;
      values: IpPool[];
    }[] {
      return this.ipPools.length !== 0
        ? [
            {
              label: "全選択",
              values: this.ipPools,
            },
          ]
        : [];
    },
    /** 既存のtype4ポリシーID一覧 */
    existsPolicyIds(): string[] {
      const p = this.type4Policy;
      return [
        ...(p.policyUtmType4?.accessPointToInternetPolicyList ?? []),
        ...(p.policyUtmType4?.internetToAccessPointPolicyList ?? []),
        ...(p.policyIfwType4?.accessPointToInternetPolicyList ?? []),
        ...(p.policyIfwType4?.internetToAccessPointPolicyList ?? []),
        ...(p.policyIPMasqueradeType4?.policyList ?? []),
        ...(p.policyNatType4?.policyList ?? []),
      ].map((e) => e.policyId);
    },
  },
  async mounted() {
    // 選択肢などの必要情報を取得
    this.extraSiteAddresses = (
      await this.$api.type4SiteApi.getType4ExtraSite(this.type4Id)
    ).siteList
      .flatMap((e) => e.addressList)
      .sortBy(["ipAddress", "asc"]);
    const internetSite = await this.$api.type4SiteApi.getType4InternetSite(
      this.type4Id
    );
    this.internetAddresses = [
      ...internetSite.addressList.sortBy(["ipAddress", "asc"]),
    ];
    this.ipPools = (
      await this.$api.ipPool.getIpPoolType4(this.type4Id)
    ).ipPoolList;

    this.isLoaded = true;
  },
  methods: {
    /** ルール編集の完了 */
    async submit() {
      const policy = {
        ...this.form,
        srcAddressList: this.form.srcAddressList,
        dstAddressList:
          this.radioDstAddress === "ANY" ? [] : this.form.dstAddressList,
      } as Type4IpMasqueradePolicy;

      await this.$modal.show(InternetType4PolicyRuleIpMasqConfirm, { policy });
      (
        this.$refs.modal as Vue & { ok: (e: Type4IpMasqueradePolicy) => void }
      ).ok(policy);
    },
    /** アドレス選択肢ラベル名 */
    addressLabel({
      ipAddressName,
      ipAddress,
    }:
      | Type4InternetAddress
      | Type4ExtraSite["siteList"][0]["addressList"][0]): string {
      return `${ipAddressName}  ${ipAddress}`;
    },
    /** IP Poolラベル名（検索用） */
    ipPoolLabel({ ipPoolName, globalIpAddress, description }: IpPool) {
      return `${ipPoolName} ${globalIpAddress} ${description}`;
    },
    /** エクストラサイト編集 */
    async showExtraSiteEdit() {
      const extraSites = await this.$api.type4SiteApi.getType4ExtraSite(
        this.type4Id
      );
      await this.$modal.show(Type4ExtraSiteList, {
        extraSite: extraSites,
        type4Id: this.type4Id,
      });
      // エクストラアドレスリスト更新
      this.isLoaded = false;
      this.extraSiteAddresses = (
        await this.$api.type4SiteApi.getType4ExtraSite(this.type4Id)
      ).siteList
        .flatMap((e) => e.addressList)
        .sortBy(["ipAddress", "asc"]);
      this.isLoaded = true;
    },
    /** インターネットサイト編集 */
    async showInternetSiteEdit() {
      let internetSite = await this.$api.type4SiteApi.getType4InternetSite(
        this.type4Id
      );
      await this.$modal.show(Type4InternetSiteModify, {
        internetSite: internetSite,
        type4Id: this.type4Id,
      });
      // インターネットアドレスリスト更新
      this.isLoaded = false;
      internetSite = await this.$api.type4SiteApi.getType4InternetSite(
        this.type4Id
      );
      this.internetAddresses = [
        ...internetSite.addressList.sortBy(["ipAddress", "asc"]),
      ];
      this.isLoaded = true;
    },
  },
});

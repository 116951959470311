var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"確認","classes":"modal-full"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"detail-container mt-2"},[_c('div',{staticClass:"font-weight-bold mb-3"},[_vm._v(" ポリシーを設定反映します。よろしいですか？ ")]),_c('div',[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6",attrs:{"label":"回線番号(メイン)"}},[_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.type1Service.enumberMainAct)+"/"+_vm._s(_vm.type1Service.enumberMainSby)+" ")])]),_c('b-form-group',{staticClass:"col-6",attrs:{"label":"回線番号(BCP)"}},[_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.type1Service.enumberBcpAct)+"/"+_vm._s(_vm.type1Service.enumberBcpSby)+" ")])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"VPNコード"}},[_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.type1Service.vpnCode)+" ")])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"Type"}},[_c('span',{staticClass:"ml-3"},[_vm._v("インターネットType1")])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"セキュリティアプライアンス"}},[_c('span',{staticClass:"ml-3"},[_vm._v(" UTM(1Gbpsベストエフォート) ")])]),_c('b-form-group',{staticClass:"col-12",attrs:{"label":"状態"}},[_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm._f("enumTo")(_vm.type1Service.status,"status")))])])],1),_vm._l((_vm.displayZoneInfo),function(obj){return _c('b-form-group',{key:obj.relation.zoneRelationId,attrs:{"label":_vm.$filter.enumTo(obj.relation.srcZoneType, 'zoneType') +
              '/' +
              obj.relation.srcZoneName +
              ' → ' +
              _vm.$filter.enumTo(obj.relation.dstZoneType, 'zoneType') +
              '/' +
              obj.relation.dstZoneName,"label-class":"font-weight-bold"}},[(_vm.contractSummary.isNat)?_c('b-form-group',{staticClass:"mt-2",attrs:{"label":obj.relation.srcZoneType === 'INTERNET'
                  ? 'NAT(IP Forwarding)'
                  : 'NAT(IP Masquerade)'}},[(obj.relation.srcZoneType === 'INTERNET')?_c('b-table-simple',{attrs:{"small":""}},[_c('b-thead',{staticClass:"text-center small",attrs:{"head-variant":"light"}},[_c('b-tr',[_c('b-th',[_vm._v("ID")]),_c('b-th',[_vm._v("送信元アドレス")]),_c('b-th',[_vm._v("IPマッピング")]),_c('b-th',[_vm._v("有効/無効")]),_c('b-th',[_vm._v("備考")])],1)],1),_c('b-tbody',_vm._l((obj.natPolicyList),function(item){return _c('b-tr',{key:item.policyId},[_c('b-td',[_vm._v(_vm._s(item.policyId))]),_c('b-td',[_c('policy-src-address',{attrs:{"src-address":{ srcAddressList: item.srcAddressList }}})],1),_c('b-td',[_vm._v(" "+_vm._s(item.ipMapping ? item.ipMapping.ipMappingName : "-")+" ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("enumTo")(item.isPolicyStatus,"enable"))+" ")]),_c('b-td',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(_vm._f("emptyTo")(item.description,"-")))])],1)}),1)],1):_c('b-table-simple',{attrs:{"small":""}},[_c('b-thead',{staticClass:"text-center small",attrs:{"head-variant":"light"}},[_c('b-tr',[_c('b-th',[_vm._v("ID")]),_c('b-th',[_vm._v("送信元アドレス")]),_c('b-th',[_vm._v("宛先アドレス")]),_c('b-th',[_vm._v("IP Pool")]),_c('b-th',[_vm._v("有効/無効")]),_c('b-th',[_vm._v("備考")])],1)],1),_c('b-tbody',_vm._l((obj.natPolicyList),function(item){return _c('b-tr',{key:item.policyId,class:{
                      'not-editable-policy':
                        item.policyCategoryInfo ===
                        'UNEDITABLE_DEFAULT_POLICY',
                    }},[_c('b-td',[_vm._v(_vm._s(item.policyId))]),_c('b-td',[_c('policy-src-address',{attrs:{"src-address":{ srcAddressList: item.srcAddressList }}})],1),_c('b-td',[_c('policy-dst-address',{attrs:{"dst-address":{ dstAddressList: item.dstAddressList }}})],1),_c('b-td',[_c('policy-ip-pool',{attrs:{"ip-pool-list":item.ipPoolList}})],1),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("enumTo")(item.isPolicyStatus,"enable"))+" ")]),_c('b-td',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(_vm._f("emptyTo")(item.description,"-")))])],1)}),1)],1)],1):_vm._e(),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"UTM"}},[_c('b-table-simple',{attrs:{"small":""}},[_c('b-thead',{staticClass:"text-center small",attrs:{"head-variant":"light"}},[_c('b-tr',[_c('b-th',[_vm._v("ID")]),_c('b-th',[_vm._v("送信元アドレス")]),_c('b-th',[_vm._v("宛先アドレス")]),_c('b-th',[_vm._v("サービス")]),_c('b-th',[_vm._v("プロファイル")]),_c('b-th',[_vm._v("アクション")]),_c('b-th',[_vm._v("ログ出力")]),_c('b-th',[_vm._v("有効/無効")]),_c('b-th',[_vm._v("備考")])],1)],1),_c('b-tbody',_vm._l((obj.utmPolicyList),function(item){return _c('b-tr',{key:item.policyId,class:{
                      'not-editable-policy':
                        item.policyCategoryInfo ===
                        'UNEDITABLE_DEFAULT_POLICY',
                    }},[_c('b-td',[_vm._v(_vm._s(item.policyId))]),_c('b-td',[_c('policy-src-address',{attrs:{"src-address":item.srcAddress}})],1),_c('b-td',[_c('policy-dst-address',{attrs:{"dst-address":item.dstAddress}})],1),_c('b-td',[_c('policy-service',{attrs:{"service-option":item.serviceOption,"default-service-list":item.defaultServiceList,"custom-service-list":item.customServiceList}})],1),_c('b-td',[_c('policy-profile',{attrs:{"profile":item.profile}})],1),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("enumTo")(item.actionType,"allow_deny"))+" ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("enumTo")(item.isLogOutput,"on_off"))+" ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("enumTo")(item.isPolicyStatus,"enable"))+" ")]),_c('b-td',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(_vm._f("emptyTo")(item.description,"-")))])],1)}),1)],1)],1)],1)})],2)])]),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":ok}},[_vm._v("はい")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("いいえ")])],1)]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import is_not_inclusion_subnet from "./is-not-inclusion-subnet";

// 以下の特定のプライベートアドレスレンジが入力できないこと
// ・172.31.0.0/16
export default {
  validate(value: string | null | undefined): boolean {
    if (!value) return true;

    if (
      !is_not_inclusion_subnet.validate?.(value, { list: ["172.31.0.0/16"] })
    ) {
      return false;
    }
    return true;
  },
} as ValidationRuleSchema;

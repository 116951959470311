




































































import Vue, { PropType } from "vue";
import { PfgwZone, PfgwZonePut } from "@/apis/PfgwZoneApi";

export default Vue.extend({
  name: "IntranetFwPfgwZoneRelationRegister",
  props: {
    enumberAct: {
      type: String as PropType<string>,
      required: true,
    },
    pfgwZone: {
      type: Object as PropType<PfgwZone>,
      required: true,
    },
  },
  data() {
    return {
      srcZoneId: "",
      dstZoneId: "",
      srcZoneList: [] as { value: string; text: string }[],
      dstZoneList: [] as { value: string; text: string }[],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      const zoneList = [
        { value: this.pfgwZone.wvs2ZoneId, text: "WVS2" },
        { value: this.pfgwZone.kcpsZoneId, text: "KCPS" },
      ];
      // 0件の場合に両方向設定可能とする。
      if (this.pfgwZone.zoneIdList.length === 0) {
        this.srcZoneList = zoneList;
        this.dstZoneList = zoneList;
      } else {
        // 設定済ゾーンの逆方向のリストを作成
        this.srcZoneList = [
          {
            value: this.pfgwZone.zoneIdList[0].dstZoneId,
            text:
              this.pfgwZone.zoneIdList[0].dstZoneId === this.pfgwZone.wvs2ZoneId
                ? "WVS2"
                : "KCPS",
          },
        ];
        this.dstZoneList = [
          {
            value: this.pfgwZone.zoneIdList[0].srcZoneId,
            text:
              this.pfgwZone.zoneIdList[0].srcZoneId === this.pfgwZone.wvs2ZoneId
                ? "WVS2"
                : "KCPS",
          },
        ];
      }
    },
    /** 追加ボタン押下時の挙動*/
    async submit() {
      await this.$confirm("ゾーン接続を追加します。よろしいですか？");

      const reqData: PfgwZonePut = {
        wvs2AddressList: this.pfgwZone.wvs2AddressList.map((e) => e.ipAddress),
        kcpsAddressList: this.pfgwZone.kcpsAddressList.map((e) => e.ipAddress),
        zoneIdList: [
          ...this.pfgwZone.zoneIdList,
          { srcZoneId: this.srcZoneId, dstZoneId: this.dstZoneId },
        ],
        pfgwAddressKeyId: this.pfgwZone.pfgwAddressKeyId,
      };

      await this.$api.pfgwZoneRelation.putPfgwZone(this.enumberAct, reqData);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"sa-nw-reference-modal","title":"CPA 5G SA設定情報"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"btn-container justify-content-end mb-3"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.confirmTerms}},[_vm._v(" 約款参照 ")]),(
            (_vm.cpaContractInfo.status === 'ACTIVE_UPDATE' ||
              _vm.cpaContractInfo.status === 'INACTIVE') &&
            _vm.isUpdate()
          )?[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config.delegate",modifiers:{"config":true,"delegate":true}}],attrs:{"variant":"info"},on:{"click":_vm.settingUpdate}},[_vm._v(" データ反映 ")])]:_vm._e(),(
            (_vm.cpaContractInfo.status === 'ACTIVE' ||
              _vm.cpaContractInfo.status === 'ACTIVE_UPDATE') &&
            _vm.cpaContractInfo.authenticationType !== 'HOMERADIUS'
          )?[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.admin",modifiers:{"admin":true}}],attrs:{"variant":"primary"},on:{"click":_vm.idRegister}},[_vm._v(" 認証ID登録 ")])]:_vm._e(),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.showAuthCpaIdInfo}},[_vm._v(" 認証ID情報画面 ")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config.delegate",modifiers:{"config":true,"delegate":true}}],attrs:{"variant":"info"},on:{"click":_vm.cpaNwSetting}},[_vm._v(" NW設定 ")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.admin.delegate",modifiers:{"admin":true,"delegate":true}}],attrs:{"variant":"info"},on:{"click":_vm.terminateContract}},[_vm._v(" 解約 ")])],2),(_vm.isLoaded)?_c('div',[_c('SaNwContractInfo',{attrs:{"cpaContractInfo":_vm.cpaContractInfo}}),(_vm.cpaContractInfo.wnumber)?_c('SaNwSettingInfo',{attrs:{"cpaContractInfo":_vm.cpaContractInfo,"nwSettingDetail":_vm.nwSettingDetail,"nwOptions":_vm.nwOptions,"isUpdateRequiredRed":true}}):_vm._e()],1):_vm._e()]),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("戻る")])],1)]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
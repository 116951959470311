import sortCompare, { Order } from "@/services/SortService";
// eslint-disable-next-line no-restricted-imports
import uniq from "lodash/uniq";
// eslint-disable-next-line no-restricted-imports
import uniqBy from "lodash/uniqBy";
// eslint-disable-next-line no-restricted-imports
import range from "lodash/range";

export {};

declare global {
  interface Array<T> {
    toMap<K extends string | number>(
      getKey: (value: T) => K
    ): { [key in K]: T };

    /**
     * 配列をkey,valueのdictionaryに変換
     * keyは重複しない想定
     * @param getKey keyを取得する関数
     * @param getValue valueを取得する関数
     */
    toMap<K extends string | number, R>(
      getKey: (value: T) => K,
      getValue?: (value: T) => R
    ): { [key in K]: R };

    groupBy<K extends string | number>(
      getKey: (value: T) => K
    ): { [key in K]: T[] };

    /**
     * 配列をkey,value[]のdictionaryに変換
     * 同一キーは配列としてまとめられる
     * @param getKey keyを取得する関数
     * @param getValue valueを取得する関数
     */
    groupBy<K extends string | number, R>(
      getKey: (value: T) => K,
      getValue?: (value: T) => R
    ): { [key in K]: R[] };

    /**
     * 配列を指定した順序で並び替えた新しい配列を返す
     * @param order asc: 昇順, desc: 降順
     */
    sortBy<T extends string>(order: "asc" | "desc"): T[];

    /**
     * 配列を指定した順序で並び替えた新しい配列を返す
     * @param order asc: 昇順, desc: 降順
     */
    sortBy<T extends number>(order: "asc" | "desc"): T[];

    /**
     * 配列を指定した順序で並び替えた新しい配列を返す
     * sortとは違いシャローコピーされるため副作用は無い
     * @param orders sortCompareのorders参照
     * @see sortCompare
     */
    sortBy(...orders: Order<T>[]): T[];

    /**
     * 重複を削除した新しい配列を返す
     * @param key 未指定の場合は単純な比較
     *            プロパティ名を指定した場合はその値
     *            functionの場合は戻り値で重複削除
     */
    uniq(key?: keyof T | ((e: T) => unknown)): T[];

    /**
     * 配列の要素内に重複しない一意な番号を採番する.
     * 番号が採番されるプロパティ名は「_index」
     * vueの表示上どうしても主キー相当の情報が欲しい時用（ドラッグ有り + 新規作成有りなど）
     */
    numbering(): void;

    /** 配列の要素からnumberingで採番したプロパティを除去する */
    removeNumbering(): void;

    /**
     * 次の採番番号を取得し指定したオブジェクトにセット
     * @param obj オブジェクト
     */
    nextNumber(obj: T): T;
  }

  interface ArrayConstructor {
    /**
     * 0 ~ 指定された個数の配列を作成
     * 例: 5の場合は1, 2, 3, 4, 5
     * @param i 個数
     */
    range(start: number, end: number, step?: number): Array<number>;

    range(end: number): Array<number>;
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */

Array.prototype.toMap = function (
  getKey: (value: any) => string | number,
  getValue: (value: any) => any = (value) => value
): { [key in string | number]: any } {
  return Object.fromEntries(this.map((e) => [getKey(e), getValue(e)]));
};

Array.prototype.groupBy = function (
  getKey: (value: any) => string | number,
  getValue: (value: any) => any = (value) => value
): { [key in string | number]: any[] } {
  return this.reduce((acc, e) => {
    const key = getKey(e);
    const group: any[] | undefined = acc[key];
    if (group) {
      return { ...acc, [key]: [...group, getValue(e)] };
    } else {
      return { ...acc, [key]: [getValue(e)] };
    }
  }, {});
};

Array.prototype.sortBy = function (...orders: any): any[] {
  if (typeof orders[0] === "string") {
    if (orders[0] === "asc") {
      return this.slice().sort();
    } else {
      return this.slice().sort().reverse();
    }
  } else {
    return this.slice().sort((a, b) => sortCompare(a, b, orders));
  }
};

Array.prototype.uniq = function (key): any[] {
  if (key) {
    return uniqBy(this, key);
  } else {
    return uniq(this);
  }
};

Array.prototype.numbering = function () {
  this.forEach((e, index) => (e._index = index));
};

Array.prototype.removeNumbering = function () {
  this.forEach((e) => delete e._index);
};

Array.prototype.nextNumber = function (obj) {
  const max = this.reduce((acc: number, e): number => {
    return acc < e._index ? e._index : acc;
  }, -1);
  obj._index = max === -1 ? 0 : max + 1;
  return obj;
};

Array.range = function (start: number, end?: number, step?: number) {
  return range(start, end, step);
};

/* eslint-enable */

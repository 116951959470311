









































































































































































































































































import Vue, { PropType } from "vue";
import { ContractType, RegistrationResult, SendType } from "@/apis/Cpa5gSaApi";
import { AppTableData } from "@/components/AppTable.vue";
import SaIdRegisterHistoryReference from "@/modals/cpa5g/SaIdRegisterHistoryReference.vue";
import Encoding from "encoding-japanese";

export default Vue.extend({
  name: "SaIdRegister",
  props: {
    /** CPA契約SEQ */
    cpaContractSeq: {
      type: String as PropType<string>,
      required: true,
    },
    /** 契約タイプ */
    contractType: {
      type: String as PropType<ContractType>,
      required: true,
    },
  },
  data() {
    return {
      downloadUrl: this.$api.cpa5gSa.downloadCpaNwCsvURL(this.cpaContractSeq),
      showRegHisFlag: false,
      alertMsg: null as null | string,
      form: {
        sendType: "IMMEDIATE" as SendType,
        sendDate: "" as string | null,
        sendTime: "" as string | null,
        sendDateTime: "" as string | null,
        isImmediateReflect: false,
      },
      idRegHitory: {
        fields: [
          {
            key: "remoteServiceType",
            label: "サービス種別",
            sortable: true,
          },
          {
            key: "sendType",
            label: "処理種別",
            sortable: true,
          },
          {
            key: "registrationDateTime",
            label: "一括登録時刻",
            sortable: true,
          },
          {
            key: "status",
            label: "ステータス",
          },
        ],
        items: [],
        search: {
          remoteServiceType: {
            type: "select",
            label: "サービス種別",
            items: [{ value: "CPA", text: "CPA" }],
          },
          sendType: {
            type: "select",
            label: "処理種別",
            items: [
              { value: "IMMEDIATE", text: "即時" },
              { value: "RESERVE", text: "送信日時指定" },
            ],
          },
          registrationDateTime: {
            type: "custom",
            label: "一括登録時刻",
          },
          status: {
            type: "select",
            label: "ステータス",
            items: [
              { value: "COMPLETE", text: "登録完了" },
              { value: "REGISTER", text: "登録中" },
              { value: "FAILURE", text: "登録エラー" },
              { value: "RESERVE", text: "予約中" },
              { value: "CANCEL", text: "予約キャンセル" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          remoteServiceType: "equal",
          sendType: "equal",
          registrationDateTime: (
            item,
            value: { start: string; end: string }
          ) => {
            return this.$moment(item.registrationDateTime).isBetween(
              this.$moment(value.start),
              this.$moment(value.end),
              undefined,
              "[]"
            );
          },
          status: "equal",
        },
      } as Omit<AppTableData<RegistrationResult>, "selected">,
    };
  },

  computed: {
    /**
     * true: 最新のステータス（表の最初の行のステータス）が予約中になっている場合
     * false: 最新のステータス（表の最初の行のステータス）が予約中以外になっている場合
     */
    canCancel(): boolean {
      return (
        this.idRegHitory.items.length > 0 &&
        this.idRegHitory.items[0].status === "RESERVE"
      );
    },
    /**
     * true: 最新のステータス（表の最初の行のステータス）が登録中になっている場合
     * false: 最新のステータス（表の最初の行のステータス）が登録中以外になっている場合
     */
    isWarning(): boolean {
      return (
        this.idRegHitory.items.length > 0 &&
        this.idRegHitory.items[0].status === "REGISTER"
      );
    },
    /** 当日以降の日付が選択可能 */
    minSendDate(): string {
      return this.$moment().format("YYYY-MM-DD");
    },

    /**
     * 時間指定の最小値
     * 当日の場合：現在時刻の時間+1
     * それ以外：指定無し
     * 例：現在が2022/09/16 10:35の場合
     * 日付の指定も2022/09/16の場合：11時以降を指定
     * 日付の指定は2022/09/17の場合：時間の制限は無し(0〜23まで指定可能)
     */
    minTime(): number {
      // フォーマットを入力に合わせて比較
      if (this.$moment().format("YYYY-MM-DD") === this.form.sendDate) {
        return this.$moment().hours() + 1;
      } else {
        return 0;
      }
    },
    /** 指定可能最大日数 */
    maxDays(): number {
      // moment-diffの仕様はミリ秒まで比較している
      // そして1ミリ秒でも差分が小さければ、1日としてカウントしないため、00秒で今日の日付を取得しておく
      const today = this.$moment().format("YYYY/MM/DD 00:00:00");

      return this.$moment().add(1, "M").diff(this.$moment(today), "days");
    },
  },

  watch: {
    /** 送信元ゾーンが選択された際に宛先ゾーンが選択不可な値が入っていたらクリア */
    "form.sendType"(val: SendType) {
      if (val === "RESERVE") {
        /** 「送信日時指定」に変更した際、 端末認証即時反映をデフォルト値に設定する */
        this.form.isImmediateReflect = false;
      }
    },
  },

  methods: {
    /** ID登録予約キャンセル */
    async cancelIdReservation() {
      this.alertMsg = null;
      await this.$confirm(
        "ID登録予約のキャンセルを実施します。よろしいですか？"
      );
      await this.$api.cpa5gSa.cancelAuthIdReservation(
        this.cpaContractSeq,
        this.idRegHitory.items[0].fileKey
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 登録履歴表示 */
    async showRegHistories() {
      this.alertMsg = null;
      await this.getRegHistories();
      /** 登録履歴一覧を表示 */
      this.showRegHisFlag = true;
    },
    async getRegHistories() {
      this.alertMsg = null;
      this.idRegHitory.items = (
        await this.$api.cpa5gSa.getAuthIdRegistrationResult(this.cpaContractSeq)
      ).registrationResultList.sortBy(["registrationDateTime", "desc"]);
    },

    /** 登録履歴詳細表示 */
    async regHistoryDetails(entity: RegistrationResult) {
      if (entity.status === "FAILURE") {
        this.alertMsg = null;
        await this.$modal.show(SaIdRegisterHistoryReference, {
          errorDetails: entity.errorDetailList,
        });
        await this.getRegHistories();
      }
    },

    /**
     * 一括ID登録のファイルアップロード処理
     * @param event イベントオブジェクト
     */
    async fileUpload(event: Event | DragEvent) {
      const file = ((): File | null => {
        if (event instanceof DragEvent) {
          return event.dataTransfer?.files?.[0] ?? null;
        } else {
          return (event.target as HTMLInputElement | null)?.files?.[0] ?? null;
        }
      })();

      if (file) {
        // サイズが0の場合はエラー
        if (file.size === 0) {
          this.alertMsg = this.$msg("file_no_size");
          return;
        }
        // ファイルの拡張子がcsvではない場合エラー
        if (!file.name.endsWith("csv")) {
          this.alertMsg = this.$msg("file_invalid_format");
          return;
        }

        // 文字コードチェック
        const fileBuffer = await this.$service.file.readAsArrayBuffer(file);
        const encode = Encoding.detect(new Uint8Array(fileBuffer));
        // エディタ上UTF8であっても、ASCIIと互換性のある1バイト文字しか利用してない場合、結果がASCIIとなる為、
        // ASCIIも許容する。
        if (encode !== "UTF8" && encode !== "ASCII") {
          this.alertMsg =
            "ファイルの文字コードがutf-8ではないため、アップロードできませんでした。";
          return;
        }

        this.alertMsg = null;

        await this.$confirm(
          `${file.name}の内容でIDの一括登録を行います。よろしいですか？
          ${
            this.form.sendType === "RESERVE"
              ? "なお、送信日時指定した一括ID登録は予約され、指定日時以降の5:00~7:00の間に処理が行われます。"
              : ""
          }`
        );

        const response = await this.$api.cpa5gSa.createAuthId(
          this.cpaContractSeq,
          this.form.sendType,
          this.form.sendType === "RESERVE"
            ? this.$moment(
                this.form.sendDate + " " + this.form.sendTime
              ).format("YYYY-MM-DDTHH:00:00+09:00")
            : null,
          this.form.isImmediateReflect,
          new TextDecoder().decode(fileBuffer)
        );
        // リクエストの形式は正しいが、ファイルの内容にエラーがある場合
        if (response.status === 200) {
          let msg =
            "ID登録を受理できませんでした。以下のエラー内容を確認し、再度登録をお願いします。\n";
          response.data.checkErrorList.forEach((e) => {
            msg += `■${e.rowNumber}行目\n`;
            e.checkErrorMessageList.forEach((em) => {
              msg += `・${em}\n`;
            });
          });
          await this.$confirm(msg, "エラー", true);
        } else {
          // 正常終了
          (this.$refs.modal as Vue & { ok: () => void }).ok();
        }
      } else {
        // ファイルが選択されていない場合エラー
        this.alertMsg = this.$msg("csv_no_select");
      }
    },
    /** ファイルを選択クリック時の処理 */
    onClickFileSelect() {
      // 同じファイルを再度アップロードした際にchangeイベントで拾えるようvalueを初期化
      (this.$refs.file as Vue & { value: string | null }).value = null;
      // input要素をクリックして、ファイル選択のウィンドウを開く
      (this.$refs.file as Vue & { click: () => void }).click();
    },

    /** 登録履歴テーブルのtrクラス */
    historyRowClass(item: RegistrationResult): string | null {
      switch (item.status) {
        case "FAILURE":
          return null;
        default:
          return "un-clickable";
      }
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 先頭がocidで入力されること */

export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }
    return /^ocid/.test(value);
  },
} as ValidationRuleSchema;

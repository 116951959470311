

























































































































































































































































































import Vue from "vue";
import { FilterOption } from "@/components/AppTable.vue";
import { SearchOption } from "@/components/AppSearch.vue";
import { ApplianceLogInformation } from "@/apis/ApplianceLogInformationApi";
import {
  Appliance,
  ApplianceLog,
  CreateApplianceLog,
} from "@/apis/ApplianceLogApi";
import { ApiInternalServerError, ApiNotFoundError } from "@/error/errors";
import ApiErrorModal from "@/modals/error/ApiErrorModal.vue";
import ApiNotFoundModal from "@/modals/error/ApiNotFoundModal.vue";

export default Vue.extend({
  name: "ApplianceLogList",
  data() {
    return {
      applianceLogInformation: {} as ApplianceLogInformation | null,
      targetDate: this.$filter.date(new Date()),
      applianceOptions: [
        {
          applianceLabel: {
            lineNum: "回線番号",
            type4Id: "Type4ID",
            applianceType: "アプライアンス名",
          },
          applianceValues: [] as Appliance[],
        },
      ],
      form: {
        applianceSelected: null as Appliance | null,
        timeFrom: "",
        timeTo: "",
      },
      applianceLog: {
        fields: [
          {
            key: "reportDate",
            label: "レポート対象日",
          },
          {
            key: "lineNum",
            label: "回線番号",
            filterByFormatted: true,
            formatter: (value: string, key: null, item: ApplianceLog) => {
              if (item.applianceType === "INTRANET_FW") {
                return this.$store.state.user.contractInfo.vnumber;
              } else {
                if (item.enumber) {
                  return item.enumber;
                } else {
                  return item.wnumber;
                }
              }
            },
          },
          {
            key: "applianceType",
            label: "アプライアンス名",
          },
          {
            key: "fileName",
            label: "ファイル名",
          },
          {
            key: "fileSize",
            label: "ファイルサイズ",
          },
        ],
        items: [] as ApplianceLog[],
        selected: [] as ApplianceLog[],
        search: {
          reportDate: { type: "custom", label: "レポート対象日" },
          lineNum: { type: "text", label: "回線番号" },
          applianceType: {
            type: "select",
            label: "アプライアンス名",
            items: [
              { value: "UTM", text: "UTM" },
              { value: "INTERNET_FW", text: "インターネットFW" },
              { value: "INTRANET_FW", text: "イントラネットFW" },
            ],
          },
        } as SearchOption<ApplianceLog>,

        filter: {} as Record<string, unknown>,
        filter_option: {
          reportDate: (item, value: { start: string; end: string }) => {
            return this.$moment(item.reportDate).isBetween(
              this.$moment(value.start),
              this.$moment(value.end),
              undefined,
              "[]"
            );
          },
          lineNum: "like",
          applianceType: "equal",
        } as FilterOption<ApplianceLog>,
      },
      showAlert: false,
      // 初期通信完了フラグ
      isLoaded: false,
    };
  },
  computed: {
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** アプライアンス選択肢ラベル名 */
    applianceCustomLabel({
      lineNum,
      type4Id,
      applianceType,
    }: Appliance): string {
      return `${lineNum} ${type4Id} ${this.$filter.enumTo(
        applianceType,
        "applianceType"
      )} `;
    },
    convertFileSize(size: number): string {
      let target = size;
      let unit = "KB";

      if (size >= gb) {
        target = gb;
        unit = "GB";
      } else if (size >= mb) {
        target = mb;
        unit = "MB";
      }
      return `${
        size >= mb ? Math.round((size / target) * 10) / 10 : size
      }${unit}`;
    },
    /** 一覧を取得 */
    async load() {
      try {
        this.applianceLogInformation =
          await this.$api.applianceLogInformationApi.getApplianceLogInformation();
      } catch (e) {
        // 404 契約無しの場合に発生する場合
        if (e instanceof ApiNotFoundError) {
          this.applianceLogInformation = null;
        } else {
          // 500 ディスク使用量の取得に失敗
          if (
            e instanceof ApiInternalServerError &&
            e.data.errorCode === "CC06013E"
          ) {
            await this.$modal.show(ApiErrorModal, { error: e });
            return;
          }
          throw e;
        }
      }

      const appliances = await this.$api.contract.getAppliances();
      const applianceList = (
        await this.$api.applianceLogApi.getApplianceLogCreatableList()
      ).applianceList.map((e) => ({
        ...e,
        type4Id:
          appliances.applianceInfoList.find(
            (v) => v.wnumberMainAct === e.wnumber
          )?.type4Id ?? null,
        lineNum:
          e.applianceType === "INTRANET_FW"
            ? this.$store.state.user.contractInfo.vnumber
            : e.enumber || e.wnumber,
      }));
      if (applianceList.length !== 0) {
        this.applianceOptions[0].applianceValues = applianceList;
      } else {
        this.applianceOptions = [];
      }

      try {
        this.applianceLog.items = (
          await this.$api.applianceLogApi.getApplianceLog()
        ).applianceLogList;
      } catch (e) {
        // 404の場合、エラーを外に出さずに
        if (e instanceof ApiNotFoundError) {
          return;
        }
        // 404以外の場合、エラーを外に出す
        throw e;
      }
    },
    /** 当日ログファイル作成 */
    async submit() {
      this.showAlert = false;
      await this.$confirm(
        `指定された時間のファイルを作成します。よろしいですか？`
      );
      const postData: CreateApplianceLog = {
        enumber: this.form.applianceSelected?.enumber || null,
        wnumber: this.form.applianceSelected?.wnumber || null,
        applianceType: this.form.applianceSelected!.applianceType,
        startDateTime:
          this.$moment(this.targetDate + " " + this.form.timeFrom).format(
            "YYYY-MM-DDTHH:mm:00"
          ) + "+09:00",
        endDateTime:
          this.$moment(this.targetDate + " " + this.form.timeTo).format(
            "YYYY-MM-DDTHH:mm:00"
          ) + "+09:00",
      };
      await this.$api.applianceLogApi.postCreateApplianceLog(postData);
      await this.reload();
    },
    /** 一覧更新 */
    async reload() {
      this.showAlert = false;

      try {
        this.applianceLog.items = (
          await this.$api.applianceLogApi.getApplianceLog()
        ).applianceLogList;
      } catch (e) {
        // 404の場合、エラーを外に出さずに
        if (e instanceof ApiNotFoundError) {
          return;
        }
        // 404以外の場合、エラーを外に出す
        throw e;
      }
    },
    /** 削除 */
    async showDel() {
      if (this.applianceLog.selected.length === 0) {
        this.showAlert = true;
        return;
      }
      this.showAlert = false;
      await this.$confirm(`選択されたログを削除します。よろしいですか？`);
      const deleteFiles = this.applianceLog.selected.map((e) => e.fileName);
      try {
        await this.$api.applianceLogApi.deleteApplianceLog(deleteFiles);
        this.applianceLog.selected = [];
        await this.reload();
      } catch (e) {
        // 500 ダウンロードに失敗
        if (
          e instanceof ApiInternalServerError &&
          e.data.errorCode === "CC99012E"
        ) {
          await this.$modal.show(ApiErrorModal, { error: e });
          return;
        }
        throw e;
      }
    },
    /** ファイルダウンロード確認 */
    async showDL(fileName: string) {
      this.showAlert = false;
      /** ダウンロードする前にダウンロード対象のデータが存在するかの確認 */
      const fileCheckItem: boolean = (
        await this.$api.applianceLogApi.getApplianceLog()
      ).applianceLogList.some((e) => e.fileName === fileName);

      if (fileCheckItem) {
        await this.$confirm(
          `指定されたファイルをダウンロードします。よろしいですか？`
        );
        window.open(this.$api.applianceLogApi.downloadFile(fileName), "_blank");
      } else {
        await this.$modal.show(ApiNotFoundModal);
      }
    },
  },
});

const mb = 1024;
const gb = 1024 * 1024;

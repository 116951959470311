import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 全角カナで入力されること */

export default {
  validate(value: string | undefined | null): boolean {
    if (!value) {
      return true;
    }
    return /^[ァ-ヶー]*$/.test(value);
  },
} as ValidationRuleSchema;

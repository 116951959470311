




























































import Vue from "vue";
import { IpSecGWEntity, IpSecGWGetEntity } from "@/apis/IpSecGwApi";
import { AppTableData } from "@/components/AppTable.vue";
import { ContractLineGetEntity } from "@/apis/ContractLineApi";
import IpSecGWReference from "@/modals/ipSecGw/IpSecGWReference.vue";
import LimitModify from "@/modals/common/LimitModify.vue";

/** 表示可能なIPsecGW（いくつかの項目が存在すること） */
export type VisibleIpSecGW = Omit<
  IpSecGWEntity,
  "ipsecGatewayAddress" | "manageAddress1" | "manageAddress2" | "manageAddress3"
> & {
  /** IPsecGWアドレス*/
  ipsecGatewayAddress: string;
  /** 管理用アドレス① */
  manageAddress1: string;
  /** 管理用アドレス② */
  manageAddress2: string;
  /** 管理用アドレス③ */
  manageAddress3: string;
};

export default Vue.extend({
  name: "IpSecGWList",
  data() {
    return {
      gatewayList: {
        fields: [
          {
            key: "enumber",
            label: "IPsecGW回線番号",
            sortable: true,
          },
          {
            key: "suffix",
            label: "サフィックス",
          },
          {
            key: "lineSize",
            label: "IPsec回線数/IPsec回線接続上限数",
            class: "text-right",
          },
        ],
        items: [],
        search: {
          enumber: { type: "text", label: "IPsecGW回線番号" },
          suffix: { type: "text", label: "サフィックス" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          enumber: "like",
          suffix: "like",
        },
      } as Omit<AppTableData<VisibleIpSecGW>, "items" | "selected">,
      ipSecGw: {} as IpSecGWGetEntity,
      contractLine: {} as ContractLineGetEntity,
      isLoaded: false,
    };
  },
  computed: {
    /**
     * 表示可能なIPsecGW.
     * サービス開廃日が未来日じゃない & WANアドレス/IPsecGWアドレス/管理用アドレス①,②,③が登録されている
     */
    items(): VisibleIpSecGW[] {
      const today = this.$moment().startOf("day");
      const contractMap = this.contractLine.contractList.toMap(
        (e) => e.enumber
      );
      return this.ipSecGw.ipsecGatewayList.filter((e) => {
        const startEndDate = contractMap[e.enumber]?.startEndDate;
        return (
          startEndDate &&
          today.isSameOrAfter(this.$moment(startEndDate)) &&
          e.wanAddress != null &&
          e.ipsecGatewayAddress != null &&
          e.manageAddress1 != null &&
          e.manageAddress2 != null &&
          e.manageAddress3 != null
        );
      }) as VisibleIpSecGW[];
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.contractLine = await this.$api.contractLine.getAll();
      this.ipSecGw = await this.$api.ipSecGw.getAll();
    },

    /** 上限数設定 */
    async modifyContractLimit() {
      await this.$modal.show(LimitModify, {
        settingType: "ipSecLine",
      });
      await this.load();
    },

    /** IPsecGW情報モーダル表示 */
    async showIPsecGWReference(entity: IpSecGWEntity) {
      await this.$modal.show(IpSecGWReference, {
        ipsecGW: entity,
        keyId: this.ipSecGw.ipsecGatewayKeyId,
      });
      await this.load();
    },
  },
});

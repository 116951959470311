

























import Vue from "vue";
import MixinSideMenu from "@/mixins/MixinSideMenu.vue";
import {
  SidebarComponentItem,
  SidebarHeaderItem,
  SidebarItem,
} from "vue-sidebar-menu";
import { mapState } from "vuex";
import { ContractSummary } from "@/apis/ContractApi";
import PermissionService from "@/services/PermissionService";

export function getOtherMenus(
  contractSummary: ContractSummary,
  permission: PermissionService
): Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem> {
  const isViewDelegate = permission.isViewDelegate();
  return [
    {
      href: "/user",
      title: "ユーザ",
      icon: { element: "b-icon-people" },
      hidden: isViewDelegate,
    },
    {
      href: "/notification-contact",
      title: "通知連絡先",
      icon: { element: "b-icon-envelope" },
    },
    {
      href: "/download",
      title: "各種ダウンロード",
      icon: { element: "b-icon-download" },
      hidden: !contractSummary.isDownloadList,
    },
  ] as Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem>;
}

export default Vue.extend({
  name: "OthersMain",
  mixins: [MixinSideMenu],
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
    menu(): Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem> {
      return getOtherMenus(this.contractSummary, this.$service.permission);
    },
  },
});

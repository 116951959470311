





















































































































































































































































import Vue, { PropType } from "vue";
import { CpaNwDetail } from "@/apis/Cpa5gSaApi";

export default Vue.extend({
  name: "SaNwEditStepper2",
  props: {
    /** 親コンポーネントで保持してるCPA NW情報 */
    value: {
      type: Object as PropType<CpaNwDetail>,
      required: true,
    },
  },
  data() {
    return {
      cpaNwSetting: this.value,
    };
  },
  computed: {
    /**
     * 保守連絡先②内の項目が全て入力されているかチェック
     * true: 正常 全て入力されている・全て未入力 false: エラー 一部の項目が未入力
     */
    isFilledAllField(): boolean {
      // 保守連絡先②内の項目いずれかが入力されていればチェックする
      if (
        Object.values(this.cpaNwSetting.maintenanceContact2!).some((v) => !!v)
      ) {
        // 保守連絡先②が全て入力されているかチェックする
        return Object.values(this.cpaNwSetting.maintenanceContact2!).every(
          (v) => !!v
        );
      }
      // 全て未入力なので通過
      return true;
    },
  },
  watch: {
    /** 親からのForm情報更新を反映 */
    value(newValue: CpaNwDetail) {
      this.cpaNwSetting = newValue;
    },
  },
});

import { AxiosResponse } from "axios";

import AbstractBaseApi from "./AbstractBaseApi";

/**
 * NAT/NAPTのグローバルIPアドレス情報を取得API用インターフェイス
 */
export interface MSPeeringIpGetEntity {
  total: number;
  /** Microsoft Peering NAT/NAPT情報 */
  msPeeringList: NatNaptDetailEntity[];
  /** 排他確認用パラメータ */
  msPeeringIpKeyId: string;
}

export interface NatNaptDetailEntity {
  /** MicrosoftサービスSEQ 主キー */
  msServiceSeq: string;
  /** VPN/VNコード */
  vpnVnCode: string;
  /** VNネットワーク名 */
  vnName: string | null;
  /** NAPT用グローバルIPアドレス数（上限） */
  upperLimitNapt: number;
  /** NAPT用グローバルIPアドレス数（現在） */
  usedNapt: number;
  /** NAT用グローバルIPアドレス数（上限） */
  upperLimitNat: number;
  /** NAT用グローバルIPアドレス数（現在） */
  usedNat: number;
  /** Microsoft 払出済グローバルIP情報（NAPT用）*/
  msNaptGlobalIpList: NaptGlobalIpEntity[];
  /** Microsoft 払出済グローバルIPおよびプライベートIP情報（NAT用） */
  msNatIpList: NatIpEntity[];
}

export interface NaptGlobalIpEntity {
  /** クラウドグローバルIPアドレスSEQ 主キー */
  cloudGlobalIpAddressSeq: string;
  /** グローバルIPアドレス */
  globalIpAddress: string;
  /** 初期アドレスフラグ
   * true: Microsoft払出済グローバルIP情報（NAPT用）のIPアドレスが初期で払い出されているIPアドれの場合
   * false: それ以外
   */
  isInitialAddress: boolean;
}

export interface NatIpEntity {
  /** クラウドグローバルIPアドレスSEQ 主キー */
  cloudGlobalIpAddressSeq: string;
  /** グローバルIPアドレス */
  globalIpAddress: string;
  /** プライベートIPアドレス */
  privateIpAddress: string;
  /** 備考 */
  description: string | null;
}

/**
 * NAT/NAPTのグローバルIPアドレス情報を更新（追加・削除）API用インターフェイス
 */
export interface MSGlobalIpUpdateEntity {
  /** 追加NAPTグローバルIP数 */
  addNapt: number | null;
  /** 追加NATグローバルIP数 */
  addNat: number | null;
  /** NAT用新規プライベートIPアドレスリスト */
  msNatAddPrivateIpList:
    | {
        /** プライベートIPアドレス */
        privateIpAddress: string;
        /** 備考 */
        description: string | null;
      }[]
    | null;
  /** 削除NAPTグローバルIP */
  deleteMsNaptGlobalIpList: string[] | null;
  /** 削除NATグローバルIP */
  deleteMsNatGlobalIpList: string[] | null;
  /** オーダー備考 */
  note?: string;
  /** 排他確認用パラメータ */
  msPeeringIpKeyId: string;
}

/**
 * NATのプライベートIPアドレス情報を更新（変更）API用インターフェイス
 */
export interface MSNatAddEntity {
  /** NAT設定リスト */
  msNatSettingList: Omit<NatIpEntity, "globalIpAddress">[];
  /** オーダー備考 */
  note?: string;
  /** 排他確認用パラメータ */
  msPeeringIpKeyId: string;
}

export default class MSPeeringIpApi extends AbstractBaseApi {
  /**
   * NAT/NAPTのグローバルIPアドレス情報を取得
   */
  async getAll(): Promise<MSPeeringIpGetEntity> {
    return (await this.api.get("v1/network/cloud/ms-peering/global-ip")).data;
  }

  /**
   * NAT/NAPTのグローバルIPアドレス情報を更新（追加・削除）
   * @param msServiceSeq パスパラメータ
   * @param data リクエストデータ
   */
  async updateGlobalIp(
    msServiceSeq: string,
    data: MSGlobalIpUpdateEntity
  ): Promise<AxiosResponse> {
    return (
      await this.api.post(
        `v1/network/cloud/ms-peering/${msServiceSeq}/global-ip`,
        data
      )
    ).data;
  }

  /**
   * NATのプライベートIPアドレス情報を更新（変更）
   * @param msServiceSeq パスパラメータ
   * @param data リクエストデータ
   */
  async addNat(
    msServiceSeq: string,
    data: MSNatAddEntity
  ): Promise<AxiosResponse> {
    return (
      await this.api.post(
        `v1/network/cloud/ms-peering/${msServiceSeq}/nat`,
        data
      )
    ).data;
  }
}

















































































































































































































import Vue, { PropType } from "vue";
import { PfgwZone, PfgwZonePut } from "@/apis/PfgwZoneApi";

export default Vue.extend({
  name: "IntranetFwPfgwZoneAddressEdit",
  props: {
    enumberAct: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      pfgwZone: {} as PfgwZone,
      wvs2AddressList: [] as string[],
      kcpsAddressList: [] as string[],
      addressListBk: [] as string[],
      /** 初期通信完了フラグ */
      isLoaded: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    /** true: 変更可能, false: 変更不可 */
    changeable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
    /** 既存のKCPSアドレス */
    existskcpsAddress() {
      // 既存のアドレスリストに同じアドレスが存在した場合はエラーとする
      return (index: number) => {
        return [
          ...this.kcpsAddressList.filter((_, num) => num !== index),
          ...this.wvs2AddressList,
        ];
      };
    },
    /** 既存のWVS2アドレス */
    existswvs2Address() {
      // 既存のアドレスリストに同じアドレスが存在した場合はエラーとする
      return (index: number) => {
        return [
          ...this.wvs2AddressList.filter((_, num) => num !== index),
          ...this.kcpsAddressList,
        ];
      };
    },

    /**
     * 初期表示時と内容が変わっているかチェック
     * true: 変更あり
     * false: 変更無し
     */
    isChanged(): boolean {
      return this.$crossValidation.objectChanged(this.addressListBk, [
        ...this.wvs2AddressList,
        ...this.kcpsAddressList,
      ]);
    },
  },
  methods: {
    /** データ取得 */
    async load() {
      this.pfgwZone = await this.$api.pfgwZoneRelation.getPfgwZone(
        this.enumberAct
      );
      this.wvs2AddressList = this.pfgwZone.wvs2AddressList.map(
        (e) => e.ipAddress
      );
      this.kcpsAddressList = this.pfgwZone.kcpsAddressList.map(
        (e) => e.ipAddress
      );
      // アドレスリストは0件の場合、初期化
      if (this.wvs2AddressList.length === 0 && this.changeable) {
        this.wvs2AddressList = [""];
      }
      if (this.kcpsAddressList.length === 0 && this.changeable) {
        this.kcpsAddressList = [""];
      }
      // 更新前のデータを保持しておく(更新があるかチェックするため)
      this.addressListBk = [...this.wvs2AddressList, ...this.kcpsAddressList];
    },

    /** WVS2アドレス追加 */
    addWvs2() {
      this.wvs2AddressList.push("");
    },

    /** WVS2アドレス削除 */
    removeWvs2(target: string) {
      if (this.wvs2AddressList.length > 1) {
        this.wvs2AddressList.splice(this.wvs2AddressList.indexOf(target), 1);
      } else {
        this.wvs2AddressList = [""];
      }
    },

    /** KCPSアドレス追加 */
    addKcps() {
      this.kcpsAddressList.push("");
    },

    /** KCPSアドレス削除 */
    removeKcps(target: string) {
      if (this.kcpsAddressList.length > 1) {
        this.kcpsAddressList.splice(this.kcpsAddressList.indexOf(target), 1);
      } else {
        this.kcpsAddressList = [""];
      }
    },

    /** アドレス設定 */
    async submit() {
      await this.$confirm(`アドレスを設定します。よろしいですか？`);

      // 更新データの設定 アドレス入力無しの場合は空配列を設定する
      const reqData: PfgwZonePut = {
        wvs2AddressList:
          this.wvs2AddressList.length === 1 && !this.wvs2AddressList[0]
            ? []
            : this.wvs2AddressList,
        kcpsAddressList:
          this.kcpsAddressList.length === 1 && !this.kcpsAddressList[0]
            ? []
            : this.kcpsAddressList,
        zoneIdList: this.pfgwZone.zoneIdList,
        pfgwAddressKeyId: this.pfgwZone.pfgwAddressKeyId,
      };

      await this.$api.pfgwZoneRelation.putPfgwZone(this.enumberAct, reqData);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

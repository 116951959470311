











































































































import Vue, { PropType } from "vue";
import { CreateCpaContract } from "@/apis/Cpa5gSaApi";

export default Vue.extend({
  name: "SaContractConfirm",
  components: {},
  props: {
    cpa5gContractData: {
      type: Object as PropType<CreateCpaContract>,
      required: true,
    },
  },
});

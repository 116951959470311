

































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  BandWidth,
  RouteAggregation,
  VpnVnCode as CloudVpnVnCode,
} from "@/apis/CloudVnMsApi";
import { CloudLine } from "@/apis/CloudLineApi";
import cloneDeep from "lodash/cloneDeep";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";
import {
  CloudVnSfdcActSby,
  CloudVnSfdcDetail,
  CloudVnSfdcPost,
  CloudVnSfdcPut,
  SfdcVpnVnCodeGetRes,
} from "@/apis/CloudVnSfdcApi";
import MultiCloudSfdcVnConnectionConfirm from "@/modals/multicloud/salesforce/MultiCloudSfdcVnConnectionConfirm.vue";

export default Vue.extend({
  name: "MultiCloudSfdcVnLineEdit",
  props: {
    /** クラウド回線SEQ */
    cloudLineSeq: {
      type: String as PropType<string>,
      required: true,
    },
    // 変更の場合用
    cloudVnSfdcDetail: {
      type: Object as PropType<CloudVnSfdcDetail>,
      default: undefined,
    },
    // 追加の場合用
    cloudVnSfdcKeyId: {
      type: String as PropType<string>,
      default: undefined,
    },
  },
  data() {
    // 新規/更新のformデータを作成
    const form: CloudVnSfdcPost | CloudVnSfdcPut = (() => {
      if (this.cloudVnSfdcDetail) {
        // 変更
        const cloudVnSfdcDetailCopy = cloneDeep(this.cloudVnSfdcDetail);
        const form: CloudVnSfdcPut = {
          cloudVnConnectCommon: cloudVnSfdcDetailCopy.cloudVnConnectCommon,
          cloudVnConnectAct: cloudVnSfdcDetailCopy.cloudVnConnectAct,
          cloudVnConnectSby: cloudVnSfdcDetailCopy.cloudVnConnectSby
            ? cloudVnSfdcDetailCopy.cloudVnConnectSby
            : ({ maximumPrefix: 100 } as CloudVnSfdcPut["cloudVnConnectSby"]),
          note: null,
          cloudVnSfdcKeyId: cloudVnSfdcDetailCopy.cloudVnSfdcKeyId,
        };
        return form;
      } else {
        // 追加
        const form: CloudVnSfdcPost = {
          cloudVnConnectCommon: {
            bandwidthType: "PF",
            routeAggregation: "OFF",
            routeAggregationAddressList: [""],
            addNaptNum: 1,
            description: null,
          } as CloudVnSfdcPost["cloudVnConnectCommon"],
          cloudVnConnectAct: {
            maximumPrefix: 100,
          } as CloudVnSfdcPost["cloudVnConnectAct"],
          cloudVnConnectSby: {
            maximumPrefix: 100,
          } as CloudVnSfdcPost["cloudVnConnectSby"],
          note: null,
          cloudVnSfdcKeyId: this.cloudVnSfdcKeyId,
        };
        return form;
      }
    })();

    return {
      form: form,
      isLoaded: false,
      vpnVnCodeList: [] as SfdcVpnVnCodeGetRes["cloudVnConnectsList"],
      cloudLineMaxBandwidth: {} as CloudLine["bandwidth"],
      selectedVpnVn: null as null | { value: CloudVpnVnCode; text: string },
      bandwidthOptions: [
        { text: "10Mbps", value: { value: 10, unit: "MBPS" } },
        { text: "20Mbps", value: { value: 20, unit: "MBPS" } },
        { text: "30Mbps", value: { value: 30, unit: "MBPS" } },
        { text: "40Mbps", value: { value: 40, unit: "MBPS" } },
        { text: "50Mbps", value: { value: 50, unit: "MBPS" } },
        { text: "60Mbps", value: { value: 60, unit: "MBPS" } },
        { text: "70Mbps", value: { value: 70, unit: "MBPS" } },
        { text: "80Mbps", value: { value: 80, unit: "MBPS" } },
        { text: "90Mbps", value: { value: 90, unit: "MBPS" } },
        { text: "100Mbps", value: { value: 100, unit: "MBPS" } },
        { text: "200Mbps", value: { value: 200, unit: "MBPS" } },
        { text: "300Mbps", value: { value: 300, unit: "MBPS" } },
        { text: "400Mbps", value: { value: 400, unit: "MBPS" } },
        { text: "500Mbps", value: { value: 500, unit: "MBPS" } },
        { text: "600Mbps", value: { value: 600, unit: "MBPS" } },
        { text: "700Mbps", value: { value: 700, unit: "MBPS" } },
        { text: "800Mbps", value: { value: 800, unit: "MBPS" } },
        { text: "900Mbps", value: { value: 900, unit: "MBPS" } },
        { text: "1Gbps", value: { value: 1, unit: "GBPS" } },
        { text: "2Gbps", value: { value: 2, unit: "GBPS" } },
        { text: "3Gbps", value: { value: 3, unit: "GBPS" } },
        { text: "4Gbps", value: { value: 4, unit: "GBPS" } },
        { text: "5Gbps", value: { value: 5, unit: "GBPS" } },
        { text: "6Gbps", value: { value: 6, unit: "GBPS" } },
        { text: "7Gbps", value: { value: 7, unit: "GBPS" } },
        { text: "8Gbps", value: { value: 8, unit: "GBPS" } },
        { text: "9Gbps", value: { value: 9, unit: "GBPS" } },
        { text: "10Gbps", value: { value: 10, unit: "GBPS" } },
      ] as { text: string; value: BandWidth }[],
      routeOptions: [
        { value: "ON", text: "ON" },
        { value: "OFF", text: "OFF" },
        { value: "CUSTOM", text: "CUSTOM" },
      ],
      locationOptions: [{ value: "EAST_JAPAN", text: "東日本" }],
      /** Maximum Prefix上限値(変更の場合用) */
      maximumPrefixLimit: null as UpperLimitEntity | null,
      /** 1G超VNコネクト */
      vnConnects1G: null as UpperLimitEntity | null,
      upperLimitNaptMap: {} as Record<string, number>,
    };
  },
  computed: {
    /** VNコネクト(Act)名編集可否 */
    isVnConnectNameActDisable(): boolean {
      return (
        !this.isAdd &&
        this.cloudVnSfdcDetail!.cloudVnConnectAct.isVnConnectStatus
      );
    },
    /** VNコネクト(Sby)名編集可否 */
    isVnConnectNameSbyDisable(): boolean {
      return (
        !this.isAdd &&
        this.cloudVnSfdcDetail!.cloudVnConnectSby.isVnConnectStatus
      );
    },
    /** 払い出しグローバルIP個数(プルダウン) */
    globalIpCountOptions(): number[] {
      const ADD_NAPT_NUM_MIN = 1;
      const ADD_NAPT_NUM_MAX = 5;
      if (!this.selectedVpnVn) {
        return Array.range(ADD_NAPT_NUM_MIN, ADD_NAPT_NUM_MAX + 1);
      } else {
        const naptUpperLimit = this.upperLimitNaptMap[this.selectedVpnVn.value];
        return Array.range(ADD_NAPT_NUM_MIN, naptUpperLimit + 1);
      }
    },
    currentLength(): number {
      return this.form.cloudVnConnectCommon!.routeAggregationAddressList
        ? this.form.cloudVnConnectCommon!.routeAggregationAddressList.length
        : 0;
    },
    /** true: 新規, false: 変更 */
    isAdd(): boolean {
      return !this.cloudVnSfdcDetail;
    },

    vpnVnCodeSelectList(): {
      value: string;
      text: string;
    }[] {
      return this.vpnVnCodeList.map((v) => {
        if (v.vpnVnCode.startsWith("D")) {
          return {
            value: v.vpnVnCode,
            text: v.vnName + ":" + v.vpnVnCode + "(L3)",
          };
        } else {
          return { value: v.vpnVnCode, text: v.vpnVnCode };
        }
      });
    },
    /** 品目の選択肢 */
    bandwidthSelectList(): { text: string; value: BandWidth }[] {
      // クラウド帯域値
      const limit1 = this.$filter.bandwidthToBps(this.cloudLineMaxBandwidth);
      // 1G超VNコネクト
      const limit2 = this.$filter.bandwidthToBps({
        value: this.vnConnects1G!.upperLimit,
        unit: "GBPS",
      });
      return this.bandwidthOptions.filter((v) => {
        const bps = this.$filter.bandwidthToBps(v.value)!;
        return bps <= limit1! && bps <= limit2!;
      });
    },
    /** Maximum Prefixの選択肢 */
    maximumPrefixSelectList(): { text: string; value: number }[] {
      if (this.isAdd) {
        return [{ value: 100, text: "100" }];
      } else {
        return [
          { value: 100, text: "100" },
          { value: 200, text: "200" },
          { value: 300, text: "300" },
          { value: 400, text: "400" },
          { value: 500, text: "500" },
          { value: 1000, text: "1000" },
          { value: 1500, text: "1500" },
          { value: 2000, text: "2000" },
        ].filter((v) => v.value <= this.maximumPrefixLimit!.upperLimit);
      }
    },

    vpnVnName(): string | null {
      if (this.isAdd) return null;
      if (
        this.cloudVnSfdcDetail.cloudVnConnectCommon.vpnVnCode.startsWith("D")
      ) {
        return (
          this.cloudVnSfdcDetail.cloudVnConnectCommon.vnName +
          ":" +
          this.cloudVnSfdcDetail.cloudVnConnectCommon.vpnVnCode +
          "(" +
          this.cloudVnSfdcDetail.cloudVnConnectCommon.vnType +
          ")"
        );
      } else {
        return this.cloudVnSfdcDetail.cloudVnConnectCommon.vpnVnCode;
      }
    },
    /** 経路集約アドレスの重複チェック用リスト作成 */
    routingAddressList() {
      return (index: number) => {
        return this.form.cloudVnConnectCommon!.routeAggregationAddressList
          ? this.form.cloudVnConnectCommon!.routeAggregationAddressList.filter(
              (_, num) => num !== index
            )
          : [];
      };
    },
  },
  watch: {
    /** 経路集約がCUSTOMに変更されて初期値が存在しない場合は初期化 */
    "form.cloudVnConnectCommon.routeAggregation"(newValue: RouteAggregation) {
      if (
        newValue === "CUSTOM" &&
        (!this.form.cloudVnConnectCommon!.routeAggregationAddressList ||
          this.form.cloudVnConnectCommon!.routeAggregationAddressList.length ===
            0)
      ) {
        this.form.cloudVnConnectCommon!.routeAggregationAddressList = [""];
      }
    },
    /** グローバルIP個数がNAPTの最大値を上回る場合、グローバルIP個数をnullにする */
    globalIpCountOptions(newVal: number[]) {
      if (!this.isAdd) return;
      const max = Math.max(...newVal);
      const postForm = this.form as CloudVnSfdcPost;
      if (postForm.cloudVnConnectCommon.addNaptNum > max) {
        (postForm.cloudVnConnectCommon.addNaptNum as null | number) = null;
      }
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      if (this.isAdd) {
        // 回線情報に紐づくクラウド回線情報を取得APIを呼び出す
        const lines = (await this.$api.cloudLine.getCloudLines()).lineList;
        const cloudLines = lines.flatMap((v) => v.cloudLineList);
        // クラウド回線の帯域最大値(品目の上限値制御用)
        this.cloudLineMaxBandwidth = cloudLines.find(
          (v) => v.cloudLineSeq === this.cloudLineSeq
        )!.bandwidth;

        // 1G超VNコネクトの値を取得（品目の上限値制御用）
        this.vnConnects1G = (
          await this.$api.upperLimit.getUpperLimitLine({
            upperLimitLineManageUnit: "MULTI_CLOUD",
            cloudLineSeq: this.cloudLineSeq,
          })
        ).cloudLine!.upperLimitList!.find(
          (v) => v.upperLimitItemName === "1G超VNコネクト"
        )!;

        this.vpnVnCodeList = (
          await this.$api.cloudVnSfdc.getVpnVnCodes()
        ).sortBy(["vpnVnCode", "asc"]);

        const saasServiceList = (await this.$api.saas.getAll()).saasServiceList;
        this.upperLimitNaptMap = saasServiceList
          .filter(
            (saasService) => saasService.sfdcInfo && saasService.sfdcInfo.sfdc
          )
          .toMap(
            (v) => v.saasCommon.vpnVnCode,
            (v) => v.sfdcInfo!.sfdc!.upperLimitNapt
          );
      } else {
        // maximumPrefixの上限値を取得
        this.maximumPrefixLimit = (
          await this.$api.upperLimit.getUpperLimitLine({
            upperLimitLineManageUnit: "MULTI_CLOUD",
            cloudLineSeq: this.cloudLineSeq,
            wnumber: this.cloudVnSfdcDetail!.cloudVnConnectAct.wnumber,
          })
        ).cloudLine!.upperLimitList!.find(
          (v) => v.upperLimitItemName === "BGP4の最大経路数"
        )!;
      }
    },

    /** 経路集約アドレス削除 */
    removeRouteAddress(index: number) {
      const list = this.form.cloudVnConnectCommon!.routeAggregationAddressList!;
      if (list.length > 1) {
        list.splice(index, 1);
      } else {
        this.form.cloudVnConnectCommon!.routeAggregationAddressList = [""];
      }
    },

    /** 経路集約アドレス追加 */
    addRouteAddress() {
      this.form.cloudVnConnectCommon!.routeAggregationAddressList = [
        ...this.form.cloudVnConnectCommon!.routeAggregationAddressList!,
        "",
      ];
    },

    async submit() {
      const form = {
        ...this.form,
        cloudVnConnectCommon: {
          ...this.form.cloudVnConnectCommon,
          routeAggregationAddressList:
            this.form.cloudVnConnectCommon!.routeAggregation === "CUSTOM"
              ? this.form.cloudVnConnectCommon!.routeAggregationAddressList
              : null,
        },
      } as
        | CloudVnSfdcPost
        | (CloudVnSfdcPut & {
            cloudVnConnectSby: Pick<CloudVnSfdcActSby, "asNumber">;
            cloudVnConnectAct: Pick<CloudVnSfdcActSby, "asNumber">;
          });
      //AS番号(act)の値をAS番号(sby)に代入する
      form.cloudVnConnectSby!.asNumber = form.cloudVnConnectAct!.asNumber;
      if (this.isAdd) {
        const postForm = form as CloudVnSfdcPost;
        postForm.cloudVnConnectCommon.vpnVnCode = this.selectedVpnVn!.value;
        const vnName = this.vpnVnCodeList.find(
          (v) => v.vpnVnCode === postForm.cloudVnConnectCommon.vpnVnCode
        )!.vnName;

        await this.$modal.show(MultiCloudSfdcVnConnectionConfirm, {
          cloudVnSfdcDetail: {
            cloudVnConnectCommon: {
              location: "EAST_JAPAN",
              naptGlobalIpTotal: postForm.cloudVnConnectCommon.addNaptNum,
              vnName,
              routingType: "BGP4",
              ...postForm.cloudVnConnectCommon,
            },
            cloudVnConnectAct: {
              ...postForm.cloudVnConnectAct,
              localPreference: "HIGH_PRIORITY",
              med: "LOW_PRIORITY",
            },
            cloudVnConnectSby: {
              ...postForm.cloudVnConnectSby,
              localPreference: "LOW_PRIORITY",
              med: "HIGH_PRIORITY",
            },
          },
          displayType: "A",
        });

        await this.$api.cloudVnSfdc.postVnConnect(this.cloudLineSeq, postForm);
        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      } else {
        form.cloudVnConnectSby.maximumPrefix =
          form.cloudVnConnectAct.maximumPrefix;

        await this.$modal.show(MultiCloudSfdcVnConnectionConfirm, {
          cloudVnSfdcDetail: form,
          displayType: "M",
        });

        await this.$api.cloudVnSfdc.putVnConnect(
          this.cloudLineSeq,
          this.cloudVnSfdcDetail.cloudVnConnectAct.wnumber,
          form as CloudVnSfdcPut
        );

        await (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },
  },
});

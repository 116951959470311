










































































































































































































































































































import Vue, { PropType } from "vue";
import VNL2L3ConnectEdit from "@/modals/vnL2L3Connect/VNL2L3ConnectEdit.vue";
import { Bgp4Peer, VNL2L3ConnectDetail } from "@/apis/VNL2L3ConnectApi";
import VNL2L3ConnectDelete from "@/modals/vnL2L3Connect/VNL2L3ConnectDelete.vue";
import { ContractInfo } from "@/apis/ContractApi";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VNL2L3ConnectReference",
  props: {
    wnumber: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      isChanged: false,
      vnL2L3ConnectDetail: {} as VNL2L3ConnectDetail,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
      contractSummary: "contractSummary",
    }),
    /** bgp4のリスト（peer1、peer2の順で返却） */
    bgp4List(): Bgp4Peer[] {
      return this.vnL2L3ConnectDetail.bgp4!.sortBy(["peerName", "asc"]);
    },
    /** 開始日が未来日の場合,true*/
    isFutureDayContract(): boolean {
      return !this.contractInfo.contractList.find(
        (e: ContractInfo["contractList"][0]) =>
          e.enumber === this.vnL2L3ConnectDetail.enumber
      );
    },
  },
  methods: {
    async load() {
      this.vnL2L3ConnectDetail =
        await this.$api.vNL2L3Connect.getVNL2L3ConnectDetail(this.wnumber);
    },
    /** 削除*/
    async deleteVNL2L3Connect() {
      await this.alertClear();
      await this.$modal.show(VNL2L3ConnectDelete, {
        vnL2L3ConnectDetail: this.vnL2L3ConnectDetail,
      });
      await this.$api.vNL2L3Connect.deleteVNL2L3Connect(this.wnumber, {
        vnl2l3ConnectKeyId: this.vnL2L3ConnectDetail.vnl2l3ConnectKeyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 変更*/
    async modifyVNL2L3Connect() {
      await this.alertClear();
      await this.$modal.show(VNL2L3ConnectEdit, {
        wnumber: this.wnumber,
        vnL2L3ConnectDetail: this.vnL2L3ConnectDetail,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 運用コマンド実行のフック */
    async executed() {
      this.isChanged = true;
      await this.load();
    },

    /** エラーメッセージを初期化する */
    async alertClear() {
      (
        this.$refs.operationCommand as Vue & { alertClear: () => void }
      ).alertClear();
    },
  },
});



























































































































































import Vue, { PropType } from "vue";
import { PostExtranetInfo } from "@/apis/ExtranetApi";
import { mapState } from "vuex";
import { VpnVnCode } from "@/apis/InformationApi";

export default Vue.extend({
  name: "ExtranetConnectRegister",
  props: {
    extranetKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      form: {} as PostExtranetInfo,
      vpnVnCodeList: [] as VpnVnCode[],
      selectedVpnVnCode: null as VpnVnCode | null,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
    /**
     * PINコードの関連項目チェック
     * false: 接続元V番号と接続先V番号同じ, true: 同じではない
     */
    isRequiredPinCode(): boolean {
      return this.contractSummary.vnumber !== this.form.dstVNumber;
    },
  },
  methods: {
    /** データ取得 */
    async load() {
      // VPN/VNコードリストをAPIから取得する
      this.vpnVnCodeList = await this.$api.information.getVpnVnCodeList();
      // L3VNコードのみを対象とする
      this.vpnVnCodeList = this.vpnVnCodeList.filter((v) =>
        v.vpnVnCode.startsWith("D")
      );
      // 主キー相当の項目が無いため、こちらでインデックスを作成
      this.vpnVnCodeList.numbering();
      // VNコードの降順でソート
      this.vpnVnCodeList = this.vpnVnCodeList.sortBy(["vpnVnCode", "desc"]);
    },
    /** エクストラネット接続申請処理 */
    async submit() {
      // 確認ダイアログの表示
      await this.$confirm(`エクストラネット接続を申請します。よろしいですか？
        申請先にて承認された場合、申請されたネットワークと接続されます。
        誤った申請をされた場合、情報漏洩やお客様の通信に支障を及ぼす可能性があります。`);

      // リクエストデータの設定
      const reqData: PostExtranetInfo = {
        srcVnCode: this.selectedVpnVnCode!.vpnVnCode,
        dstVNumber: this.form.dstVNumber,
        dstVnCode: this.form.dstVnCode,
        pinCode: this.form.pinCode || null,
        message: this.form.message || null,
        extranetKeyId: this.extranetKeyId,
      };

      // APIを呼び出しエクストラネット接続申請を行う
      await this.$api.extranetApi.postExtranet(reqData);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 検索機能付きプルダウンで選択した値から表示値のみ返す */
    getCustomLabel({ vpnVnCode, vnName, vnType }: never) {
      return `${vnName}:${vpnVnCode}(${vnType})`;
    },
  },
});

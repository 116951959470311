







































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "SaContractTermsConfirm",
  props: {
    // 契約登録から遷移：true,設定情報から遷移：false
    isConfirmationMode: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  async beforeMount() {
    // 約款情報PDF取得でエラーにならないことを確認
    // 取得出来ずに「同意する」が押せてしまうこと防ぐ為に必要
    await this.$api.cpa5gSa.checkTermsPdf();
    this.isLoadPdf = true;
  },
  data() {
    return {
      accepted: false,
      isLoadPdf: false,
      downloadUrl: this.$api.cpa5gSa.downloadContractTermsPdfURL(),
    };
  },
});



























import Vue from "vue";
import MixinSideMenu from "@/mixins/MixinSideMenu.vue";
import {
  SidebarComponentItem,
  SidebarHeaderItem,
  SidebarItem,
} from "vue-sidebar-menu";

export const NWInformationMenus = [
  {
    href: "/info/contract/line",
    title: "契約回線",
    icon: { element: "b-icon-card-heading" },
  },
  {
    href: "/info/limit",
    title: "設定上限値",
    icon: { element: "b-icon-chevron-bar-up" },
  },
  {
    href: "/info/operation/history",
    title: "操作履歴",
    icon: { element: "b-icon-journal-richtext" },
  },
  {
    href: "/info/maintenance",
    title: "障害/メンテナンス情報",
    icon: { element: "b-icon-exclamation-triangle" },
  },
  {
    href: "/info/agreement/history",
    title: "同意履歴",
    icon: { element: "b-icon-journal-check" },
  },
] as Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem>;

export default Vue.extend({
  name: "ContractMain",
  mixins: [MixinSideMenu],
  data() {
    return {
      menu: NWInformationMenus,
    };
  },
});







































import Vue, { PropType } from "vue";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";

export default Vue.extend({
  name: "InternetType1ZoneRelationDeleteConfirm",
  props: {
    zoneRelationItem: {
      type: Object as PropType<ZoneRelationItem>,
      required: true,
    },
  },
});

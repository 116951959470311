import Vue from "vue";
import {
  ApiBadGatewayError,
  ApiBadRequestError,
  ApiConflictError,
  ApiForbiddenError,
  ApiInternalServerError,
  ApiMethodNotAllowedError,
  ApiNotFoundError,
  ApiRequestTimeoutError,
  ApiServiceUnavailableError,
  ApiUnauthorizedError,
  NetworkError,
} from "@/error/errors";
import UnauthorizedErrorModal from "@/modals/error/UnauthorizedErrorModal.vue";
import ApiErrorModal from "@/modals/error/ApiErrorModal.vue";
import ApiNotFoundModal from "@/modals/error/ApiNotFoundModal.vue";

/**
 * 全体エラーハンドラー
 * App.vueやmain.tsなどでエラーハンドラーとして登録されている
 * @param err エラー
 * @param instance Vueインスタンス
 */
export default function appErrorHandler(err: unknown, instance: Vue): void {
  console.error(err);
  if (err instanceof ApiBadRequestError) {
    // 400エラー（エラーモーダル表示）
    instance.$modal.show(ApiErrorModal, { error: err });
  } else if (err instanceof ApiUnauthorizedError) {
    // 401エラー（エラーモーダル表示 + ログイン画面へ遷移）
    instance.$modal.show(UnauthorizedErrorModal, { error: err });
  } else if (err instanceof ApiForbiddenError) {
    // 403エラー（エラーモーダル表示）
    instance.$modal.show(ApiErrorModal, { error: err });
  } else if (err instanceof ApiNotFoundError) {
    // 404エラー（エラーモーダル表示）
    instance.$modal.show(ApiNotFoundModal, { error: err });
  } else if (err instanceof ApiMethodNotAllowedError) {
    // 405エラー（システムエラー画面へ遷移）
    instance.$router.push({ path: "/system/error" });
  } else if (err instanceof NetworkError) {
    // 407エラー（ネットワークエラー画面へ遷移）
    instance.$router.push({ path: "/network/error" });
  } else if (err instanceof ApiRequestTimeoutError) {
    // 408, 504エラー（エラーモーダル表示）
    instance.$modal.show(ApiErrorModal, { error: err });
  } else if (err instanceof ApiConflictError) {
    // 409エラー（エラーモーダル表示）
    instance.$modal.show(ApiErrorModal, { error: err });
  } else if (err instanceof ApiInternalServerError) {
    // 500エラー（システムエラー画面へ遷移）
    instance.$router.push({ path: "/system/error" });
  } else if (err instanceof ApiBadGatewayError) {
    // 502エラー（システムエラー画面へ遷移）
    instance.$router.push({ path: "/system/error" });
  } else if (err instanceof ApiServiceUnavailableError) {
    switch (err.data.errorCode) {
      case "CC99009W":
      case "AZZZ001W":
      case "ARCC001W":
        // 503 + 規制エラー（エラーモーダル表示）
        instance.$modal.show(ApiErrorModal, { error: err });
        break;
      default:
        // 503エラー（メンテナンスページへ遷移）
        window.location.href = "/maintenance.html";
    }
    // TODO: 商用で問題が出ているため一時的に戻し
    // } else if ((err as Record<string, string>).message === "Network Error") {
    //   // 通信エラー、CORSのOPTIONSが503でエラーになる可能性がある。
    //   // 一度ログイン画面に飛ばしてヘルスチェックAPI（自ドメイン）を実行して必要ならメンテナンスページを表示させる
    //   if (instance.$route.path !== "/login") {
    //     instance.$router.push({ path: "/login" });
    //   }
  } else {
    // 想定外エラー
    if (process.env.NODE_ENV === "development") {
      window.alert(
        "予期せぬエラーが発生。コンソールログを確認してください\n" +
          "開発モードのみの表示です\n" +
          "本来はシステムエラーページに遷移します"
      );
    } else {
      if (instance.$route.path !== "/system/error") {
        instance.$router.push({ path: "/system/error" });
      }
    }
  }
}

































































































































































































































































































import Vue from "vue";
import { CpaDomain, CpaIdFile, FileType } from "@/apis/CpaIdApi";
import { AppTableData } from "@/components/AppTable.vue";
import CpaIdFileDownloadConfirm from "@/modals/cpaAuthId/CpaIdFileDownloadConfirm.vue";
import { SearchOption } from "@/components/AppSearch.vue";
import ApiNotFoundModal from "@/modals/error/ApiNotFoundModal.vue";

export default Vue.extend({
  name: "CpaAuthIdList",
  data() {
    return {
      isLoaded: false,
      alertMsg: null as string | null,
      form: {
        fileType: "ACCOUNT_LIST" as FileType,
        domain: {} as CpaDomain | null,
        dateFrom: "",
        timeFrom: "",
        dateTo: "",
        timeTo: "",
      },
      domainList: [] as CpaDomain[],
      cpaId: {
        fields: [
          {
            key: "domain",
            label: "ドメイン",
          },
          {
            key: "fileType",
            label: "ファイル種別",
          },
          {
            key: "fileSize",
            label: "ファイルサイズ",
          },
          {
            key: "fileCreationPeriod",
            label: "ファイル作成期間",
          },
          {
            key: "executeDateTime",
            label: "作成実行日",
          },
          {
            key: "status",
            label: "ステータス",
          },
          {
            key: "progressRate",
            label: "作成状況",
          },
          {
            key: "authorityType",
            label: "作成権限",
          },
        ],
        items: [] as CpaIdFile[],
        selected: [] as CpaIdFile[],
        filter: {} as Record<string, unknown>,
        filter_option: {
          domain: "equal",
          fileType: "equal",
          status: "equal",
        },
      } as Omit<AppTableData<CpaIdFile>, "search">,
      domainOptions: [{ value: "", text: "" }],
    };
  },

  async mounted() {
    this.form.domain = null;
    await this.load();
  },

  computed: {
    /** 検索コンポーネントのオプション */
    searchOption(): SearchOption<CpaIdFile> {
      return {
        domain: {
          type: "select",
          label: "ドメイン",
          items: this.domainList.map((e) => ({
            value: e.domain,
            text: e.domain,
          })),
        },
        fileType: {
          type: "select",
          label: "ファイル種別",
          items: [
            { value: "ACCOUNT_LIST", text: "アカウント一覧" },
            { value: "ACCOUNTING_LOG", text: "アカウンティングログ" },
          ],
        },
        status: {
          type: "select",
          label: "ステータス",
          items: [
            { value: "SUCCESS", text: "成功" },
            { value: "FAILURE", text: "失敗" },
            { value: "EXECUTION", text: "作成中" },
            { value: "NODATA", text: "該当データ無し" },
          ],
        },
      };
    },
    /** fromの日と時間を結合した文字列 */
    dateTimeFrom(): string | null {
      if (!this.form.dateFrom) return null;
      if (!this.form.timeFrom) {
        return `${this.form.dateFrom}T00:00:00`;
      } else {
        return `${this.form.dateFrom}T${this.form.timeFrom}`;
      }
    },
    /** toの日と時間を結合した文字列 */
    dateTimeTo(): string | null {
      if (!this.form.dateTo) return null;
      if (!this.form.timeTo) {
        return `${this.form.dateTo}T23:59:59`;
      } else {
        return `${this.form.dateTo}T${this.form.timeTo}`;
      }
    },
    /** 現在の日付以前が選択可能 */
    maxSendDate(): string {
      return this.$moment().format("YYYY-MM-DD");
    },
  },

  methods: {
    async load() {
      /** ドメイン・ファイル一覧を取得 */
      this.domainList = (await this.$api.cpaIdApi.getCpaDomains()).domainList;
      await this.getCpaIdFileData();
      // 作成権限は代行権限のユーザのみ表示
      if (!this.$service.permission.isDelegateUser()) {
        this.cpaId.fields.splice(7, 1);
      }
      this.isLoaded = true;
    },

    async getCpaIdFileData() {
      this.alertMsg = null;
      this.cpaId.items = (
        await this.$api.cpaIdApi.getCpaAccounts()
      ).cpaIdFileList;
    },

    /** ファイル作成 */
    async submit() {
      this.alertMsg = null;
      await this.$confirm(`指定されたファイルを作成します。よろしいですか？`);
      await this.$api.cpaIdApi.createCpaContract(
        this.form.domain!.cpaContractSeq,
        {
          fileType: this.form.fileType,
          startDateTime:
            this.form.fileType === "ACCOUNTING_LOG"
              ? this.$moment(
                  this.form.dateFrom + " " + this.form.timeFrom
                ).format("YYYY-MM-DDTHH:mm:ss+09:00")
              : null,
          endDateTime:
            this.form.fileType === "ACCOUNTING_LOG"
              ? this.$moment(this.form.dateTo + " " + this.form.timeTo).format(
                  "YYYY-MM-DDTHH:mm:ss+09:00"
                )
              : null,
        }
      );
      // 一覧を更新
      await this.getCpaIdFileData();
    },

    /** ファイルダウンロード確認 */
    async downloadIdFile(entity: CpaIdFile) {
      // 成功の場合のみダウンロード可能
      if (entity.status !== "SUCCESS") {
        return;
      }
      this.alertMsg = null;
      // ダウンロード前に一覧を取得して対象ファイルの存在チェックを行う
      const isFileExist = (
        await this.$api.cpaIdApi.getCpaAccounts()
      ).cpaIdFileList.some(
        (v) => v.cpaIdInformationSeq === entity.cpaIdInformationSeq
      );

      // ファイルが存在すれば確認ダイアログを表示 はいを押下した場合ダウンロードAPIを呼び出す
      if (isFileExist) {
        await this.$modal.show(CpaIdFileDownloadConfirm, {
          cpaIdInformationSeq: entity.cpaIdInformationSeq,
          authorityType: entity.authorityType,
        });
      } else {
        // ファイルが存在しない場合はエラーモーダルを表示
        await this.$modal.show(ApiNotFoundModal);
      }
    },

    convertFileSize(size: number): string {
      // APIからMB単位で返却された値が1未満の場合、固定値「1MB未満」を返却する
      if (size < 1) {
        return "1MB未満";
      }
      const gb = 1000;
      /** APIからMB単位で返却された値が1000MB超過する場合は、GBに変換する */
      let target = size;
      let unit = "MB";

      if (size >= gb) {
        target = gb;
        unit = "GB";
      }
      return `${size >= gb ? (size / target).toFixed(1) : size}${unit}`;
    },
    /**
     * ファイル削除
     */
    async deleteFiles() {
      if (this.cpaId.selected.length === 0) {
        this.alertMsg = this.$msg("no_select_data");
        return;
      }
      this.alertMsg = null;

      // 法人権限で作成されたレコードが存在するかチェック;
      const isAuthCustomer = this.cpaId.selected.some(
        (e) => e.authorityType === "CUSTOMER"
      );
      await this.$modal.show(CpaIdFileDownloadConfirm, {
        authorityType: isAuthCustomer ? "CUSTOMER" : "OPERATOR",
      });

      // 存在チェック用に現時点の一覧情報を取得
      const presentPoint = (await this.$api.cpaIdApi.getCpaAccounts())
        .cpaIdFileList;

      for (const e of this.cpaId.selected) {
        // 現時点でファイルが存在するかチェック
        const isFileExist = presentPoint.some(
          (v) => v.cpaIdInformationSeq === e.cpaIdInformationSeq
        );

        // 存在する場合、削除処理を実施
        if (isFileExist) {
          await this.$api.cpaIdApi.deleteCpaAccount(e.cpaIdInformationSeq);
        }
      }
      await this.getCpaIdFileData();
    },
    /** CpaIdテーブルのtrクラス
     * 成功時のみダウンロードできる
     **/
    cpaIdRowClass(item: CpaIdFile): string | null {
      switch (item.status) {
        case "SUCCESS":
          return null;
        default:
          return "un-clickable";
      }
    },
  },
});

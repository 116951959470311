










































































































































































































import Vue, { PropType } from "vue";
import { FlowEntity } from "@/modals/site/SiteReference.vue";
import cloneDeep from "lodash/cloneDeep";
import { VNetworkBrief } from "@/apis/VirtualNetworkApi";
import { IfStatus, VNConnect } from "@/apis/VNConnectApi";

type dstInfo = {
  dstLabel: { vnConnectOrL3vpn: string; wnumberOrVpnCode: string };
  dstValues: DstInfoEntity[];
};

export default Vue.extend({
  name: "SiteFlowEdit",
  props: {
    /** flow詳細、変更時のみ指定 */
    flow: {
      type: Object as PropType<FlowEntity>,
      required: false,
    },
    enumber: {
      type: String as PropType<string>,
      required: true,
    },
    vlanIdType: {
      type: String as PropType<"UNTAG" | "OTHER" | "SPECIFIED">,
      required: true,
    },
    vlanId: {
      type: Number as PropType<number>,
      required: false,
      default: null,
    },
  },
  data() {
    const flowClone = cloneDeep(this.flow) as FlowEntity | undefined;
    return {
      isLoaded: false,
      addrOptions: [
        { value: null, text: "any" },
        { value: "address", text: "アドレス指定" },
      ],
      form: flowClone
        ? {
            // 変更
            selectedDstInfo: null as DstInfoEntity | null | undefined,
            srcAddress: flowClone.srcAddress,
            dstAddress: flowClone.dstAddress,
            description: flowClone.description,
            radioSrcAddress: flowClone.srcAddress ? "address" : null,
            radioDstAddress: flowClone.dstAddress ? "address" : null,
          }
        : {
            // 追加
            selectedDstInfo: null as DstInfoEntity | null | undefined,
            srcAddress: null as string | null,
            dstAddress: null as string | null,
            description: null as string | null,
            radioSrcAddress: null as string | null,
            radioDstAddress: null as string | null,
          },
      flowClone: flowClone,
      // APIの取得値を持ち
      availableVnNetwork: [] as VNetworkBrief[],
      vnConnects: [] as VNConnect[],
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },

  computed: {
    /** 変更の場合、true */
    isEdit(): boolean {
      return !!this.flow;
    },
    /** プルダウンの選択肢リスト */
    dstData(): DstInfoEntity[] {
      // 接続先VNコネクト名(W番号)/ 接続先VPN(VPNコード)の選択肢のデータ生成
      const selectOption = this.availableVnNetwork.map((e) => ({
        vnConnectOrL3vpn: e.vnConnectName ? e.vnConnectName : "L3VPN",
        wnumberOrVpnCode: e.wnumber || e.vpnCode || "",
        l2vn: e.wnumber
          ? !!(
              this.vnConnects.find((v) => v.wnumber === e.wnumber)?.vnType ===
                "L2" &&
              this.vnConnects.find((v) => v.wnumber === e.wnumber)?.vnName
            )
          : false,
        //VN面はL3の場合、有効/無効は必ずnullに指定する
        isVnConnectStatus: e.vnConnectName
          ? e.wnumber
            ? this.vnConnects.find((v) => v.wnumber === e.wnumber)
                ?.isVnConnectStatus
            : this.vnConnects.find((v) => v.vpnVnCode === e.vpnCode)
                ?.isVnConnectStatus
          : null,
        //VN面はL3の場合、IF状態は必ずnullに指定する
        ifStatus: e.vnConnectName
          ? e.wnumber
            ? this.vnConnects.find((v) => v.wnumber === e.wnumber)?.ifStatus
            : this.vnConnects.find((v) => v.vpnVnCode === e.vpnCode)?.ifStatus
          : null,
      }));

      /** VPNコード(１件のみあるはず)は一番上に表示、W番号は降順ソートで表示 */
      const vpn = selectOption.filter(
        (v) => v.wnumberOrVpnCode.charAt(0) === "B"
      );
      const wnumberList = selectOption
        .filter((v) => v.wnumberOrVpnCode.charAt(0) !== "B")
        .sortBy(["wnumberOrVpnCode", "desc"]);
      return [...vpn, ...wnumberList];
    },
    /** 接続先VNコネクト名(W番号)/ 接続先VPN(VPNコード)の選択肢 */
    dstOptions(): dstInfo[] {
      return this.dstData.length !== 0
        ? [
            {
              dstLabel: {
                wnumberOrVpnCode: "W番号/VPNコード",
                vnConnectOrL3vpn: "VNコネクト名/L3VPN",
              },
              dstValues: this.dstData,
            },
          ]
        : [];
    },
    /** 画面が採用された送信元アドレスアドレス */
    getSubmitScrAddress(): string | null {
      return !this.form.radioSrcAddress ? null : this.form.srcAddress;
    },
    /** 画面が採用された宛先アドレスアドレス */
    getSubmitDstAddress(): string | null {
      return !this.form.radioDstAddress ? null : this.form.dstAddress;
    },
  },

  methods: {
    async load() {
      // フロー設定可能なVN回線の一覧を取得
      // サイトに紐づいた回線（E番号)がL3かつサイトがUntag以外のデータがこない
      this.availableVnNetwork = (
        await this.$api.virtualNetwork.getFlowAvailableVnNetwork({
          enumber: this.enumber,
          vlanIdType: this.vlanIdType,
          vlanId: this.vlanId,
        })
      ).targetList;

      // VNコネクトを一覧取得
      this.vnConnects = (
        await this.$api.vNConnect.getVNConnects()
      ).vnConnectList;

      if (this.isEdit) {
        // 編集の場合
        this.form.selectedDstInfo = this.dstData.find(
          (v) =>
            v.wnumberOrVpnCode === this.flow?.vpnCode ||
            v.wnumberOrVpnCode === this.flow?.wnumber
        );
      }
    },

    /** 接続先検索付きプルダウンの選択後表示形式 */
    dstInfoSelectLabel({ vnConnectOrL3vpn, wnumberOrVpnCode }: DstInfoEntity) {
      return `${vnConnectOrL3vpn}(${wnumberOrVpnCode})`;
    },

    /** フロー設定 */
    async submit() {
      if (!this.isEdit) {
        const flow = {
          flowSeq: null,
          vnConnectName:
            this.form.selectedDstInfo!.vnConnectOrL3vpn !== "L3VPN"
              ? this.form.selectedDstInfo!.vnConnectOrL3vpn
              : null,
          wnumber:
            this.form.selectedDstInfo!.vnConnectOrL3vpn !== "L3VPN"
              ? this.form.selectedDstInfo!.wnumberOrVpnCode
              : null,
          vpnCode:
            this.form.selectedDstInfo!.vnConnectOrL3vpn === "L3VPN"
              ? this.form.selectedDstInfo!.wnumberOrVpnCode
              : null,
          srcAddress: this.getSubmitScrAddress,
          dstAddress: this.getSubmitDstAddress,
          // VNコネクトの状態は有効(true)の場合:VALID、無効(false)の場合:INVALID
          flowStatus:
            this.form.selectedDstInfo!.isVnConnectStatus === null
              ? null
              : this.form.selectedDstInfo!.isVnConnectStatus
              ? "VALID"
              : "INVALID",
          ifStatus: this.form.selectedDstInfo!.ifStatus,
          description: this.form.description,
        };
        (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(flow);
      } else {
        const flow = {
          ...this.flowClone,
          vnConnectName:
            this.form.selectedDstInfo!.vnConnectOrL3vpn !== "L3VPN"
              ? this.form.selectedDstInfo!.vnConnectOrL3vpn
              : null,
          wnumber:
            this.form.selectedDstInfo!.vnConnectOrL3vpn !== "L3VPN"
              ? this.form.selectedDstInfo!.wnumberOrVpnCode
              : null,
          vpnCode:
            this.form.selectedDstInfo!.vnConnectOrL3vpn === "L3VPN"
              ? this.form.selectedDstInfo!.wnumberOrVpnCode
              : null,
          srcAddress: this.getSubmitScrAddress,
          dstAddress: this.getSubmitDstAddress,
          // VNコネクトの状態は有効(true)の場合:VALID、無効(false)の場合:INVALID
          flowStatus:
            this.form.selectedDstInfo!.isVnConnectStatus === null
              ? null
              : this.form.selectedDstInfo!.isVnConnectStatus
              ? "VALID"
              : "INVALID",
          ifStatus: this.form.selectedDstInfo!.ifStatus,
          description: this.form.description,
        };
        (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(flow);
      }
    },
  },
});
export interface DstInfoEntity {
  /** VNコネクト名/L3VPN */
  vnConnectOrL3vpn: string;
  /** W番号/VPNコード */
  wnumberOrVpnCode: string;
  /** 追加時、新規で表示する項目 */
  l2vn: boolean | undefined;
  isVnConnectStatus: boolean | undefined | null;
  ifStatus: IfStatus | undefined | null;
}

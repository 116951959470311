






















































































import Vue, { PropType } from "vue";
import IntranetPolicySrcAddress from "@/components/policy/IntranetPolicySrcAddress.vue";
import IntranetPolicyDstAddress from "@/components/policy/IntranetPolicyDstAddress.vue";
import IntranetPolicyService from "@/components/policy/IntranetPolicyService.vue";
import { ZonePolicyInfo } from "@/apis/IntranetfwPolicyApi";

export default Vue.extend({
  name: "IntranetFwPfgwPolicyConfirm",
  components: {
    IntranetPolicySrcAddress,
    IntranetPolicyDstAddress,
    IntranetPolicyService,
  },
  props: {
    /** ポリシー */
    pfgwPolicy: {
      type: Object as PropType<Record<string, ZonePolicyInfo | null>>,
      required: true,
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";

export interface Type4Route {
  /** Type4経路設定排他情報. 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  type4RouteKeyId: string;
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  type4RouteList: {
    /** インターネットアドレス. xxx.xxx.xxx.xxx/xxの形式 */
    internetAddress: string;
    /**
     * Microsoft365フラグ
     * M365オプションで自動設定されているアドレスかどうかを判別
     * M365用自動設定の場合はtrue、それ以外の場合はfalseを設定
     * 新しく追加するアドレスにはfalseのみ指定可能
     */
    isMicrosoft365: boolean;
  }[];
}

export type PutType4Route = Pick<
  Type4Route,
  "type4RouteKeyId" | "type4RouteList"
>;

export default class Type4RouteApi extends AbstractBaseApi {
  /**
   * Type4経路情報取得
   * @param type4Id type4Id
   */
  async getType4Route(type4Id: string): Promise<Type4Route> {
    return (await this.api.get(`/v1/network/routes/type4/${type4Id}`)).data;
  }

  /**
   * Type4経路情報変更
   * @param type4Id type4Id
   * @param data 設定情報
   */
  async putType4Route(type4Id: string, data: PutType4Route): Promise<void> {
    await this.api.post(`/v1/network/routes/type4/${type4Id}`, data);
  }
}

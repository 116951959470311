import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** 契約回線情報 */
export interface ContractInfo {
  /** 契約SEQ. 主キー */
  contractSeq: string;
  /** V番号. */
  vnumber: string;
  /** VPNコード. */
  vpnCode: string;
  /** メールアドレス. */
  email: string;
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  /** 契約回線情報. 取得した契約回線情報。データが存在しない場合、[](空の状態)となる。 */
  contractList: {
    /** 契約詳細SEQ. 詳細のSEQ、GATEWAYの場合は「GATEWAYSEQ」、回線の場合は「回線SEQ」を設定する。  */
    contractDetailSeq: string;
    /** E番号.  */
    enumber: string;
    /** 契約者名.  */
    contractor: string;
    /** エンドユーザ名.  */
    endUserName: string;
    /** 事業所名.  */
    office: string;
    /**
     * アクセス品目. 以下のいずれかを設定する。
     * INTERNET: インターネット
     * ETHERNET_L2: イーサネット方式(L2モード)
     * ETHERNET_L3: イーサネット方式(L3モード)
     * BROADBAND2_L2: ブロードバンドアクセス方式Ⅱ(L2モード)
     * BROADBAND2_L3: ブロードバンドアクセス方式Ⅱ(L3モード)
     * WIRELESS_L2: ワイヤレスアクセス方式(L2モード)
     * WIRELESS_L3: ワイヤレスアクセス方式(L3モード)
     * REMOTE_GW_FRE: リモートアクセスGW(FRE)
     * REMOTE_GW_CPA: リモートアクセスGW(CPA)
     * REMOTE_GW_CRG: リモートアクセスGW(CRG)
     * PLATFORM_GATEWAY: PFGW
     * PLATFORM_ACCESS_GATEWAY: PFアクセスGW
     * EXTEND_ETHERNET: エクステンドイーサネット方式
     * IPSEC: IPsec方式
     * ETHER2: イーサネット方式Ⅱ
     * ETHER2_PLATFORM: イーサネット方式Ⅱ PF
     * BROADBAND3: ブロードバンドアクセス方式Ⅲ
     * WIRELESS2: ワイヤレスアクセス方式Ⅱ
     * PLATFORM_ACCESS_GATEWAY2: PFアクセスGWⅡ
     * VN_L2L3_CONNECT: VN L2/L3コネクト
     * REMOTE_GW2_FRE: リモートアクセスGW2(FRE)
     * REMOTE_GW2_CPA: リモートアクセスGW2(CPA)
     * REMOTE_GW2_CRG: リモートアクセスGW2(CRG)
     * ETHERNET_LIGHT: イーサネットライト
     * MULTI_CLOUD_GW: マルチクラウドGW
     * 5GSA_CPA: 5GSA CPA
     */
    accessType:
      | "INTERNET"
      | "ETHERNET_L2"
      | "ETHERNET_L3"
      | "BROADBAND2_L2"
      | "BROADBAND2_L3"
      | "WIRELESS_L2"
      | "WIRELESS_L3"
      | "REMOTE_GW_FRE"
      | "REMOTE_GW_CPA"
      | "REMOTE_GW_CRG"
      | "PLATFORM_GATEWAY"
      | "PLATFORM_ACCESS_GATEWAY"
      | "EXTEND_ETHERNET"
      | "IPSEC"
      | "ETHER2"
      | "ETHER2_PLATFORM"
      | "BROADBAND3"
      | "WIRELESS2"
      | "PLATFORM_ACCESS_GATEWAY2"
      | "VN_L2L3_CONNECT"
      | "REMOTE_GW2_FRE"
      | "REMOTE_GW2_CPA"
      | "REMOTE_GW2_CRG"
      | "ETHERNET_LIGHT"
      | "MULTI_CLOUD_GW"
      | "CPA_5GSA";
    /** サービス開廃年月日.  */
    startEndDate: string;
    /** セキュリティ監視オプションフラグ. 有りの場合は「true」、無しの場合は「false」を設定する。 */
    isSecurityOption: boolean;
    /** */
    delegatedContract: {
      /** 設定代行回数. 設定代行を行った回数 */
      settingTimes: string;
      /** 変更管理代行回数. 変更管理代行を行った回数 */
      contractTimes: string;
    } | null;
    /** 住所.  */
    address: string | null;
    /** ビル名.  */
    building: string | null;
    /**
     * 帯域タイプ. 以下のいずれかを設定する。
     * BANDWIDTH_SECURED:帯域確保
     * TRAFFICFREE:トラフィックフリー
     * BURST10:バースト10
     * BURST100:バースト100
     * BESTEFFORT:ベストエフォート
     */
    bandwidthType:
      | "BANDWIDTH_SECURED"
      | "TRAFFICFREE"
      | "BURST10"
      | "BURST100"
      | "BESTEFFORT";
    /** */
    bandwidth: {
      /** 設定値. */
      value: number;
      /**
       * 単位. 以下のいずれかを設定する。
       * KBPS: Kbps
       * MBPS: Mbps
       * GBPS: Gbps
       */
      unit: "KBPS" | "MBPS" | "GBPS";
    } | null;
    /**
     * QoS. 以下のいずれかを設定する。
     * ON: 有
     * OFF: 無
     * ただしアクセス品目が以下のいずれかの場合、QoSは設定不可となり、NULLが設定される。
     * PFアクセスGW、エクステンドイーサ、ブロードバンドアクセス方式Ⅲ、ワイヤレスアクセス方式Ⅱ、イーサネットライト、PFアクセスGWⅡ、マルチクラウドGW
     */
    qos: "ON" | "OFF" | null;
    /**
     * VLAN制御. 以下のいずれかを設定する。
     * ON: ON
     * OFF: OFF
     * ただしアクセス品目が以下のいずれかの場合、VLAN制御は設定不可となり、NULLが設定される。
     * PFアクセスGW、エクステンドイーサ、PFアクセスGWⅡ、マルチクラウドGW
     */
    vlanControl: "ON" | "OFF" | null;
    /** 最終更新年月日. 契約情報が最後に更新された日付  */
    updateDate: string;
  }[];
}

/** 契約に関するサマリー */
export interface ContractSummary {
  /** V番号 */
  vnumber: string;
  /** 契約者名 */
  contractor: string;
  /** VPNコード */
  vpnCode: string;
  /** メールアドレス */
  email: string;
  /** 大規模契約フラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isLargeScaleContract: boolean;
  /** VNコネクト事前設定可能フラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isVnConnectPreSetPossible: boolean;
  /** NATフラグ 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isNat: boolean;
  /** パケットフィルタリングフラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isPacketFiltering: boolean;
  /** インターネットFWフラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isInternetFw: boolean;
  /** UTMフラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isUtm: boolean;
  /** イントラネットFWフラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isIntranetFw: boolean;
  /** インターネットTYPE1フラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isInternetType1: boolean;
  /** 仮想ネットワークL3フラグ. パートナー表示の場合は「true」、非表示の場合は「false」を設定する。 */
  isVirtualNetworkL3: boolean;
  /** 仮想ネットワークL2フラグ. パートナー表示の場合は「true」、非表示の場合は「false」を設定する。 */
  isVirtualNetworkL2: boolean;
  /** セキュリティ監視設定フラグ. パートナー表示の場合は「true」、非表示の場合は「false」を設定する。 */
  isSecuritySetting: boolean;
  /** トラフィック情報検索フラグ. パートナー表示の場合は「true」、非表示の場合は「false」を設定する。 */
  isTrafficInfo: boolean;
  /** セッション情報検索フラグ. パートナー表示の場合は「true」、非表示の場合は「false」を設定する。 */
  isSessionInfo: boolean;
  /** 統計情報検索フラグ. パートナー表示の場合は「true」、非表示の場合は「false」を設定する。 */
  isStatisticsInfo: boolean;
  /** ログ保存用ディスクフラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isLogKeepDisc: boolean;
  /** 詳細トラフィックレポートフラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isDetailTrafficReport: boolean;
  /** 設定代行フラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isSetupAgency: boolean;
  /** ダウンロードフラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isDownloadList: boolean;
  /** Allowログフラグ. 契約がある場合は「true」、ない場合は「false」を設定する。 */
  isAllowLog: boolean;
}

/** アプライアンス情報一覧 */
export interface ContractAppliances {
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  /** */
  applianceInfoList: {
    /**
     * 契約SEQ
     * 主キー
     */
    contractSeq: string;
    /**
     * E番号(Main_ACT)
     * インターネット回線種別がType1以外の場合、NULLとなる。
     * Type1では必須
     */
    enumberMainAct: string | null;
    /**
     * E番号(Main_SBY)
     * インターネット回線種別がType1以外の場合、NULLとなる。
     * Type1では必須
     */
    enumberMainSby: string | null;
    /**
     * E番号(BCP_ACT)
     * インターネット回線種別がType1以外の場合、NULLとなる。
     * Type1では必須
     */
    enumberBcpAct: string | null;
    /**
     * E番号(BCP_SBY)
     * インターネット回線種別がType1以外の場合、NULLとなる。
     * Type1では必須
     */
    enumberBcpSby: string | null;
    /**
     * W番号(Main_ACT)
     * インターネット回線種別がType4以外の場合、NULLとなる。
     * Type4では必須
     */
    wnumberMainAct: string | null;
    /**
     * W番号(Main_SBY)
     * インターネット回線種別がType4以外の場合、NULLとなる。
     * Type4では必須
     */
    wnumberMainSby: string | null;
    /**
     * W番号(BCP_ACT)
     * インターネット回線種別がType4以外の場合、NULLとなる。
     * Type4では必須
     */
    wnumberBcpAct: string | null;
    /**
     * W番号(BCP_SBY)
     * インターネット回線種別がType4以外の場合、NULLとなる。
     * Type4では必須
     */
    wnumberBcpSby: string | null;
    /** VPN/VNコード */
    vpnVnCode: string;
    /**
     * インターネット回線種別
     * 以下のいずれかを指定
     * TYPE1：インターネットType1
     * TYPE4：インターネットType4
     * INTRANET：イントラネットFW
     */
    internetType: "TYPE1" | "TYPE4" | "INTRANET";
    /**
     * Type4名
     * インターネット回線種別がType4以外の場合、NULLとなる。
     * Type4では必須
     */
    type4Name: string | null;
    /**
     * Type4ID
     * インターネット回線種別がType4以外の場合、NULLとなる。
     * Type4では必須
     */
    type4Id: string | null;
    /**
     * メインサイト
     * インターネット回線種別がType4以外の場合、NULLとなる。
     * Type4では必須
     * TOKYO：東京
     * OSAKA：大阪
     */
    mainSite: "TOKYO" | "OSAKA" | null;
    /**
     * M365経路制御オプション
     * インターネット回線種別がType4以外の場合、NULLとなる。
     * Type4では必須
     * ON：true
     * OFF：false
     */
    isM365: boolean | null;
    /**
     * アプライアンス種別
     * 以下のいずれかを指定する。
     * UTM_BE:UTM(BE)
     * UTM_CONTRACT_BAND:UTM(契約帯域)
     * IFW_BE:IFW(BE)
     * IFW_CONTRACT_BAND:IFW(契約帯域)
     * INTRANET_FW:イントラネットFW(契約帯域)
     * 契約帯域の場合、帯域値を設定する。
     */
    applianceType:
      | "UTM_BE"
      | "UTM_CONTRACT_BAND"
      | "IFW_BE"
      | "IFW_CONTRACT_BAND"
      | "INTRANET_FW";
    /**
     * セキュリティオプションフラグ
     * インターネット回線種別がイントラネットFWの場合、NULLとなる。
     * イントラネットFW以外では必須
     * ON：true
     * OFF：false
     */
    isSecurityOption: boolean | null;
    /**
     * インターネット接続開始フラグ
     * インターネット回線種別がイントラネットFWの場合、NULLとなる。
     * イントラネットFW以外では必須
     * 接続：true
     * 未接続：false
     */
    isStart: boolean | null;
    /**
     * 一時停止フラグ
     * インターネット回線種別がイントラネットFWの場合、NULLとなる。
     * イントラネットFW以外では必須
     * 一時停止：true
     * 稼働中：false
     */
    isInternetPause: boolean | null;
    /** インターネット帯域 */
    bandwidth: {
      /** 設定値. */
      value: number;
      /**
       * 単位
       * 以下のいずれかを設定。設定値が「0」の場合、nullとなる。
       * KBPS: Kbps
       * MBPS: Mbps
       * GBPS: Gbps
       */
      unit: "KBPS" | "MBPS" | "GBPS";
    } | null;
    /** グローバルIP追加数(単位:個) */
    numberOfAddGlobalIpAddress: number;
  }[];
}

/**
 * アプライアンス一覧の項目型定義
 */
export type ApplianceInfo = ContractAppliances["applianceInfoList"][0];

export default class ContractApi extends AbstractBaseApi {
  /** 契約回線情報を取得 */
  async getInformation(): Promise<ContractInfo> {
    return (await this.api.get("/v1/contract/information")).data;
  }

  /** 契約に関するサマリーを取得する */
  async getSummary(): Promise<ContractSummary> {
    return (await this.api.get("/v1/contract/summary")).data;
  }

  /** アプライアンス情報一覧を取得 */
  async getAppliances(): Promise<ContractAppliances> {
    return (await this.api.get("/v1/contract/appliances")).data;
  }
}

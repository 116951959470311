
































import Vue, { PropType } from "vue";
import { GetSrcAddress1, GetSrcAddress2 } from "@/apis/NwPolicyApi";

/** インターネットType1,4のポリシーの送信元アドレス表示が出来るコンポーネント */
export default Vue.extend({
  name: "PolicySrcAddress",
  props: {
    /** 送信元アドレス */
    srcAddress: {
      type: Object as PropType<GetSrcAddress1 | GetSrcAddress2>,
      required: true,
    },
  },
  computed: {
    /** true: GetSrcAddress1, false: GetSrcAddress2 */
    isAddress1(): boolean {
      return (
        "srcFqdnList" in this.srcAddress && "srcCountryList" in this.srcAddress
      );
    },
    /** アドレス一覧を昇順でソートした一覧 */
    addressList(): GetSrcAddress1["srcAddressList"] {
      return [
        ...this.srcAddress.srcAddressList
          .filter((e) => e.ipAddress && !e.ipAddressName)
          .sortBy(["ipAddress", "asc"]),
        ...this.srcAddress.srcAddressList
          .filter((e) => e.ipAddressName)
          .sortBy(["ipAddressName", "asc"]),
      ];
    },
    /** FQDN一覧を昇順でソートした一覧. GetSrcAddress1では無い場合は空 */
    fqdnList(): GetSrcAddress1["srcFqdnList"] {
      if (this.isAddress1) {
        return (this.srcAddress as GetSrcAddress1).srcFqdnList.sortBy([
          (e) => e.fqdnName ?? e.fqdn,
          "asc",
        ]);
      } else {
        return [];
      }
    },
    /** 国一覧を昇順でソートした一覧. GetSrcAddress1では無い場合は空 */
    countryList(): GetSrcAddress1["srcCountryList"] {
      if (this.isAddress1) {
        return (this.srcAddress as GetSrcAddress1).srcCountryList.sortBy([
          "country",
          "asc",
        ]);
      } else {
        return [];
      }
    },
  },
});

































































import Vue, { PropType } from "vue";
import { FlowEntity } from "@/modals/site/SiteReference.vue";
import cloneDeep from "lodash/cloneDeep";
import { AppTableData } from "@/components/AppTable.vue";
import SiteFlowEdit from "@/modals/site/SiteFlowEdit.vue";
import { SiteListEntity } from "@/views/site/SiteList.vue";

export default Vue.extend({
  name: "SiteFlowListModify",
  props: {
    /** サイト情報 */
    siteData: {
      type: Object as PropType<SiteListEntity>,
      required: true,
    },
    /** フロー情報一覧 */
    flowData: {
      type: Array as PropType<FlowEntity[]>,
      required: true,
    },
    /** フロー情報排他制御キー */
    flowKeyId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    const flowClone: FlowEntity[] | undefined = cloneDeep(this.flowData);
    // 主キー相当の項目が無いため、こちらでインデックスを作成
    flowClone?.numbering();
    return {
      flow: {
        fields: [
          { key: "_handle", label: "↑↓", tdClass: "handle" },
          {
            key: "destWnumVpnCode",
            label: "",
            formatter: (value: string, key: null, item: FlowEntity) => {
              if (item.wnumber) {
                return `${item.vnConnectName}(${item.wnumber})`;
              } else {
                return `L3VPN(${item.vpnCode})`;
              }
            },
          },
          {
            key: "srcAddress",
            label: "送信元アドレス",
            formatter: (value: string) => this.$filter.emptyTo(value, "any"),
          },
          {
            key: "dstAddress",
            label: "宛先アドレス",
            formatter: (value: string) => this.$filter.emptyTo(value, "any"),
          },
          {
            key: "description",
            label: "備考",
            tdClass: "text-pre-wrap",
            formatter: (value: string) => this.$filter.emptyTo(value, "-"),
          },
          {
            key: "flowStatus",
            label: "有効/無効",
            formatter: (value: string) =>
              this.$filter.enumTo(value, "flowStatusItems"),
          },
          {
            key: "ifStatus",
            label: "IF状態",
            formatter: (value: string) =>
              this.$filter.enumTo(value, "cloudVn_ifStatus"),
          },
          { key: "delete", label: "" },
        ],
        items: flowClone ?? [],
      } as Pick<AppTableData<FlowEntity>, "fields" | "items">,
    };
  },
  methods: {
    /** フローを追加 */
    async registerFlow() {
      const addFlow: FlowEntity = await this.$modal.show(SiteFlowEdit, {
        enumber: this.siteData.enumber,
        vlanIdType: this.siteData.vlanIdType,
        vlanId: this.siteData.vlanId,
      });
      this.flow.items.splice(
        this.flow.items.length,
        0,
        this.flow.items.nextNumber({
          ...addFlow,
        })
      );
    },

    /** フロー情報変更 */
    async modifyFlow(flowDetail: FlowEntity, index: number) {
      const updatedItem: FlowEntity = await this.$modal.show(SiteFlowEdit, {
        flow: flowDetail,
        enumber: this.siteData.enumber,
        vlanIdType: this.siteData.vlanIdType,
        vlanId: this.siteData.vlanId,
      });
      this.$set(this.flow.items, index, updatedItem);
    },

    /** フロー削除 */
    async deleteFlow(index: number) {
      this.flow.items.splice(index, 1);
    },

    /** フロー変更 */
    async submit() {
      await this.$confirm(`フロー情報を変更します。よろしいですか？`);
      await this.$api.virtualNetwork.updateFlowSettings({
        enumber: this.siteData.enumber,
        vlanIdType: this.siteData.vlanIdType,
        vlanId:
          this.siteData.vlanIdType === "UNTAG" ||
          this.siteData.vlanIdType === "OTHER"
            ? null
            : this.siteData.vlanId,
        flowModifyList: this.flow.items.map((e) => ({
          flowSeq: e.flowSeq || null,
          wnumber: e.wnumber || null,
          vpnCode: e.vpnCode || null,
          srcAddress: e.srcAddress || null,
          dstAddress: e.dstAddress || null,
          description: e.description || null,
        })),
        flowKeyId: this.flowKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

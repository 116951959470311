








































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { Type4ExtraSite, Type4ExtraSiteList } from "@/apis/Type4SiteApi";
import { ApplianceInfo } from "@/apis/ContractApi";
import Type4ExtraSiteEdit from "@/modals/type4/Type4ExtraSiteEdit.vue";
import Type4ExtraSiteReference from "@/modals/type4/Type4ExtraSiteReference.vue";

export default Vue.extend({
  name: "Type4ExtraSiteList",
  props: {
    extraSite: {
      type: Object as PropType<Type4ExtraSite>,
      required: true,
    },
    type4Id: {
      type: String as PropType<ApplianceInfo["type4Id"]>,
      required: true,
    },
  },
  data() {
    return {
      siteList: {
        fields: [
          {
            key: "siteName",
            label: "サイト名",
            sortable: true,
          },
        ],
        items: [],
        selected: [],
      } as Pick<
        AppTableData<Type4ExtraSiteList>,
        "fields" | "items" | "selected"
      >,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    /** 初期化機能 */
    load() {
      this.siteList.items = this.extraSite.siteList;
    },
    /** 追加機能 */
    async toAddSite() {
      await this.$modal.show(Type4ExtraSiteEdit, {
        type4Id: this.type4Id,
        type4SiteKeyId: this.extraSite.type4SiteKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 行選択機能 */
    async extraSelected(item: Type4ExtraSiteList) {
      await this.$modal.show(Type4ExtraSiteReference, {
        extraSite: item,
        type4Id: this.type4Id,
        type4SiteKeyId: this.extraSite.type4SiteKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/**
 * デフォルトルートではないことを確認
 * チェック内容:
 *    値はデフォルトルート(0.0.0.0/0)の場合、false
 */
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) return true;
    return !(value === "0.0.0.0/0");
  },
} as ValidationRuleSchema;





































































































































import Vue, { PropType } from "vue";
import ProfileUrlFilteringEdit from "@/modals/applianceObject/ProfileUrlFilteringEdit.vue";
import { DeleteUrlProfile, UrlProfile } from "@/apis/UrlProfileApi";

export default Vue.extend({
  name: "ProfileUrlFilteringReference",
  props: {
    profileUrlSeq: {
      type: String as PropType<string>,
      required: true,
    },
    type4Id: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isLoaded: false,
      urlProfile: {} as UrlProfile,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** プロファイル(URLフィルタリング)詳細を取得 */
    async load() {
      if (!this.type4Id) {
        this.urlProfile = await this.$api.urlProfileApi.getType1UrlFiltering(
          this.profileUrlSeq
        );
      } else {
        this.urlProfile = await this.$api.urlProfileApi.getType4UrlFiltering(
          this.type4Id,
          this.profileUrlSeq
        );
      }
    },
    /** URLフィルタリングを編集 */
    async showProfileUrlFilteringModify() {
      await this.$modal.show(ProfileUrlFilteringEdit, {
        urlProfile: this.urlProfile,
        profileUrlSeq: this.profileUrlSeq,
        type4Id: this.type4Id,
        profileUrlKeyId: this.urlProfile.profileUrlKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** URLフィルタリングを削除 */
    async deleteUrlFiltering() {
      // 確認ダイアログの表示
      await this.$confirm("プロファイルを削除します。よろしいですか？");

      // リクエストデータの作成
      const reqData: DeleteUrlProfile = {
        profileUrlSeqList: [this.profileUrlSeq],
        profileUrlKeyId: this.urlProfile.profileUrlKeyId,
        note: null,
      };

      // プロファイル(URLフィルタリング)削除
      if (!this.type4Id) {
        await this.$api.urlProfileApi.deleteType1UrlFiltering(reqData);
      } else {
        await this.$api.urlProfileApi.deleteType4UrlFiltering(
          this.type4Id,
          reqData
        );
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});













































































import Vue, { PropType } from "vue";
import { ZoneRelation } from "@/apis/ZoneRelationApi";
import { Type1Zone } from "@/apis/Type1SiteApi";
/** ゾーン接続追加用 */
export type Type1ZoneOption = Type1Zone & {
  type: "INTERNET" | "EXTRA" | "PRIVATE";
};

export default Vue.extend({
  name: "InternetType1ZoneRelationRegister",
  props: {
    /** ゾーン関係設定排他情報 */
    zoneRelationKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    relations: {
      type: Object as PropType<ZoneRelation>,
      required: true,
    },
  },
  data() {
    return {
      /** 初期通信完了フラグ */
      isLoaded: false,
      // 追加ボタンの押下条件でインターネットゾーンは存在することが確定している
      internetZone: {} as Type1Zone,
      extraZone: {} as Type1Zone | null,
      privateZone: [] as Type1Zone[],
      zoneOption: [] as Type1Zone[],

      form: {
        srcZone: null as null | Type1ZoneOption,
        dstZone: null as null | Type1ZoneOption,
      },
    };
  },
  computed: {
    /** ゾーン一覧（インターネット、エクストラ、プライベート） */
    zoneOptions(): { value: Type1ZoneOption; text: string }[] {
      return [
        {
          value: { ...this.internetZone, type: "INTERNET" },
          text: "グローバルゾーン/" + this.internetZone.zoneName,
        },
        ...(this.extraZone
          ? [
              {
                value: { ...this.extraZone, type: "EXTRA" } as Type1ZoneOption,
                text: "エクストラゾーン/" + this.extraZone.zoneName,
              },
            ]
          : []),
        ...this.privateZone.map((v) => ({
          value: { ...v, type: "PRIVATE" } as Type1ZoneOption,
          text: "プライベートゾーン/" + v.zoneName,
        })),
      ];
    },
    /**
     * 宛先ゾーン一覧.
     * 選択済の送信元ゾーンによって選択不可な選択肢をフィルタリング
     */
    dstZoneOptions(): { value: Type1ZoneOption; text: string }[] {
      if (this.form.srcZone) {
        return this.zoneOptions.filter(({ value }) => {
          if (this.form.srcZone!.type === "INTERNET") {
            return value.type === "EXTRA" || value.type === "PRIVATE";
          } else {
            return value.type === "INTERNET";
          }
        });
      } else {
        return this.zoneOptions;
      }
    },
  },
  watch: {
    /** 送信元ゾーンが選択された際に宛先ゾーンが選択不可な値が入っていたらクリア */
    "form.srcZone"(value: Type1ZoneOption) {
      const dstZoneType = this.form.dstZone?.type;
      if (value.type === "INTERNET") {
        if (dstZoneType === "INTERNET") {
          this.form.dstZone = null;
        }
      } else {
        if (dstZoneType === "EXTRA" || dstZoneType === "PRIVATE") {
          this.form.dstZone = null;
        }
      }
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** データ取得 */
    async load() {
      this.internetZone = (
        await this.$api.type1SiteZone.getType1InternetList()
      ).zone!;
      this.extraZone = (await this.$api.type1SiteZone.getType1ExtraList()).zone;
      this.privateZone = (
        await this.$api.type1SiteZone.getType1PrivateList()
      ).zoneList.sortBy(["zoneName", "asc"]);
    },
    async submit() {
      await this.$confirm(`ゾーン接続を追加します。よろしいですか？`);
      await this.$api.zoneRelation.postZoneRelation({
        srcZoneId: this.form.srcZone!.zoneId,
        dstZoneId: this.form.dstZone!.zoneId,
        zoneRelationKeyId: this.zoneRelationKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});


















































































































































































































































import Vue, { PropType } from "vue";
import { GetPolicyType4 } from "@/apis/NwPolicyApi";
import { Type4NatPolicy } from "@/modals/applianceContractSetting/InternetType4PolicyRuleDestNatViewRD.vue";
import cloneDeep from "lodash/cloneDeep";
import { Type4InternetAddress, Type4InternetSite } from "@/apis/Type4SiteApi";
import { IpMappings } from "@/apis/IpMappingApi";
import InternetType4PolicyRuleDestNatConfirm from "@/modals/applianceContractSetting/InternetType4PolicyRuleDestNatConfirm.vue";
import MultiSelectTag from "@/components/MultiSelectTag.vue";
import Type4InternetSiteModify from "@/modals/type4/Type4InternetSiteModify.vue";

export default Vue.extend({
  name: "InternetType4PolicyRuleDestNatEdit",
  components: { MultiSelectTag },
  props: {
    /** Type4Id. 主キー */
    type4Id: {
      type: String as PropType<string>,
      required: true,
    },
    /** 変更対象ポリシー */
    policy: {
      type: Object as PropType<Type4NatPolicy>,
      default: undefined,
    },
    /** type4ポリシー全体 */
    type4Policy: {
      type: Object as PropType<GetPolicyType4>,
      required: true,
    },
  },
  data() {
    const clonePolicy = cloneDeep(this.policy);
    const form: Type4NatPolicy = clonePolicy ?? {
      srcAddressList: [],
      ipMapping: null,
      isPolicyStatus: true,
    };

    return {
      form,
      // 送信元、宛先（any/アドレス）
      radioSrcAddress: (form.srcAddressList.length === 0 ? "ANY" : "SELECT") as
        | "ANY"
        | "SELECT",
      // インターネット（アドレス一覧）
      internetAddresses: [] as Type4InternetSite["addressList"],
      // IPマッピング一覧
      ipMappings: [] as IpMappings["ipMappingList"],

      isLoaded: false,
    };
  },
  computed: {
    /** true: 編集, false: 新規 */
    isEdit(): boolean {
      return !!this.policy;
    },
    /** 送信元アドレスのSelectの選択肢 */
    srcAddresses(): {
      label: string;
      values: Type4InternetSite["addressList"];
    }[] {
      return this.internetAddresses.length !== 0
        ? [
            {
              label: "全選択",
              values: this.internetAddresses,
            },
          ]
        : [];
    },
    /** 既存のtype4ポリシーID一覧 */
    existsPolicyIds(): string[] {
      const p = this.type4Policy;
      return [
        ...(p.policyUtmType4?.accessPointToInternetPolicyList ?? []),
        ...(p.policyUtmType4?.internetToAccessPointPolicyList ?? []),
        ...(p.policyIfwType4?.accessPointToInternetPolicyList ?? []),
        ...(p.policyIfwType4?.internetToAccessPointPolicyList ?? []),
        ...(p.policyIPMasqueradeType4?.policyList ?? []),
        ...(p.policyNatType4?.policyList ?? []),
      ].map((e) => e.policyId);
    },
  },
  async mounted() {
    // 選択肢などの必要情報を取得
    this.internetAddresses = (
      await this.$api.type4SiteApi.getType4InternetSite(this.type4Id)
    ).addressList.sortBy(["ipAddress", "asc"]);
    this.ipMappings = (
      await this.$api.ipMapping.getType4IpMappingList(this.type4Id)
    ).ipMappingList.sortBy(["ipMappingName", "asc"]);

    this.isLoaded = true;
  },
  methods: {
    /** ルール編集の完了 */
    async submit() {
      const policy = {
        ...this.form,
        srcAddressList:
          this.radioSrcAddress === "ANY" ? [] : this.form.srcAddressList,
      } as Type4NatPolicy;

      await this.$modal.show(InternetType4PolicyRuleDestNatConfirm, { policy });
      (this.$refs.modal as Vue & { ok: (entity: Type4NatPolicy) => void }).ok(
        policy
      );
    },
    /** アドレス選択肢ラベル名 */
    addressLabel({ ipAddressName, ipAddress }: Type4InternetAddress): string {
      return `${ipAddressName}  ${ipAddress}`;
    },
    /** IPマッピングラベル名（検索用） */
    ipMappingLabel({
      ipMappingName,
      description,
    }: IpMappings["ipMappingList"][0]): string {
      return `${ipMappingName} ${description}`;
    },
    /** インターネットサイト編集 */
    async showInternetSiteEdit() {
      let internetSite = await this.$api.type4SiteApi.getType4InternetSite(
        this.type4Id
      );
      await this.$modal.show(Type4InternetSiteModify, {
        internetSite: internetSite,
        type4Id: this.type4Id,
      });
      // インターネットアドレスリスト更新
      this.isLoaded = false;
      internetSite = await this.$api.type4SiteApi.getType4InternetSite(
        this.type4Id
      );
      this.internetAddresses = [
        ...internetSite.addressList.sortBy(["ipAddress", "asc"]),
      ];
      this.isLoaded = true;
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"IP Pool設定","classes":"modal-medium"},scopedSlots:_vm._u([(_vm.isLoaded)?{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"modal-content"},[(_vm.ipPool)?[_c('div',{staticClass:"detail-container"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12",attrs:{"label":"名前"}},[_c('span',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.ipPoolInfo.ipPoolName)+" ")])])],1)])]:[_c('validation-provider',{attrs:{"name":"名前","rules":{
                required: true,
                max: 35,
                is_half_width_alpha_numeric: true,
                is_not_both: ' ',
              }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"名前","msg":"半角英数 35文字以内","placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.ipPoolInfo.ipPoolName),callback:function ($$v) {_vm.$set(_vm.ipPoolInfo, "ipPoolName", $$v)},expression:"ipPoolInfo.ipPoolName"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],_c('validation-provider',{attrs:{"name":"グローバルIP","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"グローバルIP","isInfo":false}})]},proxy:true}],null,true)},[_c('b-form-select',{attrs:{"options":_vm.globalIpList,"state":_vm._f("validState")(validContext)},model:{value:(_vm.ipPoolInfo.globalIpAddress),callback:function ($$v) {_vm.$set(_vm.ipPoolInfo, "globalIpAddress", $$v)},expression:"ipPoolInfo.globalIpAddress"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"備考","rules":{ max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"備考","isRequired":false,"msg":_vm._f("msg")('info_max_length',{ maxLength: '20' }),"placement":"right"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.ipPoolInfo.description),callback:function ($$v) {_vm.$set(_vm.ipPoolInfo, "description", $$v)},expression:"ipPoolInfo.description"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],2),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.ipPool ? "変更" : "追加"))]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)])]}}],null,true)})]}}:null],null,true)},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
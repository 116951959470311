import Vue from "vue";
import Vuex from "vuex";
import { userStoreModule } from "@/store/user-store";
import { metaStoreModule } from "@/store/meta-store";
import { provisioningStoreModule } from "@/store/provisioning-store";
import { provisioningQosStoreModule } from "@/store/provisioning-qos-store";

Vue.use(Vuex);

// Vuex3では型推論が厳しい気がする。Vue3にしたらちゃんと出来る
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RootState = any;

export default new Vuex.Store({
  strict: process.env.NODE_ENV === "development",
  modules: {
    meta: metaStoreModule,
    user: userStoreModule,
    provisioning: provisioningStoreModule,
    provisioningQos: provisioningQosStoreModule,
  },
});

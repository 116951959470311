import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 帯域
  備考:
    契約種別がベストエフォートの場合はnull、帯域確保の場合は必須
    低帯域アプライアンスの場合は10~1000Mbps
    広帯域アプライアンスの場合は設定された上限・下限値に従う。
 */
export interface Bandwidth {
  /**
   設定値
   */
  value: number;
  /**
   単位
    備考:
      以下のいずれかを設定。
      KBPS:Kbps
      MBPS:Mbps
      GBPS:Gbps
   */
  unit: "KBPS" | "MBPS" | "GBPS";
}

/**
 グローバルIPアドレス
 */
export interface GlobalIpAddress {
  /**
   グローバルIPアドレスSEQ
    備考:
      グローバルIPアドレスの主キー
   */
  globalIpAddressSeq: string;
  /**
   グローバルIPアドレス
   */
  globalIpAddress: string;
  /**
   初期アドレスフラグ
    備考:
      true：初期グローバルIPアドレス(削除不可)
      false：追加グローバルIPアドレス
   */
  isInitialAddress: boolean;
}

/**
 アプライアンス一覧の項目オブジェクト
 */
export interface Type1Service {
  /**
   デフォルトポリシー種別
    備考:
      ALLOW_SPECIFIC_ONE_WAY：片方向の特定サービスallow
      ALLOW_ALL_ONE_WAY：片方向の全allow
      ALLOW_ALL_TWO_WAY：双方向の全allow
   */
  policyType:
    | "ALLOW_SPECIFIC_ONE_WAY"
    | "ALLOW_ALL_ONE_WAY"
    | "ALLOW_ALL_TWO_WAY";
  /**
   契約種別
    備考:
      アプライアンス種別がインターネットFWまたは帯域タイプが帯域指定不可(BEのみ)の場合、
      ベストエフォートのみ選択可能
      BEST_EFFORT：ベストエフォート
      SECURING：帯域確保
   */
  contractType: "BEST_EFFORT" | "SECURING";
  /**
   帯域
    備考:
      契約種別がベストエフォートの場合はnull、帯域確保の場合は必須
      低帯域アプライアンスの場合は10~1000Mbps
      広帯域アプライアンスの場合は設定された上限・下限値に従う。
   */
  bandwidth: Bandwidth | null;
  /**
   アプライアンス種別
    備考:
      IFW：インターネットFW
      UTM：UTM
   */
  applianceType: "IFW" | "UTM";
  /**
   インターネットType1契約排他情報
    備考:
      排他確認用パラメータ
      設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  contractType1KeyId: string;
  /** E番号(Main_ACT) */
  enumberMainAct: string;
  /** E番号(Main_SBY) */
  enumberMainSby: string;
  /** E番号(BCP_ACT) */
  enumberBcpAct: string;
  /** E番号(BCP_SBY) */
  enumberBcpSby: string;
  /** VPNコード */
  vpnCode: string;
  /**
   セキュリティオプションフラグ
    備考:
      セキュリティ監視を契約している場合true
   */
  isSecurityOption: boolean;
  /**
   グローバルIPアドレスリスト
    備考:
      インターネット契約時に初期グローバルIPアドレスが一つ払い出される。
      初期グローバルIPアドレスが先頭、
      その他のアドレスは追加順でソートされる。
   */
  globalIpAddressList: GlobalIpAddress[];
  /**
   状態
    備考:
      ACTIVE: 稼働中。利用開始後かつ、一時停止中ではない状態
      INACTIVE: 停止。利用開始後かつ、一時停止中の状態
   */
  status: "ACTIVE" | "INACTIVE";
}

/** サービスメニュー(インターネットType1)の変更用オブジェクト */
export interface ContractChangeType1PutRequest {
  /**
   グローバルIP追加数(単位:個)
    備考
      追加で払い出すグローバルIPアドレスの数。
      初期グローバルIPアドレスが必ず一つ含まれるがそれを数に含めない。
      初期グローバルIPアドレスのみで利用する場合はnull。
   */
  numberOfAddGlobalIpAddress: number | null;
  /** 削除グローバルIPアドレスSEQリスト
    備考
      0件の場合はnull
   */
  deleteGlobalIpAddressSeqList: string[] | null;
  /** 操作履歴の備考 */
  note: string | null;
  /**
   排他確認用パラメータ
    備考
      PUTメソッドにて変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  contractType1KeyId: string;
  /**
   帯域値
    備考
      インターネットFWで帯域指定の際は必須とする。
   */
  bandwidth: Bandwidth | null;
}

/** サービスメニュー(Type1アプライアンス)の取得用オブジェクト */
export interface ContractGetType1Appliance {
  /** 排他確認用パラメータ */
  contractType1KeyId: string;
  /** UTM契約状態(true：ON、false：OFF) */
  utmEnabled: boolean;
  /** インターネットFW契約状態(true：ON、false：OFF) */
  ifwEnabled: boolean;
  /** NAT契約状態(true：ON、false：OFF) */
  natEnabled: boolean;
  /** NATルール数 */
  natRuleCount: number;
}

/** サービスメニュー(Type1アプライアンス)の変更用オブジェクト */
export interface ContractChangeType1Appliance {
  /** 排他確認用パラメータ */
  contractType1KeyId: string;
  /** UTM契約状態(true：ON、false：OFF) */
  utmEnabled: boolean;
  /** インターネットFW契約状態(true：ON、false：OFF) */
  ifwEnabled: boolean;
  /** NAT契約状態(true：ON、false：OFF) */
  natEnabled: boolean;
  /** NATルール数 */
  note: string | null;
}

export default class ContractChangeType1Api extends AbstractBaseApi {
  /** サービスメニュー(インターネットType1)を取得する */
  async getType1Service(): Promise<Type1Service> {
    return (await this.api.get("/v1/contract/service/appliance/type1")).data;
  }

  /** サービスメニュー(インターネットType1)の変更を行う */
  async putType1Service(data: ContractChangeType1PutRequest): Promise<void> {
    await this.api.post("/v1/contract/service/appliance/type1", data);
  }

  /** Type1のNAT、インターネットFW、UTMの契約状態を取得する */
  async getContractType1Appliance(): Promise<ContractGetType1Appliance> {
    return (await this.api.get("/v1/contract/service/appliance/brief/type1"))
      .data;
  }

  /** Type1のNAT、インターネットFW、UTMの契約状態を変更する */
  async postContractType1Appliance(
    data: ContractChangeType1Appliance
  ): Promise<void> {
    await this.api.post("/v1/contract/service/appliance/brief/type1", data);
  }
}






































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import SiteReference from "@/modals/site/SiteReference.vue";
import SiteEdit from "@/modals/site/SiteEdit.vue";
import {
  Type1InternetAddress,
  Type1PrivateSiteDelete,
  Type1PrivateSiteDeleteLine,
} from "@/apis/Type1SiteApi";
import { mapState } from "vuex";

export default Vue.extend({
  name: "SiteList",
  data() {
    return {
      isLoaded: false,
      alertMsg: null as string | null,
      site: {
        fields: [
          {
            key: "enumber",
            label: "回線番号",
            sortable: true,
          },
          {
            key: "vlan",
            label: "VLAN",
            sortable: true,
            tdClass: "text-right",
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, key: null, item: SiteListEntity) => {
              if (item.vlanIdType === "SPECIFIED") {
                return `${item.vlanId}`;
              } else {
                return this.$filter.enumTo(item.vlanIdType, "vlanIdType");
              }
            },
          },
          {
            key: "siteName",
            label: "サイト名",
            sortable: true,
          },
        ],
        items: [],
        selected: [],
        search: {
          enumber: { type: "text", label: "回線番号" },
          vlan: { type: "text", label: "VLAN" },
          siteName: { type: "text", label: "サイト名" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          enumber: "like",
          vlan: "like",
          siteName: "like",
        },
      } as AppTableData<SiteListEntity>,
      type1SiteZoneKeyId: "",
    };
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** 一覧を取得 */
    async load() {
      this.alertMsg = null;
      /** Type1プライベートサイトゾーン取得API */
      const type1PrivateList =
        await this.$api.type1SiteZone.getType1PrivateList();
      this.type1SiteZoneKeyId = type1PrivateList.type1SiteZoneKeyId;
      this.site.items = type1PrivateList.lineList.flatMap((line) =>
        line.siteList.map((e) => ({
          enumber: line.enumber,
          accessType: line.accessType,
          vlanIdType: e.vlanIdType,
          vlanId: e.vlanId,
          siteName: e.siteName,
          siteId: e.siteId,
          addressList: e.addressList,
        }))
      );
    },
    /** サイト詳細表示 */
    async siteDetails(entity: SiteListEntity) {
      this.alertMsg = null;
      await this.$modal.show(SiteReference, {
        siteData: entity,
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      });
      await this.load();
    },

    /** サイト作成 */
    async registerSite() {
      this.alertMsg = null;
      await this.$modal.show(SiteEdit, {
        type1SiteZoneKeyId: this.type1SiteZoneKeyId,
      });
      await this.load();
    },

    /** サイト削除 */
    async deleteSite() {
      if (this.site.selected.length > 0) {
        if (
          this.contractSummary.isLargeScaleContract &&
          this.site.selected.length > 1
        ) {
          // 大規模契約ユーザでは一度に複数の行の削除はできません
          this.alertMsg = this.$msg("more_than_one_delete");
          return;
        }
        this.alertMsg = null;
        /** 確認ダイアログの表示 */
        await this.$confirm(`サイトを削除します。よろしいですか？`);

        /** APIリクエストデータのマッピング処理 */
        const lineList = [] as Type1PrivateSiteDeleteLine[];
        this.site.selected.map((e) => {
          if (lineList.find((i) => i.enumber === e.enumber)) {
            const idx = lineList.findIndex((i) => i.enumber === e.enumber);
            lineList[idx].siteIdList.push(e.siteId);
          } else {
            lineList.push({
              enumber: e.enumber,
              siteIdList: [e.siteId],
            });
          }
        });
        const deletedData: Type1PrivateSiteDelete = {
          lineList: lineList,
          type1SiteZoneKeyId: this.type1SiteZoneKeyId,
        };
        /** APIによるサイト削除処理 */
        await this.$api.type1SiteZone.postType1PrivateSiteDelete(deletedData);
        await this.load();
      } else {
        this.alertMsg = this.$msg("no_select_data");
      }
    },
    /** フロント側のみでのCSVダウンロード */
    downloadCsv() {
      this.$service.csvService.appTableCsvDownLoad(
        "site_setting",
        this.site.items.sortBy(["enumber", "desc"]),
        this.$service.csvService.genFieldList(this.site.fields)
      );
    },
  },
});
/** 一覧画面データマッピング用インタフェース */
export interface SiteListEntity {
  /** 回線番号 */
  enumber: string;
  /**VLAN IDのType指定*/
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED";
  /**VLAN ID*/
  vlanId: number | null;
  /** サイト名 */
  siteName: string;
  /** サイトID（サイト情報変更のパスパラメータ） */
  siteId: string;
  /** フロー情報設定時使用する情報 */
  accessType:
    | "ETHERNET_L2"
    | "ETHERNET_L3"
    | "BROADBAND_ACCESS2_L2"
    | "BROADBAND_ACCESS2_L3"
    | "WIRELESS_ACCESS_L2"
    | "WIRELESS_ACCESS_L3"
    | "REMOTE_ACCESS_GW_FRE"
    | "REMOTE_ACCESS_GW_CPA"
    | "REMOTE_ACCESS_GW_CRG"
    | "PLATFORM_GATEWAY"
    | "PF_ACCESS_GW"
    | "EXTEND_ETHERNET"
    | "IPSEC"
    | "ETHERNET2"
    | "ETHERNET2_PF"
    | "BROADBAND_ACCESS3"
    | "WIRELESS_ACCESS2"
    | "REMOTE_ACCESS_GW2_FRE"
    | "REMOTE_ACCESS_GW2_CPA"
    | "REMOTE_ACCESS_GW2_CRG"
    | "PF_ACCESS_GW2"
    | "ETHERNET_LIGHT";
  addressList: Type1InternetAddress[];
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"フロー設定","classes":"modal-large","name":"SiteFlowEdit","content-class":"modal-content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [(_vm.isLoaded)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-content"},[_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"接続先VNコネクト名(W番号)/ 接続先VPN(VPNコード)","isInfo":false,"isRequired":true}})]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"接続先VNコネクト名(W番号)/ 接続先VPN(VPNコード)","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('app-multiselect',{attrs:{"options":_vm.dstOptions,"track-by":"wnumberOrVpnCode","custom-label":_vm.dstInfoSelectLabel,"group-label":"dstLabel","group-values":"dstValues","state":_vm._f("validState")(validContext)},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [(option.$isLabel)?[_c('div',{staticClass:"multiselect-thead"},[_c('p',{staticClass:"col-3 p-0"},[_vm._v(" "+_vm._s(option.$groupLabel.wnumberOrVpnCode)+" ")]),_c('p',{staticClass:"col-8 p-0"},[_vm._v(" "+_vm._s(option.$groupLabel.vnConnectOrL3vpn)+" ")])])]:_c('div',{staticClass:"multiselect-tbody"},[_c('p',{staticClass:"col-3 p-0"},[_vm._v(_vm._s(option.wnumberOrVpnCode))]),_c('p',{staticClass:"col-8 p-0"},[_vm._v(_vm._s(option.vnConnectOrL3vpn))])])]}}],null,true),model:{value:(_vm.form.selectedDstInfo),callback:function ($$v) {_vm.$set(_vm.form, "selectedDstInfo", $$v)},expression:"form.selectedDstInfo"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"送信元アドレス","isRequired":_vm.form.radioSrcAddress !== null,"isInfo":_vm.form.radioSrcAddress !== null,"msg":_vm._f("msg")('info_cidr_ip_address_of_network_address',{
                      ipaddress1: '192.168.0.0/24',
                      genre: '送信元アドレス',
                      subnet1: '1',
                      subnet2: '31',
                      ipaddress2: '192.168.0.1/32',
                    })}})]},proxy:true}],null,true)},[_c('b-form-radio-group',{attrs:{"options":_vm.addrOptions},model:{value:(_vm.form.radioSrcAddress),callback:function ($$v) {_vm.$set(_vm.form, "radioSrcAddress", $$v)},expression:"form.radioSrcAddress"}}),(_vm.form.radioSrcAddress !== null)?_c('div',[_c('validation-provider',{attrs:{"name":"送信元アドレス","rules":{
                  required: true,
                  is_ip_subnet: { min: 1, max: 32 },
                  is_not_loopback_ip: true,
                  is_not_multicast_ip: true,
                  is_not_class_e_ip: true,
                  is_not_broadcast_ip: true,
                  is_network_address: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{staticClass:"col-12",attrs:{"placeholder":_vm.$msg('placeholder_ip_address_of_private_network_address'),"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.srcAddress),callback:function ($$v) {_vm.$set(_vm.form, "srcAddress", $$v)},expression:"form.srcAddress"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1):_c('div',[_c('b-form-input',{staticClass:"col-12",attrs:{"disabled":""}})],1)],1),_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"宛先アドレス","isRequired":_vm.form.radioDstAddress !== null,"isInfo":_vm.form.radioDstAddress !== null,"msg":_vm._f("msg")('info_cidr_ip_address_of_network_address',{
                      ipaddress1: '192.168.0.0/24',
                      genre: '宛先アドレス',
                      subnet1: '1',
                      subnet2: '31',
                      ipaddress2: '192.168.0.1/32',
                    })}})]},proxy:true}],null,true)},[_c('b-form-radio-group',{attrs:{"options":_vm.addrOptions},model:{value:(_vm.form.radioDstAddress),callback:function ($$v) {_vm.$set(_vm.form, "radioDstAddress", $$v)},expression:"form.radioDstAddress"}}),(_vm.form.radioDstAddress !== null)?_c('div',[_c('validation-provider',{attrs:{"name":"宛先アドレス","rules":{
                  required: true,
                  is_ip_subnet: { min: 1, max: 32 },
                  is_not_loopback_ip: true,
                  is_not_multicast_ip: true,
                  is_not_class_e_ip: true,
                  is_not_broadcast_ip: true,
                  is_network_address: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{staticClass:"col-12",attrs:{"placeholder":_vm.$msg('placeholder_ip_address_of_private_network_address'),"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.dstAddress),callback:function ($$v) {_vm.$set(_vm.form, "dstAddress", $$v)},expression:"form.dstAddress"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})],1):_c('div',[_c('b-form-input',{staticClass:"col-12",attrs:{"disabled":""}})],1)],1),_c('b-form-group',{staticClass:"col-12",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"備考","isRequired":false,"msg":_vm._f("msg")('info_max_length',{ maxLength: 20 })}}),_c('validation-provider',{attrs:{"name":"備考","rules":{ max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)})]},proxy:true}],null,true)})],1),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("設定")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル")])],1)])]}}],null,true)}):_vm._e()]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
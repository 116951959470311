import { ValidationRuleSchema } from "vee-validate/dist/types/types";

/** 半角数字11または15桁以内(-なし)で入力されること */

export default {
  params: ["max"],
  validate(
    value: string | undefined | null,
    { max }: { max: 11 | 15 }
  ): boolean {
    if (!value) {
      return true;
    }

    const reg = new RegExp("^[0-9]{1," + max + "}$");

    return reg.test(value);
  },
} as ValidationRuleSchema;

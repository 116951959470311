













































































































































































































































































































































import Vue, { PropType } from "vue";
import InternetType4PolicyIfwConfirm from "@/modals/applianceContractSetting/InternetType4PolicyIfwConfirm.vue";
import InternetType4PolicyRuleIfwViewRD from "@/modals/applianceContractSetting/InternetType4PolicyRuleIfwViewRD.vue";
import InternetType4PolicyRuleIpMasqViewRD from "@/modals/applianceContractSetting/InternetType4PolicyRuleIpMasqViewRD.vue";
import InternetType4PolicyRuleDestNatViewRD from "@/modals/applianceContractSetting/InternetType4PolicyRuleDestNatViewRD.vue";
import { Type4Service } from "@/apis/ContractChangeType4Api";
import { GetPolicy, GetPolicyType4 } from "@/apis/NwPolicyApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicyIpPool from "@/components/policy/PolicyIpPool.vue";
import PolicyProfile from "@/components/policy/PolicyProfile.vue";
import PolicyService from "@/components/policy/PolicyService.vue";
import { get2putPolicy } from "@/modals/applianceContractSetting/InternetType4PolicyUtmList.vue";
import { UploadError } from "@/services/ConfigDownloadService";

export default Vue.extend({
  name: "InternetType4PolicyIfwList",
  components: {
    PolicyService,
    PolicyProfile,
    PolicyIpPool,
    PolicyDstAddress,
    PolicySrcAddress,
  },
  props: {
    /** Type4Id. 主キー */
    type4Id: {
      type: String as PropType<string>,
      required: true,
    },
    /** サービスメニュー(インターネットType4) */
    type4Service: {
      type: Object as PropType<Type4Service>,
      required: true,
    },
  },
  data() {
    return {
      direction: "accessPointToInternet",
      policy: {} as GetPolicyType4,
      i2aNatTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddressList", label: "送信元アドレス" },
          { key: "ipMapping", label: "IPマッピング" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      i2aIfwTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddress", label: "送信元アドレス" },
          { key: "dstAddress", label: "宛先アドレス" },
          { key: "service", label: "サービス" },
          { key: "profile", label: "プロファイル" },
          { key: "actionType", label: "アクション" },
          { key: "isLogOutput", label: "ログ出力" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      a2iNatTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddressList", label: "送信元アドレス" },
          { key: "dstAddressList", label: "宛先アドレス" },
          { key: "ipPoolList", label: "IP Pool" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      a2iIfwTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddress", label: "送信元アドレス" },
          { key: "dstAddress", label: "宛先アドレス" },
          { key: "service", label: "サービス" },
          { key: "profile", label: "プロファイル" },
          { key: "actionType", label: "アクション" },
          { key: "isLogOutput", label: "ログ出力" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      uploadAlert: null as string | null,
      isLoaded: false,
    };
  },
  async mounted() {
    this.policy = await this.$api.nwPolicy.getType4Policies(
      this.type4Id,
      "IFW_IPMASQUERADE_NAT"
    );
    this.isLoaded = true;
  },
  methods: {
    /** 設定反映 */
    async putPolicy() {
      this.uploadAlert = null;
      // ポリシー画面からオブジェクトを追加するとkeyIdを更新する必要があるため以下で再取得
      this.isLoaded = false;
      this.policy.policyKeyId = (
        await this.$api.nwPolicy.getType4Policies(
          this.type4Id,
          "IFW_IPMASQUERADE_NAT"
        )
      ).policyKeyId;
      this.isLoaded = true;
      await this.$modal.show(InternetType4PolicyIfwConfirm, {
        type4Policy: this.policy,
        type4Service: this.type4Service,
      });
      await this.$api.nwPolicy.putType4Policies(
        this.type4Id,
        get2putPolicy(this.policy),
        "IFW_IPMASQUERADE_NAT"
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 設定ダウンロード */
    downloadConfig() {
      this.$service.configDownload.downloadInternetType4Policy(
        this.type4Id,
        "IFW",
        this.policy
      );
    },

    /** 設定アップロード */
    async uploadConfig() {
      try {
        const uploadPolicy =
          await this.$service.configDownload.uploadInternetType4Policy(
            this.type4Id,
            "IFW",
            this.policy
          );
        this.uploadAlert = null;
        await this.$confirm(
          "ポリシー設定を上書きします。設定反映していない設定は破棄されます。よろしいですか？"
        );
        this.policy = uploadPolicy;
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },

    /** インターネット→アクセス拠点 NATのポリシーを編集 */
    async editPolicyInternetToAccessNAT() {
      this.uploadAlert = null;
      this.policy.policyNatType4!.policyList = await this.$modal.show(
        InternetType4PolicyRuleDestNatViewRD,
        {
          type4Id: this.type4Id,
          policyList: this.policy.policyNatType4!.policyList,
          type4Policy: this.policy,
          type4Service: this.type4Service,
        }
      );
    },

    /** インターネット→アクセス拠点 FWのポリシーを編集 */
    async editPolicyInternetToAccessIFW() {
      this.uploadAlert = null;
      this.policy.policyIfwType4!.internetToAccessPointPolicyList =
        await this.$modal.show(InternetType4PolicyRuleIfwViewRD, {
          type4Id: this.type4Id,
          from: "INTERNET",
          policyList:
            this.policy.policyIfwType4!.internetToAccessPointPolicyList,
          type4Policy: this.policy,
          type4Service: this.type4Service,
        });
    },

    /** アクセス拠点→インターネット NATのポリシーを編集 */
    async editPolicyAccessToInternetNAT() {
      this.uploadAlert = null;
      this.policy.policyIPMasqueradeType4!.policyList = await this.$modal.show(
        InternetType4PolicyRuleIpMasqViewRD,
        {
          type4Id: this.type4Id,
          policyList: this.policy.policyIPMasqueradeType4!.policyList,
          type4Policy: this.policy,
          type4Service: this.type4Service,
        }
      );
    },

    /** アクセス拠点→インターネット FWのポリシーを編集 */
    async editPolicyAccessToInternetIFW() {
      this.uploadAlert = null;
      this.policy.policyIfwType4!.accessPointToInternetPolicyList =
        await this.$modal.show(InternetType4PolicyRuleIfwViewRD, {
          type4Id: this.type4Id,
          from: "ACCESS_POINT",
          policyList:
            this.policy.policyIfwType4!.accessPointToInternetPolicyList,
          type4Policy: this.policy,
          type4Service: this.type4Service,
        });
    },

    /**
     * ポリシーテーブルのtrクラス.
     * 編集不可デフォルトポリシーの場合は特定のクラスを返す
     */
    policyRowClass(item: Pick<GetPolicy, "policyCategoryInfo">): string | null {
      return item.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY"
        ? "not-editable-policy"
        : null;
    },
  },
});

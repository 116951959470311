


























































































import moment from "moment";
import Vue, { PropType } from "vue";

export type SearchSelectOption = {
  type: "select";
  label?: string;
  items: { text: string; value: unknown }[];
};
export type SearchOptionValue =
  | { type: "text" | "date" | "custom"; label?: string }
  | SearchSelectOption;
type SearchItem = SearchOptionValue & { key: string };
export type SearchOption<T> = Record<keyof T | string, SearchOptionValue>;

/** AWSのチックな検索UIを提供してくれるコンポーネント */
export default Vue.extend({
  name: "AppSearch",
  props: {
    /**
     * 検索オプション
     * 検索の項目と種類など情報を設定することで任意の検索UIが作成できる
     */
    search: {
      type: Object as PropType<SearchOption<unknown>>,
      required: true,
    },
    /** 検索条件 */
    value: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
      required: false,
    },
    /** 検索フィームの初期値。1項目に対してオブジェクトを設定したい場合など。主にカスタム用 */
    initialForm: {
      type: Function as PropType<() => Record<string, unknown>>,
      required: false,
    },
  },
  data() {
    return {
      /** 選択中の検索項目 */
      selected_search: null as SearchItem | null,
      /** 検索項目の入力form */
      search_form: this.initialForm
        ? this.initialForm()
        : ({} as Record<string, unknown>),
      /** 検索条件（タグ） */
      search_values: this.value as Record<string, unknown>,
    };
  },
  computed: {
    /**
     * 検索オプション一覧
     * 既に条件が設定されかつcustomのものは除外される
     */
    searchList(): SearchItem[] {
      const valueKeys = new Set(Object.keys(this.search_values));
      return Object.entries(this.search)
        .filter(
          ([key, value]) =>
            (value.type === "custom" && !valueKeys.has(key)) ||
            value.type !== "custom"
        )
        .map(([key, value]) => ({ key: key, ...value }));
    },

    /** 選択中の検索項目のラベル */
    selectedSearchLabel(): null | string {
      if (this.selected_search) {
        return this.selected_search.label ?? this.selected_search.key;
      } else {
        return null;
      }
    },
    /**
     * select boxの選択肢
     * 選択されたものは非表示となる
     */
    selectItems(): [] | SearchSelectOption["items"] {
      const selectedItems = Object.entries(this.search_values)
        .filter(([key]) => key === this.selected_search?.key)
        .flatMap(([, value]) => (Array.isArray(value) ? [...value] : [value]));
      return (this.selected_search as SearchSelectOption).items.filter(
        (v) => !selectedItems.includes(v.value)
      );
    },
  },
  filters: {
    /** 検索オプションのkeyから項目のラベル文字列に変換 */
    searchLabel([key, search]: [string, SearchOption<unknown>]): string {
      return Object.entries(search)
        .filter(([searchKey]) => key === searchKey)
        .map(([key, value]) => value.label ?? key)[0];
    },

    /** 検索オプションのkeyと値から値のラベル文字列に変換
     *
     * 同じ項目に複数の選択値が指定した場合、コンマ(, )区切りで一文字列にまとめて返却する
     */
    searchValue([key, value, search]: [
      string,
      string | string[],
      SearchOption<unknown>
    ]): string {
      const targetSearch = Object.entries(search).find(
        ([searchKey]) => key === searchKey
      );
      if (targetSearch) {
        if (Array.isArray(value)) {
          return value
            .map((v) => {
              return targetSearch[1].type === "select"
                ? targetSearch[1].items
                    .filter((e) => e.value === v)
                    .map((e) => e.text)[0]
                : targetSearch[1].type === "date"
                ? moment(v).format("YYYY年MM月DD日")
                : v;
            })
            .join(", ");
        } else {
          return targetSearch[1].type === "select"
            ? targetSearch[1].items
                .filter((e) => e.value === value)
                .map((e) => e.text)[0]
            : targetSearch[1].type === "date"
            ? moment(value).format("YYYY年MM月DD日")
            : value;
        }
      } else {
        // 通常ここの処理に入ることはない
        throw new Error("cannot convert");
      }
    },
  },
  watch: {
    value(newValue: Record<string, unknown>) {
      this.search_values = newValue;
    },
  },
  methods: {
    /** 検索条件を追加 */
    submit() {
      this.search_values = {
        ...this.search_values,
        ...Object.entries(this.search_form)
          .filter(([key]) => key === this.selected_search!.key)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_key, value]) =>
            this.selected_search!.type === "text" ? value !== null : true
          )
          .reduce((acc, [key, value]) => {
            if (this.search_values[key] !== undefined) {
              // 既にある場合
              if (Array.isArray(this.search_values[key])) {
                return {
                  [key]: [...(this.search_values[key] as []), value],
                };
              } else {
                return {
                  [key]: [this.search_values[key], value],
                };
              }
            } else {
              // 初めての場合
              return { ...acc, [key]: value };
            }
          }, {}),
      };
      this.search_form = this.initialForm ? this.initialForm() : {};
      this.selected_search = null;
      this.$emit("input", this.search_values);
    },

    /** 該当のkeyの検索条件を削除 */
    remove(key: string) {
      this.$delete(this.search_values, key);
      this.$emit("input", this.search_values);
    },

    /** 全ての検索条件を削除 */
    clear() {
      this.search_values = {};
      this.selected_search = null;
      this.$emit("input", this.search_values);
    },
  },
});

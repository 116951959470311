import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** オプション契約情報 */
export interface ContractChangeOption {
  /** 課金対象となる追加ログ容量の指定
   * 単位は「GB」, 10GB刻みで指定可能となる。
   * 0を指定すると、課金対象の追加ログがOFF扱いとなる。
   * 課金対象ではないログ容量は10GBあり、本パラメータの容量と合わせた容量が、ログ保存容量となる。
   *
   * 以下の場合、NULLを返す。
   * ・パートナーユーザーで「ログ保存用ディスク表示フラグ 」がOFFの場合
   */
  additionalLogCapacity: number | null;
  /**
   * Allowログフラグ. 「ON」の場合はtrue、「OFF」の場合はfalseを設定する。
   *
   * 以下の場合、NULLを返す。
   * ・パートナーユーザーで「ログ保存用ディスク表示フラグ 」がOFFの場合
   */
  isAllowLog: boolean | null;
  /**
   * 統計情報フラグ
   * 「ON」の場合はtrue、「OFF」の場合はfalseを設定する。
   * 回線種別がインターネット回線のときのみ有効になる。
   *
   * 以下の場合、NULLを返す。
   * ・パートナーユーザーで「ログ保存用ディスク表示フラグ 」がOFFの場合
   */
  isStatistics: boolean | null;
  /** 契約オプション排他情報. 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。*/
  contractOptionKeyId: string;
}

/** オプション契約情報更新API用型定義 */
export type ContractChangeOptionPut = ContractChangeOption & {
  /** 操作履歴の備考 */
  note: string | null;
};

export default class ContractChangeOptionApi extends AbstractBaseApi {
  /** オプション契約を取得 */
  async getContractOptions(): Promise<ContractChangeOption> {
    return (await this.api.get("/v1/contract/service/appliance/options")).data;
  }

  /** オプション契約を変更 */
  async putContractOptions(data: ContractChangeOptionPut): Promise<void> {
    await this.api.post("/v1/contract/service/appliance/options", data);
  }
}

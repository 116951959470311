



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { UploadError } from "@/services/ConfigDownloadService";
import {
  Bgp4Peer,
  CreateVNL2L3ConnectRequest,
  StaticRouting,
  UpdateVNL2L3ConnectRequest,
  VNL2L3ConnectDetail,
} from "@/apis/VNL2L3ConnectApi";
import { VpnVnCode } from "@/apis/InformationApi";
import cloneDeep from "lodash/cloneDeep";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";
import { NullableProperty } from "@/shims-vue";
import { ContractInfo } from "@/apis/ContractApi";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VNL2L3ConnectEdit",
  props: {
    // 変更の場合用
    wnumber: {
      type: String as PropType<string>,
      required: false,
    },
    // 変更の場合用
    vnL2L3ConnectDetail: {
      type: Object as PropType<VNL2L3ConnectDetail>,
      required: false,
    },
    // 追加の場合用
    vnl2l3ConnectKeyId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    // 新規/更新のformデータを作成
    const form:
      | NullableProperty<CreateVNL2L3ConnectRequest, "routingType">
      | NullableProperty<UpdateVNL2L3ConnectRequest, "routingType"> = (() => {
      if (this.vnL2L3ConnectDetail) {
        // 変更
        const detailCopy = cloneDeep(this.vnL2L3ConnectDetail);
        let bgpList: Bgp4Peer[];
        if (detailCopy.bgp4) {
          if (detailCopy.bgp4.length > 1) {
            bgpList = detailCopy.bgp4;
          } else {
            bgpList = [
              ...detailCopy.bgp4,
              {
                peerName: detailCopy.bgp4.some((v) => v.peerName === "PEER1")
                  ? "PEER2"
                  : "PEER1",
                peerIpAddress: null,
                asNumber: null,
                md5Key: null,
                maximumPrefix: 100,
                localPreference: "LOW_PRIORITY",
                med: "LOW_PRIORITY",
                isRouteAggregation: false,
              },
            ];
          }
        } else {
          bgpList = [
            {
              peerName: "PEER1",
              peerIpAddress: null,
              asNumber: null,
              md5Key: null,
              maximumPrefix: 100,
              localPreference: "LOW_PRIORITY",
              med: "LOW_PRIORITY",
              isRouteAggregation: false,
            },
            {
              peerName: "PEER2",
              peerIpAddress: "",
              asNumber: null,
              md5Key: null,
              maximumPrefix: 100,
              localPreference: "LOW_PRIORITY",
              med: "LOW_PRIORITY",
              isRouteAggregation: false,
            },
          ];
        }
        const editForm: UpdateVNL2L3ConnectRequest = {
          ...detailCopy,
          bgp4: bgpList.sortBy(["peerName", "asc"]),
          static:
            detailCopy.static.length > 0
              ? detailCopy.static
              : [{ lanAddress: "", nextHopAddress: "" }],
          ospf: detailCopy.ospf ? detailCopy.ospf : { areaNumber: "" },
        };
        return editForm;
      } else {
        // 追加
        return {
          enumber: "",
          vnl2l3ConnectKeyId: this.vnl2l3ConnectKeyId,
          static: [{ lanAddress: "", nextHopAddress: "" }],
          bgp4: [
            {
              peerName: "PEER1",
              peerIpAddress: null,
              asNumber: null,
              md5Key: null,
              maximumPrefix: 100,
              localPreference: "LOW_PRIORITY",
              med: "LOW_PRIORITY",
              isRouteAggregation: false,
            },
            {
              peerName: "PEER2",
              peerIpAddress: "",
              asNumber: null,
              md5Key: null,
              maximumPrefix: 100,
              localPreference: "LOW_PRIORITY",
              med: "LOW_PRIORITY",
              isRouteAggregation: false,
            },
          ],
          ospf: { areaNumber: "" },
        } as CreateVNL2L3ConnectRequest;
      }
    })();
    const peer1Setting: boolean = (() => {
      if (this.vnL2L3ConnectDetail) {
        // 変更の場合、実績の設定値より初期値を付与
        if (this.vnL2L3ConnectDetail.bgp4) {
          return this.vnL2L3ConnectDetail.bgp4.some(
            (v) => v.peerName === "PEER1"
          );
        } else {
          return true;
        }
      } else {
        // 追加の場合はtrue
        return true;
      }
    })();

    const peer2Setting: boolean = (() => {
      if (this.vnL2L3ConnectDetail) {
        // 変更の場合、実績の設定値より初期値を付与
        if (this.vnL2L3ConnectDetail.bgp4) {
          return this.vnL2L3ConnectDetail.bgp4.some(
            (v) => v.peerName === "PEER2"
          );
        } else {
          return false;
        }
      } else {
        // 追加の場合はfalse
        return false;
      }
    })();

    return {
      isLoaded: false,
      /** 入力フォーム */
      form,
      /** VPN/VNコード取得APIの検索結果 */
      vpnVnCodeList: [] as VpnVnCode[],
      /** 回線番号の選択肢 */
      lineNumberList: [] as string[],
      /** BGP4のPEER1の有効、無効 */
      peer1Setting,
      /** BGP4のPEER2の有効、無効 */
      peer2Setting,
      /** L2Vn選択した値保存用 */
      l2VnSelect: null as null | VpnVnCode,
      /** L3Vn選択した値保存用 */
      l3VnSelect: null as null | VpnVnCode,
      /** Step No. */
      active: 1,
      /** Maximum Prefix上限値 */
      maximumPrefixLimit: null as UpperLimitEntity | null,
      /** アップロードエラー */
      uploadAlert: null as string | null,
    };
  },
  watch: {
    /** L2VN選択時の動作 */
    l2VnSelect() {
      // 追加のみ走るロジックので、asは問題ない
      if (this.l2VnSelect?.vlanIdType === "SPECIFIED") {
        (this.form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId =
          this.l2VnSelect.vlanId;
      } else if (this.l2VnSelect?.vlanIdType === "UNTAG") {
        (this.form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId = null;
      } else if (this.l2VnSelect?.vlanIdType === "OTHER") {
        (this.form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId = null;
      } else {
        // l2VnSelectが選択されてない場合、クリアする
        (this.form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId = null;
      }
    },
    /** Maximum Prefixの設定上限値を取得 */
    async "form.enumber"() {
      if ((this.form as CreateVNL2L3ConnectRequest).enumber) {
        // 契約に対象の回線情報が存在しない場合はサービス開始日が未来日のため上限値設定情報は取得できない
        if (
          !this.contractInfo.contractList.find(
            (e: ContractInfo["contractList"][0]) =>
              e.enumber === (this.form as CreateVNL2L3ConnectRequest).enumber
          )
        ) {
          this.maximumPrefixLimit = {
            upperLimitItemName: "BGP4の最大経路数",
            current: null,
            upperLimit: 100,
            unit: "",
          };
          return;
        }

        this.maximumPrefixLimit = (
          await this.$api.upperLimit.getUpperLimitLine({
            upperLimitLineManageUnit: "VNL2L3",
            enumber: (this.form as CreateVNL2L3ConnectRequest).enumber,
          })
        ).vnl2l3Line!.upperLimitList!.find(
          (v) => v.upperLimitItemName === "BGP4の最大経路数"
        )!;
      }
    },
  },
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
    }),
    /** 変更の場合、true */
    isEdit(): boolean {
      return !!this.vnL2L3ConnectDetail;
    },
    /** ルーティング種別の選択肢 */
    routingTypeOptions(): {
      value: VNL2L3ConnectDetail["routingType"];
      text: string;
    }[] {
      return [
        { value: "STATIC", text: "Static" },
        { value: "BGP4", text: "BGP4" },
        { value: "OSPF", text: "OSPF" },
      ];
    },
    /** Local Preferenceの選択肢 */
    localOptions(): {
      value: "HIGH_PRIORITY" | "LOW_PRIORITY";
      text: string;
    }[] {
      return [
        { value: "LOW_PRIORITY", text: "OFF(100)" },
        { value: "HIGH_PRIORITY", text: "優先(200)" },
      ];
    },
    /** MEDの選択肢 */
    medOptions(): { value: "HIGH_PRIORITY" | "LOW_PRIORITY"; text: string }[] {
      return [
        { value: "HIGH_PRIORITY", text: "非優先(100)" },
        { value: "LOW_PRIORITY", text: "OFF(0)" },
      ];
    },
    /** 経路集約の選択肢 */
    routeOptions(): { value: boolean; text: string }[] {
      return [
        { value: false, text: "OFF" },
        { value: true, text: "ON" },
      ];
    },
    /** Peer有効/無効の選択肢 */
    peerSettingOptions(): { value: boolean; text: string }[] {
      return [
        { value: true, text: "有効" },
        { value: false, text: "無効" },
      ];
    },
    /** MaximumPrefixの選択肢 */
    maxPrefixOptions(): {
      value: number;
      text: number;
    }[] {
      if (this.maximumPrefixLimit) {
        return [
          { value: 100, text: 100 },
          { value: 200, text: 200 },
          { value: 300, text: 300 },
          { value: 400, text: 400 },
          { value: 500, text: 500 },
          { value: 1000, text: 1000 },
          { value: 1500, text: 1500 },
          { value: 2000, text: 2000 },
        ].filter((v) => v.value <= this.maximumPrefixLimit!.upperLimit);
      } else {
        // 回線番号指定前にルーティング種別をBGP4にされた場合
        return [];
      }
    },
    /** 経路のリスト */
    staticLanList(): StaticRouting[] {
      const validList = this.form.static.filter(
        (v) => !!v.lanAddress && !!v.nextHopAddress
      );
      if (validList.length === 0) {
        return [{ lanAddress: "", nextHopAddress: "" }];
      } else {
        return validList;
      }
    },
    /** L2VNの選択肢
     * L2VPNは考慮しなくてもいい */
    l2VnListOptions(): {
      label: {
        vnName: string;
        vpnVnCode: string;
        vlanIdType: string;
      };
      l2VnList: VpnVnCode[];
    }[] {
      return this.vpnVnCodeList.filter((v) => v.vnType === "L2").length !== 0
        ? [
            {
              label: {
                vnName: "VNネットワーク名",
                vpnVnCode: "VNコード",
                vlanIdType: "VLAN",
              },
              l2VnList: this.vpnVnCodeList.filter((v) => v.vnType === "L2"),
            },
          ]
        : [];
    },
    /** L3VPN/VNの選択肢
     * vnType = "L3"はL3VN
     * vnType = nullの場合はL3VPN */
    l3VnListOptions(): {
      label: {
        vnName: string;
        vpnVnCode: string;
      };
      l3VnList: VpnVnCode[];
    }[] {
      return this.vpnVnCodeList.filter((v) => v.vnType === "L3" || !v.vnType)
        .length !== 0
        ? [
            {
              label: {
                vnName: "VPN/VNネットワーク名",
                vpnVnCode: "VPN/VNコード",
              },
              l3VnList: this.vpnVnCodeList.filter(
                (v) => v.vnType === "L3" || !v.vnType
              ),
            },
          ]
        : [];
    },
    /**「ルーティング設定」が「BGP4」の場合、「有効」が1つ以上選択されていること */
    validSomePeer(): boolean {
      return this.$crossValidation.requiredSome(
        this.peer1Setting,
        this.peer2Setting
      );
    },

    /** 複数設定時にLANアドレスが重複していないこと */
    lanAddressList() {
      return (index: number) => {
        return this.form!.static!
          ? this.form!.static!.map((e) => e.lanAddress).filter(
              (_, num) => num !== index
            )
          : [];
      };
    },
    /** 入力フォームから取得したbgp4の値
     * BGP4以外の場合はnull
     * Peer1、Peer2の中に、有効の方を返却
     * */
    bgp4(): Bgp4Peer[] | null {
      if (this.form.routingType === "BGP4") {
        return this.form.bgp4!.filter((v) => {
          if (v.peerName === "PEER1" && this.peer1Setting) {
            return true;
          } else if (v.peerName === "PEER2" && this.peer2Setting) {
            return true;
          }
          return false;
        });
      } else {
        return null;
      }
    },
    /** 追加の場合、 vnl2l3VlanIdTypeの変換(OHTERの場合は、UNTAGやSPECIFIEDへ変換する) */
    vnl2l3VlanIdType(): "UNTAG" | "SPECIFIED" {
      if (this.l2VnSelect!.vlanIdType === "OTHER") {
        if ((this.form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId) {
          return "SPECIFIED";
        } else {
          return "UNTAG";
        }
      }
      return this.l2VnSelect!.vlanIdType!;
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      if (!this.isEdit) {
        /** 回線番号リスト取得 */
        this.lineNumberList = (
          await this.$api.vNL2L3Connect.getVNL2L3lines()
        ).lineList.sortBy("asc");
        /** VpnVnコードリスト取得 */
        this.vpnVnCodeList = await this.$api.information.getVpnVnCodeList();
      } else {
        // 契約に対象の回線情報が存在しない場合はサービス開始日が未来日のため上限値設定情報は取得できない
        if (
          !this.contractInfo.contractList.find(
            (e: ContractInfo["contractList"][0]) =>
              e.enumber === this.vnL2L3ConnectDetail.enumber
          )
        ) {
          this.maximumPrefixLimit = {
            upperLimitItemName: "BGP4の最大経路数",
            current: null,
            upperLimit: 100,
            unit: "",
          };
        } else {
          /** Maximum Prefixの設定上限値を取得 */
          this.maximumPrefixLimit = (
            await this.$api.upperLimit.getUpperLimitLine({
              upperLimitLineManageUnit: "VNL2L3",
              enumber: this.vnL2L3ConnectDetail.enumber,
            })
          ).vnl2l3Line!.upperLimitList!.find(
            (v) => v.upperLimitItemName === "BGP4の最大経路数"
          )!;
        }
      }
    },
    async settingVNL2L3Connect() {
      if (this.isEdit) {
        /** 変更API呼び出し*/
        await this.$api.vNL2L3Connect.updateVNL2L3Connect(this.wnumber, {
          vnl2l3ConnectKeyId: this.form.vnl2l3ConnectKeyId,
          vnConnectName: this.form.vnConnectName,
          routingType: this.form.routingType!,
          static:
            this.form.routingType === "STATIC"
              ? this.form.static.length === 1 && !this.form.static[0].lanAddress
                ? []
                : this.form.static
              : [],
          ospf:
            this.form.routingType === "OSPF"
              ? // 要件より画面では数字通りの挙動(先頭に「0」がある場合は「0」を除いた値を登録する)ですが、
                // APIではStringと扱うので、v-model.numberとtoStringの組み合わせて実装になった
                { areaNumber: this.form.ospf!.areaNumber.toString() }
              : null,
          bgp4: this.bgp4,
          description: this.form.description,
        });
      } else {
        /** 追加API呼び出し*/
        await this.$api.vNL2L3Connect.createVNL2L3Connect({
          ...this.form,
          l2VnCode: this.l2VnSelect!.vpnVnCode,
          l3VpnVnCode: this.l3VnSelect!.vpnVnCode,
          // フォームが空欄の場合はUntagとして扱う
          vnl2l3VlanIdType: this.vnl2l3VlanIdType,
          vnl2l3VnVlanId:
            this.l2VnSelect!.vlanIdType === "OTHER" &&
            (this.form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId
              ? (this.form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId
              : this.l2VnSelect?.vlanId,
          static:
            this.form.routingType === "STATIC"
              ? this.form.static.filter(
                  (v) => !!v.lanAddress && !!v.nextHopAddress
                )
              : [],
          ospf:
            this.form.routingType === "OSPF"
              ? // 要件より画面では数字通りの挙動(先頭に「0」がある場合は「0」を除いた値を登録する)ですが、
                // APIではStringと扱うので、v-model.numberとtoStringの組み合わせて実装になった
                { areaNumber: this.form.ospf!.areaNumber.toString() }
              : null,
          bgp4: this.bgp4,
        } as CreateVNL2L3ConnectRequest);
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 設定ダウンロード */
    downLoad() {
      if (this.isEdit) {
        this.$service.configDownload.downloadVNL2L3Connect(
          this.$store.state.user.contractSummary.vnumber,
          this.wnumber,
          "modify",
          this.form,
          this.peer1Setting,
          this.peer2Setting,
          this.vnL2L3ConnectDetail
        );
      } else {
        this.$service.configDownload.downloadVNL2L3Connect(
          this.$store.state.user.contractSummary.vnumber,
          "",
          "add",
          this.form,
          this.peer1Setting,
          this.peer2Setting,
          null,
          this.l2VnSelect,
          this.l3VnSelect
        );
      }
    },
    /** 設定アップロード */
    async upLoad() {
      try {
        const uploadVNL2L3Connect = this.vnL2L3ConnectDetail
          ? await this.$service.configDownload.uploadVNL2L3Connect(
              "modify",
              undefined,
              this.wnumber
            )
          : await this.$service.configDownload.uploadVNL2L3Connect(
              "add",
              this.$store.state.user.contractSummary.vnumber
            );
        this.uploadAlert = null;
        await this.$confirm(
          "VN L2/L3コネクト設定を上書きします。よろしいですか？"
        );

        this.form.vnConnectName = uploadVNL2L3Connect.vnConnectName;
        if (uploadVNL2L3Connect.static) {
          if (Array.isArray(uploadVNL2L3Connect.static)) {
            if (uploadVNL2L3Connect.static.length > 0) {
              this.form.static = uploadVNL2L3Connect.static;
            } else {
              this.form.static = [{ lanAddress: "", nextHopAddress: "" }];
            }
          }
        } else {
          this.form.static = [{ lanAddress: "", nextHopAddress: "" }];
        }

        if (uploadVNL2L3Connect.ospf) {
          this.form.ospf!.areaNumber = uploadVNL2L3Connect.ospf.areaNumber;
        }

        if (uploadVNL2L3Connect.bgp4) {
          if (Array.isArray(uploadVNL2L3Connect.bgp4)) {
            if (uploadVNL2L3Connect.bgp4.length > 0) {
              const peer1 = uploadVNL2L3Connect.bgp4.find(
                (v) => v.peerName === "PEER1"
              );
              if (peer1) {
                this.peer1Setting = true;
                this.form.bgp4![0].peerIpAddress = peer1.peerIpAddress;
                this.form.bgp4![0].asNumber = peer1.asNumber;
                this.form.bgp4![0].md5Key = peer1.md5Key;
                this.form.bgp4![0].maximumPrefix = peer1.maximumPrefix;
                if (
                  peer1.med === "HIGH_PRIORITY" ||
                  peer1.med === "LOW_PRIORITY"
                ) {
                  this.form.bgp4![0].med = peer1.med;
                } else {
                  this.form.bgp4![0].med = "LOW_PRIORITY";
                }
                if (
                  peer1.localPreference === "HIGH_PRIORITY" ||
                  peer1.localPreference === "LOW_PRIORITY"
                ) {
                  this.form.bgp4![0].localPreference = peer1.localPreference;
                } else {
                  this.form.bgp4![0].localPreference = "LOW_PRIORITY";
                }

                if (typeof peer1.isRouteAggregation === "boolean") {
                  this.form.bgp4![0].isRouteAggregation =
                    peer1.isRouteAggregation;
                } else {
                  this.form.bgp4![0].isRouteAggregation = false;
                }
              } else {
                this.peer1Setting = false;
              }

              const peer2 = uploadVNL2L3Connect.bgp4.find(
                (v) => v.peerName === "PEER2"
              );
              if (peer2) {
                this.peer2Setting = true;
                this.form.bgp4![1].peerIpAddress = peer2.peerIpAddress;
                this.form.bgp4![1].asNumber = peer2.asNumber;
                this.form.bgp4![1].md5Key = peer2.md5Key;
                this.form.bgp4![1].maximumPrefix = peer2.maximumPrefix;

                if (
                  peer2.med === "HIGH_PRIORITY" ||
                  peer2.med === "LOW_PRIORITY"
                ) {
                  this.form.bgp4![1].med = peer2.med;
                } else {
                  this.form.bgp4![1].med = "LOW_PRIORITY";
                }
                if (
                  peer2.localPreference === "HIGH_PRIORITY" ||
                  peer2.localPreference === "LOW_PRIORITY"
                ) {
                  this.form.bgp4![1].localPreference = peer2.localPreference;
                } else {
                  this.form.bgp4![1].localPreference = "LOW_PRIORITY";
                }
                if (typeof peer2.isRouteAggregation === "boolean") {
                  this.form.bgp4![1].isRouteAggregation =
                    peer2.isRouteAggregation;
                } else {
                  this.form.bgp4![1].isRouteAggregation = false;
                }
              } else {
                this.peer2Setting = false;
              }
            } else {
              this.peer1Setting = true;
              this.peer2Setting = false;
              this.form.bgp4 = [
                {
                  peerName: "PEER1",
                  peerIpAddress: null,
                  asNumber: null,
                  md5Key: null,
                  maximumPrefix: 100,
                  localPreference: "LOW_PRIORITY",
                  med: "LOW_PRIORITY",
                  isRouteAggregation: false,
                },
                {
                  peerName: "PEER2",
                  peerIpAddress: "",
                  asNumber: null,
                  md5Key: null,
                  maximumPrefix: 100,
                  localPreference: "LOW_PRIORITY",
                  med: "LOW_PRIORITY",
                  isRouteAggregation: false,
                },
              ];
            }
          }
        } else {
          this.peer1Setting = true;
          this.peer2Setting = false;
          this.form.bgp4 = [
            {
              peerName: "PEER1",
              peerIpAddress: null,
              asNumber: null,
              md5Key: null,
              maximumPrefix: 100,
              localPreference: "LOW_PRIORITY",
              med: "LOW_PRIORITY",
              isRouteAggregation: false,
            },
            {
              peerName: "PEER2",
              peerIpAddress: "",
              asNumber: null,
              md5Key: null,
              maximumPrefix: 100,
              localPreference: "LOW_PRIORITY",
              med: "LOW_PRIORITY",
              isRouteAggregation: false,
            },
          ];
        }

        this.form.description = uploadVNL2L3Connect.description;
        const routingType = this.routingTypeOptions.find(
          (v) => v.value === uploadVNL2L3Connect.routingType
        );
        if (routingType) {
          this.form.routingType = routingType.value;
        } else {
          this.form.routingType = null;
        }
        if (!this.isEdit) {
          // 追加の場合のみ実施
          (this.form as CreateVNL2L3ConnectRequest).enumber =
            uploadVNL2L3Connect.enumber;
          (this.form as CreateVNL2L3ConnectRequest).wanAddress =
            uploadVNL2L3Connect.wanAddress;
          const l2 = this.vpnVnCodeList.find(
            (v) =>
              v.vnType === "L2" && v.vpnVnCode === uploadVNL2L3Connect.l2VnCode
          );
          if (l2) {
            this.l2VnSelect = l2;
          } else {
            this.l2VnSelect = null;
          }
          const l3 = this.vpnVnCodeList.find(
            (v) =>
              (v.vnType === "L3" || !v.vnType) &&
              v.vpnVnCode === uploadVNL2L3Connect.l3VpnVnCode
          );
          if (l3) {
            this.l3VnSelect = l3;
          } else {
            this.l3VnSelect = null;
          }

          this.$nextTick(() => {
            // Other以外は入力項目ではないので、スキップ
            if (this.l2VnSelect?.vlanIdType === "OTHER") {
              // L2VNの変更が発生した場合はwatchでvnl2l3VnVlanId項目の初期化が走るため次のタイミングで値を設定
              (this.form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId =
                uploadVNL2L3Connect.vnl2l3VnVlanId;
            }
          });
        }
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },
    // ステッパーからアクティブページを伝搬
    activePage(active: number) {
      this.uploadAlert = null;
      this.active = active;
    },
    /**「LANアドレス」と「ネクストホップアドレス」の追加,
     *  ルーティング種別で「STATIC」のみ
     */
    addLAN() {
      if (this.form.static)
        this.form.static = [
          ...this.form.static,
          { lanAddress: "", nextHopAddress: "" },
        ];
    },
    /**「LANアドレス」と「ネクストホップアドレス」の削除,
     *  ルーティング種別で「STATIC」のみ
     */
    removeLAN(index: number) {
      if (this.form.static) {
        if (this.form.static.length > 1) {
          this.form.static.splice(index, 1);
        } else {
          this.form.static = [{ lanAddress: "", nextHopAddress: "" }];
        }
      }
    },
    /**L2VNプルダウンの複数条件を表示*/
    l2VnCustomLabel({ vnName, vpnVnCode, vlanId, vlanIdType }: never) {
      if (this.l2VnSelect?.vlanIdType !== "SPECIFIED")
        return `${vnName}:${vpnVnCode}(${
          vlanIdType === "UNTAG"
            ? "Untag"
            : vlanIdType === "OTHER"
            ? "Other"
            : vlanId
        })`;
      else return `${vnName}:${vpnVnCode}(${vlanId})`;
    },
    /**L3VPN/VNプルダウンの複数条件を表示*/
    l3VnCustomLabel({ vnName, vpnVnCode }: never) {
      if (this.l3VnSelect?.vpnVnCode.charAt(0) === "B") return `${vpnVnCode}`;
      else return `${vnName}:${vpnVnCode}`;
    },
  },
});
























































































import Vue, { PropType } from "vue";
import SaNwEdit, { createCpaNwPostObj } from "@/modals/cpa5g/SaNwEdit.vue";
import SaIdRegister from "@/modals/cpa5g/SaIdRegister.vue";
import {
  CpaContract,
  CpaNwDetail,
  CpaNwOptions,
  UpdateCpaNw,
} from "@/apis/Cpa5gSaApi";
import SaNwContractDeleteConfirm from "./SaNwContractDeleteConfirm.vue";
import SaNwDataUpdateConfirm from "@/modals/cpa5g/SaNwDataUpdateConfirm.vue";
import SaContractTermsConfirm from "@/modals/cpa5g/SaContractTermsConfirm.vue";

export default Vue.extend({
  name: "SaNwReference",
  props: {
    // CPA NW契約情報
    cpaContractInfo: {
      type: Object as PropType<CpaContract>,
      required: true,
    },
  },
  data() {
    return {
      termsDownloadUrl: this.$api.cpa5gSa.downloadContractTermsPdfURL(),
      nwSettingDetail: {} as CpaNwDetail,
      nwOptions: {} as CpaNwOptions,
      isLoaded: false,
    };
  },
  async mounted() {
    this.nwSettingDetail = await this.$api.cpa5gSa.getCpaNwDetail(
      this.cpaContractInfo.cpaContractSeq
    );
    this.nwOptions = await this.$api.cpa5gSa.getCpaNwOptions(
      this.cpaContractInfo.cpaContractSeq
    );
    this.isLoaded = true;
  },
  methods: {
    isUpdate() {
      return Object.values(this.nwOptions).includes("UPDATE");
    },
    /** データ反映 */
    async settingUpdate() {
      await this.$modal.show(SaNwDataUpdateConfirm, {
        cpaContractInfo: this.cpaContractInfo,
        nwSettingDetail: this.nwSettingDetail,
        nwOptions: this.nwOptions,
      });
      // APIに設定するデータを作成
      const nwPostData: UpdateCpaNw = createCpaNwPostObj(this.nwSettingDetail);

      // NW設定の登録・更新API呼び出し
      await this.$api.cpa5gSa.updateCpaNw(
        this.cpaContractInfo.cpaContractSeq,
        nwPostData
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 認証ID登録 */
    async idRegister() {
      await this.$modal.show(SaIdRegister, {
        cpaContractSeq: this.cpaContractInfo.cpaContractSeq,
        contractType: this.cpaContractInfo.contractType,
      });
    },

    /** 認証ID情報取得 */
    async showAuthCpaIdInfo() {
      await this.$router.push({
        path: "/report/remote/access/account",
      });
    },

    /** NW設定 */
    async cpaNwSetting() {
      await this.$modal.show(SaNwEdit, {
        cpaContract: this.cpaContractInfo,
        cpaNw: this.nwSettingDetail,
        cpaNwOption: this.nwOptions,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 契約解約 */
    async terminateContract() {
      if (this.isLoaded) {
        await this.$modal.show(SaNwContractDeleteConfirm, {
          cpaContractInfo: this.cpaContractInfo,
          nwSettingDetail: this.nwSettingDetail,
          nwOptions: this.nwOptions,
        });
        await this.$api.cpa5gSa.terminateCpaContract(
          this.cpaContractInfo.cpaContractSeq,
          {
            cpaContractKeyId: this.cpaContractInfo.cpaContractKeyId,
          }
        );
        // 解約後には設定情報画面も閉じる。(一覧画面で再読み込みを行うためOKとする)
        (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },

    // 約款参照
    async confirmTerms() {
      await this.$modal.show(SaContractTermsConfirm, {
        isConfirmationMode: false,
      });
    },
  },
});

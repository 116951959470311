import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";
// 入力値がIP形式になっているか検証
// ----------------------------------------
// チェック内容
// "xxx.xxx.xxx.xxx"の形式であるかチェック
//
export default {
  validate(value: string | undefined | null): boolean {
    if (!value) return true;

    if (!ipaddr.IPv4.isValidFourPartDecimal(value)) return false;
    return true;
  },
} as ValidationRuleSchema;






















































import Vue from "vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { ZoneRelation, ZoneRelationItem } from "@/apis/ZoneRelationApi";
import InternetType1ZoneRelationRegister from "@/modals/applianceContractSetting/InternetType1ZoneRelationRegister.vue";
import InternetType1ZoneRelationDeleteConfirm from "@/modals/applianceContractSetting/InternetType1ZoneRelationDeleteConfirm.vue";
import { Type1Zone } from "@/apis/Type1SiteApi";

export default Vue.extend({
  name: "InternetType1ZoneRelationSettingList",
  data() {
    return {
      /** 初期通信完了フラグ */
      isLoaded: false,
      relations: {} as ZoneRelation,
      zoneRelations: {
        fields: [
          {
            key: "zone_src",
            label: "送信元ゾーン/ゾーン名",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: ZoneRelationItem) => {
              return (
                this.$filter.enumTo(item.srcZoneType, "zoneType") +
                "/" +
                item.srcZoneName
              );
            },
          },
          {
            key: "direction",
            label: "",
          },
          {
            key: "zone_dest",
            label: "宛先ゾーン/ゾーン名",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: ZoneRelationItem) => {
              return (
                this.$filter.enumTo(item.dstZoneType, "zoneType") +
                "/" +
                item.dstZoneName
              );
            },
          },
          { key: "delete", label: "" },
        ] as BvTableFieldArray,
      },
      // インターネット、エクストラ、プライベートゾーン
      internetZone: {} as Type1Zone | null,
      extraZone: {} as Type1Zone | null,
      privateZone: [] as Type1Zone[],
      alertMsg: null as null | string,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    displayRelationList(): ZoneRelationItem[] {
      // global -> private
      // global -> extra
      // extra -> global
      // private -> global
      // 上記のみ表示
      return this.relations.zoneRelationList.filter(
        (v) =>
          (v.srcZoneType === "INTERNET" &&
            (v.dstZoneType === "PRIVATE" || v.dstZoneType === "EXTRA")) ||
          ((v.srcZoneType === "PRIVATE" || v.srcZoneType === "EXTRA") &&
            v.dstZoneType === "INTERNET")
      );
    },
  },
  methods: {
    /** データ取得 */
    async load() {
      this.relations = await this.$api.zoneRelation.getZoneRelation();
      this.internetZone = (
        await this.$api.type1SiteZone.getType1InternetList()
      ).zone;
      this.extraZone = (await this.$api.type1SiteZone.getType1ExtraList()).zone;
      this.privateZone = (
        await this.$api.type1SiteZone.getType1PrivateList()
      ).zoneList;
    },
    async deleteZoneRelation(item: ZoneRelationItem) {
      this.alertMsg = null;
      await this.$modal.show(InternetType1ZoneRelationDeleteConfirm, {
        zoneRelationItem: item,
      });
      await this.$api.zoneRelation.deleteZoneRelation(item.zoneRelationId, {
        zoneRelationKeyId: this.relations.zoneRelationKeyId,
      });
      await this.load();
    },
    async addZoneRelation() {
      if (
        this.internetZone === null ||
        (this.extraZone === null && this.privateZone.length === 0)
      ) {
        // ゾーン作成時にsrc/dstのどちらかはインターネットゾーンが必要
        // ゾーン作成時にsrc/dstどちらかはエクストラまたはプライベーとが必要
        this.alertMsg = "ゾーン接続可能なゾーンがありません";
        return;
      } else {
        this.alertMsg = null;
      }

      await this.$modal.show(InternetType1ZoneRelationRegister, {
        zoneRelationKeyId: this.relations.zoneRelationKeyId,
        relations: this.relations,
      });
      await this.load();
    },
  },
});








































































import Vue, { PropType } from "vue";
import IntranetPolicySrcAddress from "@/components/policy/IntranetPolicySrcAddress.vue";
import IntranetPolicyDstAddress from "@/components/policy/IntranetPolicyDstAddress.vue";
import IntranetPolicyService from "@/components/policy/IntranetPolicyService.vue";
import { IntraPolicy } from "@/apis/IntranetfwPolicyApi";

/** イントラネットFW・PFGW共通の確認モーダル */
export default Vue.extend({
  name: "IntranetFwPolicyRuleConfirm",
  components: {
    IntranetPolicySrcAddress,
    IntranetPolicyDstAddress,
    IntranetPolicyService,
  },
  props: {
    /** ポリシー */
    policy: {
      type: Object as PropType<IntraPolicy>,
      required: true,
    },
  },
});

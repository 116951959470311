import { render, staticRenderFns } from "./ProfileUrlFilteringEdit.vue?vue&type=template&id=64d53482&scoped=true&"
import script from "./ProfileUrlFilteringEdit.vue?vue&type=script&lang=ts&"
export * from "./ProfileUrlFilteringEdit.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileUrlFilteringEdit.vue?vue&type=style&index=0&id=64d53482&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64d53482",
  null
  
)

export default component.exports



































































































































































































































































































































































import Vue, { PropType } from "vue";
import { VNConnectDetail } from "@/apis/VNConnectApi";
import VNConnectEdit from "@/modals/vnConnect/VNConnectEdit.vue";
import VNConnectDeleteConfirm from "@/modals/vnConnect/VNConnectDeleteConfirm.vue";
import {
  AccessLineGetEntity,
  BandwidthListGetEntity,
} from "@/apis/AccessLineApi";
import { mapState } from "vuex";
import { ContractInfo } from "@/apis/ContractApi";

export default Vue.extend({
  name: "VNConnectReference",
  props: {
    /** W番号 */
    wnumber: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      vNConnect: {} as VNConnectDetail,
      accessLine: {} as AccessLineGetEntity,
      isLoaded: false,
      isChanged: false,
    };
  },
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
      contractSummary: "contractSummary",
    }),
    /** true: 変更・削除可能, false: 不可 （PFGW(インターネット)、V連携の場合） */
    isEditDelete(): boolean {
      return this.vNConnect.enumber !== null && !this.vNConnect.isAssociate;
    },
    /** 回線契約状況、回線種別によっては存在しないのでその場合はundefined */
    lineBandwidth(): BandwidthListGetEntity | undefined {
      return this.accessLine.bandwidthList.find(
        (e) =>
          e.enumberAct === this.vNConnect.enumber ||
          e.enumberSby === this.vNConnect.enumber
      );
    },
    /** 開始日が未来日の場合,true*/
    isFutureDayContract(): boolean {
      return !this.contractInfo.contractList.find(
        (e: ContractInfo["contractList"][0]) =>
          e.enumber === this.vNConnect.enumber
      );
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.vNConnect = await this.$api.vNConnect.getVNConnectDetail(
        this.wnumber
      );
      this.accessLine = await this.$api.accessLine.getAll();
    },

    /** 変更 */
    async modifyVnConnect() {
      await this.alertClear();
      await this.$modal.show(VNConnectEdit, {
        wnumber: this.wnumber,
        connect: this.vNConnect,
        keyId: this.vNConnect.vnConnectKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 削除 */
    async delVnConnect() {
      await this.alertClear();
      await this.$modal.show(VNConnectDeleteConfirm, {
        wnumber: this.wnumber,
        connect: this.vNConnect,
        lineBandwidth: this.lineBandwidth,
      });
      await this.$api.vNConnect.deleteVNConnect(this.wnumber, {
        vnConnectKeyId: this.vNConnect.vnConnectKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    async executed() {
      this.isChanged = true;
      await this.load();
    },

    /** エラーメッセージを初期化する */
    async alertClear() {
      (
        this.$refs.operationCommand as Vue & { alertClear: () => void }
      ).alertClear();
    },
  },
});
























































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { NatNaptDetailEntity } from "@/apis/MSPeeringIpApi";
import MSNaptSettingReference from "@/modals/msNaptSetting/MSNaptSettingReference.vue";

export default Vue.extend({
  name: "MSNaptSettingList",
  data() {
    return {
      naptSetting: {
        fields: [
          {
            key: "vpnVnCode",
            label: "VPN/VNコード",
            sortable: true,
          },
          {
            key: "vnName",
            label: "VNネットワーク名",
            sortable: true,
          },
          {
            key: "napt",
            label: "NAPT用グローバルIP数(現在/上限)",
            tdClass: "text-right",
            sortable: true,
            sortByFormatted: true,
            formatter: (
              value: string,
              key: null,
              item: NatNaptDetailEntity
            ) => {
              return `${item.usedNapt}/${item.upperLimitNapt}`;
            },
          },
        ],
        items: [],
        search: {
          vpnVnCode: { type: "text", label: "VPN/VNコード" },
          vnName: { type: "text", label: "VNネットワーク名" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          vpnVnCode: "like",
          vnName: "like",
        },
      } as Omit<AppTableData<NatNaptDetailEntity>, "selected">,
      keyId: "",
      isLoaded: false,
    };
  },

  async mounted() {
    await this.load();
    this.isLoaded = true;
  },

  methods: {
    async load() {
      /** クラウド向けNAPT用グローバルIP設定情報を取得API呼び出し処理 */
      const data = await this.$api.msPeeringIp.getAll();
      this.naptSetting.items = data.msPeeringList;
      this.keyId = data.msPeeringIpKeyId;
    },
    /** 詳細情報表示 */
    async showNaptSettingReference(entity: NatNaptDetailEntity) {
      await this.$modal.show(MSNaptSettingReference, {
        keyId: this.keyId,
        naptSetting: entity,
      });
      await this.load();
    },
    /** フロント側のみでのCSVダウンロード */
    downloadCsv() {
      // NAPT用グローバルIP数(現在/上限)ではなく、NAPTグローバルIP数(現在)・NAPTグローバルIP数(上限)に分けて表示なので、新たに列データを作る
      const csvFields = [...this.naptSetting.fields];
      csvFields.splice(
        2,
        1,
        { key: "usedNapt", label: "NAPTグローバルIP数(現在)" },
        { key: "upperLimitNapt", label: "NAPTグローバルIP数(上限)" }
      );

      this.$service.csvService.appTableCsvDownLoad(
        "saas_napt_setting",
        this.naptSetting.items.sortBy(["vpnVnCode", "desc"]),
        this.$service.csvService.genFieldList(csvFields)
      );
    },
  },
});

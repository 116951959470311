import { PluginObject } from "vue/types/plugin";
import { Vue as _Vue } from "vue/types/vue";
import bandwidthTo from "@/filters/bandwidth-to.filter";
import capitalize from "@/filters/capitalize.filter";
import emptyTo from "@/filters/empty-to.filter";
import enumTo from "@/filters/enum-to.filter";
import optionText from "@/filters/option-text.filter";
import validState from "@/filters/valid-state.filter";
import datetime from "@/filters/datetime.filter";
import applianceTypeTo from "@/filters/appliance-type-to.filter";
import internetStatusTo from "@/filters/internet-status-to.filter";
import message from "@/filters/message.filter";
import bandwidthToBps from "@/filters/bandwidth-to-bps.filter";
import date from "@/filters/date.filter";
import cloudServiceMenuTo from "@/filters/cloud-service-menu-to.filter";
import riskTo from "@/filters/risk-to.filter";

const AppFilterPlugin: PluginObject<undefined> = {
  install(Vue: typeof _Vue): void {
    // カスタムフィルターを全て定義。IDEが反応できるようにforでグルグルして登録などはしないように
    Vue.filter("validState", validState);
    Vue.filter("emptyTo", emptyTo);
    Vue.filter("capitalize", capitalize);
    Vue.filter("optionText", optionText);
    Vue.filter("enumTo", enumTo);
    Vue.filter("bandwidthTo", bandwidthTo);
    Vue.filter("date", date);
    Vue.filter("datetime", datetime);
    Vue.filter("applianceTypeTo", applianceTypeTo);
    Vue.filter("internetStatusTo", internetStatusTo);
    Vue.filter("msg", message);
    Vue.filter("bandwidthToBps", bandwidthToBps);
    Vue.filter("cloudServiceMenuTo", cloudServiceMenuTo);
    Vue.filter("riskTo", riskTo);

    Vue.prototype.$filter = AppFilter;
  },
};

export const AppFilter = {
  emptyTo,
  capitalize,
  optionText,
  enumTo,
  bandwidthTo,
  date,
  datetime,
  applianceTypeTo,
  internetStatusTo,
  message,
  bandwidthToBps,
  cloudServiceMenuTo,
};

export default AppFilterPlugin;

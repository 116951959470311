import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 * 入力されたIPアドレスと既に存在するIPアドレスのリストそれぞれに包含関係が成立しないことの検証
 * ・正しいIPV4形式であることは別のバリデーションで実施が必要
 * ・CIDR形式のみ受付可能
 * ・包含関係とは指定されたCIDR形式のサブネットの範囲が重なっていることをいう
 */

export default {
  params: ["list"],
  validate(
    value: string | undefined | null,
    { list = [] }: { list: string[] | undefined | null }
  ): boolean {
    if (!value || !list) {
      return true;
    }

    for (const strIpv4 of list) {
      try {
        // 対象のIPアドレスを計算用に変換
        const targetIpv4 = ipaddr.IPv4.parseCIDR(value);
        // 対象のネットワークアドレス(範囲の始点)の算出
        const targetNetworkIp = ipaddr.IPv4.networkAddressFromCIDR(value);
        // 対象のブロードキャストアドレス(範囲の終点)の算出
        const targetBroadcastIp = ipaddr.IPv4.broadcastAddressFromCIDR(value);

        let listInIpv4, listInNetworkIpIpv4;
        // リスト内の値がIPv4形式でない場合、次ループへ
        try {
          // リスト内のIPアドレスを計算用に変換
          listInIpv4 = ipaddr.IPv4.parseCIDR(strIpv4);
          // リスト内のネットワークアドレス(範囲の始点)の算出
          listInNetworkIpIpv4 = ipaddr.IPv4.networkAddressFromCIDR(strIpv4);
        } catch (e) {
          continue;
        }

        // 包含関係が成立するのは以下の2パターン
        // 1. 対象のIPアドレスの始点または終点がリスト内のアドレスの範囲に含まれている
        // 2. 対象のIPアドレスの始点と終点がどちらもリスト内のアドレスの範囲外かつ対象のIPアドレスの範囲にリスト内のアドレスの始点が含まれている
        if (
          targetNetworkIp.match(listInIpv4) ||
          targetBroadcastIp.match(listInIpv4)
        ) {
          // パターン1
          return false;
        } else if (
          !targetNetworkIp.match(listInIpv4) &&
          !targetBroadcastIp.match(listInIpv4) &&
          listInNetworkIpIpv4.match(targetIpv4)
        ) {
          // パターン2
          return false;
        }
      } catch (e) {
        return true;
      }
    }

    return true;
  },
} as ValidationRuleSchema;

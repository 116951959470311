













































import Vue, { PropType } from "vue";
import {
  Type1ExtraInfo,
  Type1ExtraSiteDelete,
  Type1Site,
} from "@/apis/Type1SiteApi";
import { AppTableData } from "@/components/AppTable.vue";
import ExtraSiteEdit from "@/modals/type1/ExtraSiteEdit.vue";
import ExtraSiteReference from "@/modals/type1/ExtraSiteReference.vue";

export default Vue.extend({
  name: "ExtraSiteList",
  props: {
    extraSiteZone: {
      type: Object as PropType<Type1ExtraInfo>,
      required: true,
    },
  },
  data() {
    return {
      showAlert: false as boolean,
      siteList: {
        fields: [
          {
            key: "siteName",
            label: "サイト名",
            sortable: true,
          },
        ],
        items: [],
        selected: [],
      } as Pick<AppTableData<Type1Site>, "fields" | "items" | "selected">,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    /** 初期化機能 */
    load() {
      this.siteList.items = this.extraSiteZone.siteList;
    },
    /** 追加機能 */
    async toAddSite() {
      this.showAlert = false;
      await this.$modal.show(ExtraSiteEdit, {
        extraZone: this.extraSiteZone.zone,
        type1SiteZoneKeyId: this.extraSiteZone.type1SiteZoneKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 行選択機能 */
    async extraSelected(item: Type1Site) {
      this.showAlert = false;
      await this.$modal.show(ExtraSiteReference, {
        extraSite: item,
        extraZone: this.extraSiteZone.zone,
        type1SiteZoneKeyId: this.extraSiteZone.type1SiteZoneKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 削除機能 */
    async toDelete() {
      this.showAlert = this.siteList.selected.length === 0;
      if (this.showAlert) return;

      await this.$confirm(
        `エクストラサイトを${this.siteList.selected.length}件削除します。よろしいですか？`
      );

      const reqData: Type1ExtraSiteDelete = {
        siteList: this.siteList.selected.map((e) => {
          return e.siteId;
        }),
        type1SiteZoneKeyId: this.extraSiteZone.type1SiteZoneKeyId,
      };

      await this.$api.type1SiteZone.postType1ExtraSiteDelete(reqData);
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

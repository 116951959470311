













import Vue from "vue";
import appErrorHandler from "@/error/app-error-handler";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapGetters("meta", {
      isLoading: "isLoading",
    }),
    ...mapState("meta", {
      enabledLoading: "enabledLoading",
    }),
  },
  created() {
    window.addEventListener("error", this.errorHandler);
    window.addEventListener("unhandledrejection", this.errorHandler);
  },
  destroyed() {
    window.removeEventListener("error", this.errorHandler);
    window.removeEventListener("unhandledrejection", this.errorHandler);
  },
  methods: {
    /**
     * 全体エラーハンドラー。VueのerrorHandlerではcatch出来ないものをここで拾う
     * @param event イベント
     */
    errorHandler(event: ErrorEvent | PromiseRejectionEvent): void {
      if ("error" in event) {
        appErrorHandler(event.error, this);
      } else {
        appErrorHandler(event.reason, this);
      }
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"エクストラネット接続状態","classes":"modal-medium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"detail-container mt-2"},[_c('div',{staticClass:"btn-container justify-content-end"},[(
              !(
                _vm.approvalRequestType === 'DROP' &&
                _vm.connectInfo.dstVNumber ===
                  _vm.$store.state.user.contractSummary.vnumber
              )
            )?_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.statusChange()}}},[_vm._v(" "+_vm._s(_vm._f("enumTo")(_vm.approvalRequestType,"approvalRequest"))+" ")]):_vm._e(),(_vm.approvalRequestType === 'APPROVE')?_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.rejection}},[_vm._v(" 却下 ")]):_vm._e()],1),_c('connection-info-table',{staticClass:"others-border-off",attrs:{"connectionInfo":_vm.connectInfo}})],1)]),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("戻る")])],1)]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
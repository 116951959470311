import { WorkBook } from "xlsx";
import {
  Type4ServicePostRequest,
  Type4ServicePutRequest,
} from "@/apis/ContractChangeType4Api";
import { NonNullableProperty } from "@/shims-vue";
import {
  PostType4ExtraSiteAddress,
  PostType4InternetSite,
} from "@/apis/Type4SiteApi";
import { ContractChangeOption } from "@/apis/ContractChangeOptionApi";
import { CustomServiceInfo } from "@/apis/CustomServiceApi";
import { IpPool } from "@/apis/IpPoolApi";
import { IpMapping } from "@/apis/IpMappingApi";
import { InternetfwProfileInfo } from "@/apis/InternetfwProfileApi";
import { Category, UrlProfile } from "@/apis/UrlProfileApi";
import {
  PutDstAddress1,
  PutDstAddress2,
  PutPolicy,
  PutProfile,
  PutSrcAddress1,
  PutSrcAddress2,
} from "@/apis/NwPolicyApi";

export type LoadAllowLog = Pick<ContractChangeOption, "isAllowLog">;

export type LoadType4Contract = Pick<
  Type4ServicePostRequest,
  | "policyType"
  | "type4Name"
  | "isMicrosoft365"
  | "applianceType"
  | "vpnVnCode"
  | "mainSite"
  | "maintenance"
> &
  NonNullableProperty<
    Pick<Type4ServicePutRequest, "numberOfAddGlobalIpAddress">
  >;

export type LoadType4InternetSite = {
  addressList: Pick<
    PostType4InternetSite["addressList"][0],
    "ipAddressName" | "ipAddress"
  >[];
  fqdnList: Pick<PostType4InternetSite["fqdnList"][0], "fqdnName" | "fqdn">[];
};

export type LoadType4ExtraSite = (Pick<
  PostType4ExtraSiteAddress,
  "siteName"
> & {
  addressList: Pick<
    PostType4ExtraSiteAddress["addressList"][0],
    "ipAddressName" | "ipAddress"
  >[];
})[];

export type LoadType4CustomService = Pick<
  CustomServiceInfo,
  "serviceName" | "protocol" | "portNumber" | "description"
>[];

export type LoadType4IpPool = Pick<
  IpPool,
  "ipPoolName" | "globalIpAddress" | "description"
>[];

export type LoadType4IpMapping = (Pick<
  IpMapping,
  | "ipMappingName"
  | "globalIpAddress"
  | "isPortForward"
  | "protocol"
  | "globalPort"
  | "privatePort"
  | "description"
> & {
  ipAddress?: string;
})[];

export type LoadType4internetFwProfile = (Pick<
  InternetfwProfileInfo,
  "profileInternetFwName"
> & {
  applicationNameList: string[];
})[];

export type LoadType4UrlProfile = (Pick<
  UrlProfile,
  | "profileUrlName"
  | "blackList"
  | "blackListAction"
  | "whiteList"
  | "categoryList"
  | "description"
> & {
  categoryList: Pick<
    UrlProfile["categoryList"][0],
    "categoryName" | "actionType"
  >[];
})[];

export type LoadType4Policy = {
  policyIfwType4: {
    accessPointToInternetPolicyList: (Pick<
      PutPolicy,
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "description"
    > & {
      srcAddress: Pick<PutSrcAddress2, "srcAddressList">;
      dstAddress: Pick<
        PutDstAddress2,
        "dstAddressList" | "dstFqdnList" | "dstCountryList"
      >;
      profile: Pick<PutProfile, "internetFW"> & {
        profileInternetFw: null | {
          profileInternetFwName: string | null;
        };
      };
    })[];
    internetToAccessPointPolicyList: (Pick<
      PutPolicy,
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "isLogOutput"
      | "actionType"
      | "isPolicyStatus"
      | "description"
      | "policyCategoryInfo"
    > & {
      srcAddress: Pick<
        PutSrcAddress1,
        "srcAddressList" | "srcFqdnList" | "srcCountryList"
      >;
      dstAddress: Pick<PutDstAddress1, "dstAddressList">;
      profile: Pick<PutProfile, "internetFW"> & {
        profileInternetFw: null | {
          profileInternetFwName: string | null;
        };
      };
    })[];
  };
  policyIPMasqueradeType4: {
    policyList: Pick<
      PutPolicy,
      | "policyId"
      | "srcAddressList"
      | "dstAddressList"
      | "ipPoolList"
      | "isPolicyStatus"
      | "description"
    >[];
  };
  policyNatType4: {
    policyList: (Pick<
      PutPolicy,
      "policyId" | "srcAddressList" | "isPolicyStatus" | "description"
    > & {
      ipMapping: { virtualIpAddressName: string | null };
    })[];
  };
  policyUtmType4: {
    accessPointToInternetPolicyList: (Pick<
      PutPolicy,
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "actionType"
      | "isLogOutput"
      | "isPolicyStatus"
      | "description"
    > & {
      srcAddress: Pick<PutSrcAddress2, "srcAddressList">;
      dstAddress: Pick<
        PutDstAddress2,
        "dstAddressList" | "dstFqdnList" | "dstCountryList"
      >;
      profile: Pick<
        PutProfile,
        | "internetFW"
        | "idsIps"
        | "urlFiltering"
        | "webAntiVirus"
        | "isAntiSpyware"
        | "mailAntiVirus"
      > & {
        profileInternetFw: null | {
          profileInternetFwName: string | null;
        };
        profileUrl: null | {
          profileUrlName: string | null;
        };
      };
    })[];
    internetToAccessPointPolicyList: (Pick<
      PutPolicy,
      | "policyId"
      | "serviceOption"
      | "defaultServiceList"
      | "customServiceList"
      | "isLogOutput"
      | "actionType"
      | "isPolicyStatus"
      | "description"
      | "policyCategoryInfo"
    > & {
      srcAddress: Pick<
        PutSrcAddress1,
        "srcAddressList" | "srcFqdnList" | "srcCountryList"
      >;
      dstAddress: Pick<PutDstAddress1, "dstAddressList">;
      profile: Pick<
        PutProfile,
        | "internetFW"
        | "idsIps"
        | "urlFiltering"
        | "webAntiVirus"
        | "isAntiSpyware"
        | "mailAntiVirus"
      > & {
        profileInternetFw: null | {
          profileInternetFwName: string | null;
        };
        profileUrl: null | {
          profileUrlName: string | null;
        };
      };
    })[];
  };
};

export default class ImportConfigService {
  /** Allowログを読み取り */
  loadAllowLog(wb: WorkBook): LoadAllowLog {
    const sheet = wb.Sheets["Allowログ"];
    return {
      isAllowLog: sheet["D5"] ? sheet["D5"].v === "ON" : null,
    };
  }

  /** インターネットType4契約を読み取り */
  loadType4Contract(wb: WorkBook): LoadType4Contract {
    const contractSheet = wb.Sheets["契約"];
    const maintenanceSheet = wb.Sheets["保守連絡先情報"];
    return {
      policyType: contractSheet["D5"].v,
      type4Name: String(contractSheet["D6"].v),
      isMicrosoft365: contractSheet["D7"].v,
      applianceType: contractSheet["D8"].v,
      vpnVnCode: contractSheet["D9"].v,
      mainSite: contractSheet["D10"].v,
      numberOfAddGlobalIpAddress: contractSheet["D11"]
        ? contractSheet["D11"].v
        : 0,
      maintenance: {
        company: String(maintenanceSheet["D5"].v),
        department: String(maintenanceSheet["D6"].v),
        name: String(maintenanceSheet["D7"].v),
        phone: String(maintenanceSheet["D8"].v),
        email: maintenanceSheet["D9"].v,
      },
    };
  }

  /** 経路設定を読み取り */
  loadType4Route(wb: WorkBook): string[] {
    const sheet = wb.Sheets["経路"];
    const result: string[] = [];
    const baseLine = 5;
    let i = 0;
    while (sheet[`C${baseLine + i}`]) {
      result.push(sheet[`C${baseLine + i}`].v);
      i++;
    }
    return result;
  }

  /** アドレス・FQDN（インターネット）設定を読み取り */
  loadType4InternetSite(wb: WorkBook): LoadType4InternetSite {
    const sheet = wb.Sheets["アドレス・FQDN（インターネット）"];
    const addressList: LoadType4InternetSite["addressList"] = [];
    const fqdnList: LoadType4InternetSite["fqdnList"] = [];
    const baseLine = 6;

    let i = 0;
    while (sheet[`C${baseLine + i}`] || sheet[`D${baseLine + i}`]) {
      addressList.push({
        ipAddressName: sheet[`C${baseLine + i}`]
          ? String(sheet[`C${baseLine + i}`].v)
          : null,
        ipAddress: sheet[`D${baseLine + i}`].v,
      });
      i++;
    }

    i = 0;
    while (sheet[`G${baseLine + i}`] || sheet[`H${baseLine + i}`]) {
      fqdnList.push({
        fqdnName: sheet[`G${baseLine + i}`]
          ? String(sheet[`G${baseLine + i}`].v)
          : null,
        fqdn: sheet[`H${baseLine + i}`].v,
      });
      i++;
    }

    return { addressList, fqdnList };
  }

  /** サイト・アドレス（エクストラ）設定を読み取り */
  loadType4ExtraSite(wb: WorkBook): LoadType4ExtraSite {
    const sheet = wb.Sheets["サイト・アドレス（エクストラ）"];
    const siteList: {
      siteName: string;
      ipAddressName: string | null;
      ipAddress: string;
    }[] = [];
    const baseLine = 6;

    let i = 0;
    while (sheet[`C${baseLine + i}`]) {
      siteList.push({
        siteName: String(sheet[`C${baseLine + i}`].v),
        ipAddressName: sheet[`D${baseLine + i}`]
          ? String(sheet[`D${baseLine + i}`].v)
          : null,
        ipAddress: sheet[`E${baseLine + i}`].v,
      });
      i++;
    }

    return Object.entries(
      siteList.groupBy(
        (e) => e.siteName,
        (e) => ({ ipAddressName: e.ipAddressName, ipAddress: e.ipAddress })
      )
    ).map(([siteName, addressList]) => ({ siteName, addressList }));
  }

  /** 個別サービスを読み取り */
  loadType4CustomService(wb: WorkBook): LoadType4CustomService {
    const sheet = wb.Sheets["個別サービス"];
    const customServiceList: LoadType4CustomService = [];
    const baseLine = 5;

    let i = 0;
    while (sheet[`C${baseLine + i}`]) {
      customServiceList.push({
        serviceName: String(sheet[`C${baseLine + i}`].v),
        protocol: sheet[`D${baseLine + i}`].v,
        portNumber: String(sheet[`E${baseLine + i}`].v),
        description: sheet[`F${baseLine + i}`]
          ? String(sheet[`F${baseLine + i}`].v)
          : null,
      });
      i++;
    }

    return customServiceList;
  }

  /** IP Poolを読み取り */
  loadType4IpPool(wb: WorkBook): LoadType4IpPool {
    const sheet = wb.Sheets["IP Pool"];
    const ipPoolList: LoadType4IpPool = [];
    const baseLine = 5;

    let i = 0;
    while (sheet[`C${baseLine + i}`]) {
      ipPoolList.push({
        ipPoolName: String(sheet[`C${baseLine + i}`].v),
        globalIpAddress: sheet[`D${baseLine + i}`].v,
        description: sheet[`E${baseLine + i}`]
          ? String(sheet[`E${baseLine + i}`].v)
          : null,
      });
      i++;
    }

    return ipPoolList;
  }

  /** IPマッピングを読み取り */
  loadType4IpMapping(wb: WorkBook): LoadType4IpMapping {
    const sheet = wb.Sheets["IPマッピング"];
    const ipMappingList: LoadType4IpMapping = [];
    const baseLine = 6;

    let i = 0;
    while (sheet[`C${baseLine + i}`]) {
      ipMappingList.push({
        ipMappingName: String(sheet[`C${baseLine + i}`].v),
        globalIpAddress: sheet[`D${baseLine + i}`].v,
        ipAddress: sheet[`E${baseLine + i}`].v,
        isPortForward: sheet[`F${baseLine + i}`].v === "有効",
        protocol: sheet[`G${baseLine + i}`]
          ? sheet[`G${baseLine + i}`].v
          : null,
        globalPort: sheet[`H${baseLine + i}`]
          ? String(sheet[`H${baseLine + i}`].v)
          : null,
        privatePort: sheet[`I${baseLine + i}`]
          ? String(sheet[`I${baseLine + i}`].v)
          : null,
        description: sheet[`J${baseLine + i}`]
          ? String(sheet[`J${baseLine + i}`].v)
          : null,
      });
      i++;
    }

    return ipMappingList;
  }

  /** インターネットFWプロファイルを読み取り */
  loadType4InternetFwProfile(wb: WorkBook): LoadType4internetFwProfile {
    const sheet = wb.Sheets["インターネットFWプロファイル"];
    const internetFwProfileList: LoadType4internetFwProfile = [];
    const baseLine = 5;

    let i = 0;
    while (sheet[`C${baseLine + i}`]) {
      internetFwProfileList.push({
        profileInternetFwName: String(sheet[`C${baseLine + i}`].v),
        applicationNameList: sheet[`D${baseLine + i}`]
          ? sheet[`D${baseLine + i}`].v.split(",")
          : [],
      });
      i++;
    }

    return internetFwProfileList;
  }

  /** URLフィルタリングプロファイルを読み取り */
  loadType4UrlProfile(wb: WorkBook): LoadType4UrlProfile {
    const sheet = wb.Sheets["URLフィルタリングプロファイル"];
    const urlProfileList: LoadType4UrlProfile = [];
    const baseLine = 5;

    let i = 0;
    while (sheet[`C${baseLine + i}`]) {
      const categoryList: Category[] = [];
      const loadCategoryList: string[] = sheet[`D${baseLine + i}`]
        ? sheet[`D${baseLine + i}`].v.split(",")
        : [];
      loadCategoryList.forEach((e, index) => {
        if (index % 2 === 0) {
          categoryList.push({
            categoryName: e,
            actionType: loadCategoryList[
              index + 1
            ].toUpperCase() as Category["actionType"],
          });
        }
      });

      urlProfileList.push({
        profileUrlName: String(sheet[`C${baseLine + i}`].v),
        blackList: sheet[`E${baseLine + i}`]
          ? sheet[`E${baseLine + i}`].v.split(",")
          : [],
        blackListAction: sheet[`F${baseLine + i}`].v.toUpperCase(),
        whiteList: sheet[`G${baseLine + i}`]
          ? sheet[`G${baseLine + i}`].v.split(",")
          : [],
        categoryList: categoryList,
        description: sheet[`H${baseLine + i}`]
          ? String(sheet[`H${baseLine + i}`].v)
          : null,
      });
      i++;
    }

    return urlProfileList;
  }

  /** ポリシー設定を読み取り */
  loadType4Policy(wb: WorkBook): LoadType4Policy {
    const sheetIfw = wb.Sheets["ポリシー設定（インターネットFW）"];
    const sheetNat = wb.Sheets["ポリシー設定（NAT）"];
    const sheetUtm = wb.Sheets["ポリシー設定（UTM）"];
    const ifwAccessPointToInternetPolicyList: LoadType4Policy["policyIfwType4"]["accessPointToInternetPolicyList"] =
      [];
    const ifwInternetToAccessPointPolicyList: LoadType4Policy["policyIfwType4"]["internetToAccessPointPolicyList"] =
      [];
    const policyIPMasqueradeType4: LoadType4Policy["policyIPMasqueradeType4"]["policyList"] =
      [];
    const policyNatType4: LoadType4Policy["policyNatType4"]["policyList"] = [];
    const utmAccessPointToInternetPolicyList: LoadType4Policy["policyUtmType4"]["accessPointToInternetPolicyList"] =
      [];
    const utmInternetToAccessPointPolicyList: LoadType4Policy["policyUtmType4"]["internetToAccessPointPolicyList"] =
      [];
    const baseLine = 8;

    /** インターネットFW(エクストラサイト → インターネットサイト)ポリシー設定を読み取り */
    let i = 0;
    while (sheetIfw[`C${baseLine + i}`]) {
      let defaultServiceList: string[] = [];
      let customServiceList: string[] = [];
      let serviceOption: "ANY" | "SELECT" | "DEFAULT" = "ANY";
      let internetFwProfile: LoadType4Policy["policyIfwType4"]["accessPointToInternetPolicyList"][0]["profile"]["profileInternetFw"] =
        null;

      switch (sheetIfw[`H${baseLine + i}`].v) {
        case "any":
          serviceOption = sheetIfw[`H${baseLine + i}`].v.toUpperCase();
          break;
        case "Select":
          serviceOption = sheetIfw[`H${baseLine + i}`].v.toUpperCase();
          defaultServiceList = sheetIfw[`I${baseLine + i}`]
            ? sheetIfw[`I${baseLine + i}`].v.split(",")
            : [];
          customServiceList = sheetIfw[`J${baseLine + i}`]
            ? String(sheetIfw[`J${baseLine + i}`].v).split(",")
            : [];
          break;
        case "Application-default":
          serviceOption = "DEFAULT";
          break;
      }

      if (sheetIfw[`L${baseLine + i}`].v === "user") {
        internetFwProfile = {
          profileInternetFwName: sheetIfw[`M${baseLine + i}`]
            ? String(sheetIfw[`M${baseLine + i}`].v)
            : null,
        };
      }

      ifwAccessPointToInternetPolicyList.push({
        policyId: `000${sheetIfw[`C${baseLine + i}`].v}`.slice(-4),
        srcAddress: {
          srcAddressList: sheetIfw[`D${baseLine + i}`]
            ? sheetIfw[`D${baseLine + i}`].v.split(",")
            : [],
        },
        dstAddress: {
          dstAddressList: sheetIfw[`E${baseLine + i}`]
            ? sheetIfw[`E${baseLine + i}`].v.split(",")
            : [],
          dstFqdnList: sheetIfw[`F${baseLine + i}`]
            ? sheetIfw[`F${baseLine + i}`].v.split(",")
            : [],
          dstCountryList: sheetIfw[`G${baseLine + i}`]
            ? sheetIfw[`G${baseLine + i}`].v.split(",")
            : [],
        },
        serviceOption: serviceOption,
        defaultServiceList: defaultServiceList,
        customServiceList: customServiceList,
        profile: {
          internetFW: sheetIfw[`L${baseLine + i}`].v.toUpperCase(),
          profileInternetFw: internetFwProfile,
        },
        actionType: sheetIfw[`K${baseLine + i}`].v.toUpperCase(),
        isLogOutput: sheetIfw[`N${baseLine + i}`].v === "有効",
        isPolicyStatus: sheetIfw[`O${baseLine + i}`].v === "有効",
        description: sheetIfw[`P${baseLine + i}`]
          ? String(sheetIfw[`P${baseLine + i}`].v)
          : null,
      });
      i++;
    }

    /** インターネットFW(インターネットサイト → エクストラサイト)ポリシー設定を読み取り */
    i = 0;
    while (sheetIfw[`R${baseLine + i}`]) {
      let defaultServiceList: string[] = [];
      let customServiceList: string[] = [];
      let serviceOption: "ANY" | "SELECT" | "DEFAULT" = "ANY";
      let internetFwProfile: LoadType4Policy["policyIfwType4"]["internetToAccessPointPolicyList"][0]["profile"]["profileInternetFw"] =
        null;

      switch (sheetIfw[`W${baseLine + i}`].v) {
        case "any":
          serviceOption = sheetIfw[`W${baseLine + i}`].v.toUpperCase();
          break;
        case "Select":
          serviceOption = sheetIfw[`W${baseLine + i}`].v.toUpperCase();
          defaultServiceList = sheetIfw[`X${baseLine + i}`]
            ? sheetIfw[`X${baseLine + i}`].v.split(",")
            : [];
          customServiceList = sheetIfw[`Y${baseLine + i}`]
            ? String(sheetIfw[`Y${baseLine + i}`].v).split(",")
            : [];
          break;
        case "Application-default":
          serviceOption = "DEFAULT";
          break;
      }

      if (sheetIfw[`AA${baseLine + i}`].v === "user") {
        internetFwProfile = {
          profileInternetFwName: sheetIfw[`AB${baseLine + i}`]
            ? String(sheetIfw[`AB${baseLine + i}`].v)
            : null,
        };
      }

      ifwInternetToAccessPointPolicyList.push({
        policyId: `000${sheetIfw[`R${baseLine + i}`].v}`.slice(-4),
        srcAddress: {
          srcAddressList: sheetIfw[`S${baseLine + i}`]
            ? sheetIfw[`S${baseLine + i}`].v.split(",")
            : [],
          srcFqdnList: sheetIfw[`T${baseLine + i}`]
            ? sheetIfw[`T${baseLine + i}`].v.split(",")
            : [],
          srcCountryList: sheetIfw[`U${baseLine + i}`]
            ? sheetIfw[`U${baseLine + i}`].v.split(",")
            : [],
        },
        dstAddress: {
          dstAddressList: sheetIfw[`V${baseLine + i}`]
            ? sheetIfw[`V${baseLine + i}`].v.split(",")
            : [],
        },
        serviceOption: serviceOption,
        defaultServiceList: defaultServiceList,
        customServiceList: customServiceList,
        profile: {
          internetFW: sheetIfw[`AA${baseLine + i}`].v.toUpperCase(),
          profileInternetFw: internetFwProfile,
        },
        actionType: sheetIfw[`Z${baseLine + i}`].v.toUpperCase(),
        isLogOutput: sheetIfw[`AC${baseLine + i}`].v === "有効",
        isPolicyStatus: sheetIfw[`AD${baseLine + i}`].v === "有効",
        description: sheetIfw[`AE${baseLine + i}`]
          ? String(sheetIfw[`AE${baseLine + i}`].v)
          : null,
        policyCategoryInfo: "CUSTOM_POLICY",
      });
      i++;
    }

    /** NAT(IP Masquerade)ポリシー設定を読み取り */
    i = 0;
    while (sheetNat[`C${baseLine + i}`]) {
      policyIPMasqueradeType4.push({
        policyId: `000${sheetNat[`C${baseLine + i}`].v}`.slice(-4),
        srcAddressList: sheetNat[`D${baseLine + i}`]
          ? sheetNat[`D${baseLine + i}`].v.split(",")
          : [],
        dstAddressList: sheetNat[`E${baseLine + i}`]
          ? sheetNat[`E${baseLine + i}`].v.split(",")
          : [],
        ipPoolList: String(sheetNat[`F${baseLine + i}`].v).split(","),
        isPolicyStatus: sheetNat[`G${baseLine + i}`].v === "有効",
        description: sheetNat[`H${baseLine + i}`]
          ? String(sheetNat[`H${baseLine + i}`].v)
          : null,
      });
      i++;
    }

    /** NAT(IP Forwarding)ポリシー設定を読み取り */
    i = 0;
    while (sheetNat[`J${baseLine + i}`]) {
      policyNatType4.push({
        policyId: `000${sheetNat[`J${baseLine + i}`].v}`.slice(-4),
        srcAddressList: sheetNat[`K${baseLine + i}`]
          ? sheetNat[`K${baseLine + i}`].v.split(",")
          : [],
        ipMapping: {
          virtualIpAddressName: sheetNat[`L${baseLine + i}`].v
            ? String(sheetNat[`L${baseLine + i}`].v)
            : null,
        },
        isPolicyStatus: sheetNat[`M${baseLine + i}`].v === "有効",
        description: sheetNat[`N${baseLine + i}`]
          ? String(sheetNat[`N${baseLine + i}`].v)
          : null,
      });
      i++;
    }

    /** UTM(エクストラサイト → インターネットサイト)ポリシー設定を読み取り */
    i = 0;
    while (sheetUtm[`C${baseLine + i}`]) {
      let defaultServiceList: string[] = [];
      let customServiceList: string[] = [];
      let serviceOption: "ANY" | "SELECT" | "DEFAULT" = "ANY";
      let internetFwProfile: LoadType4Policy["policyUtmType4"]["accessPointToInternetPolicyList"][0]["profile"]["profileInternetFw"] =
        null;
      let urlFilteringProfile: LoadType4Policy["policyUtmType4"]["accessPointToInternetPolicyList"][0]["profile"]["profileUrl"] =
        null;

      switch (sheetUtm[`H${baseLine + i}`].v) {
        case "any":
          serviceOption = sheetUtm[`H${baseLine + i}`].v.toUpperCase();
          break;
        case "Select":
          serviceOption = sheetUtm[`H${baseLine + i}`].v.toUpperCase();
          defaultServiceList = sheetUtm[`I${baseLine + i}`]
            ? sheetUtm[`I${baseLine + i}`].v.split(",")
            : [];
          customServiceList = sheetUtm[`J${baseLine + i}`]
            ? String(sheetUtm[`J${baseLine + i}`].v).split(",")
            : [];
          break;
        case "Application-default":
          serviceOption = "DEFAULT";
          break;
      }

      if (sheetUtm[`L${baseLine + i}`].v === "user") {
        internetFwProfile = {
          profileInternetFwName: sheetUtm[`M${baseLine + i}`]
            ? String(sheetUtm[`M${baseLine + i}`].v)
            : null,
        };
      }

      if (sheetUtm[`O${baseLine + i}`].v === "user") {
        urlFilteringProfile = {
          profileUrlName: sheetUtm[`P${baseLine + i}`]
            ? String(sheetUtm[`P${baseLine + i}`].v)
            : null,
        };
      }

      utmAccessPointToInternetPolicyList.push({
        policyId: `000${sheetUtm[`C${baseLine + i}`].v}`.slice(-4),
        srcAddress: {
          srcAddressList: sheetUtm[`D${baseLine + i}`]
            ? sheetUtm[`D${baseLine + i}`].v.split(",")
            : [],
        },
        dstAddress: {
          dstAddressList: sheetUtm[`E${baseLine + i}`]
            ? sheetUtm[`E${baseLine + i}`].v.split(",")
            : [],
          dstFqdnList: sheetUtm[`F${baseLine + i}`]
            ? sheetUtm[`F${baseLine + i}`].v.split(",")
            : [],
          dstCountryList: sheetUtm[`G${baseLine + i}`]
            ? sheetUtm[`G${baseLine + i}`].v.split(",")
            : [],
        },
        serviceOption: serviceOption,
        defaultServiceList: defaultServiceList,
        customServiceList: customServiceList,
        profile: {
          internetFW: sheetUtm[`L${baseLine + i}`].v.toUpperCase(),
          profileInternetFw: internetFwProfile,
          idsIps: sheetUtm[`N${baseLine + i}`].v.toUpperCase(),
          urlFiltering: sheetUtm[`O${baseLine + i}`].v.toUpperCase(),
          profileUrl: urlFilteringProfile,
          webAntiVirus: sheetUtm[`Q${baseLine + i}`].v.toUpperCase(),
          isAntiSpyware: sheetUtm[`R${baseLine + i}`].v === "有効",
          mailAntiVirus: sheetUtm[`S${baseLine + i}`].v.toUpperCase(),
        },
        actionType: sheetUtm[`K${baseLine + i}`].v.toUpperCase(),
        isLogOutput: sheetUtm[`T${baseLine + i}`].v === "有効",
        isPolicyStatus: sheetUtm[`U${baseLine + i}`].v === "有効",
        description: sheetUtm[`V${baseLine + i}`]
          ? String(sheetUtm[`V${baseLine + i}`].v)
          : null,
      });
      i++;
    }

    /** UTM(インターネットサイト → エクストラサイト)ポリシー設定を読み取り */
    i = 0;
    while (sheetUtm[`X${baseLine + i}`]) {
      let defaultServiceList: string[] = [];
      let customServiceList: string[] = [];
      let serviceOption: "ANY" | "SELECT" | "DEFAULT" = "ANY";
      let internetFwProfile: LoadType4Policy["policyUtmType4"]["internetToAccessPointPolicyList"][0]["profile"]["profileInternetFw"] =
        null;
      let urlFilteringProfile: LoadType4Policy["policyUtmType4"]["internetToAccessPointPolicyList"][0]["profile"]["profileUrl"] =
        null;

      switch (sheetUtm[`AC${baseLine + i}`].v) {
        case "any":
          serviceOption = sheetUtm[`AC${baseLine + i}`].v.toUpperCase();
          break;
        case "Select":
          serviceOption = sheetUtm[`AC${baseLine + i}`].v.toUpperCase();
          defaultServiceList = sheetUtm[`AD${baseLine + i}`]
            ? sheetUtm[`AD${baseLine + i}`].v.split(",")
            : [];
          customServiceList = sheetUtm[`AE${baseLine + i}`]
            ? String(sheetUtm[`AE${baseLine + i}`].v).split(",")
            : [];
          break;
        case "Application-default":
          serviceOption = "DEFAULT";
          break;
      }

      if (sheetUtm[`AG${baseLine + i}`].v === "user") {
        internetFwProfile = {
          profileInternetFwName: sheetUtm[`AH${baseLine + i}`]
            ? String(sheetUtm[`AH${baseLine + i}`].v)
            : null,
        };
      }

      if (sheetUtm[`AJ${baseLine + i}`].v === "user") {
        urlFilteringProfile = {
          profileUrlName: sheetUtm[`AK${baseLine + i}`]
            ? String(sheetUtm[`AK${baseLine + i}`].v)
            : null,
        };
      }

      utmInternetToAccessPointPolicyList.push({
        policyId: `000${sheetUtm[`X${baseLine + i}`].v}`.slice(-4),
        srcAddress: {
          srcAddressList: sheetUtm[`Y${baseLine + i}`]
            ? sheetUtm[`Y${baseLine + i}`].v.split(",")
            : [],
          srcFqdnList: sheetUtm[`Z${baseLine + i}`]
            ? sheetUtm[`Z${baseLine + i}`].v.split(",")
            : [],
          srcCountryList: sheetUtm[`AA${baseLine + i}`]
            ? sheetUtm[`AA${baseLine + i}`].v.split(",")
            : [],
        },
        dstAddress: {
          dstAddressList: sheetUtm[`AB${baseLine + i}`]
            ? sheetUtm[`AB${baseLine + i}`].v.split(",")
            : [],
        },
        serviceOption: serviceOption,
        defaultServiceList: defaultServiceList,
        customServiceList: customServiceList,
        profile: {
          internetFW: sheetUtm[`AG${baseLine + i}`].v.toUpperCase(),
          profileInternetFw: internetFwProfile,
          idsIps: sheetUtm[`AI${baseLine + i}`].v.toUpperCase(),
          urlFiltering: sheetUtm[`AJ${baseLine + i}`].v.toUpperCase(),
          profileUrl: urlFilteringProfile,
          webAntiVirus: sheetUtm[`AL${baseLine + i}`].v.toUpperCase(),
          isAntiSpyware: sheetUtm[`AM${baseLine + i}`].v === "有効",
          mailAntiVirus: sheetUtm[`AN${baseLine + i}`].v.toUpperCase(),
        },
        actionType: sheetUtm[`AF${baseLine + i}`].v.toUpperCase(),
        isLogOutput: sheetUtm[`AO${baseLine + i}`].v === "有効",
        isPolicyStatus: sheetUtm[`AP${baseLine + i}`].v === "有効",
        description: sheetUtm[`AQ${baseLine + i}`]
          ? String(sheetUtm[`AQ${baseLine + i}`].v)
          : null,
        policyCategoryInfo: "CUSTOM_POLICY",
      });
      i++;
    }

    return {
      policyIfwType4: {
        accessPointToInternetPolicyList: ifwAccessPointToInternetPolicyList,
        internetToAccessPointPolicyList: ifwInternetToAccessPointPolicyList,
      },
      policyIPMasqueradeType4: {
        policyList: policyIPMasqueradeType4,
      },
      policyNatType4: {
        policyList: policyNatType4,
      },
      policyUtmType4: {
        accessPointToInternetPolicyList: utmAccessPointToInternetPolicyList,
        internetToAccessPointPolicyList: utmInternetToAccessPointPolicyList,
      },
    };
  }
}














































































































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import {
  OperationHistoryEntity,
  OperationListEntity,
} from "@/apis/OperationHistoryApi";
import HistoryReference from "@/modals/operationHistory/HistoryReference.vue";

export default Vue.extend({
  name: "OperationHistoryList",
  data() {
    return {
      history: {
        fields: [
          { key: "content", label: "内容", sortable: true },
          { key: "account", label: "実行アカウント", sortable: true },
          {
            key: "acceptDateTime",
            label: "受付日時",
            sortable: true,
          },
          {
            key: "endDateTime",
            label: "完了日時",
            sortable: true,
          },
          {
            key: "orderStatus",
            label: "ステータス",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) =>
              this.$filter.enumTo(value, "operationOrderStatus"),
          },
          {
            key: "note",
            label: "オーダー備考",
            tdClass: "text-pre-wrap",
            sortable: true,
          },
        ],
        items: [],
        search: {
          content: {
            type: "select",
            label: "内容",
            items: [
              "ポリシー設定",
              "サービス申込",
              "契約取込",
              "手動契約取込",
              "収容変更",
              "インターネット一時停止",
              "仮想NW申込",
              "VNコネクト設定",
              "フロー設定",
              "VN回線有効無効設定",
              "インターフェース設定",
              "サービス申込(Type3)",
              "アクセス品目申込",
              "IPsec設定",
              "エクストラネット接続申込",
              "Type3ポリシー設定",
              "Type3経路設定",
              "ポリシー設定(プラットフォームゲートウェイ)",
              "VN回線有効無効設定（収容変更）",
              "フロー設定（収容変更）",
              "VN回線設定（収容変更）",
              "インターフェース設定（収容変更）",
              "VN回線収容変更",
              "Hub&Spoke接続申込",
              "VN L2/L3コネクト設定",
              "トラフィック情報取得開始",
              "VNコネクト一括設定",
              "クラウド毎帯域設定",
              "サービスタグ取得",
              "サービス申込(Microsoftサービス)",
              "クラウド向けVNコネクト設定",
              "クラウド向けVN回線有効無効設定",
              "クラウド向けインターフェース設定",
              "サービス申込(Type4)",
              "Type4ポリシー設定",
              "Type4経路設定",
              "CPA 5G SA 契約変更",
              "CPA 5G SA NW設定",
              "CPA 5G SA 利用開始",
              "CPA 5G SA 認証ID一括登録",
              "サービス申込(SaaS)",
            ].map((e) => ({ value: e, text: e })),
          },
          account: { type: "text", label: "実行アカウント" },
          acceptDateTime: { type: "custom", label: "受付日時" },
          endDateTime: { type: "custom", label: "完了日時" },
          orderStatus: {
            type: "select",
            label: "ステータス",
            items: [
              { value: "SUCCESS", text: "成功" },
              { value: "FAILURE", text: "失敗" },
              { value: "RESERVATION", text: "予約中" },
              { value: "SETTING", text: "設定中" },
              { value: "ACCEPTED", text: "受付完了、処理待ち" },
            ],
          },
          note: { type: "text", label: "オーダー備考" },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          content: "equal",
          account: "like",
          acceptDateTime: (item, value: { start: string; end: string }) => {
            return this.$moment(item.acceptDateTime).isBetween(
              this.$moment(value.start),
              this.$moment(value.end),
              undefined,
              "[]"
            );
          },
          endDateTime: (item, value: { start: string; end: string }) => {
            return this.$moment(item.endDateTime).isBetween(
              this.$moment(value.start),
              this.$moment(value.end),
              undefined,
              "[]"
            );
          },
          orderStatus: "equal",
          note: "like",
        },
      } as Omit<AppTableData<OperationListEntity>, "items" | "selected">,
      operationHistory: {} as OperationHistoryEntity,
      isLoaded: false,
      noDetailContent: new Set([
        "契約取込",
        "手動契約取込",
        "エクストラネット接続申込",
        "VN回線収容変更",
        "Hub&Spoke接続申込",
        "トラフィック情報取得開始",
        "VNコネクト一括設定",
      ]),
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.operationHistory = await this.$api.operationHistory.getAll();
    },
    /** 詳細はない行はクラスで制御 */
    rowClass(item: OperationListEntity) {
      return this.noDetailContent.has(item.content) ||
        this.$moment(item.acceptDateTime).format("YYYY-MM-DD") <= "2016-12-21"
        ? "un-clickable"
        : null;
    },
    /** 操作履歴情報画面を表示*/
    async historyDetails(item: OperationListEntity) {
      /** 詳細はない行、何もしない */
      if (this.noDetailContent.has(item.content)) {
        return;
      }
      /** 受付日時が2016年12月21日以前、何もしない */
      if (
        this.$moment(item.acceptDateTime).format("YYYY-MM-DD") <= "2016-12-21"
      ) {
        return;
      }
      await this.$modal.show(HistoryReference, {
        operationSeq: item.operationSeq,
      });
    },
  },
});




















































































































































































































































































import Vue, { PropType } from "vue";
import { IpSecGWEntity } from "@/apis/IpSecGwApi";
import { UploadError } from "@/services/ConfigDownloadService";
import { IpSecLineWithGW } from "@/views/ipSecLine/IpSecLineList.vue";
import cloneDeep from "lodash/cloneDeep";
import IpSecLineViewCD from "@/modals/ipSecLine/IpSecLineViewCD.vue";

export default Vue.extend({
  name: "IpSecLineEdit",
  props: {
    /** 変更対象のIPsec回線 + IPsecGW */
    ipSecLine: {
      type: Object as PropType<IpSecLineWithGW>,
      required: false,
    },
    /** IPsec回線排他情報（変更の場合のみ必須） */
    acLineKeyId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    const form: IpSecLineWithGW = (() => {
      const form = (cloneDeep(this.ipSecLine) ?? {
        lanIpAddressList: [{ acLineIpAddressSeq: null, ipAddress: "" }],
      }) as IpSecLineWithGW;
      // 変更かつアドレスリストが0件の場合、初期化
      if (form.lanIpAddressList.length === 0) {
        form.lanIpAddressList = [{ acLineIpAddressSeq: null, ipAddress: "" }];
      }
      return form;
    })();
    return {
      uploadAlert: null as string | null,
      form: form,
      gwList: [] as IpSecGWEntity[],
      isLoaded: false,
    };
  },
  computed: {
    /** true: 変更, false: 追加 */
    isEdit(): boolean {
      return !!this.ipSecLine;
    },
    /** 選択可能IPsecGW一覧（サフィックスありかつ、作成上限値に達していない場合のみ表示） */
    selectableGwList(): IpSecGWEntity[] {
      return this.gwList.filter(
        (e) => e.suffix && e.numberOfIpsec < e.upperLimitIpsec
      );
    },
    /** LANアドレスの重複チェック用リスト作成 */
    lanAddressList() {
      return (index: number) => {
        return this.form.lanIpAddressList
          .filter((_, num) => num !== index)
          .map((e) => e.ipAddress);
      };
    },
  },
  async mounted() {
    this.gwList = (await this.$api.ipSecGw.getAll()).ipsecGatewayList;
    this.isLoaded = true;
  },
  methods: {
    // IPsec回線の追加・更新を行う
    async submit() {
      this.uploadAlert = null;
      const note: string | null = await this.$modal.show(IpSecLineViewCD, {
        mode: this.isEdit ? "modify" : "register",
        lines: [
          {
            ...this.form,
            lanIpAddressList: this.form.lanIpAddressList.filter(
              (e) => e.ipAddress
            ),
          },
        ],
      });

      if (this.isEdit) {
        await this.$api.ipSecLine.update(this.ipSecLine!.xnumber, {
          ipsecLineName: this.form.ipsecLineName,
          preSharedKey: this.form.preSharedKey,
          connectTestIpAddress: this.form.connectTestIpAddress,
          description: this.form.description,
          lanIpAddressList: this.form.lanIpAddressList.filter(
            (e) => e.ipAddress
          ),
          acLineKeyId: this.acLineKeyId,
          note: note,
        });
      } else {
        await this.$api.ipSecLine.post({
          ipsecLineName: this.form.ipsecLineName,
          preSharedKey: this.form.preSharedKey,
          connectTestIpAddress: this.form.connectTestIpAddress,
          description: this.form.description,
          enumber: this.form.gw.enumber,
          userAccount: this.form.userAccount,
          lanIpAddressList: this.form.lanIpAddressList
            .filter((e) => e.ipAddress)
            .map((e) => ({ ipAddress: e.ipAddress })),
          note: note,
        });
      }

      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    // 設定ダウンロード処理
    downloadConfig() {
      if (this.isEdit) {
        this.$service.configDownload.downloadIpsecLine(
          this.$store.state.user.contractSummary.vnumber,
          "modify",
          this.form
        );
      } else {
        this.$service.configDownload.downloadIpsecLine(
          this.$store.state.user.contractSummary.vnumber,
          "add",
          this.form
        );
      }
    },

    // 設定ダウンロード処理
    async uploadConfig() {
      try {
        const uploadIpsec = this.isEdit
          ? await this.$service.configDownload.uploadIpsecLine(
              this.$store.state.user.contractSummary.vnumber,
              "modify",
              this.ipSecLine.xnumber
            )
          : await this.$service.configDownload.uploadIpsecLine(
              this.$store.state.user.contractSummary.vnumber,
              "add"
            );
        this.uploadAlert = null;
        await this.$confirm(`IPsec回線設定を上書きします。よろしいですか？`);
        // 追加・変更共通
        this.form.ipsecLineName = uploadIpsec.ipsecLineName;
        this.form.preSharedKey = uploadIpsec.preSharedKey;
        this.form.connectTestIpAddress = uploadIpsec.connectTestIpAddress;
        this.form.description = uploadIpsec.description;
        // 追加のみ
        if (!this.isEdit) {
          (this.form as IpSecLineWithGW | { gw: null }).gw =
            (this.selectableGwList.find(
              (e) => e.enumber === uploadIpsec.enumber
            ) as IpSecLineWithGW["gw"]) ?? null;
          this.form.userAccount = uploadIpsec.userAccount ?? "";
        }
        // LANアドレス一覧は存在しないacLineIpAddressSeqはnullに変更して設定
        const seqSet = new Set(
          this.form.lanIpAddressList
            .filter((e) => e.acLineIpAddressSeq)
            .map((e) => e.acLineIpAddressSeq)
        );
        const uploadIpAddressList = uploadIpsec.lanIpAddressList?.map((e) => {
          if (e.acLineIpAddressSeq) {
            return {
              acLineIpAddressSeq: seqSet.has(e.acLineIpAddressSeq)
                ? e.acLineIpAddressSeq
                : null,
              ipAddress: e.ipAddress,
            };
          } else {
            return {
              acLineIpAddressSeq: null,
              ipAddress: e.ipAddress,
            };
          }
        });
        this.form.lanIpAddressList =
          uploadIpAddressList && uploadIpAddressList.length > 0
            ? uploadIpAddressList
            : [{ acLineIpAddressSeq: null, ipAddress: "" }];
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },

    /** LAN追加 */
    addLAN() {
      this.form.lanIpAddressList = [
        ...this.form.lanIpAddressList,
        { acLineIpAddressSeq: null, ipAddress: "" },
      ];
    },

    /** LAN削除 */
    removeLAN(index: number) {
      if (this.form.lanIpAddressList.length > 1) {
        this.form.lanIpAddressList.splice(index, 1);
      } else {
        this.form.lanIpAddressList = [
          { acLineIpAddressSeq: null, ipAddress: "" },
        ];
      }
    },
  },
});

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
// 入力されたPort番号をチェックする
// ---------------------------------------------------------------------------------------------
export default {
  // 0を許容するパターンと許容しないパターンがあるので、引数として受け取る
  params: ["minVal"],
  validate(
    value: string | undefined | null,
    { minVal }: { minVal: number }
  ): boolean {
    if (!value) return true;

    const ValidPort = (portNumber: string) => {
      if (parseInt(portNumber).toString() !== portNumber) {
        return false;
      } else {
        return minVal <= parseInt(portNumber) && parseInt(portNumber) <= 65535;
      }
    };

    if (value.indexOf("-") === -1) {
      // 範囲指定でないケース
      return ValidPort(value);
    }
    const portArray = value.split("-");

    if (portArray.length !== 2) return false;
    if (ValidPort(portArray[0]) && ValidPort(portArray[1])) {
      return parseInt(portArray[0]) < parseInt(portArray[1]);
    } else {
      return false;
    }
  },
} as ValidationRuleSchema;

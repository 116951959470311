/**
 * vee-validate-からbootstrap-vueへのinputエラー表示の変換
 * dirty（触った） or validate（submitなどでバリデーションが実施された）場合のみvalidation結果を反映
 */
export default function validState({
  dirty,
  validated,
  valid = null,
}: {
  dirty: unknown;
  validated: unknown;
  valid: unknown;
}): unknown | null {
  return dirty || validated ? valid : null;
}

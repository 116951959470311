












import Vue, { PropType } from "vue";

/**
 * vue-multiselectのtagのレイアウトを変更する際に使うコンポーネント
 * classとかremove iconとか毎回書きたくない
 */
export default Vue.extend({
  name: "MultiSelectTag",
  props: {
    option: {
      type: Object as PropType<unknown>,
      required: true,
      validator: () => true,
    },
    remove: {
      type: Function as PropType<(option: unknown) => void>,
      required: true,
    },
  },
});




























































































































import Vue, { PropType } from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { GetPolicy, GetPolicyType4 } from "@/apis/NwPolicyApi";
import cloneDeep from "lodash/cloneDeep";
import PolicyService from "@/components/policy/PolicyService.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyProfile from "@/components/policy/PolicyProfile.vue";
import InternetType4PolicyRuleIfwEdit from "@/modals/applianceContractSetting/InternetType4PolicyRuleIfwEdit.vue";
import { Type4Service } from "@/apis/ContractChangeType4Api";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";

export type Type4IFWPolicy =
  | NonNullable<
      GetPolicyType4["policyIfwType4"]
    >["internetToAccessPointPolicyList"][0]
  | NonNullable<
      GetPolicyType4["policyIfwType4"]
    >["accessPointToInternetPolicyList"][0];

export default Vue.extend({
  name: "InternetType4PolicyRuleIfwViewRD",
  components: {
    PolicyProfile,
    PolicySrcAddress,
    PolicyDstAddress,
    PolicyService,
  },
  props: {
    /** Type4Id. 主キー */
    type4Id: {
      type: String as PropType<string>,
      required: true,
    },
    /** インターネット/アクセス拠点のどっち発か */
    from: {
      type: String as PropType<"INTERNET" | "ACCESS_POINT">,
      required: true,
    },
    /** ポリシーリスト（インターネットからアクセス拠点向け/アクセス拠点からインターネット向け） */
    policyList: {
      type: Array as PropType<Type4IFWPolicy[]>,
      required: true,
    },
    /** type4ポリシー全体 */
    type4Policy: {
      type: Object as PropType<GetPolicyType4>,
      required: true,
    },
    /** サービスメニュー(インターネットType1) */
    type4Service: {
      type: Object as PropType<Type4Service>,
      required: true,
    },
  },
  data() {
    return {
      table: {
        fields: [
          { key: "_handle", label: "↑↓", tdClass: "handle" },
          { key: "policyId", label: "ID" },
          { key: "srcAddress", label: "送信元アドレス" },
          { key: "dstAddress", label: "宛先アドレス" },
          { key: "service", label: "サービス" },
          { key: "profile", label: "プロファイル" },
          { key: "actionType", label: "アクション" },
          { key: "isLogOutput", label: "ログ出力" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
          { key: "delete", label: "" },
        ],
        items: cloneDeep(this.policyList),
      } as Pick<AppTableData<Type4IFWPolicy>, "fields" | "items">,
      ifwPolicyLimit: [] as UpperLimitEntity[],
      isLoaded: false,
    };
  },
  computed: {
    /** true: 作成できるポリシー数の上限, false: 上限ではない */
    limitPolicy(): boolean {
      // IFW側のカスタムポリシー数
      const totalPolicy =
        (this.latestType4Policy.policyIfwType4?.accessPointToInternetPolicyList.filter(
          (e) => e.policyCategoryInfo === "CUSTOM_POLICY"
        ).length ?? 0) +
        (this.latestType4Policy.policyIfwType4?.internetToAccessPointPolicyList.filter(
          (e) => e.policyCategoryInfo === "CUSTOM_POLICY"
        ).length ?? 0);
      return (
        totalPolicy >=
        this.ifwPolicyLimit!.filter(
          (e) => e.upperLimitItemName === "ポリシー数(インターネットFW)"
        )[0]!.upperLimit
      );
    },
    /** 最新の全体ポリシー（全体ポリシー + 編集中のポリシー ）*/
    latestType4Policy(): GetPolicyType4 {
      return {
        ...this.type4Policy,
        policyIfwType4:
          this.from === "INTERNET"
            ? {
                ...this.type4Policy.policyIfwType4!,
                internetToAccessPointPolicyList: this.table
                  .items as NonNullable<
                  GetPolicyType4["policyIfwType4"]
                >["internetToAccessPointPolicyList"],
              }
            : {
                ...this.type4Policy.policyIfwType4!,
                accessPointToInternetPolicyList: this.table
                  .items as NonNullable<
                  GetPolicyType4["policyIfwType4"]
                >["accessPointToInternetPolicyList"],
              },
      };
    },
  },
  async mounted() {
    /** インターネットFWの設定上限値を取得 */
    this.ifwPolicyLimit = (
      await this.$api.upperLimit.getUpperLimitLine({
        upperLimitLineManageUnit: "APPLIANCE",
        applianceType: "INTERNET_FW",
        wnumber: this.type4Service.wnumberMainAct,
        type4Id: this.type4Service.type4Id,
      })
    ).internetLine!.upperLimitList!;
    this.isLoaded = true;
  },
  methods: {
    /** ルールを追加 */
    async addPolicy() {
      const addPolicy: Type4IFWPolicy = await this.$modal.show(
        InternetType4PolicyRuleIfwEdit,
        {
          type4Id: this.type4Id,
          from: this.from,
          type4Policy: this.latestType4Policy,
        }
      );
      const last = this.table.items
        .map((e) => e.policyCategoryInfo)
        .lastIndexOf("UNEDITABLE_DEFAULT_POLICY");
      this.table.items.splice(
        // 最後 or 編集不可の一つ前
        last === -1 ? this.table.items.length : last,
        0,
        addPolicy
      );
    },

    /** ポリシー編集 */
    async editPolicy(policy: Type4IFWPolicy, index: number) {
      if (policy.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY") {
        return;
      }
      const editPolicy: Type4IFWPolicy = await this.$modal.show(
        InternetType4PolicyRuleIfwEdit,
        {
          type4Id: this.type4Id,
          from: this.from,
          policy,
          type4Policy: this.type4Policy,
        }
      );
      this.$set(this.table.items, index, editPolicy);
    },

    /** ポリシー削除 */
    async deletePolicy(index: number) {
      this.table.items.splice(index, 1);
    },

    /** ポリシー編集完了 */
    async submit() {
      await this.$confirm(`ポリシーを保存します。よろしいですか？`);
      (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(
        this.table.items
      );
    },

    /** ポリシーテーブルのtrクラス. */
    policyRowClass(
      item: Pick<GetPolicy, "policyCategoryInfo">
    ): string[] | null {
      switch (item.policyCategoryInfo) {
        case "CUSTOM_POLICY":
          // ドラッグ可能
          return ["draggable"];
        case "EDITABLE_DEFAULT_POLICY":
          // ドラッグ可能
          return ["draggable"];
        case "UNEDITABLE_DEFAULT_POLICY":
          // ドラッグ不可 + 背景色グレーアウト
          return ["not-editable-policy", "un-clickable"];
      }
    },
  },
});
























import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "SaIdRegisterHistoryReference",
  props: {
    /** ID登録エラー詳細 */
    errorDetails: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});

















































































































import Vue, { PropType } from "vue";
import { CloudLine, CloudMsPut, Line } from "@/apis/CloudLineApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { maxBandwidthOptions } from "@/views/multicloud/MultiCloudList.vue";
import cloneDeep from "lodash/cloneDeep";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";

export default Vue.extend({
  name: "MultiCloudBandwidthLimitModify",
  props: {
    /** 回線番号 */
    enumber: {
      type: String as PropType<string>,
      required: true,
    },
    /** 回線帯域 */
    bandwidth: {
      type: Object as PropType<Line["bandwidth"]>,
      required: true,
    },
    /** 取得したクラウド回線情報全件 */
    cloudLineList: {
      type: Array as PropType<CloudLine[]>,
      required: true,
    },
    /** クラウド回線排他情報 */
    cloudLineKeyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "cloudServiceMenu", label: "メニュー" },
        {
          key: "bandwidth",
          label: "帯域上限値",
        },
        {
          key: "description",
          label: "備考",
        },
      ] as BvTableFieldArray,
      cloudLineItems: [] as CloudLine[],
      items: [] as CloudLine[],
      /** クラウド帯域値のデータマップ */
      cloudUpperLimitMap: {} as Record<string, UpperLimitEntity | undefined>,
      isLoaded: false,
    };
  },
  async mounted() {
    this.items = this.cloudLineList.map((e) => ({
      ...e,
      bandwidth:
        e.bandwidth.value === 0 ? { value: 0, unit: null } : e.bandwidth,
    }));
    this.cloudLineItems = cloneDeep(this.items);

    // クラウド帯域値を取得する
    const promises = this.cloudLineItems.map(async (e) => {
      const limit = await this.$api.upperLimit.getUpperLimitLine({
        upperLimitLineManageUnit: "MULTI_CLOUD",
        enumber: this.enumber,
        cloudLineSeq: e.cloudLineSeq,
      });
      this.cloudUpperLimitMap[e.cloudLineSeq] =
        limit.cloudLine!.upperLimitList!.find(
          (v) => v.upperLimitItemName === "クラウド帯域値"
        );
    });
    await Promise.all(promises);
    this.isLoaded = true;
  },

  computed: {
    cloudLineChanged(): boolean {
      return this.$crossValidation.cloudLineChanged(
        this.cloudLineItems,
        this.items
      );
    },
    cloudLineNotChangedMoreThanOne(): boolean {
      return this.$crossValidation.cloudLineNotChangedMoreThanOne(
        this.cloudLineItems,
        this.items
      );
    },
    modifyList(): CloudLine[] {
      return this.items.filter((v, index) => {
        const newBandwidth: string =
          v.bandwidth.value.toString() + v.bandwidth.unit;
        const oldBandwidth: string =
          this.cloudLineItems[index].bandwidth.value.toString() +
          this.cloudLineItems[index].bandwidth.unit;
        return (
          v.description !== this.cloudLineItems[index].description ||
          newBandwidth !== oldBandwidth
        );
      });
    },
    /** 残りの設定可能帯域 */
    remainingBandwidth(): { value: number; unit: "MBPS" | "GBPS" } {
      const limitBandwidthMbps =
        (this.$filter.bandwidthToBps(this.bandwidth) ?? 0) / 1000 / 1000;
      const sumBandwidthMbps = this.items.reduce(
        (acc, e) =>
          acc + (this.$filter.bandwidthToBps(e.bandwidth) ?? 0) / 1000 / 1000,
        0
      );
      const diffBandwidthMbps = limitBandwidthMbps - sumBandwidthMbps;
      if (diffBandwidthMbps >= 1000 || diffBandwidthMbps <= -1000) {
        return { value: diffBandwidthMbps / 1000, unit: "GBPS" };
      } else {
        return { value: diffBandwidthMbps, unit: "MBPS" };
      }
    },
  },
  methods: {
    bandwidthSelectList(item: CloudLine) {
      return maxBandwidthOptions.filter((v) => {
        const bps = this.$filter.bandwidthToBps(v.value)!;
        const bandwidthBps = this.$filter.bandwidthToBps(this.bandwidth)!;
        // クラウド帯域値が存在する場合、上限値を取得する、存在しない場合、現在は50Gbpsまで設定できる
        const cloudLineMaxBandwidthBps = this.$filter.bandwidthToBps({
          value: this.cloudUpperLimitMap[item.cloudLineSeq]?.upperLimit
            ? this.cloudUpperLimitMap[item.cloudLineSeq]!.upperLimit
            : 50,
          unit: "GBPS",
        });
        // MSの場合、<=10Gとする
        if (item.cloudServiceMenu.startsWith("MS")) {
          return (
            bps <= bandwidthBps &&
            bps <= cloudLineMaxBandwidthBps! &&
            bps <= 10 * 1000 * 1000 * 1000
          );
        } else {
          // MS以外の場合
          return bps <= bandwidthBps && bps <= cloudLineMaxBandwidthBps!;
        }
      });
    },
    async submit() {
      await this.$confirm(`帯域上限値を設定します。よろしいですか？`);
      const putData: CloudMsPut = {
        cloudLineKeyId: this.cloudLineKeyId,
        bandwidth: this.modifyList[0].bandwidth,
        description: this.modifyList[0].description,
        note: null,
      };
      await this.$api.cloudLine.putMsCloudLine(
        this.modifyList[0].cloudLineSeq,
        putData
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 * INTERNET:インターネットゾーン
 * EXTRA:エクストラゾーン
 * PRIVATE:プライベートゾーン
 */
export type ZoneType = "INTERNET" | "EXTRA" | "PRIVATE";

export interface ZoneRelationItem {
  /** ゾーン関係ID. 主キー */
  zoneRelationId: string;
  /** 接続元ゾーンID. ゾーンオブジェクトの主キー "obj"+"数値"の形式 */
  srcZoneId: string;
  /** 接続元ゾーン名. 対象ゾーン：インターネット/エクストラ/プライベート */
  srcZoneName: string;
  /** 接続元ゾーン種別. INTERNET：インターネットゾーン EXTRA：エクストラゾーン PRIVATE：プライベートゾーン */
  srcZoneType: ZoneType;
  /** 接続先ゾーンID. ゾーンオブジェクトの主キー "obj"+"数値"の形式 */
  dstZoneId: string;
  /** 接続先ゾーン名. 対象ゾーン：インターネット/エクストラ/プライベート*/
  dstZoneName: string;
  /** 接続先ゾーン種別. INTERNET：インターネットゾーン EXTRA：エクストラゾーン PRIVATE：プライベートゾーン */
  dstZoneType: ZoneType;
}

/** ゾーンリレーション情報一覧 */
export interface ZoneRelation {
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  /** ゾーン関係情報のリスト. 0件の場合は空配列 */
  zoneRelationList: ZoneRelationItem[];
  /** ゾーン関係設定排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  zoneRelationKeyId: string;
}

/** ゾーンリレーションの追加 */
export interface ZoneRelationPost {
  /**
   * 接続元ゾーンID
   * ゾーンオブジェクトの主キー "obj"+"数値"の形式
   * Type1インターネットサイトゾーン取得API, Type1エクストラサイトゾーン取得API, Type1プライベートサイトゾーン取得APIから取得
   */
  srcZoneId: string;
  /**
   * 接続先ゾーンID
   * ゾーンオブジェクトの主キー "obj"+"数値"の形式
   * Type1インターネットサイトゾーン取得API, Type1エクストラサイトゾーン取得API, Type1プライベートサイトゾーン取得APIから取得
   */
  dstZoneId: string;
  /**
   * ゾーン関係設定排他情報
   * 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   * */
  zoneRelationKeyId: string;
}

export interface ZoneRelationDelete {
  /** ゾーン関係設定排他情報. 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  zoneRelationKeyId: string;
}

export default class ZoneRelationApi extends AbstractBaseApi {
  /** ゾーン関係情報の一覧を取得 */
  async getZoneRelation(): Promise<ZoneRelation> {
    return (await this.api.get("/v1/network/zone/type1/relations")).data;
  }

  /**
   *  ゾーン関係情報の追加
   * @param postData 追加するゾーン情報
   */
  async postZoneRelation(postData: ZoneRelationPost): Promise<void> {
    await this.api.post("/v1/network/zone/type1/relation", postData);
  }

  /**
   *  ゾーン関係情報の削除
   * @param zoneRelationId ゾーンリリーションID
   * @param data 削除情報(排他制御キー)
   */
  async deleteZoneRelation(
    zoneRelationId: string,
    data: ZoneRelationDelete
  ): Promise<void> {
    await this.api.post(
      `/v1/network/zone/type1/relation/${zoneRelationId}/delete`,
      data
    );
  }
}






import Vue, { PropType } from "vue";
import { BFormInput } from "bootstrap-vue";

/**
 * 入力文字数が0になった場合に空文字ではなくnullをemitするb-form-inputのラッパーコンポーネント.
 * その他の機能はb-form-inputと変わらない
 * @see https://bootstrap-vue.org/docs/components/form-input
 */
export default Vue.extend({
  name: "AppBFormInput",
  components: { BFormInput },
  props: {
    value: {
      type: [String, Number] as PropType<string | number>,
      required: false,
    },
  },
  computed: {
    /** v-model系のリスナーを除去したリスナー（二重にv-modelすることになるので） */
    listeners(): Record<string, unknown> {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { input, blur, ...others } = this.$listeners;
      return others;
    },
    modelValue: {
      get(): unknown {
        return this.value;
      },
      set(newValue: string) {
        if (newValue) {
          this.$emit("input", newValue);
        } else {
          this.$emit("input", null);
        }
      },
    },
  },
});

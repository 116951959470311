import InformationApi, { ProvisioningStatus } from "@/apis/InformationApi";
import { Module } from "vuex";
import { RootState } from "@/store/index";
import cloneDeep from "lodash/cloneDeep";
import { isShowProvisioningStatus } from "@/store/provisioning-store";

const provisioningQosState = {
  data: {
    /**
     * プロビジョニングステータスのステータスコード.
     * nullの場合は未取得
     */
    messageCode: null as null | ProvisioningStatus["messageCode"],
    /**
     * プロビジョニングステータスのメッセージ.
     * nullの場合は未取得
     */
    message: null as null | string,
    /**
     * プロビジョニングステータスコンポーネント現在の表示数.
     * 0 → 1の場合は初回としてステータスの取得を行う必要があるためその管理用
     */
    count: 0,
    /** 自動再取得処理のpooling */
    pooling: null as null | number,
    /**
     * true: プロビジョニング完了時に更新通知をする, false: 更新通知しない
     * 特殊な事情で更新通知を表示させたくない場合用(QOS、CLOUD_QOSは初期値をfalseにする)
     */
    enabledRefresh: false,
  },
  /** プロビジョニングステータスを取得する用 */
  getProvisioningStatus: {} as InstanceType<
    typeof InformationApi
  >["getProvisioningStatus"],
  /** プロビ完了後の実行イベント */
  completedEvent: [] as (() => unknown)[],
};

export type ProvisioningQosState = typeof provisioningQosState;

/**
 * QOS、CLOUD_QOSを単位でプロビジョニングステータスを管理する.
 */
const provisioningQosStates = {
  data: {
    QOS: cloneDeep(provisioningQosState),
    CLOUD_QOS: cloneDeep(provisioningQosState),
  } as Record<string, ProvisioningQosState>,
};

export type ProvisioningQosStates = typeof provisioningQosStates;

export type MountQosData = {
  getProvisioningStatus: ProvisioningQosState["getProvisioningStatus"];
  qosType: "QOS" | "CLOUD_QOS";
};

export const provisioningQosStoreModule: Module<
  ProvisioningQosStates,
  RootState
> = {
  namespaced: true,
  state: provisioningQosStates,
  mutations: {
    mount(state, payload: MountQosData) {
      state.data[payload.qosType].data.count += 1;
      state.data[payload.qosType].getProvisioningStatus =
        payload.getProvisioningStatus;
    },
    unmount(state, qosType: string) {
      state.data[qosType].data.count -= 1;
    },
    updateStatus(
      state,
      payload: { status: ProvisioningStatus; qosType: string }
    ) {
      state.data[payload.qosType].data.messageCode = payload.status.messageCode;
      state.data[payload.qosType].data.message = payload.status.message;
    },
    setPooling(state, payload: { pooling: null | number; qosType: string }) {
      state.data[payload.qosType].data.pooling = payload.pooling;
    },
    clearCompletedEvent(state, qosType: string) {
      state.data[qosType].completedEvent = [];
    },
  },
  actions: {
    /** プロビジョニングステータスコンポーネント表示時 */
    async mount({ state, commit, dispatch }, data: MountQosData) {
      const isFirst = state.data[data.qosType].data.count === 0;
      commit("mount", data);
      // 初回のステータス表示の場合はデータを取得
      if (isFirst) {
        dispatch("updateStatus", data.qosType);
      }
    },
    /** プロビジョニングステータスコンポーネント破棄時 */
    unmount({ state, commit }, payload: { qosType: string }) {
      commit("unmount", payload.qosType);
      // ポーリングを停止
      if (state.data[payload.qosType].data.pooling !== null) {
        clearInterval(state.data[payload.qosType].data.pooling!);
        commit("setPooling", { pooling: null, qosType: payload.qosType });
      }
    },
    /** 最新のステータスを取得. ステータス表示の場合は自動更新処理を開始, 非表示は停止 */
    async updateStatus({ state, commit, dispatch }, qosType: string) {
      // コンポーネントが存在する場合はプロビジョニングステータスを再取得
      if (state.data[qosType].data.count > 0) {
        const status = await state.data[qosType].getProvisioningStatus();
        if (
          isShowProvisioningStatus(state.data[qosType].data.messageCode) &&
          !isShowProvisioningStatus(status.messageCode)
        ) {
          // 表示 → 非表示に切り替わった場合は予約されたイベント実行
          state.data[qosType].completedEvent.forEach((f) => f());
          commit("clearCompletedEvent", qosType);
        }

        commit("updateStatus", { status: status, qosType: qosType });

        if (isShowProvisioningStatus(status.messageCode)) {
          // 自動更新処理を開始
          if (state.data[qosType].data.pooling !== null) {
            clearInterval(state.data[qosType].data.pooling!);
            commit("setPooling", { pooling: null, qosType: qosType });
          }
          const pooling = setInterval(
            () => dispatch("updateStatus", qosType),
            10000
          );
          commit("setPooling", { pooling: pooling, qosType: qosType });
        } else if (state.data[qosType].data.pooling !== null) {
          // 自動更新処理を停止
          clearInterval(state.data[qosType].data.pooling!);
          commit("setPooling", { pooling: null, qosType: qosType });
        }
      }
    },
  },
};

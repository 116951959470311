import moment from "moment";
import { Vue as _Vue } from "vue/types/vue";
import { PluginObject } from "vue/types/plugin";

const MomentPlugin: PluginObject<undefined> = {
  install(Vue: typeof _Vue): void {
    moment.defaultFormat = "YYYY/MM/DD HH:mm";
    Vue.prototype.$moment = moment;
  },
};

export default MomentPlugin;

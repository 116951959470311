





































































import Vue, { PropType } from "vue";
import {
  ApplianceType,
  DisplayRange,
  LineElement,
  LineType,
  TrafficResponse,
} from "@/apis/TrafficApi";
import cloneDeep from "lodash/cloneDeep";
import { ThresholdInfoGetEntity } from "@/apis/ThresholdSettingApi";
import {
  createThresholdInfoParams,
  createTrafficInfoParams,
} from "@/views/trafficSessionStatistics/TrafficSessionStatistics.vue";
import TrafficSessionStatisticsGraph, {
  trafficOption,
} from "@/views/trafficSessionStatistics/TrafficSessionStatisticsGraph.vue";
import moment from "moment";

export default Vue.extend({
  name: "Traffic",
  props: {
    /** 回線種別 */
    lineType: {
      type: String as PropType<LineType>,
      required: true,
    },
    /** 回線対象
     * ACCESSの場合、enumberMainActを指定
     * VNの場合、wnumberMainAct、vpnVnCodeを指定
     * VNL2L3の場合、wnumberMainAct、enumberMainAct、l2VnCode、l3VpnVnCodeを指定
     * MULTICLOUDの場合、wnumberMainAct、wnumberMainSbyを指定*/
    line: {
      type: Object as PropType<{
        enumberMainAct?: string;
        wnumberMainAct?: string;
        wnumberMainSby?: string;
        vpnVnCode?: string;
        l2VnCode?: string;
        l3VpnVnCode?: string;
      }>,
      required: true,
    },
  },
  components: { TrafficSessionStatisticsGraph },
  data() {
    /** 期間の制限（現在時刻より15分前） */
    const befroeMinutes = 15;
    const currentTime = moment().toISOString();
    const form = {
      /** 表示範囲 */
      displayRange: "DAILY" as DisplayRange,
      /** 期間（形式: YYYY-MM-DD or YYYY-MM-DDTHH:mm） */
      period: moment(currentTime)
        .add(-befroeMinutes, "minutes")
        .format("YYYY-MM-DDTHH:mm"),
    };
    return {
      form,
      /** グラフコンポーネントへ受け渡し用（表示ボタンを押した場合のみ変化） */
      freezeForm: {} as typeof form,
      befroeMinutes,
      currentTime,
      /** 回線毎のトラフィック情報、閾値超過情報 */
      graphs: null as
        | null
        | {
            line: LineElement;
            traffic: TrafficResponse;
            thresholdInfo: ThresholdInfoGetEntity;
          }[],

      targetLine: [] as LineElement[],
      isLoaded: false,
      /** 表示する受信トラフィックログ情報の選択値リスト */
      selectedReceiveGraph: [] as trafficOption[],
      /** 表示する送信トラフィックログ情報の選択値リスト */
      selectedSendGraph: [] as trafficOption[],
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    /** APIで使用できる期間情報 */
    periodDateTime(): { periodDate: string; periodTime: null | string } | null {
      if (this.form.period) {
        const period = this.$moment(this.form.period);
        if (this.form.displayRange === "DAILY") {
          return {
            periodDate: period.format("YYYY-MM-DD"),
            periodTime: period.format("HH:mm"),
          };
        } else {
          return { periodDate: period.format("YYYY-MM-DD"), periodTime: null };
        }
      } else {
        return null;
      }
    },
    /** マルチクラウド系のトラフィックタブには、Legend表示用のラベルを生成 */
    shortLegend(): { wnumber: string; label: string }[] | undefined {
      if (this.lineType === "MULTICLOUD") {
        return this.line.wnumberMainSby
          ? [
              {
                wnumber: this.line.wnumberMainAct!,
                label: "ACT:" + this.line.wnumberMainAct,
              },
              {
                wnumber: this.line.wnumberMainSby!,
                label: "SBY:" + this.line.wnumberMainSby,
              },
            ]
          : [
              {
                wnumber: this.line.wnumberMainAct!,
                label: "ACT:" + this.line.wnumberMainAct,
              },
            ];
      } else {
        return undefined;
      }
    },
  },
  watch: {
    /** 表示範囲が変更された場合は期間、統計情報取得を初期化 */
    "form.displayRange"(value) {
      switch (value) {
        case "DAILY":
          this.form.period = this.$moment(this.currentTime)
            .add(-this.befroeMinutes, "minutes")
            .format("YYYY-MM-DDTHH:mm");
          return;
        case "WEEKLY":
        case "MONTHLY":
          this.form.period = this.$moment(this.currentTime)
            .add(-this.befroeMinutes, "minutes")
            .format("YYYY-MM-DD");
          return;
        default:
          this.form.period = "";
      }
    },
  },
  methods: {
    async load() {
      /** 回線選択情報取得 */
      switch (this.lineType) {
        case "ACCESS":
          this.targetLine = [
            { enumberMainAct: this.line.enumberMainAct! } as LineElement,
          ];
          break;
        case "VN":
          this.targetLine = [
            {
              wnumberMainAct: this.line.wnumberMainAct!,
              vpnVnCode: this.line.vpnVnCode,
            } as LineElement,
          ];
          break;
        case "VNL2L3":
          this.targetLine = [
            {
              wnumberMainAct: this.line.wnumberMainAct!,
              enumberMainAct: this.line.enumberMainAct,
              l2VnCode: this.line.l2VnCode,
              l3VpnVnCode: this.line.l3VpnVnCode,
            } as LineElement,
          ];
          break;
        case "MULTICLOUD":
          this.targetLine = [
            { wnumberMainAct: this.line.wnumberMainAct } as LineElement,
          ];
          if (this.line.wnumberMainSby) {
            this.targetLine = [
              ...this.targetLine,
              { wnumberMainAct: this.line.wnumberMainSby } as LineElement,
            ];
          }
      }
    },
    /** グラフ表示 */
    async showGraphs() {
      this.graphs = null;
      this.freezeForm = cloneDeep(this.form);

      this.graphs = await Promise.all(
        this.targetLine.map(async (line) => {
          return {
            line: line,
            traffic: await this.getTraffic(line),
            thresholdInfo: await this.getThresholdInfo(line),
          };
        })
      );
    },
    /** トラフィックログ情報取得 */
    getTraffic(line: LineElement): Promise<TrafficResponse> {
      return this.$api.traffic.getTraffic(
        createTrafficInfoParams(
          this.lineType,
          line as LineElement & {
            applianceType: Exclude<ApplianceType, "NAT"> | null;
          },
          this.freezeForm.displayRange,
          this.periodDateTime
        )
      );
    },
    /** 閾値超過情報取得 */
    getThresholdInfo(line: LineElement): Promise<ThresholdInfoGetEntity> {
      return this.$api.thresholdSetting.getThresholdInfo(
        createThresholdInfoParams(
          this.lineType,
          line as LineElement & {
            applianceType: Exclude<ApplianceType, "NAT"> | null;
          }
        )
      );
    },
  },
});

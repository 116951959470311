









































































import Vue from "vue";
import {
  ApiConflictError,
  ApiServiceUnavailableError,
  ApiUnauthorizedError,
  NetworkError,
} from "@/error/errors";
import PasswordExpiredAlert from "@/modals/common/PasswordExpiredAlert.vue";
import AgreeStatusChangeConfirm from "@/modals/agreement/AgreeStatusChangeConfirm.vue";

export default Vue.extend({
  name: "Login",
  data() {
    return {
      showAlert: false,
      form: {
        loginId: "",
        password: "",
      },
      isLoaded: false,
    };
  },
  async mounted() {
    try {
      await this.$api.healthCheck.healthCheck();
    } catch (e) {
      // 407,503の場合は全体のエラーに任せる、それ以外はシステムエラー
      if (
        e instanceof ApiServiceUnavailableError ||
        e instanceof NetworkError
      ) {
        throw e;
      } else {
        console.error(e);
        await this.$router.push({ path: "/system/error" });
        return;
      }
    }
    this.isLoaded = true;
  },
  methods: {
    async submit(isForceLogin = false) {
      try {
        const result = await this.$api.auth.login({
          ...this.form,
          isForceLogin,
        });

        await this.$store.dispatch("user/login", result);
        await this.$router.push({ path: "/" });
        if (result.passwordLimit >= 1 && result.passwordLimit <= 14) {
          await this.$modal.show(PasswordExpiredAlert, { login: result });
        }
        await this.agreeStatusChange();
      } catch (e) {
        if (e instanceof ApiUnauthorizedError) {
          if (e.data.errorCode === "CC01004W") {
            await this.$router.push(
              `/password/one-time-url?expired=true&loginId=${this.form.loginId}`
            );
            return;
          }
        } else if (e instanceof ApiConflictError) {
          if (e.data.errorCode === "A111001W") {
            await this.$confirm(
              "同一アカウントによるログインセッションが残っています。\n" +
                "現在残っているセッションを切断し、新しくログインしますか？",
              "強制ログイン確認"
            );
            await this.submit(true);
            return;
          }
        }
        throw e;
      }
    },
    /**
     * 同意履歴の掲載開始日が最新のレコードの状態が「未同意」の場合
     * 「同意/同意詳細」画面を表示する
     * */
    async agreeStatusChange() {
      const agreement = await this.$api.agreement.getAll();
      // この時点ではstoreが使えないので取得する。
      const loginUser = await this.$api.information.getLoginUser();
      // 同意履歴が0件以外で且つ法人管理者権限の場合のみ表示対象
      if (
        agreement.agreementList.length !== 0 &&
        loginUser.roleCode === "C0001"
      ) {
        const agreeFirst = agreement.agreementList.sortBy([
          "postStartDate",
          "desc",
        ])[0];
        if (agreeFirst.agreementStatus === null) {
          const agreementText = (
            await this.$api.agreement.getAgreementDetail(
              agreeFirst.agreementSeq
            )
          ).agreementText;
          await this.$modal.show(AgreeStatusChangeConfirm, {
            agreementItem: agreeFirst,
            agreementKeyId: agreement.agreementKeyId,
            agreementText: agreementText,
          });
        }
      }
    },
  },
});

import { AxiosResponse } from "axios";

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 * Microsoftサービス利用申込一覧のAPI用インターフェイス
 */

export interface MSServiceGetEntity {
  total: number;
  // Microsoftサービス一覧
  msPeeringList: MSPeeringListEntity[];
  /** キーID */
  msPeeringKeyId: string;
}

export interface MSPeeringListEntity {
  /** Microsoft サービス利用申込SEQ */
  msServiceSeq: string;
  /** VPN/VNコード */
  vpnVnCode: string;
  /** VN名 */
  vnName: string | null;
  /** Microsoft Peering */
  msPeering: PeeringLimitEntity | null;
}

export interface PeeringLimitEntity {
  /** NATアドレス上限 */
  upperLimitNapt: number;
  /** NAPTアドレス上限 */
  upperLimitNat: number;
}

/**
 * Microsoftサービス設定（追加）のAPI用インターフェイス
 */
export interface MSServicePostEntity {
  /** VPN/VNコード */
  vpnVnCode: string;
  /** Microsoft Peering */
  msPeering: PeeringLimitEntity | null;
  /** オーダー備考 */
  note?: string;
  /** キーID */
  msPeeringKeyId: string;
}

/**
 * Microsoftサービス設定（変更）のAPI用インターフェイス
 */
export interface MSServiceUpdateEntity {
  /** Microsoft Peering */
  msPeering: PeeringLimitEntity | null;
  /** オーダー備考*/
  note?: string;
  /** キーID */
  msPeeringKeyId: string;
}

/**
 * Microsoftサービス設定削除のAPI用インターフェイス
 */
export interface MSServiceDeleteEntity {
  /** MicrosoftサービスSEQ. 主キー */
  msServiceSeq: string;
  /** 操作履歴の備考 */
  note?: string;
  /** キーID */
  msPeeringKeyId: string;
}

export default class MsPeeringApi extends AbstractBaseApi {
  /**
   * Microsoft Peering設定情報の一覧を取得
   */
  async getAll(): Promise<MSServiceGetEntity> {
    return (await this.api.get("v1/contract/service/cloud/ms-peerings")).data;
  }

  /**
   * Microsoft Peering設定情報を追加
   * @param data リクエストデータ
   */
  async post(data: MSServicePostEntity): Promise<AxiosResponse> {
    return (await this.api.post("v1/contract/service/cloud/ms-peering", data))
      .data;
  }

  /**
   * Microsoft Peering設定情報を変更
   * @param msServiceSeq パスパラメータ
   * @param data リクエストデータ
   */
  async update(
    msServiceSeq: string,
    data: MSServiceUpdateEntity
  ): Promise<AxiosResponse> {
    return (
      await this.api.post(
        `v1/contract/service/cloud/ms-peering/${msServiceSeq}`,
        data
      )
    ).data;
  }

  /**
   * Microsoft Peering設定情報を削除
   * @param data リクエストデータ
   */
  async delete(data: MSServiceDeleteEntity): Promise<AxiosResponse> {
    return (
      await this.api.post("v1/contract/service/cloud/ms-peerings/delete", data)
    ).data;
  }
}

import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/** 入力値がCIDR形式になっているか検証
 * ----------------------------------------
 * チェック内容
 * 「IPV6アドレス/サブネット」の形式であることかどうか
 * サブネットマスクは指定範囲内(値)であるかどうか
 * @param value 入力値
 * @param min サブネットマスクの最小値
 * @param max サブネットマスクの最大値
 * @param exclude 除外アドレス
 * @param eq サブネットマスクの固定値
 */
export default {
  params: ["min", "max", "exclude", "eq"],
  validate: function (
    value: string | null | undefined,
    {
      min,
      max,
      exclude,
      eq,
    }: {
      min: number | null;
      max: number | null;
      exclude: number[] | null;
      eq: number | null;
    }
  ): boolean {
    if (!value) return true;

    const fields = value.split("/")[0].split(":");
    // フィールドが8個より多いまたは、いずれかのフィールドが4桁より多い場合はfalse
    // ipaddr.IPv6.parseCIDRでは、00000や01000等が正常終了になってしまうので抑止する
    if (fields.length > 8 || fields.some((e) => e.length > 4)) {
      return false;
    }

    let cidr: [ipaddr.IPv6, number];
    try {
      cidr = ipaddr.IPv6.parseCIDR(value);
    } catch (e) {
      return false;
    }

    const subnet: number = +cidr[1];
    if (exclude != null && exclude.includes(subnet)) {
      return false;
    }
    if (max && subnet > max) {
      return false;
    }
    if (min && subnet < min) {
      return false;
    }
    if (eq && subnet !== eq) {
      return false;
    }
    return true;
  },
} as ValidationRuleSchema;

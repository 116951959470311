











































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  AuthenticationType,
  ContractType,
  CpaNwDetail,
  CpaNwOptions,
  IpAddressType,
} from "@/apis/Cpa5gSaApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";

export default Vue.extend({
  name: "SaNwEditStepper3",
  props: {
    /** 親コンポーネントで保持してるCPA NW情報 */
    value: {
      type: Object as PropType<CpaNwDetail>,
      required: true,
    },
    /** 契約種別 */
    contractType: {
      type: String as PropType<ContractType>,
      required: true,
    },
    /** 認証種別 */
    authenticationType: {
      type: String as PropType<AuthenticationType>,
      required: true,
    },
    /** IPアドレス契約タイプ */
    ipAddressType: {
      type: String as PropType<IpAddressType>,
      required: true,
    },
    /** CPA特殊項目設定 */
    cpaNwOptions: {
      type: Object as PropType<CpaNwOptions>,
      required: true,
    },
  },
  data() {
    return {
      cpaNwSetting: this.value,
      /** 案件コード検索・追加フォーム入力値 */
      dealCode: "",
      /** 案件コードテーブルの列定義 */
      fields: [
        { key: "dealCode", label: "案件コード" },
        { key: "minus", label: "", tdClass: "text-center" },
      ] as BvTableFieldArray,
    };
  },
  computed: {
    /**
     * 案件コードリスト
     * フォームの入力値で検索して部分一致する案件コードを返す
     * オブジェクトの配列を返却(共通テーブルではオブジェクト配列にしなければならないため)
     */
    dealCodeList(): { dealCode: string; _id: number }[] {
      // フォームに入力値があれば、部分一致で検索
      if (this.dealCode) {
        // 空配列で初期化してるのでnullではない
        return this.cpaNwSetting
          .dealCodeList!.map((v, index) => {
            return { dealCode: v, _id: index };
          })
          .filter((v) => v.dealCode.includesIgnoreCase(this.dealCode));
      } else {
        // 入力無しであれば全件返す
        return this.cpaNwSetting.dealCodeList!.map((v, index) => {
          return { dealCode: v, _id: index };
        });
      }
    },
    /**
     * 案件コードが1件以上登録されているかチェック
     * true: 正常 1件以上あり false: エラー 0件
     */
    isExistDealCode(): boolean {
      return this.cpaNwSetting.dealCodeList!.length > 0;
    },
    /**
     * 案件コードの重複チェック
     * 設定アップロードでアップロードした場合、重複した案件コードを入力できるためチェックを実施
     * true: 重複なし false: 重複あり
     */
    isDuplicated(): boolean {
      if (this.cpaNwSetting.dealCodeList !== null) {
        const setElements = new Set(this.cpaNwSetting.dealCodeList);
        return setElements.size === this.cpaNwSetting.dealCodeList.length;
      } else {
        return true;
      }
    },
  },
  methods: {
    /** 案件コードの追加 */
    addDealCode() {
      // 空配列で初期化してるのでnullではない
      this.cpaNwSetting.dealCodeList!.push(this.dealCode);
      // 一覧データに変動があってもバリデーションが作動しないので、処理側でバリデーションを発動させる
      (this.$refs.provider as Vue & { validate: () => void }).validate();
    },
    /**
     * 案件コードの削除
     * @param code 案件コード
     */
    removeDealCode(code: string) {
      // 空配列で初期化してるのでnullではない
      this.cpaNwSetting.dealCodeList!.splice(
        this.cpaNwSetting.dealCodeList!.indexOf(code),
        1
      );
      // 一覧データに変動があってもバリデーションが作動しないので、処理側でバリデーションを発動させる
      (this.$refs.provider as Vue & { validate: () => void }).validate();
    },
  },
  watch: {
    /** 親からのForm情報更新を反映 */
    value(newValue: CpaNwDetail) {
      this.cpaNwSetting = newValue;
    },
    cpaNwSetting: {
      handler(newValue: CpaNwDetail) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
});

import FileService from "@/services/FileService";
import { unparse, UnparseConfig } from "papaparse";
import { BvTableField, BvTableFormatterCallback } from "bootstrap-vue/esm";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import moment from "moment";
import Encoding from "encoding-japanese";

/** CSVを作成する上で必要なb-tableの列データ */
type FieldList = {
  /** 画面に表示されている項目名 */
  label: string;
  /** 項目名に対応するキー */
  key: string;
  /** データを表示用に変換する関数 */
  formatter?: BvTableFormatterCallback | string;
}[];

/**
 * CSV関連の操作を行うサービス
 * CSVへの変換などは以下のライブラリを使用している
 * @see https://www.papaparse.com/
 */
export class CsvService {
  constructor(private readonly file: FileService) {}
  /**
   * 一覧データのCSVダウンロード
   * オブジェクトからCSVへの変換は以下を使用
   * @see https://www.papaparse.com/docs#json-to-csv
   * @param fileName ファイル名
   * @param data CSVのデータ部
   * @param config オブジェクトからCSVに変換する時の設定
   * @param fields CSVのヘッダー部(任意)
   */
  downloadCsv<T>(
    fileName: string,
    data: T[] | string[][],
    config: UnparseConfig = { newline: "\r\n" },
    fields?: string[]
  ): void {
    let csvText: string;
    if (fields) {
      csvText = unparse({ fields, data }, config);
    } else {
      csvText = unparse(data, config);
    }
    const con = Encoding.convert(csvText, {
      to: "SJIS",
      from: Encoding.detect(csvText) || "AUTO",
      type: "arraybuffer",
    });

    const csv = new Uint8Array(con);
    this.file.download(fileName, csv, "text/csv");
  }

  /**
   * b-tableの列データとレコードからcsvをダウンロードする
   * @param fileName ファイル名
   * @param data APIから取得したデータ
   * @param fields CSVを作成する上で必要なb-tableの列データ
   */
  appTableCsvDownLoad<T>(fileName: string, data: T[], fields: FieldList): void {
    const csvDataList: Record<string, unknown>[] = [];
    data.map((v) => {
      const csvData: typeof csvDataList[0] = {};
      fields.map((field) => {
        if (field.formatter) {
          csvData[field.label] =
            typeof field.formatter === "function"
              ? field.formatter(v[field.key as keyof T], field.key, v)
              : "";
        } else {
          csvData[field.label] = v[field.key as keyof T];
        }
      });
      csvDataList.push(csvData);
    });

    const _fileName = `${fileName}_${moment().format("YYYYMMDDHHmmss")}`;
    if (csvDataList.length !== 0) {
      this.downloadCsv(_fileName, csvDataList, { newline: "\r\n" });
    } else {
      // APIから取得したデータが0件の場合は、項目名のみダウンロード
      this.downloadCsv(
        _fileName,
        csvDataList,
        { newline: "\r\n" },
        this.genFieldNameOnly(fields)
      );
    }
  }

  /**
   * CSVデータ作成で必要なb-tableの列データを作成する
   * @param fields テーブルの列データ
   */
  genFieldList(fields: BvTableFieldArray): FieldList {
    return (fields as (BvTableField & { key: string })[]).map((v) => {
      return { label: v.label ?? "", key: v.key, formatter: v.formatter };
    });
  }

  /**
   * テーブルの列データから項目名のみ返却する
   * @param fields テーブルの列データ
   */
  genFieldNameOnly(fields: BvTableFieldArray): string[] {
    return (fields as (BvTableField & { key: string })[]).map((v) => {
      return v.label ?? "";
    });
  }
}

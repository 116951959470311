var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-title',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"flex-fill"},[_vm._v("通知連絡先登録")])])]),_c('b-card-body',[_c('div',{staticClass:"content"},[_c('cross-validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('b-card',[_c('b-card-body',{staticClass:"card-padding"},[_c('span',[_c('b-form-group',{attrs:{"label":"V番号"}},[_c('div',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.contractSummary.vnumber))])])],1),_c('b-form-group',{attrs:{"label":"お知らせ通知受信"}},[_c('b-form-radio-group',{attrs:{"options":_vm.modeOptions},model:{value:(_vm.form.receivesNotifications),callback:function ($$v) {_vm.$set(_vm.form, "receivesNotifications", $$v)},expression:"form.receivesNotifications"}})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"お知らせ受信種別","isRequired":_vm.form.receivesNotifications,"isInfo":false}})]},proxy:true}],null,true)},[_c('div',{staticClass:"form-check-inline"},[_c('b-form-checkbox',{staticClass:"col-auto",model:{value:(_vm.form.receivingType.receivesNotice),callback:function ($$v) {_vm.$set(_vm.form.receivingType, "receivesNotice", $$v)},expression:"form.receivingType.receivesNotice"}},[_vm._v("その他お知らせ")])],1)]),_c('cross-validation-provider',{attrs:{"passed":_vm.validReceivingTypeRequired,"vid":"receivingTypeRequired"}},[_vm._v(" お知らせ通知受信が「ON」の場合、お知らせ受信種別の「その他お知らせ」にチェックをして下さい。 ")]),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"通知先メールアドレス","msg":_vm._f("msg")('info_mail')}})]},proxy:true}],null,true)},[_vm._l((_vm.form.notificationMailList),function(_,index){return _c('div',{key:index},[_c('validation-provider',{staticClass:"row align-items-center mb-2",attrs:{"vid":("mail_" + index),"name":"通知先メールアドレス","rules":{
                        required: true,
                        is_email: true,
                        max: 127,
                        duplicate: { list: _vm.emailList(index) },
                      }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('div',{staticClass:"col mr-6"},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.form.notificationMailList[index]),callback:function ($$v) {_vm.$set(_vm.form.notificationMailList, index, $$v)},expression:"form.notificationMailList[index]"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1),_c('div',{staticClass:"mr-3 ml-2"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.removeEmail(index)}}},[_c('b-icon-dash')],1)],1)]}}],null,true)})],1)}),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary","disabled":_vm.form.notificationMailList.length >= 10},on:{"click":_vm.addEmail}},[_c('b-icon-plus')],1)],1)])],2)],1)],1),_c('b-card',{staticClass:"card-style-transparency"},[_c('b-card-body',{staticClass:"button-content"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("設定")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("キャンセル ")])],1)],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }





























































































































































































import Vue, { PropType } from "vue";
import { SaaSListEntity } from "@/views/saas/SaaSServiceApplicationList.vue";

export interface VpnVnCodeEntity {
  vpnVnCode: string;
  vnName: string;
}

type vpnVnCodeOption = {
  vpnVnCodeLabel: { vpnVnCode: string; vnName: string };
  vpnVnCodeValues: VpnVnCodeEntity[];
};

export default Vue.extend({
  name: "SaaSServiceUsageEdit",
  props: {
    /**
     * SaaS利用設定情報.
     */
    saas: {
      type: Object as PropType<SaaSListEntity>,
      required: false,
    },
    /** 設定追加・変更時、使用する排他キー */
    saasKeyId: {
      type: String as PropType<string>,
      required: true,
    },
    /** 一覧に存在しているVpnVnCodeリスト */
    existVpnVnCodeList: {
      type: Array as PropType<string[]>,
      required: false,
    },
  },
  data() {
    return {
      form: {
        connectDest: null as null | VpnVnCodeEntity,
        hasMSPeering: false,
        msUpperLimitNapt: 1,
        msUpperLimitNat: 0,
        hasSfdc: false,
        sfdcUpperLimitNapt: 1,
      },
      vpnVnCodeList: [] as VpnVnCodeEntity[],
      usageOptions: [
        { value: false, text: "利用しない" },
        { value: true, text: "利用する" },
      ],
    };
  },
  computed: {
    // VPN/VNコード : 検索付きプールダウンのタイトルと選択肢表示
    vpnVnCodeOptions(): vpnVnCodeOption[] {
      return this.vpnVnCodeList.length !== 0
        ? [
            {
              vpnVnCodeLabel: {
                vpnVnCode: "VPN/VNコード",
                vnName: "VNネットワーク名",
              },
              vpnVnCodeValues: this.vpnVnCodeList,
            },
          ]
        : [];
    },
  },

  async mounted() {
    /** VPN/VNコードの検索付きプールダウンデータ取得 */
    /**
     * VPNコード設定
     * VN情報取得 : 仮想ネットワーク一覧からL3のみのVNコード・VN名取得
     */
    if (!this.saas) {
      // 追加の場合のみ
      const vpnVn = (await this.$api.information.getVpnVnCodeList()).filter(
        (e) =>
          (e.vnType === "L3" || e.vnType === null) &&
          !this.existVpnVnCodeList?.includes(e.vpnVnCode)
      );
      this.vpnVnCodeList = vpnVn.map((e) => ({
        vpnVnCode: e.vpnVnCode,
        vnName: e.vnName ?? "-",
      }));
    }

    /** Microsoft Peering変更時、既存のデータを表示する表示マッピング */
    if (this.saas?.msPeeringInfo?.msPeering) {
      this.form.hasMSPeering = true;
      this.form.msUpperLimitNapt =
        this.saas.msPeeringInfo.msPeering.upperLimitNapt;
      this.form.msUpperLimitNat =
        this.saas.msPeeringInfo.msPeering.upperLimitNat;
    }

    /** Salesforce変更時、既存のデータを表示する表示マッピング */
    if (this.saas?.sfdcInfo?.sfdc) {
      this.form.hasSfdc = true;
      this.form.sfdcUpperLimitNapt = this.saas.sfdcInfo.sfdc.upperLimitNapt;
    }
  },
  methods: {
    /** VPN/VNコード選択後の表示形式 */
    formatConnectDest({ vpnVnCode, vnName }: VpnVnCodeEntity) {
      return vnName && vnName !== "-"
        ? `${vpnVnCode} : ${vnName}`
        : `${vpnVnCode}`;
    },

    async submit() {
      if (!this.saas) {
        /** 追加確認ダイアログ */
        await this.$confirm("SaaS利用設定を追加します。よろしいですか？");
        /** SaaS利用設定情報を追加API呼び出し処理 */
        await this.$api.saas.post({
          saasCommon: {
            vpnVnCode: this.form.connectDest!.vpnVnCode,
          },
          msPeeringInfo: {
            msServiceSeq: null,
            msPeering: this.form.hasMSPeering
              ? {
                  upperLimitNapt: this.form.msUpperLimitNapt,
                  upperLimitNat: this.form.msUpperLimitNat,
                }
              : null,
          },
          sfdcInfo: {
            sfdcServiceSeq: null,
            sfdc: this.form.hasSfdc
              ? {
                  upperLimitNapt: this.form.sfdcUpperLimitNapt,
                }
              : null,
          },
          saasKeyId: this.saasKeyId,
        });
      } else {
        /** 変更確認ダイアログ */
        await this.$confirm("SaaS利用設定を変更します。よろしいですか？");
        /** SaaS利用設定情報を変更API呼び出し処理 */
        await this.$api.saas.post({
          saasCommon: {
            vpnVnCode: this.saas.saasCommon.vpnVnCode,
          },
          msPeeringInfo: {
            msServiceSeq: this.saas.msPeeringInfo?.msServiceSeq
              ? this.saas.msPeeringInfo.msServiceSeq
              : null,
            msPeering: this.form.hasMSPeering
              ? {
                  upperLimitNapt: this.form.msUpperLimitNapt,
                  upperLimitNat: this.form.msUpperLimitNat,
                }
              : null,
          },
          sfdcInfo: {
            sfdcServiceSeq: this.saas.sfdcInfo?.sfdcServiceSeq
              ? this.saas.sfdcInfo.sfdcServiceSeq
              : null,
            sfdc: this.form.hasSfdc
              ? {
                  upperLimitNapt: this.form.sfdcUpperLimitNapt,
                }
              : null,
          },
          saasKeyId: this.saasKeyId,
        });
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

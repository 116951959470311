import { BvTableLocaleCompareOptions } from "bootstrap-vue/esm/components/table";

export type Order<T> =
  | [keyof T | ((e: T) => unknown), "asc" | "desc"]
  | [
      keyof T | ((e: T) => unknown),
      "asc" | "desc",
      {
        // true: 昇順時にnullが最後、降順時にnullが最初, false: 逆（デフォルト）
        nullLast?: boolean;
      }
    ];

/**
 * Array.prototype.sortで使用できるソート処理.
 * 第1優先、第2優先、のような指定が可能
 * @param a 比較対象A
 * @param b 比較対象B
 * @param orders 昇順/降順指定
 * @param compareOptions String.prototype.localeCompareのoptions
 * @param compareLocale String.prototype.localeCompareのlocale
 */
export default function sortCompare<T>(
  a: T,
  b: T,
  orders: Order<T>[],
  compareOptions: BvTableLocaleCompareOptions = { numeric: true },
  compareLocale?: string | string[]
): number {
  for (const [key, by, opt] of orders) {
    const aVal: unknown = typeof key === "function" ? key(a) : a[key];
    const bVal: unknown = typeof key === "function" ? key(b) : b[key];
    let compare: number;
    if (
      (aVal === null || aVal === undefined) &&
      bVal !== null &&
      bVal !== undefined
    ) {
      // aがnullまたはundefinedでbはnullまたはundefinedではない場合
      compare = opt?.nullLast ? 1 : -1;
    } else if (
      (bVal === null || bVal === undefined) &&
      aVal !== null &&
      aVal !== undefined
    ) {
      // bがnullまたはundefinedでaはnullまたはundefinedではない場合
      compare = opt?.nullLast ? -1 : 1;
    } else {
      // aとbを比較
      compare = `${aVal}`.localeCompare(
        `${bVal}`,
        compareLocale,
        compareOptions
      );
    }
    if (compare !== 0) {
      return compare * (by === "asc" ? 1 : -1);
    }
  }
  return 0;
}

export type SortCompareFunc<T> = (
  a: T,
  b: T,
  key: string,
  sortDesc: boolean
) => number | null;

/**
 * BootstrapVueのTable向けのカスタムソート処理（sort-compare）を提供する.
 * BootstrapVueのカスタムソートは常に昇順ソートが求められる。昇順/降順の最終ソートはテーブル内部で行われるためややこしい
 * @param order1 第1優先. 昇順/降順はテーブルのsort-descで制御するので不要。value値で変換を行いたい場合はオブジェクト形式で指定
 * @param orders 第2優先以降. 昇順/降順まで指定する。第1優先の昇順/降順に影響は受けず常にこの指定した昇順/降順でソートされる
 * @see https://bootstrap-vue.org/docs/components/table#custom-sort-compare-routine
 */
export function tableSortCompare<T>(
  order1:
    | keyof T
    | [keyof T, { nullLast?: boolean }]
    | {
        key: keyof T;
        value: (e: T) => unknown;
        option?: { nullLast?: boolean };
      },
  ...orders: Order<T>[]
): SortCompareFunc<T> {
  return (a: T, b: T, key: string, sortDesc: boolean): number | null => {
    // 第1優先に昇順降順はsort-descでコントロールされるが第2優先もこれに影響を受ける
    // 第1優先のsort-descに影響を受けないように指定された降順昇順を入れ替える
    const customOrders: typeof orders = orders.map(([key, by, opt]) => {
      if (sortDesc) {
        return [key, by === "asc" ? "desc" : "asc", opt] as Order<T>;
      } else {
        return [key, by, opt] as Order<T>;
      }
    });

    if (Array.isArray(order1)) {
      if (order1[0] !== key) {
        return null;
      }
      return sortCompare(a, b, [
        [order1[0], "asc", order1[1]],
        ...customOrders,
      ]);
    } else if (typeof order1 === "object") {
      if (order1.key !== key) {
        return null;
      }
      return sortCompare(a, b, [
        [order1.value, "asc", order1.option ?? {}],
        ...customOrders,
      ]);
    } else {
      if (order1 !== key) {
        return null;
      }
      return sortCompare(a, b, [[order1, "asc"], ...customOrders]);
    }
  };
}







































import Vue, { PropType } from "vue";
import { LoginResponse } from "@/apis/AuthApi";

export default Vue.extend({
  name: "PasswordExpiredAlert",
  props: {
    /** ログイン結果情報 */
    login: {
      type: Object as PropType<LoginResponse>,
      required: true,
    },
  },
});































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import {
  CpaContract,
  CpaContractOptions,
  CpaNwDetail,
} from "@/apis/Cpa5gSaApi";

export default Vue.extend({
  name: "SaNwEditStepper1",
  props: {
    /** 親コンポーネントで保持してるCPA NW情報 */
    value: {
      type: Object as PropType<CpaNwDetail>,
      required: true,
    },
    /** CPA契約情報 */
    cpaContract: {
      type: Object as PropType<CpaContract>,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      cpaNwSetting: this.value,
      contractBandwidth: {} as CpaContractOptions["contractBandwidth"],
    };
  },
  computed: {
    /** 認証IPアドレスの重複チェック用リスト作成 */
    ipv4list() {
      return (index: number) => {
        return this.cpaNwSetting.ueIpAllocationIpv4List?.filter(
          (_, num) => num !== index
        );
      };
    },
    /** 認証IPアドレスV6の重複チェック用リスト作成 */
    ipv6list() {
      return (index: number) => {
        return this.cpaNwSetting.ueIpAllocationIpv6List?.filter(
          (_, num) => num !== index
        );
      };
    },
    /** SBY-GWルータ/GW対向ルータ(IPv4)をリスト作成 */
    sbyIpv4list(): string[] | null {
      if (!this.cpaNwSetting.networkIpSettingSby) return null;
      return [
        this.cpaNwSetting.networkIpSettingSby.wanIpv4Pe || "",
        this.cpaNwSetting.networkIpSettingSby.wanIpv4Gw || "",
      ];
    },
    /** ACT-GWルータ/GW対向ルータ(IPv4)をリスト作成 */
    actIpv4list(): string[] | null {
      if (!this.cpaNwSetting.networkIpSettingAct) return null;
      return [
        this.cpaNwSetting.networkIpSettingAct.wanIpv4Pe || "",
        this.cpaNwSetting.networkIpSettingAct.wanIpv4Gw || "",
      ];
    },
    /** SBY-GWルータ/GW対向ルータ(IPv6)をリスト作成 */
    sbyIpv6list(): string[] | null {
      if (!this.cpaNwSetting.networkIpSettingSby) return null;
      return [
        this.cpaNwSetting.networkIpSettingSby.wanIpv6Pe || "",
        this.cpaNwSetting.networkIpSettingSby.wanIpv6Gw || "",
      ];
    },
    /** ACT-GWルータ/GW対向ルータ(IPv6)をリスト作成 */
    actIpv6list(): string[] | null {
      if (!this.cpaNwSetting.networkIpSettingAct) return null;
      return [
        this.cpaNwSetting.networkIpSettingAct.wanIpv6Pe || "",
        this.cpaNwSetting.networkIpSettingAct.wanIpv6Gw || "",
      ];
    },
    /**
     * 契約帯域プルダウン
     * 契約時に選択された契約種別により表示する内容を変更する
     */
    bandwidthOptions(): {
      value: { value: number; unit: string };
      text: string;
    }[] {
      if (this.cpaContract.bandwidthType === "BEST_EFFORT") return [];
      if (this.cpaContract.contractType === "CPA_NO_LIMIT") {
        // 速度制限無しの場合は帯域の上限以下を選択可能とする
        return [
          { value: { value: 100, unit: "MBPS" }, text: "100Mbps" },
          { value: { value: 200, unit: "MBPS" }, text: "200Mbps" },
          { value: { value: 300, unit: "MBPS" }, text: "300Mbps" },
          { value: { value: 400, unit: "MBPS" }, text: "400Mbps" },
          { value: { value: 500, unit: "MBPS" }, text: "500Mbps" },
          { value: { value: 600, unit: "MBPS" }, text: "600Mbps" },
          { value: { value: 700, unit: "MBPS" }, text: "700Mbps" },
          { value: { value: 800, unit: "MBPS" }, text: "800Mbps" },
          { value: { value: 900, unit: "MBPS" }, text: "900Mbps" },
          { value: { value: 1, unit: "GBPS" }, text: "1Gbps" },
          { value: { value: 2, unit: "GBPS" }, text: "2Gbps" },
          { value: { value: 3, unit: "GBPS" }, text: "3Gbps" },
          { value: { value: 4, unit: "GBPS" }, text: "4Gbps" },
          { value: { value: 5, unit: "GBPS" }, text: "5Gbps" },
          { value: { value: 6, unit: "GBPS" }, text: "6Gbps" },
          { value: { value: 7, unit: "GBPS" }, text: "7Gbps" },
          { value: { value: 8, unit: "GBPS" }, text: "8Gbps" },
          { value: { value: 9, unit: "GBPS" }, text: "9Gbps" },
          { value: { value: 10, unit: "GBPS" }, text: "10Gbps" },
          { value: { value: 20, unit: "GBPS" }, text: "20Gbps" },
          { value: { value: 30, unit: "GBPS" }, text: "30Gbps" },
          { value: { value: 40, unit: "GBPS" }, text: "40Gbps" },
          { value: { value: 50, unit: "GBPS" }, text: "50Gbps" },
          { value: { value: 60, unit: "GBPS" }, text: "60Gbps" },
          { value: { value: 70, unit: "GBPS" }, text: "70Gbps" },
          { value: { value: 80, unit: "GBPS" }, text: "80Gbps" },
          { value: { value: 90, unit: "GBPS" }, text: "90Gbps" },
          { value: { value: 100, unit: "GBPS" }, text: "100Gbps" },
        ].filter((v) => {
          return (
            v.value.unit === "MBPS" ||
            v.value.value <= this.contractBandwidth.value
          );
        });
      } else {
        return [
          { value: { value: 1, unit: "MBPS" }, text: "1Mbps" },
          { value: { value: 10, unit: "MBPS" }, text: "10Mbps" },
          { value: { value: 20, unit: "MBPS" }, text: "20Mbps" },
          { value: { value: 30, unit: "MBPS" }, text: "30Mbps" },
          { value: { value: 40, unit: "MBPS" }, text: "40Mbps" },
          { value: { value: 50, unit: "MBPS" }, text: "50Mbps" },
          { value: { value: 60, unit: "MBPS" }, text: "60Mbps" },
          { value: { value: 70, unit: "MBPS" }, text: "70Mbps" },
          { value: { value: 80, unit: "MBPS" }, text: "80Mbps" },
          { value: { value: 90, unit: "MBPS" }, text: "90Mbps" },
        ];
      }
    },
    /**
     * アドレスタイプがIPv4か判定
     * true: IPv4である false: IPv4ではない
     */
    isIpv4(): boolean {
      return (
        this.cpaContract.ipAddressType === "IPV4" ||
        this.cpaContract.ipAddressType === "IPV4_IPV6"
      );
    },
    /**
     * アドレスタイプがIPv6か判定
     * true: IPv6である false: IPv6ではない
     */
    isIpv6(): boolean {
      return (
        this.cpaContract.ipAddressType === "IPV6" ||
        this.cpaContract.ipAddressType === "IPV4_IPV6"
      );
    },
  },
  methods: {
    /** 端末割当アドレス(IPv4)のフォームを追加する */
    addUeIpAllocationIpv4List() {
      this.cpaNwSetting.ueIpAllocationIpv4List!.push("");
    },
    /** 端末割当アドレス(IPv6)のフォームを追加する */
    addUeIpAllocationIpv6List() {
      this.cpaNwSetting.ueIpAllocationIpv6List!.push("");
    },
    /** 端末割当アドレス(IPv4)のフォームを削除する */
    removeUeIpAllocationIpv4List(i: number) {
      if (this.cpaNwSetting.ueIpAllocationIpv4List!.length > 1) {
        this.cpaNwSetting.ueIpAllocationIpv4List!.splice(i, 1);
      } else {
        this.cpaNwSetting.ueIpAllocationIpv4List = [""];
      }
    },
    /** 端末割当アドレス(IPv6)のフォームを削除する */
    removeUeIpAllocationIpv6List(i: number) {
      if (this.cpaNwSetting.ueIpAllocationIpv6List!.length > 1) {
        this.cpaNwSetting.ueIpAllocationIpv6List!.splice(i, 1);
      } else {
        this.cpaNwSetting.ueIpAllocationIpv6List = [""];
      }
    },
  },
  watch: {
    /** 親からのForm情報更新を反映 */
    value(newValue: CpaNwDetail) {
      this.cpaNwSetting = newValue;
    },
    /** 親へForm情報更新を通知 */
    cpaNwSetting: {
      handler(newValue: CpaNwDetail) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
  async mounted() {
    // 速度制限無しかつ帯域確保の場合は、帯域値の設定上限を取得する
    if (
      this.cpaContract.contractType === "CPA_NO_LIMIT" &&
      this.cpaContract.bandwidthType === "SECURING"
    ) {
      this.contractBandwidth = (
        await this.$api.cpa5gSa.getCpaContractOptions()
      ).contractBandwidth;
    }
    this.isLoaded = true;
  },
});

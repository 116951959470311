






























































































import Vue, { PropType } from "vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { IntranetFwPolicy, IntraPolicy } from "@/apis/IntranetfwPolicyApi";
import IntranetPolicySrcAddress from "@/components/policy/IntranetPolicySrcAddress.vue";
import IntranetPolicyDstAddress from "@/components/policy/IntranetPolicyDstAddress.vue";
import IntranetPolicyService from "@/components/policy/IntranetPolicyService.vue";
import { PfgwZoneType } from "@/modals/applianceContractSetting/IntranetFwPfgwZoneSelectList.vue";
import cloneDeep from "lodash/cloneDeep";
import IntranetFwPfgwPolicyRuleViewRD from "@/modals/applianceContractSetting/IntranetFwPfgwPolicyRuleViewRD.vue";
import { Pfgw } from "@/apis/IntranetfwServiceMenuApi";

export default Vue.extend({
  name: "IntranetFwPfgwPolicyList",
  props: {
    /** イントラネットFWポリシー */
    policyList: {
      type: Array as PropType<IntraPolicy[]>,
      required: true,
    },
    /** イントラネットFWポリシー */
    intranetFwPolicy: {
      type: Object as PropType<IntranetFwPolicy>,
      required: true,
    },
    /** 送信元ゾーン */
    srcZone: {
      type: String as PropType<PfgwZoneType>,
      required: true,
    },
    /** PFGW */
    pfgw: {
      type: Object as PropType<Pfgw>,
      required: true,
    },
  },
  components: {
    IntranetPolicySrcAddress,
    IntranetPolicyDstAddress,
    IntranetPolicyService,
  },
  data() {
    return {
      fields: [
        { key: "policyId", label: "ID" },
        { key: "srcAddress", label: "送信元アドレス" },
        { key: "dstAddress", label: "宛先アドレス" },
        { key: "service", label: "サービス" },
        { key: "actionType", label: "アクション" },
        { key: "isPolicyStatus", label: "有効/無効" },
        { key: "description", label: "備考", tdClass: "text-pre-wrap" },
      ] as BvTableFieldArray,
      items: cloneDeep(this.policyList) as IntraPolicy[],
    };
  },
  computed: {
    /** ゾーン接続方向の表示情報 */
    zoneRelationToDisplay(): string {
      return `${this.srcZone} → ${this.srcZone === "WVS2" ? "KCPS" : "WVS2"}`;
    },
  },
  methods: {
    /**
     * ポリシーテーブルのtrクラス.
     * 編集不可デフォルトポリシーの場合は特定のクラスを返す
     */
    policyRowClass(
      item: Pick<IntraPolicy, "policyCategoryInfo">
    ): string | null {
      return item.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY"
        ? "not-editable-policy"
        : null;
    },
    /** ポリシー編集モーダルを表示 */
    async showPolicyRuleEdit() {
      // ポリシー編集モーダルの表示 ポリシー編集で変更した内容を受け取る
      this.items = await this.$modal.show(IntranetFwPfgwPolicyRuleViewRD, {
        srcZone: this.srcZone,
        policyList: this.items,
        intranetFwPolicy: this.intranetFwPolicy,
        pfgw: this.pfgw,
      });
    },
    /**
     * 戻るボタン・×を押下した時の処理
     * モーダルを閉じて遷移元に編集したポリシーを返却する
     */
    savePolicy() {
      (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(this.items);
    },
  },
});



































































































































































































































import Vue, { PropType } from "vue";
import { GetPolicy, GetPolicyType1 } from "@/apis/NwPolicyApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicyService from "@/components/policy/PolicyService.vue";
import PolicyProfile from "@/components/policy/PolicyProfile.vue";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";
import cloneDeep from "lodash/cloneDeep";
import InternetType1PolicyRuleIfwViewRD from "@/modals/applianceContractSetting/InternetType1PolicyRuleIfwViewRD.vue";
import PolicyIpPool from "@/components/policy/PolicyIpPool.vue";
import InternetType1PolicyRuleIpMasqViewRD from "@/modals/applianceContractSetting/InternetType1PolicyRuleIpMasqViewRD.vue";
import InternetType1PolicyRuleDestNatViewRD from "@/modals/applianceContractSetting/InternetType1PolicyRuleDestNatViewRD.vue";
import { Type1Service } from "@/apis/ContractChangeType1Api";
import { mapState } from "vuex";

export type Type1IFWPolicyList =
  | NonNullable<
      GetPolicyType1["policyIfwType1"]
    >["internetToAccessPointList"][0]
  | NonNullable<
      GetPolicyType1["policyIfwType1"]
    >["accessPointToInternetList"][0];
export type Type1IFWPolicy = Type1IFWPolicyList["policyList"][0];

export type Type1IpMasqueradeList = NonNullable<
  GetPolicyType1["policyIPMasqueradeType1List"]
>[0];

export type Type1NatPolicyList = NonNullable<
  GetPolicyType1["policyNatType1List"]
>[0];

export default Vue.extend({
  name: "InternetType1PolicyIfwList",
  components: {
    PolicyIpPool,
    PolicyProfile,
    PolicyService,
    PolicyDstAddress,
    PolicySrcAddress,
  },
  props: {
    /** zone relation */
    zoneRelationItem: {
      type: Object as PropType<ZoneRelationItem>,
      required: true,
    },
    /** type1 policy */
    policy: {
      type: Object as PropType<GetPolicyType1>,
      required: true,
    },
    /** サービスメニュー(インターネットType1) */
    type1Service: {
      type: Object as PropType<Type1Service>,
      required: true,
    },
  },
  data() {
    const clonePolicy: GetPolicyType1 = cloneDeep(this.policy);
    // NATはデフォルトポリシーがないため初期はゾーンが一致するオブジェクトが無い場合がある
    // その際はポリシー0件のオブジェクトを作成（NAT契約がある場合のみ）
    if (
      this.$store.state.user.contractSummary.isNat &&
      !clonePolicy.policyNatType1List!.find(
        (v) => v.zoneRelationId === this.zoneRelationItem.zoneRelationId
      )
    ) {
      clonePolicy.policyNatType1List! = [
        ...clonePolicy.policyNatType1List!,
        {
          zoneRelationId: this.zoneRelationItem.zoneRelationId,
          total: 0,
          policyList: [],
        },
      ];
    }

    return {
      clonePolicy,
      ifwTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddress", label: "送信元アドレス" },
          { key: "dstAddress", label: "宛先アドレス" },
          { key: "service", label: "サービス" },
          { key: "profile", label: "プロファイル" },
          { key: "actionType", label: "アクション" },
          { key: "isLogOutput", label: "ログ出力" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      ipMasqueradeTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddressList", label: "送信元アドレス" },
          { key: "dstAddressList", label: "宛先アドレス" },
          { key: "ipPoolList", label: "IP Pool" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
      destinationNatTable: {
        fields: [
          { key: "policyId", label: "ID" },
          { key: "srcAddressList", label: "送信元アドレス" },
          { key: "ipMapping", label: "IPマッピング" },
          { key: "isPolicyStatus", label: "有効/無効" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
      },
    };
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
    /**
     * zone relationの表示
     */
    zoneRelationToDisplay(): string {
      return (
        this.$filter.enumTo(this.zoneRelationItem.srcZoneType, "zoneType") +
        "/" +
        this.zoneRelationItem.srcZoneName +
        " → " +
        this.$filter.enumTo(this.zoneRelationItem.dstZoneType, "zoneType") +
        "/" +
        this.zoneRelationItem.dstZoneName
      );
    },
    isInternetToAccess(): boolean {
      return this.zoneRelationItem.srcZoneType === "INTERNET";
    },
    /** 方向 + ゾーンが一致するポリシーリスト */
    ifwPolicyList(): Type1IFWPolicyList {
      if (this.isInternetToAccess) {
        // IFWインターネット発
        return this.clonePolicy.policyIfwType1!.internetToAccessPointList.find(
          (v) => v.zoneRelationId === this.zoneRelationItem.zoneRelationId
        )!;
      } else {
        // IFWアクセス拠点発
        return this.clonePolicy.policyIfwType1!.accessPointToInternetList.find(
          (v) => v.zoneRelationId === this.zoneRelationItem.zoneRelationId
        )!;
      }
    },
    /** IP Masqueradeポリシーリスト */
    ipMasqueradePolicyList(): Type1IpMasqueradeList {
      return this.clonePolicy.policyIPMasqueradeType1List!.find(
        (v) => v.zoneRelationId === this.zoneRelationItem.zoneRelationId
      )!;
    },
    /** Destination NATポリシーリスト */
    natPolicyList(): Type1NatPolicyList {
      return this.clonePolicy.policyNatType1List!.find(
        (v) => v.zoneRelationId === this.zoneRelationItem.zoneRelationId
      )!;
    },
  },
  methods: {
    /**
     * ポリシーテーブルのtrクラス.
     * 編集不可デフォルトポリシーの場合は特定のクラスを返す
     */
    policyRowClass(item: Pick<GetPolicy, "policyCategoryInfo">): string | null {
      return item.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY"
        ? "not-editable-policy"
        : null;
    },
    /** IFWのルールを編集 */
    async editPolicyIFW() {
      this.ifwPolicyList.policyList = await this.$modal.show(
        InternetType1PolicyRuleIfwViewRD,
        {
          zone: this.zoneRelationItem,
          policyList: this.ifwPolicyList.policyList,
          type1Policy: this.clonePolicy,
          type1Service: this.type1Service,
        }
      );
    },
    /** IP Masqueradeのルールを編集 */
    async editPolicyIpMasquerade() {
      this.ipMasqueradePolicyList.policyList = await this.$modal.show(
        InternetType1PolicyRuleIpMasqViewRD,
        {
          zone: this.zoneRelationItem,
          policyList: this.ipMasqueradePolicyList.policyList,
          type1Policy: this.clonePolicy,
          type1Service: this.type1Service,
        }
      );
    },
    /** Destination NATのルールを編集 */
    async editPolicyDestinationNAT() {
      this.natPolicyList.policyList = await this.$modal.show(
        InternetType1PolicyRuleDestNatViewRD,
        {
          zone: this.zoneRelationItem,
          policyList: this.natPolicyList.policyList,
          type1Policy: this.clonePolicy,
          type1Service: this.type1Service,
        }
      );
    },
    /** ポリシー編集完了 */
    async submit() {
      (this.$refs.modal as Vue & { ok: (e: unknown) => void }).ok(
        this.clonePolicy
      );
    },
  },
});

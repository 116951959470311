import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 * 参照回線種別
 * VN：VNコネクト。VNコネクトAPIのGETメソッドから取得する。
 * L2L3：VN L2L3コネクト。VN L2L3コネクトAPIのGETメソッドから取得する。
 * CLOUD：クラウドVNコネクト。クラウドVN回線(MS/AWS/GCP/IBM/ORACLE)APIのGETメソッドから取得する。
 * AC：AC回線。IPsec APIのGETメソッドから取得する。
 */
export type LineCategory = "VN" | "L2L3" | "CLOUD" | "AC";

export interface PutIfStateRequest {
  /**
   * W番号.
   * 参照回線種別を取得したAPIのGETメソッドにて取得する。参照回線種別がVN、L2L3、CLOUDであった場合は必須となる。
   * */
  wnumber?: string;
  /** X番号. 参照回線種別を取得したAPIのGETメソッドにて取得する。参照回線種別がACであった場合は必須となる。*/
  xnumber?: string;
  /**
   * IF状態.
   * SHUTDOWN:閉塞(対象VNコネクトを利⽤した通信はドロップします。)
   * NO_SHUTDOWN:開放(対象VNコネクトを利⽤した通信が可能です。)
   */
  ifStatus: "SHUTDOWN" | "NO_SHUTDOWN";
  /**
   * IF状態変更排他情報.
   * 排他確認用パラメータ。参照回線種別を取得したAPIのGETメソッドから取得する。
   */
  ifStateKeyId: string;
}

export interface PostReferenceRequest {
  /**
   * W番号.
   * 参照回線種別を取得したAPIのGETメソッドにて取得する。参照回線種別がVN、L2L3、CLOUDであった場合は必須となる。
   * */
  wnumber?: string;
  /** X番号. 参照回線種別を取得したAPIのGETメソッドにて取得する。参照回線種別がACであった場合は必須となる。*/
  xnumber?: string;
  /**
   * コマンド種別.
   * 以下のいずれかを指定する。
   * ROUTE: 経路情報参照。VNコネクトモード/クラウドVNコネクトモード(ルーティング種別)がL3(BGP4),L3(static),L2L3(BGP4),L2L3(static),L2L3(OSPF)の場合
   * BGP: BGPネイバー参照。VNコネクトモード/クラウドVNコネクトモード(ルーティング種別)がL3(BGP4),L2L3(BGP4)の場合
   * ARP: ARP参照。VNコネクトモード/クラウドVNコネクトモード(ルーティング種別)がL3(BGP4),L3(static),L2L3(BGP4),L2L3(static),L2L3(OSPF)の場合
   * MAC: MACテーブル参照。VNコネクトモード(ルーティング種別)がL2の場合
   * OSPF: OSPFネイバー参照。VNコネクトモード(ルーティング種別)がL2L3(OSPF)の場合
   * GATEWAY: ゲートウェイ経路情報参照。AC回線の場合
   * SA: SA状態参照。AC回線の場合
   */
  commandType: "ROUTE" | "BGP" | "ARP" | "MAC" | "OSPF" | "GATEWAY" | "SA";
  /**
   * 参照コマンド排他情報.
   * 排他確認用パラメータ。参照回線種別を取得したAPIのGETメソッドから取得する。
   */
  referenceCommandKeyId: string;
}

export interface PostConnectivityRequest {
  /**
   * W番号.
   * 参照回線種別を取得したAPIのGETメソッドにて取得する。参照回線種別がVN、L2L3、CLOUDであった場合は必須となる。
   * */
  wnumber?: string;
  /** X番号. 参照回線種別を取得したAPIのGETメソッドにて取得する。参照回線種別がACであった場合は必須となる。*/
  xnumber?: string;
  /**
   * コマンド種別.
   * PINGかTRACEROUTEを指定する。
   * PING:PING
   * TRACEROUTE:TRACEROUTE
   */
  commandType: "PING" | "TRACEROUTE";
  /** IPアドレス */
  ipAddress: string;
  /**
   * 疎通確認コマンド排他情報.
   * 排他確認用パラメータ。参照回線種別を取得したAPIのGETメソッドから取得する。
   */
  connectTestKeyId: string;
}

export interface PostCloudInformationRequest {
  /**
   * W番号.
   * 参照回線種別を取得したAPIのGETメソッドにて取得する。必須となる。
   * */
  wnumber: string;
  /**
   * クラウド情報取得コマンド排他情報.
   * 排他確認用パラメータ。参照回線種別を取得したAPIのGETメソッドから取得する。
   */
  cloudLineInfomationKeyId: string;
}

export interface CommandInfo {
  /**
   * コマンド登録番号.
   * 主キー
   */
  entryNumber: string;
  /** コマンド登録日時. */
  createDateTime: string | null;
  /**
   * 実行コマンド.
   * 以下のいずれかを指定する
   * PING: ping
   * TRACEROUTE: traceroute
   * CLOUD: クラウド回線情報取得
   * ROUTE: 経路情報参照
   * BGP: BGPネイバー参照
   * ARP: ARP参照
   * MAC: MACテーブル参照
   * OSPF: OSPFネイバー参照
   * GATEWAY: ゲートウェイ経路情報参照
   * SA: SA状態参照
   */
  commandType:
    | "PING"
    | "TRACEROUTE"
    | "CLOUD"
    | "ROUTE"
    | "BGP"
    | "ARP"
    | "MAC"
    | "OSPF"
    | "GATEWAY"
    | "SA";
  /**
   * IPアドレス.
   * 疎通確認コマンドの実行時に使用
   */
  ipAddress: string | null;
  /**
   * 実行状態.
   * 実行されたコマンドの状態
   * EXECUTION: 実行中
   * OK: 実行結果正常
   * NG: 実行NG
   */
  commandStatus: "EXECUTION" | "SUCCESS" | "FAILURE";
  /** コマンド実行完了日時. 未完了の場合はNULLを返す。 */
  endDateTime: string | null;
}

export interface CommandResults {
  /**
   * 総件数.
   * 一覧表示対象の総件数
   */
  total: number;
  commandList: CommandInfo[];
}

export interface CommandResult {
  commandInfo: CommandInfo;
  /** コマンド実行結果. 実行結果が得られない場合は実行結果が得られない場合は省略する。 */
  result: string | null;
}

/** 運用ツール系API */
export default class NwOperationToolApi extends AbstractBaseApi {
  /**
   * VNコネクトのIF状態変更を実行する
   * @param lineCategory 参照回線種別
   * @param data 変更情報
   */
  async putIfStatus(
    lineCategory: LineCategory,
    data: PutIfStateRequest
  ): Promise<void> {
    await this.api.post(
      `/v1/network/operation-tool/if-status/${lineCategory}`,
      data
    );
  }

  /**
   * 参照コマンドを実行
   * @param lineCategory 参照回線種別
   * @param data 実行情報
   */
  async postReference(
    lineCategory: LineCategory,
    data: PostReferenceRequest
  ): Promise<void> {
    await this.api.post(
      `/v1/network/operation-tool/reference/${lineCategory}`,
      data
    );
  }

  /**
   * 疎通確認コマンドの送信を行う
   * @param lineCategory 参照回線種別
   * @param data コマンド情報
   */
  async postConnectivity(
    lineCategory: LineCategory,
    data: PostConnectivityRequest
  ): Promise<void> {
    await this.api.post(
      `/v1/network/operation-tool/connectivity/${lineCategory}`,
      data
    );
  }

  /**
   * クラウド回線情報の取得要求を行う
   * @param data 実行情報
   */
  async postCloudInformation(data: PostCloudInformationRequest): Promise<void> {
    await this.api.post("/v1/network/operation-tool/cloud-information", data);
  }

  /**
   * VN回線のコマンドの実行結果一覧を取得
   * @param vnLineCategory 参照回線種別(VN)
   * @param wnumber W番号
   */
  async getCommandResultsVN(
    vnLineCategory: Exclude<LineCategory, "AC">,
    wnumber: string
  ): Promise<CommandResults["commandList"]> {
    const { commandList }: CommandResults = (
      await this.api.get(
        `/v1/network/operation-tool/command-results/${vnLineCategory}/${wnumber}`
      )
    ).data;
    return commandList;
  }

  /**
   * AC回線のコマンドの実行結果一覧を取得
   * @param acLineCategory 参照回線種別(AC)
   * @param xnumber X番号
   */
  async getCommandResultsAC(
    acLineCategory: Extract<LineCategory, "AC">,
    xnumber: string
  ): Promise<CommandResults["commandList"]> {
    const { commandList }: CommandResults = (
      await this.api.get(
        `/v1/network/operation-tool/command-results/${acLineCategory}/${xnumber}`
      )
    ).data;
    return commandList;
  }

  /**
   * コマンドの実行結果詳細を取得
   * @param lineCategory 参照回線種別(AC、VN)
   * @param entryNumber コマンド登録番号
   */
  async getCommandResult(
    lineCategory: Extract<LineCategory, "AC" | "VN">,
    entryNumber: string
  ): Promise<CommandResult> {
    return (
      await this.api.get(
        `/v1/network/operation-tool/command-result/${lineCategory}/${entryNumber}`
      )
    ).data;
  }
}

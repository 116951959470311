











































































































































































































































































































































































import Vue, { PropType } from "vue";
import { CloudVnMsDetail } from "@/apis/CloudVnMsApi";

export default Vue.extend({
  name: "MultiCloudVNConnectionConfirmMS",
  props: {
    cloudVnMsDetail: {
      type: Object as PropType<CloudVnMsDetail>,
      required: true,
    },
    /** 画面表示モード：
     * A - 追加確認
     * M - 編集確認
     * D - 削除確認
     */
    displayType: {
      type: String as PropType<"A" | "M" | "D">,
      required: true,
    },
  },
  computed: {
    noticeOptions(): { text: string; value: string }[] {
      switch (this.displayType) {
        case "A":
          return [
            {
              text: "VNコネクト作成中はMicrosoft Azureの設定変更は実施しないこと",
              value: "MS_ADD_PRIVATE_PEERING",
            },
          ];
        case "D":
          if (
            this.cloudVnMsDetail.cloudVnConnectCommon.cloudServiceType ===
            "PRIVATE_PEERING"
          ) {
            return [
              {
                text: "Microsoft Azure ExpressRoute circuitsの接続の削除が完了していること",
                value: "MS_DELETE_PRIVATE_PEERING",
              },
            ];
          } else {
            return [
              {
                text: "Microsoft Azure Route filterの削除が完了していること",
                value: "MS_DELETE_MICROSOFT_PEERING",
              },
            ];
          }
        case "M":
        default:
          return [];
      }
    },
    showNotice(): boolean {
      switch (this.displayType) {
        case "A":
        case "D":
          return true;
        case "M":
        default:
          return false;
      }
    },
    confirmMessage(): string {
      switch (this.displayType) {
        case "A":
          return "こちらの内容でクラウド接続向けVN回線を追加します。よろしいですか？";
        case "M":
          return "こちらの内容でクラウド接続向けVN回線を変更します。よろしいですか？";
        case "D":
          return "こちらの内容でクラウド接続向けVN回線を削除します。よろしいですか？";
        default:
          throw new Error("wrong displayType!");
      }
    },
  },
  data() {
    return {
      checkedItems: [],
    };
  },
});

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/** クラウドQoS設定情報一覧 */
export interface CloudQosInfoList {
  /** 総件数. 一覧表示対象の総件数 */
  total: number;
  /** QoS情報. 取得したQoS情報。データが存在しない場合、[](空の状態)になります。 */
  qosInfoList: CloudQosInfo[];
}

export interface CloudQosInfo {
  /** VPN/VNコード. 主キー */
  vpnVnCode: string;
  /** 仮想ネットワーク名 */
  vnName: string | null;
  /**
   * VLAN IDのType指定
   * L2回線のときのみ適用項目
   * UNTAG: IDの指定なし,
   * OTHER: 他の仮想ネットワークで指定されていないID,もしくは指定なし,
   * SPECIFIED: 指定ID
   */
  vlanIdType: "UNTAG" | "OTHER" | "SPECIFIED" | null;
  /** VLAN ID. L2回線のときかつ、vlanIdTypeで「SPECIFIED: 指定ID」を選択しているときに取得可能 */
  vlanId: number | null;
  /**
   * QoS方式
   * 以下のいずれかを返す。
   * BANDWIDTH: 帯域制御方式
   * ABSOLUTE: 絶対優先方式
   * NONE: 未設定
   */
  qosType: "BANDWIDTH" | "ABSOLUTE" | "NONE";
  /**
   * 優先度参照先
   * 以下のいずれかを返す。
   * IPPRECEDENCE_DSCP: IP Precedence/DSCP
   * DSCP: DSCP
   * NONE: 未設定
   */
  priorityTarget: "IPPRECEDENCE_DSCP" | "DSCP" | "NONE";
  /** VNコネクト数 */
  numberOfVnConnect: number;
  /** VNコネクト数最大値 */
  upperLimitVnConnect: number;
  /**
   * W番号(ACT)
   * Microsoft Peering申込済みVNコネクトのW番号のみ返却する。
   * */
  wnumberAct: string | null;
  /**
   * W番号(SBY)
   * Microsoft Peering申込済みVNコネクトのW番号のみ返却する。
   */
  wnumberSby: string | null;
  /** 設定可否情報. ENABLE、DISABLEのいずれかを指定する。 */
  qosControl: "ENABLE" | "DISABLE";
}

/** クラウドQoS設定詳細 */
export interface CloudQosDetail {
  /** QoS方式. 以下のいずれかを返す。 BANDWIDTH: 帯域制御方式 ABSOLUTE: 絶対優先方式 NONE: 未設定 */
  qosType: "BANDWIDTH" | "ABSOLUTE" | "NONE";
  /** 優先度参照先. 以下のいずれかを返す。 IPPRECEDENCE_DSCP: IP Precedence/DSCP DSCP: DSCP NONE: 未設定 */
  priorityTarget: "IPPRECEDENCE_DSCP" | "DSCP" | "NONE";
  /** 絶対優先方式の設定値情報。qosTypeがABSOLUTEの場合は必須、それ以外の場合はNULLになります */
  cloudQosAbsolute: {
    /** 絶対優先方式 優先度0設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority0: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度1設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority1: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度2設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority2: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度3設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority3: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度4設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority4: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度5設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority5: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度6設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority6: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
    /** 絶対優先方式 優先度7設定値. LOW, NORMAL, MEDIUM, HIGHのいずれかを指定する。 */
    priority7: "LOW" | "NORMAL" | "MEDIUM" | "HIGH";
  } | null;
  /** 帯域制御設定値情報(詳細取得用) 取得した帯域制御方式の設定値情報。qosTypeがBANDWIDTHの場合は必須、それ以外の場合は空配列になります。 */
  cloudQosBandwidthList: CloudQosBandwidth[];
  /** クラウドQoS設定排他情報. 排他確認用パラメータ。 PUTメソッドにて変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。*/
  cloudQosKeyId: string;
}

/** クラウドQoS設定詳細登録用 */
export type PutCloudQosDetail = Omit<
  CloudQosDetail,
  "cloudQosBandwidthList"
> & {
  cloudQosBandwidthList: Omit<CloudQosBandwidth, "bestEffort">[];
};

/** クラウドQoS帯域設定地情報 */
export interface CloudQosBandwidth {
  /** パターン名. パターンA～パターンBのいずれかを指定する。 */
  patternName: "PATTERN_A" | "PATTERN_B";
  /** 帯域制御方式 DSCP_18設定値. 帯域制御方式 DSCP_18に設定する値を指定する。*/
  dscp18Value: number | null;
  /** 帯域制御方式 DSCP_24設定値. 帯域制御方式 DSCP_24に設定する値を指定する。 */
  dscp24Value: number | null;
  /** 帯域制御方式 DSCP_34設定値. 帯域制御方式 DSCP_34に設定する値を指定する。 */
  dscp34Value: number | null;
  /** 帯域制御方式 DSCP_46設定値. 帯域制御方式 DSCP_46に設定する値を指定する。 */
  dscp46Value: number | null;
  /** 帯域制御方式 ベストエフォート値
   * 帯域制御方式 ベストエフォート項目に設定する値を指定する。
   * 「100 - (DSCP10～DSCP46のすべての設定値の合計)」の値となる。
   * GET：計算・設定済みの内容を返却
   * PUT：APIにて各設定値から計算し、設定を行うため通知不要
   */
  bestEffort: number;
  /** 帯域制御方式 最優先設定フラグ. ON: true、OFF: falseのいずれかを指定する。 */
  isDscp46TopPriority: boolean;
}

export default class CloudQosApi extends AbstractBaseApi {
  /** クラウドQoS設定情報を取得 */
  async getCloudQosInfoList(): Promise<CloudQosInfo[]> {
    return (
      (await this.api.get("/v1/network/cloud/qos")).data as CloudQosInfoList
    ).qosInfoList;
  }

  /**
   * クラウドQoS設定詳細を1件取得
   * @param vpnVnCode
   */
  async getCloudQosDetail(vpnVnCode: string): Promise<CloudQosDetail> {
    return (await this.api.get(`/v1/network/${vpnVnCode}/cloud/qos`)).data;
  }

  /**
   * クラウドQoS設定の変更を行う
   * @param vpnVnCode
   * @param data クラウドQoS更新情報
   */
  async putCloudQosDetail(
    vpnVnCode: string,
    data: PutCloudQosDetail
  ): Promise<void> {
    await this.api.post(`/v1/network/${vpnVnCode}/cloud/qos`, data);
  }

  /**
   * クラウドQoS設定情報をCSVにてダウンロードする。
   */
  getCsvCloudQosURL(): string {
    return this.getFullURL("/v1/network/cloud/qos/csv", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }
}

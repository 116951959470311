import { DirectiveBinding, DirectiveOptions } from "vue/types/options";
import { VNode } from "vue";
import { SystemError } from "@/error/errors";

interface PermissionModifiers {
  /** 管理者権限のみ */
  admin?: boolean;
  /** 設定権限、管理者権限のみ */
  config?: boolean;
  /** 一般ユーザー（代行権限ではない）のみ */
  normal?: boolean;
  /** 代行権限のみ */
  delegate?: boolean;
  /** 閲覧 + 代行権限以外のみ */
  notViewDelegate?: boolean;
  /** 法人管理者権限代行(KDDI管理者)のみ */
  kddiAdmin?: boolean;
}

/**
 * 指定された権限レベルと種類によって対象のelementの表示・非表示を制御する
 * 使い方
 * ・例1 管理者権限のみ表示
 *   <b-button v-permission.admin>追加</b-button>
 *
 * ・例2 管理者権限、設定権限のみ表示
 *   <b-button v-permission.config>追加</b-button>
 *
 * ・例3 一般ユーザーのみ表示
 *   <b-button v-permission.normal>追加</b-button>
 *
 * ・例4 代行権限のみ表示
 *   <b-button v-permission.delegate>追加</b-button>
 *
 * ・例5 管理者権限 + 代行権限のみ表示（権限レベル + 種類の組み合わせ）
 *   <b-button v-permission.admin.delegate>追加</b-button>
 *
 * ・例6 閲覧権限 + 代行権限以外のみ表示
 *   <b-button v-permission.notViewDelegate>追加</b-button>
 *
 * ・例7 法人管理者権限代行(KDDI管理者)のみ表示
 *   <b-button v-permission.kddiAdmin>追加</b-button>
 */
export const PermissionDirective: DirectiveOptions = {
  inserted(el: HTMLElement, binding: DirectiveBinding, vnode: VNode): void {
    const modifiers = binding.modifiers as PermissionModifiers;
    if (
      modifiers.admin === undefined &&
      modifiers.config === undefined &&
      modifiers.normal === undefined &&
      modifiers.delegate === undefined &&
      modifiers.notViewDelegate === undefined &&
      modifiers.kddiAdmin === undefined
    ) {
      throw new SystemError("permission directive required modifiers");
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const $service = vnode.context!.$service;

    // 指定された権限を持たない場合は非表示
    if (modifiers.admin && !$service.permission.hasAuthority("ADMIN")) {
      el.classList.add("hide-permission");
    } else if (
      modifiers.config &&
      !$service.permission.hasAuthority("CONFIG")
    ) {
      el.classList.add("hide-permission");
    }

    // 指定された種類のユーザー（一般/代行）ではない場合は非表示
    if (modifiers.normal && !$service.permission.isNormalUser()) {
      el.classList.add("hide-permission");
    } else if (modifiers.delegate && !$service.permission.isDelegateUser()) {
      el.classList.add("hide-permission");
    }

    // 閲覧権限 + 代行権限以外のみ表示
    if (modifiers.notViewDelegate && $service.permission.isViewDelegate()) {
      el.classList.add("hide-permission");
    }

    // 法人管理者権限代行(KDDI管理者)のみ表示
    if (modifiers.kddiAdmin && !$service.permission.isKddiAdmin()) {
      el.classList.add("hide-permission");
    }
  },

  unbind: (el) => {
    el.classList.remove("hide-permission");
  },
};



























import Vue from "vue";
import MixinSideMenu from "@/mixins/MixinSideMenu.vue";
import {
  SidebarComponentItem,
  SidebarHeaderItem,
  SidebarItem,
} from "vue-sidebar-menu";
import { ContractInfo, ContractSummary } from "@/apis/ContractApi";
import { mapState } from "vuex";
import { UserState } from "@/store/user-store";
import PermissionService from "@/services/PermissionService";

export function getTrafficLogMenus(
  summary: ContractSummary,
  permission: PermissionService,
  contractList: ContractInfo["contractList"]
): Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem> {
  const isViewDelegate = permission.isViewDelegate();
  const hasCpa5Gsa = contractList.some((e) => e.accessType === "CPA_5GSA");
  return [
    {
      href: "/report",
      title: "トラフィック/セッション/統計情報",
      icon: { element: "b-icon-graph-up" },
      hidden:
        !summary.isTrafficInfo &&
        !summary.isSessionInfo &&
        !summary.isStatisticsInfo,
    },
    {
      href: "/report/appliance-log",
      title: "アプライアンスログ",
      icon: { element: "b-icon-file-earmark-text" },
      hidden: isViewDelegate,
    },
    {
      href: "/report/remote/access/account",
      title: "CPA認証ID",
      icon: { element: "b-icon-file-earmark-text-fill" },
      hidden: !hasCpa5Gsa,
    },
  ] as Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem>;
}

export default Vue.extend({
  name: "TrafficLogMain",
  mixins: [MixinSideMenu],
  computed: {
    ...mapState("user", {
      contractSummary: (state) => (state as UserState).contractSummary,
      contractInfo: (state) => (state as UserState).contractInfo,
    }),
    menu(): Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem> {
      return getTrafficLogMenus(
        this.contractSummary,
        this.$service.permission,
        this.contractInfo.contractList
      );
    },
  },
});

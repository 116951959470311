




























































































import Vue, { PropType } from "vue";
import { NaptGlobalIpEntity, NatNaptDetailEntity } from "@/apis/MSPeeringIpApi";
import cloneDeep from "lodash/cloneDeep";
import MSNaptSettingModifyConfirm from "@/modals/msNaptSetting/MSNaptSettingModifyConfirm.vue";

export default Vue.extend({
  name: "MSNaptSettingModify",
  props: {
    /** 設定変更時、使用する排他キー */
    keyId: {
      type: String as PropType<string>,
      required: true,
    },
    /** NAPT用グローバルIP設定情報 */
    naptSetting: {
      type: Object as PropType<NatNaptDetailEntity>,
      required: true,
    },
  },
  data() {
    return {
      msNaptGlobalIpList: cloneDeep(this.naptSetting.msNaptGlobalIpList),
      deletedIp: [] as NaptGlobalIpEntity[],
      /** 追加グローバルIP数の初期値設定 */
      addIpCount: this.naptSetting.usedNapt === 0 ? 1 : 0,
    };
  },
  computed: {
    /** true: 削除可能, false: 削除不可 */
    canRemove(): boolean {
      return this.msNaptGlobalIpList.length + this.addIpCount > 1;
    },
    /** true: 追加可能, false: 追加不可 */
    canAdd(): boolean {
      return (
        this.naptSetting.msNaptGlobalIpList.length + this.addIpCount <
        this.naptSetting.upperLimitNapt
      );
    },
  },
  methods: {
    /** NAPTグローバルIP削除処理 */
    removeNapt(index: number) {
      const deleted = this.msNaptGlobalIpList.splice(index, 1);
      this.deletedIp = [...this.deletedIp, ...deleted];
    },

    async submit() {
      /** 確認画面表示 */
      await this.$modal.show(MSNaptSettingModifyConfirm, {
        addNapt: this.addIpCount,
        naptSetting: this.naptSetting,
        msNaptGlobalIpList: this.msNaptGlobalIpList,
      });

      /** グローバルIP更新API呼び出し処理 */
      await this.$api.msPeeringIp.updateGlobalIp(
        this.naptSetting.msServiceSeq,
        {
          addNapt: this.addIpCount,
          addNat: null,
          msNatAddPrivateIpList: null,
          deleteMsNaptGlobalIpList: this.deletedIp.map(
            (e) => e.cloudGlobalIpAddressSeq
          ),
          deleteMsNatGlobalIpList: null,
          msPeeringIpKeyId: this.keyId,
        }
      );
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

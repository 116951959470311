import { GetPolicy, GetPolicyType1, GetPolicyType4 } from "@/apis/NwPolicyApi";
import FileService from "@/services/FileService";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { DeepPartial, NullableProperty } from "@/shims-vue";
import { IntranetFwPolicy, IntraPolicy } from "@/apis/IntranetfwPolicyApi";
import { VNConnectDetail } from "@/apis/VNConnectApi";
import {
  CreateVNL2L3ConnectRequest,
  UpdateVNL2L3ConnectRequest,
  VNL2L3ConnectDetail,
} from "@/apis/VNL2L3ConnectApi";
import { PacketFilterEntity } from "@/apis/PacketFilteringApi";
import { IpSecLineWithGW } from "@/views/ipSecLine/IpSecLineList.vue";
import { VNConnectForm } from "@/modals/vnConnect/VNConnectEdit.vue";
import { VpnVnCode } from "@/apis/InformationApi";
import { CpaContract, CpaNwDetail, RadiusSetting } from "@/apis/Cpa5gSaApi";
import { ZoneRelationItem } from "@/apis/ZoneRelationApi";

/**
 * 設定ダウロード・アップロード種別.
 * internetType4: インターネットType4ポリシー
 * intranetFw: イントラネットFWポリシー
 * ipsec: IPsec回線
 * vnconnect: VNコネクト
 * packetfilter: パケットフィルタリング
 * cpa CPANW設定
 */
export type ConfigFeature =
  | "internetType1"
  | "internetType4"
  | "intranetFw"
  | "intranetFwPFGW"
  | "ipsec"
  | "vnconnect"
  | "packetfilter"
  | "l2l3vnconnect"
  | "cpa";
/**
 * 設定アップロード時のエラー種別.
 * @see UploadError
 */
export type ErrorCode =
  | "VERSION"
  | "MIME"
  | "FEATURE"
  | "LINE_SEQ"
  | "APPLIANCE"
  | "OPERATION_TYPE_ADD"
  | "OPERATION_TYPE_MODIFY";

/** インターネットType4ポリシー */
export type InternetType1PolicyConfig = {
  version: string;
  feature: Extract<ConfigFeature, "internetType1"> | string;
  vpnCode: string;
  appliance: "IFW" | "UTM" | string;
  policyUtmType1: null | {
    internetToAccessPointList: {
      total: never;
      zoneRelationId: never;
      // ゾーン関係IDは可変なため代わりにゾーンIDを使用する
      srcZoneId: string;
      dstZoneId: string;
      policyList: NonNullable<
        GetPolicyType1["policyUtmType1"]
      >["internetToAccessPointList"][0]["policyList"];
    }[];
    accessPointToInternetList: {
      total: never;
      zoneRelationId: never;
      srcZoneId: string;
      dstZoneId: string;
      policyList: NonNullable<
        GetPolicyType1["policyUtmType1"]
      >["accessPointToInternetList"][0]["policyList"];
    }[];
  };
  policyIfwType1: null | {
    internetToAccessPointList: {
      total: never;
      zoneRelationId: never;
      srcZoneId: string;
      dstZoneId: string;
      policyList: NonNullable<
        GetPolicyType1["policyIfwType1"]
      >["internetToAccessPointList"][0]["policyList"];
    }[];
    accessPointToInternetList: {
      total: never;
      zoneRelationId: never;
      srcZoneId: string;
      dstZoneId: string;
      policyList: NonNullable<
        GetPolicyType1["policyIfwType1"]
      >["accessPointToInternetList"][0]["policyList"];
    }[];
  };
  policyIPMasqueradeType1List: {
    total: never;
    zoneRelationId: never;
    srcZoneId: string;
    dstZoneId: string;
    policyList: GetPolicyType1["policyIPMasqueradeType1List"][0]["policyList"];
  }[];
  policyNatType1List: {
    total: never;
    zoneRelationId: never;
    srcZoneId: string;
    dstZoneId: string;
    policyList: GetPolicyType1["policyNatType1List"][0]["policyList"];
  }[];
  policyKeyId: never;
};

/** インターネットType4ポリシー */
export type InternetType4PolicyConfig = GetPolicyType4 & {
  version: string;
  feature: Extract<ConfigFeature, "internetType4"> | string;
  type4Id: string;
  appliance: "IFW" | "UTM" | string;
  policyUtmType4: {
    internetToAccessPointTotal: never;
    accessPointToInternetTotal: never;
  };
  policyIfwType4: {
    internetToAccessPointTotal: never;
    accessPointToInternetTotal: never;
  };
  policyIPMasqueradeType4: {
    total: never;
  };
  policyNatType4: {
    total: never;
  };
  policyKeyId: never;
};

/** イントラネットFWポリシー */
export type IntranetFwPolicyConfig = {
  version: string;
  feature: Extract<ConfigFeature, "intranetFw"> | string;
  vpnCode: string;
  privateToPrivateList: {
    zoneRelationId: never;
    total: never;
    // ゾーン関係IDは可変なため代わりにゾーンIDを使用する
    srcZoneId: string;
    dstZoneId: string;
    policyList: IntraPolicy[];
  }[];
  extraToPrivateList: IntranetFwPolicyConfig["privateToPrivateList"];
  privateToExtraList: IntranetFwPolicyConfig["privateToPrivateList"];
  wvs2ToKcpsList: never;
  kcpsToWvs2List: never;
  policyIntranetFwKeyId: never;
};

/** イントラネットFW PFGWポリシー */
export type IntranetFwPfgwPolicyConfig = IntranetFwPolicy & {
  version: string;
  feature: Extract<ConfigFeature, "intranetFwPFGW"> | string;
  vpnCode: string;
  enumberAct: string;
  privateToPrivateList: never;
  extraToPrivateList: never;
  privateToExtraList: never;
  policyIntranetFwKeyId: never;
};

/** IPsec回線 */
export type IPsecLineConfig = {
  version: string;
  feature: Extract<ConfigFeature, "ipsec"> | string;
  vnumber: string;
  operationType: "add" | "modify" | string;
  /** IPsec回線名 */
  ipsecLineName: string;
  /** 事前共有鍵 */
  preSharedKey: string;
  /** 疎通試験用IPアドレス */
  connectTestIpAddress: string;
  /** 備考 */
  description: string;
  /** IPsec回線番号 */
  xnumber?: string;
  /** IPsecGw回線番号 */
  enumber?: string;
  /** ユーザアカウント(USER-FQDN) */
  userAccount?: string;
  /** LANアドレス */
  lanIpAddressList?: {
    /** アドレスシーケンス */
    acLineIpAddressSeq?: string | null;
    /** IPアドレス */
    ipAddress: string;
  }[];
};

export type VNL2L3ConnecrUpConfig = VNL2L3ConnectDetail & {
  version: string;
  feature: Extract<ConfigFeature, "l2l3vnconnect"> | string;
  vnumber?: string;
  wnumber?: string;
  operationType: "add" | "modify" | string;
};

/** VNコネクト */
export type VNConnectConfig = {
  version: string;
  feature: Extract<ConfigFeature, "vnconnect"> | string;
  vnumber: string;
  siteId: string | undefined | null;
  wnumber: string | undefined;
  operationType: "add" | "modify" | string;
} & Partial<
  NullableProperty<
    Pick<
      VNConnectDetail,
      | "vnConnectName"
      | "enumber"
      | "vlanIdType"
      | "vlanId"
      | "vnName"
      | "vpnVnCode"
      | "vnType"
      | "siteName"
      | "pairLine"
      | "bandwidthType"
      | "bandwidth"
      | "qos"
      | "l2"
      | "l3"
      | "description"
    >
  >
>;

/** パケットフィルタリング */
export type PacketFilterConfig = {
  version: string;
  feature: Extract<ConfigFeature, "packetfitler"> | string;
  /** W番号（VNコネクト回線のみダウンロード対象情報） */
  wnumber?: string;
  /** E番号（アクセス回線のみダウンロード対象情報） */
  enumber?: string;
  /** パケットフィルタリングルール一覧 */
  packetFilterRuleList: PacketFilterEntity;
};

/** CPA NW設定 */
export type CpaNwConfig = Omit<
  CpaNwDetail,
  | "cpaNetworkKeyId"
  | "isWrapCommunication"
  | "isFtpSending"
  | "isAlwaysOn"
  | "cusconClientIp"
  | "idleTimer"
  | "longTimeCallDisconnectTimer"
  | "radiusSetting"
  | "bandwidthType"
  | "dealCodeList"
> &
  Partial<
    Pick<
      CpaNwDetail,
      | "cpaNetworkKeyId"
      | "isWrapCommunication"
      | "isFtpSending"
      | "isAlwaysOn"
      | "cusconClientIp"
      | "idleTimer"
      | "longTimeCallDisconnectTimer"
      | "bandwidthType"
      | "dealCodeList"
    >
  > & {
    version: string;
    feature: Extract<ConfigFeature, "cpa"> | string;
    vnumber: string;
    cpaContractSeq: string;
    radiusSetting?:
      | (Partial<
          Pick<
            RadiusSetting,
            | "ipAddressPrimary"
            | "ipAddressSecondary"
            | "callingStationIdTransfer"
            | "isImeiTransfer"
          >
        > &
          Omit<
            RadiusSetting,
            | "ipAddressPrimary"
            | "ipAddressSecondary"
            | "callingStationIdTransfer"
            | "isImeiTransfer"
          >)
      | null;
  };

// その他の設定の型

/** 設定アップロードエラー */
export class UploadError extends Error {
  constructor(public code: ErrorCode) {
    super(
      ((): string => {
        switch (code) {
          case "VERSION":
            return "ファイルバージョンが古いためアップロードできませんでした。";
          case "FEATURE":
            return "機能種別が異なるためアップロードできませんでした。";
          case "LINE_SEQ":
            return "設定対象回線が異なるためアップロードできませんでした。";
          case "MIME":
            return "JSON形式ではないためアップロードできませんでした。";
          case "APPLIANCE":
            return "契約情報が異なるためアップロードできませんでした。";
          case "OPERATION_TYPE_ADD":
            return "設定変更用ファイルを設定追加画面で使用したためアップロードできませんでした。";
          case "OPERATION_TYPE_MODIFY":
            return "設定追加用ファイルを設定変更画面で使用したためアップロードできませんでした。";
        }
      })()
    );
  }
}

/** 設定ダウンロード・アップロード */
export class ConfigDownloadService {
  constructor(readonly file: FileService) {}

  /**
   * インターネットType1ポリシーのダウンロード
   * @param vpnCode VPNコード
   * @param appliance 契約
   * @param policy ダウンロード対象
   * @param zoneRelationList ゾーンリレーション一覧
   */
  downloadInternetType1Policy(
    vpnCode: string,
    appliance: "IFW" | "UTM",
    policy: GetPolicyType1,
    zoneRelationList: ZoneRelationItem[]
  ): void {
    // ダウンロード用に内容を整形
    const config = {
      version: "v1",
      feature: "internetType1",
      vpnCode: vpnCode,
      appliance: appliance,
      ...cloneDeep(policy),
    } as InternetType1PolicyConfig;
    if (config.policyUtmType1) {
      for (const internetToAccessPoint of config.policyUtmType1
        .internetToAccessPointList) {
        delete internetToAccessPoint.total;
        const relation = getZoneRelation(
          zoneRelationList,
          internetToAccessPoint.zoneRelationId
        );
        internetToAccessPoint.srcZoneId = relation.srcZoneId;
        internetToAccessPoint.dstZoneId = relation.dstZoneId;
        delete internetToAccessPoint.zoneRelationId;
        internetToAccessPoint.policyList =
          internetToAccessPoint.policyList.filter((e) => {
            return e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY";
          });
      }
      for (const accessPointToInternet of config.policyUtmType1
        .accessPointToInternetList) {
        delete accessPointToInternet.total;
        const relation = getZoneRelation(
          zoneRelationList,
          accessPointToInternet.zoneRelationId
        );
        accessPointToInternet.srcZoneId = relation.srcZoneId;
        accessPointToInternet.dstZoneId = relation.dstZoneId;
        delete accessPointToInternet.zoneRelationId;
        accessPointToInternet.policyList =
          accessPointToInternet.policyList.filter((e) => {
            return e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY";
          });
      }
    }
    if (config.policyIfwType1) {
      for (const internetToAccessPoint of config.policyIfwType1
        .internetToAccessPointList) {
        delete internetToAccessPoint.total;
        const relation = getZoneRelation(
          zoneRelationList,
          internetToAccessPoint.zoneRelationId
        );
        internetToAccessPoint.srcZoneId = relation.srcZoneId;
        internetToAccessPoint.dstZoneId = relation.dstZoneId;
        delete internetToAccessPoint.zoneRelationId;
        internetToAccessPoint.policyList =
          internetToAccessPoint.policyList.filter((e) => {
            return e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY";
          });
      }
      for (const accessPointToInternet of config.policyIfwType1
        .accessPointToInternetList) {
        delete accessPointToInternet.total;
        const relation = getZoneRelation(
          zoneRelationList,
          accessPointToInternet.zoneRelationId
        );
        accessPointToInternet.srcZoneId = relation.srcZoneId;
        accessPointToInternet.dstZoneId = relation.dstZoneId;
        delete accessPointToInternet.zoneRelationId;
        accessPointToInternet.policyList =
          accessPointToInternet.policyList.filter((e) => {
            return e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY";
          });
      }
    }
    if (config.policyIPMasqueradeType1List) {
      for (const policyIPMasquerade of config.policyIPMasqueradeType1List) {
        delete policyIPMasquerade.total;
        const relation = getZoneRelation(
          zoneRelationList,
          policyIPMasquerade.zoneRelationId
        );
        policyIPMasquerade.srcZoneId = relation.srcZoneId;
        policyIPMasquerade.dstZoneId = relation.dstZoneId;
        delete policyIPMasquerade.zoneRelationId;
        policyIPMasquerade.policyList = policyIPMasquerade.policyList.filter(
          (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
        );
      }
    }
    if (config.policyNatType1List) {
      for (const policyNat of config.policyNatType1List) {
        delete policyNat.total;
        const relation = getZoneRelation(
          zoneRelationList,
          policyNat.zoneRelationId
        );
        policyNat.srcZoneId = relation.srcZoneId;
        policyNat.dstZoneId = relation.dstZoneId;
        delete policyNat.zoneRelationId;
      }
    }
    delete config.policyKeyId;

    this.file.downloadJson(`internet-type1-${appliance}-${now()}.conf`, config);
  }

  /**
   * インターネットType1ポリシーのアップロード処理
   * @param vpnCode VPNコード
   * @param appliance 契約
   * @param policy マージ元となるポリシー
   * @param zoneRelationList ゾーンリレーション一覧
   */
  async uploadInternetType1Policy(
    vpnCode: string,
    appliance: "IFW" | "UTM",
    policy: GetPolicyType1,
    zoneRelationList: ZoneRelationItem[]
  ): Promise<GetPolicyType1> {
    const content = await this.file.uploadFile(".conf");
    const config = isJSON<InternetType1PolicyConfig>(content);

    // アップロード内容のチェックを行う
    if (!config) {
      throw new UploadError("MIME");
    }
    if (config.version !== "v1") {
      throw new UploadError("VERSION");
    }
    if (config.feature !== "internetType1") {
      throw new UploadError("FEATURE");
    }
    if (config.vpnCode !== vpnCode) {
      throw new UploadError("LINE_SEQ");
    }
    if (config.appliance !== appliance) {
      throw new UploadError("APPLIANCE");
    }

    // 不要な項目を削除する
    delete config.version;
    delete config.feature;
    delete config.vpnCode;
    delete config.appliance;

    // 元のポリシーにアップロードされたポリシーをマージ.
    // 既存の編集不可デフォルトポリシー以外を削除して
    // 最後尾の編集不可デフォルトポリシー（999）の前にアップロードされたポリシーを追加
    // もし編集不可デフォルトポリシー（999）がない場合は最後尾にポリシーを追加
    const result = cloneDeep(policy);

    if (result.policyUtmType1) {
      for (const internetToAccessPoint of result.policyUtmType1
        .internetToAccessPointList) {
        const relation = getZoneRelation(
          zoneRelationList,
          internetToAccessPoint.zoneRelationId
        );
        const configPolicy =
          config.policyUtmType1?.internetToAccessPointList?.find(
            (v) =>
              v?.srcZoneId === relation.srcZoneId &&
              v?.dstZoneId === relation.dstZoneId
          );
        if (configPolicy) {
          // Frontエラーを避けるため、Valueのガード処理
          const i2aPolicyList = configPolicy.policyList
            ? configPolicy.policyList.map((v) => {
                return {
                  ...v,
                  srcAddress: v!.srcAddress
                    ? {
                        srcAddressList: v!.srcAddress?.srcAddressList
                          ? v!.srcAddress?.srcAddressList
                          : [],
                        srcFqdnList: v!.srcAddress?.srcFqdnList
                          ? v!.srcAddress?.srcFqdnList
                          : [],
                        srcCountryList: v!.srcAddress?.srcCountryList
                          ? v!.srcAddress?.srcCountryList
                          : [],
                      }
                    : {
                        srcAddressList: [],
                        srcFqdnList: [],
                        srcCountryList: [],
                      },
                  dstAddress: v!.dstAddress
                    ? { dstAddressList: v!.dstAddress?.dstAddressList }
                    : { dstAddressList: [] },
                  profile: v!.profile
                    ? v!.profile
                    : {
                        internetFW: "OFF",
                        idsIps: "OFF",
                        urlFiltering: "OFF",
                        webAntiVirus: "OFF",
                        mailAntiVirus: "OFF",
                        isAntiSpyware: false,
                      },
                  serviceOption: v!.serviceOption ? v!.serviceOption : "ANY",
                  defaultServiceList: v!.defaultServiceList
                    ? v!.defaultServiceList
                    : [],
                  customServiceList: v!.customServiceList
                    ? v!.customServiceList
                    : [],
                };
              })
            : [];
          // 画面値の上書き
          internetToAccessPoint.policyList = mergePolicy(
            internetToAccessPoint.policyList,
            i2aPolicyList
          );
        }
      }
      for (const accessPointToInternet of result.policyUtmType1
        .accessPointToInternetList) {
        const relation = getZoneRelation(
          zoneRelationList,
          accessPointToInternet.zoneRelationId
        );
        const configPolicy =
          config.policyUtmType1?.accessPointToInternetList?.find(
            (v) =>
              v?.srcZoneId === relation.srcZoneId &&
              v?.dstZoneId === relation.dstZoneId
          );
        if (configPolicy) {
          // Frontエラーを避けるため、Valueのガード処理
          const a2iPolicyList = configPolicy.policyList
            ? configPolicy.policyList.map((v) => {
                return {
                  ...v,
                  srcAddress: v!.srcAddress
                    ? { srcAddressList: v!.srcAddress?.srcAddressList }
                    : { srcAddressList: [] },
                  dstAddress: v!.dstAddress
                    ? {
                        dstAddressList: v!.dstAddress?.dstAddressList
                          ? v!.dstAddress?.dstAddressList
                          : [],
                        dstFqdnList: v!.dstAddress?.dstFqdnList
                          ? v!.dstAddress?.dstFqdnList
                          : [],
                        dstCountryList: v!.dstAddress?.dstCountryList
                          ? v!.dstAddress?.dstCountryList
                          : [],
                      }
                    : {
                        dstAddressList: [],
                        dstFqdnList: [],
                        dstCountryList: [],
                      },

                  profile: v!.profile
                    ? v!.profile
                    : {
                        internetFW: "OFF",
                        idsIps: "OFF",
                        urlFiltering: "OFF",
                        webAntiVirus: "OFF",
                        mailAntiVirus: "OFF",
                        isAntiSpyware: false,
                      },
                  serviceOption: v!.serviceOption ? v!.serviceOption : "ANY",
                  defaultServiceList: v!.defaultServiceList
                    ? v!.defaultServiceList
                    : [],
                  customServiceList: v!.customServiceList
                    ? v!.customServiceList
                    : [],
                };
              })
            : [];
          // 画面値の上書き
          accessPointToInternet.policyList = mergePolicy(
            accessPointToInternet.policyList,
            a2iPolicyList
          );
        }
      }
    }
    if (result.policyIfwType1) {
      for (const internetToAccessPoint of result.policyIfwType1
        .internetToAccessPointList) {
        const relation = getZoneRelation(
          zoneRelationList,
          internetToAccessPoint.zoneRelationId
        );
        const configPolicy =
          config.policyIfwType1?.internetToAccessPointList?.find(
            (v) =>
              v?.srcZoneId === relation.srcZoneId &&
              v?.dstZoneId === relation.dstZoneId
          );
        if (configPolicy) {
          // Frontエラーを避けるため、Valueのガード処理
          const i2aPolicyList = configPolicy.policyList
            ? configPolicy.policyList.map((v) => {
                return {
                  ...v,
                  srcAddress: v!.srcAddress
                    ? {
                        srcAddressList: v!.srcAddress?.srcAddressList
                          ? v!.srcAddress?.srcAddressList
                          : [],
                        srcFqdnList: v!.srcAddress?.srcFqdnList
                          ? v!.srcAddress?.srcFqdnList
                          : [],
                        srcCountryList: v!.srcAddress?.srcCountryList
                          ? v!.srcAddress?.srcCountryList
                          : [],
                      }
                    : {
                        srcAddressList: [],
                        srcFqdnList: [],
                        srcCountryList: [],
                      },
                  dstAddress: v!.dstAddress
                    ? { dstAddressList: v!.dstAddress?.dstAddressList }
                    : { dstAddressList: [] },
                  profile: v!.profile
                    ? v!.profile
                    : {
                        internetFW: "OFF",
                      },
                  serviceOption: v!.serviceOption ? v!.serviceOption : "ANY",
                  defaultServiceList: v!.defaultServiceList
                    ? v!.defaultServiceList
                    : [],
                  customServiceList: v!.customServiceList
                    ? v!.customServiceList
                    : [],
                };
              })
            : [];
          // 画面値の上書き
          internetToAccessPoint.policyList = mergePolicy(
            internetToAccessPoint.policyList,
            i2aPolicyList
          );
        }
      }
      for (const accessPointToInternet of result.policyIfwType1
        .accessPointToInternetList) {
        const relation = getZoneRelation(
          zoneRelationList,
          accessPointToInternet.zoneRelationId
        );
        const configPolicy =
          config.policyIfwType1?.accessPointToInternetList?.find(
            (v) =>
              v?.srcZoneId === relation.srcZoneId &&
              v?.dstZoneId === relation.dstZoneId
          );
        if (configPolicy) {
          // Frontエラーを避けるため、Valueのガード処理
          const a2iPolicyList = configPolicy.policyList
            ? configPolicy.policyList.map((v) => {
                return {
                  ...v,
                  srcAddress: v!.srcAddress
                    ? { srcAddressList: v!.srcAddress?.srcAddressList }
                    : { srcAddressList: [] },
                  dstAddress: v!.dstAddress
                    ? {
                        dstAddressList: v!.dstAddress?.dstAddressList
                          ? v!.dstAddress?.dstAddressList
                          : [],
                        dstFqdnList: v!.dstAddress?.dstFqdnList
                          ? v!.dstAddress?.dstFqdnList
                          : [],
                        dstCountryList: v!.dstAddress?.dstCountryList
                          ? v!.dstAddress?.dstCountryList
                          : [],
                      }
                    : {
                        dstAddressList: [],
                        dstFqdnList: [],
                        dstCountryList: [],
                      },

                  profile: v!.profile
                    ? v!.profile
                    : {
                        internetFW: "OFF",
                      },
                  serviceOption: v!.serviceOption ? v!.serviceOption : "ANY",
                  defaultServiceList: v!.defaultServiceList
                    ? v!.defaultServiceList
                    : [],
                  customServiceList: v!.customServiceList
                    ? v!.customServiceList
                    : [],
                };
              })
            : [];
          // 画面値の上書き
          accessPointToInternet.policyList = mergePolicy(
            accessPointToInternet.policyList,
            a2iPolicyList
          );
        }
      }
    }
    if (result.policyIPMasqueradeType1List) {
      for (const policyIPMasquerade of result.policyIPMasqueradeType1List) {
        const relation = getZoneRelation(
          zoneRelationList,
          policyIPMasquerade.zoneRelationId
        );
        const configPolicy = config.policyIPMasqueradeType1List?.find(
          (v) =>
            v?.srcZoneId === relation.srcZoneId &&
            v?.dstZoneId === relation.dstZoneId
        );
        if (configPolicy) {
          // Frontエラーを避けるため、Valueのガード処理
          const ipMasqueradePolicyList = configPolicy.policyList
            ? configPolicy.policyList.map((v) => {
                return {
                  ...v,
                  srcAddressList: v!.srcAddressList ? v!.srcAddressList : [],
                  dstAddressList: v!.dstAddressList ? v!.dstAddressList : [],
                  ipPoolList: v!.ipPoolList ? v!.ipPoolList : [],
                };
              })
            : [];
          // 画面値の上書き
          policyIPMasquerade.policyList = mergePolicy(
            policyIPMasquerade.policyList,
            ipMasqueradePolicyList
          );
        }
      }
    }
    if (result.policyNatType1List) {
      for (const policyNat of result.policyNatType1List) {
        const relation = getZoneRelation(
          zoneRelationList,
          policyNat.zoneRelationId
        );
        const configPolicy = config.policyNatType1List?.find(
          (v) =>
            v?.srcZoneId === relation.srcZoneId &&
            v?.dstZoneId === relation.dstZoneId
        );
        if (configPolicy) {
          // Frontエラーを避けるため、Valueのガード処理
          policyNat.policyList = configPolicy.policyList
            ? (configPolicy.policyList.map((v) => {
                return {
                  ...v,
                  srcAddressList: v?.srcAddressList ? v.srcAddressList : [],
                };
              }) as NonNullable<GetPolicyType4["policyNatType4"]>["policyList"])
            : [];
        }
      }
    }

    return result;
  }

  /**
   * インターネットType4ポリシーのダウンロード
   * @param type4Id Type4ID
   * @param appliance 契約
   * @param policy ダウンロード対象
   */
  downloadInternetType4Policy(
    type4Id: string,
    appliance: "IFW" | "UTM",
    policy: GetPolicyType4
  ): void {
    // ダウンロード用に内容を整形
    const config = {
      version: "v1",
      feature: "internetType4",
      type4Id: type4Id,
      appliance: appliance,
      ...cloneDeep(policy),
    } as InternetType4PolicyConfig;
    if (config.policyUtmType4) {
      delete config.policyUtmType4.internetToAccessPointTotal;
      delete config.policyUtmType4.accessPointToInternetTotal;
      config.policyUtmType4.accessPointToInternetPolicyList =
        config.policyUtmType4.accessPointToInternetPolicyList.filter(
          (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
        );
      config.policyUtmType4.internetToAccessPointPolicyList =
        config.policyUtmType4.internetToAccessPointPolicyList.filter(
          (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
        );
    }
    if (config.policyIfwType4) {
      delete config.policyIfwType4.internetToAccessPointTotal;
      delete config.policyIfwType4.accessPointToInternetTotal;
      config.policyIfwType4.accessPointToInternetPolicyList =
        config.policyIfwType4.accessPointToInternetPolicyList.filter(
          (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
        );
      config.policyIfwType4.internetToAccessPointPolicyList =
        config.policyIfwType4.internetToAccessPointPolicyList.filter(
          (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
        );
    }
    if (config.policyIPMasqueradeType4) {
      delete config.policyIPMasqueradeType4.total;
      config.policyIPMasqueradeType4.policyList =
        config.policyIPMasqueradeType4.policyList.filter(
          (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
        );
    }
    delete config.policyNatType4?.total;
    delete config.policyKeyId;

    this.file.downloadJson(
      `internet-type4-${type4Id}-${appliance}-${now()}.conf`,
      config
    );
  }

  /**
   * インターネットType4ポリシーをアップロード
   * @param type4Id Type4ID
   * @param appliance 契約
   * @param policy マージ元になるType4ポリシー
   */
  async uploadInternetType4Policy(
    type4Id: string,
    appliance: "IFW" | "UTM",
    policy: GetPolicyType4
  ): Promise<GetPolicyType4> {
    const content = await this.file.uploadFile(".conf");
    const config = isJSON<InternetType4PolicyConfig>(content);
    if (!config) {
      throw new UploadError("MIME");
    }
    if (config.version !== "v1") {
      throw new UploadError("VERSION");
    }
    if (config.feature !== "internetType4") {
      throw new UploadError("FEATURE");
    }
    if (config.type4Id !== type4Id) {
      throw new UploadError("LINE_SEQ");
    }
    if (config.appliance !== appliance) {
      throw new UploadError("APPLIANCE");
    }

    delete config.version;
    delete config.feature;
    delete config.type4Id;
    delete config.appliance;

    // 元のポリシーにアップロードされたポリシーをマージ.
    // 既存の編集不可デフォルトポリシー以外を削除して
    // 最後尾の編集不可デフォルトポリシー（999）の前にアップロードされたポリシーを追加
    // もし編集不可デフォルトポリシー（999）がない場合は最後尾にポリシーを追加
    const result = cloneDeep(policy);
    if (result.policyUtmType4) {
      // Frontエラーを避けるため、Valueのガード処理
      const i2aPolicyList = config.policyUtmType4
        ?.internetToAccessPointPolicyList
        ? config.policyUtmType4?.internetToAccessPointPolicyList.map((v) => {
            return {
              ...v,
              srcAddress: v!.srcAddress
                ? {
                    srcAddressList: v!.srcAddress?.srcAddressList
                      ? v!.srcAddress?.srcAddressList
                      : [],
                    srcFqdnList: v!.srcAddress?.srcFqdnList
                      ? v!.srcAddress?.srcFqdnList
                      : [],
                    srcCountryList: v!.srcAddress?.srcCountryList
                      ? v!.srcAddress?.srcCountryList
                      : [],
                  }
                : { srcAddressList: [], srcFqdnList: [], srcCountryList: [] },
              dstAddress: v!.dstAddress
                ? { dstAddressList: v!.dstAddress?.dstAddressList }
                : { dstAddressList: [] },
              profile: v!.profile
                ? v!.profile
                : {
                    internetFW: "OFF",
                    idsIps: "OFF",
                    urlFiltering: "OFF",
                    webAntiVirus: "OFF",
                    mailAntiVirus: "OFF",
                    isAntiSpyware: false,
                  },
              serviceOption: v!.serviceOption ? v!.serviceOption : "ANY",
              defaultServiceList: v!.defaultServiceList
                ? v!.defaultServiceList
                : [],
              customServiceList: v!.customServiceList
                ? v!.customServiceList
                : [],
            };
          })
        : [];
      // 画面値の上書き
      result.policyUtmType4.internetToAccessPointPolicyList = mergePolicy(
        result.policyUtmType4.internetToAccessPointPolicyList,
        i2aPolicyList
      );
      // Frontエラーを避けるため、Valueのガード処理
      const a2iPolicyList = config.policyUtmType4
        ?.accessPointToInternetPolicyList
        ? config.policyUtmType4?.accessPointToInternetPolicyList.map((v) => {
            return {
              ...v,
              srcAddress: v!.srcAddress
                ? { srcAddressList: v!.srcAddress?.srcAddressList }
                : { srcAddressList: [] },
              dstAddress: v!.dstAddress
                ? {
                    dstAddressList: v!.dstAddress?.dstAddressList
                      ? v!.dstAddress?.dstAddressList
                      : [],
                    dstFqdnList: v!.dstAddress?.dstFqdnList
                      ? v!.dstAddress?.dstFqdnList
                      : [],
                    dstCountryList: v!.dstAddress?.dstCountryList
                      ? v!.dstAddress?.dstCountryList
                      : [],
                  }
                : { dstAddressList: [], dstFqdnList: [], dstCountryList: [] },

              profile: v!.profile
                ? v!.profile
                : {
                    internetFW: "OFF",
                    idsIps: "OFF",
                    urlFiltering: "OFF",
                    webAntiVirus: "OFF",
                    mailAntiVirus: "OFF",
                    isAntiSpyware: false,
                  },
              serviceOption: v!.serviceOption ? v!.serviceOption : "ANY",
              defaultServiceList: v!.defaultServiceList
                ? v!.defaultServiceList
                : [],
              customServiceList: v!.customServiceList
                ? v!.customServiceList
                : [],
            };
          })
        : [];
      // 画面値の上書き
      result.policyUtmType4.accessPointToInternetPolicyList = mergePolicy(
        result.policyUtmType4.accessPointToInternetPolicyList,
        a2iPolicyList
      );
    }
    if (result.policyIfwType4) {
      // Frontエラーを避けるため、Valueのガード処理
      const i2aPolicyList = config.policyIfwType4
        ?.internetToAccessPointPolicyList
        ? config.policyIfwType4?.internetToAccessPointPolicyList.map((v) => {
            return {
              ...v,
              srcAddress: v!.srcAddress
                ? {
                    srcAddressList: v!.srcAddress?.srcAddressList
                      ? v!.srcAddress?.srcAddressList
                      : [],
                    srcFqdnList: v!.srcAddress?.srcFqdnList
                      ? v!.srcAddress?.srcFqdnList
                      : [],
                    srcCountryList: v!.srcAddress?.srcCountryList
                      ? v!.srcAddress?.srcCountryList
                      : [],
                  }
                : { srcAddressList: [], srcFqdnList: [], srcCountryList: [] },
              dstAddress: v!.dstAddress
                ? { dstAddressList: v!.dstAddress?.dstAddressList }
                : { dstAddressList: [] },
              profile: v!.profile
                ? v!.profile
                : {
                    internetFW: "OFF",
                  },
              serviceOption: v!.serviceOption ? v!.serviceOption : "ANY",
              defaultServiceList: v!.defaultServiceList
                ? v!.defaultServiceList
                : [],
              customServiceList: v!.customServiceList
                ? v!.customServiceList
                : [],
            };
          })
        : [];
      // 画面値の上書き
      result.policyIfwType4.internetToAccessPointPolicyList = mergePolicy(
        result.policyIfwType4.internetToAccessPointPolicyList,
        i2aPolicyList
      );
      // Frontエラーを避けるため、Valueのガード処理
      const a2iPolicyList = config.policyIfwType4
        ?.accessPointToInternetPolicyList
        ? config.policyIfwType4?.accessPointToInternetPolicyList.map((v) => {
            return {
              ...v,
              srcAddress: v!.srcAddress
                ? { srcAddressList: v!.srcAddress?.srcAddressList }
                : { srcAddressList: [] },
              dstAddress: v!.dstAddress
                ? {
                    dstAddressList: v!.dstAddress?.dstAddressList
                      ? v!.dstAddress?.dstAddressList
                      : [],
                    dstFqdnList: v!.dstAddress?.dstFqdnList
                      ? v!.dstAddress?.dstFqdnList
                      : [],
                    dstCountryList: v!.dstAddress?.dstCountryList
                      ? v!.dstAddress?.dstCountryList
                      : [],
                  }
                : { dstAddressList: [], dstFqdnList: [], dstCountryList: [] },

              profile: v!.profile
                ? v!.profile
                : {
                    internetFW: "OFF",
                  },
              serviceOption: v!.serviceOption ? v!.serviceOption : "ANY",
              defaultServiceList: v!.defaultServiceList
                ? v!.defaultServiceList
                : [],
              customServiceList: v!.customServiceList
                ? v!.customServiceList
                : [],
            };
          })
        : [];
      // 画面値の上書き
      result.policyIfwType4.accessPointToInternetPolicyList = mergePolicy(
        result.policyIfwType4.accessPointToInternetPolicyList,
        a2iPolicyList
      );
    }
    if (result.policyIPMasqueradeType4) {
      // Frontエラーを避けるため、Valueのガード処理
      const ipMasqueradePolicyList = config.policyIPMasqueradeType4?.policyList
        ? config.policyIPMasqueradeType4?.policyList.map((v) => {
            return {
              ...v,
              srcAddressList: v!.srcAddressList ? v!.srcAddressList : [],
              dstAddressList: v!.dstAddressList ? v!.dstAddressList : [],
              ipPoolList: v!.ipPoolList ? v!.ipPoolList : [],
            };
          })
        : [];
      // 画面値の上書き
      result.policyIPMasqueradeType4.policyList = mergePolicy(
        result.policyIPMasqueradeType4.policyList,
        ipMasqueradePolicyList
      );
    }
    if (result.policyNatType4) {
      // Frontエラーを避けるため、Valueのガード処理
      result.policyNatType4.policyList = config.policyNatType4?.policyList
        ? (config.policyNatType4?.policyList.map((v) => {
            return {
              ...v,
              srcAddressList: v?.srcAddressList ? v.srcAddressList : [],
            };
          }) as NonNullable<GetPolicyType4["policyNatType4"]>["policyList"])
        : [];
    }

    return result;
  }

  /**
   * イントラネットFWポリシーのダウンロード
   * @param vpnCode VPNコード
   * @param policy ダウンロード対象
   * @param zoneRelationList ゾーンリレーション一覧
   */
  downloadIntranetFwPolicy(
    vpnCode: string,
    policy: IntranetFwPolicy,
    zoneRelationList: ZoneRelationItem[]
  ): void {
    // ダウンロード用に内容を整形
    const config = {
      version: "v1",
      feature: "intranetFw",
      vpnCode: vpnCode,
      ...cloneDeep(policy),
    } as IntranetFwPolicyConfig;
    for (const privateToPrivate of config.privateToPrivateList) {
      delete privateToPrivate.total;
      const relation = getZoneRelation(
        zoneRelationList,
        privateToPrivate.zoneRelationId
      );
      privateToPrivate.srcZoneId = relation.srcZoneId;
      privateToPrivate.dstZoneId = relation.dstZoneId;
      delete privateToPrivate.zoneRelationId;
      privateToPrivate.policyList = privateToPrivate.policyList.filter((e) => {
        return e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY";
      });
    }
    for (const extraToPrivate of config.extraToPrivateList) {
      delete extraToPrivate.total;
      const relation = getZoneRelation(
        zoneRelationList,
        extraToPrivate.zoneRelationId
      );
      extraToPrivate.srcZoneId = relation.srcZoneId;
      extraToPrivate.dstZoneId = relation.dstZoneId;
      delete extraToPrivate.zoneRelationId;
      extraToPrivate.policyList = extraToPrivate.policyList.filter((e) => {
        return e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY";
      });
    }
    for (const privateToExtra of config.privateToExtraList) {
      delete privateToExtra.total;
      const relation = getZoneRelation(
        zoneRelationList,
        privateToExtra.zoneRelationId
      );
      privateToExtra.srcZoneId = relation.srcZoneId;
      privateToExtra.dstZoneId = relation.dstZoneId;
      delete privateToExtra.zoneRelationId;
      privateToExtra.policyList = privateToExtra.policyList.filter((e) => {
        return e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY";
      });
    }
    delete config.wvs2ToKcpsList;
    delete config.kcpsToWvs2List;
    delete config.policyIntranetFwKeyId;
    this.file.downloadJson(`intranetFW-${now()}.conf`, config);
  }

  /**
   * イントラネットFWの設定アップロード処理
   * @param vpnCode VPNコード
   * @param policy マージ元となるポリシー
   * @param zoneRelationList ゾーンリレーション一覧
   */
  async uploadIntranetFwPolicy(
    vpnCode: string,
    policy: IntranetFwPolicy,
    zoneRelationList: ZoneRelationItem[]
  ): Promise<IntranetFwPolicy> {
    const content = await this.file.uploadFile(".conf");
    const config = isJSON<IntranetFwPolicyConfig>(content);

    // アップロード内容のチェックを行う
    if (!config) {
      throw new UploadError("MIME");
    }
    if (config.version !== "v1") {
      throw new UploadError("VERSION");
    }
    if (config.feature !== "intranetFw") {
      throw new UploadError("FEATURE");
    }
    if (config.vpnCode !== vpnCode) {
      throw new UploadError("LINE_SEQ");
    }

    // 不要な項目を削除する
    delete config.version;
    delete config.feature;
    delete config.vpnCode;

    // 元のポリシーにアップロードされたポリシーをマージ.
    // 既存の編集不可デフォルトポリシー以外を削除して
    // 最後尾の編集不可デフォルトポリシー（999）の前にアップロードされたポリシーを追加
    // もし編集不可デフォルトポリシー（999）がない場合は最後尾にポリシーを追加
    const result = cloneDeep(policy);

    for (const privateToPrivate of result.privateToPrivateList) {
      // 一致するゾーン接続が持つポリシーをマージする
      const relation = getZoneRelation(
        zoneRelationList,
        privateToPrivate.zoneRelationId
      );
      const configPolicy = config.privateToPrivateList?.find(
        (v) =>
          v?.srcZoneId === relation.srcZoneId &&
          v?.dstZoneId === relation.dstZoneId
      );
      if (configPolicy) {
        privateToPrivate.policyList = mergePolicy(
          privateToPrivate.policyList,
          configPolicy.policyList
        );
      }
    }

    for (const extraToPrivate of result.extraToPrivateList) {
      const relation = getZoneRelation(
        zoneRelationList,
        extraToPrivate.zoneRelationId
      );
      const configPolicy = config.extraToPrivateList?.find(
        (v) =>
          v?.srcZoneId === relation.srcZoneId &&
          v?.dstZoneId === relation.dstZoneId
      );
      if (configPolicy) {
        extraToPrivate.policyList = mergePolicy(
          extraToPrivate.policyList,
          configPolicy.policyList
        );
      }
    }

    for (const privateToExtra of result.privateToExtraList) {
      const relation = getZoneRelation(
        zoneRelationList,
        privateToExtra.zoneRelationId
      );
      const configPolicy = config.privateToExtraList?.find(
        (v) =>
          v?.srcZoneId === relation.srcZoneId &&
          v?.dstZoneId === relation.dstZoneId
      );
      if (configPolicy) {
        privateToExtra.policyList = mergePolicy(
          privateToExtra.policyList,
          configPolicy.policyList
        );
      }
    }

    return result;
  }

  /**
   * イントラネットFW PFGWポリシーのダウンロード
   * @param vpnCode VPNコード
   * @param enumberAct E番号(ACT)
   * @param policy ダウンロード対象
   */
  downloadIntranetFwPfgwPolicy(
    vpnCode: string,
    enumberAct: string,
    policy: IntranetFwPolicy
  ): void {
    // ダウンロード用に内容を整形
    const config = {
      version: "v1",
      feature: "intranetFwPFGW",
      vpnCode: vpnCode,
      enumberAct: enumberAct,
      ...cloneDeep(policy),
    } as IntranetFwPfgwPolicyConfig;
    if (config.wvs2ToKcpsList) {
      config.wvs2ToKcpsList = config.wvs2ToKcpsList.map((e) => {
        return {
          // total削除 + 編集不可ポリシー除外
          enumberAct: e.enumberAct,
          zoneRelationId: e.zoneRelationId,
          policyList: e.policyList.filter(
            (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
          ),
        };
      }) as IntranetFwPfgwPolicyConfig["wvs2ToKcpsList"];
    }
    if (config.kcpsToWvs2List) {
      config.kcpsToWvs2List = config.kcpsToWvs2List.map((e) => {
        return {
          // total削除 + 編集不可ポリシー除外
          enumberAct: e.enumberAct,
          zoneRelationId: e.zoneRelationId,
          policyList: e.policyList.filter(
            (e) => e.policyCategoryInfo !== "UNEDITABLE_DEFAULT_POLICY"
          ),
        };
      }) as IntranetFwPfgwPolicyConfig["kcpsToWvs2List"];
    }
    delete config.privateToPrivateList;
    delete config.extraToPrivateList;
    delete config.privateToExtraList;
    delete config.policyIntranetFwKeyId;
    this.file.downloadJson(`intranetFW-PFGW-${now()}.conf`, config);
  }

  /**
   * イントラネットFW PFGWの設定アップロード処理
   * @param vpnCode VPNコード
   * @param enumberAct E番号(ACT)
   * @param policy マージ元となるポリシー
   */
  async uploadIntranetFwPfgwPolicy(
    vpnCode: string,
    enumberAct: string,
    policy: IntranetFwPolicy
  ): Promise<IntranetFwPolicy> {
    const content = await this.file.uploadFile(".conf");
    const config = isJSON<IntranetFwPfgwPolicyConfig>(content);

    // アップロード内容のチェックを行う
    if (!config) {
      throw new UploadError("MIME");
    }
    if (config.version !== "v1") {
      throw new UploadError("VERSION");
    }
    if (config.feature !== "intranetFwPFGW") {
      throw new UploadError("FEATURE");
    }
    if (config.vpnCode !== vpnCode) {
      throw new UploadError("LINE_SEQ");
    }
    if (config.enumberAct !== enumberAct) {
      throw new UploadError("LINE_SEQ");
    }

    // 不要な項目を削除する
    delete config.version;
    delete config.feature;
    delete config.vpnCode;
    delete config.enumberAct;

    // 元のポリシーにアップロードされたポリシーをマージまたは追加.
    // 既存の編集不可デフォルトポリシー以外を削除して
    // 最後尾の編集不可デフォルトポリシー（999）の前にアップロードされたポリシーを追加
    // もし編集不可デフォルトポリシー（999）がない場合は最後尾にポリシーを追加
    const result = cloneDeep(policy);
    if (config.wvs2ToKcpsList) {
      // アップロード側にE番号が一致するものがある場合はマージ、一致しない場合は追加
      result.wvs2ToKcpsList = config.wvs2ToKcpsList.map((info) => {
        const matchZone = result.wvs2ToKcpsList.find(
          (e) => e.enumberAct === info?.enumberAct
        );
        if (matchZone) {
          return {
            ...matchZone,
            policyList: mergePolicy(matchZone.policyList, info?.policyList),
          };
        }
        return info as IntranetFwPolicy["wvs2ToKcpsList"][0];
      });
    }
    if (config.kcpsToWvs2List) {
      // アップロード側にE番号が一致するものがある場合はマージ、一致しない場合は追加
      result.kcpsToWvs2List = config.kcpsToWvs2List.map((info) => {
        const matchZone = result.kcpsToWvs2List?.find(
          (e) => e.enumberAct === info?.enumberAct
        );
        if (matchZone) {
          return {
            ...matchZone,
            policyList: mergePolicy(matchZone.policyList, info?.policyList),
          };
        }
        return info as IntranetFwPolicy["kcpsToWvs2List"][0];
      });
    }

    return result;
  }

  /**
   * IPsec回線のダウンロード
   * @param vnumber V番号
   * @param operationType 操作タイプ
   * @param ipSecLine IPsec回線情報
   */
  downloadIpsecLine(
    vnumber: string,
    operationType: "add" | "modify",
    ipSecLine: Partial<IpSecLineWithGW>
  ): void {
    // ダウンロード用に内容を整形
    const config: IPsecLineConfig = {
      version: "v1",
      feature: "ipsec",
      vnumber: vnumber,
      operationType: operationType,
      ipsecLineName: ipSecLine.ipsecLineName ?? "",
      preSharedKey: ipSecLine.preSharedKey ?? "",
      connectTestIpAddress: ipSecLine.connectTestIpAddress ?? "",
      description: ipSecLine.description ?? "",
      xnumber: ipSecLine.xnumber,
      enumber:
        operationType === "add" ? ipSecLine.gw?.enumber ?? "" : undefined,
      userAccount:
        operationType === "add" ? ipSecLine.userAccount ?? "" : undefined,
      lanIpAddressList: ipSecLine
        .lanIpAddressList!.filter((e) => e.ipAddress)
        .map((e) =>
          operationType === "modify" ? e : { ipAddress: e.ipAddress }
        ),
    };
    this.file.downloadJson(`ipsec-${now()}.conf`, config);
  }

  /** IPsec回線をアップロード
   * @param vnumber V番号
   * @param operationType 操作タイプ
   * @param xnumber IPsecGW回線番号（変更時のみ使う）
   */
  async uploadIpsecLine(
    vnumber: string,
    operationType: "add" | "modify",
    xnumber?: string
  ): Promise<IPsecLineConfig> {
    const content = await this.file.uploadFile(".conf");
    const config = isJSON<IPsecLineConfig>(content);
    if (!config) {
      throw new UploadError("MIME");
    }
    if (config.version !== "v1") {
      throw new UploadError("VERSION");
    }
    if (config.feature !== "ipsec") {
      throw new UploadError("FEATURE");
    }
    if (config.vnumber !== vnumber) {
      throw new UploadError("APPLIANCE");
    }
    if (operationType === "modify" && config.xnumber !== xnumber) {
      throw new UploadError("LINE_SEQ");
    }
    if (config.operationType !== operationType) {
      throw new UploadError(
        operationType === "add" ? "OPERATION_TYPE_ADD" : "OPERATION_TYPE_MODIFY"
      );
    }

    return config as IPsecLineConfig;
  }

  /**
   * VNコネクト設定ダウンロード
   * @param vnumber W番号(VNコネクト、クラウドVNコネクトの場合必要)
   * @param siteId サイトID（追加時のみ必要）
   * @param wnumber W番号（変更時のみ必要）
   * @param operationType 操作タイプ
   * @param vnconnect VNコネクト回線情報
   */
  downloadVNConnect(
    vnumber: string,
    siteId: string | undefined | null,
    wnumber: string | undefined,
    operationType: "add" | "modify",
    vnconnect: VNConnectForm
  ): void {
    // ダウンロード用に内容を整形
    const config: VNConnectConfig = {
      version: "v1",
      feature: "vnconnect",
      vnumber: vnumber,
      siteId: siteId === undefined ? undefined : siteId,
      wnumber: wnumber,
      operationType: operationType,
      vnConnectName: vnconnect.vnConnectName,
      ...(operationType === "add"
        ? {
            enumber: vnconnect.siteInfo?.enumber ?? null,
            vlanIdType: vnconnect.siteInfo?.vlanIdType ?? null,
            vlanId: vnconnect.siteInfo?.vlanId ?? null,
            vnName: vnconnect.vpnVnCodeInfo?.vnName ?? null,
            vpnVnCode: vnconnect.vpnVnCodeInfo?.vpnVnCode ?? null,
            vnType: vnconnect.vpnVnCodeInfo?.vnType ?? null,
            siteName: vnconnect.siteInfo?.siteName ?? null,
            pairLine: vnconnect.pairLine,
          }
        : {}),
      bandwidthType: vnconnect.bandwidthType,
      bandwidth: vnconnect.bandwidth,
      qos: vnconnect.qos,
      l2: operationType === "add" ? vnconnect.l2 : undefined,
      l3: {
        ...vnconnect.l3,
        // static,bgp4,dhcpはON/OFFを見てOFFの場合は初期値を設定
        static:
          vnconnect.l3!.routingType === "STATIC" ? vnconnect.l3!.static : [],
        bgp4: vnconnect.l3!.routingType === "BGP4" ? vnconnect.l3!.bgp4 : null,
        routingType: undefined,
        dhcpRelay: vnconnect.l3!.enableDhcpRelay
          ? vnconnect.l3!.dhcpRelay
          : null,
        enableDhcpRelay: undefined,
      } as VNConnectConfig["l3"],
      description: vnconnect.description,
    };

    const fileName = wnumber
      ? `vnconnnect-${wnumber}-${now()}.conf`
      : `vnconnnect-${now()}.conf`;

    this.file.downloadJson(fileName, config);
  }

  /** VNコネクト設定アップロード
   * @param vnumber V番号
   * @param operationType 操作タイプ
   * @param wnumber W番号（変更時のみ使う）
   */
  async uploadVNConnect(
    vnumber: string,
    operationType: "add" | "modify",
    wnumber?: string
  ): Promise<VNConnectConfig> {
    const content = await this.file.uploadFile(".conf");
    const config = isJSON<VNConnectConfig>(content);
    if (!config) {
      throw new UploadError("MIME");
    }
    if (config.version !== "v1") {
      throw new UploadError("VERSION");
    }
    if (config.feature !== "vnconnect") {
      throw new UploadError("FEATURE");
    }
    if (config.vnumber !== vnumber) {
      throw new UploadError("APPLIANCE");
    }
    if (config.operationType !== operationType) {
      throw new UploadError(
        operationType === "add" ? "OPERATION_TYPE_ADD" : "OPERATION_TYPE_MODIFY"
      );
    }
    if (operationType === "modify" && config.wnumber !== wnumber) {
      throw new UploadError("LINE_SEQ");
    }

    delete config.version;
    delete config.feature;
    delete config.vnumber;
    delete config.operationType;
    delete config.wnumber;

    return config as VNConnectConfig;
  }

  /**
   * VN L2L3コネクトのダウンロード
   * @param vnumber V番号
   * @param wnumber W番号
   * @param operationType 操作タイプ
   * @param form 設定変更画面の入力フォーム
   * @param peer1Setting bgp4のPeer1有効は有効の場合、true
   * @param peer2Setting bgp4のPeer2有効は有効の場合、true
   * @param vnL2L3ConnectDetail 変更画面の場合、L2L3コネクトの詳細情報、追加の場合は指定不要
   * @param l2VnSelect 追加画面の場合、L2VNの選択内容、変更の場合は指定不要
   * @param l3VnSelect 追加画面の場合、L3VNの選択内容、変更の場合は指定不要
   */
  downloadVNL2L3Connect(
    vnumber: string,
    wnumber: string,
    operationType: "add" | "modify",
    form:
      | NullableProperty<CreateVNL2L3ConnectRequest, "routingType">
      | NullableProperty<UpdateVNL2L3ConnectRequest, "routingType">,
    peer1Setting: boolean,
    peer2Setting: boolean,
    vnL2L3ConnectDetail?: VNL2L3ConnectDetail | null,
    l2VnSelect?: VpnVnCode | null,
    l3VnSelect?: VpnVnCode | null
  ): void {
    if (operationType === "modify") {
      const l2l3vnConnect: Partial<NullableProperty<VNL2L3ConnectDetail>> = {
        ...vnL2L3ConnectDetail,
        vnConnectName: form.vnConnectName,
        routingType: form.routingType,
        description: form.description,
        bgp4: [],
        static: form.static,
        ospf: form.ospf,
      };
      if (peer1Setting) {
        l2l3vnConnect.bgp4!.push(form.bgp4![0]);
      }
      if (peer2Setting) {
        l2l3vnConnect.bgp4!.push(form.bgp4![1]);
      }

      // 変更時ダウンロード
      const config = {
        version: "v1",
        feature: "l2l3vnconnect",
        wnumber: wnumber,
        operationType: operationType,
        ...cloneDeep(l2l3vnConnect),
      };

      delete config.vnl2l3ConnectKeyId;
      delete config.ifStatus;
      delete config.l2VnName;
      delete config.l2VnCode;
      delete config.l3VpnVnCode;
      delete config.l3VpnVnName;
      delete config.enumber;
      delete config.l2VnVlanIdType;
      delete config.l2VnVlanId;
      delete config.vnl2l3VlanIdType;
      delete config.vnl2l3VnVlanId;
      delete config.wanAddress;
      if (config.routingType === "STATIC") {
        config.bgp4 = null;
        config.ospf = null;
      } else if (config.routingType === "OSPF") {
        config.bgp4 = null;
        config.static = [];
      } else {
        config.ospf = null;
        config.static = [];
      }
      this.file.downloadJson(`l2l3vnconnect-${wnumber}-${now()}.conf`, config);
    } else {
      // 追加時ダウンロード
      // L2VNの選択内容よりvnl2l3VlanIdTypeを生成
      const vnl2l3VlanIdType = (() => {
        if (l2VnSelect) {
          if (l2VnSelect.vlanIdType === "OTHER") {
            if ((form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId) {
              return "SPECIFIED";
            } else {
              return "UNTAG";
            }
          }
          return l2VnSelect.vlanIdType!;
        } else {
          return null;
        }
      })();
      // L2VNの選択内容よりvnl2l3VnVlanIdを生成
      const vnl2l3VnVlanId = (() => {
        if (l2VnSelect) {
          if (l2VnSelect.vlanIdType === "OTHER") {
            return (form as CreateVNL2L3ConnectRequest).vnl2l3VnVlanId;
          }
          return l2VnSelect.vlanId!;
        } else {
          return null;
        }
      })();
      const l2l3vnConnect: Partial<NullableProperty<VNL2L3ConnectDetail>> = {
        l2VnName: l2VnSelect ? l2VnSelect.vnName : null,
        l2VnCode: l2VnSelect ? l2VnSelect.vpnVnCode : null,
        l3VpnVnName: l3VnSelect ? l3VnSelect.vnName : null,
        l3VpnVnCode: l3VnSelect ? l3VnSelect.vpnVnCode : null,
        vnConnectName: form.vnConnectName ?? null,
        bgp4: [],
        static: form.static,
        ospf: form.ospf,
        description: form.description ?? null,
        enumber: (form as CreateVNL2L3ConnectRequest).enumber ?? null,
        l2VnVlanIdType: l2VnSelect ? l2VnSelect.vlanIdType : null,
        l2VnVlanId: l2VnSelect ? l2VnSelect.vlanId : null,
        vnl2l3VlanIdType,
        vnl2l3VnVlanId,
        routingType: form.routingType ?? null,
        wanAddress: (form as CreateVNL2L3ConnectRequest).wanAddress ?? null,
      };
      if (peer1Setting) {
        l2l3vnConnect.bgp4!.push(form.bgp4![0]);
      }
      if (peer2Setting) {
        l2l3vnConnect.bgp4!.push(form.bgp4![1]);
      }

      const config = {
        version: "v1",
        feature: "l2l3vnconnect",
        vnumber: vnumber,
        operationType: operationType,
        ...cloneDeep(l2l3vnConnect),
      };
      if (config.routingType === "STATIC") {
        config.bgp4 = null;
        config.ospf = null;
      } else if (config.routingType === "OSPF") {
        config.bgp4 = null;
        config.static = [];
      } else {
        config.ospf = null;
        config.static = [];
      }
      this.file.downloadJson(`l2l3vnconnect-${now()}.conf`, config);
    }
  }
  /**
   * VN L2L3コネクトをアップロード
   * @param operationType 操作タイプ
   * @param vnumber V番号（追加時のみ使う、変更時はundefinedで指定）
   * @param wnumber VNコネクト番号（変更時のみ使う、追加時はundefinedで指定）
   */
  async uploadVNL2L3Connect(
    operationType: "add" | "modify",
    vnumber?: string,
    wnumber?: string
  ): Promise<VNL2L3ConnectDetail> {
    const content = await this.file.uploadFile(".conf");
    const config = isJSON<VNL2L3ConnecrUpConfig>(content);
    if (!config) {
      throw new UploadError("MIME");
    }
    if (config.version !== "v1") {
      throw new UploadError("VERSION");
    }
    if (config.feature !== "l2l3vnconnect") {
      throw new UploadError("FEATURE");
    }
    if (config.vnumber !== vnumber) {
      throw new UploadError("APPLIANCE");
    }
    if (config.operationType !== operationType) {
      throw new UploadError(
        operationType === "add" ? "OPERATION_TYPE_ADD" : "OPERATION_TYPE_MODIFY"
      );
    }
    if (operationType === "modify" && config.wnumber !== wnumber) {
      throw new UploadError("LINE_SEQ");
    }
    return config as VNL2L3ConnectDetail;
  }

  /**
   * パケットフィルタリングルールの設定ダウンロード
   * @param menu メニュー
   * @param wnumber W番号(VNコネクト、クラウドVNコネクトの場合必要)
   * @param enumber E番号(アクセス回線の場合必要)
   * @param packetFilter パケットフィルタ設定情報
   */
  downloadPacketFilter(
    menu: string,
    wnumber: string | undefined,
    enumber: string | undefined,
    packetFilter: PacketFilterEntity[]
  ): void {
    const config = {
      version: "v1",
      feature: "packetfilter",
      wnumber: wnumber ?? wnumber,
      enumber: enumber ?? enumber,
      packetFilterRuleList: packetFilter.map((e) => ({
        packetFilterRuleSeq: e.packetFilterRuleSeq,
        protocol: e.protocol,
        action: e.action,
        srcAddress: e.srcAddress,
        srcPort: e.srcPort,
        dstAddress: e.dstAddress,
        dstPort: e.dstPort,
        filteringStatus: e.filteringStatus,
        description: e.description,
      })),
    };

    const fileName = wnumber
      ? menu === "MULTI_CLOUD_GW"
        ? `packetfilter-cloud-vnconnect-${wnumber}-${now()}.conf`
        : `packetfilter-vnconnect-${wnumber}-${now()}.conf`
      : `packetfilter-access-${enumber}-${now()}.conf`;
    this.file.downloadJson(fileName, config);
  }

  /** パケットフィルタリングルールの設定アップロード
   * @param wnumber W番号(VNコネクト、クラウドVNコネクトの場合必要)
   * @param enumber E番号(アクセス回線の場合必要)
   */

  async uploadPacketFilter(
    wnumber: string,
    enumber: string
  ): Promise<PacketFilterEntity[]> {
    const content = await this.file.uploadFile(".conf");
    const config = isJSON<PacketFilterConfig>(content);
    if (!config) {
      throw new UploadError("MIME");
    }
    if (config.version !== "v1") {
      throw new UploadError("VERSION");
    }
    if (config.feature !== "packetfilter") {
      throw new UploadError("FEATURE");
    }
    if (wnumber && wnumber !== config.wnumber) {
      throw new UploadError("LINE_SEQ");
    }
    if (enumber && enumber !== config.enumber) {
      throw new UploadError("LINE_SEQ");
    }

    delete config.version;
    delete config.feature;
    delete config.wnumber;
    delete config.enumber;

    return config.packetFilterRuleList as PacketFilterEntity[];
  }

  /**
   * CPA NW設定のダウンロード
   * @param vnumber V番号
   * @param cpaContract CPA契約情報
   * @param cpaNwSetting CPA NW設定
   */
  downloadCpaNwSetting(
    vnumber: string,
    cpaContract: CpaContract,
    cpaNwSetting: CpaNwDetail
  ): void {
    const config = {
      version: "v1",
      feature: "cpa",
      vnumber: vnumber,
      cpaContractSeq: cpaContract.cpaContractSeq,
      ...cloneDeep(cpaNwSetting),
    } as CpaNwConfig;

    // ダウンロード用の構造に不要な項目を削除
    delete config.cpaNetworkKeyId;
    delete config.isWrapCommunication;
    delete config.isFtpSending;
    delete config.isAlwaysOn;
    delete config.cusconClientIp;
    delete config.idleTimer;
    delete config.longTimeCallDisconnectTimer;
    delete config.bandwidthType;

    if (
      cpaContract.authenticationType === "HOMERADIUS" ||
      cpaContract.authenticationType === "HOME"
    ) {
      delete config.radiusSetting!.ipAddressPrimary;
      delete config.radiusSetting!.ipAddressSecondary;
      delete config.radiusSetting!.callingStationIdTransfer;
      delete config.radiusSetting!.isImeiTransfer;
    } else {
      delete config.radiusSetting;
    }

    if (
      cpaContract.contractType !== "CPA_LIMIT_MODULE" ||
      cpaContract.authenticationType === "HOMERADIUS"
    ) {
      delete config.dealCodeList;
    }

    this.file.downloadJson(
      `cpa-${cpaContract.cpaContractSeq}-${now()}.conf`,
      config
    );
  }

  /**
   * CPA NW設定アップロード
   * @param vnumber
   * @param cpaContract
   * @param cpaNwSetting
   */
  async uploadCpaNwSetting(
    vnumber: string,
    cpaContract: CpaContract,
    cpaNwSetting: CpaNwDetail
  ): Promise<CpaNwDetail> {
    const content = await this.file.uploadFile(".conf");
    const config = isJSON<CpaNwConfig>(content);

    // アップロード内容のチェック
    if (!config) {
      throw new UploadError("MIME");
    }
    if (config.version !== "v1") {
      throw new UploadError("VERSION");
    }
    if (config.feature !== "cpa") {
      throw new UploadError("FEATURE");
    }
    if (vnumber !== config.vnumber) {
      throw new UploadError("APPLIANCE");
    }
    if (cpaContract.cpaContractSeq !== config.cpaContractSeq) {
      throw new UploadError("LINE_SEQ");
    }

    // 画面表示で不要な項目を削除
    delete config.version;
    delete config.feature;
    delete config.vnumber;
    delete config.cpaContractSeq;

    // 特殊項目などの画面表示で必要な項目を追加
    config.cpaNetworkKeyId = cpaNwSetting.cpaNetworkKeyId;
    config.isWrapCommunication = cpaNwSetting.isWrapCommunication;
    config.isFtpSending = cpaNwSetting.isFtpSending;
    config.isAlwaysOn = cpaNwSetting.isAlwaysOn;
    config.cusconClientIp = cpaNwSetting.cusconClientIp;
    config.idleTimer = cpaNwSetting.idleTimer;
    config.longTimeCallDisconnectTimer =
      cpaNwSetting.longTimeCallDisconnectTimer;
    config.bandwidthType = cpaContract.bandwidthType;

    // アップロードファイル内の想定される項目が削除されている場合の処理
    // ラジオの項目、配列、オブジェクトに対してケアする
    // 任意項目以外は画面上で入力されるから、ここで値を設定する必要はない
    config.isGlobalIpUse = config.isGlobalIpUse ?? false;
    if (cpaContract.ipAddressType !== "IPV6") {
      config.ueIpAllocationIpv4List = config.ueIpAllocationIpv4List ?? [""];
    }
    if (cpaContract.ipAddressType !== "IPV4") {
      config.ueIpAllocationIpv6List = config.ueIpAllocationIpv6List ?? [""];
    }
    config.networkIpSettingAct = config.networkIpSettingAct ?? {
      dnsIpv4: null,
      dnsIpv6: null,
    };
    config.networkIpSettingSby = config.networkIpSettingSby ?? {
      dnsIpv4: null,
      dnsIpv6: null,
    };
    config.maintenanceContact1 = config.maintenanceContact1 ?? {};
    config.maintenanceContact2 = config.maintenanceContact2 ?? {};
    config.radiusSetting = config.radiusSetting ?? {
      isDomainInfoSending: true,
      isBillingPacketSending: true,
      udpPort: "PORT_1645_1646",
      testAccount: { testUserId: null, testPassword: null, testIp: null },
    };
    if (config.radiusSetting && !config.radiusSetting.testAccount) {
      config.radiusSetting.testAccount = {
        testUserId: null,
        testPassword: null,
        testIp: null,
      };
    }
    config.dealCodeList = config.dealCodeList ?? [];

    // RADIUS設定項目は設定可能であれば、ダウンロード時に削除していた項目を追加 設定不可であればnull
    if (
      cpaContract.authenticationType === "HOMERADIUS" ||
      cpaContract.authenticationType === "HOME"
    ) {
      config.radiusSetting!.ipAddressPrimary =
        cpaNwSetting.radiusSetting!.ipAddressPrimary;
      config.radiusSetting!.ipAddressSecondary =
        cpaNwSetting.radiusSetting!.ipAddressSecondary;
      config.radiusSetting!.callingStationIdTransfer =
        cpaNwSetting.radiusSetting!.callingStationIdTransfer;
      config.radiusSetting!.isImeiTransfer =
        cpaNwSetting.radiusSetting!.isImeiTransfer;
    } else {
      config.radiusSetting = null;
    }

    // 案件コードは設定不可であればnullを設定
    config.dealCodeList =
      cpaContract.contractType !== "CPA_LIMIT_MODULE" ||
      cpaContract.authenticationType === "HOMERADIUS"
        ? null
        : config.dealCodeList;

    return config as CpaNwDetail;
  }
}

/** T: JSON文字列, null: JSON文字列ではない */
function isJSON<T>(content: string): DeepPartial<T> | null {
  try {
    return JSON.parse(content);
  } catch (e) {
    return null;
  }
}

/** ファイル名用の現在時刻文字列を返す */
function now(): string {
  return moment().format("YYYYMMDDHHmm");
}

/**
 * 元のポリシーにアップロードされたポリシーをマージ.
 * 既存の編集不可デフォルトポリシー以外を削除して
 * 最後尾の編集不可デフォルトポリシー（999）の前にアップロードされたポリシーを追加
 * もし編集不可デフォルトポリシー（999）がない場合は最後尾にポリシーを追加
 * @param basePolicies 元のポリシー
 * @param mergePolicies アップロードされたポリシー
 */
function mergePolicy<
  T extends Pick<
    GetPolicy,
    "policyObjectId" | "policyId" | "policyCategoryInfo"
  >[]
>(
  basePolicies: T,
  mergePolicies:
    | ({ [key: string]: unknown; policyId?: string } | undefined)[]
    | undefined
): T {
  const list = basePolicies.filter(
    (e) => e.policyCategoryInfo === "UNEDITABLE_DEFAULT_POLICY"
  );
  // policyObjectIdは主キー相当のため既存のポリシーを優先する
  // policyIdが一致するものが存在する場合は使用（既にポリシーが存在）、存在しない場合はconfigファイルにある値が優先、それもないとnull（新規）
  const basePolicyById = basePolicies.toMap((e) => e.policyId);
  const mergedPolicies = mergePolicies?.map((e) => {
    if (e?.policyId) {
      const basePolicy = basePolicyById[e.policyId];
      if (basePolicy) {
        return { ...e, policyObjectId: basePolicy.policyObjectId };
      }
    }
    return { ...e, policyObjectId: e?.policyObjectId ?? null };
  });
  const last = list.map((e) => e.policyId).lastIndexOf("0999");
  list.splice(last === -1 ? list.length : last, 0, ...(mergedPolicies as T));
  return list as T;
}

/**
 * ゾーンリレーション一覧から該当のリレーションを取得
 * @param zoneRelationList ゾーンリレーション一覧
 * @param zoneRelationId ゾーンリレーションID
 */
function getZoneRelation(
  zoneRelationList: ZoneRelationItem[],
  zoneRelationId: string
): { srcZoneId: string; dstZoneId: string } {
  const { srcZoneId, dstZoneId } = zoneRelationList.find(
    (e) => e.zoneRelationId === zoneRelationId
  )!;
  return { srcZoneId, dstZoneId };
}

import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { NonNullableProperty } from "@/shims-vue";
import { AxiosResponse } from "axios";

/**
 * 帯域タイプ
 * BEST_EFFORT : ベストエフォート
 * SECURING : 帯域確保
 */
export type BandwidthType = "SECURING" | "BEST_EFFORT";

/**
 * 状態
 * 以下のいずれかを設定する。
 * ACTIVE : 運用中
 * ACTIVE_UPDATE : 運用中(未反映データあり)
 * NW_SETTING_PREPARATION: NW設定準備中
 * INACTIVE : 利用開始前
 */
export type ContractStatus =
  | "ACTIVE"
  | "ACTIVE_UPDATE"
  | "NW_SETTING_PREPARATION"
  | "INACTIVE";

/**
 * 契約種別
 * CPA_NO_LIMIT : 端末速度制限無し
 * CPA_LIMIT_MODULE: 端末速度制限有り(モジュール専用)
 * CPA_LIMIT_AUDIO : 端末速度制限有り(モジュール専用/音声有り)
 */
export type ContractType =
  | "CPA_NO_LIMIT"
  | "CPA_LIMIT_MODULE"
  | "CPA_LIMIT_AUDIO";

/**
 * 認証種別
 * 以下のいずれかを設定する。追加認証タイプ利用フラグがtrueの場合のみ、Homeradius認証と端末認証（Home）が設定可能
 * RADIUS : Radius認証代行（端末認証あり）
 * RADIUS_IMEI : Radius認証代行（端末認証あり+IMEI認証あり）
 * HOMERADIUS : Homeradius認証
 * HOME : 端末認証（Home）
 */
export type AuthenticationType =
  | "RADIUS"
  | "RADIUS_IMEI"
  | "HOMERADIUS"
  | "HOME";

/**
 * アドレスアサイン方法
 * 以下のいずれかを設定する。
 * FIXED_IP : 固定IP割り当て(ユーザアサイン)
 * DYNAMIC_IP: 動的IP割り当て(キャリアアサイン)
 */
export type AddressAssignmentMethod = "FIXED_IP" | "DYNAMIC_IP";

/**
 * 冗長
 * 以下のいずれかを設定する。
 * EAST_ACT_WEST_SBY: 東日本ACT/西日本SBY
 * WEST_ACT_EAST_SBY: 西日本ACT/東日本SBY
 */
export type Redundancy = "EAST_ACT_WEST_SBY" | "WEST_ACT_EAST_SBY";

/**
 * IPアドレスタイプ
 * 以下のいずれかを設定する。
 * IPV4 : IPv4
 * IPV6 : IPv6
 * IPV4_IPV6: IPv4 / IPv6
 */
export type IpAddressType = "IPV4" | "IPV6" | "IPV4_IPV6";

/**
 * CallingStationID転送設定
 * 以下のいずれかを設定する。
 * ARIB: ARIB転送
 * MSISDN: MSISDN転送
 */
export type CallingStationIdTransfer = "ARIB" | "MSISDN";

/**
 * UDPポート番号(認証/課金)
 * 以下のいずれかを設定する。
 * PORT_1645_1646 : 1645/1646
 * PORT_1812_1813 : 1812/1813
 */
export type UdpPort = "PORT_1645_1646" | "PORT_1812_1813";

/**
 * CPA特殊項目設定
 * 以下のいずれかを設定する。
 * UPDATE : 設定可能(未反映データあり)
 * APPLIED : 設定可能(反映済み)
 * 設定不可の場合、nullを設定する。
 */
export type CpaNwSetting = "UPDATE" | "APPLIED" | null;

/**
 * サービス種別
 * 以下のいずれかを設定する。
 * IMMEDIATE : 即時
 * RESERVE : 送信日時指定
 */
export type SendType = "IMMEDIATE" | "RESERVE";

/**
 * 状態
 * 以下のいずれかを設定する。
 * COMPLETE : 登録完了
 * REGISTER : 登録中
 * FAILURE : 登録エラー
 * RESERVE : 予約中
 * CANCEL : 予約キャンセル
 */
export type RegistrationStatus =
  | "COMPLETE"
  | "REGISTER"
  | "FAILURE"
  | "RESERVE"
  | "CANCEL";

/** 帯域 */
export interface Bandwidth {
  /**
   * 設定値
   */
  value: number;
  /**
   * 単位
   * 以下のいずれかを設定する。設定値が「0」の場合、nullとなる
   * KBPS: Kbps
   * MBPS: Mbps
   * GBPS: Gbps
   */
  unit: "KBPS" | "MBPS" | "GBPS" | null;
}

export interface CpaContracts {
  /** CPA契約情報の総件数 */
  total: number;
  /** CPA契約情報リスト */
  cpaContractList: CpaContract[];
}

/** CPA契約情報リスト */
export interface CpaContract {
  /** CPA契約SEQ */
  cpaContractSeq: string;
  /** エンドユーザ名 */
  endUserName: string | null;
  /** CPA契約設定排他情報 */
  cpaContractKeyId: string;
  /** W番号
   * NW未設定の場合はnull
   */
  wnumber: {
    /** W番号(ACT) */
    wnumberAct: string;
    /** W番号(SBY) */
    wnumberSby: string;
  } | null;
  /** 帯域
   * 帯域タイプが帯域確保の場合、必須とする。
   * ベストエフォートの場合、nullとなる。
   */
  bandwidth: Bandwidth | null;
  /** 状態 */
  status: ContractStatus;
  /** VPNコード */
  vpnCode: string;
  /** APN名 */
  apn: string;
  /** 契約種別 */
  contractType: ContractType;
  /** 認証種別 */
  authenticationType: AuthenticationType;
  /** ドメイン */
  domain: string;
  /** 帯域タイプ */
  bandwidthType: BandwidthType;
  /** アドレスアサイン方法 */
  addressAssignmentMethod: AddressAssignmentMethod;
  /** 冗長 */
  redundancy: Redundancy;
  /** IPアドレスタイプ */
  ipAddressType: IpAddressType;
}

export interface CpaContractOptions {
  /** ベストエフォートのCPA契約の最大数 */
  maxBestEffortContracts: number;
  /**
   * 契約帯域
   * 帯域確保型で設定できるCPA契約毎の帯域値の上限
   */
  contractBandwidth: Bandwidth;
  /**
   * 追加認証タイプ利用可能フラグ
   * true : Homeradius認証、端末認証（Home）の利用可能
   * false : Homeradius認証、端末認証（Home）の利用可否
   */
  isEnabledAuthenticationOption: boolean;
  /**
   * 追加IPアドレスタイプ利用可能フラグ
   * true : IPv6、IPv4/IPv6の利用可能
   * false : IPv6、IPv4/IPv6の利用可否
   */
  isEnabledIpv6Option: boolean;
  /**
   * 東日本ACT利用可能フラグ
   * true : 東日本ACT/西日本SBYの冗長構成の利用可能
   * false : 東日本ACT/西日本SBYの冗長構成の利用不可
   */
  isEnabledEastAct: boolean;
}

export interface CreateCpaContract {
  /** 約款同意フラグ
   * 約款を同意する場合はtrue
   */
  isClauseAgreement: boolean;
  /** 契約種別 */
  contractType: ContractType;
  /** 認証種別 */
  authenticationType: AuthenticationType;
  /** ドメイン */
  domain: string;
  /** 帯域タイプ */
  bandwidthType: BandwidthType;
  /** アドレスアサイン方法 */
  addressAssignmentMethod: AddressAssignmentMethod;
  /** 冗長 */
  redundancy: Redundancy;
  /** IPアドレスタイプ */
  ipAddressType: IpAddressType;
}

export interface CpaContractKeyId {
  /** CPA契約設定排他情報 */
  cpaContractKeyId: string;
}

export interface NetworkIpSetting {
  /**
   * WAN IPアドレス(IPv4)(GW対向ルータ)
   * CPA契約のIPアドレスタイプがIPv4またはIPv4/IPv6の場合、必須となる。
   * グローバルIPアドレス利用フラグが「無」の場合、プライベートIPアドレスのみ設定可能とする。
   * プレフィックス長は30固定
   */
  wanIpv4Pe: string | null;
  /**
   * WAN IPアドレス(IPv4)(GWルータ)
   * CPA契約のIPアドレスタイプがIPv4またはIPv4/IPv6の場合、必須となる。
   * グローバルIPアドレス利用フラグが「無」の場合、プライベートIPアドレスのみ設定可能とする。
   * プレフィックス長は30固定
   */
  wanIpv4Gw: string | null;
  /**
   * WAN IPアドレス(IPv6)(GW対向ルータ)
   * CPA契約のIPアドレスタイプがIPv6またはIPv4/IPv6の場合、必須となる。
   * プレフィックス長は64固定
   */
  wanIpv6Pe: string | null;
  /**
   * WAN IPアドレス(IPv6)(GWルータ)
   * CPA契約のIPアドレスタイプがIPv6またはIPv4/IPv6の場合、必須となる。
   * プレフィックス長は64固定
   */
  wanIpv6Gw: string | null;
  /**
   * DNS IPアドレス(IPv4)
   * CPA契約のIPアドレスタイプがIPv4またはIPv4/IPv6の場合のみ使用可能とする。
   * グローバルIPアドレス利用フラグが「無」の場合、プライベートIPアドレスのみ設定可能とする。
   * プレフィックス長は32固定
   */
  dnsIpv4: string | null;
  /**
   * DNS IPアドレス(IPv6)
   * CPA契約のIPアドレスタイプがIPv6またはIPv4/IPv6の場合のみ使用可能とする。
   * プレフィックス長は64固定
   */
  dnsIpv6: string | null;
}

export interface MaintenanceContact {
  /** 会社名/部署名 */
  company: string;
  /** 担当者名 */
  name: string;
  /** 電話番号 */
  phone: string;
  /** 項目和名： */
  email: string;
}

export interface RadiusSetting {
  /** Home RADIUS/Accounting Server IPアドレス(Primary) */
  ipAddressPrimary: string | null;
  /** Home RADIUS/Accounting Server IPアドレス(Secondary) */
  ipAddressSecondary: string | null;
  /**
   * CallingStationID転送設定
   * 設定不可の場合はnull
   */
  callingStationIdTransfer: CallingStationIdTransfer | null;
  /**
   * IMEI転送フラグ
   * true : 有
   * false : 無
   * 設定不可の場合はnull
   */
  isImeiTransfer: boolean | null;
  /** シークレットキー */
  secretKey: string | null;
  /**
   * ドメイン情報送出フラグ
   * true : 有
   * false : 無
   */
  isDomainInfoSending: boolean | null;
  /**
   * 課金パケット送出フラグ
   * true : 有
   * false : 無
   */
  isBillingPacketSending: boolean | null;
  /** UDPポート番号(認証/課金) */
  udpPort: UdpPort | null;
  /** テストアカウント */
  testAccount: TestAccount | null;
}

export interface TestAccount {
  /** テストアカウント-ユーザID */
  testUserId: string | null;
  /** テストアカウント-PASS */
  testPassword: string | null;
  /** テストアカウント-テストIP */
  testIp: string | null;
}

export interface CpaNwDetail {
  /** CPANW設定排他情報 */
  cpaNetworkKeyId: string;
  /** エンドユーザ名 */
  endUserName: string | null;
  /**
   * グローバルIPアドレス利用フラグ
   * true : 有
   * false : 無
   */
  isGlobalIpUse: boolean | null;
  /**
   * 端末割当アドレスリスト(IPv4)
   * CPA契約のIPアドレスタイプがIPv4またはIPv4/IPv6の場合、必須となる。
   * グローバルIPアドレス利用フラグが「無」の場合、プライベートIPアドレスのみ設定可能とする。
   * 未設定または設定不可の場合はnull
   * 登録順に表示
   */
  ueIpAllocationIpv4List: string[] | null;
  /**
   * 端末割当アドレスリスト(IPv6)
   * CPA契約のIPアドレスタイプがIPv6またはIPv4/IPv6の場合、必須となる。
   * 未設定または設定不可の場合はnull
   * プレフィックス長は48固定
   * 登録順に表示
   */
  ueIpAllocationIpv6List: string[] | null;
  /** NW設定(ACT) */
  networkIpSettingAct: NetworkIpSetting | null;
  /** NW設定(SBY) */
  networkIpSettingSby: NetworkIpSetting | null;
  /** 保守連絡先1 */
  maintenanceContact1: MaintenanceContact | null;
  /** 保守連絡先2 */
  maintenanceContact2: MaintenanceContact | null;
  /**
   * 折り返し通信フラグ
   * true : 有
   * false : 無
   * 設定不可の場合はnull
   */
  isWrapCommunication: boolean | null;
  /**
   * FTP送信フラグ
   * true : 有
   * false : 無
   * 設定不可の場合はnull
   */
  isFtpSending: boolean | null;
  /**
   * AlwaysOnフラグ
   * true : 有
   * false : 無
   * 設定不可の場合はnull
   */
  isAlwaysOn: boolean | null;
  /**
   * カスコンクライアントIPアドレス設定
   * IPアドレス払い出し待ちの場合は空文字を返す
   */
  cusconClientIp: string | null;
  /** アイドルタイマ設定 */
  idleTimer: number | null;
  /** 長時間呼切断タイマ設定 */
  longTimeCallDisconnectTimer: number | null;
  /**
   * RADIUS設定項目
   * 未設定の場合、nullとなる。
   */
  radiusSetting: RadiusSetting | null;
  /** 帯域タイプ */
  bandwidthType: BandwidthType | null;
  /** 帯域
   * 帯域タイプが帯域確保の場合、必須とする。
   * ベストエフォートの場合、nullとなる。
   */
  bandwidth: Bandwidth | null;
  /** 案件コードリスト
   * 設定不可の場合はnull
   * 設定可能で0件の場合は空配列
   * 登録順に表示
   */
  dealCodeList: string[] | null;
}

export type UpdateCpaNw = Omit<
  CpaNwDetail,
  | "endUserName"
  | "isGlobalIpUse"
  | "networkIpSettingAct"
  | "networkIpSettingSby"
  | "maintenanceContact1"
  | "isWrapCommunication"
  | "isFtpSending"
  | "isAlwaysOn"
  | "cusconClientIp"
  | "idleTimer"
  | "longTimeCallDisconnectTimer"
  | "radiusSetting"
  | "bandwidthType"
  | "dealCodeList"
> & {
  radiusSetting: Omit<
    NonNullableProperty<RadiusSetting>,
    | "ipAddressPrimary"
    | "ipAddressSecondary"
    | "callingStationIdTransfer"
    | "isImeiTransfer"
    | "testAccount"
  > &
    Pick<RadiusSetting, "testAccount">;
} & {
  /** エンドユーザ名 */
  endUserName: string;
  /**
   * グローバルIPアドレス利用フラグ
   * true : 有
   * false : 無
   */
  isGlobalIpUse: boolean;
  /** NW設定(ACT) */
  networkIpSettingAct: NetworkIpSetting;
  /** NW設定(SBY) */
  networkIpSettingSby: NetworkIpSetting;
  /** 保守連絡先1 */
  maintenanceContact1: MaintenanceContact;
  /** 案件コード追加リスト */
  dealCodeAddList: string[] | null;
  /** 案件コード削除リスト */
  dealCodeDeleteList: string[] | null;
};

export interface CpaNwOptions {
  /** 折り返し通信設定 */
  wrapCommunication: CpaNwSetting;
  /** FTP送信設定 */
  ftpSendSetting: CpaNwSetting;
  /** カスコンクライアントIPアドレス設定 */
  cusconClientIp: CpaNwSetting;
  /** アイドルタイマ設定 */
  idleTimer: CpaNwSetting;
  /** AlwaysOn設定 */
  alwaysOn: CpaNwSetting;
  /** 長時間呼切断タイマ設定 */
  longTimeCallDisconnectTimer: CpaNwSetting;
  /** CallingStationID転送設定 */
  callingStationIdTransfer: CpaNwSetting;
  /** IMEI転送設定 */
  imeiTransfer: CpaNwSetting;
}

export interface AuthIdCsvCheckResponse {
  /** 総件数 */
  total: number;
  /**
   * CSVチェックエラーリスト
   * 行番号の昇順で返却する
   */
  checkErrorList: {
    /** 行番号 */
    rowNumber: number;
    /** エラー内容 */
    checkErrorMessageList: string[];
  }[];
}

export interface RegistrationResult {
  /** サービス種別 */
  remoteServiceType: "CPA";
  /** 処理種別 */
  sendType: SendType;
  /** 一括登録日時 */
  registrationDateTime: string;
  /** 状態 */
  status: RegistrationStatus;
  /** 認証IDファイルキー */
  fileKey: string;
  /** エラー詳細リスト */
  errorDetailList: string[];
}

export interface AuthIdRegistrationResult {
  /** 総件数 */
  total: number;
  /** 一括登録結果リスト */
  registrationResultList: RegistrationResult[];
}

/** リモートアクセスAPI */
export default class Cpa5gSaApi extends AbstractBaseApi {
  /**
   * CPA契約情報取得API
   */
  async getCpaContracts(): Promise<CpaContracts> {
    return (await this.api.get("/v1/contract/remoteaccess/cpas")).data;
  }

  /**
   * CPA契約項目設定取得API
   */
  async getCpaContractOptions(): Promise<CpaContractOptions> {
    return (await this.api.get("/v1/contract/remoteaccess/cpa/options")).data;
  }

  /**
   * CPA契約情報登録API
   * @param data CPA新規契約情報
   */
  async createCpaContract(data: CreateCpaContract): Promise<void> {
    await this.api.post("/v1/contract/remoteaccess/cpa", data);
  }

  /**
   * CPA利用開始API
   * @param data CPA利用開始するCPA契約情報
   * @param cpaContractSeq CPA契約SEQ
   */
  async startCpaContract(
    cpaContractSeq: string,
    data: CpaContractKeyId
  ): Promise<void> {
    await this.api.post(
      `/v1/contract/remoteaccess/cpa/${cpaContractSeq}/start`,
      data
    );
  }

  /**
   * CPA契約解約API
   * @param data CPA契約解約するCPA契約情報
   * @param cpaContractSeq CPA契約SEQ
   */
  async terminateCpaContract(
    cpaContractSeq: string,
    data: CpaContractKeyId
  ): Promise<void> {
    await this.api.post(
      `/v1/contract/remoteaccess/cpa/${cpaContractSeq}/delete`,
      data
    );
  }

  /** 契約一覧ダウンロードAPI */
  downloadCpaContractCsvURL(): string {
    return this.getFullURL("/v1/contract/remoteaccess/cpa/download", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }

  /** 約款情報ダウンロードAPI */
  downloadContractTermsPdfURL(): string {
    return this.getFullURL("/v1/contract/remoteaccess/cpa/clause/download", {
      params: {
        tokenId: this.store.state.user.token,
      },
    });
  }

  /** 約款情報PDFでエラーとならないことを確認するためのメソッド **/
  async checkTermsPdf(): Promise<void> {
    // 第二引数「_」はsafariでoriginエラーとなるため、タイムスタンプを付与
    await this.api.get("/v1/contract/remoteaccess/cpa/clause/download", {
      params: {
        tokenId: this.store.state.user.token,
        _: Date.now(),
      },
    });
  }

  /**
   * CPANW設定情報取得API
   * @param cpaContractSeq CPA契約SEQ
   */
  async getCpaNwDetail(cpaContractSeq: string): Promise<CpaNwDetail> {
    return (
      await this.api.get(`/v1/network/remoteaccess/cpa/${cpaContractSeq}`)
    ).data;
  }

  /**
   * CPANW設定の登録と更新API
   * @param cpaContractSeq CPA契約SEQ
   * @param data CPANW設定情報
   */
  async updateCpaNw(cpaContractSeq: string, data: UpdateCpaNw): Promise<void> {
    await this.api.post(`/v1/network/remoteaccess/cpa/${cpaContractSeq}`, data);
  }

  /**
   * CPA特殊項目設定情報取得API
   * @param cpaContractSeq CPA契約SEQ
   */
  async getCpaNwOptions(cpaContractSeq: string): Promise<CpaNwOptions> {
    return (
      await this.api.get(
        `/v1/network/remoteaccess/cpa/${cpaContractSeq}/options`
      )
    ).data;
  }

  /**
   * 認証ID一括登録API
   * @param cpaContractSeq CPA契約SEQ
   * @param sendType 処理種別
   * @param sendDateTime ファイル送信日時
   * @param isImmediateReflect 端末認証即時反映フラグ
   * @param textCsv CSVファイルのテキスト（UTF-8）
   */
  async createAuthId(
    cpaContractSeq: string,
    sendType: SendType,
    sendDateTime: string | null,
    isImmediateReflect: boolean,
    textCsv: string
  ): Promise<AxiosResponse<AuthIdCsvCheckResponse>> {
    return await this.api.post(
      `/v1/network/remoteaccess/cpa/${cpaContractSeq}/id/import`,
      textCsv,
      {
        headers: {
          "Content-Type": "text/csv",
        },
        params: {
          sendType: sendType,
          sendDateTime: sendDateTime,
          isImmediateReflect: isImmediateReflect,
        },
      }
    );
  }

  /**
   * 認証ID一括登録結果取得API
   * @param cpaContractSeq CPA契約SEQ
   */
  async getAuthIdRegistrationResult(
    cpaContractSeq: string
  ): Promise<AuthIdRegistrationResult> {
    return (
      await this.api.get(
        `/v1/network/remoteaccess/cpa/${cpaContractSeq}/id/import/history`
      )
    ).data;
  }

  /**
   * 認証ID予約キャンセルAPI
   * @param cpaContractSeq CPA契約SEQ
   * @param fileKey 認証IDファイルキー
   */
  async cancelAuthIdReservation(
    cpaContractSeq: string,
    fileKey: string
  ): Promise<void> {
    await this.api.post(
      `/v1/network/remoteaccess/cpa/${cpaContractSeq}/id/import/${fileKey}/cancel`
    );
  }

  /**
   * 認証ID登録用のテンプレートダウンロードAPI
   * @param cpaContractSeq CPA契約SEQ
   */
  downloadCpaNwCsvURL(cpaContractSeq: string): string {
    return this.getFullURL(
      `/v1/network/remoteaccess/cpa/${cpaContractSeq}/id/import/download`,
      {
        params: {
          tokenId: this.store.state.user.token,
        },
      }
    );
  }
}

import { Bandwidth } from "@/apis/VNConnectApi";

/** 帯域オブジェクトの操作サービス */
export default class BandwidthService {
  /**
   * 指定した帯域オブジェクトの単位を任意の単位に変換した帯域オブジェクトを作成
   * @param bandwidth 元の帯域オブジェクト
   * @param unit 変換先単位
   */
  convertUnit(
    bandwidth: Bandwidth | null,
    unit: "KBPS" | "MBPS" | "GBPS"
  ): Bandwidth | null {
    if (bandwidth && bandwidth.unit !== null) {
      const mbps = ((): number => {
        switch (bandwidth.unit) {
          case "KBPS":
            return bandwidth.value / 1000;
          case "MBPS":
            return bandwidth.value;
          case "GBPS":
            return bandwidth.value * 1000;
        }
      })();
      switch (unit) {
        case "KBPS":
          return { value: mbps * 1000, unit: "KBPS" };
        case "MBPS":
          return { value: mbps, unit: "MBPS" };
        case "GBPS":
          return { value: mbps / 1000, unit: "GBPS" };
      }
    } else {
      // 帯域オブジェクトがnullやunitが無い(値=0)の場合はそのまま返す
      return bandwidth;
    }
  }
}





















































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "AppModal",
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String as PropType<string>,
      required: false,
    },
    stepperFlg: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    active: {
      type: Number as PropType<number>,
      default: 1,
    },
    buttonSize: {
      type: String as PropType<string>,
      default: "default",
    },
  },
  methods: {
    cancel() {
      this.close();
      this.$emit("cancel", ...arguments);
    },
    close() {
      this.$emit("input", false);
    },
    ok() {
      this.close();
      this.$emit("ok", ...arguments);
    },
    downLoad() {
      this.$emit("downLoad");
    },
    upLoad() {
      this.$emit("upLoad");
    },
  },
});








































































































































































































































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import NotificationReference from "@/modals/common/NotificationReference.vue";
import { NoticeInfo, SpecialNote } from "@/apis/InformationApi";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import { mapState } from "vuex";
import { AlarmInfoEntity } from "@/apis/ThresholdSettingApi";
import { SortCompareFunc } from "@/services/SortService";

export default Vue.extend({
  name: "TopPage",
  data() {
    return {
      // その他お知らせ,
      notification: {
        fields: [
          { key: "postStartDateTime", label: "掲載日" },
          { key: "noticeInfoTitle", label: "お知らせ内容" },
        ] as BvTableFieldArray,
        items: [] as NoticeInfo[],
        selected: [] as NoticeInfo[],
      },
      // 障害お知らせ,
      failure_notification: {
        fields: [
          { key: "postStartDateTime", label: "掲載日" },
          { key: "noticeInfoTitle", label: "お知らせ内容" },
        ] as BvTableFieldArray,
        items: [] as NoticeInfo[],
        selected: [] as NoticeInfo[],
      },
      // メンテナンス,
      maintenance: {
        fields: [
          { key: "postStartDateTime", label: "掲載日" },
          { key: "noticeInfoTitle", label: "お知らせ内容" },
        ] as BvTableFieldArray,
        items: [] as NoticeInfo[],
        selected: [] as NoticeInfo[],
      },
      // トラフィック帯域超過機能ステータス一覧
      status: {
        fields: [
          {
            key: "number",
            label: "対象回線番号",
            filterByFormatted: true,
            formatter: (_v, _k, item: AlarmInfoEntity) => {
              // E番号またはW番号を表示
              if (item.enumber) {
                return item.enumber;
              } else {
                return item.wnumber;
              }
            },
          },
          {
            key: "direction",
            label: "方向",
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (v: AlarmInfoEntity["direction"]) => {
              return this.$filter.enumTo(v, "trafficThreshold_direction");
            },
          },
          { key: "exceedThresholdDateTime", label: "超過日時", sortable: true },
          { key: "restorationDateTime", label: "復旧日時", sortable: true },
          {
            key: "alarmType",
            label: "ステータス",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (v: AlarmInfoEntity["alarmType"]) => {
              return this.$filter.enumTo(v, "trafficThreshold_alarmType");
            },
          },
        ],
        items: [],
        search: {
          number: { type: "text", label: "対象回線番号" },
          direction: {
            type: "select",
            label: "方向",
            items: [
              { text: "送信", value: "SEND" },
              { text: "受信", value: "RECEIVE" },
            ],
          },
          alarmType: {
            type: "select",
            label: "ステータス",
            items: [
              { text: "超過", value: "EXCESS" },
              { text: "復旧", value: "RESTORATION" },
            ],
          },
        },
        filter: {},
        filter_option: {
          number: "like",
          direction: "equal",
          alarmType: "equal",
        },
      } as Omit<
        AppTableData<AlarmInfoEntity & { _primary: number }>,
        "selected"
      >,
      // 特記事項
      specialNotes: [] as SpecialNote[],

      isLoaded: false,
      // 未読
      isUnread: false,
    };
  },
  computed: {
    ...mapState("user", {
      loginUser: "loginUser",
      contractSummary: "contractSummary",
    }),
    /**
     * トラフィック帯域超過機能ステータス一覧ソート.
     * 第1優先: 超過日時（降順）, 第2優先: ステータス（昇順）
     */
    statusTableSort(): SortCompareFunc<AlarmInfoEntity> {
      return this.$service.tableSortCompare("exceedThresholdDateTime", [
        (e) => this.$filter.enumTo(e.alarmType, "trafficThreshold_alarmType"),
        "asc",
      ]);
    },
    /**
     * ノーマルユーザーか否かを返却
     * それによりチェックボックスの表示切り替え
     */
    selectable(): boolean {
      return this.$service.permission.isNormalUser();
    },
    /**
     * メンテナンスのitemを返却
     * 未読のみの表示の場合は、未読のみのitemを返却する。
     */
    maintenanceItem(): NoticeInfo[] {
      if (this.isUnread) {
        return this.maintenance.items.filter((e) => e.isRead === false);
      }
      return this.maintenance.items;
    },
    /**
     * 障害情報のitemを返却
     * 未読のみの表示の場合は、未読のみのitemを返却する。
     */
    failureNotificationItem(): NoticeInfo[] {
      if (this.isUnread) {
        return this.failure_notification.items.filter(
          (e) => e.isRead === false
        );
      }
      return this.failure_notification.items;
    },
    /**
     * その他お知らせのitemを返却
     * 未読のみの表示の場合は、未読のみのitemを返却する。
     */
    notificationItem(): NoticeInfo[] {
      if (this.isUnread) {
        return this.notification.items.filter((e) => e.isRead === false);
      }
      return this.notification.items;
    },
    /**
     * お知らせの既読ボタンの活性非活性を返却
     * Checkedが一つ以上で活性化
     */
    isListUnChecked(): boolean {
      return (
        this.maintenance.selected.length === 0 &&
        this.failure_notification.selected.length === 0 &&
        this.notification.selected.length === 0
      );
    },
    /**
     * 各タブの未読件数返却
     */
    maintenanceItemUnread(): number {
      return this.maintenance.items.filter((e) => e.isRead === false).length;
    },
    failureNotificationItemUnread(): number {
      return this.failure_notification.items.filter((e) => e.isRead === false)
        .length;
    },
    notificationItemUnread(): number {
      return this.notification.items.filter((e) => e.isRead === false).length;
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    /** 読み込み */
    async load() {
      const notice = await this.$api.information.getNotifications();
      this.status.items = (await this.$api.thresholdSetting.getAlarmInfo()).map(
        // 選択を使用しないのでindexでも可
        (e, i) => ({ ...e, _primary: i })
      );
      this.specialNotes = await this.$api.information.getSpecialNotes();

      // 各タブの一覧
      this.notification.items = notice.filter((item) => {
        return item.noticeType === "NOTIFICATION";
      });
      this.failure_notification.items = notice.filter((item) => {
        return item.noticeType === "FAILURE_NOTIFICATION";
      });
      this.maintenance.items = notice.filter((item) => {
        return item.noticeType === "MAINTENANCE";
      });

      // 各タブのチェックボックスを初期化
      this.maintenance.selected.splice(0);
      this.failure_notification.selected.splice(0);
      this.notification.selected.splice(0);
    },
    /** お知らせ詳細表示 */
    async showNotification(entity: NoticeInfo) {
      if (entity.isRead === false) {
        await this.$api.information.putRead(entity.noticeInfoSeq);
        entity.isRead = true;
      }
      await this.$modal.show(NotificationReference, {
        noticeInfoSeq: entity.noticeInfoSeq,
        noticeType: entity.noticeType,
      });
    },
    /**
     * お知らせ一覧未読レイアウト表示
     */
    rowUnRead(item: NoticeInfo) {
      if (this.$service.permission.isDelegateUser()) {
        return null;
      } else {
        if (item.isRead === false) {
          return "table-unread";
        } else {
          return "table-read";
        }
      }
    },
    /** トラフィック画面表示 */
    async showTrafficStatus(item: AlarmInfoEntity) {
      await this.$router.push({
        path: "/report",
        query: {
          lineType: item.lineType,
          enumber: item.enumber ? item.enumber : undefined,
          wnumber: item.wnumber ? item.wnumber : undefined,
        },
      });
    },
    /**
     * お知らせ一覧選択既読
     */
    async selectedRead() {
      const promisesMaintenance = this.maintenance.selected.map(
        async (e) => await this.$api.information.putRead(e.noticeInfoSeq)
      );

      const promiseFailure = this.failure_notification.selected.map(
        async (e) => await this.$api.information.putRead(e.noticeInfoSeq)
      );

      const promiseNotification = this.notification.selected.map(
        async (e) => await this.$api.information.putRead(e.noticeInfoSeq)
      );
      const promises = promisesMaintenance.concat(
        promiseFailure,
        promiseNotification
      );

      await Promise.all(promises);
      await this.load();
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-medium","title":"エクストラネット接続申請"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[(_vm.isLoaded)?_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"detail-container mt-2"},[_c('b-form-group',{attrs:{"label":"接続元V番号"}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.contractSummary.vnumber)+" ")])])],1),_c('app-badge',{attrs:{"item":"接続元VNコード","isInfo":false}}),_c('validation-provider',{staticClass:"mt-2 mb-3",attrs:{"vid":"selectedVnCode","name":"接続元VNコード","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('app-multiselect',{attrs:{"options":_vm.vpnVnCodeList,"state":_vm._f("validState")(validContext),"custom-label":_vm.getCustomLabel,"allow-empty":false,"deselectLabel":"","track-by":"_index"},model:{value:(_vm.selectedVpnVnCode),callback:function ($$v) {_vm.selectedVpnVnCode=$$v},expression:"selectedVpnVnCode"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"接続先V番号","rules":{
              required: true,
              is_vnumber: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"接続先V番号","msg":"半角大文字V+半角数字9桁"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.form.dstVNumber),callback:function ($$v) {_vm.$set(_vm.form, "dstVNumber", $$v)},expression:"form.dstVNumber"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"接続先VNコード","rules":{
              required: true,
              is_vncode: true,
              is_not_eq: { target: 'selectedVnCode', path: 'vpnVnCode' },
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"接続先VNコード","msg":"接続元とは異なる先頭Dから始まる半角英字2文字+半角数字4桁"}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.form.dstVnCode),callback:function ($$v) {_vm.$set(_vm.form, "dstVnCode", $$v)},expression:"form.dstVnCode"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"PINコード","rules":{
              required: _vm.isRequiredPinCode,
              length: 4,
              is_half_width_alpha_numeric: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"PINコード","is-required":_vm.isRequiredPinCode,"msg":_vm._f("msg")('info_pin_code')}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","state":_vm._f("validState")(validContext)},model:{value:(_vm.form.pinCode),callback:function ($$v) {_vm.$set(_vm.form, "pinCode", $$v)},expression:"form.pinCode"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"メッセージ","rules":{
              max: 30,
              is_alphabetic_characters_symbols: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(validContext){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-badge',{attrs:{"item":"メッセージ","msg":"記号(_ . , : ; ? < > + - * / = # % & $ @)を含む30文字以内","isRequired":false}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"state":_vm._f("validState")(validContext)},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('app-invalid-feedback',{attrs:{"context":validContext}})],1)]}}],null,true)})],1):_vm._e(),_c('footer',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" 申請 ")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)])]}}],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":"インターネットセキュリティアプライアンスポリシー設定","classes":"modal-full"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [(_vm.isLoaded)?_c('div',{staticClass:"modal-content mb-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-group',[_c('b-form-radio-group',{staticClass:"ml-2",model:{value:(_vm.direction),callback:function ($$v) {_vm.direction=$$v},expression:"direction"}},[_c('b-form-radio',{attrs:{"value":"accessPointToInternet"}},[_vm._v("アクセス拠点 → インターネット")]),_c('b-form-radio',{attrs:{"value":"internetToAccessPoint"}},[_vm._v("インターネット → アクセス拠点")])],1)],1),_c('span',{staticClass:"flex-grow-1"}),_c('div',{staticClass:"btn-container mb-3"},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.downloadConfig}},[_vm._v("設定ダウンロード ")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.uploadConfig}},[_vm._v("設定アップロード ")])],1)],1),(_vm.direction === 'accessPointToInternet')?_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"fill":"","small":"","card":"","justified":""}},[_c('b-tab',{attrs:{"title":"NAT(IP Masquerade)"}},[_c('div',{staticClass:"btn-container justify-content-between align-items-center my-2"},[_c('span',[_vm._v(_vm._s(_vm.policy.policyIPMasqueradeType4.policyList.length)+"件を表示")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.editPolicyAccessToInternetNAT}},[_vm._v(" ポリシー編集 ")])],1),_c('app-table',{attrs:{"small":"","fields":_vm.a2iNatTable.fields,"items":_vm.policy.policyIPMasqueradeType4.policyList,"pagination":false,"primary-key":"policyId","thead-class":"text-center small","tbody-tr-class":_vm.policyRowClass},scopedSlots:_vm._u([{key:"cell(srcAddressList)",fn:function(ref){
var value = ref.value;
return [_c('policy-src-address',{attrs:{"src-address":{ srcAddressList: value }}})]}},{key:"cell(dstAddressList)",fn:function(ref){
var value = ref.value;
return [_c('policy-dst-address',{attrs:{"dst-address":{ dstAddressList: value }}})]}},{key:"cell(ipPoolList)",fn:function(ref){
var value = ref.value;
return [_c('policy-ip-pool',{attrs:{"ip-pool-list":value}})]}},{key:"cell(isPolicyStatus)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}}],null,true)})],1),_c('b-tab',{attrs:{"title":"UTM","active":""}},[_c('div',{staticClass:"btn-container justify-content-between align-items-center my-2"},[_c('span',[_vm._v(_vm._s(_vm.policy.policyUtmType4.accessPointToInternetPolicyList .length)+"件を表示")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.editPolicyAccessToInternetUTM}},[_vm._v(" ポリシー編集 ")])],1),_c('app-table',{attrs:{"small":"","fields":_vm.a2iUtmTable.fields,"items":_vm.policy.policyUtmType4.accessPointToInternetPolicyList,"pagination":false,"primary-key":"policyId","thead-class":"text-center small","tbody-tr-class":_vm.policyRowClass},scopedSlots:_vm._u([{key:"cell(srcAddress)",fn:function(ref){
var value = ref.value;
return [_c('policy-src-address',{attrs:{"src-address":value}})]}},{key:"cell(dstAddress)",fn:function(ref){
var value = ref.value;
return [_c('policy-dst-address',{attrs:{"dst-address":value}})]}},{key:"cell(service)",fn:function(ref){
var item = ref.item;
return [_c('policy-service',{attrs:{"service-option":item.serviceOption,"default-service-list":item.defaultServiceList,"custom-service-list":item.customServiceList}})]}},{key:"cell(profile)",fn:function(ref){
var value = ref.value;
return [_c('policy-profile',{attrs:{"profile":value}})]}},{key:"cell(actionType)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"allow_deny"))+" ")]}},{key:"cell(isLogOutput)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"on_off"))+" ")]}},{key:"cell(isPolicyStatus)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.direction === 'internetToAccessPoint')?_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"fill":"","small":"","card":"","justified":""}},[_c('b-tab',{attrs:{"title":"NAT(IP Forwarding)"}},[_c('div',{staticClass:"btn-container justify-content-between align-items-center my-2"},[_c('span',[_vm._v(_vm._s(_vm.policy.policyNatType4.policyList.length)+"件を表示")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.editPolicyInternetToAccessNAT}},[_vm._v(" ポリシー編集 ")])],1),_c('app-table',{attrs:{"small":"","fields":_vm.i2aNatTable.fields,"items":_vm.policy.policyNatType4.policyList,"pagination":false,"primary-key":"policyId","thead-class":"text-center small"},scopedSlots:_vm._u([{key:"cell(srcAddressList)",fn:function(ref){
var value = ref.value;
return [_c('policy-src-address',{attrs:{"src-address":{ srcAddressList: value }}})]}},{key:"cell(ipMapping)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.ipMappingName)+" ")]}},{key:"cell(isPolicyStatus)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}}],null,true)})],1),_c('b-tab',{attrs:{"title":"UTM","active":""}},[_c('div',{staticClass:"btn-container justify-content-between align-items-center my-2"},[_c('span',[_vm._v(_vm._s(_vm.policy.policyUtmType4.internetToAccessPointPolicyList .length)+"件を表示")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.editPolicyInternetToAccessUTM}},[_vm._v(" ポリシー編集 ")])],1),_c('app-table',{attrs:{"small":"","fields":_vm.i2aUtmTable.fields,"items":_vm.policy.policyUtmType4.internetToAccessPointPolicyList,"pagination":false,"primary-key":"policyId","thead-class":"text-center small","tbody-tr-class":_vm.policyRowClass},scopedSlots:_vm._u([{key:"cell(srcAddress)",fn:function(ref){
var value = ref.value;
return [_c('policy-src-address',{attrs:{"src-address":value}})]}},{key:"cell(dstAddress)",fn:function(ref){
var value = ref.value;
return [_c('policy-dst-address',{attrs:{"dst-address":value}})]}},{key:"cell(service)",fn:function(ref){
var item = ref.item;
return [_c('policy-service',{attrs:{"service-option":item.serviceOption,"default-service-list":item.defaultServiceList,"custom-service-list":item.customServiceList}})]}},{key:"cell(profile)",fn:function(ref){
var value = ref.value;
return [_c('policy-profile',{attrs:{"profile":value}})]}},{key:"cell(actionType)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"allow_deny"))+" ")]}},{key:"cell(isLogOutput)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"on_off"))+" ")]}},{key:"cell(isPolicyStatus)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("enumTo")(value,"enable"))+" ")]}},{key:"cell(description)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("emptyTo")(value,"-"))+" ")]}}],null,true)})],1)],1)],1):_vm._e(),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"warning","show":!!_vm.uploadAlert}},[_vm._v(_vm._s(_vm.uploadAlert))])],1):_vm._e(),_c('footer',{staticClass:"modal-footer"},[_c('span',{staticClass:"flex-grow-1"}),_c('b-button',{directives:[{name:"permission",rawName:"v-permission.config",modifiers:{"config":true}}],attrs:{"variant":"primary"},on:{"click":_vm.putPolicy}},[_vm._v(" ポリシー設定反映 ")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("キャンセル ")])],1)]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
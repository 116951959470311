import { AxiosResponse } from "axios";

import AbstractBaseApi from "@/apis/AbstractBaseApi";

/**
 * SaaS利用一覧のAPI用インターフェイス
 */
export interface SaaSServiceGetEntity {
  /**
   * 総件数
   * 一覧表示対象の総件数
   */
  total: number;
  /**
   * SaaS利用一覧
   * Microsoft Peering設定情報とSFDC設定情報が存在しない場合、空リストを返却する。
   */
  saasServiceList: SaaSServiceListEntity[];
  /**
   * SaaS利用排他情報
   * 排他確認用パラメータ
   * 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   */
  saasKeyId: string;
}

export interface SaaSServiceListEntity {
  /** SaaS設定共通情報 */
  saasCommon: SaaSCommonEntity;
  /**
   * Microsoft Peering設定情報
   * 取得したMicrosoft Peering設定情報。データが存在しない場合、NULLを返却する。
   */
  msPeeringInfo: MsPeeringInfoEntity | null;
  /**
   * SFDC設定情報
   * 取得したSFDC設定情報。データが存在しない場合、NULLを返却する。
   */
  sfdcInfo: SfdcInfoEntity | null;
}

export interface SaaSCommonEntity {
  /**
   * VPN/VNコード
   * VPNの場合は「VPNコード」、VNの場合は「VNコード」の形式で表示する。
   */
  vpnVnCode: string;
  /**
   * 仮想ネットワーク名
   * VPN/VNコードでVPNを指定した場合は省略される為、NULLを返却する。
   */
  vnName: string | null;
}

export interface MsPeeringInfoEntity {
  /**
   * Microsoft サービス利用申込SEQ
   * 主キー
   */
  msServiceSeq: string;
  /**
   * Microsoft Peering
   * NULLの場合は「利用しない」、NULL以外の場合は「利用する」とする。
   */
  msPeering: MsPeeringLimitEntity | null;
}

export interface MsPeeringLimitEntity {
  /**
   * NAPTアドレス上限
   * Microsoft Peeringが存在する場合は必須とする。
   * 設定値上限APIのGETから上限値を取得し、その上限値以下の値を設定可能とする。
   */
  upperLimitNapt: number;
  /**
   * NATアドレス上限
   * Microsoft Peeringが存在する場合は必須とする。
   * 設定値上限APIのGETから上限値を取得し、その上限値以下の値を設定可能とする。
   */
  upperLimitNat: number;
}

export interface SfdcInfoEntity {
  /**
   * SFDCサービスSEQ
   * 主キー
   */
  sfdcServiceSeq: string;
  /**
   * SFDC
   * NULLの場合は「利用しない」、NULL以外の場合は「利用する」とする。
   */
  sfdc: sfdcLimitEntity | null;
}

export interface sfdcLimitEntity {
  /**
   * NAPTアドレス上限
   * SFDCが存在する場合は必須とする。
   * 1～5までの値を設定可能とする。
   */
  upperLimitNapt: number;
}

/**
 * SaaS利用設定（追加/変更）のAPI用インターフェイス
 */
export interface SaaSServicePostEntity {
  /** SaaS設定共通情報 */
  saasCommon: Pick<SaaSCommonEntity, "vpnVnCode">;
  /** Microsoft Peering */
  msPeeringInfo: Pick<MsPeeringInfoEntity, "msPeering"> & {
    /**
     * Microsoft サービス利用申込SEQ
     * 主キー
     */
    msServiceSeq: string | null;
  };
  /** Salesforce */
  sfdcInfo: Pick<SfdcInfoEntity, "sfdc"> & {
    /**
     * SFDCサービスSEQ
     * 主キー
     */
    sfdcServiceSeq: string | null;
  };
  /** SaaS利用排他情報 */
  saasKeyId: string;
}

/**
 * SaaS利用設定削除のAPI用インターフェイス
 */
export interface SaaSServiceDeleteEntity {
  /** VPN/VNコード */
  vpnVnCode: string;
  /** SaaS利用排他情報 備考 */
  saasKeyId: string;
}

export default class SaasApi extends AbstractBaseApi {
  /**
   * SaaS設定情報の一覧を取得
   */
  async getAll(): Promise<SaaSServiceGetEntity> {
    return (await this.api.get("v1/contract/service/cloud/saas")).data;
  }

  /**
   * SaaS設定情報を追加/変更
   * @param data リクエストデータ
   */
  async post(data: SaaSServicePostEntity): Promise<AxiosResponse> {
    return (await this.api.post("v1/contract/service/cloud/saas", data)).data;
  }

  /**
   * SaaS設定情報を削除
   * @param data リクエストデータ
   */
  async delete(data: SaaSServiceDeleteEntity): Promise<AxiosResponse> {
    return (await this.api.post("v1/contract/service/cloud/saas/delete", data))
      .data;
  }
}

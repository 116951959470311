










































































































































































































































































































































import Vue, { PropType } from "vue";
import { CloudVnSfdcDetail } from "@/apis/CloudVnSfdcApi";

export default Vue.extend({
  name: "MultiCloudSfdcVnConnectionConfirm",
  props: {
    cloudVnSfdcDetail: {
      type: Object as PropType<CloudVnSfdcDetail>,
      required: true,
    },
    /** 画面表示モード：
     * A - 追加確認
     * M - 編集確認
     * D - 削除確認
     */
    displayType: {
      type: String as PropType<"A" | "M" | "D">,
      required: true,
    },
  },
  computed: {
    confirmMessage(): string {
      switch (this.displayType) {
        case "A":
          return "こちらの内容でクラウド接続向けVN回線を追加します。よろしいですか？";
        case "M":
          return "こちらの内容でクラウド接続向けVN回線を変更します。よろしいですか？";
        case "D":
          return "こちらの内容でクラウド接続向けVN回線を削除します。よろしいですか？";
        default:
          throw new Error("wrong displayType!");
      }
    },
  },
});

/** ファイル操作サービス */
import { AxiosResponse } from "axios";

export default class FileService {
  /**
   * ファイルをダウンロード
   * @param fileName ファイル名
   * @param content ファイル内容
   * @param type mimeタイプ
   */
  download(fileName: string, content: string | Uint8Array, type: string): void {
    const blob = new Blob([content], { type });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = fileName;
    a.href = url;
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  }

  /**
   * テキストファイルをダウンロード
   * @param fileName ファイル名
   * @param text ファイル内容
   * @param type mimeタイプ
   */
  downloadText(
    fileName: string,
    text: string,
    type = "text/plain;charset=utf-8"
  ): void {
    this.download(fileName, text, type);
  }

  /**
   * JSONファイルをダウンロード
   * @param fileName ファイル名
   * @param json ファイル内容
   */
  downloadJson(fileName: string, json: unknown): void {
    this.downloadText(fileName, JSON.stringify(json, null, 2));
  }

  /**
   * input[type=file]を使用してファイル内容を取得
   * @param accept 選択ファイル形式
   */
  uploadFile(accept?: string): Promise<string> {
    // ファイルが選択でキャンセルした場合はDOMが残るため存在していた場合は削除
    const oldInput = document.querySelector("#dynamic-input-file");
    if (oldInput) {
      oldInput.remove();
    }
    return new Promise((resolve) => {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.id = "dynamic-input-file";
      input.type = "file";
      if (accept) {
        input.accept = accept;
      }
      input.style.display = "none";
      input.addEventListener("change", async (event) => {
        const text = await (event.target as HTMLInputElement)
          .files!.item(0)!
          .text();
        input.remove();
        resolve(text);
      });
      input.click();
    });
  }

  /**
   * ファイル内容を読み取りArrayBufferを取得
   * @param file 読み取り対象ファイル
   */
  readAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = (ev) => {
        reader.abort();
        reject(ev);
      };
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * HTTPレスポンスヘッダーのcontent-dispositionからファイル名を取得
   * @param response HTTPレスポンス
   */
  getFileName(response: AxiosResponse): string | null {
    const header: string | undefined =
      response.headers["content-disposition"] ??
      response.headers["Content-Disposition"];
    if (header) {
      // `filename*=`の方があれば、優先
      if (header.includes("filename*=")) {
        const fileNameUnicode = header.split("filename*=")[1].split(";")?.[0];
        if (fileNameUnicode) {
          return decodeURIComponent(fileNameUnicode.split(/'(.*?)'/)[2]);
        } else {
          return null;
        }
      } else {
        return header.split("filename=")[1]?.split(";")?.[0] ?? null;
      }
    } else {
      return null;
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"classes":"modal-large","title":"CPA 5G SA NW設定"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-card-title',{staticClass:"modal-title"},[_vm._v("CPA 5G SA NW設定")]),_c('div',{staticClass:"text-right"},[_c('div',{staticClass:"btn-container"},[(_vm.active !== _vm.stepperTitles.length)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.downloadConfig}},[_vm._v("設定ダウンロード")]):_vm._e(),(_vm.active === 1)?[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.uploadConfig}},[_vm._v("設定アップロード")])]:_vm._e(),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":cancel}},[_vm._v(" × ")])],2)])]}},{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('app-stepper',{attrs:{"size":_vm.stepperTitles.length,"titles":_vm.stepperTitles},on:{"input":function($event){_vm.active = $event}},scopedSlots:_vm._u([{key:"content(1)",fn:function(ref){
var next = ref.next;
return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.handleActionFromStep1(next); })}}},[_c('sa-nw-edit-stepper1',{attrs:{"cpa-contract":_vm.cpaContract},model:{value:(_vm.cpaNwSetting),callback:function ($$v) {_vm.cpaNwSetting=$$v},expression:"cpaNwSetting"}}),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"warning","show":!!_vm.uploadAlert}},[_vm._v(_vm._s(_vm.uploadAlert))]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("戻る ")]),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("次へ")])],1)],1)]}}],null,true)})]}},{key:"content(2)",fn:function(ref){
var prev = ref.prev;
var next = ref.next;
return [_c('cross-validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(next)}}},[_c('sa-nw-edit-stepper2',{model:{value:(_vm.cpaNwSetting),callback:function ($$v) {_vm.cpaNwSetting=$$v},expression:"cpaNwSetting"}}),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":prev}},[_vm._v("戻る")]),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("次へ")])],1)],1)]}}],null,true)})]}},{key:"content(3)",fn:function(ref){
var prev = ref.prev;
var next = ref.next;
return [_c('cross-validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.handleAction(next); })}}},[(!_vm.isStep3Disable)?_c('sa-nw-edit-stepper3',{attrs:{"cpa-nw-options":_vm.cpaNwOption,"authentication-type":_vm.cpaContract.authenticationType,"contract-type":_vm.cpaContract.contractType,"ip-address-type":_vm.cpaContract.ipAddressType},model:{value:(_vm.cpaNwSetting),callback:function ($$v) {_vm.cpaNwSetting=$$v},expression:"cpaNwSetting"}}):_c('div',{staticClass:"stepper-content"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("こちらの内容でCPA 5G SA NW設定を行います。よろしいですか？")]),(_vm.stepperTitles.length === _vm.active)?_c('SaNwSettingInfo',{attrs:{"cpaContractInfo":_vm.cpaContract,"nwSettingDetail":_vm.cpaNwSetting,"nwOptions":_vm.cpaNwOption,"isShowBandwidthInfo":true}}):_vm._e()],1),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":prev}},[_vm._v("戻る")]),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.isStep3Disable ? "設定" : "次へ"))])],1)],1)]}}],null,true)})]}},(!_vm.isStep3Disable)?{key:"content(4)",fn:function(ref){
var prev = ref.prev;
return [_c('div',{staticClass:"stepper-content"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("こちらの内容でCPA 5G SA NW設定を行います。よろしいですか？")]),(_vm.stepperTitles.length === _vm.active)?_c('SaNwSettingInfo',{attrs:{"cpaContractInfo":_vm.cpaContract,"nwSettingDetail":_vm.cpaNwSetting,"nwOptions":_vm.cpaNwOption,"isShowBandwidthInfo":true}}):_vm._e()],1),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":prev}},[_vm._v("戻る")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.postCpaNw}},[_vm._v("設定")])],1)]}}:null],null,true)})]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
import { ValidationRuleSchema } from "vee-validate/dist/types/types";
import ipaddr from "ipaddr.js";

/**
 *   入力されたIPアドレス(範囲なし)が別に存在するCIDR形式のサブネットの範囲リストに包含されていないことの検証
 *  ・CIDR形式のみ受付可能
 *  ・正しいIPV4形式であることは別のバリデーションで実施が必要
 *  ・比較対象は複数指定可
 */
export default {
  params: ["targetList"],
  validate(
    value: string | undefined | null,
    { targetList }: { targetList: string[] | null | undefined }
  ): boolean {
    if (!value || !targetList) {
      return true;
    }

    // valueがIPV4形式でない場合、true
    let valueIpv4: [ipaddr.IPv4, number];
    try {
      valueIpv4 = ipaddr.IPv4.parseCIDR(value);
    } catch (e) {
      return true;
    }

    for (const strIpv4 of targetList) {
      let targetIpv4: [ipaddr.IPv4, number];
      try {
        targetIpv4 = ipaddr.IPv4.parseCIDR(strIpv4);
      } catch (e) {
        //  targetListにIPV4形式で無い文字列が存在した場合、次のループ
        continue;
      }
      // targetListの範囲に含まれている場合、エラー
      if (valueIpv4[0].match(targetIpv4)) {
        return false;
      }
    }
    return true;
  },
} as ValidationRuleSchema;

























































































































































































































































import Vue from "vue";
import SaContractTermsConfirm from "@/modals/cpa5g/SaContractTermsConfirm.vue";
import SaContractConfirm from "@/modals/cpa5g/SaContractConfirm.vue";
import { optionList } from "@/views/cpa5gSa/Cpa5gSaContractList.vue";
import { CpaContractOptions, CreateCpaContract } from "@/apis/Cpa5gSaApi";

export default Vue.extend({
  name: "SaContractRegister",
  props: {},
  data() {
    return {
      isLoaded: false,
      agreementAlert: false,
      form: {
        isClauseAgreement: false,
        redundancy: "WEST_ACT_EAST_SBY",
        bandwidthType: "BEST_EFFORT",
        addressAssignmentMethod: "FIXED_IP",
        ipAddressType: "IPV4",
      } as CreateCpaContract,
      optionList: optionList,
      cpaOptions: {} as CpaContractOptions,
    };
  },
  computed: {
    // 契約項目が変更になるたびに表示の切り替えが必要なのでcomputedで作成
    firstDomain(): string {
      switch (this.form.contractType) {
        case "CPA_NO_LIMIT":
          return "cpx.";
        case "CPA_LIMIT_MODULE":
          return "crx.";
        case "CPA_LIMIT_AUDIO":
          return "cvx.";
        default:
          return "";
      }
    },
  },
  watch: {
    /**
     * 認証タイプがHomeRADIUS認証の場合
     * ・ベストエフォートは設定不可(非表示) → 初期値を「帯域確保」に変更
     * ・IPv6(IPv4/IPv6は可)は設定不可(非表示) → IPv6が選択されていた場合、初期値を「IPv4」に変更
     * 認証タイプが端末認証(Home)の場合
     * ・IPv6(IPv4/IPv6は可)は設定不可(非表示) → IPv6が選択されていた場合、初期値を「IPv4」に変更
     */
    "form.authenticationType": function (newval: string, oldVal: string) {
      if (newval === "HOMERADIUS") {
        this.form.bandwidthType = "SECURING";
        if (this.form.ipAddressType === "IPV6") {
          this.form.ipAddressType = "IPV4";
        }
      } else if (oldVal === "HOMERADIUS") {
        this.form.bandwidthType = "BEST_EFFORT";
      } else if (newval === "HOME" && this.form.ipAddressType === "IPV6") {
        this.form.ipAddressType = "IPV4";
      }
    },
    "form.isClauseAgreement": function (val: boolean) {
      this.agreementAlert = !val;
    },
  },
  async mounted() {
    this.cpaOptions = await this.$api.cpa5gSa.getCpaContractOptions();
    this.isLoaded = true;
  },
  methods: {
    async submit() {
      if (!this.form.isClauseAgreement) this.agreementAlert = true;

      if (this.form.isClauseAgreement) {
        // 確認画面の呼び出し
        await this.$modal.show(SaContractConfirm, {
          cpa5gContractData: {
            ...this.form,
            domain: this.firstDomain + this.form.domain,
          },
        });
        // 確認画面の「はい」が押されたらAPIを呼び出す、確認画面を閉じる
        await this.$api.cpa5gSa.createCpaContract({
          ...this.form,
          domain: this.firstDomain + this.form.domain,
        });
        (this.$refs.modal as Vue & { ok: () => void }).ok();
      }
    },
    // 約款確認
    async confirmTermsAndConditions() {
      await this.$modal.show(SaContractTermsConfirm);
      this.form.isClauseAgreement = true;
    },
  },
});

import { Store } from "vuex";
import { RootState } from "@/store";
import {
  ROLE_ADMIN,
  ROLE_CONFIG_USER,
  ROLE_DELEGATE_ADMIN_KDDI,
  ROLE_DELEGATE_ADMIN_PARTNER,
  ROLE_DELEGATE_CONFIG_USER,
  ROLE_DELEGATE_VIEW_USER,
  ROLE_VIEW_USER,
  RoleCode,
} from "@/apis/InformationApi";

/**
 * 権限サービス
 * 権限には管理者、設定権限、閲覧権限の3つのレベル。一般ユーザーと代行権限の2つの種類が存在する
 * 権限レベルには強弱が存在し 管理者権限 > 設定権限 > 閲覧権限 となっている
 * 上位の権限レベルは下位の権限レベルを内包してる
 */
export default class PermissionService {
  constructor(private store: Store<RootState>) {}

  /**
   * true: 指定された権限レベルを持っている, false: 指定された権限レベルを持っていない
   * @param authority 権限レベル。ADMIN: 管理者, CONFIG: 設定権限
   */
  hasAuthority(authority: "ADMIN" | "CONFIG"): boolean {
    const roleCode: RoleCode = this.store.state.user.loginUser.roleCode;
    switch (roleCode) {
      case ROLE_ADMIN:
      case ROLE_DELEGATE_ADMIN_KDDI:
      case ROLE_DELEGATE_ADMIN_PARTNER:
        return authority === "ADMIN" || authority === "CONFIG";
      case ROLE_CONFIG_USER:
      case ROLE_DELEGATE_CONFIG_USER:
        return authority === "CONFIG";
      case ROLE_VIEW_USER:
      case ROLE_DELEGATE_VIEW_USER:
        return false;
    }
  }

  /** true: 一般ユーザー, false: 代行権限 */
  isNormalUser(): boolean {
    const roleCode: RoleCode = this.store.state.user.loginUser.roleCode;
    switch (roleCode) {
      case ROLE_ADMIN:
      case ROLE_CONFIG_USER:
      case ROLE_VIEW_USER:
        return true;
      case ROLE_DELEGATE_ADMIN_KDDI:
      case ROLE_DELEGATE_ADMIN_PARTNER:
      case ROLE_DELEGATE_CONFIG_USER:
      case ROLE_DELEGATE_VIEW_USER:
        return false;
    }
  }

  /** true: 代行権限, false: 一般ユーザー */
  isDelegateUser(): boolean {
    return !this.isNormalUser();
  }

  /** true: 代行閲覧権限, false: 代行閲覧権限ではない */
  isViewDelegate(): boolean {
    return this.isDelegateUser() && !this.hasAuthority("CONFIG");
  }

  /** true: 法人管理者権限代行(KDDI管理者), false: 法人管理者権限代行(KDDI管理者)ではない */
  isKddiAdmin(): boolean {
    const roleCode: RoleCode = this.store.state.user.loginUser.roleCode;
    return roleCode === ROLE_DELEGATE_ADMIN_KDDI;
  }
}

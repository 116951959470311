
































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "IntranetFwPfgwZoneRelationDelete",
  props: {
    srcZoneId: {
      type: String as PropType<string>,
      required: true,
    },
    dstZoneId: {
      type: String as PropType<string>,
      required: true,
    },
    wvs2ZoneId: {
      type: String as PropType<string>,
      required: true,
    },
    kcpsZoneId: {
      type: String as PropType<string>,
      required: true,
    },
  },
});

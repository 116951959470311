































































import Vue, { PropType } from "vue";
import PolicySrcAddress from "@/components/policy/PolicySrcAddress.vue";
import PolicyDstAddress from "@/components/policy/PolicyDstAddress.vue";
import PolicyIpPool from "@/components/policy/PolicyIpPool.vue";
import { Type1IpMasqueradePolicy } from "@/modals/applianceContractSetting/InternetType1PolicyRuleIpMasqViewRD.vue";

export default Vue.extend({
  name: "InternetType1PolicyRuleIpMasqConfirm",
  components: { PolicyIpPool, PolicyDstAddress, PolicySrcAddress },
  props: {
    /** ポリシー */
    policy: {
      type: Object as PropType<Type1IpMasqueradePolicy>,
      required: true,
    },
  },
});

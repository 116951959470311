






























































































































import Vue, { PropType } from "vue";
import { UserEntity } from "@/apis/UserApi";
import PasswordModify from "@/modals/password/PasswordModify.vue";
import UserEdit from "@/modals/user/UserEdit.vue";
import OneTimeUrlIssueModal from "@/modals/password/OneTimeUrlIssueModal.vue";
import {
  ROLE_CONFIG_USER,
  ROLE_DELEGATE_VIEW_USER,
  ROLE_VIEW_USER,
} from "@/apis/InformationApi";

export default Vue.extend({
  name: "UserReference",
  props: {
    /** ユーザ設定情報 */
    user: {
      type: Object as PropType<UserEntity>,
      required: true,
    },
    /** ユーザー管理排他情報 */
    userKeyId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      operationTypeOption: [
        { text: "法人管理者", value: "ADMIN" },
        { text: "設定権限", value: "SETTING" },
        { text: "閲覧権限", value: "VIEWING" },
      ],
    };
  },
  computed: {
    /**
     * true: 変更可能, false: 変更不可
     * 法人の場合は自身以下の権限のユーザーのみ可能
     * 閲覧権限代行権限、閲覧権限は不可、その他は可能
     */
    showModify(): boolean {
      const role = this.$store.state.user.loginUser.roleCode;
      if (role === ROLE_DELEGATE_VIEW_USER || role === ROLE_VIEW_USER) {
        return false;
      }
      if (role === ROLE_CONFIG_USER && this.user.operationType === "ADMIN") {
        return false;
      }
      return true;
    },
    /**
     * true: 削除可能, false: 削除不可
     * 変更可能 + 自身でないこと
     */
    showDelete(): boolean {
      return (
        this.showModify &&
        this.user.loginId !== this.$store.state.user.loginUser.loginId
      );
    },
  },
  methods: {
    /** パスワード変更 */
    async passwordModify() {
      await this.$modal.show(PasswordModify);
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** パスワード再発行 */
    oneTimeUrlIssue() {
      this.$modal.show(OneTimeUrlIssueModal);
    },
    /** ユーザ情報削除 */
    async del() {
      await this.$confirm(`ユーザを削除します。よろしいですか？`);
      await this.$api.user.delete({
        userSeqList: [this.user.userSeq],
        userKeyId: this.userKeyId,
      });
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** ユーザ変更 */
    async showUserModify() {
      await this.$modal.show(UserEdit, {
        userData: this.user,
        userKeyId: this.userKeyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

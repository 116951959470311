import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { ApplianceInfo } from "@/apis/ContractApi";

/** IpMapping一覧情報 **/
export interface IpMappings {
  /**
   * 総件数.
   * 一覧表示対象の総件数
   */
  total: number;
  /**
   * IPマッピング情報.
   * 取得したIPマッピング設定情報。データが存在しない場合、[](空の状態)になります。
   */
  ipMappingList: {
    /** バーチャルIPアドレスSEQ. 主キー */
    virtualIpAddressSeq: string;
    /** 設定値の説明. */
    description: string | null;
    /** IPマッピング名. */
    ipMappingName: string;
    /** グローバルIPアドレス.
     * POST(追加)に選択する候補値はTYPE１の時にはサービスメニューAPI（インターネットType1）、
     * TYPE4の時にはサービスメニューAPI(インターネットType4)より取得する。
     * 取得する項目はグローバルIPアドレスになります。
     *
     */
    globalIpAddress: string;
    /** アドレスID.
     * プライベートIPアドレスのアドレスオブジェクトの主キー、
     * POST(追加)、
     * PUTで選択するプライベートIPアドレスに紐づく値で候補値はTYPE1の時には
     * Type1プライベートサイトゾーン取得APIとType1エクストラサイトゾーン取得APIをあわせた情報、
     * TYPE4の時にはtype4エクストラサイト取得APIより取得する。
     * GETの時は現在登録されているプライベートIPアドレスに紐づく値を返却する。
     */
    ipAddressId: string;
    /** プライベートIPアドレス.
     * POST(追加)、PUTで選択する候補値はTYPE１の時にはType1プライベートサイトゾーン取得APIと
     * Type1エクストラサイトゾーン取得APIをあわせた情報、
     * TYPE4の時にはtype4エクストラサイト取得APIより取得する。
     * 取得する項目はアドレスになります。
     */
    privateIpAddress: string;
    /** ポートフォワードフラグ.
     * ONの場合は「true」、OFFの場合は「false」を設定する。
     */
    isPortForward: boolean;
    /** プロトコル.
     * POST (追加)時にポートフォワードフラグがtrueの場合は必須になります。以下のいずれかを設定します。
     * TCP: TCP
     * UDP: UDP
     */
    protocol: "TCP" | "UDP" | null;
    /** グローバル側Port.
     * POST(追加)時にポートフォワードフラグがtrueの場合は必須になります。
     * ポート用のチェックはパターンでは表現できないため、パターンでは実行せずに他の方法で下記のチェックを実行する。
     * 1:指定範囲を1～65535とする。
     * 2:"-"で範囲指定可能の為、数値 + "-" + 数値の形式での指定を可能とする。
     */
    globalPort: string | null;
    /** プライベート拠点側Port.
     * POST(追加)時にポートフォワードフラグがtrueの場合は必須になります。
     * ポート用のチェックはパターンでは表現できないため、パターンでは実行せずに他の方法で下記のチェックを実行する。
     * 1:指定範囲を1～65535とする。
     * 2:"-"で範囲指定可能の為、数値 + "-" + 数値の形式での指定を可能とする。
     */
    privatePort: string | null;
    /** ポリシー使用フラグ.
     * 有りの場合は「true」、無しの場合は「false」を設定する。
     */
    isPolicyInUse: boolean;
  }[];
  /** IPマッピング排他情報.
   *  排他確認用パラメータ
   *  設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。
   **/
  ipMappingKeyId: string;
}

/**
 * IpMapping一覧の項目型定義
 */
export type IpMapping = IpMappings["ipMappingList"][0];

/**
 * IpMapping登録用
 */
export type PostIpMapping = Omit<
  IpMapping,
  "virtualIpAddressSeq" | "privateIpAddress" | "isPolicyInUse"
> &
  Pick<IpMappings, "ipMappingKeyId"> &
  Pick<DeleteIpMapping, "note">;

/**
 * IpMapping変更用
 */
export type PutIpMapping = Pick<IpMapping, "description" | "ipAddressId"> &
  Pick<IpMappings, "ipMappingKeyId"> &
  Pick<DeleteIpMapping, "note">;

/**
 * IpMapping削除用
 */
export type DeleteIpMapping = Pick<IpMappings, "ipMappingKeyId"> & {
  /** バーチャルIPアドレスSEQリスト.*/
  ipMappingList: string[];
  /** 操作履歴の備考 */
  note: string | null;
};

export default class IpMappingApi extends AbstractBaseApi {
  /** Type1IpMapping一覧を取得する */
  async getType1IpMappingList(): Promise<IpMappings> {
    return (await this.api.get("/v1/network/policy/ip-mappings/type1")).data;
  }
  /** Type4IpMapping一覧を取得する */
  async getType4IpMappingList(
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<IpMappings> {
    return (
      await this.api.get(`/v1/network/policy/ip-mappings/type4/${type4Id}`)
    ).data;
  }

  /** IPマッピング設定情報(TYPE1)を追加を行う
   @param data IPマッピング登録情報
   */
  async postType1IpMapping(data: PostIpMapping): Promise<void> {
    await this.api.post(`/v1/network/policy/ip-mapping/type1`, data);
  }

  /** IPマッピング設定情報(TYPE4)を追加を行う
   @param type4Id
   @param data IPマッピング登録情報
   */
  async postType4IpMapping(
    type4Id: ApplianceInfo["type4Id"],
    data: PostIpMapping
  ): Promise<void> {
    await this.api.post(`/v1/network/policy/ip-mapping/type4/${type4Id}`, data);
  }

  /** IPマッピング設定情報(TYPE1)を変更を行う
   @param virtualIpAddressSeq
   @param data IPマッピング変更情報
   */
  async putType1IpMapping(
    virtualIpAddressSeq: string,
    data: PutIpMapping
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/ip-mapping/type1/${virtualIpAddressSeq}`,
      data
    );
  }

  /** IPマッピング設定情報(TYPE4)を変更を行う
   @param type4Id
   @param virtualIpAddressSeq
   @param data IPマッピング変更情報
   */
  async putType4IpMapping(
    type4Id: ApplianceInfo["type4Id"],
    virtualIpAddressSeq: string,
    data: PutIpMapping
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/ip-mapping/type4/${type4Id}/${virtualIpAddressSeq}`,
      data
    );
  }

  /** IPマッピング設定情報(TYPE1)を削除を行う
   @param data IPマッピング削除情報
   */
  async deleteType1IpMapping(data: DeleteIpMapping): Promise<void> {
    await this.api.post(`/v1/network/policy/ip-mappings/type1/delete`, data);
  }

  /** IPマッピング設定情報(TYPE4)を削除を行う
   @param type4Id
   @param data IPマッピング削除情報
   */
  async deleteType4IpMapping(
    type4Id: ApplianceInfo["type4Id"],
    data: DeleteIpMapping
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/ip-mappings/type4/${type4Id}/delete`,
      data
    );
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',_vm._g(_vm._b({ref:"modal",attrs:{"title":("インターネットType4 " + (_vm.type4Service ? '設定' : '契約') + "インポート"),"classes":"modal-full"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-card-title',{staticClass:"modal-title"},[_vm._v("インターネットType4 "+_vm._s(_vm.type4Service ? "設定" : "契約")+"インポート")])]},proxy:true},{key:"default",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('div',{staticClass:"modal-body"},[(!_vm.inProgress && !_vm.isChanged)?_c('b-form-group',{attrs:{"label":"Type4設定ファイル"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center collapse-header",on:{"click":function($event){_vm.isShowUpload = !_vm.isShowUpload}}},[_c('span',{staticClass:"flex-grow-1"},[_vm._v("Type4設定ファイル")]),(_vm.isShowUpload)?_c('b-icon-caret-down'):_c('b-icon-caret-up')],1)]},proxy:true}],null,true)},[_c('b-collapse',{model:{value:(_vm.isShowUpload),callback:function ($$v) {_vm.isShowUpload=$$v},expression:"isShowUpload"}},[_c('file-upload',{attrs:{"ext":".xls,.xlsx"},on:{"input":_vm.uploadFile}})],1)],1):_vm._e(),(!!_vm.alertMsg)?[_c('b-alert',{attrs:{"variant":"warning","show":true}},[_vm._v(_vm._s(_vm.alertMsg))])]:(_vm.imports)?_c('b-table-simple',[_c('b-thead',{staticClass:"text-center",attrs:{"head-variant":"light"}},[_c('b-tr',[(_vm.showSelectable)?_c('b-th',{staticClass:"th-selected"},[_c('b-form-checkbox',{attrs:{"size":"lg"},model:{value:(_vm.allSchedule),callback:function ($$v) {_vm.allSchedule=$$v},expression:"allSchedule"}})],1):_vm._e(),_c('b-th',{staticClass:"th-name"},[_vm._v("設定項目")]),_c('b-th',{staticClass:"th-progress"},[_vm._v("進捗")]),_c('b-th',{staticClass:"th-status"},[_vm._v("ステータス")])],1)],1),_c('b-tbody',_vm._l((_vm.imports),function(item,i){return _c('b-tr',{key:i},[(_vm.showSelectable)?_c('b-td',[(item.selectable)?_c('b-form-checkbox',{attrs:{"size":"lg"},model:{value:(item.scheduled),callback:function ($$v) {_vm.$set(item, "scheduled", $$v)},expression:"item.scheduled"}}):_vm._e()],1):_vm._e(),_c('b-td',[_vm._v(_vm._s(item.name))]),_c('b-td',[_c('b-progress',{attrs:{"max":10,"animated":item.status === 'IN_PROGRESS'}},[_c('b-progress-bar',{attrs:{"value":item.progress,"variant":item.status === 'COMPLETED'
                      ? 'success'
                      : item.status === 'ERROR'
                      ? 'danger'
                      : 'info'}})],1),(item.error)?_c('div',{staticClass:"invalid-feedback d-block text-pre-line"},[_vm._v(" "+_vm._s(item.error)+" ")]):_vm._e()],1),_c('b-td',[(item.status === 'WAIT')?[_vm._v("実行待ち")]:(item.status === 'IN_PROGRESS' && item.scheduled)?[_vm._v("設定中")]:(item.status === 'IN_PROGRESS' && !item.scheduled)?[_vm._v("スキップ中")]:(item.status === 'COMPLETED')?[_vm._v("完了")]:(item.status === 'SKIPPED')?[_vm._v("スキップ")]:(item.status === 'ERROR')?[_vm._v("エラー")]:_vm._e()],2)],1)}),1)],1):_vm._e()],2),_c('footer',{staticClass:"modal-footer"},[(!_vm.isChanged)?_c('b-button',{attrs:{"variant":"primary","disabled":!!_vm.alertMsg || !_vm.imports || _vm.inProgress},on:{"click":_vm.startImport}},[_vm._v("設定")]):_vm._e(),(!_vm.isChanged)?_c('b-button',{attrs:{"variant":"outline-primary","disabled":_vm.inProgress},on:{"click":function($event){_vm.isChanged ? ok() : cancel()}}},[_vm._v("キャンセル")]):_vm._e(),(_vm.isChanged)?_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.isChanged ? ok() : cancel()}}},[_vm._v("閉じる")]):_vm._e()],1)]}}])},'app-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
import AbstractBaseApi from "@/apis/AbstractBaseApi";
import { ApplianceInfo } from "@/apis/ContractApi";

/** カスタムサービス(インターネットFW)一覧情報 */
export interface CustomServiceList {
  /** 総件数. 一覧表示対象の総件数 一覧画面で一覧に表示する件数 */
  total: number;
  customServiceList: {
    /** カスタムサービスSEQ. 主キー */
    customServiceSeq: string;
    /** サービス名 */
    serviceName: string;
    /** プロトコル. TCP : TCP UDP : UDP */
    protocol: "TCP" | "UDP";
    /** ポート番号. Port番号は0～65535が指定可能 "-"で範囲、","複数指定可能 TCP、またはUDP選択時必須 */
    portNumber: string;
    /** ポリシー使用フラグ. 有り「true」、無し「false」 */
    isPolicyInUse: boolean;
    /** 設定値の説明 */
    description: string | null;
  }[];
  /** カスタムサービス設定排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  customServiceKeyId: string;
}

/** カスタムサービス情報の型定義 */
export type CustomServiceInfo = CustomServiceList["customServiceList"][0];

/** カスタムサービス(インターネットFW)情報 */
export type CustomService = Pick<
  CustomServiceInfo,
  "serviceName" | "protocol" | "portNumber" | "description"
> &
  Pick<CustomServiceList, "customServiceKeyId"> & {
    /** 操作履歴の備考 */
    note: string | null;
  };

/** カスタムサービス(イントラネットFW)一覧情報 */
export interface CustomServiceIntranetfwList {
  /** 総件数. 一覧表示対象の総件数 一覧画面で一覧に表示する件数 */
  total: number;
  customServiceIntranetList: {
    /** カスタムサービスSEQ. 主キー */
    customServiceSeq: string;
    /** サービス名 */
    serviceName: string;
    /** プロトコル(イントラネットFW). TCP : TCP UDP : UDP IP : IP ICMP : ICMP */
    protocol: "TCP" | "UDP" | "IP" | "ICMP";
    /** ポート番号. Port番号は0～65535が指定可能 "-"で範囲、","複数指定可能 TCP、またはUDP選択時必須 */
    portNumber: string | null;
    /** プロトコル番号 0～254の範囲で入力 IP選択時必須 */
    protocolNumber: number | null;
    /** ICMPタイプ 0～255の範囲で入力 ICMP選択時必須 */
    icmpType: number | null;
    /** ICMPコード 0～255の範囲で入力 ICMP選択時必須 */
    icmpCode: number | null;
    /** ポリシー使用フラグ. 有り「true」、無し「false」 */
    isPolicyInUse: boolean;
    /** 設定値の説明 */
    description: string | null;
  }[];
  /** カスタムサービス設定排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  customServiceKeyId: string;
}

/** カスタムサービス(イントラネットFW)情報の型定義 */
export type CustomServiceIntranet =
  CustomServiceIntranetfwList["customServiceIntranetList"][0];

/** カスタムサービス(イントラネットFW)情報 */
export type CustomServiceIntranetfw = Omit<
  CustomServiceIntranet,
  "customServiceSeq" | "isPolicyInUse"
> &
  Pick<CustomServiceIntranetfwList, "customServiceKeyId"> & {
    /** 操作履歴の備考 */
    note: string | null;
  };

/** カスタムサービスの削除情報 */
export interface CustomServiceDelete {
  /** カスタムサービスSEQ 主キー */
  customServiceSeqList: string[];
  /** 操作履歴の備考 */
  note?: string;
  /** カスタムサービス設定排他情報 排他確認用パラメータ 設定変更を行う場合にはGETメソッドにて通知した内容をそのまま渡すこと。 */
  customServiceKeyId: string;
}

export default class CustomServiceApi extends AbstractBaseApi {
  /** Type1カスタムサービス情報を取得 */
  async getType1CustomService(): Promise<CustomServiceList> {
    return (
      await this.api.get("/v1/network/policy/services/custom/internetfw/type1")
    ).data;
  }

  /**
   * Type4カスタムサービス情報を取得
   * @param type4Id type4Id
   */
  async getType4CustomService(
    type4Id: ApplianceInfo["type4Id"]
  ): Promise<CustomServiceList> {
    return (
      await this.api.get(
        `/v1/network/policy/services/custom/internetfw/type4/${type4Id}`
      )
    ).data;
  }

  /**
   * Type1カスタムサービス情報を登録
   * @param postData 登録情報
   */
  async postType1CustomService(postData: CustomService): Promise<void> {
    await this.api.post(
      "/v1/network/policy/service/custom/internetfw/type1",
      postData
    );
  }

  /**
   * Type4カスタムサービス情報を登録
   * @param type4Id type4Id
   * @param postData 登録情報
   */
  async postType4CustomService(
    type4Id: string,
    postData: CustomService
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/service/custom/internetfw/type4/${type4Id}`,
      postData
    );
  }

  /**
   * Type1カスタムサービス情報を変更
   * @param customServiceSeq カスタムサービスSeq
   * @param data 更新情報
   */
  async putType1CustomService(
    customServiceSeq: string,
    data: CustomService
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/service/custom/internetfw/type1/${customServiceSeq}`,
      data
    );
  }

  /**
   * Type4カスタムサービス情報を変更
   * @param type4Id type4Id
   * @param customServiceSeq カスタムサービスSeq
   * @param data 更新情報
   */
  async putType4CustomService(
    type4Id: string,
    customServiceSeq: string,
    data: CustomService
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/service/custom/internetfw/type4/${type4Id}/${customServiceSeq}`,
      data
    );
  }

  /**
   * Type1カスタムサービス情報を削除
   * @param data 削除情報
   */
  async deleteType1CustomService(data: CustomServiceDelete): Promise<void> {
    await this.api.post(
      "/v1/network/policy/services/custom/internetfw/type1/delete",
      data
    );
  }

  /**
   * Type4カスタムサービス情報を削除
   * @param type4Id type4Id
   * @param data 削除情報
   */
  async deleteType4CustomService(
    type4Id: ApplianceInfo["type4Id"],
    data: CustomServiceDelete
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/services/custom/internetfw/type4/${type4Id}/delete`,
      data
    );
  }

  /**
   * カスタムサービス情報(イントラネットFW)を取得
   * @param policyType ポリシー種別
   *                   アプライアンスの種別を指定する。
   *                   INTRANET_FW ：イントラネットFW
   *                   INTRANET_FW_PFGW ：イントラネットFW（PFGW）
   * @param enumberAct E番号(ACT)
   *                   主キー
   *                   GETメソッドから取得したACT側のE番号
   *                   ポリシー種別でINTRANET_FW_PFGWを指定時必須
   */
  async getIntranetfwCustomService(
    policyType: "INTRANET_FW" | "INTRANET_FW_PFGW",
    enumberAct?: string
  ): Promise<CustomServiceIntranetfwList> {
    return (
      await this.api.get("/v1/network/policy/services/custom/intranetfw", {
        params: { policyType, enumberAct },
      })
    ).data;
  }

  /**
   * カスタムサービス情報(イントラネットFW)を登録
   * @param policyType ポリシー種別
   *                   アプライアンスの種別を指定する。
   *                   INTRANET_FW ：イントラネットFW
   *                   INTRANET_FW_PFGW ：イントラネットFW（PFGW）
   * @param enumberAct E番号(ACT)
   *                   主キー
   *                   GETメソッドから取得したACT側のE番号
   *                   ポリシー種別でINTRANET_FW_PFGWを指定時必須
   * @param postData 登録情報
   */
  async postIntranetfwCustomService(
    policyType: "INTRANET_FW" | "INTRANET_FW_PFGW",
    enumberAct: string | undefined,
    postData: CustomServiceIntranetfw
  ): Promise<void> {
    await this.api.post(
      "/v1/network/policy/service/custom/intranetfw",
      postData,
      { params: { policyType, enumberAct } }
    );
  }

  /**
   * カスタムサービス情報(イントラネットFW)を変更
   * @param customServiceSeq カスタムサービスSeq
   * @param policyType ポリシー種別
   *                   アプライアンスの種別を指定する。
   *                   INTRANET_FW ：イントラネットFW
   *                   INTRANET_FW_PFGW ：イントラネットFW（PFGW）
   * @param enumberAct E番号(ACT)
   *                   主キー
   *                   GETメソッドから取得したACT側のE番号
   *                   ポリシー種別でINTRANET_FW_PFGWを指定時必須
   * @param data 更新情報
   */
  async putIntranetfwCustomService(
    policyType: "INTRANET_FW" | "INTRANET_FW_PFGW",
    enumberAct: string | undefined,
    customServiceSeq: string,
    data: CustomServiceIntranetfw
  ): Promise<void> {
    await this.api.post(
      `/v1/network/policy/service/custom/intranetfw/${customServiceSeq}`,
      data,
      { params: { policyType, enumberAct } }
    );
  }

  /**
   * カスタムサービス情報(イントラネットFW)を削除
   * @param policyType ポリシー種別
   *                   アプライアンスの種別を指定する。
   *                   INTRANET_FW ：イントラネットFW
   *                   INTRANET_FW_PFGW ：イントラネットFW（PFGW）
   * @param enumberAct E番号(ACT)
   *                   主キー
   *                   GETメソッドから取得したACT側のE番号
   *                   ポリシー種別でINTRANET_FW_PFGWを指定時必須
   * @param data 削除情報
   */
  async deleteIntranetfwCustomService(
    policyType: "INTRANET_FW" | "INTRANET_FW_PFGW",
    enumberAct: string | undefined,
    data: CustomServiceDelete
  ): Promise<void> {
    await this.api.post(
      "/v1/network/policy/services/custom/intranetfw/delete",
      data,
      { params: { policyType, enumberAct } }
    );
  }
}















































































import Vue, { PropType } from "vue";
import { NoticeInfoDetail } from "@/apis/InformationApi";

export default Vue.extend({
  name: "NotificationReference",
  props: {
    /** お知らせSEQ 主キー */
    noticeInfoSeq: {
      type: String as PropType<string>,
      required: true,
    },
    /** お知らせ種別 */
    noticeType: {
      type: String as PropType<
        "NOTIFICATION" | "FAILURE_NOTIFICATION" | "MAINTENANCE"
      >,
      required: true,
    },
  },
  data() {
    return {
      notification: {} as NoticeInfoDetail,
      isLoaded: false,
    };
  },
  computed: {
    title(): string {
      if (this.noticeType === "MAINTENANCE") {
        return "メンテナンス";
      } else if (this.noticeType === "FAILURE_NOTIFICATION") {
        return "障害情報";
      } else {
        return "その他お知らせ";
      }
    },
  },
  async mounted() {
    this.notification = await this.$api.information.getNotificationDetail(
      this.noticeInfoSeq
    );
    this.isLoaded = true;
  },
});

import moment from "moment";

/**
 * 時刻情報を日時形式でフォーマット
 * @param value 時刻情報
 * @param option s = true: 秒数表示（デフォルト: false）
 */
export default function datetime(
  value?: string | null | Date,
  option: { s: boolean } = { s: false }
): string | null {
  if (!value) {
    return "-";
  }
  const datetime = moment(value);
  if (datetime.isValid()) {
    if (option.s) {
      return datetime.format("YYYY/MM/DD HH:mm:ss");
    } else {
      return datetime.format("YYYY/MM/DD HH:mm");
    }
  } else {
    return null;
  }
}

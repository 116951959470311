









































































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import { VNConnect } from "@/apis/VNConnectApi";
import LimitModify from "@/modals/common/LimitModify.vue";
import VNConnectEdit from "@/modals/vnConnect/VNConnectEdit.vue";
import VNConnectLumpRegister from "@/modals/vnConnect/VNConnectLumpRegister.vue";
import VNConnectStatusModify from "@/modals/vnConnect/VNConnectStatusModify.vue";
import VNConnectReference from "@/modals/vnConnect/VNConnectReference.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VNConnectList",
  data() {
    return {
      alertMsg: null as string | null,
      statusMixed: false,
      connect: {
        fields: [
          {
            key: "wnumber",
            label: "VNコネクト番号",
            sortable: true,
          },
          {
            key: "vnConnectName",
            label: "VNコネクト名",
            sortable: true,
          },
          {
            key: "enumber",
            label: "回線番号(E番号)",
            sortable: true,
          },
          {
            key: "vlan",
            label: "VLAN",
            sortable: true,
            tdClass: "text-right",
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, key: null, item: VNConnect) => {
              if (item.vlanIdType === "SPECIFIED") {
                return `${item.vlanId}`;
              } else {
                return this.$filter.enumTo(item.vlanIdType, "vlanIdType");
              }
            },
          },
          {
            key: "dstVpnVn",
            label: "接続先VPN/VNコード",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, key: null, item: VNConnect) => {
              if (item.vnName) {
                return `${item.vnName}(${item.vpnVnCode}:${item.vnType})`;
              } else {
                return `${item.vpnVnCode}`;
              }
            },
          },
          {
            key: "siteName",
            label: "サイト名",
            sortable: true,
          },
          {
            key: "ifStatus",
            label: "IF状態",
            sortable: true,
          },
          {
            key: "isVnConnectStatus",
            label: "有効/無効",
            sortable: true,
          },
        ],
        items: [],
        selected: [],
        search: {
          wnumber: { type: "text", label: "VNコネクト番号" },
          vnConnectName: { type: "text", label: "VNコネクト名" },
          enumber: { type: "text", label: "回線番号(E番号)" },
          vlan: { type: "text", label: "VLAN" },
          dstVpnVn: { type: "text", label: "接続先VPN/VNコード" },
          siteName: { type: "text", label: "サイト名" },
          ifStatus: {
            type: "select",
            label: "IF状態",
            items: [
              { value: "NO_SHUTDOWN", text: "no shutdown" },
              { value: "SHUTDOWN", text: "shutdown" },
              { value: null, text: "-" },
            ],
          },
          isVnConnectStatus: {
            type: "select",
            label: "有効/無効",
            items: [
              { value: true, text: "有効" },
              { value: false, text: "無効" },
            ],
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          wnumber: "like",
          vnConnectName: "like",
          enumber: "like",
          vlan: "like",
          dstVpnVn: "like",
          siteName: "like",
          ifStatus: "equal",
          isVnConnectStatus: "equal",
        },
      } as AppTableData<VNConnect>,
      keyId: "",
      isLoaded: false,
    };
  },
  computed: {
    ...mapState("user", {
      contractSummary: "contractSummary",
    }),
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  methods: {
    async load() {
      this.alertMsg = null;
      /** VNコネクトを一覧取得APIの呼び出し処理 */
      const connectsData = await this.$api.vNConnect.getVNConnects();
      this.connect.items = connectsData.vnConnectList;
      this.keyId = connectsData.vnConnectKeyId;
    },
    /** 上限値設定 */
    async limitModify() {
      this.alertMsg = null;
      await this.$modal.show(LimitModify, {
        settingType: "vnConnect",
      });
      await this.load();
    },

    /** 一括設定 */
    async lumpRegister() {
      this.alertMsg = null;
      await this.$modal.show(VNConnectLumpRegister);
      await this.load();
    },

    /** 追加 */
    async register() {
      this.alertMsg = null;
      await this.$modal.show(VNConnectEdit, {
        keyId: this.keyId,
        vnConnects: this.connect.items,
      });
      await this.load();
    },

    /** 有効・無効 */
    async modifyStatus(status: boolean) {
      if (this.connect.selected.length === 0) {
        this.alertMsg = this.$msg("no_select_data");
        return;
      }
      if (this.connect.selected.length > 10) {
        this.alertMsg = this.$msg("vn_connect_limit");
        return;
      }
      // 既に指定した状態になっているものがないかチェック
      if (status) {
        if (this.connect.selected.find((e) => e.isVnConnectStatus)) {
          this.alertMsg = this.$msg("vn_connect_activate");
          return;
        }
      } else {
        if (this.connect.selected.find((e) => !e.isVnConnectStatus)) {
          this.alertMsg = this.$msg("vn_connect_deactivate");
          return;
        }
      }

      this.alertMsg = null;
      await this.$modal.show(VNConnectStatusModify, {
        wnumbers: this.connect.selected.map((e) => e.wnumber),
        status: status ? "有効" : "無効",
      });

      if (status) {
        await this.$api.vNConnect.postVNConnectEnable({
          orderList: this.connect.selected.map((e) => e.wnumber),
          vnConnectKeyId: this.keyId,
        });
      } else {
        await this.$api.vNConnect.postVNConnectDisable({
          orderList: this.connect.selected.map((e) => e.wnumber),
          vnConnectKeyId: this.keyId,
        });
      }

      await this.load();
    },

    /** VNコネクト詳細表示 */
    async connectDetails(entity: VNConnect) {
      this.alertMsg = null;
      await this.$modal.show(VNConnectReference, {
        wnumber: entity.wnumber,
      });
      await this.load();
    },
  },
});

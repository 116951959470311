


















































































import Vue, { PropType } from "vue";
import { ContractListEntity } from "@/apis/ContractLineApi";

export default Vue.extend({
  name: "ContractLineReference",
  components: {},
  props: {
    contractReference: {
      type: Object as PropType<ContractListEntity>,
      required: true,
    },
  },
});

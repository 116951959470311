


















































































































































































































































































































































import Vue, { PropType } from "vue";
import { UserEntity } from "@/apis/UserApi";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "UserEdit",
  props: {
    vnumber: {
      type: String as PropType<string>,
      required: false,
    },
    userData: {
      type: Object as PropType<UserEntity>,
      required: false,
    },
    userKeyId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      form:
        cloneDeep(this.userData) ??
        ({
          ipAuthAddressList: [""],
          operationType: this.$service.permission.hasAuthority("ADMIN")
            ? "ADMIN"
            : this.$service.permission.hasAuthority("CONFIG")
            ? "SETTING"
            : "VIEWING",
        } as UserEntity),
      isAuthAddress: (this.userData?.ipAuthAddressList.length ?? 0) > 0,
      groupType: (this.userData?.group ? "EXISTS" : "NONE") as
        | "NONE"
        | "EXISTS"
        | "NEW",
      newGroup: null,
      userGroupListSelect: this.userData?.group ?? null,
      userGroupList: [] as string[],
    };
  },
  watch: {
    isAuthAddress: function () {
      if (this.form.ipAuthAddressList.length < 1) {
        this.form.ipAuthAddressList = [""];
      }
    },
  },
  computed: {
    /** 認証IPアドレスの重複チェック用リスト作成 */
    ipAddressList() {
      return (index: number) => {
        return this.form.ipAuthAddressList.filter((_, num) => num !== index);
      };
    },
    /**
     * true: 運用種別で法人管理者を選択可能, false: 選択不可
     * 法人の場合は自身の権限以下の運用種別が選択可能
     * 代行の場合は全てが選択可能、閲覧代行はこの画面を表示できないので考慮不要
     */
    showAdminType(): boolean {
      return (
        this.$service.permission.isDelegateUser() ||
        this.$service.permission.hasAuthority("ADMIN")
      );
    },
    /**
     * true: 運用種別で設定権限を選択可能, false: 選択不可
     * 法人の場合は自身の権限以下の運用種別が選択可能
     * 代行の場合は全てが選択可能、閲覧代行はこの画面を表示できないので考慮不要
     */
    showSettingType(): boolean {
      return (
        this.$service.permission.isDelegateUser() ||
        this.$service.permission.hasAuthority("CONFIG")
      );
    },
  },
  async mounted() {
    this.userGroupList = (await this.$api.user.getGroupList()).userGroupList;
  },
  methods: {
    async submit() {
      this.form.group =
        this.groupType === "NONE"
          ? null
          : this.groupType === "EXISTS"
          ? this.userGroupListSelect
          : this.newGroup;
      if (this.userData) {
        await this.$confirm("ユーザ情報を変更します。よろしいですか？");
        await this.$api.user.update(this.form.userSeq, {
          ...this.form,
          ipAuthAddressList: this.isAuthAddress
            ? this.form.ipAuthAddressList.length === 1 &&
              !this.form.ipAuthAddressList[0]
              ? []
              : this.form.ipAuthAddressList
            : [],
          userKeyId: this.userKeyId,
        });
      } else {
        await this.$confirm("ユーザを追加します。よろしいですか？");
        await this.$api.user.post({
          ...this.form,
          ipAuthAddressList: this.isAuthAddress
            ? this.form.ipAuthAddressList
            : [],
          oneTimeUrl: process.env.VUE_APP_ONETIME_URL,
        });
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },

    /** 認証IPアドレス削除 */
    removeIpAddress(index: number) {
      if (this.form.ipAuthAddressList.length > 1) {
        this.form.ipAuthAddressList.splice(index, 1);
      } else {
        this.form.ipAuthAddressList = [""];
      }
    },

    /** 認証IPアドレス追加 */
    addIpAddress() {
      this.form.ipAuthAddressList = [...this.form.ipAuthAddressList, ""];
    },
  },
});
